import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
    FileText,
    Download,
    Loader2,
    Share2,
    Settings,
    Printer,
    CheckSquare,
    X,
    Mail,
    Calendar
} from 'lucide-react';
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "../../ui/dialog";
import {
    Drawer,
    DrawerContent,
    DrawerDescription,
    DrawerFooter,
    DrawerHeader,
    DrawerTitle,
    DrawerTrigger,
} from "../../ui/drawer";
import { SheetFooter } from '../../ui/drawer';
import { Button } from "../../ui/button";
import { Checkbox } from "../../ui/checkbox";
import { Label } from "../../ui/label";
import { Switch } from "../../ui/switch";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "../../ui/tabs";
import { Input } from "../../ui/input";
import { showToast } from '../../../utils/toast';
import api from '../../../services/api';
import { primaryButtonClasses, secondaryButtonClasses } from '../../common/ButtonStyles';

/**
 * Component for generating PDF reports from inspection data
 */
const InspectionReportGenerator = ({ inspection, onClose }) => {
    const { projectId } = useParams();
    const [isGenerating, setIsGenerating] = useState(false);
    const [activeTab, setActiveTab] = useState('options');
    const [emailData, setEmailData] = useState({
        recipients: '',
        subject: '',
        message: ''
    });
    const [reportOptions, setReportOptions] = useState({
        includePhotos: true,
        includeQualityIssues: true,
        includeSignatures: false,
        template: 'standard',
        clientVersion: false
    });
    const [format, setFormat] = useState('pdf');
    const [error, setError] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    // Check if we're on a mobile device for responsive UI
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // Generate default email subject and message based on inspection
    useEffect(() => {
        if (inspection) {
            const templateName = inspection.template_name || "Inspection";
            const projectName = inspection.project_name || "Project";
            const date = new Date().toLocaleDateString();

            setEmailData({
                recipients: '',
                subject: `${templateName} Report - ${projectName} - ${date}`,
                message: `Please find attached the ${templateName} report for ${projectName} conducted on ${date}.\n\nThis report was automatically generated from our inspection system.`
            });
        }
    }, [inspection]);

    const generateReport = async () => {
        try {
            setIsGenerating(true);
            setError(null);

            // Construct the API URL with query parameters for options
            const params = new URLSearchParams({
                include_photos: reportOptions.includePhotos,
                include_issues: reportOptions.includeQualityIssues,
                include_signatures: reportOptions.includeSignatures,
                template: reportOptions.template,
                client_version: reportOptions.clientVersion
            });

            // Call the backend API to generate the PDF
            const response = await api.get(
                `projects/${projectId}/inspections/${inspection.id}/report/?${params}`,
                { responseType: 'blob' }
            );

            // Create a download link for the PDF
            const blob = new Blob([response.data], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `Inspection_Report_${inspection.id}.pdf`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);

            showToast.success('Report generated successfully');

            // If the email tab is active, don't close
            if (activeTab !== 'email') {
                onClose();
            }
        } catch (err) {
            console.error('Error generating report:', err);
            setError('Failed to generate report. Please try again.');
            showToast.error('Failed to generate report');
        } finally {
            setIsGenerating(false);
        }
    };

    const sendReportEmail = async () => {
        try {
            setIsGenerating(true);
            setError(null);

            // Validate email recipients
            const emailRecipients = emailData.recipients.split(',').map(email => email.trim());
            if (emailRecipients.length === 0 || emailRecipients[0] === '') {
                setError('Please enter at least one email recipient');
                setIsGenerating(false);
                return;
            }

            // Use URLSearchParams instead of FormData (simpler for this case)
            const params = {
                include_photos: reportOptions.includePhotos,
                include_issues: reportOptions.includeQualityIssues,
                include_signatures: reportOptions.includeSignatures,
                template: reportOptions.template,
                client_version: reportOptions.clientVersion,
                email_to: emailRecipients.join(','),
                email_subject: emailData.subject,
                email_message: emailData.message
            };

            // Call the backend API with a JSON request instead
            await api.post(
                `projects/${projectId}/inspections/${inspection.id}/email-report/`,
                params
            );

            showToast.success('Report emailed successfully');
            onClose();
        } catch (err) {
            console.error('Error emailing report:', err);
            setError('Failed to email report. Please try again.');
            showToast.error('Failed to email report');
        } finally {
            setIsGenerating(false);
        }
    };

    // Render options panel
    const renderOptionsPanel = () => (
        <div className="space-y-4">
            <div className="space-y-2">
                <Label className="text-base font-medium">Report Template</Label>
                <div className="grid grid-cols-1 sm:grid-cols-3 gap-2">
                    <Button
                        type="button"
                        variant={reportOptions.template === 'standard' ? 'default' : 'outline'}
                        className="justify-start w-full"
                        onClick={() => setReportOptions({ ...reportOptions, template: 'standard' })}
                    >
                        <FileText className="w-4 h-4 mr-2" />
                        Standard
                    </Button>
                    <Button
                        type="button"
                        variant={reportOptions.template === 'detailed' ? 'default' : 'outline'}
                        className="justify-start w-full"
                        onClick={() => setReportOptions({ ...reportOptions, template: 'detailed' })}
                    >
                        <FileText className="w-4 h-4 mr-2" />
                        Detailed
                    </Button>
                    <Button
                        type="button"
                        variant={reportOptions.template === 'client' ? 'default' : 'outline'}
                        className="justify-start w-full"
                        onClick={() => setReportOptions({ ...reportOptions, template: 'client' })}
                    >
                        <FileText className="w-4 h-4 mr-2" />
                        Client
                    </Button>
                </div>
            </div>

            <div className="space-y-2">
                <Label className="text-base font-medium">Include in Report</Label>
                <div className="space-y-2">
                    <div className="flex items-center space-x-2">
                        <Checkbox
                            id="includePhotos"
                            checked={reportOptions.includePhotos}
                            onCheckedChange={(checked) =>
                                setReportOptions({ ...reportOptions, includePhotos: checked })
                            }
                        />
                        <Label htmlFor="includePhotos" className="font-normal">
                            Include photos
                        </Label>
                    </div>

                    <div className="flex items-center space-x-2">
                        <Checkbox
                            id="includeQualityIssues"
                            checked={reportOptions.includeQualityIssues}
                            onCheckedChange={(checked) =>
                                setReportOptions({ ...reportOptions, includeQualityIssues: checked })
                            }
                        />
                        <Label htmlFor="includeQualityIssues" className="font-normal">
                            Include quality issues
                        </Label>
                    </div>

                    <div className="flex items-center space-x-2">
                        <Checkbox
                            id="includeSignatures"
                            checked={reportOptions.includeSignatures}
                            onCheckedChange={(checked) =>
                                setReportOptions({ ...reportOptions, includeSignatures: checked })
                            }
                        />
                        <Label htmlFor="includeSignatures" className="font-normal">
                            Include signature fields
                        </Label>
                    </div>
                </div>
            </div>

            <div className="space-y-2">
                <div className="flex items-center justify-between">
                    <Label htmlFor="clientVersion" className="text-base font-medium">
                        Client Version
                    </Label>
                    <Switch
                        id="clientVersion"
                        checked={reportOptions.clientVersion}
                        onCheckedChange={(checked) =>
                            setReportOptions({ ...reportOptions, clientVersion: checked })
                        }
                    />
                </div>
                <p className="text-sm text-gray-500">
                    Client version removes internal notes and sensitive information
                </p>
            </div>
        </div>
    );

    // Render email form
    const renderEmailForm = () => (
        <div className="space-y-4">
            <div className="space-y-2">
                <Label htmlFor="recipients" className="text-base font-medium">
                    Recipients
                </Label>
                <Input
                    id="recipients"
                    placeholder="Enter email addresses (comma separated)"
                    value={emailData.recipients}
                    onChange={(e) => setEmailData({ ...emailData, recipients: e.target.value })}
                />
                <p className="text-sm text-gray-500">
                    Separate multiple email addresses with commas
                </p>
            </div>

            <div className="space-y-2">
                <Label htmlFor="subject" className="text-base font-medium">
                    Subject
                </Label>
                <Input
                    id="subject"
                    value={emailData.subject}
                    onChange={(e) => setEmailData({ ...emailData, subject: e.target.value })}
                />
            </div>

            <div className="space-y-2">
                <Label htmlFor="message" className="text-base font-medium">
                    Message
                </Label>
                <textarea
                    id="message"
                    className="w-full min-h-[100px] lg:min-h-[120px] p-3 border border-input rounded-md"
                    value={emailData.message}
                    onChange={(e) => setEmailData({ ...emailData, message: e.target.value })}
                />
            </div>

            {/* Simplified options for email mode */}
            <div className="space-y-2 pt-4 border-t border-gray-200">
                <Label className="text-base font-medium">Report Options</Label>
                <div className="space-y-2">
                    <div className="flex items-center space-x-2">
                        <Checkbox
                            id="includePhotosEmail"
                            checked={reportOptions.includePhotos}
                            onCheckedChange={(checked) =>
                                setReportOptions({ ...reportOptions, includePhotos: checked })
                            }
                        />
                        <Label htmlFor="includePhotosEmail" className="font-normal">
                            Include photos
                        </Label>
                    </div>
                    <div className="flex items-center space-x-2">
                        <Checkbox
                            id="clientVersionEmail"
                            checked={reportOptions.clientVersion}
                            onCheckedChange={(checked) =>
                                setReportOptions({ ...reportOptions, clientVersion: checked })
                            }
                        />
                        <Label htmlFor="clientVersionEmail" className="font-normal">
                            Client version (removes internal notes)
                        </Label>
                    </div>
                </div>
            </div>
        </div>
    );

    // For mobile devices, use a drawer with tabs
    if (isMobile) {
        return (
            <Drawer open={true} onOpenChange={onClose}>
                <DrawerContent className="max-h-[90vh]">
                    <DrawerHeader className="px-4">
                        <DrawerTitle>Generate Report</DrawerTitle>
                        <DrawerDescription>
                            Create a PDF report of this inspection
                        </DrawerDescription>
                    </DrawerHeader>

                    <div className="px-4 overflow-y-auto pb-4">
                        <Tabs value={activeTab} onValueChange={setActiveTab} className="w-full">
                            <TabsList className="w-full mb-4">
                                <TabsTrigger value="options" className="flex-1">
                                    <Settings className="w-4 h-4 mr-2" />
                                    Options
                                </TabsTrigger>
                                <TabsTrigger value="email" className="flex-1">
                                    <Mail className="w-4 h-4 mr-2" />
                                    Email
                                </TabsTrigger>
                            </TabsList>

                            <TabsContent value="options" className="mt-0">
                                {renderOptionsPanel()}
                            </TabsContent>

                            <TabsContent value="email" className="mt-0">
                                {renderEmailForm()}
                            </TabsContent>
                        </Tabs>

                        {error && (
                            <div className="mt-4 p-2 bg-red-50 text-red-600 rounded text-sm">
                                {error}
                            </div>
                        )}
                    </div>

                    <DrawerFooter className="px-4 pt-2 border-t">
                        {activeTab === 'options' ? (
                            <div className="flex flex-col gap-2 w-full">
                                <Button
                                    onClick={generateReport}
                                    disabled={isGenerating}
                                    className={primaryButtonClasses}
                                >
                                    {isGenerating ? (
                                        <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                                    ) : (
                                        <Download className="w-4 h-4 mr-2" />
                                    )}
                                    Download Report
                                </Button>

                                <Button
                                    onClick={() => setActiveTab('email')}
                                    disabled={isGenerating}
                                    variant="outline"
                                >
                                    <Mail className="w-4 h-4 mr-2" />
                                    Email Report
                                </Button>

                                <Button
                                    onClick={onClose}
                                    variant="ghost"
                                    disabled={isGenerating}
                                >
                                    Cancel
                                </Button>
                            </div>
                        ) : (
                            <div className="flex flex-col gap-2 w-full">
                                <Button
                                    onClick={sendReportEmail}
                                    disabled={isGenerating}
                                    className={primaryButtonClasses}
                                >
                                    {isGenerating ? (
                                        <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                                    ) : (
                                        <Mail className="w-4 h-4 mr-2" />
                                    )}
                                    Send Email
                                </Button>

                                <Button
                                    onClick={() => setActiveTab('options')}
                                    disabled={isGenerating}
                                    variant="outline"
                                >
                                    Back to Options
                                </Button>

                                <Button
                                    onClick={onClose}
                                    variant="ghost"
                                    disabled={isGenerating}
                                >
                                    Cancel
                                </Button>
                            </div>
                        )}
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
        );
    }

    // Desktop dialog interface
    return (
        <Dialog open={true} onOpenChange={onClose}>
            <DialogContent className="max-w-md">
                <DialogHeader>
                    <DialogTitle>Generate Inspection Report</DialogTitle>
                    <DialogDescription>
                        Create a PDF report of this inspection.
                    </DialogDescription>
                </DialogHeader>

                <Tabs value={activeTab} onValueChange={setActiveTab} className="w-full">
                    <TabsList className="w-full mb-4">
                        <TabsTrigger value="options" className="flex-1">
                            <Settings className="w-4 h-4 mr-2" />
                            Options
                        </TabsTrigger>
                        <TabsTrigger value="email" className="flex-1">
                            <Mail className="w-4 h-4 mr-2" />
                            Email
                        </TabsTrigger>
                    </TabsList>

                    <TabsContent value="options" className="mt-0">
                        {renderOptionsPanel()}
                    </TabsContent>

                    <TabsContent value="email" className="mt-0">
                        {renderEmailForm()}
                    </TabsContent>
                </Tabs>

                {error && (
                    <div className="p-2 bg-red-50 text-red-600 rounded text-sm">
                        {error}
                    </div>
                )}

                <DialogFooter className="flex flex-col sm:flex-row gap-2 sm:gap-0">
                    {activeTab === 'options' ? (
                        <>
                            <Button
                                onClick={generateReport}
                                disabled={isGenerating}
                                className={`${primaryButtonClasses} w-full sm:w-auto`}
                            >
                                {isGenerating ? (
                                    <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                                ) : (
                                    <Download className="w-4 h-4 mr-2" />
                                )}
                                Download PDF
                            </Button>
                        </>
                    ) : (
                        <Button
                            onClick={sendReportEmail}
                            disabled={isGenerating}
                            className={`${primaryButtonClasses} w-full sm:w-auto`}
                        >
                            {isGenerating ? (
                                <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                            ) : (
                                <Mail className="w-4 h-4 mr-2" />
                            )}
                            Send Email
                        </Button>
                    )}
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};

export default InspectionReportGenerator;