import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "../../ui/cardui";
import { Button } from "../../ui/button";
import { Input } from "../../ui/input";
import { Plus, ChevronRight, ChevronDown, Trash2 } from "lucide-react";
import { useToast } from "../../ui/use-toast";
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogFooter,
} from "../../ui/dialog";
import { Label } from "../../ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../ui/select";
import api from '../../../services/api';
import { primaryButtonClasses, secondaryButtonClasses } from '../../common/ButtonStyles';

const WBSManager = ({ projectId }) => {
    const [wbsItems, setWbsItems] = useState([]);
    const [expandedItems, setExpandedItems] = useState(new Set());
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [selectedParentId, setSelectedParentId] = useState(null);
    const { toast } = useToast();

    useEffect(() => {
        loadWBS();
    }, [projectId]);

    const loadWBS = async () => {
        try {
            const response = await api.get(`wbs/?project=${projectId}`);
            setWbsItems(response.data.results || []);
            console.log("WBS data: ", response);
        } catch (error) {
            toast({
                title: "Error",
                description: "Failed to load WBS items",
                variant: "destructive"
            });
        }
    };

    // Function to generate the next available code
    const generateCode = (parentItem = null) => {
        if (!parentItem) {
            // For root level items, find the highest number and add 1
            const rootItems = wbsItems.filter(item => !item.parent);
            const highestCode = Math.max(0, ...rootItems.map(item => parseInt(item.code)));
            return (highestCode + 1).toString();
        } else {
            // For child items, append .1 to parent's code if no siblings,
            // or increment the last number for existing siblings
            const siblings = wbsItems.filter(item => item.parent === parentItem.id);
            if (siblings.length === 0) {
                return `${parentItem.code}.1`;
            }
            const lastSibling = siblings.sort((a, b) => {
                const aNum = parseInt(a.code.split('.').pop());
                const bNum = parseInt(b.code.split('.').pop());
                return bNum - aNum;
            })[0];
            const lastNum = parseInt(lastSibling.code.split('.').pop());
            return `${parentItem.code}.${lastNum + 1}`;
        }
    };

    // Function to reorder codes after deletion
    const reorderCodes = async (items) => {
        const updatePromises = items.map(async (item, index) => {
            const newCode = (index + 1).toString();
            try {
                await api.patch(`wbs/${item.id}/`, { code: newCode });
                return { ...item, code: newCode };
            } catch (error) {
                console.error('Error updating code:', error);
                return item;
            }
        });

        const updatedItems = await Promise.all(updatePromises);
        return updatedItems;
    };

    const handleAddItem = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const data = Object.fromEntries(formData.entries());

        try {
            const parentItem = selectedParentId ?
                wbsItems.find(item => item.id === selectedParentId) : null;

            const code = generateCode(parentItem);

            // Get tenant information from the current user
            const userResponse = await api.get('tenant/users/');
            const currentUser = Array.isArray(userResponse.data) ? userResponse.data[0] : null;

            if (!currentUser?.tenant) {
                throw new Error('Unable to determine tenant information');
            }

            const payload = {
                project: projectId,
                name: data.name,
                code: code,
                description: data.description || '',
                parent: selectedParentId,
                level: selectedParentId ? 1 : 0,
                order: wbsItems.length,
                tenant: currentUser.tenant
            };

            const response = await api.post('wbs/', payload);
            setWbsItems(prevItems => [...prevItems, response.data]);
            setIsAddModalOpen(false);
            toast({
                title: "Success",
                description: "WBS item added successfully"
            });
            loadWBS();
        } catch (error) {
            toast({
                title: "Error",
                description: error.message || "Failed to add WBS item",
                variant: "destructive"
            });
        }
    };

    const deleteItem = async (itemId) => {
        try {
            await api.delete(`wbs/${itemId}/`);

            // Get remaining items at the same level
            const deletedItem = wbsItems.find(item => item.id === itemId);
            const siblingItems = wbsItems.filter(item =>
                item.parent === deletedItem.parent &&
                item.id !== itemId
            );

            // Reorder the remaining items
            await reorderCodes(siblingItems);

            // Reload WBS to get updated structure
            loadWBS();

            toast({
                title: "Success",
                description: "WBS item deleted successfully"
            });
        } catch (error) {
            toast({
                title: "Error",
                description: "Failed to delete WBS item",
                variant: "destructive"
            });
        }
    };

    const toggleItem = (itemId) => {
        const newExpanded = new Set(expandedItems);
        if (expandedItems.has(itemId)) {
            newExpanded.delete(itemId);
        } else {
            newExpanded.add(itemId);
        }
        setExpandedItems(newExpanded);
    };



    const renderWBSItem = (item, level = 0) => {
        const isExpanded = expandedItems.has(item.id);
        const hasChildren = item.children && item.children.length > 0;

        return (
            <div key={item.id} className="border-b last:border-b-0">
                <div
                    className={`flex items-center p-2 hover:bg-gray-50 ${level ? `ml-${level * 4}` : ''}`}
                >
                    {hasChildren && (
                        <button
                            onClick={() => toggleItem(item.id)}
                            className="p-1 hover:bg-gray-200 rounded"
                        >
                            {isExpanded ? <ChevronDown className="h-4 w-4" /> : <ChevronRight className="h-4 w-4" />}
                        </button>
                    )}
                    <span className="flex-1 ml-2">{item.code} - {item.name}</span>
                    <Button
                        variant="ghost"
                        size="sm"
                        onClick={() => deleteItem(item.id)}
                        className="text-red-600 hover:text-red-800"
                    >
                        <Trash2 className="h-4 w-4" />
                    </Button>
                </div>
                {isExpanded && hasChildren && (
                    <div className="ml-4">
                        {item.children.map(child => renderWBSItem(child, level + 1))}
                    </div>
                )}
            </div>
        );
    };

    return (
        <>
            <Card>
                <CardHeader>
                    <div className="flex justify-between items-center">
                        <CardTitle>Work Breakdown Structure</CardTitle>
                        <Button
                            className={primaryButtonClasses}
                            onClick={() => setIsAddModalOpen(true)}
                        >
                            <Plus className="h-4 w-4 mr-2" />
                            Add Item
                        </Button>
                    </div>
                </CardHeader>
                <CardContent>
                    {wbsItems.length > 0 ? (
                        <div className="border rounded-lg">
                            {wbsItems.map(item => renderWBSItem(item))}
                        </div>
                    ) : (
                        <p className="text-center text-gray-500 py-4">
                            No WBS items found. Add your first item to get started.
                        </p>
                    )}
                </CardContent>
            </Card>

            <Dialog open={isAddModalOpen} onOpenChange={setIsAddModalOpen}>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>Add WBS Item</DialogTitle>
                    </DialogHeader>
                    <form onSubmit={handleAddItem} className="space-y-4">
                        <div className="space-y-2">
                            <Label htmlFor="name">Name</Label>
                            <Input id="name" name="name" required placeholder="Item name" />
                        </div>

                        <div className="space-y-2">
                            <Label htmlFor="description">Description</Label>
                            <Input id="description" name="description" placeholder="Optional description" />
                        </div>

                        {wbsItems.length > 0 && (
                            <div className="space-y-2">
                                <Label htmlFor="parent">Parent Item (Optional)</Label>
                                <Select onValueChange={setSelectedParentId}>
                                    <SelectTrigger>
                                        <SelectValue placeholder="Select parent item" />
                                    </SelectTrigger>
                                    <SelectContent>
                                        {wbsItems.map(item => (
                                            <SelectItem key={item.id} value={item.id}>
                                                {item.code} - {item.name}
                                            </SelectItem>
                                        ))}
                                    </SelectContent>
                                </Select>
                            </div>
                        )}

                        <DialogFooter>
                            <Button
                                type="button"
                                variant="outline"
                                className={secondaryButtonClasses}
                                onClick={() => setIsAddModalOpen(false)}
                            >
                                Cancel
                            </Button>
                            <Button type="submit" className={primaryButtonClasses}>
                                Add Item
                            </Button>
                        </DialogFooter>
                    </form>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default WBSManager;