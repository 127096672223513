import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '../../components/ui/cardui';
import { Spinner } from '../../components/ui/spinner';
import { Alert, AlertDescription } from '../../components/ui/alert';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import api from '../../services/api';

const RiskTrendChart = ({ projectId, timeframe, configureRequest }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [trendData, setTrendData] = useState([]);

    // Map timeframe to period parameter for API
    const getTimePeriod = () => {
        switch (timeframe) {
            case 'last7days':
                return 'day';
            case 'last30days':
                return 'day';
            case 'last90days':
                return 'week';
            case 'lastYear':
                return 'month';
            case 'all':
                return 'month';
            default:
                return 'month';
        }
    };

    // Get number of months to fetch data for
    const getMonthsToFetch = () => {
        switch (timeframe) {
            case 'last7days':
                return 1;
            case 'last30days':
                return 1;
            case 'last90days':
                return 3;
            case 'lastYear':
                return 12;
            case 'all':
                return 24; // 2 years as maximum for "all"
            default:
                return 12;
        }
    };

    useEffect(() => {
        const fetchTrendData = async () => {
            try {
                setLoading(true);
                setError(null);

                // Fix the API endpoint based on server logs
                const response = await api.get(`risks/trends/?project_id=${projectId}&time_period=${getTimePeriod()}&months=${getMonthsToFetch()}`, configureRequest());

                // Process data
                const data = response.data.map(item => ({
                    ...item,
                    period: new Date(item.period).toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'short',
                        day: timeframe === 'last7days' ? 'numeric' : undefined
                    })
                }));

                setTrendData(data);
            } catch (err) {
                console.error('Error fetching risk trend data:', err);
                setError('Failed to load risk trend data');
            } finally {
                setLoading(false);
            }
        };

        if (projectId) {
            fetchTrendData();
        }
    }, [projectId, timeframe, configureRequest]);

    // Format for tooltip display
    const formatTooltip = (value, name) => {
        if (name === 'avg_risk_score') {
            return [value.toFixed(1), 'Avg Risk Score'];
        }
        return [value, name === 'count' ? 'Total Risks' : 'High Severity Risks'];
    };

    if (loading) {
        return (
            <Card>
                <CardHeader>
                    <CardTitle>Risk Trend Over Time</CardTitle>
                </CardHeader>
                <CardContent className="flex justify-center items-center h-64">
                    <Spinner />
                </CardContent>
            </Card>
        );
    }

    if (error) {
        return (
            <Card>
                <CardHeader>
                    <CardTitle>Risk Trend Over Time</CardTitle>
                </CardHeader>
                <CardContent>
                    <Alert variant="destructive">
                        <AlertDescription>{error}</AlertDescription>
                    </Alert>
                </CardContent>
            </Card>
        );
    }

    return (
        <Card>
            <CardHeader>
                <CardTitle>Risk Trend Over Time</CardTitle>
            </CardHeader>
            <CardContent>
                {trendData.length === 0 ? (
                    <div className="flex justify-center items-center h-64">
                        <p className="text-muted-foreground">No trend data available for the selected time period.</p>
                    </div>
                ) : (
                    <div className="h-64">
                        <ResponsiveContainer width="100%" height="100%">
                            <LineChart
                                data={trendData}
                                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="period" />
                                <YAxis yAxisId="left" />
                                <YAxis yAxisId="right" orientation="right" domain={[0, 10]} />
                                <Tooltip formatter={formatTooltip} />
                                <Legend />
                                <Line
                                    yAxisId="left"
                                    type="monotone"
                                    dataKey="count"
                                    name="Total Risks"
                                    stroke="#8884d8"
                                    activeDot={{ r: 8 }}
                                />
                                <Line
                                    yAxisId="left"
                                    type="monotone"
                                    dataKey="high_severity_count"
                                    name="High Severity Risks"
                                    stroke="#ff7300"
                                />
                                <Line
                                    yAxisId="right"
                                    type="monotone"
                                    dataKey="avg_risk_score"
                                    name="Avg Risk Score"
                                    stroke="#82ca9d"
                                />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                )}
            </CardContent>
        </Card>
    );
};

export default RiskTrendChart;