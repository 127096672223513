import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "../../ui/dialog";
import { Label } from "../../ui/label";
import { Checkbox } from "../../ui/checkbox";
import { Button } from "../../ui/button";
import { Input } from "../../ui/input";
import { Textarea } from "../../ui/textarea";
import api from '../../../services/api';
import { toast } from 'react-toastify';
import { primaryButtonClasses, secondaryButtonClasses } from '../../common/ButtonStyles';

const PhaseCreationModal = ({ projectId, isOpen, onClose, onPhaseCreated }) => {
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        start_date: new Date().toISOString().split('T')[0],
        end_date: new Date().toISOString().split('T')[0],
        completion_percentage: 0,
        project: projectId,
        client_visible: true,
        critical_path: false
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await api.post(`projects/${projectId}/phases/`, formData);
            onPhaseCreated(response.data);
            onClose();
            toast.success("Phase created successfully");
        } catch (error) {
            console.error('Error creating phase:', error);
        }
    };

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className="sm:max-w-[500px]">
                <DialogHeader>
                    <DialogTitle>Create New Phase</DialogTitle>
                </DialogHeader>
                <form onSubmit={handleSubmit} className="space-y-4">
                    <div className="space-y-2">
                        <Label htmlFor="name">Phase Name</Label>
                        <Input
                            id="name"
                            value={formData.name}
                            onChange={(e) => setFormData(prev => ({
                                ...prev,
                                name: e.target.value
                            }))}
                            required
                        />
                    </div>

                    <div className="space-y-2">
                        <Label htmlFor="description">Description</Label>
                        <Textarea
                            id="description"
                            value={formData.description}
                            onChange={(e) => setFormData(prev => ({
                                ...prev,
                                description: e.target.value
                            }))}
                        />
                    </div>

                    <div className="grid grid-cols-2 gap-4">
                        <div className="space-y-2">
                            <Label htmlFor="start_date">Start Date</Label>
                            <Input
                                id="start_date"
                                type="date"
                                value={formData.start_date}
                                onChange={(e) => setFormData(prev => ({
                                    ...prev,
                                    start_date: e.target.value
                                }))}
                                required
                            />
                        </div>
                        <div className="space-y-2">
                            <Label htmlFor="end_date">End Date</Label>
                            <Input
                                id="end_date"
                                type="date"
                                value={formData.end_date}
                                onChange={(e) => setFormData(prev => ({
                                    ...prev,
                                    end_date: e.target.value
                                }))}
                                required
                            />
                        </div>
                    </div>

                    <div className="space-y-2">
                        <Label htmlFor="completion_percentage">Completion Percentage</Label>
                        <Input
                            id="completion_percentage"
                            type="number"
                            min="0"
                            max="100"
                            step="0.01"
                            value={formData.completion_percentage}
                            onChange={(e) => setFormData(prev => ({
                                ...prev,
                                completion_percentage: parseFloat(e.target.value)
                            }))}
                            required
                        />
                    </div>

                    <div className="flex items-center space-x-4">
                        <div className="flex items-center space-x-2">
                            <Checkbox
                                id="client_visible"
                                checked={formData.client_visible}
                                onCheckedChange={(checked) => setFormData(prev => ({
                                    ...prev,
                                    client_visible: checked
                                }))}
                            />
                            <Label htmlFor="client_visible">Client Visible</Label>
                        </div>

                        <div className="flex items-center space-x-2">
                            <Checkbox
                                id="critical_path"
                                checked={formData.critical_path}
                                onCheckedChange={(checked) => setFormData(prev => ({
                                    ...prev,
                                    critical_path: checked
                                }))}
                            />
                            <Label htmlFor="critical_path">Critical Path</Label>
                        </div>
                    </div>

                    <DialogFooter>
                        <Button className={secondaryButtonClasses} type="button" variant="outline" onClick={onClose}>
                            Cancel
                        </Button>
                        <Button className={primaryButtonClasses} type="submit">Create Phase</Button>
                    </DialogFooter>
                </form>
            </DialogContent>
        </Dialog>
    );
};
export default PhaseCreationModal;