import React, { useState, useEffect } from 'react';
import { Plus, RotateCw, X } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigate } from 'react-router-dom';
import * as z from 'zod';
import api from '../services/api';
import { showToast } from '../utils/toast';

import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "./ui/select";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/cardui";
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger } from "./ui/sheet";
import { Avatar, AvatarFallback } from "./ui/avatar";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "./ui/alert-dialog";
const primaryButtonClasses = "bg-[#344bfc] text-white rounded-full px-6 py-2 shadow-md transition transform hover:scale-105 hover:bg-[#2539cc] flex items-center gap-2";
const secondaryButtonClasses = "border border-[#344bfc] text-[#344bfc] rounded-full px-6 py-2 shadow-md transition transform hover:scale-105 hover:bg-[#344bfc] hover:text-white flex items-center gap-2";
const dangerButtonClasses = "bg-red-500 text-white rounded-full px-6 py-2 shadow-md transition transform hover:scale-105 hover:bg-red-600 flex items-center gap-2";

const schema = z.object({
  firstName: z.string().min(1, "First name is required"),
  lastName: z.string().min(1, "Last name is required"),
  email: z.string().email("Invalid email address").min(1, "Email is required"),
  employeeId: z.string().min(1, "Employee ID is required"),
  hireDate: z.string().min(1, "Hire date is required"),
  role: z.string().min(1, "Role is required"),
});

const RefreshButton = ({ onRefresh }) => {
  const [isRefreshing, setIsRefreshing] = useState(false);

  const handleRefresh = async () => {
    setIsRefreshing(true);
    try {
      await onRefresh();
    } finally {
      setIsRefreshing(false);
    }
  };




  return (
    <Button
      variant="outline"
      size="sm"
      onClick={handleRefresh}
      disabled={isRefreshing}
      className="flex items-center gap-2 h-9"
    >
      <RotateCw className={`h-4 w-4 ${isRefreshing ? 'animate-spin' : ''}`} />
      {isRefreshing ? 'Refreshing...' : 'Refresh'}
    </Button>
  );
};

const EmployeeManagement = () => {
  const [employees, setEmployees] = useState([]);
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [currentEmployee, setCurrentEmployee] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedJobTitle, setSelectedJobTitle] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [departments, setDepartments] = useState([]);
  const [jobTitles, setJobTitles] = useState([]);
  const [roles, setRoles] = useState([]);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    employeeId: '',
    hireDate: ''
  });
  const [formErrors, setFormErrors] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm();

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    try {
      if (currentEmployee && currentEmployee.id) {

        // First update the user data if needed
        const userPayload = {
          first_name: formData.firstName,
          last_name: formData.lastName,
          //role_id: Number(selectedRole)
          role_id: selectedRole ? parseInt(selectedRole, 10) : null,
        };

        if (currentEmployee.user.email !== formData.email) {
          userPayload.email = formData.email;
        }

        // Update user if any user data has changed
        if (userPayload.first_name !== currentEmployee.user.first_name ||
          userPayload.last_name !== currentEmployee.user.last_name ||
          userPayload.role_id !== currentEmployee.user.role_id ||
          userPayload.email) {
          await api.patch(`users/${currentEmployee.user.id}/`, userPayload);
        }

        // Then update the employee data
        const employeePayload = {
          employee_id: formData.employeeId,
          department: Number(selectedDepartment),
          job_title: Number(selectedJobTitle),
          hire_date: formData.hireDate
        };
        const currentTenantId = localStorage.getItem('currentTenantId');
        await api.patch(`employees/${currentEmployee.id}/`, employeePayload, {
          headers: {
            'X-Tenant-ID': currentTenantId,
          },
        });
        showToast.success("Employee updated successfully");
      } else {
        // For new employee creation, we can send the nested data
        const payload = {
          user: {
            email: formData.email,
            first_name: formData.firstName,
            last_name: formData.lastName,
            role_id: Number(selectedRole)
          },
          employee_id: formData.employeeId,
          department: Number(selectedDepartment),
          job_title: Number(selectedJobTitle),
          hire_date: formData.hireDate,
        };
        await api.post('employees/', payload);
        showToast.success("Employee added successfully");
      }

      await fetchEmployees();
      setIsPanelOpen(false);
      resetForm();

    } catch (error) {
      console.error('Error submitting form:', error);
      const errorMessage = error.response?.data?.message ||
        error.response?.data?.user?.email?.[0] ||
        "Error processing request. Please check your inputs.";
      showToast.error(errorMessage);
    }
  };

  const { register, reset, formState: { errors } } = useForm({
    resolver: zodResolver(schema)
  });


  const navigate = useNavigate();

  useEffect(() => {
    fetchEmployees();
    fetchDepartments();
    fetchJobTitles();
    fetchRoles();
  }, []);

  useEffect(() => {
    if (currentEmployee) {
      setSelectedDepartment(currentEmployee.department?.toString() || "");
      setSelectedJobTitle(currentEmployee.job_title?.toString() || "");
      setSelectedRole(currentEmployee.user?.role_id?.toString() || "");
      setFormData({
        firstName: currentEmployee.user.first_name,
        lastName: currentEmployee.user.last_name,
        email: currentEmployee.user.email,
        employeeId: currentEmployee.employee_id,
        hireDate: currentEmployee.hire_date
      });
    } else {
      resetForm();
    }
  }, [currentEmployee]);
  const resetForm = () => {
    setFormData({
      firstName: '',
      lastName: '',
      email: '',
      employeeId: '',
      hireDate: ''
    });
    setSelectedDepartment("");
    setSelectedJobTitle("");
    setSelectedRole("");
    setFormErrors({});
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.firstName) errors.firstName = "First name is required";
    if (!formData.lastName) errors.lastName = "Last name is required";
    if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) errors.email = "Valid email is required";
    if (!formData.employeeId) errors.employeeId = "Employee ID is required";
    if (!formData.hireDate) errors.hireDate = "Hire date is required";
    if (!selectedRole) errors.role = "Role is required";
    if (!selectedDepartment) errors.department = "Department is required";
    if (!selectedJobTitle) errors.jobTitle = "Job title is required";
    return errors;
  };

  const fetchEmployees = async () => {
    try {
      const response = await api.get('employees/');
      setEmployees(response.data.results.filter(emp => emp.is_active));
    } catch (error) {
      console.error('Error fetching employees:', error);
    }
  };

  const fetchDepartments = async () => {
    try {
      const response = await api.get('departments/');
      setDepartments(response.data.results || []);
    } catch (error) {
      console.error('Error fetching departments:', error);
      showToast.error('Failed to fetch departments');
    }
  };

  const fetchJobTitles = async () => {
    try {
      const response = await api.get('job_titles/');
      setJobTitles(response.data.results || []);
    } catch (error) {
      console.error('Error fetching job titles:', error);
      showToast.error('Failed to fetch job titles');
    }
  };

  const fetchRoles = async () => {
    try {
      const response = await api.get('roles/');
      setRoles(response.data.results || []);
    } catch (error) {
      console.error('Error fetching roles:', error);
      showToast.error('Failed to fetch roles');
    }
  };

  const handleRefresh = async () => {
    try {
      await Promise.all([
        fetchEmployees(),
        fetchDepartments(),
        fetchJobTitles(),
        fetchRoles()
      ]);
      showToast.success('Data refreshed successfully');
    } catch (error) {
      showToast.error('Failed to refresh data');
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    // Clear error when user starts typing
    if (formErrors[name]) {
      setFormErrors(prev => ({
        ...prev,
        [name]: ''
      }));
    }
  };




  const loadEmployeeForEdit = async (employeeId) => {
    try {
      const response = await api.get(`employees/${employeeId}/`);

      setCurrentEmployee(response.data);

      // Set form field values
      setFormData({
        firstName: response.data.user.first_name,
        lastName: response.data.user.last_name,
        email: response.data.user.email,
        employeeId: response.data.employee_id,
        hireDate: response.data.hire_date
      });


      // Set select values
      setSelectedDepartment(response.data.department?.toString() || "");
      setSelectedJobTitle(response.data.job_title?.toString() || "");

      // Get the role ID directly from the user object
      const roleId = response.data.user?.role_id;


      if (roleId !== undefined && roleId !== null) {
        setSelectedRole(roleId.toString());
      } else {
        console.warn('No role ID found in employee data');
      }

      setIsPanelOpen(true);
    } catch (error) {
      console.error('Error loading employee:', error);
      showToast.error('Failed to load employee details');
    }
  };

  const deactivateEmployee = async () => {
    try {
      await api.delete(`employees/${currentEmployee.id}/`);
      showToast.success("Employee deactivated successfully");
      setShowConfirmDialog(false);
      setIsPanelOpen(false);
      fetchEmployees();
    } catch (error) {
      const errorMessage = error.response?.data?.error || "Failed to deactivate employee";
      showToast.error(errorMessage);
      setShowConfirmDialog(false);
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center space-y-4 sm:space-y-0">
        <h1 className="text-3xl font-bold">Employee Management</h1>
        <div className="flex gap-2">
          <button
            onClick={() => { setIsPanelOpen(true); setCurrentEmployee(null); reset(); }}
            className={primaryButtonClasses}
          >
            <Plus className="h-4 w-4" />
            Add New
          </button>
          <RefreshButton onRefresh={handleRefresh} />
        </div>
      </div>

      <Card>
        <CardHeader>
          <CardTitle>Employee List</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="divide-y">
            {employees.map((employee) => (
              <div key={employee.id} className="py-4 flex items-center justify-between">
                <div className="flex items-center gap-4">
                  <Avatar>
                    <AvatarFallback>
                      {employee.full_name?.charAt(0)}
                    </AvatarFallback>
                  </Avatar>
                  <div>
                    <p className="font-medium">{employee.full_name}</p>
                    <p className="text-sm text-muted-foreground">{employee.email}</p>
                  </div>
                </div>
                <button
                  onClick={() => { loadEmployeeForEdit(employee.id); setIsPanelOpen(true); }}
                  className={secondaryButtonClasses}
                >
                  View Details
                </button>
              </div>
            ))}
          </div>
        </CardContent>
      </Card>

      <Sheet open={isPanelOpen} onOpenChange={setIsPanelOpen}>
        <SheetContent side="right" className="sm:max-w-xl w-full">
          <SheetHeader>
            <SheetTitle>{currentEmployee ? "Edit Employee" : "Add Employee"}</SheetTitle>
          </SheetHeader>

          <form onSubmit={handleSubmit} className="space-y-6 mt-6">
            <div className="space-y-4">
              <Input
                name="firstName"
                value={formData.firstName}
                onChange={handleInputChange}
                placeholder="First Name"
              />
              {formErrors.firstName && (
                <p className="text-sm text-destructive">{formErrors.firstName}</p>
              )}

              <Input
                name="lastName"
                value={formData.lastName}
                onChange={handleInputChange}
                placeholder="Last Name"
              />
              {formErrors.lastName && (
                <p className="text-sm text-destructive">{formErrors.lastName}</p>
              )}

              <Input
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder="Email"
              />
              {formErrors.email && (
                <p className="text-sm text-destructive">{formErrors.email}</p>
              )}

              <Input
                name="employeeId"
                value={formData.employeeId}
                onChange={handleInputChange}
                placeholder="Employee ID"
              />
              {formErrors.employeeId && (
                <p className="text-sm text-destructive">{formErrors.employeeId}</p>
              )}

              <Select
                value={selectedDepartment}
                onValueChange={setSelectedDepartment}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select Department" />
                </SelectTrigger>
                <SelectContent>
                  {departments.map((dept) => (
                    <SelectItem key={dept.id} value={dept.id.toString()}>
                      {dept.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              {formErrors.department && (
                <p className="text-sm text-destructive">{formErrors.department}</p>
              )}

              <Select
                value={selectedJobTitle}
                onValueChange={setSelectedJobTitle}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select Job Title" />
                </SelectTrigger>
                <SelectContent>
                  {jobTitles.map((job) => (
                    <SelectItem key={job.id} value={job.id.toString()}>
                      {job.title}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              {formErrors.jobTitle && (
                <p className="text-sm text-destructive">{formErrors.jobTitle}</p>
              )}

              <Select
                value={selectedRole}
                onValueChange={(value) => {
                  setSelectedRole(value);
                }}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select Role" />
                </SelectTrigger>
                <SelectContent>
                  {roles.map((role) => {
                    return (
                      <SelectItem key={role.id} value={role.id.toString()}>
                        {role.name}
                      </SelectItem>
                    );
                  })}
                </SelectContent>
              </Select>
              {formErrors.role && (
                <p className="text-sm text-destructive">{formErrors.role}</p>
              )}

              <Input
                name="hireDate"
                value={formData.hireDate}
                onChange={handleInputChange}
                type="date"
              />
              {formErrors.hireDate && (
                <p className="text-sm text-destructive">{formErrors.hireDate}</p>
              )}
            </div>

            <div className="flex flex-col gap-3 pt-4">
              <button
                type="submit"
                className={primaryButtonClasses}
              >
                {currentEmployee ? "Update Employee" : "Save Employee"}
              </button>

              {currentEmployee && (
                <button
                  type="button"
                  className={dangerButtonClasses}
                  onClick={() => setShowConfirmDialog(true)}
                >
                  Deactivate Employee
                </button>
              )}
            </div>
          </form>
        </SheetContent>
      </Sheet>

      <AlertDialog open={showConfirmDialog} onOpenChange={setShowConfirmDialog}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Confirm Deactivation</AlertDialogTitle>
            <AlertDialogDescription>
              Are you sure you want to deactivate this employee? This action cannot be undone.
              Any pending tasks assigned to this employee must be reassigned first.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <button
              onClick={() => setShowConfirmDialog(false)}
              className={secondaryButtonClasses}
            >
              Cancel
            </button>
            <button
              onClick={deactivateEmployee}
              className={dangerButtonClasses}
            >
              Deactivate
            </button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
};

export default EmployeeManagement;