import React, { useState, useEffect } from 'react';
import api from '../../services/api';
import { showToast } from '../../utils/toast';
import {
    ArrowTrendingUpIcon,
    DocumentCheckIcon,
    ClockIcon,
    ExclamationCircleIcon
} from '@heroicons/react/24/outline';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const ContractorDashboard = () => {
    const [dashboardData, setDashboardData] = useState({
        activeProjects: 0,
        pendingTasks: 0,
        openRFIs: 0,
        pendingInvoices: 0,
        recentActivity: [],
        projectProgress: []
    });
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetchDashboardData();
    }, []);

    const fetchDashboardData = async () => {
        try {
            const response = await api.get('contractor/dashboard/');
            setDashboardData(response.data);
        } catch (error) {
            console.error('Error fetching dashboard data:', error);
            showToast.error('Failed to load dashboard data');
        } finally {
            setIsLoading(false);
        }
    };

    if (isLoading) {
        return (
            <div className="flex justify-center items-center min-h-[500px]">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-[#344bfc]"></div>
            </div>
        );
    }

    return (
        <div className="p-4 md:p-6">
            {/* Summary Cards */}
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 md:gap-6 mb-6">
                <SummaryCard
                    title="Active Projects"
                    value={dashboardData.activeProjects}
                    icon={ArrowTrendingUpIcon}
                    color="text-blue-600"
                    bgColor="bg-blue-100"
                />
                <SummaryCard
                    title="Pending Tasks"
                    value={dashboardData.pendingTasks}
                    icon={DocumentCheckIcon}
                    color="text-yellow-600"
                    bgColor="bg-yellow-100"
                />
                <SummaryCard
                    title="Open RFIs"
                    value={dashboardData.openRFIs}
                    icon={ExclamationCircleIcon}
                    color="text-purple-600"
                    bgColor="bg-purple-100"
                />
                <SummaryCard
                    title="Pending Invoices"
                    value={dashboardData.pendingInvoices}
                    icon={ClockIcon}
                    color="text-green-600"
                    bgColor="bg-green-100"
                />
            </div>

            {/* Project Progress Chart */}
            <div className="bg-white rounded-lg shadow-sm mb-6">
                <div className="p-4 md:p-6">
                    <h3 className="text-lg font-semibold mb-4">Project Progress</h3>
                    <div className="h-64 md:h-80">
                        <ResponsiveContainer width="100%" height="100%">
                            <BarChart data={dashboardData.projectProgress}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Bar dataKey="progress" fill="#344bfc" />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </div>
            </div>

            {/* Recent Activity */}
            <div className="bg-white rounded-lg shadow-sm">
                <div className="p-4 md:p-6">
                    <h3 className="text-lg font-semibold mb-4">Recent Activity</h3>
                    <div className="space-y-4">
                        {dashboardData.recentActivity.map((activity, index) => (
                            <ActivityItem key={index} activity={activity} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

const SummaryCard = ({ title, value, icon: Icon, color, bgColor }) => (
    <div className="bg-white p-4 md:p-6 rounded-lg shadow-sm">
        <div className="flex items-center">
            <div className={`${bgColor} ${color} rounded-full p-3`}>
                <Icon className="w-6 h-6" />
            </div>
            <div className="ml-4">
                <p className="text-sm text-gray-600">{title}</p>
                <p className="text-xl md:text-2xl font-semibold">{value}</p>
            </div>
        </div>
    </div>
);

const ActivityItem = ({ activity }) => (
    <div className="flex items-start space-x-3">
        <div className="flex-shrink-0">
            <div className="h-8 w-8 rounded-full bg-blue-100 flex items-center justify-center">
                <activity.icon className="h-5 w-5 text-blue-600" />
            </div>
        </div>
        <div className="flex-1 min-w-0">
            <p className="text-sm font-medium text-gray-900">{activity.title}</p>
            <p className="text-sm text-gray-500">{activity.description}</p>
        </div>
        <div className="flex-shrink-0 whitespace-nowrap">
            <span className="text-sm text-gray-500">{activity.time}</span>
        </div>
    </div>
);

export default ContractorDashboard;