import React, { useState } from 'react';
import { FolderPlus, Upload } from 'lucide-react';
import api from '../../../services/api';
import { toast } from 'react-toastify';

const MAX_FILE_SIZE = 10 * 1024 * 1024;

const DocumentToolbar = ({
  folderId,
  documentTypes = [],
  projectId = null,
  featureType = 'document',
  rootFolderId = null,
  onDocumentsChange = () => { }
}) => {
  const [files, setFiles] = useState([]);
  const [oversizedFiles, setOversizedFiles] = useState([]);
  const [folderName, setFolderName] = useState('');
  const [showFolderModal, setShowFolderModal] = useState(false);
  const [showFileModal, setShowFileModal] = useState(false);
  const [documentType, setDocumentType] = useState(documentTypes.length > 0 ? documentTypes[0] : 'Other');
  const [isLoading, setIsLoading] = useState(false);

  const token = localStorage.getItem('access_token');

  const fetchFolderContents = async (folderId) => {
    try {

      const response = await api.get(`/document-management/folders/${folderId}/contents/`, {
        headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
      });


      return response.data.documents || [];
    } catch (error) {
      console.error("Error fetching folder contents:", error.response?.data || error);
      toast.error("Failed to load folder contents.");
      return [];
    }
  };

  const handleFolderClick = async () => {
    if (!folderId) {
      toast.error("No folder selected.");
      return;
    }

    console.log(`Fetching contents for folder: ${folderId} with featureType: ${featureType}`);

    const contents = await fetchFolderContents(folderId, featureType);
    console.log("Loaded Folder Contents:", contents);

    if (contents) {
      // Handle updating UI with contents
      onDocumentsChange(contents);
    }
  };




  const handleCreateFolder = async () => {
    if (!folderName) {
      toast.error('Please enter a folder name.');
      return;
    }

    setIsLoading(true);
    try {
      // Define the data object with explicit parameters
      const data = {
        title: folderName,
        file_type: 'folder',
        document_type: 'Other',
        feature_type: 'safety'
      };

      if (featureType === 'safety') {
        if (projectId) {
          data.project_id = projectId;
          if (folderId && rootFolderId && folderId !== rootFolderId) {
            data.parent_id = folderId;
          }
        } else {
          data.parent_id = folderId;
        }
      } else {
        if (projectId) {
          data.project_id = projectId;
        }
        data.parent_id = folderId;
      }

      console.log("Creating folder with data:", data);

      let token = localStorage.getItem('access_token');
      let headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      };

      try {
        const response = await api.post('document-management/create-folder/', data, { headers });
        console.log("Folder creation response:", response.data);

        toast.success('Folder created successfully!');
        setFolderName('');
        setShowFolderModal(false);

        // Refresh approach with delay
        if (projectId) {
          setTimeout(() => {
            // Approach 1: Refresh the entire document tree for this project
            api.get(`document-management/files/${projectId}/?feature_type=safety`)
              .then(response => {
                console.log("Project files after folder creation:", response.data);
                onDocumentsChange();
              })
              .catch(error => {
                console.error("Error refreshing project files:", error);
                onDocumentsChange();
              });
          }, 1000);
        } else {
          // Standard refresh with delay
          setTimeout(() => {
            onDocumentsChange();
          }, 1000);
        }
        return;
      } catch (error) {
        if (error.response?.status === 401) {
          console.warn("Access token expired, refreshing...");

          try {
            const refreshResponse = await api.post('/token/refresh/', {
              refresh: localStorage.getItem('refresh_token')
            });

            const newAccessToken = refreshResponse.data.access;
            localStorage.setItem('access_token', newAccessToken);

            // 🟢 **Now re-define `data` inside retry**
            const retryData = { ...data };

            // Retry request with new token
            const retryHeaders = {
              'Authorization': `Bearer ${newAccessToken}`,
              'Content-Type': 'application/json'
            };

            const retryResponse = await api.post('document-management/create-folder/', retryData, { headers: retryHeaders });
            console.log("Folder creation response after token refresh:", retryResponse.data);

            toast.success('Folder created successfully!');
            setFolderName('');
            setShowFolderModal(false);

            // Refresh after token refresh with delay
            if (projectId) {
              setTimeout(() => {
                api.get(`document-management/files/${projectId}/?feature_type=safety`)
                  .then(response => {
                    console.log("Project files after folder creation and token refresh:", response.data);
                    onDocumentsChange();
                  })
                  .catch(error => {
                    console.error("Error refreshing project files after token refresh:", error);
                    onDocumentsChange();
                  });
              }, 1000);
            } else {
              setTimeout(() => {
                onDocumentsChange();
              }, 1000);
            }
            return;
          } catch (refreshError) {
            console.error("Failed to refresh token:", refreshError);
            toast.error("Session expired. Please log in again.");
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
            return;
          }
        }

        console.error('Error creating folder:', error.response ? error.response.data : error);
        toast.error(error.response?.data?.error || 'Failed to create folder. Please try again.');
      }
    } finally {
      setIsLoading(false);
    }
  };



  /* const uploadFiles = async () => {
    if (!folderId) {
      toast.error("Please select a destination folder first.");
      return;
    }


    if (featureType === 'safety' && !projectId) {
      toast.error("Please select a project first before uploading files.");
      return;
    }

    setIsLoading(true);
    const formData = new FormData();


    const timestamp = new Date().getTime();


    if (featureType === 'safety') {

      formData.append('project_id', projectId);
      formData.append('feature_type', 'safety');
    } else {
      // Normal handling for other folders
      formData.append('parent_id', folderId);

      if (projectId) {
        formData.append('project_id', projectId);
      }

      if (featureType) {
        formData.append('feature_type', featureType);
      }
    }

    formData.append('document_type', documentType);

    // Add files with unique names to prevent duplicates
    files.forEach(file => {
      const fileNameParts = file.name.split('.');
      const extension = fileNameParts.pop();
      const baseName = fileNameParts.join('.');

      const uniqueFile = new File(
        [file],
        `${baseName}_${timestamp}.${extension}`,
        { type: file.type }
      );

      formData.append('files', uniqueFile);
    });

    try {

      const response = await api.post('document-management/create-file/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token || localStorage.getItem('token')}`
        }
      });

      toast.success("Files uploaded successfully!");
      setFiles([]);
      setShowFileModal(false);
      onDocumentsChange();
    } catch (error) {
      console.error('File upload error:', error.response ? error.response.data : error);
      toast.error(error.response?.data?.error || "An error occurred during file upload. Please try again.");
    } finally {
      setIsLoading(false);
    }
  }; */

  const uploadFiles = async () => {
    if (!folderId) {
      toast.error("Please select a destination folder first.");
      return;
    }

    // Check if a project is required and provided for safety documents
    if (featureType === 'safety' && !projectId) {
      toast.error("Please select a project first before uploading files.");
      return;
    }

    setIsLoading(true);
    const formData = new FormData();
    const timestamp = new Date().getTime();

    // Log the current context for debugging
    console.log("Upload context:", {
      featureType,
      folderId,
      projectId,
      currentFolderId: folderId,
      documentType
    });

    // Add the correct parameters based on document type
    // This is the critical part - ensuring proper parent relationship
    if (featureType === 'safety') {
      formData.append('project_id', projectId);
      formData.append('feature_type', 'safety');
      // Always include parent_id for proper folder structure
      formData.append('parent_id', folderId);
    } else if (featureType === 'project') {
      formData.append('project_id', projectId);
      formData.append('feature_type', 'project');
      formData.append('parent_id', folderId);
    } else if (featureType === 'bid') {
      formData.append('feature_type', 'bid');
      formData.append('parent_id', folderId);
      if (projectId) {
        formData.append('project_id', projectId);
      }
    } else {
      // General documents
      formData.append('parent_id', folderId);
      formData.append('feature_type', 'document');
      if (projectId) {
        formData.append('project_id', projectId);
      }
    }

    // Always add document type
    formData.append('document_type', documentType);

    // Add files with unique names to prevent duplicates
    files.forEach(file => {
      const fileNameParts = file.name.split('.');
      const extension = fileNameParts.pop();
      const baseName = fileNameParts.join('.');

      const uniqueFile = new File(
        [file],
        `${baseName}_${timestamp}.${extension}`,
        { type: file.type }
      );

      formData.append('files', uniqueFile);
    });

    // Log formData entries for debugging
    for (let [key, value] of formData.entries()) {
      console.log(`${key}: ${value instanceof File ? value.name : value}`);
    }

    try {
      const token = localStorage.getItem('access_token') || localStorage.getItem('token');
      const response = await api.post('document-management/create-file/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`
        }
      });

      console.log("Upload response:", response.data);
      toast.success("Files uploaded successfully!");
      setFiles([]);
      setShowFileModal(false);

      // Add a delay before refreshing to ensure backend processing is complete
      setTimeout(() => {
        onDocumentsChange();
      }, 500);
    } catch (error) {
      console.error('File upload error:', error.response ? error.response.data : error);
      toast.error(error.response?.data?.error || "An error occurred during file upload. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files || []);
    if (selectedFiles.length === 0) return;

    const validFiles = [];
    const oversized = [];

    selectedFiles.forEach(file => {
      if (file.size > MAX_FILE_SIZE) {
        oversized.push(file.name);
      } else {
        validFiles.push(file);
      }
    });

    setFiles(validFiles);
    setOversizedFiles(oversized);

    if (oversized.length > 0) {
      toast.warn(`The following files are too large (max ${MAX_FILE_SIZE / 1024 / 1024}MB): ${oversized.join(', ')}`);
    }

    if (validFiles.length > 0) {
      setShowFileModal(true);
    }
  };


  return (
    <div className="flex justify-between items-center mb-6">
      <div className="flex space-x-4">
        <input
          type="file"
          multiple
          onChange={handleFileChange}
          className="hidden"
          id="file-upload"
        />
        <label
          htmlFor="file-upload"
          className="bg-[#344bfc] text-white rounded-full px-4 py-2 shadow-md transition transform hover:scale-105 cursor-pointer inline-flex items-center"
        >
          <Upload className="mr-2 h-4 w-4" />
          Upload Files
        </label>
        <button
          onClick={() => setShowFolderModal(true)}
          className="bg-[#344bfc] text-white rounded-full px-4 py-2 shadow-md transition transform hover:scale-105 inline-flex items-center"
        >
          <FolderPlus className="mr-2 h-4 w-4" />
          Create Folder
        </button>
      </div>

      {/* Create Folder Modal */}
      {showFolderModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 w-full max-w-md shadow-lg">
            <h3 className="text-lg font-semibold mb-4">Create New Folder</h3>
            <input
              type="text"
              placeholder="Enter folder name"
              value={folderName}
              onChange={(e) => setFolderName(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded mb-4"
              autoFocus
            />
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setShowFolderModal(false)}
                className="bg-gray-500 text-white rounded-md px-4 py-2"
                disabled={isLoading}
              >
                Cancel
              </button>
              <button
                onClick={handleCreateFolder}
                className="bg-[#344bfc] text-white rounded-md px-4 py-2"
                disabled={isLoading || !folderName.trim()}
              >
                {isLoading ? 'Creating...' : 'Create'}
              </button>
            </div>
          </div>
        </div>
      )}

      {/* File Upload Modal */}
      {showFileModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 w-full max-w-md shadow-lg">
            <h3 className="text-lg font-semibold mb-4">Upload Files</h3>
            <select
              value={documentType}
              onChange={(e) => setDocumentType(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded mb-4"
            >
              {documentTypes.map(type => (
                <option key={type} value={type}>{type}</option>
              ))}
            </select>
            <p className="mb-4">Selected files: {files.map(f => f.name).join(', ')}</p>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setShowFileModal(false)}
                className="bg-gray-500 text-white rounded-md px-4 py-2"
                disabled={isLoading}
              >
                Cancel
              </button>
              <button
                onClick={uploadFiles}
                className="bg-[#344bfc] text-white rounded-md px-4 py-2"
                disabled={isLoading}
              >
                {isLoading ? 'Uploading...' : 'Upload'}
              </button>
            </div>
          </div>
        </div>
      )}

      {oversizedFiles.length > 0 && (
        <div className="bg-red-100 text-red-700 rounded-md mt-2 p-2">
          <p>Some files are too large:</p>
          <ul>{oversizedFiles.map(file => <li key={file}>{file}</li>)}</ul>
        </div>
      )}
    </div>
  );
};

export default DocumentToolbar;