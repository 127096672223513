import React, { useState, useEffect } from 'react';
import api from '../../services/api';
import { ChevronLeftIcon, ChevronRightIcon, XCircleIcon } from '@heroicons/react/24/solid';
import { toast } from 'react-toastify';
import { useParams, useNavigate } from 'react-router-dom';
import { LineWave } from 'react-loader-spinner';
import { Alert, AlertDescription } from "../ui/alert";
import secureLocalStorage from "react-secure-storage";

const RFIForm = ({ onRFICreated }) => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const STORAGE_KEY = `rfi_form_data_${projectId || 'new'}`;

  // Initialize state from localStorage if available
  const [formData, setFormData] = useState(() => {
    const savedData = localStorage.getItem(STORAGE_KEY);
    if (savedData) {
      try {
        const parsedData = JSON.parse(savedData);
        console.log('Restored form data from localStorage:', parsedData);
        return parsedData;
      } catch (e) {
        console.error('Error parsing saved form data:', e);
      }
    }

    // Default initial state if no saved data
    return {
      title: '',
      brief_description: '',
      response_deadline: '',
      requesting_name: '',
      requesting_title: '',
      requesting_company: '',
      requesting_contact: '',
      responding_name: '',
      responding_title: '',
      responding_company: '',
      responding_contact: '',
      inquiry_description: '',
      response_format: '',
      cost_impact: '',
      status: 'open',
      project: projectId || '',
      tenant: '',
    };
  });

  // Initialize current step from localStorage if available
  const [currentStep, setCurrentStep] = useState(() => {
    const savedStep = localStorage.getItem(`${STORAGE_KEY}_step`);
    return savedStep ? parseInt(savedStep, 10) : 0;
  });

  const [attachments, setAttachments] = useState([]);
  const [errors, setErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [projects, setProjects] = useState([]);
  const [projectOverview, setProjectOverview] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [tenantId, setTenantId] = useState('');

  const formSteps = [
    { title: 'Basic Information', fields: ['title', 'brief_description', 'response_deadline'] },
    { title: 'Requesting Party', fields: ['requesting_name', 'requesting_title', 'requesting_company', 'requesting_contact'] },
    { title: 'Responding Party', fields: ['responding_name', 'responding_title', 'responding_company', 'responding_contact'] },
    { title: 'Inquiry Details', fields: ['inquiry_description', 'response_format', 'cost_impact', 'status'] },
    { title: 'Attachments', fields: ['attachments'] },
  ];

  // Save form data to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(formData));
  }, [formData, STORAGE_KEY]);

  // Save current step to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem(`${STORAGE_KEY}_step`, currentStep.toString());
  }, [currentStep, STORAGE_KEY]);

  // Identical to the configureRequest function in ProjectDetail.js
  const configureRequest = (config = {}) => {
    let tenantId =
      localStorage.getItem('tenantIdentifier') ||
      (typeof secureLocalStorage !== 'undefined' && secureLocalStorage.getItem('currentTenantId')) ||
      localStorage.getItem('tenantId') ||
      sessionStorage.getItem('tenantId') ||
      sessionStorage.getItem('tenantIdentifier');

    // If still no tenant ID, try to extract from user data
    if (!tenantId) {
      try {
        // Try regular localStorage userData
        const userData = JSON.parse(localStorage.getItem('userData') || '{}');
        tenantId = userData.tenant?.id || userData.tenantId;

        // If found, save to localStorage for future use
        if (tenantId) {
          localStorage.setItem('tenantId', tenantId);
          console.log("Retrieved tenant ID from user data and saved to localStorage");
        }
      } catch (e) {
        console.error("Error parsing user data:", e);
      }
    }

    // Log a warning if we still don't have a tenant ID
    if (!tenantId) {
      console.warn("No tenant ID found in any storage location. This will cause permission errors.");
    }

    return {
      ...config,
      headers: {
        ...config.headers,
        'X-Tenant-ID': tenantId || '',
      }
    };
  };

  // Get the tenant ID and store it in state
  useEffect(() => {
    const fetchTenantId = () => {
      const id = localStorage.getItem('tenantId') ||
        localStorage.getItem('tenantIdentifier') ||
        (typeof secureLocalStorage !== 'undefined' && secureLocalStorage.getItem('currentTenantId'));

      if (!id) {
        console.warn("No tenant ID found in primary storage locations. This may cause permission errors.");
      } else {
        console.log("Tenant ID found:", id);
        setTenantId(id);
        // Also update the form data with the tenant ID
        setFormData(prevData => ({
          ...prevData,
          tenant: id
        }));
      }
    };

    fetchTenantId();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);

      try {
        // First, try to get user profile to get tenant information
        const userResponse = await api.get('users/profile/', configureRequest());
        console.log('User profile response:', userResponse.data);

        const userEmail = userResponse.data.email || '';
        const userTenantId = userResponse.data.tenant?.id || tenantId;

        if (userTenantId) {
          setTenantId(userTenantId);
          setFormData(prevData => ({
            ...prevData,
            tenant: userTenantId,
            // Only set the requesting_contact if it's not already set (from localStorage)
            requesting_contact: prevData.requesting_contact || userEmail
          }));
        }

        // Now try to get project using the ID from params if it exists
        if (projectId) {
          try {
            const projectResponse = await api.get(`projects/${projectId}/`, configureRequest());
            setProjectOverview(projectResponse.data);
            setFormData(prevData => ({
              ...prevData,
              // Only set these if they're not already set (from localStorage)
              project: prevData.project || projectId,
              tenant: prevData.tenant || userTenantId || projectResponse.data.tenant?.id
            }));
          } catch (projectError) {
            console.error('Error fetching specific project:', projectError);
          }
        }

        // Get user's projects
        const projectsResponse = await api.get('projects/', configureRequest());
        console.log('Projects response:', projectsResponse.data);

        // Check if projects array exists and has items
        if (!projectsResponse.data || !Array.isArray(projectsResponse.data.results) || projectsResponse.data.results.length === 0) {
          setError("No accessible projects found for this user.");
          setIsLoading(false);
          return;
        }

        // Map projects to the format expected by the dropdown
        const projectsList = projectsResponse.data.results.map(project => ({
          project_id: project.id,
          project_name: project.name
        }));

        setProjects(projectsList);

        // If no specific project was loaded above and no project in formData, set the first project as default
        if (!projectId && !formData.project && projectsList.length > 0) {
          setFormData(prevData => ({
            ...prevData,
            project: projectsList[0].project_id,
            tenant: userTenantId
          }));

          fetchProjectOverview(projectsList[0].project_id);
        } else if (formData.project) {
          // If there's a project in formData (from localStorage), fetch its overview
          fetchProjectOverview(formData.project);
        }

      } catch (error) {
        console.error('Error fetching data:', error);

        if (error.response?.status === 403) {
          setError("You don't have permission to access projects. Please contact your administrator.");
        } else if (error.response?.status === 404) {
          setError("Project information not found. Please check if the project exists.");
        } else {
          setError("Failed to load project data. Please try again later.");
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [projectId, tenantId, formData.project]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (errors[name]) setErrors({ ...errors, [name]: null });
  };

  const handleFileChange = (e) => {
    const newFiles = Array.from(e.target.files);
    setAttachments([...attachments, ...newFiles]);
  };

  const handleFileDelete = (index) => {
    const newAttachments = attachments.filter((_, i) => i !== index);
    setAttachments(newAttachments);
  };

  const handleProjectChange = (e) => {
    const selectedProjectId = e.target.value;
    setFormData({ ...formData, project: selectedProjectId });
    fetchProjectOverview(selectedProjectId);
  };

  const fetchProjectOverview = async (projectId) => {
    if (!projectId) return;

    try {
      const projectOverviewResponse = await api.get(`projects/${projectId}/`, configureRequest());
      setProjectOverview(projectOverviewResponse.data);
    } catch (error) {
      console.error('Error fetching project overview:', error);
      toast.error('Failed to load project details');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (submitting) return;

    // Only submit if on the last step
    if (currentStep < formSteps.length - 1) {
      nextStep();
      return;
    }

    setSubmitting(true);

    // Make sure tenant ID is included in the form data
    const formDataWithTenant = {
      ...formData,
      tenant: tenantId
    };

    const formDataToSend = new FormData();
    Object.keys(formDataWithTenant).forEach((key) => {
      if (Array.isArray(formDataWithTenant[key])) {
        formDataWithTenant[key].forEach(value => formDataToSend.append(key, value));
      } else if (formDataWithTenant[key]) {
        formDataToSend.append(key, formDataWithTenant[key]);
      }
    });

    attachments.forEach((file) => formDataToSend.append('attachments', file));

    try {
      console.log('Submitting form data:', Object.fromEntries(formDataToSend));

      const response = await api.post(`rfis/?projectId=${formDataWithTenant.project}`, formDataToSend, {
        ...configureRequest(),
        headers: {
          ...configureRequest().headers,
          'Content-Type': 'multipart/form-data',
        }
      });

      toast.success('RFI created successfully!');

      // Clear local storage after successful submission
      localStorage.removeItem(STORAGE_KEY);
      localStorage.removeItem(`${STORAGE_KEY}_step`);

      if (typeof onRFICreated === 'function') onRFICreated(response.data);
      navigate(`/projects/${formDataWithTenant.project}/`);
    } catch (error) {
      console.error('Error creating RFI:', error);
      setErrors(error.response?.data || {});
      toast.error('Error submitting form. Please try again.');
    } finally {
      setSubmitting(false);
    }
  };

  const handleCancel = () => {
    // Ask for confirmation before navigating away if form has data
    const hasData = Object.values(formData).some(value =>
      value !== '' && value !== null && value !== undefined &&
      // Exclude project and tenant from the check as they're set automatically
      !['project', 'tenant', 'status'].includes(value)
    );

    if (hasData) {
      const confirmed = window.confirm('Are you sure you want to cancel? Your progress will be saved, but you will leave the form.');
      if (!confirmed) return;
    }

    navigate(-1);
  };

  const resetForm = () => {
    // Ask for confirmation
    const confirmed = window.confirm('Are you sure you want to clear all form data? This cannot be undone.');
    if (!confirmed) return;

    // Clear localStorage entries
    localStorage.removeItem(STORAGE_KEY);
    localStorage.removeItem(`${STORAGE_KEY}_step`);

    // Reset form state
    setFormData({
      title: '',
      brief_description: '',
      response_deadline: '',
      requesting_name: '',
      requesting_title: '',
      requesting_company: '',
      requesting_contact: '',
      responding_name: '',
      responding_title: '',
      responding_company: '',
      responding_contact: '',
      inquiry_description: '',
      response_format: '',
      cost_impact: '',
      status: 'open',
      project: projectId || '',
      tenant: tenantId,
    });
    setCurrentStep(0);
    setAttachments([]);
    setErrors({});

    toast.info('Form has been reset.');
  };

  const nextStep = () => setCurrentStep(prev => Math.min(prev + 1, formSteps.length - 1));
  const prevStep = () => setCurrentStep(prev => Math.max(prev - 1, 0));

  const renderFormField = (field) => {
    const commonClasses = "w-full p-2 border rounded-lg focus:ring-2 focus:ring-brand-blue focus:border-transparent";
    const errorClasses = errors[field] ? "border-red-500" : "";

    if (field === 'status') {
      return (
        <div key={field} className="mb-4">
          <label className="block mb-2">Status</label>
          <select
            name={field}
            value={formData[field]}
            onChange={handleInputChange}
            className={`${commonClasses} ${errorClasses}`}
          >
            <option value="open">Open</option>
            <option value="pending">Pending</option>
            <option value="closed">Closed</option>
          </select>
          {errors[field] && <p className="text-red-500 text-sm mt-1">{errors[field]}</p>}
        </div>
      );
    }

    if (field === 'response_deadline') {
      return (
        <div key={field} className="mb-4">
          <label className="block mb-2">Response Deadline</label>
          <input
            type="datetime-local"
            name={field}
            value={formData[field]}
            onChange={handleInputChange}
            className={`${commonClasses} ${errorClasses}`}
          />
          {errors[field] && <p className="text-red-500 text-sm mt-1">{errors[field]}</p>}
        </div>
      );
    }

    if (field === 'responding_contact') {
      return (
        <div key={field} className="mb-4">
          <label className="block mb-2">Responding Contact (Email)</label>
          <input
            type="email"
            name={field}
            value={formData[field]}
            onChange={handleInputChange}
            className={`${commonClasses} ${errorClasses}`}
            placeholder="Enter respondent's email"
          />
          {errors[field] && <p className="text-red-500 text-sm mt-1">{errors[field]}</p>}
        </div>
      );
    }

    if (field === 'attachments') {
      return (
        <div key={field} className="mb-4">
          <label className="block mb-2">Attachments (Optional)</label>
          <input
            type="file"
            multiple
            onChange={handleFileChange}
            className={`${commonClasses} ${errorClasses}`}
          />
          {attachments.length > 0 && (
            <ul className="space-y-2 mt-2">
              {attachments.map((file, index) => (
                <li key={index} className="flex items-center justify-between bg-gray-100 p-2 rounded">
                  <span>{file.name}</span>
                  <button type="button" onClick={() => handleFileDelete(index)} className="text-red-500 hover:text-red-700">
                    <XCircleIcon className="w-5 h-5" />
                  </button>
                </li>
              ))}
            </ul>
          )}
          {errors[field] && <p className="text-red-500 text-sm mt-1">{errors[field]}</p>}
        </div>
      );
    }

    // Default field rendering
    return (
      <div key={field} className="mb-4">
        <label className="block mb-2">{field.replace(/_/g, ' ').split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</label>
        <input
          type="text"
          name={field}
          value={formData[field]}
          onChange={handleInputChange}
          placeholder={`Enter ${field.replace(/_/g, ' ')}`}
          className={`${commonClasses} ${errorClasses}`}
        />
        {errors[field] && <p className="text-red-500 text-sm mt-1">{errors[field]}</p>}
      </div>
    );
  };

  if (isLoading) {
    return (
      <div className="max-w-2xl mx-auto p-4 flex justify-center items-center h-64">
        <LineWave color="#344bfc" height={80} />
      </div>
    );
  }

  if (error) {
    return (
      <div className="max-w-2xl mx-auto p-4">
        <Alert className="mb-6">
          <AlertDescription>
            <p>{error}</p>
            <div className="mt-4">
              <button
                onClick={handleCancel}
                className="px-4 py-2 bg-brand-blue text-white rounded-md hover:bg-blue-700"
              >
                Go Back
              </button>
            </div>
          </AlertDescription>
        </Alert>
      </div>
    );
  }

  // Check if form has saved data
  const hasSavedData = localStorage.getItem(STORAGE_KEY) !== null;

  return (
    <div className="max-w-2xl mx-auto p-4">
      {/* Add a hidden input for tenant ID to ensure it's submitted */}
      <input type="hidden" name="tenant" value={tenantId} />

      {hasSavedData && (
        <div className="mb-4 p-4 bg-green-100 border-l-4 border-green-500 rounded">
          <p className="text-green-700">Your form progress has been saved. You can continue where you left off.</p>
          <button
            onClick={resetForm}
            className="mt-2 text-red-600 hover:text-red-800 underline text-sm"
          >
            Clear saved data and start over
          </button>
        </div>
      )}

      {projectOverview && (
        <div className="mb-4 p-4 bg-gray-200 rounded">
          <h3 className="text-xl font-bold">{projectOverview.name}</h3>
          <p className="mt-2">{projectOverview.description}</p>
        </div>
      )}
      <div className="flex justify-between mb-6">
        {formSteps.map((step, index) => (
          <div
            key={index}
            className="text-center cursor-pointer"
            onClick={() => setCurrentStep(index)}
          >
            <div className={`w-8 h-8 rounded-full flex items-center justify-center ${index <= currentStep ? 'bg-brand-blue text-white' : 'bg-gray-300'}`}>
              {index + 1}
            </div>
            <span className="text-xs mt-1">{step.title}</span>
          </div>
        ))}
      </div>
      <form className="space-y-6">
        <div className="mb-4">
          <label htmlFor="project" className="block mb-2">Project:</label>
          <select
            id="project"
            name="project"
            value={formData.project}
            onChange={handleProjectChange}
            className="w-full p-2 border rounded-lg mb-4 focus:ring-2 focus:ring-brand-blue focus:border-transparent"
          >
            {projects.length > 0 ? (
              projects.map(proj => (
                <option key={proj.project_id} value={proj.project_id}>
                  {proj.project_name}
                </option>
              ))
            ) : (
              <option value="">No projects available</option>
            )}
          </select>
        </div>
        {formSteps[currentStep].fields.map(renderFormField)}
        <div className="flex justify-between">
          {currentStep === 0 && (
            <button
              type="button"
              onClick={handleCancel}
              className="flex items-center px-6 py-2 bg-red-500 text-white rounded-full hover:bg-red-600"
            >
              Cancel
            </button>
          )}
          {currentStep > 0 && (
            <button
              type="button"
              onClick={prevStep}
              className="flex items-center px-6 py-2 bg-gray-200 rounded-full hover:bg-gray-300"
            >
              <ChevronLeftIcon className="w-5 h-5 mr-2" />
              Previous
            </button>
          )}
          {currentStep < formSteps.length - 1 ? (
            <button
              type="button"
              onClick={nextStep}
              className="flex items-center px-6 py-2 bg-brand-blue text-white rounded-full hover:bg-blue-700 ml-auto"
            >
              Next
              <ChevronRightIcon className="w-5 h-5 ml-2" />
            </button>
          ) : (
            <button
              type="button"
              onClick={handleSubmit}
              className="flex items-center px-6 py-2 bg-green-500 text-white rounded-full hover:bg-green-600 ml-auto"
              disabled={submitting}
            >
              {submitting ? <LineWave color="#344bfc" height={40} /> : 'Submit RFI'}
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default RFIForm;