import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Card, CardContent, CardHeader, CardTitle } from "../../../components/ui/cardui";
import { Button } from "../../../components/ui/button";
import { Input } from "../../../components/ui/input";
import { Textarea } from "../../../components/ui/textarea";
import { Label } from "../../../components/ui/label";
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogTrigger } from "../../../components/ui/alert-dialog";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../../components/ui/select";
import { useToast } from "../../../components/ui/use-toast";
import BidService from '../../../services/bidService';
import { Badge } from "../../../components/ui/badge";
import { Checkbox } from "../../../components/ui/checkbox";
import { X, ChevronRight, Star, BarChart2, Plus, ArrowLeft, Upload, FileText, Loader2 } from 'lucide-react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "../../../components/ui/dialog";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../../components/ui/tabs";
import TradeSelector from './TradeSelector';
import BidderProfileSelector from './BidderProfileSelector';
import api from '../../../services/api';
import { primaryButtonClasses } from '../../common/ButtonStyles';
import LinkRequestsTab from './tabs/LinkRequestsTab'
import BidRFIPanel from './BidRFIPanel';
import { getStatusBadgeColor } from '../../../utils/bidUtils';
import BidScopeTab from './tabs/BidScopeTab';
import { formatDateForInput } from '../../../utils/bidUtils';
import BidDocumentsTab from './tabs/BidDocumentsTab';
import BidResponsesTab from './tabs/BidResponsesTab';
import BidRFITab from './tabs/BidRFITab';
import BidComparisonDialog from './BidComparisonDialog';
import BidDetailsTab from './tabs/BidDetailsTab';



const BidDetails = () => {
    const { id } = useParams();
    const { toast } = useToast();
    const navigate = useNavigate();
    const [bid, setBid] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [activeTab, setActiveTab] = useState("details");
    const [showComparison, setShowComparison] = useState(false);
    const [bidResponses, setBidResponses] = useState([]);
    const [formErrors, setFormErrors] = useState({});
    const [comparisonCriteria, setComparisonCriteria] = useState({
        price: { weight: 0.4, enabled: true },
        timeline: { weight: 0.2, enabled: true },
        quality: { weight: 0.2, enabled: true },
        qualifications: { weight: 0.2, enabled: true }
    });
    const [trades, setTrades] = useState([]);
    const [selectedProfiles, setSelectedProfiles] = useState({});
    const [isLoadingTrades, setIsLoadingTrades] = useState(true);
    const [selectedContractors, setSelectedContractors] = useState({});
    const [tradeInput, setTradeInput] = useState('');
    const [loadingError, setLoadingError] = useState(null);
    const [documents, setDocuments] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [loadingDocuments, setLoadingDocuments] = useState(false);
    const [linkRequests, setLinkRequests] = useState([]);
    const [loadingLinkRequests, setLoadingLinkRequests] = useState(false);
    const [rawBidData, setRawBidData] = useState(null);

    const [formData, setFormData] = useState({
        title: '',
        description: '',
        submission_deadline: '',
        status: '',
        scope: {
            general_requirements: '',
            specific_requirements: [],
            exclusions: [],
            deliverables: []
        },
        required_trades: [],
        trade_estimates: [],
        notify_subcontractors: true
    });

    const [documentTypes, setDocumentTypes] = useState([]);

    useEffect(() => {
        const fetchDocTypes = async () => {
            try {
                const response = await api.get('bid-document-types/');
                setDocumentTypes(response.data.results || []);
            } catch (error) {
                console.error('Error fetching document types:', error);
            }
        };
        fetchDocTypes();
    }, []);

    const fetchBidDetails = async () => {
        try {
            setLoading(true);
            const response = await BidService.getBidRequestDetails(id);
            const bidData = response.data;

            // Store the raw bid data
            setRawBidData(bidData);
            setBid(bidData);

            if (trades.length > 0) {
                formatAndSetBidData(bidData);
            }

        } catch (err) {
            setError('Failed to fetch bid details');
            console.error('Error fetching bid details:', err);
        } finally {
            setLoading(false);
        }
    };

    // Add a new function to format bid data
    const formatAndSetBidData = (bidData) => {
        const tradeEstimates = Array.isArray(bidData.trade_estimates)
            ? bidData.trade_estimates.map(estimate => ({
                trade: {
                    id: estimate.trade_id ||
                        trades.find(t => t.name === estimate.trade)?.id
                },
                estimated_cost: estimate.estimated_cost ? parseFloat(estimate.estimated_cost) : 0,
                notes: estimate.notes || ''
            }))
            : [];

        setFormData({
            title: bidData.title || '',
            description: bidData.description || '',
            submission_deadline: formatDateForInput(bidData.submission_deadline),
            status: bidData.status || 'draft',
            scope: bidData.scope || {
                general_requirements: '',
                specific_requirements: [],
                exclusions: [],
                deliverables: []
            },
            required_trades: bidData.required_trades || [],
            trade_estimates: tradeEstimates,
            notify_subcontractors: bidData.notify_subcontractors || false,
            selected_contractors: bidData.selected_contractors || {}
        });
    };

    // Add a useEffect to handle trades loading
    useEffect(() => {
        if (trades.length > 0 && rawBidData) {
            formatAndSetBidData(rawBidData);
        }
    }, [trades]);


    useEffect(() => {
        loadTrades();
    }, []);

    useEffect(() => {
        fetchBidDetails();
        fetchBidResponses();
        fetchDocuments();
    }, [id]);

    useEffect(() => {
        const fetchLinkRequests = async () => {
            try {
                setLoadingLinkRequests(true);
                const response = await api.get(`bids/${id}/link-renewal-requests/`);
                setLinkRequests(response.data || []);
            } catch (err) {
                console.error('Error fetching link requests:', err);
                toast({
                    title: "Error",
                    description: "Failed to load link renewal requests",
                    variant: "destructive"
                });
            } finally {
                setLoadingLinkRequests(false);
            }
        };

        if (id && activeTab === 'link-requests') {
            fetchLinkRequests();
        }
    }, [id, activeTab]);


    const handleApproveLinkRequest = async (requestId) => {
        try {
            await api.post('bids/handle-renewal-request/', {
                request_id: requestId,
                action: 'approve'
            });

            // Refresh the list of requests
            const response = await api.get(`bids/${id}/link-renewal-requests/`);
            setLinkRequests(response.data || []);

            toast({
                title: "Success",
                description: "Link renewal request approved"
            });
        } catch (err) {
            console.error('Error approving request:', err);
            toast({
                title: "Error",
                description: "Failed to approve request",
                variant: "destructive"
            });
        }
    };

    const handleDenyLinkRequest = async (requestId) => {
        try {
            await api.post('bids/handle-renewal-request/', {
                request_id: requestId,
                action: 'deny'
            });

            // Refresh the list of requests
            const response = await api.get(`bids/${id}/link-renewal-requests/`);
            setLinkRequests(response.data || []);

            toast({
                title: "Success",
                description: "Link renewal request denied"
            });
        } catch (err) {
            console.error('Error denying request:', err);
            toast({
                title: "Error",
                description: "Failed to deny request",
                variant: "destructive"
            });
        }
    };

    const loadTrades = async () => {
        setIsLoadingTrades(true);
        setLoadingError(null);
        try {
            const response = await api.get('construction-trades/');
            setTrades(response.data || []);
        } catch (error) {
            console.error('Trade fetch error:', error);
            setLoadingError("Failed to load trades");
            setTrades([]);
        } finally {
            setIsLoadingTrades(false);
        }
    };

    const fetchDocuments = async () => {
        try {
            setLoadingDocuments(true);
            const response = await api.get(`bid-documents/?bid_id=${id}`);
            // Ensure we're setting an array, using the results property if it exists
            setDocuments(Array.isArray(response.data) ? response.data :
                (response.data?.results || []));
        } catch (err) {
            console.error('Error fetching documents:', err);
            setDocuments([]);
        } finally {
            setLoadingDocuments(false);
        }
    };

    const handleDocumentUpload = async (e) => {
        const files = Array.from(e.target.files);
        setUploading(true);

        try {
            const uploadPromises = files.map(file => {
                const formData = new FormData();
                formData.append('file', file);
                formData.append('bid_id', id);
                formData.append('title', file.name);
                formData.append('file_type', file.type);
                formData.append('type', 'attachment');

                return BidService.uploadDocument(formData);
            });

            await Promise.all(uploadPromises);
            await fetchDocuments();

            toast({
                title: "Success",
                description: "Documents uploaded successfully",
            });
        } catch (err) {
            console.error('Upload error:', err);
            toast({
                title: "Error",
                description: "Failed to upload documents",
                variant: "destructive"
            });
        } finally {
            setUploading(false);
        }
    };

    const handleProfileSelect = (tradeId, profileInfo) => {

        setSelectedProfiles(prev => {
            const currentProfiles = prev[tradeId] || [];

            if (profileInfo.checked) {
                // Add profile if it's not already selected
                if (!currentProfiles.includes(profileInfo.id)) {
                    const updatedProfiles = [...currentProfiles, profileInfo.id];
                    return {
                        ...prev,
                        [tradeId]: updatedProfiles
                    };
                }
            } else {
                // Remove profile
                const updatedProfiles = currentProfiles.filter(id => id !== profileInfo.id);
                return {
                    ...prev,
                    [tradeId]: updatedProfiles
                };
            }

            return prev;
        });
    };



    const fetchBidResponses = async () => {
        try {
            const response = await api.get(`/bid-submissions/?bid_request=${id}`);
            const submissions = Array.isArray(response.data) ?
                response.data :
                (response.data?.results || []);

            // Filter to only include submissions for this specific bid request
            const relevantSubmissions = submissions.filter(
                submission => submission.bid_request === id
            );

            const formattedResponses = relevantSubmissions.map(submission => {
                // Get the first specialized trade from the contractor's profile
                const profileTrades = submission.contractor_profile?.specialized_trades || [];
                const firstTrade = profileTrades[0]; // Get first trade if exists

                return {
                    id: submission.id,
                    contractor_name: submission.contractor_name,
                    company_name: submission.company_name,
                    contractor_profile_id: submission.contractor_profile?.id,
                    submitted_at: submission.submitted_at,
                    formatted_date: new Date(submission.submitted_at).toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit'
                    }),
                    price: submission.total_price,
                    formatted_price: submission.formatted_price,
                    timeline: submission.timeline,
                    status: submission.status,
                    trade_id: firstTrade?.id || null,  // Set trade_id from the specialized trade
                    quality_rating: submission.contractor_profile?.quality_rating || 0,
                    qualification_score: submission.qualification_score || 0,
                    notes: submission.notes || submission.assumptions,
                    methodology: submission.methodology
                };
            });

            setBidResponses(formattedResponses);
        } catch (err) {
            console.error('Error fetching bid responses:', err);
            setBidResponses([]);
        }
    };

    const validateForm = () => {
        const errors = {};
        if (!formData.title?.trim()) errors.title = 'Title is required';
        if (!formData.description?.trim()) errors.description = 'Description is required';
        if (!formData.submission_deadline) errors.submission_deadline = 'Deadline is required';
        if (!formData.scope.general_requirements?.trim()) {
            errors.general_requirements = 'General requirements are required';
        }
        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
        setFormErrors(prev => ({ ...prev, [name]: '' }));
    };

    const handleScopeChange = (field, value) => {
        setFormData(prev => ({
            ...prev,
            scope: {
                ...prev.scope,
                [field]: value
            }
        }));
        setFormErrors(prev => ({ ...prev, [field]: '' }));
    };

    const handleAddItem = (field) => {
        setFormData(prev => ({
            ...prev,
            scope: {
                ...prev.scope,
                [field]: [...prev.scope[field], '']
            }
        }));
    };

    const handleRemoveItem = (field, index) => {
        setFormData(prev => ({
            ...prev,
            scope: {
                ...prev.scope,
                [field]: prev.scope[field].filter((_, i) => i !== index)
            }
        }));
    };

    const handleUpdateItem = (field, index, value) => {
        setFormData(prev => {
            const newItems = [...prev.scope[field]];
            newItems[index] = value;
            return {
                ...prev,
                scope: {
                    ...prev.scope,
                    [field]: newItems
                }
            };
        });
    };
    const handleDelete = async () => {
        try {
            await BidService.deleteBidRequest(id);
            toast({
                title: "Success",
                description: "Bid deleted successfully",
                variant: "success"
            });
            navigate('/bid-management');
        } catch (err) {
            toast({
                title: "Error",
                description: "Failed to delete bid",
                variant: "destructive"
            });
        }
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        // Format trade estimates correctly
        const trade_estimates = formData.required_trades.map(tradeId => {
            const estimate = formData.trade_estimates.find(est => est.trade?.id === tradeId) || {};
            return {
                trade_id: tradeId,
                estimated_cost: parseFloat(estimate.estimated_cost || 0),
                notes: estimate.notes || ''
            };
        });
        const submitData = {
            ...formData,
            trade_estimates: trade_estimates
        };

        try {
            const response = await BidService.updateBidRequest(id, submitData);
            setBid(response.data);

            // Format the trade estimates from the response
            const updatedTradeEstimates = response.data.trade_estimates.map(estimate => ({
                trade: {
                    id: estimate.trade_id
                },
                estimated_cost: parseFloat(estimate.estimated_cost || 0),
                notes: estimate.notes || ''
            }));

            // Update formData with the response data including formatted trade estimates
            setFormData(prev => ({
                ...prev,
                ...response.data,
                trade_estimates: updatedTradeEstimates
            }));

            toast({
                title: "Success",
                description: "Bid updated successfully"
            });

            // Exit edit mode
            setIsEditing(false);

        } catch (error) {
            console.error("Error updating bid:", error);
            console.error("Error details:", error.response?.data);

        }
    };


    const handleStatusChange = (value) => {
        setFormData(prev => ({
            ...prev,
            status: value
        }));
    };

    const handleCompareBids = () => {
        if (!bidResponses.length) return [];

        // Calculate scores for each bid response
        const scoredBids = bidResponses.map(response => {
            // Only calculate scores for enabled criteria
            let totalWeight = 0;
            let totalScore = 0;

            // Price score (lower is better)
            if (comparisonCriteria.price.enabled && response.price) {
                const maxPrice = Math.max(...bidResponses.map(b => b.price));
                const priceScore = (1 - (response.price / maxPrice)) * 100;
                totalScore += priceScore * comparisonCriteria.price.weight;
                totalWeight += comparisonCriteria.price.weight;
            }

            // Timeline score (lower is better)
            if (comparisonCriteria.timeline.enabled && response.timeline) {
                const maxTimeline = Math.max(...bidResponses.map(b => b.timeline));
                const timelineScore = (1 - (response.timeline / maxTimeline)) * 100;
                totalScore += timelineScore * comparisonCriteria.timeline.weight;
                totalWeight += comparisonCriteria.timeline.weight;
            }

            // Quality score
            if (comparisonCriteria.quality.enabled && response.quality_rating) {
                const qualityScore = (response.quality_rating / 5) * 100;
                totalScore += qualityScore * comparisonCriteria.quality.weight;
                totalWeight += comparisonCriteria.quality.weight;
            }

            // Qualifications score
            if (comparisonCriteria.qualifications.enabled && response.qualification_score) {
                const qualificationsScore = (response.qualification_score / 5) * 100;
                totalScore += qualificationsScore * comparisonCriteria.qualifications.weight;
                totalWeight += comparisonCriteria.qualifications.weight;
            }

            // Normalize the total score based on the total weight of enabled criteria
            const normalizedScore = totalWeight > 0 ? totalScore / totalWeight : 0;

            return {
                ...response,
                scores: {
                    total: normalizedScore,
                    price: response.price ? (1 - (response.price / Math.max(...bidResponses.map(b => b.price)))) * 100 : 0,
                    timeline: response.timeline ? (1 - (response.timeline / Math.max(...bidResponses.map(b => b.timeline)))) * 100 : 0,
                    quality: response.quality_rating ? (response.quality_rating / 5) * 100 : 0,
                    qualifications: response.qualification_score ? (response.qualification_score / 5) * 100 : 0
                }
            };
        });

        // Sort by total score (highest first)
        return scoredBids.sort((a, b) => b.scores.total - a.scores.total);
    };

    if (loading) return (
        <div className="flex justify-center items-center h-64">
            <div className="text-lg">Loading bid details...</div>
        </div>
    );

    if (error) return (
        <div className="p-4 bg-red-50 border border-red-200 rounded-md">
            <p className="text-red-600">{error}</p>
        </div>
    );



    return (
        <div className="p-4 sm:p-6 max-w-4xl mx-auto">
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-6 gap-4">
                <div className="flex flex-col sm:flex-row items-start sm:items-center gap-4 w-full sm:w-auto">
                    <Button
                        variant="ghost"
                        onClick={() => navigate('/bid-management')}
                        className="flex items-center gap-2"
                    >
                        <ArrowLeft className="h-4 w-4" />
                        <span className="hidden sm:inline">Back to Dashboard</span>
                        <span className="sm:hidden">Back</span>
                    </Button>
                    <h1 className="text-xl sm:text-2xl font-bold">Bid Details</h1>
                </div>
                <div className="flex flex-wrap gap-2 w-full sm:w-auto">
                    {bid.status === 'open_for_submission' && (
                        <Button
                            variant="outline"
                            onClick={() => navigate(`/bid-management/${id}/compare`, {
                                state: {
                                    bidResponses,
                                    bid: {
                                        ...bid,
                                        required_trades: bid.required_trades
                                    }
                                }
                            })}
                            className="flex items-center gap-2 w-full sm:w-auto"
                        >
                            <BarChart2 className="h-4 w-4" />
                            Compare Bids
                        </Button>
                    )}
                    <Button
                        variant="outline"
                        onClick={() => setIsEditing(!isEditing)}
                        className="w-full sm:w-auto"
                    >
                        {isEditing ? 'Cancel Edit' : 'Edit Bid'}
                    </Button>
                    <AlertDialog>
                        <AlertDialogTrigger asChild>
                            <Button variant="destructive" className="w-full sm:w-auto">Delete Bid</Button>
                        </AlertDialogTrigger>
                        <AlertDialogContent className="sm:max-w-[425px]">
                            <AlertDialogHeader>
                                <AlertDialogTitle>Delete Bid?</AlertDialogTitle>
                                <AlertDialogDescription>
                                    This action cannot be undone.
                                </AlertDialogDescription>
                            </AlertDialogHeader>
                            <AlertDialogFooter>
                                <AlertDialogCancel>Cancel</AlertDialogCancel>
                                <AlertDialogAction onClick={handleDelete}>Delete</AlertDialogAction>
                            </AlertDialogFooter>
                        </AlertDialogContent>
                    </AlertDialog>
                </div>
            </div>

            <Tabs value={activeTab} onValueChange={setActiveTab} className="space-y-4">
                <TabsList className="w-full flex flex-wrap gap-2">
                    <TabsTrigger value="details" className="flex-1">Details</TabsTrigger>
                    <TabsTrigger value="scope" className="flex-1">Scope</TabsTrigger>
                    <TabsTrigger value="documents" className="flex-1">
                        Documents ({documents.length})
                    </TabsTrigger>
                    <TabsTrigger value="link-requests" className="flex-1">Link Requests</TabsTrigger>
                    <TabsTrigger value="responses" className="flex-1">
                        Responses ({bidResponses.length})
                    </TabsTrigger>
                    <TabsTrigger value="rfis" className="flex-1">RFIs</TabsTrigger>
                </TabsList>

                <TabsContent value="details">
                    <BidDetailsTab
                        bid={bid}
                        isEditing={isEditing}
                        formData={formData}
                        formErrors={formErrors}
                        handleInputChange={handleInputChange}
                        handleStatusChange={handleStatusChange}
                        handleSubmit={handleSubmit}
                        trades={trades}
                        selectedProfiles={selectedProfiles}
                        handleProfileSelect={handleProfileSelect}
                        setFormData={setFormData}
                    />
                </TabsContent>

                <TabsContent value="scope">
                    <BidScopeTab
                        isEditing={isEditing}
                        formData={formData}
                        formErrors={formErrors}
                        handleScopeChange={handleScopeChange}
                        handleAddItem={handleAddItem}
                        handleRemoveItem={handleRemoveItem}
                        handleUpdateItem={handleUpdateItem}
                        bid={bid}
                    />
                </TabsContent>

                <TabsContent value="documents">
                    <BidDocumentsTab
                        isEditing={isEditing}
                        bidId={id}
                        documentTypes={documentTypes}
                        loadingDocuments={loadingDocuments}
                        uploading={uploading}
                    />
                </TabsContent>

                <TabsContent value="link-requests">
                    <LinkRequestsTab bidId={id} />
                </TabsContent>

                <TabsContent value="responses">
                    <BidResponsesTab
                        bidResponses={bidResponses}
                        navigate={navigate}
                        id={id}
                        getStatusBadgeColor={getStatusBadgeColor}
                    />
                </TabsContent>

                <TabsContent value="rfis">
                    <BidRFITab bidRequestId={id} />
                </TabsContent>
            </Tabs>

            <BidComparisonDialog
                showComparison={showComparison}
                setShowComparison={setShowComparison}
                comparisonCriteria={comparisonCriteria}
                setComparisonCriteria={setComparisonCriteria}
                handleCompareBids={handleCompareBids}
            />
        </div>
    );
};

export default BidDetails;