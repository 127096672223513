import React from 'react';
import { Card, CardContent } from '../../components/ui/cardui';
import { ClipboardList, AlertTriangle, CheckCircle, LineChart } from 'lucide-react';

const RiskSummaryCards = ({ metrics }) => {
    return (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-4">
            <Card className="border-l-4 border-l-primary">
                <CardContent className="p-4">
                    <div className="flex items-center justify-between">
                        <div>
                            <p className="text-xs font-semibold text-primary uppercase mb-1">
                                Total Risks
                            </p>
                            <div className="text-2xl font-bold text-gray-800">
                                {metrics.totalRisks}
                            </div>
                            <div className="mt-1 text-sm text-muted-foreground">
                                Identified risks in project
                            </div>
                        </div>
                        <div className="text-gray-300">
                            <ClipboardList className="h-8 w-8" />
                        </div>
                    </div>
                </CardContent>
            </Card>

            <Card className="border-l-4 border-l-destructive">
                <CardContent className="p-4">
                    <div className="flex items-center justify-between">
                        <div>
                            <p className="text-xs font-semibold text-destructive uppercase mb-1">
                                High Severity
                            </p>
                            <div className="text-2xl font-bold text-gray-800">
                                {metrics.highSeverityRisks}
                            </div>
                            <div className="mt-1 text-sm text-muted-foreground">
                                High/Critical severity risks
                            </div>
                        </div>
                        <div className="text-gray-300">
                            <AlertTriangle className="h-8 w-8" />
                        </div>
                    </div>
                </CardContent>
            </Card>

            <Card className="border-l-4 border-l-green-500">
                <CardContent className="p-4">
                    <div className="flex items-center justify-between">
                        <div>
                            <p className="text-xs font-semibold text-green-500 uppercase mb-1">
                                Mitigated Risks
                            </p>
                            <div className="text-2xl font-bold text-gray-800">
                                {metrics.mitigatedRisks}
                            </div>
                            <div className="mt-1 text-sm text-muted-foreground">
                                Successfully mitigated risks
                            </div>
                        </div>
                        <div className="text-gray-300">
                            <CheckCircle className="h-8 w-8" />
                        </div>
                    </div>
                </CardContent>
            </Card>

            <Card className="border-l-4 border-l-blue-400">
                <CardContent className="p-4">
                    <div className="flex items-center justify-between">
                        <div>
                            <p className="text-xs font-semibold text-blue-400 uppercase mb-1">
                                Average Risk Score
                            </p>
                            <div className="text-2xl font-bold text-gray-800">
                                {metrics.avgRiskScore}
                            </div>
                            <div className="mt-1 text-sm text-muted-foreground">
                                {metrics.openRisks} open risks remaining
                            </div>
                        </div>
                        <div className="text-gray-300">
                            <LineChart className="h-8 w-8" />
                        </div>
                    </div>
                </CardContent>
            </Card>
        </div>
    );
};

export default RiskSummaryCards;