import React, { useState, useEffect, useCallback } from 'react';
import { Button } from '../components/ui/button';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '../components/ui/dialog';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../components/ui/tabs';
import { debounce } from 'lodash';
import { Progress } from '../components/ui/progress';
import { useToast } from '../components/ui/use-toast';
import { AlertCircle } from 'lucide-react';
import { primaryButtonClasses, secondaryButtonClasses } from '../components/common/ButtonStyles';
import api from '../services/api';

// Import the new separated components
import ChecklistTab from './ChecklistTab';
import FindingsTab from './FindingsTab';

const AuditExecutionView = ({ project, audit, isOpen, onClose, onComplete }) => {
    const [activeTab, setActiveTab] = useState('checklist');
    const [responses, setResponses] = useState({});
    const [progress, setProgress] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const { toast } = useToast();

    useEffect(() => {
        const fetchResponses = async () => {
            try {
                setIsLoading(true);
                const response = await api.get(`projects/${project.id}/safety-audits/${audit.id}/responses/`);

                console.log('Responses data:', response.data);

                // Handle different response formats
                const responseData = response.data;
                let responseMap = {};

                if (Array.isArray(responseData)) {
                    // Map responses by audit_item ID, not just the ID string
                    responseMap = responseData.reduce((acc, r) => ({
                        ...acc,
                        [r.audit_item_details?.id || r.audit_item]: r
                    }), {});
                } else if (responseData && Array.isArray(responseData.results)) {
                    responseMap = responseData.results.reduce((acc, r) => ({
                        ...acc,
                        [r.audit_item_details?.id || r.audit_item]: r
                    }), {});
                } else if (responseData && typeof responseData === 'object') {
                    if (responseData.audit_item) {
                        responseMap = { [responseData.audit_item_details?.id || responseData.audit_item]: responseData };
                    }
                }

                setResponses(responseMap);
                calculateProgress(responseMap);
            } catch (error) {
                console.error('Error loading responses:', error?.response?.data || error);
                setError("Failed to load audit responses. Please try again.");
            } finally {
                setIsLoading(false);
            }
        };

        if (isOpen) {
            fetchResponses();
        }
    }, [audit.id, project.id, isOpen]);

    const calculateProgress = (currentResponses) => {
        // Guard against missing template data
        if (!audit?.template?.items) {
            console.error("Missing audit template items", audit);
            setProgress(0);
            return;
        }

        // Calculate total items across all categories
        let totalItems = 0;
        let itemIds = [];

        audit.template.items.forEach(category => {
            if (Array.isArray(category.items)) {
                totalItems += category.items.length;
                itemIds = [...itemIds, ...category.items.map(item => item.id)];
            }
        });

        console.log("Total items:", totalItems);
        console.log("Item IDs:", itemIds);

        // If no items, progress is 0
        if (totalItems === 0) {
            console.warn("No audit items found in template");
            setProgress(0);
            return;
        }

        // Count completed responses
        const responseKeys = Object.keys(currentResponses);
        console.log("Response keys:", responseKeys);
        console.log("Full responses:", currentResponses);

        const calculatedProgress = Math.round((responseKeys.length / totalItems) * 100);
        console.log(`Progress calculation: ${responseKeys.length}/${totalItems} = ${calculatedProgress}%`);

        setProgress(calculatedProgress);
    };

    const handleComplete = async () => {
        try {
            await api.post(`projects/${project.id}/safety-audits/${audit.id}/complete/`);
            toast({
                title: "Audit Completed",
                description: "The audit has been successfully completed.",
            });
            onComplete(audit);
        } catch (error) {
            toast({
                title: "Error",
                description: "Failed to complete audit. Please try again.",
                variant: "destructive"
            });
        }
    };

    const debouncedSave = useCallback(
        debounce(async (itemId, status, notes) => {
            try {
                await api.post(
                    `projects/${project.id}/safety-audits/${audit.id}/responses/`,
                    {
                        audit_item: itemId,
                        status,
                        notes
                    }
                );
            } catch (error) {
                console.error('Error saving notes:', error);
            }
        }, 1000),
        [project.id, audit.id]
    );

    const handleResponse = async (itemId, status, notes = '') => {
        try {
            console.log(`Saving response for item ${itemId}, status: ${status}`);

            const responseData = {
                audit_item: itemId.toString(),
                status: status,
                notes: notes || ''
            };

            const result = await api.post(
                `projects/${project.id}/safety-audits/${audit.id}/responses/`,
                responseData
            );

            console.log("Response saved successfully:", result.data);

            // Update frontend state with the response from API
            setResponses(prev => {
                const newResponses = {
                    ...prev,
                    [itemId]: result.data
                };

                // Log the updated responses
                console.log("Updated responses:", newResponses);
                console.log("Response keys:", Object.keys(newResponses).length);

                // Calculate progress with the new responses state
                calculateProgress(newResponses);

                return newResponses;
            });

            return result.data;

        } catch (error) {
            console.error('Error saving response:', error?.response?.data || error);
            toast({
                title: "Error",
                description: "Failed to save response.",
                variant: "destructive"
            });
            throw error;
        }
    };

    const allRequiredItemsAnswered = () => {
        if (!audit?.template?.items) {
            console.error("Missing template items");
            return false;
        }

        // Get all required item IDs
        const requiredItemIds = [];
        audit.template.items.forEach(category => {
            if (Array.isArray(category.items)) {
                category.items.forEach(item => {
                    requiredItemIds.push(item.id.toString());
                });
            }
        });

        console.log("Required item IDs:", requiredItemIds);

        // Check if all required items have responses
        const responseItemIds = Object.keys(responses).map(id => id.toString());
        console.log("Response item IDs:", responseItemIds);

        // Check if every required item has a response
        const allAnswered = requiredItemIds.every(id =>
            responseItemIds.includes(id.toString())
        );

        console.log(`All items answered: ${allAnswered} (${responseItemIds.length}/${requiredItemIds.length})`);

        return allAnswered;
    };

    const handleSaveAndClose = async () => {
        try {
            // Get all responses that need to be saved
            const responsesToSave = Object.values(responses);
            console.log("Saving responses:", responsesToSave);

            // Save all responses
            const savePromises = responsesToSave.map(response => {
                return api.post(
                    `projects/${project.id}/safety-audits/${audit.id}/responses/`,
                    {
                        audit_item: response.audit_item,
                        status: response.status,
                        notes: response.notes || ''
                    }
                );
            });

            await Promise.all(savePromises);

            toast({
                title: "Saved",
                description: `Successfully saved ${responsesToSave.length} responses.`
            });
            onClose();
        } catch (error) {
            console.error('Error saving audit:', error);
            toast({
                title: "Error",
                description: "Failed to save audit progress.",
                variant: "destructive"
            });
        }
    };


    if (error) {
        return (
            <Dialog open={isOpen} onOpenChange={onClose}>
                <DialogContent className="sm:max-w-[800px]">
                    <div className="text-center p-8">
                        <AlertCircle className="h-12 w-12 text-red-500 mx-auto mb-4" />
                        <h3 className="text-lg font-medium mb-2">Error Loading Audit</h3>
                        <p className="text-gray-500 mb-4">{error}</p>
                        <Button onClick={onClose}>Close</Button>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className="sm:max-w-[800px] h-[95vh] flex flex-col overflow-hidden">
                {/* Fixed Header */}
                <DialogHeader className="px-6 py-4">
                    <DialogTitle>Conduct Safety Audit</DialogTitle>
                    <div className="mt-4">
                        <Progress value={progress} />
                        <p className="text-sm text-gray-500 mt-1">
                            {progress}% Complete
                        </p>
                    </div>
                </DialogHeader>

                <Tabs
                    value={activeTab}
                    onValueChange={setActiveTab}
                    className="flex-1 flex flex-col overflow-hidden"
                >
                    <TabsList className="px-6">
                        <TabsTrigger value="checklist">Checklist</TabsTrigger>
                        <TabsTrigger value="findings">Findings & Corrections</TabsTrigger>
                    </TabsList>

                    <TabsContent
                        value="checklist"
                        className="flex-1 overflow-y-auto px-6"
                    >
                        <div className="py-4">
                            <ChecklistTab
                                audit={audit}
                                project={project}
                                responses={responses}
                                onResponseUpdate={handleResponse}
                            />
                        </div>
                    </TabsContent>

                    <TabsContent
                        value="findings"
                        className="flex-1 overflow-y-auto px-6"
                    >
                        <div className="py-4">
                            <FindingsTab
                                audit={audit}
                                project={project}
                                responses={Object.values(responses).filter(r => r.status === 'non_compliant')}
                            />
                        </div>
                    </TabsContent>
                </Tabs>

                {/* Fixed Footer */}
                <div className="border-t px-6 py-4 mt-auto">
                    <div className="flex flex-col-reverse sm:flex-row sm:justify-end gap-2">
                        <Button
                            className={secondaryButtonClasses}
                            variant="outline"
                            onClick={handleSaveAndClose}
                        >
                            Save & Close
                        </Button>
                        <Button
                            className={primaryButtonClasses}
                            onClick={handleComplete}
                            disabled={!allRequiredItemsAnswered()}
                        >
                            Complete Audit
                        </Button>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default AuditExecutionView;