import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Card, CardContent, CardHeader, CardTitle } from "../../../components/ui/cardui";
import { Button } from "../../../components/ui/button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../../components/ui/select";
import { Textarea } from "../../../components/ui/textarea";
import { useToast } from '../../../components/ui/use-toast';
import { Star, ArrowLeft, FileText, MessageSquare, Building2, User, Calendar } from 'lucide-react';
import { Badge } from "../../../components/ui/badge";
import api from '../../../services/api';
import BidMessagesPanel from './BidMessagesPanel';
import ContractAwardDialog from './ContractAwardDialog';

const BidResponseDetail = () => {
    const { bidId, responseId } = useParams();
    const navigate = useNavigate();
    const { toast } = useToast();
    const [response, setResponse] = useState(null);
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState('');

    const [messages, setMessages] = useState([]);
    const [loadingMessages, setLoadingMessages] = useState(false);

    const [currentUser, setCurrentUser] = useState(null);
    const [showContractDialog, setShowContractDialog] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState(null);

    const [documentTypes, setDocumentTypes] = useState([]);


    const [documents, setDocuments] = useState([]);

    useEffect(() => {
        fetchResponseDetails();

    }, [responseId]);

    useEffect(() => {
        const fetchDocTypes = async () => {
            try {
                const response = await api.get('bid-document-types/');
                setDocumentTypes(response.data);
            } catch (err) {
                console.error('Error fetching document types:', err);
                toast({
                    title: "Error",
                    description: "Failed to load document types",
                    variant: "destructive"
                });
            }
        };

        fetchDocTypes();
    }, []);

    useEffect(() => {
        const fetchDocuments = async () => {
            try {
                const response = await api.get(`bid-documents/?bid_submission=${responseId}`);

                setDocuments(response.data.results);

            } catch (err) {
                toast({
                    title: "Error",
                    description: "Failed to load documents",
                    variant: "destructive"
                });
            }
        };

        if (responseId) {
            fetchDocuments();
        }
    }, [responseId]);

    const fetchResponseDetails = async () => {
        try {
            setLoading(true);
            const response = await api.get(`bid-submissions/${responseId}/`);

            setResponse(response.data);
        } catch (err) {
            console.error('Error fetching response details:', err);
            if (err.response?.status === 404) {
                toast({
                    title: "Error",
                    description: "Bid response not found",
                    variant: "destructive"
                });
                navigate(`/bids/${bidId}`);
            } else if (err.response?.status === 403) {
                toast({
                    title: "Error",
                    description: "You don't have permission to view this response",
                    variant: "destructive"
                });
                navigate(`/bid-management/${bidId}`);
            } else {
                toast({
                    title: "Error",
                    description: "Failed to load response details",
                    variant: "destructive"
                });
            }
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        const fetchMessages = async () => {
            try {
                setLoadingMessages(true);
                const response = await api.get(`bid-messages/?bid_submission=${responseId}`);
                setMessages(Array.isArray(response.data) ? response.data :
                    (response.data?.results || []));
            } catch (err) {
                console.error('Error fetching messages:', err);
                toast({
                    title: "Error",
                    description: "Failed to load messages",
                    variant: "destructive"
                });
            } finally {
                setLoadingMessages(false);
            }
        };

        if (responseId) {
            fetchMessages();
        }
    }, [responseId]);


    const handleStatusChange = async (newStatus) => {
        if (newStatus === 'awarded') {
            setShowContractDialog(true);
            setSelectedStatus(newStatus);
            return;
        }

        try {
            await api.patch(`bid-submissions/${responseId}/`, {
                status: newStatus
            });
            setResponse(prev => ({ ...prev, status: newStatus }));
            toast({
                title: "Success",
                description: "Status updated successfully"
            });
        } catch (err) {
            toast({
                title: "Error",
                description: err.response?.data?.detail || "Failed to update status",
                variant: "destructive"
            });
        }
    };


    const handleContractSubmit = async (formData) => {
        try {

            for (let [key, value] of formData.entries()) {
                //console.log(key + ':', value);
            }

            const response = await api.patch(`bid-submissions/${responseId}/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });


            // Update local state after successful response
            setResponse(prev => ({ ...prev, status: selectedStatus }));
            setShowContractDialog(false);

            toast({
                title: "Success",
                description: `Status updated to ${selectedStatus} successfully`
            });
        } catch (err) {
            console.error('Contract submission error:', err);
            console.error('Error response:', err.response?.data);
            toast({
                title: "Error",
                description: err.response?.data?.detail || "Failed to update status and send contracts",
                variant: "destructive"
            });
        }
    };

    const handleSendMessage = async () => {
        try {
            const response = await api.post('bid-messages/', {
                bid_submission: responseId,
                message: message,
                requires_action: false // Add this if needed
            });
            setMessage('');
            // Add the new message to the list
            setMessages(prev => [response.data, ...prev]);
            toast({
                title: "Success",
                description: "Message sent successfully"
            });
        } catch (err) {
            toast({
                title: "Error",
                description: "Failed to send message",
                variant: "destructive"
            });
        }
    };

    const getStatusBadgeColor = (status) => {
        const colors = {
            submitted: "bg-blue-100 text-blue-800",
            under_review: "bg-yellow-100 text-yellow-800",
            revised: "bg-purple-100 text-purple-800",
            awarded: "bg-green-100 text-green-800",
            rejected: "bg-red-100 text-red-800"
        };
        return colors[status] || "bg-gray-100 text-gray-800";
    };

    if (loading) return <div className="flex justify-center items-center h-64">Loading...</div>;
    if (!response) return <div className="text-center">Response not found</div>;

    return (
        <div className="p-6 max-w-4xl mx-auto">
            <div className="flex items-center gap-4 mb-6">
                <Button
                    variant="ghost"
                    onClick={() => navigate(`/bid-management/${bidId}`)}
                    className="flex items-center gap-2"
                >
                    <ArrowLeft className="h-4 w-4" />
                    Back to Bid
                </Button>
                <h1 className="text-2xl font-bold">Bid Response Details</h1>
            </div>

            <div className="space-y-6">
                <Card>
                    <CardHeader>
                        <div className="flex justify-between items-center">
                            <CardTitle>Response Information</CardTitle>
                            <Badge className={getStatusBadgeColor(response.status)}>
                                {response.status.replace('_', ' ').toUpperCase()}
                            </Badge>
                        </div>
                    </CardHeader>
                    <CardContent>
                        <div className="grid grid-cols-2 gap-6">
                            <div className="space-y-4">
                                <div>
                                    <div className="flex items-center gap-2">
                                        <Building2 className="h-4 w-4 text-gray-500" />
                                        <span className="text-sm font-medium text-gray-500">Company</span>
                                    </div>
                                    <p className="mt-1 font-medium">{response.company_name}</p>
                                </div>
                                <div>
                                    <div className="flex items-center gap-2">
                                        <User className="h-4 w-4 text-gray-500" />
                                        <span className="text-sm font-medium text-gray-500">Contact</span>
                                    </div>
                                    <p className="mt-1">{response.contractor_name}</p>
                                </div>
                                <div>
                                    <div className="flex items-center gap-2">
                                        <Calendar className="h-4 w-4 text-gray-500" />
                                        <span className="text-sm font-medium text-gray-500">Submitted</span>
                                    </div>
                                    <p className="mt-1">{response.formatted_date}</p>
                                </div>
                            </div>

                            <div className="space-y-4">
                                <div>
                                    <span className="text-sm font-medium text-gray-500">Price</span>
                                    <p className="mt-1 text-2xl font-bold">{response.formatted_price}</p>
                                </div>
                                <div>
                                    <span className="text-sm font-medium text-gray-500">Timeline</span>
                                    <p className="mt-1">{response.timeline} days</p>
                                </div>
                                <div>
                                    <span className="text-sm font-medium text-gray-500">Status</span>
                                    <Select value={response.status} onValueChange={handleStatusChange}>
                                        <SelectTrigger className="w-full mt-1">
                                            <SelectValue placeholder="Select status" />
                                        </SelectTrigger>
                                        <SelectContent>
                                            <SelectItem value="submitted">Submitted</SelectItem>
                                            <SelectItem value="under_review">Under Review</SelectItem>
                                            <SelectItem value="revised">Revised</SelectItem>
                                            <SelectItem value="awarded">Awarded</SelectItem>
                                            <SelectItem value="rejected">Rejected</SelectItem>
                                        </SelectContent>
                                    </Select>
                                </div>
                            </div>
                        </div>

                        <div className="mt-8 space-y-6">
                            <div>
                                <h3 className="text-sm font-medium text-gray-500 mb-2">Scope</h3>
                                <div className="p-4 bg-gray-50 rounded-lg whitespace-pre-wrap">
                                    {response.scope}
                                </div>
                            </div>

                            <div>
                                <h3 className="text-sm font-medium text-gray-500 mb-2">Methodology</h3>
                                <div className="p-4 bg-gray-50 rounded-lg whitespace-pre-wrap">
                                    {response.methodology}
                                </div>
                            </div>

                            {response.assumptions && (
                                <div>
                                    <h3 className="text-sm font-medium text-gray-500 mb-2">Assumptions</h3>
                                    <div className="p-4 bg-gray-50 rounded-lg whitespace-pre-wrap">
                                        {response.assumptions}
                                    </div>
                                </div>
                            )}

                            {response.exclusions && (
                                <div>
                                    <h3 className="text-sm font-medium text-gray-500 mb-2">Exclusions</h3>
                                    <div className="p-4 bg-gray-50 rounded-lg whitespace-pre-wrap">
                                        {response.exclusions}
                                    </div>
                                </div>
                            )}

                            {response.team && response.team.length > 0 && (
                                <div>
                                    <h3 className="text-sm font-medium text-gray-500 mb-2">Team Members</h3>
                                    <div className="grid grid-cols-2 gap-4">
                                        {response.team.map((member, index) => (
                                            <div key={index} className="p-4 bg-gray-50 rounded-lg">
                                                <p className="font-medium">{member.name}</p>
                                                <p className="text-sm text-gray-500">{member.role}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    </CardContent>
                </Card>

                {documents.length > 0 && (
                    <Card>
                        <CardHeader>
                            <CardTitle>Submission Documents</CardTitle>
                        </CardHeader>
                        <CardContent>
                            <div className="grid gap-2">
                                {documents.map(doc => (
                                    <div key={doc.id} className="flex items-center justify-between p-2 hover:bg-gray-50 rounded-lg">
                                        <div className="flex items-center gap-2">
                                            <FileText className="h-4 w-4 text-gray-500" />
                                            <span>{doc.title}</span>
                                        </div>
                                        <a
                                            href={doc.file}
                                            download
                                            className="text-blue-600 hover:text-blue-800"
                                        >
                                            Download
                                        </a>
                                    </div>
                                ))}
                            </div>
                        </CardContent>
                    </Card>
                )}

                <Card>
                    <CardHeader>
                        <CardTitle>Messages</CardTitle>
                    </CardHeader>
                    <CardContent>
                        <BidMessagesPanel bidSubmissionId={responseId} isAdmin={true} />
                    </CardContent>
                </Card>
            </div>

            <ContractAwardDialog
                open={showContractDialog}
                onClose={() => setShowContractDialog(false)}
                onSubmit={handleContractSubmit}
                documentTypes={documentTypes}
                bidRequestId={bidId}
                bidSubmissionId={responseId}
                status={selectedStatus}
            />
        </div>
    );
};

export default BidResponseDetail;
