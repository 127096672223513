import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
    FormDescription
} from "../ui/form";
import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
} from "../ui/cardui";
import { Input } from "../ui/input";
import { Button } from "../ui/button";
import { Loader2 } from 'lucide-react';
import { showToast } from '../../utils/toast';
import { contractorsApi } from '../../services/contractors';
import api from '../../services/api';
import { primaryButtonClasses, secondaryButtonClasses } from '../common/ButtonStyles';

const ContractorForm = ({ onClose, contractor: currentContractor, onSubmit: onFormSubmit }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [trades, setTrades] = useState([]);
    const [companies, setCompanies] = useState([]);

    const form = useForm({
        defaultValues: {
            first_name: currentContractor?.user?.first_name || '',
            last_name: currentContractor?.user?.last_name || '',
            email: currentContractor?.user?.email || '',
            company: currentContractor?.company?.id?.toString() || '',
            payment_terms: currentContractor?.payment_terms || '',
            hourly_rate: currentContractor?.hourly_rate || '',
            lump_sum_amount: currentContractor?.lump_sum_amount || '',
            material_markup_percentage: currentContractor?.material_markup_percentage || '',
            other_payment_details: currentContractor?.other_payment_details || '',
            trades: currentContractor?.trades?.map(t => t.id.toString()) || [],
            other_trade: currentContractor?.other_trade || '',
            contract_start_date: currentContractor?.contract_start_date || new Date().toISOString().split('T')[0],
            contract_end_date: currentContractor?.contract_end_date || new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString().split('T')[0]
        }
    });

    useEffect(() => {
        fetchTrades();
        fetchCompanies();
    }, []);

    const fetchCompanies = async () => {
        try {
            const response = await api.get('companies/');
            setCompanies(response.data.results || []);
        } catch (error) {
            showToast.error('Failed to load companies');
        }
    };

    const fetchTrades = async () => {
        try {
            const response = await api.get('construction-trades/');
            setTrades(response.data);
        } catch (error) {
            showToast.error('Failed to load trades');
        }
    };

    const onSubmit = async (data) => {
        setIsSubmitting(true);

        try {

            const formData = {
                user: {
                    id: currentContractor?.user?.id
                },

                first_name: data.first_name,
                last_name: data.last_name,
                email: data.email,
                company: data.company,
                payment_terms: data.payment_terms,  // This needs to be at root level
                trades: data.trades || [],
                other_trade: data.other_trade || '',
                contract_start_date: data.contract_start_date,
                contract_end_date: data.contract_end_date
            };

            // Add payment specific fields based on payment terms
            switch (data.payment_terms) {
                case 'hourly':
                    formData.hourly_rate = data.hourly_rate;
                    break;
                case 'lump_sum':
                    formData.lump_sum_amount = data.lump_sum_amount;
                    break;
                case 'time_and_materials':
                    formData.material_markup_percentage = data.material_markup_percentage;
                    break;
                case 'other':
                    formData.other_payment_details = data.other_payment_details;
                    break;
            }

            // Validate required fields
            const requiredFields = ['email', 'payment_terms', 'company'];
            const missingFields = requiredFields.filter(field => !formData[field]);

            if (missingFields.length > 0) {
                showToast.error(`Missing required fields: ${missingFields.join(', ')}`);
                return;
            }

            if (currentContractor) {
                await contractorsApi.updateContractor(currentContractor.id, formData);
                showToast.success('Contractor updated successfully');
            } else {
                await contractorsApi.addContractor(formData);
                showToast.success('Contractor added successfully');
            }
            onClose();
        } catch (error) {
            console.error('Form submission error:', error);
            showToast.error(error.response?.data?.error || 'Failed to save contractor');
        } finally {
            setIsSubmitting(false);
        }
    };

    const paymentTermsOptions = [
        { value: 'hourly', label: 'Hourly' },
        { value: 'lump_sum', label: 'Lump Sum' },
        { value: 'time_and_materials', label: 'Time and Materials' },
        { value: 'other', label: 'Other' }
    ];

    return (
        <div className="fixed inset-0 flex flex-col bg-white">
            <div className="flex-none p-4 border-b">
                <h2 className="text-lg font-semibold">
                    {currentContractor ? 'Edit Contractor' : 'Add Contractor'}
                </h2>
            </div>

            <div className="flex-1 overflow-auto">
                <Form {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)} className="h-full">
                        <div className="p-4 space-y-6 pb-24">
                            {/* Required Information */}
                            <Card>
                                <CardHeader>
                                    <CardTitle>Required Information</CardTitle>
                                </CardHeader>
                                <CardContent className="space-y-4">
                                    <FormField
                                        control={form.control}
                                        name="company"
                                        rules={{ required: "Company is required" }}
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormLabel>Company *</FormLabel>
                                                <FormControl>
                                                    <select
                                                        className="w-full rounded-md border border-input bg-background px-3 py-2 text-sm"
                                                        {...field}
                                                    >
                                                        <option value="">Select a company</option>
                                                        {companies.map(company => (
                                                            <option key={company.id} value={company.id}>{company.name}</option>
                                                        ))}
                                                    </select>
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />

                                    <FormField
                                        control={form.control}
                                        name="email"
                                        rules={{
                                            required: "Email is required",
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                message: "Invalid email address"
                                            }
                                        }}
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormLabel>Email *</FormLabel>
                                                <FormControl>
                                                    <Input type="email" placeholder="Enter email" {...field} />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                </CardContent>
                            </Card>

                            {/* Personal Information */}
                            <Card>
                                <CardHeader>
                                    <CardTitle>Personal Information</CardTitle>
                                </CardHeader>
                                <CardContent className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                    <FormField
                                        control={form.control}
                                        name="first_name"
                                        rules={{ required: "First name is required" }}
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormLabel>First Name *</FormLabel>
                                                <FormControl>
                                                    <Input placeholder="Enter first name" {...field} />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                    <FormField
                                        control={form.control}
                                        name="last_name"
                                        rules={{ required: "Last name is required" }}
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormLabel>Last Name *</FormLabel>
                                                <FormControl>
                                                    <Input placeholder="Enter last name" {...field} />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                </CardContent>
                            </Card>

                            {/* Trade Information */}
                            <Card>
                                <CardHeader>
                                    <CardTitle>Trade Information</CardTitle>
                                </CardHeader>
                                <CardContent className="space-y-4">
                                    <FormField
                                        control={form.control}
                                        name="trades"
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormLabel>Select Trades</FormLabel>
                                                <FormControl>
                                                    <select
                                                        multiple
                                                        className="w-full rounded-md border border-input bg-background px-3 py-2 min-h-[100px]"
                                                        {...field}
                                                        onChange={(e) => field.onChange(
                                                            Array.from(e.target.selectedOptions, option => option.value)
                                                        )}
                                                    >
                                                        {trades.map(trade => (
                                                            <option key={trade.id} value={trade.id}>
                                                                {trade.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </FormControl>
                                                <FormDescription>
                                                    Hold Ctrl/Cmd to select multiple trades
                                                </FormDescription>
                                            </FormItem>
                                        )}
                                    />
                                    <FormField
                                        control={form.control}
                                        name="other_trade"
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormLabel>Other Trade</FormLabel>
                                                <FormControl>
                                                    <Input placeholder="Specify other trade" {...field} />
                                                </FormControl>
                                            </FormItem>
                                        )}
                                    />
                                </CardContent>
                            </Card>

                            {/* Payment Information */}
                            <Card>
                                <CardHeader>
                                    <CardTitle>Payment Information</CardTitle>
                                </CardHeader>
                                <CardContent className="space-y-4">
                                    <FormField
                                        control={form.control}
                                        name="payment_terms"
                                        rules={{ required: "Payment terms are required" }}
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormLabel>Payment Terms *</FormLabel>
                                                <FormControl>
                                                    <select
                                                        className="w-full rounded-md border border-input bg-background px-3 py-2"
                                                        {...field}
                                                    >
                                                        <option value="">Select payment terms</option>
                                                        <option value="hourly">Hourly</option>
                                                        <option value="lump_sum">Lump Sum</option>
                                                        <option value="time_and_materials">Time and Materials</option>
                                                        <option value="other">Other</option>
                                                    </select>
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />

                                    {form.watch('payment_terms') === 'hourly' && (
                                        <FormField
                                            control={form.control}
                                            name="hourly_rate"
                                            rules={{ required: "Hourly rate is required" }}
                                            render={({ field }) => (
                                                <FormItem>
                                                    <FormLabel>Hourly Rate *</FormLabel>
                                                    <FormControl>
                                                        <Input type="number" step="0.01" min="0" {...field} />
                                                    </FormControl>
                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />
                                    )}

                                    {form.watch('payment_terms') === 'lump_sum' && (
                                        <FormField
                                            control={form.control}
                                            name="lump_sum_amount"
                                            rules={{ required: "Lump sum amount is required" }}
                                            render={({ field }) => (
                                                <FormItem>
                                                    <FormLabel>Lump Sum Amount *</FormLabel>
                                                    <FormControl>
                                                        <Input type="number" step="0.01" min="0" {...field} />
                                                    </FormControl>
                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />
                                    )}

                                    {form.watch('payment_terms') === 'time_and_materials' && (
                                        <FormField
                                            control={form.control}
                                            name="material_markup_percentage"
                                            rules={{ required: "Material markup percentage is required" }}
                                            render={({ field }) => (
                                                <FormItem>
                                                    <FormLabel>Material Markup Percentage *</FormLabel>
                                                    <FormControl>
                                                        <Input type="number" step="0.01" min="0" max="100" {...field} />
                                                    </FormControl>
                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />
                                    )}

                                    {form.watch('payment_terms') === 'other' && (
                                        <FormField
                                            control={form.control}
                                            name="other_payment_details"
                                            rules={{ required: "Payment details are required" }}
                                            render={({ field }) => (
                                                <FormItem>
                                                    <FormLabel>Payment Details *</FormLabel>
                                                    <FormControl>
                                                        <textarea
                                                            className="w-full rounded-md border border-input bg-background px-3 py-2 min-h-[100px]"
                                                            placeholder="Describe payment terms..."
                                                            {...field}
                                                        />
                                                    </FormControl>
                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />
                                    )}
                                </CardContent>
                            </Card>

                            {/* Contract Dates */}
                            <Card>
                                <CardHeader>
                                    <CardTitle>Contract Dates</CardTitle>
                                </CardHeader>
                                <CardContent className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                    <FormField
                                        control={form.control}
                                        name="contract_start_date"
                                        rules={{ required: "Start date is required" }}
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormLabel>Start Date *</FormLabel>
                                                <FormControl>
                                                    <Input type="date" {...field} />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                    <FormField
                                        control={form.control}
                                        name="contract_end_date"
                                        rules={{ required: "End date is required" }}
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormLabel>End Date *</FormLabel>
                                                <FormControl>
                                                    <Input type="date" {...field} />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                </CardContent>
                            </Card>
                        </div>

                        {/* Fixed Footer */}
                        <div className="fixed bottom-0 left-0 right-0 bg-white border-t p-4">
                            <div className="flex justify-end space-x-2">
                                <Button
                                    type="button"
                                    variant="outline"
                                    onClick={onClose}
                                    className={secondaryButtonClasses}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type="submit"
                                    disabled={isSubmitting}
                                    className={primaryButtonClasses}
                                >
                                    {isSubmitting ? (
                                        <>
                                            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                            Saving...
                                        </>
                                    ) : 'Save Contractor'}
                                </Button>
                            </div>
                        </div>
                    </form>
                </Form>
            </div>
        </div>
    );
};

export default ContractorForm;