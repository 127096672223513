
import React from 'react';
import { Alert, AlertTitle, AlertDescription } from '../components/ui/alert';
import { Button } from '../components/ui/button';
import { XCircle } from 'lucide-react';
import Sidebar from '../components/Sidebar';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            error: null,
            errorInfo: null
        };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        this.setState({
            error: error,
            errorInfo: errorInfo
        });

        // Log to your error tracking service (e.g., Sentry)
        this.logErrorToService(error, errorInfo);
    }

    logErrorToService = (error, errorInfo) => {
        // Example logging to backend
        fetch('/api/log-error', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                error: error.toString(),
                componentStack: errorInfo.componentStack,
                url: window.location.href,
                timestamp: new Date().toISOString(),
                // Add user context if available
                userId: localStorage.getItem('userId'),
                userEmail: localStorage.getItem('userEmail'),
            }),
        }).catch(console.error); // Fail silently if logging fails
    };

    render() {
        if (this.state.hasError) {
            // User-friendly error UI
            return (
                <div className="flex h-screen bg-gray-50">
                    <Sidebar
                        isOpen={this.state.isSidebarOpen}
                        setIsOpen={(isOpen) => this.setState({ isSidebarOpen: isOpen })}
                    />

                    <div className="flex-1 flex items-center justify-center p-4">
                        <div className="max-w-md w-full">
                            <Alert variant="destructive" className="mb-4">
                                <XCircle className="h-4 w-4" />
                                <AlertTitle>Oops! Something went wrong</AlertTitle>
                                <AlertDescription>
                                    We apologize for the inconvenience. Our team has been notified and is working to fix this issue.
                                </AlertDescription>
                            </Alert>

                            <div className="space-y-4">
                                <Button
                                    onClick={() => window.location.reload()}
                                    className="w-full"
                                >
                                    Refresh Page
                                </Button>

                                <Button
                                    variant="outline"
                                    onClick={() => window.location.href = '/'}
                                    className="w-full"
                                >
                                    Return to Homepage
                                </Button>

                                {process.env.NODE_ENV === 'development' && (
                                    <details className="mt-4 p-4 bg-gray-100 rounded-lg">
                                        <summary className="cursor-pointer font-medium">Technical Details</summary>
                                        <pre className="mt-2 text-sm overflow-auto">
                                            {this.state.error && this.state.error.toString()}
                                            {this.state.errorInfo && this.state.errorInfo.componentStack}
                                        </pre>
                                    </details>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;