import api from "./api";

const safetyApi = {
    getAuditTypes: () => api.get('safety-types/audit_types/'),
    getSafetySubtypes: () => api.get('safety-types/safety_subtypes/'),
    getHazardTypes: () => api.get('safety-types/hazard_types/'),
    getIncidentTypes: () => api.get('safety-types/incident_types/'),
    getAuditTemplates: () => api.get('audit-templates/'),
    getTrainingTypes: () => api.get('safety-types/training_types/', {
        headers: { 'X-Tenant-ID': localStorage.getItem('tenantId') }
    }),
    getCertificationTypes: () => api.get('safety-types/certification_types/', {
        headers: { 'X-Tenant-ID': localStorage.getItem('tenantId') }
    }),
};
export default safetyApi;