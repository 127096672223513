import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFeatureAccess, FEATURES } from '../../contexts/FeatureAccessContext';
import api from '../../services/api';
import { XCircle, ChevronUp, CheckCircle, Loader2 } from 'lucide-react';
import verifyStepCompletion from '../../services/verificationService';

const OnboardingFlow = () => {
    const navigate = useNavigate();
    const { featureType, loading } = useFeatureAccess();
    const [steps, setSteps] = useState([]);
    const [progress, setProgress] = useState(0);
    const [showOnboarding, setShowOnboarding] = useState(true);
    const [collapsed, setCollapsed] = useState(false);
    const [error, setError] = useState(null);
    const [verifying, setVerifying] = useState(false);



    // Check for pendingOnboarding flag when component mounts
    useEffect(() => {
        const pendingOnboarding = localStorage.getItem('pendingOnboarding');
        if (pendingOnboarding) {
            localStorage.removeItem('pendingOnboarding');
            setShowOnboarding(true);
            setCollapsed(false);
        }
    }, []);


    const fetchProgress = async () => {
        if (!featureType) return;

        try {
            setError(null);
            const response = await api.get('user-onboarding/?auto_verify=true');
            console.log('Onboarding response:', response.data);

            setSteps(response.data.steps || []);
            setProgress(response.data.progress || 0);
            setShowOnboarding(response.data.show_onboarding !== false);
            setCollapsed(response.data.collapsed || false);
        } catch (error) {
            console.error('Error fetching onboarding progress:', error);
            setError(error);

            // If API fails, create default steps based on feature type
            const defaultSteps = [
                {
                    id: 1,
                    title: 'Complete your profile',
                    description: 'Add your company information and personal details',
                    action: '/settings',
                    completed: false
                },
                {
                    id: 2,
                    title: 'Add team members',
                    description: 'Invite your team to collaborate',
                    action: '/settings',
                    completed: false
                }
            ];

            // Add feature-specific steps
            if (featureType === FEATURES.PROJECT_MANAGEMENT) {
                defaultSteps.push({
                    id: 3,
                    title: 'Create your first project',
                    description: 'Start managing your construction projects',
                    action: '/projects',
                    completed: false
                });
            } else if (featureType === FEATURES.SAFETY_MANAGEMENT) {
                defaultSteps.push({
                    id: 3,
                    title: 'Log Hazard',
                    description: 'Log your first log hazard',
                    action: '/safety',
                    completed: false
                });
            } else if (featureType === FEATURES.BID_MANAGEMENT) {
                defaultSteps.push({
                    id: 3,
                    title: 'Create your first bid solicitation',
                    description: 'Start managing your bid process',
                    action: '/bid-management',
                    completed: false
                });
            }

            setSteps(defaultSteps);
            setProgress(0);
        }
    };



    useEffect(() => {
        const pendingOnboarding = localStorage.getItem('pendingOnboarding');
        if (pendingOnboarding) {
            // Clear the flag so it doesn't trigger again
            localStorage.removeItem('pendingOnboarding');
            // Show onboarding and make sure it's not collapsed
            setShowOnboarding(true);
            setCollapsed(false);

            // Fetch onboarding steps immediately
            fetchProgress();
        }
    }, []);

    // Auto-verify steps periodically
    useEffect(() => {
        const checkAllSteps = async () => {
            if (!steps.length || !showOnboarding || collapsed) return;

            setVerifying(true);
            try {
                // Use the backend verification endpoint
                const response = await api.get('verify-all-steps/');

                if (response.data.updated) {
                    setSteps(response.data.steps);
                    setProgress(response.data.progress);

                    if (response.data.progress === 100) {
                        // All steps completed! Update UI
                        setTimeout(() => {
                            setShowOnboarding(false);

                            // Save to backend
                            api.post('user-onboarding/', {
                                show_onboarding: false,
                                completed: true
                            }).catch(err => {
                                console.error('Error updating onboarding status:', err);
                            });
                        }, 3000); // Show completion for 3 seconds before hiding
                    }
                }
            } catch (error) {
                console.error('Error verifying steps:', error);
            } finally {
                setVerifying(false);
            }
        };

        // Check steps when component mounts
        checkAllSteps();

        // And check every 30 seconds
        const interval = setInterval(checkAllSteps, 30000);
        return () => clearInterval(interval);
    }, [steps, showOnboarding, collapsed]);

    // Handle step click - either navigate to action or verify completion
    const handleStepClick = async (step) => {
        if (step.completed) {
            // If already completed, just navigate
            navigate(step.action);
            return;
        }

        // Verify this step first
        try {
            setVerifying(true);
            const response = await api.get(`verify-step/${step.id}/`);

            if (response.data.completed) {
                // Step is already completed, update it
                handleStepComplete(step.id);
            } else {
                // Not completed, navigate to help complete it
                navigate(step.action);
            }
        } catch (error) {
            console.error(`Error verifying step ${step.id}:`, error);
            // Still navigate to help complete the step
            navigate(step.action);
        } finally {
            setVerifying(false);
        }
    };

    // Mark a step as complete and update progress
    const handleStepComplete = async (stepId) => {
        const updatedSteps = steps.map(step =>
            step.id === stepId ? { ...step, completed: true } : step
        );

        const completedCount = updatedSteps.filter(step => step.completed).length;
        const newProgress = Math.round((completedCount / updatedSteps.length) * 100);

        setSteps(updatedSteps);
        setProgress(newProgress);

        try {
            await api.post('user-onboarding/', {
                steps: updatedSteps,
                progress: newProgress,
                show_onboarding: true,
                collapsed
            });

            if (newProgress === 100) {
                // All steps completed!
                setTimeout(() => {
                    setShowOnboarding(false);

                    // Save to backend
                    api.post('user-onboarding/', {
                        show_onboarding: false,
                        completed: true
                    }).catch(err => {
                        console.error('Error updating onboarding status:', err);
                    });
                }, 3000); // Show completion for 3 seconds before hiding
            }
        } catch (error) {
            console.error('Error saving onboarding progress:', error);
        }
    };

    // Toggle collapsed state
    const toggleCollapsed = async () => {
        const newCollapsedState = !collapsed;
        setCollapsed(newCollapsedState);

        try {
            await api.post('user-onboarding/', {
                collapsed: newCollapsedState,
                show_onboarding: true // Always keep this true until 100% complete
            });
        } catch (error) {
            console.error('Error saving onboarding state:', error);
        }
    };

    // If not ready to show or no data yet
    if (loading || !featureType || !showOnboarding || !steps.length) {
        return null;
    }

    // Render collapsed state
    if (collapsed) {
        return (
            <div className="fixed bottom-6 left-6 z-50">
                <div className="bg-white rounded-lg shadow-lg p-3">
                    <button
                        onClick={toggleCollapsed}
                        className="flex items-center space-x-2"
                    >
                        <div className="w-8 h-8 bg-blue-600 rounded-full flex items-center justify-center">
                            <ChevronUp className="h-5 w-5 text-white" />
                        </div>
                        <div>
                            <p className="text-sm font-medium">Setup Progress: {progress}%</p>
                            <div className="mt-1 h-1 w-16 bg-gray-200 rounded-full">
                                <div
                                    className="h-full bg-blue-600 rounded-full"
                                    style={{ width: `${progress}%` }}
                                />
                            </div>
                        </div>
                    </button>
                </div>
            </div>
        );
    }

    // Render full onboarding panel
    return (
        <div className="fixed inset-x-0 bottom-0 pb-6 sm:pb-8 lg:pb-10 z-50">
            <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:px-8">
                <div className="rounded-lg bg-white p-4 shadow-lg sm:p-6 lg:p-8">
                    <div className="flex items-center justify-between">
                        <h3 className="text-lg font-medium">
                            Getting Started with {featureType}
                            {verifying && (
                                <span className="ml-2 inline-block">
                                    <Loader2 className="h-4 w-4 animate-spin text-blue-500 inline" />
                                </span>
                            )}
                        </h3>
                        <button
                            type="button"
                            onClick={toggleCollapsed}
                            className="text-gray-400 
                            hover:text-gray-500"
                        >
                            <span className="sr-only">Minimize</span>
                            <XCircle className="h-5 w-5" />
                        </button>
                    </div>

                    <div className="mt-4">
                        <div className="mt-2 h-2 w-full bg-gray-200 rounded-full">
                            <div
                                className="h-full bg-blue-600 rounded-full transition-all duration-300"
                                style={{ width: `${progress}%` }}
                            />
                        </div>
                        <p className="mt-2 text-sm text-gray-500">
                            {progress}% complete
                        </p>
                    </div>

                    <div className="mt-6">
                        <ul className="divide-y divide-gray-200">
                            {steps.map((step) => (
                                <li key={step.id} className="py-4">
                                    <div className="flex items-start">
                                        <div className="flex-shrink-0">
                                            {step.completed ? (
                                                <CheckCircle className="h-5 w-5 text-green-500" />
                                            ) : (
                                                <input
                                                    id={`step-${step.id}`}
                                                    name={`step-${step.id}`}
                                                    type="checkbox"
                                                    className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                                                    checked={step.completed}
                                                    onChange={() => handleStepComplete(step.id)}
                                                />
                                            )}
                                        </div>
                                        <div className="ml-3 flex-grow">
                                            <p className="text-sm font-medium text-gray-900">{step.title}</p>
                                            <p className="text-sm text-gray-500">{step.description}</p>
                                        </div>
                                        <div className="ml-3">
                                            <button
                                                onClick={() => handleStepClick(step)}
                                                className={`inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded 
                                                    ${step.completed
                                                        ? 'text-green-700 bg-green-100 hover:bg-green-200'
                                                        : 'text-blue-700 bg-blue-100 hover:bg-blue-200'} 
                                                    focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`}
                                            >
                                                {step.completed ? 'View' : 'Go'}
                                            </button>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>

                    {error && (
                        <div className="mt-4 p-3 bg-red-50 text-red-700 text-sm rounded-md">
                            <p>An error occurred: {error.message || 'Please try again'}</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default OnboardingFlow;