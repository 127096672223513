import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "../../../components/ui/cardui";
import { Table, TableHeader, TableBody, TableRow, TableHead, TableCell } from "../../../components/ui/table";
import { Button } from "../../../components/ui/button";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../../components/ui/tabs";
import { useNavigate } from 'react-router-dom';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Building2, Users, ChevronRight, Clock, CheckCircle, AlertCircle } from 'lucide-react';
import { primaryButtonClasses } from '../../common/ButtonStyles';
import BidService from '../../../services/bidService';
import api from '../../../services/api';


const BidDashboard = () => {
    const [bids, setBids] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const [bidResponses, setBidResponses] = useState({});

    const [analyticsData, setAnalyticsData] = useState({
        totalBids: 0,
        pendingResponses: 0,
        totalResponses: 0,
        averageResponseRate: 0,
        responsesByMonth: []
    });

    useEffect(() => {
        fetchBids();
    }, []);

    const fetchBids = async () => {
        try {
            setLoading(true);
            const [bidsResponse, invitationsResponse] = await Promise.all([
                api.get('/bid-requests/'),
                api.get('/bid-invitations/')
            ]);
            const bidData = bidsResponse.data.results || [];
            const invitations = invitationsResponse.data.results || [];

            // Create response count map
            const responseCountMap = {};
            const pendingCountMap = {};

            // Initialize counts for each bid
            bidData.forEach(bid => {
                responseCountMap[bid.id] = 0;
                pendingCountMap[bid.id] = 0;
            });

            // Fetch all submissions in a single request instead of per bid
            const allSubmissionsResponse = await api.get('bid-submissions/');
            const allSubmissions = allSubmissionsResponse.data.results || [];

            // Count submissions per bid
            allSubmissions.forEach(submission => {
                const bidId = submission.bid_request;
                responseCountMap[bidId] = (responseCountMap[bidId] || 0) + 1;
            });

            setBidResponses(responseCountMap);
            setBids(bidData);

            const totalResponses = Object.values(responseCountMap).reduce((a, b) => a + b, 0);
            const totalPending = Object.values(pendingCountMap).reduce((a, b) => a + b, 0);

            setAnalyticsData({
                totalBids: bidData.length,
                pendingResponses: totalPending,
                totalResponses,
                averageResponseRate: bidData.length ? (totalResponses / bidData.length).toFixed(1) : 0,
                responsesByMonth: groupResponsesByMonth(allSubmissions)
            });
        } catch (err) {
            setError('Failed to fetch bid data');
            console.error('Error:', err);
        } finally {
            setLoading(false);
        }
    };


    const groupResponsesByMonth = (submissions) => {
        const monthlyData = {};
        submissions.forEach(submission => {
            const date = new Date(submission.created_at || submission.submitted_at);
            const month = date.toLocaleString('default', { month: 'short' });
            monthlyData[month] = (monthlyData[month] || 0) + 1;
        });

        return Object.entries(monthlyData)
            .map(([month, responses]) => ({
                month,
                responses
            }))
            .sort((a, b) => {
                const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                return months.indexOf(a.month) - months.indexOf(b.month);
            });
    };

    const handleBidClick = (bidId) => {
        navigate(`/bid-management/${bidId}`);
    };

    if (loading) return <div className="flex justify-center items-center h-64"><div className="text-lg">Loading bids...</div></div>;
    if (error) return <div className="p-4 bg-red-50 border border-red-200 rounded-md"><p className="text-red-600">{error}</p></div>;

    return (
        <div className="p-4 md:p-6">
            <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-6 gap-4">
                <h1 className="text-2xl font-bold">Bid Management</h1>
                <Button className={primaryButtonClasses} onClick={() => navigate('/bid-management/new')}>
                    Create New Bid
                </Button>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
                <Card>
                    <CardHeader className="flex flex-row items-center justify-between pb-2">
                        <CardTitle className="text-sm font-medium">Total Bids</CardTitle>
                        <Building2 className="h-4 w-4 text-muted-foreground" />
                    </CardHeader>
                    <CardContent>
                        <div className="text-2xl font-bold">{analyticsData.totalBids}</div>
                    </CardContent>
                </Card>

                <Card>
                    <CardHeader className="flex flex-row items-center justify-between pb-2">
                        <CardTitle className="text-sm font-medium">Pending Responses</CardTitle>
                        <Clock className="h-4 w-4 text-muted-foreground" />
                    </CardHeader>
                    <CardContent>
                        <div className="text-2xl font-bold">{analyticsData.pendingResponses}</div>
                    </CardContent>
                </Card>

                <Card>
                    <CardHeader className="flex flex-row items-center justify-between pb-2">
                        <CardTitle className="text-sm font-medium">Total Responses</CardTitle>
                        <Users className="h-4 w-4 text-muted-foreground" />
                    </CardHeader>
                    <CardContent>
                        <div className="text-2xl font-bold">{analyticsData.totalResponses}</div>
                    </CardContent>
                </Card>

                <Card>
                    <CardHeader className="flex flex-row items-center justify-between pb-2">
                        <CardTitle className="text-sm font-medium">Avg. Responses</CardTitle>
                        <AlertCircle className="h-4 w-4 text-muted-foreground" />
                    </CardHeader>
                    <CardContent>
                        <div className="text-2xl font-bold">{analyticsData.averageResponseRate}</div>
                    </CardContent>
                </Card>
            </div>

            <Tabs defaultValue="table" className="w-full mb-6">
                <TabsList className="mb-4">
                    <TabsTrigger value="table">Table View</TabsTrigger>
                    <TabsTrigger value="grid">Grid View</TabsTrigger>
                </TabsList>

                <TabsContent value="table" className="overflow-x-auto">
                    <Table>
                        <TableHeader>
                            <TableRow>
                                <TableHead>Title</TableHead>
                                <TableHead>Status</TableHead>
                                <TableHead>Deadline</TableHead>
                                <TableHead>Responses</TableHead>
                                <TableHead className="w-[50px]"></TableHead>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {bids.map(bid => (
                                <TableRow key={bid.id} className="cursor-pointer hover:bg-gray-50" onClick={() => handleBidClick(bid.id)}>
                                    <TableCell className="font-medium">{bid.title}</TableCell>
                                    <TableCell>
                                        <span className={`px-2 py-1 rounded-full text-xs ${bid.status === 'open_for_submission' ? 'bg-green-100 text-green-800' : 'bg-gray-100'}`}>
                                            {bid.status.replace(/_/g, ' ')}
                                        </span>
                                    </TableCell>
                                    <TableCell>{new Date(bid.submission_deadline).toLocaleDateString()}</TableCell>
                                    <TableCell>{bidResponses[bid.id] || 0}</TableCell>
                                    <TableCell>
                                        <ChevronRight className="h-4 w-4" />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TabsContent>

                <TabsContent value="grid" className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                    {bids.map(bid => (
                        <Card key={bid.id} className="cursor-pointer hover:border-blue-500" onClick={() => handleBidClick(bid.id)}>
                            <CardHeader>
                                <CardTitle className="text-lg">{bid.title}</CardTitle>
                            </CardHeader>
                            <CardContent>
                                <div className="space-y-2">
                                    <div className="flex justify-between items-center">
                                        <span className="text-sm text-gray-500">Status</span>
                                        <span className={`px-2 py-1 rounded-full text-xs ${bid.status === 'open_for_submission' ? 'bg-green-100 text-green-800' : 'bg-gray-100'}`}>
                                            {bid.status.replace(/_/g, ' ')}
                                        </span>
                                    </div>
                                    <div className="flex justify-between items-center">
                                        <span className="text-sm text-gray-500">Deadline</span>
                                        <span>{new Date(bid.submission_deadline).toLocaleDateString()}</span>
                                    </div>
                                    <div className="flex justify-between items-center">
                                        <span className="text-sm text-gray-500">Responses</span>
                                        <span>{bidResponses[bid.id] || 0}</span>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    ))}
                </TabsContent>
            </Tabs>

            <Card className="mb-6">
                <CardHeader>
                    <CardTitle>Response Trends</CardTitle>
                </CardHeader>
                <CardContent>
                    <div className="h-[300px] w-full">
                        <ResponsiveContainer width="100%" height="100%">
                            <LineChart data={analyticsData.responsesByMonth}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="month" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Line type="monotone" dataKey="responses" stroke="#8884d8" />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                </CardContent>
            </Card>
        </div>
    );
};

export default BidDashboard;