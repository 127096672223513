import React, { useState, useEffect } from 'react';
import { Tabs, TabsList, TabsTrigger, TabsContent } from './ui/tabs';
import { Card, CardContent } from './ui/cardui';
import { ScrollArea } from './ui/scroll-area';
import { Alert, AlertDescription } from './ui/alert';
import api from '../services/api';
import { showToast } from '../utils/toast';
import BasicInfoSettings from './BasicInfoSettings';
import EmployeeManagement from './EmployeeManagement';
import ContractorManagement from './Contractors/ContractorManagement';
import RoleManagement from '../Management/RoleManagement';
import { useFeatureAccess, FEATURES } from '../contexts/FeatureAccessContext';
import SubscriptionManagement from './Subscription/SubscriptionManagement';
import ClientManagement from './Client/ClientManagement';


const PlaceholderPanel = ({ title, message }) => (
  <div className="flex flex-col items-center justify-center p-8 space-y-2">
    <h3 className="text-lg font-semibold">{title}</h3>
    <p className="text-sm text-muted-foreground">{message || 'No data available yet.'}</p>
  </div>
);

const LoadingSpinner = () => (
  <div className="flex items-center justify-center w-full h-64">
    <div className="w-8 h-8 border-4 border-primary border-t-transparent rounded-full animate-spin" />
  </div>
);

const ErrorAlert = ({ message, retry }) => (
  <Alert variant="destructive" className="mb-6">
    <AlertDescription className="flex items-center justify-between">
      <span>{message}</span>
      {retry && (
        <button
          onClick={retry}
          className="px-3 py-1 text-sm bg-destructive/10 hover:bg-destructive/20 rounded-md transition-colors"
        >
          Retry
        </button>
      )}
    </AlertDescription>
  </Alert>
);



const Settings = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userRole, setUserRole] = useState('User');
  const { hasFeatureAccess, featureType, isSpecializedAdmin } = useFeatureAccess();

  useEffect(() => {
    console.log("Current user role:", userRole);
  }, [userRole]);

  const fetchUserRole = async (roleId) => {
    try {
      const response = await api.get(`roles/${roleId}/`);
      return response.data;
    } catch (err) {
      console.error('Failed to fetch role data:', err);
      return null;
    }
  };

  const fetchUserData = async () => {
    try {
      setLoading(true);
      setError(null);

      const userResponse = await api.get('users/profile/');

      if (!userResponse.data) {
        throw new Error('No user data received');
      }
      setUser(userResponse.data);

      // Get role from user response directly if available
      if (userResponse.data.role) {
        setUserRole(userResponse.data.role);
      } else if (userResponse.data.role_id) {
        const roleData = await fetchUserRole(userResponse.data.role_id);
        if (roleData && roleData.name) {
          setUserRole(roleData.name);
        }
      }

    } catch (err) {
      setError('Unable to load settings. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  // Debug logs for state changes
  useEffect(() => {
  }, [user, loading, error, userRole]);

  // Define categories access based on roles and feature access
  const getCategoriesByRole = () => {
    // Base categories available to all roles
    const baseCategories = [
      { id: 'basic-info', label: 'Basic Info' },
    ];

    // Admin management categories
    const adminCategories = [
      ...baseCategories,
      { id: 'employee-management', label: 'Employee Management' },
      { id: 'role-management', label: 'Role Management' },
      { id: 'plans-billing', label: 'Plans & Billing' },
      { id: 'notifications', label: 'Notifications' },
      { id: 'audit-trail', label: 'Audit Trail' },
      { id: 'integrations', label: 'Integrations' }
    ];

    // For Tenant Admin with ALL features or any specialized admin
    if (hasFeatureAccess(FEATURES.ALL_FEATURES) || isSpecializedAdmin()) {
      return adminCategories;
    }

    // For Project Admin
    if (userRole === 'Project Admin') {
      return [
        ...baseCategories,
        { id: 'employee-management', label: 'Employee Management' },
        { id: 'contractor-management', label: 'Contractor Management' },
      ];
    }

    // Default case
    return baseCategories;
  };

  const categories = getCategoriesByRole();

  const renderTabContent = (categoryId) => {
    if (loading) {
      return <LoadingSpinner />;
    }

    if (error) {
      return <ErrorAlert message={error} retry={fetchUserData} />;
    }

    if (!user) {
      return <PlaceholderPanel title="User Not Found" message="Unable to load user data" />;
    }

    // Check if user has admin access (either full Tenant Admin or specialized admin)
    const hasAdminAccess = hasFeatureAccess(FEATURES.ALL_FEATURES) || isSpecializedAdmin();

    switch (categoryId) {
      case 'basic-info':
        return <BasicInfoSettings user={user} onUpdateUser={fetchUserData} />;
      case 'employee-management':
        return (hasAdminAccess || userRole === 'Project Admin') ?
          <EmployeeManagement /> : <PlaceholderPanel title="Access Denied" />;
      case 'role-management':
        return hasAdminAccess ?
          <RoleManagement /> : <PlaceholderPanel title="Access Denied" />;
      case 'plans-billing':
        return hasAdminAccess ?
          <SubscriptionManagement /> : <PlaceholderPanel title="Access Denied" />;
      default:
        return hasAdminAccess ?
          <PlaceholderPanel
            title={categories.find(cat => cat.id === categoryId)?.label}
            message="This feature is coming soon"
          /> :
          <PlaceholderPanel title="Access Denied" />;
    }
  };

  // Show loading state only during initial load
  if (loading && !user) {
    return (
      <div className="container max-w-7xl mx-auto p-6">
        <h1 className="text-3xl font-bold mb-6">Settings</h1>
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="container max-w-7xl mx-auto p-6">
      <h1 className="text-3xl font-bold mb-6">Settings</h1>

      {error && <ErrorAlert message={error} retry={fetchUserData} />}

      <Card>
        <CardContent className="p-6">
          <ScrollArea className="h-[calc(100vh-12rem)]">
            <Tabs defaultValue="basic-info" className="w-full">
              <TabsList className="w-full justify-start mb-6 bg-background border-b rounded-none h-auto flex-wrap">
                {categories.map(({ id, label }) => (
                  <TabsTrigger
                    key={id}
                    value={id}
                    className="py-2.5 px-4 data-[state=active]:border-b-2 data-[state=active]:border-primary rounded-none"
                  >
                    {label}
                  </TabsTrigger>
                ))}
              </TabsList>

              {categories.map(({ id }) => (
                <TabsContent key={id} value={id} className="mt-4">
                  {renderTabContent(id)}
                </TabsContent>
              ))}
            </Tabs>
          </ScrollArea>
        </CardContent>
      </Card>
    </div>
  );
};

export default Settings;