import api from "./api";

export const fetchRiskCategories = async () => {
    const response = await api.get('risk-categories/');
    return response.data;
};

export const fetchRiskTemplates = async (categoryId = null) => {
    let url = 'risk-templates/';
    if (categoryId) {
        url += `?category=${categoryId}`;
    }
    const response = await api.get(url);
    return response.data.results;
};

export const fetchProjectRisks = async (projectId) => {
    const response = await api.get(`risks/project_risks/?project_id=${projectId}`);
    return response.data;
};

export const createRisk = async (riskData) => {
    const response = await api.post('risks/', riskData);
    return response.data;
};

export const updateRisk = async (riskId, riskData) => {
    const response = await api.put(`risks/${riskId}/`, riskData);
    return response.data;
};

export const deleteRisk = async (riskId) => {
    const response = await api.delete(`risks/${riskId}/`);
    return response.data;
};

export const fetchRiskById = async (riskId) => {
    const response = await api.get(`risks/${riskId}/`);
    return response.data;
};

export const createRiskResponse = async (responseData) => {
    const response = await api.post('risk-responses/', responseData);
    return response.data;
};

export const updateRiskResponse = async (responseId, responseData) => {
    const response = await api.put(`risk-responses/${responseId}/`, responseData);
    return response.data;
};

export const runMonteCarloSimulation = async (simulationData) => {
    const response = await api.post('risks/monte_carlo/', simulationData);
    return response.data;
};

export const predictProjectRisks = async (projectData) => {
    const response = await api.post('risks/predict_risks/', projectData);
    return response.data;
};