import React, { useState, useEffect } from 'react';
import { Button } from "../ui/button";
import { formatCurrency } from '../../utils/formatter';
import { Card, CardContent, CardHeader, CardTitle } from "../ui/cardui";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "../ui/tabs";
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Avatar, AvatarImage, AvatarFallback } from "../ui/avatar";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import { Input } from '../ui/input';
import { Alert, AlertDescription } from '../ui/alert';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue
} from '../ui/select';
import { LockIcon, AlertTriangle } from 'lucide-react';
import budgetService from '../../services/budgetService';
import { useToast } from '../ui/use-toast';
import {
    Plus,
    FileDown,
    Filter,
    Calendar,
    DollarSign,
    Users,
    Truck,
    LucidePenTool,
    Table,
    Package
} from 'lucide-react';


export default function BudgetActuals({ budget, onUpdate }) {
    const { toast } = useToast();
    const [activeTab, setActiveTab] = useState('all');
    const [filters, setFilters] = useState({
        type: '',
        dateRange: 'all',
        lineItem: '',
        search: ''
    });
    const [editingActual, setEditingActual] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleAddActual = () => {
        setEditingActual({
            line_item: '',
            actual_type: '',
            date: new Date(), // Initialize date as a Date object
            quantity: 0,
            unit_price: 0,
            total_amount: 0,
            reference_number: '',
            description: ''
        });
    };

    const handleSaveActual = async (actual) => {
        try {
            setLoading(true);
            if (typeof actual.date !== 'string') {
                actual.date = actual.date.toISOString().split('T')[0];
            }

            if (actual.id) {
                await budgetService.updateActual(actual.id, actual);
            } else {
                await budgetService.createActual(actual);
            }
            setEditingActual(null);
            onUpdate();
            toast({
                title: "Success",
                description: "Actual cost saved successfully"

            });
        } catch (error) {
            console.error("Error Saving Actual:", error.response.data);
            toast({
                title: "Error",
                description: "Failed to save actual cost",
                variant: "destructive"
            });
        } finally {
            setLoading(false);
        }
    };


    const handleDeleteActual = async (actualId) => {
        if (!window.confirm('Are you sure you want to delete this actual cost?')) return;

        try {
            setLoading(true);
            await budgetService.deleteActual(actualId);
            onUpdate();
            toast({
                title: "Success",
                description: "Actual cost deleted successfully"
            });
        } catch (error) {
            toast({
                title: "Error",
                description: "Failed to delete actual cost",
                variant: "destructive"
            });
        } finally {
            setLoading(false);
        }
    };

    const getActualTypeIcon = (type) => {
        switch (type) {
            case 'LABOR':
                return <Users className="w-4 h-4" />;
            case 'MATERIAL':
                return <Package className="w-4 h-4" />;
            case 'SUBCONTRACTOR':
                return <Truck className="w-4 h-4" />;
            case 'EQUIPMENT':
                return <LucidePenTool className="w-4 h-4" />;
            default:
                return <DollarSign className="w-4 h-4" />;
        }
    };

    const renderSummaryCards = () => {
        const summaryData = {
            LABOR: {
                title: 'Labor',
                icon: <Users className="w-6 h-6" />,
                color: 'blue'
            },
            MATERIAL: {
                title: 'Material',
                icon: <Package className="w-6 h-6" />,
                color: 'green'
            },
            SUBCONTRACTOR: {
                title: 'Subcontractor',
                icon: <Truck className="w-6 h-6" />,
                color: 'purple'
            },
            EQUIPMENT: {
                title: 'Equipment',
                icon: <LucidePenTool className="w-6 h-6" />,
                color: 'orange'
            }
        };

        return (
            <div className="grid grid-cols-4 gap-4 mb-6">
                {Object.entries(summaryData).map(([type, data]) => {
                    const total = budget.categories
                        .flatMap(cat => cat.line_items)
                        .flatMap(item => item.actuals)
                        .filter(actual => actual.actual_type === type)
                        .reduce((sum, actual) => sum + parseFloat(actual.total_amount), 0);

                    return (
                        <Card key={type}>
                            <CardContent className={`p-4 bg-${data.color}-50`}>
                                <div className={`flex items-center gap-2 text-${data.color}-700 mb-1`}>
                                    {data.icon}
                                    <span className="font-medium">{data.title}</span>
                                </div>
                                <div className={`text-2xl font-bold text-${data.color}-900`}>
                                    {formatCurrency(total)}
                                </div>
                            </CardContent>
                        </Card>
                    );
                })}
            </div>
        );
    };

    const renderActualsTable = () => {
        let filteredActuals = [];

        // Ensure budget and categories exist
        if (budget && Array.isArray(budget.categories)) {
            // Iterate through categories to retain category-level data
            budget.categories.forEach(category => {
                (category.line_items || []).forEach(item => {
                    (item.actuals || []).forEach(actual => {
                        filteredActuals.push({
                            ...actual,
                            line_item_description: item.description,
                            category_name: category.name // Capture category name directly
                        });
                    });
                });
            });
        }

        // Apply filters
        if (filters.type) {
            filteredActuals = filteredActuals.filter(actual => actual.actual_type === filters.type);
        }

        if (filters.lineItem) {
            filteredActuals = filteredActuals.filter(actual => actual.line_item === filters.lineItem);
        }

        if (filters.search) {
            const searchLower = filters.search.toLowerCase();
            filteredActuals = filteredActuals.filter(actual =>
                actual.description.toLowerCase().includes(searchLower) ||
                actual.reference_number.toLowerCase().includes(searchLower) ||
                actual.line_item_description.toLowerCase().includes(searchLower)
            );
        }

        return (
            <Table>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Type</th>
                        <th>Category</th>
                        <th>Line Item</th>
                        <th>Reference</th>
                        <th>Description</th>
                        <th>Quantity</th>
                        <th>Unit Price</th>
                        <th>Total</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredActuals.map(actual => (
                        <tr key={actual.id}>
                            <td>{new Date(actual.date).toLocaleDateString()}</td>
                            <td>
                                <div className="flex items-center gap-2">
                                    {getActualTypeIcon(actual.actual_type)}
                                    {actual.actual_type}
                                </div>
                            </td>
                            <td>{actual.category_name}</td>
                            <td>{actual.line_item_description}</td>
                            <td>{actual.reference_number}</td>
                            <td>{actual.description}</td>
                            <td>{actual.quantity}</td>
                            <td>{formatCurrency(actual.unit_price)}</td>
                            <td>{formatCurrency(actual.total_amount)}</td>
                            <td>
                                <div className="flex gap-2">
                                    <Button
                                        size="sm"
                                        variant="ghost"
                                        onClick={() => setEditingActual(actual)}
                                    >
                                        Edit
                                    </Button>
                                    <Button
                                        size="sm"
                                        variant="ghost"
                                        className="text-red-600"
                                        onClick={() => handleDeleteActual(actual.id)}
                                    >
                                        Delete
                                    </Button>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        );
    };


    return (
        <div className="space-y-6">
            {renderSummaryCards()}

            <Card>
                <CardContent>
                    <div className="flex justify-between items-center mb-4">
                        <div className="flex gap-4">




                            <Input
                                placeholder="Search actuals..."
                                value={filters.search}
                                onChange={(e) => setFilters({ ...filters, search: e.target.value })}
                                className="w-64"
                            />
                        </div>

                        <div className="flex gap-2">
                            <Button variant="outline">
                                <FileDown className="w-4 h-4 mr-2" />
                                Export
                            </Button>
                            <Button onClick={handleAddActual}>
                                <Plus className="w-4 h-4 mr-2" />
                                Add Actual
                            </Button>
                        </div>
                    </div>

                    {renderActualsTable()}
                </CardContent>
            </Card>

            {editingActual && (
                // Add modal for editing/creating actuals
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                    <Card className="w-full max-w-2xl">
                        <CardContent className="p-6">
                            <h3 className="text-lg font-bold mb-4">
                                {editingActual.id ? 'Edit Actual' : 'Add Actual'}
                            </h3>
                            {/* Add form fields for actual details */}
                            <div className="space-y-4">
                                {/* Line Item Selection */}
                                <Select
                                    value={editingActual.line_item}
                                    onValueChange={(value) => setEditingActual({ ...editingActual, line_item: value })}
                                >
                                    <SelectTrigger className="w-full">
                                        <SelectValue placeholder="Select Line Item" />
                                    </SelectTrigger>
                                    <SelectContent>
                                        {budget.categories.flatMap(cat => cat.line_items).map((item) => (
                                            <SelectItem key={item.id} value={item.id.toString()}>
                                                {item.description}
                                            </SelectItem>
                                        ))}
                                    </SelectContent>
                                </Select>

                                {/* Actual Type Selection */}
                                <Select
                                    value={editingActual.actual_type}
                                    onValueChange={(value) => setEditingActual({ ...editingActual, actual_type: value })}
                                >
                                    <SelectTrigger className="w-full">
                                        <SelectValue placeholder="Select Type" />
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectItem value="LABOR">Labor</SelectItem>
                                        <SelectItem value="MATERIAL">Material</SelectItem>
                                        <SelectItem value="SUBCONTRACTOR">Subcontractor</SelectItem>
                                        <SelectItem value="EQUIPMENT">Equipment</SelectItem>
                                        <SelectItem value="OTHER">Other</SelectItem>
                                    </SelectContent>
                                </Select>

                                {/* Date Picker */}
                                <Input
                                    type="date"
                                    value={editingActual.date.toISOString().split('T')[0]}
                                    onChange={(e) => setEditingActual({ ...editingActual, date: new Date(e.target.value) })}
                                    placeholder="Select Date"
                                />

                                {/* Quantity */}
                                <Input
                                    type="number"
                                    value={editingActual.quantity}
                                    onChange={(e) => setEditingActual({ ...editingActual, quantity: e.target.value })}
                                    placeholder="Quantity"
                                />

                                {/* Unit Price */}
                                <Input
                                    type="number"
                                    value={editingActual.unit_price}
                                    onChange={(e) => setEditingActual({ ...editingActual, unit_price: e.target.value })}
                                    placeholder="Unit Price"
                                />

                                {/* Total Amount - Readonly Calculated Field */}
                                <Input
                                    type="text"
                                    value={formatCurrency(editingActual.quantity * editingActual.unit_price)}
                                    readOnly
                                    placeholder="Total Amount"
                                />

                                {/* Reference Number */}
                                <Input
                                    value={editingActual.reference_number}
                                    onChange={(e) => setEditingActual({ ...editingActual, reference_number: e.target.value })}
                                    placeholder="Reference Number"
                                />

                                {/* Description */}
                                <Input
                                    value={editingActual.description}
                                    onChange={(e) => setEditingActual({ ...editingActual, description: e.target.value })}
                                    placeholder="Description"
                                />
                            </div>
                            <div className="flex justify-end gap-2 mt-6">
                                <Button
                                    variant="outline"
                                    onClick={() => setEditingActual(null)}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    onClick={() => handleSaveActual(editingActual)}
                                    disabled={loading}
                                >
                                    {loading ? 'Saving...' : 'Save'}
                                </Button>
                            </div>
                        </CardContent>
                    </Card>
                </div>
            )}
        </div>
    );
}