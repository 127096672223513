import React, { useState, useEffect } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';
import { CheckIcon, LockClosedIcon, Sparkles } from 'lucide-react';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "../ui/cardui";
import { Button } from "../ui/button";
import { Badge } from "../ui/badge";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "../ui/tabs";
import { Alert, AlertDescription } from "../ui/alert";
import { showToast } from '../../utils/toast';
import { useToast } from "../ui/use-toast";

const PaymentForm = ({ plan, billingCycle, onCancel, loading, onSubmit }) => {
    const [isCardValid, setIsCardValid] = useState(false);

    const handleCardChange = (event) => {
        setIsCardValid(event.complete);
    };

    return (
        <Card className="mt-8">
            <CardHeader>
                <CardTitle>Complete Subscription</CardTitle>
                <CardDescription>
                    Start your {plan.name} plan with a 30-day free trial
                </CardDescription>
            </CardHeader>
            <CardContent>
                <div className="space-y-4">
                    <div className="rounded-lg border p-4 bg-muted/50">
                        <h4 className="font-medium mb-2">Plan Summary</h4>
                        <p className="text-sm text-muted-foreground">
                            {plan.name} Plan - {billingCycle === 'monthly' ? 'Monthly' : 'Annual'} billing
                        </p>
                        <p className="text-sm font-medium mt-2">
                            ${billingCycle === 'monthly' ? plan.monthlyPrice : plan.yearlyPrice}/{billingCycle === 'monthly' ? 'mo' : 'yr'}
                        </p>
                    </div>
                    <div>
                        <label className="text-sm font-medium mb-2 block">Card Details</label>
                        <div className="rounded-md border p-3">
                            <CardElement
                                onChange={handleCardChange}
                                options={{
                                    style: {
                                        base: {
                                            fontSize: '16px',
                                            color: 'var(--foreground)',
                                            '::placeholder': {
                                                color: 'var(--muted-foreground)',
                                            },
                                        },
                                    },
                                }}
                            />
                        </div>
                    </div>
                </div>
            </CardContent>
            <CardFooter className="flex justify-between">
                <Button variant="outline" onClick={onCancel}>
                    Cancel
                </Button>
                <Button
                    type="submit"
                    disabled={loading || !isCardValid}
                    onClick={onSubmit}
                >
                    {loading ? (
                        <>
                            <span className="animate-spin mr-2">⚬</span>
                            Processing...
                        </>
                    ) : (
                        'Start Free Trial'
                    )}
                </Button>
            </CardFooter>
        </Card>
    );
};

export default PaymentForm;