import React, { useState, useEffect } from 'react';
import {
    BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ReferenceLine,
    ResponsiveContainer, Rectangle
} from 'recharts';
import { Card, CardContent, CardHeader, CardTitle } from "../../ui/cardui";
import { Button } from "../../ui/button";
import { Badge } from "../../ui/badge";
import { Skeleton } from "../../ui/skeleton";
import { scheduleApi } from '../../../services/scheduleApi';
import { AlertCircle, ChevronDown, ChevronRight } from 'lucide-react';
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "../../ui/select";
import api from '../../../services/api';

interface GanttTask {
    id: string;
    name: string;
    startDate: Date;
    endDate: Date;
    progress: number;
    dependencies: string[];
    isCritical: boolean;
    type: 'task' | 'milestone' | 'summary';
    level: number;
    resources: string[];
    collapsed?: boolean;
    children?: GanttTask[];
}

interface GanttChartProps {
    projectId: string;
}

const CustomBar: React.FC<any> = (props) => {
    const { x, y, width, height, task } = props;
    const radius = 2;

    if (task.type === 'milestone') {
        const diamond = `M ${x + width / 2} ${y} 
                    L ${x + width} ${y + height / 2} 
                    L ${x + width / 2} ${y + height} 
                    L ${x} ${y + height / 2} Z`;
        return (
            <path
                d={diamond}
                fill={task.isCritical ? "#ef4444" : "#3b82f6"}
                stroke={task.isCritical ? "#dc2626" : "#2563eb"}
            />
        );
    }

    return (
        <g>
            <Rectangle
                x={x}
                y={y}
                width={width}
                height={height}
                radius={radius}
                fill={task.isCritical ? "#fee2e2" : "#dbeafe"}
                stroke={task.isCritical ? "#dc2626" : "#2563eb"}
            />
            <Rectangle
                x={x}
                y={y}
                width={width * (task.progress / 100)}
                height={height}
                radius={radius}
                fill={task.isCritical ? "#ef4444" : "#3b82f6"}
            />
        </g>
    );
};

const GanttChart: React.FC<GanttChartProps> = ({ projectId }) => {
    const [tasks, setTasks] = useState<GanttTask[]>([]);
    const [baselineVisible, setBaselineVisible] = useState(false);
    const [baselineTasks, setBaselineTasks] = useState<GanttTask[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [timeScale, setTimeScale] = useState<'day' | 'week' | 'month'>('week');
    const [expandedTasks, setExpandedTasks] = useState<Set<string>>(new Set());

    useEffect(() => {
        const fetchScheduleData = async () => {
            try {
                setLoading(true);
                // Get active schedule first
                const scheduleRes = await api.get(`projects/${projectId}/schedules/?is_active=true`);
                console.log("Schedule: ", scheduleRes);

                if (!scheduleRes.data.results?.length) {
                    throw new Error('No active schedule found');
                }

                const activeSchedule = scheduleRes.data.results[0];

                // Updated to match your activities API pattern
                const [activitiesRes, baselineRes] = await Promise.all([
                    api.get(`projects/${projectId}/activities/`, {
                        params: { schedule: activeSchedule.id }
                    }),
                    scheduleApi.getScheduleBaseline(projectId)
                ]);

                const processedTasks = processScheduleData(activitiesRes.data.results);
                setTasks(processedTasks);

                if (baselineRes.data?.results?.length) {
                    const baselineSchedule = baselineRes.data.results[0];
                    const baselineActivities = await api.get(`projects/${projectId}/activities/`, {
                        params: { schedule: baselineSchedule.id }
                    });
                    const processedBaseline = processScheduleData(baselineActivities.data.results);
                    setBaselineTasks(processedBaseline);
                }
            } catch (err) {
                setError(err.message);
                console.error('Error fetching schedule:', err);
            } finally {
                setLoading(false);
            }
        };

        fetchScheduleData();
    }, [projectId]);

    const processScheduleData = (data: any): GanttTask[] => {
        const processTask = (task: any, level: number = 0): GanttTask => ({
            id: task.id,
            name: task.name,
            startDate: new Date(task.planned_start),
            endDate: new Date(task.planned_finish),
            progress: task.percent_complete || 0,
            dependencies: task.dependencies || [],
            isCritical: task.is_critical || false,
            type: task.activity_type,
            level,
            resources: task.resources || [],
            children: task.subtasks?.map((st: any) => processTask(st, level + 1)) || [],
            // Add these fields for the chart
            start: new Date(task.planned_start).getTime(),
            end: new Date(task.planned_finish).getTime(),
            duration: new Date(task.planned_finish).getTime() - new Date(task.planned_start).getTime()
        });

        // Check if data is an array directly or if it's nested under results
        const activities = Array.isArray(data) ? data : data.results || [];
        return activities.map(task => processTask(task));
    };

    const toggleTaskExpansion = (taskId: string) => {
        const newExpanded = new Set(expandedTasks);
        if (expandedTasks.has(taskId)) {
            newExpanded.delete(taskId);
        } else {
            newExpanded.add(taskId);
        }
        setExpandedTasks(newExpanded);
    };

    const renderTaskRow = (task: GanttTask) => {
        const hasChildren = task.children && task.children.length > 0;
        const isExpanded = expandedTasks.has(task.id);

        return (
            <>
                <div
                    className={`flex items-center p-2 hover:bg-gray-50 ${task.level ? `ml-${task.level * 4}` : ''}`}
                >
                    {hasChildren && (
                        <button
                            onClick={() => toggleTaskExpansion(task.id)}
                            className="p-1 hover:bg-gray-200 rounded mr-2"
                        >
                            {isExpanded ? <ChevronDown className="h-4 w-4" /> : <ChevronRight className="h-4 w-4" />}
                        </button>
                    )}

                    <div className="flex-1 flex items-center space-x-2">
                        <span className="font-medium">{task.name}</span>
                        {task.isCritical && (
                            <Badge variant="destructive">Critical</Badge>
                        )}
                        {task.type === 'milestone' && (
                            <Badge>Milestone</Badge>
                        )}
                    </div>

                    <div className="flex items-center space-x-2">
                        <span className="text-sm text-gray-500">
                            {task.progress}% Complete
                        </span>
                        {task.resources.length > 0 && (
                            <div className="flex -space-x-2">
                                {task.resources.map((resource, i) => (
                                    <div
                                        key={i}
                                        className="h-6 w-6 rounded-full bg-gray-200 flex items-center justify-center text-xs"
                                        title={resource}
                                    >
                                        {resource.charAt(0)}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>

                {isExpanded && task.children?.map(child => (
                    <div key={child.id}>{renderTaskRow(child)}</div>
                ))}
            </>
        );
    };

    const renderDependencyLines = () => {
        // Implementation for dependency lines will go here
        return null;
    };

    if (loading) {
        return <Skeleton className="w-full h-96" />;
    }

    if (error) {
        return (
            <div className="p-4 bg-red-50 text-red-700 rounded-md flex items-center space-x-2">
                <AlertCircle className="h-5 w-5" />
                <span>Error loading schedule: {error}</span>
            </div>
        );
    }

    return (
        <Card className="w-full">
            <CardHeader>
                <div className="flex items-center justify-between">
                    <CardTitle>Project Schedule</CardTitle>
                    <div className="flex items-center space-x-4">
                        <Select
                            value={timeScale}
                            onValueChange={(value: 'day' | 'week' | 'month') => setTimeScale(value)}
                        >
                            <SelectTrigger className="w-32">
                                <SelectValue placeholder="Time Scale" />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectGroup>
                                    <SelectItem value="day">Daily</SelectItem>
                                    <SelectItem value="week">Weekly</SelectItem>
                                    <SelectItem value="month">Monthly</SelectItem>
                                </SelectGroup>
                            </SelectContent>
                        </Select>

                        <Button
                            variant="outline"
                            onClick={() => setBaselineVisible(!baselineVisible)}
                        >
                            {baselineVisible ? 'Hide Baseline' : 'Show Baseline'}
                        </Button>
                    </div>
                </div>
            </CardHeader>

            <CardContent>
                <div className="flex">
                    <div className="w-1/3 border-r">
                        {tasks.map(task => renderTaskRow(task))}
                    </div>

                    <div className="flex-1">
                        <ResponsiveContainer width="100%" height={tasks.length * 40 + 50}>
                            <BarChart
                                data={tasks}
                                layout="vertical"
                                barSize={20}
                                margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                            >
                                <XAxis
                                    type="number"
                                    domain={[
                                        Math.min(...tasks.map(t => t.start)),
                                        Math.max(...tasks.map(t => t.end))
                                    ]}
                                    tickFormatter={(timestamp) => new Date(timestamp).toLocaleDateString()}
                                />
                                <YAxis type="category" dataKey="name" hide />
                                <Tooltip
                                    content={({ payload, label }) => {
                                        if (!payload?.length) return null;
                                        const task = payload[0].payload as GanttTask;
                                        return (
                                            <div className="bg-white p-2 shadow rounded border">
                                                <p className="font-medium">{task.name}</p>
                                                <p>Start: {task.startDate.toLocaleDateString()}</p>
                                                <p>End: {task.endDate.toLocaleDateString()}</p>
                                                <p>Progress: {task.progress}%</p>
                                            </div>
                                        );
                                    }}
                                />
                                <Legend />

                                {baselineVisible && (
                                    <Bar
                                        dataKey="end"
                                        fill="transparent"
                                        stroke="#9ca3af"
                                        strokeDasharray="3 3"
                                        shape={(props) => <CustomBar {...props} task={props.payload} />}
                                    />
                                )}

                                <Bar
                                    dataKey="end"
                                    fill="#3b82f6"
                                    shape={(props) => <CustomBar {...props} task={props.payload} />}
                                />
                            </BarChart>
                        </ResponsiveContainer>

                        <svg
                            className="absolute top-0 left-0 w-full h-full pointer-events-none"
                            style={{ zIndex: 1 }}
                        >
                            {renderDependencyLines()}
                        </svg>
                    </div>
                </div>
            </CardContent>
        </Card>
    );
};

export default GanttChart;