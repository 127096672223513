import React, { useState, useEffect } from 'react';
import api from '../../services/api';
import RiskForm from './RiskForm';
import RiskTable from './RiskTable';
import RiskHeatMap from './RiskHeatMap';
import { Card, CardContent } from '../../components/ui/cardui';
import { Button } from '../../components/ui/button';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../components/ui/tabs';
import { Alert, AlertTitle, AlertDescription } from '../../components/ui/alert';
import { Spinner } from '../../components/ui/spinner';
import { Badge } from '../../components/ui/badge';
import { toast } from 'react-toastify';

const RiskRegister = ({ projectId, projectType = '', projectSize = '', onRiskSelect, configureRequest }) => {
    const [risks, setRisks] = useState([]);
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showForm, setShowForm] = useState(false);
    const [editingRisk, setEditingRisk] = useState(null);
    const [aiPredictions, setAiPredictions] = useState([]);
    const [predictionsLoading, setPredictionsLoading] = useState(false);
    const [activeTab, setActiveTab] = useState('table');
    const [error, setError] = useState(null);


    // Load risks and categories when component mounts
    useEffect(() => {
        const loadData = async () => {
            try {
                setLoading(true);
                setError(null);

                // Load risks for the project using the filter
                const risksResponse = await api.get(`risks/`, {
                    ...configureRequest(),
                    params: { project: projectId }
                });
                setRisks(risksResponse.data.results || risksResponse.data);

                // Load risk categories
                const categoriesResponse = await api.get('risk-categories/', configureRequest());
                setCategories(categoriesResponse.data.results || categoriesResponse.data);

                // Make sure each category has a valid ID
                if (categoriesResponse.data.length === 0) {
                    console.warn('No risk categories found. This may cause issues with the risk form.');
                    // Add a default category if none exist
                    setCategories([
                        { id: "1", name: "General" },
                        { id: "2", name: "Technical" },
                        { id: "3", name: "Schedule" },
                        { id: "4", name: "Cost" },
                        { id: "5", name: "Quality" }
                    ]);
                }
            } catch (error) {
                console.error('Error loading risk data:', error);
                setError('Failed to load risk data. Please try again later.');
                toast.error('Failed to load risk data');

                // Provide fallback categories if the API call fails
                setCategories([
                    { id: "1", name: "General" },
                    { id: "2", name: "Technical" },
                    { id: "3", name: "Schedule" },
                    { id: "4", name: "Cost" },
                    { id: "5", name: "Quality" }
                ]);
            } finally {
                setLoading(false);
            }
        };

        if (projectId) {
            loadData();
        }
    }, [projectId, configureRequest]);

    const handleAddRisk = async (riskData) => {
        try {
            // Ensure project ID is included
            const payload = {
                ...riskData,
                project: projectId
            };

            const newRisk = await api.post(`risks/`, payload, configureRequest());

            setRisks([...risks, newRisk.data]);
            setShowForm(false);
            toast.success('Risk added successfully');
        } catch (error) {
            console.error('Error adding risk:', error);
            toast.error(`Failed to add risk: ${error.response?.data?.detail || 'Unknown error'}`);
        }
    };

    const handleUpdateRisk = async (riskData) => {
        try {
            // Ensure project ID is included
            const payload = {
                ...riskData,
                project: projectId
            };

            const updatedRisk = await api.put(`risks/${riskData.id}/`, payload, configureRequest());
            setRisks(risks.map(risk => risk.id === updatedRisk.data.id ? updatedRisk.data : risk));
            setEditingRisk(null);
            setShowForm(false);
            toast.success('Risk updated successfully');
        } catch (error) {
            console.error('Error updating risk:', error);
            toast.error(`Failed to update risk: ${error.response?.data?.detail || 'Unknown error'}`);
        }
    };

    const handleDeleteRisk = async (riskId) => {
        if (window.confirm('Are you sure you want to delete this risk?')) {
            try {
                await api.delete(`risks/${riskId}/`, configureRequest());
                setRisks(risks.filter(risk => risk.id !== riskId));
                toast.success('Risk deleted successfully');
            } catch (error) {
                console.error('Error deleting risk:', error);
                toast.error('Failed to delete risk');
            }
        }
    };

    const handleEditRisk = (risk) => {
        setEditingRisk(risk);
        setShowForm(true);
    };

    const handlePredictRisks = async () => {
        try {
            setPredictionsLoading(true);
            const predictionsResponse = await api.post(`risks/predict_risks/`, {
                project_id: projectId,
                project_type: projectType,
                project_size: projectSize
            }, configureRequest());

            setAiPredictions(predictionsResponse.data);
            toast.success('AI risk predictions generated');
        } catch (error) {
            console.error('Error predicting risks:', error);
            toast.error('Failed to generate risk predictions');
        } finally {
            setPredictionsLoading(false);
        }
    };

    const handleAddPredictedRisk = async (prediction) => {
        try {
            const newRisk = await api.post(`risks/`, {
                project: projectId,
                title: prediction.title,
                description: prediction.description,
                category: prediction.category_id,
                severity: prediction.recommended_severity,
                probability: prediction.recommended_probability,
                impact: `AI predicted impact based on ${prediction.frequency} occurrences in ${prediction.total_projects} similar projects.`,
                ai_suggested: true,
                ai_explanation: `This risk was predicted by AI based on historical data from similar projects. It occurred in ${prediction.frequency} out of ${prediction.total_projects} similar projects.`
            }, configureRequest());

            setRisks([...risks, newRisk.data]);
            // Remove from predictions
            setAiPredictions(aiPredictions.filter(p => p.title !== prediction.title));
            toast.success('Predicted risk added to register');
        } catch (error) {
            console.error('Error adding predicted risk:', error);
            toast.error('Failed to add predicted risk');
        }
    };

    if (loading) {
        return (
            <div className="flex justify-center items-center py-12">
                <Spinner size="lg" />
            </div>
        );
    }

    if (error) {
        return (
            <Alert>
                <AlertDescription>{error}</AlertDescription>
            </Alert>
        );
    }

    return (
        <div className="space-y-4">
            <div className="flex justify-between items-center">
                <h2 className="text-2xl font-bold">Risk Register</h2>
                <div className="space-x-2">
                    <Button
                        variant={showForm ? "outline" : "default"}
                        onClick={() => {
                            setEditingRisk(null);
                            setShowForm(!showForm);
                        }}
                    >
                        {showForm ? 'Cancel' : 'Add Risk'}
                    </Button>
                    <Button
                        variant="secondary"
                        onClick={handlePredictRisks}
                        disabled={predictionsLoading || !projectType || !projectSize}
                    >
                        {predictionsLoading ? (
                            <div className="flex items-center">
                                <Spinner className="mr-2" size="sm" />
                                Generating...
                            </div>
                        ) : (
                            'AI Risk Prediction'
                        )}
                    </Button>
                </div>
            </div>

            {showForm && (
                <Card className="mb-4">
                    <CardContent className="pt-6">
                        <RiskForm
                            categories={categories}
                            projectId={projectId}
                            onSubmit={editingRisk ? handleUpdateRisk : handleAddRisk}
                            initialData={editingRisk}
                            onCancel={() => {
                                setShowForm(false);
                                setEditingRisk(null);
                            }}
                            configureRequest={configureRequest}
                        />
                    </CardContent>
                </Card>
            )}

            {aiPredictions.length > 0 && (
                <Card className="mb-4">
                    <CardContent className="pt-6">
                        <h3 className="text-lg font-semibold mb-2">AI Predicted Risks</h3>
                        <div className="overflow-x-auto">
                            <table className="w-full border-collapse">
                                <thead>
                                    <tr className="bg-muted">
                                        <th className="p-2 text-left border">Title</th>
                                        <th className="p-2 text-left border">Category</th>
                                        <th className="p-2 text-left border">Severity</th>
                                        <th className="p-2 text-left border">Probability</th>
                                        <th className="p-2 text-left border">Score</th>
                                        <th className="p-2 text-left border">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {aiPredictions.map((prediction, index) => (
                                        <tr key={index} className="border-b hover:bg-muted/50">
                                            <td className="p-2 border">{prediction.title}</td>
                                            <td className="p-2 border">{prediction.category_name}</td>
                                            <td className="p-2 border">{prediction.recommended_severity}</td>
                                            <td className="p-2 border">{prediction.recommended_probability}</td>
                                            <td className="p-2 border">{prediction.score?.toFixed(2) || "N/A"}</td>
                                            <td className="p-2 border">
                                                <Button
                                                    variant="default"
                                                    size="sm"
                                                    onClick={() => handleAddPredictedRisk(prediction)}
                                                >
                                                    Add to Register
                                                </Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </CardContent>
                </Card>
            )}

            <Tabs value={activeTab} onValueChange={setActiveTab}>
                <TabsList className="grid w-full grid-cols-2">
                    <TabsTrigger value="table">Risk Table</TabsTrigger>
                    <TabsTrigger value="heatmap">Risk Heat Map</TabsTrigger>
                </TabsList>
                <TabsContent value="table">
                    <RiskTable
                        risks={risks}
                        onEdit={handleEditRisk}
                        onDelete={handleDeleteRisk}
                        onSelect={onRiskSelect}
                    />
                </TabsContent>
                <TabsContent value="heatmap">
                    <RiskHeatMap
                        risks={risks}
                        onRiskClick={handleEditRisk}
                    />
                </TabsContent>
            </Tabs>
        </div>
    );
};

export default RiskRegister;