import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardTitle, CardContent, CardFooter } from '../../components/ui/cardui';
import { Button } from '../../components/ui/button';
import { Input } from '../../components/ui/input';
import { Textarea } from '../../components/ui/textarea';
import { Label } from '../../components/ui/label';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../components/ui/select';
import { Badge } from '../../components/ui/badge';
import { Alert, AlertTitle, AlertDescription } from '../../components/ui/alert';
import { Spinner } from '../../components/ui/spinner';
import { fetchRiskById, createRiskResponse, updateRiskResponse } from '../../services/riskApi';
import { toast } from 'react-toastify';

const RiskResponsePlan = ({ riskId, onResponseAdded }) => {
    const [risk, setRisk] = useState(null);
    const [responses, setResponses] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showForm, setShowForm] = useState(false);
    const [editingResponse, setEditingResponse] = useState(null);
    const [formData, setFormData] = useState({
        action: '',
        strategy: 'MITIGATE',
        status: 'PLANNED',
        assigned_to: '',
        due_date: ''
    });

    useEffect(() => {
        const loadRiskData = async () => {
            try {
                setLoading(true);
                const riskData = await fetchRiskById(riskId);
                setRisk(riskData);
                setResponses(riskData.responses || []);
            } catch (error) {
                console.error('Error loading risk data:', error);
                toast.error('Failed to load risk data');
            } finally {
                setLoading(false);
            }
        };

        if (riskId) {
            loadRiskData();
        }
    }, [riskId]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSelectChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleAddResponse = async (e) => {
        e.preventDefault();

        try {
            const responseData = {
                ...formData,
                risk: riskId
            };

            let result;
            if (editingResponse) {
                result = await updateRiskResponse(editingResponse.id, responseData);
                setResponses(responses.map(resp => resp.id === result.id ? result : resp));
                toast.success('Response plan updated successfully');
            } else {
                result = await createRiskResponse(responseData);
                setResponses([...responses, result]);
                toast.success('Response plan added successfully');
            }

            // Reset form
            setFormData({
                action: '',
                strategy: 'MITIGATE',
                status: 'PLANNED',
                assigned_to: '',
                due_date: ''
            });
            setShowForm(false);
            setEditingResponse(null);

            if (onResponseAdded) {
                onResponseAdded(result);
            }
        } catch (error) {
            console.error('Error saving response plan:', error);
            toast.error('Failed to save response plan');
        }
    };

    const handleEditResponse = (response) => {
        setFormData({
            action: response.action,
            strategy: response.strategy,
            status: response.status,
            assigned_to: response.assigned_to?.id || response.assigned_to || '',
            due_date: response.due_date || ''
        });
        setEditingResponse(response);
        setShowForm(true);
    };

    if (loading) {
        return (
            <div className="flex justify-center items-center py-8">
                <Spinner size="lg" />
            </div>
        );
    }

    if (!risk) {
        return (
            <Card className="mb-4">
                <CardContent className="pt-6">
                    <Alert variant="warning">
                        <AlertDescription>
                            Risk data not found or could not be loaded.
                        </AlertDescription>
                    </Alert>
                </CardContent>
            </Card>
        );
    }

    const getStatusVariant = (status) => {
        switch (status) {
            case 'PLANNED': return 'default';
            case 'IN_PROGRESS': return 'primary';
            case 'COMPLETED': return 'success';
            case 'CANCELLED': return 'secondary';
            default: return 'outline';
        }
    };

    const getStrategyVariant = (strategy) => {
        switch (strategy) {
            case 'AVOID': return 'destructive';
            case 'MITIGATE': return 'warning';
            case 'TRANSFER': return 'default';
            case 'ACCEPT': return 'success';
            default: return 'secondary';
        }
    };

    return (
        <Card className="mb-4">
            <CardHeader>
                <div className="flex justify-between items-center">
                    <CardTitle>Risk Response Plan</CardTitle>
                    <Button
                        variant={showForm ? "secondary" : "default"}
                        size="sm"
                        onClick={() => {
                            setEditingResponse(null);
                            setFormData({
                                action: '',
                                strategy: 'MITIGATE',
                                status: 'PLANNED',
                                assigned_to: '',
                                due_date: ''
                            });
                            setShowForm(!showForm);
                        }}
                    >
                        {showForm ? 'Cancel' : 'Add Response'}
                    </Button>
                </div>
            </CardHeader>
            <CardContent>
                <div className="mb-6 space-y-2">
                    <h3 className="text-lg font-medium">Risk: {risk.title}</h3>
                    <p><strong>Severity:</strong> {risk.severity}</p>
                    <p><strong>Probability:</strong> {risk.probability}</p>
                    <p><strong>Description:</strong> {risk.description}</p>
                </div>

                {showForm && (
                    <Card className="mb-6 border border-muted">
                        <CardContent className="pt-6">
                            <h4 className="text-lg font-medium mb-4">{editingResponse ? 'Edit Response Plan' : 'Add Response Plan'}</h4>
                            <form onSubmit={handleAddResponse} className="space-y-4">
                                <div>
                                    <Label htmlFor="action">Action Plan*</Label>
                                    <Textarea
                                        id="action"
                                        name="action"
                                        value={formData.action}
                                        onChange={handleChange}
                                        rows={3}
                                        required
                                        placeholder="Describe the actions to be taken..."
                                        className="mt-1"
                                    />
                                </div>

                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                    <div>
                                        <Label htmlFor="strategy">Response Strategy*</Label>
                                        <Select
                                            value={formData.strategy}
                                            onValueChange={(value) => handleSelectChange("strategy", value)}
                                        >
                                            <SelectTrigger id="strategy" className="w-full mt-1">
                                                <SelectValue placeholder="Select strategy" />
                                            </SelectTrigger>
                                            <SelectContent>
                                                <SelectItem value="AVOID">Avoid</SelectItem>
                                                <SelectItem value="MITIGATE">Mitigate</SelectItem>
                                                <SelectItem value="TRANSFER">Transfer</SelectItem>
                                                <SelectItem value="ACCEPT">Accept</SelectItem>
                                            </SelectContent>
                                        </Select>
                                    </div>
                                    <div>
                                        <Label htmlFor="status">Status</Label>
                                        <Select
                                            value={formData.status}
                                            onValueChange={(value) => handleSelectChange("status", value)}
                                        >
                                            <SelectTrigger id="status" className="w-full mt-1">
                                                <SelectValue placeholder="Select status" />
                                            </SelectTrigger>
                                            <SelectContent>
                                                <SelectItem value="PLANNED">Planned</SelectItem>
                                                <SelectItem value="IN_PROGRESS">In Progress</SelectItem>
                                                <SelectItem value="COMPLETED">Completed</SelectItem>
                                                <SelectItem value="CANCELLED">Cancelled</SelectItem>
                                            </SelectContent>
                                        </Select>
                                    </div>
                                </div>

                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                    <div>
                                        <Label htmlFor="assigned_to">Assigned To (Optional)</Label>
                                        <Input
                                            id="assigned_to"
                                            name="assigned_to"
                                            value={formData.assigned_to}
                                            onChange={handleChange}
                                            placeholder="User ID of assignee"
                                            className="mt-1"
                                        />
                                    </div>
                                    <div>
                                        <Label htmlFor="due_date">Due Date (Optional)</Label>
                                        <Input
                                            id="due_date"
                                            type="date"
                                            name="due_date"
                                            value={formData.due_date}
                                            onChange={handleChange}
                                            className="mt-1"
                                        />
                                    </div>
                                </div>

                                <div className="flex justify-end space-x-2">
                                    <Button
                                        type="button"
                                        variant="outline"
                                        onClick={() => setShowForm(false)}
                                    >
                                        Cancel
                                    </Button>
                                    <Button type="submit">
                                        {editingResponse ? 'Update' : 'Add'} Response
                                    </Button>
                                </div>
                            </form>
                        </CardContent>
                    </Card>
                )}

                {responses.length === 0 ? (
                    <Alert className="my-4">
                        <AlertDescription>
                            No response plans have been created for this risk yet.
                        </AlertDescription>
                    </Alert>
                ) : (
                    <div className="overflow-x-auto">
                        <table className="w-full border-collapse">
                            <thead>
                                <tr className="bg-muted">
                                    <th className="p-2 text-left border">Action</th>
                                    <th className="p-2 text-left border">Strategy</th>
                                    <th className="p-2 text-left border">Status</th>
                                    <th className="p-2 text-left border">Assigned To</th>
                                    <th className="p-2 text-left border">Due Date</th>
                                    <th className="p-2 text-left border">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {responses.map((response) => (
                                    <tr key={response.id} className="border-b hover:bg-muted/50">
                                        <td className="p-2 border">{response.action}</td>
                                        <td className="p-2 border">
                                            <Badge variant={getStrategyVariant(response.strategy)}>
                                                {response.strategy}
                                            </Badge>
                                        </td>
                                        <td className="p-2 border">
                                            <Badge variant={getStatusVariant(response.status)}>
                                                {response.status}
                                            </Badge>
                                        </td>
                                        <td className="p-2 border">
                                            {response.assigned_to_details ?
                                                `${response.assigned_to_details.first_name} ${response.assigned_to_details.last_name}` :
                                                'Unassigned'}
                                        </td>
                                        <td className="p-2 border">{response.due_date || 'Not set'}</td>
                                        <td className="p-2 border">
                                            <Button
                                                variant="outline"
                                                size="sm"
                                                onClick={() => handleEditResponse(response)}
                                            >
                                                Edit
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </CardContent>
        </Card>
    );
};

export default RiskResponsePlan;