import axios from 'axios';

const getBaseUrl = () => {

  // Check if running in development/local environment
  const isDevelopment = !process.env.REACT_APP_ENV || process.env.REACT_APP_ENV === 'local' || process.env.REACT_APP_ENV === 'development';

  if (isDevelopment) {
    // Ensure HTTP protocol for local development
    return 'http://localhost:8000';
  }


  switch (process.env.REACT_APP_ENV) {
    case 'production':
      return 'https://buildifypro-backend-ai-44118cd22974.herokuapp.com';
    case 'staging':
      return 'https://dev-bpro-backend-ai-c35760d7e8f4.herokuapp.com';
    default:
      return 'http://localhost:8000';
  }
};

const api = axios.create({
  baseURL: `${getBaseUrl()}/api/`,
});



api.interceptors.request.use(
  (config) => {

    // Get auth token if it exists
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    // Get tenant ID from local storage
    /*  const tenantId = localStorage.getItem('tenantId');
     if (tenantId) {
       config.headers['X-Tenant-ID'] = tenantId;
     } */
    const tenantIdentifier = localStorage.getItem('tenantIdentifier');
    if (tenantIdentifier) {
      config.headers['X-Tenant-ID'] = tenantIdentifier;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

export const fetchSubscriptionPlans = async () => {
  const response = await api.get('subscription-plans/');
  return response.data;
};



api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response?.status === 401 && error.response?.data?.code === 'token_not_valid' && !originalRequest._retry) {
      originalRequest._retry = true;
      const refreshToken = localStorage.getItem('refreshToken');

      if (refreshToken) {
        try {
          const response = await axios.post(`${getBaseUrl()}/api/token/refresh/`, { refresh: refreshToken });
          const newAccessToken = response.data.access;

          localStorage.setItem('token', newAccessToken);
          api.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;
          originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;

          return api(originalRequest); // Retry original request with new token
        } catch (refreshError) {
          localStorage.removeItem('token');
          localStorage.removeItem('refreshToken');
          window.location.href = '/login';
        }
      } else {
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        window.location.href = '/login';
      }
    }
    return Promise.reject(error);
  }
);

export default api;

