import React from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '../components/ui/dialog';
import { Button } from '../components/ui/button';
import { Form, FormField, FormItem, FormLabel } from '../components/ui/form';
import { Input } from '../components/ui/input';
import { Textarea } from '../components/ui/textarea';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../components/ui/select';
import { useForm } from 'react-hook-form';
import safetyApi from '../services/safetyService';

const SafetyAuditEditModal = ({ audit, isOpen, onClose, onUpdate }) => {
    const form = useForm({
        defaultValues: {
            location: audit?.location || '',
            findings: audit?.findings || '',
            recommendations: audit?.recommendations || '',
            action_items: audit?.action_items || '',
            status: audit?.status || 'scheduled',
            priority: audit?.priority || 'medium'
        }
    });

    const handleSubmit = async (data) => {
        try {
            const response = await safetyApi.updateSafetyAudit(audit.id, data);
            onUpdate(response.data);
            onClose();
        } catch (error) {
            console.error('Error updating audit:', error);
        }
    };

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className="sm:max-w-[600px]">
                <DialogHeader>
                    <DialogTitle>Edit Safety Audit</DialogTitle>
                </DialogHeader>

                <Form {...form}>
                    <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-4">
                        <FormField
                            control={form.control}
                            name="location"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Location</FormLabel>
                                    <Input {...field} />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="status"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Status</FormLabel>
                                    <Select onValueChange={field.onChange} value={field.value}>
                                        <SelectTrigger>
                                            <SelectValue />
                                        </SelectTrigger>
                                        <SelectContent>
                                            <SelectItem value="scheduled">Scheduled</SelectItem>
                                            <SelectItem value="in-progress">In Progress</SelectItem>
                                            <SelectItem value="completed">Completed</SelectItem>
                                            <SelectItem value="cancelled">Cancelled</SelectItem>
                                        </SelectContent>
                                    </Select>
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="findings"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Findings</FormLabel>
                                    <Textarea {...field} />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="recommendations"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Recommendations</FormLabel>
                                    <Textarea {...field} />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="action_items"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Action Items</FormLabel>
                                    <Textarea {...field} />
                                </FormItem>
                            )}
                        />

                        <div className="flex justify-end space-x-2">
                            <Button type="button" variant="outline" onClick={onClose}>
                                Cancel
                            </Button>
                            <Button type="submit">
                                Save Changes
                            </Button>
                        </div>
                    </form>
                </Form>
            </DialogContent>
        </Dialog>
    );
};

export default SafetyAuditEditModal;