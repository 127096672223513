import { Navigate, useLocation } from 'react-router-dom';
import { useFeatureAccess, FEATURES } from '../contexts/FeatureAccessContext';
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';


const featureRouteMap = {
    [FEATURES.PROJECT_MANAGEMENT]: {
        base: ['/projects'],
        nested: [
            '/projects/:projectId',
            '/projects/:projectId/settings',
            '/projects/:projectId/tasks',
            '/projects/:projectId/timeline',
            '/projects/:projectId/resources',
            '/projects/:projectId/risks',
            '/projects/:projectId/budgets',
            '/projects/:projectId/budgets/new',
            '/projects/:projectId/budgets/:budgetId/basic',
            '/projects/:projectId/budgets/:budgetId/advanced',
            '/docs/project',
            '/supplier',
            '/supplier/:supplierId',
            '/supplier/categories',
            '/supplier/:supplierId/documents',
            '/tasks'
        ]
    },
    [FEATURES.SAFETY_MANAGEMENT]: {
        base: ['/safety'],
        nested: [
            '/safety/:projectId',
            '/docs/safety',
            '/safety/schedule',
            '/projects/:projectId/quality-issues',
            '/projects/:projectId/inspections',
            '/projects/:projectId/safety-training'
        ]
    },
    [FEATURES.BID_MANAGEMENT]: {
        base: [
            '/bid-management',
            '/my-bids',
            '/bids-rfi'
        ],
        nested: [
            '/bid-management/new',
            '/bid-management/:id',
            '/bid-management/:id/compare',
            '/bid-management/:bidId/responses/:responseId',
            '/my-bids/:bidId/submit',
            '/my-bids/:id',
            '/docs/bid',
            '/supplier',
            '/supplier/:supplierId',
            '/supplier/categories',
            '/supplier/:supplierId/documents'
        ]
    },
    [FEATURES.ALL_FEATURES]: {
        base: [
            '/dashboard',
            '/projects',
            '/safety',
            '/bid-management',
            '/communication',
            '/client-management',
            '/contractor-management',
            '/docs',
            '/supplier',
            '/supplier/:supplierId',
            '/supplier/categories',
            '/supplier/:supplierId/documents'
        ],
        nested: [
            '/projects/:projectId/*',
            '/safety/:projectId',
            '/bid-management/*',
            '/docs/*'
        ]
    }
};

// Common routes accessible to all features
const commonRoutes = [
    '/settings',
    '/profile',
    '/subscription',
    '/payment/success',
    '/onboarding'
];

const safetyRoleRoutes = [
    '/projects/:projectId/quality-issues',
    '/projects/:projectId/inspections'
];

export const RouteGuard = ({ children, allowedRoles }) => {
    const { userRole, featureType, loading, isSafetyRole } = useFeatureAccess();
    const location = useLocation();
    const currentPath = location.pathname;

    // Check for pending onboarding
    const pendingOnboarding = localStorage.getItem('pendingOnboarding');
    const isOnboardingRoute = currentPath === '/onboarding';

    if (loading) {
        return <div className="flex justify-center items-center h-screen">Loading...</div>;
    }


    if (pendingOnboarding === 'true' && !isOnboardingRoute && currentPath !== '/payment/success') {
        return <Navigate to="/onboarding" replace />;
    }


    if (!userRole) {
        return <Navigate to="/login" replace />;
    }


    if (allowedRoles && !allowedRoles.includes(userRole)) {

        const isSafetyRoleUser = isSafetyRole ? isSafetyRole(userRole) : false;

        const isQualityRoute = safetyRoleRoutes.some(route => {

            const pattern = route.replace(/:\w+/g, '[^/]+');
            return new RegExp(`^${pattern}$`).test(currentPath);
        });


        if (isSafetyRoleUser && isQualityRoute) {

        } else {
            return <Navigate to="/unauthorized" replace />;
        }
    }

    // Check if it's a common route
    if (commonRoutes.some(route => currentPath.startsWith(route))) {
        return (
            <div className="flex h-screen bg-snowfall font-gilroy">
                <Sidebar />
                <div className="flex-1 flex flex-col overflow-hidden">
                    <Header />
                    <main className="flex-1 overflow-x-hidden overflow-y-auto bg-white">
                        <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8">
                            {children}
                        </div>
                    </main>
                </div>
            </div>
        );
    }

    const isSafetyRoleUser = isSafetyRole ? isSafetyRole(userRole) : false;
    if (isSafetyRoleUser) {
        const safetyFeatureRoutes = featureRouteMap[FEATURES.SAFETY_MANAGEMENT];

        // Check if the current path is a safety route
        const isSafetyRoute = safetyFeatureRoutes.base.some(route =>
            currentPath === route || currentPath.startsWith(route + '/')
        ) ||
            safetyFeatureRoutes.nested.some(route => {
                const pattern = route.replace(/:\w+/g, '[^/]+');
                return new RegExp(`^${pattern}$`).test(currentPath);
            });

        if (isSafetyRoute) {
            return (
                <div className="flex h-screen bg-snowfall font-gilroy">
                    <Sidebar />
                    <div className="flex-1 flex flex-col overflow-hidden">
                        <Header />
                        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-white">
                            <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8">
                                {children}
                            </div>
                        </main>
                    </div>
                </div>
            );
        }
    }

    // Get feature routes
    const featureRoutes = featureRouteMap[featureType];
    if (!featureRoutes) {
        return <Navigate to="/unauthorized" replace />;
    }

    // Check if path matches base routes
    const hasBaseAccess = featureRoutes.base.some(route =>
        currentPath === route || currentPath.startsWith(route + '/')
    );

    // Check if path matches nested routes
    const hasNestedAccess = featureRoutes.nested.some(route => {
        // Convert route pattern to regex
        const pattern = route.replace(/:\w+/g, '[^/]+');
        return new RegExp(`^${pattern}$`).test(currentPath);
    });

    // If no access, redirect
    if (!hasBaseAccess && !hasNestedAccess) {
        return <Navigate to="/unauthorized" replace />;
    }

    // If authorized and route is permitted, render layout with children
    return (
        <div className="flex h-screen bg-snowfall font-gilroy">
            <Sidebar />
            <div className="flex-1 flex flex-col overflow-hidden">
                <Header />
                <main className="flex-1 overflow-x-hidden overflow-y-auto bg-white">
                    <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8">
                        {children}
                    </div>
                </main>
            </div>
        </div>
    );
};

export default RouteGuard;