import React from 'react';
import { Button } from "../ui/button";
import { useToast } from "../ui/use-toast";
import { Shield, Lock, AlertTriangle } from "lucide-react";
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
} from "../ui/alert-dialog";

export default function BudgetStatusControl({
    status,
    onStatusChange,
    isEditing,
    disabled = false
}) {
    const { toast } = useToast();
    const [showCloseDialog, setShowCloseDialog] = React.useState(false);

    const handleStatusChange = async (newStatus) => {
        try {
            await onStatusChange(newStatus);
            toast({
                title: "Success",
                description: `Budget ${newStatus === 'CLOSED' ? 'closed' : 'activated'} successfully`
            });
        } catch (error) {
            toast({
                title: "Error",
                description: "Failed to update budget status",
                variant: "destructive"
            });
        }
    };

    if (status === 'CLOSED') {
        return (
            <div className="flex items-center gap-2">
                <Lock className="w-4 h-4 text-gray-500" />
                <span className="text-gray-500 font-medium">Budget Closed</span>
            </div>
        );
    }

    return (
        <>
            <div className="flex items-center gap-2">
                {status === 'ACTIVE' ? (
                    <Button
                        onClick={() => setShowCloseDialog(true)}
                        variant="outline"
                        className="border-red-200 text-red-600 hover:bg-red-50"
                        disabled={disabled || isEditing}
                    >
                        <Lock className="w-4 h-4 mr-2" />
                        Close Budget
                    </Button>
                ) : (
                    <Button
                        onClick={() => handleStatusChange('ACTIVE')}
                        variant="outline"
                        className="border-green-200 text-green-600 hover:bg-green-50"
                        disabled={disabled}
                    >
                        <Shield className="w-4 h-4 mr-2" />
                        Activate Budget
                    </Button>
                )}
            </div>

            <AlertDialog open={showCloseDialog} onOpenChange={setShowCloseDialog}>
                <AlertDialogContent>
                    <AlertDialogHeader>
                        <AlertDialogTitle>Close Budget?</AlertDialogTitle>
                        <AlertDialogDescription>
                            <div className="space-y-2">
                                <p>Are you sure you want to close this budget? This action will:</p>
                                <ul className="list-disc pl-4 space-y-1">
                                    <li>Prevent any further modifications</li>
                                    <li>Lock all budget items</li>
                                    <li>Finalize all calculations</li>
                                </ul>
                                <p className="font-medium text-amber-600 flex items-center gap-2 mt-4">
                                    <AlertTriangle className="w-4 h-4" />
                                    This action cannot be undone without admin approval
                                </p>
                            </div>
                        </AlertDialogDescription>
                    </AlertDialogHeader>
                    <AlertDialogFooter>
                        <AlertDialogCancel>Cancel</AlertDialogCancel>
                        <AlertDialogAction
                            onClick={() => handleStatusChange('CLOSED')}
                            className="bg-red-600 hover:bg-red-700"
                        >
                            Close Budget
                        </AlertDialogAction>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
        </>
    );
}