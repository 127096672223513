import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import {
    Badge
} from "../../ui/badge";
import {
    Label
} from "../../ui/label";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "../../ui/select";
import {
    Button
} from "../../ui/button";
import { CheckCircle2 } from 'lucide-react';
import { showToast } from '../../../utils/toast';
import api from '../../../services/api';
import secureLocalStorage from "react-secure-storage";

const QualityIssueDetails = ({ issueId, projectId, onClose }) => {
    const [issue, setIssue] = useState(null);
    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState([]);
    const [selectedAssignee, setSelectedAssignee] = useState(null);

    useEffect(() => {
        fetchIssueDetails();
        fetchProjectUsers();
    }, [issueId]);

    const getConfig = () => {
        const tenantId = localStorage.getItem('tenantIdentifier') ||
            secureLocalStorage.getItem('currentTenantId');

        return {
            headers: {
                'X-Tenant-ID': tenantId || '',
                'Content-Type': 'application/json'
            }
        };
    };

    const getSeverityBadgeColor = (severity) => {
        switch (severity) {
            case 'critical':
                return 'bg-red-100 text-red-800';
            case 'high':
                return 'bg-orange-100 text-orange-800';
            case 'medium':
                return 'bg-yellow-100 text-yellow-800';
            case 'low':
                return 'bg-green-100 text-green-800';
            default:
                return 'bg-gray-100 text-gray-800';
        }
    };

    const getStatusBadgeColor = (status) => {
        switch (status) {
            case 'identified':
                return 'bg-blue-100 text-blue-800';
            case 'under_review':
                return 'bg-purple-100 text-purple-800';
            case 'remediation_planned':
                return 'bg-yellow-100 text-yellow-800';
            case 'in_remediation':
                return 'bg-orange-100 text-orange-800';
            case 'resolved':
                return 'bg-green-100 text-green-800';
            case 'verified':
                return 'bg-emerald-100 text-emerald-800';
            default:
                return 'bg-gray-100 text-gray-800';
        }
    };


    const fetchIssueDetails = async () => {
        try {
            setLoading(true);
            const response = await api.get(`projects/${projectId}/quality-issues/${issueId}/details/`, getConfig());
            setIssue(response.data);
            setLoading(false);
        } catch (error) {
            console.error('Failed to fetch issue details:', error);
            showToast.error('Failed to load issue details');
            setLoading(false);
        }
    };

    const fetchProjectUsers = async () => {
        try {
            const response = await api.get(`projects/${projectId}/team/`, getConfig());
            setUsers(response.data);
        } catch (error) {
            console.error('Failed to fetch project users:', error);
        }
    };

    const handleAssign = async () => {
        if (!selectedAssignee) return;

        try {
            await api.post(
                `projects/${projectId}/quality-issues/${issueId}/assign/`,
                { assignee_id: selectedAssignee },
                getConfig()
            );
            showToast.success('Issue assigned successfully');
            fetchIssueDetails();
        } catch (error) {
            console.error('Failed to assign issue:', error);
            showToast.error('Failed to assign issue');
        }
    };

    const handleResolve = async (formData) => {
        try {
            await api.post(
                `projects/${projectId}/quality-issues/${issueId}/resolve/`,
                formData,
                getConfig()
            );
            showToast.success('Issue resolved successfully');
            fetchIssueDetails();
        } catch (error) {
            console.error('Failed to resolve issue:', error);
            showToast.error('Failed to resolve issue');
        }
    };

    if (loading) {
        return (
            <div className="p-6 text-center">
                <p>Loading issue details...</p>
            </div>
        );
    }

    if (!issue) {
        return (
            <div className="p-6 text-center">
                <p>Failed to load issue details</p>
                <Button onClick={onClose} className="mt-4">Close</Button>
            </div>
        );
    }

    return (
        <div className="p-3 sm:p-6 space-y-4 sm:space-y-6">
            <div className="space-y-4">
                <div className="flex flex-col sm:flex-row justify-between items-start gap-3">
                    <div>
                        <h3 className="text-xl font-semibold break-words">{issue.issue.description}</h3>
                        <div className="flex flex-wrap mt-2 gap-2">
                            <Badge className={getSeverityBadgeColor(issue.issue.severity)}>
                                {issue.issue.severity_display}
                            </Badge>
                            <Badge className={getStatusBadgeColor(issue.issue.status)}>
                                {issue.issue.status_display}
                            </Badge>
                        </div>
                    </div>

                    {issue.issue.status !== 'resolved' && issue.issue.status !== 'verified' && (
                        <Button
                            variant="outline"
                            size="sm"
                            className="whitespace-nowrap"
                            onClick={() => {
                                // Show resolve form
                            }}
                        >
                            <CheckCircle2 className="w-4 h-4 mr-1" />
                            Resolve
                        </Button>
                    )}
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-4">
                    <div>
                        <p className="text-sm text-gray-500">Identified By</p>
                        <p className="font-medium">{issue.issue.identified_by_name || 'Unknown'}</p>
                    </div>
                    <div>
                        <p className="text-sm text-gray-500">Identified Date</p>
                        <p className="font-medium">{format(new Date(issue.issue.identified_date), 'MMM d, yyyy')}</p>
                    </div>
                    <div>
                        <p className="text-sm text-gray-500">Deadline</p>
                        <p className="font-medium">
                            {issue.issue.remediation_deadline
                                ? format(new Date(issue.issue.remediation_deadline), 'MMM d, yyyy')
                                : 'Not set'}
                        </p>
                    </div>
                    <div>
                        <p className="text-sm text-gray-500">Days Open</p>
                        <p className="font-medium">{issue.issue.days_open} days</p>
                    </div>
                </div>

                {/* Inspection Information */}
                {issue.inspection && (
                    <div className="mt-6">
                        <h4 className="text-lg font-medium">Related Inspection</h4>
                        <div className="mt-2 p-4 bg-gray-50 rounded-lg">
                            <p><strong>Template:</strong> {issue.inspection.template_name}</p>
                            <p><strong>Date:</strong> {format(new Date(issue.inspection.scheduled_date), 'PPP')}</p>
                            <p><strong>Status:</strong> {issue.inspection.status}</p>
                        </div>
                    </div>
                )}

                {/* Assignment Section */}
                <div className="mt-6">
                    <h4 className="text-lg font-medium">Assignment</h4>
                    {issue.issue.assigned_to ? (
                        <div className="mt-2 p-4 bg-gray-50 rounded-lg">
                            <p><strong>Assigned To:</strong> {issue.issue.assigned_to_name}</p>
                            <p><strong>Assigned Date:</strong> {format(new Date(issue.issue.assigned_date), 'PPP')}</p>
                        </div>
                    ) : (
                        <div className="mt-2 flex flex-col sm:flex-row sm:items-end gap-2">
                            <div className="flex-grow">
                                <Label htmlFor="assignee">Assign To</Label>
                                <Select onValueChange={setSelectedAssignee}>
                                    <SelectTrigger>
                                        <SelectValue placeholder="Select team member" />
                                    </SelectTrigger>
                                    <SelectContent>
                                        {users.length > 0 ? (
                                            users.map(user => (
                                                <SelectItem key={user.id} value={user.id || `user-${Math.random()}`}>
                                                    {user.full_name || user.email || 'Unknown User'}
                                                </SelectItem>
                                            ))
                                        ) : (
                                            <SelectItem value="no-users">No team members available</SelectItem>
                                        )}
                                    </SelectContent>
                                </Select>
                            </div>
                            <Button
                                onClick={handleAssign}
                                disabled={!selectedAssignee}
                                className="w-full sm:w-auto"
                            >
                                Assign
                            </Button>
                        </div>
                    )}
                </div>

                {/* Remediation Details */}
                <div className="mt-6">
                    <h4 className="text-lg font-medium">Remediation Plan</h4>
                    <div className="mt-2 p-4 bg-gray-50 rounded-lg">
                        {issue.issue.remediation_plan ? (
                            <p>{issue.issue.remediation_plan}</p>
                        ) : (
                            <p className="text-gray-500 italic">No remediation plan has been set.</p>
                        )}
                    </div>
                </div>

                {/* Resolution Details */}
                {issue.issue.resolution_notes && (
                    <div className="mt-6">
                        <h4 className="text-lg font-medium">Resolution</h4>
                        <div className="mt-2 p-4 bg-gray-50 rounded-lg">
                            <p>{issue.issue.resolution_notes}</p>
                            {issue.issue.resolved_date && (
                                <p className="text-sm text-gray-500 mt-2">
                                    Resolved on {format(new Date(issue.issue.resolved_date), 'PPP')}
                                </p>
                            )}
                        </div>
                    </div>
                )}

                {/* Activity Timeline */}
                {issue.history && issue.history.length > 0 && (
                    <div className="mt-8">
                        <h4 className="text-lg font-medium">Activity Timeline</h4>
                        <div className="mt-4 space-y-4">
                            {issue.history.map((item, index) => (
                                <div key={index} className="flex">
                                    <div className="mr-3 flex-shrink-0">
                                        <div className="w-2 h-2 bg-blue-500 rounded-full mt-2"></div>
                                    </div>
                                    <div className="flex-grow">
                                        <p className="font-medium">{item.action}</p>
                                        <p className="text-sm text-gray-500">
                                            {format(new Date(item.created_at), 'PPP')} by {item.user_name}
                                        </p>
                                        {item.notes && <p className="mt-1">{item.notes}</p>}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>

            <div className="mt-8 pt-6 border-t flex justify-end">
                <Button onClick={onClose}>Close</Button>
            </div>
        </div>
    );
};

export default QualityIssueDetails;