import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogFooter,
} from '../../components/ui/dialog';
import {
    Form,
    FormField,
    FormItem,
    FormLabel,
    FormControl,
    FormMessage,
} from '../../components/ui/form';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '../../components/ui/select';
import { Button } from '../../components/ui/button';
import { Input } from '../../components/ui/input';
import { Textarea } from '../../components/ui/textarea';
import { ScrollArea } from '../../components/ui/scroll-area';

import { toast } from 'react-toastify';
import api from '../../services/api';
import safetyApi from '../../services/safetyService';
import { primaryButtonClasses } from '../../components/common/ButtonStyles';

const formatDisplayName = (name) => {
    const baseType = name.split('_').pop();
    return baseType.charAt(0).toUpperCase() + baseType.slice(1);
};

const incidentFormSchema = z.object({
    incident_type: z.string().min(1, "Incident type is required"),
    incident_date: z.string(),
    description: z.string().min(10, "Description must be at least 10 characters"),
    severity: z.enum(['low', 'medium', 'high', 'critical']),
    location: z.string().min(1, "Location is required"),
    corrective_actions: z.array(z.string()).optional(),
});

export const IncidentForm = ({ project, isOpen, onClose, initialData = null, onSubmit }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [safetyIncidents, setSafetyIncidents] = useState([]);

    const form = useForm({
        resolver: zodResolver(incidentFormSchema),
        defaultValues: initialData || {
            incident_type: '',
            incident_date: new Date().toISOString().split('T')[0],
            severity: 'medium',
            corrective_actions: [],
        }
    });

    useEffect(() => {
        const fetchIncidentTypes = async () => {
            try {
                const response = await safetyApi.getIncidentTypes();
                setSafetyIncidents(response.data.types);
            } catch (error) {
                console.error('Error fetching incident types:', error);
                toast({
                    title: "Error",
                    description: "Failed to load incident types. Please try again.",
                    variant: "destructive",
                });
            }
        };
        fetchIncidentTypes();
    }, []);

    const handleSubmit = async (data) => {
        try {
            setIsSubmitting(true);

            console.log('Project object:', project);
            console.log('Full request payload:', {
                ...data,
                project: project.id,
            });

            // Create the incident
            const response = await api.post(`projects/${project.id}/safety-incidents/`, {
                ...data,
                project: project.id,
            });

            toast({
                title: "Success",
                description: "Incident reported successfully",
            });

            onSubmit(response.data);
            onClose();
        } catch (error) {
            console.error('Error creating incident:', error);
            toast({
                title: "Error",
                description: error.response?.data?.detail || "Failed to report incident. Please try again.",
                variant: "destructive",
            });
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className="sm:max-w-[600px] w-[95vw] max-h-[90vh] overflow-hidden">
                <DialogHeader>
                    <DialogTitle>
                        {initialData ? 'Edit Incident Report' : 'Report New Incident'}
                    </DialogTitle>
                </DialogHeader>
                <ScrollArea className="max-h-[calc(90vh-200px)] px-4">
                    <Form {...form}>
                        <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-4">
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <FormField
                                    control={form.control}
                                    name="incident_type"
                                    render={({ field }) => (
                                        <FormItem className="col-span-full">
                                            <FormLabel>Incident Type</FormLabel>
                                            <Select onValueChange={field.onChange} defaultValue={field.value}>
                                                <SelectTrigger>
                                                    <SelectValue placeholder="Select incident type" />
                                                </SelectTrigger>
                                                <SelectContent>
                                                    {safetyIncidents.map(type => (
                                                        <SelectItem key={type.id} value={type.id}>
                                                            {formatDisplayName(type.name)}
                                                        </SelectItem>
                                                    ))}
                                                </SelectContent>
                                            </Select>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />

                                <FormField
                                    control={form.control}
                                    name="incident_date"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>Date of Incident</FormLabel>
                                            <Input type="date" {...field} />
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />

                                <FormField
                                    control={form.control}
                                    name="severity"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>Severity Level</FormLabel>
                                            <Select onValueChange={field.onChange} defaultValue={field.value}>
                                                <SelectTrigger>
                                                    <SelectValue placeholder="Select severity" />
                                                </SelectTrigger>
                                                <SelectContent>
                                                    <SelectItem value="low">Low</SelectItem>
                                                    <SelectItem value="medium">Medium</SelectItem>
                                                    <SelectItem value="high">High</SelectItem>
                                                    <SelectItem value="critical">Critical</SelectItem>
                                                </SelectContent>
                                            </Select>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />

                                <FormField
                                    control={form.control}
                                    name="location"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>Location</FormLabel>
                                            <Input {...field} placeholder="Incident location" />
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />

                                <FormField
                                    control={form.control}
                                    name="description"
                                    render={({ field }) => (
                                        <FormItem className="col-span-full">
                                            <FormLabel>Description</FormLabel>
                                            <Textarea
                                                {...field}
                                                placeholder="Detailed description of the incident"
                                                className="min-h-[100px]"
                                            />
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                            </div>

                            <DialogFooter>
                                <Button
                                    type="button"
                                    variant="outline"
                                    onClick={onClose}
                                    disabled={isSubmitting}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    className={primaryButtonClasses}
                                    type="submit"
                                    disabled={isSubmitting}
                                >
                                    {isSubmitting ? 'Submitting...' : (initialData ? 'Update Incident' : 'Report Incident')}
                                </Button>
                            </DialogFooter>
                        </form>
                    </Form>
                </ScrollArea>
            </DialogContent>
        </Dialog>
    );
};