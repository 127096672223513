import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "../../components/ui/dialog";
import { Button } from "../../components/ui/button";
import { primaryButtonClasses } from '../common/ButtonStyles';

const RelatedTasksView = ({ isOpen, onClose, communication, relatedTasks }) => {
    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className="sm:max-w-md">
                <DialogHeader>
                    <DialogTitle>Related Tasks</DialogTitle>
                </DialogHeader>

                <div className="py-4">
                    <div className="bg-gray-50 p-3 rounded-md mb-4">
                        <p className="text-sm text-gray-600">Communication:</p>
                        <p className="font-medium">{communication?.subject}</p>
                    </div>

                    <div className="space-y-3 max-h-60 overflow-y-auto">
                        {relatedTasks.map(task => (
                            <div key={task.id} className="border rounded-md p-3 hover:bg-gray-50">
                                <div className="flex justify-between">
                                    <p className="font-medium">{task.name}</p>
                                    <span className={`text-xs px-2 py-1 rounded-full ${task.priority === 'high'
                                        ? 'bg-red-100 text-red-800'
                                        : task.priority === 'medium'
                                            ? 'bg-yellow-100 text-yellow-800'
                                            : 'bg-green-100 text-green-800'
                                        }`}>
                                        {task.priority}
                                    </span>
                                </div>
                                <div className="text-sm text-gray-600 mt-1">
                                    <p>Type: {task.activity_type}</p>
                                    <p>
                                        Duration: {new Date(task.planned_start).toLocaleDateString()} -
                                        {new Date(task.planned_finish).toLocaleDateString()}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <DialogFooter>
                    <Button
                        type="button"
                        className={primaryButtonClasses}
                        onClick={onClose}
                    >
                        Close
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};

export default RelatedTasksView;