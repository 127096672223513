import React, { useState, useEffect, useCallback } from 'react';
import api from '../../services/api';
import {
    BriefcaseIcon,
    EnvelopeIcon,
    BellIcon,
    MagnifyingGlassIcon,
    Bars3Icon,
    PaperClipIcon,
    ExclamationTriangleIcon,
    CheckCircleIcon,
    SparklesIcon,
    XMarkIcon,
    ChartBarIcon,
    ChevronDownIcon,
    ChevronUpIcon,
    AdjustmentsHorizontalIcon,
    UserGroupIcon,
    PhoneIcon,
    ClockIcon,
    ClipboardIcon,
    CalendarIcon
} from '@heroicons/react/24/outline';



const CommunicationItem = ({ comm, onCreateTask, onViewRelatedTasks }) => {
    const [expanded, setExpanded] = useState(false);
    const maxLength = 200;


    const hasLinkedTasks = comm.linkedTasks && comm.linkedTasks.length > 0;

    return (
        <div key={comm.id} className="bg-white p-4 rounded-lg shadow hover:shadow-md transition-shadow">
            <div className="flex items-center justify-between mb-2">
                <span className="font-bold">{comm.subject}</span>
                <div className="flex items-center">
                    <span className="text-sm text-gray-500 mr-2">{comm.date}</span>
                    {hasLinkedTasks ? (
                        <button
                            onClick={() => onViewRelatedTasks(comm)}
                            className="text-blue-600 hover:text-blue-800 text-sm flex items-center"
                            title="View related tasks"
                        >
                            <ClipboardIcon className="h-4 w-4 mr-1" />
                            View Tasks ({comm.linkedTasks.length})
                        </button>
                    ) : (
                        <button
                            onClick={() => onCreateTask(comm)}
                            className="text-blue-600 hover:text-blue-800 text-sm flex items-center"
                            title="Create task from this communication"
                        >
                            <CalendarIcon className="h-4 w-4 mr-1" />
                            Link to Schedule
                        </button>
                    )}
                </div>
            </div>
            <p className="text-sm text-gray-600 mb-2">From: {comm.sender} To: {comm.receiver}</p>
            <div className="text-gray-700">
                {comm.content && comm.content.length > maxLength && !expanded ? (
                    <>
                        <p>{comm.content.slice(0, maxLength)}...</p>
                        <button
                            className="text-blue-500 hover:text-blue-700 mt-2 flex items-center"
                            onClick={() => setExpanded(true)}
                        >
                            Show More <ChevronDownIcon className="h-4 w-4 ml-1" />
                        </button>
                    </>
                ) : (
                    <p>{comm.content}</p>
                )}
                {expanded && (
                    <button
                        className="text-blue-500 hover:text-blue-700 mt-2 flex items-center"
                        onClick={() => setExpanded(false)}
                    >
                        Show Less <ChevronUpIcon className="h-4 w-4 ml-1" />
                    </button>
                )}
            </div>
            {comm.attachments && comm.attachments.length > 0 && (
                <div className="mt-2">
                    <p className="text-sm font-medium text-gray-500">Attachments:</p>
                    <div className="flex flex-wrap gap-2 mt-1">
                        {comm.attachments.map((attachment, index) => (
                            <div key={index} className="flex items-center bg-gray-100 rounded p-1">
                                <PaperClipIcon className="h-4 w-4 mr-1" />
                                <span className="text-sm">{attachment.name}</span>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default CommunicationItem;