import React, { useState, useEffect } from 'react';
import { Button } from "../ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "../ui/cardui";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "../ui/tabs";
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Avatar, AvatarImage, AvatarFallback } from "../ui/avatar";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import { Input } from '../ui/input';
import { Alert, AlertDescription } from '../ui/alert';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue
} from '../ui/select';
import { LockIcon, AlertTriangle } from 'lucide-react';
import budgetService from '../../services/budgetService';
import { useToast } from '../ui/use-toast';
import { LineChart, BarChart, PieChart, Line, Bar, Pie, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Cell } from 'recharts';
import { formatCurrency } from '../../utils/formatter';

export default function BudgetSummary({ budget }) {
    const calculateCategoryData = () => {
        return budget.categories.map(category => ({
            name: category.name,
            budget: calculateCategoryBudget(category),
            actual: calculateCategoryActual(category),
            remaining: calculateCategoryBudget(category) - calculateCategoryActual(category)
        }));
    };

    const calculateCategoryBudget = (category) => {
        let total = category.line_items.reduce((sum, item) => sum + parseFloat(item.total_amount), 0);
        category.subcategories?.forEach(sub => {
            total += calculateCategoryBudget(sub);
        });
        return total;
    };

    const calculateCategoryActual = (category) => {
        let total = category.line_items.reduce((sum, item) =>
            sum + item.actuals.reduce((sum, actual) => sum + parseFloat(actual.total_amount), 0), 0
        );
        category.subcategories?.forEach(sub => {
            total += calculateCategoryActual(sub);
        });
        return total;
    };

    const calculateMonthlyData = () => {
        const monthlyData = {};

        budget.categories.forEach(category => {
            category.line_items.forEach(item => {
                item.actuals.forEach(actual => {
                    const month = new Date(actual.date).toLocaleString('default', { month: 'short', year: '2-digit' });
                    if (!monthlyData[month]) {
                        monthlyData[month] = {
                            month,
                            actual: 0,
                            budget: 0
                        };
                    }
                    monthlyData[month].actual += parseFloat(actual.total_amount);
                });
            });
        });

        return Object.values(monthlyData).sort((a, b) =>
            new Date(a.month) - new Date(b.month)
        );
    };

    const calculateCostTypeData = () => {
        const costTypes = {
            LABOR: { name: 'Labor', value: 0, color: '#3B82F6' },
            MATERIAL: { name: 'Material', value: 0, color: '#10B981' },
            SUBCONTRACTOR: { name: 'Subcontractor', value: 0, color: '#8B5CF6' },
            EQUIPMENT: { name: 'Equipment', value: 0, color: '#F59E0B' },
            OTHER: { name: 'Other', value: 0, color: '#6B7280' }
        };

        budget.categories.forEach(category => {
            category.line_items.forEach(item => {
                item.actuals.forEach(actual => {
                    costTypes[actual.actual_type].value += parseFloat(actual.total_amount);
                });
            });
        });

        return Object.values(costTypes).filter(type => type.value > 0);
    };

    const categoryData = calculateCategoryData();
    const monthlyData = calculateMonthlyData();
    const costTypeData = calculateCostTypeData();

    return (
        <div className="space-y-6">
            <div className="grid grid-cols-2 gap-6">
                <Card>
                    <CardHeader>
                        <CardTitle>Budget vs. Actual by Category</CardTitle>
                    </CardHeader>
                    <CardContent>
                        <BarChart
                            width={500}
                            height={300}
                            data={categoryData}
                            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip formatter={(value) => formatCurrency(value)} />
                            <Legend />
                            <Bar dataKey="budget" fill="#3B82F6" name="Budget" />
                            <Bar dataKey="actual" fill="#10B981" name="Actual" />
                        </BarChart>
                    </CardContent>
                </Card>

                <Card>
                    <CardHeader>
                        <CardTitle>Cost Distribution by Type</CardTitle>
                    </CardHeader>
                    <CardContent>
                        <PieChart width={500} height={300}>
                            <Pie
                                data={costTypeData}
                                cx={250}
                                cy={150}
                                innerRadius={60}
                                outerRadius={100}
                                dataKey="value"
                                label={({ name, value }) => `${name} (${formatCurrency(value)})`}
                            >
                                {costTypeData.map((entry, index) => (
                                    <Cell key={index} fill={entry.color} />
                                ))}
                            </Pie>
                            <Tooltip formatter={(value) => formatCurrency(value)} />
                            <Legend />
                        </PieChart>
                    </CardContent>
                </Card>
            </div>

            <Card>
                <CardHeader>
                    <CardTitle>Monthly Trend</CardTitle>
                </CardHeader>
                <CardContent>
                    <LineChart
                        width={1000}
                        height={300}
                        data={monthlyData}
                        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="month" />
                        <YAxis />
                        <Tooltip formatter={(value) => formatCurrency(value)} />
                        <Legend />
                        <Line
                            type="monotone"
                            dataKey="actual"
                            stroke="#10B981"
                            name="Actual"
                            strokeWidth={2}
                        />
                        <Line
                            type="monotone"
                            dataKey="budget"
                            stroke="#3B82F6"
                            name="Budget"
                            strokeWidth={2}
                        />
                    </LineChart>
                </CardContent>
            </Card>

            <Card>
                <CardHeader>
                    <CardTitle>Performance Metrics</CardTitle>
                </CardHeader>
                <CardContent>
                    <div className="grid grid-cols-3 gap-4">
                        <div className="p-4 bg-blue-50 rounded-lg">
                            <div className="text-sm text-blue-700 mb-1">Budget Performance Index</div>
                            <div className="text-2xl font-bold">
                                {((budget.total_budget - budget.total_actuals) / budget.total_budget * 100).toFixed(1)}%
                            </div>
                        </div>

                        <div className="p-4 bg-green-50 rounded-lg">
                            <div className="text-sm text-green-700 mb-1">Cost Variance</div>
                            <div className="text-2xl font-bold">
                                {formatCurrency(budget.total_budget - budget.total_actuals)}
                            </div>
                        </div>

                        <div className="p-4 bg-purple-50 rounded-lg">
                            <div className="text-sm text-purple-700 mb-1">Forecasted Completion</div>
                            <div className="text-2xl font-bold">
                                {formatCurrency(budget.total_actuals + (budget.remaining_budget * 1.1))}
                            </div>
                        </div>
                    </div>
                </CardContent>
            </Card>
        </div>
    );
}