import { showToast } from '../utils/toast';

class OfflineSyncManager {
    constructor() {
        this.dbName = 'inspectionOfflineDB';
        this.dbVersion = 1;
        this.db = null;
        this.syncInProgress = false;
        this.syncQueue = [];

        // Initialize database and set up network listeners
        this.initDatabase();
        this.setupNetworkListeners();
    }


    async initDatabase() {
        return new Promise((resolve, reject) => {
            const request = indexedDB.open(this.dbName, this.dbVersion);

            request.onerror = (event) => {
                console.error('IndexedDB error:', event.target.error);
                reject(event.target.error);
            };

            request.onsuccess = (event) => {
                this.db = event.target.result;
                console.log('IndexedDB initialized successfully');
                resolve(this.db);
            };

            request.onupgradeneeded = (event) => {
                const db = event.target.result;

                // Create object stores
                if (!db.objectStoreNames.contains('inspections')) {
                    db.createObjectStore('inspections', { keyPath: 'offlineId' });
                }

                if (!db.objectStoreNames.contains('photos')) {
                    db.createObjectStore('photos', { keyPath: 'offlineId' });
                }

                if (!db.objectStoreNames.contains('syncQueue')) {
                    db.createObjectStore('syncQueue', { keyPath: 'id', autoIncrement: true });
                }
            };
        });
    }

    /**
     * Set up network status listeners to trigger sync when connection is restored
     */
    setupNetworkListeners() {
        window.addEventListener('online', () => {
            console.log('Connection restored, starting sync');
            showToast.success('Network connection restored');
            this.syncPendingData();
        });

        window.addEventListener('offline', () => {
            console.log('Connection lost, data will be saved locally');
            showToast.warning('Network connection lost. Working offline.');
            this.syncInProgress = false;
        });
    }

    /**
     * Save inspection data to local storage for offline use
     * @param {Object} inspection - Inspection data to store
     * @returns {Promise<string>} - Promise resolving to offline ID
     */
    async saveInspectionOffline(inspection) {
        if (!this.db) await this.initDatabase();

        return new Promise((resolve, reject) => {
            const transaction = this.db.transaction(['inspections', 'syncQueue'], 'readwrite');
            const inspectionStore = transaction.objectStore('inspections');
            const syncQueueStore = transaction.objectStore('syncQueue');

            // Generate offline ID if not present
            const offlineId = inspection.offlineId || `offline_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;
            const inspectionData = { ...inspection, offlineId, lastModified: new Date().toISOString() };

            // Store inspection data
            const storeRequest = inspectionStore.put(inspectionData);

            storeRequest.onsuccess = () => {
                // Add to sync queue
                syncQueueStore.add({
                    type: 'inspection',
                    action: inspection.id ? 'update' : 'create',
                    data: inspectionData,
                    timestamp: new Date().toISOString()
                });

                console.log('Inspection saved offline:', offlineId);
                resolve(offlineId);
            };

            storeRequest.onerror = (event) => {
                console.error('Error saving inspection offline:', event.target.error);
                reject(event.target.error);
            };

            transaction.oncomplete = () => {
                // Try to sync if online
                if (navigator.onLine) {
                    this.syncPendingData();
                }
            };
        });
    }

    /**
     * Save photo data for offline use
     * @param {Object} photoData - Photo data with file and metadata
     * @param {string} inspectionOfflineId - Associated inspection offline ID
     * @returns {Promise<string>} - Promise resolving to offline photo ID
     */
    async savePhotoOffline(photoData, inspectionOfflineId) {
        if (!this.db) await this.initDatabase();

        return new Promise((resolve, reject) => {
            const transaction = this.db.transaction(['photos', 'syncQueue'], 'readwrite');
            const photoStore = transaction.objectStore('photos');
            const syncQueueStore = transaction.objectStore('syncQueue');

            // Generate offline ID
            const offlineId = `photo_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;

            // Create a reader to convert file to data URL
            const reader = new FileReader();

            reader.onload = (event) => {
                const photoBlob = event.target.result;

                // Store the photo data
                const photoRecord = {
                    offlineId,
                    inspectionOfflineId,
                    dataUrl: photoBlob,
                    metadata: photoData.metadata || {},
                    timestamp: new Date().toISOString()
                };

                const storeRequest = photoStore.put(photoRecord);

                storeRequest.onsuccess = () => {
                    // Add to sync queue
                    syncQueueStore.add({
                        type: 'photo',
                        action: 'create',
                        data: {
                            offlineId,
                            inspectionOfflineId,
                            metadata: photoData.metadata || {}
                        },
                        timestamp: new Date().toISOString()
                    });

                    console.log('Photo saved offline:', offlineId);
                    resolve(offlineId);
                };

                storeRequest.onerror = (event) => {
                    console.error('Error saving photo offline:', event.target.error);
                    reject(event.target.error);
                };
            };

            reader.onerror = (error) => {
                console.error('Error reading photo file:', error);
                reject(error);
            };

            // Read the file as data URL
            reader.readAsDataURL(photoData.file);
        });
    }

    /**
     * Get locally stored inspection by offline ID
     * @param {string} offlineId - Offline ID of the inspection
     * @returns {Promise<Object>} - Promise resolving to inspection data
     */
    async getOfflineInspection(offlineId) {
        if (!this.db) await this.initDatabase();

        return new Promise((resolve, reject) => {
            const transaction = this.db.transaction('inspections', 'readonly');
            const store = transaction.objectStore('inspections');

            const request = store.get(offlineId);

            request.onsuccess = (event) => {
                resolve(event.target.result);
            };

            request.onerror = (event) => {
                console.error('Error getting offline inspection:', event.target.error);
                reject(event.target.error);
            };
        });
    }

    /**
     * Get all locally stored inspections
     * @returns {Promise<Array>} - Promise resolving to array of inspections
     */
    async getAllOfflineInspections() {
        if (!this.db) await this.initDatabase();

        return new Promise((resolve, reject) => {
            const transaction = this.db.transaction('inspections', 'readonly');
            const store = transaction.objectStore('inspections');

            const request = store.getAll();

            request.onsuccess = (event) => {
                resolve(event.target.result);
            };

            request.onerror = (event) => {
                console.error('Error getting all offline inspections:', event.target.error);
                reject(event.target.error);
            };
        });
    }

    /**
     * Get photos for an inspection by offline ID
     * @param {string} inspectionOfflineId - Offline ID of the inspection
     * @returns {Promise<Array>} - Promise resolving to array of photos
     */
    async getPhotosForInspection(inspectionOfflineId) {
        if (!this.db) await this.initDatabase();

        return new Promise((resolve, reject) => {
            const transaction = this.db.transaction('photos', 'readonly');
            const store = transaction.objectStore('photos');

            const request = store.getAll();

            request.onsuccess = (event) => {
                const allPhotos = event.target.result;
                const inspectionPhotos = allPhotos.filter(
                    photo => photo.inspectionOfflineId === inspectionOfflineId
                );
                resolve(inspectionPhotos);
            };

            request.onerror = (event) => {
                console.error('Error getting photos for inspection:', event.target.error);
                reject(event.target.error);
            };
        });
    }

    /**
     * Synchronize pending data when online
     * @returns {Promise<void>}
     */
    async syncPendingData() {
        // Skip if sync is already in progress or offline
        if (this.syncInProgress || !navigator.onLine) return;

        this.syncInProgress = true;
        try {
            // Get all pending sync items
            const pendingItems = await this.getPendingSyncItems();
            if (pendingItems.length === 0) {
                this.syncInProgress = false;
                return;
            }

            console.log(`Starting sync of ${pendingItems.length} items`);

            // Sort by timestamp and type (photos first, then inspections)
            pendingItems.sort((a, b) => {
                if (a.type === 'photo' && b.type !== 'photo') return -1;
                if (a.type !== 'photo' && b.type === 'photo') return 1;
                return new Date(a.timestamp) - new Date(b.timestamp);
            });

            // Process each item sequentially
            for (const item of pendingItems) {
                try {
                    await this.processSyncItem(item);
                    await this.removeSyncItem(item.id);
                } catch (error) {
                    console.error(`Error syncing item ${item.id}:`, error);
                    // Continue with next item
                }
            }

            showToast.success('All data synchronized successfully');
        } catch (error) {
            console.error('Sync error:', error);
            showToast.error('Sync failed. Will try again when connection improves.');
        } finally {
            this.syncInProgress = false;
        }
    }

    /**
     * Get all pending items from sync queue
     * @returns {Promise<Array>} - Promise resolving to array of sync items
     */
    async getPendingSyncItems() {
        if (!this.db) await this.initDatabase();

        return new Promise((resolve, reject) => {
            const transaction = this.db.transaction('syncQueue', 'readonly');
            const store = transaction.objectStore('syncQueue');

            const request = store.getAll();

            request.onsuccess = (event) => {
                resolve(event.target.result);
            };

            request.onerror = (event) => {
                console.error('Error getting pending sync items:', event.target.error);
                reject(event.target.error);
            };
        });
    }

    /**
     * Process a single sync item
     * @param {Object} item - Sync queue item
     * @returns {Promise<void>}
     */
    async processSyncItem(item) {
        if (item.type === 'photo') {
            await this.syncPhoto(item);
        } else if (item.type === 'inspection') {
            await this.syncInspection(item);
        }
    }

    /**
     * Sync a photo to the server
     * @param {Object} item - Sync queue item for photo
     * @returns {Promise<void>}
     */
    async syncPhoto(item) {
        // Get the actual photo data from the store
        const photo = await this.getPhotoByOfflineId(item.data.offlineId);
        if (!photo) {
            console.error('Photo not found for syncing:', item.data.offlineId);
            return;
        }

        // Convert data URL back to File
        const blob = await fetch(photo.dataUrl).then(r => r.blob());
        const file = new File(
            [blob],
            `photo_${Date.now()}.jpg`,
            { type: 'image/jpeg' }
        );

        // Create form data for upload
        const formData = new FormData();
        formData.append('photo', file);

        // Add metadata
        for (const [key, value] of Object.entries(photo.metadata)) {
            formData.append(key, value);
        }

        // Add inspection association if we have a server ID
        const inspection = await this.getOfflineInspection(photo.inspectionOfflineId);
        if (inspection && inspection.id) { // Server ID exists
            formData.append('inspection_id', inspection.id);
        }

        // Upload to server
        const response = await fetch('/api/inspection-photos/', {
            method: 'POST',
            body: formData
        });

        if (!response.ok) {
            throw new Error(`Failed to upload photo: ${response.statusText}`);
        }

        const result = await response.json();

        // Update local photo with server ID
        await this.updatePhotoWithServerId(photo.offlineId, result.id);
    }

    /**
     * Sync an inspection to the server
     * @param {Object} item - Sync queue item for inspection
     * @returns {Promise<void>}
     */
    async syncInspection(item) {
        const inspection = item.data;
        const method = item.action === 'create' ? 'POST' : 'PUT';
        const url = item.action === 'create'
            ? `/api/projects/${inspection.project}/inspections/`
            : `/api/projects/${inspection.project}/inspections/${inspection.id}/`;

        // Prepare data for submission
        const submissionData = { ...inspection };
        delete submissionData.offlineId;
        delete submissionData.lastModified;

        // Send to server
        const response = await fetch(url, {
            method,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(submissionData)
        });

        if (!response.ok) {
            throw new Error(`Failed to sync inspection: ${response.statusText}`);
        }

        const result = await response.json();

        // Update local inspection with server ID
        await this.updateInspectionWithServerId(inspection.offlineId, result.id);
    }

    /**
     * Get photo by offline ID
     * @param {string} offlineId - Offline ID of the photo
     * @returns {Promise<Object>} - Promise resolving to photo data
     */
    async getPhotoByOfflineId(offlineId) {
        if (!this.db) await this.initDatabase();

        return new Promise((resolve, reject) => {
            const transaction = this.db.transaction('photos', 'readonly');
            const store = transaction.objectStore('photos');

            const request = store.get(offlineId);

            request.onsuccess = (event) => {
                resolve(event.target.result);
            };

            request.onerror = (event) => {
                console.error('Error getting photo by ID:', event.target.error);
                reject(event.target.error);
            };
        });
    }

    /**
     * Update local photo with server ID
     * @param {string} offlineId - Offline ID of the photo
     * @param {string} serverId - Server ID returned from API
     * @returns {Promise<void>}
     */
    async updatePhotoWithServerId(offlineId, serverId) {
        if (!this.db) await this.initDatabase();

        return new Promise((resolve, reject) => {
            const transaction = this.db.transaction('photos', 'readwrite');
            const store = transaction.objectStore('photos');

            const request = store.get(offlineId);

            request.onsuccess = (event) => {
                const photo = event.target.result;
                if (photo) {
                    photo.id = serverId;
                    photo.synced = true;

                    const updateRequest = store.put(photo);

                    updateRequest.onsuccess = () => {
                        resolve();
                    };

                    updateRequest.onerror = (event) => {
                        console.error('Error updating photo with server ID:', event.target.error);
                        reject(event.target.error);
                    };
                } else {
                    reject(new Error('Photo not found'));
                }
            };

            request.onerror = (event) => {
                console.error('Error getting photo for update:', event.target.error);
                reject(event.target.error);
            };
        });
    }

    /**
     * Update local inspection with server ID
     * @param {string} offlineId - Offline ID of the inspection
     * @param {string} serverId - Server ID returned from API
     * @returns {Promise<void>}
     */
    async updateInspectionWithServerId(offlineId, serverId) {
        if (!this.db) await this.initDatabase();

        return new Promise((resolve, reject) => {
            const transaction = this.db.transaction('inspections', 'readwrite');
            const store = transaction.objectStore('inspections');

            const request = store.get(offlineId);

            request.onsuccess = (event) => {
                const inspection = event.target.result;
                if (inspection) {
                    inspection.id = serverId;
                    inspection.synced = true;

                    const updateRequest = store.put(inspection);

                    updateRequest.onsuccess = () => {
                        resolve();
                    };

                    updateRequest.onerror = (event) => {
                        console.error('Error updating inspection with server ID:', event.target.error);
                        reject(event.target.error);
                    };
                } else {
                    reject(new Error('Inspection not found'));
                }
            };

            request.onerror = (event) => {
                console.error('Error getting inspection for update:', event.target.error);
                reject(event.target.error);
            };
        });
    }

    /**
     * Remove item from sync queue after successful sync
     * @param {number} id - ID of the sync queue item
     * @returns {Promise<void>}
     */
    async removeSyncItem(id) {
        if (!this.db) await this.initDatabase();

        return new Promise((resolve, reject) => {
            const transaction = this.db.transaction('syncQueue', 'readwrite');
            const store = transaction.objectStore('syncQueue');

            const request = store.delete(id);

            request.onsuccess = () => {
                resolve();
            };

            request.onerror = (event) => {
                console.error('Error removing sync item:', event.target.error);
                reject(event.target.error);
            };
        });
    }

    /**
     * Get sync status for UI indicators
     * @returns {Promise<Object>} - Promise resolving to sync status info
     */
    async getSyncStatus() {
        const pendingItems = await this.getPendingSyncItems();
        return {
            pendingCount: pendingItems.length,
            isSyncing: this.syncInProgress,
            isOnline: navigator.onLine,
            lastSyncAttempt: localStorage.getItem('lastSyncAttempt') || null
        };
    }

    /**
     * Clear all offline data (useful for testing or user logout)
     * @returns {Promise<void>}
     */
    async clearAllData() {
        if (!this.db) await this.initDatabase();

        return new Promise((resolve, reject) => {
            const transaction = this.db.transaction(['inspections', 'photos', 'syncQueue'], 'readwrite');
            const inspectionStore = transaction.objectStore('inspections');
            const photoStore = transaction.objectStore('photos');
            const syncQueueStore = transaction.objectStore('syncQueue');

            inspectionStore.clear();
            photoStore.clear();
            syncQueueStore.clear();

            transaction.oncomplete = () => {
                console.log('All offline data cleared');
                resolve();
            };

            transaction.onerror = (event) => {
                console.error('Error clearing offline data:', event.target.error);
                reject(event.target.error);
            };
        });
    }
}

// Create a singleton instance
const offlineSyncManager = new OfflineSyncManager();
export default offlineSyncManager;