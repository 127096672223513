import React, { useState, useEffect, Fragment } from 'react';
import { Trash2, Share2, Search, Grid, List, MoreVertical, Edit, Upload, ChevronRight, Menu as MenuIcon, X } from 'lucide-react';
import DocumentToolbar from '../shared/DocumentToolbar';
import DocumentBreadcrumbs from '../shared/DocumentBreadcrumbs';
import ShareEmailModal from '../shared/ShareEmailModal';
import DocumentAnnotator from '../shared/DocumentAnnotator';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import { Menu, Transition } from '@headlessui/react';
import api from '../../../services/api';
import CreateProjectModal from '../../Projects/CreateProjectModal';

const SAFETY_DOCUMENT_TYPES = [
    'Safety Report', 'Safety Inspection', 'Safety Audit',
    'Safety Training', 'Safety Meeting', 'Safety Analysis',
    'Safety Video', 'Hazard Report', 'Other'
];

// Project Sidebar Component
const ProjectSidebar = ({ projects, activeProject, setActiveProject, isOpen, setIsOpen }) => {
    return (
        <div className={`w-64 bg-gray-100 h-screen p-4 overflow-y-auto fixed left-0 top-0 transition-transform duration-300 ease-in-out z-20 ${isOpen ? 'translate-x-0' : '-translate-x-full'}`}>
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-bold text-gray-800">Projects</h2>
                <X className="h-6 w-6 cursor-pointer" onClick={() => setIsOpen(false)} />
            </div>
            {projects.length > 0 ? (
                <ul>
                    {projects.map(project => (
                        <li
                            key={project.id}
                            className={`mb-2 p-2 rounded cursor-pointer flex items-center justify-between ${activeProject === project.id ? 'bg-blue-100' : 'hover:bg-gray-200'}`}
                            onClick={() => {
                                setActiveProject(project.id);
                                setIsOpen(false);
                            }}
                        >
                            <span className="flex items-center">
                                <ChevronRight className="mr-2 h-5 w-5" />
                                {project.name}
                            </span>
                        </li>
                    ))}
                </ul>
            ) : (
                <p className="text-gray-500 text-sm">No projects available</p>
            )}
            <button
                onClick={() => setIsOpen(false)}
                className="mt-4 w-full bg-gray-200 p-2 rounded text-gray-700 hover:bg-gray-300"
            >
                Close
            </button>
        </div>
    );
};

// Header Component
const Header = ({ activeProjectName, setIsSidebarOpen, searchQuery, setSearchQuery, view, setView }) => {
    return (
        <div className="flex justify-between items-center mb-6">
            <div className="flex items-center">
                <MenuIcon className="mr-4 cursor-pointer h-6 w-6" onClick={() => setIsSidebarOpen(true)} />
                <h2 className="text-xl font-bold">Safety Documents</h2>
                {activeProjectName && (
                    <span className="ml-2 text-gray-600">- {activeProjectName}</span>
                )}
            </div>
            <div className="flex items-center space-x-4">
                <div className="relative">
                    <input
                        type="text"
                        placeholder="Search files..."
                        className="pl-10 pr-4 py-2 border rounded-lg w-64"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <Search className="absolute left-3 top-2.5 text-gray-400" />
                </div>
                <div className="flex border rounded-lg">
                    <button
                        onClick={() => setView('grid')}
                        className={`px-3 py-2 ${view === 'grid' ? 'bg-gray-100' : 'bg-white'}`}
                    >
                        <Grid size={20} />
                    </button>
                    <button
                        onClick={() => setView('list')}
                        className={`px-3 py-2 ${view === 'list' ? 'bg-gray-100' : 'bg-white'}`}
                    >
                        <List size={20} />
                    </button>
                </div>
            </div>
        </div>
    );
};

const SafetyDocuments = ({ rootFolderId }) => {
    const [projects, setProjects] = useState([]);
    const [allProjects, setAllProjects] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showVideoUploadModal, setShowVideoUploadModal] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [documents, setDocuments] = useState([]);
    const [activeProject, setActiveProject] = useState(null);
    const [activeProjectName, setActiveProjectName] = useState('');
    const [currentFolderId, setCurrentFolderId] = useState(null);
    const [path, setPath] = useState([]);
    const [authError, setAuthError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [documentToDelete, setDocumentToDelete] = useState(null);
    const [showShareModal, setShowShareModal] = useState(false);
    const [documentToShare, setDocumentToShare] = useState(null);
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [showAnnotator, setShowAnnotator] = useState(false);
    const [view, setView] = useState('list');
    const [uploadProgress, setUploadProgress] = useState(0);
    const [videoUploading, setVideoUploading] = useState(false);
    const [selectedVideoFile, setSelectedVideoFile] = useState(null);
    const [showCreateProjectModal, setShowCreateProjectModal] = useState(false);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const itemsPerPage = 10;
    useEffect(() => {
        if (rootFolderId) {
            setCurrentFolderId(rootFolderId);
        }
        fetchProjects();
    }, [rootFolderId]);

    useEffect(() => {
        if (projects.length > 0 && !activeProject) {
            setActiveProject(projects[0].id);
            setActiveProjectName(projects[0].name);
        }
    }, [projects, activeProject]);

    useEffect(() => {
        if (projects.length === 0) {
            setIsLoading(false);
        } else if (activeProject && (currentFolderId || rootFolderId)) {
            const folderToUse = currentFolderId || rootFolderId;
            fetchDocumentsWithProject(activeProject, folderToUse, currentPage);
        } else {
            setIsLoading(false);
        }
    }, [activeProject, currentFolderId, rootFolderId, currentPage, searchQuery, projects.length]);

    useEffect(() => {
        if (documents.length === 0 && !isLoading && activeProject && (currentFolderId || rootFolderId)) {
        }

        if (documents.length > 0) {
            const missingProps = documents.filter(doc =>
                !doc.id || !doc.title || doc.file_type === undefined
            );

            if (missingProps.length > 0) {
            }
        }
    }, [documents, isLoading, activeProject, currentFolderId, rootFolderId]);

    const handleProjectCreated = async () => {
        setShowCreateProjectModal(false);
        await fetchProjects();
        if (projects.length > 0) {
            setActiveProject(projects[0].id);
            setActiveProjectName(projects[0].name);
        }
    };

    const fetchDocumentsWithProject = async (projectId, folderId = null, page = 1) => {
        setIsLoading(true);

        try {
            // For root safety folder
            if (folderId === rootFolderId) {
                // Get all project documents - no parent filter
                const params = {
                    feature_type: 'safety',
                    t: new Date().getTime()
                };

                console.log("Fetching top-level safety documents");
                const response = await api.get(`document-management/files/${projectId}/`, { params });
                console.log("Response data:", response.data);

                if (response.data && response.data.results) {
                    setDocuments(response.data.results);
                } else if (Array.isArray(response.data)) {
                    setDocuments(response.data);
                } else {
                    setDocuments([]);
                }
            }
            // For any other folder
            else if (folderId) {
                // We'll need to handle this case differently - try getting folder contents directly
                const params = {
                    parent: folderId,
                    feature_type: 'safety',
                    t: new Date().getTime()
                };

                console.log(`Fetching contents of folder ${folderId}`);
                try {
                    // Try folder contents endpoint if available
                    const response = await api.get(`document-management/folders/${folderId}/contents/`, { params });
                    console.log("Folder contents:", response.data);

                    if (response.data && response.data.results) {
                        setDocuments(response.data.results);
                    } else if (Array.isArray(response.data)) {
                        setDocuments(response.data);
                    } else {
                        setDocuments([]);
                    }
                } catch (folderError) {
                    console.error("Error fetching folder contents:", folderError);
                    // Fall back to filtered project query
                    const fallbackResponse = await api.get(`document-management/files/${projectId}/`, {
                        params: { parent: folderId, feature_type: 'safety' }
                    });

                    if (fallbackResponse.data && fallbackResponse.data.results) {
                        setDocuments(fallbackResponse.data.results);
                    } else if (Array.isArray(fallbackResponse.data)) {
                        setDocuments(fallbackResponse.data);
                    } else {
                        setDocuments([]);
                    }
                }
            }
            // No folder ID - project root
            else {
                // Get all project documents
                const params = {
                    feature_type: 'safety',
                    t: new Date().getTime()
                };

                console.log("Fetching all project safety documents");
                const response = await api.get(`document-management/files/${projectId}/`, { params });
                console.log("All project documents:", response.data);

                if (response.data && response.data.results) {
                    setDocuments(response.data.results);
                } else if (Array.isArray(response.data)) {
                    setDocuments(response.data);
                } else {
                    setDocuments([]);
                }
            }
        } catch (error) {
            console.error('Error fetching documents:', error);
            setDocuments([]);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchProjects = async () => {
        try {
            const response = await api.get('document-management/projects/', {
                headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
            });

            setProjects(response.data.limited_projects || []);
            setAllProjects(response.data.all_projects || []);

            if (!activeProject && response.data.limited_projects?.length > 0) {
                setActiveProject(response.data.limited_projects[0].id);
                setActiveProjectName(response.data.limited_projects[0].name);
            }
        } catch (error) {
            if (error.response?.status === 401) setAuthError(true);
            toast.error('Failed to load projects');
        }
    };

    const fetchDocuments = (projectId, folderId = null, page = 1) => {
        setIsLoading(true);

        const token = localStorage.getItem('access_token') || localStorage.getItem('token');

        let url;
        let params = {
            page: page,
            per_page: itemsPerPage,
            search: searchQuery,
            type: 'safety',
            feature_type: 'safety'
        };

        if (projectId) {
            url = `document-management/files/${projectId}/`;

            if (folderId) {
                params.parent = folderId;
            } else if (rootFolderId) {
                params.parent = rootFolderId;
            }
        } else if (folderId) {
            url = `document-management/folders/${folderId}/contents/`;
        } else {
            console.error("Neither projectId nor folderId specified for document fetch");
            setIsLoading(false);
            setDocuments([]);
            return;
        }

        api.get(url, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            params: params
        })
            .then(response => {
                setDocuments(prev => {
                    if (response.data.results?.length > 0) {
                        return response.data.results;
                    }

                    return prev.length > 0 ? prev : [];
                });
                setTotalPages(Math.ceil((response.data.count || response.data.length) / itemsPerPage));
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error fetching documents:', error);
                setIsLoading(false);
                toast.error('Failed to load documents');
            });
    };

    const handleProjectSelect = (projectId) => {
        const selectedProject = projects.find(p => p.id === projectId) || allProjects.find(p => p.id === projectId);
        setActiveProject(projectId);
        setActiveProjectName(selectedProject?.name || '');
        setCurrentFolderId(null);
        setPath([]);
        setCurrentPage(1);
        fetchDocuments(projectId);
    };

    const handleFolderClick = (folderId, folderTitle) => {
        setCurrentFolderId(folderId);
        setPath(prev => [...prev, { id: folderId, title: folderTitle }]);
        setCurrentPage(1);
        fetchDocuments(activeProject, folderId, 1);
    };

    const handleBreadcrumbClick = (index) => {
        const newPath = path.slice(0, index + 1);
        const newFolderId = index === -1 ? rootFolderId : newPath.length > 0 ? newPath[newPath.length - 1].id : null;
        setPath(newPath);
        setCurrentFolderId(newFolderId);
        setCurrentPage(1);
        fetchDocuments(activeProject, newFolderId, 1);
    };

    const handleRowClick = (doc, event) => {
        if (event.target.closest('.action-menu')) {
            return;
        }

        if (doc.file_type === 'folder') {
            handleFolderClick(doc.id, doc.title);
        }
    };

    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
        // Reset to first page when searching
        setCurrentPage(1);
    };

    const initiateDelete = (document) => {
        setDocumentToDelete(document);
        setShowDeleteModal(true);
    };

    const handleDelete = () => {
        if (!documentToDelete) return;

        // Store these locally to avoid React state issues
        const docId = documentToDelete.id;
        const docTitle = documentToDelete.title || 'document';

        // Close the modal first to prevent UI issues
        setShowDeleteModal(false);
        setDocumentToDelete(null);

        // Show a loading toast
        const toastId = toast.loading(`Deleting ${docTitle}...`);

        // Use a simple fetch instead of the api helper to avoid any potential issues
        fetch(`/api/document-management/flag-delete-document/${docId}/`, {
            method: 'PATCH',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token') || localStorage.getItem('access_token')}`,
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`Server responded with status: ${response.status}`);
                }
                return response.json().catch(() => ({})); // Handle empty responses
            })
            .then(() => {
                // Success - update the toast
                toast.update(toastId, {
                    render: `${docTitle} deleted successfully`,
                    type: 'success',
                    isLoading: false,
                    autoClose: 3000
                });

                // Wait a moment before refreshing data
                setTimeout(() => {
                    // Use a direct fetch to get documents instead of calling state-changing functions
                    if (activeProject) {
                        fetch(`/api/document-management/files/${activeProject}/?feature_type=safety&t=${Date.now()}`, {
                            headers: {
                                'Authorization': `Bearer ${localStorage.getItem('token') || localStorage.getItem('access_token')}`
                            }
                        })
                            .then(response => response.json())
                            .then(data => {
                                setDocuments(data.results || data || []);
                            })
                            .catch(err => {
                                console.error("Error refreshing documents:", err);
                            });
                    }
                }, 500);
            })
            .catch(error => {
                // Error - update the toast
                console.error('Delete error:', error);
                toast.update(toastId, {
                    render: `Error deleting ${docTitle}`,
                    type: 'error',
                    isLoading: false,
                    autoClose: 3000
                });
            });
    };

    const handleShare = (document) => {
        setDocumentToShare(document);
        setShowShareModal(true);
    };

    const handleShareSubmit = async (shareData) => {
        try {
            const endpoint = activeProject
                ? `document-management/documents/${activeProject}/${shareData.documentId}/share/`
                : `document-management/documents/share/`;

            const data = {
                recipient_email: shareData.email,
                message: shareData.message
            };

            if (!activeProject) {
                data.document_id = shareData.documentId;
            }

            await api.post(endpoint, data);
            toast.success('Document shared successfully');
        } catch (error) {
            console.error('Error sharing document:', error);
            toast.error('Failed to share document');
            throw error;
        }
    };

    const handleOpenAnnotator = (document) => {
        if (document.file_type === 'folder') {
            toast.warn("Folders cannot be annotated");
            return;
        }
        setSelectedDocument(document);
        setShowAnnotator(true);
    };

    const handleCloseAnnotator = () => {
        setShowAnnotator(false);
        setSelectedDocument(null);
    };

    const handleVideoFileSelect = (event) => {
        const file = event.target.files?.[0];
        if (!file) return;

        // Validate file type
        const validTypes = [
            'video/mp4', 'video/mpeg', 'video/quicktime',
            'video/x-msvideo', 'video/x-ms-wmv', 'video/webm'
        ];

        if (!validTypes.includes(file.type)) {
            toast.error('Invalid file type. Please upload a valid video file.');
            return;
        }

        // Validate file size (100MB max)
        const maxSize = 100 * 1024 * 1024;
        if (file.size > maxSize) {
            toast.error('File size exceeds 100MB limit.');
            return;
        }

        setSelectedVideoFile(file);
    };

    const handleVideoUpload = async () => {
        if (!selectedVideoFile || !activeProject) {
            toast.error('Please select a video file and ensure a project is selected');
            return;
        }

        setVideoUploading(true);
        setUploadProgress(0);

        try {
            // Step 1: Create analysis record
            const formData = new FormData();
            formData.append('file_name', selectedVideoFile.name);
            formData.append('total_size', selectedVideoFile.size.toString());
            formData.append('content_type', selectedVideoFile.type);

            const response = await api.post(`projects/${activeProject}/safety-video-analyses/`, formData);
            const analysisId = response.data.id;

            // Step 2: Upload the video in chunks
            const chunkSize = 1024 * 1024; // 1MB chunks
            const totalChunks = Math.ceil(selectedVideoFile.size / chunkSize);

            for (let i = 0; i < totalChunks; i++) {
                const start = i * chunkSize;
                const end = Math.min(start + chunkSize, selectedVideoFile.size);
                const chunk = selectedVideoFile.slice(start, end);

                const chunkFormData = new FormData();
                chunkFormData.append('chunk', chunk);
                chunkFormData.append('chunk_index', i.toString());
                chunkFormData.append('total_chunks', totalChunks.toString());

                await api.post(`projects/${activeProject}/safety-video-analyses/${analysisId}/chunks/`, chunkFormData, {
                    onUploadProgress: (progressEvent) => {
                        const totalProgress = ((i + (progressEvent.loaded / progressEvent.total)) / totalChunks) * 100;
                        setUploadProgress(Math.round(totalProgress));
                    }
                });
            }

            // Step 3: Start the analysis
            await api.post(`projects/${activeProject}/safety-video-analyses/${analysisId}/analyze/`);

            toast.success('Video uploaded and analysis started!');
            setShowVideoUploadModal(false);
            setSelectedVideoFile(null);

            // Refresh documents after a short delay to allow server processing
            setTimeout(() => {
                fetchDocuments(activeProject, currentFolderId, currentPage);
            }, 2000);
        } catch (error) {
            console.error('Error uploading video:', error);
            toast.error('Failed to upload video: ' + (error.response?.data?.message || error.message));
        } finally {
            setVideoUploading(false);
        }
    };

    const renderActionMenu = (doc) => (
        <Menu as="div" className="relative inline-block text-left action-menu">
            <Menu.Button className="inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
                <MoreVertical className="h-5 w-5" aria-hidden="true" />
            </Menu.Button>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="absolute right-0 z-50 mt-2 w-48 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                        {doc.file_type !== 'folder' && (
                            <>
                                <Menu.Item>
                                    {({ active }) => (
                                        <button
                                            onClick={() => handleShare(doc)}
                                            className={`${active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                                                } group flex items-center px-4 py-2 text-sm w-full text-left`}
                                        >
                                            <Share2
                                                className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                                aria-hidden="true"
                                            />
                                            Share
                                        </button>
                                    )}
                                </Menu.Item>
                                <Menu.Item>
                                    {({ active }) => (
                                        <button
                                            onClick={() => handleOpenAnnotator(doc)}
                                            className={`${active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                                                } group flex items-center px-4 py-2 text-sm w-full text-left`}
                                        >
                                            <Edit
                                                className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                                aria-hidden="true"
                                            />
                                            Annotate
                                        </button>
                                    )}
                                </Menu.Item>
                            </>
                        )}
                        <Menu.Item>
                            {({ active }) => (
                                <button
                                    onClick={() => initiateDelete(doc)}
                                    className={`${active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                                        } group flex items-center px-4 py-2 text-sm w-full text-left`}
                                >
                                    <Trash2
                                        className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                        aria-hidden="true"
                                    />
                                    Delete
                                </button>
                            )}
                        </Menu.Item>
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    );

    const renderPageNumbers = () => {
        const pageNumbers = [];
        const ellipsis = <span className="px-4 py-2">...</span>;

        if (totalPages <= 7) {
            for (let i = 1; i <= totalPages; i++) {
                pageNumbers.push(
                    <button
                        key={i}
                        onClick={() => setCurrentPage(i)}
                        className={`relative inline-flex items-center px-4 py-2 border text-sm font-medium ${currentPage === i
                            ? 'z-10 bg-[#344bfc] text-white border-[#344bfc]'
                            : 'bg-white text-gray-500 hover:bg-gray-50'
                            }`}
                    >
                        {i}
                    </button>
                );
            }
        } else {
            if (currentPage <= 3) {
                for (let i = 1; i <= 3; i++) {
                    pageNumbers.push(
                        <button
                            key={i}
                            onClick={() => setCurrentPage(i)}
                            className={`relative inline-flex items-center px-4 py-2 border text-sm font-medium ${currentPage === i
                                ? 'z-10 bg-[#344bfc] text-white border-[#344bfc]'
                                : 'bg-white text-gray-500 hover:bg-gray-50'
                                }`}
                        >
                            {i}
                        </button>
                    );
                }
                pageNumbers.push(ellipsis);
                pageNumbers.push(
                    <button
                        key={totalPages}
                        onClick={() => setCurrentPage(totalPages)}
                        className="relative inline-flex items-center px-4 py-2 border text-sm font-medium bg-white text-gray-500 hover:bg-gray-50"
                    >
                        {totalPages}
                    </button>
                );
            } else if (currentPage >= totalPages - 2) {
                pageNumbers.push(
                    <button
                        key={1}
                        onClick={() => setCurrentPage(1)}
                        className="relative inline-flex items-center px-4 py-2 border text-sm font-medium bg-white text-gray-500 hover:bg-gray-50"
                    >
                        1
                    </button>
                );
                pageNumbers.push(ellipsis);
                for (let i = totalPages - 2; i <= totalPages; i++) {
                    pageNumbers.push(
                        <button
                            key={i}
                            onClick={() => setCurrentPage(i)}
                            className={`relative inline-flex items-center px-4 py-2 border text-sm font-medium ${currentPage === i
                                ? 'z-10 bg-[#344bfc] text-white border-[#344bfc]'
                                : 'bg-white text-gray-500 hover:bg-gray-50'
                                }`}
                        >
                            {i}
                        </button>
                    );
                }
            } else {
                pageNumbers.push(
                    <button
                        key={1}
                        onClick={() => setCurrentPage(1)}
                        className="relative inline-flex items-center px-4 py-2 border text-sm font-medium bg-white text-gray-500 hover:bg-gray-50"
                    >
                        1
                    </button>
                );
                pageNumbers.push(ellipsis);
                for (let i = currentPage - 1; i <= currentPage + 1; i++) {
                    pageNumbers.push(
                        <button
                            key={i}
                            onClick={() => setCurrentPage(i)}
                            className={`relative inline-flex items-center px-4 py-2 border text-sm font-medium ${currentPage === i
                                ? 'z-10 bg-[#344bfc] text-white border-[#344bfc]'
                                : 'bg-white text-gray-500 hover:bg-gray-50'
                                }`}
                        >
                            {i}
                        </button>
                    );
                }
                pageNumbers.push(ellipsis);
                pageNumbers.push(
                    <button
                        key={totalPages}
                        onClick={() => setCurrentPage(totalPages)}
                        className="relative inline-flex items-center px-4 py-2 border text-sm font-medium bg-white text-gray-500 hover:bg-gray-50"
                    >
                        {totalPages}
                    </button>
                );
            }
        }
        return pageNumbers;
    };

    const EmptyState = () => (
        <div className="flex flex-col items-center justify-center h-64 p-8">
            <div className="text-gray-400 mb-6">
                <List size={48} />
            </div>
            <h3 className="text-lg font-medium text-gray-900 mb-2">No safety documents found</h3>
            <p className="text-sm text-gray-500 mb-6 text-center">
                {searchQuery
                    ? "No results match your search criteria"
                    : projects.length === 0
                        ? "You need to create a project before you can manage safety documents"
                        : !activeProject
                            ? "Please select a project to view documents"
                            : "This folder is empty. Upload files or create folders to get started"
                }
            </p>
            {projects.length === 0 && (
                <button
                    onClick={() => setShowCreateProjectModal(true)}
                    className="text-[#344bfc] underline font-medium hover:text-blue-700"
                >
                    Create a new project
                </button>
            )}
            {projects.length > 0 && !activeProject && (
                <button
                    onClick={() => setIsSidebarOpen(true)}
                    className="text-[#344bfc] underline font-medium hover:text-blue-700"
                >
                    Select a project
                </button>
            )}
        </div>
    );



    return (
        <div className="flex flex-col h-full">
            {/* Sidebar for Project Selection */}
            <ProjectSidebar
                projects={projects}
                activeProject={activeProject}
                setActiveProject={handleProjectSelect}
                isOpen={isSidebarOpen}
                setIsOpen={setIsSidebarOpen}
            />

            {/* Header */}
            <Header
                activeProjectName={activeProjectName}
                setIsSidebarOpen={setIsSidebarOpen}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                view={view}
                setView={setView}
            />

            {/* Main Content Area */}
            <div className="flex-1 flex flex-col">
                {/* Toolbar */}
                {(currentFolderId || rootFolderId) && (
                    <DocumentToolbar
                        folderId={currentFolderId || rootFolderId}
                        documentTypes={SAFETY_DOCUMENT_TYPES}
                        projectId={activeProject}
                        featureType="safety"
                        rootFolderId={rootFolderId}
                        onDocumentsChange={() => {
                            setTimeout(() => {
                                if (activeProject) {
                                    fetchDocumentsWithProject(activeProject, currentFolderId || rootFolderId, currentPage);
                                } else {
                                    fetchDocuments(null, currentFolderId || rootFolderId, currentPage);
                                }
                            }, 500);
                        }}
                    />
                )}


                <DocumentBreadcrumbs
                    path={path}
                    onNavigate={handleBreadcrumbClick}
                />

                {/* Document List */}
                <div className="flex-1 bg-white rounded-lg shadow-md overflow-hidden">
                    {isLoading ? (
                        <div className="flex justify-center items-center h-64">
                            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
                        </div>
                    ) : documents.length === 0 ? (
                        <EmptyState />
                    ) : view === 'list' ? (
                        <div className="overflow-x-auto">
                            <table className="w-full">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Type</th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Modified</th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Size</th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200">
                                    {Array.isArray(documents) && documents.length > 0 ? (
                                        documents.map(doc => (
                                            <tr
                                                key={doc.id}
                                                onClick={(e) => handleRowClick(doc, e)}
                                                className="hover:bg-gray-100 cursor-pointer"
                                            >
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <div className="text-sm font-medium text-gray-900">{doc.file_type === 'folder' ? '📁' : '📄'} {doc.title}</div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                    {doc.type || 'Unknown'}
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                    {doc.uploaded_at ? format(new Date(doc.uploaded_at), 'MMM d, yyyy - h:mm a') : "No date available"}
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                    {doc.file_size ? `${(doc.file_size / 1024 / 1024).toFixed(2)} MB` : '-'}
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium action-menu">
                                                    {renderActionMenu(doc)}
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="5" className="text-center text-gray-500 py-6">
                                                No documents found.
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 p-4">
                            {Array.isArray(documents) && documents.length > 0 ? (
                                documents.map(doc => (
                                    <div
                                        key={doc.id}
                                        className="bg-white rounded-lg border border-gray-200 hover:shadow-md transition-shadow cursor-pointer"
                                        onClick={(e) => handleRowClick(doc, e)}
                                    >
                                        <div className="p-4">
                                            <div className="flex justify-between items-start">
                                                <div className="flex-1">
                                                    <div className="text-sm font-medium text-gray-900 truncate">
                                                        {doc.file_type === 'folder' ? '📁' : '📄'} {doc.title}
                                                    </div>
                                                    <p className="text-xs text-gray-500 mt-1">
                                                        {doc.type || 'Unknown Type'}
                                                    </p>
                                                    <p className="text-xs text-gray-500 mt-1">
                                                        {format(new Date(doc.uploaded_at), 'MMM d, yyyy')}
                                                    </p>
                                                    {doc.file_size && (
                                                        <p className="text-xs text-gray-500 mt-1">
                                                            {`${(doc.file_size / 1024 / 1024).toFixed(2)} MB`}
                                                        </p>
                                                    )}
                                                </div>
                                                <div className="action-menu ml-2">
                                                    {renderActionMenu(doc)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <p className="text-gray-500 text-center col-span-4">No documents found.</p>
                            )}
                        </div>
                    )}
                </div>

                {/* Pagination */}
                {documents.length > 0 && totalPages > 1 && (
                    <div className="mt-4 flex justify-center">
                        <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                            <button
                                onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                                disabled={currentPage === 1}
                                className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                            >
                                Previous
                            </button>
                            {renderPageNumbers()}
                            <button
                                onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                                disabled={currentPage === totalPages}
                                className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                            >
                                Next
                            </button>
                        </nav>
                    </div>
                )}
            </div>

            {/* Project Selection Modal */}
            {showModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white rounded-lg p-6 w-1/3 max-w-lg">
                        <h3 className="text-lg font-semibold mb-4">All Projects</h3>
                        <button
                            onClick={() => setShowModal(false)}
                            className="absolute top-4 right-4 text-gray-600 hover:text-gray-800"
                        >
                            ×
                        </button>
                        <input
                            type="text"
                            placeholder="Search projects..."
                            value={searchQuery}
                            onChange={handleSearch}
                            className="w-full p-2 border border-gray-300 rounded mb-4"
                        />
                        <ul className="max-h-60 overflow-y-auto">
                            {allProjects
                                .filter(proj => proj.name.toLowerCase().includes(searchQuery.toLowerCase()))
                                .map(proj => (
                                    <li
                                        key={proj.id}
                                        onClick={() => {
                                            handleProjectSelect(proj.id);
                                            setShowModal(false);
                                        }}
                                        className="cursor-pointer p-2 hover:bg-gray-100 rounded"
                                    >
                                        {proj.name}
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                </div>
            )}

            {/* Video Upload Modal */}
            {showVideoUploadModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white rounded-lg p-6 w-full max-w-md">
                        <h3 className="text-lg font-semibold mb-4">Upload Safety Video</h3>
                        <button
                            onClick={() => setShowVideoUploadModal(false)}
                            className="absolute top-4 right-4 text-gray-600 hover:text-gray-800"
                        >
                            ×
                        </button>

                        <div className="space-y-4">
                            <div
                                className="border-2 border-dashed border-gray-300 rounded-lg p-6 text-center cursor-pointer hover:border-gray-400"
                                onClick={() => document.getElementById('safety-video-input').click()}
                            >
                                <Upload className="mx-auto h-12 w-12 text-gray-400" />
                                <input
                                    id="safety-video-input"
                                    type="file"
                                    accept="video/*"
                                    onChange={handleVideoFileSelect}
                                    className="hidden"
                                />
                                <p className="mt-2 text-sm text-gray-600">
                                    {selectedVideoFile ? selectedVideoFile.name : 'Click to upload or drag and drop'}
                                </p>
                                <p className="mt-1 text-xs text-gray-500">
                                    MP4, AVI, MOV up to 100MB
                                </p>
                            </div>

                            {videoUploading && (
                                <div className="space-y-2">
                                    <div className="w-full bg-gray-200 rounded-full h-2.5">
                                        <div
                                            className="bg-blue-600 h-2.5 rounded-full transition-all duration-300"
                                            style={{ width: `${uploadProgress}%` }}
                                        />
                                    </div>
                                    <p className="text-sm text-gray-600 text-center">
                                        Uploading... {uploadProgress}%
                                    </p>
                                </div>
                            )}

                            <div className="flex justify-end space-x-3">
                                <button
                                    onClick={() => setShowVideoUploadModal(false)}
                                    className="px-4 py-2 border rounded-md text-gray-700"
                                    disabled={videoUploading}
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={handleVideoUpload}
                                    disabled={!selectedVideoFile || videoUploading || !activeProject}
                                    className="px-4 py-2 bg-blue-600 text-white rounded-md disabled:opacity-50"
                                >
                                    {videoUploading ? 'Uploading...' : 'Upload'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* Delete Confirmation Modal */}
            {showDeleteModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white rounded-lg p-6 w-full max-w-md">
                        <h3 className="text-lg font-semibold mb-2">Delete Document</h3>
                        <p className="mb-4">
                            Are you sure you want to delete {documentToDelete?.title}? This action cannot be undone.
                        </p>
                        <div className="flex justify-end space-x-3">
                            <button
                                onClick={() => setShowDeleteModal(false)}
                                className="px-4 py-2 border rounded-md"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleDelete}
                                className="px-4 py-2 bg-red-600 text-white rounded-md"
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {/* Share Modal */}
            <ShareEmailModal
                isOpen={showShareModal}
                onClose={() => setShowShareModal(false)}
                document={documentToShare}
                onShare={handleShareSubmit}
            />

            {/* Annotator */}
            {showAnnotator && selectedDocument && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white rounded-lg w-full max-w-6xl p-4 h-[90vh] flex flex-col">
                        <div className="flex justify-between items-center mb-2">
                            <h3 className="text-lg font-medium">
                                Annotating: {selectedDocument.title}
                            </h3>
                            <button
                                onClick={handleCloseAnnotator}
                                className="text-gray-600 hover:text-gray-800"
                            >
                                ×
                            </button>
                        </div>
                        <div className="flex-1 overflow-hidden">
                            <DocumentAnnotator
                                documentId={selectedDocument.id}
                                projectId={activeProject}
                                onSave={() => {
                                    handleCloseAnnotator();
                                    if (activeProject) {
                                        fetchDocuments(activeProject, currentFolderId, currentPage);
                                    } else {
                                        fetchDocuments(null, currentFolderId, currentPage);
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>
            )}
            {showCreateProjectModal && (
                <CreateProjectModal
                    closeModal={() => setShowCreateProjectModal(false)}
                    onProjectCreated={handleProjectCreated}
                />
            )}
        </div>
    );
};

export default SafetyDocuments;