import React, { useState, useEffect } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Card, CardHeader, CardContent, CardTitle } from "../ui/cardui";
import { Button } from "../ui/button";
import { Info, CreditCard, Check, Loader2 } from 'lucide-react';
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "../ui/tooltip";
import { useToast } from "../ui/use-toast";
import { Badge } from "../ui/badge";
import UpgradeDialog from './UpgradeDialog';
import SubscriptionPlansContent from './SubscriptionPlansContent';
import api from '../../services/api';
import { primaryButtonClasses, secondaryButtonClasses } from '../common/ButtonStyles';
import { FEATURES } from '../../contexts/FeatureAccessContext';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const formatFeatureName = (name) => {
    return name
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};

export default function SubscriptionManagement() {
    const [subscriptionData, setSubscriptionData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [planFeatures, setPlanFeatures] = useState([]);
    const [availablePlans, setAvailablePlans] = useState([]);
    const [currentPlanDetails, setCurrentPlanDetails] = useState(null);
    const [showUpgradeDialog, setShowUpgradeDialog] = useState(false);
    const [featureTypes, setFeatureTypes] = useState([]);
    const [currentFeatureType, setCurrentFeatureType] = useState(null);
    const [allFeaturesType, setAllFeaturesType] = useState(null);
    const [billingCycle, setBillingCycle] = useState('monthly');
    const { toast } = useToast();

    const loadData = async () => {
        setLoading(true);
        try {
            const [subscriptionResponse, featureTypesResponse] = await Promise.all([
                api.get('subscription-debug/'),
                api.get('feature-types/')
            ]);

            setSubscriptionData(subscriptionResponse.data);
            const features = featureTypesResponse.data.results || [];
            setFeatureTypes(features);

            // Find current feature type and all features type
            const currentFeature = features.find(f =>
                f.code === subscriptionResponse.data?.current_subscription?.feature_type?.code
            );
            const allFeatures = features.find(f => f.code === 'ALL');

            setCurrentFeatureType(currentFeature);
            setAllFeaturesType(allFeatures);

            if (subscriptionResponse.data?.current_subscription?.plan_id) {
                const featuresResponse = await api.get('features/', {
                    params: { plan_id: subscriptionResponse.data.current_subscription.plan_id }
                });
                setPlanFeatures(featuresResponse.data?.results || []);
            }
        } catch (error) {
            console.error('Error loading data:', error);
            toast({
                variant: "destructive",
                title: "Error",
                description: "Failed to load subscription data"
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadData();
    }, []);

    const handleUpgrade = async (upgradeDetails) => {
        try {
            const selectedPlan = availablePlans.find(
                plan => plan.name.toLowerCase() === upgradeDetails.plan.toLowerCase()
            );

            if (!selectedPlan) {
                throw new Error('Selected plan not found');
            }

            const priceId = upgradeDetails.billingCycle === 'yearly'
                ? selectedPlan.stripe_price_id_yearly
                : selectedPlan.stripe_price_id_monthly;

            const response = await api.post('create-checkout-session/', {
                plan: upgradeDetails.plan.toLowerCase(),
                billing_cycle: upgradeDetails.billingCycle,
                price_id: priceId,
                user_count: upgradeDetails.userCount,
                feature_type: selectedPlan.feature_type?.code || FEATURES.ALL_FEATURES
            });

            if (response.data.checkout_url) {
                window.location.href = response.data.checkout_url;
            } else {
                throw new Error('No checkout URL received');
            }
        } catch (error) {
            console.error('Upgrade error:', error);
            toast({
                variant: "destructive",
                title: "Error",
                description: error.message || "Failed to upgrade subscription"
            });
        }
    };

    const handleUpgradeToAnnual = async () => {
        if (!currentFeatureType) return;

        try {
            const userCount = subscriptionData.current_subscription.user_count;
            const pricing = currentFeatureType.pricing_type === 'PER_USER'
                ? currentFeatureType.yearly_price_per_user * userCount
                : currentFeatureType.flat_rate;

            const response = await api.post('create-checkout-session/', {
                feature_type: currentFeatureType.code,
                billing_cycle: 'yearly',
                price_id: currentFeatureType.stripe_price_id_yearly,
                user_count: currentFeatureType.pricing_type === 'PER_USER' ? userCount : null
            });

            if (response.data.checkout_url) {
                window.location.href = response.data.checkout_url;
            } else {
                throw new Error('No checkout URL received');
            }
        } catch (error) {
            console.error('Annual upgrade error:', error);
            toast({
                variant: "destructive",
                title: "Error",
                description: "Failed to upgrade to annual billing"
            });
        }
    };

    const handleUpgradeToAllFeatures = async () => {
        if (!allFeaturesType) return;

        try {
            const response = await api.post('create-checkout-session/', {
                feature_type: 'ALL',
                billing_cycle: 'yearly',
                price_id: allFeaturesType.stripe_price_id_flat
            });

            if (response.data.checkout_url) {
                window.location.href = response.data.checkout_url;
            } else {
                throw new Error('No checkout URL received');
            }
        } catch (error) {
            console.error('Upgrade error:', error);
            toast({
                variant: "destructive",
                title: "Error",
                description: "Failed to upgrade plan"
            });
        }
    };

    if (loading) {
        return (
            <div className="flex items-center justify-center min-h-screen">
                <Loader2 className="h-8 w-8 animate-spin" />
            </div>
        );
    }

    if (!subscriptionData?.current_subscription || subscriptionData.current_subscription === "No subscription found for tenant") {
        return (
            <Elements stripe={stripePromise}>
                <SubscriptionPlansContent />
            </Elements>
        );
    }

    const subscription = subscriptionData.current_subscription;
    const user = subscriptionData.user;

    // Handle trial subscription case
    if (subscription.is_trial && subscription.remaining_trial_days > 0) {
        return (
            <Elements stripe={stripePromise}>
                <div className="container max-w-4xl mx-auto py-10 px-4">
                    <div className="bg-primary/10 rounded-lg p-6 mb-8">
                        <h1 className="text-3xl font-bold mb-2">
                            Welcome to your trial period, {user?.tenant_name}
                        </h1>
                        <p className="text-lg">
                            {subscription.remaining_trial_days} days remaining in your trial
                        </p>
                    </div>
                    <SubscriptionPlansContent />
                </div>
            </Elements>
        );
    }

    // Only show upgrade cards if relevant
    const showAnnualUpgrade = subscription.billing_cycle === 'monthly' && currentFeatureType;
    const showPlanUpgrade = currentFeatureType?.code !== 'ALL' && allFeaturesType;

    return (
        <div className="container max-w-4xl mx-auto py-10 px-4">
            <div className="bg-primary/10 rounded-lg p-6 mb-8">
                <h1 className="text-3xl font-bold mb-2">
                    Thanks for being a {subscription.plan_name} user, {user?.tenant_name}
                </h1>
                <p className="text-lg">
                    Current plan: {subscription.billing_cycle === 'yearly' ? 'Annual' : 'Monthly'} billing
                </p>
            </div>

            {/* Current Plan Annual Section */}
            {currentFeatureType && subscription.billing_cycle === 'monthly' && (
                <Card className="mb-8">
                    <CardContent className="p-6">
                        <div className="flex flex-col space-y-6">
                            <div className="flex justify-between items-start">
                                <div>
                                    <h2 className="text-2xl font-semibold mb-2">
                                        {currentFeatureType.name}
                                    </h2>
                                    <p className="text-lg text-gray-600">
                                        Switch to annual billing and save 25%
                                    </p>
                                </div>
                                <div className="text-right">
                                    <div className="flex items-baseline justify-end">
                                        <span className="text-4xl font-bold">
                                            ${currentFeatureType.pricing_type === 'PER_USER'
                                                ? currentFeatureType.yearly_price_per_user
                                                : currentFeatureType.flat_rate}
                                        </span>
                                        <span className="text-xl text-gray-600 ml-1">
                                            {currentFeatureType.pricing_type === 'PER_USER' ? '/user/year' : '/year'}
                                        </span>
                                    </div>
                                    <p className="text-lg text-gray-600 mt-1">
                                        {currentFeatureType.pricing_type === 'PER_USER'
                                            ? `Up to ${currentFeatureType.max_users} users`
                                            : 'Unlimited users included'}
                                    </p>
                                </div>
                            </div>

                            <div>
                                <div className="inline-flex items-center px-4 py-2 rounded-full bg-blue-100 text-blue-600 text-lg font-medium">
                                    Save 25%
                                </div>
                            </div>

                            <Button
                                onClick={handleUpgradeToAnnual}
                                className="w-full bg-blue-600 hover:bg-blue-700 text-white text-lg py-6 rounded-lg"
                            >
                                Upgrade to Annual Plan
                            </Button>
                        </div>
                    </CardContent>
                </Card>
            )}

            {/* All Features Upgrade Section */}
            {showPlanUpgrade && (
                <Card className="mb-8">
                    <CardContent className="pt-6">
                        <div className="flex flex-col space-y-4">
                            <div className="flex items-center justify-between">
                                <div>
                                    <h3 className="text-lg font-semibold mb-1">
                                        All Features Plan
                                    </h3>
                                    <p className="text-sm text-muted-foreground">
                                        Get access to all features with unlimited users
                                    </p>
                                </div>
                                <div className="text-right">
                                    <div className="text-2xl font-bold">
                                        ${allFeaturesType.flat_rate}
                                        <span className="text-base font-normal text-muted-foreground">/year</span>
                                    </div>
                                    <div className="text-sm text-muted-foreground">
                                        Unlimited users included
                                    </div>
                                </div>
                            </div>

                            <div className="flex items-center gap-4">
                                <Badge variant="secondary" className="bg-blue-100 text-blue-700">
                                    All Features Included
                                </Badge>
                            </div>

                            <div className="pt-4">
                                <Button
                                    onClick={handleUpgradeToAllFeatures}
                                    className="w-full bg-blue-600 hover:bg-blue-700 text-white"
                                >
                                    Upgrade to All Features
                                </Button>
                            </div>
                        </div>
                    </CardContent>
                </Card>
            )}

            {/* Subscription Details Card */}
            <Card className="mb-8">
                <CardContent className="pt-6">
                    <div className="grid grid-cols-3 gap-6">
                        <div>
                            <p className="text-sm text-muted-foreground mb-1">Account type</p>
                            <p className="font-semibold capitalize">{subscription.plan_name} plan</p>
                        </div>
                        <div>
                            <p className="text-sm text-muted-foreground mb-1">Billing cycle</p>
                            <p className="font-semibold capitalize">{subscription.billing_cycle} plan</p>
                        </div>
                        <div>
                            <p className="text-sm text-muted-foreground mb-1">Next invoice</p>
                            <p className="font-semibold">
                                {subscription.next_billing_date
                                    ? new Date(subscription.next_billing_date).toLocaleDateString()
                                    : 'Not available'}
                            </p>
                        </div>
                    </div>
                </CardContent>
            </Card>
            {/* Payment Information Card */}
            {subscription.stripe_customer_id && (
                <Card>
                    <CardHeader>
                        <CardTitle>Payment Information</CardTitle>
                    </CardHeader>
                    <CardContent>
                        <div className="flex items-center gap-2">
                            <CreditCard className="h-5 w-5" />
                            <span>Connected to Stripe</span>
                            <span className="text-sm text-muted-foreground">
                                (ID: {subscription.stripe_customer_id})
                            </span>
                        </div>
                    </CardContent>
                </Card>
            )}

            {/* Upgrade Dialog */}
            {showUpgradeDialog && (
                <UpgradeDialog
                    currentPlan={subscriptionData.current_subscription}
                    currentBillingCycle={subscriptionData.current_subscription.billing_cycle}
                    availablePlans={availablePlans.filter(plan => {
                        const isActivePlan = plan.is_active && plan.name.toLowerCase() !== 'trial';

                        if (subscription.is_trial) {
                            return isActivePlan;
                        }

                        return (
                            isActivePlan &&
                            (plan.name.toLowerCase() === subscription.plan_name.toLowerCase() ||
                                parseFloat(plan.monthly_price) > parseFloat(subscription.monthly_price))
                        );
                    }).map(plan => ({
                        ...plan,
                        features: planFeatures.filter(feature =>
                            plan.features.includes(feature.id) ||
                            plan.features.some(f => f.id === feature.id)
                        ),
                        isCurrentPlan: plan.name.toLowerCase() === subscription.plan_name.toLowerCase()
                    }))}
                    onUpgrade={handleUpgrade}
                    onCancel={() => setShowUpgradeDialog(false)}
                    isLoading={loading}
                    isTrial={subscription.is_trial}
                    showAllPlans={subscription.is_trial}
                    planFeatures={planFeatures}
                />
            )}
        </div>
    );
}