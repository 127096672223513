import React, { useState, useEffect } from 'react';
import { PlusIcon, FilterIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { showToast } from '../../utils/toast';
import api from '../../services/api';
import { primaryButtonClasses } from '../common/ButtonStyles';

ChartJS.register(ArcElement, Tooltip, Legend);

const RFIManagementSystem = ({ projectId }) => {
  const [rfis, setRFIs] = useState([]);
  const [rfisTotal, setrfisTotal] = useState([]);
  const [metrics, setMetrics] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [filterStatus, setFilterStatus] = useState('all');

  useEffect(() => {
    fetchRFIs();
    fetchRFITotal();
  }, [projectId]);

  const fetchRFIs = async () => {
    try {
      const response = await api.get(`rfis/`);
      setRFIs(response.data.results);
      setrfisTotal(response.data.count);
      console.log("RFI Count: ", rfisTotal);
    } catch (error) {
      handleApiError(error, 'Error fetching RFIs');
    }
  };

  const fetchRFITotal = async () => {
    try {
      const response = await api.get(`rfis/`);
      setrfisTotal(response.data.count);
    } catch (error) {
      handleApiError(error, 'Error fetching RFIs');
    }
  };


  const handleApiError = (error, defaultMessage) => {
    if (error.response) {
      if (error.response.status === 401) {
        showToast.error('Session expired. Please log in again.');
        //logout();
      } else {
        showToast.error(`${defaultMessage}: ${error.response.data.message || 'Unknown error'}`);
      }
    } else if (error.request) {
      showToast.error('No response from server. Please try again later.');
    } else {
      showToast.error('An unexpected error occurred. Please try again.');
    }
    console.error('API error:', error);
  };

  const filteredRFIs = rfis.filter(rfi => filterStatus === 'all' || rfi.status === filterStatus);

  const RFIDashboard = () => (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-8">
      <MetricCard title="Open RFIs" value={metrics.open} color="bg-blue-100 text-blue-800" />
      <MetricCard title="Pending RFIs" value={metrics.pending} color="bg-yellow-100 text-yellow-800" />
      <MetricCard title="Closed RFIs" value={metrics.closed} color="bg-green-100 text-green-800" />
      <MetricCard title="Total RFIs" value={rfisTotal} color="bg-purple-100 text-purple-800" />
    </div>
  );

  const MetricCard = ({ title, value, color }) => (
    <div className={`p-4 rounded-lg shadow ${color}`}>
      <h3 className="text-lg font-semibold mb-2">{title}</h3>
      <p className="text-3xl font-bold">{value}</p>
    </div>
  );

  const RFIStatusChart = () => {
    const data = {
      labels: ['Open', 'Pending', 'Closed'],
      datasets: [
        {
          data: [metrics.open, metrics.pending, metrics.closed],
          backgroundColor: ['#3B82F6', '#FBBF24', '#10B981'],
        },
      ],
    };

    return (
      <div className="w-64 h-64 mx-auto mb-8">
        <Doughnut data={data} />
      </div>
    );
  };

  const RFITable = () => (
    <div className="bg-white shadow-md rounded-lg overflow-hidden">
      <div className="flex justify-between items-center p-4 bg-gray-50">
        <h2 className="text-xl font-semibold">RFIs</h2>
        <div className="flex space-x-2">
          <select
            className="border rounded-md p-2"
            value={filterStatus}
            onChange={(e) => setFilterStatus(e.target.value)}
          >
            <option value="all">All Status</option>
            <option value="open">Open</option>
            <option value="pending">Pending</option>
            <option value="closed">Closed</option>
          </select>
          <button className={primaryButtonClasses}>
            <PlusIcon className="h-5 w-5 mr-2" />
            New RFI
          </button>
        </div>
      </div>
      <table className="w-full">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">RFI Number</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Title</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Submission Date</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Deadline</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {filteredRFIs.map((rfi) => (
            <tr key={rfi.id}>
              <td className="px-6 py-4 whitespace-nowrap">{rfi.rfi_number}</td>
              <td className="px-6 py-4 whitespace-nowrap">{rfi.title}</td>
              <td className="px-6 py-4 whitespace-nowrap">
                <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${rfi.status === 'open' ? 'bg-green-100 text-green-800' :
                  rfi.status === 'pending' ? 'bg-yellow-100 text-yellow-800' :
                    'bg-red-100 text-red-800'
                  }`}>
                  {rfi.status}
                </span>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">{new Date(rfi.submission_date).toLocaleDateString()}</td>
              <td className="px-6 py-4 whitespace-nowrap">{new Date(rfi.response_deadline).toLocaleDateString()}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
        <div className="flex-1 flex justify-between sm:hidden">
          <button onClick={() => setCurrentPage(page => Math.max(page - 1, 1))} className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
            Previous
          </button>
          <button onClick={() => setCurrentPage(page => page + 1)} className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
            Next
          </button>
        </div>
        <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
          <div>
            <p className="text-sm text-gray-700">
              Showing <span className="font-medium">1</span> to <span className="font-medium">10</span> of{' '}
              <span className="font-medium">{filteredRFIs.length}</span> results
            </p>
          </div>
          <div>
            <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
              <button onClick={() => setCurrentPage(page => Math.max(page - 1, 1))} className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                <span className="sr-only">Previous</span>
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
              </button>
              <button onClick={() => setCurrentPage(page => page + 1)} className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                <span className="sr-only">Next</span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-8">RFI Management</h1>
      <RFIDashboard />
      <RFIStatusChart />
      <RFITable />
    </div>
  );
};

export default RFIManagementSystem;