import React, { useState, useEffect } from 'react';
import { Button } from "../ui/button";
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from "../ui/cardui";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../ui/select";
import { Alert, AlertDescription } from '../ui/alert';
import { Input } from "../ui/input";
import { formatCurrency } from '../../utils/formatter';
import budgetService from '../../services/budgetService';
import { useToast } from '../ui/use-toast';
import {
    LineChart, BarChart, Line, Bar,
    XAxis, YAxis, CartesianGrid, Tooltip, Legend,
    ResponsiveContainer, ReferenceLine
} from 'recharts';
import {
    AlertCircle, RefreshCw, Calendar,
    TrendingUp, TrendingDown, DollarSign
} from 'lucide-react';
import { Table, TableHeader, TableRow, TableHead, TableBody, TableCell } from "../ui/table";
import { Separator } from "../ui/separator";

export default function EVMMetricsPanel({ projectId, budgetId, onRefresh }) {
    const { toast } = useToast();
    const [dateRange, setDateRange] = useState('3months');
    const [calculationDate, setCalculationDate] = useState(new Date().toISOString().split('T')[0]);
    const [evmData, setEVMData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [refreshing, setRefreshing] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        loadEVMData();
    }, [projectId, dateRange]);

    const loadEVMData = async () => {
        try {
            setLoading(true);
            setError(null);

            // Fetch EVM metrics
            const response = await budgetService.getEVMMetrics(projectId);
            setEVMData(response);
        } catch (error) {
            console.error('Error loading EVM data:', error);
            setError('Failed to load Earned Value Management data. Please try again later.');
            toast({
                title: "Error",
                description: "Failed to load EVM metrics",
                variant: "destructive"
            });
        } finally {
            setLoading(false);
        }
    };

    const handleCalculateEVM = async () => {
        try {
            setRefreshing(true);

            // Calculate EVM metrics for the specified date
            await budgetService.calculateEVMMetrics(projectId, calculationDate);

            // Reload data
            await loadEVMData();

            if (onRefresh) {
                onRefresh();
            }

            toast({
                title: "Success",
                description: "EVM metrics calculated successfully"
            });
        } catch (error) {
            console.error('Error calculating EVM metrics:', error);
            toast({
                title: "Error",
                description: "Failed to calculate EVM metrics",
                variant: "destructive"
            });
        } finally {
            setRefreshing(false);
        }
    };

    const getStatusIndicator = (value, goodDirection = 'up') => {
        if (goodDirection === 'up') {
            if (value > 1.1) return <TrendingUp className="text-green-600" />;
            if (value < 0.9) return <TrendingDown className="text-red-600" />;
            return <span className="text-yellow-500">→</span>;
        } else {
            if (value < 0.9) return <TrendingUp className="text-green-600" />;
            if (value > 1.1) return <TrendingDown className="text-red-600" />;
            return <span className="text-yellow-500">→</span>;
        }
    };

    if (loading) {
        return (
            <div className="flex items-center justify-center h-64">
                <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
                <span className="ml-3">Loading EVM data...</span>
            </div>
        );
    }

    if (error) {
        return (
            <Alert>
                <AlertCircle className="h-4 w-4" />
                <AlertDescription>
                    {error}
                    <Button onClick={loadEVMData} variant="outline" className="ml-2" disabled={refreshing}>
                        {refreshing ? 'Refreshing...' : 'Try Again'}
                    </Button>
                </AlertDescription>
            </Alert>
        );
    }

    // Format metrics data
    const metrics = evmData?.results || [];
    const latestMetric = metrics?.length > 0 ? metrics[0] : null;

    return (
        <div className="space-y-6">
            <div className="flex justify-between items-center">
                <h2 className="text-2xl font-bold">Earned Value Management</h2>

                <div className="flex space-x-2">
                    <div className="flex gap-2 items-center">
                        <Input
                            type="date"
                            value={calculationDate}
                            onChange={(e) => setCalculationDate(e.target.value)}
                            className="w-40"
                        />
                        <Button onClick={handleCalculateEVM} disabled={refreshing}>
                            {refreshing ? 'Calculating...' : 'Calculate EVM'}
                            <RefreshCw className={`ml-2 h-4 w-4 ${refreshing ? 'animate-spin' : ''}`} />
                        </Button>
                    </div>
                </div>
            </div>

            {/* EVM Summary Cards */}
            {latestMetric && (
                <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                    <Card>
                        <CardContent className="pt-6">
                            <div className="flex justify-between">
                                <div>
                                    <p className="text-sm text-gray-500">CPI</p>
                                    <div className="flex items-center">
                                        <p className={`text-2xl font-bold ${latestMetric.cost_performance_index >= 1 ? 'text-green-600' : 'text-red-600'}`}>
                                            {latestMetric.cost_performance_index.toFixed(2)}
                                        </p>
                                        <span className="ml-2">
                                            {getStatusIndicator(latestMetric.cost_performance_index)}
                                        </span>
                                    </div>
                                    <p className="text-xs text-gray-500">
                                        Cost Performance Index
                                    </p>
                                </div>
                            </div>
                        </CardContent>
                    </Card>

                    <Card>
                        <CardContent className="pt-6">
                            <div className="flex justify-between">
                                <div>
                                    <p className="text-sm text-gray-500">SPI</p>
                                    <div className="flex items-center">
                                        <p className={`text-2xl font-bold ${latestMetric.schedule_performance_index >= 1 ? 'text-green-600' : 'text-red-600'}`}>
                                            {latestMetric.schedule_performance_index.toFixed(2)}
                                        </p>
                                        <span className="ml-2">
                                            {getStatusIndicator(latestMetric.schedule_performance_index)}
                                        </span>
                                    </div>
                                    <p className="text-xs text-gray-500">
                                        Schedule Performance Index
                                    </p>
                                </div>
                            </div>
                        </CardContent>
                    </Card>

                    <Card>
                        <CardContent className="pt-6">
                            <div className="flex justify-between">
                                <div>
                                    <p className="text-sm text-gray-500">CV</p>
                                    <div className="flex items-center">
                                        <p className={`text-2xl font-bold ${latestMetric.cost_variance > 0 ? 'text-green-600' : 'text-red-600'}`}>
                                            {formatCurrency(latestMetric.cost_variance)}
                                        </p>
                                    </div>
                                    <p className="text-xs text-gray-500">
                                        Cost Variance
                                    </p>
                                </div>
                            </div>
                        </CardContent>
                    </Card>

                    <Card>
                        <CardContent className="pt-6">
                            <div className="flex justify-between">
                                <div>
                                    <p className="text-sm text-gray-500">SV</p>
                                    <div className="flex items-center">
                                        <p className={`text-2xl font-bold ${latestMetric.schedule_variance > 0 ? 'text-green-600' : 'text-red-600'}`}>
                                            {formatCurrency(latestMetric.schedule_variance)}
                                        </p>
                                    </div>
                                    <p className="text-xs text-gray-500">
                                        Schedule Variance
                                    </p>
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                </div>
            )}

            {/* Main EVM Chart */}
            <Card>
                <CardHeader>
                    <CardTitle>EVM Performance Metrics</CardTitle>
                    <CardDescription>Earned Value, Planned Value, and Actual Cost over time</CardDescription>
                </CardHeader>
                <CardContent>
                    <div className="h-80">
                        <ResponsiveContainer width="100%" height="100%">
                            <LineChart data={metrics}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="calculation_date" />
                                <YAxis />
                                <Tooltip
                                    formatter={(value, name) => [
                                        formatCurrency(value),
                                        name === 'planned_value' ? 'Planned Value (PV)' :
                                            name === 'earned_value' ? 'Earned Value (EV)' :
                                                name === 'actual_cost' ? 'Actual Cost (AC)' : name
                                    ]}
                                />
                                <Legend />
                                <Line type="monotone" dataKey="planned_value" stroke="#8884d8" name="PV" />
                                <Line type="monotone" dataKey="earned_value" stroke="#82ca9d" name="EV" />
                                <Line type="monotone" dataKey="actual_cost" stroke="#ff7300" name="AC" />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                </CardContent>
            </Card>

            {/* Performance Indices Chart */}
            <Card>
                <CardHeader>
                    <CardTitle>Performance Indices</CardTitle>
                    <CardDescription>CPI and SPI over time (values above 1.0 indicate good performance)</CardDescription>
                </CardHeader>
                <CardContent>
                    <div className="h-60">
                        <ResponsiveContainer width="100%" height="100%">
                            <LineChart data={metrics}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="calculation_date" />
                                <YAxis domain={[0, 2]} />
                                <Tooltip formatter={(value) => value.toFixed(2)} />
                                <Legend />
                                <ReferenceLine y={1} stroke="#666" strokeDasharray="3 3" />
                                <Line
                                    type="monotone"
                                    dataKey="cost_performance_index"
                                    stroke="#8884d8"
                                    name="CPI"
                                    strokeWidth={2}
                                />
                                <Line
                                    type="monotone"
                                    dataKey="schedule_performance_index"
                                    stroke="#82ca9d"
                                    name="SPI"
                                    strokeWidth={2}
                                />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                </CardContent>
            </Card>

            {/* EVM Forecasting */}
            {latestMetric && (
                <Card>
                    <CardHeader>
                        <CardTitle>EVM Forecasting</CardTitle>
                        <CardDescription>Project forecasts based on current performance</CardDescription>
                    </CardHeader>
                    <CardContent>
                        <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-6">
                            <div className="p-4 bg-blue-50 rounded-lg">
                                <div className="text-sm text-blue-700 mb-1">Budget at Completion (BAC)</div>
                                <div className="text-2xl font-bold">
                                    {formatCurrency(latestMetric.budget_at_completion)}
                                </div>
                            </div>

                            <div className="p-4 bg-green-50 rounded-lg">
                                <div className="text-sm text-green-700 mb-1">Estimate at Completion (EAC)</div>
                                <div className="text-2xl font-bold">
                                    {formatCurrency(latestMetric.estimate_at_completion)}
                                </div>
                            </div>

                            <div className="p-4 bg-yellow-50 rounded-lg">
                                <div className="text-sm text-yellow-700 mb-1">Estimate to Complete (ETC)</div>
                                <div className="text-2xl font-bold">
                                    {formatCurrency(latestMetric.estimate_to_complete)}
                                </div>
                            </div>

                            <div className={`p-4 ${latestMetric.variance_at_completion >= 0 ? 'bg-green-50' : 'bg-red-50'} rounded-lg`}>
                                <div className={`text-sm ${latestMetric.variance_at_completion >= 0 ? 'text-green-700' : 'text-red-700'} mb-1`}>
                                    Variance at Completion (VAC)
                                </div>
                                <div className={`text-2xl font-bold ${latestMetric.variance_at_completion >= 0 ? 'text-green-700' : 'text-red-700'}`}>
                                    {formatCurrency(latestMetric.variance_at_completion)}
                                </div>
                            </div>
                        </div>

                        <Separator className="my-6" />

                        <div className="space-y-4">
                            <h3 className="text-lg font-medium">EVM Analysis Interpretation</h3>

                            <div className="space-y-2 text-sm">
                                <p><strong>Cost Performance</strong>: {latestMetric.cost_performance_index > 1
                                    ? 'Project is under budget (good)'
                                    : latestMetric.cost_performance_index < 1
                                        ? 'Project is over budget (caution)'
                                        : 'Project is on budget'
                                }</p>

                                <p><strong>Schedule Performance</strong>: {latestMetric.schedule_performance_index > 1
                                    ? 'Project is ahead of schedule (good)'
                                    : latestMetric.schedule_performance_index < 1
                                        ? 'Project is behind schedule (caution)'
                                        : 'Project is on schedule'
                                }</p>

                                <p><strong>Forecast</strong>: Based on current performance, this project is projected to {
                                    latestMetric.variance_at_completion > 0
                                        ? 'finish under budget by ' + formatCurrency(latestMetric.variance_at_completion)
                                        : 'exceed budget by ' + formatCurrency(Math.abs(latestMetric.variance_at_completion))
                                }</p>
                            </div>
                        </div>
                    </CardContent>
                </Card>
            )}

            {/* EVM Metrics Table */}
            <Card>
                <CardHeader>
                    <CardTitle>EVM Metrics History</CardTitle>
                    <CardDescription>Historical EVM calculations for this project</CardDescription>
                </CardHeader>
                <CardContent>
                    <Table>
                        <TableHeader>
                            <TableRow>
                                <TableHead>Date</TableHead>
                                <TableHead className="text-right">PV</TableHead>
                                <TableHead className="text-right">EV</TableHead>
                                <TableHead className="text-right">AC</TableHead>
                                <TableHead className="text-right">CPI</TableHead>
                                <TableHead className="text-right">SPI</TableHead>
                                <TableHead className="text-right">EAC</TableHead>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {metrics.map((metric, index) => (
                                <TableRow key={index}>
                                    <TableCell>{new Date(metric.calculation_date).toLocaleDateString()}</TableCell>
                                    <TableCell className="text-right">{formatCurrency(metric.planned_value)}</TableCell>
                                    <TableCell className="text-right">{formatCurrency(metric.earned_value)}</TableCell>
                                    <TableCell className="text-right">{formatCurrency(metric.actual_cost)}</TableCell>
                                    <TableCell className={`text-right ${metric.cost_performance_index >= 1 ? 'text-green-600' : 'text-red-600'}`}>
                                        {metric.cost_performance_index.toFixed(2)}
                                    </TableCell>
                                    <TableCell className={`text-right ${metric.schedule_performance_index >= 1 ? 'text-green-600' : 'text-red-600'}`}>
                                        {metric.schedule_performance_index.toFixed(2)}
                                    </TableCell>
                                    <TableCell className="text-right">{formatCurrency(metric.estimate_at_completion)}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </CardContent>
            </Card>
        </div>
    );
}