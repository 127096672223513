import React from 'react';
import { Card, CardContent } from "../../../../components/ui/cardui";
import { Input } from "../../../../components/ui/input";
import { Textarea } from "../../../../components/ui/textarea";
import { Label } from "../../../../components/ui/label";
import { Button } from "../../../../components/ui/button";
import { Plus, X } from 'lucide-react';

const BidScopeTab = ({
    isEditing,
    formData,
    formErrors,
    handleScopeChange,
    handleAddItem,
    handleRemoveItem,
    handleUpdateItem,
    bid
}) => {
    return (
        <Card>
            <CardContent className="pt-6">
                {isEditing ? (
                    <div className="space-y-6">
                        <div className="space-y-2">
                            <Label>General Requirements</Label>
                            <Textarea
                                value={formData.scope.general_requirements}
                                onChange={(e) => handleScopeChange('general_requirements', e.target.value)}
                                className={`w-full min-h-[100px] ${formErrors.general_requirements ? 'border-red-500' : ''}`}
                                rows={4}
                            />
                            {formErrors.general_requirements && (
                                <p className="text-sm text-red-500">{formErrors.general_requirements}</p>
                            )}
                        </div>

                        {/* Specific Requirements */}
                        <div className="space-y-2">
                            <Label>Specific Requirements</Label>
                            {formData.scope.specific_requirements.map((req, index) => (
                                <div key={index} className="flex flex-col sm:flex-row gap-2">
                                    <Input
                                        value={req}
                                        onChange={(e) => handleUpdateItem('specific_requirements', index, e.target.value)}
                                        className="flex-1"
                                    />
                                    <Button
                                        type="button"
                                        variant="ghost"
                                        onClick={() => handleRemoveItem('specific_requirements', index)}
                                        className="w-full sm:w-auto"
                                    >
                                        <X className="h-4 w-4" />
                                    </Button>
                                </div>
                            ))}
                            <Button
                                type="button"
                                variant="outline"
                                onClick={() => handleAddItem('specific_requirements')}
                                className="mt-2 w-full sm:w-auto"
                            >
                                <Plus className="h-4 w-4 mr-2" />
                                Add Requirement
                            </Button>
                        </div>

                        {/* Exclusions */}
                        <div className="space-y-2">
                            <Label>Exclusions</Label>
                            {formData.scope.exclusions.map((exclusion, index) => (
                                <div key={index} className="flex flex-col sm:flex-row gap-2">
                                    <Input
                                        value={exclusion}
                                        onChange={(e) => handleUpdateItem('exclusions', index, e.target.value)}
                                        className="flex-1"
                                    />
                                    <Button
                                        type="button"
                                        variant="ghost"
                                        onClick={() => handleRemoveItem('exclusions', index)}
                                        className="w-full sm:w-auto"
                                    >
                                        <X className="h-4 w-4" />
                                    </Button>
                                </div>
                            ))}
                            <Button
                                type="button"
                                variant="outline"
                                onClick={() => handleAddItem('exclusions')}
                                className="mt-2 w-full sm:w-auto"
                            >
                                <Plus className="h-4 w-4 mr-2" />
                                Add Exclusion
                            </Button>
                        </div>

                        {/* Deliverables */}
                        <div className="space-y-2">
                            <Label>Deliverables</Label>
                            {formData.scope.deliverables.map((deliverable, index) => (
                                <div key={index} className="flex flex-col sm:flex-row gap-2">
                                    <Input
                                        value={deliverable}
                                        onChange={(e) => handleUpdateItem('deliverables', index, e.target.value)}
                                        className="flex-1"
                                    />
                                    <Button
                                        type="button"
                                        variant="ghost"
                                        onClick={() => handleRemoveItem('deliverables', index)}
                                        className="w-full sm:w-auto"
                                    >
                                        <X className="h-4 w-4" />
                                    </Button>
                                </div>
                            ))}
                            <Button
                                type="button"
                                variant="outline"
                                onClick={() => handleAddItem('deliverables')}
                                className="mt-2 w-full sm:w-auto"
                            >
                                <Plus className="h-4 w-4 mr-2" />
                                Add Deliverable
                            </Button>
                        </div>
                    </div>
                ) : (
                    <div className="space-y-6">
                        <div>
                            <h3 className="text-sm font-medium text-gray-500">General Requirements</h3>
                            <p className="mt-1 text-sm sm:text-base whitespace-pre-wrap">
                                {bid.scope?.general_requirements || 'No general requirements specified'}
                            </p>
                        </div>

                        <div>
                            <h3 className="text-sm font-medium text-gray-500">Specific Requirements</h3>
                            {bid.scope?.specific_requirements?.length > 0 ? (
                                <ul className="mt-1 list-disc list-inside space-y-1">
                                    {bid.scope.specific_requirements.map((req, index) => (
                                        <li key={index} className="text-sm sm:text-base text-gray-700">{req}</li>
                                    ))}
                                </ul>
                            ) : (
                                <p className="mt-1 text-sm sm:text-base text-gray-500">No specific requirements specified</p>
                            )}
                        </div>

                        <div>
                            <h3 className="text-sm font-medium text-gray-500">Exclusions</h3>
                            {bid.scope?.exclusions?.length > 0 ? (
                                <ul className="mt-1 list-disc list-inside space-y-1">
                                    {bid.scope.exclusions.map((exclusion, index) => (
                                        <li key={index} className="text-sm sm:text-base text-gray-700">{exclusion}</li>
                                    ))}
                                </ul>
                            ) : (
                                <p className="mt-1 text-sm sm:text-base text-gray-500">No exclusions specified</p>
                            )}
                        </div>

                        <div>
                            <h3 className="text-sm font-medium text-gray-500">Deliverables</h3>
                            {bid.scope?.deliverables?.length > 0 ? (
                                <ul className="mt-1 list-disc list-inside space-y-1">
                                    {bid.scope.deliverables.map((deliverable, index) => (
                                        <li key={index} className="text-sm sm:text-base text-gray-700">{deliverable}</li>
                                    ))}
                                </ul>
                            ) : (
                                <p className="mt-1 text-sm sm:text-base text-gray-500">No deliverables specified</p>
                            )}
                        </div>
                    </div>
                )}
            </CardContent>
        </Card>
    );
};

export default BidScopeTab;