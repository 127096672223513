import React from 'react';
import PropTypes from 'prop-types';

const Skeleton = ({ className = '', variant = 'rectangular', animation = 'wave', ...props }) => {
    const baseClasses = 'bg-gray-200 relative overflow-hidden';
    const animationClasses = animation === 'wave' ? 'after:absolute after:inset-0 after:translate-x-[-100%] after:animate-[shimmer_2s_infinite] after:bg-gradient-to-r after:from-transparent after:via-white/60 after:to-transparent' : 'animate-pulse';

    const variantClasses = {
        circular: 'rounded-full',
        rectangular: 'rounded-md',
        text: 'rounded-md h-4 w-3/4'
    };

    const combinedClasses = `${baseClasses} ${animationClasses} ${variantClasses[variant]} ${className}`;

    return (
        <div className={combinedClasses} {...props} />
    );
};

Skeleton.propTypes = {
    className: PropTypes.string,
    variant: PropTypes.oneOf(['circular', 'rectangular', 'text']),
    animation: PropTypes.oneOf(['pulse', 'wave', 'none'])
};

const SkeletonGroup = ({ count = 1, gap = 4, as = 'div', children, className = '', ...props }) => {
    const Component = as;
    const items = Array(count).fill(null);

    return (
        <Component className={`flex flex-col gap-${gap} ${className}`} {...props}>
            {items.map((_, index) => (
                <React.Fragment key={index}>
                    {typeof children === 'function' ? children(index) : children}
                </React.Fragment>
            ))}
        </Component>
    );
};

SkeletonGroup.propTypes = {
    count: PropTypes.number,
    gap: PropTypes.number,
    as: PropTypes.elementType,
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    className: PropTypes.string
};

// Example usage components
const SkeletonText = ({ lines = 3, ...props }) => (
    <SkeletonGroup count={lines} gap={2} {...props}>
        <Skeleton variant="text" />
    </SkeletonGroup>
);

SkeletonText.propTypes = {
    lines: PropTypes.number
};

const SkeletonCard = () => (
    <div className="border rounded-lg p-4 max-w-sm w-full">
        <Skeleton className="h-48 w-full mb-4" />
        <SkeletonText lines={3} />
        <Skeleton className="h-8 w-24 mt-4" />
    </div>
);

const SkeletonTable = ({ rows = 5, columns = 4 }) => (
    <div className="w-full">
        <SkeletonGroup count={rows} gap={2}>
            <div className="flex gap-4">
                {Array(columns).fill(null).map((_, i) => (
                    <Skeleton key={i} className="h-8 flex-1" />
                ))}
            </div>
        </SkeletonGroup>
    </div>
);

SkeletonTable.propTypes = {
    rows: PropTypes.number,
    columns: PropTypes.number
};

// Add animations to tailwind.config.js
const tailwindConfig = {
    theme: {
        extend: {
            keyframes: {
                shimmer: {
                    '100%': { transform: 'translateX(100%)' }
                }
            }
        }
    }
};

export {
    Skeleton,
    SkeletonGroup,
    SkeletonText,
    SkeletonCard,
    SkeletonTable
};

export default Skeleton;