import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardHeader, CardContent, CardFooter } from '../ui/cardui';
import { Button } from '../ui/button';
import { Alert, AlertDescription, AlertTitle } from '../ui/alert';
import { Loader2, AlertCircle, CheckCircle2 } from 'lucide-react';
import api from '../../services/api';

const debugLog = (message, data = null) => {
    const timestamp = new Date().toISOString();
    const logMessage = `[SubscriptionSuccess ${timestamp}] ${message}`;
    console.log(logMessage);
    if (data) {
        console.log('Data:', data);
    }
};

const SubscriptionSuccess = () => {
    const [state, setState] = useState({
        status: 'loading',
        subscription: null,
        error: null,
        retryCount: 0,
        redirecting: false
    });
    const navigate = useNavigate();

    const MAX_RETRIES = 5;
    const POLL_INTERVAL = 2000;
    const REDIRECT_DELAY = 3000;

    useEffect(() => {
        let pollTimer;
        let mounted = true;

        const checkSubscriptionStatus = async () => {
            try {
                const response = await api.get('subscriptions-current/');
                debugLog("Subscription check response:", response);

                if (!mounted) return;

                if (response.data) {
                    setState(prev => ({
                        ...prev,
                        status: 'success',
                        subscription: response.data
                    }));

                    // Clear polling
                    if (pollTimer) clearInterval(pollTimer);

                    const pendingOnboarding = localStorage.getItem('pendingOnboarding');

                    // Set up redirect to onboarding after delay
                    setTimeout(() => {
                        if (mounted) {
                            setState(prev => ({ ...prev, redirecting: true }));
                            // Clear the pending flag
                            localStorage.removeItem('pendingOnboarding');
                            // Redirect to onboarding if pending, otherwise to dashboard
                            navigate(pendingOnboarding ? '/onboarding' : '/dashboard');
                        }
                    }, REDIRECT_DELAY);
                }
            } catch (error) {
                if (!mounted) return;

                // Handle different error types
                if (error.response?.status === 404) {
                    setState(prev => ({
                        ...prev,
                        retryCount: prev.retryCount + 1,
                        status: prev.retryCount >= MAX_RETRIES ? 'not_found' : 'loading'
                    }));
                } else {
                    setState(prev => ({
                        ...prev,
                        status: 'error',
                        error: error.response?.data?.error || 'Failed to load subscription details'
                    }));
                    if (pollTimer) clearInterval(pollTimer);
                }
            }
        };

        // Initial check
        checkSubscriptionStatus();

        // Start polling if initial check doesn't succeed
        pollTimer = setInterval(() => {
            if (state.retryCount < MAX_RETRIES) {
                checkSubscriptionStatus();
            } else {
                clearInterval(pollTimer);
            }
        }, POLL_INTERVAL);

        return () => {
            mounted = false;
            if (pollTimer) clearInterval(pollTimer);
        };
    }, [navigate]);

    const renderContent = () => {
        if (state.redirecting) {
            return (
                <div className="flex flex-col items-center justify-center min-h-[400px] space-y-4">
                    <Loader2 className="h-8 w-8 animate-spin text-primary" />
                    <p className="text-lg">Redirecting to onboarding...</p>
                </div>
            );
        }

        switch (state.status) {
            case 'loading':
                return (
                    <div className="flex flex-col items-center justify-center min-h-[400px] space-y-4">
                        <Loader2 className="h-8 w-8 animate-spin text-primary" />
                        <p className="text-lg">Processing your subscription...</p>
                        {state.retryCount > 0 && (
                            <p className="text-sm text-muted-foreground">
                                Attempt {state.retryCount} of {MAX_RETRIES}
                            </p>
                        )}
                    </div>
                );

            case 'error':
                return (
                    <Alert variant="destructive">
                        <AlertCircle className="h-4 w-4" />
                        <AlertTitle>Error</AlertTitle>
                        <AlertDescription>{state.error}</AlertDescription>
                    </Alert>
                );

            case 'not_found':
                return (
                    <Alert variant="warning">
                        <AlertCircle className="h-4 w-4" />
                        <AlertTitle>Subscription Not Found</AlertTitle>
                        <AlertDescription>
                            We couldn't find your subscription. This might happen if the payment is still processing.
                            Please check back in a few minutes or contact support if the issue persists.
                        </AlertDescription>
                    </Alert>
                );

            case 'success':
                return (
                    <div className="space-y-4">
                        <div className="flex items-center justify-center space-x-2">
                            <CheckCircle2 className="h-6 w-6 text-green-500" />
                            <h2 className="text-xl font-semibold text-green-600">Payment Successful!</h2>
                        </div>

                        {state.subscription && (
                            <div className="space-y-2">
                                <p className="font-medium">Your subscription details:</p>
                                <div className="bg-muted p-4 rounded-lg space-y-2">
                                    <p><span className="font-medium">Plan:</span> {state.subscription.plan.name}</p>
                                    <p><span className="font-medium">Billing Cycle:</span> {state.subscription.billing_cycle}</p>
                                    <p><span className="font-medium">Status:</span> {state.subscription.status}</p>
                                    {state.subscription.current_period_end && (
                                        <p>
                                            <span className="font-medium">Next billing date:</span>{' '}
                                            {new Date(state.subscription.current_period_end).toLocaleDateString()}
                                        </p>
                                    )}
                                    {state.subscription.upcoming_invoice && (
                                        <p>
                                            <span className="font-medium">Next payment:</span>{' '}
                                            ${state.subscription.upcoming_invoice.amount_due.toFixed(2)}
                                        </p>
                                    )}
                                </div>
                                <p className="text-sm text-muted-foreground text-center mt-4">
                                    Redirecting to onboarding in a few seconds...
                                </p>
                            </div>
                        )}
                    </div>
                );

            default:
                return null;
        }
    };

    return (
        <Card className="max-w-lg mx-auto mt-8">
            <CardContent className="p-6">
                {renderContent()}
            </CardContent>
            <CardFooter className="flex justify-center space-x-4 p-6 pt-0">
                {(state.status === 'error' || state.status === 'not_found') && (
                    <>
                        <Button
                            onClick={() => navigate('/dashboard')}
                        >
                            Go to Dashboard
                        </Button>
                        <Button
                            variant="outline"
                            onClick={() => navigate('/subscription')}
                        >
                            View Plans
                        </Button>
                    </>
                )}
            </CardFooter>
        </Card>
    );
};

export default SubscriptionSuccess;