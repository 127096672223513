import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import api from '../services/api';
import { Loader2 } from 'lucide-react';
import Signup from './Signup';

const PromotionSignup = () => {
    const { promotionId } = useParams();
    const navigate = useNavigate();
    const [activePromotion, setActivePromotion] = useState(null);
    const [isValid, setIsValid] = useState(true);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchPromotion = async () => {
            console.log(`Fetching promotion with ID: ${promotionId}`);
            try {
                setLoading(true);
                const response = await api.get(`promotions/${promotionId}/`);
                console.log('API Response:', response.data);
                if (!response.data.valid) {
                    setIsValid(false);
                    setError(response.data.message || 'Promotion is not available');
                    return;
                }


                const promotionData = {
                    name: response.data.name,
                    description: response.data.description,
                    bannerColor: response.data.banner_color,
                    stripeCoupon: response.data.stripe_coupon,
                    discounts: response.data.discounts,
                    icon: response.data.icon,
                    endDate: new Date(response.data.end_date),
                    isLifetime: response.data.is_lifetime || false,
                    skipTrial: response.data.skip_trial || false,
                    monthlyFixedPrice: response.data.monthly_fixed_price || null,
                    yearlyFixedPrice: response.data.yearly_fixed_price || null
                };

                setActivePromotion(promotionData);
                setIsValid(true);
            } catch (error) {
                console.error('Failed to fetch promotion:', error);
                setIsValid(false);
                setError('Unable to load promotion details. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        fetchPromotion();
    }, [promotionId]);


    if (loading) {
        return (
            <div className="min-h-screen bg-gray-50 flex flex-col justify-center items-center">
                <Loader2 className="h-12 w-12 animate-spin text-blue-500 mb-4" />
                <p className="text-gray-600">Loading promotion...</p>
            </div>
        );
    }


    if (!isValid) {
        return (
            <div className="min-h-screen bg-gray-50 flex justify-center items-center p-4">
                <div className="w-full max-w-md bg-white p-8 rounded-lg shadow-md text-center">
                    <h2 className="text-2xl font-bold mb-4">BUILDIFYPRO <br />Promotion Not Available</h2>
                    <p className="text-gray-600 mb-6">
                        {error || "This promotion is no longer active or doesn't exist."}
                    </p>
                    <button
                        onClick={() => navigate('/register')}
                        className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                    >
                        Continue to Regular Signup
                    </button>
                </div>
            </div>
        );
    }


    return (
        <Signup
            promotion={activePromotion}
            customTitle={activePromotion.name}
        />
    );
};

export default PromotionSignup;