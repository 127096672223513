import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, CardHeader, CardTitle, CardContent } from '../../../components/ui/cardui';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../../components/ui/tabs';
import { Button } from '../../../components/ui/button';
import { Input } from '../../../components/ui/input';
import { Textarea } from '../../../components/ui/textarea';
import { useToast } from '../../../components/ui/use-toast';
import { Badge } from '../../../components/ui/badge';

import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '../../../components/ui/select';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow
} from '../../../components/ui/table';

import { FileText, Send, Plus, Loader2 } from 'lucide-react';
import api from '../../../services/api';
import { primaryButtonClasses, secondaryButtonClasses } from '../../common/ButtonStyles';
import BidMessagesPanel from './BidMessagesPanel';
import BidRFIPanel from './BidRFIPanel';

const RFIManager = () => {
    const { id } = useParams();

    // If there's an ID, show the BidRFIPanel
    if (id) {
        return (
            <div className="space-y-6">
                <BidRFIPanel bidRequestId={id} />
            </div>
        );
    }

    return (
        <div className="space-y-6">
            <Card>
                <CardHeader>
                    <CardTitle>RFI Management</CardTitle>
                </CardHeader>
                <CardContent>
                    <BidRFIPanel />
                </CardContent>
            </Card>
        </div>
    );
};

export default RFIManager;