import React, { useEffect, useState } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from "../components/ui/cardui";
import { PDFDownloadLink } from '@react-pdf/renderer';
import { CloudArrowDownIcon, Loader2, CloudDownloadIcon } from 'lucide-react';
import SafetyReport from './SafetyReport';
import api from '../services/api';

const SafetyReports = ({ project }) => {
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchReports = async () => {
      try {
        const response = await api.get(`/projects/${project.id}/safety-documents/`, {
          params: {
            safety_type: 'video',
            has_analysis: true
          }
        });

        setReports(response.data.results);
      } catch (error) {
        console.error('Error fetching reports:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchReports();
  }, [project.id]);

  if (loading) {
    return (
      <Card className="mt-6">
        <CardContent className="flex items-center justify-center p-6">
          <Loader2 className="h-6 w-6 animate-spin" />
        </CardContent>
      </Card>
    );
  }

  if (!reports.length) {
    return (
      <Card className="mt-6">
        <CardContent className="p-6">
          <p className="text-gray-500 text-center">No analysis reports available</p>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card className="mt-6">
      <CardHeader>
        <CardTitle>Safety Analysis Reports</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          {reports.map((report) => (
            <div
              key={report.id}
              className="flex items-center justify-between p-4 bg-gray-50 rounded-lg"
            >
              <div className="flex items-center">
                <CloudDownloadIcon className="h-5 w-5 text-gray-400 mr-2" />
                <div>
                  <span className="text-sm font-medium">
                    Safety Analysis Report - {new Date(report.created_at).toLocaleDateString()}
                  </span>
                  <p className="text-xs text-gray-500">
                    Created by: {report.created_by_name}
                  </p>
                </div>
              </div>
              <PDFDownloadLink
                document={<SafetyReport analysisResults={report.analysis_results} project={project} />}
                fileName={`safety_analysis_${report.id}.pdf`}
                className="text-blue-600 hover:text-blue-800"
              >
                {({ loading }) => loading ? 'Preparing...' : 'Download PDF'}
              </PDFDownloadLink>
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
};

export default SafetyReports;


















/* import React from 'react';
import { DocumentTextIcon, CloudArrowDownIcon } from '@heroicons/react/24/outline';

const reports = [
  { id: 1, name: 'Monthly Safety Summary', date: '2024-08-31', type: 'PDF' },
  { id: 2, name: 'Incident Investigation Report', date: '2024-08-15', type: 'DOCX' },
  { id: 3, name: 'Safety Training Attendance', date: '2024-08-10', type: 'XLSX' },
  { id: 4, name: 'Hazard Identification Checklist', date: '2024-08-05', type: 'PDF' },
  { id: 5, name: 'Equipment Inspection Log', date: '2024-07-31', type: 'PDF' },
];

function SafetyReports({ project }) {
  return (
    <div className="space-y-6">
      <h3 className="text-lg font-semibold text-gray-700">Safety Reports</h3>
      <div className="bg-white shadow overflow-hidden sm:rounded-md">
        <ul className="divide-y divide-gray-200">
          {reports.map((report) => (
            <li key={report.id}>
              <a href="#" className="block hover:bg-gray-50">
                <div className="px-4 py-4 sm:px-6">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <DocumentTextIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                      <p className="ml-2 text-sm font-medium text-blue-600 truncate">{report.name}</p>
                    </div>
                    <div className="ml-2 flex-shrink-0 flex">
                      <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                        {report.type}
                      </p>
                    </div>
                  </div>
                  <div className="mt-2 sm:flex sm:justify-between">
                    <div className="sm:flex">
                      <p className="flex items-center text-sm text-gray-500">
                        {report.date}
                      </p>
                    </div>
                    <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                      <CloudArrowDownIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                      <p>Download</p>
                    </div>
                  </div>
                </div>
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default SafetyReports; */