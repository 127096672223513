import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import api from '../../services/api';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { CheckCircleIcon, ClockIcon, ExclamationTriangleIcon, WrenchIcon } from '@heroicons/react/24/solid';

const getIconForTaskStatus = (status) => {
  switch (status) {
    case 'completed':
      return <CheckCircleIcon className="w-full h-full" />;
    case 'inProgress':
      return <ClockIcon className="w-full h-full" />;
    case 'notStarted':
    default:
      return <ExclamationTriangleIcon className="w-full h-full" />;
  }
};

const getColorForTaskStatus = (status) => {
  switch (status) {
    case 'completed':
      return { background: '#4ade80', color: '#fff' };
    case 'inProgress':
      return { background: '#fbbf24', color: '#fff' };
    case 'notStarted':
    default:
      return { background: '#9ca3af', color: '#fff' };
  }
};

const ProjectTimeline = () => {
  const [project, setProject] = useState(null);
  const [phases, setPhases] = useState([]); // Initialize as an empty array
  const [achievementRate, setAchievementRate] = useState(null);
  const [expandedPhase, setExpandedPhase] = useState(null);
  const [filter, setFilter] = useState("all");
  const [isAllExpanded, setIsAllExpanded] = useState(false);
  const location = useLocation();

  const { projectId } = useParams();

  useEffect(() => {
    if (projectId) {
      // Fetch specific project by ID
      api.get('projects/')
        .then(response => {
          const foundProject = response.data.results.find(p => p.id === projectId);
          setProject(foundProject || null);
          if (foundProject) console.log("Project Name:", foundProject.name);
        })
        .catch(error => console.error('Error fetching projects:', error));

      // Fetch milestones for the specified project
      api.get(`milestones?project_id=${projectId}`)
        .then(response => {

          setPhases(Array.isArray(response.data.results) ? response.data.results : []);
        })
        .catch(error => console.error('Error fetching milestones:', error));

      // Fetch achievement rate for the project milestones
      api.get(`milestones/achievement-rate/?project_id=${projectId}`)
        .then(response => {
          setAchievementRate(response.data);
        })
        .catch(error => console.error('Error fetching achievement rate:', error));
    }
  }, [projectId]);

  const handleToggleExpandAll = () => {
    if (Array.isArray(phases)) {
      setIsAllExpanded(!isAllExpanded);
      setExpandedPhase(isAllExpanded ? null : phases.map(phase => phase.id));
    }
  };

  const filteredPhases = Array.isArray(phases) ? phases.filter(phase => {
    if (filter === "all") return true;
    if (filter === "completed") return phase.progress_percentage === 100;
    if (filter === "inProgress") return phase.progress_percentage > 0 && phase.progress_percentage < 100;
    if (filter === "notStarted") return phase.progress_percentage === 0;
    return true;
  }) : [];

  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-6 text-gray-800">
        {project ? `${project.name} Timeline` : 'Loading...'}
      </h2>
      {achievementRate && (
        <div className="mb-6">
          <p className="text-lg text-gray-700">Achievement Rate: {achievementRate.achievement_rate}%</p>
          <p className="text-sm text-gray-500">Total Milestones: {achievementRate.total_milestones}</p>
          <p className="text-sm text-gray-500">Completed Milestones: {achievementRate.completed_milestones}</p>
        </div>
      )}

      <div className="flex justify-between items-center mb-4">
        <div>
          <label className="mr-2">Filter by Status:</label>
          <select
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            className="border border-gray-300 rounded p-1"
          >
            <option value="all">All</option>
            <option value="completed">Completed</option>
            <option value="inProgress">In Progress</option>
            <option value="notStarted">Not Started</option>
          </select>
        </div>
        <button
          onClick={handleToggleExpandAll}
          className="text-blue-600 hover:text-blue-800"
        >
          {isAllExpanded ? 'Collapse All' : 'Expand All'}
        </button>
      </div>

      <VerticalTimeline>
        {filteredPhases.map(phase => (
          <VerticalTimelineElement
            key={phase.id}
            className="vertical-timeline-element--work"
            contentStyle={{ background: 'rgb(249, 250, 251)', color: '#1f2937' }}
            contentArrowStyle={{ borderRight: '7px solid  rgb(249, 250, 251)' }}
            date={`${phase.start_date} - ${phase.due_date}`}
            iconStyle={{ background: '#3b82f6', color: '#fff' }}
            icon={<WrenchIcon className="w-full h-full" />}
          >
            <h3 className="vertical-timeline-element-title font-bold">{phase.name}</h3>
            <div className="relative h-4 bg-gray-200 rounded mt-2">
              <div
                style={{ width: `${phase.progress_percentage}%` }}
                className="absolute left-0 top-0 h-full bg-blue-500 rounded"
              ></div>
              <span className="absolute right-2 top-0 text-xs text-gray-700">{phase.progress_percentage}%</span>
            </div>

            <button
              onClick={() => setExpandedPhase(expandedPhase === phase.id ? null : phase.id)}
              className="mt-2 text-blue-600 hover:text-blue-800"
            >
              {expandedPhase === phase.id ? 'Hide Details' : 'Show Details'}
            </button>
            {(isAllExpanded || expandedPhase === phase.id) && (
              <ul className="mt-4 space-y-2">
                <li className="flex items-center space-x-2">
                  <span className={`w-4 h-4 rounded-full ${getColorForTaskStatus(
                    phase.progress_percentage === 100
                      ? 'completed'
                      : phase.progress_percentage > 0
                        ? 'inProgress'
                        : 'notStarted'
                  ).background
                    }`}></span>
                  <span>Progress: {phase.progress_percentage}%</span>
                </li>
              </ul>
            )}
          </VerticalTimelineElement>
        ))}
      </VerticalTimeline>
    </div>
  );
};

export default ProjectTimeline;
