import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "../../ui/cardui";
import { Badge } from "../../ui/badge";
import { Clock, AlertTriangle } from "lucide-react";
import { useToast } from "../../ui/use-toast";
import api from '../../../services/api';

const CriticalPathAnalysis = ({ projectId }) => {
    const [criticalTasks, setCriticalTasks] = useState([]);
    const [criticalPathDelay, setCriticalPathDelay] = useState(0);
    const { toast } = useToast();

    useEffect(() => {
        loadCriticalPath();
    }, [projectId]);

    const loadCriticalPath = async () => {
        try {
            const response = await api.get(`projects/${projectId}/critical-path/`);
            setCriticalTasks(response.data.tasks || []);
            setCriticalPathDelay(response.data.total_delay || 0);
        } catch (error) {
            toast({
                title: "Error",
                description: "Failed to load critical path analysis",
                variant: "destructive"
            });
        }
    };

    return (
        <div className="space-y-6">
            <Card>
                <CardHeader>
                    <div className="flex justify-between items-center">
                        <CardTitle>Critical Path Analysis</CardTitle>
                        <Badge
                            variant={criticalPathDelay > 0 ? "destructive" : "default"}
                            className="flex items-center"
                        >
                            <Clock className="h-4 w-4 mr-1" />
                            {criticalPathDelay} days delay
                        </Badge>
                    </div>
                </CardHeader>
                <CardContent>
                    <div className="space-y-4">
                        {criticalTasks.map((task, index) => (
                            <div
                                key={task.id}
                                className="flex items-center p-4 bg-gray-50 rounded-lg"
                            >
                                <div className="flex-1">
                                    <div className="flex items-center">
                                        <span className="text-sm font-medium">
                                            {index + 1}. {task.name}
                                        </span>
                                        {task.delay > 0 && (
                                            <Badge
                                                variant="destructive"
                                                className="ml-2"
                                            >
                                                {task.delay} days behind
                                            </Badge>
                                        )}
                                    </div>
                                    <p className="text-sm text-gray-500 mt-1">
                                        Duration: {task.duration} days |
                                        Float: {task.float} days
                                    </p>
                                </div>
                                {task.risk_level === 'high' && (
                                    <AlertTriangle className="h-5 w-5 text-yellow-500" />
                                )}
                            </div>
                        ))}
                        {criticalTasks.length === 0 && (
                            <p className="text-center text-gray-500 py-4">
                                No critical path tasks found.
                            </p>
                        )}
                    </div>
                </CardContent>
            </Card>
        </div>
    );
};

export default CriticalPathAnalysis;