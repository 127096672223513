import React, { createContext, useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import api from '../../../services/api';


export const SubscriptionProvider = ({ children }) => {
    const [subscription, setSubscription] = useState(null);
    const [features, setFeatures] = useState([]);
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        fetchSubscription();
    }, []);



    const fetchSubscription = async () => {
        try {

            // Check if current path is a bid invitation route
            const isBidRoute = window.location.pathname.includes('/bids/') &&
                window.location.pathname.includes('/invitation/');

            // Skip subscription check for bid routes
            if (isBidRoute) {
                setLoading(false);
                return;
            }
            const subscriptionResponse = await api.get('subscription-debug/');
            console.log("Subscription data: ", subscriptionResponse);

            if (subscriptionResponse.data?.current_subscription?.plan_id) {
                const planId = subscriptionResponse.data.current_subscription.plan_id;
                const allFeatures = [];
                let nextUrl = `features/?plan_id=${planId}`;

                // Fetch all pages
                while (nextUrl) {
                    const featuresResponse = await api.get(nextUrl);
                    allFeatures.push(...(featuresResponse.data.results || []));
                    nextUrl = featuresResponse.data.next ? new URL(featuresResponse.data.next).pathname.split('/api/')[1] : null;
                }

                setFeatures(allFeatures);
                setSubscription(subscriptionResponse.data.current_subscription);
            }
        } catch (error) {
            console.error('Subscription Error:', error.response?.data || error);
            setFeatures([]);
        } finally {
            setLoading(false);
        }
    };

    const checkFeature = (featureCode) => {
        return features?.some(feature => feature.name === featureCode) || false;
    };



    return (
        <SubscriptionContext.Provider value={{
            subscription,
            loading,
            checkFeature,
            features,
            refreshSubscription: fetchSubscription
        }}>
            {children}
        </SubscriptionContext.Provider>
    );
};

export const SubscriptionContext = createContext();