import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useFieldArray } from 'react-hook-form';
import { format } from 'date-fns';
import { useToast } from "../../../../components/ui/use-toast";
import { cn } from '../../../../lib/utils';
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "../../../../components/ui/dialog";
import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
} from "../../../../components/ui/cardui";
import { Input } from "../../../../components/ui/input";
import { Badge } from "../../../../components/ui/badge";
import { Button } from "../../../../components/ui/button";
import { Label } from "../../../../components/ui/label";
import { Textarea } from "../../../../components/ui/textarea";
import {
    Eye,
    Upload,
    File,
    X,
    Download,
    DollarSign,
    Calendar,
    FileText,
    Plus
} from 'lucide-react';

// Project Preview Component
export const ProjectPreview = ({ project }) => {
    const [isOpen, setIsOpen] = useState(false);

    const formatCurrency = (value) => {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD'
        }).format(value);
    };

    return (
        <>
            <Button
                variant="ghost"
                size="sm"
                onClick={() => setIsOpen(true)}
            >
                <Eye className="h-4 w-4" />
            </Button>

            <Dialog open={isOpen} onOpenChange={setIsOpen}>
                <DialogContent className="sm:max-w-[525px]">
                    <DialogHeader>
                        <DialogTitle>{project.name}</DialogTitle>
                    </DialogHeader>
                    <div className="space-y-4">
                        <div className="flex justify-between items-center">
                            <Badge variant="outline">
                                <DollarSign className="h-4 w-4 mr-1" />
                                {formatCurrency(project.value)}
                            </Badge>
                        </div>

                        <div className="border rounded-lg p-4 bg-muted/50">
                            <h4 className="font-medium mb-2">Description</h4>
                            <p className="text-sm text-muted-foreground">
                                {project.description}
                            </p>
                        </div>

                        {project.images?.length > 0 && (
                            <div className="grid grid-cols-2 gap-2">
                                {project.images.map((image, index) => (
                                    <img
                                        key={index}
                                        src={URL.createObjectURL(image)}
                                        alt={`Project image ${index + 1}`}
                                        className="rounded-md object-cover h-32 w-full"
                                    />
                                ))}
                            </div>
                        )}
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
};

// Certification Upload Component
export const CertificationUpload = ({ form }) => {
    const [files, setFiles] = useState([]);
    const { toast } = useToast();

    const onDrop = useCallback((acceptedFiles) => {
        const newFiles = acceptedFiles.map(file => ({
            file,
            name: file.name,
            type: file.type,
            uploadDate: new Date()
        }));

        setFiles(prev => [...prev, ...newFiles]);

        // Update form with the complete certification objects
        const certifications = form.getValues('certifications') || [];
        form.setValue('certifications', [...certifications, ...newFiles], {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [form]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
            'application/pdf': ['.pdf'],
            'image/*': ['.png', '.jpg', '.jpeg']
        },
        maxSize: 5242880 // 5MB
    });

    const removeFile = (fileToRemove) => {
        setFiles(files.filter(f => f.file !== fileToRemove.file));

        // Update form value
        const certifications = form.getValues('certifications') || [];
        form.setValue(
            'certifications',
            certifications.filter(cert => cert.file !== fileToRemove.file),
            { shouldValidate: true }
        );
    };

    const formatFileSize = (bytes) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const sizes = ['Bytes', 'KB', 'MB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    };

    return (
        <div className="space-y-4">
            <div
                {...getRootProps()}
                className={cn(
                    "border-2 border-dashed rounded-lg p-6 text-center transition-colors",
                    isDragActive ? "border-primary bg-primary/5" : "border-muted"
                )}
            >
                <input {...getInputProps()} />
                <div className="flex flex-col items-center gap-2">
                    <Upload className="h-8 w-8 text-muted-foreground" />
                    <p className="text-sm text-muted-foreground">
                        Drag & drop certification files here, or click to select
                    </p>
                    <p className="text-xs text-muted-foreground">
                        PDF, PNG or JPG (max. 5MB)
                    </p>
                </div>
            </div>

            {files.length > 0 && (
                <div className="space-y-2">
                    {files.map((file) => (
                        <div
                            key={file.name}
                            className="flex items-center justify-between p-2 border rounded-lg"
                        >
                            <div className="flex items-center gap-2">
                                <FileText className="h-4 w-4 text-muted-foreground" />
                                <div className="flex flex-col">
                                    <p className="text-sm font-medium">{file.name}</p>
                                    <p className="text-xs text-muted-foreground">
                                        {formatFileSize(file.file.size)} • {format(file.uploadDate, 'MMM d, yyyy')}
                                    </p>
                                </div>
                            </div>
                            <Button
                                variant="ghost"
                                size="sm"
                                onClick={() => removeFile(file)}
                            >
                                <X className="h-4 w-4" />
                            </Button>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

// Update the NotableProjectForm to include preview
export const NotableProjectForm = ({ form }) => {
    const { fields, append, remove } = useFieldArray({
        control: form.control,
        name: "notable_projects"
    });

    return (
        <Card>
            <CardHeader className="flex flex-row items-center justify-between">
                <CardTitle className="text-sm font-medium">Notable Projects</CardTitle>
                <Button
                    type="button"
                    variant="outline"
                    size="sm"
                    onClick={() => append({
                        name: '',
                        description: '',
                        value: 0,
                        images: []
                    })}
                >
                    <Plus className="h-4 w-4 mr-2" />
                    Add Project
                </Button>
            </CardHeader>
            <CardContent className="space-y-4">
                {fields.map((field, index) => (
                    <div key={field.id} className="space-y-4 p-4 border rounded-lg relative">
                        <div className="absolute right-2 top-2 flex gap-2">
                            <ProjectPreview
                                project={form.watch(`notable_projects.${index}`)}
                            />
                            <Button
                                type="button"
                                variant="ghost"
                                size="sm"
                                onClick={() => remove(index)}
                            >
                                <X className="h-4 w-4" />
                            </Button>
                        </div>

                        <div className="grid gap-4">
                            <div>
                                <Label htmlFor={`notable_projects.${index}.name`}>Project Name</Label>
                                <Input
                                    {...form.register(`notable_projects.${index}.name`)}
                                    placeholder="Enter project name"
                                />
                            </div>

                            <div>
                                <Label htmlFor={`notable_projects.${index}.description`}>Description</Label>
                                <Textarea
                                    {...form.register(`notable_projects.${index}.description`)}
                                    placeholder="Describe the project"
                                />
                            </div>

                            <div>
                                <Label htmlFor={`notable_projects.${index}.value`}>Project Value</Label>
                                <div className="relative">
                                    <DollarSign className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-gray-500" />
                                    <Input
                                        type="number"
                                        className="pl-8"
                                        {...form.register(`notable_projects.${index}.value`, {
                                            valueAsNumber: true
                                        })}
                                        placeholder="0.00"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </CardContent>
        </Card>
    );
};
