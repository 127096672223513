import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "../ui/cardui";
import { Button } from "../ui/button";
import { Badge } from "../ui/badge";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../ui/tabs";
import { Alert, AlertDescription } from "../ui/alert";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../ui/select";
import { AlertCircle, ChevronLeft, ChevronRight, Calendar, Info } from 'lucide-react';
import api from '../../services/api';

// Custom virtualized table component for timeline
const VirtualizedTimeline = ({ dateCells, resourceAssignments, getActivity, formatDateCell, isAssignmentInDateCell, getAssignmentCellWidth, containerHeight = 400 }) => {
    const [scrollTop, setScrollTop] = useState(0);
    const itemHeight = 64; // Height of each row in pixels

    const onScroll = (e) => {
        setScrollTop(e.target.scrollTop);
    };

    const startIndex = Math.floor(scrollTop / itemHeight);
    const visibleItemCount = Math.ceil(containerHeight / itemHeight) + 1;
    const endIndex = Math.min(startIndex + visibleItemCount, resourceAssignments.length);

    const visibleAssignments = resourceAssignments.slice(startIndex, endIndex);
    const paddingTop = startIndex * itemHeight;
    const totalHeight = resourceAssignments.length * itemHeight;

    return (
        <div className="overflow-x-auto">
            <table className="w-full min-w-max">
                <thead>
                    <tr>
                        <th className="w-48 sticky left-0 z-10 p-2 text-left text-sm font-medium text-gray-500 bg-gray-50 border-b">
                            Activity
                        </th>
                        {dateCells.map((cell, index) => (
                            <th
                                key={index}
                                className="p-2 text-center text-sm font-medium text-gray-500 bg-gray-50 border-b"
                                style={{ minWidth: '120px' }}
                            >
                                {formatDateCell(cell)}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody className="relative" style={{ height: totalHeight }}>
                    <div
                        style={{ position: 'absolute', top: paddingTop, width: '100%' }}
                    >
                        {visibleAssignments.map((assignment) => {
                            const activity = getActivity(assignment.activity);

                            return (
                                <tr key={assignment.id} className="hover:bg-gray-50">
                                    <td className="sticky left-0 z-10 p-2 border-b text-sm bg-white">
                                        <div className="font-medium">{activity.name}</div>
                                        <div className="text-xs text-gray-500">
                                            {new Date(assignment.start_date).toLocaleDateString()} -
                                            {new Date(assignment.end_date).toLocaleDateString()}
                                        </div>
                                    </td>

                                    {dateCells.map((cell, index) => {
                                        const isAssigned = isAssignmentInDateCell(assignment, cell);
                                        const widthPercentage = getAssignmentCellWidth(assignment, cell);

                                        return (
                                            <td
                                                key={index}
                                                className="p-0 border-b relative h-16"
                                            >
                                                {isAssigned && (
                                                    <div
                                                        className={`absolute top-0 h-full ${activity.is_critical ? 'bg-red-100 border-red-400' : 'bg-blue-100 border-blue-400'
                                                            } border-2 rounded-sm flex items-center justify-center`}
                                                        style={{
                                                            width: `${widthPercentage}%`,
                                                            left: `${(100 - widthPercentage) / 2}%`
                                                        }}
                                                    >
                                                        {widthPercentage > 40 && (
                                                            <span className="text-xs font-medium px-1 truncate">
                                                                {assignment.units} unit{assignment.units !== 1 ? 's' : ''}
                                                            </span>
                                                        )}
                                                    </div>
                                                )}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </div>
                </tbody>
            </table>
        </div>
    );
};

const ResourceTimelineViewer = ({ projectId, scheduleId }) => {
    const [resources, setResources] = useState([]);
    const [assignments, setAssignments] = useState([]);
    const [activities, setActivities] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedResourceType, setSelectedResourceType] = useState('labor');
    const [selectedResource, setSelectedResource] = useState(null);
    const [currentView, setCurrentView] = useState('week');
    const [dateRange, setDateRange] = useState({
        start: new Date(),
        end: new Date(new Date().setDate(new Date().getDate() + 28))
    });
    const [containerHeight, setContainerHeight] = useState(500);
    const [resourceFilter, setResourceFilter] = useState('');

    // Adjust container height based on screen size
    useEffect(() => {
        const updateHeight = () => {
            const isMobile = window.innerWidth < 768;
            setContainerHeight(isMobile ? 300 : 500);
        };

        updateHeight();
        window.addEventListener('resize', updateHeight);
        return () => window.removeEventListener('resize', updateHeight);
    }, []);

    // Fetch data on component mount
    useEffect(() => {
        if (projectId && scheduleId) {
            fetchData();
        }
    }, [projectId, scheduleId]);

    const fetchData = async () => {
        setIsLoading(true);
        try {
            const [
                activitiesRes,
                assignmentsRes,
                laborRes,
                equipmentRes,
                materialRes
            ] = await Promise.all([
                api.get(`projects/${projectId}/activities/`, { params: { schedule: scheduleId } }),
                api.get(`projects/${projectId}/resource-assignments/`),
                api.get(`projects/${projectId}/resources/`, { params: { type: 'labor' } }),
                api.get(`projects/${projectId}/resources/`, { params: { type: 'equipment' } }),
                api.get(`projects/${projectId}/resources/`, { params: { type: 'material' } })
            ]);

            setActivities(activitiesRes.data.results || []);
            setAssignments(assignmentsRes.data.results || []);

            // Organize resources
            const allResources = {
                labor: laborRes.data.results || [],
                equipment: equipmentRes.data.results || [],
                material: materialRes.data.results || []
            };

            setResources(allResources);

            // Set a default selected resource if available
            if (allResources.labor.length > 0) {
                setSelectedResource(allResources.labor[0].id);
            }

            // Set date range based on project schedule
            if (activitiesRes.data.results && activitiesRes.data.results.length > 0) {
                const sortedByStart = [...activitiesRes.data.results].sort(
                    (a, b) => new Date(a.planned_start) - new Date(b.planned_start)
                );
                const sortedByEnd = [...activitiesRes.data.results].sort(
                    (a, b) => new Date(b.planned_finish) - new Date(a.planned_finish)
                );

                if (sortedByStart.length && sortedByEnd.length) {
                    setDateRange({
                        start: new Date(sortedByStart[0].planned_start),
                        end: new Date(sortedByEnd[0].planned_finish)
                    });
                }
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setError('Failed to load resource timeline data');
        } finally {
            setIsLoading(false);
        }
    };

    // Navigate to previous/next time period
    const navigatePeriod = (direction) => {
        const { start, end } = dateRange;
        const diff = end.getTime() - start.getTime();

        if (direction === 'prev') {
            setDateRange({
                start: new Date(start.getTime() - diff),
                end: new Date(end.getTime() - diff)
            });
        } else {
            setDateRange({
                start: new Date(start.getTime() + diff),
                end: new Date(end.getTime() + diff)
            });
        }
    };

    // Filtered resources based on search
    const filteredResources = useMemo(() => {
        if (!resourceFilter) return resources;

        return {
            labor: resources.labor?.filter(r =>
                r.name.toLowerCase().includes(resourceFilter.toLowerCase())
            ) || [],
            equipment: resources.equipment?.filter(r =>
                r.name.toLowerCase().includes(resourceFilter.toLowerCase())
            ) || [],
            material: resources.material?.filter(r =>
                r.name.toLowerCase().includes(resourceFilter.toLowerCase())
            ) || []
        };
    }, [resources, resourceFilter]);

    // Generate date cells for the timeline - memoized for performance
    const dateCells = useMemo(() => {
        const cells = [];
        const { start, end } = dateRange;
        const current = new Date(start);

        while (current <= end) {
            if (currentView === 'day') {
                cells.push(new Date(current));
                current.setDate(current.getDate() + 1);
            } else if (currentView === 'week') {
                // Start of week
                // Start of week (Sunday)
                const weekStart = new Date(current);
                weekStart.setDate(current.getDate() - current.getDay());

                // End of week (Saturday)
                const weekEnd = new Date(weekStart);
                weekEnd.setDate(weekStart.getDate() + 6);

                cells.push({
                    start: new Date(weekStart),
                    end: new Date(weekEnd)
                });

                // Move to next week
                current.setDate(current.getDate() + 7 - current.getDay());
            } else if (currentView === 'month') {
                // Start of month
                const monthStart = new Date(current.getFullYear(), current.getMonth(), 1);

                // End of month
                const monthEnd = new Date(current.getFullYear(), current.getMonth() + 1, 0);

                cells.push({
                    start: new Date(monthStart),
                    end: new Date(monthEnd)
                });

                // Move to next month
                current.setMonth(current.getMonth() + 1);
                current.setDate(1);
            }
        }

        return cells;
    }, [dateRange, currentView]);

    // Format date or date range for display
    const formatDateCell = useCallback((cell) => {
        if (!cell) return '';

        if (currentView === 'day') {
            return cell.toLocaleDateString('en-US', {
                month: 'short',
                day: 'numeric'
            });
        } else if (currentView === 'week') {
            return `${cell.start.toLocaleDateString('en-US', { month: 'short', day: 'numeric' })} - ${cell.end.toLocaleDateString('en-US', { month: 'short', day: 'numeric' })}`;
        } else if (currentView === 'month') {
            return cell.start.toLocaleDateString('en-US', { month: 'long', year: 'numeric' });
        }
    }, [currentView]);

    // Check if an assignment falls within a date cell
    const isAssignmentInDateCell = useCallback((assignment, cell) => {
        const assignmentStart = new Date(assignment.start_date);
        const assignmentEnd = new Date(assignment.end_date);

        if (currentView === 'day') {
            // For day view, check if assignment includes this day
            return assignmentStart <= cell && assignmentEnd >= cell;
        } else {
            // For week/month view, check for overlap with cell range
            return assignmentStart <= cell.end && assignmentEnd >= cell.start;
        }
    }, [currentView]);

    // Calculate assignment duration that falls within a date cell (as percentage)
    const getAssignmentCellWidth = useCallback((assignment, cell) => {
        const assignmentStart = new Date(assignment.start_date);
        const assignmentEnd = new Date(assignment.end_date);

        if (currentView === 'day') {
            // For day view, it's either 100% or 0%
            return isAssignmentInDateCell(assignment, cell) ? 100 : 0;
        } else {
            // Calculate overlap percentage
            const cellStart = new Date(Math.max(cell.start.getTime(), assignmentStart.getTime()));
            const cellEnd = new Date(Math.min(cell.end.getTime(), assignmentEnd.getTime()));

            if (cellEnd < cellStart) return 0;

            const overlapDuration = cellEnd.getTime() - cellStart.getTime();
            const cellDuration = cell.end.getTime() - cell.start.getTime();

            return Math.min(100, (overlapDuration / cellDuration) * 100);
        }
    }, [currentView, isAssignmentInDateCell]);

    // Get activity by ID
    const getActivity = useCallback((activityId) => {
        return activities.find(a => a.id === activityId) || { name: 'Unknown Activity' };
    }, [activities]);

    // Get resource assignments
    const getResourceAssignments = useCallback((resourceId, resourceType) => {
        return assignments.filter(
            a => a.resource_id === resourceId && a.resource_type === resourceType
        );
    }, [assignments]);

    // Render the timeline for a selected resource
    const renderResourceTimeline = () => {
        if (!selectedResource) {
            return (
                <div className="p-4 text-center">
                    <p className="text-gray-500">Select a resource to view its timeline.</p>
                </div>
            );
        }

        const resourceType = selectedResourceType;
        const resource = resources[resourceType]?.find(r => r.id === selectedResource);

        if (!resource) {
            return (
                <div className="p-4 text-center">
                    <p className="text-gray-500">Resource not found.</p>
                </div>
            );
        }

        const resourceAssignments = getResourceAssignments(selectedResource, resourceType);

        if (resourceAssignments.length === 0) {
            return (
                <div className="p-4 text-center">
                    <p className="text-gray-500">No assignments for this resource in the selected time period.</p>
                </div>
            );
        }

        // Use virtualized timeline for large datasets
        return (
            <div style={{ height: containerHeight, overflow: 'auto' }}>
                <VirtualizedTimeline
                    dateCells={dateCells}
                    resourceAssignments={resourceAssignments}
                    getActivity={getActivity}
                    formatDateCell={formatDateCell}
                    isAssignmentInDateCell={isAssignmentInDateCell}
                    getAssignmentCellWidth={getAssignmentCellWidth}
                    containerHeight={containerHeight}
                />
            </div>
        );
    };

    if (isLoading) {
        return (
            <div className="w-full h-64 flex items-center justify-center">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
            </div>
        );
    }

    if (error) {
        return (
            <Alert variant="destructive">
                <AlertCircle className="h-5 w-5" />
                <AlertDescription>{error}</AlertDescription>
            </Alert>
        );
    }

    return (
        <Card className="overflow-hidden">
            <CardHeader className="bg-gray-50">
                <div className="flex flex-col md:flex-row justify-between items-start md:items-center space-y-4 md:space-y-0">
                    <CardTitle>Resource Timeline</CardTitle>

                    <div className="flex space-x-2">
                        <Button
                            variant="outline"
                            size="sm"
                            onClick={() => navigatePeriod('prev')}
                        >
                            <ChevronLeft className="h-4 w-4" />
                        </Button>

                        <Select
                            value={currentView}
                            onValueChange={setCurrentView}
                        >
                            <SelectTrigger className="w-28">
                                <SelectValue placeholder="View" />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectItem value="day">Day</SelectItem>
                                <SelectItem value="week">Week</SelectItem>
                                <SelectItem value="month">Month</SelectItem>
                            </SelectContent>
                        </Select>

                        <Button
                            variant="outline"
                            size="sm"
                            onClick={() => navigatePeriod('next')}
                        >
                            <ChevronRight className="h-4 w-4" />
                        </Button>
                    </div>
                </div>
            </CardHeader>

            <div className="border-b">
                <div className="px-4 py-3">
                    <Tabs
                        defaultValue="labor"
                        value={selectedResourceType}
                        onValueChange={(value) => {
                            setSelectedResourceType(value);
                            // Reset selected resource when changing type
                            if (resources[value]?.length > 0) {
                                setSelectedResource(resources[value][0].id);
                            } else {
                                setSelectedResource(null);
                            }
                        }}
                    >
                        <TabsList className="grid w-full grid-cols-3">
                            <TabsTrigger value="labor">Labor</TabsTrigger>
                            <TabsTrigger value="equipment">Equipment</TabsTrigger>
                            <TabsTrigger value="material">Material</TabsTrigger>
                        </TabsList>

                        <div className="mt-4 space-y-4">
                            {/* Resource filter */}
                            <div className="relative">
                                <input
                                    type="text"
                                    placeholder={`Search ${selectedResourceType} resources...`}
                                    value={resourceFilter}
                                    onChange={(e) => setResourceFilter(e.target.value)}
                                    className="w-full px-3 py-2 border rounded-md"
                                />
                            </div>

                            {/* Resource selector */}
                            <Select
                                value={selectedResource}
                                onValueChange={setSelectedResource}
                            >
                                <SelectTrigger>
                                    <SelectValue placeholder={`Select ${selectedResourceType}`} />
                                </SelectTrigger>
                                <SelectContent className="max-h-60 overflow-y-auto">
                                    {filteredResources[selectedResourceType]?.length > 0 ? (
                                        filteredResources[selectedResourceType].map(resource => (
                                            <SelectItem key={resource.id} value={resource.id}>
                                                {resource.name}
                                            </SelectItem>
                                        ))
                                    ) : (
                                        <div className="px-2 py-2 text-sm text-gray-500">
                                            No {selectedResourceType} resources found
                                        </div>
                                    )}
                                </SelectContent>
                            </Select>

                            {/* Info about displayed date range */}
                            <div className="bg-blue-50 p-2 rounded-md flex items-start">
                                <Info className="h-4 w-4 text-blue-500 mt-0.5 mr-1.5 flex-shrink-0" />
                                <div className="text-xs text-blue-700">
                                    Showing timeline from {dateRange.start.toLocaleDateString()} to {dateRange.end.toLocaleDateString()}
                                </div>
                            </div>
                        </div>
                    </Tabs>
                </div>
            </div>

            <CardContent className="p-0">
                {renderResourceTimeline()}
            </CardContent>
        </Card>
    );
};

export default ResourceTimelineViewer;