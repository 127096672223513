import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardTitle, CardContent, CardFooter } from '../../components/ui/cardui';
import { Badge } from '../../components/ui/badge';
import { Button } from '../../components/ui/button';
import { Spinner } from '../../components/ui/spinner';
import { Alert, AlertDescription } from '../../components/ui/alert';
import api from '../../services/api';

const UpcomingResponsesTable = ({ projectId, onViewResponse, configureRequest, setActiveTab }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [responses, setResponses] = useState([]);

    useEffect(() => {
        const fetchUpcomingResponses = async () => {
            try {
                setLoading(true);
                setError(null);
                const response = await api.get(`risk-responses/upcoming/?project_id=${projectId}&days=30`, configureRequest());
                setResponses(response.data);
            } catch (err) {
                console.error('Error fetching upcoming responses:', err);
                setError('Failed to load upcoming responses');
            } finally {
                setLoading(false);
            }
        };

        if (projectId) {
            fetchUpcomingResponses();
        }
    }, [projectId, configureRequest]);

    // Format date to a readable format
    const formatDate = (dateString) => {
        if (!dateString) return 'No date set';
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
    };

    // Get status badge variant
    const getStatusVariant = (status) => {
        switch (status) {
            case 'PLANNED': return 'secondary';
            case 'IN_PROGRESS': return 'warning';
            case 'COMPLETED': return 'success';
            case 'CANCELLED': return 'destructive';
            case 'OVERDUE': return 'default';
            default: return 'secondary';
        }
    };

    // Get severity badge variant
    const getSeverityVariant = (severity) => {
        switch (severity) {
            case 'LOW': return 'success';
            case 'MEDIUM': return 'warning';
            case 'HIGH': return 'destructive';
            case 'CRITICAL': return 'default';
            default: return 'secondary';
        }
    };

    if (loading) {
        return (
            <Card className="h-full">
                <CardHeader>
                    <CardTitle>Upcoming Risk Responses</CardTitle>
                </CardHeader>
                <CardContent className="flex justify-center items-center h-48">
                    <Spinner />
                </CardContent>
            </Card>
        );
    }

    if (error) {
        return (
            <Card className="h-full">
                <CardHeader>
                    <CardTitle>Upcoming Risk Responses</CardTitle>
                </CardHeader>
                <CardContent>
                    <Alert variant="destructive">
                        <AlertDescription>{error}</AlertDescription>
                    </Alert>
                </CardContent>
            </Card>
        );
    }

    return (
        <Card className="h-full">
            <CardHeader>
                <CardTitle>Upcoming Risk Responses</CardTitle>
            </CardHeader>
            <CardContent className="p-0">
                {responses.length === 0 ? (
                    <div className="flex justify-center items-center py-6">
                        <p className="text-muted-foreground">No upcoming responses to display.</p>
                    </div>
                ) : (
                    <div className="overflow-x-auto">
                        <table className="w-full">
                            <thead>
                                <tr className="bg-muted">
                                    <th className="p-2 text-left border">Response</th>
                                    <th className="p-2 text-left border">For Risk</th>
                                    <th className="p-2 text-left border">Due Date</th>
                                    <th className="p-2 text-left border">Status</th>
                                    <th className="p-2 text-left border">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {responses.map(response => (
                                    <tr key={response.id} className="border-b hover:bg-muted/50">
                                        <td className="p-2 border">
                                            <span className="font-medium">{response.action || 'Unnamed Response'}</span>
                                        </td>
                                        <td className="p-2 border">
                                            <div className="flex items-center gap-2">
                                                <span className="text-sm">{response.risk.title}</span>
                                                <Badge variant={getSeverityVariant(response.risk.severity)}>
                                                    {response.risk.severity}
                                                </Badge>
                                            </div>
                                        </td>
                                        <td className="p-2 border">
                                            <span className={response.overdue ? 'text-destructive font-medium' : ''}>
                                                {formatDate(response.due_date)}
                                                {response.overdue && ' (Overdue)'}
                                            </span>
                                        </td>
                                        <td className="p-2 border">
                                            <Badge variant={getStatusVariant(response.status)}>
                                                {response.status}
                                            </Badge>
                                        </td>
                                        <td className="p-2 border">
                                            <Button
                                                variant="outline"
                                                size="sm"
                                                onClick={() => onViewResponse({
                                                    ...response,
                                                    risk_id: response.risk.id
                                                })}
                                            >
                                                View
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </CardContent>
            <CardFooter className="flex justify-center border-t py-2">
                <Button
                    variant="link"
                    onClick={() => { setActiveTab('responses'); }}
                >
                    View All Responses
                </Button>
            </CardFooter>
        </Card>
    );
};

export default UpcomingResponsesTable;