import React, { useState, useEffect } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from '../../components/ui/table';
import { Button } from '../../components/ui/button';
import { Input } from '../../components/ui/input';
import { Badge } from '../../components/ui/badge';
import {
    Edit,
    Trash2,
    Download,
} from 'lucide-react';
import api from '../../services/api';
import { toast } from 'react-toastify';
import { HazardForm } from '../Forms/HazardForm';
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
} from '../../components/ui/alert-dialog';
import LoadingSpinner from '../../components/common/LoadingSpinner';

const RiskLevelBadge = ({ level }) => {
    const colors = {
        low: 'bg-green-100 text-green-800',
        medium: 'bg-yellow-100 text-yellow-800',
        high: 'bg-red-100 text-red-800'
    };
    return (
        <Badge className={colors[level] || 'bg-gray-100 text-gray-800'}>
            {level}
        </Badge>
    );
};

const StatusBadge = ({ status }) => {
    const colors = {
        open: 'bg-red-100 text-red-800',
        'in progress': 'bg-blue-100 text-blue-800',
        mitigated: 'bg-green-100 text-green-800',
        closed: 'bg-gray-100 text-gray-800'
    };
    return (
        <Badge className={colors[status] || 'bg-gray-100 text-gray-800'}>
            {status}
        </Badge>
    );
};

const formatTypeName = (name) => {
    const parts = name.split('_');
    if (parts.length > 1) {
        return parts.slice(1).join('_').charAt(0).toUpperCase() + parts.slice(1).join('_').slice(1);
    }
    return name.charAt(0).toUpperCase() + name.slice(1);
};

export const HazardsTable = ({ project }) => {
    const [hazards, setHazards] = useState([]);
    const [hazardTypes, setHazardTypes] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingTypes, setIsLoadingTypes] = useState(true);
    const [sortConfig, setSortConfig] = useState({ key: 'hazard_date', direction: 'desc' });
    const [filters, setFilters] = useState({
        risk_level: '',
        status: '',
        location: '',
        hazard_type: '',
    });
    const [editingHazard, setEditingHazard] = useState(null);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [hazardToDelete, setHazardToDelete] = useState(null);

    useEffect(() => {
        fetchHazards();
        fetchHazardTypes();
    }, [project.id]);

    const fetchHazardTypes = async () => {
        try {
            setIsLoadingTypes(true);
            const response = await api.get('safety-types/hazard_types/');
            const transformedTypes = (response.data.types || []).map(type => ({
                ...type,
                displayName: formatTypeName(type.name)
            }));
            setHazardTypes(transformedTypes);
        } catch (error) {
            console.error('Error fetching hazard types:', error);
            setHazardTypes([]);
            toast.error("Failed to load hazard types");
        } finally {
            setIsLoadingTypes(false);
        }
    };

    const fetchHazards = async () => {
        try {
            setIsLoading(true);
            const response = await api.get(`projects/${project.id}/safety-hazards/`);
            setHazards(response.data.results || []);
        } catch (error) {
            console.error('Error fetching hazards:', error);
            toast.error("Failed to load hazards");
        } finally {
            setIsLoading(false);
        }
    };

    const handleEdit = (hazard) => {
        setEditingHazard(hazard);
        setIsEditModalOpen(true);
    };

    const handleEditComplete = async (updatedHazard) => {
        setIsEditModalOpen(false);
        setEditingHazard(null);
        await fetchHazards(); // Refresh the table data
    };

    const handleSort = (key) => {
        setSortConfig(current => ({
            key,
            direction: current.key === key && current.direction === 'asc' ? 'desc' : 'asc',
        }));
    };

    const handleExport = async (hazardId) => {
        try {
            const response = await api.get(
                `projects/${project.id}/safety-hazards/${hazardId}/export/`,
                {
                    responseType: 'blob',  // Changed from arraybuffer to blob
                    headers: {
                        'Accept': 'application/pdf',
                    }
                }
            );

            // Check if the response is actually a PDF
            const contentType = response.headers['content-type'];
            if (contentType && contentType.includes('application/pdf')) {
                // Create blob from response
                const blob = new Blob([response.data], { type: 'application/pdf' });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `hazard-report-${hazardId}.pdf`);
                document.body.appendChild(link);
                link.click();
                window.URL.revokeObjectURL(url);
                link.remove();
            } else {
                // Handle case where response is not a PDF
                toast.error("Received invalid response format");
            }
        } catch (error) {
            console.error('Error exporting hazard:', error);
            if (error.response?.status === 401) {
                toast.error("Authentication required. Please log in again.");
            } else if (error.response?.status === 406) {
                toast.error("Export format not supported.");
            } else if (error.response?.status === 403) {
                toast.error("You don't have permission to export this hazard.");
            } else {
                toast.error("Failed to export hazard report");
            }
        }
    };

    const confirmDelete = (hazard) => {
        setHazardToDelete(hazard);
        setDeleteDialogOpen(true);
    };

    const handleDelete = async () => {
        if (!hazardToDelete) return;

        try {
            await api.delete(`projects/${project.id}/safety-hazards/${hazardToDelete.id}/`);
            toast.success("Hazard deleted successfully");
            await fetchHazards();
        } catch (error) {
            console.error('Error deleting hazard:', error);
            if (error.response?.status === 401) {
                toast.error("Authentication required. Please log in again.");
            } else {
                toast.error("Failed to delete hazard");
            }
        } finally {
            setDeleteDialogOpen(false);
            setHazardToDelete(null);
        }
    };

    const sortedAndFilteredHazards = React.useMemo(() => {
        return [...hazards]
            .filter(hazard =>
                (!filters.risk_level || hazard.risk_level === filters.risk_level) &&
                (!filters.status || hazard.status === filters.status) &&
                (!filters.location || hazard.location?.toLowerCase().includes(filters.location.toLowerCase())) &&
                (!filters.hazard_type || hazard.hazard_type.id === filters.hazard_type)
            )
            .sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'asc' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'asc' ? 1 : -1;
                }
                return 0;
            });
    }, [hazards, sortConfig, filters]);

    return (
        <>
            <div className="space-y-4">
                <div className="flex items-center justify-between">
                    <div className="flex gap-4">
                        <select
                            value={filters.hazard_type}
                            onChange={(e) => setFilters(prev => ({ ...prev, hazard_type: e.target.value }))}
                            className="w-[150px] px-3 py-2 rounded-md border border-gray-300 bg-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                        >
                            <option value="">All Types</option>
                            {Array.isArray(hazardTypes) && hazardTypes.map((type) => (
                                <option key={type.id} value={type.id}>
                                    {type.displayName}
                                </option>
                            ))}
                        </select>

                        <select
                            value={filters.risk_level}
                            onChange={(e) => setFilters(prev => ({ ...prev, risk_level: e.target.value }))}
                            className="w-[150px] px-3 py-2 rounded-md border border-gray-300 bg-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                        >
                            <option value="">All Levels</option>
                            <option value="low">Low</option>
                            <option value="medium">Medium</option>
                            <option value="high">High</option>
                        </select>

                        <select
                            value={filters.status}
                            onChange={(e) => setFilters(prev => ({ ...prev, status: e.target.value }))}
                            className="w-[150px] px-3 py-2 rounded-md border border-gray-300 bg-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                        >
                            <option value="">All Status</option>
                            <option value="open">Open</option>
                            <option value="in progress">In Progress</option>
                            <option value="mitigated">Mitigated</option>
                            <option value="closed">Closed</option>
                        </select>

                        <Input
                            placeholder="Filter by location..."
                            value={filters.location}
                            onChange={(e) => setFilters(prev => ({ ...prev, location: e.target.value }))}
                            className="w-[200px]"
                        />
                    </div>
                </div>
                <Table>
                    <TableHeader>
                        <TableRow>
                            <TableHead
                                className="cursor-pointer"
                                onClick={() => handleSort('hazard_date')}
                            >
                                Date {sortConfig.key === 'hazard_date' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                            </TableHead>
                            <TableHead>Type</TableHead>
                            <TableHead>Location</TableHead>
                            <TableHead>Risk Level</TableHead>
                            <TableHead>Status</TableHead>
                            <TableHead>Mitigation Steps</TableHead>
                            <TableHead>Actions</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {isLoading ? (
                            <TableCell colSpan={7}>
                                <LoadingSpinner message="Loading hazards..." />
                            </TableCell>
                        ) : sortedAndFilteredHazards.length === 0 ? (
                            <TableRow>
                                <TableCell colSpan={7} className="text-center">No hazards found</TableCell>
                            </TableRow>
                        ) : (
                            sortedAndFilteredHazards.map((hazard) => (
                                <TableRow key={hazard.id}>
                                    <TableCell>{new Date(hazard.hazard_date).toLocaleDateString()}</TableCell>
                                    <TableCell>
                                        {formatTypeName(hazard.hazard_type.name)}{' '}
                                        {hazard.hazard_type.default_risk_level && (
                                            <Badge variant="outline" className="ml-2">
                                                {hazard.hazard_type.default_risk_level}
                                            </Badge>
                                        )}
                                    </TableCell>
                                    <TableCell>{hazard.location}</TableCell>
                                    <TableCell>
                                        <RiskLevelBadge level={hazard.risk_level} />
                                    </TableCell>
                                    <TableCell>
                                        <StatusBadge status={hazard.status} />
                                    </TableCell>
                                    <TableCell>
                                        {hazard.mitigation_steps?.length > 0 ? (
                                            <ul className="list-disc list-inside">
                                                {hazard.mitigation_steps.slice(0, 2).map((step, index) => (
                                                    <li key={index} className="truncate">{step}</li>
                                                ))}
                                                {hazard.mitigation_steps.length > 2 && <li>...</li>}
                                            </ul>
                                        ) : (
                                            'No steps defined'
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        <div className="flex gap-2">
                                            <Button
                                                variant="ghost"
                                                size="icon"
                                                onClick={() => handleEdit(hazard)}
                                            >
                                                <Edit className="h-4 w-4" />
                                            </Button>
                                            <Button
                                                variant="ghost"
                                                size="icon"
                                                onClick={() => handleExport(hazard.id)}
                                            >
                                                <Download className="h-4 w-4" />
                                            </Button>
                                            <Button
                                                variant="ghost"
                                                size="icon"
                                                onClick={() => confirmDelete(hazard)}
                                                className="text-red-600 hover:text-red-700"
                                            >
                                                <Trash2 className="h-4 w-4" />
                                            </Button>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            </div>

            {isEditModalOpen && (
                <HazardForm
                    project={project}
                    isOpen={isEditModalOpen}
                    onClose={() => {
                        setIsEditModalOpen(false);
                        setEditingHazard(null);
                    }}
                    initialData={editingHazard}
                    onSubmit={handleEditComplete}
                />
            )}
            <AlertDialog open={deleteDialogOpen} onOpenChange={setDeleteDialogOpen}>
                <AlertDialogContent>
                    <AlertDialogHeader>
                        <AlertDialogTitle>Delete Hazard</AlertDialogTitle>
                        <AlertDialogDescription>
                            Are you sure you want to delete this hazard? This action cannot be undone.
                        </AlertDialogDescription>
                    </AlertDialogHeader>
                    <AlertDialogFooter>
                        <AlertDialogCancel onClick={() => {
                            setDeleteDialogOpen(false);
                            setHazardToDelete(null);
                        }}>
                            Cancel
                        </AlertDialogCancel>
                        <AlertDialogAction
                            onClick={handleDelete}
                            className="bg-red-600 hover:bg-red-700 text-white"
                        >
                            Delete
                        </AlertDialogAction>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
        </>
    );
};

export default HazardsTable;