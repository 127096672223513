import React, { useState, useEffect } from 'react';
import { Input } from "../../ui/input";
import { Label } from "../../ui/label";
import { Button } from '../../ui/button';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue
} from "../../ui/select";
import { Alert } from "../../ui/alert";
import { AlertCircle } from "lucide-react";
import api from '../../../services/api';
import { primaryButtonClasses, secondaryButtonClasses } from '../../common/ButtonStyles';
import secureLocalStorage from "react-secure-storage";

const ScheduleInspectionForm = ({ template, projectId, phaseId, onSubmit, onCancel }) => {
    const [scheduledDate, setScheduledDate] = useState('');
    const [scheduledTime, setScheduledTime] = useState('');
    const [selectedPhaseId, setSelectedPhaseId] = useState(phaseId || '');
    const [phases, setPhases] = useState([]);
    const [loading, setLoading] = useState(false);
    const [phasesLoading, setPhasesLoading] = useState(true);
    const [error, setError] = useState(null);

    // Set current time as default
    useEffect(() => {
        const now = new Date();
        setScheduledDate(now.toISOString().split('T')[0]);
        setScheduledTime(now.toTimeString().split(' ')[0].substring(0, 5));
    }, []);

    // Fetch available phases for the project
    useEffect(() => {
        const fetchPhases = async () => {
            if (!projectId) return;

            setPhasesLoading(true);
            try {
                const tenantId = localStorage.getItem('tenantIdentifier') ||
                    secureLocalStorage.getItem('currentTenantId');

                const response = await api.get(`projects/${projectId}/phases/`, {
                    headers: { 'X-Tenant-ID': tenantId }
                });

                // Inside fetchPhases function
                if (response.data) {
                    // Handle different API response formats
                    let phasesData = [];

                    if (Array.isArray(response.data)) {
                        // Direct array
                        phasesData = response.data;
                    } else if (response.data.results && Array.isArray(response.data.results)) {
                        // Paginated response
                        phasesData = response.data.results;
                    }

                    console.log('Processed phases data:', phasesData);

                    if (phasesData.length > 0) {
                        setPhases(phasesData);

                        // If phaseId was provided and exists in the phases, select it
                        if (phaseId && phasesData.some(p => p.id === phaseId)) {
                            setSelectedPhaseId(phaseId);
                        }
                        // If we have phases but no phaseId was provided, select the first one
                        else if (!selectedPhaseId) {
                            setSelectedPhaseId(phasesData[0].id);
                            console.log('Auto-selected first phase:', phasesData[0].id);
                        }
                    } else {
                        console.warn('No phases found in response');
                    }
                } else {
                    console.warn('Unexpected phases response format:', response.data);
                }
            } catch (error) {
                console.error('Failed to fetch phases:', error);
                setError('Failed to load project phases. Please try again.');
            } finally {
                setPhasesLoading(false);
            }
        };

        fetchPhases();
    }, [projectId]);

    const handleSubmit = (e) => {
        e.preventDefault();

        // Validate phase selection
        if (!selectedPhaseId) {
            setError('Please select a phase for this inspection');
            return;
        }

        console.log('Submitting form with phase ID:', selectedPhaseId);

        setLoading(true);
        setError(null);

        try {
            const dateTime = new Date(`${scheduledDate}T${scheduledTime}`);
            onSubmit(template.id, dateTime, selectedPhaseId);
        } catch (err) {
            console.error("Error preparing inspection data:", err);
            setError(err.message || "Failed to prepare inspection data");
            setLoading(false);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="space-y-4">
            {error && (
                <Alert variant="destructive">
                    <AlertCircle className="h-4 w-4" />
                    <span>{error}</span>
                </Alert>
            )}

            <div className="space-y-2">
                <h3 className="text-lg font-semibold">{template.name}</h3>
                <p className="text-sm text-gray-600">
                    Template Type: {template.template_type}
                </p>
                <p className="text-sm text-gray-600">
                    Checklist Items: {template.checklist_count || 'N/A'}
                </p>
            </div>

            <div>
                <Label htmlFor="phase">Construction Phase</Label>
                <Select
                    value={selectedPhaseId}
                    onValueChange={setSelectedPhaseId}
                    disabled={phasesLoading}
                >
                    <SelectTrigger className="w-full">
                        <SelectValue placeholder={phasesLoading ? "Loading phases..." : "Select a phase"} />
                    </SelectTrigger>
                    <SelectContent>
                        {phases.length === 0 && !phasesLoading ? (
                            <SelectItem value="no-phases" disabled>
                                No phases available. Please create a phase first.
                            </SelectItem>
                        ) : (
                            phases.map(phase => (
                                <SelectItem key={phase.id} value={phase.id}>
                                    {phase.name}
                                </SelectItem>
                            ))
                        )}
                    </SelectContent>
                </Select>
                <p className="text-xs text-gray-500 mt-1">
                    Required: Inspections must be associated with a construction phase
                </p>
            </div>

            <div className="grid grid-cols-2 gap-4">
                <div>
                    <Label htmlFor="date">Date</Label>
                    <Input
                        id="date"
                        type="date"
                        required
                        value={scheduledDate}
                        onChange={(e) => setScheduledDate(e.target.value)}
                    />
                </div>
                <div>
                    <Label htmlFor="time">Time</Label>
                    <Input
                        id="time"
                        type="time"
                        required
                        value={scheduledTime}
                        onChange={(e) => setScheduledTime(e.target.value)}
                    />
                </div>
            </div>

            <div className="flex justify-end space-x-2 mt-4">
                <Button
                    className={secondaryButtonClasses}
                    type="button"
                    variant="outline"
                    onClick={onCancel}
                    disabled={loading}
                >
                    Cancel
                </Button>
                <Button
                    className={primaryButtonClasses}
                    type="submit"
                    disabled={loading || phasesLoading || phases.length === 0}
                >
                    {loading ? 'Scheduling...' : 'Schedule Inspection'}
                </Button>
            </div>
        </form>
    );
};

export default ScheduleInspectionForm;