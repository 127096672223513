import React, { useState, useEffect } from 'react';
import { X, Paperclip, Search, UserIcon } from 'lucide-react';
import { Progress } from "../ui/progress";
import { FeatureUpgrade } from '../Subscription/FeatureUpgrade';
import api from '../../services/api';
import { showToast } from '../../utils/toast';
import MilestoneDropdown from '../Timeline/MilestoneDropdown';
import { primaryButtonClasses, secondaryButtonClasses } from '../common/ButtonStyles';
import { LockClosedIcon } from '@heroicons/react/24/solid';

const TaskForm = ({ onClose, onSave, projectId, task = {}, allTasks = [] }) => {
    const [isProfessional, setIsProfessional] = useState(false);
    const [showUpgradeModal, setShowUpgradeModal] = useState(false);
    const [selectedFeature, setSelectedFeature] = useState(null);
    const [users, setUsers] = useState([]);
    const [visibleUsers, setVisibleUsers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [dependency, setDependency] = useState(task?.dependency || []);
    const [userCount, setUserCount] = useState(10);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploading, setUploading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [milestones, setMilestones] = useState(task?.milestone || '');
    const [existingAttachments, setExistingAttachments] = useState([]);
    const [milestoneDates, setMilestoneDates] = useState({
        start_date: '',
        due_date: ''
    });
    const [comments, setComments] = useState(task?.comments || '');
    const [selectedDependencies, setSelectedDependencies] = useState(task?.dependency || []);
    const [formData, setFormData] = useState({
        title: '',
        description: '',
        status: 'not_started',
        dependency: task?.dependency || null,
        comments,
        start_date: '',  // Added start date
        due_date: '',
        assigned_to: [],
        milestone: task?.milestone || '',
        document_type: '',
        project: projectId,
        tenant: '', // 
    });



    useEffect(() => {
        fetchSubscriptionStatus();
        fetchUsers();
        fetchMilestones();
        if (isProfessional) {
            fetchDependencies();
        }
    }, [projectId, userCount, searchTerm, isProfessional]);

    const fetchSubscriptionStatus = async () => {
        try {
            const response = await api.get('subscription-debug/');
            const currentSubscription = response.data?.current_subscription;
            const isPro = currentSubscription?.plan_name?.toLowerCase() === 'professional';
            setIsProfessional(isPro);
        } catch (error) {
            console.error('Error fetching subscription status:', error);
        }
    };

    const handleFeatureClick = (feature) => {
        if (!isProfessional) {
            setSelectedFeature(feature);
            setShowUpgradeModal(true);
            return false;
        }
        console.log("Selected Feature: ", selectedFeature);
        return true;
    };

    useEffect(() => {
        if (task) {
            setFormData({
                title: task.title || '',
                description: task.description || '',
                status: task.status || 'not_started',
                start_date: task.start_date ? task.start_date.split('T')[0] : '',
                due_date: task.due_date ? task.due_date.split('T')[0] : '',
                assigned_to: task.assigned_to || [],
                milestone: task.milestone || '',
                document_type: task.document_type || '',
                project: projectId,
                tenant: task.tenant,
                dependency: task.dependency ? task.dependency.map(dep => dep.id) : [],
            });
            setSelectedDependencies(allTasks);

            console.log("Dependencies on edit load:", task.dependency);
            console.log("formData.dependency:", formData.dependency);

        } else {
            // Set project ID for new tasks
            setFormData(prev => ({
                ...prev,
                project: projectId
            }));
        }
    }, [task, projectId]);

    const fetchMilestones = async () => {
        try {
            // Use the base milestone endpoint and filter by project
            const response = await api.get(`milestones/?project_id=${projectId}`)

            // Handle response data depending on your API structure
            const projectMilestones = response.data.results || [];

            // If we have milestones, set the tenant ID from the first milestone

            if (projectMilestones.length > 0) {
                setFormData(prev => ({
                    ...prev,
                    tenant: projectMilestones[0].tenant
                }));
            }
            setMilestones(projectMilestones);

        } catch (error) {
            console.error('Error fetching milestones:', error);
            setMilestones([]);
        }
    };

    const fetchDependencies = async () => {
        try {
            const response = await api.get(`projects/${projectId}/tasks/`);
            const availableTasks = response.data.results || [];

            // Exclude the current task if editing (check if `task` exists)
            const filteredTasks = task ? availableTasks.filter(t => t.id !== task.id) : availableTasks;

            setSelectedDependencies(filteredTasks);
        } catch (error) {
            console.error('Error fetching dependencies:', error);
            setSelectedDependencies([]);
        }
    };


    // Update handleMilestoneSelect to include date info
    const handleMilestoneSelect = (milestoneId) => {
        const selectedMilestone = milestones.find(m => m.id === milestoneId);

        setFormData(prev => ({
            ...prev,
            milestone: milestoneId,
            tenant: selectedMilestone?.tenant || prev.tenant,
            // Reset task dates if they're outside milestone range
            start_date: validateDateWithinMilestone(prev.start_date, selectedMilestone) ? prev.start_date : selectedMilestone?.start_date || '',
            due_date: validateDateWithinMilestone(prev.due_date, selectedMilestone) ? prev.due_date : selectedMilestone?.due_date || ''
        }));

        if (selectedMilestone) {
            setMilestoneDates({
                start_date: selectedMilestone.start_date,
                due_date: selectedMilestone.due_date
            });
        } else {
            setMilestoneDates({ start_date: '', due_date: '' });
        }
    };

    // Helper function to validate dates
    const validateDateWithinMilestone = (date, milestone) => {
        if (!date || !milestone) return true;
        const taskDate = new Date(date);
        const milestoneStart = new Date(milestone.start_date);
        const milestoneEnd = new Date(milestone.due_date);
        return taskDate >= milestoneStart && taskDate <= milestoneEnd;
    };


    const fetchUsers = async () => {
        try {
            const response = await api.get(`projects/${projectId}/users/`);
            const allUsers = Array.isArray(response.data) ? response.data : response.data.results || [];
            const filteredUsers = allUsers.filter(user =>
                user.first_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                user.email.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setUsers(allUsers);
            setVisibleUsers(filteredUsers.slice(0, userCount));
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    useEffect(() => {
        if (users.length > 0 && task && task.assigned_to) {
            // Only set the IDs of assigned users in formData.assigned_to
            setFormData(prev => ({
                ...prev,
                assigned_to: task.assigned_to.map(user => user.id)
            }));

        }
    }, [users, task]);


    const loadMoreUsers = () => setUserCount(prev => prev + 10);

    // Update handle change for date validation
    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'start_date' || name === 'due_date') {
            const newDate = new Date(value);
            const currentStartDate = name === 'start_date' ? value : formData.start_date;
            const currentDueDate = name === 'due_date' ? value : formData.due_date;

            // Validate against milestone dates
            if (milestoneDates.start_date && milestoneDates.due_date) {
                const milestoneStart = new Date(milestoneDates.start_date);
                const milestoneEnd = new Date(milestoneDates.due_date);

                if (newDate < milestoneStart || newDate > milestoneEnd) {
                    showToast.warning('Date must be within milestone duration');
                    return;
                }
            }

            // Validate start date isn't after due date and vice versa
            if (name === 'start_date' && new Date(currentDueDate) < newDate) {
                showToast.warning('Start date cannot be after due date');
                return;
            }
            if (name === 'due_date' && new Date(currentStartDate) > newDate) {
                showToast.warning('Due date cannot be before start date');
                return;
            }
        }

        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleFileSelect = (event) => {
        const files = Array.from(event.target.files);
        setSelectedFiles(prev => [...prev, ...files]);
    };

    const removeFile = (index) => {
        setSelectedFiles(prev => prev.filter((_, i) => i !== index));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setUploadProgress(0);

        try {
            const taskData = new FormData();

            // Basic fields
            taskData.append('title', formData.title);
            taskData.append('description', formData.description);
            taskData.append('status', formData.status);
            taskData.append('start_date', formData.start_date);
            taskData.append('due_date', formData.due_date);
            taskData.append('project', projectId);
            taskData.append('comments', formData.comments);
            taskData.append('tenant', formData.tenant);
            taskData.append('milestone', formData.milestone);
            if (formData.dependency) {
                taskData.append('dependency', formData.dependency);
            }

            // Handle assigned users
            if (Array.isArray(formData.assigned_to)) {
                formData.assigned_to.forEach(userId => {
                    taskData.append('assigned_to', userId);
                });
            }

            // Handle dependencies if any
            if (Array.isArray(selectedDependencies) && selectedDependencies.length > 0) {
                selectedDependencies.forEach(depId => {
                    if (depId) {
                        taskData.append('dependency', depId);
                    }
                });
            }

            // Handle document type if present
            if (formData.document_type) {
                taskData.append('document_type', formData.document_type);
            }

            // Handle file attachments
            if (selectedFiles.length > 0) {
                selectedFiles.forEach(file => {
                    taskData.append('attachments', file);
                });
            }

            await onSave(taskData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                onUploadProgress: (progressEvent) => {
                    const progress = (progressEvent.loaded / progressEvent.total) * 100;
                    setUploadProgress(progress);
                },
            });

            onClose();
        } catch (error) {
            console.error('Error saving task:', error);
            console.error('Error response:', error.response?.data);
        } finally {
            setIsLoading(false);
        }
    };

    // Update the dependency selection handler
    const handleDependencyChange = (selectedIds) => {
        // Ensure we're working with an array of valid UUIDs
        const validDependencies = Array.isArray(selectedIds)
            ? selectedIds.filter(id => id && typeof id === 'string' && id.trim() !== '')
            : [];

        setFormData(prev => ({
            ...prev,
            dependency: validDependencies
        }));
    };

    // Updated user selection handling
    const handleUserSelect = (userId) => {
        setFormData(prev => {
            // Ensure we're working with an array
            const currentAssigned = Array.isArray(prev.assigned_to) ? prev.assigned_to : [];
            const isSelected = currentAssigned.includes(userId);

            return {
                ...prev,
                assigned_to: isSelected
                    ? currentAssigned.filter(id => id !== userId)
                    : [...currentAssigned, userId]
            };
        });
    };


    const ProFeatureBadge = () => (
        <span className="ml-2 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-gray-100 text-gray-800">
            <LockClosedIcon className="h-3 w-3 mr-1" />
            Pro Feature
        </span>
    );

    // Update dependencies section
    const DependencySection = () => (
        <div>
            <div className="flex items-center">
                <label className="block text-sm font-medium text-gray-700">
                    Dependencies
                </label>
                <ProFeatureBadge />
            </div>
            <div
                className="mt-1 relative cursor-pointer border rounded-md p-3 bg-gray-50"
                onClick={() => handleFeatureClick('task_dependencies_milestones')}
            >
                <div className="text-sm text-gray-500 flex items-center">
                    <LockClosedIcon className="h-4 w-4 mr-2" />
                    Add task dependencies with our Professional plan
                </div>
            </div>
        </div>
    );

    // Update milestones section
    const MilestoneSection = () => (
        <div>

            <MilestoneDropdown
                projectId={projectId}
                selectedMilestone={formData.milestone}
                onMilestoneSelect={handleMilestoneSelect}
            />
        </div>
    );


    return (
        <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 bg-black bg-opacity-50" onClick={onClose}></div>
            <div className="absolute inset-y-0 right-0 w-full max-w-xl bg-white shadow-xl">
                <div className="h-full flex flex-col">
                    {/* Header */}
                    <div className="px-6 py-4 border-b border-gray-200 flex justify-between items-center">
                        <h2 className="text-2xl font-bold">{task ? 'Edit Task' : 'New Task'}</h2>
                        <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
                            <X size={24} />
                        </button>
                    </div>

                    {/* Form Content */}
                    <div className="flex-1 overflow-y-auto p-6">
                        <form onSubmit={handleSubmit} className="space-y-6">
                            {/* Title */}
                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                    Title <span className="text-red-500">*</span>
                                </label>
                                <input
                                    type="text"
                                    name="title"
                                    value={formData.title}
                                    onChange={handleChange}
                                    required
                                    className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                />
                            </div>

                            {/* Description */}
                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-1">Description</label>
                                <textarea
                                    name="description"
                                    value={formData.description}
                                    onChange={handleChange}
                                    rows={4}
                                    className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                />
                            </div>
                            {isProfessional ? (
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Dependencies
                                    </label>
                                    <select
                                        name="dependency"
                                        value={formData.dependency || ''}
                                        onChange={(e) => handleDependencyChange(Array.from(e.target.selectedOptions, option => option.value))}
                                        className="w-full p-2 border border-gray-300 rounded-md"
                                    >
                                        <option value="">Select Dependency</option>
                                        {allTasks.map(depTask => (
                                            <option key={depTask.id} value={depTask.id}>
                                                {depTask.title}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            ) : (
                                <DependencySection />
                            )}
                            {/* Status */}
                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-1">Status</label>
                                <select
                                    name="status"
                                    value={formData.status}
                                    onChange={handleChange}
                                    className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                >
                                    <option value="not_started">Not Started</option>
                                    <option value="in_progress">In Progress</option>
                                    <option value="done">Done</option>
                                </select>
                            </div>
                            <MilestoneSection />
                            {/*} {isProfessional ? (
                                <MilestoneDropdown
                                    projectId={projectId}
                                    selectedMilestone={formData.milestone}
                                    onMilestoneSelect={handleMilestoneSelect}
                                />
                            ) : (
                                <MilestoneSection />
                            )}
                            {/* Milestone Dates Display */}
                            {milestoneDates.start_date && milestoneDates.due_date && (
                                <div className="text-sm text-gray-500 -mt-4 mb-4 pl-2 border-l-2 border-blue-200">
                                    <p className="text-xs uppercase tracking-wide mb-1">Milestone Duration</p>
                                    <div className="flex items-center space-x-2">
                                        <span>{new Date(milestoneDates.start_date).toLocaleDateString()}</span>
                                        <span>→</span>
                                        <span>{new Date(milestoneDates.due_date).toLocaleDateString()}</span>
                                    </div>
                                </div>
                            )}
                            {/* Update the date input fields */}
                            <div className="grid grid-cols-2 gap-4">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Start Date <span className="text-red-500">*</span>
                                    </label>
                                    <input
                                        type="date"
                                        name="start_date"
                                        value={formData.start_date}
                                        onChange={handleChange}
                                        min={milestoneDates.start_date}
                                        max={milestoneDates.due_date}
                                        required
                                        className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                    />
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Due Date <span className="text-red-500">*</span>
                                    </label>
                                    <input
                                        type="date"
                                        name="due_date"
                                        value={formData.due_date}
                                        onChange={handleChange}
                                        min={formData.start_date || milestoneDates.start_date}
                                        max={milestoneDates.due_date}
                                        required
                                        className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                    />
                                </div>
                            </div>

                            {/* Assign Users Section */}
                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                    Assign Users <span className="text-red-500">*</span>
                                </label>
                                <div className="border rounded-md p-4">
                                    <div className="relative mb-4">
                                        <input
                                            type="text"
                                            placeholder="Search users..."
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                            className="w-full pl-10 pr-4 py-2 border rounded-md"
                                        />
                                        <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
                                    </div>

                                    <div className="max-h-60 overflow-y-auto space-y-2">
                                        {visibleUsers.map((user) => (
                                            <div
                                                key={user.id}
                                                className="flex items-center p-2 hover:bg-gray-50 rounded-md"
                                                onClick={() => handleUserSelect(user.id)}
                                            >
                                                <input
                                                    type="checkbox"
                                                    checked={formData.assigned_to.includes(user.id)}
                                                    onChange={() => handleUserSelect(user.id)}
                                                    className="mr-3"
                                                />
                                                <div>
                                                    <p className="font-medium">
                                                        {user.first_name} {user.last_name}
                                                    </p>
                                                    <p className="text-sm text-gray-600">{user.email}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>

                                    {formData.assigned_to.length > 0 && (
                                        <div className="mt-2 p-2 bg-blue-50 rounded-md">
                                            <p className="text-sm text-blue-600">
                                                {formData.assigned_to.length} user(s) selected
                                            </p>
                                        </div>
                                    )}
                                </div>


                                {/* Load More Users */}
                                {users.length > visibleUsers.length && (
                                    <button
                                        type="button"
                                        onClick={loadMoreUsers}
                                        className="w-full mt-2 py-2 text-blue-600 hover:text-blue-800 text-sm"
                                    >
                                        Load More Users
                                    </button>
                                )}
                            </div>


                            {/* File Attachments */}
                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-2">Attachments</label>
                                <div className="space-y-4">
                                    {/* Document Type */}
                                    <select
                                        name="document_type"
                                        value={formData.document_type}
                                        onChange={(e) => setFormData({ ...formData, document_type: e.target.value })}
                                        className="w-full p-2 border border-gray-300 rounded-md"
                                    >
                                        <option value="">Select Document Type</option>
                                        <option value="Blueprint">Blueprint</option>
                                        <option value="RFI">Request for Information</option>
                                        <option value="Change Order">Change Order</option>
                                        <option value="Permit">Permit</option>
                                        <option value="Submittal">Submittal</option>
                                        <option value="Contract">Contract</option>
                                        <option value="Inspection Report">Inspection Report</option>
                                        <option value="Other">Other</option>
                                    </select>

                                    {/* File Upload */}
                                    <div>
                                        <input
                                            type="file"
                                            multiple
                                            onChange={handleFileSelect}
                                            className="hidden"
                                            id="task-file-upload"
                                        />
                                        <label
                                            htmlFor="task-file-upload"
                                            className="inline-flex items-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 cursor-pointer"
                                        >
                                            <Paperclip className="mr-2" size={16} />
                                            Select Files
                                        </label>
                                    </div>

                                    {/* Selected Files List */}
                                    {selectedFiles.length > 0 && (
                                        <div className="mt-4">
                                            <h4 className="font-medium mb-2">Selected Files:</h4>
                                            <ul className="space-y-2">
                                                {selectedFiles.map((file, index) => (
                                                    <li key={index} className="flex items-center justify-between bg-gray-100 p-2 rounded">
                                                        <span className="truncate">{file.name}</span>
                                                        <button
                                                            type="button"
                                                            onClick={() => removeFile(index)}
                                                            className="text-red-600 hover:text-red-800"
                                                        >
                                                            <X size={16} />
                                                        </button>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}

                                    {/* Existing Attachments */}
                                    {existingAttachments.length > 0 && (
                                        <div className="mt-4">
                                            <h4 className="font-medium mb-2">Existing Attachments:</h4>
                                            <ul className="space-y-2">
                                                {existingAttachments.map((attachment) => (
                                                    <li key={attachment.id} className="flex items-center justify-between bg-gray-100 p-2 rounded">
                                                        <a href={attachment.document.file} target="_blank" rel="noopener noreferrer" className="truncate">
                                                            {attachment.document.title}
                                                        </a>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                </div>
                            </div>

                        </form>
                    </div>

                    {/* Footer */}
                    <div className="px-6 py-4 border-t border-gray-200 flex justify-end space-x-4">
                        <button
                            type="button"
                            onClick={onClose}
                            className={secondaryButtonClasses}
                            disabled={isLoading || uploading}
                        >
                            Cancel
                        </button>
                        <button
                            onClick={handleSubmit}
                            className={primaryButtonClasses}
                            disabled={isLoading || uploading}
                        >
                            {isLoading || uploading ? 'Saving...' : (task ? 'Update Task' : 'Create Task')}
                        </button>
                    </div>
                </div >

            </div >
            {/* Upgrade Modal */}
            {showUpgradeModal && selectedFeature && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
                    <div className="bg-white rounded-lg max-w-2xl w-full">
                        <div className="p-6">
                            <div className="flex justify-between items-center mb-4">
                                <h3 className="text-lg font-semibold">
                                    {selectedFeature === 'task_dependencies_milestones' ? 'Unlock Task Dependencies' : 'Unlock Milestone Management'}
                                </h3>
                                <button
                                    onClick={() => setShowUpgradeModal(false)}
                                    className="text-gray-500 hover:text-gray-700"
                                >
                                    <X className="h-6 w-6" />
                                </button>
                            </div>
                            <FeatureUpgrade
                                featureName={selectedFeature}
                                className="mt-4"
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};


export default TaskForm;