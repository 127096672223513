import React, { useState } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '../../components/ui/cardui';
import { Button } from '../../components/ui/button';
import { Input } from '../../components/ui/input';
import { Label } from '../../components/ui/label';
import { Spinner } from '../../components/ui/spinner';
import { Alert, AlertTitle, AlertDescription } from '../../components/ui/alert';
import { runMonteCarloSimulation } from '../../services/riskApi';
import { toast } from 'react-toastify';
import { Bar } from 'react-chartjs-2';

const MonteCarloSimulation = ({ projectId }) => {
    const [formData, setFormData] = useState({
        baseline_duration: 100,
        baseline_budget: 500000,
        simulation_count: 1000,
        confidence_level: 80
    });
    const [results, setResults] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: name === 'confidence_level' ? parseInt(value) : parseFloat(value)
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const simulationResults = await runMonteCarloSimulation({
                project_id: projectId,
                ...formData
            });
            setResults(simulationResults);
            toast.success('Monte Carlo simulation completed successfully');
        } catch (error) {
            console.error('Error running Monte Carlo simulation:', error);
            toast.error('Failed to run Monte Carlo simulation');
        } finally {
            setLoading(false);
        }
    };

    // Format currency
    const formatCurrency = (value) => {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        }).format(value);
    };

    // Format duration (days)
    const formatDuration = (value) => {
        return `${Math.round(value)} days`;
    };

    // Calculate chart data for budget
    const getBudgetChartData = () => {
        if (!results) return null;

        return {
            labels: ['Baseline', 'P10', 'P50', 'P80', 'P90', `P${results.confidence_level}`, 'Worst Case'],
            datasets: [
                {
                    label: 'Budget Forecast',
                    data: [
                        results.budget.baseline,
                        results.budget.p10,
                        results.budget.p50,
                        results.budget.p80,
                        results.budget.p90,
                        results.budget.confidence_level,
                        results.budget.worst_case
                    ],
                    backgroundColor: [
                        'rgba(75, 192, 192, 0.6)',  // Baseline - Teal
                        'rgba(54, 162, 235, 0.6)',  // P10 - Blue
                        'rgba(255, 206, 86, 0.6)',  // P50 - Yellow
                        'rgba(255, 159, 64, 0.6)',  // P80 - Orange
                        'rgba(255, 99, 132, 0.6)',  // P90 - Red
                        'rgba(153, 102, 255, 0.6)', // Confidence - Purple
                        'rgba(231, 76, 60, 0.6)'    // Worst - Dark Red
                    ],
                    borderColor: [
                        'rgba(75, 192, 192, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(255, 159, 64, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(231, 76, 60, 1)'
                    ],
                    borderWidth: 1
                }
            ]
        };
    };

    // Calculate chart data for duration
    const getDurationChartData = () => {
        if (!results) return null;

        return {
            labels: ['Baseline', 'P10', 'P50', 'P80', 'P90', `P${results.confidence_level}`, 'Worst Case'],
            datasets: [
                {
                    label: 'Duration Forecast',
                    data: [
                        results.duration.baseline,
                        results.duration.p10,
                        results.duration.p50,
                        results.duration.p80,
                        results.duration.p90,
                        results.duration.confidence_level,
                        results.duration.worst_case
                    ],
                    backgroundColor: [
                        'rgba(75, 192, 192, 0.6)',  // Baseline - Teal
                        'rgba(54, 162, 235, 0.6)',  // P10 - Blue
                        'rgba(255, 206, 86, 0.6)',  // P50 - Yellow
                        'rgba(255, 159, 64, 0.6)',  // P80 - Orange
                        'rgba(255, 99, 132, 0.6)',  // P90 - Red
                        'rgba(153, 102, 255, 0.6)', // Confidence - Purple
                        'rgba(231, 76, 60, 0.6)'    // Worst - Dark Red
                    ],
                    borderColor: [
                        'rgba(75, 192, 192, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(255, 159, 64, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(231, 76, 60, 1)'
                    ],
                    borderWidth: 1
                }
            ]
        };
    };

    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            y: {
                beginAtZero: false
            }
        }
    };

    return (
        <Card className="mb-4">
            <CardHeader>
                <CardTitle>Monte Carlo Risk Simulation</CardTitle>
            </CardHeader>
            <CardContent>
                <p className="text-muted-foreground mb-6">
                    Run Monte Carlo simulations to forecast project outcomes based on identified risks.
                    This helps estimate the likely range of project duration and budget considering risk probabilities.
                </p>

                <form onSubmit={handleSubmit}>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
                        <div>
                            <Label htmlFor="baseline_duration" className="mb-1">Baseline Duration (Days)</Label>
                            <Input
                                id="baseline_duration"
                                type="number"
                                name="baseline_duration"
                                value={formData.baseline_duration}
                                onChange={handleChange}
                                min="1"
                                required
                            />
                        </div>
                        <div>
                            <Label htmlFor="baseline_budget" className="mb-1">Baseline Budget ($)</Label>
                            <Input
                                id="baseline_budget"
                                type="number"
                                name="baseline_budget"
                                value={formData.baseline_budget}
                                onChange={handleChange}
                                min="1"
                                required
                            />
                        </div>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
                        <div>
                            <Label htmlFor="simulation_count" className="mb-1">Number of Simulations</Label>
                            <Input
                                id="simulation_count"
                                type="number"
                                name="simulation_count"
                                value={formData.simulation_count}
                                onChange={handleChange}
                                min="100"
                                max="10000"
                                required
                            />
                            <p className="text-sm text-muted-foreground mt-1">
                                More simulations = more accurate results (recommended: 1000+)
                            </p>
                        </div>
                        <div>
                            <Label htmlFor="confidence_level" className="mb-1">Confidence Level (%)</Label>
                            <Input
                                id="confidence_level"
                                type="number"
                                name="confidence_level"
                                value={formData.confidence_level}
                                onChange={handleChange}
                                min="1"
                                max="99"
                                required
                            />
                            <p className="text-sm text-muted-foreground mt-1">
                                Typical values: 80% or 90%
                            </p>
                        </div>
                    </div>

                    <div className="flex justify-center mb-6">
                        <Button
                            type="submit"
                            disabled={loading}
                            className="px-6"
                        >
                            {loading ? (
                                <div className="flex items-center">
                                    <Spinner className="mr-2" />
                                    Running Simulation...
                                </div>
                            ) : (
                                'Run Simulation'
                            )}
                        </Button>
                    </div>
                </form>

                {results && (
                    <div className="mt-8">
                        <h5 className="text-lg font-semibold mb-4">Simulation Results ({results.simulation_count} iterations)</h5>

                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            <Card>
                                <CardHeader className="bg-primary text-primary-foreground">
                                    <CardTitle>Duration Forecast</CardTitle>
                                </CardHeader>
                                <CardContent className="pt-4">
                                    <div className="h-64">
                                        <Bar data={getDurationChartData()} options={chartOptions} />
                                    </div>

                                    <div className="mt-4">
                                        <table className="w-full">
                                            <tbody>
                                                <tr className="border-b">
                                                    <th className="text-left py-2">Baseline:</th>
                                                    <td className="text-right py-2">{formatDuration(results.duration.baseline)}</td>
                                                </tr>
                                                <tr className="border-b">
                                                    <th className="text-left py-2">Best Case (P10):</th>
                                                    <td className="text-right py-2">{formatDuration(results.duration.p10)}</td>
                                                </tr>
                                                <tr className="border-b">
                                                    <th className="text-left py-2">Most Likely (P50):</th>
                                                    <td className="text-right py-2">{formatDuration(results.duration.p50)}</td>
                                                </tr>
                                                <tr className="border-b bg-amber-50">
                                                    <th className="text-left py-2">Recommended (P{results.confidence_level}):</th>
                                                    <td className="text-right py-2 font-medium">{formatDuration(results.duration.confidence_level)}</td>
                                                </tr>
                                                <tr>
                                                    <th className="text-left py-2">Worst Case:</th>
                                                    <td className="text-right py-2">{formatDuration(results.duration.worst_case)}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </CardContent>
                            </Card>

                            <Card>
                                <CardHeader className="bg-green-600 text-white">
                                    <CardTitle>Budget Forecast</CardTitle>
                                </CardHeader>
                                <CardContent className="pt-4">
                                    <div className="h-64">
                                        <Bar data={getBudgetChartData()} options={chartOptions} />
                                    </div>

                                    <div className="mt-4">
                                        <table className="w-full">
                                            <tbody>
                                                <tr className="border-b">
                                                    <th className="text-left py-2">Baseline:</th>
                                                    <td className="text-right py-2">{formatCurrency(results.budget.baseline)}</td>
                                                </tr>
                                                <tr className="border-b">
                                                    <th className="text-left py-2">Best Case (P10):</th>
                                                    <td className="text-right py-2">{formatCurrency(results.budget.p10)}</td>
                                                </tr>
                                                <tr className="border-b">
                                                    <th className="text-left py-2">Most Likely (P50):</th>
                                                    <td className="text-right py-2">{formatCurrency(results.budget.p50)}</td>
                                                </tr>
                                                <tr className="border-b bg-amber-50">
                                                    <th className="text-left py-2">Recommended (P{results.confidence_level}):</th>
                                                    <td className="text-right py-2 font-medium">{formatCurrency(results.budget.confidence_level)}</td>
                                                </tr>
                                                <tr>
                                                    <th className="text-left py-2">Worst Case:</th>
                                                    <td className="text-right py-2">{formatCurrency(results.budget.worst_case)}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </CardContent>
                            </Card>
                        </div>

                        <Alert className="mt-6 bg-blue-50">
                            <AlertTitle>Analysis Summary</AlertTitle>
                            <AlertDescription>
                                <p className="mb-2">
                                    Based on the Monte Carlo simulation with {results.simulation_count} iterations and a {results.confidence_level}% confidence level, we recommend:
                                </p>
                                <ul className="list-disc pl-6 space-y-1">
                                    <li>
                                        <strong>Project Duration:</strong> Plan for {formatDuration(results.duration.confidence_level)} (a {Math.round((results.duration.confidence_level / results.duration.baseline - 1) * 100)}% buffer).
                                    </li>
                                    <li>
                                        <strong>Project Budget:</strong> Budget {formatCurrency(results.budget.confidence_level)} (a {Math.round((results.budget.confidence_level / results.budget.baseline - 1) * 100)}% contingency).
                                    </li>
                                </ul>
                            </AlertDescription>
                        </Alert>
                    </div>
                )}
            </CardContent>
        </Card>
    );
};

export default MonteCarloSimulation;