import React from 'react';
import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue } from '../components/ui/select';
import PropTypes from 'prop-types';

const UNIT_GROUPS = {
    LENGTH: [
        { value: 'LF', label: 'Linear Foot' }
    ],
    AREA: [
        { value: 'SF', label: 'Square Foot' },
        { value: 'SY', label: 'Square Yard' },
        { value: 'SQUARE', label: 'Square (Roofing)' }
    ],
    VOLUME: [
        { value: 'CF', label: 'Cubic Foot' },
        { value: 'CY', label: 'Cubic Yard' },
        { value: 'GAL', label: 'Gallon' },
        { value: 'ML', label: 'Milliliter' }
    ],
    WEIGHT: [
        { value: 'LB', label: 'Pound' },
        { value: 'TON', label: 'Ton' }
    ],
    TIME: [
        { value: 'HR', label: 'Hour' },
        { value: 'DAY', label: 'Day' },
        { value: 'MO', label: 'Month' }
    ],
    QUANTITY: [
        { value: 'EA', label: 'Each' },
        { value: 'PKG', label: 'Package' }
    ],
    LUMBER: [
        { value: 'BF', label: 'Board Foot' }
    ],
    OTHER: [
        { value: 'LS', label: 'Lump Sum' },
        { value: 'OTHER', label: 'Other' }
    ]
};

const UnitSelect = ({ value, onChange, className, material, disabled }) => {
    // Filter units based on material if provided
    const getCompatibleUnits = (material) => {
        const materialUnitMap = {
            'CONCRETE': ['CY', 'CF', 'TON'],
            'GRAVEL': ['CY', 'TON', 'LB'],
            'SAND': ['CY', 'TON', 'LB'],
            'ASPHALT': ['TON', 'SF', 'SY'],
            'ROOFING': ['SQUARE', 'SF', 'BF'],
            'LUMBER': ['BF', 'LF', 'EA']
        };

        return material ? materialUnitMap[material] : null;
    };

    const compatibleUnits = material ? getCompatibleUnits(material) : null;

    return (
        <Select
            value={value}
            onValueChange={onChange}
            disabled={disabled}
        >
            <SelectTrigger className={className}>
                <SelectValue placeholder="Select unit" />
            </SelectTrigger>
            <SelectContent>
                {Object.entries(UNIT_GROUPS).map(([groupName, units]) => {
                    // Filter units if material is specified
                    const filteredUnits = compatibleUnits
                        ? units.filter(unit => compatibleUnits.includes(unit.value))
                        : units;

                    // Only show groups that have units after filtering
                    if (filteredUnits.length === 0) return null;

                    return (
                        <SelectGroup key={groupName}>
                            <SelectLabel>{groupName}</SelectLabel>
                            {filteredUnits.map((unit) => (
                                <SelectItem
                                    key={unit.value}
                                    value={unit.value}
                                    className="pl-6"
                                >
                                    {unit.label}
                                </SelectItem>
                            ))}
                        </SelectGroup>
                    );
                })}
            </SelectContent>
        </Select>
    );
};

// Add PropTypes for better documentation and type checking
UnitSelect.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    className: PropTypes.string,
    material: PropTypes.oneOf([
        'CONCRETE',
        'GRAVEL',
        'SAND',
        'ASPHALT',
        'ROOFING',
        'LUMBER',
        null
    ]),
    disabled: PropTypes.bool
};

UnitSelect.defaultProps = {
    className: '',
    material: null,
    disabled: false
};

export default UnitSelect;