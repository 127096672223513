import React, { useState, useEffect } from 'react';
import {
    Card,
    CardContent,
    CardHeader,
    CardTitle
} from "../../components/ui/cardui";
import { Button } from "../../components/ui/button";
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogFooter
} from "../../components/ui/dialog";
import { Input } from "../../components/ui/input";
import { Label } from "../../components/ui/label";
import { Textarea } from "../../components/ui/textarea";
import { DatePicker } from "../../components/ui/date-picker";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../components/ui/select";
import { Badge } from "../../components/ui/badge";
import {
    Star,
    Building,
    Tag,
    Calendar,
    Clock,
    ArrowUp,
    ArrowDown,
    Edit,
    Trash2,
    Plus,
    Search
} from "lucide-react";
import { toast } from "react-toastify";
import api from '../../services/api';
import { primaryButtonClasses, secondaryButtonClasses } from '../common/ButtonStyles';
import secureLocalStorage from "react-secure-storage";

const PreferredSupplierForm = ({ isOpen, onClose, onSubmit, preferredSupplier = null }) => {
    const [formData, setFormData] = useState({
        supplier: '',
        category: '',
        priority: 1,
        negotiated_discount: '',
        agreement_start: new Date(),
        agreement_end: null,
        notes: ''
    });
    const [loading, setLoading] = useState(false);
    const [suppliers, setSuppliers] = useState([]);
    const [categories, setCategories] = useState([]);
    const [loadingReferences, setLoadingReferences] = useState(true);

    useEffect(() => {
        const fetchReferenceData = async () => {
            setLoadingReferences(true);
            try {
                const [suppliersResponse, categoriesResponse] = await Promise.all([
                    api.get('suppliers/'),
                    api.get('supplier-categories/')
                ]);

                setSuppliers(suppliersResponse.data.results || suppliersResponse.data);
                setCategories(categoriesResponse.data.results || categoriesResponse.data);
            } catch (error) {
                console.error("Error fetching reference data:", error);
                toast.error("Failed to load suppliers or categories");
            } finally {
                setLoadingReferences(false);
            }
        };

        fetchReferenceData();
    }, []);

    useEffect(() => {
        if (preferredSupplier) {
            setFormData({
                supplier: preferredSupplier.supplier.id || '',
                category: preferredSupplier.category.id || '',
                priority: preferredSupplier.priority || 1,
                negotiated_discount: preferredSupplier.negotiated_discount || '',
                agreement_start: preferredSupplier.agreement_start ? new Date(preferredSupplier.agreement_start) : new Date(),
                agreement_end: preferredSupplier.agreement_end ? new Date(preferredSupplier.agreement_end) : null,
                notes: preferredSupplier.notes || ''
            });
        } else {
            setFormData({
                supplier: '',
                category: '',
                priority: 1,
                negotiated_discount: '',
                agreement_start: new Date(),
                agreement_end: null,
                notes: ''
            });
        }
    }, [preferredSupplier, isOpen]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleSelectChange = (name, value) => {
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleDateChange = (name, date) => {
        setFormData(prev => ({ ...prev, [name]: date }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!formData.supplier || !formData.category) {
            toast.error("Supplier and category are required");
            return;
        }

        if (!formData.agreement_start) {
            toast.error("Agreement start date is required");
            return;
        }

        if (!formData.agreement_end) {
            toast.error("Agreement end date is required");
            return;
        }

        // Validate that end date is after start date
        if (new Date(formData.agreement_end) <= new Date(formData.agreement_start)) {
            toast.error("Agreement end date must be after start date");
            return;
        }

        setLoading(true);
        try {
            // Format dates for API
            const formattedData = {
                ...formData,
                agreement_start: formData.agreement_start.toISOString().split('T')[0],
                agreement_end: formData.agreement_end.toISOString().split('T')[0]
            };

            await onSubmit(formattedData);
            onClose();
        } catch (error) {
            console.error("Error submitting preferred supplier:", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className="sm:max-w-[600px]">
                <DialogHeader>
                    <DialogTitle>{preferredSupplier ? 'Edit Preferred Supplier' : 'Add Preferred Supplier'}</DialogTitle>
                </DialogHeader>

                <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                        <Label htmlFor="supplier">Supplier*</Label>
                        <Select
                            value={formData.supplier}
                            onValueChange={(value) => handleSelectChange('supplier', value)}
                            disabled={loadingReferences}
                            required
                        >
                            <SelectTrigger>
                                <SelectValue placeholder="Select supplier" />
                            </SelectTrigger>
                            <SelectContent>
                                {suppliers.map(supplier => (
                                    <SelectItem key={supplier.id} value={supplier.id}>
                                        {supplier.company_name}
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    </div>

                    <div>
                        <Label htmlFor="category">Category*</Label>
                        <Select
                            value={formData.category}
                            onValueChange={(value) => handleSelectChange('category', value)}
                            disabled={loadingReferences}
                            required
                        >
                            <SelectTrigger>
                                <SelectValue placeholder="Select category" />
                            </SelectTrigger>
                            <SelectContent>
                                {categories.map(category => (
                                    <SelectItem key={category.id} value={category.id}>
                                        {category.name}
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                            <Label htmlFor="priority">Priority</Label>
                            <Input
                                id="priority"
                                name="priority"
                                type="number"
                                min="1"
                                value={formData.priority}
                                onChange={handleInputChange}
                                required
                            />
                            <p className="text-xs text-gray-500 mt-1">
                                Lower number = Higher priority (1 is highest)
                            </p>
                        </div>
                        <div>
                            <Label htmlFor="negotiated_discount">Negotiated Discount (%)</Label>
                            <Input
                                id="negotiated_discount"
                                name="negotiated_discount"
                                type="number"
                                step="0.01"
                                min="0"
                                max="100"
                                value={formData.negotiated_discount}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                            <Label htmlFor="agreement_start">Agreement Start Date*</Label>
                            <DatePicker
                                value={formData.agreement_start}
                                onChange={(date) => handleDateChange('agreement_start', date)}
                            />
                        </div>
                        <div>
                            <Label htmlFor="agreement_end">Agreement End Date*</Label>
                            <DatePicker
                                value={formData.agreement_end}
                                onChange={(date) => handleDateChange('agreement_end', date)}
                            />
                        </div>
                    </div>

                    <div>
                        <Label htmlFor="notes">Notes</Label>
                        <Textarea
                            id="notes"
                            name="notes"
                            rows={3}
                            value={formData.notes || ''}
                            onChange={handleInputChange}
                            placeholder="Enter any additional notes about this preferred supplier arrangement"
                        />
                    </div>

                    <DialogFooter>
                        <Button
                            type="button"
                            variant="outline"
                            onClick={onClose}
                            className={secondaryButtonClasses}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            className={primaryButtonClasses}
                            disabled={loading || loadingReferences}
                        >
                            {loading ? 'Saving...' : preferredSupplier ? 'Update Agreement' : 'Create Agreement'}
                        </Button>
                    </DialogFooter>
                </form>
            </DialogContent>
        </Dialog>
    );
};

const PreferredSuppliers = () => {
    const [preferredSuppliers, setPreferredSuppliers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [selectedAgreement, setSelectedAgreement] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [filterCategory, setFilterCategory] = useState('');
    const [categories, setCategories] = useState([]);
    const [suppressFetch, setSuppressFetch] = useState(false);

    useEffect(() => {
        fetchCategories();
    }, []);

    useEffect(() => {
        if (!suppressFetch) {
            fetchPreferredSuppliers();
        }
    }, [filterCategory]);

    const fetchCategories = async () => {
        try {
            const response = await api.get('supplier-categories/');
            setCategories(response.data.results || response.data);
        } catch (error) {
            console.error("Error fetching categories:", error);
        }
    };

    const fetchPreferredSuppliers = async () => {
        setLoading(true);
        try {
            const params = {};
            if (filterCategory) {
                params.category = filterCategory;
            }

            const response = await api.get('preferred-suppliers/', { params });

            // Get full data for each preferred supplier
            const psData = response.data.results || response.data;
            const enhancedData = await Promise.all(psData.map(async (ps) => {
                // If the supplier and category are already expanded objects, use them
                if (typeof ps.supplier === 'object' && ps.supplier !== null && typeof ps.category === 'object' && ps.category !== null) {
                    return ps;
                }

                // Otherwise fetch the detailed data
                try {
                    const [supplierResponse, categoryResponse] = await Promise.all([
                        api.get(`suppliers/${ps.supplier}/`),
                        api.get(`supplier-categories/${ps.category}/`)
                    ]);

                    return {
                        ...ps,
                        supplier: supplierResponse.data,
                        category: categoryResponse.data
                    };
                } catch (error) {
                    console.error(`Error fetching details for preferred supplier ${ps.id}:`, error);
                    return ps;
                }
            }));

            setPreferredSuppliers(enhancedData);
        } catch (error) {
            console.error("Error fetching preferred suppliers:", error);
            toast.error("Failed to load preferred suppliers");
        } finally {
            setLoading(false);
        }
    };

    const handleAddAgreement = () => {
        setSelectedAgreement(null);
        setIsFormOpen(true);
    };

    const handleEditAgreement = (agreement) => {
        setSelectedAgreement(agreement);
        setIsFormOpen(true);
    };

    const handleDeleteAgreement = async (agreementId) => {
        if (window.confirm("Are you sure you want to delete this preferred supplier agreement?")) {
            try {
                await api.delete(`preferred-suppliers/${agreementId}/`);
                toast.success("Agreement deleted successfully");
                fetchPreferredSuppliers();
            } catch (error) {
                console.error("Error deleting agreement:", error);
                toast.error("Failed to delete agreement");
            }
        }
    };

    const getTenantId = () => {
        let tenantId =
            localStorage.getItem('tenantIdentifier') ||
            (typeof secureLocalStorage !== 'undefined' && secureLocalStorage.getItem('currentTenantId')) ||
            localStorage.getItem('tenantId') ||
            sessionStorage.getItem('tenantId') ||
            sessionStorage.getItem('tenantIdentifier');

        if (!tenantId) {
            try {
                // Try regular localStorage userData
                const userData = JSON.parse(localStorage.getItem('userData') || '{}');
                tenantId = userData.tenant?.id || userData.tenantId;

                // If found, save to localStorage for future use
                if (tenantId) {
                    localStorage.setItem('tenantId', tenantId);
                    console.log("Retrieved tenant ID from user data and saved to localStorage");
                }
            } catch (e) {
                console.error("Error parsing user data:", e);
            }
        }

        return tenantId;
    };

    const handleSubmitAgreement = async (formData) => {
        try {
            // Get the tenant ID
            const tenantId = getTenantId();
            if (!tenantId) {
                toast.error("Tenant ID not found. Cannot create agreement.");
                return;
            }

            const agreementData = {
                ...formData,
                tenant: tenantId
            };

            if (selectedAgreement) {
                // Update existing agreement
                await api.put(`preferred-suppliers/${selectedAgreement.id}/`, agreementData);
                toast.success("Agreement updated successfully");
            } else {
                // Create new agreement
                await api.post('preferred-suppliers/', agreementData);
                toast.success("Agreement created successfully");
            }
            fetchPreferredSuppliers();
        } catch (error) {
            console.error("Error submitting agreement:", error);
            toast.error("Failed to save agreement");
            throw error;
        }
    };

    const formatDate = (dateString) => {
        if (!dateString) return 'N/A';
        return new Date(dateString).toLocaleDateString();
    };

    const isAgreementExpired = (endDate) => {
        if (!endDate) return false;
        return new Date(endDate) < new Date();
    };

    const isAgreementExpiringSoon = (endDate) => {
        if (!endDate) return false;
        const expiryDate = new Date(endDate);
        const now = new Date();
        const thirtyDaysFromNow = new Date(now);
        thirtyDaysFromNow.setDate(now.getDate() + 30);
        return expiryDate > now && expiryDate <= thirtyDaysFromNow;
    };

    const filteredSuppliers = searchTerm
        ? preferredSuppliers.filter(ps =>
            ps.supplier?.company_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            ps.category?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            (ps.notes && ps.notes.toLowerCase().includes(searchTerm.toLowerCase()))
        )
        : preferredSuppliers;

    return (
        <div className="space-y-6">
            <div className="flex justify-between items-center">
                <h2 className="text-xl font-semibold">Preferred Suppliers</h2>
                <Button
                    onClick={handleAddAgreement}
                    className={primaryButtonClasses}
                >
                    <Plus className="h-4 w-4 mr-2" />
                    Add Agreement
                </Button>
            </div>

            <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4">
                <form className="relative w-full md:w-auto md:flex-1 max-w-sm">
                    <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-gray-400" />
                    <Input
                        placeholder="Search agreements..."
                        className="pl-9 pr-4"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </form>

                <div>
                    <Select
                        value={filterCategory}
                        onValueChange={setFilterCategory}
                    >
                        <SelectTrigger className="w-[200px]">
                            <SelectValue placeholder="All Categories" />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectItem value="all">All Categories</SelectItem>
                            {categories.map(category => (
                                <SelectItem key={category.id} value={category.id}>
                                    {category.name}
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                </div>
            </div>

            {loading ? (
                <div className="text-center py-8">Loading preferred suppliers...</div>
            ) : filteredSuppliers.length === 0 ? (
                <Card>
                    <CardContent className="text-center py-12">
                        <Building className="h-12 w-12 text-gray-400 mx-auto mb-4" />
                        <p className="text-gray-500">No preferred supplier agreements found.</p>
                    </CardContent>
                </Card>
            ) : (
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                    {filteredSuppliers.map((ps) => (
                        <Card key={ps.id} className="hover:shadow-md transition-shadow">
                            <CardHeader className="pb-2 flex flex-row justify-between items-start">
                                <div>
                                    <CardTitle className="text-lg">{ps.supplier?.company_name}</CardTitle>
                                    <div className="flex items-center mt-1">
                                        <Tag className="h-4 w-4 text-gray-500 mr-1" />
                                        <span className="text-sm text-gray-600">{ps.category?.name}</span>
                                    </div>
                                </div>
                                <Badge
                                    variant={
                                        isAgreementExpired(ps.agreement_end)
                                            ? 'destructive'
                                            : isAgreementExpiringSoon(ps.agreement_end)
                                                ? 'warning'
                                                : 'success'
                                    }
                                >
                                    {isAgreementExpired(ps.agreement_end)
                                        ? 'Expired'
                                        : isAgreementExpiringSoon(ps.agreement_end)
                                            ? 'Expiring Soon'
                                            : 'Active'
                                    }
                                </Badge>
                            </CardHeader>
                            <CardContent>
                                <div className="grid grid-cols-2 gap-x-4 gap-y-2 mb-4">
                                    <div>
                                        <p className="text-xs text-gray-500">Agreement Period</p>
                                        <div className="flex items-center">
                                            <Calendar className="h-4 w-4 text-gray-500 mr-1" />
                                            <p className="text-sm">
                                                {formatDate(ps.agreement_start)} - {formatDate(ps.agreement_end)}
                                            </p>
                                        </div>
                                    </div>
                                    <div>
                                        <p className="text-xs text-gray-500">Priority</p>
                                        <div className="flex items-center">
                                            {ps.priority <= 2 ? (
                                                <ArrowUp className="h-4 w-4 text-green-500 mr-1" />
                                            ) : ps.priority <= 4 ? (
                                                <ArrowUp className="h-4 w-4 text-blue-500 mr-1" />
                                            ) : (
                                                <ArrowDown className="h-4 w-4 text-gray-500 mr-1" />
                                            )}
                                            <p className="text-sm">{ps.priority} {ps.priority === 1 ? '(Highest)' : ''}</p>
                                        </div>
                                    </div>
                                    <div>
                                        <p className="text-xs text-gray-500">Negotiated Discount</p>
                                        <p className="text-sm font-medium">
                                            {ps.negotiated_discount ? `${ps.negotiated_discount}%` : 'None'}
                                        </p>
                                    </div>
                                    <div>
                                        <p className="text-xs text-gray-500">Rating</p>
                                        <div className="flex items-center">
                                            <Star className={`h-4 w-4 ${ps.supplier?.rating >= 4 ? 'text-yellow-400 fill-yellow-400' : 'text-gray-300'} mr-1`} />
                                            <p className="text-sm">{ps.supplier?.rating ? `${ps.supplier.rating}/5` : 'N/A'}</p>
                                        </div>
                                    </div>
                                </div>

                                {ps.notes && (
                                    <div className="mt-3">
                                        <p className="text-xs text-gray-500">Notes</p>
                                        <p className="text-sm text-gray-600 line-clamp-2">{ps.notes}</p>
                                    </div>
                                )}

                                <div className="flex justify-end mt-4 space-x-2">
                                    <Button
                                        variant="outline"
                                        size="sm"
                                        onClick={() => handleEditAgreement(ps)}
                                    >
                                        <Edit className="h-4 w-4 mr-1" />
                                        Edit
                                    </Button>
                                    <Button
                                        variant="destructive"
                                        size="sm"
                                        onClick={() => handleDeleteAgreement(ps.id)}
                                    >
                                        <Trash2 className="h-4 w-4 mr-1" />
                                        Delete
                                    </Button>
                                </div>
                            </CardContent>
                        </Card>
                    ))}
                </div>
            )}

            <PreferredSupplierForm
                isOpen={isFormOpen}
                onClose={() => setIsFormOpen(false)}
                onSubmit={handleSubmitAgreement}
                preferredSupplier={selectedAgreement}
            />
        </div>
    );
};

export default PreferredSuppliers;