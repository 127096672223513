import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardTitle, CardContent, CardDescription } from '../components/ui/cardui';
import { Button } from '../components/ui/button';
import { Alert, AlertDescription } from '../components/ui/alert';
import {
  BarChart,
  LineChart,
  Bar,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell
} from 'recharts';
import {
  AlertCircle,
  ShieldAlert,
  ClipboardCheck,
  HardHat,
  Award,
  TrendingDown,
  AlertTriangle,
  Clock,
  Check,
  X,
  ChevronDown,
  ChevronUp,
  CheckCircle2,
  PieChart as PieChartIcon,
  Users,
  Calendar,
  AlertOctagon
} from 'lucide-react';
import TrendIndicator from './TrendIndicator';
import api from '../services/api';
import { Progress } from '../components/ui/progress';
import { primaryButtonClasses } from '../components/common/ButtonStyles';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../components/ui/accordion";
import QualityIntegrationComponent from '../components/Projects/Defects/QualityIntegrationComponent';

function SafetyDashboard({ project, userRole, showQualityMetrics = false }) {


  const [metrics, setMetrics] = useState([]);
  const [incidentStats, setIncidentStats] = useState(null);
  const [oshaStats, setOshaStats] = useState(null);
  const [riskStats, setRiskStats] = useState(null);
  const [hazardStats, setHazardStats] = useState(null);
  const [trendData, setTrendData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);

  // Move training-related state to the top level
  const [trainingMetrics, setTrainingMetrics] = useState({
    totalTrainings: 0,
    completedTrainings: 0,
    scheduledTrainings: 0,
    complianceRate: 0
  });
  const [certificationMetrics, setCertificationMetrics] = useState({
    totalEmployees: 0,
    expiringSoon: 0,
    expired: 0,
    compliant: 0,
    complianceRate: 0
  });

  // Safe way to access window for SSR compatibility
  const [windowWidth, setWindowWidth] = useState(typeof window !== 'undefined' ? window.innerWidth : 1024);

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

  // Mobile responsiveness
  const isMobile = windowWidth < 768;

  useEffect(() => {
    if (typeof window === 'undefined') return;

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (project?.id) {
      fetchSafetyData();
      fetchTrendData();
      fetchIncidentStats();
      fetchOshaStats();
      fetchRiskStats();
      fetchHazardStats();
      fetchTrainingData(); // Added training data fetch
    } else {
      setIsLoading(false);
    }
  }, [project?.id, userRole]);

  const fetchSafetyData = async () => {
    try {
      setIsLoading(true);

      const response = await api.get(`projects/${project.id}/safety-metrics/?role=${userRole}`);
      // Ensure metrics is always an array
      setMetrics(Array.isArray(response.data) ? response.data : []);
      setError(null);
    } catch (err) {
      console.error('Error fetching safety data:', err);
      setError('Failed to load safety metrics');

      // Fallback data - ensure it's an array
      setMetrics([
        { name: 'Total Recordable Incident Rate (TRIR)', value: 2.3, target: 3.0, trend: 'decreasing' },
        { name: 'Days Since Last Incident', value: 45, trend: 'increasing' },
        { name: 'Safety Training Completion Rate', value: 95, unit: '%', target: 100, trend: 'increasing' },
        { name: 'PPE Compliance Rate', value: 98, unit: '%', target: 100, trend: 'stable' },
        { name: 'Near Miss Reports', value: 37, target: 30, trend: 'increasing' },
        { name: 'Hazard Resolution Time', value: 2.3, unit: 'days', target: 2, trend: 'decreasing' }
      ]);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchTrendData = async () => {
    try {
      if (!project?.id) return;

      const response = await api.get(`projects/${project.id}/safety-metrics/trends/?role=${userRole}`);
      const trendArray = response.data.trends || [];

      // Process trend data
      const groupedData = {};
      trendArray.forEach(item => {
        if (!groupedData[item.date]) {
          groupedData[item.date] = {
            date: item.date,
          };
        }
        groupedData[item.date][item.metricName] = item.value;
      });

      if (Object.keys(groupedData).length > 0) {
        setTrendData(Object.values(groupedData));
      } else {
        // Fallback data
        setTrendData([
          { date: '2023-01', TRIR: 3.2, 'Near Misses': 20, 'PPE Compliance': 92 },
          { date: '2023-02', TRIR: 3.0, 'Near Misses': 24, 'PPE Compliance': 94 },
          { date: '2023-03', TRIR: 2.8, 'Near Misses': 28, 'PPE Compliance': 95 },
          { date: '2023-04', TRIR: 2.7, 'Near Misses': 30, 'PPE Compliance': 95 },
          { date: '2023-05', TRIR: 2.5, 'Near Misses': 33, 'PPE Compliance': 96 },
          { date: '2023-06', TRIR: 2.3, 'Near Misses': 37, 'PPE Compliance': 98 }
        ]);
      }
    } catch (err) {
      console.error('Error fetching trend data:', err);
    }
  };

  // Move fetchTrainingData to top level
  const fetchTrainingData = async () => {
    try {
      // Fetch trainings
      const trainingsResponse = await api.get(`projects/${project.id}/safety-trainings/`);
      const trainings = trainingsResponse.data.results || [];

      // Calculate training metrics
      const completedTrainings = trainings.filter(t => t.status === 'completed').length;
      const scheduledTrainings = trainings.filter(t => t.status === 'scheduled').length;
      const totalTrainings = trainings.length;

      setTrainingMetrics({
        totalTrainings,
        completedTrainings,
        scheduledTrainings,
        complianceRate: totalTrainings > 0 ? (completedTrainings / totalTrainings) * 100 : 0
      });

      // Fetch certification metrics
      try {
        const metricsResponse = await api.get(`projects/${project.id}/safety-certification-metrics/`);
        setCertificationMetrics(metricsResponse.data);
      } catch (error) {
        console.error('Error fetching certification metrics:', error);
      }
    } catch (err) {
      console.error('Error fetching training data:', err);
    }
  };

  const fetchIncidentStats = async () => {
    try {
      if (!project?.id) return;

      // Log the request URL for debugging
      console.log(`Fetching incident stats from: projects/${project.id}/safety-incidents/stats/`);

      const response = await api.get(`projects/${project.id}/safety-incidents/stats/`);

      // Log the response for debugging
      console.log('Incident stats API response:', response.data);

      // Verify important fields are present, use defaults if missing
      const processedData = {
        total_recordable_incidents: response.data.total_recordable_incidents || 0,
        ltir: response.data.ltir || 0,
        trir: response.data.trir || 0,
        dart_rate: response.data.dart_rate || 0,
        near_misses: response.data.near_misses || 0,
        first_aid_cases: response.data.first_aid_cases || 0,
        last_incident_days: response.data.last_incident_days || 0,
        incident_types: Array.isArray(response.data.incident_types) ? response.data.incident_types : [],
        monthly_incidents: Array.isArray(response.data.monthly_incidents) ? response.data.monthly_incidents : []
      };

      setIncidentStats(processedData);
    } catch (err) {
      console.error('Error fetching incident stats:', err);

    }
  };

  // Improve fetchOshaStats to ensure we're getting data
  const fetchOshaStats = async () => {
    try {
      if (!project?.id) return;

      // Log the request URL for debugging
      console.log(`Fetching OSHA stats from: projects/${project.id}/osha-compliance/stats/`);

      const response = await api.get(`projects/${project.id}/osha-compliance/stats/`);

      // Log the response for debugging
      console.log('OSHA stats API response:', response.data);

      // Verify important fields are present, use defaults if missing
      const processedData = {
        inspections_total: response.data.inspections_total || 0,
        inspections_passed: response.data.inspections_passed || 0,
        inspections_scheduled: response.data.inspections_scheduled || 0,
        violation_count: response.data.violation_count || 0,
        violations_by_type: Array.isArray(response.data.violations_by_type) ?
          response.data.violations_by_type : [],
        ppe_compliance: response.data.ppe_compliance || 0,
        compliance_by_area: Array.isArray(response.data.compliance_by_area) ?
          response.data.compliance_by_area : []
      };

      setOshaStats(processedData);
    } catch (err) {
      console.error('Error fetching OSHA stats:', err);

      // Fallback data for demo/testing
      setOshaStats({
        inspections_total: 12,
        inspections_passed: 10,
        inspections_scheduled: 2,
        violation_count: 3,
        violations_by_type: [
          { type: 'Fall Protection', count: 1 },
          { type: 'Hazard Communication', count: 1 },
          { type: 'Electrical', count: 1 }
        ],
        ppe_compliance: 98,
        compliance_by_area: [
          { area: 'Scaffolding', compliance: 95 },
          { area: 'Electrical Safety', compliance: 97 },
          { area: 'Fall Protection', compliance: 92 },
          { area: 'PPE', compliance: 98 },
          { area: 'Hazcom', compliance: 94 }
        ]
      });
    }
  };

  const fetchRiskStats = async () => {
    try {
      if (!project?.id) return;

      const response = await api.get(`projects/${project.id}/risk-management/stats/`);
      setRiskStats(response.data);
    } catch (err) {
      console.error('Error fetching risk stats:', err);

      // Fallback data
      setRiskStats({
        total_risks: 28,
        high_risks: 4,
        medium_risks: 12,
        low_risks: 12,
        risks_by_status: [
          { status: 'Open', count: 8 },
          { status: 'In Progress', count: 12 },
          { status: 'Mitigated', count: 5 },
          { status: 'Closed', count: 3 }
        ],
        mitigated_percentage: 28.6,
        risk_trend: [
          { month: 'Jan', identified: 8, mitigated: 2 },
          { month: 'Feb', identified: 6, mitigated: 4 },
          { month: 'Mar', identified: 5, mitigated: 4 },
          { month: 'Apr', identified: 4, mitigated: 3 },
          { month: 'May', identified: 3, mitigated: 4 },
          { month: 'Jun', identified: 2, mitigated: 5 }
        ]
      });
    }
  };

  const fetchHazardStats = async () => {
    try {
      if (!project?.id) return;

      const response = await api.get(`projects/${project.id}/safety-hazards/stats/`);
      setHazardStats(response.data);
    } catch (err) {
      console.error('Error fetching hazard stats:', err);

      // Fallback data for demo/testing
      setHazardStats({
        total_hazards: 35,
        open_hazards: 12,
        closed_hazards: 23,
        hazards_by_severity: [
          { severity: 'High', count: 5 },
          { severity: 'Medium', count: 15 },
          { severity: 'Low', count: 15 }
        ],
        avg_resolution_time: 2.3,
        resolution_trend: [
          { month: 'Jan', avg_days: 4.2 },
          { month: 'Feb', avg_days: 3.8 },
          { month: 'Mar', avg_days: 3.2 },
          { month: 'Apr', avg_days: 2.9 },
          { month: 'May', avg_days: 2.6 },
          { month: 'Jun', avg_days: 2.3 }
        ],
        hazards_by_type: [
          { type: 'Fall Hazard', count: 8 },
          { type: 'Electrical', count: 7 },
          { type: 'Material Handling', count: 6 },
          { type: 'Struck By', count: 5 },
          { type: 'Chemical', count: 5 },
          { type: 'Other', count: 4 }
        ]
      });
    }
  };

  const formatValue = (value, unit) => {
    if (unit === '%') return `${value}%`;
    if (unit === 'days') return `${value} days`;
    return value;
  };

  if (isLoading) {
    return <div className="flex justify-center p-8">Loading...</div>;
  }

  const renderTrainingMetrics = () => {

    const canManageTraining = ['Safety Manager', 'Tenant Admin Safety', 'Tenant Admin', 'Safety Director', 'Chief Safety Officer'].includes(userRole);

    return (
      <div className="mt-8">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-xl font-semibold text-gray-800">Training & Certification</h3>
          {canManageTraining && (
            <Button
              variant="outline"
              onClick={() => window.location.href = `/projects/${project.id}/safety-training`}
              className={primaryButtonClasses}
            >
              <Users className="h-4 w-4 mr-2" />
              Manage Training
            </Button>
          )}
        </div>


        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
          <Card className="bg-white">
            <CardHeader className="pb-2">
              <CardTitle className="text-sm font-medium text-gray-500">
                <div className="flex items-center">
                  <Users className="w-4 h-4 mr-2" />
                  Training Completion
                </div>
              </CardTitle>
            </CardHeader>
            <CardContent>
              <p className="text-2xl font-semibold text-blue-600">
                {Math.round(trainingMetrics.complianceRate)}%
              </p>
              <p className="text-xs text-gray-500">
                {trainingMetrics.completedTrainings} of {trainingMetrics.totalTrainings} trainings
              </p>
            </CardContent>
          </Card>

          <Card className="bg-white">
            <CardHeader className="pb-2">
              <CardTitle className="text-sm font-medium text-gray-500">
                <div className="flex items-center">
                  <Calendar className="w-4 h-4 mr-2" />
                  Upcoming Trainings
                </div>
              </CardTitle>
            </CardHeader>
            <CardContent>
              <p className="text-2xl font-semibold text-green-600">
                {trainingMetrics.scheduledTrainings}
              </p>
              <p className="text-xs text-gray-500">
                Scheduled sessions
              </p>
            </CardContent>
          </Card>

          <Card className="bg-white">
            <CardHeader className="pb-2">
              <CardTitle className="text-sm font-medium text-gray-500">
                <div className="flex items-center">
                  <Award className="w-4 h-4 mr-2" />
                  Certification Compliance
                </div>
              </CardTitle>
            </CardHeader>
            <CardContent>
              <p className="text-2xl font-semibold text-green-600">
                {Math.round(certificationMetrics.complianceRate || 0)}%
              </p>
              <p className="text-xs text-gray-500">
                Employee certifications
              </p>
            </CardContent>
          </Card>

          <Card className="bg-white">
            <CardHeader className="pb-2">
              <CardTitle className="text-sm font-medium text-gray-500">
                <div className="flex items-center">
                  <AlertTriangle className="w-4 h-4 mr-2" />
                  Expiring Certifications
                </div>
              </CardTitle>
            </CardHeader>
            <CardContent>
              <p className="text-2xl font-semibold text-yellow-600">
                {certificationMetrics.expiringSoon || 0}
              </p>
              <p className="text-xs text-gray-500">
                Need attention
              </p>
            </CardContent>
          </Card>
        </div>
      </div>
    );
  };

  const renderMetricCard = (metric) => (
    <Card key={metric.name} className="bg-white">
      <CardHeader className="pb-2">
        <CardTitle className="text-sm font-medium text-gray-500">
          {metric.name}
        </CardTitle>
      </CardHeader>
      <CardContent>
        <div className="mt-1 flex justify-between items-baseline">
          <p className="text-2xl font-semibold text-gray-900">
            {formatValue(metric.value, metric.unit)}
          </p>
          {metric.target && (
            <p className="text-sm text-gray-500">
              Target: {formatValue(metric.target, metric.unit)}
            </p>
          )}
        </div>
        <div className="mt-4">
          <div className="bg-gray-200 rounded-full h-2">
            <div
              className={`rounded-full h-2 ${metric.value >= (metric.target || 0) ? 'bg-green-600' : 'bg-blue-600'}`}
              style={{ width: `${Math.min((metric.value / (metric.target || 1)) * 100, 100)}%` }}
            />
          </div>
        </div>
        <div className="mt-2 text-sm">
          <TrendIndicator trend={metric.trend} />
        </div>
      </CardContent>
    </Card>
  );

  const renderOshaCompliance = () => {
    if (!oshaStats) return null;

    // Calculate compliance rate with protection against division by zero
    const inspectionComplianceRate = oshaStats.inspections_total > 0
      ? Math.round((oshaStats.inspections_passed / oshaStats.inspections_total) * 100)
      : 0;

    // Check if inspections data is valid
    const hasInspectionData = oshaStats.inspections_total > 0;

    return (
      <div className="mt-8">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-xl font-semibold text-gray-800">OSHA Compliance</h3>
          <Button variant="ghost" onClick={() => setIsExpanded(!isExpanded)}>
            {isExpanded ? "Show Less" : "Show More"}
            {isExpanded ? <ChevronUp className="ml-2 h-4 w-4" /> : <ChevronDown className="ml-2 h-4 w-4" />}
          </Button>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <Card className="bg-white">
            <CardHeader className="pb-2">
              <CardTitle className="text-sm font-medium text-gray-500">
                <div className="flex items-center">
                  <ClipboardCheck className="w-4 h-4 mr-2" />
                  Inspection Compliance
                </div>
              </CardTitle>
            </CardHeader>
            <CardContent>
              {hasInspectionData ? (
                <>
                  <p className="text-2xl font-semibold text-gray-900">
                    {inspectionComplianceRate}%
                  </p>
                  <p className="text-sm text-gray-500">
                    {oshaStats.inspections_passed} of {oshaStats.inspections_total} inspections passed
                  </p>
                </>
              ) : (
                <>
                  <p className="text-2xl font-semibold text-gray-400">
                    N/A
                  </p>
                  <p className="text-sm text-gray-500">
                    No inspection data available
                  </p>
                </>
              )}
              <div className="mt-4 flex justify-between text-xs text-gray-500">
                <span>
                  {oshaStats.inspections_scheduled || 0} scheduled
                </span>
                <span>
                  {oshaStats.violation_count || 0} violations
                </span>
              </div>
            </CardContent>
          </Card>

          <Card className="bg-white">
            <CardHeader className="pb-2">
              <CardTitle className="text-sm font-medium text-gray-500">
                <div className="flex items-center">
                  <HardHat className="w-4 h-4 mr-2" />
                  PPE Compliance Rate
                </div>
              </CardTitle>
            </CardHeader>
            <CardContent>
              <p className="text-2xl font-semibold text-gray-900">
                {oshaStats.ppe_compliance || 0}%
              </p>
              <div className="mt-2">
                <div className="bg-gray-200 rounded-full h-2">
                  <div
                    className="rounded-full h-2 bg-green-600"
                    style={{ width: `${oshaStats.ppe_compliance || 0}%` }}
                  />
                </div>
              </div>
              <p className="mt-2 text-sm text-gray-500">
                Target: 100%
              </p>
            </CardContent>
          </Card>

          <Card className="bg-white">
            <CardHeader className="pb-2">
              <CardTitle className="text-sm font-medium text-gray-500">
                <div className="flex items-center">
                  <AlertCircle className="w-4 h-4 mr-2" />
                  OSHA Violations
                </div>
              </CardTitle>
            </CardHeader>
            <CardContent>
              <p className="text-2xl font-semibold text-gray-900">
                {oshaStats.violation_count || 0}
              </p>
              <div className="mt-2 space-y-1">
                {Array.isArray(oshaStats.violations_by_type) && oshaStats.violations_by_type.length > 0 ? (
                  oshaStats.violations_by_type.map((violation, index) => (
                    <div key={index} className="flex justify-between text-sm">
                      <span>{violation.type}</span>
                      <span>{violation.count}</span>
                    </div>
                  ))
                ) : (
                  <div className="text-sm text-gray-500">No violations reported</div>
                )}
              </div>
            </CardContent>
          </Card>
        </div>

        {isExpanded && (
          <div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-4">
            <Card>
              <CardHeader>
                <CardTitle className="text-base">Compliance By Area</CardTitle>
                <CardDescription>OSHA standards compliance ratings</CardDescription>
              </CardHeader>
              <CardContent>
                <div className="space-y-4">
                  {Array.isArray(oshaStats.compliance_by_area) && oshaStats.compliance_by_area.length > 0 ? (
                    oshaStats.compliance_by_area.map((area, index) => (
                      <div key={index}>
                        <div className="flex justify-between mb-1">
                          <span className="text-sm font-medium">{area.area}</span>
                          <span className="text-sm font-medium">{area.compliance}%</span>
                        </div>
                        <Progress value={area.compliance} className="h-2" />
                      </div>
                    ))
                  ) : (
                    <div className="text-sm text-gray-500">No compliance data available</div>
                  )}
                </div>
              </CardContent>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle className="text-base">Violation Breakdown</CardTitle>
                <CardDescription>Types of OSHA violations</CardDescription>
              </CardHeader>
              <CardContent className="flex justify-center">
                {Array.isArray(oshaStats.violations_by_type) && oshaStats.violations_by_type.length > 0 ? (
                  <div className="w-64 h-64">
                    <ResponsiveContainer width="100%" height="100%">
                      <PieChart>
                        <Pie
                          data={oshaStats.violations_by_type}
                          cx="50%"
                          cy="50%"
                          labelLine={false}
                          label={({ name, percent }) => `${name}: ${(percent * 100).toFixed(0)}%`}
                          outerRadius={80}
                          fill="#8884d8"
                          dataKey="count"
                          nameKey="type"
                        >
                          {oshaStats.violations_by_type.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                          ))}
                        </Pie>
                        <Tooltip />
                      </PieChart>
                    </ResponsiveContainer>
                  </div>
                ) : (
                  <div className="flex items-center justify-center h-64 w-full text-gray-500">
                    No violation data to display
                  </div>
                )}
              </CardContent>
            </Card>
          </div>
        )}
      </div>
    );
  };

  const renderIncidentTracking = () => {
    const isManager = ['Safety Manager', 'Tenant Admin Safety', 'Tenant Admin', 'Safety Director', 'Chief Safety Officer'].includes(userRole);
    if (!incidentStats) return null;

    const trir = isManager ? (incidentStats.trir || 0) : 'N/A';
    const ltir = isManager ? (incidentStats.ltir || 0) : 'N/A';
    const nearMisses = incidentStats.near_misses || 0;
    const lastIncidentDays = incidentStats.last_incident_days || 0;

    return (
      <div className="mt-8">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-xl font-semibold text-gray-800">Incident Tracking</h3>
          <Button variant="ghost" onClick={() => setIsExpanded(!isExpanded)}>
            {isExpanded ? "Show Less" : "Show More"}
            {isExpanded ? <ChevronUp className="ml-2 h-4 w-4" /> : <ChevronDown className="ml-2 h-4 w-4" />}
          </Button>
        </div>

        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
          <Card className="bg-white">
            <CardHeader className="pb-2">
              <CardTitle className="text-sm font-medium text-gray-500">
                <div className="flex items-center">
                  <AlertCircle className="w-4 h-4 mr-2" />
                  TRIR
                </div>
              </CardTitle>
            </CardHeader>
            <CardContent>
              <p className="text-2xl font-semibold text-blue-600">
                {trir}
              </p>
              <p className="text-xs text-gray-500">
                Total Recordable Incident Rate
              </p>
            </CardContent>
          </Card>

          <Card className="bg-white">
            <CardHeader className="pb-2">
              <CardTitle className="text-sm font-medium text-gray-500">
                <div className="flex items-center">
                  <Clock className="w-4 h-4 mr-2" />
                  LTIR
                </div>
              </CardTitle>
            </CardHeader>
            <CardContent>
              <p className="text-2xl font-semibold text-blue-600">
                {ltir}
              </p>
              <p className="text-xs text-gray-500">
                Lost Time Incident Rate
              </p>
            </CardContent>
          </Card>

          <Card className="bg-white">
            <CardHeader className="pb-2">
              <CardTitle className="text-sm font-medium text-gray-500">
                <div className="flex items-center">
                  <Check className="w-4 h-4 mr-2" />
                  Near Misses
                </div>
              </CardTitle>
            </CardHeader>
            <CardContent>
              <p className="text-2xl font-semibold text-green-600">
                {nearMisses}
              </p>
              <p className="text-xs text-gray-500">
                Reported this month
              </p>
            </CardContent>
          </Card>

          <Card className="bg-white">
            <CardHeader className="pb-2">
              <CardTitle className="text-sm font-medium text-gray-500">
                <div className="flex items-center">
                  <CheckCircle2 className="w-4 h-4 mr-2" />
                  Days Since Last
                </div>
              </CardTitle>
            </CardHeader>
            <CardContent>
              <p className="text-2xl font-semibold text-green-600">
                {lastIncidentDays}
              </p>
              <p className="text-xs text-gray-500">
                Recordable incident
              </p>
            </CardContent>
          </Card>
        </div>

        {isExpanded && (
          <div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-4">
            <Card>
              <CardHeader>
                <CardTitle className="text-base">Monthly Incidents</CardTitle>
                <CardDescription>Recordable vs. Non-Recordable</CardDescription>
              </CardHeader>
              <CardContent>
                {Array.isArray(incidentStats.monthly_incidents) && incidentStats.monthly_incidents.length > 0 ? (
                  <div className="h-80">
                    <ResponsiveContainer width="100%" height="100%">
                      <BarChart
                        data={incidentStats.monthly_incidents}
                        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="month" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="recordable" name="Recordable" fill="#FF8042" />
                        <Bar dataKey="non_recordable" name="Non-Recordable" fill="#8884d8" />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                ) : (
                  <div className="flex items-center justify-center h-80 text-gray-500">
                    No monthly incident data available
                  </div>
                )}
              </CardContent>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle className="text-base">Incident Types</CardTitle>
                <CardDescription>Breakdown by category</CardDescription>
              </CardHeader>
              <CardContent className="h-80">
                {Array.isArray(incidentStats.incident_types) && incidentStats.incident_types.length > 0 ? (
                  <ResponsiveContainer width="100%" height="100%">
                    <PieChart>
                      <Pie
                        data={incidentStats.incident_types}
                        cx="50%"
                        cy="50%"
                        labelLine={true}
                        label={({ name, percent }) => `${name}: ${(percent * 100).toFixed(0)}%`}
                        outerRadius={80}
                        fill="#8884d8"
                        dataKey="count"
                        nameKey="type"
                      >
                        {incidentStats.incident_types.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                      </Pie>
                      <Tooltip />
                      <Legend />
                    </PieChart>
                  </ResponsiveContainer>
                ) : (
                  <div className="flex items-center justify-center h-full text-gray-500">
                    No incident type data available
                  </div>
                )}
              </CardContent>
            </Card>
          </div>
        )}
      </div>
    );
  };

  // RISK MANAGEMENT SECTION
  const renderRiskManagement = () => {
    if (!riskStats) return null;

    // Convert risk_categories object to an array for charting
    const riskCategoriesArray = riskStats.risk_categories
      ? Object.entries(riskStats.risk_categories).map(([category, count]) => ({
        category,
        count
      }))
      : [];

    return (
      <div className="mt-8">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-xl font-semibold text-gray-800">Risk Management</h3>
          <Button variant="ghost" onClick={() => setIsExpanded(!isExpanded)}>
            {isExpanded ? "Show Less" : "Show More"}
            {isExpanded ? <ChevronUp className="ml-2 h-4 w-4" /> : <ChevronDown className="ml-2 h-4 w-4" />}
          </Button>
        </div>

        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
          <Card className="bg-white">
            <CardHeader className="pb-2">
              <CardTitle className="text-sm font-medium text-gray-500">
                <div className="flex items-center">
                  <AlertTriangle className="w-4 h-4 mr-2" />
                  High Risks
                </div>
              </CardTitle>
            </CardHeader>
            <CardContent>
              <p className="text-2xl font-semibold text-red-600">
                {riskStats.high_severity || 0}
              </p>
              <p className="text-xs text-gray-500">
                of {riskStats.total_risks || 0} total
              </p>
            </CardContent>
          </Card>

          <Card className="bg-white">
            <CardHeader className="pb-2">
              <CardTitle className="text-sm font-medium text-gray-500">
                <div className="flex items-center">
                  <AlertOctagon className="w-4 h-4 mr-2" />
                  Open Risks
                </div>
              </CardTitle>
            </CardHeader>
            <CardContent>
              <p className="text-2xl font-semibold text-yellow-600">
                {riskStats.open_risks || 0}
              </p>
              <p className="text-xs text-gray-500">
                of {riskStats.total_risks || 0} total
              </p>
            </CardContent>
          </Card>

          <Card className="bg-white">
            <CardHeader className="pb-2">
              <CardTitle className="text-sm font-medium text-gray-500">
                <div className="flex items-center">
                  <ShieldAlert className="w-4 h-4 mr-2" />
                  Risks Mitigated
                </div>
              </CardTitle>
            </CardHeader>
            <CardContent>
              <p className="text-2xl font-semibold text-green-600">
                {riskStats.mitigation_rate || 0}%
              </p>
              <p className="text-xs text-gray-500">
                Risks with controls
              </p>
            </CardContent>
          </Card>

          <Card className="bg-white">
            <CardHeader className="pb-2">
              <CardTitle className="text-sm font-medium text-gray-500">
                <div className="flex items-center">
                  <TrendingDown className="w-4 h-4 mr-2" />
                  Risk Trend
                </div>
              </CardTitle>
            </CardHeader>
            <CardContent>
              <p className="text-2xl font-semibold text-blue-600">
                <TrendingDown className="inline h-6 w-6 text-green-600" />
                {" "}12%
              </p>
              <p className="text-xs text-gray-500">
                Risk reduction this month
              </p>
            </CardContent>
          </Card>
        </div>

        {isExpanded && (
          <div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-4">
            <Card>
              <CardHeader>
                <CardTitle className="text-base">Risks by Category</CardTitle>
                <CardDescription>Breakdown by risk type</CardDescription>
              </CardHeader>
              <CardContent>
                <div className="h-60">
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                      data={riskCategoriesArray}
                      margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="category" />
                      <YAxis />
                      <Tooltip />
                      <Bar dataKey="count" fill="#3B82F6" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </CardContent>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle className="text-base">Risk Status</CardTitle>
                <CardDescription>Current risk management status</CardDescription>
              </CardHeader>
              <CardContent>
                <div className="h-60">
                  <ResponsiveContainer width="100%" height="100%">
                    <PieChart>
                      <Pie
                        data={[
                          { status: 'Open', count: riskStats.open_risks || 0 },
                          { status: 'Mitigated', count: riskStats.mitigated_risks || 0 },
                          {
                            status: 'Other', count: (riskStats.total_risks || 0) -
                              ((riskStats.open_risks || 0) + (riskStats.mitigated_risks || 0))
                          }
                        ].filter(item => item.count > 0)}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        label={({ name, value, percent }) => `${name}: ${(percent * 100).toFixed(0)}%`}
                        outerRadius={80}
                        fill="#8884d8"
                        dataKey="count"
                        nameKey="status"
                      >
                        {[
                          { status: 'Open', count: riskStats.open_risks || 0 },
                          { status: 'Mitigated', count: riskStats.mitigated_risks || 0 },
                          {
                            status: 'Other', count: (riskStats.total_risks || 0) -
                              ((riskStats.open_risks || 0) + (riskStats.mitigated_risks || 0))
                          }
                        ].filter(item => item.count > 0).map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                      </Pie>
                      <Tooltip />
                    </PieChart>
                  </ResponsiveContainer>
                </div>
              </CardContent>
            </Card>
          </div>
        )}
      </div>
    );
  };

  const renderHazardManagement = () => {
    if (!hazardStats) return null;

    return (
      <div className="mt-8">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-xl font-semibold text-gray-800">Hazard Management</h3>
          <Button variant="ghost" onClick={() => setIsExpanded(!isExpanded)}>
            {isExpanded ? "Show Less" : "Show More"}
            {isExpanded ? <ChevronUp className="ml-2 h-4 w-4" /> : <ChevronDown className="ml-2 h-4 w-4" />}
          </Button>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <Card className="bg-white">
            <CardHeader className="pb-2">
              <CardTitle className="text-sm font-medium text-gray-500">
                <div className="flex items-center">
                  <AlertTriangle className="w-4 h-4 mr-2" />
                  Open Hazards
                </div>
              </CardTitle>
            </CardHeader>
            <CardContent>
              <p className="text-2xl font-semibold text-yellow-600">
                {hazardStats.open_hazards}
              </p>
              <p className="text-xs text-gray-500">
                of {hazardStats.total_hazards} total
              </p>
              <div className="mt-2">
                <div className="bg-gray-200 rounded-full h-2">
                  <div
                    className="rounded-full h-2 bg-yellow-500"
                    style={{ width: `${(hazardStats.open_hazards / hazardStats.total_hazards) * 100}%` }}
                  />
                </div>
              </div>
            </CardContent>
          </Card>

          <Card className="bg-white">
            <CardHeader className="pb-2">
              <CardTitle className="text-sm font-medium text-gray-500">
                <div className="flex items-center">
                  <Clock className="w-4 h-4 mr-2" />
                  Avg. Resolution Time
                </div>
              </CardTitle>
            </CardHeader>
            <CardContent>
              <p className="text-2xl font-semibold text-blue-600">
                {hazardStats.avg_resolution_time} days
              </p>
              <div className="flex items-center mt-1">
                <TrendingDown className="h-4 w-4 text-green-500 mr-1" />
                <span className="text-sm text-green-500">Improving</span>
              </div>
              <p className="text-xs text-gray-500 mt-1">
                Target: 2.0 days
              </p>
            </CardContent>
          </Card>

          <Card className="bg-white">
            <CardHeader className="pb-2">
              <CardTitle className="text-sm font-medium text-gray-500">
                <div className="flex items-center">
                  <CheckCircle2 className="w-4 w-4 mr-2" />
                  Hazard Closure Rate
                </div>
              </CardTitle>
            </CardHeader>
            <CardContent>
              <p className="text-2xl font-semibold text-green-600">
                {Math.round((hazardStats.closed_hazards / hazardStats.total_hazards) * 100)}%
              </p>
              <p className="text-xs text-gray-500">
                {hazardStats.closed_hazards} of {hazardStats.total_hazards} hazards closed
              </p>
              <div className="mt-2">
                <div className="bg-gray-200 rounded-full h-2">
                  <div
                    className="rounded-full h-2 bg-green-500"
                    style={{ width: `${(hazardStats.closed_hazards / hazardStats.total_hazards) * 100}%` }}
                  />
                </div>
              </div>
            </CardContent>
          </Card>
        </div>

        {isExpanded && (
          <div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-4">
            <Card>
              <CardHeader>
                <CardTitle className="text-base">Hazards by Severity</CardTitle>
                <CardDescription>Breakdown of identified hazards</CardDescription>
              </CardHeader>
              <CardContent>
                <div className="h-60">
                  <ResponsiveContainer width="100%" height="100%">
                    <PieChart>
                      <Pie
                        data={hazardStats.hazards_by_severity}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        label={({ name, percent }) => `${name}: ${(percent * 100).toFixed(0)}%`}
                        outerRadius={80}
                        fill="#8884d8"
                        dataKey="count"
                        nameKey="severity"
                      >
                        {hazardStats.hazards_by_severity.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={entry.severity === 'High' ? '#FF8042' :
                              entry.severity === 'Medium' ? '#FFBB28' : '#00C49F'}
                          />
                        ))}
                      </Pie>
                      <Tooltip />
                    </PieChart>
                  </ResponsiveContainer>
                </div>
              </CardContent>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle className="text-base">Resolution Time Trend</CardTitle>
                <CardDescription>Average days to close hazards</CardDescription>
              </CardHeader>
              <CardContent>
                <div className="h-60">
                  <ResponsiveContainer width="100%" height="100%">
                    <LineChart
                      data={hazardStats.resolution_trend}
                      margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="month" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Line
                        type="monotone"
                        dataKey="avg_days"
                        name="Average Resolution Time (days)"
                        stroke="#8884d8"
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              </CardContent>
            </Card>
          </div>
        )}
      </div>
    );
  };

  // SAFETY CULTURE - Insights section
  const renderSafetyCulture = () => {
    return (
      <div className="mt-8">
        <div className="flex justify-between items-center">
          <h3 className="text-xl font-semibold text-gray-800 mb-4">Safety Culture & AI Insights</h3>
        </div>

        <div className="bg-gradient-to-r from-blue-50 to-indigo-50 p-4 rounded-lg shadow">
          <Accordion type="single" collapsible className="w-full">
            <AccordionItem value="insights" className="border-none">
              <AccordionTrigger className="py-2 px-0 text-md font-semibold">
                <span className="flex items-center gap-2">
                  <PieChartIcon className="h-5 w-5" />
                  AI Insights & Recommendations
                </span>
              </AccordionTrigger>
              <AccordionContent className="pt-2">
                <div className="space-y-4">
                  <div className="p-3 bg-white bg-opacity-60 rounded-md">
                    <p className="text-gray-800">
                      <span className="font-semibold">Risk Insight:</span> Analysis of your near-miss reports shows a positive correlation with the decrease in actual incidents. Your reporting system is effectively preventing accidents.
                    </p>
                  </div>

                  <div className="p-3 bg-white bg-opacity-60 rounded-md">
                    <p className="text-gray-800">
                      <span className="font-semibold">TRIR Reduction Opportunity:</span> Based on the current TRIR trend and hazard management improvements, we project a potential 15% decrease in TRIR if hazard resolution time is reduced to below 2.0 days.
                    </p>
                  </div>

                  <div className="p-3 bg-white bg-opacity-60 rounded-md">
                    <p className="text-gray-800">
                      <span className="font-semibold">PPE Recommendation:</span> PPE compliance is high at 98%, but focused attention on Fall Protection compliance (currently at 92%) would significantly reduce your highest incident category.
                    </p>
                  </div>

                  {hazardStats && hazardStats.open_hazards > 0 && (
                    <div className="p-3 bg-yellow-50 border-l-4 border-yellow-400 rounded-md">
                      <p className="text-yellow-800">
                        <span className="font-semibold">Alert:</span> There are {hazardStats.open_hazards} open hazards, with {hazardStats.hazards_by_severity.find(h => h.severity === 'High')?.count || 0} high-severity items requiring immediate attention.
                      </p>
                    </div>
                  )}
                </div>
              </AccordionContent>
            </AccordionItem>

            <AccordionItem value="upcoming" className="border-none mt-2">
              <AccordionTrigger className="py-2 px-0 text-md font-semibold">
                <span className="flex items-center gap-2">
                  <Calendar className="h-5 w-5" />
                  Upcoming Safety Events
                </span>
              </AccordionTrigger>
              <AccordionContent className="pt-2">
                <div className="space-y-2">
                  <div className="flex justify-between items-center p-2 bg-white bg-opacity-60 rounded-md">
                    <div className="flex items-center">
                      <Calendar className="h-4 w-4 mr-2 text-blue-600" />
                      <span>Weekly Safety Meeting</span>
                    </div>
                    <span className="text-sm">Tomorrow, 8:00 AM</span>
                  </div>

                  <div className="flex justify-between items-center p-2 bg-white bg-opacity-60 rounded-md">
                    <div className="flex items-center">
                      <Users className="h-4 w-4 mr-2 text-green-600" />
                      <span>Fall Protection Training</span>
                    </div>
                    <span className="text-sm">June 15, 1:00 PM</span>
                  </div>

                  <div className="flex justify-between items-center p-2 bg-white bg-opacity-60 rounded-md">
                    <div className="flex items-center">
                      <ClipboardCheck className="h-4 w-4 mr-2 text-purple-600" />
                      <span>OSHA Compliance Audit</span>
                    </div>
                    <span className="text-sm">June 22, 9:00 AM</span>
                  </div>
                </div>
              </AccordionContent>
            </AccordionItem>

            <AccordionItem value="alerts" className="border-none mt-2">
              <AccordionTrigger className="py-2 px-0 text-md font-semibold">
                <span className="flex items-center gap-2">
                  <AlertTriangle className="h-5 w-5" />
                  Important Alerts
                </span>
              </AccordionTrigger>
              <AccordionContent className="pt-2">
                <div className="space-y-2">
                  {incidentStats && incidentStats.incident_types.some(t => t.type === 'Fall Protection' && t.count > 0) && (
                    <div className="p-3 bg-red-50 border-l-4 border-red-400 rounded-md">
                      <p className="text-red-800 font-medium">
                        Fall protection incidents are your highest category. Schedule additional training and inspections.
                      </p>
                    </div>
                  )}

                  {oshaStats && oshaStats.inspections_scheduled > 0 && (
                    <div className="p-3 bg-blue-50 border-l-4 border-blue-400 rounded-md">
                      <p className="text-blue-800 font-medium">
                        {oshaStats.inspections_scheduled} safety inspections are scheduled in the next 14 days. Ensure preparations are complete.
                      </p>
                    </div>
                  )}

                  {hazardStats && hazardStats.resolution_trend.length > 0 && hazardStats.resolution_trend[hazardStats.resolution_trend.length - 1].avg_days < 2.5 && (
                    <div className="p-3 bg-green-50 border-l-4 border-green-400 rounded-md">
                      <p className="text-green-800 font-medium">
                        Congratulations! Your hazard resolution time is trending positively, now at {hazardStats.avg_resolution_time} days.
                      </p>
                    </div>
                  )}
                </div>
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        </div>
      </div>
    );
  };

  // Responsive rendering for mobile/desktop
  const renderMobileView = () => (
    <div className="space-y-6">
      <div className="grid grid-cols-1 gap-4">
        {/* Use Array.isArray(metrics) to ensure metrics is an array */}
        {Array.isArray(metrics) && metrics.length > 0 ?
          metrics.slice(0, 2).map(renderMetricCard) :
          <Alert>
            <AlertDescription>No metrics data available</AlertDescription>
          </Alert>
        }
      </div>

      {renderTrainingMetrics()}

      {renderIncidentTracking()}
      {renderOshaCompliance()}
      {renderRiskManagement()}
      {renderHazardManagement()}
      {renderSafetyCulture()}

      {/* Quality Component */}
      {showQualityMetrics && <QualityIntegrationComponent project={project} />}
    </div>
  );

  const renderDesktopView = () => (
    <div className="space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {/* Use Array.isArray(metrics) to ensure metrics is an array */}
        {Array.isArray(metrics) && metrics.length > 0 ?
          metrics.map(renderMetricCard) :
          <Alert>
            <AlertDescription>No metrics data available</AlertDescription>
          </Alert>
        }
      </div>

      {/* Main Safety Metrics Trend Chart */}
      <div className="mt-8">
        {/* Trend chart content would go here */}
      </div>

      {/* Add training metrics section */}
      {renderTrainingMetrics()}

      {renderIncidentTracking()}
      {renderOshaCompliance()}
      {renderRiskManagement()}
      {renderHazardManagement()}
      {renderSafetyCulture()}

      {/* Quality Component */}
      {showQualityMetrics && <QualityIntegrationComponent project={project} />}
    </div>
  );

  return isMobile ? renderMobileView() : renderDesktopView();
}

export default SafetyDashboard;