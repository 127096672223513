import React, { useState, useEffect } from 'react';
import {
    Card,
    CardContent,
    CardHeader,
    CardTitle
} from "../../components/ui/cardui";
import { Button } from "../../components/ui/button";
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogFooter
} from "../../components/ui/dialog";
import { Input } from "../../components/ui/input";
import { Label } from "../../components/ui/label";
import { Textarea } from "../../components/ui/textarea";
import { Switch } from "../../components/ui/switch";
import {
    User,
    Phone,
    Mail,
    Building,
    UserPlus,
    Edit,
    Trash2,
    Users
} from "lucide-react";
import { toast } from "react-toastify";
import api from '../../services/api';
import { primaryButtonClasses, secondaryButtonClasses } from '../common/ButtonStyles';
import secureLocalStorage from "react-secure-storage";

const ContactForm = ({ isOpen, onClose, onSubmit, supplierId, contact = null }) => {
    const [formData, setFormData] = useState({
        name: '',
        title: '',
        email: '',
        phone: '',
        is_primary: false,
        department: '',
        notes: ''
    });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (contact) {
            setFormData({
                name: contact.name || '',
                title: contact.title || '',
                email: contact.email || '',
                phone: contact.phone || '',
                is_primary: contact.is_primary || false,
                department: contact.department || '',
                notes: contact.notes || ''
            });
        } else {
            setFormData({
                name: '',
                title: '',
                email: '',
                phone: '',
                is_primary: false,
                department: '',
                notes: ''
            });
        }
    }, [contact, isOpen]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleSwitchChange = (checked) => {
        setFormData(prev => ({ ...prev, is_primary: checked }));
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!formData.name || !formData.email || !formData.phone) {
            toast.error("Name, email, and phone are required");
            return;
        }

        // Simple email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(formData.email)) {
            toast.error("Please enter a valid email address");
            return;
        }

        setLoading(true);
        try {
            const dataToSubmit = {
                ...formData,
                supplier: supplierId
            };

            await onSubmit(dataToSubmit);
            onClose();
        } catch (error) {
            console.error("Error submitting contact:", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className="sm:max-w-[500px]">
                <DialogHeader>
                    <DialogTitle>{contact ? 'Edit Contact' : 'Add New Supplier Contact'}</DialogTitle>
                </DialogHeader>

                <form onSubmit={handleSubmit} className="space-y-4">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                            <Label htmlFor="name">Full Name*</Label>
                            <Input
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleInputChange}
                                placeholder="Enter full name"
                                required
                            />
                        </div>
                        <div>
                            <Label htmlFor="title">Job Title*</Label>
                            <Input
                                id="title"
                                name="title"
                                value={formData.title}
                                onChange={handleInputChange}
                                placeholder="Enter job title"
                                required
                            />
                        </div>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                            <Label htmlFor="email">Email*</Label>
                            <Input
                                id="email"
                                name="email"
                                type="email"
                                value={formData.email}
                                onChange={handleInputChange}
                                placeholder="Enter email address"
                                required
                            />
                        </div>
                        <div>
                            <Label htmlFor="phone">Phone Number*</Label>
                            <Input
                                id="phone"
                                name="phone"
                                value={formData.phone}
                                onChange={handleInputChange}
                                placeholder="Enter phone number"
                                required
                            />
                        </div>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                            <Label htmlFor="department">Department</Label>
                            <Input
                                id="department"
                                name="department"
                                value={formData.department}
                                onChange={handleInputChange}
                                placeholder="Enter department"
                            />
                        </div>
                        <div className="flex items-center space-x-2 mt-8">
                            <Switch
                                id="is_primary"
                                checked={formData.is_primary}
                                onCheckedChange={handleSwitchChange}
                            />
                            <Label htmlFor="is_primary">Primary Contact</Label>
                        </div>
                    </div>

                    <div>
                        <Label htmlFor="notes">Notes</Label>
                        <Textarea
                            id="notes"
                            name="notes"
                            rows={3}
                            value={formData.notes}
                            onChange={handleInputChange}
                            placeholder="Enter any additional notes about this contact"
                        />
                    </div>

                    <DialogFooter>
                        <Button
                            type="button"
                            variant="outline"
                            onClick={onClose}
                            className={secondaryButtonClasses}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            className={primaryButtonClasses}
                            disabled={loading}
                        >
                            {loading ? 'Saving...' : contact ? 'Update Contact' : 'Add Contact'}
                        </Button>
                    </DialogFooter>
                </form>
            </DialogContent>
        </Dialog>
    );
};

const SupplierContacts = ({ supplierId }) => {
    const [contacts, setContacts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [selectedContact, setSelectedContact] = useState(null);

    useEffect(() => {
        fetchContacts();
    }, [supplierId]);

    const fetchContacts = async () => {
        setLoading(true);
        try {
            const response = await api.get('supplier-contacts/', {
                params: { supplier: supplierId }
            });

            const contactsData = response.data.results || response.data;
            setContacts(contactsData);
        } catch (error) {
            console.error("Error fetching supplier contacts:", error);
            toast.error("Failed to load supplier contacts");
        } finally {
            setLoading(false);
        }
    };

    const handleAddContact = () => {
        setSelectedContact(null);
        setIsFormOpen(true);
    };

    const handleEditContact = (contact) => {
        setSelectedContact(contact);
        setIsFormOpen(true);
    };

    const handleDeleteContact = async (contactId) => {
        if (window.confirm("Are you sure you want to delete this contact?")) {
            try {
                await api.delete(`supplier-contacts/${contactId}/`);
                toast.success("Contact deleted successfully");
                fetchContacts();
            } catch (error) {
                console.error("Error deleting contact:", error);
                toast.error("Failed to delete contact");
            }
        }
    };
    const getTenantId = () => {
        let tenantId =
            localStorage.getItem('tenantIdentifier') ||
            (typeof secureLocalStorage !== 'undefined' && secureLocalStorage.getItem('currentTenantId')) ||
            localStorage.getItem('tenantId') ||
            sessionStorage.getItem('tenantId') ||
            sessionStorage.getItem('tenantIdentifier');

        if (!tenantId) {
            try {
                // Try regular localStorage userData
                const userData = JSON.parse(localStorage.getItem('userData') || '{}');
                tenantId = userData.tenant?.id || userData.tenantId;

                // If found, save to localStorage for future use
                if (tenantId) {
                    localStorage.setItem('tenantId', tenantId);
                    console.log("Retrieved tenant ID from user data and saved to localStorage");
                }
            } catch (e) {
                console.error("Error parsing user data:", e);
            }
        }

        return tenantId;
    };

    const handleSubmitContact = async (formData) => {
        try {
            // Get the tenant ID
            const tenantId = getTenantId();
            if (!tenantId) {
                toast.error("Tenant ID not found. Cannot add contact.");
                return;
            }

            const contactData = {
                ...formData,
                supplier: supplierId,
                tenant: tenantId
            };

            if (selectedContact) {
                // Update existing contact
                await api.put(`supplier-contacts/${selectedContact.id}/`, contactData);
                toast.success("Contact updated successfully");
            } else {
                // Create new contact
                await api.post('supplier-contacts/', contactData);
                toast.success("Contact added successfully");
            }
            fetchContacts();
        } catch (error) {
            console.error("Error submitting contact:", error);
            toast.error("Failed to save contact");
            throw error;
        }
    };

    const getPrimaryContact = () => {
        return contacts.find(contact => contact.is_primary);
    };

    return (
        <div className="space-y-6">
            <div className="flex justify-between items-center">
                <h2 className="text-xl font-semibold">Supplier Contacts</h2>
                <Button
                    onClick={handleAddContact}
                    className={primaryButtonClasses}
                >
                    <UserPlus className="h-4 w-4 mr-2" />
                    Add Contact
                </Button>
            </div>

            {loading ? (
                <div className="text-center py-8">Loading contacts...</div>
            ) : contacts.length === 0 ? (
                <Card>
                    <CardContent className="text-center py-12">
                        <Users className="h-12 w-12 text-gray-400 mx-auto mb-4" />
                        <p className="text-gray-500">No contacts added yet.</p>
                    </CardContent>
                </Card>
            ) : (
                <div className="space-y-4">
                    {getPrimaryContact() && (
                        <Card className="border-blue-200 bg-blue-50">
                            <CardHeader className="pb-2">
                                <CardTitle className="text-lg flex items-center">
                                    <span>Primary Contact</span>
                                </CardTitle>
                            </CardHeader>
                            <CardContent>
                                <ContactCard
                                    contact={getPrimaryContact()}
                                    onEdit={handleEditContact}
                                    onDelete={handleDeleteContact}
                                />
                            </CardContent>
                        </Card>
                    )}

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        {contacts
                            .filter(contact => !contact.is_primary)
                            .map(contact => (
                                <Card key={contact.id} className="hover:shadow-md transition-shadow">
                                    <CardContent className="p-4">
                                        <ContactCard
                                            contact={contact}
                                            onEdit={handleEditContact}
                                            onDelete={handleDeleteContact}
                                        />
                                    </CardContent>
                                </Card>
                            ))
                        }
                    </div>
                </div>
            )}

            <ContactForm
                isOpen={isFormOpen}
                onClose={() => setIsFormOpen(false)}
                onSubmit={handleSubmitContact}
                supplierId={supplierId}
                contact={selectedContact}
            />
        </div>
    );
};

const ContactCard = ({ contact, onEdit, onDelete }) => {
    return (
        <div>
            <div className="flex justify-between items-start">
                <div className="flex items-start space-x-3">
                    <div className="bg-gray-100 p-2 rounded-full">
                        <User className="h-5 w-5 text-gray-600" />
                    </div>
                    <div>
                        <h3 className="font-medium">{contact.name}</h3>
                        <p className="text-sm text-gray-500">{contact.title}</p>
                    </div>
                </div>
                <div className="flex space-x-2">
                    <Button
                        variant="outline"
                        size="sm"
                        onClick={() => onEdit(contact)}
                    >
                        <Edit className="h-4 w-4" />
                    </Button>
                    <Button
                        variant="destructive"
                        size="sm"
                        onClick={() => onDelete(contact.id)}
                    >
                        <Trash2 className="h-4 w-4" />
                    </Button>
                </div>
            </div>

            <div className="mt-4 space-y-2">
                <div className="flex items-center">
                    <Mail className="h-4 w-4 text-gray-500 mr-2" />
                    <a href={`mailto:${contact.email}`} className="text-sm text-blue-600 hover:underline">
                        {contact.email}
                    </a>
                </div>
                <div className="flex items-center">
                    <Phone className="h-4 w-4 text-gray-500 mr-2" />
                    <a href={`tel:${contact.phone}`} className="text-sm">
                        {contact.phone}
                    </a>
                </div>
                {contact.department && (
                    <div className="flex items-center">
                        <Building className="h-4 w-4 text-gray-500 mr-2" />
                        <span className="text-sm">{contact.department}</span>
                    </div>
                )}
            </div>

            {contact.notes && (
                <div className="mt-3 p-2 bg-gray-50 rounded-md">
                    <p className="text-xs text-gray-500">Notes</p>
                    <p className="text-sm">{contact.notes}</p>
                </div>
            )}
        </div>
    );
};

export default SupplierContacts;