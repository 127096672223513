import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    ChartBarIcon,
    DocumentIcon,
    CurrencyDollarIcon,
    BellIcon,
    CalendarIcon,
    CheckCircleIcon,
    ExclamationCircleIcon,
    ExclamationTriangleIcon
} from '@heroicons/react/24/outline';
import { format } from 'date-fns';
import api from '../../services/api';
import BackButton from '../common/BackButton';

const ClientDashboard = () => {
    const [projects, setProjects] = useState([]);
    const [selectedProject, setSelectedProject] = useState(null);
    const [recentUpdates, setRecentUpdates] = useState([]);
    const [notifications, setNotifications] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        fetchDashboardData();
        fetchNotifications();
    }, []);

    const fetchDashboardData = async () => {
        try {
            setLoading(true);
            setError(null);
            const response = await api.get('client-portal/');
            if (response.data && response.data.length > 0) {
                setProjects(response.data);
                setSelectedProject(response.data[0]);
                await fetchProjectUpdates(response.data[0].id);
            } else {
                setProjects([]);
                setSelectedProject(null);
            }
        } catch (error) {
            console.error('Error fetching dashboard data:', error);
            setError('Failed to load dashboard data. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    const fetchProjectUpdates = async (projectId) => {
        if (!projectId) return;

        try {
            const response = await api.get(`client-portal/${projectId}/project-timeline/`);
            setRecentUpdates(response.data || []);
        } catch (error) {
            console.error('Error fetching project updates:', error);
            setRecentUpdates([]);
        }
    };

    const fetchNotifications = async () => {
        try {
            const response = await api.get('client-notifications/');
            if (response.data && response.data.notifications) {
                // Filter unread notifications
                setNotifications(response.data.notifications.filter(n => !n.read));
            } else {
                setNotifications([]);
            }
        } catch (error) {
            console.error('Error fetching notifications:', error);
            setNotifications([]);
        }
    };
    const handleProjectClick = (project) => {
        navigate(`/client-portal/dashboard/${project.id}`);
    };

    const ProjectSummaryCard = ({ project }) => {
        if (!project) {
            return (
                <div className="bg-white rounded-lg shadow-sm p-6">
                    <div className="flex items-center justify-center h-48">
                        <div className="text-center">
                            <ExclamationTriangleIcon className="h-12 w-12 text-gray-400 mx-auto mb-4" />
                            <h3 className="text-lg font-medium text-gray-900">No Active Projects</h3>
                            <p className="mt-2 text-sm text-gray-500">
                                You currently don't have any active projects.
                            </p>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div
                className="bg-white rounded-lg shadow-sm p-6 cursor-pointer hover:shadow-md transition-shadow duration-200"
                onClick={() => handleProjectClick(project)}
            >
                <div className="flex items-center justify-between mb-4">
                    <h3 className="text-lg font-medium text-gray-900">{project.name}</h3>
                    <span className={`px-3 py-1 rounded-full text-sm font-medium
                        ${project.status === 'In Progress' ? 'bg-blue-100 text-blue-800' :
                            project.status === 'Completed' ? 'bg-green-100 text-green-800' :
                                'bg-yellow-100 text-yellow-800'}`}>
                        {project.status}
                    </span>
                </div>

                <div className="grid grid-cols-2 gap-4 mb-6">
                    <div className="space-y-2">
                        <p className="text-sm text-gray-500">Progress</p>
                        <div className="flex items-center">
                            <div className="flex-1 bg-gray-200 rounded-full h-2">
                                <div
                                    className="bg-blue-600 h-2 rounded-full"
                                    style={{ width: `${project.progress || 0}%` }}
                                />
                            </div>
                            <span className="ml-2 text-sm font-medium text-gray-700">
                                {project.progress || 0}%
                            </span>
                        </div>
                    </div>
                    <div className="space-y-2">
                        <p className="text-sm text-gray-500">Days Remaining</p>
                        <p className="text-lg font-medium text-gray-900">
                            {project.days_remaining || 'N/A'}
                        </p>
                    </div>
                </div>

                <div className="grid grid-cols-3 gap-4">
                    <div className="text-center">
                        <DocumentIcon className="h-6 w-6 mx-auto text-blue-500" />
                        <p className="mt-1 text-sm font-medium text-gray-900">
                            {project.key_stats?.total_documents || 0}
                        </p>
                        <p className="text-xs text-gray-500">Documents</p>
                    </div>
                    <div className="text-center">
                        <ChartBarIcon className="h-6 w-6 mx-auto text-green-500" />
                        <p className="mt-1 text-sm font-medium text-gray-900">
                            {project.key_stats?.open_changes || 0}
                        </p>
                        <p className="text-xs text-gray-500">Changes</p>
                    </div>
                    <div className="text-center">
                        <CurrencyDollarIcon className="h-6 w-6 mx-auto text-purple-500" />
                        <p className="mt-1 text-sm font-medium text-gray-900">
                            {project.key_stats?.upcoming_payments || 0}
                        </p>
                        <p className="text-xs text-gray-500">Payments Due</p>
                    </div>
                </div>
            </div>
        );
    };

    const UpdateTimeline = ({ updates }) => (
        <div className="flow-root bg-white rounded-lg shadow-sm p-6">
            <h3 className="text-lg font-medium text-gray-900 mb-4">Recent Updates</h3>
            <ul className="-mb-8">
                {updates.map((update, idx) => (
                    <li key={update.id}>
                        <div className="relative pb-8">
                            {idx !== updates.length - 1 && (
                                <span
                                    className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                                    aria-hidden="true"
                                />
                            )}
                            <div className="relative flex space-x-3">
                                <div>
                                    <span className={`h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white
                    ${update.update_type === 'progress' ? 'bg-blue-500' :
                                            update.update_type === 'document' ? 'bg-green-500' :
                                                update.update_type === 'financial' ? 'bg-purple-500' :
                                                    'bg-gray-500'}`}>
                                        {update.update_type === 'progress' && <ChartBarIcon className="h-5 w-5 text-white" />}
                                        {update.update_type === 'document' && <DocumentIcon className="h-5 w-5 text-white" />}
                                        {update.update_type === 'financial' && <CurrencyDollarIcon className="h-5 w-5 text-white" />}
                                    </span>
                                </div>
                                <div className="flex-1 min-w-0">
                                    <div className="text-sm text-gray-500">
                                        <div className="font-medium text-gray-900">
                                            {update.title}
                                            {update.is_important && (
                                                <ExclamationCircleIcon className="inline-block h-5 w-5 ml-1 text-yellow-500" />
                                            )}
                                        </div>
                                        <p className="mt-0.5">{update.description}</p>
                                        <time dateTime={update.created_at} className="text-xs">
                                            {format(new Date(update.created_at), 'MMM d, yyyy h:mm a')}
                                        </time>
                                    </div>
                                    {update.related_data && (
                                        <div className="mt-2">
                                            {update.update_type === 'progress' && (
                                                <div className="flex items-center space-x-2">
                                                    <div className="flex-1 bg-gray-200 rounded-full h-2">
                                                        <div
                                                            className="bg-blue-600 h-2 rounded-full"
                                                            style={{ width: `${update.related_data.percentage}%` }}
                                                        />
                                                    </div>
                                                    <span className="text-sm font-medium text-gray-700">
                                                        {update.related_data.percentage}%
                                                    </span>
                                                </div>
                                            )}
                                            {update.update_type === 'document' && (
                                                <a
                                                    href={update.related_data.download_url}
                                                    className="inline-flex items-center px-3 py-1 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                                                >
                                                    View Document
                                                </a>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );

    const NotificationsPanel = ({ notifications }) => {
        if (!notifications.length) {
            return (
                <div className="bg-white rounded-lg shadow-sm p-6">
                    <h3 className="text-lg font-medium text-gray-900 mb-4">Notifications</h3>
                    <div className="text-center py-6">
                        <BellIcon className="mx-auto h-10 w-10 text-gray-400" />
                        <p className="mt-2 text-sm text-gray-500">No new notifications</p>
                    </div>
                </div>
            );
        }

        return (
            <div className="bg-white rounded-lg shadow-sm p-6">
                <h3 className="text-lg font-medium text-gray-900 mb-4">
                    Notifications
                    {notifications.length > 0 && (
                        <span className="ml-2 px-2 py-1 text-xs font-medium bg-blue-100 text-blue-800 rounded-full">
                            {notifications.length}
                        </span>
                    )}
                </h3>
                <div className="space-y-4">
                    {notifications.map((notification) => (
                        <div
                            key={notification.id}
                            className={`flex items-start space-x-3 p-3 rounded-md
                                ${notification.priority === 'high' ? 'bg-red-50' :
                                    notification.priority === 'medium' ? 'bg-yellow-50' :
                                        'bg-blue-50'}`}
                        >
                            <BellIcon className={`h-5 w-5
                                ${notification.priority === 'high' ? 'text-red-400' :
                                    notification.priority === 'medium' ? 'text-yellow-400' :
                                        'text-blue-400'}`}
                            />
                            <div className="flex-1 min-w-0">
                                <p className="text-sm font-medium text-gray-900">
                                    {notification.title}
                                </p>
                                <p className="text-sm text-gray-500">
                                    {notification.message}
                                </p>
                                <div className="mt-1 flex items-center justify-between">
                                    <span className="text-xs text-gray-500">
                                        {format(new Date(notification.created_at), 'MMM d, h:mm a')}
                                    </span>
                                    {notification.action_url && (
                                        <a
                                            href={notification.action_url}
                                            className="text-sm font-medium text-blue-600 hover:text-blue-500"
                                        >
                                            View Details
                                        </a>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    if (loading) {
        return (
            <div className="flex items-center justify-center h-screen">
                <div className="text-center">
                    <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900 mb-4"></div>
                    <p className="text-gray-600">Loading dashboard...</p>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex items-center justify-center h-screen">
                <div className="text-center">
                    <ExclamationCircleIcon className="h-12 w-12 text-red-500 mx-auto mb-4" />
                    <h3 className="text-lg font-medium text-gray-900 mb-2">Error Loading Dashboard</h3>
                    <p className="text-gray-600">{error}</p>
                    <button
                        onClick={fetchDashboardData}
                        className="mt-4 px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                    >
                        Retry
                    </button>
                </div>
            </div>
        );
    }

    return (
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            {/* Project List Section */}
            <div className="mb-6">
                <h2 className="text-2xl font-bold text-gray-900 mb-4">Your Projects</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    {projects.map(project => (
                        <ProjectSummaryCard
                            key={project.id}
                            project={project}
                        />
                    ))}
                </div>
            </div>

            <div className="grid grid-cols-12 gap-6">
                {/* Timeline */}
                <div className="col-span-12">
                    <UpdateTimeline updates={recentUpdates} />
                </div>
            </div>
        </div>
    );
};

export default ClientDashboard;