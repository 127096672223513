import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import {
    CurrencyDollarIcon,
    ClockIcon,
    DocumentCheckIcon,
    ArrowTrendingUpIcon
} from '@heroicons/react/24/outline';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer
} from 'recharts';
import { Card, CardHeader } from '../../components/ui/cardui';
import api from '../../services/api';
import { AlertCircleIcon } from 'lucide-react';
import BackButton from '../common/BackButton';

const ClientFinancials = ({ projectId }) => {
    const [financialData, setFinancialData] = useState({
        financial_summary: {
            current_contract_sum: 0,
            original_contract_sum: 0,
            total_paid: 0,
            remaining_balance: 0,
            change_orders_total: 0
        }
    });
    const [paymentMilestones, setPaymentMilestones] = useState([]);
    const [changeOrders, setChangeOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [activeTab, setActiveTab] = useState('overview');

    useEffect(() => {
        fetchFinancialData();
    }, [projectId]);

    const fetchFinancialData = async () => {
        try {
            const [financialResponse, milestonesResponse, changeOrdersResponse] = await Promise.all([
                api.get(`projects/${projectId}/financials/summary/`),
                api.get(`projects/${projectId}/payments/`),
                api.get(`projects/${projectId}/change-orders/`)
            ]);

            setFinancialData(financialResponse.data || {
                financial_summary: {
                    current_contract_sum: 0,
                    original_contract_sum: 0,
                    total_paid: 0,
                    remaining_balance: 0,
                    change_orders_total: 0
                }
            });
            setPaymentMilestones(milestonesResponse.data || []);
            setChangeOrders(changeOrdersResponse.data || []);
            setLoading(false);
        } catch (error) {
            console.error('Failed to fetch financial data:', error);
            setLoading(false);
        }
    };

    const formatCurrency = (amount) => {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD'
        }).format(amount);
    };

    const LoadingState = () => (
        <div className="flex items-center justify-center h-64">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600" />
        </div>
    );

    const ErrorState = () => (
        <div className="text-center py-12">
            <div className="text-red-600 mb-4">
                <AlertCircleIcon className="h-12 w-12 mx-auto" />
            </div>
            <h3 className="text-lg font-medium text-gray-900 mb-2">Error Loading Data</h3>
            <p className="text-gray-500">{error}</p>
            <button
                onClick={fetchFinancialData}
                className="mt-4 px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
            >
                Retry
            </button>
        </div>
    );

    const FinancialOverview = () => {
        const summary = financialData?.financial_summary || {
            current_contract_sum: 0,
            original_contract_sum: 0,
            total_paid: 0,
            remaining_balance: 0,
            change_orders_total: 0
        };

        return (
            <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
                <Card>
                    <div className="p-5">
                        <div className="flex items-center">
                            <div className="flex-shrink-0">
                                <CurrencyDollarIcon className="h-6 w-6 text-gray-400" />
                            </div>
                            <div className="ml-5 w-0 flex-1">
                                <dl>
                                    <dt className="text-sm font-medium text-gray-500 truncate">
                                        Current Contract Amount
                                    </dt>
                                    <dd className="text-lg font-medium text-gray-900">
                                        {formatCurrency(summary.current_contract_sum)}
                                    </dd>
                                </dl>
                            </div>
                        </div>
                    </div>
                    <div className="bg-gray-50 px-5 py-3">
                        <div className="text-sm">
                            <span className="text-gray-500">
                                Original: {formatCurrency(summary.original_contract_sum)}
                            </span>
                        </div>
                    </div>
                </Card>

                {/* Add similar cards for other financial metrics */}
            </div>
        );
    };

    const PaymentSchedule = () => (
        <div className="mt-8">
            <h3 className="text-lg font-medium text-gray-900">Payment Schedule</h3>
            <div className="mt-4">
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                            <tr>
                                <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">
                                    Milestone
                                </th>
                                <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Amount
                                </th>
                                <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Due Date
                                </th>
                                <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Status
                                </th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                            {paymentMilestones.map((milestone) => (
                                <tr key={milestone.id}>
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900">
                                        {milestone.title}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                        {formatCurrency(milestone.amount)}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                        {format(new Date(milestone.due_date), 'MMM d, yyyy')}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm">
                                        <span className={`inline-flex rounded-full px-2 text-xs font-semibold leading-5 
                      ${milestone.status === 'paid' ? 'bg-green-100 text-green-800' :
                                                milestone.status === 'due' ? 'bg-yellow-100 text-yellow-800' :
                                                    milestone.status === 'overdue' ? 'bg-red-100 text-red-800' :
                                                        'bg-gray-100 text-gray-800'}`}>
                                            {milestone.status}
                                        </span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );

    const ChangeOrdersList = () => (
        <div className="mt-8">
            <h3 className="text-lg font-medium text-gray-900">Change Orders</h3>
            <div className="mt-4 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle">
                        <table className="min-w-full divide-y divide-gray-300">
                            <thead>
                                <tr>
                                    <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">
                                        Title
                                    </th>
                                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Amount
                                    </th>
                                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Submitted Date
                                    </th>
                                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Status
                                    </th>
                                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Schedule Impact
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200">
                                {changeOrders.map((co) => (
                                    <tr key={co.id}>
                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm">
                                            <div className="font-medium text-gray-900">{co.title}</div>
                                            <div className="text-gray-500">{co.description}</div>
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                            {formatCurrency(co.amount)}
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                            {format(new Date(co.submitted_date), 'MMM d, yyyy')}
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm">
                                            <span className={`inline-flex rounded-full px-2 text-xs font-semibold leading-5 
                        ${co.status === 'approved' ? 'bg-green-100 text-green-800' :
                                                    co.status === 'pending_approval' ? 'bg-yellow-100 text-yellow-800' :
                                                        co.status === 'rejected' ? 'bg-red-100 text-red-800' :
                                                            'bg-gray-100 text-gray-800'}`}>
                                                {co.status.replace('_', ' ')}
                                            </span>
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                            {co.impact_days > 0 ? `${co.impact_days} days` : 'None'}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );

    const FinancialCharts = () => {
        // Prepare data for charts
        const paymentData = paymentMilestones.map(milestone => ({
            name: milestone.title,
            amount: parseFloat(milestone.amount),
            status: milestone.status
        }));

        const changeOrderData = changeOrders.map(co => ({
            name: co.title,
            amount: parseFloat(co.amount),
            status: co.status
        }));

        return (
            <div className="mt-8 grid grid-cols-1 gap-8 lg:grid-cols-2">
                <div className="bg-white rounded-lg shadow p-6">
                    <h4 className="text-lg font-medium text-gray-900 mb-4">Payment Schedule</h4>
                    <div className="h-80">
                        <ResponsiveContainer width="100%" height="100%">
                            <BarChart data={paymentData}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" angle={-45} textAnchor="end" height={80} />
                                <YAxis />
                                <Tooltip
                                    formatter={(value) => formatCurrency(value)}
                                    labelFormatter={(label) => `Milestone: ${label}`}
                                />
                                <Bar
                                    dataKey="amount"
                                    fill="#4F46E5"
                                    name="Payment Amount"
                                />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </div>

                <div className="bg-white rounded-lg shadow p-6">
                    <h4 className="text-lg font-medium text-gray-900 mb-4">Change Orders Impact</h4>
                    <div className="h-80">
                        <ResponsiveContainer width="100%" height="100%">
                            <BarChart data={changeOrderData}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" angle={-45} textAnchor="end" height={80} />
                                <YAxis />
                                <Tooltip
                                    formatter={(value) => formatCurrency(value)}
                                    labelFormatter={(label) => `Change Order: ${label}`}
                                />
                                <Bar
                                    dataKey="amount"
                                    fill="#4F46E5"
                                    name="Change Order Amount"
                                />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </div>
            </div>
        );
    };

    if (loading) {
        return <LoadingState />;
    }

    if (error) {
        return <ErrorState />;
    }

    return (
        <div className="space-y-6">
            <BackButton />
            <div className="sm:flex sm:items-center sm:justify-between">
                <h2 className="text-2xl font-bold text-gray-900">Financial Overview</h2>
            </div>

            <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                <div className="border-b border-gray-200">
                    <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                        {['overview', 'payments', 'change-orders', 'charts'].map((tab) => (
                            <button
                                key={tab}
                                onClick={() => setActiveTab(tab)}
                                className={`
                    whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm
                    ${activeTab === tab
                                        ? 'border-indigo-500 text-indigo-600'
                                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                                    }
                  `}
                            >
                                {tab.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                            </button>
                        ))}
                    </nav>
                </div>

                <div className="p-6">
                    {activeTab === 'overview' && <FinancialOverview />}
                    {activeTab === 'payments' && <PaymentSchedule />}
                    {activeTab === 'change-orders' && <ChangeOrdersList />}
                    {activeTab === 'charts' && <FinancialCharts />}
                </div>
            </div>
        </div>
    );
};

export default ClientFinancials;