import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
    Tabs,
    TabsList,
    TabsTrigger,
    TabsContent
} from "../../components/ui/tabs";
import { Alert, AlertDescription } from "../../components/ui/alert";
import {
    FileText,
    Users,
    Star,
    FileCheck,
    Building
} from "lucide-react";
import { toast } from "react-toastify";
import api from '../../services/api';
import SupplierReviews from './SupplierReviews';
import SupplierDocuments from './SupplierDocument';
import SupplierContacts from './SupplierContacts';
import PreferredSuppliers from './PreferredSuppliers';
import SupplierCategories from './SupplierCategories';

const SupplierDetailTabs = ({ supplierId, supplier }) => {
    const [activeTab, setActiveTab] = useState('contacts');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    if (!supplierId && !supplier) {
        return (
            <Alert>
                <AlertDescription>
                    Supplier information is required.
                </AlertDescription>
            </Alert>
        );
    }

    const id = supplierId || supplier.id;

    return (
        <Tabs value={activeTab} onValueChange={setActiveTab} className="w-full">
            <TabsList className="grid grid-cols-4 mb-6">
                <TabsTrigger value="contacts" className="flex items-center">
                    <Users className="h-4 w-4 mr-2" />
                    <span>Contacts</span>
                </TabsTrigger>
                <TabsTrigger value="documents" className="flex items-center">
                    <FileText className="h-4 w-4 mr-2" />
                    <span>Documents</span>
                </TabsTrigger>
                <TabsTrigger value="reviews" className="flex items-center">
                    <Star className="h-4 w-4 mr-2" />
                    <span>Reviews</span>
                </TabsTrigger>
                <TabsTrigger value="preferred" className="flex items-center">
                    <FileCheck className="h-4 w-4 mr-2" />
                    <span>Preferred</span>
                </TabsTrigger>
            </TabsList>

            <TabsContent value="contacts">
                <SupplierContacts supplierId={id} />
            </TabsContent>

            <TabsContent value="documents">
                <SupplierDocuments supplierId={id} />
            </TabsContent>

            <TabsContent value="reviews">
                <SupplierReviews supplierId={id} />
            </TabsContent>

            <TabsContent value="preferred">
                <PreferredSuppliers />
            </TabsContent>
        </Tabs>
    );
};

export default SupplierDetailTabs;