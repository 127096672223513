import React, { useState, useEffect, Fragment } from 'react';
import { Trash2, Share2, Search, Grid, List, MoreVertical, Edit, ChevronRight, Menu as MenuIcon, X } from 'lucide-react';
import DocumentToolbar from '../shared/DocumentToolbar';
import DocumentBreadcrumbs from '../shared/DocumentBreadcrumbs';
import ShareEmailModal from '../shared/ShareEmailModal';
import DocumentAnnotator from '../shared/DocumentAnnotator';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import { Menu, Transition } from '@headlessui/react';
import api from '../../../services/api';

const GENERAL_DOCUMENT_TYPES = [
    'Contract', 'Agreement', 'Report', 'Spreadsheet',
    'Presentation', 'Template', 'Image', 'Other'
];

// Header Component
const Header = ({ setShowCreateFolderModal, searchQuery, handleSearch, view, setView }) => {
    return (
        <div className="flex justify-between items-center mb-6">
            <div className="flex items-center">
                <h2 className="text-xl font-bold">General Documents</h2>
            </div>
            <div className="flex items-center space-x-4">
                <div className="relative">
                    <input
                        type="text"
                        placeholder="Search files..."
                        className="pl-10 pr-4 py-2 border rounded-lg w-64"
                        value={searchQuery}
                        onChange={handleSearch}
                    />
                    <Search className="absolute left-3 top-2.5 text-gray-400" />
                </div>
                <div className="flex border rounded-lg">
                    <button
                        onClick={() => setView('grid')}
                        className={`px-3 py-2 ${view === 'grid' ? 'bg-gray-100' : 'bg-white'}`}
                    >
                        <Grid size={20} />
                    </button>
                    <button
                        onClick={() => setView('list')}
                        className={`px-3 py-2 ${view === 'list' ? 'bg-gray-100' : 'bg-white'}`}
                    >
                        <List size={20} />
                    </button>
                </div>
            </div>
        </div>
    );
};

const GeneralDocuments = () => {
    const [rootFolder, setRootFolder] = useState(null);
    const [showCreateFolderModal, setShowCreateFolderModal] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [documents, setDocuments] = useState([]);
    const [currentFolderId, setCurrentFolderId] = useState(null);
    const [path, setPath] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [documentToDelete, setDocumentToDelete] = useState(null);
    const [showShareModal, setShowShareModal] = useState(false);
    const [documentToShare, setDocumentToShare] = useState(null);
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [showAnnotator, setShowAnnotator] = useState(false);
    const [view, setView] = useState('list');
    const [newFolderName, setNewFolderName] = useState('');

    const itemsPerPage = 10;

    useEffect(() => {
        fetchRootFolder();
    }, []);

    useEffect(() => {
        if (rootFolder) {
            fetchDocuments(currentFolderId || rootFolder, currentPage);
        }
    }, [rootFolder, currentFolderId, currentPage]);

    const fetchRootFolder = async () => {
        try {
            const response = await api.get('document-management/root-folders/', {
                params: { feature_types: 'document' }
            });

            if (response.data.root_folders.length > 0) {
                setRootFolder(response.data.root_folders[0].id);
            }
        } catch (error) {
            console.error('Error fetching root folder:', error);
            toast.error('Failed to load document root folder');
        }
    };

    const fetchDocuments = (folderId, page = 1) => {
        if (!folderId) return;
        setIsLoading(true);

        const token = localStorage.getItem('access_token') || localStorage.getItem('token');
        const url = `document-management/folders/${folderId}/contents/`;

        const params = {
            page: page,
            per_page: itemsPerPage,
            search: searchQuery
        };

        api.get(url, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            params: params
        })
            .then(response => {
                setDocuments(response.data.results || response.data);
                setTotalPages(Math.ceil((response.data.count || response.data.length) / itemsPerPage));
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error fetching documents:', error);
                setIsLoading(false);
                toast.error('Failed to load documents');
            });
    };

    const handleFolderClick = (folderId, folderTitle) => {
        setCurrentFolderId(folderId);
        setPath(prev => [...prev, { id: folderId, title: folderTitle }]);
        setCurrentPage(1);
        fetchDocuments(folderId, 1);
    };

    const handleBreadcrumbClick = (index) => {
        if (index === 0) {
            // Root folder
            setCurrentFolderId(null);
            setPath([]);
            setCurrentPage(1);
            fetchDocuments(rootFolder, 1);
        } else {
            const newPath = path.slice(0, index);
            const newFolderId = newPath.length > 0 ? newPath[newPath.length - 1].id : rootFolder;
            setPath(newPath);
            setCurrentFolderId(newFolderId);
            setCurrentPage(1);
            fetchDocuments(newFolderId, 1);
        }
    };

    const handleRowClick = (doc, event) => {
        if (event.target.closest('.action-menu')) {
            return;
        }

        if (doc.file_type === 'folder') {
            handleFolderClick(doc.id, doc.title);
        }
    };

    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
        // Reset to first page when searching
        setCurrentPage(1);
        // Debounce search
        const timeoutId = setTimeout(() => {
            fetchDocuments(currentFolderId || rootFolder, 1);
        }, 500);

        return () => clearTimeout(timeoutId);
    };

    const handleCreateFolder = async () => {
        if (!newFolderName.trim()) {
            toast.error('Please enter a folder name');
            return;
        }

        try {
            const response = await api.post('document-management/create-folder/', {
                parent_id: currentFolderId || rootFolder,
                title: newFolderName,
                file_type: 'folder',
                type: 'Other'
            });

            toast.success('Folder created successfully');
            setNewFolderName('');
            setShowCreateFolderModal(false);
            fetchDocuments(currentFolderId || rootFolder, currentPage);
        } catch (error) {
            console.error('Error creating folder:', error);
            toast.error('Failed to create folder');
        }
    };

    const initiateDelete = (document) => {
        setDocumentToDelete(document);
        setShowDeleteModal(true);
    };

    const handleDelete = () => {
        if (documentToDelete) {
            api.patch(`document-management/flag-delete-document/${documentToDelete.id}/`)
                .then(() => {
                    fetchDocuments(currentFolderId || rootFolder, currentPage);
                    toast.success('Document deleted successfully');
                    setShowDeleteModal(false);
                    setDocumentToDelete(null);
                })
                .catch(error => {
                    toast.error('Error deleting document');
                    console.error('Error deleting document:', error);
                });
        }
    };

    const handleShare = (document) => {
        setDocumentToShare(document);
        setShowShareModal(true);
    };

    const handleShareSubmit = async (shareData) => {
        try {
            await api.post(`document-management/documents/share/`, {
                document_id: shareData.documentId,
                recipient_email: shareData.email,
                message: shareData.message
            });
            toast.success('Document shared successfully');
        } catch (error) {
            console.error('Error sharing document:', error);
            toast.error('Failed to share document');
            throw error;
        }
    };

    const handleOpenAnnotator = (document) => {
        if (document.file_type === 'folder') {
            toast.warn("Folders cannot be annotated");
            return;
        }
        setSelectedDocument(document);
        setShowAnnotator(true);
    };

    const handleCloseAnnotator = () => {
        setShowAnnotator(false);
        setSelectedDocument(null);
    };

    const renderActionMenu = (doc) => (
        <Menu as="div" className="relative inline-block text-left action-menu">
            <Menu.Button className="inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
                <MoreVertical className="h-5 w-5" aria-hidden="true" />
            </Menu.Button>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="absolute right-0 z-50 mt-2 w-48 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                        {doc.file_type !== 'folder' && (
                            <>
                                <Menu.Item>
                                    {({ active }) => (
                                        <button
                                            onClick={() => handleShare(doc)}
                                            className={`${active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                                                } group flex items-center px-4 py-2 text-sm w-full text-left`}
                                        >
                                            <Share2
                                                className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                                aria-hidden="true"
                                            />
                                            Share
                                        </button>
                                    )}
                                </Menu.Item>
                                <Menu.Item>
                                    {({ active }) => (
                                        <button
                                            onClick={() => handleOpenAnnotator(doc)}
                                            className={`${active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                                                } group flex items-center px-4 py-2 text-sm w-full text-left`}
                                        >
                                            <Edit
                                                className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                                aria-hidden="true"
                                            />
                                            Annotate
                                        </button>
                                    )}
                                </Menu.Item>
                            </>
                        )}
                        <Menu.Item>
                            {({ active }) => (
                                <button
                                    onClick={() => initiateDelete(doc)}
                                    className={`${active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                                        } group flex items-center px-4 py-2 text-sm w-full text-left`}
                                >
                                    <Trash2
                                        className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                        aria-hidden="true"
                                    />
                                    Delete
                                </button>
                            )}
                        </Menu.Item>
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    );

    const renderPageNumbers = () => {
        const pageNumbers = [];
        const ellipsis = <span className="px-4 py-2">...</span>;

        if (totalPages <= 7) {
            for (let i = 1; i <= totalPages; i++) {
                pageNumbers.push(
                    <button
                        key={i}
                        onClick={() => setCurrentPage(i)}
                        className={`relative inline-flex items-center px-4 py-2 border text-sm font-medium ${currentPage === i
                            ? 'z-10 bg-[#344bfc] text-white border-[#344bfc]'
                            : 'bg-white text-gray-500 hover:bg-gray-50'
                            }`}
                    >
                        {i}
                    </button>
                );
            }
        } else {
            // Simplified pagination logic
            if (currentPage <= 3) {
                for (let i = 1; i <= 3; i++) {
                    pageNumbers.push(
                        <button
                            key={i}
                            onClick={() => setCurrentPage(i)}
                            className={`relative inline-flex items-center px-4 py-2 border text-sm font-medium ${currentPage === i
                                ? 'z-10 bg-[#344bfc] text-white border-[#344bfc]'
                                : 'bg-white text-gray-500 hover:bg-gray-50'
                                }`}
                        >
                            {i}
                        </button>
                    );
                }
                pageNumbers.push(ellipsis);
                pageNumbers.push(
                    <button
                        key={totalPages}
                        onClick={() => setCurrentPage(totalPages)}
                        className="relative inline-flex items-center px-4 py-2 border text-sm font-medium bg-white text-gray-500 hover:bg-gray-50"
                    >
                        {totalPages}
                    </button>
                );
            } else if (currentPage >= totalPages - 2) {
                pageNumbers.push(
                    <button
                        key={1}
                        onClick={() => setCurrentPage(1)}
                        className="relative inline-flex items-center px-4 py-2 border text-sm font-medium bg-white text-gray-500 hover:bg-gray-50"
                    >
                        1
                    </button>
                );
                pageNumbers.push(ellipsis);
                for (let i = totalPages - 2; i <= totalPages; i++) {
                    pageNumbers.push(
                        <button
                            key={i}
                            onClick={() => setCurrentPage(i)}
                            className={`relative inline-flex items-center px-4 py-2 border text-sm font-medium ${currentPage === i
                                ? 'z-10 bg-[#344bfc] text-white border-[#344bfc]'
                                : 'bg-white text-gray-500 hover:bg-gray-50'
                                }`}
                        >
                            {i}
                        </button>
                    );
                }
            } else {
                pageNumbers.push(
                    <button
                        key={1}
                        onClick={() => setCurrentPage(1)}
                        className="relative inline-flex items-center px-4 py-2 border text-sm font-medium bg-white text-gray-500 hover:bg-gray-50"
                    >
                        1
                    </button>
                );
                pageNumbers.push(ellipsis);
                for (let i = currentPage - 1; i <= currentPage + 1; i++) {
                    pageNumbers.push(
                        <button
                            key={i}
                            onClick={() => setCurrentPage(i)}
                            className={`relative inline-flex items-center px-4 py-2 border text-sm font-medium ${currentPage === i
                                ? 'z-10 bg-[#344bfc] text-white border-[#344bfc]'
                                : 'bg-white text-gray-500 hover:bg-gray-50'
                                }`}
                        >
                            {i}
                        </button>
                    );
                }
                pageNumbers.push(ellipsis);
                pageNumbers.push(
                    <button
                        key={totalPages}
                        onClick={() => setCurrentPage(totalPages)}
                        className="relative inline-flex items-center px-4 py-2 border text-sm font-medium bg-white text-gray-500 hover:bg-gray-50"
                    >
                        {totalPages}
                    </button>
                );
            }
        }
        return pageNumbers;
    };

    const EmptyState = () => (
        <div className="flex flex-col items-center justify-center h-64 p-8">
            <div className="text-gray-400 mb-6">
                <List size={48} />
            </div>
            <h3 className="text-lg font-medium text-gray-900 mb-2">No documents found</h3>
            <p className="text-sm text-gray-500 mb-6 text-center">
                {searchQuery ? "No results match your search criteria" : "Upload documents or create folders to organize your files"}
            </p>
        </div>
    );

    return (
        <div className="flex flex-col h-full">
            {/* Header */}
            <Header
                setShowCreateFolderModal={setShowCreateFolderModal}
                searchQuery={searchQuery}
                handleSearch={handleSearch}
                view={view}
                setView={setView}
            />

            {/* Main Content */}
            <div className="flex-1 flex flex-col">
                {/* Toolbar */}
                {rootFolder && (
                    <DocumentToolbar
                        folderId={currentFolderId || rootFolder}
                        documentTypes={GENERAL_DOCUMENT_TYPES}
                        featureType="document"
                        onDocumentsChange={() => fetchDocuments(currentFolderId || rootFolder, currentPage)}
                    />
                )}

                {/* Breadcrumbs */}
                <DocumentBreadcrumbs
                    path={[{ id: rootFolder, title: 'Documents' }, ...path]}
                    onNavigate={handleBreadcrumbClick}
                />

                {/* Document List */}
                <div className="flex-1 bg-white rounded-lg shadow-md overflow-hidden">
                    {isLoading ? (
                        <div className="flex justify-center items-center h-64">
                            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
                        </div>
                    ) : documents.length === 0 ? (
                        <EmptyState />
                    ) : view === 'list' ? (
                        <div className="overflow-x-auto">
                            <table className="w-full">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Type</th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Modified</th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Size</th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200">
                                    {documents.map(doc => (
                                        <tr
                                            key={doc.id}
                                            onClick={(e) => handleRowClick(doc, e)}
                                            className="hover:bg-gray-100 cursor-pointer"
                                        >
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <div className="text-sm font-medium text-gray-900">{doc.file_type === 'folder' ? '📁' : '📄'} {doc.title}</div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                {doc.type || 'Unknown'}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                {format(new Date(doc.uploaded_at), 'MMM d, yyyy - h:mm a')}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                {doc.file_size ? `${(doc.file_size / 1024 / 1024).toFixed(2)} MB` : '-'}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium action-menu">
                                                {renderActionMenu(doc)}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 p-4">
                            {documents.map(doc => (
                                <div
                                    key={doc.id}
                                    className="bg-white rounded-lg border border-gray-200 hover:shadow-md transition-shadow cursor-pointer"
                                    onClick={(e) => handleRowClick(doc, e)}
                                >
                                    <div className="p-4">
                                        <div className="flex justify-between items-start">
                                            <div className="flex-1">
                                                <div className="text-sm font-medium text-gray-900 truncate">
                                                    {doc.file_type === 'folder' ? '📁' : '📄'} {doc.title}
                                                </div>
                                                <p className="text-xs text-gray-500 mt-1">
                                                    {doc.type || 'Unknown Type'}
                                                </p>
                                                <p className="text-xs text-gray-500 mt-1">
                                                    {format(new Date(doc.uploaded_at), 'MMM d, yyyy')}
                                                </p>
                                                {doc.file_size && (
                                                    <p className="text-xs text-gray-500 mt-1">
                                                        {`${(doc.file_size / 1024 / 1024).toFixed(2)} MB`}
                                                    </p>
                                                )}
                                            </div>
                                            <div className="action-menu ml-2">
                                                {renderActionMenu(doc)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>

                {/* Pagination */}
                {documents.length > 0 && totalPages > 1 && (
                    <div className="mt-4 flex justify-center">
                        <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                            <button
                                onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                                disabled={currentPage === 1}
                                className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                            >
                                Previous
                            </button>
                            {renderPageNumbers()}
                            <button
                                onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                                disabled={currentPage === totalPages}
                                className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                            >
                                Next
                            </button>
                        </nav>
                    </div>
                )}
            </div>

            {/* Create Folder Modal */}
            {showCreateFolderModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white rounded-lg p-6 w-full max-w-md">
                        <h3 className="text-lg font-semibold mb-4">Create New Folder</h3>
                        <button
                            onClick={() => setShowCreateFolderModal(false)}
                            className="absolute top-4 right-4 text-gray-600 hover:text-gray-800"
                        >
                            ×
                        </button>

                        <div className="space-y-4">
                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                    Folder Name
                                </label>
                                <input
                                    type="text"
                                    value={newFolderName}
                                    onChange={(e) => setNewFolderName(e.target.value)}
                                    placeholder="Enter folder name"
                                    className="w-full p-2 border border-gray-300 rounded"
                                />
                            </div>

                            <div className="flex justify-end space-x-3">
                                <button
                                    onClick={() => setShowCreateFolderModal(false)}
                                    className="px-4 py-2 border rounded-md text-gray-700"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={handleCreateFolder}
                                    className="px-4 py-2 bg-blue-600 text-white rounded-md"
                                    disabled={!newFolderName.trim()}
                                >
                                    Create
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* Delete Confirmation Modal */}
            {showDeleteModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white rounded-lg p-6 w-full max-w-md">
                        <h3 className="text-lg font-semibold mb-2">Delete Document</h3>
                        <p className="mb-4">
                            Are you sure you want to delete {documentToDelete?.title}? This action cannot be undone.
                        </p>
                        <div className="flex justify-end space-x-3">
                            <button
                                onClick={() => setShowDeleteModal(false)}
                                className="px-4 py-2 border rounded-md"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleDelete}
                                className="px-4 py-2 bg-red-600 text-white rounded-md"
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {/* Share Modal */}
            <ShareEmailModal
                isOpen={showShareModal}
                onClose={() => setShowShareModal(false)}
                document={documentToShare}
                onShare={handleShareSubmit}
            />

            {/* Annotator */}
            {showAnnotator && selectedDocument && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white rounded-lg w-full max-w-6xl p-4 h-[90vh] flex flex-col">
                        <div className="flex justify-between items-center mb-2">
                            <h3 className="text-lg font-medium">
                                Annotating: {selectedDocument.title}
                            </h3>
                            <button
                                onClick={handleCloseAnnotator}
                                className="text-gray-600 hover:text-gray-800"
                            >
                                ×
                            </button>
                        </div>
                        <div className="flex-1 overflow-hidden">
                            <DocumentAnnotator
                                documentId={selectedDocument.id}
                                projectId={null}
                                onSave={() => {
                                    handleCloseAnnotator();
                                    fetchDocuments(currentFolderId || rootFolder, currentPage);
                                }}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default GeneralDocuments;