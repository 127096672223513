import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "../../components/ui/cardui";
import { Button } from "../../components/ui/button";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "../../components/ui/dialog";
import { Input } from "../../components/ui/input";
import { Label } from "../../components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../components/ui/select";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../components/ui/tabs";
import { Alert, AlertDescription } from "../../components/ui/alert";
import { Badge } from "../../components/ui/badge";
import { Plus, Edit, Trash2, Box, Users, Wrench, Search, ChevronLeft, ChevronRight } from "lucide-react";
import { toast } from "react-toastify";
import api from '../../services/api';
import { primaryButtonClasses, secondaryButtonClasses } from '../common/ButtonStyles';
import ResourceForm from './ResourceForm';

const ResourceManagement = ({ projectId }) => {
    const [resources, setResources] = useState([]);
    const [selectedType, setSelectedType] = useState('labor');
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedResource, setSelectedResource] = useState(null);
    const [pagination, setPagination] = useState({
        currentPage: 1,
        perPage: 10,
        totalItems: 0,
        totalPages: 1
    });
    const [filters, setFilters] = useState({
        labor: {
            type: 'all', // internal, subcontractor, temporary
            availability: 'all'
        },
        equipment: {
            type: 'all',
            ownership: 'all'
        },
        material: {
            stock: 'all'
        }
    });

    useEffect(() => {
        fetchResources();
    }, [projectId, selectedType, pagination.currentPage]);

    useEffect(() => {
        // Reset pagination when type or filters change
        if (pagination.currentPage !== 1) {
            setPagination({ ...pagination, currentPage: 1 });
        } else {
            fetchResources();
        }
    }, [filters, searchTerm]);

    const fetchResources = async () => {
        setLoading(true);
        try {
            console.log("Fetching resources with type:", selectedType);
            const response = await api.get(`projects/${projectId}/resources/`, {
                params: {
                    type: selectedType,
                    search: searchTerm,
                    page: pagination.currentPage,
                    page_size: pagination.perPage,
                    // Only include relevant filters
                    ...(selectedType === 'labor' ?
                        {
                            labor_type: filters.labor.type !== 'all' ? filters.labor.type : undefined,
                            availability: filters.labor.availability !== 'all' ? filters.labor.availability : undefined
                        } :
                        selectedType === 'equipment' ?
                            {
                                equipment_type: filters.equipment.type !== 'all' ? filters.equipment.type : undefined,
                                ownership: filters.equipment.ownership !== 'all' ? filters.equipment.ownership : undefined
                            } :
                            { stock: filters.material.stock !== 'all' ? filters.material.stock : undefined })
                }
            });
            console.log("Resources response:", response);
            setResources(response.data.results || []);

            // Update pagination info from response
            setPagination({
                ...pagination,
                totalItems: response.data.count || 0,
                totalPages: Math.ceil((response.data.count || 0) / pagination.perPage)
            });
        } catch (error) {
            console.error("Failed to load resources:", error);
            toast.error("Failed to load resources");
        } finally {
            setLoading(false);
        }
    };

    const handleCreateResource = async (data) => {
        try {
            const response = await api.post(`projects/${projectId}/resources/`, data);
            toast.success("Resource created successfully");
            fetchResources();
        } catch (error) {
            console.error("Error creating resource:", error.response?.data || error);
            toast.error(error.response?.data?.detail || "Failed to create resource");
            throw error;
        }
    };

    const handleUpdateResource = async (data) => {
        try {
            await api.put(`projects/${projectId}/resources/${selectedResource.id}/`, data);
            toast.success("Resource updated successfully");
            setSelectedResource(null);
            fetchResources();
        } catch (error) {
            console.error("Error updating resource:", error.response?.data || error);
            toast.error(error.response?.data?.detail || "Failed to update resource");
            throw error;
        }
    };

    const handleDeleteResource = async (resourceId) => {
        if (window.confirm("Are you sure you want to delete this resource?")) {
            try {
                await api.delete(`projects/${projectId}/resources/${resourceId}/`);
                toast.success("Resource deleted successfully");
                fetchResources();
            } catch (error) {
                console.error("Error deleting resource:", error.response?.data || error);
                toast.error(error.response?.data?.detail || "Failed to delete resource");
            }
        }
    };

    const openEditForm = (resource) => {
        setSelectedResource(resource);
        setIsFormOpen(true);
    };

    const getResourceIcon = (type) => {
        switch (type) {
            case 'labor':
                return <Users className="h-5 w-5" />;
            case 'equipment':
                return <Wrench className="h-5 w-5" />;
            case 'material':
                return <Box className="h-5 w-5" />;
            default:
                return null;
        }
    };

    const renderFilters = () => {
        switch (selectedType) {
            case 'labor':
                return (
                    <div className="flex flex-col sm:flex-row gap-4 mb-4">
                        <Select
                            value={filters.labor.type}
                            onValueChange={(value) => setFilters({ ...filters, labor: { ...filters.labor, type: value } })}
                        >
                            <SelectTrigger className="w-full sm:w-[180px]">
                                <SelectValue placeholder="Filter by type" />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectItem value="all">All Types</SelectItem>
                                <SelectItem value="internal">Internal</SelectItem>
                                <SelectItem value="subcontractor">Subcontractor</SelectItem>
                                <SelectItem value="temporary">Temporary</SelectItem>
                            </SelectContent>
                        </Select>
                        <Select
                            value={filters.labor.availability}
                            onValueChange={(value) => setFilters({ ...filters, labor: { ...filters.labor, availability: value } })}
                        >
                            <SelectTrigger className="w-full sm:w-[180px]">
                                <SelectValue placeholder="Filter by availability" />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectItem value="all">All</SelectItem>
                                <SelectItem value="available">Available</SelectItem>
                                <SelectItem value="unavailable">Unavailable</SelectItem>
                            </SelectContent>
                        </Select>
                    </div>
                );
            case 'equipment':
                return (
                    <div className="flex flex-col sm:flex-row gap-4 mb-4">
                        <Select
                            value={filters.equipment.type}
                            onValueChange={(value) => setFilters({ ...filters, equipment: { ...filters.equipment, type: value } })}
                        >
                            <SelectTrigger className="w-full sm:w-[180px]">
                                <SelectValue placeholder="Filter by type" />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectItem value="all">All Types</SelectItem>
                                <SelectItem value="heavy">Heavy Equipment</SelectItem>
                                <SelectItem value="power_tools">Power Tools</SelectItem>
                                <SelectItem value="vehicles">Vehicles</SelectItem>
                                <SelectItem value="specialized">Specialized</SelectItem>
                            </SelectContent>
                        </Select>
                        <Select
                            value={filters.equipment.ownership}
                            onValueChange={(value) => setFilters({ ...filters, equipment: { ...filters.equipment, ownership: value } })}
                        >
                            <SelectTrigger className="w-full sm:w-[180px]">
                                <SelectValue placeholder="Filter by ownership" />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectItem value="all">All</SelectItem>
                                <SelectItem value="owned">Owned</SelectItem>
                                <SelectItem value="rented">Rented</SelectItem>
                                <SelectItem value="leased">Leased</SelectItem>
                            </SelectContent>
                        </Select>
                    </div>
                );
            case 'material':
                return (
                    <div className="flex flex-col sm:flex-row gap-4 mb-4">
                        <Select
                            value={filters.material.stock}
                            onValueChange={(value) => setFilters({ ...filters, material: { ...filters.material, stock: value } })}
                        >
                            <SelectTrigger className="w-full sm:w-[180px]">
                                <SelectValue placeholder="Filter by stock" />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectItem value="all">All Stock</SelectItem>
                                <SelectItem value="in_stock">In Stock</SelectItem>
                                <SelectItem value="low_stock">Low Stock</SelectItem>
                                <SelectItem value="out_of_stock">Out of Stock</SelectItem>
                            </SelectContent>
                        </Select>
                    </div>
                );
            default:
                return null;
        }
    };

    const ResourceCard = ({ resource }) => {
        const getStatusBadge = () => {
            switch (selectedType) {
                case 'labor':
                    return (
                        <Badge variant={resource.availability ? 'success' : 'secondary'}>
                            {resource.availability ? 'Available' : 'Unavailable'}
                        </Badge>
                    );
                case 'equipment':
                    return (
                        <Badge variant={resource.ownership === 'owned' ? 'default' : 'outline'}>
                            {resource.ownership}
                        </Badge>
                    );
                case 'material':
                    return (
                        <Badge variant={resource.current_stock > resource.reorder_point ? 'success' : 'destructive'}>
                            {resource.current_stock > resource.reorder_point ? 'In Stock' : 'Low Stock'}
                        </Badge>
                    );
                default:
                    return null;
            }
        };

        // Calculate cost display
        const getCostDisplay = () => {
            switch (selectedType) {
                case 'labor':
                    return resource.cost_per_hour ? `$${resource.cost_per_hour}/hr` : 'N/A';
                case 'equipment':
                    return resource.daily_cost ? `$${resource.daily_cost}/day` : 'N/A';
                case 'material':
                    return resource.unit_cost ? `$${resource.unit_cost}/${resource.unit}` : 'N/A';
                default:
                    return null;
            }
        };

        // Show appropriate details
        const getDetailsDisplay = () => {
            switch (selectedType) {
                case 'labor':
                    return (
                        <>
                            <div className="text-sm text-gray-500">{resource.trade_name || 'No trade'} - {resource.labor_type}</div>
                            {resource.contractor_name && <div className="text-xs text-gray-400">Contractor: {resource.contractor_name}</div>}
                        </>
                    );
                case 'equipment':
                    return (
                        <>
                            <div className="text-sm text-gray-500">{resource.equipment_type}</div>
                            {resource.last_maintenance &&
                                <div className="text-xs text-gray-400">
                                    Last maintenance: {new Date(resource.last_maintenance).toLocaleDateString()}
                                </div>
                            }
                        </>
                    );
                case 'material':
                    return (
                        <>
                            <div className="text-sm text-gray-500">{resource.material_type} - {resource.unit}</div>
                            <div className="text-xs text-gray-400">
                                Stock: {resource.current_stock} {resource.unit}
                                {resource.supplier_name && <span> • Supplier: {resource.supplier_name}</span>}
                            </div>
                        </>
                    );
                default:
                    return null;
            }
        };

        return (
            <Card className="mb-4 hover:shadow-md transition-shadow">
                <CardContent className="pt-4 pb-4">
                    <div className="flex flex-col sm:flex-row sm:items-center justify-between">
                        <div className="flex items-start gap-4">
                            <div className={`p-2 rounded-full ${selectedType === 'labor'
                                    ? 'bg-blue-100 text-blue-600'
                                    : selectedType === 'equipment'
                                        ? 'bg-green-100 text-green-600'
                                        : 'bg-amber-100 text-amber-600'
                                }`}>
                                {getResourceIcon(selectedType)}
                            </div>
                            <div className="flex-1">
                                <div className="flex items-baseline justify-between">
                                    <h3 className="font-medium">{resource.name}</h3>
                                    <div className="text-sm font-medium text-gray-700 hidden sm:block">
                                        {getCostDisplay()}
                                    </div>
                                </div>
                                {getDetailsDisplay()}
                                <div className="text-sm font-medium text-gray-700 mt-1 sm:hidden">
                                    {getCostDisplay()}
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center justify-between sm:flex-col sm:items-end gap-2 mt-2 sm:mt-0">
                            {getStatusBadge()}
                            <div className="flex space-x-1 mt-1">
                                <Button variant="ghost" size="sm" onClick={() => openEditForm(resource)}>
                                    <Edit className="h-4 w-4" />
                                </Button>
                                <Button variant="ghost" size="sm" onClick={() => handleDeleteResource(resource.id)}>
                                    <Trash2 className="h-4 w-4" />
                                </Button>
                            </div>
                        </div>
                    </div>
                </CardContent>
            </Card>
        );
    };

    const renderPagination = () => {
        if (pagination.totalPages <= 1) return null;

        return (
            <div className="flex flex-col sm:flex-row justify-between items-center mt-4 gap-2">
                <div className="text-sm text-gray-500 order-2 sm:order-1">
                    Showing {((pagination.currentPage - 1) * pagination.perPage) + 1} to {
                        Math.min(pagination.currentPage * pagination.perPage, pagination.totalItems)
                    } of {pagination.totalItems} resources
                </div>
                <div className="flex gap-1 order-1 sm:order-2">
                    <Button
                        variant="outline"
                        size="sm"
                        disabled={pagination.currentPage === 1}
                        onClick={() => setPagination({ ...pagination, currentPage: pagination.currentPage - 1 })}
                    >
                        <ChevronLeft className="h-4 w-4" />
                        <span className="ml-1">Previous</span>
                    </Button>
                    {pagination.totalPages <= 5 ? (
                        // Show all pages if 5 or fewer
                        Array.from({ length: pagination.totalPages }, (_, i) => (
                            <Button
                                key={i + 1}
                                variant={i + 1 === pagination.currentPage ? "default" : "outline"}
                                size="sm"
                                onClick={() => setPagination({ ...pagination, currentPage: i + 1 })}
                                className="hidden sm:inline-flex"
                            >
                                {i + 1}
                            </Button>
                        ))
                    ) : (
                        // Show current page and surrounding pages
                        <>
                            {pagination.currentPage > 1 && (
                                <Button
                                    variant="outline"
                                    size="sm"
                                    onClick={() => setPagination({ ...pagination, currentPage: 1 })}
                                    className="hidden sm:inline-flex"
                                >
                                    1
                                </Button>
                            )}
                            {pagination.currentPage > 2 && (
                                <span className="px-2 py-1 text-gray-500 hidden sm:inline-flex">...</span>
                            )}
                            {pagination.currentPage > 1 && (
                                <Button
                                    variant="outline"
                                    size="sm"
                                    onClick={() => setPagination({ ...pagination, currentPage: pagination.currentPage - 1 })}
                                    className="hidden sm:inline-flex"
                                >
                                    {pagination.currentPage - 1}
                                </Button>
                            )}
                            <Button
                                variant="default"
                                size="sm"
                                className="hidden sm:inline-flex"
                            >
                                {pagination.currentPage}
                            </Button>
                            {pagination.currentPage < pagination.totalPages && (
                                <Button
                                    variant="outline"
                                    size="sm"
                                    onClick={() => setPagination({ ...pagination, currentPage: pagination.currentPage + 1 })}
                                    className="hidden sm:inline-flex"
                                >
                                    {pagination.currentPage + 1}
                                </Button>
                            )}
                            {pagination.currentPage < pagination.totalPages - 1 && (
                                <span className="px-2 py-1 text-gray-500 hidden sm:inline-flex">...</span>
                            )}
                            {pagination.currentPage < pagination.totalPages && (
                                <Button
                                    variant="outline"
                                    size="sm"
                                    onClick={() => setPagination({ ...pagination, currentPage: pagination.totalPages })}
                                    className="hidden sm:inline-flex"
                                >
                                    {pagination.totalPages}
                                </Button>
                            )}
                        </>
                    )}
                    <Button
                        variant="outline"
                        size="sm"
                        disabled={pagination.currentPage === pagination.totalPages}
                        onClick={() => setPagination({ ...pagination, currentPage: pagination.currentPage + 1 })}
                    >
                        <span className="mr-1">Next</span>
                        <ChevronRight className="h-4 w-4" />
                    </Button>
                </div>
                <div className="flex items-center gap-2 mt-2 sm:mt-0 order-3 w-full sm:w-auto sm:hidden justify-center">
                    <span className="text-sm">Page {pagination.currentPage} of {pagination.totalPages}</span>
                </div>
            </div>
        );
    };

    const handleSearch = (e) => {
        e.preventDefault();
        fetchResources();
    };

    return (
        <div className="space-y-6">
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
                <div className="w-full sm:w-auto">
                    <form onSubmit={handleSearch} className="flex gap-2 w-full">
                        <div className="relative flex-1">
                            <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
                            <Input
                                placeholder="Search resources..."
                                className="pl-8"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                        <Button type="submit">Search</Button>
                    </form>
                </div>
                <Button
                    className={`${primaryButtonClasses} w-full sm:w-auto mt-2 sm:mt-0`}
                    onClick={() => {
                        setSelectedResource(null);
                        setIsFormOpen(true);
                    }}
                >
                    <Plus className="h-4 w-4 mr-2" />
                    Add Resource
                </Button>
            </div>

            <Tabs value={selectedType} onValueChange={setSelectedType}>
                <TabsList className="w-full sm:w-auto grid grid-cols-3">
                    <TabsTrigger value="labor">Labor</TabsTrigger>
                    <TabsTrigger value="equipment">Equipment</TabsTrigger>
                    <TabsTrigger value="material">Material</TabsTrigger>
                </TabsList>

                {renderFilters()}

                {['labor', 'equipment', 'material'].map(type => (
                    <TabsContent key={type} value={type}>
                        {loading ? (
                            <div className="text-center py-10">
                                <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-primary mx-auto"></div>
                                <p className="mt-2">Loading resources...</p>
                            </div>
                        ) : resources.length === 0 ? (
                            <Alert>
                                <AlertDescription>
                                    No {type} resources found. Add some resources to get started.
                                </AlertDescription>
                            </Alert>
                        ) : (
                            <div>
                                {resources.map(resource => (
                                    <ResourceCard
                                        key={resource.id}
                                        resource={resource}
                                    />
                                ))}
                                {renderPagination()}
                            </div>
                        )}
                    </TabsContent>
                ))}
            </Tabs>

            {isFormOpen && (
                <ResourceForm
                    isOpen={isFormOpen}
                    onClose={() => {
                        setIsFormOpen(false);
                        setSelectedResource(null);
                    }}
                    onSubmit={selectedResource ? handleUpdateResource : handleCreateResource}
                    projectId={projectId}
                    resource={selectedResource}
                />
            )}
        </div>
    );
};

export default ResourceManagement;