import React, { useEffect, useState } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '../../components/ui/cardui';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';

// Register ChartJS components
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const RiskStatusTracker = ({ risks }) => {
    const [chartData, setChartData] = useState({
        labels: [],
        datasets: []
    });

    useEffect(() => {
        if (!risks || risks.length === 0) {
            setChartData({
                labels: ['No Data'],
                datasets: [{
                    data: [0],
                    backgroundColor: '#e0e0e0',
                }]
            });
            return;
        }

        // Count risks by status
        const statusCounts = {
            'IDENTIFIED': 0,
            'ASSESSED': 0,
            'MITIGATED': 0,
            'CLOSED': 0,
            'OCCURRED': 0
        };

        risks.forEach(risk => {
            if (statusCounts.hasOwnProperty(risk.status)) {
                statusCounts[risk.status]++;
            }
        });

        // Set chart data
        setChartData({
            labels: ['Identified', 'Assessed', 'Mitigated', 'Closed', 'Occurred'],
            datasets: [{
                label: 'Number of Risks',
                data: [
                    statusCounts.IDENTIFIED,
                    statusCounts.ASSESSED,
                    statusCounts.MITIGATED,
                    statusCounts.CLOSED,
                    statusCounts.OCCURRED
                ],
                backgroundColor: [
                    '#007bff', // Blue for Identified
                    '#6f42c1', // Purple for Assessed
                    '#20c997', // Teal for Mitigated
                    '#6c757d', // Gray for Closed
                    '#dc3545'  // Red for Occurred
                ],
                borderColor: [
                    '#007bff',
                    '#6f42c1',
                    '#20c997',
                    '#6c757d',
                    '#dc3545'
                ],
                borderWidth: 1
            }]
        });
    }, [risks]);

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        const label = context.dataset.label || '';
                        const value = context.raw || 0;
                        return `${label}: ${value}`;
                    }
                }
            }
        },
        scales: {
            y: {
                beginAtZero: true,
                ticks: {
                    precision: 0
                }
            }
        }
    };

    return (
        <Card className="h-full">
            <CardHeader>
                <CardTitle>Risk Status Tracker</CardTitle>
            </CardHeader>
            <CardContent>
                <div className="h-72">
                    <Bar data={chartData} options={options} />
                </div>
            </CardContent>
        </Card>
    );
};

export default RiskStatusTracker;