import React, { useState, useEffect } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { ChevronRight, Home } from 'lucide-react';
import api from '../../services/api';

export const Breadcrumb = () => {
  const location = useLocation();
  const params = useParams();
  const [projectName, setProjectName] = useState('');
  const [estimateName, setEstimateName] = useState('');
  const [budgetName, setBudgetName] = useState('');
  const [userRole, setUserRole] = useState('');
  const pathnames = location.pathname.split('/').filter((x) => x);

  // Determine the current context
  const isEstimates = pathnames.includes('estimates');
  const isProjects = pathnames.includes('projects');
  const isBudgets = pathnames.includes('budgets');
  const isNew = pathnames.includes('new');
  const isDashboard = location.pathname === '/';
  const isApproval = pathnames.includes('approve');
  const isApproveReview = pathnames.includes('approve-review');
  const isConceptual = pathnames.includes('conceptual-estimates');

  const isEstimateDetail = (params.id || params.estimateId || params.conceptualEstimateId) && !isNew;
  const isProjectDetail = params.projectId && !isNew;
  const isBudgetDetail = params.budgetId && !isNew;

  // Get the correct estimate ID based on URL structure
  const getEstimateId = () => {
    if (params.id) return params.id;
    if (isConceptual) return params.conceptualEstimateId;
    return params.estimateId;
  };

  // Fetch necessary data based on current route
  useEffect(() => {
    const fetchData = async () => {
      try {
        // First fetch user role
        const roleResponse = await api.get('users/profile/');
        const role = roleResponse.data.role_name;
        setUserRole(role);
        localStorage.setItem('userRole', role);

        // Fetch project name if needed
        if (params.projectId) {
          const projectResponse = await api.get(`projects/${params.projectId}`);
          setProjectName(projectResponse.data.name);
        }

        // Fetch estimate name if needed
        const estimateId = getEstimateId();
        if (estimateId && !isNew) {
          const estimateEndpoint = isConceptual
            ? `conceptual-estimates/${estimateId}`
            : `estimates/${estimateId}`;
          const estimateResponse = await api.get(estimateEndpoint);
          setEstimateName(estimateResponse.data.name || estimateResponse.data.project_name);
        }

        // Fetch budget name if needed
        if (params.budgetId && !isNew) {
          const budgetResponse = await api.get(`budgets/${params.budgetId}`);
          setBudgetName(budgetResponse.data.name);
        }
      } catch (error) {
        console.error('Error fetching data for breadcrumbs:', error);
      }
    };

    fetchData();
  }, [params.projectId, params.id, params.estimateId, params.conceptualEstimateId, params.budgetId, isNew, isConceptual]);

  // Map of path segments to display names
  const breadcrumbMap = {
    estimates: 'Estimates',
    'conceptual-estimates': 'Conceptual Estimates',
    projects: 'Projects',
    budgets: 'Budgets',
    new: 'Create New',
    'line-items': 'Line Items',
    actuals: 'Actuals',
    'change-orders': 'Change Orders',
    settings: 'Settings',
    approve: 'Approve',
  };

  // Helper function to get the appropriate text for new items
  const getNewItemText = () => {
    if (isEstimates) return 'Create New Estimate';
    if (isBudgets) return 'Create New Budget';
    if (isProjects) return 'Create New Project';
    return 'Create New';
  };

  // Helper function to render a breadcrumb link
  const renderBreadcrumbLink = (to, text, isActive = false) => (
    isActive ? (
      <span className="text-[#344bfc] font-medium">{text}</span>
    ) : (
      <Link
        to={to}
        className="text-gray-600 hover:text-[#344bfc]"
      >
        {text}
      </Link>
    )
  );

  // Helper function to render separator
  const renderSeparator = () => (
    <ChevronRight size={16} className="text-gray-400" />
  );

  const renderBreadcrumbs = () => {
    const breadcrumbs = [];

    // Home link (conditional based on role and current path)
    if (userRole === 'Tenant Admin') {
      if (isDashboard) {
        breadcrumbs.push(
          <span key="dashboard" className="flex items-center text-[#344bfc] font-medium">
            <Home size={16} className="mr-1" />
            Dashboard
          </span>
        );
      } else {
        breadcrumbs.push(
          <Link
            key="dashboard"
            to="/"
            className="flex items-center text-gray-600 hover:text-[#344bfc]"
          >
            <Home size={16} className="mr-1" />
            Dashboard
          </Link>
        );
      }
    } else {
      if (isProjects && pathnames.length === 1) {
        breadcrumbs.push(
          <span key="projects" className="flex items-center text-[#344bfc] font-medium">
            <Home size={16} className="mr-1" />
            Projects
          </span>
        );
      } else {
        breadcrumbs.push(
          <Link
            key="projects"
            to="/projects"
            className="flex items-center text-gray-600 hover:text-[#344bfc]"
          >
            <Home size={16} className="mr-1" />
            Projects
          </Link>
        );
      }
    }

    // For non-home pages, add separator and subsequent items
    if (pathnames.length > 0 && !(isProjects && pathnames.length === 1)) {
      breadcrumbs.push(renderSeparator());
    }

    // Estimates section (including conceptual estimates)
    if (isEstimates || isConceptual) {
      const baseLink = isConceptual ? '/conceptual-estimates' : '/estimates';
      const baseName = isConceptual ? 'Conceptual Estimates' : 'Estimates';
      const estimateId = getEstimateId();

      breadcrumbs.push(renderBreadcrumbLink(baseLink, baseName, pathnames.length === 1));

      if (isNew) {
        breadcrumbs.push(renderSeparator());
        breadcrumbs.push(renderBreadcrumbLink(null, `Create New ${isConceptual ? 'Conceptual ' : ''}Estimate`, true));
      } else if (isEstimateDetail) {
        breadcrumbs.push(renderSeparator());
        if (isApproval || isApproveReview) {
          breadcrumbs.push(renderBreadcrumbLink(
            `${baseLink}/${estimateId}`,
            estimateName || 'Loading...',
            false
          ));
          breadcrumbs.push(renderSeparator());
          breadcrumbs.push(renderBreadcrumbLink(null, 'Approve', true));
        } else {
          breadcrumbs.push(renderBreadcrumbLink(
            `${baseLink}/${estimateId}`,
            estimateName || 'Loading...',
            true
          ));
        }
      }
    }

    // Projects section
    if (isProjects && pathnames.length > 1) {
      if (isProjectDetail) {
        breadcrumbs.push(renderBreadcrumbLink(
          `/projects/${params.projectId}`,
          projectName || 'Loading...',
          !pathnames.includes('settings') && !isBudgets
        ));

        if (pathnames.includes('settings')) {
          breadcrumbs.push(renderSeparator());
          breadcrumbs.push(renderBreadcrumbLink(null, 'Settings', true));
        }

        if (isBudgets) {
          breadcrumbs.push(renderSeparator());
          breadcrumbs.push(renderBreadcrumbLink(
            `/projects/${params.projectId}/budgets`,
            'Budgets',
            pathnames.length === 3
          ));

          if (isNew) {
            breadcrumbs.push(renderSeparator());
            breadcrumbs.push(renderBreadcrumbLink(null, 'Create New Budget', true));
          } else if (isBudgetDetail) {
            breadcrumbs.push(renderSeparator());
            breadcrumbs.push(renderBreadcrumbLink(null, budgetName || 'Loading...', true));

            const subPages = ['line-items', 'actuals', 'change-orders'];
            const currentSubPage = pathnames[pathnames.length - 1];
            if (subPages.includes(currentSubPage)) {
              breadcrumbs.push(renderSeparator());
              breadcrumbs.push(renderBreadcrumbLink(
                null,
                breadcrumbMap[currentSubPage],
                true
              ));
            }
          }
        }
      }
    }

    return breadcrumbs;
  };

  return (
    <nav className="flex items-center space-x-2 text-sm mb-6">
      {renderBreadcrumbs()}
    </nav>
  );
};

export default Breadcrumb;