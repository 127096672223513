import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "../../components/ui/dialog";
import { Input } from "../../components/ui/input";
import { Label } from "../../components/ui/label";
import { Button } from "../../components/ui/button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../components/ui/select";
import { XMarkIcon, PaperClipIcon, ArrowRightIcon } from '@heroicons/react/24/outline';
import { toast } from 'react-toastify';
import api from '../../services/api';

const NewCommunicationForm = ({ isOpen, setIsOpen, activeProject }) => {
    const [formData, setFormData] = useState({
        subject: '',
        content: '',
        communication_type: 'general',
        receivers: [],
        priority: 'medium',
        send_email: true
    });

    const [assignedUserDetails, setAssignedUserDetails] = useState([]);
    const [attachments, setAttachments] = useState([]);
    const [submitting, setSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (isOpen && activeProject) {
            fetchAssignedUsers();
        }
    }, [isOpen, activeProject]);

    // Add this debugging code to see what's happening
    useEffect(() => {
        if (formData.receivers.length > 0) {
            console.log("Selected receivers:", formData.receivers);
            console.log("Available users:", assignedUserDetails);

            // Check if the selected IDs match any users
            formData.receivers.forEach(id => {
                const found = assignedUserDetails.find(u => u.id === id);
                console.log(`User with ID ${id} found?`, found ? "Yes" : "No");
            });
        }
    }, [formData.receivers, assignedUserDetails]);



    const fetchAssignedUsers = async () => {
        setIsLoading(true);
        try {
            const tenantIdentifier = localStorage.getItem('tenantIdentifier');
            const response = await api.get(`projects/${activeProject.id}/users/`, {
                headers: {
                    'X-Tenant-ID': tenantIdentifier
                }
            });

            // Store the full user objects with proper null checks
            const userDetails = Array.isArray(response.data) ? response.data.map(user => ({
                id: user.id,
                first_name: user.first_name || '',
                last_name: user.last_name || '',
                email: user.email || ''
            })) : [];

            setAssignedUserDetails(userDetails);
        } catch (error) {
            console.error('Error fetching project users:', error);
            toast.error('Could not load project users');
            setAssignedUserDetails([]);
        } finally {
            setIsLoading(false);
        }
    };

    const handleChange = (field, value) => {
        setFormData({ ...formData, [field]: value });
    };

    const handleReceiversChange = (selectedIds) => {
        console.log("Selected ID type:", typeof selectedIds);

        const receiverIds = Array.isArray(selectedIds) ? selectedIds : [selectedIds];
        setFormData({ ...formData, receivers: receiverIds });
    };

    const handleFileChange = (e) => {
        const files = Array.from(e.target.files);
        setAttachments(prev => [...prev, ...files]);
    };

    const removeAttachment = (index) => {
        setAttachments(prev => prev.filter((_, i) => i !== index));
    };

    // In your handleSubmit method in NewCommunicationForm.js
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!formData.subject || !formData.content || formData.receivers.length === 0) {
            toast.error('Please fill all required fields');
            return;
        }

        setSubmitting(true);

        try {
            const submitData = new FormData();

            submitData.append('subject', formData.subject);
            submitData.append('content', formData.content);
            submitData.append('communication_type', formData.communication_type);
            submitData.append('priority', formData.priority);
            submitData.append('send_email', formData.send_email);

            // Make sure receivers are integers
            const cleanReceivers = formData.receivers.map(id => {
                if (typeof id === 'string') return parseInt(id, 10);
                return id;
            });

            // Add receivers as plain integers array 
            submitData.append('receivers', JSON.stringify(cleanReceivers));

            // Add files
            attachments.forEach(file => {
                submitData.append('attachments', file);
            });

            // Make sure to include tenant header
            const tenantIdentifier = localStorage.getItem('tenantIdentifier');

            await api.post(
                `projects/${activeProject.id}/communications/create/`,
                submitData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'X-Tenant-ID': tenantIdentifier
                    }
                }
            );

            toast.success('Communication sent successfully!');
            setIsOpen(false);

            // Reset form
            setFormData({
                subject: '',
                content: '',
                communication_type: 'general',
                receivers: [],
                priority: 'medium',
                send_email: true
            });
            setAttachments([]);

        } catch (error) {
            console.error('Error sending communication:', error);
            const errorMessage = error.response?.data?.detail || 'Failed to send communication';
            toast.error(errorMessage);
        } finally {
            setSubmitting(false);
        }
    };



    const getSelectedUserNames = () => {
        if (!formData.receivers.length) return "No recipients selected";

        return formData.receivers.map(id => {

            const user = assignedUserDetails.find(u => String(u.id) === String(id));
            return user ? `${user.first_name} ${user.last_name}` : `User #${id}`;
        }).join(", ");
    };

    return (
        <Dialog open={isOpen} onOpenChange={setIsOpen}>
            <DialogContent className="sm:max-w-md md:max-w-2xl max-h-[95vh] overflow-hidden flex flex-col">
                <DialogHeader>
                    <DialogTitle>New Communication</DialogTitle>
                </DialogHeader>

                <form onSubmit={handleSubmit} className="overflow-y-auto flex-1 p-4">
                    <div className="space-y-4">
                        {/* Mobile-responsive grid */}
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <div className="space-y-2">
                                <Label htmlFor="subject">Subject</Label>
                                <Input
                                    id="subject"
                                    value={formData.subject}
                                    onChange={(e) => handleChange('subject', e.target.value)}
                                    required
                                />
                            </div>

                            <div className="space-y-2">
                                <Label htmlFor="communication_type">Type</Label>
                                <Select
                                    value={formData.communication_type}
                                    onValueChange={(value) => handleChange('communication_type', value)}
                                >
                                    <SelectTrigger>
                                        <SelectValue placeholder="Select type" />
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectItem value="general">General</SelectItem>
                                        <SelectItem value="rfi">RFI</SelectItem>
                                        <SelectItem value="change_order">Change Order</SelectItem>
                                        <SelectItem value="issue">Issue</SelectItem>
                                    </SelectContent>
                                </Select>
                            </div>
                        </div>

                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <div className="space-y-2">
                                <Label htmlFor="priority">Priority</Label>
                                <Select
                                    value={formData.priority}
                                    onValueChange={(value) => handleChange('priority', value)}
                                >
                                    <SelectTrigger>
                                        <SelectValue placeholder="Select priority" />
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectItem value="low">Low</SelectItem>
                                        <SelectItem value="medium">Medium</SelectItem>
                                        <SelectItem value="high">High</SelectItem>
                                        <SelectItem value="critical">Critical</SelectItem>
                                    </SelectContent>
                                </Select>
                            </div>

                            <div className="space-y-2">
                                <Label htmlFor="receivers">Recipients</Label>
                                <Select
                                    onValueChange={handleReceiversChange}
                                >
                                    <SelectTrigger className="w-full">
                                        <SelectValue placeholder="Select recipients" />
                                    </SelectTrigger>
                                    <SelectContent className="max-h-60">
                                        {isLoading ? (
                                            <div className="p-2 text-center">
                                                <div className="inline-block h-4 w-4 animate-spin rounded-full border-2 border-solid border-blue-600 border-r-transparent"></div>
                                                <span className="ml-2">Loading users...</span>
                                            </div>
                                        ) : assignedUserDetails.length > 0 ? (
                                            assignedUserDetails.map(user => (
                                                <SelectItem key={user.id} value={user.id} className="flex items-center py-2">
                                                    <div>
                                                        <span className="font-medium">{user.first_name} {user.last_name}</span>
                                                        <span className="text-gray-500 ml-1">({user.email})</span>
                                                    </div>
                                                </SelectItem>
                                            ))
                                        ) : (
                                            <div className="p-2 text-center text-gray-500">No users assigned to this project</div>
                                        )}

                                        {/* Add link to project settings */}
                                        <div className="p-2 border-t mt-2">
                                            <a
                                                href={`/projects/${activeProject?.id}/settings`}
                                                className="flex items-center text-blue-600 hover:text-blue-800"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <ArrowRightIcon className="h-4 w-4 mr-1" />
                                                Add users to project
                                            </a>
                                        </div>
                                    </SelectContent>
                                </Select>
                                <div className="mt-1 text-sm text-gray-700">
                                    {formData.receivers.length > 0 ? (
                                        <>
                                            <span className="font-medium">Selected:</span> {getSelectedUserNames()}
                                        </>
                                    ) : (
                                        <span className="text-amber-600">No recipients selected</span>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="space-y-2">
                            <Label htmlFor="content">Message</Label>
                            <textarea
                                id="content"
                                rows={6}
                                className="w-full p-2 border rounded"
                                value={formData.content}
                                onChange={(e) => handleChange('content', e.target.value)}
                                required
                            />
                        </div>

                        {/* File attachments section */}
                        <div className="space-y-2">
                            <Label>Attachments</Label>
                            <div className="border rounded p-3 bg-gray-50">
                                <div className="flex items-center">
                                    <label className="cursor-pointer bg-white px-3 py-1.5 text-sm border border-gray-300 rounded hover:bg-gray-100">
                                        <PaperClipIcon className="h-4 w-4 inline mr-1" />
                                        Attach Files
                                        <input
                                            type="file"
                                            className="hidden"
                                            multiple
                                            onChange={handleFileChange}
                                        />
                                    </label>
                                    <span className="ml-2 text-xs text-gray-500">
                                        {attachments.length} file(s) selected
                                    </span>
                                </div>

                                {attachments.length > 0 && (
                                    <div className="mt-3 space-y-2 max-h-32 overflow-y-auto p-2">
                                        {attachments.map((file, index) => (
                                            <div key={index} className="flex items-center justify-between bg-white p-2 rounded border text-sm">
                                                <div className="truncate flex-1">{file.name}</div>
                                                <button
                                                    type="button"
                                                    onClick={() => removeAttachment(index)}
                                                    className="ml-2 text-gray-500 hover:text-red-500"
                                                >
                                                    <XMarkIcon className="h-4 w-4" />
                                                </button>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </form>

                <DialogFooter className="px-4 py-3 border-t">
                    <Button
                        type="button"
                        variant="outline"
                        onClick={() => setIsOpen(false)}
                        disabled={submitting}
                    >
                        Cancel
                    </Button>
                    <Button
                        type="submit"
                        onClick={handleSubmit}
                        disabled={submitting}
                        className="bg-blue-600 hover:bg-blue-700 text-white"
                    >
                        {submitting ? 'Sending...' : 'Send Communication'}
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};

export default NewCommunicationForm;