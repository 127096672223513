import React, { useState, useEffect } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { UserCircleIcon } from '@heroicons/react/24/outline';
import { useNavigate, Link } from 'react-router-dom';
import secureLocalStorage from "react-secure-storage";
import api from '../services/api';
import { showToast } from '../utils/toast';
import TrialBanner from '../components/TrialBanner'

function Header() {
  const [user, setUser] = useState({ first_name: 'User' });
  const [tenants, setTenants] = useState([]);
  const [currentTenant, setCurrentTenant] = useState(null);
  const navigate = useNavigate();
  const [subscription, setSubscription] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const storedTenantId = secureLocalStorage.getItem('currentTenantId');
    const token = localStorage.getItem('token');

    // Set up all headers at once
    if (token) {
      api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }
    if (storedTenantId) {
      api.defaults.headers.common['X-Tenant-ID'] = storedTenantId;
    }



    fetchUserData();
    fetchTenantData();
    fetchSubscriptionData();
  }, []);

  const fetchSubscriptionData = async () => {
    try {
      const tenantId = secureLocalStorage.getItem('currentTenantId');
      // Set tenant ID in headers before making the request
      if (tenantId) {
        api.defaults.headers.common['X-Tenant-ID'] = tenantId;
      }
      const response = await api.get('subscription-debug/');

      setSubscription(response.data.current_subscription);
    } catch (err) {
      console.error('Failed to fetch subscription data:', err);
    }
  };

  useEffect(() => {
    const storedTenantId = secureLocalStorage.getItem('currentTenantId');
    // Set tenant ID in headers at component mount
    if (storedTenantId) {
      api.defaults.headers.common['X-Tenant-ID'] = storedTenantId;
    }
    fetchUserData();
    fetchTenantData(storedTenantId);
    fetchSubscriptionData();
  }, []);



  const fetchUserData = async () => {
    try {
      const response = await api.get('users/profile/');
      setUser(response.data);
    } catch (err) {
      console.error('Failed to fetch user data', err);
    }
  };



  const handleTrialCancel = async () => {
    if (isLoading) return;

    try {
      setIsLoading(true);
      // Get current tenant ID from storage
      const tenantId = secureLocalStorage.getItem('currentTenantId');

      // Important: Set the header before ANY API calls
      if (!tenantId) {
        throw new Error('No tenant ID found');
      }

      // Ensure header is set for all subsequent requests
      api.defaults.headers.common['X-Tenant-ID'] = tenantId;

      // Now make the API calls
      const subResponse = await api.get('subscriptions/current/');
      if (!subResponse.data?.current_subscription?.id) {
        throw new Error('No active subscription found');
      }

      const subscriptionId = subResponse.data.current_subscription.id;

      // Make sure to include tenant ID in headers for cancel request
      await api.post(`subscriptions/${subscriptionId}/cancel/`, {
        at_period_end: true
      }, {
        headers: {
          'X-Tenant-ID': tenantId
        }
      });

      showToast.success('Trial successfully cancelled');
      await fetchSubscriptionData();
    } catch (error) {
      console.error('Cancellation failed:', error?.response?.data || error);
      showToast.error(error?.response?.data?.error || 'Failed to cancel trial. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleTrialReactivate = async () => {
    if (isLoading) return;

    try {
      setIsLoading(true);
      const subResponse = await api.get('subscriptions/current/');
      const subscriptionId = subResponse.data.current_subscription.id;

      await api.post(`subscriptions/${subscriptionId}/reactivate/`);
      showToast.success('Trial successfully reactivated');
      await fetchSubscriptionData();
    } catch (error) {
      console.error('Reactivation failed:', error);
      showToast.error('Failed to reactivate trial. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchTenantData = async () => {
    try {


      // Get token
      const token = localStorage.getItem('token');
      if (token) {
        api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      }

      // Use regular api call instead of absolute URL
      const response = await api.get('user/tenants/');
      const tenantsData = response.data;

      setTenants(tenantsData);

      // Handle single tenant scenario
      if (tenantsData.length === 1) {
        const singleTenant = tenantsData[0];
        setCurrentTenant(singleTenant);
        await handleSingleTenantSetup(singleTenant);
      } else if (tenantsData.length > 1) {
        const storedTenantId = secureLocalStorage.getItem('currentTenantId');
        if (storedTenantId) {

          const storedTenant = tenantsData.find(t => t.tenant_id === storedTenantId);
          if (storedTenant) {
            setCurrentTenant(storedTenant);
            await handleSingleTenantSetup(storedTenant); // Ensure headers are set
          } else {
            setCurrentTenant(tenantsData[0]);
            await handleSingleTenantSetup(tenantsData[0]);
          }
        } else {
          setCurrentTenant(tenantsData[0]);
          await handleSingleTenantSetup(tenantsData[0]);
        }
      }
    } catch (err) {
      console.error('Failed to fetch tenants', err);
      console.error('Error details:', {
        message: err.message,
        response: err.response,
        headers: api.defaults.headers
      });
    }
  };

  const handleTenantSwitch = async (tenant) => {
    // Don't do anything if it's the same tenant
    if (currentTenant?.tenant_id === tenant.tenant_id) {
      return;
    }

    try {
      // Update API headers
      api.defaults.headers.common['X-Tenant-ID'] = tenant.tenant_id;

      // Make the switch request
      const response = await api.post('user/switch-tenant/', {
        tenant_id: tenant.tenant_id
      });

      // Update both storage locations
      secureLocalStorage.setItem('currentTenantId', tenant.tenant_id);
      localStorage.setItem('tenantIdentifier', tenant.tenant_id); // Add this line

      setCurrentTenant(tenant);

      // Only show success message for manual tenant switches
      if (tenants.length > 1) {
        showToast.success(response.data.message || `Switched to ${tenant.tenant_name}`);
      }

      // Reload if on dashboard
      if (window.location.pathname === '/') {
        window.location.reload();
      }
    } catch (err) {
      console.error('Tenant switch error:', err);
      if (tenants.length > 1) {
        showToast.error('Failed to switch tenant. Please try again.');
      }

      // Rollback on error
      const previousTenantId = secureLocalStorage.getItem('currentTenantId');
      if (previousTenantId) {
        api.defaults.headers.common['X-Tenant-ID'] = previousTenantId;
        localStorage.setItem('tenantIdentifier', previousTenantId); // Add this line
        const previousTenant = tenants.find(t => t.tenant_id === previousTenantId);
        if (previousTenant) {
          setCurrentTenant(previousTenant);
        }
      }
    }
  };

  const handleSingleTenantSetup = async (tenant) => {
    try {
      // Set up API headers
      api.defaults.headers.common['X-Tenant-ID'] = tenant.tenant_id;

      // Store in both storage locations
      secureLocalStorage.setItem('currentTenantId', tenant.tenant_id);
      localStorage.setItem('tenantIdentifier', tenant.tenant_id); // Add this line

      // Quietly make the switch-tenant call without showing any messages
      await api.post('user/switch-tenant/', {
        tenant_id: tenant.tenant_id
      });
    } catch (err) {
      // For single tenant, we'll handle errors silently
      console.error('Single tenant setup error:', err);
    }
  };

  const handleChoosePlan = () => {
    navigate('/subscription');
  };


  const handleLogout = () => {
    // Clear all stored data including tenant information
    secureLocalStorage.clear();
    localStorage.removeItem('tenantIdentifier');
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    // Remove tenant header from API client
    delete api.defaults.headers.common['X-Tenant-ID'];
    showToast.info('You are logged out successfully');
    navigate('/login');
  };

  return (
    <div className="flex flex-col">
      {subscription &&
        ['Tenant Admin', 'Tenant Admin Project', 'Tenant Admin Safety', 'Tenant Admin Bid'].includes(user.role_name) &&
        (subscription.status === 'trialing' || subscription.status === 'canceled') &&
        subscription.trial_end_date &&
        new Date(subscription.trial_end_date) > new Date() && (
          <TrialBanner
            daysRemaining={subscription.remaining_trial_days}
            onCancel={handleTrialCancel}
            onReactivate={handleTrialReactivate}
            onChoosePlan={handleChoosePlan}
            status={subscription.status}
            trialEndDate={subscription.trial_end_date}
            isLoading={isLoading}
          />
        )}
      <header className="bg-white shadow-lg">
        <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8 flex justify-between items-center">
          <h1 className="text-2xl font-bold text-midnight-blue">Hi {user.first_name}</h1>

          <div className="flex items-center">
            {tenants.length > 1 ? (
              <Menu as="div" className="relative mr-4">
                <Menu.Button className="flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-brand-blue">
                  {currentTenant ? currentTenant.tenant_name : 'Select Tenant'}
                </Menu.Button>
                <Transition
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {tenants.map((tenant) => (
                      <Menu.Item key={tenant.tenant_id}>
                        {({ active }) => (
                          <button
                            onClick={() => handleTenantSwitch(tenant)}
                            className={`${active ? 'bg-gray-100' : ''
                              } block w-full text-left px-4 py-2 text-sm text-gray-700 ${currentTenant?.tenant_id === tenant.tenant_id ? 'font-semibold' : ''
                              }`}
                          >
                            {tenant.tenant_name}
                          </button>
                        )}
                      </Menu.Item>
                    ))}
                  </Menu.Items>
                </Transition>
              </Menu>
            ) : (
              <span className="text-sm text-gray-600">{currentTenant?.tenant_name || 'Tenant not assigned'}</span>
            )}

            <Menu as="div" className="relative">
              <Menu.Button className="flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-brand-blue">
                <UserCircleIcon className="h-8 w-8 text-gray-400" />
              </Menu.Button>
              <Transition
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        to="/settings"
                        className={`${active ? 'bg-gray-100' : ''} block px-4 py-2 text-sm text-gray-700`}
                      >
                        Settings
                      </Link>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={handleLogout}
                        className={`${active ? 'bg-gray-100' : ''} block w-full text-left px-4 py-2 text-sm text-gray-700`}
                      >
                        Logout
                      </button>
                    )}
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </header>
    </div>

  );
}

export default Header;