import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { format } from 'date-fns';
import {
    AlertCircle, CheckCircle2, Clock, XCircle,
    Plus, Filter, Search, ArrowUpDown, X, AlertTriangle, Eye, PieChart
} from 'lucide-react';
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
} from "../ui/dialog";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "../ui/table";
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
} from "../ui/cardui";
import { Button } from "../ui/button";
import { Badge } from "../ui/badge";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "../ui/select";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "../ui/tabs";
import { showToast } from '../../utils/toast';
import api from '../../services/api';
import { primaryButtonClasses, secondaryButtonClasses } from '../common/ButtonStyles';
import secureLocalStorage from "react-secure-storage";
import QualityIssueDetails from './Defects/QualityIssueDetails';

const QualityIssuesManager = ({ projectId }) => {
    const [issues, setIssues] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isAddingIssue, setIsAddingIssue] = useState(false);
    const [isResolveModalOpen, setIsResolveModalOpen] = useState(false);
    const [selectedIssue, setSelectedIssue] = useState(null);
    const [inspections, setInspections] = useState([]);
    const [isLoadingInspections, setIsLoadingInspections] = useState(true);
    const [filters, setFilters] = useState({
        severity: 'all',
        status: 'all',
        searchTerm: ''
    });
    const [metrics, setMetrics] = useState(null);
    const [activeTab, setActiveTab] = useState('issues');
    const [isViewModalOpen, setIsViewModalOpen] = useState(false);

    useEffect(() => {
        // Check projectId validity inside the effect
        if (!projectId || projectId === 'undefined' || projectId === 'null') {
            setIsLoading(false);
            return;
        }
        fetchProjectIssues();
        fetchInspections();
        fetchMetrics();
    }, [projectId]);

    const getConfig = () => {
        const tenantId = localStorage.getItem('tenantIdentifier') ||
            secureLocalStorage.getItem('currentTenantId');

        return {
            headers: {
                'X-Tenant-ID': tenantId || '',
                'Content-Type': 'application/json'
            }
        };
    };

    const fetchInspections = async () => {
        try {
            // Check if projectId is valid before making the API call
            if (!projectId || projectId === 'undefined' || projectId === 'null') {
                console.warn('Invalid projectId:', projectId);
                setInspections([]);
                setIsLoadingInspections(false);
                return;
            }

            const response = await api.get(`projects/${projectId}/inspections/`, getConfig());

            // Handle different response formats
            let inspectionsData = [];
            if (response.data && Array.isArray(response.data)) {
                inspectionsData = response.data;
            } else if (response.data && response.data.results && Array.isArray(response.data.results)) {
                inspectionsData = response.data.results;
            }

            setInspections(inspectionsData);
            setIsLoadingInspections(false);
        } catch (error) {
            console.error('Failed to fetch inspections:', error);
            setInspections([]);
            setIsLoadingInspections(false);
        }
    };

    const fetchProjectIssues = async () => {
        try {
            setIsLoading(true);
            // Check if projectId is valid before making the API call
            if (!projectId || projectId === 'undefined' || projectId === 'null') {
                console.warn('Invalid projectId:', projectId);
                setIssues([]);
                setIsLoading(false);
                return;
            }

            const response = await api.get(`projects/${projectId}/quality-issues/`, getConfig());

            // Handle different response formats
            let issuesData = [];
            if (response.data && Array.isArray(response.data)) {
                issuesData = response.data;
            } else if (response.data && response.data.results && Array.isArray(response.data.results)) {
                issuesData = response.data.results;
            }

            setIssues(issuesData);
            setIsLoading(false);
        } catch (error) {
            console.error('Failed to fetch issues:', error);
            setIssues([]);
            setIsLoading(false);
        }
    };

    const fetchMetrics = async () => {
        try {
            if (!projectId || projectId === 'undefined' || projectId === 'null') {
                return;
            }

            const response = await api.get(`projects/${projectId}/quality-issues/dashboard_metrics/`, getConfig());
            let metricsData = response.data || response;

            setMetrics(metricsData);
        } catch (error) {
            console.error('Failed to fetch metrics:', error);
        }
    };

    const handleAddIssue = async (formData) => {
        try {
            // Get tenant_id from localStorage
            const tenantId = localStorage.getItem('tenantIdentifier') ||
                secureLocalStorage.getItem('currentTenantId');

            const data = {
                ...formData,
                tenant_id: tenantId  // Add tenant_id to the form data
            };

            const response = await api.post(`projects/${projectId}/quality-issues/`, data, getConfig());

            if (response) {
                fetchProjectIssues();
                fetchMetrics();
                setIsAddingIssue(false);
                showToast.success("Defect entered successfully");
            }
        } catch (error) {
            console.error('Failed to add issue:', error);
            showToast.error("Failed to add defect. Please try again.");
        }
    };

    const handleResolveIssue = async (formData) => {
        try {
            if (!selectedIssue) return;

            const response = await api.post(
                `projects/${projectId}/quality-issues/${selectedIssue.id}/resolve/`,
                formData,
                getConfig()
            );

            if (response) {
                fetchProjectIssues();
                fetchMetrics();
                setIsResolveModalOpen(false);
                setSelectedIssue(null);
                showToast.success("Issue resolved successfully");
            }
        } catch (error) {
            console.error('Failed to resolve issue:', error);
            showToast.error("Failed to resolve issue. Please try again.");
        }
    };

    const handleVerifyIssue = async (issueId) => {
        try {
            const response = await api.post(
                `projects/${projectId}/quality-issues/${issueId}/verify/`,
                {},
                getConfig()
            );

            if (response) {
                fetchProjectIssues();
                fetchMetrics();
                showToast.success("Issue verified successfully");
            }
        } catch (error) {
            console.error('Failed to verify issue:', error);
            showToast.error("Failed to verify issue. Please try again.");
        }
    };

    const filteredIssues = issues.filter(issue => {
        return (
            // Update these conditions
            (filters.severity === 'all' || issue.severity === filters.severity) &&
            (filters.status === 'all' || issue.status === filters.status) &&
            (!filters.searchTerm ||
                issue.description.toLowerCase().includes(filters.searchTerm.toLowerCase()) ||
                issue.remediation_plan?.toLowerCase().includes(filters.searchTerm.toLowerCase())
            )
        );
    });

    const getStatusBadgeColor = (status) => {
        switch (status) {
            case 'identified':
                return 'bg-blue-100 text-blue-800';
            case 'under_review':
                return 'bg-purple-100 text-purple-800';
            case 'remediation_planned':
                return 'bg-yellow-100 text-yellow-800';
            case 'in_remediation':
                return 'bg-orange-100 text-orange-800';
            case 'resolved':
                return 'bg-green-100 text-green-800';
            case 'verified':
                return 'bg-emerald-100 text-emerald-800';
            default:
                return 'bg-gray-100 text-gray-800';
        }
    };

    const getSeverityBadgeColor = (severity) => {
        switch (severity) {
            case 'critical':
                return 'bg-red-100 text-red-800';
            case 'high':
                return 'bg-orange-100 text-orange-800';
            case 'medium':
                return 'bg-yellow-100 text-yellow-800';
            case 'low':
                return 'bg-green-100 text-green-800';
            default:
                return 'bg-gray-100 text-gray-800';
        }
    };

    const IssuesList = () => (
        <div className="space-y-4">
            <div className="flex flex-wrap gap-4 items-center">
                <div className="flex items-center space-x-2">
                    <Search className="w-4 h-4 text-gray-500" />
                    <Input
                        type="text"
                        placeholder="Search issues..."
                        className="w-64"
                        value={filters.searchTerm}
                        onChange={(e) => setFilters(prev => ({ ...prev, searchTerm: e.target.value }))}
                    />
                </div>
                <Select
                    value={filters.severity}
                    onValueChange={(value) => setFilters(prev => ({ ...prev, severity: value }))}
                >
                    <SelectTrigger className="w-40">
                        <SelectValue placeholder="Severity" />
                    </SelectTrigger>
                    <SelectContent>
                        <SelectItem value="all">All Severities</SelectItem>
                        <SelectItem value="low">Low</SelectItem>
                        <SelectItem value="medium">Medium</SelectItem>
                        <SelectItem value="high">High</SelectItem>
                        <SelectItem value="critical">Critical</SelectItem>
                    </SelectContent>
                </Select>
                <Select
                    value={filters.status}
                    onValueChange={(value) => setFilters(prev => ({ ...prev, status: value }))}
                >
                    <SelectTrigger className="w-40">
                        <SelectValue placeholder="Status" />
                    </SelectTrigger>
                    <SelectContent>
                        <SelectItem value="all">All Statuses</SelectItem>
                        <SelectItem value="identified">Identified</SelectItem>
                        <SelectItem value="under_review">Under Review</SelectItem>
                        <SelectItem value="remediation_planned">Remediation Planned</SelectItem>
                        <SelectItem value="in_remediation">In Remediation</SelectItem>
                        <SelectItem value="resolved">Resolved</SelectItem>
                        <SelectItem value="verified">Verified</SelectItem>
                    </SelectContent>
                </Select>
                <Button
                    onClick={() => setIsAddingIssue(true)}
                    className={primaryButtonClasses}
                >
                    <Plus className="w-4 h-4 mr-2" />
                    Add Issue
                </Button>
            </div>

            <div className="rounded-md border">
                <Table>
                    <TableHeader>
                        <TableRow>
                            <TableHead className="w-[100px]">Severity</TableHead>
                            <TableHead>Description</TableHead>
                            <TableHead>Status</TableHead>
                            <TableHead>Identified Date</TableHead>
                            <TableHead>Deadline</TableHead>
                            <TableHead className="text-right">Actions</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {filteredIssues.length === 0 ? (
                            <TableRow>
                                <TableCell colSpan={6} className="text-center py-6">
                                    <p className="text-gray-500">No quality issues found matching your criteria.</p>
                                    <Button
                                        onClick={() => setIsAddingIssue(true)}
                                        className="mt-4"
                                        variant="outline"
                                    >
                                        <Plus className="w-4 h-4 mr-2" />
                                        Add Issue
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ) : (
                            filteredIssues.map((issue) => (
                                <TableRow key={issue.id}>
                                    <TableCell>
                                        <Badge className={getSeverityBadgeColor(issue.severity)}>
                                            {issue.severity_display}
                                        </Badge>
                                    </TableCell>
                                    <TableCell className="font-medium max-w-xs truncate" title={issue.description}>
                                        {issue.description}
                                    </TableCell>
                                    <TableCell>
                                        <Badge className={getStatusBadgeColor(issue.status)}>
                                            {issue.status_display}
                                        </Badge>
                                    </TableCell>
                                    <TableCell>{format(new Date(issue.identified_date), 'MMM d, yyyy')}</TableCell>
                                    <TableCell>
                                        {issue.remediation_deadline ?
                                            format(new Date(issue.remediation_deadline), 'MMM d, yyyy') :
                                            'Not set'}
                                    </TableCell>
                                    <TableCell className="text-right">
                                        <div className="flex justify-end space-x-2">
                                            {issue.status === 'resolved' && (
                                                <Button
                                                    variant="outline"
                                                    size="sm"
                                                    onClick={() => handleVerifyIssue(issue.id)}
                                                    title="Verify resolution"
                                                >
                                                    <CheckCircle2 className="w-4 h-4 mr-1" />
                                                    Verify
                                                </Button>
                                            )}
                                            {issue.status !== 'resolved' && issue.status !== 'verified' && (
                                                <Button
                                                    variant="outline"
                                                    size="sm"
                                                    onClick={() => {
                                                        setSelectedIssue(issue);
                                                        setIsResolveModalOpen(true);
                                                    }}
                                                    title="Mark as resolved"
                                                >
                                                    <CheckCircle2 className="w-4 h-4 mr-1" />
                                                    Resolve
                                                </Button>
                                            )}
                                            <Button
                                                variant="ghost"
                                                size="sm"
                                                onClick={() => {
                                                    setSelectedIssue(issue);
                                                    setIsViewModalOpen(true);
                                                }}
                                                title="View details"
                                            >
                                                <Eye className="w-4 h-4" />
                                            </Button>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
                <Dialog open={isViewModalOpen} onOpenChange={setIsViewModalOpen}>
                    <DialogContent className="sm:max-w-[800px]">
                        <DialogHeader>
                            <DialogTitle>Issue Details</DialogTitle>
                        </DialogHeader>
                        {selectedIssue && (
                            <QualityIssueDetails
                                issueId={selectedIssue.id}
                                projectId={projectId}
                                onClose={() => {
                                    setIsViewModalOpen(false);
                                    setSelectedIssue(null);
                                    fetchProjectIssues();
                                }}
                            />
                        )}
                    </DialogContent>
                </Dialog>
            </div>
        </div>

    );

    const Dashboard = () => {
        if (!metrics) {
            return (
                <div className="text-center py-8">
                    <p className="text-gray-500">Loading metrics...</p>
                </div>
            );
        }

        return (
            <div className="space-y-6">
                {/* Summary Cards */}
                <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                    <Card>
                        <CardHeader className="pb-2">
                            <CardTitle className="text-lg">Open Issues</CardTitle>
                        </CardHeader>
                        <CardContent>
                            <p className="text-3xl font-bold">{metrics.total_open || 0}</p>
                        </CardContent>
                    </Card>
                    <Card>
                        <CardHeader className="pb-2">
                            <CardTitle className="text-lg">Critical Issues</CardTitle>
                        </CardHeader>
                        <CardContent>
                            <p className="text-3xl font-bold text-red-600">{metrics.critical_issues || 0}</p>
                        </CardContent>
                    </Card>
                    <Card>
                        <CardHeader className="pb-2">
                            <CardTitle className="text-lg">Recently Resolved</CardTitle>
                        </CardHeader>
                        <CardContent>
                            <p className="text-3xl font-bold text-green-600">{metrics.recently_resolved || 0}</p>
                        </CardContent>
                    </Card>
                    <Card>
                        <CardHeader className="pb-2">
                            <CardTitle className="text-lg">Avg Resolution Time</CardTitle>
                        </CardHeader>
                        <CardContent>
                            <p className="text-3xl font-bold">
                                {metrics.resolution_time ?
                                    `${Math.round(metrics.resolution_time / (24 * 60 * 60 * 1000))} days` :
                                    'N/A'}
                            </p>
                        </CardContent>
                    </Card>
                </div>

                {/* Severity Distribution */}
                <Card>
                    <CardHeader>
                        <CardTitle>Issues by Severity</CardTitle>
                    </CardHeader>
                    <CardContent>
                        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                            {metrics.by_severity?.map(item => (
                                <div key={item.severity} className="border rounded-lg p-4 text-center">
                                    <Badge className={getSeverityBadgeColor(item.severity)}>
                                        {item.severity.charAt(0).toUpperCase() + item.severity.slice(1)}
                                    </Badge>
                                    <p className="text-2xl font-bold mt-2">{item.count}</p>
                                </div>
                            ))}
                        </div>
                    </CardContent>
                </Card>

                {/* Status Distribution */}
                <Card>
                    <CardHeader>
                        <CardTitle>Issues by Status</CardTitle>
                    </CardHeader>
                    <CardContent>
                        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4">
                            {metrics.by_status?.map(item => (
                                <div key={item.status} className="text-center">
                                    <Badge className={getStatusBadgeColor(item.status)}>
                                        {item.status.replace('_', ' ')}
                                    </Badge>
                                    <p className="text-2xl font-bold mt-2">{item.count}</p>
                                </div>
                            ))}
                        </div>
                    </CardContent>
                </Card>

                {/* Action Items */}
                <Card>
                    <CardHeader>
                        <CardTitle>Action Items</CardTitle>
                    </CardHeader>
                    <CardContent>
                        <div className="space-y-4">
                            {metrics.critical_issues > 0 && (
                                <div className="flex items-center space-x-4 p-4 bg-red-50 rounded-lg border border-red-200">
                                    <AlertTriangle className="w-6 h-6 text-red-500" />
                                    <div>
                                        <p className="font-medium">{metrics.critical_issues} Critical Issues Require Attention</p>
                                        <p className="text-sm text-gray-500">Address these issues immediately to prevent delays</p>
                                    </div>
                                    <Button
                                        className="ml-auto"
                                        variant="outline"
                                        onClick={() => {
                                            setActiveTab('issues');
                                            setFilters(prev => ({ ...prev, severity: 'critical' }));
                                        }}
                                    >
                                        View Issues
                                    </Button>
                                </div>
                            )}
                            <Button variant="outline" className="w-full" onClick={() => setIsAddingIssue(true)}>
                                <Plus className="w-4 h-4 mr-2" />
                                Record New Quality Issue
                            </Button>
                        </div>
                    </CardContent>
                </Card>
            </div>
        );
    };

    const QualityIssueForm = ({ projectId, onSubmit, initialData = null, onCancel }) => {
        const {
            register,
            handleSubmit,
            formState: { errors },
            watch
        } = useForm({
            defaultValues: initialData || {
                description: '',
                severity: 'medium',
                status: 'identified',
                inspection: '',
                client_visible: false
            }
        });

        const status = watch('status');
        const showResolutionFields = ['resolved', 'verified'].includes(status);

        if (isLoadingInspections) {
            return <div className="p-4 text-center">Loading inspections...</div>;
        }

        const inputClasses = "w-full rounded-lg border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition duration-150 ease-in-out px-4 py-2 bg-white";
        const labelClasses = "block text-sm font-medium text-gray-700 mb-1";
        const selectClasses = "w-full rounded-lg border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition duration-150 ease-in-out px-4 py-2 bg-white";

        if (!projectId || projectId === 'undefined' || projectId === 'null') {
            return <div>Invalid project ID. Please select a valid project.</div>;
        }

        return (
            <form onSubmit={handleSubmit(onSubmit)} className="space-y-6 p-4 sm:p-6 max-h-[85vh] overflow-y-auto">
                <div className="space-y-6">
                    {/* Inspection Selection */}
                    <div className="space-y-2">
                        <label className={labelClasses}>
                            Inspection <span className="text-red-500">*</span>
                        </label>
                        <select
                            {...register('inspection', { required: 'Inspection is required' })}
                            className={selectClasses}
                        >
                            <option value="none">Select an inspection</option>
                            {inspections.map((inspection) => (
                                <option key={inspection.id} value={inspection.id}>
                                    {inspection.template_name ||
                                        inspection.name ||
                                        `Inspection ${new Date(inspection.scheduled_date).toLocaleDateString()}`}
                                </option>
                            ))}
                        </select>
                        {errors.inspection && (
                            <p className="text-sm text-red-600 mt-1">{errors.inspection.message}</p>
                        )}
                    </div>

                    {/* Description */}
                    <div className="space-y-2">
                        <label className={labelClasses}>
                            Issue Description <span className="text-red-500">*</span>
                        </label>
                        <textarea
                            {...register('description', { required: 'Description is required' })}
                            rows={4}
                            className={`${inputClasses} min-h-[100px]`}
                        />
                        {errors.description && (
                            <p className="text-sm text-red-600 mt-1">{errors.description.message}</p>
                        )}
                    </div>

                    {/* Severity and Status */}
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                        <div className="space-y-2">
                            <label className={labelClasses}>
                                Severity Level
                            </label>
                            <select
                                {...register('severity', { required: 'Severity is required' })}
                                className={selectClasses}
                            >
                                <option value="low">Low</option>
                                <option value="medium">Medium</option>
                                <option value="high">High</option>
                                <option value="critical">Critical</option>
                            </select>
                        </div>

                        <div className="space-y-2">
                            <label className={labelClasses}>
                                Status
                            </label>
                            <select
                                {...register('status', { required: 'Status is required' })}
                                className={selectClasses}
                            >
                                <option value="identified">Identified</option>
                                <option value="under_review">Under Review</option>
                                <option value="remediation_planned">Remediation Planned</option>
                                <option value="in_remediation">In Remediation</option>
                                <option value="resolved">Resolved</option>
                                <option value="verified">Verified</option>
                            </select>
                        </div>
                    </div>

                    {/* Remediation Plan */}
                    <div className="space-y-2">
                        <label className={labelClasses}>
                            Remediation Plan
                        </label>
                        <textarea
                            {...register('remediation_plan')}
                            rows={3}
                            className={`${inputClasses} min-h-[80px]`}
                        />
                    </div>

                    {/* Deadline */}
                    <div className="space-y-2">
                        <label className={labelClasses}>
                            Remediation Deadline
                        </label>
                        <input
                            type="date"
                            {...register('remediation_deadline')}
                            className={inputClasses}
                        />
                    </div>

                    {/* Resolution Fields */}
                    {showResolutionFields && (
                        <div className="space-y-4 border-t pt-4">
                            <h4 className="font-medium text-gray-900">Resolution Details</h4>
                            <div className="space-y-2">
                                <label className={labelClasses}>
                                    Resolution Notes
                                </label>
                                <textarea
                                    {...register('resolution_notes', {
                                        required: showResolutionFields ? 'Resolution notes are required' : false
                                    })}
                                    rows={3}
                                    className={`${inputClasses} min-h-[80px]`}
                                />
                                {errors.resolution_notes && (
                                    <p className="text-sm text-red-600 mt-1">{errors.resolution_notes.message}</p>
                                )}
                            </div>
                        </div>
                    )}

                    {/* Client Visibility */}
                    <div className="flex items-center space-x-2">
                        <input
                            type="checkbox"
                            {...register('client_visible')}
                            className="rounded border-gray-300 text-blue-600 focus:ring-blue-500 w-4 h-4"
                        />
                        <label className="text-sm text-gray-700">
                            Make visible to client
                        </label>
                    </div>
                </div>

                <div className="flex flex-col sm:flex-row justify-end space-y-2 sm:space-y-0 sm:space-x-4 pt-6 border-t sticky bottom-0 bg-white">
                    <button
                        type="button"
                        onClick={onCancel}
                        className={secondaryButtonClasses}
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className={primaryButtonClasses}
                    >
                        Save Issue
                    </button>
                </div>
            </form>
        );
    };

    const ResolveIssueForm = ({ issue, onSubmit, onCancel }) => {
        const { register, handleSubmit, formState: { errors } } = useForm({
            defaultValues: {
                resolution_notes: issue?.resolution_notes || ''
            }
        });

        return (
            <form onSubmit={handleSubmit(onSubmit)} className="space-y-6 p-4">
                <div className="space-y-4">
                    <div>
                        <h3 className="text-lg font-medium">Issue Details</h3>
                        <p className="mt-1">{issue.description}</p>
                    </div>

                    <div className="space-y-2">
                        <Label htmlFor="resolution_notes">
                            Resolution Notes <span className="text-red-500">*</span>
                        </Label>
                        <textarea
                            id="resolution_notes"
                            {...register('resolution_notes', { required: 'Resolution notes are required' })}
                            rows={4}
                            className="w-full rounded-md border-gray-300"
                            placeholder="Describe how this issue was resolved..."
                        />
                        {errors.resolution_notes && (
                            <p className="text-sm text-red-600 mt-1">{errors.resolution_notes.message}</p>
                        )}
                    </div>
                </div>

                <div className="flex justify-end space-x-2">
                    <Button
                        type="button"
                        variant="outline"
                        onClick={onCancel}
                    >
                        Cancel
                    </Button>
                    <Button type="submit">
                        <CheckCircle2 className="w-4 h-4 mr-2" />
                        Mark as Resolved
                    </Button>
                </div>
            </form>
        );
    };

    return (
        <div className="space-y-6">
            <div className="flex justify-between items-center">
                <h2 className="text-2xl font-bold text-gray-900">Quality Issues</h2>
            </div>

            <Tabs defaultValue="issues" value={activeTab} onValueChange={setActiveTab}>
                <TabsList>
                    <TabsTrigger value="dashboard">
                        <PieChart className="w-4 h-4 mr-2" />
                        Dashboard
                    </TabsTrigger>
                    <TabsTrigger value="issues">
                        <AlertCircle className="w-4 h-4 mr-2" />
                        Issues
                    </TabsTrigger>
                </TabsList>

                <TabsContent value="dashboard" className="mt-6">
                    <Dashboard />
                </TabsContent>

                <TabsContent value="issues" className="mt-6">
                    {isLoading ? (
                        <div className="flex justify-center items-center h-64">
                            <span className="text-gray-500">Loading issues...</span>
                        </div>
                    ) : (
                        <IssuesList />
                    )}
                </TabsContent>
            </Tabs>

            {/* Add Issue Modal */}
            <Dialog open={isAddingIssue} onOpenChange={setIsAddingIssue}>
                <DialogContent className="sm:max-w-[600px] lg:max-w-[800px]">
                    <DialogHeader>
                        <DialogTitle>Add Quality Issue</DialogTitle>
                    </DialogHeader>
                    <QualityIssueForm
                        projectId={projectId}
                        onSubmit={handleAddIssue}
                        onCancel={() => setIsAddingIssue(false)}
                    />
                </DialogContent>
            </Dialog>

            {/* Resolve Issue Modal */}
            <Dialog open={isResolveModalOpen} onOpenChange={setIsResolveModalOpen}>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>Resolve Quality Issue</DialogTitle>
                    </DialogHeader>
                    {selectedIssue && (
                        <ResolveIssueForm
                            issue={selectedIssue}
                            onSubmit={handleResolveIssue}
                            onCancel={() => {
                                setIsResolveModalOpen(false);
                                setSelectedIssue(null);
                            }}
                        />
                    )}
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default QualityIssuesManager;