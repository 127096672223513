import React, { useState, useEffect } from 'react';
import { Search, ChevronRight, Info, Lock } from 'lucide-react';
import api from '../services/api';
import { toast } from 'react-toastify';

import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import { Sheet, SheetContent, SheetHeader, SheetTitle } from "../components/ui/sheet";
import { Checkbox } from "../components/ui/checkbox";
import { Switch } from "../components/ui/switch";
import { Card, CardContent } from "../components/ui/cardui";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../components/ui/tooltip";
import { ScrollArea } from "../components/ui/scroll-area";
import { Badge } from "../components/ui/badge";

// Feature type constants
const FEATURE_TYPES = {
  PROJECT: 'Project Management',
  SAFETY: 'Safety Management',
  BID: 'Bid Management',
  ALL: 'All Features'
};

const RoleInfo = ({ description, isLocked, requiredFeature }) => {
  const tooltipContent = isLocked
    ? `Available with ${requiredFeature} feature`
    : description;

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button variant="ghost" size="icon" className="h-4 w-4 p-0">
            {isLocked ? (
              <Lock className="h-4 w-4 text-muted-foreground" />
            ) : (
              <Info className="h-4 w-4 text-muted-foreground" />
            )}
          </Button>
        </TooltipTrigger>
        <TooltipContent className="max-w-[200px]">
          <p className="text-sm">{tooltipContent}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

const RoleManagement = () => {
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rolesLoading, setRolesLoading] = useState(false);
  const [featureType, setFeatureType] = useState(null);
  const [featureName, setFeatureName] = useState(null);
  const [allowedRoles, setAllowedRoles] = useState([]);

  const [sortedRoles, setSortedRoles] = useState({ available: [], restricted: [] });

  useEffect(() => {
    if (roles) {
      const available = roles.filter(role => isRoleAllowed(role.name));
      const restricted = roles.filter(role => !isRoleAllowed(role.name));
      setSortedRoles({ available, restricted });
    }
  }, [roles, allowedRoles]);

  useEffect(() => {
    fetchUsers();
    loadSubscriptionData();
  }, []);

  useEffect(() => {
    if (isPanelOpen && !roles) {
      fetchRoles();
    }
  }, [isPanelOpen, roles]);

  const getRolesForFeatureType = (featureCode) => {
    const basicRoles = [
      'Tenant Member',
      'Project Manager',
      'Field Worker'
    ];

    switch (featureCode?.toUpperCase()) {
      case 'PROJECT':
        return [...basicRoles, 'Tenant Admin Project'];
      case 'SAFETY':
        return [...basicRoles, 'Tenant Admin Safety', 'Safety Manager', 'Safety Officer', 'Quality Control Officer'];
      case 'BID':
        return [...basicRoles, 'Tenant Admin Bid', 'Bid Manager', 'Bid Reviewer', 'Bid Submitter'];
      case 'ALL':

        return [
          'Tenant Admin',
          'Tenant Member',
          'Project Director',
          'Project Admin',
          'Project Manager',
          'Construction Manager',
          'Assistant Project Manager',
          'Site Supervisor',
          'Field Worker',
          'Safety Officer',
          'Quality Control Officer',
          'Bid Manager',
          'Bid Reviewer',
          'Bid Submitter',
          'Client Viewer',
          'Client User',
          'Client Admin',
          'Contractor Admin',
          'Contractor',
          'General Viewer',
          'Superintendent',
          'Safety Director',
          'Safety Manager'
        ];
      default:
        return basicRoles;
    }
  };

  const isRoleAllowed = (roleName) => {
    return allowedRoles.some(allowedRole =>
      allowedRole.toLowerCase().replace(/\s+/g, '') ===
      roleName.toLowerCase().replace(/\s+/g, '')
    );
  };

  const loadSubscriptionData = async () => {
    try {
      const response = await api.get('subscription-debug/');
      console.log("Subscription data:", response.data);

      const subData = response.data.current_subscription;
      let featureCode = 'PROJECT';

      // Try to determine feature type from different possible paths
      if (subData?.plan?.feature_type?.code) {
        featureCode = subData.plan.feature_type.code;
      } else if (subData?.plan?.name) {
        const planName = subData.plan.name.toUpperCase();
        if (planName.includes('PROJECT')) featureCode = 'PROJECT';
        else if (planName.includes('SAFETY')) featureCode = 'SAFETY';
        else if (planName.includes('BID')) featureCode = 'BID';
        else if (planName.includes('ALL')) featureCode = 'ALL';
      } else if (subData?.plan_name) {
        const planName = subData.plan_name.toUpperCase();
        if (planName.includes('PROJECT')) featureCode = 'PROJECT';
        else if (planName.includes('SAFETY')) featureCode = 'SAFETY';
        else if (planName.includes('BID')) featureCode = 'BID';
        else if (planName.includes('ALL') ||
          planName.includes('PROFESSIONAL') ||
          planName.includes('ENTERPRISE')) featureCode = 'ALL';
      }

      // Set feature type state
      setFeatureType(featureCode);

      // Get friendly name for the feature
      const friendlyName = FEATURE_TYPES[featureCode.toUpperCase()] || featureCode;
      setFeatureName(friendlyName);

      // Set allowed roles based on feature type
      const roles = getRolesForFeatureType(featureCode);
      setAllowedRoles(roles);
      console.log("Feature Type:", featureCode);
      console.log("Allowed Roles:", roles);

    } catch (error) {
      console.error('Error loading subscription data:', error);
      // Fall back to PROJECT if there's an error
      setFeatureType('PROJECT');
      setFeatureName(FEATURE_TYPES.PROJECT);
      setAllowedRoles(getRolesForFeatureType('PROJECT'));
    }
  };

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const response = await api.get('role-management/users_with_roles/');
      const uniqueUsers = new Map(response.data.map(user => [user.id, user]));
      setUsers(prevUsers => {
        const newUsers = [...prevUsers];
        uniqueUsers.forEach(user => {
          if (!newUsers.some(u => u.id === user.id)) {
            newUsers.push(user);
          }
        });
        return newUsers;
      });
    } catch (error) {
      console.error('Error fetching users:', error);
      toast.error('Failed to fetch users');
    }
    setLoading(false);
  };

  const fetchRoles = async () => {
    setRolesLoading(true);
    try {
      const response = await api.get('roles/');
      setRoles(response.data.results || []);
    } catch (error) {
      console.error('Error fetching roles:', error);
      toast.error('Failed to fetch roles');
      setRoles([]);
    }
    setRolesLoading(false);
  };

  const handleUserSelect = (userId) => {
    setSelectedUsers(prevSelected =>
      prevSelected.includes(userId)
        ? prevSelected.filter(id => id !== userId)
        : [...prevSelected, userId]
    );
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleRoleToggle = async (roleId, roleName) => {
    if (!isRoleAllowed(roleName)) {
      toast.warn(`This role requires a different feature subscription. Please upgrade to access it.`);
      return;
    }

    if (selectedUsers.length === 0) {
      toast.warn('Please select users to assign the role');
      return;
    }

    try {
      await Promise.all(selectedUsers.map(userId =>
        api.post('assign-role/', { user_id: userId, role_id: roleId })
      ));
      toast.success('Roles assigned successfully!');

      setUsers(prevUsers =>
        prevUsers.map(user =>
          selectedUsers.includes(user.id)
            ? { ...user, role: roles.find(r => r.id === roleId) }
            : user
        )
      );

      setSelectedUsers([]);
    } catch (error) {
      console.error('Error assigning roles:', error);
      toast.error('Failed to assign roles');
    }
  };

  const filteredUsers = users.filter(user =>
    `${user.first_name} ${user.last_name}`.toLowerCase().includes(searchQuery.toLowerCase()) ||
    user.email.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Determine what feature is needed for each restricted role
  const getRequiredFeatureForRole = (roleName) => {
    const lowerRole = roleName.toLowerCase();

    if (lowerRole.includes('bid') || lowerRole === 'tenant admin bid') {
      return FEATURE_TYPES.BID;
    } else if (lowerRole.includes('safety') || lowerRole === 'quality control officer') {
      return FEATURE_TYPES.SAFETY;
    } else if (lowerRole.includes('project admin') || lowerRole === 'tenant admin project') {
      return FEATURE_TYPES.PROJECT;
    }

    return FEATURE_TYPES.ALL;
  };

  return (
    <div className="flex h-screen">
      <div className="flex-1 overflow-hidden p-6">
        <div className="flex items-center justify-between mb-6 gap-4">
          <div className="relative flex-grow">
            <Search className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-muted-foreground" />
            <Input
              placeholder="Search users..."
              value={searchQuery}
              onChange={handleSearch}
              className="pl-9"
            />
          </div>
          <Button
            size="icon"
            onClick={() => setIsPanelOpen(true)}
          >
            <ChevronRight className="h-4 w-4" />
          </Button>
        </div>

        <Card>
          <ScrollArea className="h-[calc(100vh-200px)]">
            <CardContent className="p-0">
              {filteredUsers.map((user, index) => (
                <div
                  key={`${user.id}-${index}`}
                  className="flex items-center p-4 border-b last:border-b-0"
                >
                  <Checkbox
                    checked={selectedUsers.includes(user.id)}
                    onCheckedChange={() => handleUserSelect(user.id)}
                    className="mr-3"
                  />
                  <div className="flex-1">
                    <p className="font-medium">
                      {user.first_name} {user.last_name}
                    </p>
                    <p className="text-sm text-muted-foreground">
                      {user.email}
                    </p>
                  </div>
                  <span className="text-sm text-muted-foreground">
                    {user.role ? user.role.name : 'No Role'}
                  </span>
                </div>
              ))}
            </CardContent>
          </ScrollArea>
        </Card>
      </div>

      <Sheet open={isPanelOpen} onOpenChange={setIsPanelOpen}>
        <SheetContent>
          <SheetHeader>
            <SheetTitle>Manage Roles</SheetTitle>
            {featureName && (
              <Badge variant="secondary" className="w-fit">
                {featureName} Feature
              </Badge>
            )}
          </SheetHeader>

          <ScrollArea className="h-[calc(100vh-100px)] mt-6">
            {rolesLoading ? (
              <p className="text-center text-muted-foreground">Loading roles...</p>
            ) : roles && roles.length > 0 ? (
              <div className="space-y-6">
                {sortedRoles.available.length > 0 && (
                  <>
                    <h4 className="text-sm font-medium text-muted-foreground mb-4">Available Roles</h4>
                    {sortedRoles.available.map(role => (
                      <div key={role.id} className="flex items-center justify-between">
                        <div className="flex items-center gap-2">
                          <h3 className="font-medium">{role.name}</h3>
                          <RoleInfo description={role.role_description} />
                        </div>
                        <Switch
                          checked={selectedUsers.length > 0 && selectedUsers.every(userId =>
                            users.find(u => u.id === userId)?.role?.id === role.id
                          )}
                          onCheckedChange={() => handleRoleToggle(role.id, role.name)}
                        />
                      </div>
                    ))}
                  </>
                )}

                {sortedRoles.restricted.length > 0 && (
                  <>
                    <div className="border-t my-6" />
                    <h4 className="text-sm font-medium text-muted-foreground mb-4">
                      Roles Available with Other Features
                    </h4>
                    {sortedRoles.restricted.map(role => (
                      <div key={role.id} className="flex items-center justify-between opacity-50">
                        <div className="flex items-center gap-2">
                          <h3 className="font-medium">{role.name}</h3>
                          <RoleInfo
                            description={role.role_description}
                            isLocked={true}
                            requiredFeature={getRequiredFeatureForRole(role.name)}
                          />
                        </div>
                        <Switch disabled />
                      </div>
                    ))}
                  </>
                )}
              </div>
            ) : (
              <p className="text-center text-muted-foreground">No roles available.</p>
            )}
          </ScrollArea>
        </SheetContent>
      </Sheet>
    </div>
  );
};

export default RoleManagement;