import React from 'react';
import logoImage from './logo.png';

const Logo = ({alt = 'BUILDIFYPRO Logo', width='250px', height='120px' }) => (
  <img 
    src={logoImage} 
    alt={alt}
    width={width} 
    height={height}
  />
  
);

export default Logo;