import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
    Card,
    CardContent,
    CardHeader,
    CardTitle
} from "../ui/cardui";
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogFooter
} from "../ui/dialog";
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import { Textarea } from "../ui/textarea";
import { DatePicker } from "../ui/date-picker";
import { Star, UserCircle } from "lucide-react";
import { toast } from "react-toastify";
import api from '../../services/api';
import { primaryButtonClasses, secondaryButtonClasses } from '../common/ButtonStyles';
import secureLocalStorage from "react-secure-storage";

const RatingStars = ({ value, onChange, disabled = false }) => {
    const [hoverValue, setHoverValue] = useState(0);

    const handleMouseOver = (newValue) => {
        if (!disabled) {
            setHoverValue(newValue);
        }
    };

    const handleMouseLeave = () => {
        setHoverValue(0);
    };

    const handleClick = (newValue) => {
        if (!disabled) {
            onChange(newValue);
        }
    };

    return (
        <div className="flex">
            {[1, 2, 3, 4, 5].map((star) => (
                <Star
                    key={star}
                    className={`h-6 w-6 cursor-${disabled ? 'default' : 'pointer'} transition-colors ${(hoverValue || value) >= star
                        ? 'text-yellow-400 fill-yellow-400'
                        : 'text-gray-300'
                        }`}
                    onMouseOver={() => handleMouseOver(star)}
                    onMouseLeave={handleMouseLeave}
                    onClick={() => handleClick(star)}
                />
            ))}
        </div>
    );
};

const SupplierReviewForm = ({ isOpen, onClose, onSubmit, review = null }) => {
    const [formData, setFormData] = useState({
        delivery_rating: 0,
        quality_rating: 0,
        communication_rating: 0,
        pricing_rating: 0,
        comments: '',
        review_date: new Date()
    });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (review) {
            setFormData({
                ...review,
                review_date: review.review_date ? new Date(review.review_date) : new Date()
            });
        } else {
            setFormData({
                delivery_rating: 0,
                quality_rating: 0,
                communication_rating: 0,
                pricing_rating: 0,
                comments: '',
                review_date: new Date()
            });
        }
    }, [review, isOpen]);

    const handleRatingChange = (type, value) => {
        setFormData(prev => ({ ...prev, [type]: value }));
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleDateChange = (date) => {
        setFormData(prev => ({ ...prev, review_date: date }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate ratings
        if (formData.delivery_rating === 0 ||
            formData.quality_rating === 0 ||
            formData.communication_rating === 0 ||
            formData.pricing_rating === 0) {
            toast.error("Please provide ratings for all categories");
            return;
        }

        setLoading(true);
        try {
            await onSubmit(formData);
            onClose();
        } catch (error) {
            console.error("Error submitting review:", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className="sm:max-w-[600px]">
                <DialogHeader>
                    <DialogTitle>{review ? 'Edit Review' : 'Add New Review'}</DialogTitle>
                </DialogHeader>

                <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                        <Label htmlFor="review_date">Review Date</Label>
                        <DatePicker
                            value={formData.review_date}
                            onChange={handleDateChange}
                        />
                    </div>

                    <div className="space-y-4">
                        <div className="flex justify-between items-center">
                            <Label>Delivery Rating</Label>
                            <RatingStars
                                value={formData.delivery_rating}
                                onChange={(value) => handleRatingChange('delivery_rating', value)}
                            />
                        </div>

                        <div className="flex justify-between items-center">
                            <Label>Quality Rating</Label>
                            <RatingStars
                                value={formData.quality_rating}
                                onChange={(value) => handleRatingChange('quality_rating', value)}
                            />
                        </div>

                        <div className="flex justify-between items-center">
                            <Label>Communication Rating</Label>
                            <RatingStars
                                value={formData.communication_rating}
                                onChange={(value) => handleRatingChange('communication_rating', value)}
                            />
                        </div>

                        <div className="flex justify-between items-center">
                            <Label>Pricing Rating</Label>
                            <RatingStars
                                value={formData.pricing_rating}
                                onChange={(value) => handleRatingChange('pricing_rating', value)}
                            />
                        </div>
                    </div>

                    <div>
                        <Label htmlFor="comments">Comments</Label>
                        <Textarea
                            id="comments"
                            name="comments"
                            rows={4}
                            value={formData.comments}
                            onChange={handleInputChange}
                            placeholder="Please provide detailed feedback about this supplier"
                            required
                        />
                    </div>

                    <DialogFooter>
                        <Button
                            type="button"
                            variant="outline"
                            onClick={onClose}
                            className={secondaryButtonClasses}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            className={primaryButtonClasses}
                            disabled={loading}
                        >
                            {loading ? 'Submitting...' : review ? 'Update Review' : 'Submit Review'}
                        </Button>
                    </DialogFooter>
                </form>
            </DialogContent>
        </Dialog>
    );
};

const SupplierReviews = ({ supplierId }) => {
    const [reviews, setReviews] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [selectedReview, setSelectedReview] = useState(null);
    const [averageRatings, setAverageRatings] = useState({
        overall: 0,
        delivery: 0,
        quality: 0,
        communication: 0,
        pricing: 0
    });

    useEffect(() => {
        fetchReviews();
    }, [supplierId]);

    const fetchReviews = async () => {
        setLoading(true);
        try {
            const response = await api.get('supplier-reviews/', {
                params: { supplier: supplierId }
            });

            const reviewsData = response.data.results || response.data;
            setReviews(reviewsData);

            // Calculate average ratings
            if (reviewsData.length > 0) {
                const delivery = reviewsData.reduce((sum, review) => sum + Number(review.delivery_rating), 0) / reviewsData.length;
                const quality = reviewsData.reduce((sum, review) => sum + Number(review.quality_rating), 0) / reviewsData.length;
                const communication = reviewsData.reduce((sum, review) => sum + Number(review.communication_rating), 0) / reviewsData.length;
                const pricing = reviewsData.reduce((sum, review) => sum + Number(review.pricing_rating), 0) / reviewsData.length;
                const overall = (delivery + quality + communication + pricing) / 4;

                setAverageRatings({
                    overall: overall.toFixed(1),
                    delivery: delivery.toFixed(1),
                    quality: quality.toFixed(1),
                    communication: communication.toFixed(1),
                    pricing: pricing.toFixed(1)
                });
            }
        } catch (error) {
            console.error("Error fetching supplier reviews:", error);
            toast.error("Failed to load supplier reviews");
        } finally {
            setLoading(false);
        }
    };

    const handleAddReview = () => {
        setSelectedReview(null);
        setIsFormOpen(true);
    };

    const handleEditReview = (review) => {
        setSelectedReview(review);
        setIsFormOpen(true);
    };

    const handleDeleteReview = async (reviewId) => {
        if (window.confirm("Are you sure you want to delete this review?")) {
            try {
                await api.delete(`supplier-reviews/${reviewId}/`);
                toast.success("Review deleted successfully");
                fetchReviews();
            } catch (error) {
                console.error("Error deleting review:", error);
                toast.error("Failed to delete review");
            }
        }
    };

    const getTenantId = () => {
        let tenantId =
            localStorage.getItem('tenantIdentifier') ||
            (typeof secureLocalStorage !== 'undefined' && secureLocalStorage.getItem('currentTenantId')) ||
            localStorage.getItem('tenantId') ||
            sessionStorage.getItem('tenantId') ||
            sessionStorage.getItem('tenantIdentifier');

        if (!tenantId) {
            try {
                // Try regular localStorage userData
                const userData = JSON.parse(localStorage.getItem('userData') || '{}');
                tenantId = userData.tenant?.id || userData.tenantId;

                // If found, save to localStorage for future use
                if (tenantId) {
                    localStorage.setItem('tenantId', tenantId);
                    console.log("Retrieved tenant ID from user data and saved to localStorage");
                }
            } catch (e) {
                console.error("Error parsing user data:", e);
            }
        }

        return tenantId;
    };


    const handleSubmitReview = async (formData) => {
        try {
            // Get the tenant ID
            const tenantId = getTenantId();
            if (!tenantId) {
                toast.error("Tenant ID not found. Cannot submit review.");
                return;
            }

            const reviewData = {
                ...formData,
                supplier: supplierId,
                tenant: tenantId
            };

            console.log('Submitting review data:', reviewData); // Add this line

            if (selectedReview) {
                // Update existing review
                await api.put(`supplier-reviews/${selectedReview.id}/`, reviewData);
                toast.success("Review updated successfully");
            } else {
                // Create new review
                await api.post('supplier-reviews/', reviewData);
                toast.success("Review submitted successfully");
            }
            fetchReviews();
        } catch (error) {
            console.error("Error submitting review:", error);
            console.error("Error details:", error.response?.data); // Add this line
            toast.error("Failed to submit review");
            throw error;
        }
    };

    const formatDate = (dateString) => {
        return new Date(dateString).toLocaleDateString(undefined, {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });
    };

    return (
        <div className="space-y-6">
            <div className="flex justify-between items-center">
                <h2 className="text-xl font-semibold">Supplier Reviews</h2>
                <Button
                    onClick={handleAddReview}
                    className={primaryButtonClasses}
                >
                    Add Review
                </Button>
            </div>

            {/* Average Ratings Card */}
            {reviews.length > 0 && (
                <Card>
                    <CardHeader className="pb-2">
                        <CardTitle>Rating Summary</CardTitle>
                    </CardHeader>
                    <CardContent>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4">
                            <div className="flex flex-col items-center p-4 border rounded-md">
                                <p className="text-sm text-gray-500 mb-2">Overall Rating</p>
                                <div className="flex items-center">
                                    <span className="text-2xl font-bold mr-2">{averageRatings.overall}</span>
                                    <RatingStars value={Math.round(averageRatings.overall)} disabled />
                                </div>
                                <p className="text-xs text-gray-500 mt-2">Based on {reviews.length} reviews</p>
                            </div>
                            <div className="flex flex-col items-center p-4 border rounded-md">
                                <p className="text-sm text-gray-500 mb-2">Delivery</p>
                                <span className="text-xl font-semibold">{averageRatings.delivery}/5</span>
                            </div>
                            <div className="flex flex-col items-center p-4 border rounded-md">
                                <p className="text-sm text-gray-500 mb-2">Quality</p>
                                <span className="text-xl font-semibold">{averageRatings.quality}/5</span>
                            </div>
                            <div className="flex flex-col items-center p-4 border rounded-md">
                                <p className="text-sm text-gray-500 mb-2">Communication</p>
                                <span className="text-xl font-semibold">{averageRatings.communication}/5</span>
                            </div>
                            <div className="flex flex-col items-center p-4 border rounded-md">
                                <p className="text-sm text-gray-500 mb-2">Pricing</p>
                                <span className="text-xl font-semibold">{averageRatings.pricing}/5</span>
                            </div>
                        </div>
                    </CardContent>
                </Card>
            )}

            {loading ? (
                <div className="text-center py-8">Loading reviews...</div>
            ) : reviews.length === 0 ? (
                <Card>
                    <CardContent className="text-center py-12">
                        <p className="text-gray-500">No reviews yet. Be the first to review this supplier.</p>
                    </CardContent>
                </Card>
            ) : (
                <div className="space-y-4">
                    {reviews.map((review) => (
                        <Card key={review.id}>
                            <CardContent className="p-6">
                                <div className="flex flex-col md:flex-row md:justify-between md:items-start gap-4">
                                    <div className="flex-1">
                                        <div className="flex items-center mb-4">
                                            <UserCircle className="h-10 w-10 text-gray-400 mr-3" />
                                            <div>
                                                <p className="font-medium">{review.reviewer?.name || 'Anonymous'}</p>
                                                <p className="text-sm text-gray-500">{formatDate(review.review_date)}</p>
                                            </div>
                                        </div>

                                        <p className="mb-4">{review.comments}</p>

                                        <div className="grid grid-cols-2 md:grid-cols-4 gap-4 text-sm">
                                            <div>
                                                <p className="text-gray-500">Delivery</p>
                                                <div className="flex items-center">
                                                    <span className="font-medium mr-1">{review.delivery_rating}</span>
                                                    <Star className="h-4 w-4 text-yellow-400 fill-yellow-400" />
                                                </div>
                                            </div>
                                            <div>
                                                <p className="text-gray-500">Quality</p>
                                                <div className="flex items-center">
                                                    <span className="font-medium mr-1">{review.quality_rating}</span>
                                                    <Star className="h-4 w-4 text-yellow-400 fill-yellow-400" />
                                                </div>
                                            </div>
                                            <div>
                                                <p className="text-gray-500">Communication</p>
                                                <div className="flex items-center">
                                                    <span className="font-medium mr-1">{review.communication_rating}</span>
                                                    <Star className="h-4 w-4 text-yellow-400 fill-yellow-400" />
                                                </div>
                                            </div>
                                            <div>
                                                <p className="text-gray-500">Pricing</p>
                                                <div className="flex items-center">
                                                    <span className="font-medium mr-1">{review.pricing_rating}</span>
                                                    <Star className="h-4 w-4 text-yellow-400 fill-yellow-400" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex md:flex-col space-x-2 md:space-x-0 md:space-y-2">
                                        <Button
                                            variant="outline"
                                            size="sm"
                                            onClick={() => handleEditReview(review)}
                                        >
                                            Edit
                                        </Button>
                                        <Button
                                            variant="destructive"
                                            size="sm"
                                            onClick={() => handleDeleteReview(review.id)}
                                        >
                                            Delete
                                        </Button>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    ))}
                </div>
            )}

            <SupplierReviewForm
                isOpen={isFormOpen}
                onClose={() => setIsFormOpen(false)}
                onSubmit={handleSubmitReview}
                review={selectedReview}
            />
        </div>
    );
};

export default SupplierReviews;