import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogFooter,
} from "../../../components/ui/dialog";
import { Label } from "../../../components/ui/label";
import { Button } from "../../../components/ui/button";
import { Input } from "../../../components/ui/input";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "../../../components/ui/select";
import { primaryButtonClasses, secondaryButtonClasses } from '../../common/ButtonStyles';
import { toast } from 'react-toastify';
import api from '../../../services/api';

const DependencyForm = ({
    isOpen,
    onClose,
    onSubmit,
    initialData,
    projectId,
    activities,
    mode = 'create'
}) => {
    const [formData, setFormData] = useState({
        predecessor: '',
        successor: '',
        dependency_type: 'FS',
        lag: 0
    });
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (initialData) {
            setFormData({
                predecessor: initialData.predecessor || '',
                successor: initialData.successor || '',
                dependency_type: initialData.dependency_type || 'FS',
                lag: initialData.lag || 0
            });
        }
    }, [initialData]);

    const handleChange = (field, value) => {
        setFormData(prev => ({ ...prev, [field]: value }));
        setError('');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError('');

        try {
            if (formData.predecessor === formData.successor) {
                throw new Error('An activity cannot depend on itself');
            }

            const payload = {
                ...formData,
                project: projectId,
                tenant: api.defaults.headers.common['X-Tenant-ID']
            };

            await onSubmit(payload);
            onClose();
        } catch (err) {
            const errorMessage = err.response?.data?.detail || err.message || `Failed to ${mode} dependency`;
            setError(errorMessage);
            toast.error(errorMessage);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>
                        {mode === 'create' ? 'Create New Dependency' : 'Edit Dependency'}
                    </DialogTitle>
                </DialogHeader>

                <form onSubmit={handleSubmit} className="space-y-4">
                    {error && (
                        <div className="text-red-500 text-sm p-2 bg-red-50 rounded">
                            {error}
                        </div>
                    )}

                    <div className="space-y-2">
                        <Label>Predecessor Activity</Label>
                        <Select
                            value={formData.predecessor}
                            onValueChange={value => handleChange('predecessor', value)}
                        >
                            <SelectTrigger>
                                <SelectValue placeholder="Select predecessor" />
                            </SelectTrigger>
                            <SelectContent>
                                {activities.map(activity => (
                                    <SelectItem key={activity.id} value={activity.id}>
                                        {activity.name}
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    </div>

                    <div className="space-y-2">
                        <Label>Successor Activity</Label>
                        <Select
                            value={formData.successor}
                            onValueChange={value => handleChange('successor', value)}
                        >
                            <SelectTrigger>
                                <SelectValue placeholder="Select successor" />
                            </SelectTrigger>
                            <SelectContent>
                                {activities.map(activity => (
                                    <SelectItem key={activity.id} value={activity.id}>
                                        {activity.name}
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    </div>

                    <div className="space-y-2">
                        <Label>Dependency Type</Label>
                        <Select
                            value={formData.dependency_type}
                            onValueChange={value => handleChange('dependency_type', value)}
                        >
                            <SelectTrigger>
                                <SelectValue />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectItem value="FS">Finish to Start</SelectItem>
                                <SelectItem value="SS">Start to Start</SelectItem>
                                <SelectItem value="FF">Finish to Finish</SelectItem>
                                <SelectItem value="SF">Start to Finish</SelectItem>
                            </SelectContent>
                        </Select>
                    </div>

                    <div className="space-y-2">
                        <Label>Lag (days)</Label>
                        <Input
                            type="number"
                            value={formData.lag}
                            onChange={e => handleChange('lag', parseInt(e.target.value) || 0)}
                            min="0"
                        />
                    </div>

                    <DialogFooter>
                        <Button
                            type="button"
                            onClick={onClose}
                            className={secondaryButtonClasses}
                            disabled={isLoading}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            className={primaryButtonClasses}
                            disabled={isLoading || !!error}
                        >
                            {isLoading ? 'Saving...' : mode === 'create' ? 'Create Dependency' : 'Save Changes'}
                        </Button>
                    </DialogFooter>
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default DependencyForm;