import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, CardHeader, CardTitle, CardContent } from '../../../components/ui/cardui';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../../components/ui/tabs';
import { Button } from '../../../components/ui/button';
import { Input } from '../../../components/ui/input';
import { Textarea } from '../../../components/ui/textarea';
import { useToast } from '../../../components/ui/use-toast';
import { Badge } from '../../../components/ui/badge';

import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '../../../components/ui/select';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow
} from '../../../components/ui/table';

import { FileText, Send, Plus, Loader2 } from 'lucide-react';
import api from '../../../services/api';
import { primaryButtonClasses, secondaryButtonClasses } from '../../common/ButtonStyles';
import BidMessagesPanel from './BidMessagesPanel';


const MyBids = ({ bids, loading, currentUser, selectedBid, setSelectedBid, unreadCounts, setUnreadCounts }) => {
    const [localUnreadCount, setLocalUnreadCount] = useState(unreadCounts);
    const { toast } = useToast();
    const navigate = useNavigate();

    useEffect(() => {
        setLocalUnreadCount(unreadCounts);
    }, [unreadCounts]);

    useEffect(() => {
        const handleMessagesRead = (event) => {
            setLocalUnreadCount(event.detail.unreadCount);
        };

        window.addEventListener('messagesRead', handleMessagesRead);
        return () => {
            window.removeEventListener('messagesRead', handleMessagesRead);
        };
    }, []);

    const handleViewDetails = (bid) => {
        if (!bid?.bid_request?.id) {
            toast({
                title: "Error",
                description: "Invalid bid data",
                variant: "destructive"
            });
            return;
        }

        const validUUID = /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
        if (!validUUID.test(bid.bid_request.id)) {

            toast({
                title: "Error",
                description: "Invalid bid ID format",
                variant: "destructive"
            });
            return;
        }

        navigate(`/my-bids/${bid.bid_request.id}`);
    };

    const handleViewMessages = async (bid) => {
        if (bid && bid.has_submission && bid.bid_request?.id) {
            try {
                const response = await api.get(`bid-submissions/by_bid_request/?bid_request=${bid.bid_request.id}`);
                const submissions = response.data;

                if (submissions && submissions.length > 0) {
                    const submission = submissions[0];
                    setSelectedBid({
                        ...bid,
                        submission_id: submission.id
                    });
                } else {
                    throw new Error('No submission found');
                }
            } catch (error) {
                console.error('Error fetching bid submission:', error);
                toast({
                    title: "Error",
                    description: "Unable to load messages at this time",
                    variant: "destructive"
                });
            }
        } else {
            toast({
                title: "Error",
                description: "No submission found for this bid",
                variant: "destructive"
            });
        }
    };

    return (
        <div className="space-y-6">
            <Card>
                <CardHeader>
                    <CardTitle>My Bids</CardTitle>
                </CardHeader>
                <CardContent className="p-0 sm:p-6">
                    {loading ? (
                        <div className="flex justify-center py-4">
                            <Loader2 className="h-6 w-6 animate-spin" />
                        </div>
                    ) : (
                        <div className="overflow-x-auto">
                            <Table>
                                <TableHeader>
                                    <TableRow>
                                        <TableHead className="min-w-[120px]">Bid Title</TableHead>
                                        <TableHead className="min-w-[100px]">Status</TableHead>
                                        <TableHead className="min-w-[200px]">Actions</TableHead>
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                    {Array.isArray(bids) && bids.length > 0 ? (
                                        bids.map((bid, index) => (
                                            <TableRow key={bid?.id || `bid-${index}`}>
                                                <TableCell className="break-words">
                                                    {bid?.bid_request?.title ||
                                                        (bid?.bid_request ? 'Untitled Bid' : 'Invalid Bid Request')}
                                                </TableCell>
                                                <TableCell>{bid?.status || 'Unknown'}</TableCell>
                                                <TableCell>
                                                    <div className="flex flex-col sm:flex-row gap-2">
                                                        <Button
                                                            variant="outline"
                                                            size="sm"
                                                            onClick={() => handleViewDetails(bid)}
                                                            disabled={!bid?.bid_request?.id}
                                                            className="w-full sm:w-auto"
                                                        >
                                                            View Details
                                                        </Button>
                                                        {bid?.has_submission && (
                                                            <div className="inline-flex items-center w-full sm:w-auto">
                                                                <Button
                                                                    variant="outline"
                                                                    size="sm"
                                                                    onClick={() => handleViewMessages(bid)}
                                                                    className="relative w-full sm:w-auto"
                                                                >
                                                                    Messages
                                                                    {localUnreadCount > 0 && (
                                                                        <Badge
                                                                            variant="destructive"
                                                                            className="absolute -top-2 -right-2 h-5 w-5 flex items-center justify-center rounded-full"
                                                                        >
                                                                            {localUnreadCount}
                                                                        </Badge>
                                                                    )}
                                                                </Button>
                                                            </div>
                                                        )}
                                                        {bid?.status === 'accepted' && !bid?.has_submission && (
                                                            <Button
                                                                className={`${primaryButtonClasses} w-full sm:w-auto`}
                                                                size="sm"
                                                                onClick={() => navigate(`/my-bids/${bid.bid_request?.id}/submit`)}
                                                            >
                                                                Submit Bid
                                                            </Button>
                                                        )}
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={3} className="text-center">
                                                No bids available
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </div>
                    )}
                </CardContent>
            </Card>
            {selectedBid && selectedBid.submission_id && (
                <Card className="mt-6">
                    <CardHeader>
                        <CardTitle className="text-base sm:text-lg">Messages - {selectedBid.bid_request?.title}</CardTitle>
                    </CardHeader>
                    <CardContent>
                        <BidMessagesPanel
                            bidSubmissionId={selectedBid.submission_id}
                            currentUser={currentUser}
                            onMessagesRead={async () => {
                                try {
                                    const response = await api.get('bid-messages/unread_count/');
                                    setUnreadCounts(response.data.unread_count || 0);
                                    setLocalUnreadCount(response.data.unread_count || 0);
                                } catch (error) {
                                    console.error('Error updating unread count:', error);
                                }
                            }}
                        />
                    </CardContent>
                </Card>
            )}
        </div>
    );
};
export default MyBids;