// utils/calculations.js

// Import constant values if needed from a separate file
const CONVERSION_RATES = {
    SF_TO_SY: 1 / 9,
    SF_TO_SQUARE: 1 / 100,
    CF_TO_CY: 1 / 27,
    CF_TO_GAL: 7.48052,
    GAL_TO_CF: 0.133681,
    LF_TO_SY: 1 / 3,
    LB_TO_TON: 1 / 2000,
    TON_TO_LB: 2000,
    BF_TO_CF: 1 / 12,
    GAL_TO_ML: 3785.41,
    ML_TO_GAL: 1 / 3785.41
};

const MATERIAL_RATES = {
    CONCRETE: {
        CF_PER_CY: 27,
        TONS_PER_CY: 2
    },
    GRAVEL: {
        CF_PER_CY: 27,
        TONS_PER_CY: 1.4
    },
    SAND: {
        CF_PER_CY: 27,
        TONS_PER_CY: 1.35
    },
    ASPHALT: {
        SF_PER_TON: 80,
        TONS_PER_CY: 2.025
    },
    ROOFING: {
        SQUARES_TO_SF: 100,
        BUNDLES_PER_SQUARE: 3
    }
};

export const calculateTotalAmount = (quantity, unitPrice, unit, material = null) => {
    if (!quantity || !unitPrice) return 0;

    let total = 0;
    const qty = parseFloat(quantity);
    const price = parseFloat(unitPrice);

    // Validate inputs
    if (isNaN(qty) || isNaN(price)) {
        throw new Error('Invalid quantity or unit price');
    }

    switch (unit) {
        // Area measurements
        case 'SF':
            total = qty * price;
            break;
        case 'SY':
            // Price is typically per SY
            total = qty * price;
            break;
        case 'SQUARE':
            // Roofing squares (100 SF)
            total = qty * price * 100;
            break;

        // Volume measurements
        case 'CY':
            if (material) {
                // Apply material-specific calculations
                switch (material) {
                    case 'CONCRETE':
                        total = qty * price * MATERIAL_RATES.CONCRETE.TONS_PER_CY;
                        break;
                    case 'GRAVEL':
                        total = qty * price * MATERIAL_RATES.GRAVEL.TONS_PER_CY;
                        break;
                    case 'SAND':
                        total = qty * price * MATERIAL_RATES.SAND.TONS_PER_CY;
                        break;
                    default:
                        total = qty * price;
                }
            } else {
                total = qty * price;
            }
            break;
        case 'CF':
            total = (qty / 27) * price; // Convert to CY for pricing
            break;

        // Length measurements
        case 'LF':
            total = qty * price;
            break;
        case 'BF':
            total = qty * price;
            break;

        // Weight measurements
        case 'TON':
            total = qty * price;
            break;
        case 'LB':
            total = (qty / 2000) * price; // Convert to tons for pricing
            break;

        // Volume (liquid)
        case 'GAL':
            total = qty * price;
            break;
        case 'ML':
            total = (qty / CONVERSION_RATES.GAL_TO_ML) * price; // Convert to gallons for pricing
            break;

        // Time measurements
        case 'HR':
            total = qty * price;
            break;
        case 'DAY':
            total = qty * price * 8; // Assuming 8 hours per day
            break;
        case 'MO':
            total = qty * price * 160; // Assuming 160 hours per month
            break;

        // Special cases
        case 'LS':
            total = price; // Lump sum just uses the unit price
            break;
        case 'EA':
        case 'PKG':
            total = qty * price;
            break;
        case 'OTHER':
            total = qty * price;
            break;

        default:
            total = qty * price;
    }

    // Round to 2 decimal places
    return Math.round(total * 100) / 100;
};

// Helper function for unit conversion
export const convertUnits = (value, fromUnit, toUnit) => {
    const conversionKey = `${fromUnit}_TO_${toUnit}`;
    if (CONVERSION_RATES[conversionKey]) {
        return value * CONVERSION_RATES[conversionKey];
    }
    throw new Error(`No conversion rate found for ${fromUnit} to ${toUnit}`);
};

// Helper function to format display values
export const formatUnitValue = (value, unit) => {
    const formats = {
        SF: '0',
        SY: '0.1',
        CY: '0.2',
        TON: '0.2',
        LF: '0.1',
        GAL: '0.1',
        HR: '0.1',
        EA: '0',
        LS: '0',
        DEFAULT: '0.2'
    };

    const precision = formats[unit] || formats.DEFAULT;
    return Number(value).toFixed(precision.split('.')[1]?.length || 0);
};

// Helper function to validate quantity ranges
export const validateQuantityRange = (quantity, unit) => {
    const ranges = {
        HR: { min: 0, max: 24 },
        DAY: { min: 0, max: 365 },
        MO: { min: 0, max: 60 },
        SF: { min: 0, max: 1000000 },
        SY: { min: 0, max: 111111 },
        CY: { min: 0, max: 100000 },
        LF: { min: 0, max: 100000 },
        TON: { min: 0, max: 10000 },
        GAL: { min: 0, max: 1000000 }
    };

    if (ranges[unit]) {
        const { min, max } = ranges[unit];
        if (quantity < min || quantity > max) {
            throw new Error(`Value must be between ${min} and ${max} for ${unit}`);
        }
    }

    return true;
};