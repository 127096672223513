import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import api from '../../services/api';
import { showToast } from '../../utils/toast';
import {
    CalendarIcon,
    CurrencyDollarIcon,
    ChevronRightIcon
} from '@heroicons/react/24/outline';
import BackButton from '../common/BackButton';

const ContractorProjects = () => {
    const [projects, setProjects] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [statusFilter, setStatusFilter] = useState('all');

    useEffect(() => {
        fetchProjects();
    }, []);

    const fetchProjects = async () => {
        try {
            const response = await api.get('contractor/projects/');
            // Ensure we're setting an array
            setProjects(Array.isArray(response.data.results) ? response.data.results : []);
        } catch (error) {
            console.error('Error fetching projects:', error);
            showToast.error('Failed to load projects');
        } finally {
            setIsLoading(false);
        }
    };

    const filteredProjects = projects
        .filter(project =>
            (project.name?.toLowerCase() || '').includes(searchTerm.toLowerCase()) ||
            (project.location?.toLowerCase() || '').includes(searchTerm.toLowerCase())
        )
        .filter(project =>
            statusFilter === 'all' ? true : project.status.toLowerCase() === statusFilter
        );

    if (isLoading) {
        return (
            <div className="flex justify-center items-center h-96">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-[#344bfc]"></div>
            </div>
        );
    }

    return (
        <div className="p-6">
            <BackButton />
            <div className="mb-6 flex flex-col sm:flex-row justify-between items-start sm:items-center space-y-4 sm:space-y-0">
                <h1 className="text-2xl font-bold text-gray-900">My Projects</h1>
                <div className="flex space-x-4">
                    <input
                        type="text"
                        placeholder="Search projects..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    />
                    <select
                        value={statusFilter}
                        onChange={(e) => setStatusFilter(e.target.value)}
                        className="rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    >
                        <option value="all">All Status</option>
                        <option value="in progress">In Progress</option>
                        <option value="on hold">On Hold</option>
                        <option value="completed">Completed</option>
                    </select>
                </div>
            </div>

            <div className="grid grid-cols-1 gap-6">
                {filteredProjects.map((project) => (
                    <Link
                        key={project.id}
                        to={`/contractor/projects/${project.id}`}
                        className="block hover:bg-gray-50"
                    >
                        <div className="bg-white shadow rounded-lg p-6">
                            <div className="flex items-center justify-between">
                                <div className="flex-1">
                                    <h3 className="text-lg font-semibold text-gray-900">
                                        {project.name}
                                    </h3>
                                    <p className="text-sm text-gray-500">{project.location}</p>
                                </div>
                                <ChevronRightIcon className="h-5 w-5 text-gray-400" />
                            </div>

                            <div className="mt-4 grid grid-cols-1 sm:grid-cols-3 gap-4">
                                <div className="flex items-center text-sm text-gray-500">
                                    <CalendarIcon className="h-5 w-5 mr-2" />
                                    <span>Start: {new Date(project.start_date).toLocaleDateString()}</span>
                                </div>
                                <div className="flex items-center text-sm text-gray-500">
                                    <CurrencyDollarIcon className="h-5 w-5 mr-2" />
                                    <span>Contract: ${(project.contract_amount || 0).toLocaleString()}</span>
                                </div>
                                <div className="flex items-center">
                                    <span className={`px-2 py-1 text-xs font-medium rounded-full ${project.status === 'active' ? 'bg-green-100 text-green-800' :
                                        project.status === 'on_hold' ? 'bg-yellow-100 text-yellow-800' :
                                            'bg-gray-100 text-gray-800'
                                        }`}>
                                        {project.status.charAt(0).toUpperCase() + project.status.slice(1)}
                                    </span>
                                </div>
                            </div>

                            <div className="mt-4">
                                <div className="w-full bg-gray-200 rounded-full h-2">
                                    <div
                                        className="bg-blue-600 h-2 rounded-full"
                                        style={{ width: `${project.progress}%` }}
                                    />
                                </div>
                                <p className="mt-1 text-sm text-gray-500">
                                    {project.progress}% Complete
                                </p>
                            </div>
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default ContractorProjects;