import React, { useState, useRef, useEffect } from 'react';
import { Button } from "../../../components/ui/button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../../components/ui/select";
import { Upload, X } from 'lucide-react';
import { showToast } from '../../../utils/toast';
import api from '../../../services/api';

const BidDocumentUpload = ({
    documents,
    setDocuments,
    documentTypes = []
}) => {
    const fileInputRef = useRef(null);

    const handleFileSelect = (e) => {
        if (!e.target.files?.length) return;

        const files = Array.from(e.target.files);
        files.forEach(file => {
            setDocuments(prev => [...prev, {
                file,
                name: file.name,
                documentType: null,
                status: 'pending'
            }]);
        });

        fileInputRef.current.value = '';
    };

    const handleTypeSelection = (index, typeId) => {
        setDocuments(prev => prev.map((doc, i) =>
            i === index ? { ...doc, documentType: typeId } : doc
        ));
    };

    const handleRemoveDocument = (index) => {
        setDocuments(prev => prev.filter((_, i) => i !== index));
    };

    return (
        <div className="space-y-4">
            <div className="border-2 border-dashed rounded-lg p-6 text-center">
                <input
                    type="file"
                    multiple
                    onChange={handleFileSelect}
                    className="hidden"
                    id="file-upload"
                    ref={fileInputRef}
                />
                <Button
                    type="button"
                    variant="ghost"
                    onClick={() => fileInputRef.current?.click()}
                    className="w-full h-full flex flex-col items-center"
                >
                    <Upload className="h-8 w-8 mb-2 text-gray-400" />
                    <span className="text-sm text-gray-600">
                        Click to upload or drag and drop
                    </span>
                </Button>
            </div>

            <div className="space-y-2">
                {documents.map((doc, index) => (
                    <div key={index} className="flex items-center gap-2 p-2 bg-gray-50 rounded">
                        <span className="text-sm truncate flex-1">
                            {doc.name}
                            {doc.status === 'pending' && ' (Select type)'}
                            {doc.status === 'ready' && ' (Ready)'}
                            {doc.status === 'uploaded' && ' (✓)'}
                            {doc.status === 'error' && ` (${doc.error || 'Upload failed'})`}
                        </span>
                        <Select
                            value={doc.documentType?.toString()}
                            onValueChange={(value) => handleTypeSelection(index, value)}
                            disabled={doc.status === 'uploaded'}
                        >
                            <SelectTrigger className="w-40">
                                <SelectValue placeholder="Select type" />
                            </SelectTrigger>
                            <SelectContent>
                                {documentTypes?.results?.map(type => (
                                    <SelectItem
                                        key={type.id}
                                        value={type.id.toString()}
                                    >
                                        {type.name} {type.is_required && '*'}
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                        <Button
                            type="button"
                            variant="ghost"
                            size="sm"
                            onClick={() => handleRemoveDocument(index)}
                        >
                            <X className="h-4 w-4" />
                        </Button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default BidDocumentUpload;

