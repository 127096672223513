import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from "../../../../components/ui/cardui";
import { Input } from "../../../../components/ui/input";
import { Label } from "../../../../components/ui/label";
import { Button } from "../../../../components/ui/button";
import { Upload, FileText, X, Loader2, Download } from 'lucide-react';
import { useToast } from "../../../../components/ui/use-toast";
import api from '../../../../services/api';
import { showToast } from '../../../../utils/toast';

const BidDocumentsTab = ({
    isEditing,
    loadingDocuments,
    uploading,
    bidId,
    documentTypes
}) => {
    const { toast } = useToast();
    const [uploadingLocal, setUploadingLocal] = useState(false);
    const [bidDocuments, setBidDocuments] = useState([]);
    const [uploadingDocument, setUploadingDocument] = useState(false);

    useEffect(() => {
        if (bidId) {
            fetchBidDocuments();
        }
    }, [bidId]);

    const fetchBidDocuments = async () => {
        try {
            if (!bidId) return;

            const response = await api.get('bid-documents/', {
                params: {
                    bid_id: bidId,
                    owner_type: 'request'
                }
            });

            const documents = response.data.results || [];
            const enrichedDocs = documents.map(doc => ({
                ...doc,
                typeInfo: documentTypes?.results?.find(t => t.id === doc.document_type)
            }));

            setBidDocuments(enrichedDocs);
        } catch (error) {
            console.error("Document fetch error:", error);
            showToast.error("Failed to fetch documents");
            setBidDocuments([]);
        }
    };

    const handleDocumentUpload = async (e, documentTypeId) => {
        const file = e.target.files[0];
        if (!file) return;

        const formData = new FormData();
        formData.append('file', file);
        formData.append('bid', bidId);
        formData.append('document_type', documentTypeId);
        formData.append('owner_type', 'request');

        try {
            setUploadingDocument(true);
            await api.post('bid-documents/', formData);
            await fetchBidDocuments();
            showToast.success("Document uploaded successfully");
        } catch (error) {
            showToast.error("Failed to upload document");
        } finally {
            setUploadingDocument(false);
        }
    };

    const handleDocumentDelete = async (docId) => {
        try {
            await api.delete(`bid-documents/${docId}/`);
            await fetchBidDocuments();
            showToast.success("Document deleted successfully");
        } catch (error) {
            showToast.error("Failed to delete document");
        }
    };

    const handleDocumentDownload = async (doc) => {
        try {
            const response = await api.get(`bid-documents/${doc.id}/download/`);
            window.open(response.data.download_url, '_blank');
        } catch (error) {
            showToast.error("Failed to download document");
        }
    };

    return (
        <Card>
            <CardHeader>
                <CardTitle>Bid Documents</CardTitle>
            </CardHeader>
            <CardContent>
                <div className="space-y-4">
                    {documentTypes?.results && (
                        <div className="space-y-4">
                            {documentTypes.results.map(type => (
                                <div key={type.id} className="p-4 border rounded">
                                    <div className="flex justify-between items-center">
                                        <div>
                                            <h4 className="font-medium">{type.name}</h4>
                                            <p className="text-sm text-gray-600">{type.description}</p>
                                        </div>
                                        <Input
                                            type="file"
                                            onChange={(e) => handleDocumentUpload(e, type.id)}
                                            className="hidden"
                                            id={`file-${type.id}`}
                                            disabled={uploadingDocument}
                                        />
                                        <Label htmlFor={`file-${type.id}`}>
                                            <Button disabled={uploadingDocument}>
                                                Upload
                                            </Button>
                                        </Label>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}

                    {bidDocuments.length > 0 && (
                        <div className="grid gap-4">
                            {bidDocuments.map((doc) => (
                                <div key={doc.id} className="flex flex-col sm:flex-row items-start justify-between p-4 bg-gray-50 rounded-lg">
                                    <div className="flex items-center gap-3 mb-3 sm:mb-0">
                                        <FileText className="h-5 w-5 text-gray-400 flex-shrink-0" />
                                        <div>
                                            <p className="font-medium">{doc.title}</p>
                                            <p className="text-sm text-gray-500">
                                                Type: {doc.typeInfo?.name || 'Unknown'}
                                            </p>
                                            <p className="text-sm text-gray-500">
                                                Added {new Date(doc.created_at).toLocaleDateString()}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex w-full sm:w-auto gap-2">
                                        <Button
                                            variant="outline"
                                            className="flex items-center gap-2 w-full sm:w-auto justify-center"
                                            onClick={() => handleDocumentDownload(doc)}
                                        >
                                            <Download className="h-4 w-4" />
                                            Download
                                        </Button>
                                        {isEditing && (
                                            <Button
                                                variant="ghost"
                                                size="sm"
                                                onClick={() => handleDocumentDelete(doc.id)}
                                                className="text-red-600 hover:text-red-800"
                                            >
                                                <X className="h-4 w-4" />
                                            </Button>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </CardContent>
        </Card>
    );
};

export default BidDocumentsTab;


