import React, { useState, useEffect } from 'react';
import { Card } from "../../../components/ui/cardui";
import { Badge } from "../../../components/ui/badge";
import { Button } from "../../../components/ui/button";
import { Input } from "../../../components/ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../../components/ui/select";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "../../../components/ui/dialog";
import { Label } from "../../../components/ui/label";
import { Trash2, Edit, Plus } from "lucide-react";
import ConfirmationDialog from '../../common/ConfirmationDialog';
import { primaryButtonClasses, secondaryButtonClasses } from '../../common/ButtonStyles';
import ActivityForm from './ActivityForm';
import { scheduleApi } from '../../../services/scheduleApi';
import api from '../../../services/api';
import { toast } from 'react-toastify';

const ActivityList = ({
    phases,
    onUpdate,
    onDelete,
    projectId,
    scheduleId,
    wbsId
}) => {
    const [editingActivity, setEditingActivity] = useState(null);
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [deleteConfirmation, setDeleteConfirmation] = useState(null);
    const [defaultWbs, setDefaultWbs] = useState(null);
    const [schedules, setSchedules] = useState([]);
    const [selectedSchedule, setSelectedSchedule] = useState(null);
    const [activities, setActivities] = useState([]);

    const createActivity = async (data) => {
        try {
            const start = new Date(data.planned_start);
            const end = new Date(data.planned_finish);
            const durationDays = Math.max(1, Math.ceil((end - start) / (1000 * 60 * 60 * 24)));

            const payload = {
                ...data,
                project: projectId,
                wbs: wbsId, // Use prop instead of defaultWbs.id
                schedule: scheduleId, // Use prop instead of selectedSchedule.id
                original_duration: durationDays,
                remaining_duration: durationDays
            };

            const response = await api.post(`projects/${projectId}/activities/`, payload);
            setActivities(prev => [...prev, response.data]);
            toast.success("Activity created successfully");
            return response.data;
        } catch (error) {
            console.log('Error response:', error.response?.data);
            throw error;
        }
    };

    const updateActivity = async (activityId, field, value) => {
        try {
            const response = await api.patch(`projects/${projectId}/activities/${activityId}/`, { [field]: value });
            setActivities(prevActivities =>
                prevActivities.map(activity =>
                    activity.id === activityId ? { ...activity, [field]: value } : activity
                )
            );
            return response.data;
        } catch (error) {
            console.error('Error updating activity:', error);
            throw error;
        }
    };

    const handleUpdateSubmit = async (data) => {
        try {
            const response = await api.patch(`projects/${projectId}/activities/${editingActivity.id}/`, data);
            setActivities(prevActivities =>
                prevActivities.map(activity =>
                    activity.id === editingActivity.id ? { ...activity, ...data } : activity
                )
            );
            toast.success("Activity updated successfully");
            setEditingActivity(null);
        } catch (error) {
            console.error('Error updating activity:', error);
            toast.error(error.response?.data?.detail || 'Failed to update activity');
        }
    };

    const handleCreateSubmit = async (data) => {
        await createActivity(data);
        setIsCreateModalOpen(false);
    };

    useEffect(() => {
        const fetchActivities = async () => {
            try {
                const response = await api.get(`projects/${projectId}/activities/`, {
                    params: { schedule: scheduleId, wbs: wbsId }
                });
                setActivities(response.data.results);
            } catch (error) {
                console.error('Error fetching activities:', error);
                toast.error('Failed to load activities');
            }
        };

        if (projectId && scheduleId && wbsId) {
            fetchActivities();
        }
    }, [projectId, scheduleId, wbsId]);


    return (
        <div className="space-y-4">
            {/*  <div className="flex justify-end">
                <Button
                    onClick={() => setIsCreateModalOpen(true)}
                    className={primaryButtonClasses}
                >
                    <Plus className="h-4 w-4 mr-2" />
                    Add Activity
                </Button>
            </div> */}

            {activities.map(activity => (
                <Card key={activity.id} className="p-4">
                    <div className="flex items-center justify-between">
                        <div className="flex items-center gap-2">
                            <span className="font-medium">{activity.name}</span>
                            {activity.is_critical && (
                                <Badge variant="destructive">Critical Path</Badge>
                            )}
                        </div>
                        <div className="flex gap-2">
                            <Button
                                variant="ghost"
                                size="icon"
                                onClick={() => setEditingActivity(activity)}
                            >
                                <Edit className="h-4 w-4" />
                            </Button>
                            <Button
                                variant="ghost"
                                size="icon"
                                onClick={() => setDeleteConfirmation(activity)}
                            >
                                <Trash2 className="h-4 w-4" />
                            </Button>
                        </div>
                    </div>

                    <div className="mt-2 grid grid-cols-2 gap-4 text-sm text-gray-500">
                        <div>Type: {activity.activity_type}</div>
                        <div>Priority: {activity.priority}</div>
                        <div>Start: {new Date(activity.planned_start).toLocaleDateString()}</div>
                        <div>End: {new Date(activity.planned_finish).toLocaleDateString()}</div>
                        <div>Duration: {activity.original_duration} days</div>
                        <div>Progress: {activity.percent_complete}%</div>
                    </div>
                </Card>
            ))}

            {/* Create/Edit Form */}
            <ActivityForm
                isOpen={isCreateModalOpen}
                onClose={() => setIsCreateModalOpen(false)}
                onSubmit={handleCreateSubmit}
                projectId={projectId}
                scheduleId={scheduleId}
                wbsId={wbsId}
                mode="create"
            />

            <ActivityForm
                isOpen={!!editingActivity}
                onClose={() => setEditingActivity(null)}
                onSubmit={handleUpdateSubmit}
                initialData={editingActivity}
                mode="edit"
            />

            {/* Delete Confirmation */}
            <ConfirmationDialog
                isOpen={!!deleteConfirmation}
                onClose={() => setDeleteConfirmation(null)}
                onConfirm={() => {
                    onDelete(deleteConfirmation.id);
                    setDeleteConfirmation(null);
                }}
                title="Delete Activity"
                message={`Are you sure you want to delete "${deleteConfirmation?.name}"? This action cannot be undone.`}
                confirmText="Delete"
                cancelText="Cancel"
                type="danger"
            />
        </div>
    );
};

export default ActivityList;