import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Tab } from '@headlessui/react';
import {
    CalendarIcon,
    ClipboardDocumentListIcon,
    DocumentIcon,
    UserGroupIcon
} from '@heroicons/react/24/outline';
import api from '../../services/api';
import { showToast } from '../../utils/toast';
import BackButton from '../common/BackButton';

const ContractorProjectDetail = () => {
    const { projectId } = useParams();
    const [project, setProject] = useState(null);
    const [tasks, setTasks] = useState([]);
    const [documents, setDocuments] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetchProjectData();
    }, [projectId]);

    const fetchProjectData = async () => {
        try {
            setIsLoading(true);
            const [projectResponse, tasksResponse, documentsResponse] = await Promise.all([
                api.get(`/contractor/projects/${projectId}/`),
                api.get(`/contractor/projects/${projectId}/tasks/`),
                api.get(`/contractor/projects/${projectId}/documents/`)
            ]);

            setProject(projectResponse.data);
            setTasks(tasksResponse.data);
            setDocuments(documentsResponse.data);
        } catch (error) {
            console.error('Error fetching project data:', error);
            showToast.error('Failed to load project data');
        } finally {
            setIsLoading(false);
        }
    };

    if (isLoading) {
        return (
            <div className="flex justify-center items-center h-96">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-[#344bfc]"></div>
            </div>
        );
    }

    if (!project) {
        return (
            <div className="p-6">
                <BackButton />
                <p>Project not found</p>
            </div>
        );
    }

    return (
        <div className="p-6">
            <BackButton />
            {/* Project Header */}
            <div className="mb-6">
                <div className="flex justify-between items-start">
                    <div>
                        <h1 className="text-2xl font-bold text-gray-900">{project.name}</h1>
                        <p className="text-gray-500">{project.location}</p>
                    </div>
                    <span className={`px-3 py-1 rounded-full text-sm font-medium ${project.status === 'active' ? 'bg-green-100 text-green-800' :
                        project.status === 'on_hold' ? 'bg-yellow-100 text-yellow-800' :
                            'bg-gray-100 text-gray-800'
                        }`}>
                        {project.status}
                    </span>
                </div>

                {/* Project Info Cards */}
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-6">
                    <div className="bg-white p-4 rounded-lg shadow">
                        <div className="flex items-center">
                            <CalendarIcon className="h-5 w-5 text-gray-400 mr-2" />
                            <div>
                                <p className="text-sm text-gray-500">Start Date</p>
                                <p className="font-medium">{new Date(project.start_date).toLocaleDateString()}</p>
                            </div>
                        </div>
                    </div>
                    <div className="bg-white p-4 rounded-lg shadow">
                        <div className="flex items-center">
                            <ClipboardDocumentListIcon className="h-5 w-5 text-gray-400 mr-2" />
                            <div>
                                <p className="text-sm text-gray-500">Tasks</p>
                                <p className="font-medium">{tasks.length} Assigned</p>
                            </div>
                        </div>
                    </div>
                    <div className="bg-white p-4 rounded-lg shadow">
                        <div className="flex items-center">
                            <DocumentIcon className="h-5 w-5 text-gray-400 mr-2" />
                            <div>
                                <p className="text-sm text-gray-500">Documents</p>
                                <p className="font-medium">{documents.length} Available</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Tabs */}
            <Tab.Group>
                <Tab.List className="flex space-x-1 rounded-xl bg-blue-900/20 p-1">
                    <Tab
                        className={({ selected }) =>
                            `w-full rounded-lg py-2.5 text-sm font-medium leading-5
               ${selected
                                ? 'bg-white text-blue-700 shadow'
                                : 'text-gray-600 hover:bg-white/[0.12] hover:text-blue-600'
                            }`
                        }
                    >
                        Tasks
                    </Tab>
                    <Tab
                        className={({ selected }) =>
                            `w-full rounded-lg py-2.5 text-sm font-medium leading-5
               ${selected
                                ? 'bg-white text-blue-700 shadow'
                                : 'text-gray-600 hover:bg-white/[0.12] hover:text-blue-600'
                            }`
                        }
                    >
                        Documents
                    </Tab>
                    <Tab
                        className={({ selected }) =>
                            `w-full rounded-lg py-2.5 text-sm font-medium leading-5
               ${selected
                                ? 'bg-white text-blue-700 shadow'
                                : 'text-gray-600 hover:bg-white/[0.12] hover:text-blue-600'
                            }`
                        }
                    >
                        Team
                    </Tab>
                </Tab.List>
                <Tab.Panels className="mt-4">
                    <Tab.Panel className="rounded-xl bg-white p-4">
                        <div className="space-y-4">
                            {tasks.map((task) => (
                                <div key={task.id} className="border rounded-lg p-4">
                                    <div className="flex justify-between items-start">
                                        <div>
                                            <h3 className="font-medium text-gray-900">{task.title}</h3>
                                            <p className="text-sm text-gray-500">{task.description}</p>
                                        </div>
                                        <span className={`px-2 py-1 text-xs font-medium rounded-full ${task.status === 'done' ? 'bg-green-100 text-green-800' :
                                            task.status === 'in_progress' ? 'bg-blue-100 text-blue-800' :
                                                'bg-yellow-100 text-yellow-800'
                                            }`}>
                                            {task.status}
                                        </span>
                                    </div>
                                    <div className="mt-2 flex items-center text-sm text-gray-500">
                                        <CalendarIcon className="h-4 w-4 mr-1" />
                                        Due: {new Date(task.due_date).toLocaleDateString()}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </Tab.Panel>
                    <Tab.Panel className="rounded-xl bg-white p-4">
                        <div className="space-y-4">
                            {documents.map((doc) => (
                                <div key={doc.id} className="border rounded-lg p-4 flex items-center justify-between">
                                    <div className="flex items-center">
                                        <DocumentIcon className="h-5 w-5 text-gray-400 mr-2" />
                                        <div>
                                            <p className="font-medium text-gray-900">{doc.title}</p>
                                            <p className="text-sm text-gray-500">
                                                Added: {new Date(doc.uploaded_at).toLocaleDateString()}
                                            </p>
                                        </div>
                                    </div>
                                    <button
                                        onClick={() => window.open(doc.file_url, '_blank')}
                                        className="text-blue-600 hover:text-blue-800"
                                    >
                                        View
                                    </button>
                                </div>
                            ))}
                        </div>
                    </Tab.Panel>
                    <Tab.Panel className="rounded-xl bg-white p-4">
                        <div className="space-y-4">
                            {project.team_members?.map((member) => (
                                <div key={member.id} className="border rounded-lg p-4 flex items-center">
                                    <UserGroupIcon className="h-5 w-5 text-gray-400 mr-2" />
                                    <div>
                                        <p className="font-medium text-gray-900">{member.name}</p>
                                        <p className="text-sm text-gray-500">{member.role}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </Tab.Panel>
                </Tab.Panels>
            </Tab.Group>
        </div>
    );
};

export default ContractorProjectDetail;