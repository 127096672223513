import CryptoJS from 'crypto-js';

//const SECRET_KEY = process.env.REACT_APP_STORAGE_KEY;

const ENV = process.env.NODE_ENV;
const SECRET_KEY = ENV === 'development'
    ? process.env.REACT_APP_DEV_STORAGE_KEY
    : process.env.REACT_APP_STORAGE_KEY;

export const encryptData = (data) => {
    return CryptoJS.AES.encrypt(data, SECRET_KEY).toString();
};

export const decryptData = (encryptedData) => {
    const bytes = CryptoJS.AES.decrypt(encryptedData, SECRET_KEY);
    return bytes.toString(CryptoJS.enc.Utf8);
};

export const setSecureItem = (key, value) => {
    try {
        sessionStorage.setItem(key, value);
    } catch (error) {
        console.error('Storage error:', error);
    }
};

export const getSecureItem = (key) => {
    try {
        const encryptedData = sessionStorage.getItem(key);
        if (!encryptedData) return null;
        return decryptData(encryptedData);
    } catch (error) {
        console.error('Retrieval error:', error);
        return null;
    }
};

export const removeSecureItem = (key) => {
    sessionStorage.removeItem(key);
};