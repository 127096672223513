import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Loader2, CheckCircle, XCircle } from 'lucide-react';
import api from '../services/api';
import { showToast } from '../utils/toast';
import secureLocalStorage from "react-secure-storage";

const CheckoutSuccessHandler = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [status, setStatus] = useState('verifying');
    const [error, setError] = useState(null);

    useEffect(() => {
        const verifyCheckoutSession = async () => {
            // Get URL parameters
            const params = new URLSearchParams(location.search);
            const sessionId = params.get('session_id');
            const featureType = params.get('feature_type');

            // If we have a feature type but no session ID, we can navigate directly
            if (featureType && !sessionId) {
                setStatus('success');

                // Use the feature type to determine redirect
                const redirectMap = {
                    'PROJECT': '/projects',
                    'SAFETY': '/safety',
                    'BID': '/bid-management',
                    'ALL': '/dashboard'
                };

                // Show success message
                showToast.success('Payment successful! Setting up your workspace...');

                // Redirect to the appropriate page after a short delay
                setTimeout(() => {
                    navigate(redirectMap[featureType] || '/dashboard');
                }, 2000);

                return;
            }

            // If no session ID and no feature type, try to get user's active feature from API
            if (!sessionId) {
                try {
                    // Try to get user's active feature type
                    const userResponse = await api.get('user/active-feature/');

                    if (userResponse.data && userResponse.data.feature_type) {
                        setStatus('success');

                        // Use the feature type from response to determine redirect
                        const userFeatureType = userResponse.data.feature_type;
                        const redirectMap = {
                            'PROJECT': '/projects',
                            'SAFETY': '/safety',
                            'BID': '/bid-management',
                            'ALL': '/dashboard'
                        };

                        // Redirect to the appropriate page after a short delay
                        setTimeout(() => {
                            navigate(redirectMap[userFeatureType] || '/dashboard');
                        }, 2000);

                        return;
                    } else {
                        // Default to dashboard if we can't determine feature type
                        setTimeout(() => {
                            navigate('/dashboard');
                        }, 2000);
                        return;
                    }
                } catch (error) {
                    console.error('Error getting user feature:', error);
                }
            }

            // Only proceed with session verification if sessionId is available
            if (sessionId) {
                try {
                    // Validate session ID format before sending to API
                    // Stripe session IDs typically start with 'cs_' for checkout sessions
                    if (!sessionId.startsWith('cs_') || sessionId.length > 66) {
                        setStatus('error');
                        setError('Invalid session ID format');
                        return;
                    }

                    // Call the API to verify the session without requiring authentication
                    const response = await api.post('verify-checkout-session-public/', {
                        session_id: sessionId
                    });

                    if (response.data.verified) {
                        // Session verified successfully
                        setStatus('success');

                        // Store the new tokens if provided
                        if (response.data.access && response.data.refresh) {
                            // Store tokens in localStorage
                            localStorage.setItem('token', response.data.access);
                            localStorage.setItem('refreshToken', response.data.refresh);

                            // Update API headers for subsequent calls
                            api.defaults.headers['Authorization'] = `Bearer ${response.data.access}`;

                            // Store user data with role
                            const userData = {
                                role_name: response.data.role,
                                feature_type: response.data.feature_type
                            };
                            secureLocalStorage.setItem('user', userData);
                            localStorage.setItem('userRole', response.data.role);
                        }

                        // Use the feature type from response to determine redirect
                        const featureType = response.data.feature_type;
                        const redirectMap = {
                            'PROJECT': '/projects',
                            'SAFETY': '/safety',
                            'BID': '/bid-management',
                            'ALL': '/dashboard'
                        };

                        // Show success message
                        showToast.success('Payment successful! Setting up your workspace...');

                        // Redirect to the appropriate page after a short delay
                        setTimeout(() => {
                            navigate(redirectMap[featureType] || '/dashboard');
                        }, 2000);
                    } else {
                        // Session verification failed
                        setStatus('error');
                        setError(response.data.message || 'Failed to verify checkout session');
                        showToast.error('Failed to verify payment. Please contact support.');
                    }
                } catch (error) {
                    console.error('Error verifying checkout session:', error);
                    setStatus('error');

                    if (error.response?.data?.error?.includes('Invalid string') ||
                        error.response?.data?.error?.includes('must be at most 66 characters')) {
                        setError('Invalid checkout session format');
                    } else {
                        setError(error.response?.data?.error || 'An unexpected error occurred');
                    }

                    showToast.error('Error verifying payment. Please contact support.');
                }
            }
        };

        verifyCheckoutSession();
    }, [location, navigate]);

    if (status === 'verifying') {
        return (
            <div className="min-h-screen flex flex-col items-center justify-center bg-gray-50 p-4">
                <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full text-center">
                    <Loader2 className="h-12 w-12 animate-spin mx-auto mb-4 text-blue-500" />
                    <h2 className="text-2xl font-bold mb-2">Verifying your payment...</h2>
                    <p className="text-gray-600">Please wait while we confirm your subscription.</p>
                </div>
            </div>
        );
    }

    if (status === 'success') {
        return (
            <div className="min-h-screen flex flex-col items-center justify-center bg-gray-50 p-4">
                <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full text-center">
                    <div className="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center mx-auto mb-4">
                        <CheckCircle className="h-8 w-8 text-green-500" />
                    </div>
                    <h2 className="text-2xl font-bold mb-2">Payment Successful!</h2>
                    <p className="text-gray-600 mb-4">Your subscription has been confirmed. Setting up your workspace...</p>
                    <div className="mt-2 h-2 w-full bg-gray-200 rounded-full">
                        <div className="h-full bg-blue-600 rounded-full transition-all duration-300 animate-pulse" style={{ width: '100%' }} />
                    </div>
                </div>
            </div>
        );
    }

    if (status === 'error') {
        return (
            <div className="min-h-screen flex flex-col items-center justify-center bg-gray-50 p-4">
                <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full text-center">
                    <div className="w-16 h-16 bg-red-100 rounded-full flex items-center justify-center mx-auto mb-4">
                        <XCircle className="h-8 w-8 text-red-500" />
                    </div>
                    <h2 className="text-2xl font-bold mb-2">Payment Verification Failed</h2>
                    <p className="text-gray-600 mb-4">{error || "We couldn't verify your payment. Please contact support."}</p>
                    <div className="space-y-4">
                        <button
                            onClick={() => navigate('/login')}
                            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 w-full"
                        >
                            Go to Login
                        </button>
                        <button
                            onClick={() => navigate('/register')}
                            className="px-4 py-2 border border-gray-300 rounded-md hover:bg-gray-50 w-full"
                        >
                            Sign Up
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    return null;
};

export default CheckoutSuccessHandler;