import React, { useState } from 'react';
import TaskBoard from './TaskBoard';
import TaskForm from './TaskForm';

const TaskManagement = () => {
    const [tasks, setTasks] = useState([]);
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [currentTask, setCurrentTask] = useState(null);

    // Function to open the task form (for new or existing task)
    const openTaskForm = (task = null) => {
        setCurrentTask(task);
        setIsFormOpen(true);
    };

    // Function to close the task form
    const closeTaskForm = () => {
        setIsFormOpen(false);
        setCurrentTask(null);
    };

    // Function to save or update a task
    const handleSaveTask = (newTask) => {
        if (newTask.id) {
            // Update an existing task
            setTasks((prevTasks) =>
                prevTasks.map((task) => (task.id === newTask.id ? newTask : task))
            );
        } else {
            // Add a new task
            newTask.id = tasks.length + 1; // Example ID generation
            setTasks((prevTasks) => [...prevTasks, newTask]);
        }
        closeTaskForm(); // Close the form after saving
    };

    return (
        <div>
            {/* TaskBoard should receive the onSaveTask prop */}
            <TaskBoard tasks={tasks} onOpenTaskForm={openTaskForm} onSaveTask={handleSaveTask} />

            {/* TaskForm for creating or editing tasks */}
            {isFormOpen && (
                <TaskForm
                    task={currentTask}
                    onSave={handleSaveTask}
                    onClose={closeTaskForm}
                />
            )}
        </div>
    );
};

export default TaskManagement;
