import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '../../../components/ui/cardui';
import { Button } from '../../../components/ui/button';
import { ClipboardCheck, AlertCircle, CheckCircle } from 'lucide-react';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import api from '../../../services/api';
import { useFeatureAccess } from '../../../contexts/FeatureAccessContext';


const QualityIntegrationComponent = ({ project }) => {
    const [qualityMetrics, setQualityMetrics] = useState(null);
    const [inspections, setInspections] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const { userRole, canAccessQualityFeatures } = useFeatureAccess();

    useEffect(() => {
        if (!project || !project.id) return;

        const fetchQualityData = async () => {
            try {
                setIsLoading(true);

                // Fetch quality issues metrics
                const metricsResponse = await api.get(`projects/${project.id}/quality-issues/dashboard_metrics/`);
                setQualityMetrics(metricsResponse.data);

                // Fetch recent inspections
                const inspectionsResponse = await api.get(`projects/${project.id}/inspections/`, {
                    params: { limit: 5, ordering: '-scheduled_date' }
                });

                setInspections(inspectionsResponse.data.results || []);
            } catch (error) {
                console.error('Error fetching quality data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchQualityData();
    }, [project]);

    if (!canAccessQualityFeatures()) {
        return null;
    }

    if (isLoading) {
        return <div className="py-4 text-center">Loading quality data...</div>;
    }

    // Format inspection date
    const formatDate = (dateString) => {
        return new Date(dateString).toLocaleDateString();
    };

    // Get status badge style
    const getStatusBadge = (status) => {
        const baseClass = "px-2 py-1 text-xs font-medium rounded-full";

        switch (status) {
            case 'passed':
                return `${baseClass} bg-green-100 text-green-800`;
            case 'failed':
                return `${baseClass} bg-red-100 text-red-800`;
            case 'scheduled':
                return `${baseClass} bg-blue-100 text-blue-800`;
            case 'in_progress':
                return `${baseClass} bg-yellow-100 text-yellow-800`;
            default:
                return `${baseClass} bg-gray-100 text-gray-800`;
        }
    };

    return (
        <div className="space-y-6 mt-8">
            <h3 className="text-xl font-semibold text-gray-800">Quality & Inspection Overview</h3>

            {/* Quality metrics summary */}
            {qualityMetrics && (
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
                    <Card>
                        <CardHeader className="pb-2">
                            <CardTitle className="text-sm font-medium text-gray-500 flex items-center">
                                <AlertCircle className="w-4 h-4 mr-2" />
                                Open Issues
                            </CardTitle>
                        </CardHeader>
                        <CardContent>
                            <p className="text-2xl font-semibold text-blue-600">{qualityMetrics.total_open || 0}</p>
                            <p className="text-xs text-gray-500">Total quality issues to address</p>
                        </CardContent>
                    </Card>

                    <Card>
                        <CardHeader className="pb-2">
                            <CardTitle className="text-sm font-medium text-gray-500 flex items-center">
                                <AlertCircle className="w-4 h-4 mr-2" />
                                Critical Issues
                            </CardTitle>
                        </CardHeader>
                        <CardContent>
                            <p className="text-2xl font-semibold text-red-600">{qualityMetrics.critical_issues || 0}</p>
                            <p className="text-xs text-gray-500">High priority items</p>
                        </CardContent>
                    </Card>

                    <Card>
                        <CardHeader className="pb-2">
                            <CardTitle className="text-sm font-medium text-gray-500 flex items-center">
                                <CheckCircle className="w-4 h-4 mr-2" />
                                Recently Resolved
                            </CardTitle>
                        </CardHeader>
                        <CardContent>
                            <p className="text-2xl font-semibold text-green-600">{qualityMetrics.recently_resolved || 0}</p>
                            <p className="text-xs text-gray-500">Resolved in past 7 days</p>
                        </CardContent>
                    </Card>

                    <Card>
                        <CardHeader className="pb-2">
                            <CardTitle className="text-sm font-medium text-gray-500 flex items-center">
                                <ClipboardCheck className="w-4 h-4 mr-2" />
                                Inspections Passed
                            </CardTitle>
                        </CardHeader>
                        <CardContent>
                            <p className="text-2xl font-semibold text-green-600">
                                {qualityMetrics.pass_rate ?
                                    `${Math.round(qualityMetrics.pass_rate)}%` :
                                    'N/A'}
                            </p>
                            <p className="text-xs text-gray-500">Pass rate for inspections</p>
                        </CardContent>
                    </Card>
                </div>
            )}

            {/* Quality severity distribution chart */}
            {qualityMetrics?.by_severity && qualityMetrics.by_severity.length > 0 && (
                <Card>
                    <CardHeader>
                        <CardTitle>Quality Issues by Severity</CardTitle>
                    </CardHeader>
                    <CardContent>
                        <ResponsiveContainer width="100%" height={200}>
                            <BarChart data={qualityMetrics.by_severity}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="severity" />
                                <YAxis />
                                <Tooltip />
                                <Bar dataKey="count" fill="#3B82F6" />
                            </BarChart>
                        </ResponsiveContainer>
                    </CardContent>
                </Card>
            )}

            {/* Recent inspections */}
            {inspections.length > 0 && (
                <Card>
                    <CardHeader>
                        <CardTitle>Recent Inspections</CardTitle>
                    </CardHeader>
                    <CardContent>
                        <div className="space-y-4">
                            {inspections.map(inspection => (
                                <div key={inspection.id} className="flex items-center justify-between border-b pb-2">
                                    <div>
                                        <p className="font-medium">{inspection.template_name || 'Inspection'}</p>
                                        <p className="text-sm text-gray-500">{formatDate(inspection.scheduled_date)}</p>
                                    </div>
                                    <span className={getStatusBadge(inspection.status)}>
                                        {inspection.status.replace('_', ' ')}
                                    </span>
                                </div>
                            ))}

                            <Button variant="outline" className="w-full mt-2" onClick={() => window.location.href = `/safety/${project.id}?tab=inspections`}>
                                View All Inspections
                            </Button>
                        </div>
                    </CardContent>
                </Card>
            )}

            {/* Issue remediation recommendations */}
            {qualityMetrics?.critical_issues > 0 && (
                <Card className="bg-amber-50 border-amber-200">
                    <CardHeader className="pb-2">
                        <CardTitle className="text-amber-800">Safety-Quality Action Items</CardTitle>
                    </CardHeader>
                    <CardContent>
                        <div className="space-y-2">
                            <p className="text-amber-800">
                                <span className="font-semibold">Critical Action Required:</span> There are {qualityMetrics.critical_issues} critical quality issues that may impact safety. Addressing these should be prioritized.
                            </p>
                            <Button variant="outline" className="bg-white border-amber-400 text-amber-800 hover:bg-amber-100">
                                Review Critical Issues
                            </Button>
                        </div>
                    </CardContent>
                </Card>
            )}
        </div>
    );
};

export default QualityIntegrationComponent;