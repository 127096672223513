import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogFooter
} from "../ui/dialog";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage
} from "../ui/form";
import { Card, CardHeader, CardTitle, CardContent } from "../ui/cardui";
import { Input } from "../ui/input";
import { Button } from "../ui/button";
import { Loader2 } from 'lucide-react';
import api from '../../services/api';
import { showToast } from '../../utils/toast';
import { primaryButtonClasses, secondaryButtonClasses } from '../common/ButtonStyles';

const CompanyCreationModal = ({ isOpen, onClose, onSuccess }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [trades, setTrades] = useState([]);

    const form = useForm({
        defaultValues: {
            name: '',
            business_id: '',
            address: '',
            phone: '',
            email: '',
            trades: [],
            license_number: '',
            license_expiry: '',
            insurance_expiry: ''
        }
    });

    useEffect(() => {
        fetchTrades();
    }, []);

    const fetchTrades = async () => {
        try {
            const response = await api.get('construction-trades/');
            setTrades(response.data || []);
        } catch (error) {
            showToast.error('Failed to load trades');
        }
    };

    const onSubmit = async (data) => {
        setIsSubmitting(true);
        try {
            const response = await api.post('companies/create_company/', {
                ...data,
                trades: Array.isArray(data.trades) ? data.trades : [data.trades]
            });
            showToast.success('Company created successfully');
            onSuccess(response.data);
            onClose();
        } catch (error) {
            const errorMessage = error.response?.data?.detail || 'Failed to create company';
            showToast.error(errorMessage);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className="sm:max-w-[600px] max-h-[90vh] overflow-y-auto">
                <DialogHeader>
                    <DialogTitle>Add New Company</DialogTitle>
                </DialogHeader>

                <Form {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
                        <Card>
                            <CardHeader>
                                <CardTitle>Company Information</CardTitle>
                            </CardHeader>
                            <CardContent className="space-y-4">
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                    <FormField
                                        control={form.control}
                                        name="name"
                                        rules={{ required: "Company name is required" }}
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormLabel>Company Name *</FormLabel>
                                                <FormControl>
                                                    <Input placeholder="Enter company name" {...field} />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />

                                    <FormField
                                        control={form.control}
                                        name="business_id"
                                        rules={{ required: "Business ID is required" }}
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormLabel>Business ID *</FormLabel>
                                                <FormControl>
                                                    <Input placeholder="Enter business ID" {...field} />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                </div>

                                <FormField
                                    control={form.control}
                                    name="address"
                                    rules={{ required: "Address is required" }}
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>Address *</FormLabel>
                                            <FormControl>
                                                <Input placeholder="Enter company address" {...field} />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />

                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                    <FormField
                                        control={form.control}
                                        name="phone"
                                        rules={{ required: "Phone is required" }}
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormLabel>Phone *</FormLabel>
                                                <FormControl>
                                                    <Input type="tel" placeholder="Phone number" {...field} />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />

                                    <FormField
                                        control={form.control}
                                        name="email"
                                        rules={{
                                            required: "Email is required",
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                message: "Invalid email address"
                                            }
                                        }}
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormLabel>Email *</FormLabel>
                                                <FormControl>
                                                    <Input type="email" placeholder="company@example.com" {...field} />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                </div>
                            </CardContent>
                        </Card>

                        <Card>
                            <CardHeader>
                                <CardTitle>Trade & Licensing</CardTitle>
                            </CardHeader>
                            <CardContent className="space-y-4">
                                <FormField
                                    control={form.control}
                                    name="trades"
                                    rules={{ required: "Please select at least one trade" }}
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>Trades *</FormLabel>
                                            <FormControl>
                                                <select
                                                    className="w-full rounded-md border border-input bg-background px-3 py-2 text-sm"
                                                    multiple
                                                    {...field}
                                                    onChange={(e) => field.onChange(
                                                        Array.from(e.target.selectedOptions, option => option.value)
                                                    )}
                                                >
                                                    {trades.map(trade => (
                                                        <option key={trade.id} value={trade.id}>
                                                            {trade.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />

                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                    <FormField
                                        control={form.control}
                                        name="license_number"
                                        rules={{ required: "License number is required" }}
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormLabel>License Number *</FormLabel>
                                                <FormControl>
                                                    <Input placeholder="Enter license number" {...field} />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />

                                    <FormField
                                        control={form.control}
                                        name="license_expiry"
                                        rules={{ required: "License expiry is required" }}
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormLabel>License Expiry *</FormLabel>
                                                <FormControl>
                                                    <Input type="date" {...field} />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                </div>

                                <FormField
                                    control={form.control}
                                    name="insurance_expiry"
                                    rules={{ required: "Insurance expiry is required" }}
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>Insurance Expiry *</FormLabel>
                                            <FormControl>
                                                <Input type="date" {...field} />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                            </CardContent>
                        </Card>

                        <DialogFooter className="gap-2 sm:gap-0">
                            <Button
                                type="button"
                                variant="outline"
                                onClick={onClose}
                                className={secondaryButtonClasses}
                            >
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                disabled={isSubmitting}
                                className={primaryButtonClasses}
                            >
                                {isSubmitting ? (
                                    <>
                                        <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                        Creating...
                                    </>
                                ) : 'Create Company'}
                            </Button>
                        </DialogFooter>
                    </form>
                </Form>
            </DialogContent>
        </Dialog>
    );
};

export default CompanyCreationModal;