import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { Search, Calendar, Filter, Trash, Edit, Eye, Table, Grid, Paperclip, X } from 'lucide-react';
import { Alert, AlertDescription } from "../ui/alert";
import { Progress } from "../ui/progress";
import TaskForm from './TaskForm';
import api from '../../services/api';
import { showToast } from '../../utils/toast';
import { primaryButtonClasses } from '../common/ButtonStyles';
import { useFeatureAccess, FEATURES } from '../../contexts/FeatureAccessContext.js';

const TaskBoard = () => {
    const [tasks, setTasks] = useState([]);
    const [filteredTasks, setFilteredTasks] = useState([]);
    const [selectedTask, setSelectedTask] = useState(null);
    const [isFormOpen, setFormOpen] = useState(false);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [isViewModalOpen, setViewModalOpen] = useState(false);
    const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
    const [viewMode, setViewMode] = useState('table');
    const [searchTerm, setSearchTerm] = useState('');
    const [dateFilter, setDateFilter] = useState('');
    const [statusFilter, setStatusFilter] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [projectTitle, setProjectTitle] = useState('');
    const [activities, setActivities] = useState([]);
    const [attachments, setAttachments] = useState([]);
    const [assignedUsers, setAssignedUsers] = useState([]);
    const [activityPage, setActivityPage] = useState(1);
    const [hasMoreActivities, setHasMoreActivities] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploading, setUploading] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [documentType, setDocumentType] = useState('');
    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const tasksPerPage = 10;
    const { projectId } = useParams();
    const [isLoadingDetails, setIsLoadingDetails] = useState(false);

    const { hasFeatureAccess } = useFeatureAccess();
    const canAccessTaskManagement = hasFeatureAccess(FEATURES.PROJECT_MANAGEMENT);

    // Function to configure API requests with the tenant ID header
    const configureRequest = (config = {}) => {
        const tenantId = localStorage.getItem('tenantId');
        return {
            ...config,
            headers: {
                ...config.headers,
                'X-Tenant-ID': tenantId,
            }
        };
    };

    useEffect(() => {
        if (canAccessTaskManagement) {
            fetchTasks();
        }
    }, [canAccessTaskManagement, projectId]);

    const fetchTasks = async () => {
        setIsLoading(true);
        setError(null);
        try {
            if (projectId) {
                const response = await api.get(`projects/${projectId}/tasks/`, configureRequest());
                setTasks(response.data.results || []);
                setFilteredTasks(response.data.results || []);

                const projectResponse = await api.get(`projects/${projectId}/`, configureRequest());
                setProjectTitle(projectResponse.data.name);
            }
        } catch (error) {
            console.error("Error fetching tasks or project title:", error);
            if (error.response?.status === 403) {
                setError("You don't have permission to access tasks for this project.");
            } else {
                setError("Failed to load tasks. Please try again later.");
            }
            setTasks([]);
            setFilteredTasks([]);
        } finally {
            setIsLoading(false);
        }
    };

    const submitComment = async () => {
        try {
            if (!newComment.trim()) {
                showToast.error('Please enter a comment');
                return;
            }

            // Use the correct endpoint from TaskViewSet with tenant header
            await api.post(`tasks/${selectedTask.id}/task-comments/`, {
                project_id: projectId,
                comment: newComment,
            }, configureRequest());

            // Refresh comments after posting
            const commentsResponse = await api.get(`task-comments/${selectedTask.id}/?project_id=${projectId}`, configureRequest());
            setComments(commentsResponse.data.results || []);

            // Clear the input
            setNewComment('');
            showToast.success('Comment added successfully');
        } catch (error) {
            console.error('Error creating comment:', error);
            showToast.error('Failed to add comment');
        }
    };

    useEffect(() => {
        const filtered = tasks.filter(task => {
            const matchesSearch = task.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                task.description.toLowerCase().includes(searchTerm.toLowerCase());
            const matchesDate = !dateFilter || task.due_date === dateFilter;
            const matchesStatus = !statusFilter || task.status === statusFilter;
            return matchesSearch && matchesDate && matchesStatus;
        });
        setFilteredTasks(filtered);
        setCurrentPage(1); // Reset to first page when filters change
    }, [tasks, searchTerm, dateFilter, statusFilter]);

    const handleFileSelect = (event) => {
        const files = Array.from(event.target.files);
        setSelectedFiles(prev => [...prev, ...files]);
    };

    const removeFile = (index) => {
        setSelectedFiles(prev => prev.filter((_, i) => i !== index));
    };

    const handleFileUpload = async (taskId) => {
        if (!documentType) {
            showToast.error('Please select a document type');
            return;
        }

        setUploading(true);
        setUploadProgress(0);

        try {
            const formData = new FormData();
            selectedFiles.forEach(file => {
                formData.append('attachments', file);
            });
            formData.append('document_type', documentType);

            // Add tenant header to upload request
            const response = await api.post(`tasks/${taskId}/task-attachments/`, formData, {
                ...configureRequest(),
                headers: {
                    ...configureRequest().headers,
                    'Content-Type': 'multipart/form-data',
                },
                onUploadProgress: (progressEvent) => {
                    const progress = (progressEvent.loaded / progressEvent.total) * 100;
                    setUploadProgress(progress);
                },
            });

            // Refresh attachments
            const attachmentsResponse = await api.get(`tasks/${taskId}/task-attachments/`, configureRequest());
            setAttachments(attachmentsResponse.data.results || []);

            showToast.success('Files uploaded successfully');
            setSelectedFiles([]);
            setUploadProgress(0);
            setIsUploadModalOpen(false);
            setDocumentType('');

        } catch (error) {
            console.error('Upload error:', error);
            showToast.error('Error uploading files');
        } finally {
            setUploading(false);
        }
    };

    const openViewTask = async (task) => {
        setSelectedTask(task);
        setViewModalOpen(true);
        setIsLoadingDetails(true);

        try {
            const fetchPromises = [
                api.get(`tasks/${task.id}/task-attachments/`, configureRequest()),
                api.get(`task-comments/${task.id}/?project_id=${projectId}`, configureRequest()),
                Promise.all(task.assigned_to.map(userId => api.get(`users/${userId.id}/`, configureRequest())))
            ];

            // Always fetch activities
            fetchPromises.unshift(api.get(`tasks/${task.id}/activities/?page=1&page_size=3`, configureRequest()));

            const [activitiesResponse, attachmentsResponse, commentsResponse, userResponses] = await Promise.all(fetchPromises);

            if (activitiesResponse.data) {
                setActivities(activitiesResponse.data);
                setHasMoreActivities(activitiesResponse.data.next !== null);
            }
            setActivityPage(1);
            setAttachments(attachmentsResponse.data || []);
            setComments(commentsResponse.data.results || []);
            setAssignedUsers(userResponses.map(response => response.data));
        } catch (error) {
            console.error("Error fetching task details:", error);
            setActivities([]);
            setAttachments([]);
            setComments([]);
            setAssignedUsers([]);
        } finally {
            setIsLoadingDetails(false);
        }
    };

    const loadMoreActivities = async () => {
        try {
            const nextPage = activityPage + 1;
            const response = await api.get(`tasks/${selectedTask.id}/activities/?page=${nextPage}&page_size=5`, configureRequest());

            // Check if we have new activities
            if (response.data && response.data.results) {
                // Get the new activities
                const newActivities = response.data.results;

                // Update activities ensuring no duplicates by using Set
                const uniqueActivities = [...new Set([...activities, ...newActivities].map(a => JSON.stringify(a)))]
                    .map(a => JSON.parse(a));

                setActivities(uniqueActivities);
                setHasMoreActivities(response.data.next !== null);
                setActivityPage(nextPage);
            }
        } catch (error) {
            console.error("Error loading more activities:", error);
            showToast.error("Error loading more activities");
        }
    };

    const handleDeleteTask = async () => {
        try {
            await api.delete(`tasks/${selectedTask.id}/`, configureRequest());
            setTasks(tasks.filter(task => task.id !== selectedTask.id));
            fetchTasks();
            setDeleteModalOpen(false);
            showToast.success('Task deleted successfully');
        } catch (error) {
            console.error('Error deleting task:', error);
            showToast.error('Error deleting task');
        }
    };

    const handleSaveTask = async (taskData, config = {}) => {
        try {
            if (taskData.has('dependency')) {
                taskData.delete('dependency');
            }
            // Ensure dependencies are in the correct format
            const dependencyIds = selectedTask?.dependency?.map(dep => dep.id || dep) || [];
            dependencyIds.forEach(dependencyId => {
                taskData.append('dependency', dependencyId);
            });

            // Add tenant header to the request
            const mergedConfig = {
                ...configureRequest(),
                ...config,
                headers: {
                    ...configureRequest().headers,
                    ...config.headers,
                    'Content-Type': 'multipart/form-data',
                }
            };

            let response;
            if (selectedTask?.id) {
                response = await api.put(`tasks/${selectedTask.id}/`, taskData, mergedConfig);
            } else {
                response = await api.post(`projects/${projectId}/tasks/`, taskData, mergedConfig);
            }

            showToast.success(selectedTask ? "Task updated successfully" : "Task created successfully");

            // Refresh task list
            fetchTasks();
            setFormOpen(false);
        } catch (error) {
            console.error("Error saving task:", error);
            console.log('Error response:', error.response?.data); // Debug log

            const errorMessage = error.response?.data;
            showToast.error(errorMessage);
        }
    };

    if (isLoading) {
        return (
            <div className="flex items-center justify-center p-8">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
            </div>
        );
    }

    if (error) {
        return (
            <Alert>
                <AlertDescription>
                    {error}
                </AlertDescription>
            </Alert>
        );
    }

    if (!canAccessTaskManagement) {
        return (
            <Alert>
                <AlertDescription>
                    Please upgrade your subscription to access task management features.
                    <a href="/subscription" className="text-blue-600 hover:text-blue-800 ml-2">
                        Upgrade now
                    </a>
                </AlertDescription>
            </Alert>
        );
    }

    const formatStatus = (status) => {
        return status?.replace(/_/g, ' ').toLowerCase().replace(/(^|\s)\S/g, L => L.toUpperCase());
    };

    // Pagination calculations
    const indexOfLastTask = currentPage * tasksPerPage;
    const indexOfFirstTask = indexOfLastTask - tasksPerPage;
    const currentTasks = filteredTasks.slice(indexOfFirstTask, indexOfLastTask);
    const totalPages = Math.ceil(filteredTasks.length / tasksPerPage);

    const renderFileUploadModal = () => (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-2xl">
                <div className="flex justify-between items-center mb-6">
                    <h2 className="text-2xl font-bold">Upload Attachments</h2>
                    <button
                        onClick={() => setIsUploadModalOpen(false)}
                        className="text-gray-500 hover:text-gray-700"
                    >
                        <X size={24} />
                    </button>
                </div>

                <div className="space-y-6">
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-2">Document Type</label>
                        <select
                            value={documentType}
                            onChange={(e) => setDocumentType(e.target.value)}
                            className="w-full p-2 border border-gray-300 rounded-md"
                        >
                            <option value="">Select Document Type</option>
                            <option value="Blueprint">Blueprint</option>
                            <option value="RFI">Request for Information</option>
                            <option value="Change Order">Change Order</option>
                            <option value="Permit">Permit</option>
                            <option value="Submittal">Submittal</option>
                            <option value="Contract">Contract</option>
                            <option value="Inspection Report">Inspection Report</option>
                            <option value="Other">Other</option>
                        </select>
                    </div>

                    <div>
                        <input
                            type="file"
                            multiple
                            onChange={handleFileSelect}
                            className="hidden"
                            id="file-upload"
                        />
                        <label
                            htmlFor="file-upload"
                            className="inline-block px-4 py-2 bg-blue-600 text-white rounded-lg cursor-pointer hover:bg-blue-700"
                        >
                            Select Files
                        </label>
                    </div>

                    {selectedFiles.length > 0 && (
                        <div>
                            <h4 className="font-semibold mb-2">Selected Files:</h4>
                            <ul className="space-y-2">
                                {selectedFiles.map((file, index) => (
                                    <li key={index} className="flex items-center justify-between bg-gray-100 p-2 rounded">
                                        <span className="truncate">{file.name}</span>
                                        <button
                                            onClick={() => removeFile(index)}
                                            className="text-red-600 hover:text-red-800"
                                        >
                                            <X size={16} />
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}

                    {uploading && (
                        <div>
                            <Progress value={uploadProgress} className="w-full" />
                            <span className="text-sm text-gray-600">{Math.round(uploadProgress)}% uploaded</span>
                        </div>
                    )}

                    <div className="flex justify-end space-x-4">
                        <button
                            onClick={() => setIsUploadModalOpen(false)}
                            className="px-4 py-2 text-gray-600 bg-gray-200 rounded-lg hover:bg-gray-300"
                        >
                            Cancel
                        </button>
                        <button
                            onClick={() => handleFileUpload(selectedTask.id)}
                            disabled={selectedFiles.length === 0 || uploading || !documentType}
                            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:opacity-50"
                        >
                            Upload
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );

    const renderViewModal = () => (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-4xl max-h-[90vh] overflow-y-auto">
                {/* Header section */}
                <div className="flex justify-between items-center mb-6">
                    <h2 className="text-2xl font-bold">{selectedTask?.title}</h2>
                    <button
                        onClick={() => setViewModalOpen(false)}
                        className="text-gray-500 hover:text-gray-700"
                    >
                        <X size={24} />
                    </button>
                </div>

                {isLoadingDetails ? (
                    <div className="flex items-center justify-center py-8">
                        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
                    </div>
                ) : (
                    <>
                        {/* Task Details section */}
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
                            <div>
                                <h3 className="font-semibold mb-2">Description</h3>
                                <p className="text-gray-700">{selectedTask?.description}</p>
                            </div>

                            <div>
                                <h3 className="font-semibold mb-2">Details</h3>
                                <p><span className="font-medium">Due Date:</span> {selectedTask?.due_date && format(new Date(selectedTask.due_date), 'MMM d, yyyy')}</p>
                                <p><span className="font-medium">Status:</span> {formatStatus(selectedTask?.status)}</p>
                                <p>
                                    <span className="font-medium">Assigned To:</span> {' '}
                                    {assignedUsers && assignedUsers.length > 0
                                        ? assignedUsers.map(user => user.first_name) + ' ' + assignedUsers.map(user => user.last_name)
                                        : 'No users assigned'}
                                </p>
                            </div>
                        </div>

                        {/* Attachments Section */}
                        <div className="mb-6">
                            <div className="flex justify-between items-center mb-4">
                                <h3 className="text-lg font-semibold">Attachments</h3>
                                <button
                                    onClick={() => setIsUploadModalOpen(true)}
                                    className="flex items-center text-blue-600 hover:text-blue-800"
                                >
                                    <Paperclip size={16} className="mr-2" />
                                    Add Attachment
                                </button>
                            </div>

                            {attachments && attachments.length > 0 ? (
                                <ul className="space-y-2">
                                    {attachments.map((attachment, index) => (
                                        <li key={index} className="flex items-center justify-between bg-gray-100 p-3 rounded">
                                            <div className="flex items-center">
                                                <Paperclip size={16} className="mr-2" />
                                                <span>{attachment.file_name}</span>
                                            </div>
                                            <a
                                                href={attachment.file_url}
                                                download
                                                className="text-blue-600 hover:text-blue-800"
                                            >
                                                Download
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                <p className="text-gray-500">No attachments yet</p>
                            )}
                        </div>

                        {/* Comments Section */}
                        <div className="mb-6">
                            <h3 className="text-lg font-semibold mb-4">Comments</h3>

                            {/* Add Comment Form */}
                            <div className="mb-4">
                                <textarea
                                    value={newComment}
                                    onChange={(e) => setNewComment(e.target.value)}
                                    placeholder="Write a comment..."
                                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                                    rows={3}
                                />
                                <button
                                    onClick={submitComment}
                                    className={primaryButtonClasses}
                                >
                                    Add Comment
                                </button>
                            </div>

                            {/* Comments List */}
                            <div className="space-y-4">
                                {Array.isArray(comments) && comments.length > 0 ? (
                                    comments.slice(0, 10).map((comment, index) => (
                                        <div key={index} className="bg-gray-50 p-4 rounded-lg">
                                            <div className="flex justify-between items-start">
                                                <p className="text-gray-800">{comment.comment}</p>
                                                <span className="text-sm text-gray-500">
                                                    {new Date(comment.created_at).toLocaleString()}
                                                </span>
                                            </div>
                                            <p className="text-sm text-gray-600 mt-1">
                                                By {comment.user || 'Anonymous'}
                                            </p>
                                        </div>
                                    ))
                                ) : (
                                    <p className="text-gray-500">No comments yet</p>
                                )}

                                {Array.isArray(comments) && comments.length > 10 && (
                                    <button
                                        className="text-blue-600 hover:text-blue-800 font-medium"
                                        onClick={() => {/* Load more comments logic */ }}
                                    >
                                        View More Comments
                                    </button>
                                )}
                            </div>
                        </div>

                        {/* Activities Section */}
                        <div className="mb-6">
                            <h3 className="text-lg font-semibold mb-4">Activities</h3>
                            {activities && activities.length > 0 ? (
                                <div className="space-y-4">
                                    {activities.map((activity, index) => (
                                        <div key={`${activity.id}-${index}`} className="border-l-4 border-blue-500 pl-4 py-2">
                                            <div className="flex justify-between items-start">
                                                <div>
                                                    <p className="font-medium">{activity.action}</p>
                                                    {activity.changes && (
                                                        <div className="mt-1 text-sm text-gray-600">
                                                            {Object.entries(activity.changes).map(([field, change]) => (
                                                                <p key={field}>
                                                                    <span className="font-medium">{field}:</span>{' '}
                                                                    {typeof change.old === 'object'
                                                                        ? `Users updated`
                                                                        : `${change.old || 'none'} → ${change.new || 'none'}`
                                                                    }
                                                                </p>
                                                            ))}
                                                        </div>
                                                    )}
                                                    <p className="text-sm text-gray-500">by {activity.performed_by}</p>
                                                </div>
                                                <span className="text-sm text-gray-500">
                                                    {new Date(activity.created_at).toLocaleString()}
                                                </span>
                                            </div>
                                        </div>
                                    ))}
                                    {hasMoreActivities && (
                                        <button
                                            onClick={loadMoreActivities}
                                            className="w-full text-blue-600 hover:text-blue-800 py-2 text-center"
                                        >
                                            Load More Activities
                                        </button>
                                    )}
                                </div>
                            ) : (
                                <p className="text-gray-500">No activities recorded yet</p>
                            )}
                        </div>
                    </>
                )}
            </div>
        </div>
    );

    return (
        <div className="container mx-auto px-4 py-8">
            <div className="flex justify-between items-center mb-8">
                <h1 className="text-3xl font-bold text-gray-800">{`Tasks For ${projectTitle || "Task Board"}`}</h1>
                <div className="flex items-center space-x-4">
                    <button
                        onClick={() => setViewMode('board')}
                        className={`p-2 ${viewMode === 'board' ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-600'} rounded-full transition duration-300 ease-in-out`}
                    >
                        <Grid size={20} />
                    </button>
                    <button
                        onClick={() => setViewMode('table')}
                        className={`p-2 ${viewMode === 'table' ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-600'} rounded-full transition duration-300 ease-in-out`}
                    >
                        <Table size={20} />
                    </button>
                    <button
                        className="bg-blue-600 hover:bg-blue-700 text-white font-semibold px-6 py-2 rounded-full transition duration-300 ease-in-out transform hover:scale-105"
                        onClick={() => {
                            setSelectedTask(null);
                            setFormOpen(true);
                        }}
                    >
                        + New Task
                    </button>
                </div>
            </div>

            <div className="mb-6 flex flex-wrap gap-4">
                <div className="relative flex-grow">
                    <input
                        type="text"
                        placeholder="Search tasks..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                    <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
                </div>
                <div className="relative">
                    <input
                        type="date"
                        value={dateFilter}
                        onChange={(e) => setDateFilter(e.target.value)}
                        className="pl-10 pr-4 py-2 border border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                    <Calendar className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
                </div>
                <div className="relative">
                    <select
                        value={statusFilter}
                        onChange={(e) => setStatusFilter(e.target.value)}
                        className="pl-10 pr-4 py-2 border border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500 appearance-none"
                    >
                        <option value="">All Statuses</option>
                        <option value="not_started">Not Started</option>
                        <option value="in_progress">In Progress</option>
                        <option value="done">Done</option>
                    </select>
                    <Filter className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
                </div>
            </div>

            {viewMode === 'table' ? (
                <div className="overflow-auto rounded-lg shadow">
                    <table className="w-full table-auto bg-white rounded-lg">
                        <thead className="bg-gray-200 border-b">
                            <tr>
                                <th className="px-4 py-2 text-left text-gray-600 font-semibold">Title</th>
                                <th className="px-4 py-2 text-left text-gray-600 font-semibold">Status</th>
                                <th className="px-4 py-2 text-left text-gray-600 font-semibold">Due Date</th>
                                <th className="px-4 py-2 text-center text-gray-600 font-semibold">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentTasks.length > 0 ? (
                                currentTasks.map(task => (
                                    <tr key={task.id} className="hover:bg-gray-100 transition duration-200">
                                        <td className="px-4 py-3">{task.title}</td>
                                        <td className="px-5 py-3">
                                            <span className={`px-3 py-1 rounded-full text-sm font-medium ${task.status === 'not_started' ? 'bg-yellow-200 text-yellow-800' :
                                                    task.status === 'in_progress' ? 'bg-blue-200 text-blue-800' :
                                                        'bg-green-200 text-green-800'
                                                }`}>
                                                {formatStatus(task.status)}
                                            </span>
                                        </td>
                                        <td className="px-4 py-3">{format(new Date(task.due_date), 'MMM d, yyyy')}</td>
                                        <td className="px-4 py-3 text-center flex space-x-2 justify-center">
                                            <button
                                                onClick={() => openViewTask(task)}
                                                className="text-gray-500 hover:text-gray-700"
                                            >
                                                <Eye size={20} />
                                            </button>
                                            <button
                                                onClick={() => {
                                                    setSelectedTask(task);
                                                    setFormOpen(true);
                                                }}
                                                className="text-blue-500 hover:text-blue-700"
                                            >
                                                <Edit size={20} />
                                            </button>
                                            <button
                                                onClick={() => {
                                                    setSelectedTask(task);
                                                    setDeleteModalOpen(true);
                                                }}
                                                className="text-red-500 hover:text-red-700"
                                            >
                                                <Trash size={20} />
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="5" className="text-center py-4 text-gray-500 text-lg">
                                        No tasks available
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>

                    <div className="flex justify-center items-center py-4 bg-white">
                        {Array.from({ length: totalPages }, (_, index) => (
                            <button
                                key={index}
                                onClick={() => setCurrentPage(index + 1)}
                                className={`mx-1 px-3 py-1 rounded ${currentPage === index + 1
                                    ? 'bg-blue-600 text-white'
                                    : 'bg-gray-200 text-gray-600 hover:bg-gray-300'
                                    }`}
                            >
                                {index + 1}
                            </button>
                        ))}
                    </div>
                </div>
            ) : (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {currentTasks.map(task => (
                        <div
                            key={task.id}
                            className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition duration-300 ease-in-out cursor-pointer"
                            onClick={() => openViewTask(task)}
                        >
                            <div className="flex justify-between items-center mb-4">
                                <h3 className="text-xl font-semibold text-gray-800">{task.title}</h3>
                                <span className={`px-3 py-1 rounded-full text-sm font-medium ${task.status === 'not_started' ? 'bg-yellow-200 text-yellow-800' :
                                    task.status === 'in_progress' ? 'bg-blue-200 text-blue-800' :
                                        'bg-green-200 text-green-800'
                                    }`}>
                                    {formatStatus(task.status)}
                                </span>
                            </div>
                            <p className="text-gray-600 mb-4">{task.description}</p>
                            <div className="text-sm text-gray-500">
                                Due: {format(new Date(task.due_date), 'MMM d, yyyy')}
                            </div>
                        </div>
                    ))}
                </div>
            )}

            {/* Delete Confirmation Modal */}
            {isDeleteModalOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="bg-white p-8 rounded-lg shadow-lg w-96">
                        <h2 className="text-xl font-bold mb-4">Confirm Delete</h2>
                        <p>Are you sure you want to delete this task?</p>
                        <div className="flex justify-end mt-6 space-x-4">
                            <button
                                onClick={() => setDeleteModalOpen(false)}
                                className="px-4 py-2 bg-gray-300 rounded-md hover:bg-gray-400 transition duration-200"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleDeleteTask}
                                className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 transition duration-200"
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {/* Task Form Modal */}
            {isFormOpen && (
                <TaskForm
                    onClose={() => setFormOpen(false)}
                    onSave={handleSaveTask}
                    task={selectedTask}
                    projectId={projectId}
                    configureRequest={configureRequest}
                />
            )}

            {/* View Task Modal */}
            {isViewModalOpen && renderViewModal()}

            {/* File Upload Modal */}
            {isUploadModalOpen && renderFileUploadModal()}
        </div>
    );
};

export default TaskBoard;