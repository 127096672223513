import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '../../../components/ui/cardui';
import { Button } from '../../../components/ui/button';
import { Textarea } from '../../../components/ui/textarea';
import { Badge } from '../../../components/ui/badge';
import { Loader2, MessageCircle, Check, X } from 'lucide-react';
import { useToast } from '../../../components/ui/use-toast';
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
    DialogFooter,
} from '../../../components/ui/dialog';
import { Alert, AlertDescription } from '../../../components/ui/alert';
import api from '../../../services/api';


const BidRFIPanel = ({ bidRequestId, isManager = false }) => {
    const [rfis, setRfis] = useState([]);
    const [loading, setLoading] = useState(true);
    const [newQuestion, setNewQuestion] = useState('');
    const [selectedRFI, setSelectedRFI] = useState(null);
    const [answer, setAnswer] = useState('');
    const { toast } = useToast();


    useEffect(() => {
        let mounted = true;

        const fetchRFIs = async () => {
            try {
                setLoading(true);
                const url = isManager && bidRequestId ?
                    `bid-rfis/?bid_request=${bidRequestId}` :
                    'bid-rfis/';

                const response = await api.get(url);


                if (mounted) {
                    const rfiData = Array.isArray(response.data) ?
                        response.data :
                        response.data.results || [];
                    setRfis(rfiData);
                }
            } catch (error) {
                console.error('Error fetching RFIs:', error);
                if (mounted) {
                    toast({
                        title: "Error",
                        description: "Failed to load RFIs",
                        variant: "destructive"
                    });
                }
            } finally {
                if (mounted) {
                    setLoading(false);
                }
            }
        };

        fetchRFIs();

        return () => {
            mounted = false;
        };
    }, [bidRequestId, isManager]);

    // Create a refresh function outside useEffect for reuse
    const refreshRFIs = async () => {
        try {
            setLoading(true);
            const url = isManager && bidRequestId ?
                `bid-rfis/?bid_request=${bidRequestId}` :
                'bid-rfis/';

            const response = await api.get(url);
            const rfiData = Array.isArray(response.data) ?
                response.data :
                response.data.results || [];
            setRfis(rfiData);
        } catch (error) {
            console.error('Error refreshing RFIs:', error);
            toast({
                title: "Error",
                description: "Failed to refresh RFIs",
                variant: "destructive"
            });
        } finally {
            setLoading(false);
        }
    };

    const handleSubmitAnswer = async () => {
        try {
            await api.patch(`bid-rfis/${selectedRFI.id}/submit_answer/`, {
                answer: answer
            });
            setSelectedRFI(null);
            setAnswer('');
            toast({
                title: "Success",
                description: "Answer submitted successfully"
            });
            await refreshRFIs();
        } catch (error) {
            toast({
                title: "Error",
                description: "Failed to submit answer",
                variant: "destructive"
            });
        }
    };

    const handleCloseRFI = async (rfiId) => {
        try {
            await api.patch(`bid-rfis/${rfiId}/close_rfi/`);
            toast({
                title: "Success",
                description: "RFI closed successfully"
            });
            await refreshRFIs();
        } catch (error) {
            toast({
                title: "Error",
                description: "Failed to close RFI",
                variant: "destructive"
            });
        }
    };

    const handleSubmitQuestion = async () => {
        try {
            await api.post('bid-rfis/', {
                bid_request: bidRequestId,
                question: newQuestion
            });
            setNewQuestion('');
            toast({
                title: "Success",
                description: "Question submitted successfully"
            });
            await refreshRFIs();
        } catch (error) {
            toast({
                title: "Error",
                description: "Failed to submit question",
                variant: "destructive"
            });
        }
    };






    const getStatusBadge = (status) => {
        const styles = {
            pending: "bg-yellow-100 text-yellow-800",
            answered: "bg-green-100 text-green-800",
            closed: "bg-gray-100 text-gray-800"
        };
        return styles[status] || "bg-gray-100 text-gray-800";
    };

    return (
        <Card className="w-full">
            <CardHeader>
                <CardTitle className="flex justify-between items-center">
                    <span>RFI Management</span>
                    {!isManager && (
                        <Button
                            onClick={() => setNewQuestion('')}
                            className="bg-blue-600 hover:bg-blue-700"
                        >
                            <MessageCircle className="w-4 h-4 mr-2" />
                            New Question
                        </Button>
                    )}
                </CardTitle>
            </CardHeader>
            <CardContent>
                {loading ? (
                    <div className="flex justify-center py-4">
                        <Loader2 className="h-6 w-6 animate-spin" />
                    </div>
                ) : (
                    <div className="space-y-4">
                        {!isManager && newQuestion !== null && (
                            <div className="space-y-2">
                                <Textarea
                                    value={newQuestion}
                                    onChange={(e) => setNewQuestion(e.target.value)}
                                    placeholder="Type your question here..."
                                    className="min-h-[100px]"
                                />
                                <div className="flex justify-end gap-2">
                                    <Button
                                        variant="outline"
                                        onClick={() => setNewQuestion(null)}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        onClick={handleSubmitQuestion}
                                        disabled={!newQuestion.trim()}
                                    >
                                        Submit Question
                                    </Button>
                                </div>
                            </div>
                        )}

                        {rfis.map((rfi) => (
                            <Card key={rfi.id} className="p-4">
                                <div className="space-y-2">
                                    <div className="flex justify-between items-start">
                                        <div>
                                            <p className="font-medium">{rfi.contractor_name}</p>
                                            <p className="text-sm text-gray-500">
                                                {new Date(rfi.submitted_at).toLocaleDateString()}
                                            </p>
                                        </div>
                                        <Badge className={getStatusBadge(rfi.status)}>
                                            {rfi.status.toUpperCase()}
                                        </Badge>
                                    </div>

                                    <Alert>
                                        <AlertDescription>{rfi.question}</AlertDescription>
                                    </Alert>

                                    {rfi.answer && (
                                        <Alert className="bg-blue-50 border-blue-200">
                                            <AlertDescription>
                                                <p className="font-medium mb-1">Answer:</p>
                                                {rfi.answer}
                                                <p className="text-sm text-gray-500 mt-2">
                                                    Answered by: {rfi.answered_by_name}
                                                </p>
                                            </AlertDescription>
                                        </Alert>
                                    )}

                                    <div className="flex justify-end gap-2">
                                        {isManager && rfi.status === 'pending' && (
                                            <Button
                                                onClick={() => setSelectedRFI(rfi)}
                                                className="bg-blue-600 hover:bg-blue-700"
                                            >
                                                Answer
                                            </Button>
                                        )}
                                        {!isManager && rfi.status === 'answered' && (
                                            <Button
                                                onClick={() => handleCloseRFI(rfi.id)}
                                                variant="outline"
                                            >
                                                <Check className="w-4 h-4 mr-2" />
                                                Close RFI
                                            </Button>
                                        )}
                                    </div>
                                </div>
                            </Card>
                        ))}

                        {rfis.length === 0 && (
                            <p className="text-center text-gray-500 py-4">
                                No RFIs found
                            </p>
                        )}
                    </div>
                )}

                <Dialog open={!!selectedRFI} onOpenChange={(open) => !open && setSelectedRFI(null)}>
                    <DialogContent>
                        <DialogHeader>
                            <DialogTitle>Answer RFI</DialogTitle>
                        </DialogHeader>
                        <div className="space-y-4">
                            <Alert>
                                <AlertDescription>{selectedRFI?.question}</AlertDescription>
                            </Alert>
                            <Textarea
                                value={answer}
                                onChange={(e) => setAnswer(e.target.value)}
                                placeholder="Type your answer here..."
                                className="min-h-[100px]"
                            />
                        </div>
                        <DialogFooter>
                            <Button variant="outline" onClick={() => setSelectedRFI(null)}>
                                Cancel
                            </Button>
                            <Button onClick={handleSubmitAnswer} disabled={!answer.trim()}>
                                Submit Answer
                            </Button>
                        </DialogFooter>
                    </DialogContent>
                </Dialog>
            </CardContent>
        </Card>
    );
};

export default BidRFIPanel;