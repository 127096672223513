import React from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "../../../components/ui/dialog";
import { Card, CardContent } from "../../../components/ui/cardui";
import { Label } from "../../../components/ui/label";
import { Input } from "../../../components/ui/input";
import { Checkbox } from "../../../components/ui/checkbox";
import { Star } from 'lucide-react';

const BidComparisonDialog = ({
    showComparison,
    setShowComparison,
    comparisonCriteria,
    setComparisonCriteria,
    handleCompareBids
}) => {
    return (
        <Dialog open={showComparison} onOpenChange={setShowComparison}>
            <DialogContent className="max-w-[95vw] sm:max-w-4xl">
                <DialogHeader>
                    <DialogTitle className="text-lg sm:text-xl">Bid Response Comparison</DialogTitle>
                </DialogHeader>
                <div className="space-y-6 overflow-y-auto max-h-[80vh]">
                    {/* Comparison criteria weights */}
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 p-4 border rounded-lg bg-gray-50">
                        {Object.entries(comparisonCriteria).map(([criterion, { weight, enabled }]) => (
                            <div key={criterion} className="space-y-2">
                                <div className="flex items-center justify-between">
                                    <Label className="text-sm sm:text-base">
                                        {criterion.charAt(0).toUpperCase() + criterion.slice(1)}
                                    </Label>
                                    <Checkbox
                                        checked={enabled}
                                        onCheckedChange={(checked) => {
                                            setComparisonCriteria(prev => ({
                                                ...prev,
                                                [criterion]: { ...prev[criterion], enabled: checked }
                                            }));
                                        }}
                                    />
                                </div>
                                <Input
                                    type="range"
                                    min="0"
                                    max="1"
                                    step="0.1"
                                    value={weight}
                                    onChange={(e) => {
                                        setComparisonCriteria(prev => ({
                                            ...prev,
                                            [criterion]: { ...prev[criterion], weight: parseFloat(e.target.value) }
                                        }));
                                    }}
                                    disabled={!enabled}
                                    className="w-full"
                                />
                                <span className="text-sm text-gray-500 block text-right">
                                    {(weight * 100).toFixed(0)}%
                                </span>
                            </div>
                        ))}
                    </div>

                    {/* Comparison results */}
                    <div className="space-y-4">
                        {handleCompareBids().map((bid, index) => (
                            <Card key={bid.id}>
                                <CardContent className="p-4">
                                    <div className="flex flex-col sm:flex-row items-start gap-4">
                                        <span className="text-2xl font-bold">{index + 1}</span>
                                        <div className="flex-1 w-full">
                                            <h3 className="font-medium text-base sm:text-lg mb-2">
                                                {bid.contractor_name}
                                            </h3>
                                            <div className="grid grid-cols-1 sm:grid-cols-4 gap-4">
                                                <div>
                                                    <p className="text-sm text-gray-500">Price</p>
                                                    <p className="font-medium">${bid.price.toLocaleString()}</p>
                                                </div>
                                                <div>
                                                    <p className="text-sm text-gray-500">Timeline</p>
                                                    <p className="font-medium">{bid.timeline} days</p>
                                                </div>
                                                <div>
                                                    <p className="text-sm text-gray-500">Quality</p>
                                                    <div className="flex items-center">
                                                        {Array.from({ length: 5 }).map((_, i) => (
                                                            <Star
                                                                key={i}
                                                                className={`h-4 w-4 ${i < bid.quality_rating
                                                                    ? 'text-yellow-400 fill-current'
                                                                    : 'text-gray-300'
                                                                    }`}
                                                            />
                                                        ))}
                                                    </div>
                                                </div>
                                                <div>
                                                    <p className="text-sm text-gray-500">Total Score</p>
                                                    <p className="font-medium">{bid.scores.total.toFixed(1)}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                        ))}
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default BidComparisonDialog;