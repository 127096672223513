import React, { useState } from 'react';
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "../../../components/ui/dialog";
import { Input } from "../../../components/ui/input";
import { Label } from "../../../components/ui/label";
import { Button } from "../../../components/ui/button";
import BidDocumentUpload from './BidDocumentUpload';
import { primaryButtonClasses, secondaryButtonClasses } from '../../common/ButtonStyles';

const ContractAwardDialog = ({
    open,
    onClose,
    onSubmit,
    documentTypes,
    bidRequestId,
    bidSubmissionId,
    status
}) => {
    const [email, setEmail] = useState('');
    const [documents, setDocuments] = useState([]);
    const [loading, setLoading] = useState(false);



    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const formData = new FormData();
            // Use the status prop instead of hardcoding
            formData.append('status', status);
            formData.append('contract_email', email);

            // Explicitly include IDs (fixing UUID issue)
            formData.append('bid', bidRequestId);
            formData.append('bid_submission', bidSubmissionId);

            // Add documents preserving all document data
            if (documents && documents.length > 0) {
                documents.forEach((doc, index) => {
                    if (doc && doc.file) {
                        formData.append(`documents[${index}][file]`, doc.file);
                        formData.append(`documents[${index}][type]`, doc.type);
                        formData.append(`documents[${index}][owner_type]`, 'submission');
                    }
                });
            }


            for (let [key, value] of formData.entries()) {
                //console.log(key, value);
            }

            await onSubmit(formData);
            onClose();
        } catch (error) {
            console.error('Error in handleSubmit:', error);
            console.error('Error response:', error.response?.data);
        } finally {
            setLoading(false);
        }
    };

    const handleClose = () => {
        setDocuments([]);
        setEmail('');
        onClose();
    };

    return (
        <Dialog open={open} onOpenChange={handleClose}>
            <DialogContent className="sm:max-w-[600px]">
                <DialogHeader>
                    <DialogTitle>Award Bid and Upload Contracts</DialogTitle>
                    <DialogDescription>
                        Enter the email address for contract communication and upload the contract documents.
                        The contractor will be notified to sign and return the contracts to this email.
                    </DialogDescription>
                </DialogHeader>
                <form onSubmit={handleSubmit}>
                    <div className="grid gap-4 py-4">
                        <div className="grid gap-2">
                            <Label htmlFor="email">Contract Email</Label>
                            <Input
                                id="email"
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Enter email address"
                                required
                            />
                        </div>
                        <div className="grid gap-2">
                            <Label>Contract Documents</Label>
                            <BidDocumentUpload
                                documents={documents}
                                setDocuments={setDocuments}
                                documentTypes={documentTypes}
                                ownerType="submission"
                                bidRequestId={bidRequestId}
                                submissionId={bidSubmissionId}
                            />
                        </div>
                    </div>
                    <DialogFooter>
                        <Button className={secondaryButtonClasses} type="button" variant="outline" onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button
                            className={primaryButtonClasses}
                            type="submit"
                            disabled={loading || documents.length === 0 || documents.some(doc => !doc.documentType)}
                        >
                            {loading ? "Awarding..." : "Award Bid"}
                        </Button>
                    </DialogFooter>
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default ContractAwardDialog;