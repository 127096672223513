import React, { useState, useEffect } from 'react';
import {
    PlusIcon,
    ArrowPathIcon,
    BuildingOfficeIcon,
    XMarkIcon,
    TrashIcon,
    PencilIcon,
    EnvelopeIcon,
    StarIcon,
    BuildingOffice2Icon,
    PencilSquareIcon,
    BuildingLibraryIcon
} from '@heroicons/react/24/solid';

import { StarIcon as StarIconOutline } from '@heroicons/react/24/outline';
import api from '../../services/api';
import { showToast } from '../../utils/toast';
import ContractorForm from './ContractorForm';
import ContractorInviteForm from './ContractorInviteForm';
import ConfirmationDialog from '../common/ConfirmationDialog';
import CompanyCreationModal from './CompanyCreationModal';
import RatingForm from './RatingForm';
import { primaryButtonClasses, secondaryButtonClasses } from '../common/ButtonStyles';
import CompanyDetailModal from './CompanyDetailModal';

const ContractorCard = ({ contractor, onCompanyClick, onRate, onInvite, onEdit, onDelete }) => {
    const averageRating = (ratings) => {
        if (!ratings?.length) return 0;
        return ratings.reduce((acc, curr) => acc + curr.rating, 0) / ratings.length;
    };

    return (
        <div className="flex items-center justify-between p-4 border rounded-lg hover:bg-gray-50">
            <div className="flex items-center space-x-4">
                <div className="flex-shrink-0">
                    <div className="h-12 w-12 rounded-full bg-blue-100 flex items-center justify-center">
                        <BuildingOffice2Icon className="h-6 w-6 text-blue-600" />
                    </div>
                </div>
                <div>
                    <h3 className="text-lg font-medium text-gray-900">
                        {contractor.company ? (
                            <button
                                onClick={() => onCompanyClick(contractor.company)}
                                className="text-blue-600 hover:text-blue-800 hover:underline focus:outline-none"
                            >
                                {contractor.company.name || 'Unnamed Company'}
                            </button>
                        ) : (
                            <span className="text-gray-500">No Company</span>
                        )}
                    </h3>
                    <p className="text-sm text-gray-500">
                        {contractor.user.first_name} {contractor.user.last_name}
                    </p>
                    <div className="flex items-center mt-1">
                        <div className="flex items-center">
                            {[1, 2, 3, 4, 5].map((star) => (
                                <StarIcon
                                    key={star}
                                    className={`h-4 w-4 ${star <= averageRating(contractor.ratings)
                                        ? 'text-yellow-400 fill-current'
                                        : 'text-gray-300'
                                        }`}
                                />
                            ))}
                            <span className="ml-1 text-sm text-gray-600">
                                ({contractor.ratings?.length || 0})
                            </span>
                        </div>
                    </div>
                </div>
                {contractor.trades && (
                    <div className="flex flex-wrap gap-2 ml-4">
                        {contractor.trades.map(trade => (
                            <span
                                key={trade.id}
                                className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800"
                            >
                                {trade.name}
                            </span>
                        ))}
                    </div>
                )}
            </div>
            <div className="flex items-center space-x-2">
                <button
                    onClick={() => onRate(contractor)}
                    className="p-2 text-gray-400 hover:text-yellow-400 transition-colors"
                    title="Rate Contractor"
                >
                    <StarIcon className="h-5 w-5" />
                </button>
                <button
                    onClick={() => onInvite(contractor)}
                    className="p-2 text-gray-400 hover:text-blue-600 transition-colors"
                    title="Send Invitation"
                >
                    <EnvelopeIcon className="h-5 w-5" />
                </button>
                <button
                    onClick={() => onEdit(contractor)}
                    className="p-2 text-gray-400 hover:text-blue-600 transition-colors"
                    title="Edit Contractor"
                >
                    <PencilSquareIcon className="h-5 w-5" />
                </button>
                <button
                    onClick={() => onDelete(contractor)}
                    className="p-2 text-gray-400 hover:text-red-600 transition-colors"
                    title="Delete Contractor"
                >
                    <TrashIcon className="h-5 w-5" />
                </button>
            </div>
        </div>
    );
};

const ContractorManagement = () => {
    // Local state
    const [contractors, setContractors] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [isPanelOpen, setIsPanelOpen] = useState(false);
    const [currentTab, setCurrentTab] = useState('details');
    const [currentContractor, setCurrentContractor] = useState(null);
    const [isCompanyCreationModalOpen, setIsCompanyCreationModalOpen] = useState(false);
    const [isCompanyDetailModalOpen, setIsCompanyDetailModalOpen] = useState(false);
    const [isRatingFormOpen, setIsRatingFormOpen] = useState(false);
    const [selectedContractorForRating, setSelectedContractorForRating] = useState(null);
    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        contractor: null
    });
    const [selectedCompany, setSelectedCompany] = useState(null);

    // Fetch contractors on mount
    useEffect(() => {
        fetchContractors();
    }, []);

    const averageRating = (ratings) => {
        if (!ratings?.length) return 0;
        return ratings.reduce((acc, curr) => acc + curr.rating, 0) / ratings.length;
    };

    const renderStars = (rating) => (
        <div className="flex">
            {[1, 2, 3, 4, 5].map((star) => (
                <span key={star}>
                    {star <= rating ? (
                        <StarIcon className="h-4 w-4 text-yellow-400" />
                    ) : (
                        <StarIconOutline className="h-4 w-4 text-yellow-400" />
                    )}
                </span>
            ))}
        </div>
    );

    const fetchContractors = async () => {
        setLoading(true);
        try {
            // First get contractors
            const contractorsResponse = await api.get('contractors/');
            const contractors = contractorsResponse.data.results;

            // Get unique company IDs
            const companyIds = [...new Set(contractors
                .map(c => c.company)
                .filter(id => id))];

            // Fetch company details for all companies
            const companiesDetails = await Promise.all(
                companyIds.map(id => api.get(`companies/${id}`))
            );

            // Create a map of company details
            const companyMap = companiesDetails.reduce((acc, response) => {
                const company = response.data;
                acc[company.id] = company;
                return acc;
            }, {});

            // Combine contractor data with company details
            const transformedContractors = contractors.map(contractor => ({
                ...contractor,
                company: contractor.company ? companyMap[contractor.company] : null
            }));

            setContractors(transformedContractors);
        } catch (error) {
            console.error('Error fetching data:', error);
            setError('Failed to load contractors');
            showToast.error('Failed to load contractors');
        } finally {
            setLoading(false);
        }
    };

    const deactivateContractor = async (contractorId) => {
        try {
            await api.delete(`contractors/${contractorId}/`);
            setContractors(prev => prev.filter(c => c.id !== contractorId));
            showToast.success('Contractor deactivated successfully');
        } catch (err) {
            showToast.error('Failed to deactivate contractor');
        }
    };

    // Handlers
    const handleAddNew = () => {
        setCurrentContractor(null);
        setCurrentTab('details');
        setIsPanelOpen(true);
    };

    const handleInvite = (contractor) => {
        setCurrentContractor(contractor);
        setCurrentTab('invite');
        setIsPanelOpen(true);
    };

    const handleEdit = (contractor) => {
        setCurrentContractor(contractor);
        setCurrentTab('details');
        setIsPanelOpen(true);
    };

    const handleClose = () => {
        setCurrentContractor(null);
        setIsPanelOpen(false);
    };

    const handleRating = (contractor) => {
        setSelectedContractorForRating(contractor);
        setIsRatingFormOpen(true);
    };

    const handleDeactivate = (contractor) => {
        setConfirmDialog({
            isOpen: true,
            contractor
        });
    };

    const confirmDeactivation = async () => {
        if (confirmDialog.contractor) {
            await deactivateContractor(confirmDialog.contractor.id);
            setConfirmDialog({ isOpen: false, contractor: null });
        }
    };

    const handleFormSubmit = async () => {
        await fetchContractors();  // Refresh list after form submission
        handleClose();
    };

    const handleCompanyClick = (company) => {
        if (!company) return;
        setSelectedCompany(company);
        setIsCompanyDetailModalOpen(true);
    };


    const renderContractorList = () => (
        <div className="bg-white shadow rounded-lg p-6">
            <h2 className="text-xl font-semibold text-gray-900 mb-4">Contractor List</h2>
            <div className="space-y-4">
                {contractors.map((contractor) => (
                    <div
                        key={contractor.id}
                        className="flex items-center justify-between p-4 border rounded-lg hover:bg-gray-50"
                    >
                        <div className="flex items-center space-x-4">
                            <div className="flex-shrink-0">
                                <div className="h-12 w-12 rounded-full bg-blue-100 flex items-center justify-center">
                                    <BuildingOfficeIcon className="h-6 w-6 text-blue-600" />
                                </div>
                            </div>
                            <div>
                                <h3 className="text-lg font-medium text-gray-900">
                                    {/* {contractor.company?.name || 'No Company'} */}
                                    <button
                                        onClick={() => handleCompanyClick(contractor.company)}
                                        className="hover:text-blue-600 hover:underline"
                                    >
                                        {contractor.company?.name || 'No Company'}
                                    </button>
                                </h3>
                                <p className="text-sm text-gray-500">
                                    {contractor.user.first_name} {contractor.user.last_name}
                                </p>
                                <p className="text-sm text-gray-500">
                                    {contractor.contractor_id}
                                </p>
                            </div>
                            {contractor.trades && (
                                <div className="flex flex-wrap gap-2 ml-4">
                                    {contractor.trades.map(trade => (
                                        <span
                                            key={trade.id}
                                            className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800"
                                        >
                                            {trade.name}
                                        </span>
                                    ))}
                                </div>
                            )}
                        </div>
                        <div className="flex items-center space-x-2">
                            <button
                                onClick={() => handleEdit(contractor)}
                                className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                            >
                                Edit
                            </button>
                            <button
                                onClick={() => handleDeactivate(contractor)}
                                className="inline-flex items-center px-3 py-2 border border-red-300 shadow-sm text-sm leading-4 font-medium rounded-md text-red-700 bg-white hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                            >
                                <TrashIcon className="h-4 w-4 mr-1" />
                                Deactivate
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );

    const renderSidePanel = () => (
        <div className="fixed inset-0 overflow-hidden z-50">
            <div className="absolute inset-0 overflow-hidden">
                <div
                    className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                    onClick={handleClose}
                />
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                    <div className="pointer-events-auto w-screen max-w-2xl"> {/* Made wider */}
                        <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                            <div className="flex-1 overflow-y-auto py-6 px-4 sm:px-6">
                                <div className="flex items-start justify-between">
                                    <h2 className="text-lg font-medium text-gray-900">
                                        {currentContractor ? 'Edit Contractor' : 'Add New Contractor'}
                                    </h2>
                                    <button
                                        type="button"
                                        className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                                        onClick={handleClose}
                                    >
                                        <span className="sr-only">Close panel</span>
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>

                                <div className="mt-8">
                                    <div className="border-b border-gray-200">
                                        <nav className="-mb-px flex space-x-8">
                                            <button
                                                onClick={() => setCurrentTab('details')}
                                                className={`${currentTab === 'details'
                                                    ? 'border-blue-500 text-blue-600'
                                                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                                                    } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm`}
                                            >
                                                Contractor Details
                                            </button>
                                            <button
                                                onClick={() => setCurrentTab('invite')}
                                                className={`${currentTab === 'invite'
                                                    ? 'border-blue-500 text-blue-600'
                                                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                                                    } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm`}
                                            >
                                                Send Invitation
                                            </button>
                                        </nav>
                                    </div>

                                    <div className="mt-6">
                                        {currentTab === 'details' ? (
                                            <ContractorForm
                                                contractor={currentContractor}
                                                onSubmit={handleFormSubmit}  // Pass the refresh handler
                                                onClose={handleClose}
                                            />
                                        ) : (
                                            <ContractorInviteForm onClose={handleClose} />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <div className="space-y-6 p-6">
            {/* Header */}
            <div className="flex justify-between items-center">
                <h1 className="text-2xl font-semibold text-gray-900">
                    Contractor Management
                </h1>
                <div className="flex space-x-3">
                    <button
                        onClick={() => setIsCompanyCreationModalOpen(true)}
                        // onClick={() => setIsCompanyModalOpen(true)}
                        className={secondaryButtonClasses}
                    >
                        <PlusIcon className="h-5 w-5 mr-2" />
                        Company
                    </button>
                    <button
                        onClick={handleAddNew}
                        className={primaryButtonClasses}
                    >
                        <PlusIcon className="h-5 w-5 mr-2" />
                        Contractor
                    </button>
                    <button
                        onClick={fetchContractors}
                        className={secondaryButtonClasses}
                        disabled={loading}
                    >
                        <ArrowPathIcon
                            className={`h-5 w-5 mr-2 ${loading ? 'animate-spin' : ''}`}
                        />
                        Refresh
                    </button>
                </div>
            </div>

            {/* Error message - kept from original */}
            {error && (
                <div className="rounded-md bg-red-50 p-4">
                    <div className="flex">
                        <div className="ml-3">
                            <h3 className="text-sm font-medium text-red-800">{error}</h3>
                        </div>
                    </div>
                </div>
            )}

            {/* Contractor list - enhanced version */}
            {loading ? (
                <div className="flex justify-center py-8">
                    <ArrowPathIcon className="h-8 w-8 animate-spin text-gray-400" />
                </div>
            ) : (
                <div className="bg-white shadow rounded-lg p-6">
                    <div className="space-y-4">
                        {contractors.map((contractor) => (
                            <ContractorCard
                                key={contractor.id}
                                contractor={contractor}
                                onCompanyClick={handleCompanyClick}
                                onRate={(c) => handleRating(c)}
                                onInvite={(c) => handleInvite(c)}
                                onEdit={(c) => handleEdit(c)}
                                onDelete={(c) => handleDeactivate(c)}
                            />
                        ))}
                        {/* {contractors.map((contractor) => (
                            <div
                                key={contractor.id}
                                className="flex items-center justify-between p-4 border rounded-lg hover:bg-gray-50"
                            >
                                <div className="flex items-center space-x-4">
                                    <div className="h-12 w-12 rounded-full bg-blue-100 flex items-center justify-center">
                                        <BuildingOfficeIcon className="h-6 w-6 text-blue-600" />
                                    </div>
                                    <div>
                                        <h3 className="text-lg font-medium text-gray-900">
                                            {contractor.company?.name || 'No Company'}
                                        </h3>
                                        <p className="text-sm text-gray-500">
                                            {contractor.user.first_name} {contractor.user.last_name}
                                        </p>
                                        <div className="flex items-center mt-1">
                                            {renderStars(averageRating(contractor.ratings))}
                                            <span className="ml-2 text-sm text-gray-500">
                                                ({contractor.ratings?.length || 0})
                                            </span>
                                        </div>
                                    </div>
                                    {contractor.trades && (
                                        <div className="flex flex-wrap gap-2 ml-4">
                                            {contractor.trades.map(trade => (
                                                <span
                                                    key={trade.id}
                                                    className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800"
                                                >
                                                    {trade.name}
                                                </span>
                                            ))}
                                        </div>
                                    )}
                                </div>
                                <div className="flex items-center space-x-2">
                                    <button
                                        onClick={() => handleRating(contractor)}
                                        className="inline-flex items-center p-2 border border-gray-300 rounded-md text-gray-700 bg-white hover:bg-gray-50"
                                    >
                                        <StarIcon className="h-4 w-4" />
                                    </button>
                                    <button
                                        onClick={() => handleInvite(contractor)}
                                        className="inline-flex items-center p-2 border border-gray-300 rounded-md text-gray-700 bg-white hover:bg-gray-50"
                                    >
                                        <EnvelopeIcon className="h-4 w-4" />
                                    </button>
                                    <button
                                        onClick={() => handleEdit(contractor)}
                                        className="inline-flex items-center p-2 border border-gray-300 rounded-md text-gray-700 bg-white hover:bg-gray-50"
                                    >
                                        <PencilIcon className="h-4 w-4" />
                                    </button>
                                    <button
                                        onClick={() => handleDeactivate(contractor)}
                                        className="inline-flex items-center p-2 border border-red-300 rounded-md text-red-700 bg-white hover:bg-red-50"
                                    >
                                        <TrashIcon className="h-4 w-4" />
                                    </button>
                                </div>
                            </div>
                        ))} */}
                    </div>
                </div>
            )}

            {/* Keep original side panel implementation */}
            {isPanelOpen && renderSidePanel()}

            {/* New modals */}
            {isCompanyCreationModalOpen && (
                <CompanyCreationModal
                    isOpen={isCompanyCreationModalOpen}
                    onClose={() => setIsCompanyCreationModalOpen(false)}
                    onSuccess={fetchContractors}
                />
            )}

            {isRatingFormOpen && (
                <RatingForm
                    contractor={selectedContractorForRating}
                    onClose={() => {
                        setIsRatingFormOpen(false);
                        setSelectedContractorForRating(null);
                    }}
                    onSuccess={fetchContractors}
                />
            )}

            {/* Keep original confirmation dialog */}
            <ConfirmationDialog
                isOpen={confirmDialog.isOpen}
                onClose={() => setConfirmDialog({ isOpen: false, contractor: null })}
                onConfirm={confirmDeactivation}
                title="Deactivate Contractor"
                message={`Are you sure you want to deactivate ${confirmDialog.contractor?.company?.name || 'this contractor'}?`}
                confirmText="Deactivate"
                cancelText="Cancel"
                type="danger"
            />
            {selectedCompany && (
                <CompanyDetailModal
                    company={selectedCompany}
                    isOpen={isCompanyDetailModalOpen}
                    onClose={() => {
                        setIsCompanyDetailModalOpen(false);
                        setSelectedCompany(null);
                    }}
                    onSuccess={fetchContractors}
                />
            )}
            {/*  <CompanyDetailModal
                company={selectedCompany}
                isOpen={!!selectedCompany}
                onClose={() => setSelectedCompany(null)}
                onSuccess={fetchContractors}
            /> */}
        </div>
    );
};

export default ContractorManagement;