import React, { useState, useEffect, useMemo } from 'react';
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
} from '../components/ui/cardui';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from '../components/ui/table';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '../components/ui/select';
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
    DialogFooter,
} from '../components/ui/dialog';
import { Button } from '../components/ui/button';
import { Input } from '../components/ui/input';
import { Label } from '../components/ui/label';
import { Badge } from '../components/ui/badge';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../components/ui/tabs';
import { toast } from 'react-toastify';
import { Progress } from '../components/ui/progress';
import { Pencil, Plus, Calendar, Users, Award, BarChart, Eye } from 'lucide-react';
import api from '../services/api';
import safetyApi from '../services/safetyService';
import { primaryButtonClasses, secondaryButtonClasses } from '../components/common/ButtonStyles';

const SafetyTrainingManager = ({ project, userRole }) => {
    const [isEditingEmployee, setIsEditingEmployee] = useState(false);
    const [isEditingTraining, setIsEditingTraining] = useState(false);
    const [isEditingCertification, setIsEditingCertification] = useState(false);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [selectedTraining, setSelectedTraining] = useState(null);
    const [selectedCertification, setSelectedCertification] = useState(null);
    const [attendanceStatus, setAttendanceStatus] = useState({});
    const [editCertForm, setEditCertForm] = useState({
        certification_number: '',
        issue_date: '',
        expiry_date: '',
        status: ''
    });
    const [viewingTrainingType, setViewingTrainingType] = useState(null);
    const [trainingTypeDetails, setTrainingTypeDetails] = useState({
        employees: [],
        compliance: 0,
        lastCompleted: null
    });
    const [selectedTrainingType, setSelectedTrainingType] = useState(null);
    const [trainings, setTrainings] = useState([]);
    const [certifications, setCertifications] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isAddingTraining, setIsAddingTraining] = useState(false);
    const [isAddingCertification, setIsAddingCertification] = useState(false);
    const [activeTab, setActiveTab] = useState('employees');
    const [currentUser, setCurrentUser] = useState(null);
    const [trainingTypes, setTrainingTypes] = useState([]);
    const [certificationTypes, setCertificationTypes] = useState([]);
    const [certificationMetrics, setCertificationMetrics] = useState({
        totalEmployees: 0,
        expiringSoon: 0,
        expired: 0,
        compliant: 0,
        complianceRate: 0
    });

    const [isAddingUser, setIsAddingUser] = useState(false);
    const [newUserForm, setNewUserForm] = useState({
        email: '',
        first_name: '',
        last_name: '',
        role: ''
    });
    const [roles, setRoles] = useState([]);

    // Role-based permissions
    const canManageTraining = ['Safety Manager', 'Tenant Admin Safety', 'Tenant Admin', 'Safety Director', 'Chief Safety Officer'].includes(userRole);
    const canViewTraining = true; // All safety roles can view
    const isViewOnlyMode = !canManageTraining;

    useEffect(() => {
        fetchData();
        fetchRoles();
    }, [project?.id]);

    const fetchRoles = async () => {
        try {
            const rolesResponse = await api.get('roles/');
            setRoles(rolesResponse.data.results || []);
        } catch (error) {
            console.error('Error fetching roles:', error);
        }
    };

    const handleAddUser = async (formData) => {
        // Only allow this action for users with management permissions
        if (!canManageTraining) {
            toast.error("You don't have permission to add users");
            return;
        }

        try {
            // First create/invite the user
            const createUserResponse = await api.post('users/invite/', {
                email: formData.email,
                first_name: formData.first_name,
                last_name: formData.last_name,
                role_id: formData.role || null
            });

            // Then assign them to the project
            if (createUserResponse.data && createUserResponse.data.id) {
                await api.post(`projects/${project.id}/assign_user/`, {
                    user_id: createUserResponse.data.id
                });

                // Close dialog
                setIsAddingUser(false);

                // Refresh data
                fetchData();

                toast.success("User added and assigned to project successfully");
            }
        } catch (error) {
            console.error('Error adding user:', error);
            toast.error(error.response?.data?.detail || "Failed to add user");
        }
    };

    const fetchData = async () => {
        if (!project?.id) return;

        setIsLoading(true);
        try {
            // Fetch user profile
            try {
                const userProfileResponse = await api.get('users/profile/');
                setCurrentUser(userProfileResponse.data);
            } catch (error) {
                console.error('Error fetching user profile:', error);
            }

            // Fetch project users for employee selection
            try {
                const projectUsersResponse = await api.get(`projects/${project.id}/users/`);
                const projectUsers = Array.isArray(projectUsersResponse.data)
                    ? projectUsersResponse.data
                    : (projectUsersResponse.data?.results || []);
                setEmployees(projectUsers);
            } catch (error) {
                console.error('Error fetching project users:', error);
                setEmployees([]);
            }

            // Fetch roles
            try {
                const rolesResponse = await api.get('roles/');
                setRoles(rolesResponse.data.results || []);
            } catch (error) {
                console.error('Error fetching roles:', error);
                setRoles([]);
            }

            // Fetch trainings
            try {
                const trainingsResponse = await api.get(`projects/${project.id}/safety-trainings/`);
                setTrainings(trainingsResponse.data.results || trainingsResponse.data || []);
            } catch (error) {
                console.error('Error fetching trainings:', error);
                setTrainings([]);
            }

            // Fetch certifications
            try {
                const certificationsResponse = await api.get(`projects/${project.id}/safety-certifications/`);
                setCertifications(certificationsResponse.data.results || certificationsResponse.data || []);
            } catch (error) {
                console.error('Error fetching certifications:', error);
                setCertifications([]);
            }

            // Fetch training types
            try {
                const trainingTypesResponse = await safetyApi.getTrainingTypes();
                setTrainingTypes(trainingTypesResponse.data.types || []);
            } catch (error) {
                console.error('Error fetching training types:', error);
                setTrainingTypes([]);
            }

            // Fetch certification types
            try {
                const certificationTypesResponse = await safetyApi.getCertificationTypes();
                setCertificationTypes(certificationTypesResponse.data.types || []);
            } catch (error) {
                console.error('Error fetching certification types:', error);
                setCertificationTypes([]);
            }

            // Fetch metrics
            try {
                const metricsResponse = await api.get(`projects/${project.id}/safety-certification-metrics/`);
                setCertificationMetrics(metricsResponse.data || calculateMetrics(certifications, employees));
            } catch (error) {
                console.error('Error fetching certification metrics:', error);
                setCertificationMetrics(calculateMetrics(certifications, employees));
            }
        } catch (error) {
            console.error('Error fetching safety training data:', error);
            toast.error("Error loading safety data. Some features may be limited.");
        } finally {
            setIsLoading(false);
        }
    };

    // Helper function to calculate metrics when API fails
    const calculateMetrics = (certifications, employees) => {
        const expiringSoon = certifications.filter(cert => cert.status === 'expiring_soon').length;
        const expired = certifications.filter(cert => cert.status === 'expired').length;
        const compliant = certifications.filter(cert => cert.status === 'valid').length;
        const totalEmployees = employees.length;

        return {
            totalEmployees,
            expiringSoon,
            expired,
            compliant,
            complianceRate: totalEmployees > 0 ? (compliant / totalEmployees) * 100 : 0
        };
    };

    const handleAddTraining = async (formData) => {
        // Check permissions
        if (!canManageTraining) {
            toast.error("You don't have permission to add trainings");
            return;
        }

        try {
            // Check if trainees is an array
            const trainees = Array.isArray(formData.trainees) ? formData.trainees : [formData.trainees];

            if (trainees.length === 0) {
                toast.error("Please select at least one trainee");
                return;
            }

            // Create a base training object
            const baseTrainingData = {
                training_type_id: formData.training_type_id || formData.training_type,
                description: formData.description,
                completion_date: formData.training_date || formData.date,
                status: formData.status,
                title: formData.title
            };

            // Create a training record for each selected trainee
            const promises = trainees.map(traineeId => {
                const trainingData = {
                    ...baseTrainingData,
                    trainee: traineeId
                };
                return api.post(`projects/${project.id}/safety-trainings/`, trainingData);
            });

            // Wait for all API calls to complete
            const responses = await Promise.all(promises);

            // Add all new training records to the state
            const newTrainings = responses.map(response => response.data);
            setTrainings([...trainings, ...newTrainings]);

            setIsAddingTraining(false);
            toast.success(`Training session scheduled successfully for ${trainees.length} employee(s)`);
        } catch (error) {
            console.error('Error adding training:', error);
            toast.error(error.response?.data?.detail || "Failed to schedule training");
        }
    };

    const handleAddCertification = async (formData) => {
        // Check permissions
        if (!canManageTraining) {
            toast.error("You don't have permission to add certifications");
            return;
        }

        try {
            const response = await api.post(`projects/${project.id}/safety-certifications/`, formData);
            setCertifications([...certifications, response.data]);
            setIsAddingCertification(false);

            // Refresh certification metrics
            const metricsResponse = await api.get(`projects/${project.id}/safety-certification-metrics/`);
            setCertificationMetrics(metricsResponse.data);
        } catch (error) {
            console.error('Error adding certification:', error);
            toast.error(error.response?.data?.detail || "Failed to add certification");
        }
    };

    const formatDate = (dateString) => {
        if (!dateString) return 'N/A';
        return new Date(dateString).toLocaleDateString();
    };

    const getStatusBadge = (status) => {
        const statusStyles = {
            completed: "bg-green-100 text-green-800",
            scheduled: "bg-blue-100 text-blue-800",
            in_progress: "bg-yellow-100 text-yellow-800",
            cancelled: "bg-red-100 text-red-800",
            valid: "bg-green-100 text-green-800",
            expiring_soon: "bg-yellow-100 text-yellow-800",
            expired: "bg-red-100 text-red-800"
        };

        const statusLabels = {
            completed: "Completed",
            scheduled: "Scheduled",
            in_progress: "In Progress",
            cancelled: "Cancelled",
            valid: "Valid",
            expiring_soon: "Expiring Soon",
            expired: "Expired"
        };

        return (
            <Badge className={statusStyles[status] || "bg-gray-100 text-gray-800"}>
                {statusLabels[status] || status}
            </Badge>
        );
    };

    // Add User Dialog Component
    const AddUserDialog = () => {
        // Local state for form inputs
        const [localForm, setLocalForm] = useState({
            email: '',
            first_name: '',
            last_name: '',
            role: ''
        });
        // Add loading state
        const [isSubmitting, setIsSubmitting] = useState(false);

        // Create a local handler for form submission
        const handleLocalSubmit = async (e) => {
            e.preventDefault();
            setIsSubmitting(true);
            try {
                // This function should handle adding the user
                await handleAddUser(localForm);

                // Close the dialog on success
                setIsAddingUser(false);

                // Show success toast message
                toast.success(`${localForm.first_name} ${localForm.last_name} was successfully added to the project`);

                // Refresh the employee data
                fetchData();

            } catch (error) {
                console.error('Error in form submission:', error);
                // Error is already handled in handleAddUser with toast notification
            } finally {
                setIsSubmitting(false);
            }
        };

        // Initialize local form when dialog opens
        useEffect(() => {
            if (isAddingUser) {
                setLocalForm({
                    email: '',
                    first_name: '',
                    last_name: '',
                    role: ''
                });
                setIsSubmitting(false);
            }
        }, [isAddingUser]);

        return (
            <Dialog open={isAddingUser} onOpenChange={(open) => {
                // Only allow closing if not currently submitting
                if (!isSubmitting || !open) {
                    setIsAddingUser(open);
                }
            }}>
                <DialogContent className="sm:max-w-[500px] max-h-[90vh] overflow-y-auto">
                    <DialogHeader>
                        <DialogTitle>Add New User</DialogTitle>
                        <DialogDescription>
                            Invite a new user and assign them to this project.
                        </DialogDescription>
                    </DialogHeader>

                    <form onSubmit={handleLocalSubmit}>
                        <div className="space-y-4 py-4">
                            <div className="space-y-2">
                                <Label htmlFor="email">Email Address</Label>
                                <Input
                                    id="email"
                                    type="email"
                                    value={localForm.email}
                                    onChange={(e) => setLocalForm(prev => ({ ...prev, email: e.target.value }))}
                                    required
                                    className="w-full"
                                    disabled={isSubmitting}
                                />
                            </div>

                            <div className="space-y-2">
                                <Label htmlFor="first_name">First Name</Label>
                                <Input
                                    id="first_name"
                                    value={localForm.first_name}
                                    onChange={(e) => setLocalForm(prev => ({ ...prev, first_name: e.target.value }))}
                                    required
                                    className="w-full"
                                    disabled={isSubmitting}
                                />
                            </div>

                            <div className="space-y-2">
                                <Label htmlFor="last_name">Last Name</Label>
                                <Input
                                    id="last_name"
                                    value={localForm.last_name}
                                    onChange={(e) => setLocalForm(prev => ({ ...prev, last_name: e.target.value }))}
                                    required
                                    className="w-full"
                                    disabled={isSubmitting}
                                />
                            </div>

                            <div className="space-y-2">
                                <Label htmlFor="role">Role</Label>
                                <Select
                                    value={localForm.role}
                                    onValueChange={(value) => setLocalForm(prev => ({ ...prev, role: value }))}
                                    disabled={isSubmitting}
                                >
                                    <SelectTrigger className="w-full">
                                        <SelectValue placeholder="Select a role">
                                            {localForm.role ? roles.find(r => r.id === localForm.role)?.name : "Select a role"}
                                        </SelectValue>
                                    </SelectTrigger>
                                    <SelectContent>
                                        {Array.isArray(roles) && roles.length > 0 ? (
                                            roles.map(role => (
                                                <SelectItem key={role.id} value={role.id}>
                                                    {role.name}
                                                </SelectItem>
                                            ))
                                        ) : (
                                            <SelectItem value="no-roles" disabled>
                                                No roles available
                                            </SelectItem>
                                        )}
                                    </SelectContent>
                                </Select>
                            </div>
                        </div>

                        <DialogFooter className="flex-col sm:flex-row gap-2">
                            <Button
                                type="button"
                                variant="outline"
                                onClick={() => setIsAddingUser(false)}
                                className="w-full sm:w-auto"
                                disabled={isSubmitting}
                            >
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                className={`${primaryButtonClasses} w-full sm:w-auto`}
                                disabled={isSubmitting}
                            >
                                {isSubmitting ? (
                                    <>
                                        <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                        Creating...
                                    </>
                                ) : "Add User"}
                            </Button>
                        </DialogFooter>
                    </form>
                </DialogContent>
            </Dialog>
        );
    };

    // Employees with certification status tab
    const EmployeesTab = () => {
        const [localEditingEmployee, setLocalEditingEmployee] = useState(false);
        const [localSelectedEmployee, setLocalSelectedEmployee] = useState(null);

        const handleEditEmployee = (employee) => {
            setLocalSelectedEmployee(employee);
            setLocalEditingEmployee(true);
        };

        const handleViewEmployee = (employee) => {
            setLocalSelectedEmployee(employee);
            setLocalEditingEmployee(true);
        };

        return (
            <div className="space-y-4">
                <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-6">
                    <Card>
                        <CardHeader className="pb-2">
                            <CardTitle className="text-sm font-medium text-gray-500">Total Employees</CardTitle>
                        </CardHeader>
                        <CardContent>
                            <p className="text-2xl font-semibold">{certificationMetrics.totalEmployees || employees.length}</p>
                        </CardContent>
                    </Card>

                    <Card>
                        <CardHeader className="pb-2">
                            <CardTitle className="text-sm font-medium text-gray-500">Compliant</CardTitle>
                        </CardHeader>
                        <CardContent>
                            <p className="text-2xl font-semibold text-green-600">{certificationMetrics.compliant}</p>
                        </CardContent>
                    </Card>

                    <Card>
                        <CardHeader className="pb-2">
                            <CardTitle className="text-sm font-medium text-gray-500">Expiring Soon</CardTitle>
                        </CardHeader>
                        <CardContent>
                            <p className="text-2xl font-semibold text-yellow-600">{certificationMetrics.expiringSoon}</p>
                        </CardContent>
                    </Card>

                    <Card>
                        <CardHeader className="pb-2">
                            <CardTitle className="text-sm font-medium text-gray-500">Expired</CardTitle>
                        </CardHeader>
                        <CardContent>
                            <p className="text-2xl font-semibold text-red-600">{certificationMetrics.expired}</p>
                        </CardContent>
                    </Card>
                </div>

                <div className="flex items-center justify-between">
                    <h3 className="text-lg font-semibold">Employees & Certification Status</h3>
                    {canManageTraining && (
                        <div className="flex gap-2">
                            <Button onClick={() => setIsAddingUser(true)} className={primaryButtonClasses}>
                                <Plus className="h-4 w-4 mr-2" />
                                Add User
                            </Button>
                            <Button className={primaryButtonClasses} onClick={() => setIsAddingCertification(true)}>
                                <Plus className="h-4 w-4 mr-2" />
                                Add Certification
                            </Button>
                        </div>
                    )}
                </div>

                <div className="rounded-md border">
                    <Table>
                        <TableHeader>
                            <TableRow>
                                <TableHead>Employee</TableHead>
                                <TableHead>Role</TableHead>
                                <TableHead>Certifications</TableHead>
                                <TableHead>Status</TableHead>
                                <TableHead>Action</TableHead>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {isLoading ? (
                                <TableRow>
                                    <TableCell colSpan={5} className="text-center py-4">Loading...</TableCell>
                                </TableRow>
                            ) : employees.length === 0 ? (
                                <TableRow>
                                    <TableCell colSpan={5} className="text-center py-4">No employees found</TableCell>
                                </TableRow>
                            ) : (
                                employees.map(employee => {
                                    const employeeCertifications = certifications.filter(cert =>
                                        cert.trainee === employee.id || cert.employee === employee.id || cert.employee_id === employee.id
                                    );

                                    const expiringCerts = employeeCertifications.filter(cert => cert.status === 'expiring_soon');
                                    const expiredCerts = employeeCertifications.filter(cert => cert.status === 'expired');

                                    let status = 'compliant';
                                    if (expiredCerts.length > 0) status = 'expired';
                                    else if (expiringCerts.length > 0) status = 'expiring_soon';

                                    const employeeName = `${employee.first_name || ''} ${employee.last_name || ''}`.trim() || employee.name || 'Unknown';

                                    return (
                                        <TableRow key={employee.id}>
                                            <TableCell className="font-medium">{employeeName}</TableCell>
                                            <TableCell>{employee.title || employee.role}</TableCell>
                                            <TableCell>{employeeCertifications.length}</TableCell>
                                            <TableCell>{getStatusBadge(status)}</TableCell>
                                            <TableCell>
                                                <div className="flex gap-2">
                                                    {canManageTraining ? (
                                                        <>
                                                            <Button variant="ghost" size="sm" onClick={() => handleEditEmployee(employee)}>
                                                                <Pencil className="h-4 w-4" />
                                                            </Button>
                                                            <Button
                                                                variant="ghost"
                                                                size="sm"
                                                                onClick={() => {
                                                                    setSelectedEmployee(employee);
                                                                    setIsAddingCertification(true);
                                                                }}>
                                                                <Award className="h-4 w-4" />
                                                            </Button>
                                                        </>
                                                    ) : (
                                                        <Button variant="ghost" size="sm" onClick={() => handleViewEmployee(employee)}>
                                                            <Eye className="h-4 w-4" />
                                                        </Button>
                                                    )}
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })
                            )}
                        </TableBody>
                    </Table>
                </div>

                {/* Employee Edit/View Dialog */}
                {localEditingEmployee && localSelectedEmployee && (
                    <Dialog open={localEditingEmployee} onOpenChange={setLocalEditingEmployee}>
                        <DialogContent className="sm:max-w-[500px]">
                            <DialogHeader>
                                <DialogTitle>{canManageTraining ? 'Edit Employee' : 'View Employee'}</DialogTitle>
                                <DialogDescription>
                                    {canManageTraining ? 'Update employee details and safety compliance information.' : 'Employee details and safety compliance information.'}
                                </DialogDescription>
                            </DialogHeader>

                            <div className="space-y-4 py-4">
                                <div className="space-y-2">
                                    <Label>Name</Label>
                                    <div className="font-medium">
                                        {`${localSelectedEmployee.first_name || ''} ${localSelectedEmployee.last_name || ''}`.trim() || localSelectedEmployee.name || 'Unknown'}
                                    </div>
                                </div>

                                <div className="space-y-2">
                                    <Label>Role</Label>
                                    <div>{localSelectedEmployee.title || localSelectedEmployee.role || 'Not assigned'}</div>
                                </div>

                                <div className="space-y-2">
                                    <Label>Current Certifications</Label>
                                    <div className="border rounded-md p-3 max-h-[150px] overflow-y-auto">
                                        {certifications.filter(cert =>
                                            cert.trainee === localSelectedEmployee.id ||
                                            cert.employee === localSelectedEmployee.id ||
                                            cert.employee_id === localSelectedEmployee.id
                                        ).length === 0 ? (
                                            <p className="text-sm text-gray-500">No certifications found</p>
                                        ) : (
                                            certifications.filter(cert =>
                                                cert.trainee === localSelectedEmployee.id ||
                                                cert.employee === localSelectedEmployee.id ||
                                                cert.employee_id === localSelectedEmployee.id
                                            ).map(cert => (
                                                <div key={cert.id} className="py-1 flex justify-between items-center">
                                                    <span className="text-sm">
                                                        {cert.title || cert.certification_type?.name || 'Unnamed certification'}
                                                    </span>
                                                    <span className="text-xs">
                                                        {getStatusBadge(cert.status)}
                                                    </span>
                                                </div>
                                            ))
                                        )}
                                    </div>
                                </div>

                                <div className="space-y-2">
                                    <Label>Required Trainings</Label>
                                    <div className="border rounded-md p-3 max-h-[150px] overflow-y-auto">
                                        {trainings.filter(train =>
                                            train.trainee === localSelectedEmployee.id
                                        ).length === 0 ? (
                                            <p className="text-sm text-gray-500">No assigned trainings</p>
                                        ) : (
                                            trainings.filter(train =>
                                                train.trainee === localSelectedEmployee.id
                                            ).map(train => (
                                                <div key={train.id} className="py-1 flex justify-between items-center">
                                                    <span className="text-sm">
                                                        {train.title}
                                                    </span>
                                                    <span className="text-xs">
                                                        {getStatusBadge(train.status)}
                                                    </span>
                                                </div>
                                            ))
                                        )}
                                    </div>
                                </div>
                            </div>

                            <DialogFooter className="flex justify-between">
                                <Button
                                    variant="outline"
                                    onClick={() => setLocalEditingEmployee(false)}
                                >
                                    Close
                                </Button>
                                {canManageTraining && (
                                    <div className="flex gap-2">
                                        <Button
                                            onClick={() => {
                                                setLocalEditingEmployee(false);
                                                setIsAddingCertification(true);
                                            }}
                                            className={primaryButtonClasses}
                                        >
                                            <Award className="h-4 w-4 mr-2" />
                                            Add Certification
                                        </Button>
                                        <Button
                                            onClick={() => {
                                                setLocalEditingEmployee(false);
                                                setIsAddingTraining(true);
                                            }}
                                            className={primaryButtonClasses}
                                        >
                                            <Calendar className="h-4 w-4 mr-2" />
                                            Schedule Training
                                        </Button>
                                    </div>
                                )}
                            </DialogFooter>
                        </DialogContent>
                    </Dialog>
                )}
            </div>
        );
    };


    const TrainingsTab = () => {
        const [isEditingTraining, setIsEditingTraining] = useState(false);
        const [selectedTraining, setSelectedTraining] = useState(null);
        const [attendanceStatus, setAttendanceStatus] = useState({});

        const handleEditTraining = (training) => {
            setSelectedTraining(training);
            setIsEditingTraining(true);

            // Initialize attendance status
            const trainingAttendees = employees.filter(emp =>
                trainings.some(t =>
                    t.training_type_id === training.training_type_id &&
                    (t.trainee === emp.id || t.employee === emp.id)
                )
            );

            const statusObj = {};
            trainingAttendees.forEach(emp => {
                const empTraining = trainings.find(t =>
                    t.training_type_id === training.training_type_id &&
                    (t.trainee === emp.id || t.employee === emp.id)
                );
                statusObj[emp.id] = empTraining?.status || 'pending';
            });

            setAttendanceStatus(statusObj);
        };

        const handleUpdateAttendance = async () => {
            if (!canManageTraining) {
                toast.error("You don't have permission to update attendance");
                return;
            }

            try {
                const updatePromises = Object.entries(attendanceStatus).map(([empId, status]) => {
                    const training = trainings.find(t =>
                        t.training_type_id === selectedTraining.training_type_id &&
                        (t.trainee === empId || t.employee === empId)
                    );

                    if (training) {
                        return api.patch(`projects/${project.id}/safety-trainings/${training.id}/`, {
                            status: status
                        });
                    }
                    return Promise.resolve();
                });

                await Promise.all(updatePromises);
                toast.success("Training attendance updated successfully");
                setIsEditingTraining(false);
                fetchData(); // Refresh data
            } catch (error) {
                console.error("Error updating training attendance:", error);
                toast.error("Failed to update training attendance");
            }
        };

        // Group trainings by training_type_id to show as single entries with multiple attendees
        const groupedTrainings = trainings.reduce((acc, training) => {
            const key = training.training_type_id || training.id;
            if (!acc[key]) {
                acc[key] = {
                    ...training,
                    attendees: [],
                    attendeeCount: 0
                };
            }

            // Add attendee if not already in the list
            const attendeeId = training.trainee || training.employee;
            if (attendeeId && !acc[key].attendees.includes(attendeeId)) {
                acc[key].attendees.push(attendeeId);
                acc[key].attendeeCount = acc[key].attendeeCount + 1;
            }

            return acc;
        }, {});

        return (
            <div className="space-y-4">
                <div className="flex items-center justify-between">
                    <h3 className="text-lg font-semibold">Safety Trainings</h3>
                    {canManageTraining && (
                        <Button className={primaryButtonClasses} onClick={() => setIsAddingTraining(true)}>
                            <Plus className="h-4 w-4 mr-2" />
                            Schedule Training
                        </Button>
                    )}
                </div>

                <div className="rounded-md border">
                    <Table>
                        <TableHeader>
                            <TableRow>
                                <TableHead>Training</TableHead>
                                <TableHead>Date</TableHead>
                                <TableHead>Attendees</TableHead>
                                <TableHead>Status</TableHead>
                                <TableHead>Action</TableHead>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {isLoading ? (
                                <TableRow>
                                    <TableCell colSpan={5} className="text-center py-4">Loading...</TableCell>
                                </TableRow>
                            ) : Object.keys(groupedTrainings).length === 0 ? (
                                <TableRow>
                                    <TableCell colSpan={5} className="text-center py-4">No trainings found</TableCell>
                                </TableRow>
                            ) : (
                                Object.values(groupedTrainings).map(training => (
                                    <TableRow key={training.id}>
                                        <TableCell>
                                            <div>
                                                <p className="font-medium">{training.title}</p>
                                                <p className="text-sm text-gray-500">{training.description}</p>
                                            </div>
                                        </TableCell>
                                        <TableCell>{formatDate(training.training_date || training.completion_date)}</TableCell>
                                        <TableCell>
                                            {training.attendeeCount || (training.attendees && training.attendees.length) || '—'}
                                        </TableCell>
                                        <TableCell>
                                            {(() => {
                                                // Calculate overall status
                                                const statuses = trainings
                                                    .filter(t => t.training_type_id === training.training_type_id)
                                                    .map(t => t.status);

                                                if (statuses.every(s => s === 'completed')) {
                                                    return getStatusBadge('completed');
                                                } else if (statuses.some(s => s === 'in_progress')) {
                                                    return getStatusBadge('in_progress');
                                                } else if (statuses.some(s => s === 'scheduled')) {
                                                    return getStatusBadge('scheduled');
                                                } else {
                                                    return getStatusBadge(training.status);
                                                }
                                            })()}
                                        </TableCell>
                                        <TableCell>
                                            <Button
                                                variant="ghost"
                                                size="sm"
                                                onClick={() => handleEditTraining(training)}
                                            >
                                                {canManageTraining ? <Pencil className="h-4 w-4" /> : <Eye className="h-4 w-4" />}
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                </div>

                {/* Training Edit Dialog */}
                {isEditingTraining && selectedTraining && (
                    <Dialog open={isEditingTraining} onOpenChange={setIsEditingTraining}>
                        <DialogContent className="sm:max-w-[600px]">
                            <DialogHeader>
                                <DialogTitle>{canManageTraining ? 'Manage Training Attendance' : 'View Training Attendance'}</DialogTitle>
                                <DialogDescription>
                                    {canManageTraining ? 'Update attendance and completion status for this training.' : 'View attendance and completion status for this training.'}
                                </DialogDescription>
                            </DialogHeader>

                            <div className="space-y-4 py-4">
                                <div className="grid grid-cols-2 gap-4">
                                    <div className="space-y-2">
                                        <Label>Training</Label>
                                        <div className="font-medium">{selectedTraining.title}</div>
                                    </div>

                                    <div className="space-y-2">
                                        <Label>Date</Label>
                                        <div>
                                            {formatDate(selectedTraining.training_date || selectedTraining.completion_date)}
                                        </div>
                                    </div>
                                </div>

                                <div className="space-y-2">
                                    <Label>Description</Label>
                                    <div className="text-sm text-gray-600">
                                        {selectedTraining.description || "No description provided"}
                                    </div>
                                </div>

                                <div className="space-y-2">
                                    <Label>Attendee Status</Label>
                                    <div className="border rounded-md p-3 max-h-[250px] overflow-y-auto">
                                        {employees.filter(emp =>
                                            trainings.some(t =>
                                                t.training_type_id === selectedTraining.training_type_id &&
                                                (t.trainee === emp.id || t.employee === emp.id)
                                            )
                                        ).length === 0 ? (
                                            <p className="text-sm text-gray-500">No attendees assigned</p>
                                        ) : (
                                            employees.filter(emp =>
                                                trainings.some(t =>
                                                    t.training_type_id === selectedTraining.training_type_id &&
                                                    (t.trainee === emp.id || t.employee === emp.id)
                                                )
                                            ).map(emp => {
                                                const empName = `${emp.first_name || ''} ${emp.last_name || ''}`.trim() || emp.name || 'Unknown';
                                                return (
                                                    <div key={emp.id} className="py-2 border-b last:border-0">
                                                        <div className="flex justify-between items-center mb-1">
                                                            <span className="font-medium">
                                                                {empName}
                                                            </span>
                                                            <span className="text-sm text-gray-500">
                                                                {emp.title || emp.role || ''}
                                                            </span>
                                                        </div>
                                                        <div>
                                                            {canManageTraining ? (
                                                                <Select
                                                                    value={attendanceStatus[emp.id] || 'pending'}
                                                                    onValueChange={(value) =>
                                                                        setAttendanceStatus({
                                                                            ...attendanceStatus,
                                                                            [emp.id]: value
                                                                        })
                                                                    }
                                                                >
                                                                    <SelectTrigger className="w-full">
                                                                        <SelectValue />
                                                                    </SelectTrigger>
                                                                    <SelectContent>
                                                                        <SelectItem value="pending">Pending</SelectItem>
                                                                        <SelectItem value="scheduled">Scheduled</SelectItem>
                                                                        <SelectItem value="in_progress">In Progress</SelectItem>
                                                                        <SelectItem value="completed">Completed</SelectItem>
                                                                        <SelectItem value="cancelled">Cancelled</SelectItem>
                                                                    </SelectContent>
                                                                </Select>
                                                            ) : (
                                                                <div className="py-1">
                                                                    {getStatusBadge(attendanceStatus[emp.id] || 'pending')}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        )}
                                    </div>
                                </div>
                            </div>

                            <DialogFooter>
                                <Button
                                    variant="outline"
                                    onClick={() => setIsEditingTraining(false)}
                                >
                                    {canManageTraining ? 'Cancel' : 'Close'}
                                </Button>
                                {canManageTraining && (
                                    <Button
                                        onClick={handleUpdateAttendance}
                                        className={primaryButtonClasses}
                                    >
                                        Update Attendance
                                    </Button>
                                )}
                            </DialogFooter>
                        </DialogContent>
                    </Dialog>
                )}
            </div>
        );
    };


    const CertificationsTab = () => {
        const [isEditingCertification, setIsEditingCertification] = useState(false);
        const [selectedCertification, setSelectedCertification] = useState(null);
        const [editForm, setEditForm] = useState({
            certification_number: '',
            issue_date: '',
            expiry_date: '',
            status: ''
        });

        const handleEditCertification = (certification) => {
            setSelectedCertification(certification);
            setEditForm({
                certification_number: certification.certification_number || '',
                issue_date: certification.issue_date || '',
                expiry_date: certification.expiry_date || '',
                status: certification.status || 'valid'
            });
            setIsEditingCertification(true);
        };

        const handleUpdateCertification = async () => {
            if (!canManageTraining) {
                toast.error("You don't have permission to update certifications");
                return;
            }

            try {
                if (!selectedCertification) return;

                await api.patch(`projects/${project.id}/safety-certifications/${selectedCertification.id}/`, {
                    ...editForm
                });

                toast.success("Certification updated successfully");
                setIsEditingCertification(false);
                fetchData(); // Refresh data
            } catch (error) {
                console.error("Error updating certification:", error);
                toast.error("Failed to update certification");
            }
        };

        return (
            <div className="space-y-4">
                <div className="flex items-center justify-between">
                    <h3 className="text-lg font-semibold">Certifications</h3>
                    {canManageTraining && (
                        <Button className={primaryButtonClasses} onClick={() => setIsAddingCertification(true)}>
                            <Plus className="h-4 w-4 mr-2" />
                            Add Certification
                        </Button>
                    )}
                </div>

                <div className="rounded-md border">
                    <Table>
                        <TableHeader>
                            <TableRow>
                                <TableHead>Certification</TableHead>
                                <TableHead>Employee</TableHead>
                                <TableHead>Issue Date</TableHead>
                                <TableHead>Expiry Date</TableHead>
                                <TableHead>Status</TableHead>
                                <TableHead>Action</TableHead>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {isLoading ? (
                                <TableRow>
                                    <TableCell colSpan={6} className="text-center py-4">Loading...</TableCell>
                                </TableRow>
                            ) : certifications.length === 0 ? (
                                <TableRow>
                                    <TableCell colSpan={6} className="text-center py-4">No certifications found</TableCell>
                                </TableRow>
                            ) : (
                                certifications.map(certification => {
                                    // Find employee name
                                    const certEmployee = employees.find(emp =>
                                        emp.id === certification.trainee ||
                                        emp.id === certification.employee ||
                                        emp.id === certification.employee_id
                                    );

                                    const employeeName = certEmployee ?
                                        `${certEmployee.first_name || ''} ${certEmployee.last_name || ''}`.trim() || certEmployee.name :
                                        certification.employee_name || 'Unknown';

                                    return (
                                        <TableRow key={certification.id}>
                                            <TableCell className="font-medium">
                                                {certification.title || certification.certification_type?.name || 'Unnamed certification'}
                                            </TableCell>
                                            <TableCell>{employeeName}</TableCell>
                                            <TableCell>{formatDate(certification.issue_date)}</TableCell>
                                            <TableCell>{formatDate(certification.expiry_date)}</TableCell>
                                            <TableCell>{getStatusBadge(certification.status)}</TableCell>
                                            <TableCell>
                                                <Button
                                                    variant="ghost"
                                                    size="sm"
                                                    onClick={() => handleEditCertification(certification)}
                                                >
                                                    {canManageTraining ? <Pencil className="h-4 w-4" /> : <Eye className="h-4 w-4" />}
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })
                            )}
                        </TableBody>
                    </Table>
                </div>

                {/* Certification Edit Dialog */}
                {isEditingCertification && selectedCertification && (
                    <Dialog open={isEditingCertification} onOpenChange={setIsEditingCertification}>
                        <DialogContent className="sm:max-w-[500px]">
                            <DialogHeader>
                                <DialogTitle>{canManageTraining ? 'Edit Certification' : 'View Certification'}</DialogTitle>
                                <DialogDescription>
                                    {canManageTraining ? 'Update certification details and status.' : 'Certification details and status.'}
                                </DialogDescription>
                            </DialogHeader>

                            <div className="space-y-4 py-4">
                                <div className="space-y-2">
                                    <Label>Certification</Label>
                                    <div className="font-medium">
                                        {selectedCertification.title || selectedCertification.certification_type?.name || 'Unnamed certification'}
                                    </div>
                                </div>

                                <div className="space-y-2">
                                    <Label>Employee</Label>
                                    <div>
                                        {(() => {
                                            const certEmployee = employees.find(emp =>
                                                emp.id === selectedCertification.trainee ||
                                                emp.id === selectedCertification.employee ||
                                                emp.id === selectedCertification.employee_id
                                            );

                                            return certEmployee ?
                                                `${certEmployee.first_name || ''} ${certEmployee.last_name || ''}`.trim() || certEmployee.name :
                                                selectedCertification.employee_name || 'Unknown';
                                        })()}
                                    </div>
                                </div>

                                {canManageTraining ? (
                                    <>
                                        <div className="space-y-2">
                                            <Label htmlFor="certification_number">Certification Number</Label>
                                            <Input
                                                id="certification_number"
                                                value={editForm.certification_number}
                                                onChange={(e) => setEditForm({ ...editForm, certification_number: e.target.value })}
                                            />
                                        </div>

                                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                            <div className="space-y-2">
                                                <Label htmlFor="issue_date">Issue Date</Label>
                                                <Input
                                                    id="issue_date"
                                                    type="date"
                                                    value={editForm.issue_date}
                                                    onChange={(e) => setEditForm({ ...editForm, issue_date: e.target.value })}
                                                />
                                            </div>

                                            <div className="space-y-2">
                                                <Label htmlFor="expiry_date">Expiry Date</Label>
                                                <Input
                                                    id="expiry_date"
                                                    type="date"
                                                    value={editForm.expiry_date}
                                                    onChange={(e) => setEditForm({ ...editForm, expiry_date: e.target.value })}
                                                />
                                            </div>
                                        </div>

                                        <div className="space-y-2">
                                            <Label htmlFor="status">Status</Label>
                                            <Select
                                                value={editForm.status}
                                                onValueChange={(value) => setEditForm({ ...editForm, status: value })}
                                            >
                                                <SelectTrigger>
                                                    <SelectValue />
                                                </SelectTrigger>
                                                <SelectContent>
                                                    <SelectItem value="valid">Valid</SelectItem>
                                                    <SelectItem value="expiring_soon">Expiring Soon</SelectItem>
                                                    <SelectItem value="expired">Expired</SelectItem>
                                                </SelectContent>
                                            </Select>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="space-y-2">
                                            <Label>Certification Number</Label>
                                            <div className="p-2 border rounded-md">
                                                {selectedCertification.certification_number || 'N/A'}
                                            </div>
                                        </div>

                                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                            <div className="space-y-2">
                                                <Label>Issue Date</Label>
                                                <div className="p-2 border rounded-md">
                                                    {formatDate(selectedCertification.issue_date)}
                                                </div>
                                            </div>

                                            <div className="space-y-2">
                                                <Label>Expiry Date</Label>
                                                <div className="p-2 border rounded-md">
                                                    {formatDate(selectedCertification.expiry_date)}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="space-y-2">
                                            <Label>Status</Label>
                                            <div className="p-2">
                                                {getStatusBadge(selectedCertification.status)}
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>

                            <DialogFooter>
                                <Button
                                    variant="outline"
                                    onClick={() => setIsEditingCertification(false)}
                                >
                                    {canManageTraining ? 'Cancel' : 'Close'}
                                </Button>
                                {canManageTraining && (
                                    <Button
                                        onClick={handleUpdateCertification}
                                        className={primaryButtonClasses}
                                    >
                                        Update Certification
                                    </Button>
                                )}
                            </DialogFooter>
                        </DialogContent>
                    </Dialog>
                )}
            </div>
        );
    };

    const AddTrainingDialog = () => (
        <Dialog open={isAddingTraining} onOpenChange={setIsAddingTraining}>
            <DialogContent className="sm:max-w-[500px] max-h-[90vh] overflow-y-auto">
                <DialogHeader>
                    <DialogTitle>Schedule New Training</DialogTitle>
                    <DialogDescription>
                        Add a new safety training session for project employees.
                    </DialogDescription>
                </DialogHeader>

                <form onSubmit={(e) => {
                    e.preventDefault();

                    if (!canManageTraining) {
                        toast.error("You don't have permission to add trainings");
                        return;
                    }

                    const formData = new FormData(e.target);

                    // Get all selected employees
                    const selectedEmployees = Array.from(
                        document.querySelectorAll('input[name="employees"]:checked')
                    ).map(el => el.value);

                    // Validate that at least one employee is selected
                    if (selectedEmployees.length === 0) {
                        toast.error("Please select at least one employee");
                        return;
                    }

                    const data = {
                        title: formData.get('title'),
                        training_type_id: formData.get('training_type'),
                        description: formData.get('description'),
                        training_date: formData.get('date'),
                        status: formData.get('status'),
                        trainees: selectedEmployees
                    };

                    handleAddTraining(data);
                }}>
                    <div className="space-y-4 py-4">
                        <div className="space-y-2">
                            <Label htmlFor="title">Training Title</Label>
                            <Input id="title" name="title" required className="w-full" />
                        </div>

                        <div className="space-y-2">
                            <Label htmlFor="training_type">Training Type</Label>
                            <Select name="training_type" required>
                                <SelectTrigger className="w-full">
                                    <SelectValue placeholder="Select training type" />
                                </SelectTrigger>
                                <SelectContent>
                                    {trainingTypes && trainingTypes.length > 0 ? (
                                        trainingTypes.map(type => (
                                            <SelectItem key={type.id} value={type.id}>
                                                {type.name}
                                            </SelectItem>
                                        ))
                                    ) : (
                                        <SelectItem value="no_types_available" disabled>
                                            No training types available
                                        </SelectItem>
                                    )}
                                </SelectContent>
                            </Select>
                        </div>

                        <div className="space-y-2">
                            <Label htmlFor="description">Description</Label>
                            <Input id="description" name="description" className="w-full" />
                        </div>

                        <div className="space-y-2">
                            <Label htmlFor="date">Training Date</Label>
                            <Input id="date" name="date" type="date" required className="w-full" />
                        </div>

                        <div className="space-y-2">
                            <Label htmlFor="status">Status</Label>
                            <Select name="status" defaultValue="scheduled">
                                <SelectTrigger className="w-full">
                                    <SelectValue placeholder="Select status" />
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectItem value="scheduled">Scheduled</SelectItem>
                                    <SelectItem value="in_progress">In Progress</SelectItem>
                                    <SelectItem value="completed">Completed</SelectItem>
                                    <SelectItem value="cancelled">Cancelled</SelectItem>
                                </SelectContent>
                            </Select>
                        </div>

                        <div className="space-y-2">
                            <Label>Participants</Label>
                            <div className="max-h-[200px] overflow-y-auto border rounded-md p-2">
                                {!employees || employees.length === 0 ? (
                                    <p className="text-sm text-gray-500 p-2">No employees assigned to this project</p>
                                ) : (
                                    Array.isArray(employees) && employees.map(employee => (
                                        <div key={employee.id} className="flex items-center space-x-2 py-2 border-b last:border-0">
                                            <input
                                                type="checkbox"
                                                id={`employee-${employee.id}`}
                                                name="employees"
                                                value={employee.id}
                                                className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                                            />
                                            <label htmlFor={`employee-${employee.id}`} className="text-sm">
                                                {employee.first_name || ''} {employee.last_name || ''}
                                                {(employee.title || employee.role) ? ` (${employee.title || employee.role})` : ''}
                                            </label>
                                        </div>
                                    ))
                                )}
                            </div>
                        </div>
                    </div>

                    <DialogFooter className="flex-col sm:flex-row gap-2">
                        <Button type="button" variant="outline" onClick={() => setIsAddingTraining(false)} className="w-full sm:w-auto">
                            Cancel
                        </Button>
                        <Button type="submit" className={`${primaryButtonClasses} w-full sm:w-auto`}>Schedule Training</Button>
                    </DialogFooter>
                </form>
            </DialogContent>
        </Dialog>
    );


    const AddCertificationDialog = () => (
        <Dialog open={isAddingCertification} onOpenChange={setIsAddingCertification}>
            <DialogContent className="sm:max-w-[500px] max-h-[90vh] overflow-y-auto">
                <DialogHeader>
                    <DialogTitle>Add New Certification</DialogTitle>
                    <DialogDescription>
                        Record a new safety certification for an employee.
                    </DialogDescription>
                </DialogHeader>

                <form onSubmit={(e) => {
                    e.preventDefault();

                    if (!canManageTraining) {
                        toast.error("You don't have permission to add certifications");
                        return;
                    }

                    const formData = new FormData(e.target);

                    // Handle the case where certification type is not available from API
                    let certification_type_id = formData.get('certification_type');

                    // If no certification types available, create a temporary one
                    if (!certification_type_id && certificationTypes.length === 0) {
                        certification_type_id = formData.get('custom_certification_type');
                    }

                    const data = {
                        certification_type_id: certification_type_id,
                        trainee: formData.get('employee'), // This matches the backend 'trainee' field
                        issue_date: formData.get('issue_date'),
                        expiry_date: formData.get('expiry_date'),
                        certification_number: formData.get('certification_number') || '',
                        title: formData.get('custom_certification_type') || // Use custom title if provided
                            certificationTypes.find(t => t.id === certification_type_id)?.name ||
                            'Unnamed certification'
                    };

                    handleAddCertification(data);
                }}>
                    <div className="space-y-4 py-4">
                        {certificationTypes && certificationTypes.length > 0 ? (
                            <div className="space-y-2">
                                <Label htmlFor="certification_type">Certification Type</Label>
                                <Select name="certification_type" required>
                                    <SelectTrigger className="w-full">
                                        <SelectValue placeholder="Select certification type" />
                                    </SelectTrigger>
                                    <SelectContent>
                                        {certificationTypes.map(type => (
                                            <SelectItem key={type.id} value={type.id}>
                                                {type.name}
                                            </SelectItem>
                                        ))}
                                    </SelectContent>
                                </Select>
                            </div>
                        ) : (
                            <div className="space-y-2">
                                <Label htmlFor="custom_certification_type">Certification Type</Label>
                                <Input
                                    id="custom_certification_type"
                                    name="custom_certification_type"
                                    placeholder="Enter certification type name"
                                    required
                                    className="w-full"
                                />
                            </div>
                        )}

                        <div className="space-y-2">
                            <Label htmlFor="employee">Employee</Label>
                            <Select name="employee" required>
                                <SelectTrigger className="w-full">
                                    <SelectValue placeholder="Select employee" />
                                </SelectTrigger>
                                <SelectContent>
                                    {Array.isArray(employees) && employees.map(employee => (
                                        <SelectItem key={employee.id} value={employee.id}>
                                            {employee.first_name || ''} {employee.last_name || ''}
                                            {!employee.first_name && !employee.last_name && employee.name ? employee.name : ''}
                                        </SelectItem>
                                    ))}
                                    {!employees || employees.length === 0 && (
                                        <SelectItem value="no_employees" disabled>
                                            No employees available
                                        </SelectItem>
                                    )}
                                </SelectContent>
                            </Select>
                        </div>

                        <div className="space-y-2">
                            <Label htmlFor="certification_number">Certification Number</Label>
                            <Input id="certification_number" name="certification_number" className="w-full" />
                        </div>

                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                            <div className="space-y-2">
                                <Label htmlFor="issue_date">Issue Date</Label>
                                <Input id="issue_date" name="issue_date" type="date" required className="w-full" />
                            </div>

                            <div className="space-y-2">
                                <Label htmlFor="expiry_date">Expiry Date</Label>
                                <Input id="expiry_date" name="expiry_date" type="date" required className="w-full" />
                            </div>
                        </div>
                    </div>

                    <DialogFooter className="flex-col sm:flex-row gap-2">
                        <Button type="button" variant="outline" onClick={() => setIsAddingCertification(false)} className="w-full sm:w-auto">
                            Cancel
                        </Button>
                        <Button type="submit" className={`${primaryButtonClasses} w-full sm:w-auto`}>Add Certification</Button>
                    </DialogFooter>
                </form>
            </DialogContent>
        </Dialog>
    );

    const ComplianceOverview = () => {
        const [viewingTrainingType, setViewingTrainingType] = useState(null);
        const [trainingTypeDetails, setTrainingTypeDetails] = useState({
            employees: [],
            compliance: 0,
            lastCompleted: null
        });

        // Calculate training type statistics based on actual training data
        const trainingTypeStats = useMemo(() => {
            // Group trainings by type
            const typeGroups = trainings.reduce((acc, training) => {
                const typeId = training.training_type_id;
                if (!typeId) return acc;

                if (!acc[typeId]) {
                    // Find the training type from the list
                    const trainingType = trainingTypes.find(type => type.id === typeId);

                    acc[typeId] = {
                        id: typeId,
                        title: trainingType?.name || training.title || 'Unnamed Training',
                        totalAssigned: 0,
                        completed: 0,
                        inProgress: 0,
                        scheduled: 0,
                        lastCompletedDate: null,
                        employees: [],
                        requiredFor: trainingType?.required_for || 'All Employees'
                    };
                }

                // Count this training
                acc[typeId].totalAssigned++;

                // Track status
                if (training.status === 'completed') {
                    acc[typeId].completed++;

                    // Track most recent completion date
                    const completionDate = new Date(training.completion_date || training.training_date);
                    if (!acc[typeId].lastCompletedDate || completionDate > new Date(acc[typeId].lastCompletedDate)) {
                        acc[typeId].lastCompletedDate = training.completion_date || training.training_date;
                    }
                } else if (training.status === 'in_progress') {
                    acc[typeId].inProgress++;
                } else if (training.status === 'scheduled') {
                    acc[typeId].scheduled++;
                }

                // Track assigned employees
                const employeeId = training.trainee || training.employee;
                if (employeeId && !acc[typeId].employees.includes(employeeId)) {
                    acc[typeId].employees.push(employeeId);
                }

                return acc;
            }, {});

            // Convert to array and calculate compliance percentages
            return Object.values(typeGroups).map(group => ({
                ...group,
                complianceRate: Math.round((group.completed / Math.max(group.totalAssigned, 1)) * 100)
            }));
        }, [trainings, trainingTypes]);

        const handleViewTrainingDetails = (trainingType) => {
            // Find all employees assigned to this training type
            const assignedEmployeeIds = trainings
                .filter(t => t.training_type_id === trainingType.id)
                .map(t => t.trainee || t.employee)
                .filter((v, i, a) => a.indexOf(v) === i); // Unique values

            // Get employee details and their status for this training
            const employeeDetails = assignedEmployeeIds.map(empId => {
                const emp = employees.find(e => e.id === empId);
                const training = trainings.find(t =>
                    t.training_type_id === trainingType.id &&
                    (t.trainee === empId || t.employee === empId)
                );

                return {
                    id: empId,
                    name: emp ? `${emp.first_name || ''} ${emp.last_name || ''}`.trim() || emp.name : 'Unknown',
                    role: emp?.title || emp?.role || 'Unknown',
                    status: training?.status || 'pending',
                    completionDate: training?.completion_date || training?.training_date
                };
            });

            setTrainingTypeDetails({
                ...trainingType,
                employees: employeeDetails
            });

            setViewingTrainingType(trainingType);
        };

        const scheduleTrainingForAll = () => {
            // Prefill the training type when opening the dialog
            if (viewingTrainingType) {
                // Close the current dialog
                setViewingTrainingType(null);

                // Wait for the current dialog to close
                setTimeout(() => {
                    // Open the training dialog with pre-selected type
                    setSelectedTrainingType(viewingTrainingType.id);
                    setIsAddingTraining(true);
                }, 300);
            }
        };

        // Group trainings by status to get high-level metrics
        const trainingStatusCounts = trainings.reduce((acc, training) => {
            const status = training.status || 'pending';
            acc[status] = (acc[status] || 0) + 1;
            return acc;
        }, {});

        // Calculate upcoming trainings - those scheduled in the next 30 days
        const upcomingTrainings = trainings.filter(training => {
            if (training.status !== 'scheduled') return false;

            const trainingDate = new Date(training.training_date || training.completion_date);
            const now = new Date();
            const thirtyDaysFromNow = new Date();
            thirtyDaysFromNow.setDate(now.getDate() + 30);

            return trainingDate >= now && trainingDate <= thirtyDaysFromNow;
        });

        return (
            <div className="space-y-6">
                <div className="mb-4">
                    <h3 className="text-lg font-semibold mb-2">Certification Compliance</h3>
                    <div className="bg-gray-100 p-4 rounded-lg">
                        <div className="flex justify-between items-center mb-2">
                            <span className="text-sm font-medium">Overall Compliance Rate</span>
                            <span className="text-sm font-medium">{Math.round(certificationMetrics.complianceRate || 0)}%</span>
                        </div>
                        <Progress value={certificationMetrics.complianceRate || 0} className="h-2" />

                        <div className="grid grid-cols-3 gap-4 mt-6">
                            <div className="text-center">
                                <span className="text-sm text-gray-500">Compliant</span>
                                <p className="text-xl font-semibold text-green-600">{certificationMetrics.compliant}</p>
                            </div>
                            <div className="text-center">
                                <span className="text-sm text-gray-500">Expiring Soon</span>
                                <p className="text-xl font-semibold text-yellow-600">{certificationMetrics.expiringSoon}</p>
                            </div>
                            <div className="text-center">
                                <span className="text-sm text-gray-500">Expired</span>
                                <p className="text-xl font-semibold text-red-600">{certificationMetrics.expired}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    <Card>
                        <CardHeader className="pb-2">
                            <CardTitle className="text-base">Required Training Compliance</CardTitle>
                        </CardHeader>
                        <CardContent>
                            <div className="text-2xl font-semibold">
                                {Math.round((trainings.filter(t => t.status === 'completed').length / Math.max(trainings.length, 1)) * 100)}%
                            </div>
                            <p className="text-sm text-gray-500">
                                {trainings.filter(t => t.status === 'completed').length} of {trainings.length} trainings completed
                            </p>
                        </CardContent>
                    </Card>

                    <Card>
                        <CardHeader className="pb-2">
                            <CardTitle className="text-base">Upcoming Trainings</CardTitle>
                        </CardHeader>
                        <CardContent>
                            <div className="text-2xl font-semibold">
                                {upcomingTrainings.length}
                            </div>
                            <p className="text-sm text-gray-500">
                                Scheduled in the next 30 days
                            </p>
                        </CardContent>
                    </Card>

                    <Card>
                        <CardHeader className="pb-2">
                            <CardTitle className="text-base">Certification Renewals Needed</CardTitle>
                        </CardHeader>
                        <CardContent>
                            <div className="text-2xl font-semibold text-yellow-600">
                                {certificationMetrics.expiringSoon + certificationMetrics.expired}
                            </div>
                            <p className="text-sm text-gray-500">
                                Requiring attention
                            </p>
                        </CardContent>
                    </Card>
                </div>

                <div className="mt-8">
                    <div className="flex justify-between items-center mb-4">
                        <h3 className="text-lg font-semibold">Training Requirements Overview</h3>
                        {canManageTraining && (
                            <Button
                                className={primaryButtonClasses}
                                onClick={() => setIsAddingTraining(true)}
                            >
                                <Plus className="h-4 w-4 mr-2" />
                                Schedule Training
                            </Button>
                        )}
                    </div>
                    <div className="rounded-md border">
                        <Table>
                            <TableHeader>
                                <TableRow>
                                    <TableHead>Training Type</TableHead>
                                    <TableHead>Required For</TableHead>
                                    <TableHead>Compliance Status</TableHead>
                                    <TableHead>Last Completed</TableHead>
                                    <TableHead>Action</TableHead>
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {trainingTypeStats.length === 0 ? (
                                    <TableRow>
                                        <TableCell colSpan={5} className="text-center py-4">
                                            No training types defined
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    trainingTypeStats.map(training => {
                                        // Determine badge color based on compliance rate
                                        let badgeClass = "bg-green-100 text-green-800";
                                        if (training.complianceRate < 70) {
                                            badgeClass = "bg-red-100 text-red-800";
                                        } else if (training.complianceRate < 90) {
                                            badgeClass = "bg-yellow-100 text-yellow-800";
                                        }

                                        return (
                                            <TableRow key={training.id}>
                                                <TableCell className="font-medium">{training.title}</TableCell>
                                                <TableCell>{training.requiredFor}</TableCell>
                                                <TableCell>
                                                    <Badge className={badgeClass}>
                                                        {training.complianceRate}% Compliant
                                                    </Badge>
                                                </TableCell>
                                                <TableCell>
                                                    {training.lastCompletedDate ?
                                                        formatDate(training.lastCompletedDate) :
                                                        'Not completed yet'}
                                                </TableCell>
                                                <TableCell>
                                                    <Button
                                                        variant="ghost"
                                                        size="sm"
                                                        onClick={() => handleViewTrainingDetails(training)}
                                                    >
                                                        View
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })
                                )}
                            </TableBody>
                        </Table>
                    </div>
                </div>

                {/* Training Type Details Dialog */}
                {viewingTrainingType && (
                    <Dialog open={!!viewingTrainingType} onOpenChange={(open) => !open && setViewingTrainingType(null)}>
                        <DialogContent className="sm:max-w-[700px]">
                            <DialogHeader>
                                <DialogTitle>{viewingTrainingType.title} - Details</DialogTitle>
                                <DialogDescription>
                                    Training compliance details and assigned employees.
                                </DialogDescription>
                            </DialogHeader>

                            <div className="space-y-4 py-2">
                                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                                    <div className="space-y-1 bg-gray-50 p-3 rounded-md">
                                        <h4 className="text-sm text-gray-500">Compliance Rate</h4>
                                        <p className="text-xl font-semibold">
                                            {viewingTrainingType.complianceRate}%
                                        </p>
                                    </div>

                                    <div className="space-y-1 bg-gray-50 p-3 rounded-md">
                                        <h4 className="text-sm text-gray-500">Assigned To</h4>
                                        <p className="text-xl font-semibold">
                                            {viewingTrainingType.employees.length} employees
                                        </p>
                                    </div>

                                    <div className="space-y-1 bg-gray-50 p-3 rounded-md">
                                        <h4 className="text-sm text-gray-500">Last Completed</h4>
                                        <p className="text-xl font-semibold">
                                            {viewingTrainingType.lastCompletedDate ?
                                                formatDate(viewingTrainingType.lastCompletedDate) :
                                                'Not completed yet'}
                                        </p>
                                    </div>
                                </div>

                                <div className="mt-4">
                                    <h4 className="text-sm font-medium mb-2">Assigned Employees</h4>
                                    <div className="border rounded-md overflow-hidden">
                                        <Table>
                                            <TableHeader>
                                                <TableRow>
                                                    <TableHead>Employee</TableHead>
                                                    <TableHead>Role</TableHead>
                                                    <TableHead>Status</TableHead>
                                                    <TableHead>Completion Date</TableHead>
                                                </TableRow>
                                            </TableHeader>
                                            <TableBody>
                                                {trainingTypeDetails.employees.length === 0 ? (
                                                    <TableRow>
                                                        <TableCell colSpan={4} className="text-center py-4">
                                                            No employees assigned to this training
                                                        </TableCell>
                                                    </TableRow>
                                                ) : (
                                                    trainingTypeDetails.employees.map(emp => (
                                                        <TableRow key={emp.id}>
                                                            <TableCell className="font-medium">{emp.name}</TableCell>
                                                            <TableCell>{emp.role}</TableCell>
                                                            <TableCell>{getStatusBadge(emp.status)}</TableCell>
                                                            <TableCell>
                                                                {emp.completionDate ?
                                                                    formatDate(emp.completionDate) :
                                                                    'Not completed'}
                                                            </TableCell>
                                                        </TableRow>
                                                    ))
                                                )}
                                            </TableBody>
                                        </Table>
                                    </div>
                                </div>
                            </div>

                            <DialogFooter>
                                <Button
                                    variant="outline"
                                    onClick={() => setViewingTrainingType(null)}
                                >
                                    Close
                                </Button>
                                {canManageTraining && (
                                    <Button
                                        className={primaryButtonClasses}
                                        onClick={scheduleTrainingForAll}
                                    >
                                        <Calendar className="h-4 w-4 mr-2" />
                                        Schedule Training
                                    </Button>
                                )}
                            </DialogFooter>
                        </DialogContent>
                    </Dialog>
                )}
            </div>
        );
    };

    return (
        <div className="space-y-6">
            <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4">
                <h2 className="text-2xl font-bold">Safety Training & Certifications</h2>
                {canManageTraining && (
                    <div className="flex gap-2">
                        <Button
                            variant="outline"
                            className="hidden md:flex"
                            onClick={() => setIsAddingTraining(true)}
                        >
                            <Calendar className="h-4 w-4 mr-2" />
                            Schedule Training
                        </Button>
                        <Button
                            className={`${primaryButtonClasses} hidden md:flex`}
                            onClick={() => setIsAddingCertification(true)}
                        >
                            <Award className="h-4 w-4 mr-2" />
                            Add Certification
                        </Button>
                    </div>
                )}
            </div>

            <Tabs value={activeTab} onValueChange={setActiveTab}>
                <TabsList className="w-full justify-start">
                    <TabsTrigger value="overview" className="flex items-center gap-1">
                        <BarChart className="h-4 w-4" />
                        <span className="hidden sm:inline-block">Compliance Overview</span>
                        <span className="inline-block sm:hidden">Overview</span>
                    </TabsTrigger>
                    <TabsTrigger value="employees" className="flex items-center gap-1">
                        <Users className="h-4 w-4" />
                        <span className="hidden sm:inline-block">Employees & Status</span>
                        <span className="inline-block sm:hidden">Employees</span>
                    </TabsTrigger>
                    <TabsTrigger value="trainings" className="flex items-center gap-1">
                        <Calendar className="h-4 w-4" />
                        <span className="hidden sm:inline-block">Trainings</span>
                        <span className="inline-block sm:hidden">Trainings</span>
                    </TabsTrigger>
                    <TabsTrigger value="certifications" className="flex items-center gap-1">
                        <Award className="h-4 w-4" />
                        <span className="hidden sm:inline-block">Certifications</span>
                        <span className="inline-block sm:hidden">Certs</span>
                    </TabsTrigger>
                </TabsList>

                <TabsContent value="overview">
                    <ComplianceOverview />
                </TabsContent>

                <TabsContent value="employees">
                    <EmployeesTab />
                </TabsContent>

                <TabsContent value="trainings">
                    <TrainingsTab />
                </TabsContent>

                <TabsContent value="certifications">
                    <CertificationsTab />
                </TabsContent>
            </Tabs>

            {/* Mobile action buttons - only for managers */}
            {canManageTraining && (
                <div className="md:hidden fixed bottom-4 right-4 flex flex-col gap-2">
                    <Button className="rounded-full h-12 w-12 p-0 shadow-lg" onClick={() => setIsAddingTraining(true)}>
                        <Calendar className="h-5 w-5" />
                    </Button>
                    <Button className={`rounded-full h-12 w-12 p-0 shadow-lg ${primaryButtonClasses}`} onClick={() => setIsAddingCertification(true)}>
                        <Award className="h-5 w-5" />
                    </Button>
                </div>
            )}

            {/* Dialogs - only shown when needed */}
            {canManageTraining && isAddingTraining && <AddTrainingDialog />}
            {canManageTraining && isAddingUser && <AddUserDialog />}
            {canManageTraining && isAddingCertification && <AddCertificationDialog />}
        </div>
    );
};

export default SafetyTrainingManager;