import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '../components/ui/dialog';
import { Form, FormField, FormItem, FormLabel, FormControl } from '../components/ui/form';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../components/ui/select';
import { Table, TableHeader, TableBody, TableRow, TableHead, TableCell } from '../components/ui/table';
import { Button } from '../components/ui/button';
import { Calendar, AlertCircle, Loader2 } from 'lucide-react';
import { Input } from '../components/ui/input';
import { Alert, AlertDescription } from '../components/ui/alert';
import { Checkbox } from '../components/ui/checkbox';
import { useForm } from 'react-hook-form';
import api from '../services/api';
import safetyApi from '../services/safetyService';
import { primaryButtonClasses, secondaryButtonClasses } from '../components/common/ButtonStyles';

const formatDisplayName = (name) => {
    // Extract the part after the last underscore
    const baseType = name.split('_').pop();
    // Capitalize the first letter
    return baseType.charAt(0).toUpperCase() + baseType.slice(1);
};

const formatTemplateName = (templateName) => {
    // Check if the name contains any of our frequency terms
    const frequencies = ['daily', 'weekly', 'monthly', 'quarterly', 'annual'];

    // Extract the part after the UUID (usually after the last underscore)
    let displayName = templateName;

    try {
        // Look for a frequency term in the name
        const nameParts = templateName.split('_');
        const frequency = nameParts.find(part => frequencies.includes(part.toLowerCase()));

        if (frequency) {
            // Format as "Daily Safety Inspection"
            return `${frequency.charAt(0).toUpperCase() + frequency.slice(1)} Safety Inspection`;
        } else {
            // Just use the last part of the name if no frequency is found
            return formatDisplayName(templateName);
        }
    } catch (e) {
        // Fall back to the original name if parsing fails
        return templateName;
    }
};

const ScheduleAuditModal = ({ project, isOpen, onClose, onSchedule }) => {
    const [selectedAuditType, setSelectedAuditType] = useState(null);
    const [isUrgent, setIsUrgent] = useState(false);
    const [auditTypes, setAuditTypes] = useState([]);
    const [templates, setTemplates] = useState([]);
    const [safetySubtypes, setSafetySubtypes] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState(null);

    const form = useForm({
        defaultValues: {
            audit_type: '',
            subtype: '',
            template: '',
            planned_date: '',
            location: '',
            priority: 'medium',
            justification: ''
        }
    });

    useEffect(() => {
        const fetchTypes = async () => {
            try {
                const [auditTypesRes, subtypesRes, templatesRes] = await Promise.all([
                    safetyApi.getAuditTypes(),
                    safetyApi.getSafetySubtypes(),
                    safetyApi.getAuditTemplates()
                ]);
                setAuditTypes(auditTypesRes.data.types || []);
                setSafetySubtypes(subtypesRes.data.subtypes || []);

                // Handle different possible response structures
                if (templatesRes.data.results && Array.isArray(templatesRes.data.results)) {
                    setTemplates(templatesRes.data.results);
                } else if (templatesRes.data && Array.isArray(templatesRes.data.templates)) {
                    setTemplates(templatesRes.data.templates);
                } else {
                    console.error('Templates data structure is not as expected:', templatesRes);
                    setTemplates([]);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                setError('Failed to load form data. Please try again.');
                setAuditTypes([]);
                setSafetySubtypes([]);
                setTemplates([]);
            }
        };
        fetchTypes();
    }, []);

    const getMinDate = () => {
        if (isUrgent) return new Date().toISOString().split('T')[0];

        const today = new Date();
        const minDays = selectedAuditType?.minimum_notice_days || 0;
        today.setDate(today.getDate() + minDays);
        return today.toISOString().split('T')[0];
    };

    const handleSubmit = async (data) => {
        try {
            setIsSubmitting(true);
            setError(null);

            const submissionData = {
                ...data,
                project: project.id,
                status: 'scheduled',
                findings: 'Pending audit completion',
                recommendations: 'To be provided after audit',
                action_items: 'To be determined',
                requires_followup: false,
                participants: [],
            };

            const response = await api.post(
                `projects/${project.id}/safety-audits/`,
                submissionData
            );

            onSchedule(response.data);
        } catch (error) {
            console.error('Error details:', {
                status: error.response?.status,
                data: error.response?.data,
                originalData: data
            });

            // Display user-friendly error message
            if (error.response?.data?.template) {
                setError(`Template error: ${error.response.data.template}`);
            } else if (error.response?.data?.error) {
                setError(`Error: ${error.response.data.error}`);
            } else {
                setError('An error occurred while scheduling the audit. Please try again.');
            }
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className="sm:max-w-[600px]">
                <DialogHeader>
                    <DialogTitle>Schedule Safety Audit</DialogTitle>
                </DialogHeader>

                {error && (
                    <Alert variant="destructive">
                        <AlertCircle className="h-4 w-4" />
                        <AlertDescription>{error}</AlertDescription>
                    </Alert>
                )}

                <Form {...form}>
                    <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-4">
                        {/* Audit Type Selection */}
                        <FormField
                            control={form.control}
                            name="audit_type"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Audit Type</FormLabel>
                                    <Select
                                        onValueChange={(value) => {
                                            const auditType = auditTypes.find(type => type.id === value);
                                            setSelectedAuditType(auditType);
                                            field.onChange(value);
                                        }}
                                        value={field.value}
                                        disabled={isSubmitting}
                                    >
                                        <SelectTrigger>
                                            <SelectValue placeholder="Select audit type" />
                                        </SelectTrigger>
                                        <SelectContent>
                                            {auditTypes.map(type => (
                                                <SelectItem key={type.id} value={type.id}>
                                                    {formatDisplayName(type.name)}
                                                </SelectItem>
                                            ))}
                                        </SelectContent>
                                    </Select>
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="subtype"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Audit Subtype</FormLabel>
                                    <Select
                                        onValueChange={field.onChange}
                                        value={field.value}
                                        disabled={isSubmitting}
                                    >
                                        <SelectTrigger>
                                            <SelectValue placeholder="Select audit subtype" />
                                        </SelectTrigger>
                                        <SelectContent>
                                            {safetySubtypes.map(subtype => (
                                                <SelectItem
                                                    key={subtype.id}
                                                    value={subtype.id}
                                                >
                                                    {formatDisplayName(subtype.name.split('_').pop())}
                                                </SelectItem>
                                            ))}
                                        </SelectContent>
                                    </Select>
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="template"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Audit Template</FormLabel>
                                    <Select
                                        onValueChange={field.onChange}
                                        value={field.value}
                                        disabled={isSubmitting}
                                    >
                                        <SelectTrigger>
                                            <SelectValue placeholder="Select template" />
                                        </SelectTrigger>
                                        <SelectContent>
                                            {templates && templates.length > 0 ? (
                                                templates.map(template => (
                                                    <SelectItem key={template.id} value={template.id}>
                                                        {formatTemplateName(template.name)}
                                                    </SelectItem>
                                                ))
                                            ) : (
                                                <SelectItem value="no templates avail" disabled>
                                                    No templates available
                                                </SelectItem>
                                            )}
                                        </SelectContent>
                                    </Select>
                                </FormItem>
                            )}
                        />
                        {/* Urgent Audit Option */}
                        <div className="flex items-center space-x-2">
                            <Checkbox
                                id="urgent"
                                checked={isUrgent}
                                onCheckedChange={setIsUrgent}
                                disabled={isSubmitting}
                            />
                            <label htmlFor="urgent" className="text-sm">
                                This is an urgent safety audit
                            </label>
                        </div>

                        {/* Justification for Urgent Audits */}
                        {isUrgent && (
                            <FormField
                                control={form.control}
                                name="justification"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Urgency Justification</FormLabel>
                                        <Input
                                            {...field}
                                            placeholder="Please explain why this audit needs immediate attention"
                                            disabled={isSubmitting}
                                        />
                                    </FormItem>
                                )}
                            />
                        )}

                        {/* Date Selection */}
                        <FormField
                            control={form.control}
                            name="planned_date"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Planned Date</FormLabel>
                                    <Input
                                        type="date"
                                        {...field}
                                        min={getMinDate()}
                                        disabled={isSubmitting}
                                    />
                                    {selectedAuditType && !isUrgent && (
                                        <p className="text-sm text-gray-500">
                                            Minimum {selectedAuditType.minimum_notice_days} days notice required
                                        </p>
                                    )}
                                </FormItem>
                            )}
                        />

                        {/* Location */}
                        <FormField
                            control={form.control}
                            name="location"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Location</FormLabel>
                                    <Input
                                        {...field}
                                        placeholder="Audit location"
                                        disabled={isSubmitting}
                                    />
                                </FormItem>
                            )}
                        />

                        {/* Priority */}
                        <FormField
                            control={form.control}
                            name="priority"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Priority</FormLabel>
                                    <Select
                                        onValueChange={field.onChange}
                                        value={field.value}
                                        disabled={isSubmitting}
                                    >
                                        <SelectTrigger>
                                            <SelectValue placeholder="Select priority" />
                                        </SelectTrigger>
                                        <SelectContent>
                                            <SelectItem value="low">Low</SelectItem>
                                            <SelectItem value="medium">Medium</SelectItem>
                                            <SelectItem value="high">High</SelectItem>
                                        </SelectContent>
                                    </Select>
                                </FormItem>
                            )}
                        />

                        {/* Urgent Audit Warning */}
                        {isUrgent && (
                            <Alert>
                                <AlertCircle className="h-4 w-4" />
                                <AlertDescription>
                                    Urgent audits will notify relevant stakeholders immediately
                                </AlertDescription>
                            </Alert>
                        )}

                        {/* Form Actions */}
                        <div className="flex justify-end gap-2 pt-4">
                            <Button
                                className={secondaryButtonClasses}
                                type="button"
                                variant="outline"
                                onClick={onClose}
                                disabled={isSubmitting}
                            >
                                Cancel
                            </Button>
                            <Button
                                className={primaryButtonClasses}
                                type="submit"
                                disabled={isSubmitting}
                            >
                                {isSubmitting ? (
                                    <>
                                        <Loader2 className="h-4 w-4 mr-2 animate-spin" />
                                        Scheduling...
                                    </>
                                ) : (
                                    'Schedule Audit'
                                )}
                            </Button>
                        </div>
                    </form>
                </Form>
            </DialogContent>
        </Dialog>
    );
};

export default ScheduleAuditModal;