import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '../../components/ui/dialog';
import { Form, FormField, FormItem, FormLabel, FormControl, FormMessage } from '../../components/ui/form';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../components/ui/select';
import { Table, TableHeader, TableBody, TableRow, TableHead, TableCell } from '../../components/ui/table';
import { Textarea } from '../../components/ui/textarea';
import { Input } from '../../components/ui/input';
import { Button } from '../../components/ui/button';
import { ScrollArea } from '../../components/ui/scroll-area';
import { AlertCircle, CheckCircle2, Edit, Trash2, FileText, Calendar, Eye, Pencil } from 'lucide-react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import api from '../../services/api';
import ScheduleAuditModal from '../ScheduleAuditModal';
import { primaryButtonClasses } from '../../components/common/ButtonStyles';
import SafetyAuditViewModal from '../SafetyAuditViewModal';
import SafetyAuditEditModal from '../SafetyAuditEditModal';
import AuditExecutionModal from '../AuditExecutionView';
import LoadingSpinner from '../../components/common/LoadingSpinner';


const StatusBadge = ({ status }) => {
    const colors = {
        draft: 'bg-gray-100 text-gray-800',
        scheduled: 'bg-blue-100 text-blue-800',
        in_progress: 'bg-yellow-100 text-yellow-800',
        completed: 'bg-green-100 text-green-800',
        cancelled: 'bg-red-100 text-red-800',
    };

    // Format status for display (convert snake_case to Title Case)
    const formattedStatus = status
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');

    return (
        <span className={`px-2 py-1 rounded-full text-xs font-medium ${colors[status] || colors.draft}`}>
            {formattedStatus}
        </span>
    );
};
export const SafetyAuditsTable = ({ project }) => {
    const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false);
    const [audits, setAudits] = useState([]);
    const [selectedAudit, setSelectedAudit] = useState(null);
    const [isViewModalOpen, setIsViewModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false)
    const [isExecutionModalOpen, setIsExecutionModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);


    const handleEdit = (audit) => {
        setSelectedAudit(audit);
        setIsEditModalOpen(true);
    };

    const handleView = (audit) => {
        setSelectedAudit(audit);
        setIsViewModalOpen(true);
    };

    const handleUpdate = (updatedAudit) => {
        console.log('Audit updated:');
    };





    const fetchAudits = async () => {
        try {
            setIsLoading(true);
            const response = await api.get(`projects/${project.id}/safety-audits/`);
            setAudits(response.data.results || []);
        } catch (error) {
            console.error('Error fetching audits:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchAudits();
    }, [project.id]);




    const handleNewAudit = (newAudit) => {
        setAudits([...audits, newAudit]);
        setIsScheduleModalOpen(false);
    };

    const formatAuditType = (audit) => {
        // Extract the type from audit_type_display or fallback to a clean version of audit_type
        const displayType = audit.audit_type_display?.split('_').pop() || 'Safety';
        const displaySubtype = audit.subtype_display?.split('_').pop() || 'Monthly';
        return `${displayType} - ${displaySubtype}`;
    };

    const formatDate = (dateString) => {
        return new Date(dateString).toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        });
    };


    // Priority badge component
    const PriorityBadge = ({ priority }) => {
        const priorityColors = {
            high: 'bg-red-100 text-red-800',
            medium: 'bg-yellow-100 text-yellow-800',
            low: 'bg-green-100 text-green-800'
        };

        return (
            <span className={`px-2 py-1 rounded-full text-sm font-medium ${priorityColors[priority] || 'bg-gray-100'}`}>
                {priority.charAt(0).toUpperCase() + priority.slice(1)}
            </span>
        );
    };

    const handleStartAudit = async (audit) => {
        try {
            if (audit.status === 'in_progress') {
                // If audit is already in progress, just open the execution modal
                setSelectedAudit(audit);
                setIsExecutionModalOpen(true);
                return;
            }

            if (audit.status !== 'scheduled') {
                console.warn(`Cannot start audit with status: ${audit.status}`);
                return;
            }

            const response = await api.post(
                `projects/${project.id}/safety-audits/${audit.id}/start/`
            );

            // Update local audit data
            const updatedAudits = audits.map(a =>
                a.id === audit.id ? response.data : a
            );
            setAudits(updatedAudits);
            setSelectedAudit(response.data);
            setIsExecutionModalOpen(true);
        } catch (error) {
            console.error('Error starting audit:', error);
            if (error.response?.data?.error) {
                fetchAudits();
            }
        }
    };

    // Add handler for continuing in-progress audit
    const handleContinueAudit = (audit) => {
        setSelectedAudit(audit);
        setIsExecutionModalOpen(true);
    };

    const getActionButtons = (audit) => {
        switch (audit.status) {
            case 'scheduled':
                return (
                    <Button
                        className={primaryButtonClasses}
                        variant="default"
                        size="sm"
                        onClick={() => handleStartAudit(audit)}
                    >
                        Start Audit
                    </Button>
                );
            case 'in_progress':
                return (
                    <Button
                        className={primaryButtonClasses}
                        variant="default"
                        size="sm"
                        onClick={() => handleContinueAudit(audit)}
                    >
                        Continue Audit
                    </Button>
                );
            case 'completed':
                return (
                    <Button
                        className={primaryButtonClasses}
                        variant="ghost"
                        size="sm"
                        onClick={() => handleView(audit)}
                    >
                        View Report
                    </Button>
                );
            default:
                return (
                    <>
                        <Button
                            variant="ghost"
                            size="icon"
                            onClick={() => handleEdit(audit)}
                            title="Edit Audit"
                        >
                            <Pencil className="h-4 w-4" />
                        </Button>
                        <Button
                            variant="ghost"
                            size="icon"
                            onClick={() => handleView(audit)}
                            title="View Details"
                        >
                            <Eye className="h-4 w-4" />
                        </Button>
                    </>
                );
        }
    };


    return (
        <div className="space-y-4">
            <div className="flex justify-between items-center">
                <h2 className="text-lg font-semibold">Safety Audits</h2>
                <Button
                    onClick={() => setIsScheduleModalOpen(true)}
                    className={primaryButtonClasses}
                >
                    <Calendar className="w-4 h-4" />
                    Schedule Audit
                </Button>
            </div>

            <Table>
                <TableHeader>
                    <TableRow>
                        <TableHead>Date</TableHead>
                        <TableHead>Type</TableHead>
                        <TableHead>Location</TableHead>
                        <TableHead>Status</TableHead>
                        <TableHead>Priority</TableHead>
                        <TableHead className="text-right">Actions</TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>{isLoading ? (
                    <TableRow>
                        <TableCell colSpan={6}>
                            <LoadingSpinner message="Loading audits..." />
                        </TableCell>
                    </TableRow>
                ) : audits.length === 0 ? (
                    <TableRow>
                        <TableCell colSpan={6} className="text-center">No audits found</TableCell>
                    </TableRow>
                ) : (
                    audits.map((audit) => (
                        <TableRow key={audit.id}>
                            <TableCell>{formatDate(audit.planned_date)}</TableCell>
                            <TableCell>{formatAuditType(audit)}</TableCell>
                            <TableCell>{audit.location}</TableCell>
                            <TableCell><StatusBadge status={audit.status} /></TableCell>
                            <TableCell><PriorityBadge priority={audit.priority} /></TableCell>
                            <TableCell className="text-right">
                                {getActionButtons(audit)}
                            </TableCell>
                        </TableRow>
                    ))
                )}
                </TableBody>
            </Table>
            <SafetyAuditViewModal
                audit={selectedAudit}
                isOpen={isViewModalOpen}
                onClose={() => setIsViewModalOpen(false)}
            />

            <SafetyAuditEditModal
                audit={selectedAudit}
                isOpen={isEditModalOpen}
                onClose={() => setIsEditModalOpen(false)}
                onUpdate={handleUpdate}
            />

            {isScheduleModalOpen && (
                <ScheduleAuditModal
                    project={project}
                    isOpen={isScheduleModalOpen}
                    onClose={() => setIsScheduleModalOpen(false)}
                    onSchedule={handleNewAudit}
                />
            )}
            {/* Add Audit Execution Modal */}
            {isExecutionModalOpen && (
                <AuditExecutionModal
                    project={project}
                    audit={selectedAudit}
                    isOpen={isExecutionModalOpen}
                    onClose={() => {
                        setIsExecutionModalOpen(false);
                        setSelectedAudit(null);
                    }}
                    onComplete={(completedAudit) => {
                        // Refresh audit list
                        fetchAudits();
                        setIsExecutionModalOpen(false);
                        setSelectedAudit(null);
                    }}
                />
            )}
        </div>
    );
};
export default SafetyAuditsTable;