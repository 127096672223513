import React, { useState, useEffect, useContext } from 'react';
import api from '../../services/api';
import { UserIcon } from '@heroicons/react/24/outline';
import { useParams, Link } from 'react-router-dom';
import RFIWorkflowForm from '../RFI/RFIWorkflowForm';
import { toast } from 'react-toastify';
import { Alert, AlertDescription } from "../ui/alert";
import { SubscriptionContext } from '../Subscription/context/SubscriptionContext';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { primaryButtonClasses } from '../common/ButtonStyles';
import { Button } from "../../components/ui/button";
import { useFeatureAccess, FEATURES } from '../../contexts/FeatureAccessContext.js';

const ProjectSettings = () => {
  const { projectId } = useParams();
  const [users, setUsers] = useState([]);
  const [assignedUsers, setAssignedUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [userCount, setUserCount] = useState(10);
  const [assignedUserCount, setAssignedUserCount] = useState(5);
  const [visibleUsers, setVisibleUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [contractors, setContractors] = useState([]);
  const [selectedContractors, setSelectedContractors] = useState([]);
  const [contractorAssignments, setContractorAssignments] = useState([]);


  // Use the FeatureAccessContext for consistent permission checking
  const { hasFeatureAccess } = useFeatureAccess();
  const hasContractorAccess = hasFeatureAccess(FEATURES.PROJECT_MANAGEMENT);

  const [activeTab, setActiveTab] = useState('users');
  const [contractorCount, setContractorCount] = useState(10);
  const [assignedUserDetails, setAssignedUserDetails] = useState([]);

  const togglePanel = () => {
    setIsPanelOpen(!isPanelOpen);
  };

  useEffect(() => {
    fetchUsers();
    fetchContractors();
  }, [userCount, searchTerm]);

  const fetchContractors = async () => {
    try {
      if (!hasContractorAccess) {
        setContractors([]);
        return;
      }
      const response = await api.get('companies/');
      const allContractors = Array.isArray(response.data.results) ? response.data.results : [];
      const filteredContractors = allContractors.filter(contractor => {
        if (!contractor) return false;

        const companyName = (contractor.company_name || contractor.name || '');
        const email = contractor.email || '';

        return companyName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          email.toLowerCase().includes(searchTerm.toLowerCase());
      });

      setContractors(filteredContractors);
    } catch (error) {
      console.error('Error fetching contractors:', error);
      toast.error('Failed to fetch contractors');
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await api.get('tenant/users/');
      const allUsers = Array.isArray(response.data) ? response.data : [];

      const filteredUsers = allUsers.filter(user => {
        if (!user) return false;

        const firstName = user.first_name || '';
        const email = user.email || '';

        return firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          email.toLowerCase().includes(searchTerm.toLowerCase());
      });
      setVisibleUsers(filteredUsers.slice(0, userCount));
      setError(null);
    } catch (error) {
      console.error('Error fetching users:', error);
      setVisibleUsers([]);
      setError('Error fetching users');
    }
  };

  const loadMoreUsers = () => setUserCount(prevCount => prevCount + 10);

  const handleContractorAssignment = (contractorId) => {
    setSelectedContractors(prevSelected => {
      if (prevSelected.includes(contractorId)) {
        setContractorAssignments(prev =>
          prev.filter(assignment => assignment.company_id !== contractorId)
        );
        return prevSelected.filter(id => id !== contractorId);
      } else {
        // Add new contractor assignment
        const assignment = {
          company_id: contractorId,
          start_date: new Date().toISOString().split('T')[0],
          status: 'active',
        };
        setContractorAssignments(prev => [...prev.filter(a => a.company_id !== contractorId), assignment]);
        return [...prevSelected, contractorId];
      }
    });
  };


  useEffect(() => {
    const fetchAssignedUsers = async () => {
      setIsLoading(true);
      try {
        const tenantIdentifier = localStorage.getItem('tenantIdentifier');
        const response = await api.get(`projects/${projectId}/users/`, {
          headers: {
            'X-Tenant-ID': tenantIdentifier
          }
        });

        // Store the full user objects
        const assignedUserDetails = Array.isArray(response.data) ? response.data : [];

        // Extract just the IDs for the checkboxes
        const assignedIds = assignedUserDetails.map(user => user.id);

        setAssignedUsers(assignedIds);
        setSelectedUsers(assignedIds);

        // Store the full user objects for display
        setAssignedUserDetails(assignedUserDetails);
      } catch (error) {
        console.error('Complete error:', error);
        setError('No assigned users found for this project');
        setAssignedUsers([]);
        setSelectedUsers([]);
        setAssignedUserDetails([]);
      } finally {
        setIsLoading(false);
      }
    };
    fetchAssignedUsers();
  }, [projectId]);

  const handleCheckboxChange = (userId) => {
    setSelectedUsers(prevSelected =>
      prevSelected.includes(userId)
        ? prevSelected.filter(id => id !== userId)
        : [...prevSelected, userId]
    );
  };

  const updateProjectUsers = async () => {
    try {
      const payload = {
        user_ids: selectedUsers,
        contractor_assignments: hasContractorAccess ? contractorAssignments.map(assignment => ({
          company_id: assignment.company_id,
          start_date: assignment.start_date,
          status: assignment.status
        })) : []
      };

      const response = await api.put(`projects/${projectId}/update_users/`, payload);

      // Update local state
      setAssignedUsers(selectedUsers);

      // Close panel and show success message
      setIsPanelOpen(false);
      toast.success(response.data.detail || "Project users updated successfully");

      // Refresh users and contractors lists
      fetchUsers();
      if (hasContractorAccess) {
        fetchContractors();
      }

    } catch (error) {
      console.error('Error updating project users:', error);
      toast.error(error.response?.data?.detail || "Failed to update project users");
    }
  };

  const loadMoreAssignedUsers = () => setAssignedUserCount(prevCount => prevCount + 10);
  const loadMoreContractors = () => setContractorCount(prevCount => prevCount + 10);

  const renderAssignedUsersList = () => {
    if (isLoading) {
      return <div className="animate-pulse bg-gray-200 h-20 rounded-md"></div>;
    }

    if (error) {
      return (
        <div className="bg-gray-50 rounded-lg p-4 text-center">
          <p className="text-gray-600">{error}</p>
          <button
            onClick={() => setIsPanelOpen(true)}
            className="mt-2 text-blue-600 hover:text-blue-800 font-medium"
          >
            Assign Users
          </button>
        </div>
      );
    }

    return (
      <div className="flex flex-col space-y-2">
        {assignedUserDetails.slice(0, assignedUserCount).map(user => (
          <div key={user.id} className="flex items-center bg-green-100 p-3 rounded-md shadow-md">
            <UserIcon className="h-5 w-5 mr-2" />
            <div>
              <p className="font-semibold">{user.first_name || ''} {user.last_name || ''}</p>
              <p className="text-sm text-gray-600">{user.email || ''}</p>
            </div>
          </div>
        ))}
        {assignedUserDetails.length > assignedUserCount && (
          <button
            onClick={loadMoreAssignedUsers}
            className="mt-4 bg-gray-200 text-gray-700 py-2 rounded hover:bg-gray-300"
          >
            Load More Assigned Users
          </button>
        )}
      </div>
    );
  };

  return (
    <div className="bg-white shadow-md rounded-lg p-6 max-w-full md:max-w-3xl mx-auto">
      <div className="flex items-center mb-6">
        <Link to={`/projects/${projectId}`} className="mr-3 text-gray-500 hover:text-gray-700">
          <ArrowLeftIcon className="h-5 w-5" />
        </Link>
        <h2 className="text-2xl font-bold">Project Settings</h2>
      </div>

      {/* Assign Users Toggle */}
      <div className="mb-6">
        <h3 className="text-xl font-semibold mb-2">Assign Users</h3>
        <button onClick={togglePanel} className={`relative inline-flex h-6 w-11 items-center rounded-full ${isPanelOpen ? 'bg-green-500' : 'bg-gray-300'}`}>
          <span className={`inline-block h-4 w-4 transform rounded-full bg-white transition-transform ${isPanelOpen ? 'translate-x-6' : 'translate-x-1'}`}></span>
        </button>
      </div>

      {/* Assigned Users List */}
      <div className="mb-6">
        <h3 className="text-xl font-semibold mb-2">Assigned Users</h3>
        {renderAssignedUsersList()}
      </div>

      {/* RFI Workflow Section - Show unconditionally */}
      <div className="mt-8">
        <RFIWorkflowForm projectId={projectId} assignedUsers={assignedUsers} />
      </div>

      {/* Right Panel for Assigning Users */}
      <div className={`fixed top-0 right-0 h-full w-full sm:w-1/3 bg-white shadow-lg p-6 transition-transform transform ${isPanelOpen ? 'translate-x-0' : 'translate-x-full'}`}>
        <h3 className="text-2xl font-semibold mb-4">Assign Users</h3>

        {/* Save Changes Button */}
        <button
          onClick={updateProjectUsers}
          className="bg-[#344bfc] text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition-all mb-4 w-full"
        >
          Save Changes ({selectedUsers.length + selectedContractors.length} Selected)
        </button>

        {/* Tabs */}
        <div className="flex border-b mb-4">
          <button
            className={`py-2 px-4 mr-2 ${activeTab === 'users'
              ? 'border-b-2 border-[#344bfc] text-[#344bfc] font-semibold'
              : 'text-gray-500 hover:text-gray-700'}`}
            onClick={() => setActiveTab('users')}
          >
            Users
          </button>
          <button
            className={`py-2 px-4 ${activeTab === 'contractors'
              ? 'border-b-2 border-[#344bfc] text-[#344bfc] font-semibold'
              : 'text-gray-500 hover:text-gray-700'}`}
            onClick={() => setActiveTab('contractors')}
          >
            Contractors
          </button>
        </div>

        {/* Search Bar */}
        <input
          type="text"
          placeholder={`Search ${activeTab === 'users' ? 'users' : 'companies'}...`}
          className="w-full p-2 border rounded-md mb-4"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />

        <div className="overflow-y-auto h-4/5">
          {activeTab === 'users' ? (
            /* Users Section */
            <div>
              {visibleUsers.map((user) => (
                <div key={user.id} className="flex items-center p-2 border-b">
                  <input
                    type="checkbox"
                    checked={selectedUsers.includes(user.id)}
                    onChange={() => handleCheckboxChange(user.id)}
                    className="mr-2"
                  />
                  <div>
                    <p className="font-semibold">{user.first_name} {user.last_name}</p>
                    <p className="text-sm text-gray-600">{user.email}</p>
                  </div>
                </div>
              ))}
              <button
                onClick={loadMoreUsers}
                className="w-full bg-gray-200 text-gray-700 py-2 mt-4 rounded hover:bg-gray-300"
              >
                Load 10 More Users
              </button>
            </div>
          ) : (
            /* Contractor Companies Section */
            hasContractorAccess ? (
              <div>
                {contractors.slice(0, contractorCount).map((contractor) => (
                  <div key={contractor.id} className="flex items-center p-2 border-b">
                    <input
                      type="checkbox"
                      checked={selectedContractors.includes(contractor.id)}
                      onChange={() => handleContractorAssignment(contractor.id)}
                      className="mr-2"
                    />
                    <div>
                      <p className="font-semibold">{contractor.company_name || contractor.name}</p>
                      <p className="text-sm text-gray-600">{contractor.email}</p>
                    </div>
                  </div>
                ))}
                {contractors.length > contractorCount && (
                  <button
                    onClick={loadMoreContractors}
                    className="w-full bg-gray-200 text-gray-700 py-2 mt-4 rounded hover:bg-gray-300"
                  >
                    Load 10 More Contractors
                  </button>
                )}
              </div>
            ) : (
              <div className="p-4 bg-gray-100 rounded-lg text-center">
                <p className="text-gray-600">You need project management access to manage contractors.</p>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default ProjectSettings;