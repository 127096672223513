import React, { useState, useEffect } from 'react';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { toast } from 'react-toastify';
import api from '../../services/api';

const RFIApproval = ({ projectName, rfi, onApprovalUpdate, onClose }) => {
  const [comment, setComment] = useState('');
  const [comments, setComments] = useState([]);
  const [signature, setSignature] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [visibleComments, setVisibleComments] = useState(2);

  useEffect(() => {
    const fetchComments = async () => {
      try {
        const response = await api.get(`/rfis/${rfi.id}/comments/`);
        setComments(response.data);
      } catch (error) {
        console.error("Error fetching comments:", error);
      }
    };

    fetchComments();
  }, [rfi.id]);

  const loadMoreComments = () => {
    setVisibleComments(prev => prev + 3); // Load 3 more comments
  };

  const handleSignatureChange = (e) => {
    setSignature(e.target.value);
  };

  const handleCommentSubmit = async () => {
    if (!comment.trim()) {
      toast.error("Please enter a comment");
      return;
    }

    try {
      const response = await api.post(`/rfis/${rfi.id}/add-comment/`, { comment });
      const newComment = response.data;
      setComments(prevComments => [...(prevComments || []), newComment]);
      setComment('');
      toast.success("Comment posted successfully");
    } catch (error) {
      console.error("Error submitting comment:", error);
      toast.error("Failed to post comment");
    }
  };

  const validateApproval = () => {
    if (!comment || comment.length < 10) {
      toast.error("Please add a comment (minimum 10 characters)");
      return false;
    }

    if (!signature) {
      toast.error("Signature is required");
      return false;
    }

    return true;
  };

  const handleApprove = async () => {
    if (!validateApproval()) return;

    setIsSubmitting(true);
    try {
      await api.post(`rfis/${rfi.id}/approve/`, {
        comment,
        signature
      });

      toast.success('RFI has been approved successfully');
      onApprovalUpdate();
    } catch (error) {
      console.error('Error approving RFI:', error);
      toast.error('Error approving RFI. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleReject = async () => {
    if (!validateApproval()) return;

    setIsSubmitting(true);
    try {
      await api.post(`rfis/${rfi.id}/reject/`, {
        comment,
        signature
      });

      toast.success('RFI has been rejected successfully');
      onApprovalUpdate();
    } catch (error) {
      console.error('Error rejecting RFI:', error);
      toast.error('Error rejecting RFI. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(amount);
  };

  return (
    <div className="bg-white shadow-md rounded-lg p-6 w-full max-w-3xl relative overflow-auto" style={{ maxHeight: '90vh' }}>
      {/* Close Button */}
      <button onClick={onClose} className="absolute top-4 right-4 text-gray-400 hover:text-gray-600">
        X
      </button>

      {/* Project and RFI Details */}
      <h3 className="text-2xl font-semibold mb-2 text-[#344bfc]">{projectName}</h3>
      <h4 className="text-xl font-semibold mb-4">RFI Details - {projectName}</h4>
      <div className="grid grid-cols-2 gap-4 mb-6">
        <div>
          <p className="text-sm font-medium text-gray-500">RFI Number</p>
          <p className="mt-1">{rfi.rfi_number}</p>
        </div>
        <div>
          <p className="text-sm font-medium text-gray-500">Title</p>
          <p className="mt-1">{rfi.title}</p>
        </div>
        <div>
          <p className="text-sm font-medium text-gray-500">Requesting Party</p>
          <p className="mt-1">{rfi.requesting_name} - {rfi.requesting_company}</p>
        </div>
        <div>
          <p className="text-sm font-medium text-gray-500">Cost Impact</p>
          <p className="mt-1">{formatCurrency(rfi.cost_impact)}</p>
        </div>
      </div>

      {/* Attachments */}
      <div className="mb-6">
        <p className="text-sm font-medium text-gray-500">Attachments</p>
        {rfi.attachments && rfi.attachments.length > 0 ? (
          rfi.attachments.map((attachment) => (
            attachment.document_url ? (
              <a
                href={attachment.document_url}
                target="_blank"
                rel="noopener noreferrer"
                key={attachment.id}
                className="block text-blue-600 hover:underline"
              >
                {attachment.document_url.split('/').pop() || 'Document'}
              </a>
            ) : (
              <p key={attachment.id} className="text-sm text-gray-400">
                No URL available for this attachment.
              </p>
            )
          ))
        ) : (
          <p className="text-sm text-gray-400">No attachments available.</p>
        )}
      </div>

      {/* Comments Section with Scrollable Container */}
      <div className="mb-6 comments-container" style={{ maxHeight: '200px', overflowY: 'auto' }}>
        <h4 className="text-lg font-semibold mb-2">Comments</h4>
        {comments.slice(0, visibleComments).map((com) => (
          <div key={com.id} className="mb-2 border p-2 rounded">
            <p className="text-sm text-gray-800">{com.user_name}: {com.comment}</p>
            <p className="text-xs text-gray-400">{new Date(com.updated_at).toLocaleString()}</p>
          </div>
        ))}
        {comments.length > visibleComments && (
          <button onClick={loadMoreComments} className="text-blue-500 hover:text-blue-700">Load More Comments</button>
        )}
        <textarea
          id="comment"
          rows={3}
          className="mt-2 block w-full rounded-lg border-gray-300 shadow-md focus:border-[#344bfc] focus:ring focus:ring-[#344bfc] focus:ring-opacity-50 px-4 py-2"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          placeholder="Add a comment (10 characters minimum)..."
        />
        <button
          onClick={handleCommentSubmit}
          className="mt-2 px-4 py-2 bg-blue-600 text-white rounded-full hover:bg-blue-700"
        >
          Submit Comment
        </button>
      </div>

      {/* Signature section above approval buttons */}
      <div className="mb-6 p-4 bg-gray-50 rounded-lg border border-gray-200">
        <h3 className="font-semibold mb-2">Electronic Signature</h3>
        <p className="text-sm text-gray-600 mb-2">
          Type your full name to electronically sign this RFI approval or rejection
        </p>
        <input
          type="text"
          value={signature}
          onChange={handleSignatureChange}
          className="w-full p-3 border border-gray-300 rounded-md mb-2"
          placeholder="Your full name"
        />
        <p className="text-xs text-gray-500">
          By typing your name above, you agree that this constitutes a legal electronic signature.
        </p>
      </div>

      {/* Approval Buttons */}
      <div className="flex justify-end space-x-4 mt-4">
        <button
          onClick={handleApprove}
          disabled={comment.length < 10 || !signature || isSubmitting}
          className={`bg-green-600 text-white rounded-full px-6 py-2 transition-all flex items-center ${comment.length >= 10 && signature && !isSubmitting
              ? 'hover:bg-green-700'
              : 'opacity-50 cursor-not-allowed'
            }`}
        >
          <CheckCircleIcon className="h-5 w-5 inline mr-2" /> Approve
        </button>
        <button
          onClick={handleReject}
          disabled={comment.length < 10 || !signature || isSubmitting}
          className={`bg-red-600 text-white rounded-full px-6 py-2 transition-all flex items-center ${comment.length >= 10 && signature && !isSubmitting
              ? 'hover:bg-red-700'
              : 'opacity-50 cursor-not-allowed'
            }`}
        >
          <XCircleIcon className="h-5 w-5 inline mr-2" /> Reject
        </button>
      </div>
    </div>
  );
};

export default RFIApproval;