import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import {
    Tabs,
    TabsList,
    TabsTrigger,
    TabsContent
} from "../../ui/tabs";
import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
} from "../../ui/cardui";
import {
    ClipboardCheck,
    Calendar,
    Clock,
    Camera,
    AlertTriangle,
    CheckCircle2,
    Search,
    Filter,
    Plus,
    Eye,
    ChevronDown,
    PieChart,
    Smartphone,
    Trash2
} from 'lucide-react';
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
} from "../../ui/alert-dialog";
import { Button } from "../../ui/button";
import { Input } from "../../ui/input";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "../../ui/select";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "../../ui/dialog";
import { Badge } from "../../ui/badge";
import { showToast } from '../../../utils/toast';
import api from '../../../services/api';
import ScheduleInspectionForm from './ScheduleInspectionForm';
import InspectionDetails from './InspectionDetails';
import InspectionForm from './InspectionForm';
import MobileInspectionForm from './MobileInspectionForm';
import SyncStatusIndicator from '../../../utils/SyncStatusIndicator';
import { primaryButtonClasses, secondaryButtonClasses } from '../../common/ButtonStyles';
import secureLocalStorage from "react-secure-storage";

const InspectionManager = ({ projectId, phaseId, configureRequest }) => {
    const [templates, setTemplates] = useState({});
    const [activeTemplate, setActiveTemplate] = useState(null);
    const [isInspectionModalOpen, setIsInspectionModalOpen] = useState(false);
    const [inspections, setInspections] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedInspection, setSelectedInspection] = useState(null);
    const [isViewModalOpen, setIsViewModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [statusFilter, setStatusFilter] = useState('all');
    const [searchTerm, setSearchTerm] = useState('');
    const [dashboardMetrics, setDashboardMetrics] = useState(null);
    const [error, setError] = useState(null);
    const [activeTab, setActiveTab] = useState('inspections');
    const [isMobile, setIsMobile] = useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [inspectionToDelete, setInspectionToDelete] = useState(null);

    // Detect if device is mobile
    useEffect(() => {
        const checkMobile = () => {
            // Use user agent and screen size to determine if mobile
            const userAgent = navigator.userAgent.toLowerCase();
            const mobileKeywords = ['android', 'iphone', 'ipad', 'mobile', 'tablet'];
            const isMobileDevice =
                mobileKeywords.some(keyword => userAgent.includes(keyword)) ||
                window.innerWidth < 768;

            setIsMobile(isMobileDevice);
        };

        checkMobile();
        window.addEventListener('resize', checkMobile);

        return () => window.removeEventListener('resize', checkMobile);
    }, []);


    useEffect(() => {
        if (isMobile) {
            setActiveTab('mobile');
        }
    }, [isMobile]);

    const defaultConfigureRequest = (config = {}) => {
        // Try both storage methods for backward compatibility
        const tenantId = localStorage.getItem('tenantIdentifier') ||
            secureLocalStorage.getItem('currentTenantId');

        return {
            ...config,
            headers: {
                ...config.headers,
                'X-Tenant-ID': tenantId || '',
            }
        };
    };

    const getConfig = config => {
        // Try both storage methods for backward compatibility
        const tenantId = localStorage.getItem('tenantIdentifier') ||
            secureLocalStorage.getItem('currentTenantId');

        if (!tenantId) {
            console.warn('No tenant ID found in storage');
        }

        if (typeof configureRequest === 'function') {
            return configureRequest({
                ...config,
                headers: {
                    ...config?.headers,
                    'X-Tenant-ID': tenantId || '',
                }
            });
        }

        return {
            ...config,
            headers: {
                ...config?.headers,
                'X-Tenant-ID': tenantId || '',
            }
        };
    };

    useEffect(() => {
        // Validate projectId before making API calls
        if (projectId && projectId !== 'undefined' && projectId !== 'null') {
            // Check for tenant ID using the correct keys
            const tenantId = localStorage.getItem('tenantIdentifier') ||
                secureLocalStorage.getItem('currentTenantId');

            if (!tenantId) {
                console.warn("No tenant ID found in storage");
                setLoading(false);
                setError("Missing tenant ID. Please log in again.");
                showToast.error('Missing tenant ID. Please log in again.');
            } else {
                // Tenant ID exists, proceed with fetching data
                fetchTemplatesAndInspections();
                fetchDashboardMetrics();
            }
        } else {
            console.warn("Invalid project ID detected:", projectId);
            setLoading(false);
            setError("Invalid project ID. Please ensure you're viewing a valid project.");
        }
    }, [projectId]);

    const fetchTemplatesAndInspections = async () => {
        try {
            setLoading(true);
            setError(null);

            // Validate projectId again as a safeguard
            if (!projectId || projectId === 'undefined' || projectId === 'null') {
                throw new Error("Invalid project ID");
            }

            // First fetch templates
            let templatesRes;
            try {
                templatesRes = await api.get('inspection-templates/', getConfig());
            } catch (error) {
                console.warn('Error fetching templates, attempting to create defaults:', error);

                // If no templates exist, try to create default templates
                if (error.response?.status === 404 || error.response?.data?.results?.length === 0) {
                    try {
                        await api.post('inspection-templates/create-defaults/', {}, getConfig());
                        templatesRes = await api.get('inspection-templates/', getConfig());
                    } catch (createError) {
                        console.error('Failed to create default templates:', createError);
                        throw new Error('Failed to create inspection templates');
                    }
                } else {
                    throw error;
                }
            }

            // Now fetch inspections
            //const inspectionsRes = await api.get(`projects/${projectId}/inspections/`, getConfig());
            console.log(`Fetching inspections for project ${projectId}...`);
            const inspectionsRes = await api.get(`projects/${projectId}/inspections/`, getConfig());
            console.log("Inspections response:", inspectionsRes);

            // Handle different response formats for templates
            let templatesData;
            if (templatesRes && typeof templatesRes === 'object') {
                if (templatesRes.data?.results) {
                    templatesData = templatesRes.data.results;
                } else if (templatesRes.results) {
                    templatesData = templatesRes.results;
                } else {
                    templatesData = templatesRes;
                }
            } else {
                const parsed = await templatesRes.json();
                templatesData = parsed.data?.results || parsed.results || parsed;
            }

            if (!Array.isArray(templatesData)) {
                console.error('Templates data is not an array:', templatesData);
                throw new Error('Invalid templates data format');
            }

            // Ensure each template has proper structure for forms
            templatesData = templatesData.map(template => {
                // Make sure checklist_items is initialized as an array
                if (!template.checklist_items) {
                    template.checklist_items = [];
                }

                // Add IDs to checklist items if they don't have them
                template.checklist_items = template.checklist_items.map((item, index) => {
                    return {
                        ...item,
                        id: item.id || `item-${index}`,
                        name: item.name || item.item || `Item ${index + 1}`
                    };
                });

                return template;
            });

            const groupedTemplates = templatesData.reduce((acc, template) => {
                const type = template.template_type;
                if (!acc[type]) acc[type] = [];
                acc[type].push(template);
                return acc;
            }, {});

            console.log('Final grouped templates:', groupedTemplates);

            setTemplates(groupedTemplates);

            let inspectionsData;
            if (inspectionsRes && typeof inspectionsRes === 'object') {
                // First try to get data from the response object
                inspectionsData = inspectionsRes.data || inspectionsRes;

                // If it's still not an array, try to extract it from different properties
                if (!Array.isArray(inspectionsData)) {
                    if (Array.isArray(inspectionsData.results)) {
                        inspectionsData = inspectionsData.results;
                    } else if (inspectionsData.data && Array.isArray(inspectionsData.data)) {
                        inspectionsData = inspectionsData.data;
                    } else {
                        console.warn("Unexpected inspections data format:", inspectionsData);
                        inspectionsData = [];
                    }
                }
            } else {
                try {
                    const parsed = await inspectionsRes.json();
                    inspectionsData = Array.isArray(parsed) ? parsed :
                        Array.isArray(parsed.results) ? parsed.results :
                            Array.isArray(parsed.data) ? parsed.data : [];
                } catch (err) {
                    console.error("Error parsing inspections response:", err);
                    inspectionsData = [];
                }
            }

            // Log the final data we're setting
            console.log("Setting inspections with data:", inspectionsData);
            setInspections(Array.isArray(inspectionsData) ? inspectionsData : []);

        } catch (error) {
            console.error('Fetch error:', error);
            setError(error.message || "Failed to load inspection data");
        } finally {
            setLoading(false);
        }
    };
    const handleDeleteInspection = async (inspectionId) => {
        try {
            // Get tenant ID
            const tenantId = localStorage.getItem('tenantIdentifier') ||
                secureLocalStorage.getItem('currentTenantId');

            if (!tenantId) {
                showToast.error('Missing tenant ID. Please log in again.');
                return;
            }

            // Make delete request
            await api.delete(
                `projects/${projectId}/inspections/${inspectionId}/`,
                {
                    headers: {
                        'X-Tenant-ID': tenantId,
                        'Content-Type': 'application/json'
                    }
                }
            );

            showToast.success('Inspection deleted successfully');
            await fetchTemplatesAndInspections();
            await fetchDashboardMetrics();
        } catch (error) {
            console.error('API error:', error);
            showToast.error('Failed to delete inspection: ' +
                (error.response?.data?.detail || error.message));
        }
    };

    const fetchDashboardMetrics = async () => {
        try {
            if (!projectId || projectId === 'undefined' || projectId === 'null') {
                return;
            }

            const response = await api.get(`projects/${projectId}/inspections/dashboard_metrics/`, getConfig());
            let metricsData = response.data || response;

            setDashboardMetrics(metricsData);
        } catch (error) {
            console.error('Failed to fetch dashboard metrics:', error);
        }
    };

    const handleScheduleInspection = async (templateId, scheduledDate, selectedPhaseId) => {
        try {
            // Get tenant ID
            const tenantId = localStorage.getItem('tenantIdentifier') ||
                secureLocalStorage.getItem('currentTenantId');

            if (!tenantId) {
                showToast.error('Missing tenant ID. Please log in again.');
                return;
            }

            // Get template details - find template object across all template types
            let foundTemplate = null;
            for (const type in templates) {
                const matchingTemplate = templates[type].find(t => t.id === templateId);
                if (matchingTemplate) {
                    foundTemplate = matchingTemplate;
                    break;
                }
            }

            if (!foundTemplate) {
                showToast.error('Template not found');
                return;
            }

            // CRITICAL FIX: Create properly structured results object for each checklist item
            const initialResults = {};

            // Make sure each checklist item has an entry in the results
            if (foundTemplate.checklist_items && Array.isArray(foundTemplate.checklist_items)) {
                foundTemplate.checklist_items.forEach((item) => {
                    // Use item.id as the key if it exists, otherwise use the item name or generate a key
                    const itemId = item.id || item.name || `item-${Object.keys(initialResults).length}`;
                    initialResults[itemId] = {
                        name: item.name || item.item || `Item ${Object.keys(initialResults).length + 1}`,
                        passed: false,
                        notes: "",
                        photos: []
                    };
                });
            }

            // Add "None" entry as a default if there are no items or as an additional entry
            if (Object.keys(initialResults).length === 0) {
                initialResults["None"] = {
                    name: "Default Item",
                    passed: false,
                    notes: "",
                    photos: []
                };
            }

            // Format the date
            const formattedDate = scheduledDate instanceof Date
                ? scheduledDate.toISOString()
                : scheduledDate;

            // Create payload with initialized results
            const payload = {
                template: templateId,
                project: projectId,
                phase: selectedPhaseId,
                scheduled_date: formattedDate,
                status: 'scheduled',
                client_visible: true,
                tenant: tenantId,
                results: initialResults,
                // Add a template reference that includes the checklist items
                template_data: {
                    name: foundTemplate.name,
                    template_type: foundTemplate.template_type,
                    checklist_items: foundTemplate.checklist_items || []
                }
            };

            console.log('Final inspection payload:', JSON.stringify(payload, null, 2));

            // Make request with explicit headers
            const response = await api.post(
                `projects/${projectId}/inspections/`,
                payload,
                {
                    headers: {
                        'X-Tenant-ID': tenantId,
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
                    }
                }
            );

            showToast.success('Inspection scheduled successfully');
            setIsInspectionModalOpen(false);
            await fetchTemplatesAndInspections();
            await fetchDashboardMetrics();
        } catch (error) {
            console.error('API error:', error);
            console.error('Full error response:', error.response?.data);

            showToast.error('Failed to schedule inspection: ' +
                (error.response?.data?.detail || error.response?.data?.non_field_errors?.[0] || error.message));
        }
    };

    const handleCompleteInspection = async (inspectionId, formData) => {
        try {
            // Get tenant ID
            const tenantId = localStorage.getItem('tenantIdentifier') ||
                secureLocalStorage.getItem('currentTenantId');

            if (!tenantId) {
                showToast.error('Missing tenant ID. Please log in again.');
                return;
            }

            // Prepare payload
            const payload = {
                ...formData,
                status: formData.status || 'in_progress', // Default to in_progress if not specified
                completed_date: formData.status === 'passed' || formData.status === 'failed'
                    ? new Date().toISOString()
                    : null
            };

            // Make the update request
            const response = await api.put(
                `projects/${projectId}/inspections/${inspectionId}/`,
                payload,
                {
                    headers: {
                        'X-Tenant-ID': tenantId,
                        'Content-Type': 'application/json'
                    }
                }
            );

            // If inspection is being completed, also call the complete endpoint
            if (formData.status === 'passed' || formData.status === 'failed') {
                await api.post(
                    `projects/${projectId}/inspections/${inspectionId}/complete_inspection/`,
                    {},
                    {
                        headers: {
                            'X-Tenant-ID': tenantId,
                            'Content-Type': 'application/json'
                        }
                    }
                );
            }

            showToast.success('Inspection updated successfully');
            setIsEditModalOpen(false);
            await fetchTemplatesAndInspections();
            await fetchDashboardMetrics();
        } catch (error) {
            console.error('API error:', error);
            showToast.error('Failed to update inspection: ' +
                (error.response?.data?.detail || error.message));
        }
    };

    const getStatusBadgeColor = (status) => {
        switch (status) {
            case 'passed':
                return 'bg-green-100 text-green-800';
            case 'failed':
                return 'bg-red-100 text-red-800';
            case 'scheduled':
                return 'bg-blue-100 text-blue-800';
            case 'in_progress':
                return 'bg-yellow-100 text-yellow-800';
            case 'remediation_required':
                return 'bg-orange-100 text-orange-800';
            default:
                return 'bg-gray-100 text-gray-800';
        }
    };

    const TemplateSelector = () => {
        if (loading) {
            return <div className="text-center py-4">Loading templates...</div>;
        }

        if (Object.keys(templates).length === 0) {
            return (
                <div className="text-center py-4">
                    <p className="text-gray-500">No templates available.</p>
                </div>
            );
        }

        return (
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                {Object.entries(templates).map(([type, typeTemplates]) => (
                    <Card key={type} className="flex flex-col">
                        <CardHeader>
                            <CardTitle className="capitalize">
                                {type.replace('_', ' ')} Inspections
                            </CardTitle>
                            <CardDescription>
                                {getTemplateTypeDescription(type)}
                            </CardDescription>
                        </CardHeader>
                        <CardContent className="flex-grow">
                            <div className="space-y-2">
                                {typeTemplates.map(template => (
                                    <Button
                                        key={template.id}
                                        variant="outline"
                                        className="w-full justify-start text-left"
                                        onClick={() => {
                                            setActiveTemplate(template);
                                            setIsInspectionModalOpen(true);
                                        }}
                                    >
                                        <ClipboardCheck className="w-4 h-4 mr-2" />
                                        {template.name}
                                        {template.checklist_items?.length > 0 &&
                                            <span className="ml-auto bg-gray-100 text-gray-700 text-xs rounded-full px-2 py-1">
                                                {template.checklist_items.length} items
                                            </span>
                                        }
                                    </Button>
                                ))}
                            </div>
                        </CardContent>
                    </Card>
                ))}
            </div>
        );
    };

    const getTemplateTypeDescription = (type) => {
        switch (type) {
            case 'daily':
                return 'Regular safety and progress checks';
            case 'phase':
                return 'Major construction phase verifications';
            case 'milestone':
                return 'Critical milestone validations';
            case 'specialty':
                return 'Specialized inspection requirements';
            default:
                return 'Custom inspection templates';
        }
    };

    const InspectionsList = () => {
        console.log("Current inspections:", inspections);
        console.log("Filter criteria:", { searchTerm, statusFilter });

        const filteredInspections = inspections.filter(inspection => {
            if (!inspection || !inspection.template_name) {
                console.warn("Invalid inspection data:", inspection);
                return false;
            }
            const matchesSearch = searchTerm === '' ||
                inspection.template_name.toLowerCase().includes(searchTerm.toLowerCase());
            const matchesStatus = statusFilter === 'all' || inspection.status === statusFilter;
            return matchesSearch && matchesStatus;
        });

        console.log("Filtered inspections:", filteredInspections);

        return (
            <div className="space-y-4">
                <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-2">
                    <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2 w-full sm:w-auto">
                        <Input
                            placeholder="Search inspections..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="w-full sm:w-64"
                            icon={<Search className="w-4 h-4" />}
                        />
                        <Select value={statusFilter} onValueChange={setStatusFilter}>
                            <SelectTrigger className="w-full sm:w-40">
                                <SelectValue placeholder="Status" />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectItem value="all">All Status</SelectItem>
                                <SelectItem value="scheduled">Scheduled</SelectItem>
                                <SelectItem value="in_progress">In Progress</SelectItem>
                                <SelectItem value="passed">Passed</SelectItem>
                                <SelectItem value="failed">Failed</SelectItem>
                                <SelectItem value="remediation_required">Remediation Required</SelectItem>
                            </SelectContent>
                        </Select>
                    </div>
                    <Button
                        onClick={() => setIsInspectionModalOpen(true)}
                        className={`${primaryButtonClasses} w-full sm:w-auto`}
                    >
                        <Plus className="w-4 h-4 mr-2" />
                        Schedule Inspection
                    </Button>
                </div>

                <div className="grid gap-4">
                    {loading ? (
                        <div className="text-center py-8">
                            <p className="text-gray-500">Loading inspections...</p>
                        </div>
                    ) : filteredInspections.length === 0 ? (
                        <div className="text-center py-8">
                            <p className="text-gray-500">
                                {inspections.length > 0
                                    ? "No inspections match your current filters."
                                    : "No inspections found for this project."}
                            </p>
                            <Button
                                onClick={() => setIsInspectionModalOpen(true)}
                                className="mt-4"
                                variant="outline"
                            >
                                <Plus className="w-4 h-4 mr-2" />
                                Schedule New Inspection
                            </Button>
                        </div>
                    ) : (
                        filteredInspections.map(inspection => (
                            <Card key={inspection.id}>
                                <CardHeader className="pb-4">
                                    <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-2">
                                        <div>
                                            <CardTitle>{inspection.template_name}</CardTitle>
                                            <CardDescription>
                                                Scheduled: {format(new Date(inspection.scheduled_date), 'PPP')}
                                            </CardDescription>
                                        </div>
                                        <Badge className={getStatusBadgeColor(inspection.status)}>
                                            {inspection.status.replace('_', ' ')}
                                        </Badge>
                                    </div>
                                </CardHeader>
                                <CardContent>
                                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                        <div>
                                            <p className="text-sm text-gray-500">Inspector</p>
                                            <p>{inspection.inspector_name || 'Not assigned'}</p>
                                        </div>
                                        <div>
                                            <p className="text-sm text-gray-500">Type</p>
                                            <p className="capitalize">{inspection.template_type?.replace('_', ' ') || 'Standard'}</p>
                                        </div>
                                        {inspection.phase_name && (
                                            <div>
                                                <p className="text-sm text-gray-500">Phase</p>
                                                <p>{inspection.phase_name}</p>
                                            </div>
                                        )}
                                        {inspection.completed_date && (
                                            <div>
                                                <p className="text-sm text-gray-500">Completed</p>
                                                <p>{format(new Date(inspection.completed_date), 'PPP')}</p>
                                            </div>
                                        )}
                                        {inspection.quality_issues_count > 0 && (
                                            <div>
                                                <p className="text-sm text-gray-500">Quality Issues</p>
                                                <p>{inspection.quality_issues_count}</p>
                                            </div>
                                        )}
                                    </div>
                                </CardContent>
                                <CardFooter className="flex flex-col sm:flex-row justify-end space-y-2 sm:space-y-0 sm:space-x-2">
                                    <Button
                                        className={`${secondaryButtonClasses} w-full sm:w-auto`}
                                        variant="outline"
                                        onClick={() => {
                                            setSelectedInspection(inspection);
                                            setIsViewModalOpen(true);
                                        }}
                                    >
                                        <Eye className="w-4 h-4 mr-2" />
                                        View Details
                                    </Button>
                                    {inspection.status !== 'passed' && inspection.status !== 'failed' && (
                                        <Button
                                            className={`${primaryButtonClasses} w-full sm:w-auto`}
                                            onClick={() => {
                                                setSelectedInspection(inspection);
                                                setIsEditModalOpen(true);
                                            }}
                                        >
                                            <ClipboardCheck className="w-4 h-4 mr-2" />
                                            {inspection.status === 'in_progress' ? 'Start Inspection' : 'Continue Inspection'}
                                        </Button>
                                    )}
                                    {inspection.status !== 'passed' && inspection.status !== 'failed' && (
                                        <Button
                                            className="text-red-500 border-red-300 hover:bg-red-50 w-full sm:w-auto"
                                            variant="outline"
                                            onClick={() => {
                                                setInspectionToDelete(inspection);
                                                setIsDeleteDialogOpen(true);
                                            }}
                                        >
                                            <Trash2 className="w-4 h-4 mr-2" />
                                            Delete
                                        </Button>
                                    )}
                                </CardFooter>
                            </Card>
                        ))
                    )}
                </div>
            </div>
        );
    };

    const Dashboard = () => {
        if (!dashboardMetrics) {
            return (
                <div className="text-center py-8">
                    <p className="text-gray-500">Loading metrics...</p>
                </div>
            );
        }

        return (
            <div className="space-y-6">
                {/* Summary Cards */}
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
                    <Card>
                        <CardHeader className="pb-2">
                            <CardTitle className="text-lg">Total Inspections</CardTitle>
                        </CardHeader>
                        <CardContent>
                            <p className="text-3xl font-bold">{dashboardMetrics.total_inspections || 0}</p>
                        </CardContent>
                    </Card>
                    <Card>
                        <CardHeader className="pb-2">
                            <CardTitle className="text-lg">Passed</CardTitle>
                        </CardHeader>
                        <CardContent>
                            <p className="text-3xl font-bold text-green-600">{dashboardMetrics.passed || 0}</p>
                            <p className="text-sm text-gray-500">
                                {dashboardMetrics.average_pass_percentage ?
                                    `${Math.round(dashboardMetrics.average_pass_percentage)}% avg pass rate` : ''}
                            </p>
                        </CardContent>
                    </Card>
                    <Card>
                        <CardHeader className="pb-2">
                            <CardTitle className="text-lg">Failed</CardTitle>
                        </CardHeader>
                        <CardContent>
                            <p className="text-3xl font-bold text-red-600">{dashboardMetrics.failed || 0}</p>
                        </CardContent>
                    </Card>
                    <Card>
                        <CardHeader className="pb-2">
                            <CardTitle className="text-lg">Quality Issues</CardTitle>
                        </CardHeader>
                        <CardContent>
                            <p className="text-3xl font-bold text-amber-600">{dashboardMetrics.quality_issues || 0}</p>
                        </CardContent>
                    </Card>
                </div>

                {/* Status Distribution */}
                <Card>
                    <CardHeader>
                        <CardTitle>Inspection Status</CardTitle>
                    </CardHeader>
                    <CardContent>
                        <div className="grid grid-cols-2 md:grid-cols-5 gap-4">
                            {dashboardMetrics.by_status?.map(item => (
                                <div key={item.status} className="text-center">
                                    <div className="inline-flex items-center justify-center w-12 h-12 rounded-full mb-2"
                                        style={{ backgroundColor: getStatusColor(item.status) }}>
                                        <span className="text-white font-bold">{item.count}</span>
                                    </div>
                                    <p className="text-sm font-medium capitalize">{item.status.replace('_', ' ')}</p>
                                </div>
                            ))}
                        </div></CardContent>
                </Card>

                {/* Inspection Types */}
                <Card>
                    <CardHeader>
                        <CardTitle>Inspection Types</CardTitle>
                    </CardHeader>
                    <CardContent>
                        <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                            {dashboardMetrics.by_template_type?.map(item => (
                                <div key={item.template__template_type} className="border rounded-lg p-4 text-center">
                                    <p className="text-xl font-bold">{item.count}</p>
                                    <p className="text-sm text-gray-500 capitalize">
                                        {item.template__template_type?.replace('_', ' ') || 'Unknown'} Inspections
                                    </p>
                                </div>
                            ))}
                        </div>
                    </CardContent>
                </Card>

                {/* Recent Activity */}
                <Card>
                    <CardHeader>
                        <CardTitle>Recent Activity</CardTitle>
                    </CardHeader>
                    <CardContent>
                        <div className="space-y-4">
                            <div className="flex justify-between items-center">
                                <p className="font-medium">Recent Inspections</p>
                                <p className="text-lg font-bold">{dashboardMetrics.recent_inspections || 0}</p>
                            </div>
                            <div className="h-2 bg-gray-100 rounded-full">
                                <div
                                    className="h-2 bg-blue-500 rounded-full"
                                    style={{
                                        width: `${Math.min(
                                            (dashboardMetrics.recent_inspections / Math.max(dashboardMetrics.total_inspections, 1)) * 100,
                                            100
                                        )}%`
                                    }}
                                ></div>
                            </div>
                            <Button variant="outline" className="w-full mt-4" onClick={() => setActiveTab('inspections')}>
                                View All Inspections
                            </Button>
                        </div>
                    </CardContent>
                </Card>
            </div>
        );
    };

    const getStatusColor = (status) => {
        switch (status) {
            case 'passed':
                return '#22c55e'; // green-500
            case 'failed':
                return '#ef4444'; // red-500
            case 'scheduled':
                return '#3b82f6'; // blue-500
            case 'in_progress':
                return '#f59e0b'; // amber-500
            case 'remediation_required':
                return '#f97316'; // orange-500
            default:
                return '#6b7280'; // gray-500
        }
    };

    const MobileView = () => {
        return (
            <div className="space-y-4">
                <Card>
                    <CardHeader>
                        <CardTitle className="text-xl flex items-center">
                            <Smartphone className="w-5 h-5 mr-2" />
                            Field Inspection Mode
                        </CardTitle>
                        <CardDescription>
                            Optimized view for completing inspections in the field
                        </CardDescription>
                    </CardHeader>
                    <CardContent className="space-y-4">
                        <div className="grid grid-cols-2 gap-4">
                            <Button
                                onClick={() => setIsInspectionModalOpen(true)}
                                className={`${primaryButtonClasses} h-24 flex flex-col justify-center`}
                            >
                                <Plus className="w-8 h-8 mb-2" />
                                <span>New Inspection</span>
                            </Button>

                            <Button
                                onClick={() => setActiveTab('inspections')}
                                variant="outline"
                                className="h-24 flex flex-col justify-center"
                            >
                                <Eye className="w-8 h-8 mb-2" />
                                <span>View All</span>
                            </Button>
                        </div>

                        {/* Quick access to recent inspections */}
                        <div className="mt-4">
                            <h3 className="font-medium mb-2">Recent Inspections</h3>
                            <div className="space-y-2">
                                {inspections.slice(0, 3).map(inspection => (
                                    <Card key={inspection.id} className="p-4">
                                        <div className="flex justify-between items-center">
                                            <div>
                                                <p className="font-medium">{inspection.template_name}</p>
                                                <p className="text-sm text-gray-500">
                                                    {format(new Date(inspection.scheduled_date), 'PP')}
                                                </p>
                                            </div>

                                            <Button
                                                onClick={() => {
                                                    setSelectedInspection(inspection);
                                                    setIsEditModalOpen(true);
                                                }}
                                                size="sm"
                                                disabled={inspection.status === 'passed' || inspection.status === 'failed'}
                                            >
                                                {inspection.status === 'scheduled' ? 'Start' : 'Continue'}
                                            </Button>
                                        </div>
                                    </Card>
                                ))}
                            </div>
                        </div>
                    </CardContent>
                </Card>

                {/* Sync status indicator for offline support */}
                <SyncStatusIndicator />
            </div>
        );
    };


    return (
        <div className="space-y-6">
            <div className="flex justify-between items-center">
                <h2 className="text-3xl font-bold">Quality Inspections</h2>
                <Button
                    variant="ghost"
                    onClick={() => setIsMobile(!isMobile)}
                    className="flex items-center"
                >
                    {isMobile ? 'Desktop View' : 'Mobile View'}
                </Button>
            </div>

            {isMobile ? (
                // Mobile view with recent inspections and quick actions
                <MobileView />
            ) : (
                // Desktop view with tabs
                <Tabs defaultValue="inspections" value={activeTab} onValueChange={setActiveTab}>
                    <TabsList>
                        <TabsTrigger value="dashboard">
                            <PieChart className="w-4 h-4 mr-2" />
                            Dashboard
                        </TabsTrigger>
                        <TabsTrigger value="inspections">
                            <ClipboardCheck className="w-4 h-4 mr-2" />
                            Inspections
                        </TabsTrigger>
                        <TabsTrigger value="templates">
                            <Calendar className="w-4 h-4 mr-2" />
                            Templates
                        </TabsTrigger>
                    </TabsList>

                    <TabsContent value="dashboard" className="mt-6">
                        <Dashboard />
                    </TabsContent>

                    <TabsContent value="inspections" className="mt-6">
                        <InspectionsList />
                    </TabsContent>

                    <TabsContent value="templates" className="mt-6">
                        <TemplateSelector />
                    </TabsContent>
                </Tabs>
            )}

            {/* Schedule Inspection Modal */}
            <Dialog
                open={isInspectionModalOpen}
                onOpenChange={(open) => {
                    if (!open) {
                        setActiveTemplate(null);
                    }
                    setIsInspectionModalOpen(open);
                }}
            >
                <DialogContent className="max-w-4xl">
                    <DialogHeader>
                        <DialogTitle>
                            {activeTemplate ? 'Schedule Inspection' : 'Select Template'}
                        </DialogTitle>
                        <DialogDescription>
                            {activeTemplate
                                ? 'Choose a date and time for the inspection'
                                : 'Select an inspection template to schedule'}
                        </DialogDescription>
                    </DialogHeader>
                    {activeTemplate ? (
                        <ScheduleInspectionForm
                            template={activeTemplate}
                            projectId={projectId}
                            phaseId={phaseId}
                            onSubmit={handleScheduleInspection}
                            onCancel={() => setIsInspectionModalOpen(false)}
                        />
                    ) : (
                        <TemplateSelector />
                    )}
                </DialogContent>
            </Dialog>

            {/* View Inspection Modal */}
            {selectedInspection && (
                <Dialog
                    open={isViewModalOpen}
                    onOpenChange={setIsViewModalOpen}
                    className="max-h-[90vh] overflow-y-auto"
                >
                    <DialogContent className="max-w-4xl max-h-[90vh] overflow-y-auto">
                        <DialogHeader>
                            <DialogTitle>Inspection Details</DialogTitle>
                        </DialogHeader>
                        <InspectionDetails inspection={selectedInspection} />
                    </DialogContent>
                </Dialog>
            )}

            {/* Edit/Conduct Inspection Modal */}
            {selectedInspection && (
                <Dialog
                    open={isEditModalOpen}
                    onOpenChange={setIsEditModalOpen}
                    className="max-h-[90vh] overflow-y-auto"
                >
                    <DialogContent className="max-w-4xl max-h-[90vh] overflow-y-auto">
                        <DialogHeader>
                            <DialogTitle>
                                {selectedInspection.status === 'scheduled' ? 'Start Inspection' : 'Continue Inspection'}
                            </DialogTitle>
                        </DialogHeader>
                        {isMobile ? (
                            <MobileInspectionForm
                                template={
                                    selectedInspection.template_data ||
                                    (selectedInspection.template_type && templates[selectedInspection.template_type]?.find(t => t.id === selectedInspection.template)) ||
                                    null
                                }
                                projectId={projectId}
                                phaseId={selectedInspection.phase}
                                inspection={selectedInspection}
                                onSubmit={async (data) => handleCompleteInspection(selectedInspection.id, data)}
                                onCancel={() => setIsEditModalOpen(false)}
                            />
                        ) : (
                            <InspectionForm
                                template={
                                    selectedInspection.template_data ||
                                    (selectedInspection.template_type && templates[selectedInspection.template_type]?.find(t => t.id === selectedInspection.template)) ||
                                    null
                                }
                                projectId={projectId}
                                phaseId={selectedInspection.phase}
                                inspection={selectedInspection}
                                onSubmit={async (data) => handleCompleteInspection(selectedInspection.id, data)}
                                onCancel={() => setIsEditModalOpen(false)}
                            />
                        )}
                    </DialogContent>
                </Dialog>
            )}
            {inspectionToDelete && (
                <AlertDialog open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
                    <AlertDialogContent>
                        <AlertDialogHeader>
                            <AlertDialogTitle>Confirm Deletion</AlertDialogTitle>
                            <AlertDialogDescription>
                                Are you sure you want to delete the inspection "{inspectionToDelete.template_name}"?
                                This action cannot be undone.
                            </AlertDialogDescription>
                        </AlertDialogHeader>
                        <AlertDialogFooter>
                            <AlertDialogCancel>Cancel</AlertDialogCancel>
                            <AlertDialogAction
                                className="bg-red-600 hover:bg-red-700 text-white"
                                onClick={() => {
                                    handleDeleteInspection(inspectionToDelete.id);
                                    setIsDeleteDialogOpen(false);
                                }}
                            >
                                Delete
                            </AlertDialogAction>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialog>
            )}
        </div>
    );
};

export default InspectionManager;