import api from './api';
import { calculateTotalAmount, validateQuantityRange } from '../utils/calculations';

export const budgetService = {
    // Budget endpoints
    getAllBudgets: async (projectId) => {
        const response = await api.get(`budgets/?project=${projectId}`);
        return response.data;
    },
    getBudgetById: async (budgetId) => {
        const response = await api.get(`/budgets/${budgetId}`);
        return response.data;
    },

    createBudget: async (budgetData) => {
        const response = await api.post('budgets/', budgetData);
        return response.data;
    },

    updateBudget: async (id, budgetData) => {
        const response = await api.put(`budgets/${id}/`, budgetData);
        return response.data;
    },

    deleteBudget: async (id) => {
        await api.delete(`budgets/${id}/`);
    },

    createBudgetFromEstimate: async (budgetId, estimateId) => {
        const response = await api.post(`budgets/${budgetId}/create_from_estimate/`, {
            estimate_id: estimateId
        });
        return response.data;
    },

    getBudgetSummary: async (id) => {
        const response = await api.get(`budgets/${id}/summary/`);
        return response.data;
    },
    // Budget Categories
    createCategory: async (categoryData) => {
        const response = await api.post(`budgets/${categoryData.budget}/categories/`, categoryData);
        return response.data;
    },

    getBudgetLineItems: async (budgetId) => {
        const response = await api.get(`budget-line-items/?budget=${budgetId}`);
        return response.data;
    },
    updateCategory: async (categoryId, data) => {
        const response = await api.put(`budget-category/${categoryId}/`, data);
        return response.data;
    },

    deleteCategory: async (id) => {
        await api.delete(`budget-category/${id}/`);
    },
    getCategories: async (budgetId) => {
        const response = await api.get(`budgets/${budgetId}/categories/`);
        return response.data;
    },

    // Budget Line Items
    createLineItem: async (lineItemData) => {
        const response = await api.post('budget-line-items/', lineItemData);
        return response.data;
    },

    updateLineItem: async (id, lineItemData) => {
        const response = await api.put(`budget-line-items/${id}/`, lineItemData);
        return response.data;
    },

    deleteLineItem: async (id) => {
        await api.delete(`budget-line-items/${id}/`);
    },

    // Budget Actuals
    createActual: async (actualData) => {
        const response = await api.post('budget-actuals/', actualData);
        return response.data;
    },

    updateActual: async (id, actualData) => {
        const response = await api.put(`budget-actuals/${id}/`, actualData);
        return response.data;
    },

    deleteActual: async (id) => {
        await api.delete(`budget-actuals/${id}/`);
    },

    // Budget Change Orders
    createChangeOrder: async (changeOrderData) => {
        const response = await api.post('budget-change-orders/', changeOrderData);
        return response.data;
    },

    updateChangeOrder: async (id, changeOrderData) => {
        const response = await api.put(`budget-change-orders/${id}/`, changeOrderData);
        return response.data;
    },

    deleteChangeOrder: async (id) => {
        await api.delete(`budget-change-orders/${id}/`);
    },

    // Cost Codes (with pagination and search)
    getCostCodes: async (page = 1, search = '') => {
        const response = await api.get('cost-codes/', {
            params: {
                page,
                search,
                page_size: 50 // Limit the number of results per page
            }
        });
        return response.data;
    },

    // Approvals for change orders
    getChangeOrderApprovals: async (changeOrderId) => {
        const response = await api.get(`change-orders/${changeOrderId}/approvals/`);
        return response.data;
    },

    approveChangeOrder: async (changeOrderId, approvalData) => {
        const response = await api.post(`change-orders/${changeOrderId}/approve/`, approvalData);
        return response.data;
    },

    rejectChangeOrder: async (changeOrderId, rejectionData) => {
        const response = await api.post(`change-orders/${changeOrderId}/reject/`, rejectionData);
        return response.data;
    },

    addBasicLineItem: async (budgetId, itemData) => {
        try {
            // Convert budgetId to integer to match backend expectation
            const lineItemData = {
                budget_id: parseInt(budgetId), // Changed from budget to budget_id
                description: itemData.description,
                quantity: itemData.quantity,
                unit_price: itemData.unit_price,
                unit: itemData.unit || 'EA',
                material: itemData.material || null,
                total_amount: calculateTotalAmount(
                    itemData.quantity,
                    itemData.unit_price,
                    itemData.unit || 'EA',
                    itemData.material
                )
            };
            const response = await api.post('budget-line-items/', lineItemData);
            return response.data;
        } catch (error) {
            console.error('Error in addBasicLineItem:', error);
            throw error;
        }
    },

    updateBasicLineItem: async (itemId, itemData) => {
        const lineItemData = {
            description: itemData.description,
            quantity: itemData.quantity,
            unit_price: itemData.unit_price,
            total_amount: itemData.quantity * itemData.unit_price
        };

        const response = await api.put(`budget-line-items/${itemId}/`, lineItemData);
        return response.data;
    },

    updateBudget: async (budgetId, data) => {
        try {
            const updateData = {
                name: data.name,
                description: data.description || '',
                total_budget: parseFloat(data.total_budget),
                job: data.job,
                status: data.status
            };
            const response = await api.put(`budgets/${budgetId}/`, updateData);
            return response.data;
        } catch (error) {
            console.error('Error updating budget:', error.response?.data || error);
            throw error;
        }
    },

    deleteBasicLineItem: async (budgetId, itemId) => {
        await api.delete(`budget-line-items/${itemId}/`);
    },

    // NEW COST MANAGEMENT METHODS

    // Cost Management Settings
    getCostSettings: async () => {
        const response = await api.get('cost-settings/');
        return response.data;
    },

    updateCostSettings: async (id, settingsData) => {
        const response = await api.put(`cost-settings/${id}/`, settingsData);
        return response.data;
    },

    createCostSettings: async (settingsData) => {
        const response = await api.post('cost-settings/', settingsData);
        return response.data;
    },

    // Cost forecasting
    createForecast: async (forecastData) => {
        const response = await api.post('cost-forecasts/', forecastData);
        return response.data;
    },

    updateForecast: async (id, forecastData) => {
        const response = await api.put(`cost-forecasts/${id}/`, forecastData);
        return response.data;
    },

    // Committed costs
    createCommittedCost: async (committedCostData) => {
        const response = await api.post('committed-costs/', committedCostData);
        return response.data;
    },

    getCommittedCosts: async (projectId) => {
        const response = await api.get(`committed-costs/?project_id=${projectId}`);
        return response.data;
    },

    // EVM metrics
    calculateEVMMetrics: async (projectId, calculationDate) => {
        const data = calculationDate
            ? { project_id: projectId, calculation_date: calculationDate }
            : { project_id: projectId };

        const response = await api.post('evm-metrics/calculate/', data);
        return response.data;
    },

    getEVMMetrics: async (projectId) => {
        const response = await api.get(`evm-metrics/?project_id=${projectId}`);
        return response.data;
    },

    // Cost performance data
    getCostPerformance: async (budgetId) => {
        const response = await api.get(`budgets/${budgetId}/cost_performance/`);
        return response.data;
    },

    // Budget snapshot
    createBudgetSnapshot: async (budgetId) => {
        const response = await api.post(`budgets/${budgetId}/create_snapshot/`);
        return response.data;
    },

    // Budget recalculation
    recalculateBudget: async (budgetId) => {
        const response = await api.post(`budgets/${budgetId}/recalculate/`);
        return response.data;
    },

    // Line item progress tracking
    updateLineItemProgress: async (lineItemId, progressData) => {
        const response = await api.post(`budget-line-items/${lineItemId}/update_progress/`, progressData);
        return response.data;
    },

    // Cost snapshots
    getCostSnapshots: async (projectId) => {
        const response = await api.get(`cost-snapshots/?project_id=${projectId}`);
        return response.data;
    },

    getLatestCostSnapshot: async (projectId) => {
        const response = await api.get(`cost-snapshots/latest_by_project/?project_ids[]=${projectId}`);
        return response.data;
    }
};

export default budgetService;

