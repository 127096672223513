import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    ClockIcon,
    CheckCircleIcon,
    ExclamationCircleIcon,
    FunnelIcon,
    CalendarIcon,
    ChatBubbleLeftIcon
} from '@heroicons/react/24/outline';
import api from '../../services/api';
import { showToast } from '../../utils/toast';
import BackButton from '../common/BackButton';

const ContractorTasks = () => {
    const [tasks, setTasks] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [filters, setFilters] = useState({
        status: 'all',
        project: 'all',
        priority: 'all'
    });
    const [projects, setProjects] = useState([]);
    const [selectedTask, setSelectedTask] = useState(null);
    const [showStatusModal, setShowStatusModal] = useState(false);

    useEffect(() => {
        fetchTasks();
        fetchProjects();
    }, []);

    const fetchTasks = async () => {
        try {
            setIsLoading(true);
            const response = await api.get('contractor/tasks/');
            setTasks(response.data.results || []);
        } catch (error) {
            console.error('Error fetching tasks:', error);
            showToast.error('Failed to load tasks');
        } finally {
            setIsLoading(false);
        }
    };

    const fetchProjects = async () => {
        try {
            const response = await api.get('contractor/projects/');
            setProjects(Array.isArray(response.data.results) ? response.data.results : []);
        } catch (error) {
            console.error('Error fetching projects:', error);
        }
    };

    const updateTaskStatus = async (taskId, newStatus, comment = '') => {
        try {
            await api.patch(`contractor/tasks/${taskId}/update-status/`, {
                status: newStatus,
                comment: comment
            });

            showToast.success('Task status updated successfully');
            fetchTasks(); // Refresh tasks
            setShowStatusModal(false);
        } catch (error) {
            console.error('Error updating task status:', error);
            showToast.error('Failed to update task status');
        }
    };

    const filteredTasks = tasks.filter(task => {
        return (filters.status === 'all' || task.status === filters.status) &&
            (filters.project === 'all' || task.project_id === filters.project) &&
            (filters.priority === 'all' || task.priority === filters.priority);
    });

    const getStatusColor = (status) => {
        switch (status) {
            case 'not_started': return 'bg-gray-100 text-gray-800';
            case 'in_progress': return 'bg-blue-100 text-blue-800';
            case 'done': return 'bg-green-100 text-green-800';
            default: return 'bg-gray-100 text-gray-800';
        }
    };

    return (
        <div className="p-6">
            <BackButton />
            <div className="mb-6">
                <h1 className="text-2xl font-bold text-gray-900">My Tasks</h1>

                {/* Filters */}
                <div className="mt-4 grid grid-cols-1 md:grid-cols-3 gap-4">
                    <select
                        value={filters.status}
                        onChange={(e) => setFilters({ ...filters, status: e.target.value })}
                        className="rounded-lg border-gray-300 focus:border-blue-500 focus:ring-blue-500"
                    >
                        <option value="all">All Status</option>
                        <option value="not_started">Not Started</option>
                        <option value="in_progress">In Progress</option>
                        <option value="done">Done</option>
                    </select>

                    <select
                        value={filters.project}
                        onChange={(e) => setFilters({ ...filters, project: e.target.value })}
                        className="rounded-lg border-gray-300 focus:border-blue-500 focus:ring-blue-500"
                    >
                        <option value="all">All Projects</option>
                        {projects.map(project => (
                            <option key={project.id} value={project.id}>{project.name}</option>
                        ))}
                    </select>

                    <select
                        value={filters.priority}
                        onChange={(e) => setFilters({ ...filters, priority: e.target.value })}
                        className="rounded-lg border-gray-300 focus:border-blue-500 focus:ring-blue-500"
                    >
                        <option value="all">All Priorities</option>
                        <option value="high">High</option>
                        <option value="medium">Medium</option>
                        <option value="low">Low</option>
                    </select>
                </div>
            </div>

            {/* Tasks List */}
            <div className="space-y-4">
                {isLoading ? (
                    <div className="flex justify-center items-center h-48">
                        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
                    </div>
                ) : filteredTasks.length === 0 ? (
                    <div className="text-center py-12 bg-gray-50 rounded-lg">
                        <p className="text-gray-500">No tasks found</p>
                    </div>
                ) : (
                    filteredTasks.map((task) => (
                        <div
                            key={task.id}
                            className="bg-white rounded-lg shadow hover:shadow-md transition-shadow duration-200 p-4"
                        >
                            <div className="flex justify-between items-start">
                                <div className="flex-1">
                                    <div className="flex items-center space-x-2">
                                        <span className={`px-2 py-1 text-xs font-medium rounded-full ${getStatusColor(task.status)}`}>
                                            {task.status.replace('_', ' ').toUpperCase()}
                                        </span>
                                        {task.priority === 'high' && (
                                            <ExclamationCircleIcon className="h-5 w-5 text-red-500" />
                                        )}
                                    </div>
                                    <h3 className="font-medium text-gray-900 mt-2">{task.title}</h3>
                                    <p className="text-sm text-gray-500 mt-1">{task.description}</p>

                                    <div className="mt-3 flex items-center space-x-4 text-sm text-gray-500">
                                        <span className="flex items-center">
                                            <CalendarIcon className="h-4 w-4 mr-1" />
                                            Due: {new Date(task.due_date).toLocaleDateString()}
                                        </span>
                                        <span className="flex items-center">
                                            <ChatBubbleLeftIcon className="h-4 w-4 mr-1" />
                                            {task.comments_count || 0} comments
                                        </span>
                                    </div>
                                </div>

                                <button
                                    onClick={() => {
                                        setSelectedTask(task);
                                        setShowStatusModal(true);
                                    }}
                                    className="ml-4 px-3 py-1 text-sm text-blue-600 hover:text-blue-800 hover:bg-blue-50 rounded-md transition-colors duration-200"
                                >
                                    Update Status
                                </button>
                            </div>
                        </div>
                    ))
                )}
            </div>

            {/* Status Update Modal */}
            {showStatusModal && selectedTask && (
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4">
                    <div className="bg-white rounded-lg shadow-xl max-w-md w-full p-6">
                        <h3 className="text-lg font-medium text-gray-900 mb-4">Update Task Status</h3>

                        <div className="space-y-4">
                            <div>
                                <label className="block text-sm font-medium text-gray-700">New Status</label>
                                <select
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                    defaultValue={selectedTask.status}
                                    onChange={(e) => updateTaskStatus(selectedTask.id, e.target.value)}
                                >
                                    <option value="not_started">Not Started</option>
                                    <option value="in_progress">In Progress</option>
                                    <option value="done">Done</option>
                                </select>
                            </div>

                            <div>
                                <label className="block text-sm font-medium text-gray-700">Comment (optional)</label>
                                <textarea
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                    rows={3}
                                    placeholder="Add a comment about the status change..."
                                />
                            </div>

                            <div className="flex justify-end space-x-3">
                                <button
                                    onClick={() => setShowStatusModal(false)}
                                    className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={() => updateTaskStatus(selectedTask.id, selectedTask.status)}
                                    className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700"
                                >
                                    Update Status
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ContractorTasks;