import React from 'react';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';

// Create styles for PDF
const styles = StyleSheet.create({
    page: {
        padding: 30,
        fontFamily: 'Helvetica'
    },
    section: {
        marginBottom: 20
    },
    title: {
        fontSize: 24,
        marginBottom: 20,
        fontWeight: 'bold'
    },
    subtitle: {
        fontSize: 18,
        marginBottom: 10,
        marginTop: 15,
        fontWeight: 'bold'
    },
    text: {
        fontSize: 12,
        marginBottom: 5
    },
    issueContainer: {
        marginBottom: 15,
        padding: 10,
        backgroundColor: '#f8f9fa'
    },
    summaryGrid: {
        flexDirection: 'row',
        marginBottom: 20
    },
    summaryItem: {
        flex: 1,
        padding: 10
    },
    violationItem: {
        marginBottom: 10,
        padding: 8,
        backgroundColor: '#fff1f2'
    }
});

const SafetyReport = ({ analysisResults, project }) => {
    if (!analysisResults || !analysisResults.compliance_summary) {
        return (
            <Document>
                <Page size="A4" style={styles.page}>
                    <Text style={styles.title}>Safety Analysis Report</Text>
                    <Text style={styles.text}>No analysis results available</Text>
                </Page>
            </Document>
        );
    }

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                {/* Header */}
                <View style={styles.section}>
                    <Text style={styles.title}>Safety Analysis Report</Text>
                    <Text style={styles.text}>Project: {project.name}</Text>
                    <Text style={styles.text}>Date: {new Date().toLocaleDateString()}</Text>
                </View>

                {/* Compliance Summary */}
                <View style={styles.section}>
                    <Text style={styles.subtitle}>Compliance Summary</Text>
                    <View style={styles.summaryGrid}>
                        <View style={styles.summaryItem}>
                            <Text style={styles.text}>Total Requirements</Text>
                            <Text style={styles.text}>{analysisResults.compliance_summary.total_requirements}</Text>
                        </View>
                        <View style={styles.summaryItem}>
                            <Text style={styles.text}>Compliant Items</Text>
                            <Text style={styles.text}>{analysisResults.compliance_summary.compliant_items}</Text>
                        </View>
                        <View style={styles.summaryItem}>
                            <Text style={styles.text}>Violations</Text>
                            <Text style={styles.text}>{analysisResults.compliance_summary.violation_count}</Text>
                        </View>
                    </View>
                </View>

                {/* Safety Issues */}
                <View style={styles.section}>
                    <Text style={styles.subtitle}>Safety Issues</Text>
                    {Object.entries(analysisResults.safety_issues).map(([itemName, data]) => (
                        <View key={itemName} style={styles.issueContainer}>
                            <Text style={[styles.text, { fontWeight: 'bold' }]}>{itemName}</Text>
                            <Text style={styles.text}>Detection Rate: {data.detection_rate}%</Text>
                            <Text style={styles.text}>Status: {data.compliant ? 'Compliant' : 'Non-Compliant'}</Text>

                            {data.violations?.length > 0 && (
                                <View style={{ marginTop: 5 }}>
                                    <Text style={styles.text}>Violations:</Text>
                                    {data.violations.map((violation, index) => (
                                        <View key={index} style={styles.violationItem}>
                                            <Text style={styles.text}>Frame: {violation.frame}</Text>
                                            <Text style={styles.text}>Time: {new Date(violation.time * 1000).toISOString().substr(11, 8)}</Text>
                                            <Text style={styles.text}>{violation.note}</Text>
                                        </View>
                                    ))}
                                </View>
                            )}
                        </View>
                    ))}
                </View>
            </Page>
        </Document>
    );
};

export default SafetyReport;