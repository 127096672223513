import React, { useState, useEffect } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';
import { Card } from "../ui/cardui";
import { Badge } from "../ui/badge";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "../ui/tabs";
import { Button } from "../ui/button";
import { PlanCard } from './PlanCard';
import api from '../../services/api';
import { showToast } from '../../utils/toast';
import { useToast } from "../ui/use-toast";
import { fetchSubscriptionPlans } from '../../services/api';
import PaymentForm from './PaymentForm';

export default function SubscriptionPlansContent() {
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [loading, setLoading] = useState(false);
    const [billingCycle, setBillingCycle] = useState('monthly');
    const [availablePlans, setAvailablePlans] = useState([]);
    const [showPaymentForm, setShowPaymentForm] = useState(false);
    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();
    const { toast } = useToast();

    useEffect(() => {
        const loadPlans = async () => {
            setLoading(true);
            try {
                const data = await fetchSubscriptionPlans();
                setAvailablePlans(data.results);
            } catch (error) {
                toast({
                    variant: "destructive",
                    title: "Error",
                    description: "Failed to load plans"
                });
                setAvailablePlans([]);
            } finally {
                setLoading(false);
            }
        };
        loadPlans();
    }, []);

    const handleSubmit = async () => {
        if (!stripe || !selectedPlan) return;

        setLoading(true);
        try {
            const response = await api.post('create-checkout-session/', {
                plan: selectedPlan.name.toLowerCase(),
                billing_cycle: billingCycle
            });

            if (response.data.checkout_url) {
                window.location.href = response.data.checkout_url;
            } else {
                throw new Error('No checkout URL received');
            }
        } catch (error) {
            toast({
                variant: "destructive",
                title: "Error",
                description: error.message
            });
        } finally {
            setLoading(false);
        }
    };

    if (loading && !availablePlans.length) {
        return <div>Loading plans...</div>;
    }

    return (
        <div className="container max-w-7xl mx-auto py-10 space-y-8">
            <div className="text-center space-y-4">
                <h1 className="text-4xl font-bold">Choose Your Plan</h1>
                <p className="text-xl text-muted-foreground">
                    Start building better projects with BUILDIFYPRO
                </p>
            </div>

            {!showPaymentForm ? (
                <>
                    <Tabs defaultValue="monthly" className="w-full" onValueChange={setBillingCycle}>
                        <div className="flex justify-center mb-8">
                            <TabsList>
                                <TabsTrigger value="monthly">Monthly Billing</TabsTrigger>
                                <TabsTrigger value="yearly">
                                    Yearly Billing
                                    <Badge variant="success" className="ml-2">Save 20%</Badge>
                                </TabsTrigger>
                            </TabsList>
                        </div>

                        <TabsContent value="monthly" className="mt-0">
                            <div className="grid md:grid-cols-3 gap-6">
                                {Array.isArray(availablePlans) && availablePlans.map((plan) => (
                                    <PlanCard
                                        key={plan.name}
                                        plan={plan}
                                        isSelected={selectedPlan?.name === plan.name}
                                        onSelect={setSelectedPlan}
                                        billingCycle="monthly"
                                    />
                                ))}
                            </div>
                        </TabsContent>

                        <TabsContent value="yearly" className="mt-0">
                            <div className="grid md:grid-cols-3 gap-6">
                                {Array.isArray(availablePlans) && availablePlans.map((plan) => (
                                    <PlanCard
                                        key={plan.name}
                                        plan={plan}
                                        isSelected={selectedPlan?.name === plan.name}
                                        onSelect={setSelectedPlan}
                                        billingCycle="yearly"
                                    />
                                ))}
                            </div>
                        </TabsContent>
                    </Tabs>

                    {selectedPlan && (
                        <Card className="mt-8">
                            <div className="p-6">
                                <h3 className="text-lg font-semibold mb-4">Selected Plan: {selectedPlan.name}</h3>
                                <p className="mb-4">
                                    Total: ${billingCycle === 'monthly' ? selectedPlan.monthly_price : selectedPlan.annual_price}
                                </p>
                                <div className="flex justify-end gap-4">
                                    <Button variant="outline" onClick={() => setSelectedPlan(null)}>
                                        Cancel
                                    </Button>
                                    <Button
                                        onClick={handleSubmit}
                                        disabled={loading}
                                    >
                                        {loading ? 'Processing...' : 'Continue to Payment'}
                                    </Button>
                                </div>
                            </div>
                        </Card>
                    )}
                </>
            ) : (
                <PaymentForm
                    plan={{
                        ...selectedPlan,
                        monthlyPrice: selectedPlan.monthly_price,
                        yearlyPrice: selectedPlan.annual_price,
                        name: selectedPlan.name
                    }}
                    billingCycle={billingCycle}
                    onCancel={() => setShowPaymentForm(false)}
                    loading={loading}
                    onSubmit={handleSubmit}
                />
            )}
        </div>
    );
}