import React, { useState, useEffect } from 'react';
import { Card } from "../../../components/ui/cardui";
import { Badge } from "../../../components/ui/badge";
import { Button } from "../../../components/ui/button";
import { Input } from "../../../components/ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../../components/ui/select";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "../../../components/ui/dialog";
import { Label } from "../../../components/ui/label";
import { Trash2, Edit, Plus } from "lucide-react";
import ConfirmationDialog from '../../common/ConfirmationDialog';
import { primaryButtonClasses, secondaryButtonClasses } from '../../common/ButtonStyles';
import ActivityForm from './ActivityForm';
import { scheduleApi } from '../../../services/scheduleApi';
import api from '../../../services/api';
import { toast } from 'react-toastify';
import DependencyForm from './DependencyForm';
import { ArrowUpCircle, ArrowRight, Tree, ArrowDown } from "lucide-react";

const DependencyList = ({
    projectId,
    scheduleId
}) => {
    const [dependencies, setDependencies] = useState([]);
    const [activities, setActivities] = useState([]);
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [editingDependency, setEditingDependency] = useState(null);
    const [deleteConfirmation, setDeleteConfirmation] = useState(null);


    const createDependency = async (data) => {
        try {
            const response = await api.post(`projects/${projectId}/dependencies/`, {
                ...data,
                project: projectId
            });
            setDependencies(prev => [...prev, response.data]);
            toast.success('Dependency created successfully');
            return response.data;
        } catch (error) {
            console.error('Error creating dependency:', error);
            throw error;
        }
    };

    const updateDependency = async (dependencyId, data) => {
        try {
            const response = await api.patch(
                `projects/${projectId}/dependencies/${dependencyId}/`,
                data
            );
            setDependencies(prev =>
                prev.map(dep => dep.id === dependencyId ? { ...dep, ...data } : dep)
            );
            toast.success('Dependency updated successfully');
            return response.data;
        } catch (error) {
            console.error('Error updating dependency:', error);
            throw error;
        }
    };

    const deleteDependency = async (dependencyId) => {
        try {
            await api.delete(`projects/${projectId}/dependencies/${dependencyId}/`);
            setDependencies(prev => prev.filter(dep => dep.id !== dependencyId));
            toast.success('Dependency deleted successfully');
        } catch (error) {
            console.error('Error deleting dependency:', error);
            toast.error('Failed to delete dependency');
        }
    };
    const handleCreateSubmit = async (data) => {
        await createDependency(data);
        setIsCreateModalOpen(false);
    };

    const handleUpdateSubmit = async (data) => {
        await updateDependency(editingDependency.id, data);
        setEditingDependency(null);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [depResponse, actResponse] = await Promise.all([
                    api.get(`projects/${projectId}/dependencies/`, {
                        params: { schedule: scheduleId }
                    }),
                    api.get(`projects/${projectId}/activities/`, {
                        params: { schedule: scheduleId }
                    })
                ]);
                setDependencies(depResponse.data.results);
                setActivities(actResponse.data.results);
            } catch (error) {
                console.error('Error fetching dependencies:', error);
                toast.error('Failed to load dependencies');
            }
        };

        if (projectId && scheduleId) {
            fetchData();
        }
    }, [projectId, scheduleId]);

    const onUpdateDependency = async (dependencyId, field, value) => {
        try {
            const response = await api.patch(
                `projects/${projectId}/dependencies/${dependencyId}/`,
                { [field]: value }
            );
            setDependencies(prev =>
                prev.map(dep => dep.id === dependencyId ? { ...dep, [field]: value } : dep)
            );
            toast.success('Dependency updated successfully');
        } catch (error) {
            console.error('Error updating dependency:', error);
            toast.error('Failed to update dependency');
        }
    };

    return (
        <div className="space-y-4">
            <div className="flex justify-between items-center">
                <h3 className="text-lg font-medium">Dependencies</h3>
                <Button
                    onClick={() => setIsCreateModalOpen(true)}
                    className={primaryButtonClasses}
                >
                    <Plus className="h-4 w-4 mr-2" />
                    Add Dependency
                </Button>
            </div>

            <div className="grid gap-4">
                {dependencies.map(dependency => (
                    <Card key={dependency.id} className="p-4">
                        <div className="flex items-center gap-4">
                            <Select
                                value={dependency.predecessor}
                                onValueChange={(value) => onUpdateDependency(dependency.id, 'predecessor', value)}
                            >
                                <SelectTrigger className="w-[200px]">
                                    <SelectValue placeholder="Predecessor" />
                                </SelectTrigger>
                                <SelectContent>
                                    {activities.map(activity => (
                                        <SelectItem key={activity.id} value={activity.id}>
                                            {activity.name}
                                        </SelectItem>
                                    ))}
                                </SelectContent>
                            </Select>

                            <ArrowRight className="h-4 w-4" />

                            <Select
                                value={dependency.successor}
                                onValueChange={(value) => onUpdateDependency(dependency.id, 'successor', value)}
                            >
                                <SelectTrigger className="w-[200px]">
                                    <SelectValue placeholder="Successor" />
                                </SelectTrigger>
                                <SelectContent>
                                    {activities.map(activity => (
                                        <SelectItem key={activity.id} value={activity.id}>
                                            {activity.name}
                                        </SelectItem>
                                    ))}
                                </SelectContent>
                            </Select>

                            <Select
                                value={dependency.dependency_type}
                                onValueChange={(value) => onUpdateDependency(dependency.id, 'dependency_type', value)}
                            >
                                <SelectTrigger className="w-[100px]">
                                    <SelectValue placeholder="Type" />
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectItem value="FS">Finish-Start</SelectItem>
                                    <SelectItem value="SS">Start-Start</SelectItem>
                                    <SelectItem value="FF">Finish-Finish</SelectItem>
                                    <SelectItem value="SF">Start-Finish</SelectItem>
                                </SelectContent>
                            </Select>

                            <Input
                                type="number"
                                value={dependency.lag}
                                onChange={(e) => onUpdateDependency(dependency.id, 'lag', parseInt(e.target.value))}
                                className="w-[100px]"
                                placeholder="Lag (days)"
                            />
                        </div>
                    </Card>
                ))}
            </div>

            <DependencyForm
                isOpen={isCreateModalOpen}
                onClose={() => setIsCreateModalOpen(false)}
                onSubmit={createDependency}
                projectId={projectId}
                activities={activities}
                mode="create"
            />

            <ConfirmationDialog
                isOpen={!!deleteConfirmation}
                onClose={() => setDeleteConfirmation(null)}
                onConfirm={() => {
                    deleteDependency(deleteConfirmation.id);
                    setDeleteConfirmation(null);
                }}
                title="Delete Dependency"
                message="Are you sure you want to delete this dependency? This action cannot be undone."
                confirmText="Delete"
                cancelText="Cancel"
                type="danger"
            />
        </div>
    );
};

export default DependencyList;