import React, { useState, useEffect } from 'react';
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem } from "../../../components/ui/command";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "../../../components/ui/dialog";
import { Badge } from "../../../components/ui/badge";
import { Button } from "../../../components/ui/button";
import { Loader2, Star, Shield, X } from "lucide-react"; // Added X icon import
import BidService from '../../../services/bidService';
import AddBidderDialog from './AddBidderDialog';
import { Checkbox } from "../../../components/ui/checkbox";
import { Label } from "../../../components/ui/label";

const BidderProfileSelector = ({ trade, onSelect, selectedProfiles = [] }) => {
    const [bidders, setBidders] = useState([]);
    const [selectedBidder, setSelectedBidder] = useState(null);
    const [detailsOpen, setDetailsOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!trade?.id) return;

        const fetchBidders = async () => {
            setLoading(true);
            setError(null);
            try {
                const response = await BidService.getProfilesByTrade(trade.id);
                const results = Array.isArray(response?.data?.results) ? response.data.results : [];
                setBidders(results);
            } catch (err) {
                console.error("Error fetching bidders:", err);
                setError("Failed to load bidders. Please try again.");
            } finally {
                setLoading(false);
            }
        };

        fetchBidders();
    }, [trade]);

    const handleBidderSelection = (bidder, checked) => {
        onSelect({
            id: bidder.id,
            company_name: bidder.company_name,
            checked: checked
        });
    };

    const handleNewBidderAdded = async (bidder) => {
        setBidders(prev => [...prev, bidder]);
        // Automatically select the newly added bidder
        handleBidderSelection(bidder, true);
    };

    if (loading) {
        return (
            <div className="flex justify-center items-center">
                <Loader2 className="animate-spin" />
            </div>
        );
    }

    if (error) {
        return (
            <div className="text-red-500 p-4 rounded-lg border border-red-200">
                <p>{error}</p>
                <Button
                    onClick={() => window.location.reload()}
                    className="mt-2"
                >
                    Retry
                </Button>
            </div>
        );
    }

    return (
        <div className="mt-4 space-y-4">
            <div className="font-medium">Bidders for {trade?.name}</div>

            <div className="space-y-2">
                {bidders.map(bidder => (
                    <div key={bidder.id} className="flex items-center space-x-3 p-2 border rounded-lg">
                        <Checkbox
                            id={`bidder-${bidder.id}`}
                            checked={selectedProfiles.includes(bidder.id)}
                            onCheckedChange={(checked) => handleBidderSelection(bidder, checked)}
                        />
                        <div className="flex justify-between w-full">
                            <Label htmlFor={`bidder-${bidder.id}`} className="flex-1">
                                {bidder.company_name}
                            </Label>
                            <div className="flex items-center space-x-2">
                                {bidder.verified && <Shield className="h-4 w-4" />}
                                <Button
                                    variant="ghost"
                                    size="sm"
                                    onClick={() => {
                                        setSelectedBidder(bidder);
                                        setDetailsOpen(true);
                                    }}
                                >
                                    View Details
                                </Button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <AddBidderDialog
                trade={trade}
                onBidderAdded={handleNewBidderAdded}
            />

            {/* Selected Contractors Display */}
            {selectedProfiles.length > 0 && (
                <div className="mt-4">
                    <Label>Selected Contractors:</Label>
                    <div className="flex flex-wrap gap-2 mt-1">
                        {selectedProfiles.map(profileId => {
                            const bidder = bidders.find(b => b.id === profileId);
                            if (!bidder) return null;

                            return (
                                <Badge
                                    key={profileId}
                                    variant="secondary"
                                    className="flex items-center gap-2"
                                >
                                    {bidder.company_name}
                                    <Button
                                        type="button"
                                        variant="ghost"
                                        size="sm"
                                        className="h-4 w-4 p-0 hover:bg-transparent"
                                        onClick={() => handleBidderSelection(bidder, false)}
                                    >
                                        <X className="h-3 w-3" />
                                    </Button>
                                </Badge>
                            );
                        })}
                    </div>
                </div>
            )}

            {/* Details Dialog */}
            <Dialog open={detailsOpen} onOpenChange={setDetailsOpen}>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>{selectedBidder?.company_name}</DialogTitle>
                    </DialogHeader>
                    {selectedBidder && (
                        <div className="space-y-4">
                            <div>
                                <Label>Business Type</Label>
                                <p>{selectedBidder.business_type}</p>
                            </div>
                            <div>
                                <Label>Experience</Label>
                                <p>{selectedBidder.years_experience} years</p>
                            </div>
                            <div>
                                <Label>Completed Projects</Label>
                                <p>{selectedBidder.completed_projects_count}</p>
                            </div>
                            <div>
                                <Label>Safety Rating</Label>
                                <p>{selectedBidder.safety_rating}</p>
                            </div>
                        </div>
                    )}
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default BidderProfileSelector;