
import React, { useState } from 'react';
import { Button } from "../../../components/ui/button";
import { Input } from "../../../components/ui/input";
import { Badge } from "../../../components/ui/badge";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "../../../components/ui/dialog";
import { DialogDescription } from '../../../components/ui/dialog';
import { X } from 'lucide-react';

const TradeSelector = ({
    trades,
    formData,
    onTradeSelect
}) => {
    const [open, setOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    const filteredTrades = trades.filter(trade =>
        trade.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleTradeSelect = (trade) => {
        const isSelected = formData.required_trades.includes(trade.id);
        onTradeSelect(trade);

        if (!isSelected) {
            setOpen(false);
        }
    };

    if (!trades?.length) {
        return <Button disabled>No trades available</Button>;
    }

    return (
        <div className="space-y-4">
            <Dialog open={open} onOpenChange={setOpen}>
                <DialogTrigger asChild>
                    <Button variant="outline" className="w-full">
                        Select Required Trades
                    </Button>
                </DialogTrigger>
                <DialogContent className="w-[90vw] max-w-[500px] h-[80vh] flex flex-col p-0">
                    <DialogHeader className="px-4 py-2 border-b">
                        <DialogTitle>Select Trades</DialogTitle>
                        <DialogDescription>
                            Choose required trades for your bid request
                        </DialogDescription>
                    </DialogHeader>

                    <div className="flex-1 overflow-y-auto px-4">
                        <div className="grid gap-2 py-4">
                            <Input
                                placeholder="Search trades..."
                                className="mb-2"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                            <div className="grid gap-2">
                                {filteredTrades.map((trade) => (
                                    <div
                                        key={trade.id}
                                        className="flex items-center justify-between p-3 rounded-lg border hover:bg-accent cursor-pointer"
                                        onClick={() => handleTradeSelect(trade)}
                                    >
                                        <span className="font-medium">{trade.name}</span>
                                        {formData.required_trades?.includes(trade.id) && (
                                            <Badge variant="secondary">Selected</Badge>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>

            {formData.required_trades?.length > 0 && (
                <div className="rounded-lg border p-4 space-y-3">
                    <div className="flex items-center justify-between">
                        <h3 className="font-medium">Selected Trades</h3>
                        <Badge variant="secondary">
                            {formData.required_trades.length} selected
                        </Badge>
                    </div>
                    <div className="flex flex-wrap gap-2">
                        {formData.required_trades.map((tradeId) => {
                            const trade = trades.find(t => t.id === tradeId);
                            return trade ? (
                                <Badge
                                    key={trade.id}
                                    className="flex items-center gap-2 py-1.5"
                                >
                                    {trade.name}
                                    <Button
                                        type="button"
                                        variant="ghost"
                                        size="sm"
                                        className="h-4 w-4 p-0 hover:bg-transparent"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleTradeSelect(trade);
                                        }}
                                    >
                                        <X className="h-3 w-3" />
                                    </Button>
                                </Badge>
                            ) : null;
                        })}
                    </div>
                </div>
            )}
        </div>
    );
};

export default TradeSelector;