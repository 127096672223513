import React, { useState } from 'react';
import api from '../../services/api';
import { toast } from 'react-toastify';

const RFIWorkflowForm = ({ projectId, assignedUsers }) => {
  const [workflowSteps, setWorkflowSteps] = useState([{ level: 1, approverEmail: '' }]);

  const addStep = () => {
    setWorkflowSteps([...workflowSteps, { level: workflowSteps.length + 1, approverEmail: '' }]);
  };

  const updateStep = (index, field, value) => {
    const updatedSteps = [...workflowSteps];
    updatedSteps[index][field] = value;
    setWorkflowSteps(updatedSteps);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await api.post(`projects/${projectId}/rfi-workflow/`, { steps: workflowSteps });
      toast.success('RFI workflow created successfully!');
    } catch (error) {
      console.error('Error creating RFI workflow:', error);
      toast.error('Failed to create RFI workflow.');
    }
  };
  

  return (
    <form onSubmit={handleSubmit} className="bg-white shadow-md rounded-lg p-6">
      <h3 className="text-xl font-semibold mb-4">Create RFI Approval Workflow For this Project</h3>
      {workflowSteps.map((step, index) => (
        <div key={index} className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Level {step.level} Approver's Email</label>
          <input
            type="email"
            value={step.approverEmail}
            onChange={(e) => updateStep(index, 'approverEmail', e.target.value)}
            className="mt-1 block w-full rounded-full border border-gray-300 shadow-sm focus:border-[#344bfc] focus:ring focus:ring-[#344bfc] focus:ring-opacity-50 px-4 py-2"
            placeholder="Approver's Email"
            required
          />
        </div>
      ))}
      <button
        type="button"
        onClick={addStep}
        className="mb-4 bg-gray-200 text-gray-700 px-4 py-2 rounded-full hover:bg-gray-300 transition-all"
      >
        Add Step
      </button>
      <button
        type="submit"
        className="w-full bg-[#344bfc] text-white px-4 py-2 rounded-full hover:bg-blue-700 transition-all"
      >
        Create Workflow
      </button>
    </form>
  );
};

export default RFIWorkflowForm;
