import React, { useState, useEffect, useRef } from 'react';
import { Button } from "../../../components/ui/button";
import { Textarea } from "../../../components/ui/textarea";
import { ScrollArea } from "../../../components/ui/scroll-area";
import { useToast } from "../../../components/ui/use-toast";
import { MessageSquare, ChevronDown, Bell } from 'lucide-react';
import { Badge } from "../../../components/ui/badge";
import api from '../../../services/api';

const BidMessagesPanel = ({ bidSubmissionId }) => {
    const [messages, setMessages] = useState([]);
    const [displayedMessages, setDisplayedMessages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [showMore, setShowMore] = useState(false);
    const [unreadCount, setUnreadCount] = useState(0);
    const { toast } = useToast();
    const messagesEndRef = useRef(null);
    const [loadingMessages, setLoadingMessages] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);

    const [canRespond, setCanRespond] = useState(false);

    useEffect(() => {
        fetchMessages();
        fetchUnreadCount();
        markMessagesAsRead();
    }, [bidSubmissionId]);

    useEffect(() => {
        // Check permissions when component mounts
        const checkPermissions = async () => {
            try {
                const response = await api.get(`bid-submissions/${bidSubmissionId}/`);
                const submission = response.data;
                setCanRespond(
                    submission.bid_request.created_by === currentUser?.id ||
                    submission.contractor_profile?.user?.id === currentUser?.id
                );
            } catch (err) {
                console.error('Error checking permissions:', err);
            }
        };

        if (bidSubmissionId) {
            checkPermissions();
        }
    }, [bidSubmissionId, currentUser]);

    useEffect(() => {
        const fetchCurrentUser = async () => {
            try {
                const response = await api.get('users/profile/');
                setCurrentUser(response.data);
            } catch (err) {
                console.error('Error fetching user:', err);
            }
        };

        fetchCurrentUser();
    }, []);


    const fetchUnreadCount = async () => {
        try {
            const response = await api.get(`bid-messages/unread_count/?bid_submission=${bidSubmissionId}`);
            setUnreadCount(response.data.unread_count);
        } catch (err) {
            console.error('Error fetching unread count:', err);
        }
    };

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };



    useEffect(() => {
        if (messages.length > 0) {
            setDisplayedMessages(messages.slice(0, 3));
        }
    }, [messages]);

    useEffect(() => {
        scrollToBottom();
    }, [displayedMessages]);

    const fetchMessages = async () => {
        try {
            setLoading(true);
            const response = await api.get(`bid-messages/?bid_submission=${bidSubmissionId}`);
            setMessages(Array.isArray(response.data) ? response.data : (response.data?.results || []));
        } catch (err) {
            console.error('Error fetching messages:', err);
            toast({
                title: "Error",
                description: "Failed to load messages",
                variant: "destructive"
            });
        } finally {
            setLoading(false);
        }
    };



    const [debugInfo, setDebugInfo] = useState({
        messages: null,
        unreadInfo: null
    });

    // Add debug fetch function
    const fetchDebugInfo = async () => {
        try {
            // Fetch debug messages
            const messagesResponse = await api.get(
                `bid-messages/debug_messages/?bid_submission=${bidSubmissionId}`
            );

            // Fetch debug unread info
            const unreadResponse = await api.get('bid-messages/debug_unread/');

            setDebugInfo({
                messages: messagesResponse.data,
                unreadInfo: unreadResponse.data
            });


        } catch (err) {
            console.error('Error fetching debug info:', err);
        }
    };

    // Add to your existing useEffect or create a new one
    useEffect(() => {
        if (bidSubmissionId) {
            fetchDebugInfo();
        }
    }, [bidSubmissionId]);



    const markMessagesAsRead = async () => {
        if (!bidSubmissionId) return;

        try {
            const response = await api.post('bid-messages/mark_read/', {
                bid_submission: bidSubmissionId
            });
            // Update local message state to reflect read status
            setMessages(prevMessages =>
                prevMessages.map(msg => ({
                    ...msg,
                    is_read: true
                }))
            );

            // Update displayed messages as well
            setDisplayedMessages(prevDisplayed =>
                prevDisplayed.map(msg => ({
                    ...msg,
                    is_read: true
                }))
            );

            // Fetch updated unread count
            const unreadCountResponse = await api.get('bid-messages/unread_count/');
            setUnreadCount(unreadCountResponse.data.unread_count);
        } catch (err) {
            console.error('Error marking messages as read:', err);
        }
    };

    const handleSendMessage = async () => {
        try {
            const response = await api.post('bid-messages/', {
                bid_submission: bidSubmissionId,
                message: message.trim()
            });

            // Add new message to the list
            setMessages(prev => [response.data, ...prev]);
            setDisplayedMessages(prev => {
                if (showMore) {
                    return [response.data, ...prev];
                }
                return [response.data, ...prev.slice(0, 2)];
            });

            setMessage('');
            toast({
                title: "Success",
                description: "Message sent successfully"
            });
        } catch (err) {
            toast({
                title: "Error",
                description: "Failed to send message",
                variant: "destructive"
            });
        }
    };

    const handleViewMore = () => {
        setShowMore(true);
        setDisplayedMessages(messages);
    };

    return (
        <div className="flex flex-col h-[600px] bg-white rounded-lg shadow-sm">
            <div className="p-4 border-b flex justify-between items-center">
                <h3 className="text-lg font-semibold">Messages</h3>
                {unreadCount > 0 && (
                    <Badge variant="danger" className="flex items-center">
                        <Bell className="h-4 w-4 mr-1" />
                        {unreadCount} unread
                    </Badge>
                )}
            </div>
            {canRespond && (
                <div className="p-4 border-b">
                    <div className="space-y-4">
                        <Textarea
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            placeholder="Type your message here..."
                            className="min-h-[100px] resize-none"
                        />
                        <Button
                            onClick={handleSendMessage}
                            disabled={!message.trim()}
                            className="w-full"
                        >
                            <MessageSquare className="h-4 w-4 mr-2" />
                            Send Message
                        </Button>
                    </div>
                </div>
            )}




            <ScrollArea className="flex-1 p-4">
                {loading ? (
                    <div className="flex justify-center items-center h-full">
                        <p className="text-gray-500">Loading messages...</p>
                    </div>
                ) : (
                    <div className="space-y-4">
                        {displayedMessages.map((msg) => (
                            <div
                                key={msg.id}
                                className={`p-4 rounded-lg ${msg.sender === currentUser?.id
                                    ? 'bg-blue-50 ml-8'
                                    : 'bg-gray-50 mr-8'
                                    } ${!msg.read_by?.includes(currentUser?.id)
                                        ? 'border-l-4 border-blue-500'
                                        : ''
                                    }`}
                            >
                                <div className="flex justify-between items-start">
                                    <span className="font-medium text-sm">
                                        {msg.sender_name}
                                    </span>
                                    <span className="text-xs text-gray-500">
                                        {msg.formatted_date}
                                    </span>
                                </div>
                                <p className="mt-2 text-sm whitespace-pre-wrap">
                                    {msg.message}
                                </p>
                                {msg.requires_action && (
                                    <Badge
                                        variant="warning"
                                        className="mt-2"
                                    >
                                        Action Required
                                    </Badge>
                                )}
                            </div>
                        ))}
                        <div ref={messagesEndRef} />
                    </div>
                )}

                {!showMore && messages.length > 3 && (
                    <Button
                        variant="ghost"
                        className="w-full mt-4"
                        onClick={handleViewMore}
                    >
                        <ChevronDown className="h-4 w-4 mr-2" />
                        View More Messages
                    </Button>
                )}
            </ScrollArea>
        </div>
    );
};

export default BidMessagesPanel;