import React, { useState } from 'react';
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
} from "../components/ui/alert-dialog";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../components/ui/select";
import { Textarea } from "../components/ui/textarea";
import { Alert, AlertDescription } from "../components/ui/alert";
import { AlertCircle } from 'lucide-react';

const CANCELLATION_REASONS = [
    { value: 'PRICE', label: 'Price' },
    { value: 'FEATURES', label: 'Features' },
    { value: 'USABILITY', label: 'Ease of use' },
    { value: 'SUPPORT', label: 'Support' },
    { value: 'STABILITY', label: 'Stability and performance' },
    { value: 'CHANGING_PROVIDER', label: 'Changing provider' },
    { value: 'DOCUMENTATION', label: 'Documentation' },
    { value: 'DELIVERABILITY', label: 'Deliverability' },
    { value: 'OTHER', label: 'Other' }
];

const CancellationDialog = ({
    isOpen,
    onClose,
    onConfirm,
    title,
    description,
    isTrialCancellation = false,
    isAccountDeletion = false,
    confirmButtonText = "Confirm Cancellation",
    confirmButtonClass = "bg-red-600 hover:bg-red-700"
}) => {
    const [reasonType, setReasonType] = useState('');
    const [reasonDescription, setReasonDescription] = useState('');
    const [error, setError] = useState('');

    const handleConfirm = () => {
        if (!reasonType) {
            setError('Please select a reason for cancellation');
            return;
        }

        onConfirm({
            reason_type: reasonType,
            description: reasonDescription,
            is_trial_cancellation: isTrialCancellation,
            is_account_deletion: isAccountDeletion
        });
    };

    return (
        <AlertDialog open={isOpen} onOpenChange={onClose}>
            <AlertDialogContent className="max-w-xl">
                <AlertDialogHeader>
                    <AlertDialogTitle>{title}</AlertDialogTitle>
                    <AlertDialogDescription className="text-base">
                        {description}
                    </AlertDialogDescription>
                </AlertDialogHeader>

                <div className="py-4 space-y-4">
                    {error && (
                        <Alert variant="destructive">
                            <AlertCircle className="h-4 w-4" />
                            <AlertDescription>{error}</AlertDescription>
                        </Alert>
                    )}

                    <div className="space-y-2">
                        <label className="text-sm font-medium leading-none">
                            Reason for leaving
                        </label>
                        <Select
                            value={reasonType}
                            onValueChange={(value) => {
                                setReasonType(value);
                                setError('');
                            }}
                        >
                            <SelectTrigger className="w-full">
                                <SelectValue placeholder="Select a reason" />
                            </SelectTrigger>
                            <SelectContent>
                                {CANCELLATION_REASONS.map((reason) => (
                                    <SelectItem key={reason.value} value={reason.value}>
                                        {reason.label}
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    </div>

                    <div className="space-y-2">
                        <label className="text-sm font-medium leading-none">
                            Additional feedback (optional)
                        </label>
                        <Textarea
                            value={reasonDescription}
                            onChange={(e) => setReasonDescription(e.target.value)}
                            placeholder="Please tell us more about your decision..."
                            className="min-h-[100px]"
                        />
                    </div>
                </div>

                <AlertDialogFooter>
                    <AlertDialogCancel>Cancel</AlertDialogCancel>
                    <AlertDialogAction
                        onClick={handleConfirm}
                        className={confirmButtonClass}
                    >
                        {confirmButtonText}
                    </AlertDialogAction>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    );
};

export default CancellationDialog;