import React, { useEffect, useState } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '../../components/ui/cardui';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

// Register ChartJS components
ChartJS.register(ArcElement, Tooltip, Legend);

const RiskSeverityDistribution = ({ risks }) => {
    const [chartData, setChartData] = useState({
        labels: [],
        datasets: []
    });

    useEffect(() => {
        if (!risks || risks.length === 0) {
            setChartData({
                labels: ['No Data'],
                datasets: [{
                    data: [1],
                    backgroundColor: ['#e0e0e0'],
                    borderWidth: 1
                }]
            });
            return;
        }

        // Count risks by severity
        const severityCounts = {
            'LOW': 0,
            'MEDIUM': 0,
            'HIGH': 0,
            'CRITICAL': 0
        };

        risks.forEach(risk => {
            if (severityCounts.hasOwnProperty(risk.severity)) {
                severityCounts[risk.severity]++;
            }
        });

        // Set chart data
        setChartData({
            labels: ['Low', 'Medium', 'High', 'Critical'],
            datasets: [{
                data: [
                    severityCounts.LOW,
                    severityCounts.MEDIUM,
                    severityCounts.HIGH,
                    severityCounts.CRITICAL
                ],
                backgroundColor: [
                    '#28a745', // Green for Low
                    '#ffc107', // Yellow for Medium
                    '#fd7e14', // Orange for High
                    '#dc3545'  // Red for Critical
                ],
                borderColor: [
                    '#28a745',
                    '#ffc107',
                    '#fd7e14',
                    '#dc3545'
                ],
                borderWidth: 1
            }]
        });
    }, [risks]);

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'right',
                labels: {
                    boxWidth: 15,
                    padding: 15
                }
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        const label = context.label || '';
                        const value = context.raw || 0;
                        const total = context.dataset.data.reduce((a, b) => a + b, 0);
                        const percentage = Math.round((value / total) * 100);
                        return `${label}: ${value} (${percentage}%)`;
                    }
                }
            }
        }
    };

    return (
        <Card className="h-full">
            <CardHeader>
                <CardTitle>Risk Severity Distribution</CardTitle>
            </CardHeader>
            <CardContent>
                <div className="h-72">
                    <Pie data={chartData} options={options} />
                </div>
            </CardContent>
        </Card>
    );
};

export default RiskSeverityDistribution;