import React, { useState, useEffect, useContext } from 'react';
import { Button } from "../ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "../ui/cardui";
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Input } from '../ui/input';
import { Alert, AlertDescription } from '../ui/alert';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue
} from '../ui/select';
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "../ui/dialog";
import { LockIcon, AlertTriangle } from 'lucide-react';
import budgetService from '../../services/budgetService';
import { useToast } from '../ui/use-toast';
import api from '../../services/api';
import { Breadcrumb } from '../common/Breadcrumb';
import { primaryButtonClasses, secondaryButtonClasses } from '../common/ButtonStyles';
import { useFeatureAccess, FEATURES } from '../../contexts/FeatureAccessContext.js';

export default function CreateBudget({ projectId }) {
    const navigate = useNavigate();
    const { toast } = useToast();
    const [projects, setProjects] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showUpgradeModal, setShowUpgradeModal] = useState(false);
    const { hasFeatureAccess, userRole } = useFeatureAccess();

    // Feature access check using the same approach as ProjectDetail component
    const canAccessProjectManagement =
        hasFeatureAccess(FEATURES.PROJECT_MANAGEMENT) ||
        hasFeatureAccess(FEATURES.ALL_FEATURES) ||
        userRole === 'Tenant Admin Project';

    // For advanced features, we'll check for additional permissions
    const canAccessAdvancedBudget =
        hasFeatureAccess(FEATURES.ADVANCED_BUDGET) ||
        hasFeatureAccess(FEATURES.ALL_FEATURES);

    const [formData, setFormData] = useState({
        name: '',
        description: '',
        budget_type: 'BASIC',
        total_budget: 0,
        job: projectId || ''
    });

    useEffect(() => {
        loadProjects();
    }, []);

    useEffect(() => {
        if (projectId) {
            setFormData(prev => ({
                ...prev,
                job: projectId
            }));
        }
    }, [projectId]);

    const loadProjects = async () => {
        try {
            const response = await api.get('projects/');
            setProjects(response.data.results);
        } catch (error) {
            toast({
                title: "Error",
                description: "Failed to load projects",
                variant: "destructive"
            });
        }
    };

    const handleBudgetTypeChange = (value) => {
        if (value === 'ADVANCED' && !canAccessAdvancedBudget) {
            setShowUpgradeModal(true);
        } else {
            setFormData({ ...formData, budget_type: value });
        }
    };

    const handleUpgradeClick = () => {
        window.location.href = '/subscription';
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!formData.job) {
            toast({
                title: "Error",
                description: "Please select a project",
                variant: "destructive"
            });
            return;
        }

        // Check for advanced budget access and show upgrade modal if needed
        if (formData.budget_type === 'ADVANCED' && !canAccessAdvancedBudget) {
            setShowUpgradeModal(true);
            return;
        }

        try {
            setLoading(true);
            const budget = await budgetService.createBudget(formData);

            // Navigate to different components based on budget type
            if (formData.budget_type === 'ADVANCED') {
                navigate(`/projects/${formData.job}/budgets/${budget.id}/advanced`);
            } else {
                navigate(`/projects/${formData.job}/budgets/${budget.id}/basic`);
            }

            toast({
                title: "Success",
                description: "Budget created successfully"
            });
        } catch (error) {
            toast({
                title: "Error",
                description: "Failed to create budget",
                variant: "destructive"
            });
        } finally {
            setLoading(false);
        }
    };

    if (!canAccessProjectManagement) {
        return (
            <Alert>
                <AlertDescription>
                    Please upgrade your subscription to access project management features.
                    <Link to="/subscription" className="text-blue-600 hover:text-blue-800 ml-2">
                        Upgrade now
                    </Link>
                </AlertDescription>
            </Alert>
        );
    }

    return (
        <div className="space-y-6">
            <Breadcrumb />
            <Card className="max-w-2xl mx-auto">
                <CardHeader>
                    <h2 className="text-2xl font-bold">Create New Budget</h2>
                    <p className="text-sm text-gray-500">Set up a budget for your project</p>
                </CardHeader>
                <CardContent>
                    <form onSubmit={handleSubmit} className="space-y-6">
                        <div className="space-y-4">
                            {!projectId && (
                                <div className="space-y-2">
                                    <label className="text-sm font-medium">Project</label>
                                    <Select
                                        value={formData.job}
                                        onValueChange={(value) => setFormData({ ...formData, job: value })}
                                    >
                                        <SelectTrigger className="w-full">
                                            <SelectValue placeholder="Select a project" />
                                        </SelectTrigger>
                                        <SelectContent>
                                            {projects.map(project => (
                                                <SelectItem key={project.id} value={project.id}>
                                                    {project.name}
                                                </SelectItem>
                                            ))}
                                        </SelectContent>
                                    </Select>
                                </div>
                            )}

                            <div>
                                <label className="text-sm font-medium">Budget Name</label>
                                <Input
                                    required
                                    value={formData.name}
                                    onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                                    placeholder="Enter budget name"
                                />
                            </div>

                            <div>
                                <label className="text-sm font-medium">Description</label>
                                <Input
                                    value={formData.description}
                                    onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                                    placeholder="Enter budget description"
                                />
                            </div>

                            <div>
                                <label className="text-sm font-medium">Budget Type</label>
                                <Select
                                    value={formData.budget_type}
                                    onValueChange={handleBudgetTypeChange}
                                >
                                    <SelectTrigger>
                                        <SelectValue placeholder="Select budget type" />
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectItem value="BASIC">Basic Budget</SelectItem>
                                        <SelectItem value="ADVANCED">
                                            <div className="flex items-center gap-2">
                                                Advanced Budget
                                                {!canAccessAdvancedBudget && (
                                                    <LockIcon className="w-4 h-4 text-gray-400" />
                                                )}
                                            </div>
                                        </SelectItem>
                                    </SelectContent>
                                </Select>
                            </div>
                        </div>

                        <div className="flex justify-end gap-4">
                            <Button
                                className={secondaryButtonClasses}
                                type="button"
                                variant="outline"
                                onClick={() => navigate(`/projects/${formData.job}/budgets`)}
                            >
                                Cancel
                            </Button>
                            <Button
                                className={primaryButtonClasses}
                                type="submit"
                                disabled={loading}
                            >
                                {loading ? 'Creating...' : 'Create Budget'}
                            </Button>
                        </div>
                    </form>
                </CardContent>
            </Card>

            <Dialog open={showUpgradeModal} onOpenChange={setShowUpgradeModal}>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>Upgrade Required</DialogTitle>
                        <DialogDescription>
                            Advanced budgeting features are available with our Professional plan.
                            Upgrade now to access:
                            <ul className="list-disc pl-6 mt-2 space-y-1">
                                <li>Multi-level categorization</li>
                                <li>Detailed financial summaries</li>
                                <li>Advanced budget tracking</li>
                                <li>And more...</li>
                            </ul>
                        </DialogDescription>
                    </DialogHeader>
                    <DialogFooter>
                        <Button
                            type="button"
                            variant="outline"
                            onClick={() => setShowUpgradeModal(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="button"
                            className={primaryButtonClasses}
                            onClick={handleUpgradeClick}
                        >
                            Upgrade to Professional
                        </Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </div>
    );
}