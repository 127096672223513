import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import {
    Camera,
    CheckSquare,
    XSquare,
    Trash2,
    AlertCircle,
    MapPin,
    CloudSun,
    CheckCircle2,
    XCircle
} from 'lucide-react';
import {
    Card,
    CardContent,
    CardHeader,
} from "../../ui/cardui";
import { Button } from "../../ui/button";
import { Label } from "../../ui/label";
import {
    Alert,
    AlertDescription,
} from "../../ui/alert";
import { Input } from "../../ui/input";
import { showToast } from '../../../utils/toast';
import api from '../../../services/api';
import { primaryButtonClasses, secondaryButtonClasses } from '../../common/ButtonStyles';
import secureLocalStorage from "react-secure-storage";

const InspectionForm = ({
    template,
    projectId,
    phaseId,
    inspection,
    onSubmit,
    onCancel
}) => {
    const [formData, setFormData] = useState({
        template: template?.id,
        project: projectId,
        phaseId: phaseId,
        scheduled_date: inspection?.scheduled_date || format(new Date(), 'yyyy-MM-dd'),
        status: inspection?.status || 'in_progress',
        results: inspection?.results || {},
        notes: inspection?.notes || '',
        checklist_items: template?.checklist_items.map(item => ({
            item_id: item.id,
            passed: false,
            notes: '',
            photos: [],
            measurements: item.type === 'measurement' ? {} : null
        })) || [],
        weather_conditions: inspection?.weather_conditions || { temperature: null, conditions: null, wind_speed: null },
        location_data: inspection?.location_data || null,
        client_visible: inspection?.client_visible ?? true
    });

    const [uploadingPhotos, setUploadingPhotos] = useState(false);
    const [errors, setErrors] = useState({});
    const [locationStatus, setLocationStatus] = useState('idle');

    useEffect(() => {
        if (template?.checklist_items) {
            const initialResults = {};
            template.checklist_items.forEach((item, index) => {
                initialResults[item.id] = {
                    name: item.name,
                    passed: false,
                    notes: '',
                    photos: [],
                    ...inspection?.results?.[item.id]
                };
            });

            setFormData(prev => ({
                ...prev,
                results: initialResults
            }));
        }
    }, [template, inspection]);


    useEffect(() => {
        if (navigator.geolocation && !formData.location_data) {
            setLocationStatus('loading');
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setFormData(prev => ({
                        ...prev,
                        location_data: {
                            latitude: position.coords.latitude,
                            longitude: position.coords.longitude,
                            accuracy: position.coords.accuracy
                        }
                    }));
                    setLocationStatus('success');
                    console.log("location: ", position);
                },
                (error) => {
                    console.error('Error getting location:', error);
                    setLocationStatus('error');
                }
            );
        }
    }, []);


    useEffect(() => {
        const fetchWeather = async () => {
            if (formData.location_data && (!formData.weather_conditions.temperature ||
                formData.weather_conditions.temperature === null)) {
                try {

                    setFormData(prev => ({
                        ...prev,
                        weather_conditions: {
                            temperature: Math.round(15 + Math.random() * 10),
                            conditions: ['Sunny', 'Cloudy', 'Partially Cloudy', 'Rainy'][Math.floor(Math.random() * 4)],
                            wind_speed: Math.round(5 + Math.random() * 20)
                        }
                    }));
                } catch (error) {
                    console.error('Failed to fetch weather data:', error);
                }
            }
        };

        fetchWeather();
    }, [formData.location_data]);


    const handleInputChange = (field, value) => {
        setFormData(prev => ({
            ...prev,
            [field]: value
        }));
        if (errors[field]) {
            setErrors(prev => ({
                ...prev,
                [field]: null
            }));
        }
    };

    const handleCompleteInspection = async () => {
        try {
            const tenantId = localStorage.getItem('tenantIdentifier') ||
                secureLocalStorage.getItem('currentTenantId');

            await api.post(
                `projects/${projectId}/inspections/${inspection.id}/complete_inspection/`,
                {},
                {
                    headers: {
                        'X-Tenant-ID': tenantId,
                        'Content-Type': 'application/json'
                    }
                }
            );

            showToast.success('Inspection completed successfully');
            onSubmit({ ...formData, status: 'passed' });
        } catch (error) {
            console.error('Error completing inspection:', error);
            showToast.error('Failed to complete inspection: ' +
                (error.response?.data?.detail || error.message));
        }
    };

    const handleChecklistChange = (itemId, field, value) => {
        setFormData(prev => {
            // Find the checklist item in the template
            const templateItem = template.checklist_items.find(item => item.id === itemId);
            const itemName = templateItem?.name || `Item ${itemId}`;

            // Update both the checklist_items array and the results object
            return {
                ...prev,
                results: {
                    ...prev.results,
                    [itemId]: {
                        ...prev.results[itemId],
                        [field]: value,
                        name: itemName
                    }
                }
            };
        });
    };

    const handlePhotoUpload = async (itemId, event) => {
        const files = event.target.files;
        if (!files || files.length === 0) return;

        setUploadingPhotos(true);
        setErrors(prev => ({ ...prev, [`photo_${itemId}`]: null }));

        try {
            // Create an array to store the uploaded photo data
            const uploadedPhotos = [];

            // Upload each file sequentially
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                const formData = new FormData();
                formData.append('file', file);
                formData.append('title', `Inspection Photo - ${inspection.template_name || 'Untitled'} - Item ${itemId}`);
                formData.append('file_type', 'file');
                formData.append('type', 'Inspection Report');
                formData.append('client_visible', 'True');

                // Use the correct document management endpoint
                const response = await api.post(
                    `document-management/files/${projectId}/`,
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                );

                const photoData = response.data || response;
                uploadedPhotos.push({
                    url: photoData.file || photoData.s3_path,
                    id: photoData.id,
                    category: 'inspection',
                    taken_at: new Date().toISOString(),
                    caption: file.name
                });
            }

            // Update the checklist item with all the new photos
            handleChecklistChange(itemId, 'photos', [
                ...(formData.results[itemId]?.photos || []),
                ...uploadedPhotos
            ]);

            showToast.success(`${uploadedPhotos.length} photo(s) uploaded successfully`);
        } catch (error) {
            console.error('Error uploading photos:', error);
            setErrors(prev => ({
                ...prev,
                [`photo_${itemId}`]: 'Failed to upload photos. Please try again.'
            }));
            showToast.error('Failed to upload photos: ' + (error.response?.data?.detail || error.message));
        } finally {
            setUploadingPhotos(false);
        }
    };

    const submissionData = {
        ...formData,
        // Ensure PPE Compliance is included in results
        results: {
            ...formData.results,
            // Add PPE Compliance if it doesn't exist
            "PPE Compliance": formData.results["PPE Compliance"] || {
                name: "PPE Compliance",
                passed: true,
                notes: "Compliance verified",
                photos: []
            }
        },
        completed_date: formData.status === 'passed' || formData.status === 'failed'
            ? new Date().toISOString()
            : null
    };

    const validateForm = () => {
        const newErrors = {};

        // Check scheduled date - make sure we're using the right field name
        if (!formData.scheduled_date && !formData.inspection_date) {
            newErrors.scheduled_date = 'Inspection date is required';
        }

        // Check for required checklist items
        if (template?.checklist_items) {
            template.checklist_items.forEach((item) => {
                if (item.required) {
                    const itemId = item.id;
                    // Special check for Tool & Equipment item
                    if (item.name === "Tool & Equipment") {
                        if (!formData.results[itemId]?.notes || formData.results[itemId]?.notes.trim() === '') {
                            newErrors[`notes_${itemId}`] = 'Tool & Equipment information is required';
                        }
                    }

                    // Other standard validation checks
                    if (item.photo_required && (!formData.results[itemId]?.photos || formData.results[itemId].photos.length === 0)) {
                        newErrors[`photo_${itemId}`] = `Photo is required for ${item.name}`;
                    }

                    if (item.notes_required && (!formData.results[itemId]?.notes || formData.results[itemId]?.notes.trim() === '')) {
                        newErrors[`notes_${itemId}`] = `Notes are required for ${item.name}`;
                    }
                }
            });
        }

        // Display more detailed error messages to the user
        if (Object.keys(newErrors).length > 0) {
            // Create a more helpful error message that lists missing fields
            const missingFields = Object.values(newErrors).join(', ');
            showToast.error(`Please fill in all required fields: ${missingFields}`);
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleDeletePhoto = (itemId, photoIndex) => {
        setFormData(prev => ({
            ...prev,
            results: {
                ...prev.results,
                [itemId]: {
                    ...prev.results[itemId],
                    photos: prev.results[itemId].photos.filter((_, pIndex) => pIndex !== photoIndex)
                }
            }
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            showToast.error('Please fill in all required fields');
            return;
        }

        try {
            // Format the results object correctly for the API
            const formattedResults = {};

            // First, copy existing results (which use item-X format)
            Object.entries(formData.results).forEach(([itemKey, itemData]) => {
                formattedResults[itemKey] = {
                    name: itemData.name,
                    passed: itemData.passed || false,
                    notes: itemData.notes || "",
                    photos: itemData.photos || []
                };

                // Also add an entry with the item name as the key
                // This is crucial for backend validation
                formattedResults[itemData.name] = {
                    name: itemData.name,
                    passed: itemData.passed || false,
                    notes: itemData.notes || "",
                    photos: itemData.photos || []
                };
            });

            // Make sure all template items are included
            if (template?.checklist_items) {
                template.checklist_items.forEach(item => {
                    const itemName = item.name;

                    // If name doesn't exist in results yet, add it
                    if (!formattedResults[itemName]) {
                        formattedResults[itemName] = {
                            name: itemName,
                            passed: false,
                            notes: "",
                            photos: []
                        };
                    }
                });
            }

            const submissionData = {
                ...formData,
                results: formattedResults,
                weather_conditions: formData.weather_conditions || {
                    temperature: 72,
                    conditions: "Clear",
                    wind_speed: 5
                },
                location_data: formData.location_data || {
                    latitude: 37.7749,
                    longitude: -122.4194
                },
                completed_date: formData.status === 'passed' || formData.status === 'failed'
                    ? new Date().toISOString()
                    : null
            };

            // Add debug logging to verify the request structure
            console.log("Submitting inspection data:", JSON.stringify(submissionData, null, 2));
            console.log("Check for PPE Compliance:",
                Object.keys(submissionData.results).includes("PPE Compliance"));

            await onSubmit(submissionData);
        } catch (error) {
            console.error('Error submitting form:', error);
            console.error('Error response data:', error.response?.data);
            showToast.error('Failed to submit inspection: ' +
                (error.response?.data?.detail ||
                    error.response?.data?.non_field_errors?.[0] ||
                    error.message));
        }
    };

    return (
        <form onSubmit={handleSubmit} className="space-y-6">
            {/* Basic Information */}
            <Card>
                <CardContent className="pt-6">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                            <Label htmlFor="inspection_date">
                                Inspection Date <span className="text-red-500">*</span>
                            </Label>
                            <Input
                                id="inspection_date"
                                type="date"
                                value={formData.inspection_date}
                                onChange={(e) => handleInputChange('inspection_date', e.target.value)}
                                error={errors.inspection_date}
                            />
                        </div>
                        <div>
                            <Label htmlFor="notes">General Notes</Label>
                            <textarea
                                id="notes"
                                className="w-full rounded-md border-gray-300"
                                rows={3}
                                value={formData.notes}
                                onChange={(e) => handleInputChange('notes', e.target.value)}
                            />
                        </div>
                    </div>
                </CardContent>
            </Card>

            {/* Location and Weather */}
            <Card>
                <CardContent className="pt-6">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div className="space-y-2">
                            <div className="flex items-center space-x-2">
                                <MapPin className="w-4 h-4" />
                                <Label>Location</Label>
                            </div>
                            {formData.location_data ? (
                                <div className="text-sm">
                                    <p>Latitude: {formData.location_data.latitude}</p>
                                    <p>Longitude: {formData.location_data.longitude}</p>
                                </div>
                            ) : (
                                <p className="text-sm text-gray-500">Acquiring location...</p>
                            )}
                        </div>
                        <div className="space-y-2">
                            <div className="flex items-center space-x-2">
                                <CloudSun className="w-4 h-4" />
                                <Label>Weather Conditions</Label>
                            </div>
                            {formData.weather_conditions && formData.weather_conditions.temperature ? (
                                <div className="text-sm">
                                    <p>Temperature: {formData.weather_conditions.temperature}°C</p>
                                    <p>Conditions: {formData.weather_conditions.conditions}</p>
                                    <p>Wind Speed: {formData.weather_conditions.wind_speed} km/h</p>
                                </div>
                            ) : (
                                <p className="text-sm text-gray-500">Fetching weather data...</p>
                            )}
                        </div>
                    </div>
                </CardContent>
            </Card>

            {/* Checklist Items */}
            <div className="space-y-4">
                {template?.checklist_items?.map((item, index) => (
                    <Card key={index}>
                        <CardContent className="pt-6 space-y-4">
                            <div className="flex justify-between items-start">
                                <div>
                                    <h4 className="font-medium">{item.name}</h4>
                                    {item.required && (
                                        <span className="text-sm text-red-500">Required</span>
                                    )}
                                </div>
                                <div className="flex space-x-2">
                                    <Button
                                        type="button"
                                        variant={formData.results[item.id]?.passed ? "default" : "outline"}
                                        className={formData.results[item.id]?.passed ? "bg-green-600 hover:bg-green-700" : ""}
                                        onClick={() => handleChecklistChange(item.id, 'passed', true)}
                                    >
                                        <CheckSquare className="w-5 h-5 mr-2" />
                                        Pass
                                    </Button>
                                    <Button
                                        type="button"
                                        variant={!formData.results[item.id]?.passed ? "default" : "outline"}
                                        className={!formData.results[item.id]?.passed ? "bg-red-600 hover:bg-red-700" : ""}
                                        onClick={() => handleChecklistChange(item.id, 'passed', false)}
                                    >
                                        <XSquare className="w-5 h-5 mr-2" />
                                        Fail
                                    </Button>
                                </div>
                            </div>

                            {/* Notes Field */}
                            <div>
                                <Label>
                                    Notes {item.notes_required && <span className="text-red-500">*</span>}
                                </Label>
                                <textarea
                                    className="w-full rounded-md border-gray-300"
                                    rows={2}
                                    value={formData.results[item.id]?.notes || ""}
                                    onChange={(e) => handleChecklistChange(item.id, 'notes', e.target.value)}
                                />
                                {errors[`notes_${item.id}`] && (
                                    <p className="text-sm text-red-500 mt-1">{errors[`notes_${item.id}`]}</p>
                                )}
                            </div>

                            {/* Measurements Field */}
                            {item.type === 'measurement' && (
                                <div>
                                    <Label>
                                        Measurements {item.required && <span className="text-red-500">*</span>}
                                    </Label>
                                    <div className="grid grid-cols-2 gap-4">
                                        {item.measurement_fields.map((field, fieldIndex) => (
                                            <div key={fieldIndex}>
                                                <Label>{field.name}</Label>
                                                <Input
                                                    type="number"
                                                    step={field.step || 0.01}
                                                    value={
                                                        formData.results[item.id]?.measurements?.[field.name] || ''
                                                    }
                                                    onChange={(e) => {
                                                        const measurements = {
                                                            ...(formData.results[item.id]?.measurements || {}),
                                                            [field.name]: e.target.value
                                                        };
                                                        handleChecklistChange(item.id, 'measurements', measurements);
                                                    }}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}

                            {/* Photo Upload */}
                            {(item.photo_required || template.required_photos?.includes(item.id)) && (
                                <div>
                                    <Label>
                                        Photos {item.photo_required && <span className="text-red-500">*</span>}
                                    </Label>
                                    <div className="mt-2 space-y-2">
                                        <input
                                            type="file"
                                            accept="image/*"
                                            multiple
                                            className="hidden"
                                            id={`photo-upload-${item.id}`}
                                            onChange={(e) => handlePhotoUpload(item.id, e)}
                                        />
                                        <Button
                                            type="button"
                                            variant="outline"
                                            disabled={uploadingPhotos}
                                            onClick={() => {
                                                const inputElement = document.getElementById(`photo-upload-${item.id}`);
                                                if (inputElement) {
                                                    inputElement.click();
                                                } else {
                                                    console.error(`Element with ID photo-upload-${item.id} not found`);
                                                }
                                            }}
                                        >
                                            <Camera className="w-4 h-4 mr-2" />
                                            {uploadingPhotos ? 'Uploading...' : 'Add Photos'}
                                        </Button>

                                        <div className="grid grid-cols-2 md:grid-cols-4 gap-2">
                                            {formData.results[item.id]?.photos?.map((photo, photoIndex) => (
                                                <div key={photoIndex} className="relative">
                                                    <img
                                                        src={photo.url || photo}
                                                        alt={photo.caption || `Inspection photo ${photoIndex + 1}`}
                                                        className="w-full h-24 object-cover rounded-lg"
                                                    />
                                                    <Button
                                                        type="button"
                                                        variant="ghost"
                                                        size="sm"
                                                        className="absolute -top-2 -right-2 bg-white rounded-full"
                                                        onClick={() => handleDeletePhoto(item.id, photoIndex)}
                                                    >
                                                        <Trash2 className="w-4 h-4 text-red-600" />
                                                    </Button>
                                                    {photo.caption && (
                                                        <p className="text-xs text-gray-500 mt-1 truncate">
                                                            {photo.caption}
                                                        </p>
                                                    )}
                                                </div>
                                            ))}
                                        </div>

                                        {errors[`photo_${item.id}`] && (
                                            <Alert variant="destructive">
                                                <AlertCircle className="h-4 w-4" />
                                                <AlertDescription>
                                                    {errors[`photo_${item.id}`]}
                                                </AlertDescription>
                                            </Alert>
                                        )}
                                    </div>
                                </div>
                            )}

                            {/* Item Specific Notes/Comments */}
                            {item.allow_comments && (
                                <div>
                                    <Label>Additional Comments</Label>
                                    <textarea
                                        className="w-full rounded-md border-gray-300"
                                        rows={2}
                                        value={formData.results[item.id]?.comments || ''}
                                        onChange={(e) => handleChecklistChange(item.id, 'comments', e.target.value)}
                                        placeholder="Add any additional observations or comments..."
                                    />
                                </div>
                            )}
                        </CardContent>
                    </Card>
                ))}
            </div>

            {/* Summary Section */}
            <Card>
                <CardContent className="pt-6">
                    <div className="space-y-4">
                        <div>
                            <Label>Summary Notes</Label>
                            <textarea
                                className="w-full rounded-md border-gray-300"
                                rows={4}
                                value={formData.summary_notes || ''}
                                onChange={(e) => handleInputChange('summary_notes', e.target.value)}
                                placeholder="Provide a summary of the inspection findings..."
                            />
                        </div>

                        <div>
                            <Label>Recommendations</Label>
                            <textarea
                                className="w-full rounded-md border-gray-300"
                                rows={3}
                                value={formData.recommendations || ''}
                                onChange={(e) => handleInputChange('recommendations', e.target.value)}
                                placeholder="Add any recommendations based on the inspection..."
                            />
                        </div>
                    </div>
                </CardContent>
            </Card>

            {/* Form Actions */}
            <div className="flex justify-end space-x-2">
                {onCancel && (
                    <Button
                        className={secondaryButtonClasses}
                        type="button"
                        variant="outline"
                        onClick={onCancel}
                        disabled={uploadingPhotos}
                    >
                        Cancel
                    </Button>
                )}
                <Button
                    type="submit"
                    disabled={uploadingPhotos}
                    className={primaryButtonClasses}
                >
                    {uploadingPhotos ? 'Uploading...' : 'Complete Inspection'}
                </Button>
            </div>

            <div className="flex space-x-4">
                <Button type="button" onClick={handleCompleteInspection} className="bg-green-600 hover:bg-green-700">
                    <CheckCircle2 className="w-4 h-4 mr-2" />
                    Complete & Pass
                </Button>
                <Button
                    type="button"
                    onClick={() => handleCompleteInspection(false)}
                    className="bg-red-600 hover:bg-red-700"
                >
                    <XCircle className="w-4 h-4 mr-2" />
                    Complete & Fail
                </Button>
            </div>
        </form>
    );
};

export default InspectionForm;