import React, { useState, useEffect } from 'react';
import { Button } from "../ui/button";
import { Card, CardContent, CardHeader } from "../ui/cardui";
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Input } from '../ui/input';
import { Alert, AlertDescription } from '../ui/alert';
import {
    DollarSign,
    Clock,
    AlertTriangle,
    ArrowUpCircle,
    Plus,
    Trash2
} from 'lucide-react';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "../ui/table";
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogFooter,
} from "../ui/dialog";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "../ui/select";
import budgetService from '../../services/budgetService';
import { useToast } from '../ui/use-toast';
import { formatCurrency } from '../../utils/formatter';
import { Breadcrumb } from '../common/Breadcrumb';
import { primaryButtonClasses, secondaryButtonClasses } from '../common/ButtonStyles';
import { calculateTotalAmount } from '../../utils/calculations';
import UnitSelect from '../../utils/UnitSelect';
import BudgetStatusControl from './BudgetStatusControl';

export default function BasicBudgetDetail() {
    const { projectId, budgetId } = useParams();
    const navigate = useNavigate();
    const { toast } = useToast();
    const [showItemDialog, setShowItemDialog] = useState(false);
    const [budget, setBudget] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isEditing, setIsEditing] = useState(false);
    const [items, setItems] = useState([]);
    const [newItem, setNewItem] = useState({
        description: '',
        quantity: 1,
        unit_price: 0,
        total: 0
    });
    const [itemForm, setItemForm] = useState({
        description: '',
        quantity: '',
        unit: 'EA',
        unit_price: '',
        material: null
    });
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        total_budget: 0,
        labor_cost: 0,
        material_cost: 0,
        other_cost: 0
    });

    const handleItemFormChange = (field, value) => {
        setItemForm(prev => {
            const updated = { ...prev, [field]: value };
            return updated;
        });
    };

    useEffect(() => {
        loadBudget();
        loadBudgetItems();
    }, [budgetId]);



    const handleAddItem = async () => {
        if (!itemForm.description || !itemForm.quantity || !itemForm.unit_price) {
            toast({
                title: "Error",
                description: "Please fill in all required fields",
                variant: "destructive"
            });
            return;
        }

        try {
            const total = calculateTotalAmount(
                parseFloat(itemForm.quantity),
                parseFloat(itemForm.unit_price),
                itemForm.unit,
                itemForm.material
            );

            const newItem = {
                budget: budgetId,
                description: itemForm.description,
                quantity: parseFloat(itemForm.quantity),
                unit: itemForm.unit,
                material: itemForm.material,
                unit_price: parseFloat(itemForm.unit_price),
                total_amount: total
            };

            await budgetService.addBasicLineItem(budgetId, newItem);
            await loadBudgetItems();

            setShowItemDialog(false);
            setItemForm({
                description: '',
                quantity: '',
                unit: 'EA',
                unit_price: '',
                material: null
            });

            toast({
                title: "Success",
                description: "Item added successfully"
            });
        } catch (error) {
            toast({
                title: "Error",
                description: error.message || "Failed to add item",
                variant: "destructive"
            });
        }
    };

    const loadBudgetItems = async () => {
        try {
            const response = await budgetService.getBudgetLineItems(budgetId);
            if (response?.results) {
                const processedItems = response.results.map(item => ({
                    ...item,
                    total: item.total_amount || item.quantity * item.unit_price
                }));

                setItems(processedItems);

                // Calculate totals
                const itemsTotal = processedItems.reduce(
                    (sum, item) => sum + (parseFloat(item.total_amount) || 0),
                    0
                );

                // Update form data with calculated totals
                setFormData(prev => ({
                    ...prev,
                    material_cost: itemsTotal // Assuming all items count toward material cost
                }));

                const totalCosts = (formData.labor_cost || 0) +
                    itemsTotal +
                    (formData.other_cost || 0);

                setBudget(prev => ({
                    ...prev,
                    material_cost: itemsTotal,
                    total_budget: Math.max(prev.total_budget, totalCosts)
                }));
            }
        } catch (error) {
            toast({
                title: "Error",
                description: error.message || "Failed to load budget items",
                variant: "destructive"
            });
        }
    };

    const handleNewItemChange = (e) => {
        const { name, value } = e.target;
        const updatedItem = { ...newItem, [name]: value };

        // Calculate total whenever quantity or unit_price changes
        if (name === 'quantity' || name === 'unit_price') {
            updatedItem.total = updatedItem.quantity * updatedItem.unit_price;
        }

        setNewItem(updatedItem);
    };

    const handleStatusChange = async (newStatus) => {
        try {
            const updatedBudget = await budgetService.updateBudget(budgetId, {
                ...budget,
                status: newStatus
            });
            setBudget(updatedBudget);
        } catch (error) {
            throw error;
        }
    };


    const handleDeleteItem = async (itemId) => {
        try {
            await budgetService.deleteBasicLineItem(budgetId, itemId);
            setItems(items.filter(item => item.id !== itemId));
            toast({
                title: "Success",
                description: "Item deleted successfully"
            });
            loadBudget(); // Refresh budget totals
        } catch (error) {
            toast({
                title: "Error",
                description: "Failed to delete item",
                variant: "destructive"
            });
        }
    };

    const loadBudget = async () => {
        try {
            setLoading(true);
            const budgetData = await budgetService.getBudgetById(budgetId);
            setBudget(budgetData);
            setFormData({
                name: budgetData.name,
                description: budgetData.description,
                total_budget: budgetData?.total_budget,
                labor_cost: budgetData.labor_cost || 0,
                material_cost: budgetData.material_cost || 0,
                other_cost: budgetData.other_cost || 0
            });
        } catch (error) {
            toast({
                title: "Error",
                description: "Failed to load budget details",
                variant: "destructive"
            });
            navigate(`/projects/${projectId}/budgets`);
        } finally {
            setLoading(false);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: name.includes('cost') || name === 'total_budget' ? parseFloat(value) || 0 : value
        }));
    };

    const handleSave = async () => {
        if (budget.status === 'CLOSED') {
            toast({
                title: "Error",
                description: "Cannot edit a closed budget",
                variant: "destructive"
            });
            return;
        }
        try {
            if (!formData.name || formData?.total_budget === undefined) {
                toast({
                    title: "Error",
                    description: "Name and total budget are required",
                    variant: "destructive"
                });
                return;
            }

            const updatedBudget = await budgetService.updateBudget(budgetId, {
                name: formData.name,
                description: formData.description || '',
                total_budget: parseFloat(formData?.total_budget),
                job: projectId,
                status: budget.status // Preserve existing status
            });

            setBudget(updatedBudget);
            setIsEditing(false);
            toast({
                title: "Success",
                description: "Budget updated successfully"
            });
        } catch (error) {
            toast({
                title: "Error",
                description: error.response?.data?.detail || "Failed to update budget",
                variant: "destructive"
            });
        }
    };


    if (loading || !budget) {
        return (
            <div className="space-y-6">
                <Breadcrumb />
                <div className="flex justify-center p-8">
                    <span className="loading loading-spinner" />
                </div>
            </div>
        );
    }

    const startEditing = () => {
        if (budget.status === 'CLOSED') {
            toast({
                title: "Error",
                description: "Cannot edit a closed budget",
                variant: "destructive"
            });
            return;
        }
        setIsEditing(true);
    };

    const totalCosts = formData.labor_cost + formData.material_cost + formData.other_cost;
    const remainingBudget = formData.total_budget - totalCosts;
    const isOverBudget = remainingBudget < 0;


    return (
        <div className="space-y-6">
            <Breadcrumb />
            <Card>
                <CardHeader className="flex flex-row items-center justify-between">
                    <div>
                        <h2 className="text-2xl font-bold">
                            {isEditing ? (
                                <Input
                                    name="name"
                                    value={formData.name}
                                    onChange={handleInputChange}
                                    className="max-w-md"
                                />
                            ) : (
                                budget.name
                            )}
                        </h2>
                        <p className="text-sm text-gray-500">Basic Budget</p>
                    </div>
                    <div className="flex gap-2">
                        <BudgetStatusControl
                            status={budget.status}
                            onStatusChange={handleStatusChange}
                            isEditing={isEditing}
                        />
                        {isEditing ? (
                            <>
                                <Button
                                    className={secondaryButtonClasses}
                                    variant="outline"
                                    //onClick={() => setIsEditing(false)}
                                    onClick={startEditing}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    className={primaryButtonClasses}
                                    onClick={handleSave}
                                >
                                    Save Changes
                                </Button>
                            </>
                        ) : (
                            <Button
                                className={primaryButtonClasses}
                                onClick={() => setIsEditing(true)}
                            >
                                Edit
                            </Button>
                        )}
                    </div>
                </CardHeader>

                <CardContent className="space-y-6">
                    {/* Main Budget Metrics */}
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                        <div className="p-4 bg-green-50 rounded-lg">
                            <div className="flex items-center gap-2 text-green-700 mb-1">
                                <DollarSign className="w-4 h-4" />
                                <span className="font-medium">Total Budget</span>
                            </div>
                            <div className="text-2xl font-bold text-green-900">
                                {isEditing ? (
                                    <Input
                                        type="number"
                                        name="total_budget"
                                        value={formData.total_budget}
                                        onChange={handleInputChange}
                                    />
                                ) : (
                                    formatCurrency(budget?.total_budget)
                                )}
                            </div>
                        </div>

                        <div className="p-4 bg-blue-50 rounded-lg">
                            <div className="flex items-center gap-2 text-blue-700 mb-1">
                                <Clock className="w-4 h-4" />
                                <span className="font-medium">Total Costs</span>
                            </div>
                            <div className="text-2xl font-bold text-blue-900">
                                {formatCurrency(totalCosts)}
                            </div>
                        </div>

                        <div className={`p-4 rounded-lg ${isOverBudget ? 'bg-red-50' : 'bg-purple-50'}`}>
                            <div className={`flex items-center gap-2 mb-1 ${isOverBudget ? 'text-red-700' : 'text-purple-700'}`}>
                                <DollarSign className="w-4 h-4" />
                                <span className="font-medium">Remaining</span>
                            </div>
                            <div className={`text-2xl font-bold ${isOverBudget ? 'text-red-900' : 'text-purple-900'}`}>
                                {formatCurrency(remainingBudget)}
                            </div>
                        </div>
                    </div>


                    <Card>
                        <CardHeader className="flex flex-row items-center justify-between">
                            <h3 className="text-lg font-semibold">Budget Items</h3>
                            <Button
                                onClick={() => setShowItemDialog(true)}
                                className={primaryButtonClasses}
                            >
                                <Plus className="w-4 h-4 mr-2" />
                                Add Item
                            </Button>
                        </CardHeader>
                        <CardContent>
                            <div className="space-y-4">
                                {/* New Item Form */}
                                <div className="grid grid-cols-12 gap-4 items-center">
                                    <div className="col-span-5">
                                        <Input
                                            placeholder="Description"
                                            name="description"
                                            value={newItem.description}
                                            onChange={handleNewItemChange}
                                        />
                                    </div>
                                    <div className="col-span-2">
                                        <Input
                                            type="number"
                                            placeholder="Quantity"
                                            name="quantity"
                                            value={newItem.quantity}
                                            onChange={handleNewItemChange}
                                            min="1"
                                        />
                                    </div>
                                    <div className="col-span-2">
                                        <Input
                                            type="number"
                                            placeholder="Unit Price"
                                            name="unit_price"
                                            value={newItem.unit_price}
                                            onChange={handleNewItemChange}
                                            min="0"
                                            step="0.01"
                                        />
                                    </div>
                                    <div className="col-span-3 text-right">
                                        <strong>Total: {formatCurrency(newItem.quantity * newItem.unit_price)}</strong>
                                    </div>
                                </div>

                                {/* Items Table */}
                                <Table>
                                    <TableHeader>
                                        <TableRow>
                                            <TableHead>Description</TableHead>
                                            <TableHead className="text-right">Quantity</TableHead>
                                            <TableHead className="text-center">Unit</TableHead>
                                            <TableHead className="text-right">Unit Price</TableHead>
                                            <TableHead className="text-right">Total</TableHead>
                                            <TableHead className="text-right">Actions</TableHead>
                                        </TableRow>
                                    </TableHeader>
                                    <TableBody>
                                        {items.map((item) => (
                                            <TableRow key={item.id}>
                                                <TableCell>{item.description}</TableCell>
                                                <TableCell className="text-right">{item.quantity}</TableCell>
                                                <TableCell className="text-center">{item.unit}</TableCell>
                                                <TableCell className="text-right">{formatCurrency(item.unit_price)}</TableCell>
                                                <TableCell className="text-right">{formatCurrency(item.total)}</TableCell>
                                                <TableCell className="text-right">
                                                    <Button
                                                        variant="ghost"
                                                        size="sm"
                                                        onClick={() => handleDeleteItem(item.id)}
                                                        className="text-red-600 hover:text-red-800"
                                                    >
                                                        <Trash2 className="w-4 h-4" />
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </div>
                        </CardContent>
                    </Card>
                    <Dialog open={showItemDialog} onOpenChange={setShowItemDialog}>
                        <DialogContent>
                            <DialogHeader>
                                <DialogTitle>Add Budget Item</DialogTitle>
                            </DialogHeader>
                            <div className="space-y-4">
                                <div>
                                    <label className="text-sm font-medium">Description</label>
                                    <Input
                                        value={itemForm.description}
                                        onChange={(e) => handleItemFormChange('description', e.target.value)}
                                        placeholder="Enter item description"
                                    />
                                </div>
                                <div className="grid grid-cols-3 gap-4">
                                    <div>
                                        <label className="text-sm font-medium">Quantity</label>
                                        <Input
                                            type="number"
                                            value={itemForm.quantity}
                                            onChange={(e) => handleItemFormChange('quantity', e.target.value)}
                                            placeholder="Enter quantity"
                                            min="0"
                                            step="0.01"
                                        />
                                    </div>
                                    <div>
                                        <label className="text-sm font-medium">Unit</label>
                                        <UnitSelect
                                            value={itemForm.unit}
                                            onChange={(value) => handleItemFormChange('unit', value)}
                                            material={itemForm.material}
                                            className="w-full"
                                        />
                                    </div>
                                    <div>
                                        <label className="text-sm font-medium">Unit Price</label>
                                        <Input
                                            type="number"
                                            value={itemForm.unit_price}
                                            onChange={(e) => handleItemFormChange('unit_price', e.target.value)}
                                            placeholder="Enter price"
                                            min="0"
                                            step="0.01"
                                        />
                                    </div>
                                </div>
                                <div>
                                    <label className="text-sm font-medium">Material Type (Optional)</label>
                                    <Select
                                        value={itemForm.material}
                                        onValueChange={(value) => handleItemFormChange('material', value)}
                                    >
                                        <SelectTrigger className="w-full">
                                            <SelectValue placeholder="Select material type" />
                                        </SelectTrigger>
                                        <SelectContent>
                                            <SelectItem value="CONCRETE">Concrete</SelectItem>
                                            <SelectItem value="GRAVEL">Gravel</SelectItem>
                                            <SelectItem value="SAND">Sand</SelectItem>
                                            <SelectItem value="ASPHALT">Asphalt</SelectItem>
                                            <SelectItem value="ROOFING">Roofing</SelectItem>
                                            <SelectItem value="LUMBER">Lumber</SelectItem>
                                        </SelectContent>
                                    </Select>
                                </div>
                            </div>
                            <DialogFooter>
                                <Button variant="outline" onClick={() => setShowItemDialog(false)} className={secondaryButtonClasses}>
                                    Cancel
                                </Button>
                                <Button onClick={handleAddItem} className={primaryButtonClasses}>
                                    Add Item
                                </Button>
                            </DialogFooter>
                        </DialogContent>

                    </Dialog>
                    {/* Upgrade Notice */}
                    <Alert className="bg-blue-50 border-blue-200">
                        <ArrowUpCircle className="h-4 w-4 text-blue-600" />
                        <AlertDescription className="text-blue-800">
                            Unlock advanced budgeting features with our Professional plan:
                            <ul className="mt-2 list-disc list-inside space-y-1">
                                <li>Detailed line item tracking</li>
                                <li>Cost codes and categories</li>
                                <li>Change order management</li>
                                <li>Actual cost tracking</li>
                                <li>Budget vs. actual reporting</li>
                            </ul>
                            <Link
                                to="/subscription"
                                className="mt-2 inline-flex items-center text-blue-600 hover:text-blue-800 font-medium"
                            >
                                Upgrade Now →
                            </Link>
                        </AlertDescription>
                    </Alert>
                </CardContent>
            </Card>
        </div>
    );
}