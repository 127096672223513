import React, { useState, useEffect } from 'react';
import { Button } from "../ui/button";
import { Card, CardContent } from "../ui/cardui";
import { Input } from '../ui/input';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../ui/select';
import { ChevronRight, ChevronDown, Edit2, Trash2, Plus, FileDown, BarChart2 } from 'lucide-react';
import { Table } from '../ui/table';
import budgetService from '../../services/budgetService';
import { useToast } from '../ui/use-toast';
import { formatCurrency } from '../../utils/formatter';
import CategoryManager from './BudgetCategoryManager';
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "../ui/dialog";
import { Slider } from "../ui/slider";
import { Progress } from "../ui/progress";
import { primaryButtonClasses, secondaryButtonClasses } from '../common/ButtonStyles';

export default function BudgetLineItems({ budget, isEditing, onUpdate, canAccessCostFeatures = false }) {
    const { toast } = useToast();
    const [expandedCategories, setExpandedCategories] = useState(new Set());
    const [costCodes, setCostCodes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState({ category: '', costCode: '', search: '' });
    const [editingItem, setEditingItem] = useState(null);
    const [editingProgress, setEditingProgress] = useState(null);
    const [costCodeSearch, setCostCodeSearch] = useState('');
    const [costCodePage, setCostCodePage] = useState(1);
    const [showCategoryManager, setShowCategoryManager] = useState(false);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        loadCategories();
    }, []);

    useEffect(() => {
        // Load cost codes with pagination and search
        const loadCostCodes = async () => {
            try {
                const data = await budgetService.getCostCodes(costCodePage, costCodeSearch);
                if (costCodePage === 1) {
                    setCostCodes(data.results);
                } else {
                    setCostCodes(prev => [...prev, ...data.results]);
                }
            } catch (error) {
                toast({ title: "Error", description: "Failed to load cost codes", variant: "destructive" });
            }
        };
        loadCostCodes();
    }, [costCodeSearch, costCodePage]);

    const loadCategories = async () => {
        try {
            const data = await budgetService.getCategories(budget.id);
            console.log("Loaded categories data:", data);
            setCategories(Array.isArray(data.results) ? data.results : []);
        } catch (error) {
            toast({
                title: 'Error',
                description: 'Failed to load categories',
                variant: 'destructive'
            });
            setCategories([]);
        }
    };

    const toggleCategory = (categoryId) => {
        const newExpanded = new Set(expandedCategories);
        if (newExpanded.has(categoryId)) newExpanded.delete(categoryId);
        else newExpanded.add(categoryId);
        setExpandedCategories(newExpanded);
    };

    const handleAddItem = async (categoryId) => {
        const newItem = {
            budget: budget.id,
            category: categoryId,
            description: '',
            quantity: 0,
            unit: '',
            unit_price: 0,
            total_amount: 0,
            progress_percentage: 0
        };
        setEditingItem(newItem);
    };

    const handleSaveItem = async (item) => {
        try {
            setLoading(true);
            if (item.id) await budgetService.updateLineItem(item.id, item);
            else await budgetService.createLineItem(item);
            setEditingItem(null);
            onUpdate();
            toast({ title: "Success", description: "Line item saved successfully" });
        } catch (error) {
            toast({ title: "Error", description: "Failed to save line item", variant: "destructive" });
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteItem = async (itemId) => {
        if (!window.confirm('Are you sure you want to delete this item?')) return;
        try {
            setLoading(true);
            await budgetService.deleteLineItem(itemId);
            onUpdate();
            toast({ title: "Success", description: "Line item deleted successfully" });
        } catch (error) {
            toast({ title: "Error", description: "Failed to delete line item", variant: "destructive" });
        } finally {
            setLoading(false);
        }
    };

    const handleEditItem = (item) => {
        setEditingItem(item);
    };

    const handleUpdateProgress = (item) => {
        setEditingProgress({
            id: item.id,
            description: item.description,
            progress_percentage: item.progress_percentage || 0
        });
    };

    const handleSaveProgress = async () => {
        try {
            setLoading(true);
            // Add the new updateLineItemProgress API method call
            await budgetService.updateLineItemProgress(
                editingProgress.id,
                { progress_percentage: editingProgress.progress_percentage }
            );
            setEditingProgress(null);
            onUpdate();
            toast({ title: "Success", description: "Progress updated successfully" });
        } catch (error) {
            toast({
                title: "Error",
                description: "Failed to update progress",
                variant: "destructive"
            });
        } finally {
            setLoading(false);
        }
    };

    const calculateTotal = (category) => {
        let total = 0;
        category.line_items?.forEach(item => total += parseFloat(item.total_amount));
        category.subcategories?.forEach(sub => total += calculateTotal(sub));
        return total;
    };

    const getProgressColor = (percentage) => {
        if (percentage >= 90) return "bg-green-600";
        if (percentage >= 70) return "bg-green-500";
        if (percentage >= 50) return "bg-yellow-500";
        if (percentage >= 25) return "bg-yellow-400";
        return "bg-gray-300";
    };

    const renderCategory = (category, level = 0) => {
        if (!category || !category.line_items) return null;
        const isExpanded = expandedCategories.has(category.id);
        const paddingLeft = `${level * 2}rem`;
        const total = category.line_items.reduce((sum, item) => sum + parseFloat(item.total_amount || 0), 0);

        return (
            <React.Fragment key={category.id}>
                <tr className={level === 0 ? 'bg-gray-50' : ''}>
                    <td className="py-2">
                        <div className="flex items-center" style={{ paddingLeft }}>
                            <button onClick={() => toggleCategory(category.id)} className="p-1 hover:bg-gray-200 rounded">
                                {isExpanded ? <ChevronDown className="w-4 h-4" /> : <ChevronRight className="w-4 h-4" />}
                            </button>
                            <span className="font-medium ml-2">{category.name}</span>
                        </div>
                    </td>
                    <td colSpan={canAccessCostFeatures ? 6 : 5} className="text-right pr-4">
                        <span className="font-medium">{formatCurrency(total)}</span>
                    </td>
                    <td>
                        {isEditing && (
                            <Button size="sm" variant="outline" onClick={() => handleAddItem(category.id)}>
                                <Plus className="w-4 h-4 mr-1" /> Add Item
                            </Button>
                        )}
                    </td>
                </tr>

                {isExpanded && (
                    <>
                        {category.line_items?.map(item => (
                            <tr key={item.id}>
                                <td>{item.description}</td>
                                <td>{item.cost_code_display}</td>
                                <td>{item.quantity}</td>
                                <td>{item.unit}</td>
                                <td>{formatCurrency(item.unit_price)}</td>
                                <td>{formatCurrency(item.total_amount)}</td>

                                {/* Progress column - only show if cost features are enabled */}
                                {canAccessCostFeatures && (
                                    <td>
                                        <div className="flex items-center gap-2">
                                            <div className="w-24 h-2 bg-gray-200 rounded-full overflow-hidden">
                                                <div
                                                    className={`h-full rounded-full ${getProgressColor(item.progress_percentage || 0)}`}
                                                    style={{ width: `${item.progress_percentage || 0}%` }}
                                                ></div>
                                            </div>
                                            <span className="text-xs">{item.progress_percentage || 0}%</span>
                                        </div>
                                    </td>
                                )}

                                <td>
                                    <div className="flex gap-2">
                                        <Button size="sm" variant="outline" onClick={() => handleEditItem(item)}>
                                            Edit
                                        </Button>

                                        {/* Add progress update button if cost features are enabled */}
                                        {canAccessCostFeatures && (
                                            <Button
                                                size="sm"
                                                variant="outline"
                                                onClick={() => handleUpdateProgress(item)}
                                            >
                                                <BarChart2 className="w-4 h-4" />
                                            </Button>
                                        )}

                                        <Button size="sm" variant="destructive" onClick={() => handleDeleteItem(item.id)}>
                                            Delete
                                        </Button>
                                    </div>
                                </td>
                            </tr>
                        ))}

                        {editingItem && editingItem.category === category.id && (
                            <tr>
                                <td><Input placeholder="Description" value={editingItem.description} onChange={(e) => setEditingItem({ ...editingItem, description: e.target.value })} /></td>
                                <td><Input placeholder="Cost Code" value={editingItem.cost_code_display} onChange={(e) => setEditingItem({ ...editingItem, cost_code_display: e.target.value })} /></td>
                                <td><Input type="number" placeholder="Quantity" value={editingItem.quantity} onChange={(e) => setEditingItem({ ...editingItem, quantity: e.target.value })} /></td>
                                <td><Input placeholder="Unit" value={editingItem.unit} onChange={(e) => setEditingItem({ ...editingItem, unit: e.target.value })} /></td>
                                <td><Input type="number" placeholder="Unit Price" value={editingItem.unit_price} onChange={(e) => setEditingItem({ ...editingItem, unit_price: e.target.value })} /></td>
                                <td>{formatCurrency(editingItem.quantity * editingItem.unit_price)}</td>

                                {/* Add progress cell when editing if cost features enabled */}
                                {canAccessCostFeatures && <td></td>}

                                <td>
                                    <div className="flex gap-2">
                                        <Button size="sm" className={primaryButtonClasses} onClick={() => handleSaveItem(editingItem)}>
                                            Save
                                        </Button>
                                        <Button size="sm" className={secondaryButtonClasses} onClick={() => setEditingItem(null)}>
                                            Cancel
                                        </Button>
                                    </div>
                                </td>
                            </tr>
                        )}
                    </>
                )}
            </React.Fragment>
        );
    };

    return (
        <Card>
            <CardContent>
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-2xl font-bold">Line Items</h2>
                    <Button
                        onClick={() => setShowCategoryManager(true)}
                        className="bg-[#344bfc] text-white rounded-full"
                    >
                        Add Category
                    </Button>
                </div>

                {showCategoryManager && (
                    <CategoryManager
                        budgetId={budget.id}
                        onCategoriesUpdate={loadCategories}
                    />
                )}

                {Array.isArray(categories) && categories.length > 0 ? (
                    <div className="overflow-x-auto">
                        <Table>
                            <thead>
                                <tr>
                                    <th>Description</th>
                                    <th>Cost Code</th>
                                    <th>Quantity</th>
                                    <th>Unit</th>
                                    <th>Unit Price</th>
                                    <th>Total</th>
                                    {canAccessCostFeatures && <th>Progress</th>}
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(Array.isArray(categories) ? categories : []).map(category => renderCategory(category))}
                            </tbody>
                        </Table>
                    </div>
                ) : (
                    <div className="text-center py-4">
                        <p>No categories available for this budget.</p>
                    </div>
                )}

                {/* Progress Update Dialog */}
                {editingProgress && (
                    <Dialog open={!!editingProgress} onOpenChange={() => setEditingProgress(null)}>
                        <DialogContent>
                            <DialogHeader>
                                <DialogTitle>Update Progress</DialogTitle>
                                <DialogDescription>
                                    Update the completion percentage for "{editingProgress.description}"
                                </DialogDescription>
                            </DialogHeader>
                            <div className="py-4 space-y-4">
                                <div>
                                    <label className="text-sm font-medium">Progress Percentage: {editingProgress.progress_percentage}%</label>
                                    <Slider
                                        value={[editingProgress.progress_percentage]}
                                        min={0}
                                        max={100}
                                        step={1}
                                        className="mt-2"
                                        onValueChange={(value) => setEditingProgress({
                                            ...editingProgress,
                                            progress_percentage: value[0]
                                        })}
                                    />
                                </div>
                                <Progress value={editingProgress.progress_percentage} className="h-2" />
                                <div className="flex justify-between text-xs text-gray-500">
                                    <span>Not Started</span>
                                    <span>In Progress</span>
                                    <span>Complete</span>
                                </div>
                            </div>
                            <DialogFooter>
                                <Button variant="outline" onClick={() => setEditingProgress(null)}>
                                    Cancel
                                </Button>
                                <Button onClick={handleSaveProgress} disabled={loading}>
                                    {loading ? 'Saving...' : 'Save Progress'}
                                </Button>
                            </DialogFooter>
                        </DialogContent>
                    </Dialog>
                )}
            </CardContent>
        </Card>
    );
}