import React, { useState, useEffect, useContext } from 'react';
import { Button } from "../ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "../ui/cardui";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "../ui/tabs";
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Avatar, AvatarImage, AvatarFallback } from "../ui/avatar";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import { Input } from '../ui/input';
import { Alert, AlertDescription } from '../ui/alert';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue
} from '../ui/select';
import {
    Save,
    PlusCircle,
    FileUp,
    ChevronDown,
    DollarSign,
    Clock,
    TrendingUp,
    TrendingDown,
    RefreshCw
} from 'lucide-react';
import budgetService from '../../services/budgetService';
import { useToast } from '../ui/use-toast';
import { formatCurrency } from '../../utils/formatter';
import BudgetLineItems from './BudgetLineItems';
import BudgetActuals from './BudgetActuals';
import BudgetChangeOrders from './BudgetChangeOrders';
import BudgetSummary from './BudgetSummary';
import { Breadcrumb } from '../common/Breadcrumb';
import CostManagementDashboard from './CostManagementDashboard';
import EVMMetricsPanel from './EVMMetricsPanel';
import { SubscriptionContext } from '../Subscription/context/SubscriptionContext';

export default function BudgetDetail() {
    const { projectId, budgetId } = useParams();
    const navigate = useNavigate();
    const { toast } = useToast();
    const { checkFeature } = useContext(SubscriptionContext);

    const [budget, setBudget] = useState(null);
    const [loading, setLoading] = useState(true);
    const [activeTab, setActiveTab] = useState('summary');
    const [isEditing, setIsEditing] = useState(false);
    const [refreshing, setRefreshing] = useState(false);

    // Feature flags for cost management
    const canAccessBasicCost = checkFeature('basic_cost_management');
    const canAccessAdvancedCost = checkFeature('advanced_cost_management');
    const canAccessEVM = checkFeature('earned_value_management');

    useEffect(() => {
        loadBudget();
    }, [budgetId]);

    const loadBudget = async () => {
        try {
            setLoading(true);
            const budgetData = await budgetService.getBudgetById(budgetId);

            // Calculate total budget by summing up total_amount from line items within categories
            const calculatedTotalBudget = budgetData.categories.reduce((total, category) => {
                const categoryTotal = category.line_items.reduce(
                    (sum, item) => sum + parseFloat(item.total_amount || 0),
                    0
                );
                return total + categoryTotal;
            }, 0);

            // Update the budget state with the calculated total
            setBudget({
                ...budgetData,
                total_budget: calculatedTotalBudget,
            });
        } catch (error) {
            toast({
                title: "Error",
                description: "Failed to load budget details",
                variant: "destructive"
            });
            navigate(`/projects/${projectId}/budgets`);
        } finally {
            setLoading(false);
        }
    };

    const handleSave = async (updatedData) => {
        try {
            const updated = await budgetService.updateBudget(budgetId, {
                ...budget,
                ...updatedData
            });
            setBudget(updated);
            setIsEditing(false);
            toast({
                title: "Success",
                description: "Budget updated successfully"
            });
        } catch (error) {
            toast({
                title: "Error",
                description: "Failed to update budget",
                variant: "destructive"
            });
        }
    };

    const handleCreateFromEstimate = async (estimateId) => {
        try {
            const updated = await budgetService.createBudgetFromEstimate(budgetId, estimateId);
            setBudget(updated);
            toast({
                title: "Success",
                description: "Budget created from estimate successfully"
            });
        } catch (error) {
            toast({
                title: "Error",
                description: "Failed to create budget from estimate",
                variant: "destructive"
            });
        }
    };

    const handleRefreshData = async () => {
        try {
            setRefreshing(true);

            // Recalculate budget metrics
            if (canAccessBasicCost) {
                await budgetService.recalculateBudget(budgetId);
            }

            // Create a budget snapshot for trend analysis
            if (canAccessAdvancedCost) {
                await budgetService.createBudgetSnapshot(budgetId);
            }

            // Calculate EVM metrics
            if (canAccessEVM) {
                await budgetService.calculateEVMMetrics(projectId);
            }

            // Reload the budget
            await loadBudget();

            toast({
                title: "Success",
                description: "Budget data refreshed successfully"
            });
        } catch (error) {
            toast({
                title: "Error",
                description: "Failed to refresh budget data",
                variant: "destructive"
            });
        } finally {
            setRefreshing(false);
        }
    };

    if (loading) {
        return (
            <div className="space-y-6">
                <Breadcrumb />
                <div className="flex justify-center p-8">
                    <span className="loading loading-spinner" />
                </div>
            </div>
        );
    }

    return (
        <div className="space-y-6">
            <Breadcrumb />
            <Card>
                <CardHeader className="flex flex-row items-center justify-between">
                    <div>
                        <h2 className="text-2xl font-bold">{budget.name}</h2>
                        <p className="text-sm text-gray-500">
                            {budget.budget_type} Budget • {budget.status}
                        </p>
                    </div>
                    <div className="flex gap-2">
                        <Button
                            variant="outline"
                            onClick={() => setIsEditing(!isEditing)}
                        >
                            {isEditing ? 'Cancel' : 'Edit'}
                        </Button>
                        {isEditing && (
                            <Button onClick={() => handleSave(budget)}>
                                <Save className="w-4 h-4 mr-2" />
                                Save Changes
                            </Button>
                        )}
                        {canAccessBasicCost && (
                            <Button variant="outline" onClick={handleRefreshData} disabled={refreshing}>
                                <RefreshCw className={`w-4 h-4 mr-2 ${refreshing ? 'animate-spin' : ''}`} />
                                {refreshing ? 'Refreshing...' : 'Refresh Data'}
                            </Button>
                        )}
                        <Button variant="outline">
                            <FileUp className="w-4 h-4 mr-2" />
                            Import from Estimate
                        </Button>
                    </div>
                </CardHeader>

                <CardContent>
                    <div className="grid grid-cols-3 gap-4 mb-6">
                        <div className="p-4 bg-green-50 rounded-lg">
                            <div className="flex items-center gap-2 text-green-700 mb-1">
                                <DollarSign className="w-4 h-4" />
                                <span className="font-medium">Total Budget</span>
                            </div>
                            <div className="text-2xl font-bold text-green-900">
                                {formatCurrency(budget.total_budget)}
                            </div>
                        </div>

                        <div className="p-4 bg-blue-50 rounded-lg">
                            <div className="flex items-center gap-2 text-blue-700 mb-1">
                                <Clock className="w-4 h-4" />
                                <span className="font-medium">Total Actuals</span>
                            </div>
                            <div className="text-2xl font-bold text-blue-900">
                                {formatCurrency(budget.total_actuals)}
                            </div>
                        </div>

                        <div className="p-4 bg-purple-50 rounded-lg">
                            <div className="flex items-center gap-2 text-purple-700 mb-1">
                                <DollarSign className="w-4 h-4" />
                                <span className="font-medium">Remaining</span>
                            </div>
                            <div className="text-2xl font-bold text-purple-900">
                                {formatCurrency(budget.remaining_budget)}
                            </div>
                            {canAccessEVM && budget.cost_performance_index && (
                                <div className="mt-1 flex items-center">
                                    <span className={`text-sm ${budget.cost_performance_index >= 1 ? 'text-green-600' : 'text-red-600'}`}>
                                        CPI: {budget.cost_performance_index.toFixed(2)}
                                    </span>
                                    {budget.cost_performance_index >= 1 ? (
                                        <TrendingUp className="ml-1 w-3 h-3 text-green-600" />
                                    ) : (
                                        <TrendingDown className="ml-1 w-3 h-3 text-red-600" />
                                    )}
                                </div>
                            )}
                        </div>
                    </div>

                    <Tabs value={activeTab} onValueChange={setActiveTab}>
                        <TabsList>
                            <TabsTrigger value="summary">Summary</TabsTrigger>
                            <TabsTrigger value="line-items">Line Items</TabsTrigger>
                            <TabsTrigger value="actuals">Actuals</TabsTrigger>
                            <TabsTrigger value="change-orders">Change Orders</TabsTrigger>
                            {canAccessBasicCost && (
                                <TabsTrigger value="cost-management">Cost Management</TabsTrigger>
                            )}
                            {canAccessEVM && (
                                <TabsTrigger value="evm">Earned Value</TabsTrigger>
                            )}
                        </TabsList>

                        <TabsContent value="summary">
                            <BudgetSummary budget={budget} onRefresh={loadBudget} />
                        </TabsContent>

                        <TabsContent value="line-items">
                            <BudgetLineItems
                                budget={budget}
                                isEditing={isEditing}
                                onUpdate={loadBudget}
                                canAccessCostFeatures={canAccessBasicCost}
                            />
                        </TabsContent>

                        <TabsContent value="actuals">
                            <BudgetActuals
                                budget={budget}
                                onUpdate={loadBudget}
                            />
                        </TabsContent>

                        <TabsContent value="change-orders">
                            <BudgetChangeOrders
                                budget={budget}
                                onUpdate={loadBudget}
                            />
                        </TabsContent>

                        {canAccessBasicCost && (
                            <TabsContent value="cost-management">
                                <CostManagementDashboard
                                    projectId={projectId}
                                    budgetId={budgetId}
                                    onRefresh={loadBudget}
                                    canAccessAdvancedCost={canAccessAdvancedCost}
                                    canAccessEVM={canAccessEVM}
                                />
                            </TabsContent>
                        )}

                        {canAccessEVM && (
                            <TabsContent value="evm">
                                <EVMMetricsPanel
                                    projectId={projectId}
                                    budgetId={budgetId}
                                    onRefresh={loadBudget}
                                />
                            </TabsContent>
                        )}
                    </Tabs>
                </CardContent>
            </Card>
        </div>
    );
}