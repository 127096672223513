import React, { useState, useEffect } from 'react';
import { Button } from "../ui/button";
import { Card, CardContent, CardHeader } from "../ui/cardui";
import { useNavigate, Link } from 'react-router-dom';
import { Table } from '../ui/table';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../ui/select';
import { Alert, AlertDescription } from "../ui/alert";
import budgetService from '../../services/budgetService';
import { useToast } from '../ui/use-toast';
import { formatCurrency } from '../../utils/formatter';
import { Breadcrumb } from '../common/Breadcrumb';
import { primaryButtonClasses } from '../common/ButtonStyles';
import api from '../../services/api';
import { Plus, FileText, Trash2, AlertCircle } from 'lucide-react';
import { useFeatureAccess, FEATURES } from '../../contexts/FeatureAccessContext.js';

export default function BudgetList({ projectId, configureRequest }) {
    const [budgets, setBudgets] = useState([]);
    const [loading, setLoading] = useState(true);
    const [projects, setProjects] = useState([]);
    const [selectedProject, setSelectedProject] = useState(projectId || '');
    const { toast } = useToast();
    const navigate = useNavigate();
    const { hasFeatureAccess, userRole } = useFeatureAccess();

    // Use the same feature access check as in ProjectDetail component
    const canAccessBudgetManagement =
        hasFeatureAccess(FEATURES.PROJECT_MANAGEMENT) ||
        hasFeatureAccess(FEATURES.ALL_FEATURES) ||
        userRole === 'Tenant Admin Project';

    useEffect(() => {
        loadProjects();
    }, []);

    useEffect(() => {
        if (selectedProject) {
            loadBudgets(selectedProject);
        }
    }, [selectedProject]);

    const loadProjects = async () => {
        try {
            const response = await api.get('projects/', configureRequest ? configureRequest() : {});
            const activeProjects = response.data.results;
            setProjects(activeProjects);

            if (projectId && !activeProjects.find(p => p.id === projectId)) {
                setSelectedProject('');
            }

            // If no projectId is provided, select the first active project
            if (!projectId && activeProjects.length > 0) {
                setSelectedProject(activeProjects[0].id);
            }
        } catch (error) {
            toast({
                title: "Error",
                description: "Failed to load projects",
                variant: "destructive"
            });
        }
    };

    const loadBudgets = async (projectId) => {
        try {
            setLoading(true);
            const data = await budgetService.getAllBudgets(projectId);
            setBudgets(data.results);
        } catch (error) {
            toast({
                title: "Error",
                description: "Failed to load budgets",
                variant: "destructive"
            });
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async (budgetId) => {
        if (!window.confirm('Are you sure you want to delete this budget?')) return;

        try {
            await budgetService.deleteBudget(budgetId);
            setBudgets(budgets.filter(budget => budget.id !== budgetId));
            toast({
                title: "Success",
                description: "Budget deleted successfully"
            });
        } catch (error) {
            toast({
                title: "Error",
                description: "Failed to delete budget",
                variant: "destructive"
            });
        }
    };

    const handleCreateProject = () => {
        navigate('/projects/');
    };

    // Use the same subscription check message as in ProjectDetail component
    if (!canAccessBudgetManagement) {
        return (
            <Alert>
                <AlertDescription>
                    Please upgrade your subscription to access project management features.
                    <a href="/subscription" className="text-blue-600 hover:text-blue-800 ml-2">
                        Upgrade now
                    </a>
                </AlertDescription>
            </Alert>
        );
    }

    if (projects.length === 0) {
        return (
            <div className="space-y-6">
                <Breadcrumb />
                <Card className="w-full">
                    <CardContent className="pt-6">
                        <Alert>
                            <AlertCircle className="h-4 w-4" />
                            <AlertDescription>
                                You need to create an active project before you can manage budgets.
                            </AlertDescription>
                        </Alert>
                        <div className="mt-4 flex justify-center">
                            <Button onClick={handleCreateProject} className={primaryButtonClasses}>
                                Create New Project
                            </Button>
                        </div>
                    </CardContent>
                </Card>
            </div>
        );
    }

    return (
        <div className="space-y-6">
            <Breadcrumb />
            <Card className="w-full">
                <CardHeader className="flex flex-row items-center justify-between">
                    <h2 className="text-2xl font-bold">Project Budgets</h2>
                    <div className="flex items-center gap-4">
                        <Select
                            value={selectedProject}
                            onValueChange={(value) => setSelectedProject(value)}
                        >
                            <SelectTrigger className="w-56">
                                <SelectValue placeholder="Select Project" />
                            </SelectTrigger>
                            <SelectContent>
                                {projects.map((project) => (
                                    <SelectItem key={project.id} value={project.id}>
                                        {project.name}
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                        {selectedProject && (
                            <Link to={`/projects/${selectedProject}/budgets/new`}>
                                <Button className={primaryButtonClasses}>New Budget</Button>
                            </Link>
                        )}
                    </div>
                </CardHeader>
                <CardContent>
                    {loading ? (
                        <div className="flex justify-center p-4">
                            <span className="loading loading-spinner" />
                        </div>
                    ) : (
                        <Table>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Type</th>
                                    <th>Status</th>
                                    <th>Total Budget</th>
                                    <th>Created</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {budgets.map((budget) => (
                                    <tr key={budget.id}>
                                        <td>
                                            <Link
                                                to={`/projects/${selectedProject}/budgets/${budget.id}${budget.budget_type === 'ADVANCED' ? '/advanced' : '/basic'}`}
                                                className="text-blue-600 hover:text-blue-800"
                                            >
                                                {budget.name}
                                            </Link>
                                        </td>
                                        <td>{budget.budget_type}</td>
                                        <td>
                                            <span className={`px-2 py-1 rounded-full text-sm ${budget.status === 'ACTIVE' ? 'bg-green-100 text-green-800' :
                                                budget.status === 'CLOSED' ? 'bg-gray-100 text-gray-800' :
                                                    'bg-yellow-100 text-yellow-800'
                                                }`}>
                                                {budget.status}
                                            </span>
                                        </td>
                                        <td>{formatCurrency(budget.total_budget)}</td>
                                        <td>{new Date(budget.created_at).toLocaleDateString()}</td>
                                        <td>
                                            <div className="flex gap-2">
                                                <Link
                                                    to={`/projects/${selectedProject}/budgets/${budget.id}${budget.budget_type === 'ADVANCED' ? '/advanced' : '/basic'}`}
                                                    className="p-2 hover:bg-gray-100 rounded-full"
                                                >
                                                    <FileText className="w-4 h-4" />
                                                </Link>
                                                <button
                                                    onClick={() => handleDelete(budget.id)}
                                                    className="p-2 hover:bg-red-100 rounded-full text-red-600"
                                                >
                                                    <Trash2 className="w-4 h-4" />
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                                {budgets.length === 0 && (
                                    <tr>
                                        <td colSpan="6" className="text-center py-4">
                                            No budgets found for this project.
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    )}
                </CardContent>
            </Card>
        </div>
    );
}