import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import api from '../services/api';
import { showToast } from '../utils/toast';
import { logout } from '../services/auth';
import CancellationDialog from './CancellationDialog';

const BasicInfoSettings = ({ user, onUpdateUser }) => {
  const [isUploading, setIsUploading] = useState(false);
  const [isTwoFactorEnabled, setIsTwoFactorEnabled] = useState(user?.two_factor_enabled || false);
  const defaultProfileImage = 'https://www.gravatar.com/avatar/?d=mp';
  const [profileImageSrc, setProfileImageSrc] = useState(user?.profile_picture || defaultProfileImage);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const { register, handleSubmit, setValue } = useForm({
    defaultValues: {
      // profile_picture: user?.profile_picture || '',
      first_name: user?.first_name || '',
      last_name: user?.last_name || '',
      email: user?.email || '',
    }
  });

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await api.get('users/profile/', {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });
        setIsTwoFactorEnabled(response.data.two_factor_enabled);
        setProfileImageSrc(response.data.profile_picture || defaultProfileImage);
      } catch (error) {
        console.error('Failed to fetch user data:', error);
      }
    };

    fetchUserData();
  }, []);

  const handleProfilePictureUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    // Validate file type
    const validTypes = ['image/jpeg', 'image/png'];
    if (!validTypes.includes(file.type)) {
      showToast.error('Invalid file type. Please upload a JPEG or PNG image.');
      return;
    }

    // Validate file size (e.g., max 5 MB)
    const maxSizeInBytes = 5 * 1024 * 1024; // 5 MB
    if (file.size > maxSizeInBytes) {
      showToast.error('File size exceeds the 5MB limit.');
      return;
    }

    setIsUploading(true);
    const formData = new FormData();
    formData.append('profile_picture', file);

    try {
      const response = await api.patch('users/profile/', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      const updatedUser = response.data;
      setProfileImageSrc(updatedUser.profile_picture);
      showToast.success('Profile picture updated successfully');
      onUpdateUser(updatedUser);
    } catch (error) {
      showToast.error('Failed to upload profile picture');
    } finally {
      setIsUploading(false);
    }
  };


  const onSubmit = async (data) => {
    try {
      const response = await api.patch('users/profile/', data);
      showToast.success('Profile updated successfully');
      onUpdateUser(response.data);
    } catch (error) {
      // Parse and show specific validation errors
      if (error.response?.data?.validation_errors) {
        Object.entries(error.response.data.validation_errors).forEach(([field, messages]) => {
          console.error(`Field ${field}: ${messages.join(', ')}`);
        });
      } else {
        showToast.error('Failed to update profile');
      }
    }
  };

  const handleEnableTwoFactor = async () => {
    try {
      const response = await api.post('enable-two-factor/', {}, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`, // Include token here
        },
      });

      // Fetch updated user data to refresh the state
      const userResponse = await api.get('users/profile/', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });

      // Update the two-factor authentication state

      setIsTwoFactorEnabled(userResponse.data.two_factor_enabled);
      onUpdateUser(userResponse.data); // Update the user in the parent component if needed

      showToast.success(response.data.detail || 'Two-factor authentication enabled successfully');
    } catch (error) {
      showToast.error(
        error.response?.data?.detail || 'Failed to enable two-factor authentication. Please try again.'
      );
    }
  };

  const handleDisableTwoFactor = async () => {
    try {
      const response = await api.post('disable-two-factor/');
      setIsTwoFactorEnabled(false);
      showToast.success(response.data.detail || 'Two-factor authentication disabled successfully');
    } catch (error) {
      showToast.error(
        error.response?.data?.detail || 'Failed to disable two-factor authentication. Please try again.'
      );
    }
  };

  const handleCancellation = async (cancellationDetails) => {
    try {
      await api.post('/cancel-subscription/', cancellationDetails);

      if (cancellationDetails.is_account_deletion) {
        showToast.success('Your account has been deleted');
        logout();
      } else {
        showToast.success('Subscription cancelled successfully');
        // Refresh subscription data or redirect as needed
      }
    } catch (error) {
      showToast.error(error.response?.data?.error || 'Failed to process cancellation');
    }
  };


  const handleDeleteAccount = () => {
    setShowDeleteDialog(true);
  };

  return (
    <div className="space-y-8 p-6 bg-white rounded-lg shadow-md">
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
        {/* Profile Picture Section */}
        <div>
          <h2 className="text-xl font-semibold text-gray-800 mb-2">Profile Picture</h2>
          <div className="flex items-center space-x-4">
            <img
              src={profileImageSrc}
              alt="Profile"
              className="w-20 h-20 rounded-full object-cover border border-gray-300"
            />
            <label htmlFor="profile-picture-upload" className="cursor-pointer bg-gray-200 py-2 px-4 rounded-full shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-300">
              {isUploading ? 'Uploading...' : 'Upload Picture'}
            </label>
            <input
              type="file"
              accept="image/*"
              id="profile-picture-upload"
              onChange={handleProfilePictureUpload}
              className="hidden"
            />
          </div>
        </div>

        {/* User Info Section */}
        <div>
          <h2 className="text-xl font-semibold text-gray-800 mb-2">Full Name</h2>
          <div className="grid grid-cols-2 gap-4">
            <input
              {...register('first_name')}
              placeholder="First Name"
              className="w-full border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-400"
            />
            <input
              {...register('last_name')}
              placeholder="Last Name"
              className="w-full border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-400"
            />
          </div>
          <h2 className="text-xl font-semibold text-gray-800 mt-6 mb-2">Email</h2>
          <input
            {...register('email')}
            type="email"
            placeholder="Email"
            className="w-full border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-400"
          />
        </div>

        {/* Submit Button */}
        <button
          type="submit"
          className="py-2 px-6 bg-[#344bfc] text-white font-semibold rounded-full shadow-md hover:bg-[#2d41d1] transition duration-200"
        >
          Save Changes
        </button>
      </form>

      {/* Two-Factor Authentication */}
      {isTwoFactorEnabled ? (
        <>
          <p className="text-green-600">Two-factor authentication is enabled.</p>
          <button
            className="py-2 px-6 bg-red-600 text-white font-semibold rounded-full shadow-md hover:bg-red-700 transition duration-200"
            onClick={handleDisableTwoFactor}
          >
            Disable Two-Factor Authentication
          </button>
        </>
      ) : (
        <button
          className="py-2 px-6 bg-[#344bfc] text-white font-semibold rounded-full shadow-md hover:bg-[#2d41d1] transition duration-200"
          onClick={handleEnableTwoFactor}
        >
          Enable Two-Factor Authentication
        </button>
      )}


      {/* Delete Account */}
      <div>
        <h2 className="text-xl font-semibold text-gray-800 mb-2">Delete Account</h2>
        <p className="text-sm text-gray-600 mb-4">Once you delete your account, there is no going back. Please be certain.</p>
        <button
          onClick={handleDeleteAccount}
          className="py-2 px-6 bg-red-600 text-white font-semibold rounded-full shadow-md hover:bg-red-700 transition duration-200"
        >
          Delete My Account
        </button>
      </div>

      <CancellationDialog
        isOpen={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
        onConfirm={handleCancellation}
        title="Delete Account"
        description="Are you sure you want to delete your account? This will permanently delete all your data and cancel any active subscriptions."
        confirmButtonText="Delete Account"
        confirmButtonClass="bg-red-600 hover:bg-red-700"
        isAccountDeletion={true}
      />
    </div>
  );
};

export default BasicInfoSettings;
