import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Card, CardHeader, CardTitle, CardContent } from '../../../../components/ui/cardui';
import { Button } from '../../../../components/ui/button';
import { Input } from '../../../../components/ui/input';
import { Textarea } from '../../../../components/ui/textarea';
import { Label } from '../../../../components/ui/label';
import { useToast } from '../../../../components/ui/use-toast';
import { FileText, Upload } from 'lucide-react';
import api from '../../../../services/api';
import { primaryButtonClasses } from '../../../common/ButtonStyles';
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogTrigger } from "../../../../components/ui/alert-dialog";
import BidDocumentSection from '../BidDocumentSection';
import BidDocumentUpload from '../BidDocumentUpload';

const BidSubmissionForm = () => {
    const { bidId } = useParams();
    const navigate = useNavigate();
    const { toast } = useToast();
    const [loading, setLoading] = useState(true);
    const [bidDetails, setBidDetails] = useState(null);
    const [documents, setDocuments] = useState([]);
    const [documentTypes, setDocumentTypes] = useState([]);
    const [bidRequest, setBidRequest] = useState(null);
    const [bidSubmission, setBidSubmission] = useState(null);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [userRole, setUserRole] = useState(null);

    const [formData, setFormData] = useState({
        total_price: '',
        timeline: '',
        scope: '',
        methodology: '',
        team: [],
        assumptions: '',
        exclusions: '',
        documents: []
    });

    useEffect(() => {
        fetchBidDetails();
    }, [bidId]);

    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                const response = await api.get('users/profile/');
                setUserRole(response.data.role);
            } catch (error) {
                console.error('Error fetching user profile:', error);
                toast({
                    title: "Error",
                    description: "Failed to load user profile",
                    variant: "destructive"
                });
            }
        };

        fetchUserProfile();
    }, []);

    const fetchBidDetails = async () => {
        try {
            setLoading(true);
            const [bidResponse, docTypesResponse] = await Promise.all([
                api.get(`bid-submitter/${bidId}/`),
                api.get('bid-document-types/')
            ]);


            setBidDetails(bidResponse.data);
            setBidRequest(bidResponse.data);
            setDocumentTypes(docTypesResponse.data);

            // Check if there's a submission ID in the bid response
            const submissionId = bidResponse.data.submission?.id;

            if (submissionId) {
                try {
                    // Fetch the specific submission details
                    const submissionResponse = await api.get(`bid-submissions/${submissionId}/`);
                    const submission = submissionResponse.data;


                    setBidSubmission(submission);

                    // Update form data with submission details
                    setFormData({
                        total_price: submission.total_price?.toString() || '',
                        timeline: submission.timeline?.toString() || '',
                        scope: submission.scope || '',
                        methodology: submission.methodology || '',
                        team: submission.team || [],
                        assumptions: submission.assumptions || '',
                        exclusions: submission.exclusions || '',
                        documents: submission.documents || []
                    });

                    // Fetch documents using the new endpoint
                    try {
                        const documentsResponse = await api.get(`bid-submitter/${bidId}/submission-documents/`);


                        if (documentsResponse.data) {
                            const formattedDocuments = documentsResponse.data.map(doc => ({
                                id: doc.id,
                                name: doc.title || doc.name,
                                documentType: doc.document_type,
                                status: 'uploaded',
                                file: null,
                                url: doc.file_url
                            }));


                            setDocuments(formattedDocuments);
                        }
                    } catch (documentsError) {
                        console.error('Error fetching submission documents:', documentsError);
                        toast({
                            title: "Warning",
                            description: "Failed to load submission documents",
                            variant: "destructive"
                        });
                    }
                } catch (submissionError) {
                    console.error('Error fetching submission details:', submissionError);
                    toast({
                        title: "Warning",
                        description: "Failed to load submission details",
                        variant: "destructive"
                    });
                }
            }
        } catch (error) {
            console.error('Error:', error);
            toast({
                title: "Error",
                description: "Failed to load bid details",
                variant: "destructive"
            });
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = async () => {
        try {
            const url = bidSubmission ? `bid-submissions/${bidSubmission.id}/` : 'bid-submissions/';
            const method = bidSubmission ? 'put' : 'post';

            const formDataToSend = new FormData();
            formDataToSend.append('bid_request', bidId);

            Object.keys(formData).forEach(key => {
                if (key === 'team') {
                    formDataToSend.append('team', JSON.stringify(formData.team));
                } else if (key !== 'documents') {
                    formDataToSend.append(key, formData[key]);
                }
            });

            const bidResponse = await api[method](url, formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            const submissionId = bidSubmission?.id || bidResponse.data.id;

            if (documents.length > 0) {
                const newDocuments = documents.filter(doc => doc.file && !doc.id);

                for (const doc of newDocuments) {
                    if (!doc.documentType) continue;

                    const docFormData = new FormData();
                    docFormData.append('file', doc.file);
                    docFormData.append('bid', bidId);
                    docFormData.append('submission', submissionId);
                    docFormData.append('document_type_id', doc.documentType);
                    docFormData.append('owner_type', 'submission');
                    docFormData.append('title', doc.name);
                    docFormData.append('file_type', 'file');

                    try {
                        await api.post('bid-documents/', docFormData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        });

                    } catch (uploadError) {
                        console.error('Failed to upload document:', doc.name, uploadError);
                        toast({
                            title: "Warning",
                            description: `Failed to upload ${doc.name}`,
                            variant: "destructive"
                        });
                    }
                }
            }

            toast({
                title: "Success",
                description: bidSubmission ? "Bid updated successfully" : "Bid submitted successfully"
            });
            navigate('/my-bids');

        } catch (error) {
            console.error('Submission error:', error);
            toast({
                title: "Error",
                description: error.response?.data?.error || "Failed to submit bid",
                variant: "destructive"
            });
        }
    };

    if (loading) return <div>Loading...</div>;

    return (
        <Card>
            <CardHeader>
                <CardTitle>
                    {bidSubmission ? 'Edit Bid Submission' : 'Submit Bid'} for {bidDetails?.title}
                </CardTitle>
            </CardHeader>
            <CardContent>
                <form onSubmit={(e) => {
                    e.preventDefault();
                    setShowConfirmDialog(true);
                }} className="space-y-6">
                    <div className="grid grid-cols-2 gap-4">
                        <div>
                            <Label htmlFor="total_price">Total Price ($)</Label>
                            <Input
                                id="total_price"
                                type="number"
                                value={formData.total_price}
                                onChange={(e) => setFormData({ ...formData, total_price: e.target.value })}
                                required
                            />
                        </div>
                        <div>
                            <Label htmlFor="timeline">Timeline (days)</Label>
                            <Input
                                id="timeline"
                                type="number"
                                value={formData.timeline}
                                onChange={(e) => setFormData({ ...formData, timeline: e.target.value })}
                                required
                            />
                        </div>
                    </div>

                    <div>
                        <Label htmlFor="scope">Scope of Work</Label>
                        <Textarea
                            id="scope"
                            value={formData.scope}
                            onChange={(e) => setFormData({ ...formData, scope: e.target.value })}
                            required
                            rows={4}
                        />
                    </div>

                    <div>
                        <Label htmlFor="methodology">Methodology</Label>
                        <Textarea
                            id="methodology"
                            value={formData.methodology}
                            onChange={(e) => setFormData({ ...formData, methodology: e.target.value })}
                            required
                            rows={4}
                        />
                    </div>

                    <div>
                        <Label htmlFor="assumptions">Assumptions</Label>
                        <Textarea
                            id="assumptions"
                            value={formData.assumptions}
                            onChange={(e) => setFormData({ ...formData, assumptions: e.target.value })}
                            rows={3}
                        />
                    </div>

                    <div>
                        <Label htmlFor="exclusions">Exclusions</Label>
                        <Textarea
                            id="exclusions"
                            value={formData.exclusions}
                            onChange={(e) => setFormData({ ...formData, exclusions: e.target.value })}
                            rows={3}
                        />
                    </div>
                    <div onClick={e => e.stopPropagation()}>
                        <BidDocumentUpload
                            documents={documents}
                            setDocuments={setDocuments}
                            documentTypes={documentTypes}
                            bidRequestId={bidRequest.id}
                        />
                    </div>

                    <div className="flex justify-end space-x-4">
                        <Button variant="outline" onClick={() => navigate('/my-bids')}>
                            Cancel
                        </Button>
                        <Button className={primaryButtonClasses} type="submit">
                            Submit Bid
                        </Button>
                    </div>
                </form>
                <AlertDialog open={showConfirmDialog} onOpenChange={setShowConfirmDialog}>
                    <AlertDialogContent>
                        <AlertDialogHeader>
                            <AlertDialogTitle>Confirm Submission</AlertDialogTitle>
                            <AlertDialogDescription>
                                <div className="space-y-2">
                                    <p>Total Price: ${formData.total_price}</p>
                                    <p>Timeline: {formData.timeline} days</p>
                                    <p>Documents: {documents.length} files uploaded</p>
                                </div>
                            </AlertDialogDescription>
                        </AlertDialogHeader>
                        <AlertDialogFooter>
                            <AlertDialogCancel>Cancel</AlertDialogCancel>
                            <AlertDialogAction onClick={handleSubmit}>
                                {bidSubmission ? 'Update' : 'Submit'}
                            </AlertDialogAction>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialog>
            </CardContent>
        </Card>
    );
};

export default BidSubmissionForm;