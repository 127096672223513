import React, { useState, useEffect } from 'react';
import {
    QuestionMarkCircleIcon,
    PlusIcon,
    ClockIcon,
    DocumentTextIcon,
    PaperClipIcon
} from '@heroicons/react/24/outline';
import { useForm } from 'react-hook-form';
import api from '../../services/api';
import { showToast } from '../../utils/toast';
import { primaryButtonClasses, secondaryButtonClasses } from '../common/ButtonStyles';

const ContractorRFIs = () => {
    const [rfis, setRFIs] = useState([]);
    const [projects, setProjects] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [selectedRFI, setSelectedRFI] = useState(null);
    const [selectedFiles, setSelectedFiles] = useState([]);

    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    useEffect(() => {
        fetchRFIs();
        fetchProjects();
    }, []);

    const fetchRFIs = async () => {
        try {
            setIsLoading(true);
            const response = await api.get('contractor/rfis/');


            // Ensure we're setting an array
            if (response.data && !Array.isArray(response.data)) {
                // If response.data.results exists, use that (common in DRF pagination)
                if (response.data.results && Array.isArray(response.data.results)) {
                    setRFIs(response.data.results);
                } else {
                    // If it's an object, convert to array or set empty array
                    setRFIs(Object.values(response.data) || []);
                }
            } else {
                // If it's already an array, use it directly
                setRFIs(response.data || []);
            }
        } catch (error) {
            console.error('Error fetching RFIs:', error);
            showToast.error('Failed to load RFIs');
            // Set empty array on error
            setRFIs([]);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchProjects = async () => {
        try {
            const response = await api.get('contractor/projects/');
            setProjects(Array.isArray(response.data.results) ? response.data.results : []);
        } catch (error) {
            console.error('Error fetching projects:', error);
        }
    };

    const handleFileChange = (e) => {
        setSelectedFiles(Array.from(e.target.files));
    };

    const onSubmit = async (data) => {
        try {
            const formData = new FormData();
            formData.append('project_id', data.project_id);
            formData.append('title', data.title);
            formData.append('description', data.description);
            formData.append('response_needed_by', data.response_needed_by);

            selectedFiles.forEach((file) => {
                formData.append('attachments', file);
            });

            await api.post('contractor/rfis/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            showToast.success('RFI created successfully');
            setShowCreateModal(false);
            reset();
            setSelectedFiles([]);
            fetchRFIs();
        } catch (error) {
            console.error('Error creating RFI:', error);
            showToast.error('Failed to create RFI');
        }
    };

    return (
        <div className="p-6">
            <div className="flex justify-between items-center mb-6">
                <h1 className="text-2xl font-bold text-gray-900">RFI Management</h1>
                <button
                    onClick={() => setShowCreateModal(true)}
                    className={primaryButtonClasses}
                >
                    <PlusIcon className="h-5 w-5 mr-2" />
                    Create New RFI
                </button>
            </div>

            {/* RFI List */}
            <div className="space-y-4">
                {isLoading ? (
                    <div className="flex justify-center items-center h-48">
                        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
                    </div>
                ) : !Array.isArray(rfis) || rfis.length === 0 ? (
                    <div className="text-center py-12 bg-gray-50 rounded-lg">
                        <QuestionMarkCircleIcon className="mx-auto h-12 w-12 text-gray-400" />
                        <p className="mt-2 text-gray-500">No RFIs found</p>
                    </div>
                ) : (
                    rfis.map((rfi) => (
                        <div key={rfi.id} className="bg-white rounded-lg shadow p-6">
                            <div className="flex justify-between items-start">
                                <div>
                                    <div className="flex items-center gap-2">
                                        <span className={`px-2 py-1 text-xs font-medium rounded-full ${rfi.status === 'open' ? 'bg-yellow-100 text-yellow-800' :
                                            rfi.status === 'answered' ? 'bg-green-100 text-green-800' :
                                                'bg-gray-100 text-gray-800'
                                            }`}>
                                            {rfi.status.toUpperCase()}
                                        </span>
                                        <span className="text-sm text-gray-500">RFI #{rfi.number}</span>
                                    </div>
                                    <h3 className="font-medium text-gray-900 mt-2">{rfi.title}</h3>
                                    <p className="text-sm text-gray-500 mt-1">{rfi.description}</p>
                                </div>
                                <button
                                    onClick={() => setSelectedRFI(rfi)}
                                    className="text-blue-600 hover:text-blue-800"
                                >
                                    View Details
                                </button>
                            </div>

                            <div className="mt-4 flex items-center gap-4 text-sm text-gray-500">
                                <span className="flex items-center">
                                    <ClockIcon className="h-4 w-4 mr-1" />
                                    Response needed by: {new Date(rfi.response_needed_by).toLocaleDateString()}
                                </span>
                                {rfi.attachments_count > 0 && (
                                    <span className="flex items-center">
                                        <PaperClipIcon className="h-4 w-4 mr-1" />
                                        {rfi.attachments_count} attachment(s)
                                    </span>
                                )}
                            </div>
                        </div>
                    ))
                )}
            </div>

            {/* Create RFI Modal */}
            {showCreateModal && (
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4">
                    <div className="bg-white rounded-lg shadow-xl max-w-2xl w-full p-6">
                        <h2 className="text-xl font-semibold mb-4">Create New RFI</h2>
                        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Project</label>
                                <select
                                    {...register('project_id', { required: 'Project is required' })}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                >
                                    <option value="">Select a project</option>
                                    {projects.map(project => (
                                        <option key={project.id} value={project.id}>{project.name}</option>
                                    ))}
                                </select>
                                {errors.project_id && (
                                    <p className="mt-1 text-sm text-red-600">{errors.project_id.message}</p>
                                )}
                            </div>

                            <div>
                                <label className="block text-sm font-medium text-gray-700">Title</label>
                                <input
                                    type="text"
                                    {...register('title', { required: 'Title is required' })}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                />
                                {errors.title && (
                                    <p className="mt-1 text-sm text-red-600">{errors.title.message}</p>
                                )}
                            </div>

                            <div>
                                <label className="block text-sm font-medium text-gray-700">Description</label>
                                <textarea
                                    {...register('description', { required: 'Description is required' })}
                                    rows={4}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                />
                                {errors.description && (
                                    <p className="mt-1 text-sm text-red-600">{errors.description.message}</p>
                                )}
                            </div>

                            <div>
                                <label className="block text-sm font-medium text-gray-700">Response Needed By</label>
                                <input
                                    type="date"
                                    {...register('response_needed_by', { required: 'Date is required' })}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                />
                                {errors.response_needed_by && (
                                    <p className="mt-1 text-sm text-red-600">{errors.response_needed_by.message}</p>
                                )}
                            </div>

                            <div>
                                <label className="block text-sm font-medium text-gray-700">Attachments</label>
                                <input
                                    type="file"
                                    multiple
                                    onChange={handleFileChange}
                                    className="mt-1 block w-full text-sm text-gray-500
                    file:mr-4 file:py-2 file:px-4
                    file:rounded-full file:border-0
                    file:text-sm file:font-semibold
                    file:bg-blue-50 file:text-blue-700
                    hover:file:bg-blue-100"
                                />
                            </div>

                            <div className="flex justify-end space-x-3 mt-6">
                                <button
                                    type="button"
                                    onClick={() => {
                                        setShowCreateModal(false);
                                        reset();
                                        setSelectedFiles([]);
                                    }}
                                    className={secondaryButtonClasses}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className={primaryButtonClasses}
                                >
                                    Create RFI
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}

            {/* RFI Detail Modal */}
            {selectedRFI && (
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4">
                    <div className="bg-white rounded-lg shadow-xl max-w-2xl w-full p-6">
                        <div className="flex justify-between items-start mb-4">
                            <h2 className="text-xl font-semibold">RFI Details</h2>
                            <button
                                onClick={() => setSelectedRFI(null)}
                                className="text-gray-400 hover:text-gray-500"
                            >
                                <span className="sr-only">Close</span>
                                ×
                            </button>
                        </div>

                        <div className="space-y-4">
                            <div>
                                <h3 className="text-sm font-medium text-gray-500">Status</h3>
                                <p className="mt-1">{selectedRFI.status.toUpperCase()}</p>
                            </div>

                            <div>
                                <h3 className="text-sm font-medium text-gray-500">Title</h3>
                                <p className="mt-1">{selectedRFI.title}</p>
                            </div>

                            <div>
                                <h3 className="text-sm font-medium text-gray-500">Description</h3>
                                <p className="mt-1">{selectedRFI.description}</p>
                            </div>

                            {selectedRFI.response && (
                                <div>
                                    <h3 className="text-sm font-medium text-gray-500">Response</h3>
                                    <p className="mt-1">{selectedRFI.response}</p>
                                </div>
                            )}

                            {selectedRFI.attachments?.length > 0 && (
                                <div>
                                    <h3 className="text-sm font-medium text-gray-500">Attachments</h3>
                                    <ul className="mt-2 divide-y divide-gray-200">
                                        {selectedRFI.attachments.map((attachment) => (
                                            <li key={attachment.id} className="py-2 flex items-center justify-between">
                                                <div className="flex items-center">
                                                    <DocumentTextIcon className="h-5 w-5 text-gray-400 mr-2" />
                                                    <span className="text-sm text-gray-900">{attachment.filename}</span>
                                                </div>
                                                <button
                                                    onClick={() => window.open(attachment.url, '_blank')}
                                                    className="text-blue-600 hover:text-blue-800 text-sm"
                                                >
                                                    Download
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ContractorRFIs;