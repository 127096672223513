export const formatCurrency = (amount, currency = 'USD') => {
    try {
        const value = parseFloat(amount);
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: currency,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(value);
    } catch (error) {
        return `${currency} 0.00`;
    }
};

export const formatDate = (date) => {
    try {
        return new Date(date).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        });
    } catch (error) {
        return '';
    }
};

export const formatNumber = (number) => {
    try {
        return new Intl.NumberFormat('en-US').format(number);
    } catch (error) {
        return '0';
    }
};

export const formatPercentage = (value) => {
    try {
        return `${parseFloat(value).toFixed(1)}%`;
    } catch (error) {
        return '0%';
    }
};

export const formatFileSize = (bytes) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`;
};

export const shortenNumber = (num) => {
    const units = ['', 'K', 'M', 'B', 'T'];
    const order = Math.floor(Math.log10(Math.abs(num)) / 3);
    if (order < 0) return num;

    const unit = units[order];
    const value = num / Math.pow(10, order * 3);

    return value.toFixed(1) + unit;
};

export const formatDuration = (minutes) => {
    if (minutes < 60) return `${minutes}m`;
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return remainingMinutes ? `${hours}h ${remainingMinutes}m` : `${hours}h`;
};