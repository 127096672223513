import React, { useEffect, useState } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from "../../components/ui/cardui";
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

// Register ChartJS components
ChartJS.register(ArcElement, Tooltip, Legend);

// Define a color palette for categories
const colorPalette = [
    '#4e73df', // Primary blue
    '#1cc88a', // Success green
    '#36b9cc', // Info cyan
    '#f6c23e', // Warning yellow
    '#fd7e14', // Orange
    '#e74a3b', // Danger red
    '#5a5c69', // Gray
    '#2c9faf', // Light blue
    '#6f42c1', // Purple
    '#20c997', // Teal
    '#6610f2', // Indigo
    '#f8f9fa', // Light
    '#858796', // Secondary gray
    '#f39c12', // Amber
    '#3498db'  // Sky blue
];

const RiskCategoryDistribution = ({ risks = [], categories = [] }) => {
    const [chartData, setChartData] = useState({
        labels: [],
        datasets: []
    });

    useEffect(() => {
        if (!Array.isArray(risks) || risks.length === 0) {
            setChartData({
                labels: ['No Data'],
                datasets: [{
                    data: [1],
                    backgroundColor: ['#e0e0e0'],
                    borderWidth: 1
                }]
            });
            return;
        }

        // Create a map of category IDs to their names
        const categoryMap = {};

        // Ensure categories is an array before iterating
        if (Array.isArray(categories)) {
            categories.forEach(category => {
                if (category && category.id) {
                    categoryMap[category.id] = category.name;
                }
            });
        }

        // Count risks by category
        const categoryCounts = {};

        risks.forEach(risk => {
            // Safely access category information
            const categoryName =
                (risk.category_name) ||
                (risk.category && categoryMap[risk.category]) ||
                'Uncategorized';

            if (categoryCounts[categoryName]) {
                categoryCounts[categoryName]++;
            } else {
                categoryCounts[categoryName] = 1;
            }
        });

        // Prepare labels and data for chart
        const labels = Object.keys(categoryCounts);
        const data = Object.values(categoryCounts);

        // Assign colors from palette (cycling if needed)
        const backgroundColor = labels.map((_, index) =>
            colorPalette[index % colorPalette.length]
        );

        // Set chart data
        setChartData({
            labels,
            datasets: [{
                data,
                backgroundColor,
                borderColor: backgroundColor,
                borderWidth: 1
            }]
        });
    }, [risks, categories]);

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'right',
                labels: {
                    boxWidth: 15,
                    padding: 10,
                    font: {
                        size: 11
                    }
                }
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        const label = context.label || '';
                        const value = context.raw || 0;
                        const total = context.dataset.data.reduce((a, b) => a + b, 0);
                        const percentage = Math.round((value / total) * 100);
                        return `${label}: ${value} (${percentage}%)`;
                    }
                }
            }
        },
        cutout: '60%'
    };

    return (
        <Card className="h-full">
            <CardHeader>
                <CardTitle>Risk Category Distribution</CardTitle>
            </CardHeader>
            <CardContent>
                <div className="h-72">
                    <Doughnut data={chartData} options={options} />
                </div>
            </CardContent>
        </Card>
    );
};

export default RiskCategoryDistribution;