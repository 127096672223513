import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "../../ui/cardui";
import { Button } from "../../ui/button";
import { Input } from "../../ui/input";
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogFooter,
} from "../../ui/dialog";
import { Badge } from "../../ui/badge";
import { Plus, Flag, Calendar } from "lucide-react";
import { useToast } from "../../ui/use-toast";
import api from '../../../services/api';
import { primaryButtonClasses, secondaryButtonClasses } from '../../common/ButtonStyles';

const BasicMilestoneTracker = ({ projectId }) => {
    const [milestones, setMilestones] = useState([]);
    const [showAddDialog, setShowAddDialog] = useState(false);
    const [loading, setLoading] = useState(true);
    const { toast } = useToast();
    const [newMilestone, setNewMilestone] = useState({
        name: '',
        due_date: '',
        description: ''
    });

    useEffect(() => {
        loadMilestones();
    }, [projectId]);

    const loadMilestones = async () => {
        try {
            setLoading(true);
            const response = await api.get(`projects/${projectId}/milestones/`);
            setMilestones(response.data.results || []);
        } catch (error) {
            toast({
                title: "Error",
                description: "Failed to load milestones",
                variant: "destructive"
            });
        } finally {
            setLoading(false);
        }
    };

    const handleAddMilestone = async () => {
        try {
            const milestoneData = {
                name: newMilestone.name,
                start_date: new Date().toISOString().split('T')[0],
                due_date: newMilestone.due_date,
                description: newMilestone.description,
                progress_percentage: 0,
                project: projectId
            };

            const response = await api.post(
                `projects/${projectId}/milestones/`,
                milestoneData
            );

            setMilestones([...milestones, response.data]);
            setShowAddDialog(false);
            setNewMilestone({ name: '', due_date: '', description: '' });

            toast({
                title: "Success",
                description: "Milestone added successfully"
            });
        } catch (error) {
            console.error('Error creating milestone:', error);
            toast({
                title: "Error",
                description: error.response?.data?.detail || "Failed to add milestone",
                variant: "destructive"
            });
        }
    };

    const getMilestoneStatus = (milestone) => {
        const dueDate = new Date(milestone.due_date);
        const today = new Date();

        if (milestone.completed) return "completed";
        if (dueDate < today) return "overdue";
        if (dueDate.getTime() - today.getTime() < 7 * 24 * 60 * 60 * 1000) return "upcoming";
        return "scheduled";
    };

    const getStatusBadge = (status) => {
        const statusConfig = {
            completed: { color: "bg-green-100 text-green-800", text: "Completed" },
            overdue: { color: "bg-red-100 text-red-800", text: "Overdue" },
            upcoming: { color: "bg-yellow-100 text-yellow-800", text: "Upcoming" },
            scheduled: { color: "bg-blue-100 text-blue-800", text: "Scheduled" }
        };

        const config = statusConfig[status];
        return <Badge className={config.color}>{config.text}</Badge>;
    };

    return (
        <div className="space-y-4">
            <div className="flex justify-between items-center">
                <div className="flex items-center gap-2">
                    <Flag className="h-5 w-5 text-gray-500" />
                    <h2 className="text-lg font-semibold">Project Milestones</h2>
                </div>
                <Button className={primaryButtonClasses} onClick={() => setShowAddDialog(true)}>
                    <Plus className="h-4 w-4 mr-2" />
                    Add Milestone
                </Button>
            </div>

            {loading ? (
                <div className="text-center py-4">Loading...</div>
            ) : (
                <div className="grid gap-4">
                    {milestones.map((milestone) => {
                        const status = getMilestoneStatus(milestone);
                        return (
                            <Card key={milestone.id}>
                                <CardContent className="p-4">
                                    <div className="flex items-center justify-between">
                                        <div>
                                            <h3 className="font-medium">{milestone.name}</h3>
                                            <p className="text-sm text-gray-500 mt-1">
                                                {milestone.description}
                                            </p>
                                        </div>
                                        <div className="flex items-center gap-4">
                                            <div className="text-right">
                                                <div className="flex items-center gap-2">
                                                    <Calendar className="h-4 w-4 text-gray-400" />
                                                    <span className="text-sm text-gray-600">
                                                        {new Date(milestone.due_date).toLocaleDateString()}
                                                    </span>
                                                </div>
                                            </div>
                                            {getStatusBadge(status)}
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                        );
                    })}
                    {milestones.length === 0 && (
                        <div className="text-center py-8 text-gray-500">
                            No milestones added yet
                        </div>
                    )}
                </div>
            )}

            <Dialog open={showAddDialog} onOpenChange={setShowAddDialog}>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>Add New Milestone</DialogTitle>
                    </DialogHeader>
                    <div className="space-y-4 py-4">
                        <div>
                            <label className="text-sm font-medium mb-1 block">
                                Milestone Name
                            </label>
                            <Input
                                value={newMilestone.name}
                                onChange={(e) => setNewMilestone({
                                    ...newMilestone,
                                    name: e.target.value
                                })}
                                placeholder="Enter milestone name"
                            />
                        </div>
                        <div>
                            <label className="text-sm font-medium mb-1 block">
                                Due Date
                            </label>
                            <Input
                                type="date"
                                value={newMilestone.due_date}
                                onChange={(e) => setNewMilestone({
                                    ...newMilestone,
                                    due_date: e.target.value
                                })}
                            />
                        </div>
                        <div>
                            <label className="text-sm font-medium mb-1 block">
                                Description
                            </label>
                            <Input
                                value={newMilestone.description}
                                onChange={(e) => setNewMilestone({
                                    ...newMilestone,
                                    description: e.target.value
                                })}
                                placeholder="Enter description"
                            />
                        </div>
                    </div>
                    <DialogFooter>
                        <Button className={secondaryButtonClasses} variant="outline" onClick={() => setShowAddDialog(false)}>
                            Cancel
                        </Button>
                        <Button className={primaryButtonClasses} onClick={handleAddMilestone}>
                            Add Milestone
                        </Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default BasicMilestoneTracker;