
import api from "./api";

export const contractorsApi = {
    // Create a new contractor
    addContractor: async (contractorData) => {
        try {
            const response = await api.post('add-contractor/', {
                first_name: contractorData.first_name,
                last_name: contractorData.last_name,
                email: contractorData.email,
                company: contractorData.company,
                payment_terms: contractorData.payment_terms,
                trades: contractorData.trades,
                other_trade: contractorData.other_trade,
                contract_start_date: contractorData.contract_start_date,
                contract_end_date: contractorData.contract_end_date,
                ...(contractorData.payment_terms === 'hourly' && {
                    hourly_rate: contractorData.hourly_rate
                }),
                ...(contractorData.payment_terms === 'lump_sum' && {
                    lump_sum_amount: contractorData.lump_sum_amount
                }),
                ...(contractorData.payment_terms === 'time_and_materials' && {
                    material_markup_percentage: contractorData.material_markup_percentage
                }),
                ...(contractorData.payment_terms === 'other' && {
                    other_payment_details: contractorData.other_payment_details
                })
            });
            return response.data;
        } catch (error) {
            // Improve error handling
            if (error.response?.data?.error) {
                throw new Error(error.response.data.error);
            }
            console.error('Error in addContractor:', error);
            throw new Error('Failed to create contractor');
        }
    },

    // Update an existing contractor
    updateContractor: async (contractorId, contractorData) => {
        try {

            const contractorIdString = String(contractorId).substring(0, 20);

            const response = await api.put(`contractors/${contractorId}/`, {

                user_id: contractorData.user?.id,
                contractor_id: contractorIdString,


                first_name: contractorData.first_name,
                last_name: contractorData.last_name,
                email: contractorData.email,
                company: contractorData.company,
                payment_terms: contractorData.payment_terms,
                trades: contractorData.trades,
                other_trade: contractorData.other_trade,
                contract_start_date: contractorData.contract_start_date,
                contract_end_date: contractorData.contract_end_date,
                // Add payment specific fields based on payment terms
                ...(contractorData.payment_terms === 'hourly' && {
                    hourly_rate: contractorData.hourly_rate
                }),
                ...(contractorData.payment_terms === 'lump_sum' && {
                    lump_sum_amount: contractorData.lump_sum_amount
                }),
                ...(contractorData.payment_terms === 'time_and_materials' && {
                    material_markup_percentage: contractorData.material_markup_percentage
                }),
                ...(contractorData.payment_terms === 'other' && {
                    other_payment_details: contractorData.other_payment_details
                })
            });
            return response.data;
        } catch (error) {
            console.error('Error in updateContractor:', error.response?.data || error);
            if (error.response?.data?.detail) {
                throw new Error(error.response.data.detail);
            }
            throw new Error('Failed to update contractor');
        }
    },
    // Get a single contractor
    getById: async (contractorId) => {
        try {
            const response = await api.get(`contractors/${contractorId}/`);
            return response.data;
        } catch (error) {
            throw new Error(error.response?.data?.message || 'Failed to fetch contractor');
        }
    },

    // Get all contractors
    getAll: async (params = {}) => {
        try {
            const response = await api.get('list-contractors/', { params });
            return response.data;
        } catch (error) {
            throw new Error(error.response?.data?.message || 'Failed to fetch contractors');
        }
    },

    // Delete a contractor
    delete: async (contractorId) => {
        try {
            const response = await api.delete(`contractors/${contractorId}/`);
            return response.data;
        } catch (error) {
            throw new Error(error.response?.data?.message || 'Failed to delete contractor');
        }
    },
    sendInvite: async (inviteData) => {
        try {
            const response = await api.post('contractors/invite/', {
                company_id: inviteData.company_id,
                first_name: inviteData.first_name,
                last_name: inviteData.last_name,
                email: inviteData.email,
                role: inviteData.role,
                message: inviteData.message || ''
            });
            return response.data;
        } catch (error) {
            const errorMessage = error.response?.data?.message
                || error.response?.data?.detail
                || 'Failed to send invitation';
            throw new Error(errorMessage);
        }
    },

    // Verify invitation token
    verifyInvite: async (token) => {
        try {
            const response = await api.get(`contractors/invite/verify/${token}/`);
            return response.data;
        } catch (error) {
            throw new Error('Invalid or expired invitation');
        }
    },

    // Resend invitation
    resendInvite: async (inviteId) => {
        try {
            const response = await api.post(`contractors/invite/${inviteId}/resend/`);
            return response.data;
        } catch (error) {
            throw new Error('Failed to resend invitation');
        }
    },

    // Cancel invitation
    cancelInvite: async (inviteId) => {
        try {
            const response = await api.delete(`contractors/invite/${inviteId}/`);
            return response.data;
        } catch (error) {
            throw new Error('Failed to cancel invitation');
        }
    }
};