import React from 'react';

const LoadingSpinner = ({ message = "Loading..." }) => {
    return (
        <div className="flex items-center justify-center w-full py-8">
            <div className="text-center">
                <div className="inline-block animate-spin h-8 w-8 border-4 border-blue-500 rounded-full border-t-transparent mb-4"></div>
                <p className="font-medium text-gray-700">{message}</p>
                <p className="text-sm text-gray-500 mt-1">BUILDIFYPRO thanks you for your patience...</p>
            </div>
        </div>
    );
};

export default LoadingSpinner;