import React, { useState, useEffect } from 'react';
import api from '../../services/api';
import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaymentForm from './PaymentForm';
import { showToast } from '../../utils/toast';
import { formatCurrency } from '../../utils/formatter';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const PaymentModal = ({ milestone, isOpen, onClose }) => {
    const [clientSecret, setClientSecret] = useState(null);

    useEffect(() => {
        if (isOpen && milestone) {
            // Get payment intent when modal opens
            api.post(`/projects/${milestone.project}/payments/intent/`, {
                milestone_id: milestone.id
            })
                .then(response => {
                    setClientSecret(response.data.client_secret);
                })
                .catch(error => {
                    console.error('Error creating payment intent:', error);
                });
        }
    }, [isOpen, milestone]);

    if (!isOpen || !clientSecret) return null;

    const options = {
        clientSecret,
        appearance: {
            theme: 'stripe',
            variables: {
                colorPrimary: '#0066cc',
            },
        },
    };

    return (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center">
            <div className="bg-white rounded-lg p-6 max-w-md w-full">
                <div className="mb-4">
                    <h3 className="text-lg font-medium text-gray-900">
                        Payment for {milestone.title}
                    </h3>
                    <p className="mt-1 text-sm text-gray-600">
                        Amount: {formatCurrency(milestone.amount)}
                    </p>
                </div>

                <Elements stripe={stripePromise} options={options}>
                    <PaymentForm
                        milestone={milestone}
                        onSuccess={() => {
                            onClose();
                            showToast.success('Payment processed successfully');
                        }}
                    />
                </Elements>

                <button
                    onClick={onClose}
                    className="mt-4 w-full flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                    Cancel
                </button>
            </div>
        </div>
    );
};

export default PaymentModal;