import React from 'react';
import { Card, CardHeader, CardTitle, CardContent } from "../../../../components/ui/cardui";
import { Badge } from "../../../../components/ui/badge";

const BidResponsesTab = ({ bidResponses, navigate, id, getStatusBadgeColor }) => {
    return (
        <Card>
            <CardHeader>
                <CardTitle className="text-lg sm:text-xl">Bid Responses</CardTitle>
            </CardHeader>
            <CardContent>
                {bidResponses.length === 0 ? (
                    <p className="text-center text-gray-500 py-8">No responses received yet</p>
                ) : (
                    <div className="space-y-4">
                        {bidResponses.map((response) => (
                            <Card key={response.id}>
                                <CardContent
                                    className="p-4 hover:bg-gray-50 cursor-pointer"
                                    onClick={() => navigate(`/bid-management/${id}/responses/${response.id}`)}
                                >
                                    <div className="flex flex-col sm:flex-row justify-between items-start gap-4">
                                        <div className="space-y-1 w-full sm:w-auto">
                                            <h3 className="font-medium text-base sm:text-lg">{response.company_name}</h3>
                                            <p className="text-sm text-gray-500">
                                                Contact: {response.contractor_name}
                                            </p>
                                            <p className="text-sm text-gray-500">
                                                Submitted: {response.formatted_date}
                                            </p>
                                        </div>
                                        <div className="flex flex-col items-end gap-2 w-full sm:w-auto">
                                            <p className="font-medium text-base sm:text-lg">{response.formatted_price}</p>
                                            <p className="text-sm text-gray-500">
                                                Timeline: {response.timeline} days
                                            </p>
                                            <Badge className={`mt-2 ${getStatusBadgeColor(response.status)}`}>
                                                {response.status.replace('_', ' ').toUpperCase()}
                                            </Badge>
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                        ))}
                    </div>
                )}
            </CardContent>
        </Card>
    );
};

export default BidResponsesTab;