import React, { useState, useEffect, useCallback } from 'react';
import api from '../../services/api';
import {
    BriefcaseIcon,
    EnvelopeIcon,
    BellIcon,
    MagnifyingGlassIcon,
    Bars3Icon,
    PaperClipIcon,
    XMarkIcon,
    ChevronDownIcon,
    ChevronUpIcon,
    CalendarIcon,
    AdjustmentsHorizontalIcon,
    ClipboardListIcon,
    SparklesIcon,
    ChevronRightIcon
} from '@heroicons/react/24/outline';
import { Input } from "../../components/ui/input";
import { Label } from "../../components/ui/label";
import { Button } from "../../components/ui/button";


const ProjectSidebar = ({ projects, activeProject, setActiveProject, isOpen, setIsOpen }) => {
    const [filterText, setFilterText] = useState('');

    const filteredProjects = projects.filter(project =>
        project.name.toLowerCase().includes(filterText.toLowerCase())
    );

    return (
        <div className={`w-64 bg-gray-100 h-screen p-4 overflow-y-auto fixed left-0 top-0 transition-transform duration-300 ease-in-out z-30 ${isOpen ? 'translate-x-0' : '-translate-x-full'}`}>
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-bold text-gray-800">Projects</h2>
                <XMarkIcon className="h-6 w-6 cursor-pointer" onClick={() => setIsOpen(false)} />
            </div>

            <div className="mb-4">
                <Input
                    type="text"
                    placeholder="Filter projects..."
                    className="w-full"
                    value={filterText}
                    onChange={(e) => setFilterText(e.target.value)}
                />
            </div>

            {filteredProjects.length > 0 ? (
                <ul>
                    {filteredProjects.map(project => (
                        <li
                            key={project.id}
                            className={`mb-2 p-2 rounded cursor-pointer flex items-center justify-between ${activeProject && activeProject.id === project.id ? 'bg-blue-100' : 'hover:bg-gray-200'}`}
                            onClick={() => {
                                setActiveProject(project);
                                setIsOpen(false);
                            }}
                        >
                            <span className="flex items-center">
                                <ChevronRightIcon className="mr-2 h-5 w-5" />
                                {project.name}
                            </span>
                            {project.unreadCount > 0 && (
                                <span className="bg-red-500 text-white rounded-full px-2 py-1 text-xs">
                                    {project.unreadCount}
                                </span>
                            )}
                        </li>
                    ))}
                </ul>
            ) : (
                <p className="text-gray-500">No projects available</p>
            )}

            <Button
                onClick={() => setIsOpen(false)}
                className="mt-4 w-full bg-gray-200 hover:bg-gray-300 text-gray-700"
                variant="outline"
            >
                Close
            </Button>
        </div>
    );
};
export default ProjectSidebar;