import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate, NavLink, useLocation } from 'react-router-dom';
import { Card } from '../../components/ui/cardui';
import {
    LayoutDashboard,
    FileText,
    DollarSign,
    Bell,
    FileIcon,
    BellIcon,
    FolderIcon,
    ChartBarIcon,
    AlertCircle
} from 'lucide-react';
import api from '../../services/api';
import PaymentModal from './PaymentModal'
import ClientFinancials from './ClientFinancials';
import ClientDashboard from './ClientDashboard';
import ClientDocumentManagement from './ClientDocumentManagement';
import ClientPortalDashboard from './ClientPortalDashboard';

const ClientPortalLayout = ({ children }) => {
    const location = useLocation();
    const [paymentModalOpen, setPaymentModalOpen] = useState(false);
    const [selectedMilestone, setSelectedMilestone] = useState(null);

    const navLinks = [
        { path: '/client-portal/dashboard', label: 'Dashboard', icon: LayoutDashboard },
        { path: '/client-portal/documents', label: 'Documents', icon: FileText },
        { path: '/client-portal/financials', label: 'Financials', icon: DollarSign }
    ];

    return (
        <div className="min-h-screen bg-gray-50">
            {/* Top Navigation */}
            <nav className="bg-white shadow-sm">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="flex justify-between h-16">
                        <div className="flex space-x-8">
                            {navLinks.map(({ path, label, icon: Icon }) => (
                                <NavLink
                                    key={path}
                                    to={path}
                                    className={({ isActive }) =>
                                        `flex items-center px-4 border-b-2 ${isActive
                                            ? 'border-blue-500 text-blue-600'
                                            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                                        }`
                                    }
                                >
                                    <Icon className="h-5 w-5 mr-2" />
                                    {label}
                                </NavLink>
                            ))}
                        </div>

                        {/* Notification Bell */}
                        <div className="flex items-center">
                            <button className="p-2 text-gray-400 hover:text-gray-500 relative">
                                <Bell className="h-6 w-6" />
                                <span className="absolute top-0 right-0 block h-2 w-2 rounded-full bg-blue-400 ring-2 ring-white" />
                            </button>
                        </div>
                    </div>
                </div>
            </nav>

            {/* Main Content */}
            <main className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
                {children}
            </main>

            {/* Payment Modal */}
            {paymentModalOpen && (
                <PaymentModal
                    isOpen={paymentModalOpen}
                    milestone={selectedMilestone}
                    onClose={() => {
                        setPaymentModalOpen(false);
                        setSelectedMilestone(null);
                    }}
                />
            )}
        </div>
    );
};

// Updated Routes Component with proper nesting
const ClientPortalRoutes = () => {
    return (
        <Routes>
            <Route path="*" element={<ClientPortalLayout>
                <Routes>
                    <Route index element={<Navigate to="dashboard" replace />} />
                    <Route path="dashboard" element={<ClientDashboard />} />
                    <Route path="dashboard/:projectId/*" element={<ClientPortalDashboard />} />
                    <Route path="documents" element={<ClientDocumentManagement />} />
                    <Route path="financials" element={<ClientFinancials />} />
                    <Route path="*" element={<Navigate to="dashboard" replace />} />
                </Routes>
            </ClientPortalLayout>} />
        </Routes>
    );
};

export default ClientPortalRoutes;