import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getHomeRouteForRole } from '../utils/routeHelpers';
import secureLocalStorage from "react-secure-storage";
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import { primaryButtonClasses } from '../components/common/ButtonStyles';

const NotFound = () => {
    const navigate = useNavigate();
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const secureUser = secureLocalStorage.getItem('user');
    const regularUserRole = localStorage.getItem('userRole');
    const userRole = secureUser?.role_name || regularUserRole;

    const handleRedirect = () => {
        const homeRoute = getHomeRouteForRole(userRole);
        navigate(homeRoute);
    };

    return (
        <div className="flex h-screen bg-snowfall font-gilroy">
            <Sidebar isOpen={isSidebarOpen} setIsOpen={setIsSidebarOpen} />
            <div className="flex-1 flex flex-col overflow-hidden">
                <Header />
                <main className="flex-1 overflow-x-hidden overflow-y-auto bg-white">
                    <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8">
                        <div className="flex flex-col items-center justify-center min-h-[80vh]">
                            <div className="text-center">
                                <h1 className="text-6xl font-bold text-gray-900 mb-4">404</h1>
                                <h2 className="text-3xl font-semibold text-gray-800 mb-4">
                                    Page Not Found
                                </h2>
                                <p className="text-gray-600 mb-8">
                                    The page you're looking for doesn't exist or you don't have permission to access it.
                                </p>
                                <button
                                    onClick={handleRedirect}
                                    className={"bg-blue-500 text-white px-6 py-3 rounded-md hover:bg-blue-600 transition duration-300"}
                                >
                                    Go to Dashboard
                                </button>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
};

export default NotFound;