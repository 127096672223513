import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Card, CardContent } from "../../../components/ui/cardui";
import { Label } from "../../../components/ui/label";
import { Input } from "../../../components/ui/input";
import { Checkbox } from "../../../components/ui/checkbox";
import { Star, ArrowLeft } from 'lucide-react';
import { Button } from "../../../components/ui/button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../../components/ui/select";
import api from '../../../services/api';

const BidComparisonPage = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const location = useLocation();
    const bidResponses = location.state?.bidResponses || [];
    const bid = location.state?.bid || {};

    const [selectedTrade, setSelectedTrade] = useState('all');
    const [filteredBids, setFilteredBids] = useState([]);
    const [tradeNames, setTradeNames] = useState({});
    const [localComparisonCriteria, setLocalComparisonCriteria] = useState({
        price: { weight: 0.4, enabled: true },
        timeline: { weight: 0.2, enabled: true },
        quality: { weight: 0.2, enabled: true },
        qualifications: { weight: 0.2, enabled: true }
    });


    useEffect(() => {
        const fetchTradeDetails = async () => {
            try {
                const response = await api.get('/construction-trades/');
                const tradesData = response.data || [];

                // Create mapping of trade IDs to names
                const mapping = {};
                tradesData.forEach(trade => {
                    if (trade.id) {
                        mapping[trade.id.toString()] = trade.name;
                    }
                });

                setTradeNames(mapping);

                // Assign trades to bids
                if (bidResponses.length && bid?.required_trades) {
                    // Distribute bids evenly across required trades
                    const bidsWithTrades = bidResponses.map((response, index) => {
                        // Use modulo to cycle through required trades
                        const tradeId = bid.required_trades[index % bid.required_trades.length];

                        return {
                            ...response,
                            trade_id: tradeId.toString()
                        };
                    });

                    if (selectedTrade === 'all') {
                        setFilteredBids(bidsWithTrades);
                    } else {
                        const filtered = bidsWithTrades.filter(b =>
                            b.trade_id === selectedTrade
                        );
                        setFilteredBids(filtered);
                    }
                }
            } catch (error) {
                console.error('Error fetching trades:', error);
            }
        };

        fetchTradeDetails();
    }, [bidResponses, bid?.required_trades, selectedTrade]);

    // Simplify the trade name lookup
    const getBidTradeName = (bid) => {
        return tradeNames[bid.trade_id] || 'Unknown Trade';
    };

    // Update available trades to show only required trades
    const getAvailableTrades = () => {
        if (!bid?.required_trades) return [];

        return bid.required_trades.map(tradeId => ({
            id: tradeId.toString(),
            name: tradeNames[tradeId] || `Trade ${tradeId}`
        }));
    };


    useEffect(() => {
        if (!bid?.required_trades || !bidResponses.length) return;

        const bidsWithTrades = bidResponses.map(response => {
            // Get the specialized trades from the contractor profile
            const specializedTrades = response.contractor_profile?.specialized_trades || [];

            // Find the first trade that matches the required trades
            const matchingTradeId = specializedTrades.find(trade =>
                bid.required_trades.includes(trade.id)
            )?.id;

            console.log('Trade mapping for', response.contractor_name, {
                specializedTrades,
                matchingTradeId,
                requiredTrades: bid.required_trades
            });

            return {
                ...response,
                trade_id: matchingTradeId || null
            };
        });

        if (selectedTrade === 'all') {
            setFilteredBids(bidsWithTrades);
        } else {
            const filtered = bidsWithTrades.filter(bid =>
                bid.trade_id?.toString() === selectedTrade
            );
            setFilteredBids(filtered);
        }
    }, [selectedTrade, bidResponses, bid?.required_trades]);



    const handleLocalCompareBids = () => {
        if (!filteredBids.length) return [];

        const scoredBids = filteredBids.map(response => {
            let totalWeight = 0;
            let totalScore = 0;

            // Calculate scores for each enabled criterion
            if (localComparisonCriteria.price.enabled && response.price) {
                const maxPrice = Math.max(...filteredBids.map(b => b.price || 0));
                const priceScore = maxPrice ? (1 - (response.price / maxPrice)) * 100 : 0;
                totalScore += priceScore * localComparisonCriteria.price.weight;
                totalWeight += localComparisonCriteria.price.weight;
            }

            if (localComparisonCriteria.timeline.enabled && response.timeline) {
                const maxTimeline = Math.max(...filteredBids.map(b => b.timeline || 0));
                const timelineScore = maxTimeline ? (1 - (response.timeline / maxTimeline)) * 100 : 0;
                totalScore += timelineScore * localComparisonCriteria.timeline.weight;
                totalWeight += localComparisonCriteria.timeline.weight;
            }

            if (localComparisonCriteria.quality.enabled && response.quality_rating) {
                const qualityScore = (response.quality_rating / 5) * 100;
                totalScore += qualityScore * localComparisonCriteria.quality.weight;
                totalWeight += localComparisonCriteria.quality.weight;
            }

            if (localComparisonCriteria.qualifications.enabled && response.qualification_score) {
                const qualificationsScore = (response.qualification_score / 5) * 100;
                totalScore += qualificationsScore * localComparisonCriteria.qualifications.weight;
                totalWeight += localComparisonCriteria.qualifications.weight;
            }

            const normalizedScore = totalWeight > 0 ? totalScore / totalWeight : 0;

            return {
                ...response,
                scores: {
                    total: normalizedScore,
                    price: response.price ? (1 - (response.price / Math.max(...filteredBids.map(b => b.price || 0)))) * 100 : 0,
                    timeline: response.timeline ? (1 - (response.timeline / Math.max(...filteredBids.map(b => b.timeline || 0)))) * 100 : 0,
                    quality: response.quality_rating ? (response.quality_rating / 5) * 100 : 0,
                    qualifications: response.qualification_score ? (response.qualification_score / 5) * 100 : 0
                }
            };
        });

        return scoredBids.sort((a, b) => b.scores.total - a.scores.total);
    };

    const availableTrades = getAvailableTrades();

    return (
        <div className="p-4 sm:p-6">
            <div className="flex items-center gap-4 mb-6">
                <Button
                    variant="ghost"
                    onClick={() => navigate(`/bid-management/${id}`)}
                    className="flex items-center gap-2"
                >
                    <ArrowLeft className="h-4 w-4" />
                    Back to Bid Details
                </Button>
                <h1 className="text-2xl font-bold">Bid Response Comparison</h1>
            </div>

            {/* Trade Filter */}
            <Card className="mb-6">
                <CardContent className="p-4">
                    <div className="flex items-center gap-4">
                        <Label>Filter by Trade:</Label>
                        <Select
                            value={selectedTrade}
                            onValueChange={setSelectedTrade}
                        >
                            <SelectTrigger className="w-[200px]">
                                <SelectValue placeholder="Select trade" />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectItem value="all">All Trades</SelectItem>
                                {availableTrades.map((trade) => (
                                    <SelectItem key={trade.id} value={trade.id.toString()}>
                                        {trade.name}
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    </div>
                </CardContent>
            </Card>

            {/* Comparison Criteria */}
            <Card className="mb-6">
                <CardContent className="p-4">
                    <div className="grid grid-cols-2 md:grid-cols-4 gap-4 bg-gray-50 p-4 rounded-lg">
                        {Object.entries(localComparisonCriteria).map(([criterion, { weight, enabled }]) => (
                            <div key={criterion} className="space-y-2">
                                <div className="flex items-center justify-between">
                                    <Label className="text-sm sm:text-base">
                                        {criterion.charAt(0).toUpperCase() + criterion.slice(1)}
                                    </Label>
                                    <Checkbox
                                        checked={enabled}
                                        onCheckedChange={(checked) => {
                                            setLocalComparisonCriteria(prev => ({
                                                ...prev,
                                                [criterion]: { ...prev[criterion], enabled: checked }
                                            }));
                                        }}
                                    />
                                </div>
                                <Input
                                    type="range"
                                    min="0"
                                    max="1"
                                    step="0.1"
                                    value={weight}
                                    onChange={(e) => {
                                        setLocalComparisonCriteria(prev => ({
                                            ...prev,
                                            [criterion]: { ...prev[criterion], weight: parseFloat(e.target.value) }
                                        }));
                                    }}
                                    disabled={!enabled}
                                    className="w-full"
                                />
                                <span className="text-sm text-gray-500 block text-right">
                                    {(weight * 100).toFixed(0)}%
                                </span>
                            </div>
                        ))}
                    </div>
                </CardContent>
            </Card>

            {/* Comparison Results */}
            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4">
                {handleLocalCompareBids().map((bid, index) => (
                    <Card key={bid.id} className="h-full">
                        <CardContent className="p-4">
                            <div className="flex items-center gap-2 mb-4">
                                <span className="text-2xl font-bold">#{index + 1}</span>
                                <div className="text-lg font-medium truncate">
                                    {bid.contractor_name}
                                </div>
                            </div>
                            <div className="space-y-4">
                                <div>
                                    <p className="text-sm text-gray-500">Trade</p>
                                    <p className="font-medium">{getBidTradeName(bid)}</p>
                                </div>
                                <div>
                                    <p className="text-sm text-gray-500">Price</p>
                                    <p className="font-medium">${bid.price?.toLocaleString() || 'N/A'}</p>
                                </div>
                                <div>
                                    <p className="text-sm text-gray-500">Timeline</p>
                                    <p className="font-medium">{bid.timeline ? `${bid.timeline} days` : 'N/A'}</p>
                                </div>
                                <div>
                                    <p className="text-sm text-gray-500">Quality Rating</p>
                                    <div className="flex items-center">
                                        {Array.from({ length: 5 }).map((_, i) => (
                                            <Star
                                                key={i}
                                                className={`h-4 w-4 ${i < bid.quality_rating
                                                    ? 'text-yellow-400 fill-current'
                                                    : 'text-gray-300'
                                                    }`}
                                            />
                                        ))}
                                    </div>
                                </div>
                                <div>
                                    <p className="text-sm text-gray-500">Total Score</p>
                                    <p className="font-medium">{bid.scores.total.toFixed(1)}</p>
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                ))}
            </div>
        </div>
    );
};

export default BidComparisonPage;