import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue
} from "../components/ui/select";
import { Textarea } from "../components/ui/textarea";
import { Alert, AlertDescription } from "../components/ui/alert";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "../components/ui/cardui";
import { Button } from "../components/ui/button";
import { AlertCircle } from 'lucide-react';
import api from '../services/api';
import { primaryButtonClasses } from './common/ButtonStyles';

const CANCELLATION_REASONS = [
    { value: 'PRICE', label: 'Price' },
    { value: 'FEATURES', label: 'Features' },
    { value: 'USABILITY', label: 'Ease of use' },
    { value: 'SUPPORT', label: 'Support' },
    { value: 'STABILITY', label: 'Stability and performance' },
    { value: 'CHANGING_PROVIDER', label: 'Changing provider' },
    { value: 'DOCUMENTATION', label: 'Documentation' },
    { value: 'DELIVERABILITY', label: 'Deliverability' },
    { value: 'OTHER', label: 'Other' }
];

const CheckoutCanceledPage = () => {
    const [searchParams] = useSearchParams();
    const featureType = searchParams.get('feature_type');
    const navigate = useNavigate();

    const [reasonType, setReasonType] = useState('');
    const [reasonDescription, setReasonDescription] = useState('');
    const [error, setError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);

    const getFeatureTypeName = (code) => {
        const featureNames = {
            'PROJECT': 'Project Management',
            'SAFETY': 'Safety Management',
            'BID': 'Bid Management',
            'ALL': 'All Features'
        };
        return featureNames[code] || 'our service';
    };

    const handleSubmitFeedback = async () => {
        if (!reasonType) {
            setError('Please select a reason');
            return;
        }

        setIsSubmitting(true);

        try {
            // Reuse your existing cancellation endpoint
            await api.post('checkout-canceled-feedback/', {
                reason_type: reasonType,
                description: reasonDescription,
                feature_type: featureType,
                is_checkout_cancellation: true
            });

            setFeedbackSubmitted(true);
        } catch (error) {
            console.error('Error submitting feedback:', error);
            setError('Failed to submit feedback. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleReturnToPricing = () => {
        navigate('/pricing');
    };

    const handleTryAgain = () => {
        navigate('/signup');
    };

    if (feedbackSubmitted) {
        return (
            <div className="container mx-auto px-4 py-12 max-w-3xl">
                <Card>
                    <CardHeader>
                        <CardTitle>Thank You For Your Feedback</CardTitle>
                        <CardDescription>
                            We appreciate your input and will use it to improve our service.
                        </CardDescription>
                    </CardHeader>
                    <CardContent>
                        <p className="text-center py-4">
                            Your feedback has been submitted successfully.
                        </p>
                    </CardContent>
                    <CardFooter className="flex justify-center space-x-4">
                        <Button onClick={handleReturnToPricing} className={primaryButtonClasses}>
                            View Pricing Options
                        </Button>
                        <Button variant="outline" onClick={() => navigate('/dashboard')}>
                            Return to Dashboard
                        </Button>
                    </CardFooter>
                </Card>
            </div>
        );
    }

    return (
        <div className="container mx-auto px-4 py-12 max-w-3xl">
            <Card>
                <CardHeader>
                    <CardTitle>Checkout Canceled</CardTitle>
                    <CardDescription>
                        You've canceled your checkout for {featureType ? getFeatureTypeName(featureType) : 'our service'}.
                    </CardDescription>
                </CardHeader>

                <CardContent className="space-y-4">
                    <p>
                        We'd love to know why you decided not to proceed so we can improve our service.
                        Would you mind sharing your thoughts with us?
                    </p>

                    {error && (
                        <Alert variant="destructive">
                            <AlertCircle className="h-4 w-4" />
                            <AlertDescription>{error}</AlertDescription>
                        </Alert>
                    )}

                    <div className="space-y-2">
                        <label className="text-sm font-medium leading-none">
                            Reason for not proceeding
                        </label>
                        <Select
                            value={reasonType}
                            onValueChange={(value) => {
                                setReasonType(value);
                                setError('');
                            }}
                        >
                            <SelectTrigger className="w-full">
                                <SelectValue placeholder="Select a reason" />
                            </SelectTrigger>
                            <SelectContent>
                                {CANCELLATION_REASONS.map((reason) => (
                                    <SelectItem key={reason.value} value={reason.value}>
                                        {reason.label}
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    </div>

                    <div className="space-y-2">
                        <label className="text-sm font-medium leading-none">
                            Additional feedback (optional)
                        </label>
                        <Textarea
                            value={reasonDescription}
                            onChange={(e) => setReasonDescription(e.target.value)}
                            placeholder="Please tell us more about your decision..."
                            className="min-h-[100px]"
                        />
                    </div>
                </CardContent>

                <CardFooter className="flex flex-col sm:flex-row justify-between space-y-4 sm:space-y-0">
                    <div className="flex space-x-3">
                        <Button onClick={handleTryAgain} variant="outline">
                            Try Again
                        </Button>
                        <Button onClick={handleReturnToPricing} variant="outline">
                            View Pricing
                        </Button>
                    </div>

                    <Button
                        onClick={handleSubmitFeedback}
                        disabled={isSubmitting}
                        className={primaryButtonClasses}
                    >
                        {isSubmitting ? 'Submitting...' : 'Submit Feedback'}
                    </Button>
                </CardFooter>
            </Card>
        </div>
    );
};

export default CheckoutCanceledPage;