import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import api from '../services/api';
import { encryptData, setSecureItem } from '../utils/secureStorage';
import { Loader2, CheckCircle, Check, Star } from 'lucide-react';
import { showToast } from '../utils/toast';
import { FEATURES } from '../contexts/FeatureAccessContext';
import secureLocalStorage from "react-secure-storage";

const ROLE_MAPPING = {
  [FEATURES.PROJECT_MANAGEMENT]: 'Tenant Admin Project',
  [FEATURES.SAFETY_MANAGEMENT]: 'Tenant Admin Safety',
  [FEATURES.BID_MANAGEMENT]: 'Tenant Admin Bid',
  [FEATURES.ALL_FEATURES]: 'Tenant Admin'
};

// Pricing constants
const MONTHLY_PRICE = 39.99;
const YEARLY_PRICE = 29.99;
const YEARLY_FLAT_PRICE = 6999.99;

// Feature benefits mapping
const FEATURE_BENEFITS = {
  [FEATURES.PROJECT_MANAGEMENT]: [
    { title: "Performance Tracking", description: "Monitor labor productivity, equipment usage, and task completion in real-time" },
    { title: "Budget Control", description: "Track cost performance and prevent budget overruns with early alerts" },
    { title: "Document Management", description: "Centralize all project documentation with version control" },
    { title: "Resource Optimization", description: "Reduce idle time and improve workforce efficiency" },
    { title: "Change Impact Analysis", description: "Understand how changes affect your schedule and costs" }
  ],
  [FEATURES.SAFETY_MANAGEMENT]: [
    { title: "Incident Tracking", description: "Monitor TRIR, LTIFR, and near-misses to create a safer workplace" },
    { title: "OSHA Compliance", description: "Track violations, inspections, and PPE compliance to avoid penalties" },
    { title: "Risk Management", description: "Identify and mitigate hazards before they cause accidents" },
    { title: "Safety Training", description: "Manage certifications and ensure workers are properly trained" },
    { title: "Safety Culture", description: "Build accountability with automated alerts and reporting tools" }
  ],
  [FEATURES.BID_MANAGEMENT]: [
    { title: "Win Rate Optimization", description: "Track bid success metrics to improve competitiveness and profitability" },
    { title: "Vendor Management", description: "Evaluate subcontractor performance and manage prequalifications" },
    { title: "Bid Transparency", description: "Streamline communication with RFIs and direct messaging" },
    { title: "Cost Analysis", description: "Monitor bid price accuracy and prevent underbidding on projects" },
    { title: "Performance Insights", description: "Identify trends and optimize strategies with bid analytics" }
  ],
  [FEATURES.ALL_FEATURES]: [
    { title: "Complete Project Solution", description: "Access all Project, Safety, and Bid Management features in one centralized system" },
    { title: "Custom System Integrations", description: "Seamlessly connect with your existing construction management software, accounting tools, and ERP systems" },
    { title: "Unlimited Users", description: "No user limits across all teams and departments with comprehensive role-based access control" },
    { title: "Advanced Analytics Dashboard", description: "Get cross-functional insights across all projects, teams, and workflows with customizable reports" },
    { title: "Dedicated Customer Success", description: "Premium support with implementation specialists, training sessions, and dedicated account management" },
    { title: "Communication Hub", description: "Centralize all project communication with response tracking and message clarity metrics" },
    { title: "White-label Reporting", description: "Generate branded client reports with your company logo and custom templates" },
    { title: "API Access", description: "Build custom workflows and integrations with full API access and developer support" },
    { title: "Contractor Management", description: "Track compliance, work completion, quality, and safety across all contractors with automated alerts" },
    { title: "Client Portal", description: "Enhance transparency with secure client access to project updates, photos, and approvals" },
    { title: "Advanced Document Control", description: "Maintain ISO-compliant document workflows with approval chains and audit trails" },
    { title: "Enterprise Data Security", description: "Enhanced security with SSO, MFA, and role-based permissions to protect sensitive information" }
  ]
};

// Custom "All Features" option to add when yearly is selected
const ALL_FEATURES_OPTION = {
  id: 'all-features',
  name: 'Enterprise Bundle',
  code: FEATURES.ALL_FEATURES,
  pricing_type: 'FLAT_RATE',
  description: 'Complete solution with unlimited users'
};

const Signup = ({ promotion = null, customTitle = null }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loadingFeatures, setLoadingFeatures] = useState(true);
  const [featureTypes, setFeatureTypes] = useState([]);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    feature_type: null,
    user_count: 1,
    billing_cycle: 'monthly'
  });
  const [error, setError] = useState('');
  const [helpText, setHelpText] = useState('');
  const [step, setStep] = useState(1);
  const [isEnterpriseMode, setIsEnterpriseMode] = useState(false);
  const [promoCode, setPromoCode] = useState(promotion?.stripeCoupon || null);

  const displayedFeatureTypes = useMemo(() => {
    let features = [...featureTypes];

    features.sort((a, b) => {
      if (a.code === FEATURES.BID_MANAGEMENT) return 1;
      if (b.code === FEATURES.BID_MANAGEMENT) return -1;
      return 0;
    });

    if (isEnterpriseMode) {
      return [ALL_FEATURES_OPTION];
    }

    return features;
  }, [featureTypes, isEnterpriseMode]);

  const formatCurrency = (amount) => {
    return `$${parseFloat(amount).toFixed(2)}`;
  };

  const totalPrice = useMemo(() => {
    if (!formData.feature_type) return 0;

    const selectedFeature = [...featureTypes, ALL_FEATURES_OPTION].find(f => f.id === formData.feature_type);
    if (!selectedFeature) return 0;

    let price;

    // Check if there's a special lifetime fixed price promotion
    if (promotion && promotion.monthly_fixed_price && formData.billing_cycle === 'monthly') {
      price = promotion.monthly_fixed_price * formData.user_count;
    } else if (promotion && promotion.yearly_fixed_price && formData.billing_cycle === 'yearly') {
      price = promotion.yearly_fixed_price * formData.user_count * 12;
    } else if (selectedFeature.pricing_type === 'FLAT_RATE') {
      price = YEARLY_FLAT_PRICE;

      // Apply enterprise discount if promotion exists
      if (promotion && promotion.discounts && selectedFeature.code === FEATURES.ALL_FEATURES) {
        price = price * (1 - promotion.discounts.enterprise / 100);
      }
    } else {
      const basePrice = formData.billing_cycle === 'yearly' ? YEARLY_PRICE : MONTHLY_PRICE;
      const multiplier = formData.billing_cycle === 'yearly' ? 12 : 1;
      price = (basePrice * formData.user_count * multiplier);

      // Apply monthly plan discount if promotion exists and monthly billing selected
      if (promotion && promotion.discounts && formData.billing_cycle === 'monthly') {
        price = price * (1 - promotion.discounts.monthly / 100);
      }
    }

    return price.toFixed(2);
  }, [formData.feature_type, formData.user_count, formData.billing_cycle, featureTypes, promotion]);



  useEffect(() => {
    const fetchFeatureTypes = async () => {
      try {
        const response = await api.get('feature-types/');
        const filteredFeatures = Array.isArray(response.data.results)
          ? response.data.results.filter(feature => feature.code !== FEATURES.ALL_FEATURES)
          : [];

        // Find the ALL_FEATURES feature type to get its ID
        const allFeaturesType = response.data.results.find(
          feature => feature.code === FEATURES.ALL_FEATURES
        );

        // Set state with the correct ID
        if (allFeaturesType) {
          ALL_FEATURES_OPTION.id = allFeaturesType.id; // Use the actual UUID
        }

        setFeatureTypes(filteredFeatures);
      } catch (error) {
        console.error('Failed to fetch feature types:', error);
        showToast.error('Failed to load feature options');
        setFeatureTypes([]);
      } finally {
        setLoadingFeatures(false);
      }
    };

    fetchFeatureTypes();
  }, []);

  useEffect(() => {
    setFormData({
      ...formData,
      feature_type: null
    });
  }, [isEnterpriseMode]);

  useEffect(() => {
    if (formData.feature_type) {
      const selectedFeature = [...featureTypes, ALL_FEATURES_OPTION].find(f => f.id === formData.feature_type);
      if (selectedFeature && selectedFeature.pricing_type === 'PER_USER' && !formData.user_count) {
        setFormData(prev => ({ ...prev, user_count: 1 }));
      }
    }
  }, [formData.feature_type, featureTypes]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });

    if (error) {
      setError('');
      setHelpText('');
    }
  };

  const handleFeatureSelect = (featureType) => {
    setFormData({
      ...formData,
      feature_type: featureType.id,
      user_count: featureType.pricing_type === 'FLAT_RATE' ? null : 1 // Set to 1 for per-user pricing
    });
  };

  const handleUserCountChange = (e) => {
    const count = parseInt(e.target.value) || 1;
    const selectedFeature = displayedFeatureTypes.find(f => f.id === formData.feature_type);
    const maxUsers = selectedFeature?.max_users || 35;

    setFormData({
      ...formData,
      user_count: Math.min(Math.max(1, count), maxUsers)
    });
  };

  const handleBillingCycleChange = (cycle) => {
    setFormData({ ...formData, billing_cycle: cycle });
  };

  const handleEnterpriseToggle = (isEnterprise) => {
    setIsEnterpriseMode(isEnterprise);

    if (isEnterprise) {
      setFormData({
        ...formData,
        billing_cycle: 'yearly'
      });
    }
  };

  const storeUserData = (accessToken, refreshToken, featureType, role) => {
    const encryptedAccess = encryptData(accessToken);
    const encryptedRefresh = encryptData(refreshToken);
    setSecureItem('token', encryptedAccess);
    setSecureItem('refreshToken', encryptedRefresh);

    const userData = {
      role_name: role,
      feature_type: featureType
    };
    secureLocalStorage.setItem('user', userData);
    localStorage.setItem('userRole', role);
  };

  const handleSubmit = async () => {
    setError('');
    setHelpText('');
    setLoading(true);

    try {
      // Step 1: Create account
      const accountResponse = await api.post('signup/', {
        email: formData.email,
        password: formData.password,
        feature_type: formData.feature_type,
        billing_cycle: formData.billing_cycle,
        user_count: formData.user_count,
        promotion_code: promoCode
      });

      const selectedFeature = [...featureTypes, ALL_FEATURES_OPTION].find(f => f.id === formData.feature_type);
      const role = ROLE_MAPPING[selectedFeature.code];

      // Store user data immediately
      storeUserData(
        accountResponse.data.access,
        accountResponse.data.refresh,
        selectedFeature.code,
        role
      );

      api.defaults.headers['Authorization'] = `Bearer ${accountResponse.data.access}`;

      // Step 2: Create checkout session for payment
      const checkoutResponse = await api.post('create-checkout-session/', {
        feature_type: selectedFeature.code,
        billing_cycle: formData.billing_cycle,
        user_count: formData.user_count,
        promotion_code: promoCode
      });

      if (checkoutResponse.data.checkout_url) {
        window.location.href = checkoutResponse.data.checkout_url;
      } else if (checkoutResponse.data.landing_page) {
        navigate(checkoutResponse.data.landing_page);
        showToast.success('Account created successfully!');
      } else {
        throw new Error('No redirection URL received');
      }

    } catch (error) {
      setLoading(false);

      const errorMessage = error.response?.data?.error || 'Signup failed';
      const helpTextMessage = error.response?.data?.help_text || 'Please check your information and try again.';

      console.error('Signup error:', error);
      setError(errorMessage);
      setHelpText(helpTextMessage);

      showToast.error(errorMessage);
    }
  };


  const renderPriceWithDiscount = (feature, originalPrice) => {
    if (promotion && promotion.isLifetime && promotion.monthlyFixedPrice && formData.billing_cycle === 'monthly') {
      return (
        <>
          <span className="line-through text-gray-500 mr-2">${originalPrice.toFixed(2)}</span>
          <span className="text-green-600 font-bold">${promotion.monthlyFixedPrice.toFixed(2)}</span>
        </>
      );
    }

    if (!promotion) return originalPrice;

    const discountPercentage = feature.code === FEATURES.ALL_FEATURES ?
      promotion.discounts.enterprise :
      (formData.billing_cycle === 'monthly' ? promotion.discounts.monthly : 0);

    if (discountPercentage === 0) return originalPrice;

    const discountedPrice = originalPrice * (1 - discountPercentage / 100);

    return (
      <>
        <span className="line-through text-gray-500 mr-2">${originalPrice.toFixed(2)}</span>
        <span className="text-green-600 font-bold">${discountedPrice.toFixed(2)}</span>
      </>
    );
  };


  const renderPromotionalBadge = (feature) => {
    if (!promotion) return null;

    const discountPercentage = feature.code === FEATURES.ALL_FEATURES ?
      promotion.discounts.enterprise :
      (formData.billing_cycle === 'monthly' ? promotion.discounts.monthly : 0);

    if (discountPercentage === 0) return null;

    return (
      <div className="absolute -top-2 -right-2 bg-green-600 text-white px-2 py-0.5 rounded-full text-xs font-semibold shadow-md">
        SAVE {discountPercentage}%
      </div>
    );
  };


  const getFeatureBenefits = (featureCode) => {
    return FEATURE_BENEFITS[featureCode] || [];
  };

  const renderPlanToggle = () => (
    <div className="mb-8 flex flex-col items-center">
      <h4 className="text-lg font-medium mb-2">Plan Selection</h4>
      <div className="flex items-center bg-gray-100 p-1.5 rounded-lg">
        <button
          type="button"
          onClick={() => handleEnterpriseToggle(false)}
          className={`px-6 py-2 rounded-md transition-colors text-sm font-medium relative
            ${!isEnterpriseMode
              ? 'bg-white text-blue-600 shadow-sm'
              : 'text-gray-700 hover:bg-gray-200'
            }`}
        >
          Standard Plans
        </button>
        <button
          type="button"
          onClick={() => handleEnterpriseToggle(true)}
          className={`px-6 py-2 rounded-md transition-colors text-sm font-medium relative
           ${isEnterpriseMode
              ? 'bg-white text-blue-600 shadow-sm'
              : 'text-gray-700 hover:bg-gray-200'
            }`}
        >
          Enterprise Bundle
          <span className="absolute -top-2 -right-2 bg-blue-600 text-white px-2 py-0.5 rounded-full text-xs font-semibold shadow-md">
            BEST VALUE
          </span>
        </button>
      </div>
    </div>
  );

  const renderBillingToggle = () => (
    <div className="mb-8 flex flex-col items-center">
      <h4 className="text-lg font-medium mb-2">Billing Preference</h4>
      <div className="flex items-center bg-gray-100 p-1.5 rounded-lg">
        <button
          type="button"
          onClick={() => handleBillingCycleChange('monthly')}
          disabled={isEnterpriseMode}
          className={`px-6 py-2 rounded-md transition-colors text-sm font-medium
            ${formData.billing_cycle === 'monthly'
              ? 'bg-white text-blue-600 shadow-sm'
              : 'text-gray-700 hover:bg-gray-200'
            } ${isEnterpriseMode ? 'opacity-50 cursor-not-allowed' : ''}`}
        >
          Monthly
        </button>
        <button
          type="button"
          onClick={() => handleBillingCycleChange('yearly')}
          className={`px-6 py-2 rounded-md transition-colors text-sm font-medium
           ${formData.billing_cycle === 'yearly'
              ? 'bg-white text-blue-600 shadow-sm'
              : 'text-gray-700 hover:bg-gray-200'
            }`}
        >
          Yearly {!isEnterpriseMode && "(Save 25%)"}
        </button>
      </div>
      {formData.feature_type && (
        <div className="mt-3 text-center font-medium">
          <span className="text-gray-700">Total: </span>
          {promotion && promotion.isLifetime && promotion.monthlyFixedPrice && formData.billing_cycle === 'monthly' ? (
            <>
              <span className="line-through text-gray-500 mr-1">${MONTHLY_PRICE.toFixed(2)}</span>
              <span className="text-xl text-green-600 font-bold">${promotion.monthlyFixedPrice.toFixed(2)}</span>
            </>
          ) : (
            <span className="text-xl text-blue-600 font-bold">${totalPrice}</span>
          )}
          <span className="text-gray-700 text-sm ml-1">
            {formData.billing_cycle === 'yearly' ? '/year' : '/month'}
          </span>
          {formData.billing_cycle === 'yearly' && (
            <span className="text-gray-500 text-xs block">Billed annually upfront</span>
          )}
        </div>
      )}
    </div>
  );

  const renderFeatureSelection = () => (
    <div className="space-y-6">
      <h3 className="text-2xl font-semibold text-center">Choose Your Feature Set</h3>
      <p className="text-center text-gray-600 text-lg">
        {promotion && promotion.skipTrial
          ? "Get Started Immediately!"
          : "Start with a 35-day free trial"
        }
      </p>


      {promotion && (
        <div className={`${promotion.bannerColor || 'bg-blue-600'} text-white p-4 my-4 rounded-lg shadow-md`}>
          <h3 className="text-xl font-bold mb-1">{promotion.icon} {promotion.name}</h3>
          <p className="text-lg">{promotion.description}</p>
          <p className="text-sm mt-1">Offer valid until {promotion.endDate.toLocaleDateString()}</p>
        </div>
      )}

      {renderPlanToggle()}

      {!isEnterpriseMode && renderBillingToggle()}

      {isEnterpriseMode && (
        <div className="mt-8 mb-10">
          <h4 className="text-xl font-bold text-center text-blue-700 mb-6">
            Enterprise Bundle Benefits
          </h4>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-x-6 gap-y-4">
            {getFeatureBenefits(FEATURES.ALL_FEATURES).map((benefit, index) => (
              <div key={index} className="flex items-start">
                <Star className="h-5 w-5 text-blue-500 mr-2 flex-shrink-0 mt-0.5" />
                <div>
                  <span className="font-medium text-gray-700">{benefit.title}:</span>{' '}
                  <span className="text-gray-600">{benefit.description}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {loadingFeatures ? (
        <div className="flex justify-center items-center py-8">
          <Loader2 className="h-8 w-8 animate-spin" />
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {displayedFeatureTypes.map(feature => (
            <div
              key={feature.id}
              className={`border rounded-lg p-6 cursor-pointer transition-all relative h-full flex flex-col
               ${formData.feature_type === feature.id
                  ? 'border-blue-500 bg-blue-50'
                  : feature.code === FEATURES.ALL_FEATURES
                    ? 'border-blue-300 bg-blue-50 hover:border-blue-500'
                    : 'border-gray-200 hover:border-blue-300'
                }`}
              onClick={() => handleFeatureSelect(feature)}
            >
              {/* Promotional Badge */}
              {renderPromotionalBadge(feature)}

              {/* Radio Button */}
              <div className="absolute top-6 right-6">
                <div className={`w-6 h-6 rounded-full border-2 flex items-center justify-center
                 ${formData.feature_type === feature.id
                    ? 'border-blue-500 bg-blue-500'
                    : 'border-gray-300'
                  }`}
                >
                  {formData.feature_type === feature.id && (
                    <div className="w-2 h-2 rounded-full bg-white" />
                  )}
                </div>
              </div>

              {/* Header Section */}
              <div className="pr-8">
                <div className={`text-xl font-medium mb-1 ${feature.code === FEATURES.ALL_FEATURES ? 'text-blue-700' : ''}`}>
                  {feature.name?.split(' & ')[0] || feature.name || ''}
                </div>
                <div className={`text-xl font-medium mb-2 ${feature.code === FEATURES.ALL_FEATURES ? 'text-blue-600' : 'text-blue-600'}`}>
                  {renderPriceWithDiscount(
                    feature,
                    feature.code === FEATURES.ALL_FEATURES ?
                      formatCurrency((YEARLY_FLAT_PRICE / 12).toFixed(2)) :
                      (formData.billing_cycle === 'yearly'
                        ? (feature.pricing_type === 'PER_USER' ? YEARLY_PRICE : (YEARLY_FLAT_PRICE / 12).toFixed(2))
                        : (feature.pricing_type === 'PER_USER' ? MONTHLY_PRICE : (YEARLY_FLAT_PRICE / 12).toFixed(2))
                      )
                  )}
                  <span className="text-gray-600 text-base ml-1">
                    {feature.pricing_type === 'PER_USER' ? '/user/month' : '/month'}
                  </span>
                </div>
                {formData.billing_cycle === 'yearly' && (
                  <div className="text-xs text-gray-500 mb-3">
                    {feature.code === FEATURES.ALL_FEATURES
                      ? `$${YEARLY_FLAT_PRICE.toLocaleString()} billed annually`
                      : 'Billed annually upfront'}
                  </div>
                )}
              </div>

              {/* Description Section with Feature Benefits */}
              <div className="text-gray-600 mb-6 flex-grow">
                {/* Special display for All Features */}
                {feature.code === FEATURES.ALL_FEATURES && (
                  <div className="mb-3">
                    <p className="font-medium text-blue-800">
                      Complete construction management platform with unlimited users and custom integrations
                    </p>
                  </div>
                )}

                {/* Display feature benefits for standard feature types */}
                {!isEnterpriseMode && getFeatureBenefits(feature.code).length > 0 && (
                  <div className="mt-2 space-y-2">
                    {getFeatureBenefits(feature.code).map((benefit, index) => (
                      <div key={index} className="flex items-start">
                        <Check className="h-5 w-5 text-blue-500 mr-2 flex-shrink-0 mt-0.5" />
                        <div>
                          <span className="font-medium text-gray-700">{benefit.title}:</span>{' '}
                          <span className="text-gray-600">{benefit.description}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                )}

                {/* Default description if no benefits available */}
                {getFeatureBenefits(feature.code).length === 0 && (
                  <p>{feature.name?.split(' & ')[0]?.toLowerCase() || ''} management features</p>
                )}
              </div>

              {/* Pricing Section */}
              <div className="pt-4 border-t border-gray-200">
                <div className="flex justify-between items-center">
                  <div>
                    <p className="text-gray-600 font-medium">
                      {feature.code === FEATURES.ALL_FEATURES
                        ? 'Enterprise: Unlimited Users'
                        : (feature.pricing_type === 'PER_USER' ? 'Up to 35 users' : 'Unlimited users')}
                    </p>
                  </div>
                  {(formData.billing_cycle === 'yearly' || feature.code === FEATURES.ALL_FEATURES) && (
                    <div className={`${feature.code === FEATURES.ALL_FEATURES ? 'bg-blue-100 text-blue-800' : 'bg-green-100 text-green-800'} px-2 py-1 rounded text-xs font-medium`}>
                      {feature.code === FEATURES.ALL_FEATURES ? 'ENTERPRISE' : 'Save up 25%'}
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      {formData.feature_type &&
        !isEnterpriseMode &&
        displayedFeatureTypes.find(f => f.id === formData.feature_type)?.pricing_type === 'PER_USER' && (
          <div className="mt-8 p-6 bg-gray-50 rounded-lg border border-gray-200">
            <div className="flex flex-col sm:flex-row justify-between items-center gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Number of Users
                </label>
                <input
                  type="number"
                  min="1"
                  max={35}
                  value={formData.user_count}
                  onChange={handleUserCountChange}
                  className="w-24 p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>

              <div className="text-center">
                <div className="text-gray-700 font-bold mb-1">Total Price</div>
                {promotion && promotion.isLifetime && promotion.monthlyFixedPrice && formData.billing_cycle === 'monthly' ? (
                  <div>
                    <span className="line-through text-gray-500 mr-1">${(MONTHLY_PRICE * formData.user_count).toFixed(2)}</span>
                    <div className="text-2xl font-bold text-green-600">${(promotion.monthlyFixedPrice * formData.user_count).toFixed(2)}</div>
                  </div>
                ) : (
                  <div className="text-2xl font-bold text-blue-600">${formatCurrency(totalPrice)}</div>
                )}
                <div className="text-sm text-gray-600">
                  {formData.billing_cycle === 'yearly' ? 'per year' : 'per month'}
                </div>
                {formData.billing_cycle === 'yearly' && (
                  <div className="text-xs text-gray-500 mt-1">Billed annually upfront</div>
                )}
              </div>
            </div>
          </div>
        )}
    </div>
  );

  const renderAccountForm = () => (
    <div className="space-y-6">
      <h3 className="text-xl font-semibold text-center">Create Your Account</h3>

      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Email
          </label>
          <input
            type="email"
            name="email"
            placeholder="your@email.com"
            value={formData.email}
            onChange={handleChange}
            className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
            disabled={loading}
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Password
          </label>
          <input
            type="password"
            name="password"
            placeholder="Minimum 12 characters"
            value={formData.password}
            onChange={handleChange}
            className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
            disabled={loading}
          />
        </div>
      </div>
    </div>
  );

  const renderProgressBar = () => {
    const steps = [
      { id: 1, name: 'Features' },
      { id: 2, name: 'Account' }
    ];

    return (
      <div className="mb-8">
        <div className="flex items-center justify-between">
          {steps.map((s, i) => (
            <React.Fragment key={s.id}>
              <div className="flex flex-col items-center">
                <div className={`w-8 h-8 flex items-center justify-center rounded-full ${step >= s.id ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'
                  }`}>
                  {step > s.id ? (
                    <CheckCircle className="h-5 w-5" />
                  ) : (
                    s.id
                  )}
                </div>
                <span className="text-xs mt-1">{s.name}</span>
              </div>

              {i < steps.length - 1 && (
                <div className={`flex-1 h-1 mx-2 ${step > i + 1 ? 'bg-blue-500' : 'bg-gray-200'
                  }`} />
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    );
  };

  const renderCurrentStep = () => {
    switch (step) {
      case 1:
        return renderFeatureSelection();
      case 2:
        return renderAccountForm();
      default:
        return null;
    }
  };

  const canProceed = () => {
    if (step === 1) {
      return !!formData.feature_type;
    }
    if (step === 2) {
      return formData.email && formData.password && formData.password.length >= 12;
    }
    return false;
  };

  const handleNext = () => {
    if (step === 1) {
      setStep(2);
    } else if (step === 2) {
      handleSubmit();
    }
  };

  const handleBack = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  return (
    <div className="min-h-screen w-[100%] max-w-7xl bg-gray-50 flex justify-center items-center p-4">
      <div className="w-full max-w-5xl rounded-lg shadow-lg">
        <div className="p-8">
          <h2 className="text-3xl font-bold text-center mb-8">
            {customTitle ? customTitle : "BUILDIFYPRO"}
          </h2>
          {renderProgressBar()}

          {error && (
            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4">
              <span className="block sm:inline">{error}</span>
              {helpText && <span className="block text-sm mt-1">{helpText}</span>}
            </div>
          )}

          {renderCurrentStep()}

          <div className="flex justify-between mt-8">
            {step > 1 && (
              <button
                type="button"
                onClick={handleBack}
                disabled={loading}
                className="px-4 py-2 border border-gray-300 rounded-md hover:bg-gray-50"
              >
                Back
              </button>
            )}

            <button
              type="button"
              onClick={handleNext}
              disabled={!canProceed() || loading}
              className="px-6 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 disabled:opacity-50 disabled:cursor-not-allowed flex items-center gap-2"
            >
              {loading && step === 2 ? (
                <>
                  <Loader2 className="h-5 w-5 animate-spin" />
                  Processing...
                </>
              ) : (
                step === 2 ? 'Create Account' : 'Next'
              )}
            </button>
          </div>

          <p className="mt-6 text-center text-gray-600">
            Already have an account? <Link to="/login" className="text-blue-500 hover:underline">Login</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Signup;