import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import {
    Camera,
    CheckSquare,
    XSquare,
    Trash2,
    AlertCircle,
    MapPin,
    CloudSun,
    Save,
    Clock,
    CheckCircle2,
    XCircle
} from 'lucide-react';
import secureLocalStorage from "react-secure-storage";
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
} from "../../ui/alert-dialog";
import {
    Card,
    CardContent,
    CardHeader,
} from "../../ui/cardui";
import { Button } from "../../ui/button";
import { Label } from "../../ui/label";
import {
    Alert,
    AlertDescription,
} from "../../ui/alert";
import { Input } from "../../ui/input";
import { showToast } from '../../../utils/toast';
import api from '../../../services/api';
import { primaryButtonClasses, secondaryButtonClasses } from '../../common/ButtonStyles';

const MobileInspectionForm = ({
    template,
    projectId,
    phaseId,
    inspection,
    onSubmit,
    onCancel
}) => {
    const [formData, setFormData] = useState({
        template: template?.id,
        project: projectId,
        phase: phaseId,
        scheduled_date: inspection?.scheduled_date || format(new Date(), 'yyyy-MM-dd'),
        status: inspection?.status || 'in_progress',
        results: inspection?.results || {},
        notes: inspection?.notes || '',
        weather_conditions: inspection?.weather_conditions || {
            temperature: '',
            conditions: '',
            wind_speed: ''
        },
        location_data: inspection?.location_data || null,
        client_visible: inspection?.client_visible ?? true
    });

    const [activeSection, setActiveSection] = useState('info');
    const [uploadingPhotos, setUploadingPhotos] = useState(false);
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [locationStatus, setLocationStatus] = useState('idle');
    const [photoToDelete, setPhotoToDelete] = useState(null);
    const [showDeletePhotoDialog, setShowDeletePhotoDialog] = useState(false);

    useEffect(() => {
        if (template?.checklist_items) {
            const initialResults = {};
            template.checklist_items.forEach(item => {
                initialResults[item.id] = {
                    name: item.name,
                    passed: false,
                    notes: '',
                    photos: [],
                    ...inspection?.results?.[item.id]
                };
            });

            setFormData(prev => ({
                ...prev,
                results: initialResults
            }));
        }
    }, [template, inspection]);

    // Get current location
    useEffect(() => {
        if (navigator.geolocation && !formData.location_data) {
            setLocationStatus('loading');
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setFormData(prev => ({
                        ...prev,
                        location_data: {
                            latitude: position.coords.latitude,
                            longitude: position.coords.longitude,
                            accuracy: position.coords.accuracy
                        }
                    }));
                    setLocationStatus('success');
                },
                (error) => {
                    console.error('Error getting location:', error);
                    setLocationStatus('error');
                }
            );
        }
    }, []);

    useEffect(() => {
        const fetchWeather = async () => {
            if (formData.location_data && !formData.weather_conditions.temperature) {
                try {
                    // Simplified mock weather for demo - in production, use a weather API
                    setFormData(prev => ({
                        ...prev,
                        weather_conditions: {
                            temperature: Math.round(15 + Math.random() * 10),
                            conditions: ['Sunny', 'Cloudy', 'Partially Cloudy', 'Rainy'][Math.floor(Math.random() * 4)],
                            wind_speed: Math.round(5 + Math.random() * 20)
                        }
                    }));
                } catch (error) {
                    console.error('Failed to fetch weather data:', error);
                }
            }
        };

        fetchWeather();
    }, [formData.location_data]);

    const handleCompleteInspection = async (shouldPass) => {
        try {
            // First update the form status
            const newStatus = shouldPass ? 'passed' : 'failed';
            setFormData(prev => ({
                ...prev,
                status: newStatus,
                completed_date: new Date().toISOString()
            }));

            // Prepare data for submission
            const formattedResults = {};
            Object.entries(formData.results).forEach(([itemKey, itemData]) => {
                formattedResults[itemKey] = {
                    name: itemData.name,
                    passed: itemData.passed || false,
                    notes: itemData.notes || "",
                    photos: itemData.photos || []
                };

                // Also add entry with item name as key for backend validation
                formattedResults[itemData.name] = {
                    name: itemData.name,
                    passed: itemData.passed || false,
                    notes: itemData.notes || "",
                    photos: itemData.photos || []
                };
            });

            const submissionData = {
                ...formData,
                status: newStatus,
                results: formattedResults,
                completed_date: new Date().toISOString()
            };

            // Submit form
            await onSubmit(submissionData);

            // Call the complete inspection endpoint
            const tenantId = localStorage.getItem('tenantIdentifier') ||
                secureLocalStorage.getItem('currentTenantId');

            if (tenantId && inspection?.id) {
                await api.post(
                    `projects/${projectId}/inspections/${inspection.id}/complete_inspection/`,
                    {},
                    {
                        headers: {
                            'X-Tenant-ID': tenantId,
                            'Content-Type': 'application/json'
                        }
                    }
                );

                showToast.success('Inspection completed successfully');
            }
        } catch (error) {
            console.error('Error completing inspection:', error);
            showToast.error('Failed to complete inspection: ' +
                (error.response?.data?.detail || error.message));
        }
    };

    const handleInputChange = (field, value) => {
        setFormData(prev => ({
            ...prev,
            [field]: value
        }));

        if (errors[field]) {
            setErrors(prev => ({
                ...prev,
                [field]: null
            }));
        }
    };

    const handleChecklistChange = (itemId, field, value) => {
        setFormData(prev => ({
            ...prev,
            results: {
                ...prev.results,
                [itemId]: {
                    ...prev.results[itemId],
                    [field]: value,
                    name: template.checklist_items.find(item => item.id === itemId)?.name || prev.results[itemId].name
                }
            }
        }));
    };

    const handlePhotoCapture = async (itemId) => {
        // For mobile browsers, we'll use the device camera directly
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = 'image/*';
        fileInput.capture = 'environment'; // Use rear camera by default

        fileInput.onchange = async (event) => {
            const file = event.target.files[0];
            if (!file) return;

            setUploadingPhotos(true);
            setErrors(prev => ({ ...prev, [`photo_${itemId}`]: null }));

            try {
                // 1. Create a compressed version for upload
                const compressedFile = await compressImage(file, 800); // 800px max width/height

                // 2. Upload the compressed image using document management endpoint
                const uploadFormData = new FormData();
                uploadFormData.append('file', compressedFile);
                uploadFormData.append('title', `Inspection Photo - Item ${itemId}`);
                uploadFormData.append('file_type', 'file');
                uploadFormData.append('type', 'Inspection Report');
                uploadFormData.append('client_visible', 'True');

                // Use the correct document management endpoint
                const response = await api.post(
                    `document-management/files/${projectId}/`,
                    uploadFormData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                );

                const photoData = response.data || response;

                // 3. Add to the results - make sure we're accessing data correctly
                const currentPhotos = formData.results[itemId]?.photos || [];

                handleChecklistChange(itemId, 'photos', [
                    ...currentPhotos,
                    {
                        url: photoData.file || photoData.s3_path,
                        id: photoData.id,
                        category: 'inspection',
                        taken_at: new Date().toISOString(),
                        caption: file.name
                    }
                ]);

                showToast.success('Photo captured successfully');
            } catch (error) {
                console.error('Error capturing photo:', error);
                setErrors(prev => ({
                    ...prev,
                    [`photo_${itemId}`]: 'Failed to save photo. Please try again.'
                }));
                showToast.error('Failed to save photo: ' + (error.response?.data?.detail || error.message));
            } finally {
                setUploadingPhotos(false);
            }
        };

        fileInput.click();
    };

    const compressImage = (file, maxDimension) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (event) => {
                const img = new Image();
                img.src = event.target.result;
                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    let width = img.width;
                    let height = img.height;

                    if (width > height && width > maxDimension) {
                        height = Math.round(height * maxDimension / width);
                        width = maxDimension;
                    } else if (height > maxDimension) {
                        width = Math.round(width * maxDimension / height);
                        height = maxDimension;
                    }

                    canvas.width = width;
                    canvas.height = height;
                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(img, 0, 0, width, height);

                    // Convert to Blob with reduced quality
                    canvas.toBlob(
                        (blob) => {
                            // Create a new file with the compressed data
                            const compressedFile = new File(
                                [blob],
                                file.name,
                                { type: 'image/jpeg', lastModified: Date.now() }
                            );
                            resolve(compressedFile);
                        },
                        'image/jpeg',
                        0.6
                    );
                };
                img.onerror = reject;
            };
            reader.onerror = reject;
        });
    };

    const handleDeletePhoto = () => {
        if (!photoToDelete) return;

        setFormData(prev => ({
            ...prev,
            results: {
                ...prev.results,
                [photoToDelete.itemId]: {
                    ...prev.results[photoToDelete.itemId],
                    photos: prev.results[photoToDelete.itemId].photos.filter((_, idx) => idx !== photoToDelete.photoIndex)
                }
            }
        }));

        setPhotoToDelete(null);
        setShowDeletePhotoDialog(false);
    };

    const validateForm = () => {
        const newErrors = {};

        if (!formData.scheduled_date) {
            newErrors.scheduled_date = 'Scheduled date is required';
        }

        if (template) {
            template.checklist_items.forEach((item) => {
                if (item.required) {
                    const result = formData.results[item.id];

                    if (!result) {
                        newErrors[`item_${item.id}`] = 'This item requires a response';
                    } else {
                        if (item.photo_required && (!result.photos || result.photos.length === 0)) {
                            newErrors[`photo_${item.id}`] = 'Photo is required for this item';
                        }
                        if (item.notes_required && !result.notes) {
                            newErrors[`notes_${item.id}`] = 'Notes are required for this item';
                        }
                    }
                }
            });
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) {
            showToast.error('Please fill in all required fields');
            return;
        }
        try {
            setIsSubmitting(true);

            // Format the results object correctly for the API
            const formattedResults = {};

            // First, copy existing results (which use item-X format)
            Object.entries(formData.results).forEach(([itemKey, itemData]) => {
                formattedResults[itemKey] = {
                    name: itemData.name,
                    passed: itemData.passed || false,
                    notes: itemData.notes || "",
                    photos: itemData.photos || []
                };

                // Also add an entry with the item name as the key
                // This is crucial for backend validation
                formattedResults[itemData.name] = {
                    name: itemData.name,
                    passed: itemData.passed || false,
                    notes: itemData.notes || "",
                    photos: itemData.photos || []
                };
            });

            // Make sure all template items are included
            if (template?.checklist_items) {
                template.checklist_items.forEach(item => {
                    const itemName = item.name;

                    // If name doesn't exist in results yet, add it
                    if (!formattedResults[itemName]) {
                        formattedResults[itemName] = {
                            name: itemName,
                            passed: false,
                            notes: "",
                            photos: []
                        };
                    }
                });
            }

            const submissionData = {
                ...formData,
                results: formattedResults,
                weather_conditions: formData.weather_conditions || {
                    temperature: 72,
                    conditions: "Clear",
                    wind_speed: 5
                },
                location_data: formData.location_data || {
                    latitude: 37.7749,
                    longitude: -122.4194
                },
                completed_date: formData.status === 'passed' || formData.status === 'failed'
                    ? new Date().toISOString()
                    : null
            };

            // Add debug logging to verify the request structure
            console.log("Submitting inspection data:", JSON.stringify(submissionData, null, 2));
            console.log("Check for PPE Compliance:",
                Object.keys(submissionData.results).includes("PPE Compliance"));

            await onSubmit(submissionData);
        } catch (error) {
            console.error('Error submitting form:', error);
            console.error('Error response data:', error.response?.data);
            showToast.error('Failed to submit inspection: ' +
                (error.response?.data?.detail ||
                    error.response?.data?.non_field_errors?.[0] ||
                    error.message));
        } finally {
            setIsSubmitting(false);
        }
    };

    const renderSectionNav = () => (
        <div className="flex overflow-x-auto gap-2 pb-2 sticky top-0 bg-white z-10 border-b mb-4">
            <Button
                type="button"
                size="sm"
                variant={activeSection === 'info' ? 'default' : 'outline'}
                onClick={() => setActiveSection('info')}
                className="min-w-[120px] rounded-full"
            >
                Basic Info
            </Button>
            <Button
                type="button"
                size="sm"
                variant={activeSection === 'checklist' ? 'default' : 'outline'}
                onClick={() => setActiveSection('checklist')}
                className="min-w-[120px] rounded-full"
            >
                Checklist
            </Button>
            <Button
                type="button"
                size="sm"
                variant={activeSection === 'summary' ? 'default' : 'outline'}
                onClick={() => setActiveSection('summary')}
                className="min-w-[120px] rounded-full"
            >
                Summary
            </Button>
        </div>
    );

    // Basic information section
    const renderBasicInfo = () => (
        <Card className="mb-6">
            <CardContent className="pt-6">
                <div className="space-y-6">
                    <div>
                        <Label htmlFor="inspection_date" className="text-base">
                            Inspection Date <span className="text-red-500">*</span>
                        </Label>
                        <Input
                            id="inspection_date"
                            type="date"
                            value={formData.scheduled_date}
                            onChange={(e) => handleInputChange('scheduled_date', e.target.value)}
                            className="mt-1 h-12 text-lg"
                            error={errors.scheduled_date}
                        />
                        {errors.scheduled_date && (
                            <p className="text-sm text-red-600 mt-1">{errors.scheduled_date}</p>
                        )}
                    </div>

                    <div>
                        <Label htmlFor="notes" className="text-base">General Notes</Label>
                        <textarea
                            id="notes"
                            className="w-full rounded-md border-gray-300 mt-1 p-4 text-base min-h-[120px]"
                            value={formData.notes}
                            onChange={(e) => handleInputChange('notes', e.target.value)}
                        />
                    </div>

                    {/* Location & Weather Card */}
                    <div className="bg-gray-50 rounded-xl p-4 space-y-4">
                        <div className="flex items-center gap-2">
                            <MapPin className="w-5 h-5 text-gray-500" />
                            <span className="font-medium">Location</span>

                            {locationStatus === 'loading' && (
                                <span className="text-sm text-gray-500 ml-auto">Getting location...</span>
                            )}

                            {locationStatus === 'error' && (
                                <Button
                                    size="sm"
                                    variant="outline"
                                    className="ml-auto"
                                    onClick={() => {
                                        setLocationStatus('loading');
                                        navigator.geolocation.getCurrentPosition(
                                            (position) => {
                                                setFormData(prev => ({
                                                    ...prev,
                                                    location_data: {
                                                        latitude: position.coords.latitude,
                                                        longitude: position.coords.longitude,
                                                        accuracy: position.coords.accuracy
                                                    }
                                                }));
                                                setLocationStatus('success');
                                            },
                                            () => setLocationStatus('error')
                                        );
                                    }}
                                >
                                    Retry
                                </Button>
                            )}
                        </div>

                        {formData.location_data && (
                            <div className="text-sm pl-7">
                                <p>Lat: {formData.location_data.latitude.toFixed(6)}</p>
                                <p>Long: {formData.location_data.longitude.toFixed(6)}</p>
                            </div>
                        )}

                        <div className="flex items-center gap-2 pt-2 border-t">
                            <CloudSun className="w-5 h-5 text-gray-500" />
                            <span className="font-medium">Weather</span>
                        </div>

                        {formData.weather_conditions.temperature ? (
                            <div className="text-sm pl-7">
                                <p>{formData.weather_conditions.temperature}°C, {formData.weather_conditions.conditions}</p>
                                <p>Wind: {formData.weather_conditions.wind_speed} km/h</p>
                            </div>
                        ) : (
                            <p className="text-sm text-gray-500 pl-7">Fetching weather data...</p>
                        )}
                    </div>
                </div>
            </CardContent>
        </Card>
    );

    // Checklist section (the most important for field workers)
    const renderChecklist = () => (
        <div className="space-y-4">
            {template?.checklist_items?.map((item, index) => (
                <Card key={index} className="mb-4 overflow-hidden">
                    <CardHeader className="pb-2 bg-gray-50">
                        <div className="flex justify-between items-center">
                            <h4 className="font-medium text-base">{item.name}</h4>
                            {item.required && (
                                <span className="text-xs text-red-500 font-medium">Required</span>
                            )}
                        </div>
                    </CardHeader>
                    <CardContent className="space-y-4 pt-4">
                        {/* Pass/Fail Buttons - Large and touch-friendly */}
                        <div className="flex items-center justify-center gap-4">
                            <Button
                                type="button"
                                className={`flex-1 h-16 ${formData.results[item.id]?.passed ?
                                    'bg-green-500 hover:bg-green-600' : 'bg-gray-200 text-gray-700'}`}
                                onClick={() => handleChecklistChange(item.id, 'passed', true)}
                            >
                                <CheckSquare className="w-6 h-6 mr-2" />
                                PASS
                            </Button>
                            <Button
                                type="button"
                                className={`flex-1 h-16 ${!formData.results[item.id]?.passed ?
                                    'bg-red-500 hover:bg-red-600' : 'bg-gray-200 text-gray-700'}`}
                                onClick={() => handleChecklistChange(item.id, 'passed', false)}
                            >
                                <XSquare className="w-6 h-6 mr-2" />
                                FAIL
                            </Button>
                        </div>

                        {errors[`item_${item.id}`] && (
                            <Alert variant="destructive">
                                <AlertCircle className="h-4 w-4" />
                                <AlertDescription>{errors[`item_${item.id}`]}</AlertDescription>
                            </Alert>
                        )}

                        {/* Notes Field */}
                        <div>
                            <div className="flex justify-between">
                                <Label className="text-base">
                                    Notes {item.notes_required && <span className="text-red-500">*</span>}
                                </Label>
                                {formData.results[item.id]?.passed === false && (
                                    <span className="text-red-500 text-sm">Explain why this failed</span>
                                )}
                            </div>
                            <textarea
                                className="w-full rounded-md border-gray-300 mt-1 min-h-[80px] p-3 text-base"
                                value={formData.results[item.id]?.notes || ''}
                                onChange={(e) => handleChecklistChange(item.id, 'notes', e.target.value)}
                            />
                            {errors[`notes_${item.id}`] && (
                                <p className="text-sm text-red-500 mt-1">{errors[`notes_${item.id}`]}</p>
                            )}
                        </div>

                        {/* Camera Button and Photos */}
                        <div>
                            <Label className="text-base">
                                Photos {item.photo_required && <span className="text-red-500">*</span>}
                            </Label>
                            <div className="mt-2 space-y-3">
                                <Button
                                    type="button"
                                    className="w-full h-14 bg-blue-600 hover:bg-blue-700"
                                    disabled={uploadingPhotos}
                                    onClick={() => handlePhotoCapture(item.id)}
                                >
                                    <Camera className="w-6 h-6 mr-2" />
                                    {uploadingPhotos ? 'Saving...' : 'Take Photo'}
                                </Button>

                                <div className="grid grid-cols-2 gap-2">
                                    {formData.results[item.id]?.photos?.map((photo, photoIndex) => (
                                        <div key={photoIndex} className="relative">
                                            <img
                                                src={photo.url || photo}
                                                alt={photo.caption || `Inspection photo ${photoIndex + 1}`}
                                                className="w-full h-32 object-cover rounded-lg"
                                            />
                                            <Button
                                                type="button"
                                                variant="destructive"
                                                size="sm"
                                                className="absolute -top-2 -right-2 rounded-full w-8 h-8 p-0"
                                                onClick={() => {
                                                    setPhotoToDelete({ itemId: item.id, photoIndex });
                                                    setShowDeletePhotoDialog(true);
                                                }}
                                            >
                                                <Trash2 className="w-4 h-4" />
                                            </Button>
                                        </div>
                                    ))}
                                </div>

                                {errors[`photo_${item.id}`] && (
                                    <Alert variant="destructive">
                                        <AlertCircle className="h-4 w-4" />
                                        <AlertDescription>{errors[`photo_${item.id}`]}</AlertDescription>
                                    </Alert>
                                )}
                            </div>
                        </div>
                    </CardContent>
                </Card>
            ))}
        </div>
    );

    // Summary Section
    const renderSummary = () => (
        <Card>
            <CardContent className="pt-6">
                <div className="space-y-6">
                    <div>
                        <Label className="text-base">Overall Status</Label>
                        <div className="flex flex-col gap-3 mt-2">
                            <Button
                                type="button"
                                className={`h-14 justify-start px-4 ${formData.status === 'passed' ?
                                    'bg-green-500 hover:bg-green-600' : 'bg-gray-200 text-gray-700'}`}
                                onClick={() => handleInputChange('status', 'passed')}
                            >
                                <CheckCircle2 className="w-6 h-6 mr-3" />
                                <span className="text-lg">Mark as PASSED</span>
                            </Button>

                            <Button
                                type="button"
                                className={`h-14 justify-start px-4 ${formData.status === 'failed' ?
                                    'bg-red-500 hover:bg-red-600' : 'bg-gray-200 text-gray-700'}`}
                                onClick={() => handleInputChange('status', 'failed')}
                            >
                                <XCircle className="w-6 h-6 mr-3" />
                                <span className="text-lg">Mark as FAILED</span>
                            </Button>

                            <Button
                                type="button"
                                className={`h-14 justify-start px-4 ${formData.status === 'in_progress' ?
                                    'bg-yellow-500 hover:bg-yellow-600' : 'bg-gray-200 text-gray-700'}`}
                                onClick={() => handleInputChange('status', 'in_progress')}
                            >
                                <Clock className="w-6 h-6 mr-3" />
                                <span className="text-lg">SAVE AS DRAFT</span>
                            </Button>
                        </div>
                    </div>

                    <div>
                        <Label className="text-base">Summary Notes</Label>
                        <textarea
                            className="w-full rounded-md border-gray-300 mt-1 min-h-[120px] p-3 text-base"
                            value={formData.summary_notes || ''}
                            onChange={(e) => handleInputChange('summary_notes', e.target.value)}
                            placeholder="Provide a summary of the inspection findings..."
                        />
                    </div>

                    <div>
                        <Label className="text-base">Recommendations</Label>
                        <textarea
                            className="w-full rounded-md border-gray-300 mt-1 min-h-[100px] p-3 text-base"
                            value={formData.recommendations || ''}
                            onChange={(e) => handleInputChange('recommendations', e.target.value)}
                            placeholder="Add any recommendations based on the inspection..."
                        />
                    </div>

                    <div className="flex items-center space-x-2 mb-2">
                        <input
                            type="checkbox"
                            id="client_visible"
                            className="rounded border-gray-300 text-blue-600 focus:ring-blue-500 w-5 h-5"
                            checked={formData.client_visible}
                            onChange={(e) => handleInputChange('client_visible', e.target.checked)}
                        />
                        <Label htmlFor="client_visible" className="font-normal">
                            Share results with client
                        </Label>
                    </div>

                    <div className="mt-8 border-t pt-6">
                        <h3 className="text-lg font-medium mb-4">Complete This Inspection</h3>
                        <div className="flex flex-col gap-4">
                            <Button
                                type="button"
                                className="h-14 bg-green-600 hover:bg-green-700 text-white"
                                onClick={() => {
                                    if (window.confirm('Are you sure you want to complete this inspection as PASSED?')) {
                                        handleCompleteInspection(true);
                                    }
                                }}
                            >
                                <CheckCircle2 className="w-6 h-6 mr-2" />
                                Complete & Pass
                            </Button>

                            <Button
                                type="button"
                                className="h-14 bg-red-600 hover:bg-red-700 text-white"
                                onClick={() => {
                                    if (window.confirm('Are you sure you want to complete this inspection as FAILED?')) {
                                        handleCompleteInspection(false);
                                    }
                                }}
                            >
                                <XCircle className="w-6 h-6 mr-2" />
                                Complete & Fail
                            </Button>
                        </div>
                    </div>
                </div>
            </CardContent>
        </Card>
    );

    // Main render logic
    return (
        <form onSubmit={handleSubmit} className="pb-20">
            {renderSectionNav()}

            {/* Display active section */}
            {activeSection === 'info' && renderBasicInfo()}
            {activeSection === 'checklist' && renderChecklist()}
            {activeSection === 'summary' && renderSummary()}

            <div className="fixed bottom-0 left-0 right-0 p-4 bg-white border-t flex justify-between z-30">
                <Button
                    type="button"
                    variant="outline"
                    onClick={onCancel}
                    disabled={isSubmitting}
                    className={secondaryButtonClasses}
                >
                    Cancel
                </Button>
                <Button
                    type="submit"
                    disabled={isSubmitting}
                    className={primaryButtonClasses}
                >
                    <Save className="w-5 h-5 mr-2" />
                    {isSubmitting ? 'Submitting...' : (
                        formData.status === 'passed' ? 'Save as Passed' :
                            formData.status === 'failed' ? 'Save as Failed' :
                                'Save Progress'
                    )}
                </Button>
            </div>

            {/* Photo Delete Confirmation Dialog */}
            {photoToDelete && (
                <AlertDialog
                    open={showDeletePhotoDialog}
                    onOpenChange={setShowDeletePhotoDialog}
                >
                    <AlertDialogContent>
                        <AlertDialogHeader>
                            <AlertDialogTitle>Delete Photo</AlertDialogTitle>
                            <AlertDialogDescription>
                                Are you sure you want to delete this photo? This action cannot be undone.
                            </AlertDialogDescription>
                        </AlertDialogHeader>
                        <AlertDialogFooter>
                            <AlertDialogCancel>Cancel</AlertDialogCancel>
                            <AlertDialogAction
                                className="bg-red-600 hover:bg-red-700 text-white"
                                onClick={handleDeletePhoto}
                            >
                                Delete
                            </AlertDialogAction>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialog>
            )}
        </form>
    );
};

export default MobileInspectionForm;