import React, { useState, useEffect } from 'react';
import {
    FolderIcon,
    DocumentIcon,
    DocumentTextIcon,
    PhotoIcon,
    CloudArrowDownIcon,
    MagnifyingGlassIcon,
    FunnelIcon,
    ClockIcon,
    ExclamationTriangleIcon,
    XMarkIcon
} from '@heroicons/react/24/outline';
import { format } from 'date-fns';
import api from '../../services/api';
import { showToast } from '../../utils/toast';
import { Breadcrumb } from '../common/Breadcrumb';
import ContractorInviteForm from '../Contractors/ContractorInviteForm';
import BackButton from '../common/BackButton';

const ClientDocumentManagement = ({ projectId }) => {
    const [documents, setDocuments] = useState([]);
    const [currentFolder, setCurrentFolder] = useState(null);
    const [breadcrumbs, setBreadcrumbs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const [filters, setFilters] = useState({
        type: [],
        dateRange: 'all',
        status: 'all'
    });
    const [sortBy, setSortBy] = useState('date');
    const [groupedDocuments, setGroupedDocuments] = useState({});
    const [view, setView] = useState('grid');
    const [selectedDocument, setSelectedDocument] = useState(null);

    // Document type icons mapping
    const documentIcons = {
        'blueprint': DocumentIcon,
        'contract': DocumentTextIcon,
        'photo': PhotoIcon,
        'report': DocumentTextIcon,
        'permit': DocumentIcon,
        'folder': FolderIcon
    };

    useEffect(() => {
        fetchDocuments();
    }, [projectId, currentFolder, filters]);



    const fetchDocuments = async () => {
        try {
            setLoading(true);
            const response = await api.get(`client-portal/projects/${projectId}/documents`, {
                params: {
                    folder: currentFolder,
                    search: searchQuery,
                    ...filters
                }
            });

            // Group documents by type
            const grouped = response.data.reduce((acc, doc) => {
                if (!acc[doc.type]) {
                    acc[doc.type] = [];
                }
                acc[doc.type].push(doc);
                return acc;
            }, {});

            setGroupedDocuments(grouped);
            setDocuments(response.data);
        } catch (error) {
            console.error('Failed to load documents:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleDownload = async (document) => {
        try {
            const response = await api.get(`client-portal/documents/${document.id}/download`);

            // Create temporary link and trigger download
            const link = document.createElement('a');
            link.href = response.data.downloadUrl;
            link.download = document.title;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Failed to download document:', error);
        }
    };

    const navigateToFolder = (folder) => {
        setCurrentFolder(folder.id);
        setBreadcrumbs(prev => [...prev, folder]);
    };

    const navigateBreadcrumb = (index) => {
        const folder = index === 0 ? null : breadcrumbs[index - 1];
        setCurrentFolder(folder?.id || null);
        setBreadcrumbs(prev => prev.slice(0, index));
    };

    const FilterPanel = () => (
        <div className="bg-white rounded-lg shadow-sm p-4 mb-6">
            <div className="flex items-center justify-between mb-4">
                <h3 className="text-lg font-medium text-gray-900">Filters</h3>
                <button
                    onClick={() => setFilters({
                        type: [],
                        dateRange: 'all',
                        status: 'all'
                    })}
                    className="text-sm text-blue-600 hover:text-blue-700"
                >
                    Reset Filters
                </button>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                {/* Document Type Filter */}
                <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                        Document Type
                    </label>
                    <div className="space-y-2">
                        {['blueprint', 'contract', 'photo', 'report', 'permit'].map((type) => (
                            <label key={type} className="flex items-center">
                                <input
                                    type="checkbox"
                                    checked={filters.type.includes(type)}
                                    onChange={(e) => {
                                        const newTypes = e.target.checked
                                            ? [...filters.type, type]
                                            : filters.type.filter(t => t !== type);
                                        setFilters(prev => ({ ...prev, type: newTypes }));
                                    }}
                                    className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                                />
                                <span className="ml-2 text-sm text-gray-600 capitalize">
                                    {type}
                                </span>
                            </label>
                        ))}
                    </div>
                </div>

                {/* Date Range Filter */}
                <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                        Date Range
                    </label>
                    <select
                        value={filters.dateRange}
                        onChange={(e) => setFilters(prev => ({
                            ...prev,
                            dateRange: e.target.value
                        }))}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    >
                        <option value="all">All Time</option>
                        <option value="today">Today</option>
                        <option value="week">This Week</option>
                        <option value="month">This Month</option>
                        <option value="quarter">This Quarter</option>
                    </select>
                </div>

                {/* Status Filter */}
                <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                        Status
                    </label>
                    <select
                        value={filters.status}
                        onChange={(e) => setFilters(prev => ({
                            ...prev,
                            status: e.target.value
                        }))}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    >
                        <option value="all">All Status</option>
                        <option value="current">Current Version</option>
                        <option value="archived">Archived</option>
                        <option value="pending">Pending Review</option>
                    </select>
                </div>
            </div>
        </div>
    );

    const DocumentGrid = () => (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
            {documents.map((document) => {
                const Icon = documentIcons[document.type] || DocumentIcon;

                return (
                    <div
                        key={document.id}
                        className="bg-white rounded-lg shadow-sm p-4 hover:shadow-md transition-shadow duration-200"
                    >
                        <div className="flex items-start justify-between">
                            <div className="flex-1">
                                <div className="flex items-center space-x-2">
                                    <Icon className="h-6 w-6 text-gray-400" />
                                    <h3 className="text-sm font-medium text-gray-900 truncate">
                                        {document.title}
                                    </h3>
                                </div>
                                <p className="mt-1 text-xs text-gray-500">
                                    Version {document.version}
                                </p>
                            </div>
                            {document.type !== 'folder' && (
                                <button
                                    onClick={() => handleDownload(document)}
                                    className="p-2 text-gray-400 hover:text-gray-500"
                                >
                                    <CloudArrowDownIcon className="h-5 w-5" />
                                </button>
                            )}
                        </div>

                        <div className="mt-4 text-xs text-gray-500">
                            <p>Updated {format(new Date(document.updated_at), 'MMM d, yyyy')}</p>
                            <p className="mt-1">{document.size}</p>
                        </div>

                        {document.type === 'folder' ? (
                            <button
                                onClick={() => navigateToFolder(document)}
                                className="mt-4 w-full text-sm text-blue-600 hover:text-blue-700"
                            >
                                Open Folder
                            </button>
                        ) : (
                            <div className="mt-4 flex items-center space-x-2">
                                {document.requires_action && (
                                    <ExclamationTriangleIcon className="h-4 w-4 text-yellow-500" />
                                )}
                                {document.status === 'pending' && (
                                    <ClockIcon className="h-4 w-4 text-blue-500" />
                                )}
                                <span className={`text-xs font-medium ${document.status === 'current' ? 'text-green-600' :
                                    document.status === 'pending' ? 'text-blue-600' :
                                        'text-gray-600'
                                    }`}>
                                    {document.status.charAt(0).toUpperCase() + document.status.slice(1)}
                                </span>
                            </div>
                        )}
                    </div>
                );
            })}
        </div>
    );

    const DocumentList = () => (
        <div className="bg-white rounded-lg shadow-sm overflow-hidden">
            <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                    <tr>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Name
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Type
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Updated
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Size
                        </th>
                        <th scope="col" className="relative px-6 py-3">
                            <span className="sr-only">Actions</span>
                        </th>
                    </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                    {documents.map((document) => {
                        const Icon = documentIcons[document.type] || DocumentIcon;

                        return (
                            <tr key={document.id}>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    <div className="flex items-center">
                                        <Icon className="h-5 w-5 text-gray-400" />
                                        <div className="ml-4">
                                            <div className="text-sm font-medium text-gray-900">
                                                {document.title}
                                            </div>
                                            <div className="text-sm text-gray-500">
                                                Version {document.version}
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    <span className="text-sm text-gray-900 capitalize">
                                        {document.type}
                                    </span>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {format(new Date(document.updated_at), 'MMM d, yyyy')}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {document.size}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                    {document.type === 'folder' ? (
                                        <button
                                            onClick={() => navigateToFolder(document)}
                                            className="text-blue-600 hover:text-blue-700"
                                        >
                                            Open
                                        </button>
                                    ) : (
                                        <button
                                            onClick={() => handleDownload(document)}
                                            className="text-blue-600 hover:text-blue-700"
                                        >
                                            Download
                                        </button>
                                    )}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );

    return (
        <div className="space-y-6">
            <BackButton />
            {/* Header */}
            <div className="flex justify-between items-center">
                <h2 className="text-2xl font-bold text-gray-900">Project Documents</h2>
                <div className="flex items-center space-x-4">
                    <button
                        onClick={() => setView(view === 'grid' ? 'list' : 'grid')}
                        className="p-2 text-gray-400 hover:text-gray-500"
                    >
                        {view === 'grid' ? 'List View' : 'Grid View'}
                    </button>
                </div>
            </div>

            {/* Search and Filters */}
            <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
                <div className="flex-1">
                    <div className="relative">
                        <input
                            type="text"
                            placeholder="Search documents..."
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 pl-10"
                        />
                        <MagnifyingGlassIcon className="h-5 w-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" />
                    </div>
                </div>
                <button
                    onClick={() => document.getElementById('filterPanel').classList.toggle('hidden')}
                    className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                >
                    <FunnelIcon className="h-5 w-5 mr-2" />
                    Filters
                </button>
            </div>

            {/* Filter Panel */}
            <div id="filterPanel" className="hidden">
                <FilterPanel />
            </div>

            {/* Breadcrumb Navigation */}
            {/* <Breadcrumb
                items={[
                    { label: 'Documents', onClick: () => navigateBreadcrumb(0) },
                    ...breadcrumbs.map((folder, index) => ({
                        label: folder.title,
                        onClick: () => navigateBreadcrumb(index + 1)
                    }))
                ]}
            /> */}

            {/* Document Grid/List */}
            {loading ? (
                <div className="flex justify-center items-center h-64">
                    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600" />
                </div>
            ) : documents.length === 0 ? (
                <div className="text-center py-12">
                    <DocumentIcon className="mx-auto h-12 w-12 text-gray-400" />
                    <h3 className="mt-2 text-sm font-medium text-gray-900">
                        No documents found
                    </h3>
                    <p className="mt-1 text-sm text-gray-500">
                        {searchQuery
                            ? 'Try adjusting your search or filters'
                            : 'Documents shared with you will appear here'}
                    </p>
                </div>
            ) : (
                view === 'grid' ? <DocumentGrid /> : <DocumentList />
            )}

            {/* Document Preview Modal */}
            <DocumentPreviewModal
                isOpen={!!selectedDocument}
                document={selectedDocument}
                onClose={() => setSelectedDocument(null)}
            />
        </div>
    );
};

const DocumentPreviewModal = ({ isOpen, document, onClose }) => {
    const [loading, setLoading] = useState(true);
    const [previewUrl, setPreviewUrl] = useState(null);
    const [documentHistory, setDocumentHistory] = useState([]);
    const [selectedDocument, setSelectedDocument] = useState(null);

    useEffect(() => {
        if (document) {
            fetchDocumentDetails();
        }
    }, [document]);

    const handleDownload = async (document) => {
        try {
            const response = await api.get(`client-portal/documents/${document.id}/download/`);
            // Create download link
            const link = document.createElement('a');
            link.href = response.data.download_url;
            link.setAttribute('download', document.title);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            showToast.error('Failed to download document');
        }
    };

    const handleVersionPreview = async (version) => {
        try {
            const response = await api.get(`client-portal//documents/${version.id}/preview/`);
            setSelectedDocument({
                ...version,
                previewUrl: response.data.preview_url
            });
        } catch (error) {
            showToast.error('Failed to load version preview');
        }
    };

    const fetchDocumentDetails = async () => {
        try {
            setLoading(true);
            const [previewRes, historyRes] = await Promise.all([
                api.get(`client-portal/documents/${document.id}/preview/`),
                api.get(`client-portal/documents/${document.id}/history/`)
            ]);
            setPreviewUrl(previewRes.data.preview_url);
            setDocumentHistory(historyRes.data);
        } catch (error) {
            showToast.error('Failed to load document preview');
        } finally {
            setLoading(false);
        }
    };

    if (!isOpen || !document) return null;

    return (
        <div className="fixed inset-0 z-50 overflow-y-auto">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div
                    className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                    onClick={onClose}
                />

                <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full">
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        {/* Document Header */}
                        <div className="flex justify-between items-start mb-4">
                            <div>
                                <h3 className="text-lg font-medium text-gray-900">
                                    {document.title}
                                </h3>
                                <p className="mt-1 text-sm text-gray-500">
                                    Version {document.version} • Updated {format(new Date(document.updated_at), 'MMM d, yyyy')}
                                </p>
                            </div>
                            <button
                                onClick={onClose}
                                className="rounded-md text-gray-400 hover:text-gray-500"
                            >
                                <span className="sr-only">Close</span>
                                <XMarkIcon className="h-6 w-6" />
                            </button>
                        </div>

                        {/* Document Preview */}
                        <div className="mt-4">
                            {loading ? (
                                <div className="flex justify-center items-center h-96">
                                    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600" />
                                </div>
                            ) : (
                                <div className="h-96 bg-gray-100 rounded-lg overflow-hidden">
                                    {document.type === 'photo' ? (
                                        <img
                                            src={previewUrl}
                                            alt={document.title}
                                            className="w-full h-full object-contain"
                                        />
                                    ) : (
                                        <iframe
                                            src={previewUrl}
                                            className="w-full h-full"
                                            title={document.title}
                                        />
                                    )}
                                </div>
                            )}
                        </div>

                        {/* Document Details */}
                        <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 gap-6">
                            {/* Document Info */}
                            <div>
                                <h4 className="text-sm font-medium text-gray-900">
                                    Document Information
                                </h4>
                                <dl className="mt-2 space-y-2">
                                    <div>
                                        <dt className="text-sm text-gray-500">Type</dt>
                                        <dd className="text-sm text-gray-900 capitalize">
                                            {document.type}
                                        </dd>
                                    </div>
                                    <div>
                                        <dt className="text-sm text-gray-500">Size</dt>
                                        <dd className="text-sm text-gray-900">{document.size}</dd>
                                    </div>
                                    <div>
                                        <dt className="text-sm text-gray-500">Created</dt>
                                        <dd className="text-sm text-gray-900">
                                            {format(new Date(document.created_at), 'MMM d, yyyy')}
                                        </dd>
                                    </div>
                                    {document.client_notes && (
                                        <div>
                                            <dt className="text-sm text-gray-500">Notes</dt>
                                            <dd className="text-sm text-gray-900">
                                                {document.client_notes}
                                            </dd>
                                        </div>
                                    )}
                                </dl>
                            </div>

                            {/* Version History */}
                            <div>
                                <h4 className="text-sm font-medium text-gray-900">
                                    Version History
                                </h4>
                                <div className="mt-2 space-y-3">
                                    {documentHistory.map((version) => (
                                        <div
                                            key={version.version}
                                            className="flex items-start space-x-3"
                                        >
                                            <div className="flex-shrink-0">
                                                <span className="inline-flex items-center justify-center h-8 w-8 rounded-full bg-blue-100">
                                                    <span className="text-sm font-medium leading-none text-blue-600">
                                                        v{version.version}
                                                    </span>
                                                </span>
                                            </div>
                                            <div className="min-w-0 flex-1">
                                                <p className="text-sm text-gray-900">
                                                    {version.changes}
                                                </p>
                                                <p className="mt-0.5 text-sm text-gray-500">
                                                    {format(new Date(version.created_at), 'MMM d, yyyy h:mm a')}
                                                </p>
                                            </div>
                                            {version.version !== document.version && (
                                                <button
                                                    onClick={() => handleVersionPreview(version)}
                                                    className="text-sm text-blue-600 hover:text-blue-700"
                                                >
                                                    View
                                                </button>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Modal Footer */}
                    <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                        <button
                            type="button"
                            onClick={() => handleDownload(document)}
                            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                        >
                            Download
                        </button>
                        <button
                            type="button"
                            onClick={onClose}
                            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                        >
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ClientDocumentManagement;