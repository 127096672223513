import React, { useState, useRef, useEffect } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '../../components/ui/cardui';
import { Button } from '../../components/ui/button';
import { Label } from '../../components/ui/label';
import { Input } from '../../components/ui/input';
import { Checkbox } from '../../components/ui/checkbox';
import { Alert, AlertTitle, AlertDescription } from '../../components/ui/alert';
import { Spinner } from '../../components/ui/spinner';
import { Badge } from '../../components/ui/badge';
import { fetchProjectRisks, fetchRiskCategories } from '../../services/riskApi';
import { toast } from 'react-toastify';
import { useReactToPrint } from 'react-to-print';
import { Pie, Bar } from 'react-chartjs-2';

const RiskReportGenerator = ({ projectId, projectName = 'Project' }) => {
    const [loading, setLoading] = useState(false);
    const [reportData, setReportData] = useState(null);
    const [reportOptions, setReportOptions] = useState({
        includeHeatMap: true,
        includeSeverityChart: true,
        includeStatusChart: true,
        includeCategoryChart: true,
        includeTopRisks: true,
        includeResponses: true,
        topRisksCount: 10
    });
    const [printReady, setPrintReady] = useState(false);
    const printRef = useRef(null);
    const reportRef = useRef(null);

    const handlePrint = useReactToPrint({
        content: () => printRef.current,
        content: () => {
            if (!reportRef.current) {
                console.error('Report ref is not attached to any element');
            }
            return reportRef.current;
        },
        documentTitle: `Risk Report - ${projectName} - ${new Date().toLocaleDateString()}`,
        onBeforeGetContent: () => {
            return new Promise((resolve) => {
                setLoading(true);
                // Give charts time to render before printing
                setTimeout(() => {
                    setPrintReady(true);
                    resolve();
                }, 500);
            });
        },
        onAfterPrint: () => {
            setLoading(false);
            setPrintReady(false);
        },
        onError: (error) => {
            console.error('Print failed:', error);
            setLoading(false);
            setPrintReady(false);
            toast.error('Failed to generate PDF. Please try again.');
        },
    });

    const handleOptionChange = (name, value) => {
        setReportOptions({
            ...reportOptions,
            [name]: value
        });
    };

    // Effect to ensure ref is ready when report data is loaded
    useEffect(() => {
        if (reportData && reportRef.current) {
            console.log('Report content is ready and ref is attached');
        }
    }, [reportData]);

    const generateReport = async () => {
        try {
            setLoading(true);

            // Fetch risk data
            const risks = await fetchProjectRisks(projectId);
            const categories = await fetchRiskCategories();

            // Process data for report
            const severityCounts = {
                'LOW': 0,
                'MEDIUM': 0,
                'HIGH': 0,
                'CRITICAL': 0
            };

            const statusCounts = {
                'IDENTIFIED': 0,
                'ASSESSED': 0,
                'MITIGATED': 0,
                'CLOSED': 0,
                'OCCURRED': 0
            };

            // Create categoryCountsMap safely
            const categoryCountsMap = {};

            // Ensure categories is an array before processing
            if (Array.isArray(categories)) {
                categories.forEach(cat => {
                    if (cat && cat.id) {
                        categoryCountsMap[cat.id] = {
                            name: cat.name || 'Unnamed Category',
                            count: 0
                        };
                    }
                });
            } else {
                console.warn('Categories is not an array:', categories);
                // Create some default categories as fallback
                ['Technical', 'Schedule', 'Cost', 'Quality', 'Management'].forEach((name, index) => {
                    categoryCountsMap[`default-${index}`] = {
                        name,
                        count: 0
                    };
                });
            }

            // Ensure risks is an array before processing
            if (Array.isArray(risks)) {
                // Count risks by categories
                risks.forEach(risk => {
                    // Count by severity
                    if (severityCounts.hasOwnProperty(risk.severity)) {
                        severityCounts[risk.severity]++;
                    }

                    // Count by status
                    if (statusCounts.hasOwnProperty(risk.status)) {
                        statusCounts[risk.status]++;
                    }

                    // Count by category
                    if (risk.category && categoryCountsMap[risk.category]) {
                        categoryCountsMap[risk.category].count++;
                    } else if (risk.category_name) {
                        // Try to use category_name if available
                        const existingCategory = Object.values(categoryCountsMap)
                            .find(cat => cat.name === risk.category_name);

                        if (existingCategory) {
                            existingCategory.count++;
                        } else {
                            // Create a new category entry if needed
                            categoryCountsMap[`dynamic-${Date.now()}`] = {
                                name: risk.category_name,
                                count: 1
                            };
                        }
                    }
                });
            } else {
                console.warn('Risks is not an array:', risks);
            }

            // Convert category counts to array
            const categoryCounts = Object.values(categoryCountsMap)
                .filter(cat => cat.count > 0)
                .sort((a, b) => b.count - a.count);

            // Get top risks by risk score (safely)
            const topRisks = Array.isArray(risks) ?
                [...risks]
                    .sort((a, b) => (b.risk_score || 0) - (a.risk_score || 0))
                    .slice(0, reportOptions.topRisksCount) :
                [];

            // Get all responses (safely)
            const allResponses = Array.isArray(risks) ?
                risks.flatMap(risk =>
                    Array.isArray(risk.responses) ?
                        risk.responses.map(response => ({
                            ...response,
                            risk_title: risk.title || 'Untitled Risk',
                            risk_id: risk.id,
                            risk_severity: risk.severity || 'UNKNOWN'
                        })) :
                        []
                ) :
                [];

            // Set report data
            setReportData({
                risks: Array.isArray(risks) ? risks : [],
                severityCounts,
                statusCounts,
                categoryCounts,
                topRisks,
                responses: allResponses,
                generatedAt: new Date()
            });

        } catch (error) {
            console.error('Error generating report:', error);
            toast.error('Failed to generate report: ' + (error.message || 'Unknown error'));
        } finally {
            setLoading(false);
        }
    };

    // Chart options and data
    const getSeverityChartData = () => {
        if (!reportData) return null;

        return {
            labels: ['Low', 'Medium', 'High', 'Critical'],
            datasets: [{
                data: [
                    reportData.severityCounts.LOW,
                    reportData.severityCounts.MEDIUM,
                    reportData.severityCounts.HIGH,
                    reportData.severityCounts.CRITICAL
                ],
                backgroundColor: [
                    '#28a745', // Green for Low
                    '#ffc107', // Yellow for Medium
                    '#fd7e14', // Orange for High
                    '#dc3545'  // Red for Critical
                ]
            }]
        };
    };

    const getStatusChartData = () => {
        if (!reportData) return null;

        return {
            labels: ['Identified', 'Assessed', 'Mitigated', 'Closed', 'Occurred'],
            datasets: [{
                label: 'Number of Risks',
                data: [
                    reportData.statusCounts.IDENTIFIED,
                    reportData.statusCounts.ASSESSED,
                    reportData.statusCounts.MITIGATED,
                    reportData.statusCounts.CLOSED,
                    reportData.statusCounts.OCCURRED
                ],
                backgroundColor: [
                    '#007bff', // Blue for Identified
                    '#6f42c1', // Purple for Assessed
                    '#20c997', // Teal for Mitigated
                    '#6c757d', // Gray for Closed
                    '#dc3545'  // Red for Occurred
                ]
            }]
        };
    };

    const getCategoryChartData = () => {
        if (!reportData || !reportData.categoryCounts || reportData.categoryCounts.length === 0) return null;

        return {
            labels: reportData.categoryCounts.map(cat => cat.name),
            datasets: [{
                data: reportData.categoryCounts.map(cat => cat.count),
                backgroundColor: [
                    '#4e73df', '#1cc88a', '#36b9cc', '#f6c23e', '#fd7e14',
                    '#e74a3b', '#5a5c69', '#2c9faf', '#6f42c1', '#20c997'
                ]
            }]
        };
    };

    // Get color class for severity
    const getSeverityColor = (severity) => {
        switch (severity) {
            case 'LOW': return 'bg-green-500 text-white';
            case 'MEDIUM': return 'bg-yellow-500 text-white';
            case 'HIGH': return 'bg-orange-500 text-white';
            case 'CRITICAL': return 'bg-red-500 text-white';
            default: return 'bg-gray-500 text-white';
        }
    };

    // Get color class for status
    const getStatusColor = (status) => {
        switch (status) {
            case 'IDENTIFIED': return 'bg-blue-500 text-white';
            case 'ASSESSED': return 'bg-purple-500 text-white';
            case 'MITIGATED': return 'bg-green-500 text-white';
            case 'CLOSED': return 'bg-gray-500 text-white';
            case 'OCCURRED': return 'bg-red-500 text-white';
            default: return 'bg-gray-300 text-gray-800';
        }
    };

    // Function to trigger print after ensuring data is ready
    const triggerPrint = () => {
        if (reportRef.current) {
            handlePrint();
        } else {
            toast.error('Report content is not ready. Please try again.');
        }
    };

    return (
        <Card className="mb-4">
            <CardHeader>
                <CardTitle>Risk Management Report</CardTitle>
            </CardHeader>
            <CardContent>
                <p className="text-muted-foreground mb-6">
                    Generate a comprehensive risk management report for this project.
                    Select the sections you want to include in the report.
                </p>

                <form className="space-y-6">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div className="space-y-4">
                            <div className="flex items-center space-x-2">
                                <Checkbox
                                    id="include-heatmap"
                                    checked={reportOptions.includeHeatMap}
                                    onCheckedChange={(checked) =>
                                        handleOptionChange('includeHeatMap', checked)
                                    }
                                />
                                <Label htmlFor="include-heatmap">Include Risk Heat Map</Label>
                            </div>

                            <div className="flex items-center space-x-2">
                                <Checkbox
                                    id="include-severity-chart"
                                    checked={reportOptions.includeSeverityChart}
                                    onCheckedChange={(checked) =>
                                        handleOptionChange('includeSeverityChart', checked)
                                    }
                                />
                                <Label htmlFor="include-severity-chart">Include Severity Distribution Chart</Label>
                            </div>

                            <div className="flex items-center space-x-2">
                                <Checkbox
                                    id="include-status-chart"
                                    checked={reportOptions.includeStatusChart}
                                    onCheckedChange={(checked) =>
                                        handleOptionChange('includeStatusChart', checked)
                                    }
                                />
                                <Label htmlFor="include-status-chart">Include Status Distribution Chart</Label>
                            </div>
                        </div>

                        <div className="space-y-4">
                            <div className="flex items-center space-x-2">
                                <Checkbox
                                    id="include-category-chart"
                                    checked={reportOptions.includeCategoryChart}
                                    onCheckedChange={(checked) =>
                                        handleOptionChange('includeCategoryChart', checked)
                                    }
                                />
                                <Label htmlFor="include-category-chart">Include Category Distribution Chart</Label>
                            </div>

                            <div className="flex items-center space-x-2">
                                <Checkbox
                                    id="include-top-risks"
                                    checked={reportOptions.includeTopRisks}
                                    onCheckedChange={(checked) =>
                                        handleOptionChange('includeTopRisks', checked)
                                    }
                                />
                                <Label htmlFor="include-top-risks">Include Top Risks</Label>
                            </div>

                            <div className="flex items-center space-x-2">
                                <Checkbox
                                    id="include-responses"
                                    checked={reportOptions.includeResponses}
                                    onCheckedChange={(checked) =>
                                        handleOptionChange('includeResponses', checked)
                                    }
                                />
                                <Label htmlFor="include-responses">Include Risk Responses</Label>
                            </div>
                        </div>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div>
                            <Label htmlFor="topRisksCount">Number of Top Risks to Include</Label>
                            <Input
                                id="topRisksCount"
                                type="number"
                                value={reportOptions.topRisksCount}
                                onChange={(e) => handleOptionChange('topRisksCount', parseInt(e.target.value))}
                                min="1"
                                max="20"
                                className="mt-1"
                            />
                        </div>
                    </div>

                    <div className="flex justify-between">
                        <Button
                            type="button"
                            onClick={generateReport}
                            disabled={loading}
                        >
                            {loading ? (
                                <div className="flex items-center">
                                    <Spinner className="mr-2" size="sm" />
                                    Generating...
                                </div>
                            ) : (
                                'Generate Report'
                            )}
                        </Button>

                        {reportData && (
                            <Button
                                type="button"
                                variant="secondary"
                                onClick={triggerPrint}
                                disabled={loading}
                            >
                                {loading ? 'Preparing...' : 'Export to PDF'}
                            </Button>
                        )}
                    </div>
                </form>

                {reportData && (
                    <div className="mt-6">
                        <Alert className="bg-green-50 border-green-200">
                            <AlertTitle>Success</AlertTitle>
                            <AlertDescription>
                                Report generated successfully! Click "Export to PDF" to download or print.
                            </AlertDescription>
                        </Alert>
                    </div>
                )}


                {reportData && (
                    <div className={printReady ? "visible" : "invisible"} style={{ position: "absolute", left: "-9999px", top: 0 }}>
                        <div ref={printRef} className="p-6 bg-white" style={{ width: "800px", minHeight: "1100px" }}>
                            <div className="mb-6 text-center">
                                <h2 className="text-2xl font-bold">Risk Management Report</h2>
                                <h4 className="text-xl">{projectName}</h4>
                                <p className="text-muted-foreground">
                                    Generated on {reportData.generatedAt.toLocaleDateString()} at {reportData.generatedAt.toLocaleTimeString()}
                                </p>
                            </div>

                            <div className="mb-6">
                                <h5 className="text-lg font-medium">Executive Summary</h5>
                                <hr className="my-2" />
                                <p>
                                    This report provides an overview of the risk management status for {projectName}.
                                    The project currently has a total of {reportData.risks.length} identified risks.
                                </p>

                                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 mt-4">
                                    <div className="text-center border rounded p-4">
                                        <h2 className="text-2xl font-bold">{reportData.risks.length}</h2>
                                        <div>Total Risks</div>
                                    </div>
                                    <div className="text-center border rounded p-4">
                                        <h2 className="text-2xl font-bold">{reportData.severityCounts.HIGH + reportData.severityCounts.CRITICAL}</h2>
                                        <div>High Severity</div>
                                    </div>
                                    <div className="text-center border rounded p-4">
                                        <h2 className="text-2xl font-bold">{reportData.statusCounts.MITIGATED + reportData.statusCounts.CLOSED}</h2>
                                        <div>Mitigated</div>
                                    </div>
                                    <div className="text-center border rounded p-4">
                                        <h2 className="text-2xl font-bold">{reportData.statusCounts.IDENTIFIED + reportData.statusCounts.ASSESSED}</h2>
                                        <div>Open Risks</div>
                                    </div>
                                </div>
                            </div>

                            {reportOptions.includeSeverityChart && (
                                <div className="mb-6">
                                    <h5 className="text-lg font-medium">Risk Severity Distribution</h5>
                                    <hr className="my-2" />
                                    <div className="flex justify-center">
                                        <div style={{ width: '400px', height: '300px' }}>
                                            <Pie
                                                data={getSeverityChartData()}
                                                options={{
                                                    responsive: true,
                                                    maintainAspectRatio: false,
                                                    plugins: { legend: { position: 'right' } }
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}

                            {reportOptions.includeStatusChart && (
                                <div className="mb-6">
                                    <h5 className="text-lg font-medium">Risk Status Distribution</h5>
                                    <hr className="my-2" />
                                    <div className="flex justify-center">
                                        <div style={{ width: '500px', height: '300px' }}>
                                            <Bar
                                                data={getStatusChartData()}
                                                options={{
                                                    responsive: true,
                                                    maintainAspectRatio: false,
                                                    plugins: { legend: { display: false } },
                                                    scales: { y: { beginAtZero: true } }
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}

                            {reportOptions.includeCategoryChart && reportData.categoryCounts && reportData.categoryCounts.length > 0 && (
                                <div className="mb-6">
                                    <h5 className="text-lg font-medium">Risk Category Distribution</h5>
                                    <hr className="my-2" />
                                    <div className="flex justify-center">
                                        <div style={{ width: '500px', height: '300px' }}>
                                            <Pie
                                                data={getCategoryChartData()}
                                                options={{
                                                    responsive: true,
                                                    maintainAspectRatio: false,
                                                    plugins: { legend: { position: 'right' } }
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}

                            {reportOptions.includeTopRisks && reportData.topRisks.length > 0 && (
                                <div className="mb-6">
                                    <h5 className="text-lg font-medium">Top {reportData.topRisks.length} Risks by Risk Score</h5>
                                    <hr className="my-2" />
                                    <table className="w-full border-collapse border">
                                        <thead className="bg-muted">
                                            <tr>
                                                <th className="p-2 text-left border">Risk</th>
                                                <th className="p-2 text-left border">Category</th>
                                                <th className="p-2 text-left border">Severity</th>
                                                <th className="p-2 text-left border">Probability</th>
                                                <th className="p-2 text-left border">Risk Score</th>
                                                <th className="p-2 text-left border">Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {reportData.topRisks.map((risk, index) => (
                                                <tr key={risk.id || index}>
                                                    <td className="p-2 border">
                                                        <div className="font-medium">{risk.title || 'Untitled Risk'}</div>
                                                        <div className="text-xs text-muted-foreground">
                                                            {risk.description ? `${risk.description.substring(0, 100)}...` : 'No description'}
                                                        </div>
                                                    </td>
                                                    <td className="p-2 border">{risk.category_name || 'Uncategorized'}</td>
                                                    <td className="p-2 border">
                                                        <Badge className={getSeverityColor(risk.severity)}>
                                                            {risk.severity || 'Unknown'}
                                                        </Badge>
                                                    </td>
                                                    <td className="p-2 border">{risk.probability || 'Unknown'}</td>
                                                    <td className="p-2 border">{risk.risk_score || 'N/A'}</td>
                                                    <td className="p-2 border">
                                                        <Badge className={getStatusColor(risk.status)}>
                                                            {risk.status || 'Unknown'}
                                                        </Badge>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            )}

                            {reportOptions.includeResponses && reportData.responses && reportData.responses.length > 0 && (
                                <div className="mb-6">
                                    <h5 className="text-lg font-medium">Risk Response Plans</h5>
                                    <hr className="my-2" />
                                    <table className="w-full border-collapse border">
                                        <thead className="bg-muted">
                                            <tr>
                                                <th className="p-2 text-left border">Risk</th>
                                                <th className="p-2 text-left border">Response Action</th>
                                                <th className="p-2 text-left border">Strategy</th>
                                                <th className="p-2 text-left border">Status</th>
                                                <th className="p-2 text-left border">Due Date</th>
                                                <th className="p-2 text-left border">Assigned To</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {reportData.responses.map((response, index) => (
                                                <tr key={response.id || index}>
                                                    <td className="p-2 border">
                                                        <div className="font-medium">{response.risk_title || 'Untitled Risk'}</div>
                                                        <Badge className={getSeverityColor(response.risk_severity)}>
                                                            {response.risk_severity || 'Unknown'}
                                                        </Badge>
                                                    </td>
                                                    <td className="p-2 border">{response.action || 'No action specified'}</td>
                                                    <td className="p-2 border">{response.strategy || 'No strategy'}</td>
                                                    <td className="p-2 border">{response.status || 'Not specified'}</td>
                                                    <td className="p-2 border">{response.due_date || 'Not set'}</td>
                                                    <td className="p-2 border">
                                                        {response.assigned_to_details ?
                                                            `${response.assigned_to_details.first_name || ''} ${response.assigned_to_details.last_name || ''}`.trim() || 'Unnamed' :
                                                            'Unassigned'}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            )}

                            <div className="mt-12 pt-6 text-center border-t">
                                <p className="text-xs text-muted-foreground">
                                    This report is for internal use only. The information contained in this report is confidential
                                    and proprietary to the company. Unauthorized access, use, or distribution is prohibited.
                                </p>
                            </div>
                        </div>
                    </div>
                )}
            </CardContent>
        </Card>
    );
};

export default RiskReportGenerator;