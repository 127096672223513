
import React, { useState, useEffect, useContext } from 'react';
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent
} from '../../components/ui/cardui';
import { Button } from '../../components/ui/button';
import { useToast } from '../../components/ui/use-toast';
import { Textarea } from '../../components/ui/textarea';
import { Alert, AlertDescription } from "../../components/ui/alert";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../components/ui/dialog";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../../components/ui/alert-dialog";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../components/ui/form";
import { Input } from '../../components/ui/input';
import { Select } from '../../components/ui/select';
import { useNavigate, useParams } from 'react-router-dom';
import { Plus, Search, Trash2, Edit2 } from 'lucide-react';
import { Breadcrumb } from '../../components/common/Breadcrumb';
import EstimateFilters from '../../components/Estimate/EstimateFilters';
import { primaryButtonClasses, secondaryButtonClasses } from '../../components/common/ButtonStyles';
import api from '../../services/api';
import { ToastContainer } from 'react-toastify';
import { Toaster } from '../../components/ui/toaster';
import { useForm } from "react-hook-form";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { EstimateWarningDialog } from '../../components/Estimate/EstimateWarningDialog';
import { canCreateNextEstimate } from '../../utils/estimateHelpers';


// Form validation schema
const estimateFormSchema = z.object({
  name: z.string().min(1, "Title is required"),
  description: z.string().optional(),
  profit_percentage: z.number().min(0).max(100).optional(),
  overhead_percentage: z.number().min(0).max(100).optional(),
  tax_percentage: z.number().min(0).max(100).optional(),
  notes: z.string().optional(),
});


const EstimateList = () => {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const { toast } = useToast();
  const [estimates, setEstimates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [estimateToDelete, setEstimateToDelete] = useState(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editingEstimate, setEditingEstimate] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [warningDialogOpen, setWarningDialogOpen] = useState(false);
  const [missingEstimateType, setMissingEstimateType] = useState(null);
  const [pendingNavigation, setPendingNavigation] = useState(null);

  const form = useForm({
    resolver: zodResolver(estimateFormSchema),
    defaultValues: {
      name: "",
      description: "",
      profit_percentage: 0,
      overhead_percentage: 0,
      tax_percentage: 0,
      notes: "",
    },
  });

  const handleEditClick = (estimate) => {
    setEditingEstimate(estimate);
    // Populate form with current estimate values
    form.reset({
      name: estimate.name,
      description: estimate.description || "",
      profit_percentage: estimate.profit_percentage || 0,
      overhead_percentage: estimate.overhead_percentage || 0,
      tax_percentage: estimate.tax_percentage || 0,
      notes: estimate.notes || "",
    });
    setEditDialogOpen(true);
  };

  const handleViewClick = async (estimate) => {
    if (estimate.estimate_type === 'conceptual') {
      try {
        // Try to get the conceptual estimate
        const response = await api.get(`conceptual-estimates/estimate/${estimate.id}/`);
        // If successful, navigate to the detail view
        navigate(`/conceptual-estimates/${response.data.id}`);
      } catch (error) {
        if (error.response?.status === 404) {
          // No conceptual estimate exists yet, navigate to create form
          navigate(`/estimates/${estimate.id}/conceptual/create`);
        } else {
          toast({
            title: "Error",
            description: "Failed to load conceptual estimate",
            variant: "destructive"
          });
        }
      }
    } else {
      navigate(`/estimates/${estimate.id}`);
    }
  };

  const handleWarningContinue = () => {
    setWarningDialogOpen(false);
    if (pendingNavigation) {
      navigate(`/estimates/${pendingNavigation.id}/${missingEstimateType}/create`);
    }
  };

  const onSubmit = async (data) => {
    if (!editingEstimate) return;

    try {
      setIsUpdating(true);
      const response = await api.put(`estimates/${editingEstimate.id}`, data);

      toast({
        title: "Success",
        description: "Estimate updated successfully",
        duration: 3000,
      });

      // Refresh estimates list
      fetchEstimates();
      setEditDialogOpen(false);
    } catch (error) {
      toast({
        variant: "destructive",
        title: "Error",
        description: error.response?.data?.detail || "Failed to update estimate",
        duration: 5000,
      });
    } finally {
      setIsUpdating(false);
    }
  };

  const createNewConceptualEstimate = async () => {
    try {
      // Create base estimate
      const estimateResponse = await api.post('estimates/', {
        name: 'New Conceptual Estimate',
        estimate_type: 'conceptual',
        status: 'draft',
        // ... other required fields
      });

      // Create conceptual estimate
      const conceptualResponse = await api.post('conceptual-estimates/', {
        estimate: estimateResponse.data.id,
        // Default values
        contingency_percentage: 10,
        profit_margin: 15,
        overhead_percentage: 10
      });

      // Navigate to form
      navigate(`/estimates/conceptual/${conceptualResponse.data.id}`);
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to create conceptual estimate",
        variant: "destructive"
      });
    }
  };


  const [filters, setFilters] = useState({
    status: 'all',
    type: 'all',
    dateRange: '30'
  });



  useEffect(() => {
    fetchEstimates();
  }, [searchTerm, filters]);

  const fetchEstimates = async () => {
    try {
      setLoading(true);
      const params = new URLSearchParams();

      if (searchTerm) {
        params.append('search', searchTerm);
      }

      if (filters.status && filters.status !== 'all') {
        params.append('status', filters.status);
      }

      if (filters.type && filters.type !== 'all') {
        params.append('type', filters.type);
      }

      if (filters.dateRange && filters.dateRange !== 'all') {
        params.append('date_range', filters.dateRange);
      }

      const response = await api.get(`estimates/?${params.toString()}`);

      // Log the data for debugging
      //console.log('Fetched estimates:', response.data);

      // Transform the data if needed
      const transformedEstimates = response.data.map(estimate => ({
        ...estimate,
        // Ensure total is properly calculated from either total_cost or total
        total: estimate.total_cost || estimate.total || 0,
        // Ensure status is properly formatted
        status: estimate.status.toLowerCase()
      }));

      setEstimates(transformedEstimates);
    } catch (error) {
      console.error('Error fetching estimates:', error);
      toast({
        title: "Error",
        description: error.response?.data?.detail || 'Failed to fetch estimates',
        variant: "destructive"
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    fetchEstimates();
  };

  const handleFilterChange = (name, value) => {
    setFilters(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleDeleteClick = (estimate) => {
    setEstimateToDelete(estimate);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (!estimateToDelete) return;

    try {
      await api.delete(`estimates/${estimateToDelete.id}/`);
      toast({
        title: "Success",
        description: "Estimate deleted successfully",
      });
      fetchEstimates(); // Refresh the list
    } catch (error) {
      toast({
        title: "Error",
        description: error.response?.data?.detail || 'Failed to delete estimate',
        variant: "destructive"
      });
    } finally {
      setDeleteDialogOpen(false);
      setEstimateToDelete(null);
    }
  };



  return (
    <div className="p-6">
      <Toaster />
      <Breadcrumb />

      <Card>
        <CardHeader>
          <div className="flex justify-between items-center">
            <CardTitle className="text-2xl font-bold">
              {projectId ? 'Project Estimates' : 'All Estimates'}
            </CardTitle>
            <Button
              onClick={() => navigate('/estimates/new')}
              className={primaryButtonClasses}
            >
              <Plus className="mr-2" />
              New Estimate
            </Button>
            {/*<Button
              onClick={createNewConceptualEstimate}
              className={primaryButtonClasses}
            >
              New Conceptual Estimate
            </Button>*/}
          </div>
        </CardHeader>

        <CardContent>
          {/* Search and Filters */}
          <div className="mb-6 space-y-4">
            <form onSubmit={handleSearch} className="flex gap-4">
              <div className="flex-1">
                <Input
                  placeholder="Search estimates..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="rounded-xl"
                  icon={<Search className="text-gray-400" />}
                />
              </div>
              <Button
                type="submit"
                className={primaryButtonClasses}
              >
                Search
              </Button>
            </form>

            <EstimateFilters
              filters={filters}
              onFilterChange={handleFilterChange}
            />
          </div>
          {/* Estimates Table */}
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead>
                <tr className="border-b">
                  <th className="text-left py-3">Name</th>
                  <th className="text-left py-3">Status</th>
                  <th className="text-left py-3">Type</th>
                  <th className="text-left py-3">Created</th>
                  <th className="text-right py-3">Total</th>
                  <th className="text-right py-3">Actions</th>
                </tr>
              </thead>
              <tbody>
                {estimates.map((estimate) => (
                  <tr
                    key={estimate.id}
                    className="border-b hover:bg-gray-50"
                  >
                    <td className="py-4">{estimate.name}</td>
                    <td className="py-4">
                      <span className={`
        px-3 py-1 rounded-full text-sm
        ${getStatusColor(estimate.status)}
      `}>
                        {estimate.status.replace('_', ' ').split(' ').map(word =>
                          word.charAt(0).toUpperCase() + word.slice(1)
                        ).join(' ')}
                      </span>
                    </td>
                    <td className="py-4 capitalize">{estimate.estimate_type}</td>
                    <td className="py-4">
                      {new Date(estimate.created_at).toLocaleDateString()}
                    </td>
                    <td className="py-4 text-right">
                      {estimate.total_cost || estimate.total ?
                        `$${(estimate.total_cost || estimate.total).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })}` :
                        '$0.00'
                      }
                    </td>
                    <td className="py-4 text-right space-x-2">
                      <Button
                        //onClick={() => navigate(`/estimates/${estimate.id}`)}
                        onClick={() => handleViewClick(estimate)}
                        variant="outline"
                        className="rounded-xl border-[#344bfc] text-[#344bfc] hover:bg-[#344bfc] hover:text-white"
                      >
                        View
                      </Button>
                      <Button
                        onClick={() => handleEditClick(estimate)}
                        variant="outline"
                        className="rounded-xl border-green-500 text-green-500 hover:bg-green-500 hover:text-white"
                      >
                        <Edit2 className="h-4 w-4" />
                      </Button>
                      <Button
                        onClick={() => handleDeleteClick(estimate)}
                        variant="outline"
                        className="rounded-xl border-red-500 text-red-500 hover:bg-red-500 hover:text-white"
                      >
                        <Trash2 className="h-4 w-4" />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {estimates.length === 0 && !loading && (
              <div className="text-center py-8 text-gray-500">
                No estimates found. Create your first estimate to get started.
              </div>
            )}
          </div>
        </CardContent>
        {/* Edit Dialog */}
        <Dialog open={editDialogOpen} onOpenChange={setEditDialogOpen}>
          <DialogContent className="sm:max-w-[425px]">
            <DialogHeader>
              <DialogTitle>Edit Estimate</DialogTitle>
              <DialogDescription>
                Update the estimate details below.
              </DialogDescription>
            </DialogHeader>

            <Form {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
                <FormField
                  control={form.control}
                  name="name"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Title</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="description"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Description</FormLabel>
                      <FormControl>
                        <Textarea {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <div className="grid grid-cols-3 gap-4">
                  <FormField
                    control={form.control}
                    name="profit_percentage"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Profit %</FormLabel>
                        <FormControl>
                          <Input
                            {...field}
                            type="number"
                            min="0"
                            max="100"
                            onChange={e => field.onChange(parseFloat(e.target.value))}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="overhead_percentage"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Overhead %</FormLabel>
                        <FormControl>
                          <Input
                            {...field}
                            type="number"
                            min="0"
                            max="100"
                            onChange={e => field.onChange(parseFloat(e.target.value))}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="tax_percentage"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Tax %</FormLabel>
                        <FormControl>
                          <Input
                            {...field}
                            type="number"
                            min="0"
                            max="100"
                            onChange={e => field.onChange(parseFloat(e.target.value))}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>

                <FormField
                  control={form.control}
                  name="notes"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Notes</FormLabel>
                      <FormControl>
                        <Textarea {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <DialogFooter>
                  <Button
                    type="button"
                    variant="outline"
                    onClick={() => setEditDialogOpen(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    disabled={isUpdating}
                    className="bg-[#344bfc] hover:bg-[#2338ce]"
                  >
                    {isUpdating ? "Updating..." : "Update Estimate"}
                  </Button>
                </DialogFooter>
              </form>
            </Form>
          </DialogContent>
        </Dialog>
      </Card>
      <AlertDialog open={deleteDialogOpen} onOpenChange={setDeleteDialogOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Delete Estimate</AlertDialogTitle>
            <AlertDialogDescription>
              Are you sure you want to delete this estimate? This action cannot be undone.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              onClick={handleDeleteConfirm}
              className="bg-red-500 hover:bg-red-600"
            >
              Delete
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <EstimateWarningDialog
        open={warningDialogOpen}
        onClose={() => setWarningDialogOpen(false)}
        missingEstimateType={missingEstimateType}
        onContinue={handleWarningContinue}
      />
    </div>
  );
};

// Helper function for status colors
const getStatusColor = (status) => {
  const colors = {
    draft: 'bg-gray-100 text-gray-800',
    review: 'bg-yellow-100 text-yellow-800',
    approved: 'bg-green-100 text-green-800',
    revision_requested: 'bg-orange-100 text-orange-800',
    sent: 'bg-purple-100 text-purple-800',
    accepted: 'bg-teal-100 text-teal-800',
    rejected: 'bg-red-100 text-red-800'
  };
  return colors[status] || colors.draft;
};

export default EstimateList;