import React, { useState, useEffect, useMemo } from 'react';
import { Gantt, ViewMode } from 'gantt-task-react';
import "gantt-task-react/dist/index.css";
import { Card, CardContent, CardHeader, CardTitle } from "../ui/cardui";
import { Alert, AlertDescription } from "../ui/alert";
import { AlertCircle } from "lucide-react";
import { Skeleton } from "../ui/skeleton";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../ui/select";
import api from '../../services/api';

const GanttTaskView = ({ projectId }) => {
    const [data, setData] = useState({ tasks: [], milestones: [] });
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [view, setView] = useState(ViewMode.Day);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                const [tasksResponse, milestonesResponse] = await Promise.all([
                    api.get(`projects/${projectId}/tasks/`),
                    api.get(`projects/${projectId}/milestones/`)
                ]);

                setData({
                    tasks: tasksResponse.data?.results || [],
                    milestones: milestonesResponse.data?.results || []
                });
            } catch (error) {
                console.error('Error fetching data:', error);
                setError('Failed to load project timeline');
            } finally {
                setIsLoading(false);
            }
        };

        if (projectId) fetchData();
    }, [projectId]);

    const formatCompactDate = (date) => {
        const d = new Date(date);
        const month = String(d.getMonth() + 1).padStart(2, '0');
        const day = String(d.getDate()).padStart(2, '0');
        const year = d.getFullYear().toString().slice(-2);
        return `${month}/${day}/${year}`;
    };

    const ganttData = useMemo(() => {
        if (!data.tasks.length && !data.milestones.length) return [];

        const formattedTasks = [];

        // Add milestones
        data.milestones.forEach((milestone) => {
            const milestoneTasks = data.tasks.filter(task => task.milestone === milestone.id);

            formattedTasks.push({
                id: `milestone-${milestone.id}`,
                name: `${milestone.name} (${formatCompactDate(milestone.start_date)} - ${formatCompactDate(milestone.due_date)})`,
                start: new Date(milestone.start_date),
                end: new Date(milestone.due_date),
                progress: milestone.progress_percentage || 0,
                isDisabled: false,
                type: 'project',
                hideChildren: false,
                displayOrder: formattedTasks.length,
                styles: {
                    backgroundColor: '#fef3c7',
                    progressColor: '#f59e0b',
                }
            });

            // Add tasks under milestone
            milestoneTasks.forEach((task) => {
                formattedTasks.push({
                    id: `task-${task.id}`,
                    name: `${task.title} (${formatCompactDate(task.start_date)} - ${formatCompactDate(task.due_date)})`,
                    start: new Date(task.start_date),
                    end: new Date(task.due_date),
                    progress: task.status === 'done' ? 100 :
                        task.status === 'in_progress' ? 50 : 0,
                    isDisabled: false,
                    project: `milestone-${milestone.id}`,
                    type: 'task',
                    displayOrder: formattedTasks.length,
                    styles: {
                        backgroundColor: task.status === 'done' ? '#86efac' :
                            task.status === 'in_progress' ? '#93c5fd' :
                                '#e2e8f0',
                        progressColor: task.status === 'done' ? '#22c55e' :
                            task.status === 'in_progress' ? '#3b82f6' :
                                '#64748b',
                    }
                });
            });
        });

        // Add tasks without milestones
        const orphanTasks = data.tasks.filter(task => !task.milestone);
        orphanTasks.forEach((task) => {
            formattedTasks.push({
                id: `task-${task.id}`,
                name: `${task.title} (${formatCompactDate(task.start_date)} - ${formatCompactDate(task.due_date)})`,
                start: new Date(task.start_date),
                end: new Date(task.due_date),
                progress: task.status === 'done' ? 100 :
                    task.status === 'in_progress' ? 50 : 0,
                isDisabled: false,
                type: 'task',
                displayOrder: formattedTasks.length,
                styles: {
                    backgroundColor: task.status === 'done' ? '#86efac' :
                        task.status === 'in_progress' ? '#93c5fd' :
                            '#e2e8f0',
                    progressColor: task.status === 'done' ? '#22c55e' :
                        task.status === 'in_progress' ? '#3b82f6' :
                            '#64748b',
                }
            });
        });

        return formattedTasks;
    }, [data]);

    if (isLoading) return <Skeleton className="h-[500px]" />;
    if (error) return <Alert variant="destructive"><AlertCircle className="h-4 w-4" /><AlertDescription>{error}</AlertDescription></Alert>;
    if (!ganttData.length) return <div>No timeline data available</div>;

    return (
        <Card className="border-none shadow-none">
            <CardHeader className="px-0 flex flex-row justify-between items-center">
                <CardTitle>Project Timeline</CardTitle>
                <Select
                    value={view}
                    onValueChange={(value) => setView(value)}
                >
                    <SelectTrigger className="w-[180px]">
                        <SelectValue placeholder="Select view mode" />
                    </SelectTrigger>
                    <SelectContent>
                        <SelectItem value={ViewMode.Day}>Day</SelectItem>
                        <SelectItem value={ViewMode.Week}>Week</SelectItem>
                        <SelectItem value={ViewMode.Month}>Month</SelectItem>
                    </SelectContent>
                </Select>
            </CardHeader>
            <CardContent className="px-0">
                <div className="gantt-chart-container">
                    <Gantt
                        tasks={ganttData}
                        viewMode={view}
                        listCellWidth="240px"
                        columnWidth={48}
                        ganttHeight={400}
                        barCornerRadius={4}
                        barFill={80}
                        handleWidth={10}
                        onDoubleClick={() => { }}
                        onClick={() => { }}
                        todayColor="rgba(252, 231, 243, 0.5)"
                        rowHeight={40}
                        fontSize="12px"
                    />
                </div>
            </CardContent>
        </Card>
    );
};

export default GanttTaskView;