import React, { useState, useEffect } from 'react';
import { Plus, Search, UserPlus, Mail, Building, Shield, Edit, Trash2 } from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle } from '../../components/ui/cardui';
import { Button } from '../../components/ui/button';
import { Input } from '../../components/ui/input';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from '../../components/ui/dialog';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../components/ui/select';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../components/ui/table';
import { Alert, AlertDescription } from '../../components/ui/alert';
import { primaryButtonClasses, secondaryButtonClasses } from '../common/ButtonStyles';
import api from '../../services/api';
import { useToast } from '../ui/use-toast';


const ClientManagement = () => {
    const [clients, setClients] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const [isAddingClient, setIsAddingClient] = useState(false);
    const [isEditingClient, setIsEditingClient] = useState(false);
    const [projects, setProjects] = useState([]);
    const [selectedClient, setSelectedClient] = useState(null);
    const [availableRoles, setAvailableRoles] = useState([]);
    const { toast } = useToast();

    const [formData, setFormData] = useState({
        email: '',
        firstName: '',
        lastName: '',
        roleType: null,
        projectIds: []
    });

    // Fetch clients and projects on component mount
    useEffect(() => {
        fetchClients();
        fetchProjects();
    }, []);


    // Then update all the error handlers:
    const fetchProjects = async () => {
        try {
            const response = await api.get('projects/');
            setProjects(Array.isArray(response.data.results) ? response.data.results : []);
        } catch (error) {
            console.error('Error fetching projects:', error);
            toast({
                title: "Error",
                description: "Failed to load projects",
                variant: "destructive"
            });
        }
    };

    const fetchClients = async () => {
        try {
            setLoading(true);
            const response = await api.get('clients/');
            setClients(Array.isArray(response.data.results) ? response.data.results : []);
        } catch (error) {
            console.error('Error fetching clients:', error);
            toast({
                title: "Error",
                description: "Failed to load clients",
                variant: "destructive"
            });
        } finally {
            setLoading(false);
        }
    };

    const handleAddClient = async () => {
        try {
            // Ensure projectIds is an array before mapping
            const projectIds = formData.projectIds || [];

            const payload = {
                email: formData.email,
                first_name: formData.firstName,
                last_name: formData.lastName,
                project_ids: projectIds.map(id => id.toString())
            };

            // Validation
            if (!payload.email || !payload.first_name || !payload.last_name) {
                toast({
                    title: "Validation Error",
                    description: "Please fill in all required fields",
                    variant: "destructive"
                });
                return;
            }

            const response = await api.post('clients/', payload);

            if (response.data) {
                await fetchClients();
                setIsAddingClient(false);
                resetForm();
                toast({
                    title: "Success",
                    description: "Client added successfully"
                });
            }
        } catch (error) {
            console.error('Error adding client:', error);
            const errorMessage = error.response?.data?.error ||
                error.response?.data?.message ||
                "Failed to add client. Please check the form and try again.";
            toast({
                title: "Error",
                description: errorMessage,
                variant: "destructive"
            });
        }
    };

    const handleEditClient = async () => {
        try {
            if (!selectedClient?.id) {
                throw new Error('No client selected for editing');
            }

            const projectIds = formData.projectIds || [];

            const payload = {
                first_name: formData.firstName,
                last_name: formData.lastName,
                project_ids: projectIds.map(id => id.toString())
            };

            const response = await api.patch(`clients/${selectedClient.id}/`, payload);

            await fetchClients();
            setIsEditingClient(false);
            resetForm();
            toast({
                title: "Success",
                description: "Client updated successfully"
            });
        } catch (error) {
            console.error('Error updating client:', error);
            toast({
                title: "Error",
                description: "Failed to update client",
                variant: "destructive"
            });
        }
    };

    const handleEdit = (client) => {
        setSelectedClient(client);
        setFormData({
            email: client.email,
            firstName: client.first_name,
            lastName: client.last_name,
            projectIds: client.projects ? client.projects.map(p => p.id) : [] // Ensure projects array exists
        });
        setIsEditingClient(true);
    };

    const handleDeleteClient = async (clientId) => {
        if (!window.confirm('Are you sure you want to delete this client?')) return;

        try {
            await api.delete(`clients/${clientId}/`);
            await fetchClients();
            toast({
                title: "Success",
                description: "Client deleted successfully"
            });
        } catch (error) {
            console.error('Error deleting client:', error);
            toast({
                title: "Error",
                description: "Failed to delete client",
                variant: "destructive"
            });
        }
    };

    const resetForm = () => {
        setFormData({
            email: '',
            firstName: '',
            lastName: '',
            roleType: '',
            projectIds: []
        });
        setSelectedClient(null);
    };

    const filteredClients = (clients || []).filter(client =>
        `${client?.first_name || ''} ${client?.last_name || ''}`.toLowerCase().includes(searchQuery.toLowerCase()) ||
        (client?.email || '').toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div className="space-y-6">
            <Card>
                <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-4">
                    <CardTitle className="text-2xl font-bold">Client Management</CardTitle>
                    <Dialog open={isAddingClient} onOpenChange={setIsAddingClient}>
                        <DialogTrigger asChild>
                            <Button className={primaryButtonClasses}>
                                <UserPlus className="w-4 h-4 mr-2" />
                                Add Client
                            </Button>
                        </DialogTrigger>
                        <DialogContent className="sm:max-w-[500px]">
                            <DialogHeader>
                                <DialogTitle>Add New Client</DialogTitle>
                            </DialogHeader>
                            <div className="space-y-4 py-4">
                                <div className="space-y-2">
                                    <label className="text-sm font-medium">Email</label>
                                    <Input
                                        type="email"
                                        placeholder="client@example.com"
                                        value={formData.email}
                                        onChange={e => setFormData({ ...formData, email: e.target.value })}
                                    />
                                </div>
                                <div className="grid grid-cols-2 gap-4">
                                    <div className="space-y-2">
                                        <label className="text-sm font-medium">First Name</label>
                                        <Input
                                            placeholder="John"
                                            value={formData.firstName}
                                            onChange={e => setFormData({ ...formData, firstName: e.target.value })}
                                        />
                                    </div>
                                    <div className="space-y-2">
                                        <label className="text-sm font-medium">Last Name</label>
                                        <Input
                                            placeholder="Doe"
                                            value={formData.lastName}
                                            onChange={e => setFormData({ ...formData, lastName: e.target.value })}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-2">
                                    <label className="text-sm font-medium">Assign Projects</label>
                                    <Select
                                        value={formData.projectIds}
                                        onValueChange={value => {
                                            // Check if project ID already exists to avoid duplicates
                                            if (!formData.projectIds.includes(value)) {
                                                setFormData({ ...formData, projectIds: [...formData.projectIds, value] })
                                            }
                                        }}
                                    >
                                        <SelectTrigger>
                                            <SelectValue placeholder="Select projects" />
                                        </SelectTrigger>
                                        <SelectContent>
                                            {projects.map(project => (
                                                <SelectItem key={project.id} value={project.id}>
                                                    {project.name} - {project.location}
                                                </SelectItem>
                                            ))}
                                        </SelectContent>
                                    </Select>

                                    {/* Show selected projects with ability to remove */}
                                    <div className="mt-2 space-y-1">
                                        {formData.projectIds.map(projectId => {
                                            const project = projects.find(p => p.id === projectId);
                                            return project ? (
                                                <div key={projectId} className="flex items-center justify-between bg-gray-50 px-2 py-1 rounded">
                                                    <span className="text-sm">{project.name}</span>
                                                    <Button
                                                        variant="ghost"
                                                        size="sm"
                                                        onClick={() => setFormData({
                                                            ...formData,
                                                            projectIds: formData.projectIds.filter(id => id !== projectId)
                                                        })}
                                                    >
                                                        <Trash2 className="h-4 w-4 text-red-500" />
                                                    </Button>
                                                </div>
                                            ) : null;
                                        })}
                                    </div>
                                </div>
                                <Button className={primaryButtonClasses} onClick={handleAddClient}>
                                    Add Client
                                </Button>
                            </div>
                        </DialogContent>
                    </Dialog>
                </CardHeader>
                <CardContent>
                    <div className="flex items-center space-x-4 mb-4">
                        <div className="relative flex-1">
                            <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
                            <Input
                                placeholder="Search clients..."
                                className="pl-8"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                        </div>
                    </div>

                    {loading ? (
                        <div className="flex items-center justify-center h-64">
                            <div className="w-8 h-8 border-4 border-primary border-t-transparent rounded-full animate-spin" />
                        </div>
                    ) : (
                        <div className="rounded-md border">
                            <Table>
                                <TableHeader>
                                    <TableRow>
                                        <TableHead>Name</TableHead>
                                        <TableHead>Email</TableHead>
                                        <TableHead>Role</TableHead>
                                        <TableHead>Projects</TableHead>
                                        <TableHead>Status</TableHead>
                                        <TableHead className="text-right">Actions</TableHead>
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                    {filteredClients.map((client) => (
                                        <TableRow key={client.id}>
                                            <TableCell className="font-medium">
                                                {client?.first_name} {client?.last_name}
                                            </TableCell>
                                            <TableCell>{client?.email}</TableCell>
                                            <TableCell>{client?.role_name}</TableCell>
                                            <TableCell>
                                                {client?.projects ? client.projects.map(p => p.name).join(', ') : ''}
                                            </TableCell>
                                            <TableCell>
                                                <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${client?.is_active ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
                                                    }`}>
                                                    {client?.is_active ? 'Active' : 'Inactive'}
                                                </span>
                                            </TableCell>
                                            <TableCell className="text-right space-x-2">
                                                <Button
                                                    variant="ghost"
                                                    size="sm"
                                                    onClick={() => handleEdit(client)}
                                                >
                                                    <Edit className="h-4 w-4" />
                                                </Button>
                                                <Button
                                                    variant="ghost"
                                                    size="sm"
                                                    onClick={() => handleDeleteClient(client.id)}
                                                >
                                                    <Trash2 className="h-4 w-4 text-red-500" />
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </div>
                    )}
                </CardContent>
            </Card>

            {/* Edit Dialog */}
            <Dialog open={isEditingClient} onOpenChange={setIsEditingClient}>
                <DialogContent className="sm:max-w-[500px]">
                    <DialogHeader>
                        <DialogTitle>Edit Client</DialogTitle>
                    </DialogHeader>
                    <div className="space-y-4 py-4">
                        <div className="grid grid-cols-2 gap-4">
                            <div className="space-y-2">
                                <label className="text-sm font-medium">First Name</label>
                                <Input
                                    value={formData.firstName}
                                    onChange={e => setFormData({ ...formData, firstName: e.target.value })}
                                />
                            </div>
                            <div className="space-y-2">
                                <label className="text-sm font-medium">Last Name</label>
                                <Input
                                    value={formData.lastName}
                                    onChange={e => setFormData({ ...formData, lastName: e.target.value })}
                                />
                            </div>
                        </div>
                        <div className="space-y-2">
                            <label className="text-sm font-medium">Assign Projects</label>
                            <Select
                                value={formData.projectIds}
                                onValueChange={value => setFormData({ ...formData, projectIds: [...formData.projectIds, value] })}
                            >
                                <SelectTrigger>
                                    <SelectValue placeholder="Select projects" />
                                </SelectTrigger>
                                <SelectContent>
                                    {projects.map(project => (
                                        <SelectItem key={project.id} value={project.id}>
                                            {project.name}
                                        </SelectItem>
                                    ))}
                                </SelectContent>
                            </Select>
                        </div>
                        <Button className={primaryButtonClasses} onClick={handleEditClient}>
                            Save Changes
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default ClientManagement;