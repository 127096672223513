import React, { useState, useEffect } from 'react';
import { CloudUploadIcon, CloudOff, CloudSync, Check, AlertTriangle } from 'lucide-react';
import { Button } from '../components/ui/button';
import offlineSyncManager from './OfflineSyncManager';

/**
 * Sync Status Indicator for field workers
 * Shows sync status and provides manual sync functionality
 */
const SyncStatusIndicator = () => {
    const [status, setStatus] = useState({
        isOnline: navigator.onLine,
        pendingCount: 0,
        isSyncing: false,
        lastSyncAttempt: null
    });

    // Update status on mount and every 30 seconds
    useEffect(() => {
        const updateStatus = async () => {
            const currentStatus = await offlineSyncManager.getSyncStatus();
            setStatus(currentStatus);
        };

        // Update immediately on mount
        updateStatus();

        // Set up interval for regular updates
        const intervalId = setInterval(updateStatus, 30000);

        // Set up network event listeners
        const handleOnline = () => {
            setStatus(prev => ({ ...prev, isOnline: true }));
            updateStatus();
        };

        const handleOffline = () => {
            setStatus(prev => ({ ...prev, isOnline: false }));
        };

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        // Clean up on unmount
        return () => {
            clearInterval(intervalId);
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };
    }, []);

    const handleManualSync = async () => {
        if (!status.isOnline || status.isSyncing) return;

        setStatus(prev => ({ ...prev, isSyncing: true }));
        try {
            await offlineSyncManager.syncPendingData();
            // Update status after sync
            const currentStatus = await offlineSyncManager.getSyncStatus();
            setStatus(currentStatus);
        } catch (error) {
            console.error('Manual sync failed:', error);
        } finally {
            setStatus(prev => ({ ...prev, isSyncing: false }));
        }
    };

    // Determine the icon and color based on status
    const getStatusInfo = () => {
        if (!status.isOnline) {
            return {
                icon: <CloudOff className="w-5 h-5" />,
                color: 'bg-red-100 text-red-800',
                text: 'Offline'
            };
        }

        if (status.isSyncing) {
            return {
                icon: <CloudUploadIcon className="w-5 h-5 animate-spin" />,
                color: 'bg-blue-100 text-blue-800',
                text: 'Syncing...'
            };
        }

        if (status.pendingCount > 0) {
            return {
                icon: <AlertTriangle className="w-5 h-5" />,
                color: 'bg-yellow-100 text-yellow-800',
                text: `${status.pendingCount} pending`
            };
        }

        return {
            icon: <Check className="w-5 h-5" />,
            color: 'bg-green-100 text-green-800',
            text: 'All synced'
        };
    };

    const statusInfo = getStatusInfo();

    return (
        <div className="fixed bottom-20 right-4 z-10">
            <Button
                onClick={handleManualSync}
                disabled={!status.isOnline || status.isSyncing || status.pendingCount === 0}
                className={`${statusInfo.color} px-3 py-2 rounded-full shadow-lg flex items-center space-x-2`}
            >
                {statusInfo.icon}
                <span className="text-sm font-medium">{statusInfo.text}</span>
            </Button>
        </div>
    );
};

export default SyncStatusIndicator;