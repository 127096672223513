import { Button } from '../components/ui/button';

export default function SafetyActionButtons({ onAuditClick, onIncidentClick, onHazardClick }) {
    const handleHazardClick = (e) => {
        if (onHazardClick) {
            onHazardClick(e);
        } else {
            console.log('onHazardClick is not defined');
        }
    };
    return (
        <div className="flex space-x-4">

            <Button
                onClick={onIncidentClick}
                className="rounded-full bg-red-500 hover:bg-red-600 text-white"
            >
                Report Incident
            </Button>
            <Button
                onClick={handleHazardClick}
                className="rounded-full bg-yellow-500 hover:bg-yellow-600 text-white"
            >
                Log Hazard
            </Button>
        </div>
    )
}