import React, { useState, useEffect } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
    DialogFooter,
} from "../../../components/ui/dialog";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "../../../components/ui/select";
import { Button } from "../../../components/ui/button";
import { Input } from "../../../components/ui/input";
import { Label } from "../../../components/ui/label";
import { Textarea } from "../../../components/ui/textarea";
import { useToast } from "../../../components/ui/use-toast";
import { Loader2, Plus, X, DollarSign, Calendar } from 'lucide-react';
import BidService from '../../../services/bidService';
import { format } from 'date-fns';
import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
} from "../../../components/ui/cardui";
import { cn } from '../../../lib/utils';
import { ProjectPreview, CertificationUpload, NotableProjectForm } from '../subs/Forms/CertificationAndProjectForms';
import { primaryButtonClasses } from '../../common/ButtonStyles';

const bidderSchema = z.object({
    company_name: z.string().min(2, "Company name is required"),
    business_type: z.string().min(2, "Business type is required"),
    tax_id: z.string().optional(),
    years_in_business: z.coerce.number().min(0, "Years in business must be positive"),

    address: z.string().min(5, "Address is required"),
    city: z.string().min(2, "City is required"),
    state: z.string().min(2, "State is required"),
    zip_code: z.string().min(5, "ZIP code is required"),
    phone: z.string().min(10, "Phone number is required"),
    email: z.string().email("Invalid email address"),

    specialized_trade_ids: z.array(
        z.union([
            z.number(),
            z.string().transform(val => {
                const num = parseInt(val, 10);
                if (isNaN(num)) throw new Error('Invalid trade ID');
                return num;
            })
        ])
    ).min(1, "At least one trade is required"),
    services: z.record(z.string(), z.boolean()).optional(),

    average_project_size: z.coerce.number().min(0, "Average project size must be positive"),
    license_number: z.string().min(1, "License number is required"),
    bonding_capacity: z.coerce.number().min(0, "Bonding capacity must be positive"),
    insurance_info: z.object({
        policy_number: z.string().min(1, "Policy number is required"),
        coverage_amount: z.coerce.number().min(0),
        expiration_date: z.string().min(1, "Expiration date is required")
    }),

    years_experience: z.coerce.number().min(0),
    completed_projects_count: z.coerce.number().min(0),
    safety_rating: z.enum(['A', 'B', 'C', 'D']),

    portfolio_link: z.string().url().optional(),
    certifications: z.array(
        z.object({
            id: z.string().optional(),
            name: z.string().optional(),
            type: z.string().optional(),
            file: z.instanceof(File).optional()
        })
    ).optional().default([]),

    notable_projects: z.array(z.object({
        name: z.string().min(1),
        description: z.string().min(1),
        value: z.coerce.number().min(0),
        images: z.array(z.any()).optional()
    })).optional(),

    type: z.enum(['vendor', 'subcontractor', 'general_contractor', 'architect']).default('subcontractor')
});

const AddBidderDialog = ({ trade, onBidderAdded }) => {
    const [step, setStep] = useState(1);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const { toast } = useToast();

    const form = useForm({
        resolver: zodResolver(bidderSchema),
        defaultValues: {
            type: 'subcontractor',
            specialized_trade_ids: trade?.id ? [parseInt(trade.id, 10)] : [],
            bonding_capacity: 0,
            years_experience: 0,
            insurance_info: {
                policy_number: '',
                coverage_amount: 0,
                expiration_date: ''
            },
            notable_projects: [],
            completed_projects_count: 0,
            safety_rating: 'A',
            services: {},
            certifications: [],
            // Initialize other fields to prevent undefined values
            company_name: '',
            business_type: '',
            tax_id: '',
            years_in_business: 0,
            address: '',
            city: '',
            state: '',
            zip_code: '',
            phone: '',
            email: ''
        },
        mode: 'onChange'
    });


    const onSubmit = form.handleSubmit(async (data) => {
        if (isSubmitting) return;

        try {
            setIsSubmitting(true);
            const formData = new FormData();

            // Core profile data
            formData.append('type', data.type || 'subcontractor');
            formData.append('company_name', data.company_name);
            formData.append('business_type', data.business_type);
            formData.append('tax_id', data.tax_id || '');
            formData.append('years_in_business', data.years_in_business.toString());

            // Contact information
            formData.append('address', data.address);
            formData.append('city', data.city);
            formData.append('state', data.state);
            formData.append('zip_code', data.zip_code);
            formData.append('phone', data.phone);
            formData.append('email', data.email);

            // Financial and Legal
            formData.append('license_number', data.license_number);
            formData.append('bonding_capacity', data.bonding_capacity.toString());
            formData.append('years_experience', data.years_experience.toString());
            formData.append('completed_projects_count', data.completed_projects_count.toString());
            formData.append('average_project_size', data.average_project_size.toString());
            formData.append('safety_rating', data.safety_rating);
            formData.append('portfolio_link', data.portfolio_link || '');

            // Handle specialized trades
            // Convert IDs to numbers and filter out invalid ones
            const tradeIds = data.specialized_trade_ids
                .map(id => typeof id === 'string' ? parseInt(id, 10) : id)
                .filter(id => !isNaN(id));

            // For Django's PrimaryKeyRelatedField with many=True
            tradeIds.forEach(id => {
                formData.append('specialized_trade_ids', id);
            });

            // Services is optional JSONField
            if (data.services) {
                formData.append('services', JSON.stringify(data.services));
            }

            // Insurance info is required JSONField
            formData.append('insurance_info', JSON.stringify({
                policy_number: data.insurance_info.policy_number,
                coverage_amount: data.insurance_info.coverage_amount,
                expiration_date: data.insurance_info.expiration_date
            }));

            // Optional JSONFields
            if (data.notable_projects?.length) {
                formData.append('notable_projects', JSON.stringify(data.notable_projects.map(project => ({
                    name: project.name,
                    description: project.description,
                    value: Number(project.value)
                }))));
            }

            // Handle certification files and metadata
            if (data.certifications?.length) {
                const certificationsMetadata = data.certifications.map(cert => ({
                    name: cert.name || cert.file.name,
                    type: cert.type || cert.file.type
                }));
                formData.append('certifications', JSON.stringify(certificationsMetadata));

                // Append actual files
                data.certifications.forEach((cert, index) => {
                    if (cert.file instanceof File) {
                        formData.append(`certification_files`, cert.file);
                    }
                });
            }

            // Safety/Quality fields with defaults
            formData.append('safety_incident_rate', data.safety_incident_rate?.toString() || '0');
            formData.append('quality_rating', '0.0'); // Let server set default
            formData.append('active', 'true');

            const response = await BidService.createProfile(formData);

            if (response.data) {
                onBidderAdded(response.data);
                setDialogOpen(false);
                form.reset();
                toast({
                    title: "Success",
                    description: "Bidder profile created successfully"
                });
            }
        } catch (error) {
            console.error('Submission error:', error?.response?.data || error);

            if (error.response?.data) {
                // Handle validation errors
                const errors = error.response.data;
                Object.entries(errors).forEach(([key, value]) => {
                    if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
                        Object.entries(value).forEach(([nestedKey, nestedValue]) => {
                            form.setError(`${key}.${nestedKey}`, {
                                type: 'manual',
                                message: Array.isArray(nestedValue) ? nestedValue[0] : nestedValue
                            });
                        });
                    } else {
                        form.setError(key, {
                            type: 'manual',
                            message: Array.isArray(value) ? value[0] : value
                        });
                    }
                });

                toast({
                    title: "Validation Error",
                    description: "Please check the form for errors and try again",
                    variant: "destructive"
                });
            } else {
                toast({
                    title: "Error",
                    description: "Failed to create bidder profile. Please try again.",
                    variant: "destructive"
                });
            }
        } finally {
            setIsSubmitting(false);
        }
    });

    const steps = [
        {
            title: "Company Information",
            fields: ['company_name', 'business_type', 'tax_id', 'years_in_business']
        },
        {
            title: "Contact Information",
            fields: ['address', 'city', 'state', 'zip_code', 'phone', 'email']
        },
        {
            title: "Licenses & Insurance",
            fields: ['license_number', 'bonding_capacity', 'insurance_info']
        },
        {
            title: "Experience & Performance",
            fields: ['years_experience', 'completed_projects_count', 'average_project_size', 'safety_rating']
        },
        {
            title: "Projects & Certifications",
            fields: ['notable_projects', 'certifications', 'portfolio_link']
        }
    ];


    const CompanyInformationStep = () => {
        const { register, formState: { errors } } = form;

        return (
            <div className="space-y-4">
                <div className="space-y-2">
                    <Label htmlFor="company_name">Company Name*</Label>
                    <Input
                        id="company_name"
                        {...register('company_name')}
                        className={cn(errors.company_name && "border-red-500")}
                    />
                    {errors.company_name && (
                        <p className="text-sm text-red-500">{errors.company_name.message}</p>
                    )}
                </div>

                <div className="space-y-2">
                    <Label htmlFor="business_type">Business Type*</Label>
                    <Select
                        onValueChange={(value) => form.setValue('business_type', value)}
                        defaultValue={form.watch('business_type')}
                    >
                        <SelectTrigger id="business_type">
                            <SelectValue placeholder="Select business type" />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectItem value="corporation">Corporation</SelectItem>
                            <SelectItem value="llc">LLC</SelectItem>
                            <SelectItem value="partnership">Partnership</SelectItem>
                            <SelectItem value="sole_proprietorship">Sole Proprietorship</SelectItem>
                        </SelectContent>
                    </Select>
                    {errors.business_type && (
                        <p className="text-sm text-red-500">{errors.business_type.message}</p>
                    )}
                </div>

                <div className="space-y-2">
                    <Label htmlFor="tax_id">Tax ID</Label>
                    <Input
                        id="tax_id"
                        {...register('tax_id')}
                        className={cn(errors.tax_id && "border-red-500")}
                    />
                    {errors.tax_id && (
                        <p className="text-sm text-red-500">{errors.tax_id.message}</p>
                    )}
                </div>

                <div className="space-y-2">
                    <Label htmlFor="years_in_business">Years in Business*</Label>
                    <Input
                        id="years_in_business"
                        type="number"
                        min="0"
                        {...register('years_in_business', {
                            valueAsNumber: true
                        })}
                        className={cn(errors.years_in_business && "border-red-500")}
                    />
                    {errors.years_in_business && (
                        <p className="text-sm text-red-500">{errors.years_in_business.message}</p>
                    )}
                </div>
            </div>
        );
    };

    const ContactInformationStep = () => {
        const { register, formState: { errors } } = form;

        return (
            <div className="space-y-4">
                <div className="space-y-2">
                    <Label htmlFor="address">Address*</Label>
                    <Textarea
                        id="address"
                        {...register('address')}
                        className={cn(errors.address && "border-red-500")}
                    />
                    {errors.address && (
                        <p className="text-sm text-red-500">{errors.address.message}</p>
                    )}
                </div>

                <div className="space-y-2">
                    <Label htmlFor="city">City*</Label>
                    <Input
                        id="city"
                        {...register('city')}
                        className={cn(errors.city && "border-red-500")}
                    />
                    {errors.city && (
                        <p className="text-sm text-red-500">{errors.city.message}</p>
                    )}
                </div>

                <div className="space-y-2">
                    <Label htmlFor="state">State*</Label>
                    <Input
                        id="state"
                        {...register('state')}
                        className={cn(errors.state && "border-red-500")}
                    />
                    {errors.state && (
                        <p className="text-sm text-red-500">{errors.state.message}</p>
                    )}
                </div>

                <div className="space-y-2">
                    <Label htmlFor="zip_code">ZIP Code*</Label>
                    <Input
                        id="zip_code"
                        {...register('zip_code')}
                        className={cn(errors.zip_code && "border-red-500")}
                    />
                    {errors.zip_code && (
                        <p className="text-sm text-red-500">{errors.zip_code.message}</p>
                    )}
                </div>

                <div className="space-y-2">
                    <Label htmlFor="phone">Phone*</Label>
                    <Input
                        id="phone"
                        {...register('phone')}
                        className={cn(errors.phone && "border-red-500")}
                    />
                    {errors.phone && (
                        <p className="text-sm text-red-500">{errors.phone.message}</p>
                    )}
                </div>

                <div className="space-y-2">
                    <Label htmlFor="email">Email*</Label>
                    <Input
                        id="email"
                        type="email"
                        {...register('email')}
                        className={cn(errors.email && "border-red-500")}
                    />
                    {errors.email && (
                        <p className="text-sm text-red-500">{errors.email.message}</p>
                    )}
                </div>
            </div>
        );
    };

    /* const handleSubmit = async (formData) => {
        if (isSubmitting) return;

        try {
            setIsSubmitting(true);

            const apiData = {
                ...formData,
                bonding_capacity: Number(formData.bonding_capacity),
                specialized_trades: formData.specialized_trade_ids,
                years_in_business: Number(formData.years_in_business),
                years_experience: Number(formData.years_experience),
                completed_projects_count: Number(formData.completed_projects_count),
                average_project_size: Number(formData.average_project_size),
                insurance_info: {
                    ...formData.insurance_info,
                    coverage_amount: Number(formData.insurance_info.coverage_amount)
                }
            };

            const response = await BidService.createProfile(apiData);
            onBidderAdded(response.data);
            setDialogOpen(false);
            form.reset();

            toast({
                title: "Success",
                description: "Bidder profile created successfully"
            });
        } catch (error) {
            console.error('Submission error:', error);
            toast({
                title: "Error",
                description: error.message || "Failed to create bidder profile"
            });
        } finally {
            setIsSubmitting(false);
        }
    }; */

    /* const validateStep = async (stepIndex) => {
        const currentStep = steps[stepIndex - 1];
        try {
            const result = await form.trigger(currentStep.fields);
            return result;
        } catch (error) {
            console.error('Step validation error:', error);
            return false;
        }
    }; */

    const validateStep = async (stepIndex) => {
        const currentStep = steps[stepIndex - 1];
        try {
            const result = await form.trigger(currentStep.fields);
            const errors = form.formState.errors;

            // Check if there are any errors in the current step's fields
            const hasErrors = currentStep.fields.some(field => {
                if (field === 'insurance_info') {
                    return errors.insurance_info?.policy_number ||
                        errors.insurance_info?.coverage_amount ||
                        errors.insurance_info?.expiration_date;
                }
                return errors[field];
            });

            return !hasErrors;
        } catch (error) {
            console.error('Validation error:', error);
            return false;
        }
    };


    const InsuranceInfoForm = ({ form, error }) => (
        <Card>
            <CardHeader>
                <CardTitle className="text-sm font-medium">Insurance Information</CardTitle>
            </CardHeader>
            <CardContent className="space-y-4">
                <div>
                    <Label htmlFor="insurance_info.policy_number">Policy Number*</Label>
                    <Input
                        id="insurance_info.policy_number"
                        {...form.register('insurance_info.policy_number')}
                        error={error?.policy_number?.message}
                    />
                </div>

                <div>
                    <Label htmlFor="insurance_info.coverage_amount">Coverage Amount ($)*</Label>
                    <div className="relative">
                        <DollarSign className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-gray-500" />
                        <Input
                            id="insurance_info.coverage_amount"
                            type="number"
                            className="pl-8"
                            {...form.register('insurance_info.coverage_amount', {
                                valueAsNumber: true
                            })}
                            error={error?.coverage_amount?.message}
                        />
                    </div>
                </div>

                <div>
                    <Label htmlFor="insurance_info.expiration_date">Expiration Date*</Label>
                    <div className="relative">
                        <Calendar className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-gray-500" />
                        <Input
                            id="insurance_info.expiration_date"
                            type="date"
                            className="pl-8"
                            {...form.register('insurance_info.expiration_date')}
                            error={error?.expiration_date?.message}
                        />
                    </div>
                </div>
            </CardContent>
        </Card>
    );

    const LicensesAndInsuranceStep = () => {
        const { register, formState: { errors } } = form;

        return (
            <div className="space-y-4">
                <div className="space-y-2">
                    <Label htmlFor="license_number">License Number*</Label>
                    <Input
                        id="license_number"
                        {...register('license_number')}
                        className={cn(errors.license_number && "border-red-500")}
                    />
                    {errors.license_number && (
                        <p className="text-sm text-red-500">{errors.license_number.message}</p>
                    )}
                </div>

                <div className="space-y-2">
                    <Label htmlFor="bonding_capacity">Bonding Capacity ($)*</Label>
                    <div className="relative">
                        <DollarSign className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-gray-500" />
                        <Input
                            id="bonding_capacity"
                            type="number"
                            min="0"
                            className="pl-8"
                            {...register('bonding_capacity', {
                                valueAsNumber: true
                            })}
                        />
                    </div>
                    {errors.bonding_capacity && (
                        <p className="text-sm text-red-500">{errors.bonding_capacity.message}</p>
                    )}
                </div>

                <InsuranceInfoForm form={form} error={errors.insurance_info} />
            </div>
        );
    };

    const renderField = (field, form) => {
        const error = form.formState.errors[field];

        switch (field) {
            case 'insurance_info':
                return <InsuranceInfoForm form={form} error={error} />;

            case 'notable_projects':
                return <NotableProjectForm form={form} />;

            case 'business_type':
                return (
                    <div className="space-y-2">
                        <Label htmlFor={field}>Business Type*</Label>
                        <Select
                            onValueChange={(value) => form.setValue(field, value)}
                            defaultValue={form.watch(field)}
                        >
                            <SelectTrigger id={field}>
                                <SelectValue placeholder="Select business type" />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectItem value="corporation">Corporation</SelectItem>
                                <SelectItem value="llc">LLC</SelectItem>
                                <SelectItem value="partnership">Partnership</SelectItem>
                                <SelectItem value="sole_proprietorship">Sole Proprietorship</SelectItem>
                            </SelectContent>
                        </Select>
                        {error && <p className="text-sm text-red-500">{error.message}</p>}
                    </div>
                );

            case 'safety_rating':
                return (
                    <div className="space-y-2">
                        <Label htmlFor={field}>Safety Rating*</Label>
                        <Select
                            onValueChange={(value) => form.setValue(field, value)}
                            defaultValue={form.watch(field)}
                        >
                            <SelectTrigger id={field}>
                                <SelectValue placeholder="Select safety rating" />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectItem value="A">A - Excellent</SelectItem>
                                <SelectItem value="B">B - Good</SelectItem>
                                <SelectItem value="C">C - Fair</SelectItem>
                                <SelectItem value="D">D - Poor</SelectItem>
                            </SelectContent>
                        </Select>
                        {error && <p className="text-sm text-red-500">{error.message}</p>}
                    </div>
                );

            case 'years_experience':
            case 'completed_projects_count':
            case 'years_in_business':
                return (
                    <div className="space-y-2">
                        <Label htmlFor={field}>
                            {field.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}*
                        </Label>
                        <Input
                            id={field}
                            type="number"
                            min="0"
                            {...form.register(field, {
                                valueAsNumber: true
                            })}
                            className={cn(error && "border-red-500")}
                        />
                        {error && <p className="text-sm text-red-500">{error.message}</p>}
                    </div>
                );

            case 'average_project_size':
            case 'bonding_capacity':
                return (
                    <div className="space-y-2">
                        <Label htmlFor={field}>
                            {field.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}*
                        </Label>
                        <div className="relative">
                            <DollarSign className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-gray-500" />
                            <Input
                                id={field}
                                type="number"
                                min="0"
                                className="pl-8"
                                {...form.register(field, {
                                    valueAsNumber: true
                                })}
                            />
                        </div>
                        {error && <p className="text-sm text-red-500">{error.message}</p>}
                    </div>
                );

            case 'address':
                return (
                    <div className="space-y-2">
                        <Label htmlFor={field}>Address*</Label>
                        <Textarea
                            id={field}
                            {...form.register(field)}
                            className={cn(error && "border-red-500")}
                        />
                        {error && <p className="text-sm text-red-500">{error.message}</p>}
                    </div>
                );

            case 'certifications':
                return (
                    <div className="space-y-2">
                        <Label>Certifications & Licenses</Label>
                        <CertificationUpload form={form} />
                        {error && <p className="text-sm text-red-500">{error.message}</p>}
                    </div>
                );

            case 'portfolio_link':
                return (
                    <div className="space-y-2">
                        <Label htmlFor={field}>Portfolio Website</Label>
                        <Input
                            id={field}
                            type="url"
                            placeholder="https://"
                            {...form.register(field)}
                            className={cn(error && "border-red-500")}
                        />
                        {error && <p className="text-sm text-red-500">{error.message}</p>}
                    </div>
                );

            default:
                return (
                    <div className="space-y-2">
                        <Label htmlFor={field}>
                            {field.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                            {field !== 'tax_id' && '*'}
                        </Label>
                        <Input
                            id={field}
                            type={field.includes('email') ? 'email' : 'text'}
                            {...form.register(field)}
                            className={cn(error && "border-red-500")}
                        />
                        {error && <p className="text-sm text-red-500">{error.message}</p>}
                    </div>
                );
        }
    };

    const renderStepContent = (stepIndex) => {
        switch (stepIndex) {
            case 1:
                return <CompanyInformationStep />;
            case 2:
                return <ContactInformationStep />;
            case 3:
                return <LicensesAndInsuranceStep />;
            case 4:
                return (
                    <div className="space-y-4">
                        {steps[3].fields.map(field => renderField(field, form))}
                    </div>
                );
            case 5:
                return (
                    <div className="space-y-4">
                        {steps[4].fields.map(field => renderField(field, form))}
                    </div>
                );
            default:
                return null;
        }
    };


    return (
        <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
            <DialogTrigger asChild>
                <Button variant="outline" className="w-full mt-2">
                    <Plus className="h-4 w-4 mr-2" />
                    Add New Bidder
                </Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[600px]">
                <DialogHeader>
                    <DialogTitle className="flex items-center justify-between">
                        {steps[step - 1].title}
                        <span className="text-sm font-normal text-muted-foreground">
                            Step {step} of {steps.length}
                        </span>
                    </DialogTitle>
                    <div className="flex space-x-2 mt-4">
                        {steps.map((_, index) => (
                            <div
                                key={index}
                                className={`h-1 flex-1 rounded-full transition-colors duration-300 ${step > index ? 'bg-primary' : 'bg-muted'
                                    }`}
                            />
                        ))}
                    </div>
                </DialogHeader>

                <form onSubmit={onSubmit} className="space-y-6 mt-4">
                    {renderStepContent(step)}

                    <div className="flex justify-between">
                        <Button
                            type="button"
                            variant="outline"
                            onClick={() => step > 1 && setStep(step - 1)}
                            disabled={step === 1}
                        >
                            Previous
                        </Button>

                        {step < steps.length ? (
                            <Button
                                type="button"
                                onClick={async () => {
                                    const valid = await validateStep(step);
                                    if (valid) {
                                        setStep(step + 1);
                                    } else {
                                        toast({
                                            title: "Validation Error",
                                            description: "Please fill in all required fields correctly",
                                            variant: "destructive"
                                        });
                                    }
                                }}
                            >
                                Next
                            </Button>
                        ) : (
                            <Button
                                type="submit"
                                className={primaryButtonClasses}
                                disabled={isSubmitting}
                                onClick={(e) => {
                                    e.preventDefault();
                                    onSubmit(e);
                                }}
                            >
                                {isSubmitting ? (
                                    <>
                                        <Loader2 className="h-4 w-4 mr-2 animate-spin" />
                                        Creating...
                                    </>
                                ) : 'Create Bidder'}
                            </Button>
                        )}
                    </div>
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default AddBidderDialog;