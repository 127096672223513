import React from 'react';
import { TrendingUp, TrendingDown, ArrowRight } from 'lucide-react';

const TrendIndicator = ({ trend }) => {
    const getIndicator = () => {
        switch (trend?.toLowerCase()) {
            case 'increasing':
            case 'improving':
                return {
                    icon: <TrendingUp className="h-4 w-4 text-green-500" />,
                    text: 'Improving',
                    color: 'text-green-500'
                };
            case 'decreasing':
            case 'declining':
                return {
                    icon: <TrendingDown className="h-4 w-4 text-red-500" />,
                    text: 'Declining',
                    color: 'text-red-500'
                };
            case 'stable':
                return {
                    icon: <ArrowRight className="h-4 w-4 text-blue-500" />,
                    text: 'Stable',
                    color: 'text-blue-500'
                };
            default:
                return null;
        }
    };

    const indicator = getIndicator();

    if (!indicator) return null;

    return (
        <div className="flex items-center space-x-1">
            {indicator.icon}
            <span className={`text-sm ${indicator.color}`}>{indicator.text}</span>
        </div>
    );
};

export default TrendIndicator;