import React, { useState, useEffect } from 'react';
import {
    Card,
    CardContent,
    CardHeader,
    CardTitle
} from "../ui/cardui";
import { Button } from "../ui/button";
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogFooter
} from "../ui/dialog";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import { Textarea } from "../ui/textarea";
import { Search, Edit, Trash2, Plus, Tag } from "lucide-react";
import { toast } from "react-toastify";
import api from '../../services/api';
import { primaryButtonClasses, secondaryButtonClasses } from '../common/ButtonStyles';

const CategoryForm = ({ isOpen, onClose, onSubmit, category = null }) => {
    const [formData, setFormData] = useState({
        name: '',
        description: ''
    });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (category) {
            setFormData({
                name: category.name || '',
                description: category.description || ''
            });
        } else {
            setFormData({
                name: '',
                description: ''
            });
        }
    }, [category, isOpen]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!formData.name.trim()) {
            toast.error("Category name is required");
            return;
        }

        setLoading(true);
        try {
            await onSubmit(formData);
            onClose();
        } catch (error) {
            console.error("Error submitting category:", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className="sm:max-w-[500px]">
                <DialogHeader>
                    <DialogTitle>{category ? 'Edit Category' : 'Add New Category'}</DialogTitle>
                </DialogHeader>

                <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                        <Label htmlFor="name">Category Name*</Label>
                        <Input
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleInputChange}
                            placeholder="Enter category name"
                            required
                        />
                    </div>

                    <div>
                        <Label htmlFor="description">Description</Label>
                        <Textarea
                            id="description"
                            name="description"
                            rows={4}
                            value={formData.description}
                            onChange={handleInputChange}
                            placeholder="Enter category description"
                        />
                    </div>

                    <DialogFooter>
                        <Button
                            type="button"
                            variant="outline"
                            onClick={onClose}
                            className={secondaryButtonClasses}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            className={primaryButtonClasses}
                            disabled={loading}
                        >
                            {loading ? 'Saving...' : category ? 'Update Category' : 'Create Category'}
                        </Button>
                    </DialogFooter>
                </form>
            </DialogContent>
        </Dialog>
    );
};

const SupplierCategories = () => {
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [supplierCounts, setSupplierCounts] = useState({});

    useEffect(() => {
        fetchCategories();
    }, []);

    const fetchCategories = async () => {
        setLoading(true);
        try {
            const response = await api.get('supplier-categories/');
            const categoriesData = response.data.results || response.data;
            setCategories(categoriesData);

            // Fetch supplier count for each category
            const counts = {};
            for (const category of categoriesData) {
                try {
                    const supplierResponse = await api.get('suppliers/', {
                        params: { category: category.id }
                    });
                    counts[category.id] = supplierResponse.data.count || supplierResponse.data.length || 0;
                } catch (error) {
                    console.error(`Error fetching suppliers for category ${category.id}:`, error);
                    counts[category.id] = 0;
                }
            }
            setSupplierCounts(counts);
        } catch (error) {
            console.error("Error fetching supplier categories:", error);
            toast.error("Failed to load supplier categories");
        } finally {
            setLoading(false);
        }
    };

    const handleSearch = (e) => {
        e.preventDefault();
        // Filter categories locally for quick search
        // A more complex implementation would call the API with search params
    };

    const handleAddCategory = () => {
        setSelectedCategory(null);
        setIsFormOpen(true);
    };

    const handleEditCategory = (category) => {
        setSelectedCategory(category);
        setIsFormOpen(true);
    };

    const handleDeleteCategory = async (categoryId) => {
        if (window.confirm("Are you sure you want to delete this category? This may affect suppliers assigned to this category.")) {
            try {
                await api.delete(`supplier-categories/${categoryId}/`);
                toast.success("Category deleted successfully");
                fetchCategories();
            } catch (error) {
                console.error("Error deleting category:", error);
                toast.error("Failed to delete category");
            }
        }
    };

    const handleSubmitCategory = async (formData) => {
        try {
            if (selectedCategory) {
                // Update existing category
                await api.put(`supplier-categories/${selectedCategory.id}/`, formData);
                toast.success("Category updated successfully");
            } else {
                // Create new category
                await api.post('supplier-categories/', formData);
                toast.success("Category created successfully");
            }
            fetchCategories();
        } catch (error) {
            console.error("Error submitting category:", error);
            toast.error("Failed to save category");
            throw error;
        }
    };

    const filteredCategories = searchTerm
        ? categories.filter(cat =>
            cat.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            (cat.description && cat.description.toLowerCase().includes(searchTerm.toLowerCase()))
        )
        : categories;

    return (
        <div className="space-y-6">
            <div className="flex justify-between items-center">
                <h2 className="text-xl font-semibold">Supplier Categories</h2>
                <Button
                    onClick={handleAddCategory}
                    className={primaryButtonClasses}
                >
                    <Plus className="h-4 w-4 mr-2" />
                    Add Category
                </Button>
            </div>

            <div className="flex justify-between items-center">
                <form onSubmit={handleSearch} className="relative flex-1 max-w-sm">
                    <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-gray-400" />
                    <Input
                        placeholder="Search categories..."
                        className="pl-9 pr-4"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </form>
            </div>

            {loading ? (
                <div className="text-center py-8">Loading categories...</div>
            ) : filteredCategories.length === 0 ? (
                <Card>
                    <CardContent className="text-center py-12">
                        <Tag className="h-12 w-12 text-gray-400 mx-auto mb-4" />
                        <p className="text-gray-500">No categories found. Create your first category.</p>
                    </CardContent>
                </Card>
            ) : (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    {filteredCategories.map((category) => (
                        <Card key={category.id} className="hover:shadow-md transition-shadow">
                            <CardHeader className="pb-2">
                                <CardTitle className="text-lg">{category.name}</CardTitle>
                            </CardHeader>
                            <CardContent>
                                <p className="text-gray-600 mb-4 text-sm line-clamp-2">
                                    {category.description || "No description provided"}
                                </p>
                                <div className="flex justify-between items-center">
                                    <span className="text-sm font-medium text-gray-500">
                                        {supplierCounts[category.id] || 0} suppliers
                                    </span>
                                    <div className="flex space-x-2">
                                        <Button
                                            variant="outline"
                                            size="sm"
                                            onClick={() => handleEditCategory(category)}
                                        >
                                            <Edit className="h-4 w-4" />
                                        </Button>
                                        <Button
                                            variant="destructive"
                                            size="sm"
                                            onClick={() => handleDeleteCategory(category.id)}
                                        >
                                            <Trash2 className="h-4 w-4" />
                                        </Button>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    ))}
                </div>
            )}

            <CategoryForm
                isOpen={isFormOpen}
                onClose={() => setIsFormOpen(false)}
                onSubmit={handleSubmitCategory}
                category={selectedCategory}
            />
        </div>
    );
};

export default SupplierCategories;