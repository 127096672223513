import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "../ui/cardui";
import { Button } from "../ui/button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../ui/select";
import { Input } from "../ui/input";
import { Plus, Users, Wrench, Box } from "lucide-react";
import { useToast } from "../ui/use-toast";
import api from '../../services/api';
import { primaryButtonClasses } from '../common/ButtonStyles';

const ResourceManager = ({ projectId }) => {
    const [resources, setResources] = useState([]);
    const [resourceType, setResourceType] = useState('labor');
    const { toast } = useToast();

    useEffect(() => {
        loadResources();
    }, [projectId]);

    const loadResources = async () => {
        try {
            const response = await api.get(`projects/${projectId}/resources/`);
            setResources(response.data.results || []);
        } catch (error) {
            toast({
                title: "Error",
                description: "Failed to load resources",
                variant: "destructive"
            });
        }
    };

    const ResourceCard = ({ resource }) => (
        <Card>
            <CardContent className="p-4">
                <div className="flex items-center space-x-4">
                    <div className={`p-2 rounded-full 
                        ${resource.type === 'labor' ? 'bg-blue-100' :
                            resource.type === 'equipment' ? 'bg-green-100' : 'bg-yellow-100'}`}>
                        {resource.type === 'labor' ? <Users className="h-5 w-5" /> :
                            resource.type === 'equipment' ? <Wrench className="h-5 w-5" /> :
                                <Box className="h-5 w-5" />}
                    </div>
                    <div className="flex-1">
                        <h3 className="font-medium">{resource.name}</h3>
                        <p className="text-sm text-gray-500">{resource.availability}</p>
                    </div>
                    <div className="text-right">
                        <p className="font-medium">${resource.cost_rate}/hr</p>
                        <p className="text-sm text-gray-500">{resource.utilization}% utilized</p>
                    </div>
                </div>
            </CardContent>
        </Card>
    );

    return (
        <div className="space-y-6">
            <div className="flex justify-between items-center">
                <div className="flex items-center gap-4">
                    <Select value={resourceType} onValueChange={setResourceType}>
                        <SelectTrigger className="w-32">
                            <SelectValue placeholder="Type" />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectItem value="labor">Labor</SelectItem>
                            <SelectItem value="equipment">Equipment</SelectItem>
                            <SelectItem value="material">Material</SelectItem>
                        </SelectContent>
                    </Select>
                    <Input
                        placeholder="Search resources..."
                        className="max-w-xs"
                    />
                </div>
                <Button className={primaryButtonClasses}>
                    <Plus className="h-4 w-4 mr-2" />
                    Add Resource
                </Button>
            </div>

            <div className="grid grid-cols-1 gap-4">
                {resources
                    .filter(r => r.type === resourceType)
                    .map(resource => (
                        <ResourceCard key={resource.id} resource={resource} />
                    ))
                }
                {resources.filter(r => r.type === resourceType).length === 0 && (
                    <p className="text-center text-gray-500 py-4">
                        No {resourceType} resources found.
                    </p>
                )}
            </div>
        </div>
    );
};

export default ResourceManager;