import React, { useState, useEffect } from 'react';
import { Upload, AlertCircle, CheckCircle2, AlertTriangle } from 'lucide-react';
import { Alert, AlertDescription, AlertTitle } from "../components/ui/alert";
import { PDFDownloadLink, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import SafetyAnalysisResults from './SafetyAnalysisResults';
import api from '../services/api';

// PDF Report Styles remain the same
const pdfStyles = StyleSheet.create({
  page: { padding: 30 },
  title: { fontSize: 24, marginBottom: 10 },
  subtitle: { fontSize: 18, marginBottom: 10 },
  text: { fontSize: 12, marginBottom: 5 },
});

// PDF Report Component remains the same
const SafetyReport = ({ analysisResults }) => {
  if (!analysisResults || !analysisResults.compliance_summary) {
    return (
      <Document>
        <Page size="A4" style={pdfStyles.page}>
          <Text style={pdfStyles.title}>Safety Analysis Report</Text>
          <Text style={pdfStyles.text}>No analysis results available</Text>
        </Page>
      </Document>
    );
  }

  return (
    <Document>
      <Page size="A4" style={pdfStyles.page}>
        <Text style={pdfStyles.subtitle}>Compliance Summary</Text>
        <Text style={pdfStyles.text}>Total Requirements: {analysisResults.compliance_summary.total_requirements}</Text>
        <Text style={pdfStyles.text}>Compliant Items: {analysisResults.compliance_summary.compliant_items}</Text>
        <Text style={pdfStyles.text}>Violations: {analysisResults.compliance_summary.violation_count}</Text>

        <Text style={pdfStyles.subtitle}>Safety Issues Report</Text>
        {Object.entries(analysisResults.safety_issues).map(([item, data], index) => (
          <View key={index} style={{ marginBottom: 10 }}>
            <Text style={pdfStyles.text}>{item}</Text>
            <Text style={pdfStyles.text}>Compliant: {data.compliant ? 'Yes' : 'No'}</Text>
            <Text style={pdfStyles.text}>Detection Rate: {data.detection_rate}%</Text>
            {data.osha_code && <Text style={pdfStyles.text}>OSHA Code: {data.osha_code}</Text>}
          </View>
        ))}
      </Page>
    </Document>
  );
};

const VideoAnalysis = ({ project }) => {
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [ppeRequirements, setPpeRequirements] = useState([]);
  const [analysisResults, setAnalysisResults] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [processingProgress, setProcessingProgress] = useState(0);

  useEffect(() => {
    const fetchPpeRequirements = async () => {
      if (!project?.id) return;

      try {
        const response = await api.get('ppe-requirements/');
        setPpeRequirements(response.data);
      } catch (err) {
        console.error('Error fetching PPE requirements:', err);
        setError('No project requirements');
      }
    };

    fetchPpeRequirements();
  }, [project]);

  const handleUpload = async () => {
    if (!selectedFile || !project?.id) {
      setError('Please select a file and ensure a project is selected');
      return;
    }

    try {
      setUploading(true);
      setError(null);
      setSuccess(false);
      setUploadProgress(0);
      setProcessingProgress(0);
      setAnalysisResults(null);

      const formData = new FormData();
      formData.append('video', selectedFile);
      formData.append('file_name', selectedFile.name);
      formData.append('total_size', selectedFile.size.toString());
      formData.append('content_type', selectedFile.type);


      const createResponse = await api.post(
        `projects/${project.id}/video-analysis/`,
        formData,
        {
          headers: {
            'Accept': 'application/json',
          },
          onUploadProgress: (progressEvent) => {
            const progress = (progressEvent.loaded / progressEvent.total) * 100;
            setUploadProgress(Math.round(progress));
          },
        }
      );


      const analysisId = createResponse.data.id;

      setIsProcessing(true);
      setUploading(false);

      // Simulate processing progress (since actual processing progress might not be available)
      const progressInterval = setInterval(() => {
        setProcessingProgress(prev => {
          if (prev >= 90) {
            clearInterval(progressInterval);
            return prev;
          }
          return prev + 10;
        });
      }, 2000);

      const analyzeResponse = await api.post(
        `projects/${project.id}/video-analysis/${analysisId}/analyze/`
      );

      clearInterval(progressInterval);
      setProcessingProgress(100);
      setAnalysisResults(analyzeResponse.data);
      setIsProcessing(false);
      setSuccess(true);

      console.log('Analysis completed:', analyzeResponse.data);

    } catch (err) {
      console.error('Upload/analysis error:', err);
      setError(err.response?.data?.error || err.message || 'Error during video analysis');
      setSuccess(false);
    } finally {
      setUploading(false);
      setIsProcessing(false);
    }
  };

  const handleFileSelect = (event) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const validTypes = [
      'video/mp4',
      'video/mpeg',
      'video/quicktime',
      'video/x-msvideo',
      'video/x-ms-wmv',
      'video/webm',
    ];

    if (!validTypes.includes(file.type)) {
      setError('Invalid file type. Please upload a valid video file.');
      return;
    }

    const maxSize = 100 * 1024 * 1024;
    if (file.size > maxSize) {
      setError('File size exceeds 100MB limit.');
      return;
    }

    setSelectedFile(file);
    setError(null);
  };

  if (!project) {
    return <div className="text-center p-6">Please select a project to continue.</div>;
  }

  return (
    <div className="max-w-4xl mx-auto p-6">
      <h2 className="text-3xl font-bold mb-6">Safety Video Analysis</h2>

      {/* PPE Requirements Section */}
      {ppeRequirements.length > 0 && (
        <div className="mb-6">
          <h3 className="text-xl font-semibold mb-2">Required PPE for this Project:</h3>
          <div className="bg-gray-50 p-4 rounded">
            {ppeRequirements.map(ppe => (
              <div key={ppe.id} className="mb-2">
                <p className="font-medium">{ppe.item_name}</p>
                <p className="text-sm text-gray-600">Required Accuracy: {ppe.required_accuracy * 100}%</p>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Upload Section */}
      <div className="w-full max-w-md mx-auto p-6 space-y-4">
        <div
          className="border-2 border-dashed border-gray-300 rounded-lg p-6 text-center hover:border-gray-400 transition-colors cursor-pointer"
          onClick={() => document.getElementById('fileInput').click()}
        >
          <Upload className="mx-auto h-12 w-12 text-gray-400" />
          <input
            id="fileInput"
            type="file"
            accept="video/*"
            onChange={handleFileSelect}
            className="hidden"
          />
          <p className="mt-2 text-sm text-gray-600">
            {selectedFile ? selectedFile.name : 'Click to upload or drag and drop'}
          </p>
          <p className="mt-1 text-xs text-gray-500">
            MP4, AVI, MOV up to 100MB
          </p>
        </div>

        {(uploading || isProcessing) && (
          <div className="space-y-2">
            <div className="w-full bg-gray-200 rounded-full h-2.5">
              <div
                className="bg-blue-600 h-2.5 rounded-full transition-all duration-300"
                style={{ width: `${isProcessing ? processingProgress : uploadProgress}%` }}
              />
            </div>
            <p className="text-sm text-gray-600 text-center">
              {isProcessing ? `Processing... ${processingProgress}%` : `Uploading... ${uploadProgress}%`}
            </p>
          </div>
        )}

        {error && (
          <Alert variant="destructive">
            <AlertCircle className="h-4 w-4" />
            <AlertTitle>Error</AlertTitle>
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}

        {success && (
          <Alert className="bg-green-50 border-green-200">
            <CheckCircle2 className="h-4 w-4 text-green-600" />
            <AlertTitle className="text-green-800">Success</AlertTitle>
            <AlertDescription className="text-green-700">
              Video uploaded and analysis completed!
            </AlertDescription>
          </Alert>
        )}

        <button
          onClick={handleUpload}
          disabled={!selectedFile || uploading || isProcessing}
          className={`w-full py-2 px-4 rounded-md text-white font-medium ${!selectedFile || uploading || isProcessing
            ? 'bg-gray-400 cursor-not-allowed'
            : 'bg-blue-600 hover:bg-blue-700'
            }`}
        >
          {uploading ? 'Uploading...' : isProcessing ? 'Processing...' : 'Start Analysis'}
        </button>
      </div>

      {/* Results Section */}
      {analysisResults && (
        <div className="mt-8">
          <h3 className="text-2xl font-bold mb-4">Analysis Results</h3>
          <div className="bg-white shadow rounded-lg p-6 mb-4">
            <h4 className="text-lg font-semibold mb-3">Compliance Summary</h4>
            <div className="grid grid-cols-3 gap-4">
              <div className="text-center">
                <p className="text-3xl font-bold text-blue-600">
                  {analysisResults.compliance_summary.total_requirements}
                </p>
                <p className="text-gray-600">Total Requirements</p>
              </div>
              <div className="text-center">
                <p className="text-3xl font-bold text-green-600">
                  {analysisResults.compliance_summary.compliant_items}
                </p>
                <p className="text-gray-600">Compliant Items</p>
              </div>
              <div className="text-center">
                <p className="text-3xl font-bold text-red-600">
                  {analysisResults.compliance_summary.violation_count}
                </p>
                <p className="text-gray-600">Violations</p>
              </div>
            </div>
          </div>

          <SafetyAnalysisResults
            analysisData={analysisResults}
            key={JSON.stringify(analysisResults)}
          />

          <div className="mt-6">
            <PDFDownloadLink
              document={<SafetyReport analysisResults={analysisResults} />}
              fileName="safety_analysis_report.pdf"
              className="inline-block bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded transition-colors"
            >
              {({ loading }) => (loading ? 'Preparing document...' : 'Download Report')}
            </PDFDownloadLink>
          </div>
        </div>
      )}
    </div>
  );
};

export default VideoAnalysis;