import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
} from "../ui/cardui";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
    FormDescription
} from "../ui/form";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "../ui/select";
import { Input } from "../ui/input";
import { Button } from "../ui/button";
import { Textarea } from "../ui/textarea";
import {
    Building2,
    Mail,
    User,
    Briefcase,
    Loader2
} from 'lucide-react';
import { contractorsApi } from '../../services/contractors';
import { showToast } from '../../utils/toast';
import api from '../../services/api';
import { primaryButtonClasses, secondaryButtonClasses } from '../common/ButtonStyles';

const ContractorInviteForm = ({ onClose }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [companies, setCompanies] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const form = useForm({
        defaultValues: {
            company_id: '',
            first_name: '',
            last_name: '',
            email: '',
            role: '',
            message: ''
        }
    });

    // Fetch companies when component mounts
    useEffect(() => {
        const fetchCompanies = async () => {
            try {
                setIsLoading(true);
                const response = await api.get('companies/');
                setCompanies(response.data.results || []);
            } catch (err) {
                setError('Failed to load companies');
                showToast.error('Failed to load companies');
            } finally {
                setIsLoading(false);
            }
        };

        fetchCompanies();
    }, []);

    const onSubmit = async (formData) => {
        setIsSubmitting(true);
        try {
            // Find the selected company to get its name
            const selectedCompany = companies.find(
                company => company.id.toString() === formData.company_id
            );

            if (!selectedCompany) {
                throw new Error('Selected company not found');
            }

            // Prepare the data in the format the API expects
            const inviteData = {
                email: formData.email,
                company_name: selectedCompany.name,
                first_name: formData.first_name,
                last_name: formData.last_name,
                // Only include message if it's not empty
                ...(formData.message && { message: formData.message })
            };

            const response = await api.post('contractors/invite/', inviteData);

            if (response.data.message) {
                showToast.success(response.data.message);
            } else {
                showToast.success('Invitation sent successfully');
            }

            onClose();
        } catch (error) {
            let errorMessage = 'Failed to send invitation';

            if (error.response?.data?.error) {
                errorMessage = error.response.data.error;
            } else if (error.message) {
                errorMessage = error.message;
            }

            showToast.error(errorMessage);
        } finally {
            setIsSubmitting(false);
        }
    };


    if (isLoading) {
        return (
            <div className="flex items-center justify-center h-full">
                <Loader2 className="h-8 w-8 animate-spin text-blue-500" />
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex flex-col items-center justify-center h-full p-6">
                <div className="text-red-500 mb-4">{error}</div>
                <Button variant="outline" onClick={onClose}>Close</Button>
            </div>
        );
    }

    return (
        <div className="flex flex-col h-[calc(100vh-4rem)]">
            {/* Header */}
            <div className="flex items-center justify-between px-6 py-4 border-b">
                <div className="flex items-center gap-2">
                    <Mail className="h-5 w-5 text-blue-500" />
                    <h2 className="text-lg font-semibold">Invite Contractor</h2>
                </div>
                <div className="flex space-x-2">
                    <Button classname={secondaryButtonClasses} variant="outline" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button
                        className={primaryButtonClasses}
                        onClick={form.handleSubmit(onSubmit)}
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? (
                            <>
                                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                Sending...
                            </>
                        ) : (
                            'Send Invitation'
                        )}
                    </Button>
                </div>
            </div>

            {/* Scrollable Content */}
            <div className="flex-1 overflow-y-auto">
                <div className="max-w-2xl mx-auto p-6">
                    <Form {...form}>
                        <form className="space-y-6">
                            {/* Company Selection */}
                            <Card>
                                <CardHeader>
                                    <CardTitle className="flex items-center gap-2">
                                        <Building2 className="h-5 w-5 text-gray-500" />
                                        Company Information
                                    </CardTitle>
                                </CardHeader>
                                <CardContent>
                                    <FormField
                                        control={form.control}
                                        name="company_id"
                                        rules={{ required: "Please select a company" }}
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormLabel>Select Company</FormLabel>
                                                <Select
                                                    onValueChange={field.onChange}
                                                    defaultValue={field.value}
                                                >
                                                    <FormControl>
                                                        <SelectTrigger>
                                                            <SelectValue placeholder="Select a company" />
                                                        </SelectTrigger>
                                                    </FormControl>
                                                    <SelectContent>
                                                        {companies.length === 0 ? (
                                                            <SelectItem value="" disabled>
                                                                No companies available
                                                            </SelectItem>
                                                        ) : (
                                                            companies.map(company => (
                                                                <SelectItem
                                                                    key={company.id}
                                                                    value={company.id.toString()}
                                                                >
                                                                    {company.name}
                                                                </SelectItem>
                                                            ))
                                                        )}
                                                    </SelectContent>
                                                </Select>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                </CardContent>
                            </Card>

                            {/* Rest of the form remains unchanged */}
                            {/* Contractor Details */}
                            <Card>
                                <CardHeader>
                                    <CardTitle className="flex items-center gap-2">
                                        <User className="h-5 w-5 text-gray-500" />
                                        Contractor Details
                                    </CardTitle>
                                </CardHeader>
                                <CardContent className="space-y-4">
                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                        <FormField
                                            control={form.control}
                                            name="first_name"
                                            rules={{ required: "First name is required" }}
                                            render={({ field }) => (
                                                <FormItem>
                                                    <FormLabel>First Name</FormLabel>
                                                    <FormControl>
                                                        <Input placeholder="Enter first name" {...field} />
                                                    </FormControl>
                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />

                                        <FormField
                                            control={form.control}
                                            name="last_name"
                                            rules={{ required: "Last name is required" }}
                                            render={({ field }) => (
                                                <FormItem>
                                                    <FormLabel>Last Name</FormLabel>
                                                    <FormControl>
                                                        <Input placeholder="Enter last name" {...field} />
                                                    </FormControl>
                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />
                                    </div>

                                    <FormField
                                        control={form.control}
                                        name="email"
                                        rules={{
                                            required: "Email is required",
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                message: "Invalid email address"
                                            }
                                        }}
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormLabel>Email Address</FormLabel>
                                                <FormControl>
                                                    <Input
                                                        type="email"
                                                        placeholder="contractor@yourcompany.com"
                                                        {...field}
                                                    />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />

                                    <FormField
                                        control={form.control}
                                        name="role"
                                        rules={{ required: "Role is required" }}
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormLabel>Role/Position</FormLabel>
                                                <FormControl>
                                                    <Input
                                                        placeholder="e.g. Project Manager, Engineer"
                                                        {...field}
                                                    />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                </CardContent>
                            </Card>

                            {/* Invitation Message */}
                            <Card>
                                <CardHeader>
                                    <CardTitle className="flex items-center gap-2">
                                        <Mail className="h-5 w-5 text-gray-500" />
                                        Invitation Message
                                    </CardTitle>
                                </CardHeader>
                                <CardContent>
                                    <FormField
                                        control={form.control}
                                        name="message"
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormLabel>Personal Message (Optional)</FormLabel>
                                                <FormControl>
                                                    <Textarea
                                                        placeholder="Add a personal message to the invitation email..."
                                                        className="h-32 resize-none"
                                                        {...field}
                                                    />
                                                </FormControl>
                                                <FormDescription>
                                                    This message will be included in the invitation email sent to the contractor.
                                                </FormDescription>
                                            </FormItem>
                                        )}
                                    />
                                </CardContent>
                            </Card>
                        </form>
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default ContractorInviteForm;