import React from 'react';
import { Cloud, Settings } from 'lucide-react';

const DocumentHeader = () => {
    return (
        <div className="flex items-center justify-between p-4 border-b border-gray-200">
            <div className="flex items-center space-x-2">
                <span className="text-lg font-semibold">Document Management</span>
            </div>
            <div className="flex items-center space-x-4">
                <Cloud className="w-5 h-5 text-gray-600 cursor-pointer hover:text-gray-900" />
                <Settings className="w-5 h-5 text-gray-600 cursor-pointer hover:text-gray-900" />
            </div>
        </div>
    );
};

export default DocumentHeader;