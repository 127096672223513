import React, { useState, useEffect } from 'react';
import { Button } from "../ui/button";
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from "../ui/cardui";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "../ui/tabs";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../ui/select";
import { Alert, AlertDescription } from '../ui/alert';
import { formatCurrency, formatPercentage } from '../../utils/formatter';
import budgetService from '../../services/budgetService';
import { useToast } from '../ui/use-toast';
import {
    LineChart, BarChart, PieChart, Line, Bar, Pie,
    XAxis, YAxis, CartesianGrid, Tooltip, Legend, Cell,
    ResponsiveContainer
} from 'recharts';
import {
    ArrowUpRight, ArrowDownRight, DollarSign,
    TrendingUp, TrendingDown, RefreshCw, AlertCircle,
    Clock, Users, Package, Truck, PenTool
} from 'lucide-react';
import { Table, TableHeader, TableRow, TableHead, TableBody, TableCell } from "../ui/table";
import { Separator } from "../ui/separator";
import { Badge } from "../ui/badge";

export default function CostManagementDashboard({ projectId, budgetId, onRefresh, canAccessAdvancedCost = false, canAccessEVM = false }) {
    const { toast } = useToast();
    const [activeTab, setActiveTab] = useState('overview');
    const [dateRange, setDateRange] = useState('6months');
    const [costData, setCostData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [refreshing, setRefreshing] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        loadCostData();
    }, [budgetId, dateRange]);

    const loadCostData = async () => {
        try {
            setLoading(true);
            setError(null);

            // Fetch cost performance data
            const response = await budgetService.getCostPerformance(budgetId);
            setCostData(response);
        } catch (error) {
            console.error('Error loading cost data:', error);
            setError('Failed to load cost data. Please try again later.');
            toast({
                title: "Error",
                description: "Failed to load cost data",
                variant: "destructive"
            });
        } finally {
            setLoading(false);
        }
    };

    const handleRefresh = async () => {
        try {
            setRefreshing(true);

            // Recalculate budget
            await budgetService.recalculateBudget(budgetId);

            // Create a snapshot if advanced cost management is enabled
            if (canAccessAdvancedCost) {
                await budgetService.createBudgetSnapshot(budgetId);
            }

            // Calculate EVM metrics if EVM is enabled
            if (canAccessEVM) {
                await budgetService.calculateEVMMetrics(projectId);
            }

            // Reload data
            await loadCostData();

            if (onRefresh) {
                onRefresh();
            }

            toast({
                title: "Success",
                description: "Cost data refreshed successfully"
            });
        } catch (error) {
            console.error('Error refreshing data:', error);
            toast({
                title: "Error",
                description: "Failed to refresh cost data",
                variant: "destructive"
            });
        } finally {
            setRefreshing(false);
        }
    };

    // Helper function to determine variance color
    const getVarianceColor = (variance) => {
        if (variance > 5) return 'text-green-600';
        if (variance < -5) return 'text-red-600';
        return 'text-amber-600';
    };

    if (loading) {
        return (
            <div className="flex items-center justify-center h-64">
                <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
                <span className="ml-3">Loading cost data...</span>
            </div>
        );
    }

    if (error) {
        return (
            <Alert>
                <AlertCircle className="h-4 w-4" />
                <AlertDescription>
                    {error}
                    <Button onClick={handleRefresh} variant="outline" className="ml-2" disabled={refreshing}>
                        {refreshing ? 'Refreshing...' : 'Try Again'}
                    </Button>
                </AlertDescription>
            </Alert>
        );
    }

    if (!costData) {
        return (
            <Alert>
                <AlertCircle className="h-4 w-4" />
                <AlertDescription>
                    No cost data available. Try refreshing the data.
                    <Button onClick={handleRefresh} variant="outline" className="ml-2" disabled={refreshing}>
                        {refreshing ? 'Refreshing...' : 'Refresh Data'}
                    </Button>
                </AlertDescription>
            </Alert>
        );
    }

    // Format data for charts
    const categoryData = costData.category_breakdown || [];
    const monthlyData = costData.monthly_trend || [];
    const evmData = costData.evm_metrics || [];

    // Get cost type breakdown data
    const costTypeData = [
        { type: 'LABOR', name: 'Labor', value: costData.summary.labor_total || 0, color: '#3B82F6', icon: <Users className="h-4 w-4" /> },
        { type: 'MATERIAL', name: 'Material', value: costData.summary.material_total || 0, color: '#10B981', icon: <Package className="h-4 w-4" /> },
        { type: 'SUBCONTRACTOR', name: 'Subcontractor', value: costData.summary.subcontractor_total || 0, color: '#8B5CF6', icon: <Truck className="h-4 w-4" /> },
        { type: 'EQUIPMENT', name: 'Equipment', value: costData.summary.equipment_total || 0, color: '#F59E0B', icon: <PenTool className="h-4 w-4" /> },
        { type: 'OTHER', name: 'Other', value: costData.summary.other_total || 0, color: '#6B7280', icon: <DollarSign className="h-4 w-4" /> }
    ].filter(item => item.value > 0);

    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884D8', '#82CA9D'];

    return (
        <div className="space-y-6">
            <div className="flex justify-between items-center">
                <h2 className="text-2xl font-bold">Cost Management Dashboard</h2>

                <div className="flex space-x-2">
                    <Select value={dateRange} onValueChange={setDateRange}>
                        <SelectTrigger className="w-[180px]">
                            <SelectValue placeholder="Select date range" />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectItem value="3months">Last 3 Months</SelectItem>
                            <SelectItem value="6months">Last 6 Months</SelectItem>
                            <SelectItem value="12months">Last 12 Months</SelectItem>
                            <SelectItem value="all">All Time</SelectItem>
                        </SelectContent>
                    </Select>

                    <Button onClick={handleRefresh} disabled={refreshing}>
                        {refreshing ? 'Refreshing...' : 'Refresh Data'}
                        <RefreshCw className={`ml-2 h-4 w-4 ${refreshing ? 'animate-spin' : ''}`} />
                    </Button>
                </div>
            </div>

            {/* Cost Summary Cards */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                <Card>
                    <CardContent className="pt-6">
                        <div className="flex justify-between">
                            <div>
                                <p className="text-sm text-gray-500">Budget Total</p>
                                <p className="text-2xl font-bold">{formatCurrency(costData.summary.budget_total)}</p>
                            </div>
                            <div className="h-12 w-12 rounded-full bg-blue-100 flex items-center justify-center">
                                <DollarSign className="h-6 w-6 text-blue-600" />
                            </div>
                        </div>
                    </CardContent>
                </Card>

                <Card>
                    <CardContent className="pt-6">
                        <div className="flex justify-between">
                            <div>
                                <p className="text-sm text-gray-500">Actual Cost</p>
                                <p className="text-2xl font-bold">{formatCurrency(costData.summary.actual_total)}</p>
                            </div>
                            <div className="h-12 w-12 rounded-full bg-indigo-100 flex items-center justify-center">
                                <DollarSign className="h-6 w-6 text-indigo-600" />
                            </div>
                        </div>
                    </CardContent>
                </Card>

                <Card>
                    <CardContent className="pt-6">
                        <div className="flex justify-between">
                            <div>
                                <p className="text-sm text-gray-500">Variance</p>
                                <div className="flex items-center">
                                    <p className={`text-2xl font-bold ${getVarianceColor(costData.summary.variance_percentage)}`}>
                                        {formatCurrency(costData.summary.variance_amount)}
                                    </p>
                                    {costData.summary.variance_amount > 0 ? (
                                        <ArrowUpRight className="ml-2 h-5 w-5 text-green-600" />
                                    ) : (
                                        <ArrowDownRight className="ml-2 h-5 w-5 text-red-600" />
                                    )}
                                </div>
                                <p className={`text-sm ${getVarianceColor(costData.summary.variance_percentage)}`}>
                                    {formatPercentage(costData.summary.variance_percentage / 100)}
                                </p>
                            </div>
                            {costData.summary.variance_amount > 0 ? (
                                <div className="h-12 w-12 rounded-full bg-green-100 flex items-center justify-center">
                                    <TrendingUp className="h-6 w-6 text-green-600" />
                                </div>
                            ) : (
                                <div className="h-12 w-12 rounded-full bg-red-100 flex items-center justify-center">
                                    <TrendingDown className="h-6 w-6 text-red-600" />
                                </div>
                            )}
                        </div>
                    </CardContent>
                </Card>

                {canAccessEVM && (
                    <Card>
                        <CardContent className="pt-6">
                            <div className="flex justify-between">
                                <div>
                                    <p className="text-sm text-gray-500">Cost Performance Index</p>
                                    <p className={`text-2xl font-bold ${costData.summary.cpi >= 1 ? 'text-green-600' : 'text-red-600'}`}>
                                        {costData.summary.cpi?.toFixed(2) || "N/A"}
                                    </p>
                                    <p className="text-sm text-gray-500">
                                        {costData.summary.cpi >= 1 ? 'Under Budget' : 'Over Budget'}
                                    </p>
                                </div>
                                <div className={`h-12 w-12 rounded-full ${costData.summary.cpi >= 1 ? 'bg-green-100' : 'bg-red-100'} flex items-center justify-center`}>
                                    {costData.summary.cpi >= 1 ? (
                                        <TrendingUp className="h-6 w-6 text-green-600" />
                                    ) : (
                                        <TrendingDown className="h-6 w-6 text-red-600" />
                                    )}
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                )}
            </div>

            {/* Main Content Tabs */}
            <Tabs value={activeTab} onValueChange={setActiveTab}>
                <TabsList className="grid grid-cols-3 mb-4">
                    <TabsTrigger value="overview">Overview</TabsTrigger>
                    <TabsTrigger value="categories">Cost Categories</TabsTrigger>
                    {canAccessEVM && (
                        <TabsTrigger value="evm">Earned Value</TabsTrigger>
                    )}
                </TabsList>

                {/* Overview Tab */}
                <TabsContent value="overview" className="space-y-4">
                    <Card>
                        <CardHeader>
                            <CardTitle>Cost Trend</CardTitle>
                            <CardDescription>Monthly trend of budget vs. actual vs. forecast costs</CardDescription>
                        </CardHeader>
                        <CardContent>
                            <div className="h-80">
                                <ResponsiveContainer width="100%" height="100%">
                                    <LineChart data={monthlyData}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="date" />
                                        <YAxis />
                                        <Tooltip formatter={(value) => formatCurrency(value)} />
                                        <Legend />
                                        <Line type="monotone" dataKey="budget" stroke="#8884d8" name="Budget" />
                                        <Line type="monotone" dataKey="actual" stroke="#82ca9d" name="Actual" />
                                        <Line type="monotone" dataKey="forecast" stroke="#ffc658" name="Forecast" strokeDasharray="5 5" />
                                        <Line type="monotone" dataKey="committed" stroke="#ff7300" name="Committed" />
                                    </LineChart>
                                </ResponsiveContainer>
                            </div>
                        </CardContent>
                    </Card>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <Card>
                            <CardHeader>
                                <CardTitle>Cost Distribution by Type</CardTitle>
                                <CardDescription>Breakdown of actual costs by type</CardDescription>
                            </CardHeader>
                            <CardContent>
                                <div className="h-64">
                                    <ResponsiveContainer width="100%" height="100%">
                                        <PieChart>
                                            <Pie
                                                data={costTypeData}
                                                cx="50%"
                                                cy="50%"
                                                outerRadius={80}
                                                innerRadius={40}
                                                dataKey="value"
                                                nameKey="name"
                                                label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                                            >
                                                {costTypeData.map((entry, index) => (
                                                    <Cell key={`cell-${index}`} fill={entry.color} />
                                                ))}
                                            </Pie>
                                            <Tooltip formatter={(value) => formatCurrency(value)} />
                                            <Legend />
                                        </PieChart>
                                    </ResponsiveContainer>
                                </div>
                                <div className="mt-4">
                                    <h3 className="text-sm font-medium mb-2">Cost Type Breakdown</h3>
                                    <div className="space-y-2">
                                        {costTypeData.map((type, index) => (
                                            <div key={index} className="flex items-center justify-between">
                                                <div className="flex items-center">
                                                    <div className="w-3 h-3 rounded-full" style={{ backgroundColor: type.color }}></div>
                                                    <span className="ml-2 flex items-center">
                                                        {type.icon}
                                                        <span className="ml-2">{type.name}</span>
                                                    </span>
                                                </div>
                                                <span>{formatCurrency(type.value)}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </CardContent>
                        </Card>

                        <Card>
                            <CardHeader>
                                <CardTitle>Budget vs. Actual by Category</CardTitle>
                                <CardDescription>Comparison by cost category</CardDescription>
                            </CardHeader>
                            <CardContent>
                                <div className="h-64">
                                    <ResponsiveContainer width="100%" height="100%">
                                        <BarChart data={categoryData}>
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="name" />
                                            <YAxis />
                                            <Tooltip formatter={(value) => formatCurrency(value)} />
                                            <Legend />
                                            <Bar dataKey="budget" fill="#8884d8" name="Budget" />
                                            <Bar dataKey="actual" fill="#82ca9d" name="Actual" />
                                        </BarChart>
                                    </ResponsiveContainer>
                                </div>
                            </CardContent>
                        </Card>
                    </div>

                    {canAccessAdvancedCost && (
                        <Card>
                            <CardHeader>
                                <CardTitle>Performance Metrics</CardTitle>
                                <CardDescription>Key cost performance indicators</CardDescription>
                            </CardHeader>
                            <CardContent>
                                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                                    <div className="p-4 bg-blue-50 rounded-lg">
                                        <p className="text-sm text-blue-700 mb-1">Budget Performance</p>
                                        <p className="text-2xl font-bold">
                                            {((costData.summary.budget_total - costData.summary.actual_total) / costData.summary.budget_total * 100).toFixed(1)}%
                                        </p>
                                    </div>

                                    <div className="p-4 bg-green-50 rounded-lg">
                                        <p className="text-sm text-green-700 mb-1">Cost Variance</p>
                                        <p className="text-2xl font-bold">
                                            {formatCurrency(costData.summary.variance_amount)}
                                        </p>
                                    </div>

                                    <div className="p-4 bg-purple-50 rounded-lg">
                                        <p className="text-sm text-purple-700 mb-1">Forecast at Completion</p>
                                        <p className="text-2xl font-bold">
                                            {formatCurrency(costData.summary.forecast_total || costData.summary.budget_total)}
                                        </p>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    )}
                </TabsContent>

                {/* Cost Categories Tab */}
                <TabsContent value="categories" className="space-y-4">
                    <Card>
                        <CardHeader>
                            <CardTitle>Cost Category Analysis</CardTitle>
                            <CardDescription>Detailed view of categories and their performance</CardDescription>
                        </CardHeader>
                        <CardContent>
                            <Table>
                                <TableHeader>
                                    <TableRow>
                                        <TableHead>Category</TableHead>
                                        <TableHead className="text-right">Budget</TableHead>
                                        <TableHead className="text-right">Actual</TableHead>
                                        <TableHead className="text-right">Variance</TableHead>
                                        <TableHead className="text-right">Variance %</TableHead>
                                        <TableHead className="text-right">Status</TableHead>
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                    {categoryData.map((category, index) => (
                                        <TableRow key={index}>
                                            <TableCell className="font-medium">{category.name}</TableCell>
                                            <TableCell className="text-right">{formatCurrency(category.budget)}</TableCell>
                                            <TableCell className="text-right">{formatCurrency(category.actual)}</TableCell>
                                            <TableCell className="text-right">{formatCurrency(category.variance)}</TableCell>
                                            <TableCell className={`text-right ${getVarianceColor(category.variance_pct)}`}>
                                                {formatPercentage(category.variance_pct / 100)}
                                            </TableCell>
                                            <TableCell className="text-right">
                                                <Badge variant={category.variance > 0 ? "success" : category.variance < 0 ? "destructive" : "outline"}>
                                                    {category.variance > 0 ? "Under Budget" : category.variance < 0 ? "Over Budget" : "On Budget"}
                                                </Badge>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </CardContent>
                    </Card>
                </TabsContent>

                {/* EVM Tab */}
                {canAccessEVM && (
                    <TabsContent value="evm" className="space-y-4">
                        <Card>
                            <CardHeader>
                                <CardTitle>Earned Value Management</CardTitle>
                                <CardDescription>Key EVM metrics over time</CardDescription>
                            </CardHeader>
                            <CardContent>
                                <div className="h-80">
                                    <ResponsiveContainer width="100%" height="100%">
                                        <LineChart data={evmData}>
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="date" />
                                            <YAxis />
                                            <Tooltip formatter={(value, name) => {
                                                if (name === 'cpi' || name === 'spi') {
                                                    return [value.toFixed(2), name.toUpperCase()];
                                                }
                                                return [formatCurrency(value), name.toUpperCase()];
                                            }} />
                                            <Legend />
                                            <Line type="monotone" dataKey="pv" stroke="#8884d8" name="PV" />
                                            <Line type="monotone" dataKey="ev" stroke="#82ca9d" name="EV" />
                                            <Line type="monotone" dataKey="ac" stroke="#ff7300" name="AC" />
                                        </LineChart>
                                    </ResponsiveContainer>
                                </div>

                                <div className="grid grid-cols-2 gap-4 mt-4">
                                    <Card>
                                        <CardContent className="p-4">
                                            <h3 className="font-medium mb-2">Performance Indices</h3>
                                            <ResponsiveContainer width="100%" height={100}>
                                                <LineChart data={evmData}>
                                                    <XAxis dataKey="date" />
                                                    <YAxis domain={[0, 2]} />
                                                    <Tooltip formatter={(value) => value.toFixed(2)} />
                                                    <Line type="monotone" dataKey="cpi" stroke="#8884d8" name="CPI" />
                                                    <Line type="monotone" dataKey="spi" stroke="#82ca9d" name="SPI" />
                                                    <CartesianGrid strokeDasharray="3 3" />
                                                    <Legend />
                                                </LineChart>
                                            </ResponsiveContainer>
                                        </CardContent>
                                    </Card>

                                    <Card>
                                        <CardContent className="p-4">
                                            <h3 className="font-medium mb-2">EVM Glossary</h3>
                                            <div className="text-sm space-y-1">
                                                <p><strong>PV</strong> - Planned Value: Budgeted cost of work scheduled</p>
                                                <p><strong>EV</strong> - Earned Value: Budgeted cost of work performed</p>
                                                <p><strong>AC</strong> - Actual Cost: Actual cost of work performed</p>
                                                <p><strong>CPI</strong> - Cost Performance Index: EV/AC (>1 is good)</p>
                                                <p><strong>SPI</strong> - Schedule Performance Index: EV/PV (>1 is good)</p>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </div>

                                <Separator className="my-6" />

                                <div className="space-y-4">
                                    <h3 className="text-lg font-medium">EVM Analysis</h3>

                                    <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                                        <Card>
                                            <CardContent className="p-4">
                                                <p className="text-sm text-gray-500">Budget at Completion (BAC)</p>
                                                <p className="text-xl font-bold">
                                                    {formatCurrency(costData.evm_summary?.bac || costData.summary.budget_total)}
                                                </p>
                                            </CardContent>
                                        </Card>

                                        <Card>
                                            <CardContent className="p-4">
                                                <p className="text-sm text-gray-500">Estimate at Completion (EAC)</p>
                                                <p className="text-xl font-bold">
                                                    {formatCurrency(costData.evm_summary?.eac || 0)}
                                                </p>
                                            </CardContent>
                                        </Card>

                                        <Card>
                                            <CardContent className="p-4">
                                                <p className="text-sm text-gray-500">Estimate to Complete (ETC)</p>
                                                <p className="text-xl font-bold">
                                                    {formatCurrency(costData.evm_summary?.etc || 0)}
                                                </p>
                                            </CardContent>
                                        </Card>

                                        <Card>
                                            <CardContent className="p-4">
                                                <p className="text-sm text-gray-500">Variance at Completion (VAC)</p>
                                                <p className={`text-xl font-bold ${costData.evm_summary?.vac > 0 ? 'text-green-600' : 'text-red-600'}`}>
                                                    {formatCurrency(costData.evm_summary?.vac || 0)}
                                                </p>
                                            </CardContent>
                                        </Card>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    </TabsContent>
                )}
            </Tabs>
        </div>
    );
}