import React, { useState, useEffect } from 'react';
import { DatePicker } from "../../components/ui/date-picker";
import { Card, CardContent, CardHeader, CardTitle } from "../../components/ui/cardui";
import { Button } from "../../components/ui/button";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "../../components/ui/dialog";
import { Input } from "../../components/ui/input";
import { Label } from "../../components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../components/ui/select";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../components/ui/tabs";
import { Alert, AlertDescription } from "../../components/ui/alert";
import { Badge } from "../../components/ui/badge";
import { toast } from "react-toastify";
import api from '../../services/api';
import { primaryButtonClasses, secondaryButtonClasses } from '../common/ButtonStyles';

const ResourceForm = ({
    isOpen,
    onClose,
    onSubmit,
    projectId,
    tenantId
}) => {
    const [resourceType, setResourceType] = useState('');
    const [contractors, setContractors] = useState([]);
    const [trades, setTrades] = useState([]);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({});

    useEffect(() => {
        if (resourceType === 'labor') {
            fetchContractors();
            fetchTrades();

        }
    }, [resourceType]);
    const [suppliers, setSuppliers] = useState([]);
    const [categories, setCategories] = useState([]);

    // In your useEffect hook
    useEffect(() => {
        if (resourceType === 'labor') {
            fetchContractors();
            fetchTrades();
        } else if (resourceType === 'material') {
            fetchSuppliers();
            fetchCategories();
        }
    }, [resourceType]);

    // Add these functions
    const fetchSuppliers = async () => {
        try {
            const response = await api.get(`suppliers/`);
            setSuppliers(response.data.results || []);
        } catch (error) {
            console.error("Failed to load suppliers:", error);
            toast.error("Failed to load suppliers");
        }
    };

    const fetchCategories = async () => {
        try {
            const response = await api.get(`supplier-categories/`);
            setCategories(response.data.results || []);
        } catch (error) {
            console.error("Failed to load categories:", error);
            toast.error("Failed to load categories");
        }
    };

    const fetchContractors = async () => {
        try {
            const response = await api.get(`contractors/`, {
                params: { tenant: tenantId }
            });
            setContractors(response.data.results);
        } catch (error) {
            toast.error("Failed to load contractors");
        }
    };

    const fetchTrades = async () => {
        try {
            const response = await api.get(`construction-trades/`);
            console.log("trades: ", response);
            setTrades(response.data);
        } catch (error) {
            toast.error("Failed to load trades");
        }
    };


    const handleTypeChange = (type) => {
        setResourceType(type);
        setFormData({});
    };

    const renderLaborForm = () => (
        <>
            <div className="space-y-4">
                <div>
                    <Label>Labor Type</Label>
                    <Select onValueChange={(value) => setFormData({ ...formData, labor_type: value })}>
                        <SelectTrigger>
                            <SelectValue placeholder="Select type" />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectItem value="internal">Internal</SelectItem>
                            <SelectItem value="subcontractor">Subcontractor</SelectItem>
                            <SelectItem value="temporary">Temporary</SelectItem>
                        </SelectContent>
                    </Select>
                </div>

                <div>
                    <Label>Trade</Label>
                    <Select
                        onValueChange={(value) => {
                            console.log("Selected trade ID:", value);
                            setFormData({ ...formData, trade: parseInt(value) });
                        }}
                    >
                        <SelectTrigger>
                            <SelectValue placeholder="Select trade" />
                        </SelectTrigger>
                        <SelectContent>
                            {trades && trades.length > 0 ? (
                                trades.map(trade => (
                                    <SelectItem key={trade.id} value={trade.id.toString()}>
                                        {trade.name}
                                    </SelectItem>
                                ))
                            ) : (
                                <SelectItem value="none" disabled>No trades available</SelectItem>
                            )}
                        </SelectContent>
                    </Select>
                </div>

                {formData.labor_type === 'subcontractor' && (
                    <div>
                        <Label>Contractor</Label>
                        <Select onValueChange={(value) => {
                            if (value === 'create_new') {
                                // Navigate to contractor creation
                                window.location.href = '/contractors/create';
                                return;
                            }
                            setFormData({ ...formData, contractor: value })
                        }}>
                            <SelectTrigger>
                                <SelectValue placeholder="Select contractor" />
                            </SelectTrigger>
                            <SelectContent>
                                {contractors.length === 0 ? (
                                    <SelectItem value="create_new">+ Create New Contractor</SelectItem>
                                ) : (
                                    <>
                                        {contractors.map(contractor => (
                                            <SelectItem key={contractor.id} value={contractor.id}>
                                                {contractor.name}
                                            </SelectItem>
                                        ))}
                                        <SelectItem value="create_new" className="border-t">+ Create New Contractor</SelectItem>
                                    </>
                                )}
                            </SelectContent>
                        </Select>
                    </div>
                )}

                <div>
                    <Label>Cost per Hour</Label>
                    <Input
                        type="number"
                        value={formData.cost_per_hour || ''}
                        onChange={(e) => setFormData({ ...formData, cost_per_hour: e.target.value })}
                    />
                </div>

                <div>
                    <Label>Availability Start</Label>
                    <DatePicker
                        value={formData.availability_start}
                        onChange={(date) => setFormData({ ...formData, availability_start: date })}
                    />
                </div>

                <div>
                    <Label>Availability End</Label>
                    <DatePicker
                        value={formData.availability_end}
                        onChange={(date) => setFormData({ ...formData, availability_end: date })}
                    />
                </div>
            </div>
        </>
    );

    const renderEquipmentForm = () => (
        <>
            <div className="space-y-4">
                <div>
                    <Label>Equipment Type</Label>
                    <Select onValueChange={(value) => setFormData({ ...formData, equipment_type: value })}>
                        <SelectTrigger>
                            <SelectValue placeholder="Select type" />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectItem value="heavy">Heavy Equipment</SelectItem>
                            <SelectItem value="power_tools">Power Tools</SelectItem>
                            <SelectItem value="vehicles">Vehicles</SelectItem>
                            <SelectItem value="specialized">Specialized Equipment</SelectItem>
                        </SelectContent>
                    </Select>
                </div>

                <div>
                    <Label>Ownership</Label>
                    <Select onValueChange={(value) => setFormData({ ...formData, ownership: value })}>
                        <SelectTrigger>
                            <SelectValue placeholder="Select ownership type" />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectItem value="owned">Owned</SelectItem>
                            <SelectItem value="rented">Rented</SelectItem>
                            <SelectItem value="leased">Leased</SelectItem>
                        </SelectContent>
                    </Select>
                </div>

                <div>
                    <Label>Daily Cost</Label>
                    <Input
                        type="number"
                        value={formData.daily_cost || ''}
                        onChange={(e) => setFormData({ ...formData, daily_cost: e.target.value })}
                    />
                </div>

                <div>
                    <Label>Last Maintenance Date</Label>
                    <DatePicker
                        value={formData.last_maintenance}
                        onChange={(date) => setFormData({ ...formData, last_maintenance: date })}
                    />
                </div>
            </div>
        </>
    );

    const renderMaterialForm = () => {
        // Common material types in construction
        const materialTypes = [
            "Concrete",
            "Lumber",
            "Steel",
            "Insulation",
            "Drywall",
            "Brick",
            "Paint",
            "Roofing",
            "Electrical",
            "Plumbing",
            "HVAC",
            "Flooring",
            "Lighting",
            "Hardware",
            "Adhesives",
            "Sealants",
            "Glass",
            "Tile",
            "Landscaping",
            "Other"
        ];

        return (
            <>
                <div className="space-y-4">
                    {/* Required Fields */}
                    <div>
                        <Label>Material Type</Label>
                        <Select
                            onValueChange={(value) => setFormData({ ...formData, material_type: value })}
                            value={formData.material_type || ''}
                        >
                            <SelectTrigger>
                                <SelectValue placeholder="Select material type" />
                            </SelectTrigger>
                            <SelectContent>
                                {materialTypes.map(type => (
                                    <SelectItem key={type} value={type}>
                                        {type}
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    </div>

                    <div>
                        <Label>Unit</Label>
                        <Select
                            onValueChange={(value) => setFormData({ ...formData, unit: value })}
                            value={formData.unit || ''}
                        >
                            <SelectTrigger>
                                <SelectValue placeholder="Select unit" />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectItem value="each">Each</SelectItem>
                                <SelectItem value="sq_ft">Square Feet</SelectItem>
                                <SelectItem value="sq_yd">Square Yards</SelectItem>
                                <SelectItem value="linear_ft">Linear Feet</SelectItem>
                                <SelectItem value="cubic_yd">Cubic Yards</SelectItem>
                                <SelectItem value="gallons">Gallons</SelectItem>
                                <SelectItem value="pounds">Pounds</SelectItem>
                                <SelectItem value="tons">Tons</SelectItem>
                                <SelectItem value="bundle">Bundle</SelectItem>
                                <SelectItem value="box">Box</SelectItem>
                                <SelectItem value="roll">Roll</SelectItem>
                                <SelectItem value="sheet">Sheet</SelectItem>
                                <SelectItem value="pallet">Pallet</SelectItem>
                            </SelectContent>
                        </Select>
                    </div>

                    <div>
                        <Label>Unit Cost</Label>
                        <Input
                            type="number"
                            step="0.01"
                            value={formData.unit_cost || ''}
                            onChange={(e) => setFormData({ ...formData, unit_cost: e.target.value })}
                            required
                        />
                    </div>

                    <div>
                        <Label>Lead Time (Days)</Label>
                        <Input
                            type="number"
                            value={formData.lead_time_days || ''}
                            onChange={(e) => setFormData({ ...formData, lead_time_days: e.target.value })}
                            required
                        />
                    </div>

                    <div>
                        <Label>Current Stock</Label>
                        <Input
                            type="number"
                            step="0.01"
                            value={formData.current_stock || ''}
                            onChange={(e) => setFormData({ ...formData, current_stock: e.target.value })}
                            required
                        />
                    </div>

                    <div>
                        <Label>Minimum Order</Label>
                        <Input
                            type="number"
                            step="0.01"
                            value={formData.minimum_order || ''}
                            onChange={(e) => setFormData({ ...formData, minimum_order: e.target.value })}
                            required
                        />
                    </div>

                    <div>
                        <Label>Reorder Point</Label>
                        <Input
                            type="number"
                            step="0.01"
                            value={formData.reorder_point || ''}
                            onChange={(e) => setFormData({ ...formData, reorder_point: e.target.value })}
                            required
                        />
                    </div>

                    {/* Supplier Field - Assuming you have suppliers loaded */}
                    <div>
                        <Label>Supplier</Label>
                        <Select
                            onValueChange={(value) => setFormData({ ...formData, supplier: value })}
                            value={formData.supplier || ''}
                        >
                            <SelectTrigger>
                                <SelectValue placeholder="Select supplier" />
                            </SelectTrigger>
                            <SelectContent>
                                {suppliers && suppliers.length > 0 ? (
                                    suppliers.map(supplier => (
                                        <SelectItem key={supplier.id} value={supplier.id.toString()}>
                                            {supplier.name}
                                        </SelectItem>
                                    ))
                                ) : (
                                    <SelectItem value="none" disabled>No suppliers available</SelectItem>
                                )}
                            </SelectContent>
                        </Select>
                    </div>

                    {/* Optional Fields */}
                    <div>
                        <Label>Description (Optional)</Label>
                        <Input
                            value={formData.description || ''}
                            onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                        />
                    </div>

                    <div>
                        <Label>Specifications (Optional)</Label>
                        <Input
                            value={formData.specifications || ''}
                            onChange={(e) => setFormData({ ...formData, specifications: e.target.value })}
                        />
                    </div>

                    <div>
                        <Label>Manufacturer (Optional)</Label>
                        <Input
                            value={formData.manufacturer || ''}
                            onChange={(e) => setFormData({ ...formData, manufacturer: e.target.value })}
                        />
                    </div>

                    <div>
                        <Label>Manufacturer Part Number (Optional)</Label>
                        <Input
                            value={formData.manufacturer_part_number || ''}
                            onChange={(e) => setFormData({ ...formData, manufacturer_part_number: e.target.value })}
                        />
                    </div>

                    {/* Category Field - Assuming you have categories loaded */}
                    <div>
                        <Label>Category (Optional)</Label>
                        <Select
                            onValueChange={(value) => setFormData({ ...formData, category: value })}
                            value={formData.category || ''}
                        >
                            <SelectTrigger>
                                <SelectValue placeholder="Select category" />
                            </SelectTrigger>
                            <SelectContent>
                                {categories && categories.length > 0 ? (
                                    categories.map(category => (
                                        <SelectItem key={category.id} value={category.id.toString()}>
                                            {category.name}
                                        </SelectItem>
                                    ))
                                ) : (
                                    <SelectItem value="none" disabled>No categories available</SelectItem>
                                )}
                            </SelectContent>
                        </Select>
                    </div>

                    <div>
                        <Label>Last Purchase Date (Optional)</Label>
                        <DatePicker
                            value={formData.last_purchase_date}
                            onChange={(date) => setFormData({ ...formData, last_purchase_date: date })}
                        />
                    </div>

                    <div>
                        <Label>Last Purchase Price (Optional)</Label>
                        <Input
                            type="number"
                            step="0.01"
                            value={formData.last_purchase_price || ''}
                            onChange={(e) => setFormData({ ...formData, last_purchase_price: e.target.value })}
                        />
                    </div>
                </div>
            </>
        );
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const payload = {
                ...formData,
                type: resourceType,
                project: projectId
            };
            await onSubmit(payload);
            onClose();
        } catch (error) {
            toast.error(error.response?.data?.detail || "Failed to create resource");
        } finally {
            setLoading(false);
        }
    };

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className="sm:max-w-[600px] w-full mx-4 sm:mx-auto max-h-[90vh] overflow-y-auto">
                <DialogHeader>
                    <DialogTitle>Add New Resource</DialogTitle>
                </DialogHeader>

                <form onSubmit={handleSubmit} className="space-y-6">
                    <div>
                        <Label>Resource Type</Label>
                        <Select onValueChange={handleTypeChange} value={resourceType}>
                            <SelectTrigger>
                                <SelectValue placeholder="Select resource type" />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectItem value="labor">Labor</SelectItem>
                                <SelectItem value="equipment">Equipment</SelectItem>
                                <SelectItem value="material">Material</SelectItem>
                            </SelectContent>
                        </Select>
                    </div>

                    {resourceType && (
                        <div>
                            <Label>Name</Label>
                            <Input
                                value={formData.name || ''}
                                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                                required
                            />
                        </div>
                    )}

                    {resourceType === 'labor' && renderLaborForm()}
                    {resourceType === 'equipment' && renderEquipmentForm()}
                    {resourceType === 'material' && renderMaterialForm()}

                    <DialogFooter>
                        <Button className={secondaryButtonClasses} type="button" variant="outline" onClick={onClose}>
                            Cancel
                        </Button>
                        <Button className={primaryButtonClasses} type="submit" disabled={loading || !resourceType}>
                            {loading ? "Creating..." : "Create Resource"}
                        </Button>
                    </DialogFooter>
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default ResourceForm;