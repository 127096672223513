import React, { useState, useEffect } from 'react';
import { Card, CardContent } from '../../components/ui/cardui';
import { Button } from '../../components/ui/button';
import { Input } from '../../components/ui/input';
import { Label } from '../../components/ui/label';
import { Textarea } from '../../components/ui/textarea';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../components/ui/select';
import { fetchRiskTemplates } from '../../services/riskApi';
import api from '../../services/api';
import { primaryButtonClasses, secondaryButtonClasses } from '../common/ButtonStyles';

const RiskForm = ({ categories, projectId, onSubmit, initialData, onCancel, configureRequest }) => {
    const [formData, setFormData] = useState({
        title: '',
        description: '',
        category: '',
        severity: 'MEDIUM',
        probability: 'POSSIBLE',
        impact: '',
        status: 'IDENTIFIED',
        assigned_to: '',
        project: projectId // Make sure project ID is set
    });
    const [templates, setTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState('');
    const [templatesLoading, setTemplatesLoading] = useState(false);
    const [templatesError, setTemplatesError] = useState(null);
    const [projectUsers, setProjectUsers] = useState([]);
    const [selectedUserName, setSelectedUserName] = useState('');

    // Load templates when component mounts
    useEffect(() => {
        const loadTemplates = async () => {
            try {
                setTemplatesLoading(true);
                setTemplatesError(null);
                const templatesData = await fetchRiskTemplates();
                // Ensure templatesData is an array
                setTemplates(Array.isArray(templatesData) ? templatesData : []);
            } catch (error) {
                console.error('Error loading risk templates:', error);
                setTemplatesError('Failed to load templates');
                setTemplates([]);
            } finally {
                setTemplatesLoading(false);
            }
        };

        loadTemplates();
    }, []);

    // Load project users
    useEffect(() => {
        const fetchProjectUsers = async () => {
            if (!projectId) return;

            try {
                const response = await api.get(`projects/${projectId}/users/`, configureRequest());
                const usersData = response.data || [];
                setProjectUsers(usersData);
            } catch (error) {
                console.error('Error loading project users:', error);
            }
        };

        fetchProjectUsers();
    }, [projectId, configureRequest]);

    // Populate form with initial data if editing
    useEffect(() => {
        if (initialData) {
            // Create a copy of initialData to modify
            const formattedData = { ...initialData };

            // Ensure category and assigned_to are strings for the Select component
            if (formattedData.category && typeof formattedData.category === 'object') {
                formattedData.category = formattedData.category.id;
            }

            if (formattedData.assigned_to) {
                if (typeof formattedData.assigned_to === 'object') {
                    setSelectedUserName(`${formattedData.assigned_to.first_name} ${formattedData.assigned_to.last_name}`);
                    formattedData.assigned_to = formattedData.assigned_to.id;
                } else {
                    // If assigned_to is just an ID, find the user name
                    const user = projectUsers.find(u => u.id === formattedData.assigned_to);
                    if (user) {
                        setSelectedUserName(`${user.first_name} ${user.last_name}`);
                    }
                }
            }

            setFormData({
                ...formattedData,
                project: projectId // Ensure project ID is always set
            });
        } else {
            // For new risks, ensure project ID is set
            setFormData(prev => ({
                ...prev,
                project: projectId
            }));
        }
    }, [initialData, projectUsers, projectId]);

    // Update selected user name when assigned_to changes
    useEffect(() => {
        if (formData.assigned_to) {
            const selectedUser = projectUsers.find(user => user.id === formData.assigned_to);
            if (selectedUser) {
                setSelectedUserName(`${selectedUser.first_name} ${selectedUser.last_name}`);
            }
        } else {
            setSelectedUserName('');
        }
    }, [formData.assigned_to, projectUsers]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSelectChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleTemplateChange = async (templateId) => {
        setSelectedTemplate(templateId);

        if (templateId) {
            try {
                // Find the selected template
                const template = templates.find(t => t.id === templateId);
                if (template) {
                    // Auto-fill form with template data
                    setFormData({
                        ...formData,
                        title: template.name,
                        description: template.description,
                        category: template.category,
                        severity: template.severity,
                        probability: template.probability,
                        impact: template.impact,
                        project: projectId // Make sure project ID is still set
                    });
                }
            } catch (error) {
                console.error('Error applying risk template:', error);
            }
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Ensure project ID is included in the submission

        const tenantId = localStorage.getItem('tenantId');
        const submissionData = {
            ...formData,
            project: projectId,
            tenant: tenantId
        };
        onSubmit(submissionData);
    };

    return (
        <form onSubmit={handleSubmit}>
            <h4 className="text-xl font-semibold mb-4">{initialData ? 'Edit Risk' : 'Add New Risk'}</h4>

            {!initialData && (
                <div className="mb-4">
                    <Label htmlFor="template">Risk Template (Optional)</Label>
                    <Select
                        value={selectedTemplate}
                        onValueChange={(value) => handleTemplateChange(value)}
                        disabled={templatesLoading || templates.length === 0}
                    >
                        <SelectTrigger id="template" className="w-full">
                            <SelectValue placeholder={templatesLoading ? "Loading templates..." : templates.length === 0 ? "No templates available" : "Select a template..."} />
                        </SelectTrigger>
                        <SelectContent>
                            {Array.isArray(templates) && templates.length > 0 ? (
                                templates.map((template) => (
                                    <SelectItem key={template.id} value={template.id}>
                                        {template.name}
                                    </SelectItem>
                                ))
                            ) : (
                                <SelectItem value="no-templates">No templates available</SelectItem>
                            )}
                        </SelectContent>
                    </Select>
                    {templatesError && (
                        <p className="text-sm text-red-500 mt-1">{templatesError}</p>
                    )}
                    <p className="text-sm text-muted-foreground mt-1">
                        Select a template to quickly fill in common risk information
                    </p>
                </div>
            )}

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                <div>
                    <Label htmlFor="title" className="mb-1">Risk Title*</Label>
                    <Input
                        id="title"
                        name="title"
                        value={formData.title}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div>
                    <Label htmlFor="category" className="mb-1">Risk Category*</Label>
                    <Select
                        value={formData.category}
                        onValueChange={(value) => handleSelectChange("category", value)}
                        required
                    >
                        <SelectTrigger id="category" className="w-full">
                            <SelectValue placeholder="Select Category" />
                        </SelectTrigger>
                        <SelectContent>
                            {Array.isArray(categories) && categories.map((category) => (
                                <SelectItem key={category.id} value={category.id}>
                                    {category.name}
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                </div>
            </div>

            <div className="mb-4">
                <Label htmlFor="description" className="mb-1">Description*</Label>
                <Textarea
                    id="description"
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                    rows={3}
                    required
                />
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                <div>
                    <Label htmlFor="severity" className="mb-1">Severity*</Label>
                    <Select
                        value={formData.severity}
                        onValueChange={(value) => handleSelectChange("severity", value)}
                    >
                        <SelectTrigger id="severity" className="w-full">
                            <SelectValue placeholder="Select Severity" />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectItem value="LOW">Low</SelectItem>
                            <SelectItem value="MEDIUM">Medium</SelectItem>
                            <SelectItem value="HIGH">High</SelectItem>
                            <SelectItem value="CRITICAL">Critical</SelectItem>
                        </SelectContent>
                    </Select>
                </div>
                <div>
                    <Label htmlFor="probability" className="mb-1">Probability*</Label>
                    <Select
                        value={formData.probability}
                        onValueChange={(value) => handleSelectChange("probability", value)}
                    >
                        <SelectTrigger id="probability" className="w-full">
                            <SelectValue placeholder="Select Probability" />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectItem value="RARE">Rare</SelectItem>
                            <SelectItem value="UNLIKELY">Unlikely</SelectItem>
                            <SelectItem value="POSSIBLE">Possible</SelectItem>
                            <SelectItem value="LIKELY">Likely</SelectItem>
                            <SelectItem value="ALMOST_CERTAIN">Almost Certain</SelectItem>
                        </SelectContent>
                    </Select>
                </div>
            </div>

            <div className="mb-4">
                <Label htmlFor="impact" className="mb-1">Impact Description*</Label>
                <Textarea
                    id="impact"
                    name="impact"
                    value={formData.impact}
                    onChange={handleChange}
                    rows={2}
                    required
                    placeholder="Describe the potential impact if this risk occurs..."
                />
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
                <div>
                    <Label htmlFor="status" className="mb-1">Status</Label>
                    <Select
                        value={formData.status}
                        onValueChange={(value) => handleSelectChange("status", value)}
                    >
                        <SelectTrigger id="status" className="w-full">
                            <SelectValue placeholder="Select Status" />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectItem value="IDENTIFIED">Identified</SelectItem>
                            <SelectItem value="ASSESSED">Assessed</SelectItem>
                            <SelectItem value="MITIGATED">Mitigated</SelectItem>
                            <SelectItem value="CLOSED">Closed</SelectItem>
                            <SelectItem value="OCCURRED">Occurred</SelectItem>
                        </SelectContent>
                    </Select>
                </div>
                <div>
                    <Label htmlFor="assigned_to" className="mb-1">Assign To (Optional)</Label>
                    <Select
                        value={formData.assigned_to}
                        onValueChange={(value) => handleSelectChange("assigned_to", value)}
                    >
                        <SelectTrigger id="assigned_to" className="w-full">
                            <SelectValue placeholder="Select User">
                                {selectedUserName || "Select User"}
                            </SelectValue>
                        </SelectTrigger>
                        <SelectContent>
                            {projectUsers.length > 0 ? (
                                projectUsers.map((user) => (
                                    <SelectItem key={user.id} value={user.id}>
                                        {user.first_name} {user.last_name}
                                    </SelectItem>
                                ))
                            ) : (
                                <SelectItem value="no-users">No project users available</SelectItem>
                            )}
                        </SelectContent>
                    </Select>
                </div>
            </div>

            <div className="flex justify-end gap-2">
                <Button className={secondaryButtonClasses} variant="outline" type="button" onClick={onCancel}>
                    Cancel
                </Button>
                <Button className={primaryButtonClasses} type="submit">
                    {initialData ? 'Update Risk' : 'Add Risk'}
                </Button>
            </div>
        </form>
    );
};

export default RiskForm;