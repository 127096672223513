import React from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '../components/ui/dialog';
import { Badge } from '../components/ui/badge';
import { formatDate } from './../lib/utils';

const SafetyAuditViewModal = ({ audit, isOpen, onClose }) => {
    if (!audit) return null;

    const statusColors = {
        scheduled: 'bg-blue-100 text-blue-800',
        completed: 'bg-green-100 text-green-800',
        'in-progress': 'bg-yellow-100 text-yellow-800',
        cancelled: 'bg-red-100 text-red-800'
    };

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className="sm:max-w-[600px]">
                <DialogHeader>
                    <DialogTitle>Safety Audit Details</DialogTitle>
                </DialogHeader>
                <div className="space-y-4">
                    <div className="grid grid-cols-2 gap-4">
                        <div>
                            <h4 className="text-sm font-semibold mb-1">Audit Type</h4>
                            <p>{audit.audit_type_display?.split('_').pop()}</p>
                        </div>
                        <div>
                            <h4 className="text-sm font-semibold mb-1">Subtype</h4>
                            <p>{audit.subtype_display?.split('_').pop()}</p>
                        </div>
                        <div>
                            <h4 className="text-sm font-semibold mb-1">Planned Date</h4>
                            <p>{formatDate(audit.planned_date)}</p>
                        </div>
                        <div>
                            <h4 className="text-sm font-semibold mb-1">Status</h4>
                            <Badge className={statusColors[audit.status]}>
                                {audit.status.charAt(0).toUpperCase() + audit.status.slice(1)}
                            </Badge>
                        </div>
                        <div>
                            <h4 className="text-sm font-semibold mb-1">Location</h4>
                            <p>{audit.location}</p>
                        </div>
                        <div>
                            <h4 className="text-sm font-semibold mb-1">Auditor</h4>
                            <p>{audit.auditor_name}</p>
                        </div>
                    </div>

                    <div>
                        <h4 className="text-sm font-semibold mb-1">Findings</h4>
                        <p className="text-sm">{audit.findings}</p>
                    </div>

                    <div>
                        <h4 className="text-sm font-semibold mb-1">Recommendations</h4>
                        <p className="text-sm">{audit.recommendations}</p>
                    </div>

                    <div>
                        <h4 className="text-sm font-semibold mb-1">Action Items</h4>
                        <p className="text-sm">{audit.action_items}</p>
                    </div>

                    {audit.requires_followup && (
                        <div>
                            <h4 className="text-sm font-semibold mb-1">Follow-up Date</h4>
                            <p>{formatDate(audit.followup_date)}</p>
                        </div>
                    )}
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default SafetyAuditViewModal;