import React, { useState } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '../components/ui/cardui';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '../components/ui/dialog';
import { Badge } from '../components/ui/badge';
import { ExternalLink, AlertTriangle, CheckCircle2, XCircle } from 'lucide-react';

const ViolationViewer = ({ violation, onClose }) => {
    if (!violation) return null;
    return (
        <DialogContent className="max-w-2xl">
            <DialogHeader>
                <DialogTitle className="flex items-center gap-2">
                    <AlertTriangle className="h-5 w-5 text-red-500" />
                    Safety Violation Details
                </DialogTitle>
            </DialogHeader>

            <div className="space-y-4">
                {/* Screenshot Section */}
                {violation.screenshot && (
                    <div className="relative border rounded-lg overflow-hidden">
                        screenshot:
                        <img
                            src={violation.screenshot}  // Using S3 URL
                            alt="Violation evidence"
                            className="w-full rounded-lg"
                            onError={(e) => {
                                e.target.src = '/placeholder-image.png'; // Fallback image
                                console.error('Error loading violation screenshot');
                            }}
                        />
                        <div className="absolute bottom-2 right-2 bg-black bg-opacity-75 text-white px-2 py-1 rounded">
                            Frame {violation.frame}
                        </div>
                    </div>
                )}

                {/* Violation Details */}
                <div className="grid gap-3 bg-gray-50 p-4 rounded-lg">
                    <div>
                        <span className="font-semibold text-gray-700">Time:</span>{' '}
                        <span>{new Date(violation.time * 1000).toISOString().substr(11, 8)}</span>
                    </div>
                    <div>
                        <span className="font-semibold text-gray-700">Score:</span>{' '}
                        <span>{violation.score}</span>
                    </div>
                    <div>
                        <span className="font-semibold text-gray-700">Issue:</span>{' '}
                        <span>{violation.note}</span>
                    </div>

                    {/* OSHA Regulation Link */}
                    {(violation.osha_url || violation.osha_code) && (
                        <div className="border-t pt-3 mt-2">
                            <span className="font-semibold text-gray-700 block mb-2">OSHA Regulation:</span>
                            {violation.osha_code && (
                                <p className="text-sm text-gray-600 mb-1">Code: {violation.osha_code}</p>
                            )}
                            {violation.osha_url && (
                                <a
                                    href={violation.osha_url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="flex items-center gap-2 text-blue-600 hover:text-blue-800"
                                >
                                    <ExternalLink className="h-4 w-4" />
                                    View OSHA Regulation Details
                                </a>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </DialogContent>
    );
};

// StatusBadge component remains unchanged
const StatusBadge = ({ detected, detection_rate }) => {
    const isCompliant = detected && detection_rate > 70;

    return (
        <Badge
            variant={isCompliant ? "success" : "destructive"}
            className={`flex items-center gap-1 ${isCompliant
                ? "bg-green-100 text-green-800"
                : "bg-red-100 text-red-800"
                }`}
        >
            {isCompliant ? (
                <>
                    <CheckCircle2 className="h-4 w-4" />
                    <span>Compliant</span>
                </>
            ) : (
                <>
                    <XCircle className="h-4 w-4" />
                    <span>Non-Compliant</span>
                </>
            )}
        </Badge>
    );
};

const SafetyAnalysisResults = ({ analysisData }) => {
    const [selectedViolation, setSelectedViolation] = useState(null);

    if (!analysisData?.safety_issues) {
        return (
            <Card>
                <CardContent className="p-6">
                    <p className="text-gray-500">No analysis data available</p>
                </CardContent>
            </Card>
        );
    }

    // Filter out items with no violations
    const relevantItems = Object.entries(analysisData.safety_issues).filter(
        ([_, data]) => data.violations && data.violations.length > 0
    );

    if (relevantItems.length === 0) {
        return (
            <Card>
                <CardContent className="p-6">
                    <p className="text-gray-500">No safety issues detected</p>
                </CardContent>
            </Card>
        );
    }

    return (
        <div className="space-y-6">
            {/* Summary Card remains unchanged */}
            <Card>
                <CardContent className="p-6">
                    <h3 className="text-lg font-semibold mb-4">Analysis Summary</h3>
                    <div className="grid grid-cols-3 gap-4">
                        <div>
                            <p className="text-sm text-gray-600">Total Requirements</p>
                            <p className="text-2xl font-bold">{analysisData.compliance_summary.total_requirements}</p>
                        </div>
                        <div>
                            <p className="text-sm text-gray-600">Compliant Items</p>
                            <p className="text-2xl font-bold text-green-600">{analysisData.compliance_summary.compliant_items}</p>
                        </div>
                        <div>
                            <p className="text-sm text-gray-600">Violations</p>
                            <p className="text-2xl font-bold text-red-600">{analysisData.compliance_summary.violation_count}</p>
                        </div>
                    </div>
                </CardContent>
            </Card>

            {/* Safety Issues Cards */}
            {relevantItems.map(([itemName, data]) => (
                <Card key={itemName} className="border-l-4 border-l-red-500">
                    <CardContent className="p-6">
                        <div className="flex justify-between items-start mb-4">
                            <div>
                                <h3 className="text-lg font-semibold">{itemName}</h3>
                                {data.osha_code && (
                                    <p className="text-sm text-gray-600 mt-1">
                                        OSHA Code: {data.osha_code}
                                    </p>
                                )}
                            </div>
                            <StatusBadge detected={data.detected} detection_rate={data.detection_rate} />
                        </div>

                        {/* Detection Stats remain unchanged */}
                        <div className="grid grid-cols-2 gap-4 mt-4">
                            <div className="bg-gray-50 p-3 rounded-md">
                                <p className="text-sm text-gray-600">Detection Rate</p>
                                <p className="text-lg font-semibold">{data.detection_rate}%</p>
                            </div>
                            <div className="bg-gray-50 p-3 rounded-md">
                                <p className="text-sm text-gray-600">Confidence</p>
                                <p className="text-lg font-semibold">{data.confidence}%</p>
                            </div>
                        </div>

                        {/* Violations Section */}
                        {data.violations?.length > 0 && (
                            <div className="mt-4">
                                <h4 className="font-medium mb-2 flex items-center gap-2">
                                    <AlertTriangle className="h-4 w-4 text-red-500" />
                                    Violations Detected ({data.violations.length})
                                </h4>
                                <div className="space-y-2">
                                    {data.violations.map((violation, idx) => (
                                        <button
                                            key={idx}
                                            onClick={() => setSelectedViolation(violation)}
                                            className="w-full flex items-center justify-between p-3 rounded-lg bg-red-50 hover:bg-red-100 transition-colors text-left"
                                        >
                                            <div className="flex items-center gap-2">
                                                <span className="text-sm">
                                                    Frame {violation.frame}: {violation.note}
                                                </span>
                                            </div>
                                            <span className="text-blue-600 text-sm">View Details →</span>
                                        </button>
                                    ))}
                                </div>
                            </div>
                        )}
                    </CardContent>
                </Card>
            ))}

            <Dialog open={!!selectedViolation} onOpenChange={() => setSelectedViolation(null)}>
                <ViolationViewer violation={selectedViolation} onClose={() => setSelectedViolation(null)} />
            </Dialog>
        </div>
    );
};

export default SafetyAnalysisResults;