import React, { useState } from 'react';
import api from '../../services/api';
import { showToast } from '../../utils/toast';

function CreateProjectModal({ closeModal, onProjectCreated }) {
    const [name, setName] = useState('');
    const [location, setLocation] = useState('');
    const [status, setStatus] = useState('In Progress');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [budget, setBudget] = useState('');
    const [description, setDescription] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await api.post('projects/', {
                name,
                location,
                status,
                start_date: startDate,
                end_date: endDate,
                budget,
                description
            });
            showToast.success("Project created successfully");
            onProjectCreated();
            closeModal();
        } catch (error) {
            showToast.error("Failed to create project", error);
        }
    };

    return (
        <div className="modal-overlay">
            <div className="modal-content p-6 bg-white rounded-lg shadow-md relative">
                <h2 className="text-2xl font-semibold mb-4">Create New Project</h2>
                <form onSubmit={handleSubmit} className="space-y-4">
                    <input
                        type="text"
                        placeholder="Project Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="w-full border border-gray-300 p-2 rounded-md"
                        required
                    />
                    <input
                        type="text"
                        placeholder="Location"
                        value={location}
                        onChange={(e) => setLocation(e.target.value)}
                        className="w-full border border-gray-300 p-2 rounded-md"
                        required
                    />
                    <select
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                        className="w-full border border-gray-300 p-2 rounded-md"
                    >
                        <option value="In Progress">In Progress</option>
                        <option value="Completed">Completed</option>
                        <option value="Pending">Pending</option>
                    </select>
                    <input
                        type="date"
                        placeholder="Start Date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        className="w-full border border-gray-300 p-2 rounded-md"
                        required
                    />
                    <input
                        type="date"
                        placeholder="End Date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        className="w-full border border-gray-300 p-2 rounded-md"
                    />
                    <input
                        type="number"
                        placeholder="Budget"
                        value={budget}
                        onChange={(e) => setBudget(e.target.value)}
                        className="w-full border border-gray-300 p-2 rounded-md"
                        required
                    />
                    <textarea
                        placeholder="Description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        className="w-full border border-gray-300 p-2 rounded-md"
                        rows="4"
                    />
                    <div className="flex justify-end space-x-4 mt-6">
                        <button
                            type="button"
                            onClick={closeModal}
                            className="border border-[#344bfc] text-[#344bfc] rounded-full px-6 py-2 shadow-md transition transform hover:scale-105 hover:bg-[#344bfc] hover:text-white flex items-center gap-2"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="bg-[#344bfc] text-white rounded-full px-6 py-2 shadow-md transition transform hover:scale-105 hover:bg-[#2539cc] flex items-center gap-2"
                        >
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default CreateProjectModal;