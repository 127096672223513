import api from './api';

export const scheduleApi = {
    // Schedule endpoints
    getProjectSchedule: (projectId) =>
        api.get(`schedules/?project=${projectId}&is_active=true`),

    getScheduleBaseline: (projectId) =>
        api.get(`schedules/?project=${projectId}&type=baseline`),

    createSchedule: (data) =>
        api.post('schedules/', data),

    updateSchedule: (scheduleId, data) =>
        api.patch(`schedules/${scheduleId}/`, data),

    // Activity endpoints
    getActivities: (scheduleId) =>
        api.get(`activities/?schedule=${scheduleId}`),

    createActivity: (data) =>
        api.post('activities/', data),

    updateActivity: (activityId, data) =>
        api.patch(`activities/${activityId}/`, data),

    // Dependencies
    getActivityDependencies: (activityId) =>
        api.get(`activities/${activityId}/dependencies/`),

    // Progress Updates
    createProgressUpdate: (projectId, data) =>
        api.post(`projects/${projectId}/progress/`, data),

    getProgressUpdates: (projectId) =>
        api.get(`projects/${projectId}/progress/`),

    // Contractor Schedule
    getContractorSchedule: (projectId) =>
        api.get(`projects/${projectId}/contractor-schedules/`),

    updateContractorProgress: (scheduleId, data) =>
        api.post(`contractor-schedules/${scheduleId}/update_progress/`, data),

    // Phase endpoints
    getPhases: async (projectId) => {
        return api.get(`projects/${projectId}/phases/`);
    },

    createPhase: async (projectId, phaseData) => {
        return api.post(`projects/${projectId}/phases/`, phaseData);
    },

    updatePhase: async (projectId, phaseId, data) => {
        return api.patch(`projects/${projectId}/phases/${phaseId}/`, data);
    },

    deletePhase: async (projectId, phaseId) => {
        return api.delete(`projects/${projectId}/phases/${phaseId}/`);
    },

    updatePhaseCompletion: (phaseId, completionPercentage) =>
        api.post(`phases/${phaseId}/update_completion/`, {
            completion_percentage: completionPercentage
        }),

    getPhaseAnalysis: (phaseId) =>
        api.get(`phases/${phaseId}/critical_path_analysis/`),
    // WBS endpoints
    getWBS: (projectId, params = {}) =>
        api.get('wbs/', {
            params: {
                project: projectId,
                ...params
            }
        }),

    getWBSItem: (wbsId) =>
        api.get(`wbs/${wbsId}/`),

    createWBSItem: (data) =>
        api.post('wbs/', data),

    updateWBSItem: (wbsId, data) =>
        api.patch(`wbs/${wbsId}/`, data),

    deleteWBSItem: (wbsId) =>
        api.delete(`wbs/${wbsId}/`),

    reorderWBSItems: (items) =>
        api.post('wbs/reorder/', { items }),

    moveWBSItem: (wbsId, parentId, order) =>
        api.post(`wbs/${wbsId}/move/`, {
            parent: parentId,
            order
        }),

    // Helper function to get WBS items by level
    getWBSByLevel: (projectId, level) =>
        api.get('wbs/', {
            params: {
                project: projectId,
                level
            }
        }),

    // Helper function to get children of a WBS item
    getWBSChildren: (projectId, parentId) =>
        api.get('wbs/', {
            params: {
                project: projectId,
                parent: parentId
            }
        })
};

// scheduleApi.js
const createSchedule = async (projectId, scheduleData) => {
    const response = await api.post('schedules/', {
        project: projectId,
        type: 'current',  // or whatever type you want
        version: 1,       // you might want to calculate this
        start_date: scheduleData.start_date,
        end_date: scheduleData.end_date,
        is_active: true
    });
    return response.data;
};

// Helper function to generate WBS code
export const generateWBSCode = (parentCode, order) => {
    if (!parentCode) {
        return String(order + 1);
    }
    return `${parentCode}.${order + 1}`;
};

// Helper function to validate WBS code
export const isValidWBSCode = (code) => {
    const pattern = /^\d+(\.\d+)*$/;
    return pattern.test(code);
};
