
import React, { useState, useEffect } from 'react';
import { BellIcon, XMarkIcon } from '@heroicons/react/24/outline';
import api from '../../services/api';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "../../components/ui/dialog";
import { formatDistanceToNow } from 'date-fns';

const NotificationPanel = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [unreadCount, setUnreadCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [showAllModal, setShowAllModal] = useState(false);

    // Fetch unread count regularly
    useEffect(() => {
        const fetchUnreadCount = async () => {
            try {
                const response = await api.get('notifications/count/');
                setUnreadCount(response.data.unread_count);
            } catch (error) {
                console.error('Error fetching notification count:', error);
            }
        };

        // Initial fetch
        fetchUnreadCount();

        // Set up interval (every minute)
        const intervalId = setInterval(fetchUnreadCount, 60000);

        // Clean up interval on unmount
        return () => clearInterval(intervalId);
    }, []);

    // Fetch notifications when panel opens
    useEffect(() => {
        if (isOpen) {
            fetchNotifications();
        }
    }, [isOpen]);

    const fetchNotifications = async () => {
        setLoading(true);
        try {
            const response = await api.get('notifications/');
            setNotifications(response.data.results || []);
        } catch (error) {
            console.error('Error fetching notifications:', error);
        } finally {
            setLoading(false);
        }
    };

    const markAllRead = async () => {
        try {
            await api.patch('notifications/mark-all-read/');
            setUnreadCount(0);

            // Update the read status in the local state
            setNotifications(prev => prev.map(notif => ({ ...notif, is_read: true })));
        } catch (error) {
            console.error('Error marking notifications as read:', error);
        }
    };

    const markAsRead = async (notificationId) => {
        try {
            await api.patch(`notifications/${notificationId}/mark-read/`);

            // Update the unread count
            setUnreadCount(prev => Math.max(0, prev - 1));

            // Update the read status in the local state
            setNotifications(prev =>
                prev.map(notif =>
                    notif.id === notificationId ? { ...notif, is_read: true } : notif
                )
            );
        } catch (error) {
            console.error('Error marking notification as read:', error);
        }
    };

    const handleNotificationClick = (notification) => {
        // Mark as read
        if (!notification.is_read) {
            markAsRead(notification.id);
        }

        // Handle navigation based on notification type
        if (notification.communication) {
            // Navigate to the specific communication
            window.location.href = `/projects/${notification.project?.id}/communications/${notification.communication.id}`;
        } else if (notification.project) {
            // Navigate to the project
            window.location.href = `/projects/${notification.project.id}`;
        }

        setIsOpen(false);
    };

    return (
        <div className="relative">
            <button
                className="relative"
                onClick={() => setIsOpen(!isOpen)}
            >
                <BellIcon className="h-6 w-6 text-white" />
                {unreadCount > 0 && (
                    <span className="absolute -top-1 -right-1 bg-red-500 text-white text-xs rounded-full w-5 h-5 flex items-center justify-center">
                        {unreadCount > 9 ? '9+' : unreadCount}
                    </span>
                )}
            </button>

            {isOpen && (
                <div className="absolute right-0 mt-2 w-80 bg-white rounded-md shadow-lg overflow-hidden z-50">
                    <div className="px-4 py-2 bg-gray-50 flex justify-between items-center border-b">
                        <h3 className="font-medium text-gray-700">Notifications</h3>
                        <div className="flex items-center">
                            {unreadCount > 0 && (
                                <button
                                    className="text-sm text-blue-600 hover:text-blue-800 mr-3"
                                    onClick={markAllRead}
                                >
                                    Mark all read
                                </button>
                            )}
                            {/* Add close button */}
                            <button
                                className="text-gray-500 hover:text-gray-700"
                                onClick={() => setIsOpen(false)}
                            >
                                <XMarkIcon className="h-5 w-5" />
                            </button>
                        </div>
                    </div>

                    <div className="max-h-96 overflow-y-auto">
                        {/* Existing notification list code... */}
                    </div>

                    <div className="bg-gray-50 p-2 border-t text-center">
                        <button
                            onClick={() => {
                                setIsOpen(false);
                                setShowAllModal(true);
                            }}
                            className="text-sm text-blue-600 hover:text-blue-800"
                        >
                            View all notifications
                        </button>
                    </div>
                </div>
            )}

            {/* All Notifications Modal */}
            <Dialog open={showAllModal} onOpenChange={setShowAllModal}>
                <DialogContent className="sm:max-w-md md:max-w-2xl">
                    <DialogHeader>
                        <DialogTitle>All Notifications</DialogTitle>
                    </DialogHeader>

                    <div className="py-4 max-h-[70vh] overflow-y-auto">
                        {loading ? (
                            <div className="flex justify-center items-center h-32">
                                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
                            </div>
                        ) : notifications.length > 0 ? (
                            <div className="space-y-3">
                                {notifications.map(notification => (
                                    <div
                                        key={notification.id}
                                        className={`p-4 border rounded-md ${notification.is_read ? 'bg-white' : 'bg-blue-50'}`}
                                    >
                                        <div className="flex justify-between">
                                            <h4 className="font-medium text-gray-900">{notification.title}</h4>
                                            <span className="text-xs text-gray-500">
                                                {formatDistanceToNow(new Date(notification.created_at), { addSuffix: true })}
                                            </span>
                                        </div>
                                        <p className="text-sm text-gray-600 mt-1">{notification.message}</p>
                                        {!notification.is_read && (
                                            <button
                                                onClick={() => markAsRead(notification.id)}
                                                className="mt-2 text-sm text-blue-600 hover:text-blue-800"
                                            >
                                                Mark as read
                                            </button>
                                        )}
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className="text-center py-8 text-gray-500">
                                <p>No notifications</p>
                            </div>
                        )}
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default NotificationPanel;