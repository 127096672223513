import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useFeatureAccess, FEATURES } from '../contexts/FeatureAccessContext';
import Logo from './Logo';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { showToast } from '../utils/toast';
import api from '../services/api';
import { FeatureUpgrade } from './Subscription/FeatureUpgrade';
import {
  HomeIcon,
  BriefcaseIcon,
  ShieldCheckIcon,
  ChatBubbleLeftRightIcon,
  FolderIcon,
  Bars3Icon,
  Cog6ToothIcon,
  ArrowRightOnRectangleIcon,
  UserGroupIcon,
  BuildingStorefrontIcon,
  DocumentCheckIcon,
  ClipboardDocumentCheckIcon,
  TruckIcon,
  ExclamationTriangleIcon,
  ChartBarIcon,
  CalendarIcon,
  ClipboardDocumentListIcon
} from '@heroicons/react/24/solid';

const featureMenuItems = {
  [FEATURES.PROJECT_MANAGEMENT]: [
    { name: 'Projects', icon: BriefcaseIcon, path: '/projects' },
    { name: 'Documents', icon: FolderIcon, path: '/docs/project' },
    { name: 'Supplier', icon: TruckIcon, path: '/supplier' }
  ],
  [FEATURES.SAFETY_MANAGEMENT]: [
    { name: 'Safety Dashboard', icon: ShieldCheckIcon, path: '/safety' },
    { name: 'Safety Documents', icon: FolderIcon, path: '/docs/safety' }
  ],
  [FEATURES.BID_MANAGEMENT]: [
    { name: 'Bid Dashboard', icon: DocumentCheckIcon, path: '/bid-management' },
    { name: 'Create Bid', icon: DocumentCheckIcon, path: '/bid-management/new' },
    { name: 'Bid Documents', icon: FolderIcon, path: '/docs/bid' },
    { name: 'Supplier', icon: BriefcaseIcon, path: '/supplier' }
  ],
  [FEATURES.ALL_FEATURES]: [
    { name: 'Dashboard', icon: HomeIcon, path: '/dashboard' },
    { name: 'Projects', icon: BriefcaseIcon, path: '/projects' },
    { name: 'Supplier', icon: TruckIcon, path: '/supplier' },
    { name: 'Safety', icon: ShieldCheckIcon, path: '/safety' },
    { name: 'Communication', icon: ChatBubbleLeftRightIcon, path: '/communication' },
    { name: 'Client Management', icon: UserGroupIcon, path: '/client-management' },
    { name: 'Contractor Management', icon: BuildingStorefrontIcon, path: '/contractor-management' },
    { name: 'Bid Management', icon: DocumentCheckIcon, path: '/bid-management' },
    { name: 'Documents', icon: FolderIcon, path: '/docs' }
  ]
};

const baseMenuItems = [
  { name: 'Settings', icon: Cog6ToothIcon, path: '/settings' }
];

function Sidebar({ isOpen, setIsOpen }) {
  const [collapsed, setCollapsed] = useState(false);
  const [isProfessional, setIsProfessional] = useState(true);
  const navigate = useNavigate();
  const { featureType, loading } = useFeatureAccess();

  const getMenuItems = () => {
    if (!featureType || loading) return [];
    return [...(featureMenuItems[featureType] || []), ...baseMenuItems];
  };

  const handleLogout = () => {
    localStorage.clear();
    showToast.info('You have been logged out successfully');
    navigate('/login');
  };

  if (loading) {
    return <div className="animate-pulse bg-primary h-screen w-64" />;
  }

  return (
    <div className={`bg-primary text-white h-screen flex flex-col ${collapsed ? 'w-16' : 'w-64'} transition-all duration-300 ease-in-out`}>
      <div className="flex-none">
        <button
          onClick={() => setCollapsed(!collapsed)}
          className="p-2 text-white hover:bg-primary/90 transition-colors duration-300"
        >
          <Bars3Icon className="h-6 w-6" />
        </button>

        <div className="flex justify-between items-center px-4">
          {!collapsed && (
            <NavLink to="/" className="flex items-center space-x-2">
              <Logo className="w-8 h-8" />
            </NavLink>
          )}
          {!collapsed && isOpen && (
            <button
              onClick={() => setIsOpen(false)}
              className="md:hidden text-white hover:text-white/90"
            >
              <XMarkIcon className="h-6 w-6" />
            </button>
          )}
        </div>
      </div>

      <nav className="flex-1 overflow-y-auto scrollbar-thin scrollbar-thumb-white/10 scrollbar-track-transparent">
        <div className="py-2">
          {getMenuItems().map((item) => (
            <NavLink
              key={item.name}
              to={item.path}
              className={({ isActive }) =>
                `flex items-center space-x-2 py-2.5 px-4 mx-2 rounded-md transition duration-200 
                                ${isActive ? 'bg-white/10 text-white' : 'text-white/80 hover:bg-white/10 hover:text-white'}
                                ${collapsed ? 'justify-center' : ''}`
              }
            >
              <item.icon className={`h-5 w-5 ${collapsed ? 'mx-auto' : ''}`} />
              {!collapsed && <span className="font-medium">{item.name}</span>}
            </NavLink>
          ))}
        </div>
      </nav>

      <div className="flex-none p-4 border-t border-white/10">
        <button
          onClick={handleLogout}
          className={`flex items-center space-x-2 py-2.5 px-4 rounded-md transition duration-200 
                        text-white/80 hover:bg-white/10 hover:text-white w-full
                        ${collapsed ? 'justify-center' : ''}`}
        >
          <ArrowRightOnRectangleIcon className={`h-5 w-5 ${collapsed ? 'mx-auto' : ''}`} />
          {!collapsed && <span className="font-medium">Log Out</span>}
        </button>
      </div>
    </div>
  );
}

export default Sidebar;