import React, { useState } from 'react';
import { Card } from '../../ui/cardui';
import { Input } from "../../ui/input";
import { Textarea } from "../../ui/textarea";
import { Badge } from '../../ui/badge';
import { Label } from "../../ui/label";
import { Checkbox } from '../../ui/checkbox';
import { Alert } from '../../ui/alert';
import { Button } from '../../ui/button';
import { Trash2, CheckCircle2, Loader2, Pencil, X, Save } from 'lucide-react';
import { primaryButtonClasses, secondaryButtonClasses } from '../../common/ButtonStyles';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "../../ui/select";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "../../ui/tooltip";
import ConfirmationDialog from '../../common/ConfirmationDialog';

const PhaseCard = ({ projectId, phase, onUpdate, onDelete }) => {
    const [loading, setLoading] = useState({});
    const [showSuccess, setShowSuccess] = useState(false);
    const [formData, setFormData] = useState(phase);
    const [isEditing, setIsEditing] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);


    const getStatusColor = (status) => {
        switch (status) {
            case 'not_started': return 'bg-gray-100 text-gray-700';
            case 'in_progress': return 'bg-blue-100 text-blue-700';
            case 'completed': return 'bg-green-100 text-green-700';
            default: return 'bg-gray-100 text-gray-700';
        }
    };
    const formatPercentage = (value) => {
        const num = Number(value);
        return !isNaN(num) ? num.toFixed(1) : '0.0';
    };

    const handleSave = async () => {
        setIsSaving(true);
        try {
            // Find changed fields
            const updates = {};
            Object.keys(formData).forEach(key => {
                if (formData[key] !== phase[key]) {
                    updates[key] = formData[key];
                }
            });

            // Save all changes
            if (Object.keys(updates).length > 0) {
                for (const [key, value] of Object.entries(updates)) {
                    await onUpdate(phase.id, key, value);
                }
            }
            setIsEditing(false);
        } catch (error) {
            console.error('Error saving phase:', error);
        } finally {
            setIsSaving(false);
        }
    };

    const handleDelete = async () => {
        try {
            await onDelete(phase.id);
        } catch (error) {
            console.error('Error deleting phase:', error);
        }
    };


    const handleCancel = () => {
        setFormData(phase);
        setIsEditing(false);
    };

    return (
        <>
            <Card className="p-4">
                <div className="space-y-4">
                    {/* Header with name and controls */}
                    <div className="flex items-center justify-between">
                        <div className="flex-1">
                            {isEditing ? (
                                <Input
                                    value={formData.name}
                                    onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
                                    className="font-medium"
                                />
                            ) : (
                                <h3 className="text-lg font-medium">{phase.name}</h3>
                            )}
                        </div>
                        <div className="flex items-center gap-3">
                            <Badge variant="outline">
                                {phase.completion_percentage}% Complete
                            </Badge>

                            {isEditing ? (
                                <div className="flex gap-2">
                                    <Button
                                        variant="outline"
                                        onClick={handleCancel}
                                        disabled={isSaving}
                                        className={secondaryButtonClasses}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        onClick={handleSave}
                                        disabled={isSaving}
                                        className={primaryButtonClasses}
                                    >
                                        {isSaving ? (
                                            <>
                                                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                                Saving...
                                            </>
                                        ) : (
                                            'Save Changes'
                                        )}
                                    </Button>
                                </div>
                            ) : (
                                <div className="flex gap-2">
                                    <Button
                                        variant="ghost"
                                        size="icon"
                                        onClick={() => setIsEditing(true)}
                                    >
                                        <Pencil className="h-4 w-4" />
                                    </Button>
                                    <Button
                                        variant="ghost"
                                        size="icon"
                                        onClick={() => onDelete(phase.id)}
                                        className="text-gray-400 hover:text-red-600"
                                    >
                                        <Trash2 className="h-4 w-4" />
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>

                    {/* Form fields */}
                    {isEditing ? (
                        <div className="space-y-4">
                            <div>
                                <Label>Status</Label>
                                <Select
                                    value={formData.status || 'not_started'}
                                    onValueChange={(value) => setFormData(prev => ({ ...prev, status: value }))}
                                >
                                    <SelectTrigger>
                                        <SelectValue placeholder="Status" />
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectItem value="not_started">Not Started</SelectItem>
                                        <SelectItem value="in_progress">In Progress</SelectItem>
                                        <SelectItem value="completed">Completed</SelectItem>
                                    </SelectContent>
                                </Select>
                            </div>

                            <div>
                                <Label>Description</Label>
                                <Textarea
                                    value={formData.description || ''}
                                    onChange={(e) => setFormData(prev => ({ ...prev, description: e.target.value }))}
                                    placeholder="Phase description"
                                    className="min-h-[100px]"
                                />
                            </div>

                            <div className="grid grid-cols-3 gap-4">
                                <div>
                                    <Label>Start Date</Label>
                                    <Input
                                        type="date"
                                        value={formData.start_date || ''}
                                        onChange={(e) => setFormData(prev => ({ ...prev, start_date: e.target.value }))}
                                    />
                                </div>
                                <div>
                                    <Label>End Date</Label>
                                    <Input
                                        type="date"
                                        value={formData.end_date || ''}
                                        onChange={(e) => setFormData(prev => ({ ...prev, end_date: e.target.value }))}
                                    />
                                </div>
                                <div>
                                    <Label>Duration</Label>
                                    <div className="h-10 flex items-center px-3 border rounded-md bg-gray-50">
                                        {formData.duration_days || 0} days
                                    </div>
                                </div>
                            </div>

                            <div className="flex items-center gap-6">
                                <div className="flex items-center gap-2">
                                    <Checkbox
                                        id={`client_visible_${phase.id}`}
                                        checked={Boolean(formData.client_visible)}
                                        onCheckedChange={(checked) => setFormData(prev => ({
                                            ...prev,
                                            client_visible: checked
                                        }))}
                                    />
                                    <Label htmlFor={`client_visible_${phase.id}`}>Client Visible</Label>
                                </div>

                                <div className="flex items-center gap-2">
                                    <Checkbox
                                        id={`critical_path_${phase.id}`}
                                        checked={Boolean(formData.critical_path)}
                                        onCheckedChange={(checked) => setFormData(prev => ({
                                            ...prev,
                                            critical_path: checked
                                        }))}
                                    />
                                    <Label htmlFor={`critical_path_${phase.id}`}>Critical Path</Label>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="space-y-4">
                            <div>
                                <Label className="text-gray-500">Status</Label>
                                <div className="mt-1">
                                    {formData.status?.replace('_', ' ') || 'Not Started'}
                                </div>
                            </div>

                            {formData.description && (
                                <div>
                                    <Label className="text-gray-500">Description</Label>
                                    <p className="mt-1">{formData.description}</p>
                                </div>
                            )}

                            <div className="grid grid-cols-3 gap-4">
                                <div>
                                    <Label className="text-gray-500">Start Date</Label>
                                    <div className="mt-1">
                                        {new Date(formData.start_date).toLocaleDateString()}
                                    </div>
                                </div>
                                <div>
                                    <Label className="text-gray-500">End Date</Label>
                                    <div className="mt-1">
                                        {new Date(formData.end_date).toLocaleDateString()}
                                    </div>
                                </div>
                                <div>
                                    <Label className="text-gray-500">Duration</Label>
                                    <div className="mt-1">{formData.duration_days || 0} days</div>
                                </div>
                            </div>

                            <div className="flex gap-2">
                                {formData.client_visible && (
                                    <Badge variant="outline">Client Visible</Badge>
                                )}
                                {formData.critical_path && (
                                    <Badge variant="destructive">Critical Path</Badge>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </Card>
            <ConfirmationDialog
                isOpen={showDeleteConfirmation}
                onClose={() => setShowDeleteConfirmation(false)}
                onConfirm={handleDelete}
                title="Delete Phase"
                message={`Are you sure you want to delete the phase "${phase.name}"? This action cannot be undone.`}
                confirmText="Delete"
                cancelText="Cancel"
                type="danger"
            />
        </>
    );
};

export default PhaseCard;