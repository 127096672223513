import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, CardHeader, CardTitle, CardContent } from '../../../components/ui/cardui';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../../components/ui/tabs';
import { Button } from '../../../components/ui/button';
import { Input } from '../../../components/ui/input';
import { Textarea } from '../../../components/ui/textarea';
import { useToast } from '../../../components/ui/use-toast';
import { Badge } from '../../../components/ui/badge';

import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '../../../components/ui/select';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow
} from '../../../components/ui/table';
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogTrigger
} from '../../../components/ui/dialog';
import { Label } from '../../../components/ui/label';
import { FileText, Send, Plus, Loader2 } from 'lucide-react';
import api from '../../../services/api';
import { primaryButtonClasses, secondaryButtonClasses } from '../../common/ButtonStyles';
import BidMessagesPanel from './BidMessagesPanel';
import BidRFIPanel from './BidRFIPanel';
import MyBids from './MyBids';
import RFIManager from './RFIManager';


// Main Portal Component
const SubcontractorBidPortal = () => {
    const [activeTab, setActiveTab] = useState('bids');
    const [bids, setBids] = useState([]);
    const [selectedBid, setSelectedBid] = useState(null);
    const [loading, setLoading] = useState(true);
    const [currentUser, setCurrentUser] = useState(null);
    const { toast } = useToast();
    const [unreadCounts, setUnreadCounts] = useState({});

    useEffect(() => {
        const fetchCurrentUser = async () => {
            try {
                const response = await api.get('users/profile/');
                setCurrentUser(response.data);
            } catch (err) {
                console.error('Error fetching current user:', err);
            }
        };

        fetchCurrentUser();
    }, []);

    useEffect(() => {
        const fetchBids = async () => {
            try {
                const response = await api.get('bid-invitations/my_invitations/');
                const validBids = (response.data || []).filter(bid =>
                    bid?.bid_request?.id && typeof bid.bid_request.id === 'string'
                );
                setBids(validBids);
                if (validBids.length > 0) {
                    await fetchUnreadCounts(validBids);
                }
            } catch (error) {
                console.error('Error fetching bids:', error);
                toast({
                    title: "Error",
                    description: "Failed to load bids"
                });
            } finally {
                setLoading(false);
            }
        };
        fetchBids();
    }, []);

    const fetchUnreadCounts = async (bidsData) => {
        try {
            const response = await api.get('bid-messages/unread_count/');
            setUnreadCounts(response.data.unread_count || 0);
        } catch (error) {
            console.error('Error fetching unread counts:', error);
        }
    };

    return (
        <div className="container mx-auto px-4 sm:px-6 py-4 sm:py-6">
            <Tabs value={activeTab} onValueChange={setActiveTab} className="w-full">
                <TabsList className="mb-6 w-full flex justify-start overflow-x-auto">
                    <TabsTrigger value="bids" className="flex-1">My Bids</TabsTrigger>
                    <TabsTrigger value="rfi" className="flex-1">RFI</TabsTrigger>
                </TabsList>

                <TabsContent value="bids">
                    <MyBids
                        bids={bids}
                        loading={loading}
                        currentUser={currentUser}
                        selectedBid={selectedBid}
                        setSelectedBid={setSelectedBid}
                        unreadCounts={unreadCounts}
                        setUnreadCounts={setUnreadCounts}
                    />
                </TabsContent>

                <TabsContent value="rfi">
                    <RFIManager />
                </TabsContent>
            </Tabs>
        </div>
    );
};

export default SubcontractorBidPortal;