import React, { useState, useRef, useEffect } from 'react';
import { Save, Eraser, Undo, PenTool, Square, Circle, ArrowUp } from 'lucide-react';
import api from '../../../services/api';
import { toast } from 'react-toastify';

const DocumentAnnotator = ({ documentId, projectId, onSave }) => {
    const [annotationMode, setAnnotationMode] = useState('pen');
    const [color, setColor] = useState('#FF0000');
    const [lineWidth, setLineWidth] = useState(3);
    const [isDrawing, setIsDrawing] = useState(false);
    const [documentUrl, setDocumentUrl] = useState('');
    const [loading, setLoading] = useState(true);
    const [annotations, setAnnotations] = useState([]);
    const [currentPath, setCurrentPath] = useState([]);
    const [history, setHistory] = useState([]);

    const canvasRef = useRef(null);
    const fileContainerRef = useRef(null);

    useEffect(() => {
        fetchDocument();
    }, [documentId]);

    useEffect(() => {
        const canvas = canvasRef.current;
        if (canvas) {
            const ctx = canvas.getContext('2d');
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            drawAnnotations(ctx);
        }
    }, [annotations]);

    const fetchDocument = async () => {
        try {
            setLoading(true);
            const response = await api.get(`document-management/documents/${projectId}/${documentId}/download/`);
            setDocumentUrl(response.data.download_url);

            // Also fetch any existing annotations
            try {
                const annotationsResponse = await api.get(`document-management/documents/${projectId}/${documentId}/annotations/`);
                if (annotationsResponse.data && annotationsResponse.data.length > 0) {
                    setAnnotations(annotationsResponse.data);
                }
            } catch (error) {
                // Just ignore if no annotations found
                console.log('No existing annotations found');
            }
        } catch (error) {
            console.error('Error fetching document:', error);
            toast.error('Failed to load document');
        } finally {
            setLoading(false);
        }
    };

    const handleCanvasMouseDown = (e) => {
        setIsDrawing(true);
        const canvas = canvasRef.current;
        const rect = canvas.getBoundingClientRect();
        const scaleX = canvas.width / rect.width;
        const scaleY = canvas.height / rect.height;
        const x = (e.clientX - rect.left) * scaleX;
        const y = (e.clientY - rect.top) * scaleY;

        setCurrentPath([{ x, y }]);
    };

    const handleCanvasMouseMove = (e) => {
        if (!isDrawing) return;

        const canvas = canvasRef.current;
        const rect = canvas.getBoundingClientRect();
        const scaleX = canvas.width / rect.width;
        const scaleY = canvas.height / rect.height;
        const x = (e.clientX - rect.left) * scaleX;
        const y = (e.clientY - rect.top) * scaleY;

        setCurrentPath(prevPath => [...prevPath, { x, y }]);

        const ctx = canvas.getContext('2d');
        ctx.strokeStyle = color;
        ctx.lineWidth = lineWidth;
        ctx.lineJoin = 'round';
        ctx.lineCap = 'round';

        // Draw the current path
        if (currentPath.length > 0) {
            ctx.beginPath();
            ctx.moveTo(currentPath[0].x, currentPath[0].y);
            for (let i = 1; i < currentPath.length; i++) {
                ctx.lineTo(currentPath[i].x, currentPath[i].y);
            }
            ctx.lineTo(x, y);
            ctx.stroke();
        }
    };

    const handleCanvasMouseUp = () => {
        if (!isDrawing) return;
        setIsDrawing(false);

        if (currentPath.length < 2) return;

        const newAnnotation = {
            id: Date.now().toString(),
            type: annotationMode,
            color: color,
            lineWidth: lineWidth,
            points: currentPath
        };

        setAnnotations(prevAnnotations => [...prevAnnotations, newAnnotation]);
        setHistory([]);
        setCurrentPath([]);
    };

    const drawAnnotations = (ctx) => {
        annotations.forEach(annotation => {
            ctx.strokeStyle = annotation.color;
            ctx.lineWidth = annotation.lineWidth;
            ctx.lineJoin = 'round';
            ctx.lineCap = 'round';

            ctx.beginPath();

            if (annotation.points.length > 0) {
                ctx.moveTo(annotation.points[0].x, annotation.points[0].y);
                for (let i = 1; i < annotation.points.length; i++) {
                    ctx.lineTo(annotation.points[i].x, annotation.points[i].y);
                }
                ctx.stroke();
            }
        });
    };

    const handleUndo = () => {
        if (annotations.length === 0) return;

        const lastAnnotation = annotations[annotations.length - 1];
        setHistory(prevHistory => [...prevHistory, lastAnnotation]);
        setAnnotations(prevAnnotations => prevAnnotations.slice(0, -1));
    };

    const handleRedo = () => {
        if (history.length === 0) return;

        const lastHistory = history[history.length - 1];
        setAnnotations(prevAnnotations => [...prevAnnotations, lastHistory]);
        setHistory(prevHistory => prevHistory.slice(0, -1));
    };

    const handleClear = () => {
        setAnnotations([]);
        setHistory([]);
    };

    const handleSaveAnnotations = async () => {
        try {
            await api.post(`document-management/documents/${projectId}/${documentId}/annotations/`, {
                annotations: annotations
            });
            toast.success('Annotations saved successfully');
            if (onSave) onSave();
        } catch (error) {
            console.error('Error saving annotations:', error);
            toast.error('Failed to save annotations');
        }
    };

    if (loading) {
        return (
            <div className="flex items-center justify-center h-full">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
            </div>
        );
    }

    return (
        <div className="flex flex-col h-full">
            {/* Toolbar */}
            <div className="flex justify-between items-center p-2 bg-gray-100 rounded-t-lg">
                <div className="flex space-x-2">
                    <button
                        onClick={() => setAnnotationMode('pen')}
                        className={`p-2 rounded ${annotationMode === 'pen' ? 'bg-blue-500 text-white' : 'bg-white'}`}
                        title="Pen Tool"
                    >
                        <PenTool className="h-5 w-5" />
                    </button>
                    <button
                        onClick={() => setAnnotationMode('rectangle')}
                        className={`p-2 rounded ${annotationMode === 'rectangle' ? 'bg-blue-500 text-white' : 'bg-white'}`}
                        title="Rectangle"
                    >
                        <Square className="h-5 w-5" />
                    </button>
                    <button
                        onClick={() => setAnnotationMode('circle')}
                        className={`p-2 rounded ${annotationMode === 'circle' ? 'bg-blue-500 text-white' : 'bg-white'}`}
                        title="Circle"
                    >
                        <Circle className="h-5 w-5" />
                    </button>
                    <button
                        onClick={() => setAnnotationMode('arrow')}
                        className={`p-2 rounded ${annotationMode === 'arrow' ? 'bg-blue-500 text-white' : 'bg-white'}`}
                        title="Arrow"
                    >
                        <ArrowUp className="h-5 w-5" />
                    </button>

                    <div className="flex items-center ml-4">
                        <input
                            type="color"
                            value={color}
                            onChange={(e) => setColor(e.target.value)}
                            className="h-8 w-8 cursor-pointer"
                            title="Color"
                        />
                        <select
                            value={lineWidth}
                            onChange={(e) => setLineWidth(parseInt(e.target.value))}
                            className="ml-2 border rounded p-1"
                            title="Line Width"
                        >
                            <option value="1">Thin</option>
                            <option value="3">Medium</option>
                            <option value="5">Thick</option>
                        </select>
                    </div>
                </div>

                <div className="flex space-x-2">
                    <button
                        onClick={handleUndo}
                        className="p-2 bg-white rounded"
                        title="Undo"
                        disabled={annotations.length === 0}
                    >
                        <Undo className="h-5 w-5" />
                    </button>
                    <button
                        onClick={handleClear}
                        className="p-2 bg-white rounded"
                        title="Clear All"
                    >
                        <Eraser className="h-5 w-5" />
                    </button>
                    <button
                        onClick={handleSaveAnnotations}
                        className="p-2 bg-green-500 text-white rounded flex items-center"
                        title="Save Annotations"
                    >
                        <Save className="h-5 w-5 mr-1" />
                        Save
                    </button>
                </div>
            </div>

            {/* Document Viewer with Canvas Overlay */}
            <div className="flex-1 relative overflow-auto" ref={fileContainerRef}>
                <div className="relative">
                    {/* Document Display */}
                    <iframe
                        src={documentUrl}
                        className="w-full h-[calc(100vh-200px)]"
                        title="Document Viewer"
                    />

                    {/* Canvas Overlay */}
                    <canvas
                        ref={canvasRef}
                        width={fileContainerRef.current?.clientWidth || 800}
                        height={fileContainerRef.current?.clientHeight || 600}
                        className="absolute top-0 left-0 w-full h-full"
                        onMouseDown={handleCanvasMouseDown}
                        onMouseMove={handleCanvasMouseMove}
                        onMouseUp={handleCanvasMouseUp}
                        onMouseLeave={handleCanvasMouseUp}
                    />
                </div>
            </div>
        </div>
    );
};

export default DocumentAnnotator;