import React, { useState, useEffect, useCallback } from 'react';
import api from '../../services/api';
import {
    BriefcaseIcon,
    EnvelopeIcon,
    BellIcon,
    MagnifyingGlassIcon,
    Bars3Icon,
    PaperClipIcon,
    ExclamationTriangleIcon,
    CheckCircleIcon,
    SparklesIcon,
    XMarkIcon,
    ChartBarIcon,
    ChevronDownIcon,
    ChevronUpIcon,
    UserGroupIcon,
    PhoneIcon,
    AdjustmentsHorizontalIcon,
    ClockIcon,
    UsersIcon,
    CalendarIcon
} from '@heroicons/react/24/outline';
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogFooter
} from "../../components/ui/dialog";
import { Input } from "../../components/ui/input";
import { Label } from "../../components/ui/label";
import { Button } from "../../components/ui/button";
import { toast } from 'react-toastify';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "../../components/ui/select";

const ActivityForm = ({ isOpen, onClose, onSubmit, selectedCommunication, projectId, scheduleId }) => {
    const [formData, setFormData] = useState({
        name: selectedCommunication ? `Task: ${selectedCommunication.subject}` : '',
        description: selectedCommunication ? selectedCommunication.content?.slice(0, 200) : '',
        activity_type: 'task',
        priority: 'medium',
        planned_start: new Date().toISOString().slice(0, 16),
        planned_finish: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toISOString().slice(0, 16)
    });
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleChange = (field, value) => {
        setFormData(prev => ({ ...prev, [field]: value }));
        setError('');
    };

    const validateDates = (start, end) => {
        const startDate = new Date(start);
        const endDate = new Date(end);

        if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
            return 'Invalid date format';
        }

        if (startDate >= endDate) {
            return 'End date must be after start date';
        }

        return null;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError('');

        try {
            if (!formData.name) {
                throw new Error('Name is required');
            }

            const dateError = validateDates(formData.planned_start, formData.planned_finish);
            if (dateError) {
                throw new Error(dateError);
            }

            const start = new Date(formData.planned_start);
            const end = new Date(formData.planned_finish);
            const durationDays = Math.max(1, Math.ceil((end - start) / (1000 * 60 * 60 * 24)));

            const payload = {
                ...formData,
                original_duration: durationDays,
                remaining_duration: durationDays,
                project: projectId,
                schedule: scheduleId,
                wbs: 1, // This would need to be populated properly in a real implementation
                percent_complete: 0,
                is_critical: false,
                is_milestone: formData.activity_type === 'milestone',
                communication_id: selectedCommunication?.id
            };

            await onSubmit(payload);
        } catch (err) {
            const errorMessage = err.response?.data?.detail || err.message || 'Failed to create activity';
            setError(errorMessage);
            toast.error(errorMessage);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Create New Activity</DialogTitle>
                </DialogHeader>

                <form onSubmit={handleSubmit} className="space-y-4">
                    {error && (
                        <div className="text-red-500 text-sm p-2 bg-red-50 rounded">
                            {error}
                        </div>
                    )}

                    <div className="space-y-2">
                        <Label htmlFor="name">Name</Label>
                        <Input
                            id="name"
                            value={formData.name}
                            onChange={e => handleChange('name', e.target.value)}
                            required
                        />
                    </div>

                    <div className="space-y-2">
                        <Label htmlFor="description">Description</Label>
                        <Input
                            id="description"
                            value={formData.description}
                            onChange={e => handleChange('description', e.target.value)}
                        />
                    </div>

                    <div className="space-y-2">
                        <Label htmlFor="activity_type">Type</Label>
                        <Select
                            value={formData.activity_type}
                            onValueChange={value => handleChange('activity_type', value)}
                        >
                            <SelectTrigger>
                                <SelectValue placeholder="Select type" />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectItem value="task">Task</SelectItem>
                                <SelectItem value="milestone">Milestone</SelectItem>
                                <SelectItem value="summary">Summary</SelectItem>
                            </SelectContent>
                        </Select>
                    </div>

                    <div className="space-y-2">
                        <Label htmlFor="priority">Priority</Label>
                        <Select
                            value={formData.priority}
                            onValueChange={value => handleChange('priority', value)}
                        >
                            <SelectTrigger>
                                <SelectValue placeholder="Select priority" />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectItem value="low">Low</SelectItem>
                                <SelectItem value="medium">Medium</SelectItem>
                                <SelectItem value="high">High</SelectItem>
                                <SelectItem value="critical">Critical</SelectItem>
                            </SelectContent>
                        </Select>
                    </div>

                    <div className="grid grid-cols-2 gap-4">
                        <div className="space-y-2">
                            <Label htmlFor="planned_start">Start Date</Label>
                            <Input
                                type="datetime-local"
                                value={formData.planned_start}
                                onChange={e => handleChange('planned_start', e.target.value)}
                                required
                            />
                        </div>
                        <div className="space-y-2">
                            <Label htmlFor="planned_finish">End Date</Label>
                            <Input
                                type="datetime-local"
                                value={formData.planned_finish}
                                onChange={e => handleChange('planned_finish', e.target.value)}
                                required
                            />
                        </div>
                    </div>

                    <DialogFooter>
                        <Button
                            type="button"
                            onClick={onClose}
                            variant="outline"
                            disabled={isLoading}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            disabled={isLoading || !!error}
                        >
                            {isLoading ? 'Creating...' : 'Create Activity'}
                        </Button>
                    </DialogFooter>
                </form>
            </DialogContent>
        </Dialog>
    );
};


export default ActivityForm;