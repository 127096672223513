import React from 'react';

const DocumentBreadcrumbs = ({ path = [], onNavigate = () => { } }) => (
  <div className="flex items-center space-x-2 mb-4 overflow-x-auto whitespace-nowrap pb-2">
    <span
      className="text-blue-600 hover:text-blue-800 cursor-pointer"
      onClick={() => onNavigate(0)}
    >
      Home
    </span>
    {path.map((folder, index) => (
      <React.Fragment key={folder.id}>
        <span className="text-gray-500">/</span>
        <span
          className="text-blue-600 hover:text-blue-800 cursor-pointer truncate max-w-xs"
          onClick={() => onNavigate(index + 1)}
        >
          {folder.title}
        </span>
      </React.Fragment>
    ))}
  </div>
);

export default DocumentBreadcrumbs;