import React, { useState, useEffect } from 'react';
import {
    DocumentIcon,
    ClockIcon,
    ClipboardDocumentListIcon,
    UserGroupIcon,
    HomeIcon,
    ChatBubbleLeftRightIcon,
    CurrencyDollarIcon
} from '@heroicons/react/24/outline';
import api from '../../services/api';

const ContentCards = () => {
    const [data, setData] = useState({
        projects: {
            overall: { total: 0, active: 0, completed: 0, on_hold: 0 },
            by_tenant: []
        },
        tasks: { total: 0, open: 0, overdue: 0 },
        rfis: { total: 0, open: 0, pending: 0 },
        changeOrders: { total: 0, pending: 0, approved: 0 },
        hours: { total: 0, thisMonth: 0, pending: 0 }
    });
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetchAllData();
    }, []);

    const fetchAllData = async () => {
        try {
            const [
                projectsResponse,
                tasksResponse,
                rfisResponse,
                changeOrdersResponse,
                hoursResponse
            ] = await Promise.all([
                api.get('contractor/projects/counts/'),
                api.get('contractor/tasks/summary/'),
                api.get('contractor/rfis/summary/'),
                api.get('contractor/change-orders/summary/'),
                api.get('contractor/timesheets/summary/')
            ]);

            setData({
                projects: projectsResponse.data,
                tasks: tasksResponse.data,
                rfis: rfisResponse.data,
                changeOrders: changeOrdersResponse.data,
                hours: hoursResponse.data
            });

        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const cards = [
        {
            title: 'Active Projects',
            value: data.projects.overall.total,
            change: '', // You might want to calculate this
            icon: DocumentIcon,
            iconBg: 'bg-blue-500'
        },
        {
            title: 'Hours This Month',
            value: data.hours.thisMonth,
            change: `${data.hours.pending} pending`,
            icon: ClockIcon,
            iconBg: 'bg-green-500'
        },
        {
            title: 'Open Tasks',
            value: data.tasks.open,
            change: data.tasks.overdue > 0 ? `-${data.tasks.overdue} overdue` : '0 overdue',
            icon: ClipboardDocumentListIcon,
            iconBg: 'bg-purple-500'
        },
        {
            title: 'Change Orders',
            value: data.changeOrders.total,
            change: `${data.changeOrders.pending} pending`,
            icon: CurrencyDollarIcon,
            iconBg: 'bg-orange-500'
        },
        {
            title: 'Active RFIs',
            value: data.rfis.open,
            change: `${data.rfis.pending} pending`,
            icon: ChatBubbleLeftRightIcon,
            iconBg: 'bg-indigo-500'
        }
    ];

    if (isLoading) {
        return (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {[1, 2, 3, 4, 5].map((index) => (
                    <div key={index} className="bg-white rounded-2xl shadow-sm p-6 animate-pulse">
                        <div className="flex items-start space-x-4">
                            <div className="bg-gray-200 p-3 rounded-xl w-12 h-12"></div>
                            <div className="flex-1">
                                <div className="h-4 bg-gray-200 rounded w-24 mb-2"></div>
                                <div className="h-8 bg-gray-200 rounded w-16"></div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        );
    }

    return (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {cards.map((card, index) => (
                <div
                    key={index}
                    className="bg-white rounded-2xl shadow-sm p-6"
                >
                    <div className="flex items-start space-x-4">
                        <div className={`${card.iconBg} p-3 rounded-xl`}>
                            <card.icon className="h-6 w-6 text-white" />
                        </div>
                        <div>
                            <h3 className="text-gray-600 text-base font-normal mb-1">{card.title}</h3>
                            <div className="flex items-center space-x-2">
                                <span className="text-3xl font-semibold text-gray-900">
                                    {card.value}
                                </span>
                                <span className="text-sm font-medium text-gray-500">
                                    {card.change}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ContentCards;