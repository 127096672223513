import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogFooter,
} from '../../components/ui/dialog';
import {
    Form,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '../../components/ui/form';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '../../components/ui/select';
import { Button } from '../../components/ui/button';
import { Input } from '../../components/ui/input';
import { Textarea } from '../../components/ui/textarea';
import { ScrollArea } from '../../components/ui/scroll-area';
import api from '../../services/api';
import { primaryButtonClasses } from '../../components/common/ButtonStyles';
import { Badge } from '../../components/ui/badge';
import { Plus, X } from 'lucide-react';
import { toast } from 'react-toastify';

const hazardFormSchema = z.object({
    hazard_type: z.string().min(1, "Hazard type is required"),
    incident_date: z.string().min(1, "Incident date is required"),
    hazard_date: z.string().min(1, "Date identified is required"),
    location: z.string().nullable(),
    description: z.string().min(1, "Description is required"),
    risk_level: z.enum(['low', 'medium', 'high']),
    //severity: z.string().min(1, "Severity is required"),
    status: z.enum(['open', 'in progress', 'mitigated', 'closed']).default('open'),
    mitigation_steps: z.array(z.string()).nullable().optional(),
});

const formatDisplayName = (name) => {
    const baseType = name.split('_').pop();
    return baseType.charAt(0).toUpperCase() + baseType.slice(1);
};

export const HazardForm = ({ project, isOpen, onClose, initialData = null, onSubmit }) => {
    const [hazardTypes, setHazardTypes] = useState([]);
    const [selectedType, setSelectedType] = useState(null);
    const [mitigationSteps, setMitigationSteps] = useState([]);
    const [newStep, setNewStep] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoadingTypes, setIsLoadingTypes] = useState(false);


    const form = useForm({
        resolver: zodResolver(hazardFormSchema),
        defaultValues: {
            hazard_type: initialData?.hazard_type || '',
            incident_date: initialData?.incident_date || new Date().toISOString().split('T')[0],
            hazard_date: initialData?.hazard_date || new Date().toISOString().split('T')[0],
            location: initialData?.location || '',
            description: initialData?.description || '',
            risk_level: initialData?.risk_level || 'low',
            //severity: initialData?.severity || '',
            status: initialData?.status || 'open',
        },
        mode: 'onSubmit'
    });


    useEffect(() => {
        if (initialData) {
            form.reset({
                hazard_type: initialData.hazard_type.id,
                incident_date: initialData.incident_date || new Date().toISOString().split('T')[0],
                hazard_date: initialData.hazard_date,
                location: initialData.location || '',
                description: initialData.description,
                risk_level: initialData.risk_level,
                status: initialData.status,
            });
            setMitigationSteps(initialData.mitigation_steps || []);
            setSelectedType(initialData.hazard_type);
        }
    }, [initialData, form]);


    useEffect(() => {
        if (initialData?.mitigation_steps) {
            setMitigationSteps(initialData.mitigation_steps);
        }
    }, [initialData]);

    useEffect(() => {
        fetchHazardTypes();
    }, []);

    const fetchHazardTypes = async () => {
        try {
            setIsLoadingTypes(true);
            const response = await api.get('safety-types/hazard_types/');
            setHazardTypes(response.data.types || []);
        } catch (error) {
            console.error('Error fetching hazard types:', error);
            setHazardTypes([]);
            toast.error("Failed to load hazard types");
        } finally {
            setIsLoadingTypes(false);
        }
    };

    const handleHazardTypeChange = (typeId) => {
        const selected = hazardTypes.find(type => type.id === typeId);
        setSelectedType(selected);

        if (selected?.default_risk_level) {
            form.setValue('risk_level', selected.default_risk_level, { shouldValidate: true });
        }

        if (selected?.mitigation_guidelines?.length > 0 && mitigationSteps.length === 0) {
            setMitigationSteps(selected.mitigation_guidelines);
        }

        form.setValue('hazard_type', typeId, { shouldValidate: true });
    };

    const addMitigationStep = () => {
        if (newStep.trim()) {
            setMitigationSteps(prev => [...prev, newStep.trim()]);
            setNewStep('');
        }
    };

    const removeMitigationStep = (index) => {
        setMitigationSteps(prev => prev.filter((_, i) => i !== index));
    };

    const onSubmitForm = async (data) => {

        if (data.status === 'mitigated' && mitigationSteps.length === 0) {
            toast.error("Please add mitigation steps before marking as mitigated");
            return;
        }

        try {
            setIsSubmitting(true);

            const submitData = {
                hazard_type_id: data.hazard_type,
                hazard_date: data.hazard_date,
                incident_date: data.incident_date,
                location: data.location,
                description: data.description,
                risk_level: data.risk_level,
                status: data.status,
                mitigation_steps: mitigationSteps,
            };


            let response;

            if (initialData?.id) {

                response = await api.patch(
                    `projects/${project.id}/safety-hazards/${initialData.id}/`,
                    submitData
                );
                toast.success("Hazard updated successfully");
            } else {
                response = await api.post(
                    `projects/${project.id}/safety-hazards/`,
                    submitData
                );
                toast.success("Hazard created successfully");
            }


            onSubmit?.(response.data);
            onClose();
        } catch (error) {
            console.error('Submit error:', error);
            console.error('Error response:', error.response); // Debug log
            const errorMessage = error.response?.data?.hazard_type_id?.[0]
                || error.response?.data?.detail
                || "Failed to submit hazard";
            toast.error(errorMessage);
        } finally {
            setIsSubmitting(false);
        }
    };
    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className="sm:max-w-[600px]">
                <DialogHeader>
                    <DialogTitle>
                        {initialData ? 'Edit Hazard Report' : 'Log New Hazard'}
                    </DialogTitle>
                </DialogHeader>
                <ScrollArea className="max-h-[80vh] px-4">
                    {isLoadingTypes ? (
                        <div>Loading hazard types...</div>
                    ) : (
                        <Form {...form}>
                            <form onSubmit={form.handleSubmit(onSubmitForm)} className="space-y-4">
                                <FormField
                                    control={form.control}
                                    name="hazard_type"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>Hazard Type</FormLabel>
                                            <select
                                                className="w-full px-3 py-2 rounded-md border border-gray-300 bg-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                                onChange={(e) => handleHazardTypeChange(e.target.value)}
                                                value={field.value}
                                            >
                                                <option value="">Select hazard type</option>
                                                {hazardTypes.map((type) => (
                                                    <option key={type.id} value={type.id}>
                                                        {formatDisplayName(type.name)}
                                                    </option>
                                                ))}
                                            </select>
                                            <FormMessage />
                                            {selectedType?.default_risk_level && (
                                                <div className="text-sm text-gray-500 mt-1">
                                                    Default Risk Level:
                                                    <Badge variant="outline" className="ml-2">
                                                        {selectedType.default_risk_level}
                                                    </Badge>
                                                </div>
                                            )}
                                        </FormItem>
                                    )}
                                />

                                <FormField
                                    control={form.control}
                                    name="description"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>Description</FormLabel>
                                            <Textarea
                                                {...field}
                                                placeholder="Detailed description of the hazard"
                                                className="min-h-[100px]"
                                            />
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />

                                <FormField
                                    control={form.control}
                                    name="risk_level"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>Risk Level</FormLabel>
                                            <Select
                                                onValueChange={field.onChange}
                                                defaultValue={field.value}
                                            >
                                                <SelectTrigger>
                                                    <SelectValue placeholder="Select risk level" />
                                                </SelectTrigger>
                                                <SelectContent>
                                                    <SelectItem value="low">Low</SelectItem>
                                                    <SelectItem value="medium">Medium</SelectItem>
                                                    <SelectItem value="high">High</SelectItem>
                                                </SelectContent>
                                            </Select>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />

                                <FormField
                                    control={form.control}
                                    name="status"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>Status</FormLabel>
                                            <Select
                                                onValueChange={field.onChange}
                                                defaultValue={field.value}
                                            >
                                                <SelectTrigger>
                                                    <SelectValue placeholder="Select status" />
                                                </SelectTrigger>
                                                <SelectContent>
                                                    <SelectItem value="open">Open</SelectItem>
                                                    <SelectItem value="in progress">In Progress</SelectItem>
                                                    <SelectItem value="mitigated">Mitigated</SelectItem>
                                                    <SelectItem value="closed">Closed</SelectItem>
                                                </SelectContent>
                                            </Select>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />

                                <div className="space-y-2">
                                    <FormLabel>Mitigation Steps</FormLabel>
                                    <div className="space-y-2">
                                        {mitigationSteps.map((step, index) => (
                                            <div key={index} className="flex items-center gap-2">
                                                <div className="flex-1 p-2 bg-gray-50 rounded">
                                                    {step}
                                                </div>
                                                <Button
                                                    type="button"
                                                    variant="ghost"
                                                    size="icon"
                                                    onClick={() => removeMitigationStep(index)}
                                                >
                                                    <X className="h-4 w-4" />
                                                </Button>
                                            </div>
                                        ))}
                                        <div className="flex gap-2">
                                            <Input
                                                value={newStep}
                                                onChange={(e) => setNewStep(e.target.value)}
                                                placeholder="Add mitigation step..."
                                                onKeyPress={(e) => {
                                                    if (e.key === 'Enter') {
                                                        e.preventDefault();
                                                        addMitigationStep();
                                                    }
                                                }}
                                            />
                                            <Button
                                                type="button"
                                                variant="outline"
                                                onClick={addMitigationStep}
                                            >
                                                <Plus className="h-4 w-4" />
                                            </Button>
                                        </div>
                                    </div>
                                </div>

                                <DialogFooter>
                                    <Button
                                        type="submit"
                                        disabled={isSubmitting}
                                        className={primaryButtonClasses}
                                    >
                                        {isSubmitting ? 'Saving...' : (initialData ? 'Update Hazard' : 'Create Hazard')}
                                    </Button>
                                </DialogFooter>
                            </form>
                        </Form>
                    )}
                </ScrollArea>
            </DialogContent>
        </Dialog>
    );
};