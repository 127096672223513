import React, { useState, useEffect } from 'react';
import {
    LineChart, Line, BarChart, Bar, XAxis, YAxis, CartesianGrid,
    Tooltip, Legend, ResponsiveContainer
} from 'recharts';
import { format } from 'date-fns';
import {
    ChartBarIcon, DocumentReportIcon, TableCellsIcon,
    AdjustmentsHorizontalIcon, ArrowTrendingUpIcon,
    CalendarIcon, CurrencyDollarIcon
} from '@heroicons/react/24/outline';
import api from '../../services/api';
import { showToast } from '../../utils/toast';

const AnalyticsDashboard = ({ projectId }) => {
    const [metrics, setMetrics] = useState(null);
    const [selectedDateRange, setSelectedDateRange] = useState('day');
    const [loading, setLoading] = useState(true);
    const [activeView, setActiveView] = useState('overview');

    useEffect(() => {
        fetchMetrics();
    }, [selectedDateRange]);

    const fetchMetrics = async () => {
        try {
            const response = await api.get('analytics/tenant-metrics/');
            console.log("Analytics: ", response);
            setMetrics(response.data);
            setLoading(false);
        } catch (error) {
            console.error('Analytics Error:', error);
            setMetrics(null);
            setLoading(false);
        }
    };

    // Safe data getter utility
    const getSafeValue = (obj, path, defaultValue = 'N/A') => {
        try {
            return path.split('.').reduce((acc, curr) => acc[curr], obj) ?? defaultValue;
        } catch (e) {
            return defaultValue;
        }
    };

    // Safe array check utility
    const getSafeArray = (data) => {
        return Array.isArray(data) ? data : [];
    };

    const MetricCard = ({ title, value, trend, icon: Icon, description }) => (
        <div className="bg-white rounded-lg shadow p-6">
            <div className="flex items-center justify-between">
                <div className="flex items-center">
                    <div className="rounded-lg p-3 bg-blue-50">
                        <Icon className="h-6 w-6 text-blue-600" />
                    </div>
                    <div className="ml-4">
                        <h3 className="text-sm font-medium text-gray-500">{title}</h3>
                        <div className="flex items-baseline">
                            <p className="text-2xl font-semibold text-gray-900">
                                {value || 'N/A'}
                            </p>
                            {trend !== undefined && trend !== null && (
                                <span className={`ml-2 text-sm font-medium ${Number(trend) > 0 ? 'text-green-600' : 'text-red-600'}`}>
                                    {Number(trend) > 0 ? '+' : ''}{trend}%
                                </span>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {description && (
                <p className="mt-2 text-sm text-gray-500">{description}</p>
            )}
        </div>
    );

    const ProgressChart = ({ data = [] }) => (
        <div className="bg-white rounded-lg shadow p-6 h-full">
            <h3 className="text-lg font-medium text-gray-900 mb-4">Progress Timeline</h3>
            {data.length > 0 ? (
                <div className="h-[calc(100%-2rem)]">
                    <ResponsiveContainer width="100%" height="100%">
                        <LineChart data={data}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                                dataKey="date"
                                tickFormatter={(date) => date ? format(new Date(date), 'MMM d') : ''}
                            />
                            <YAxis />
                            <Tooltip
                                labelFormatter={(date) => date ? format(new Date(date), 'MMM d, yyyy') : ''}
                                formatter={(value) => `${value || 0}%`}
                            />
                            <Legend />
                            <Line
                                type="monotone"
                                dataKey="planned"
                                stroke="#8884d8"
                                name="Planned Progress"
                            />
                            <Line
                                type="monotone"
                                dataKey="actual"
                                stroke="#82ca9d"
                                name="Actual Progress"
                            />
                        </LineChart>
                    </ResponsiveContainer>
                </div>
            ) : (
                <div className="h-64 flex items-center justify-center text-gray-500">
                    No progress data available
                </div>
            )}
        </div>
    );

    const CostChart = ({ data = [] }) => (
        <div className="bg-white rounded-lg shadow p-6 h-full">
            <h3 className="text-lg font-medium text-gray-900 mb-4">Cost Analysis</h3>
            {data.length > 0 ? (
                <div className="h-[calc(100%-2rem)]">
                    <ResponsiveContainer width="100%" height="100%">
                        <BarChart data={data}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="category" />
                            <YAxis />
                            <Tooltip
                                formatter={(value) => `$${(value || 0).toLocaleString()}`}
                            />
                            <Legend />
                            <Bar dataKey="planned" fill="#8884d8" name="Planned Cost" />
                            <Bar dataKey="actual" fill="#82ca9d" name="Actual Cost" />
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            ) : (
                <div className="h-64 flex items-center justify-center text-gray-500">
                    No cost data available
                </div>
            )}
        </div>
    );

    const QualityMetrics = ({ data = {} }) => {
        const trends = Array.isArray(data.trends) ? data.trends : [];
        const metricEntries = Object.entries(data).filter(([key]) =>
            key !== 'trends' && key !== 'projects_summary'
        );

        return (
            <div className="bg-white rounded-lg shadow p-6">
                <h3 className="text-lg font-medium text-gray-900 mb-4">Quality Overview</h3>
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
                    {/* Metrics Section */}
                    <div className="space-y-4">
                        {metricEntries.map(([metric, value]) => (
                            <div key={metric} className="bg-gray-50 rounded-lg p-4">
                                <h4 className="text-sm font-medium text-gray-500">
                                    {metric.split('_').map(word =>
                                        word.charAt(0).toUpperCase() + word.slice(1)
                                    ).join(' ')}
                                </h4>
                                <p className="mt-1 text-xl font-semibold text-gray-900">
                                    {typeof value === 'number' ? `${value}%` : value || 'N/A'}
                                </p>
                            </div>
                        ))}
                    </div>

                    {/* Trends Chart Section */}
                    <div className="lg:col-span-2">
                        {trends.length > 0 ? (
                            <div className="h-64">
                                <ResponsiveContainer width="100%" height="100%">
                                    <LineChart data={trends}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis
                                            dataKey="date"
                                            tickFormatter={(date) => format(new Date(date), 'MMM d')}
                                        />
                                        <YAxis />
                                        <Tooltip
                                            labelFormatter={(date) => format(new Date(date), 'MMM d, yyyy')}
                                        />
                                        <Legend />
                                        <Line
                                            type="monotone"
                                            dataKey="defects"
                                            stroke="#ff7300"
                                            name="Critical Defects"
                                            dot={false}
                                        />
                                        <Line
                                            type="monotone"
                                            dataKey="issues"
                                            stroke="#387908"
                                            name="Total Issues"
                                            dot={false}
                                        />
                                    </LineChart>
                                </ResponsiveContainer>
                            </div>
                        ) : (
                            <div className="h-64 flex items-center justify-center text-gray-500">
                                No quality trend data available
                            </div>
                        )}
                    </div>
                </div>

                {/* Projects Summary Table */}
                {Array.isArray(data.projects_summary) && data.projects_summary.length > 0 && (
                    <div className="mt-6">
                        <h4 className="text-lg font-medium text-gray-900 mb-4">Project Quality Summary</h4>
                        <div className="overflow-x-auto">
                            <table className="min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Project
                                        </th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Open Issues
                                        </th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Critical Issues
                                        </th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Resolution Rate
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                    {data.projects_summary.map((project, index) => (
                                        <tr key={index}>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                {project.name || 'Unnamed Project'}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                {project.open_issues || 0}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                {project.critical_issues || 0}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                {project.resolution_rate || 0}%
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}
            </div>
        );
    };

    const ResourceUtilization = ({ data = {} }) => (
        <div className="bg-white rounded-lg shadow p-6 h-full">
            <h3 className="text-lg font-medium text-gray-900 mb-4">Resource Utilization</h3>
            {Object.keys(data).length > 0 ? (
                <div className="space-y-4">
                    {Object.entries(data).map(([resource, metrics]) => (
                        <div key={resource} className="relative pt-1">
                            <div className="flex items-center justify-between">
                                <div>
                                    <span className="text-sm font-medium text-gray-900">
                                        {resource.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                                    </span>
                                </div>
                                <div className="text-sm text-gray-600">
                                    {metrics?.utilization || 0}%
                                </div>
                            </div>
                            <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-gray-200">
                                <div
                                    className={`shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center ${(metrics?.utilization || 0) > 90 ? 'bg-red-500' :
                                        (metrics?.utilization || 0) > 70 ? 'bg-yellow-500' : 'bg-green-500'
                                        }`}
                                    style={{ width: `${metrics?.utilization || 0}%` }}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <div className="h-32 flex items-center justify-center text-gray-500">
                    No resource utilization data available
                </div>
            )}
        </div>
    );

    const ScheduleAnalysis = ({ data = {} }) => (
        <div className="bg-white rounded-lg shadow p-6">
            <h3 className="text-lg font-medium text-gray-900 mb-4">Schedule Analysis</h3>
            <div className="space-y-4">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    <MetricCard
                        title="Schedule Variance"
                        value={data.schedule_variance ? `${data.schedule_variance} days` : 'N/A'}
                        icon={CalendarIcon}
                        trend={data.variance_trend}
                    />
                    <MetricCard
                        title="Milestone Performance"
                        value={data.milestone_performance ? `${data.milestone_performance}%` : 'N/A'}
                        icon={ChartBarIcon}
                    />
                    <MetricCard
                        title="Forecasted Completion"
                        value={data.forecasted_completion ?
                            format(new Date(data.forecasted_completion), 'MMM d, yyyy') : 'N/A'}
                        icon={ArrowTrendingUpIcon}
                    />
                </div>
                <div className="mt-6">
                    <h4 className="text-sm font-medium text-gray-900 mb-2">Critical Path Status</h4>
                    {getSafeArray(data.critical_path_status).length > 0 ? (
                        <div className="space-y-2">
                            {data.critical_path_status.map((item, index) => (
                                <div key={index} className="flex items-center justify-between p-2 bg-gray-50 rounded">
                                    <span className="text-sm text-gray-600">{item.task}</span>
                                    <span className={`text-sm font-medium ${item.status === 'on_track' ? 'text-green-600' :
                                        item.status === 'at_risk' ? 'text-yellow-600' : 'text-red-600'
                                        }`}>
                                        {(item.status || '').replace('_', ' ').toUpperCase()}
                                    </span>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className="text-center text-gray-500 py-4">
                            No critical path data available
                        </div>
                    )}
                </div>
            </div>
        </div>
    );

    if (loading) {
        return (
            <div className="flex items-center justify-center h-64">
                <div className="text-gray-600">Loading analytics...</div>
            </div>
        );
    }

    if (!metrics) {
        return (
            <div className="flex items-center justify-center h-64">
                <div className="text-gray-600">No analytics data available</div>
            </div>
        );
    }

    return (
        <div className="space-y-6">
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center space-y-4 sm:space-y-0">
                <h2 className="text-2xl font-bold text-gray-900">Tenant Analytics Overview</h2>
                <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-4 w-full sm:w-auto">
                    <select
                        value={selectedDateRange}
                        onChange={(e) => setSelectedDateRange(e.target.value)}
                        className="rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    >
                        <option value="week">Last Week</option>
                        <option value="month">Last Month</option>
                        <option value="quarter">Last Quarter</option>
                        <option value="year">Last Year</option>
                    </select>
                    <select
                        value={activeView}
                        onChange={(e) => setActiveView(e.target.value)}
                        className="rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    >
                        <option value="overview">Overview</option>
                        <option value="financial">Financial</option>
                        <option value="schedule">Schedule</option>
                        <option value="quality">Quality</option>
                        <option value="resources">Resources</option>
                    </select>
                </div>
            </div>

            {activeView === 'overview' && (
                <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6">
                    {/* Key Metrics Row */}
                    <div className="col-span-1 md:col-span-2 xl:col-span-3 grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-6">
                        <MetricCard
                            title="Overall Progress"
                            value={`${getSafeValue(metrics, 'general.overall_progress', 0)}%`}
                            trend={getSafeValue(metrics, 'general.progress_trend')}
                            icon={ChartBarIcon}
                        />
                        <MetricCard
                            title="Budget Performance"
                            value={`${getSafeValue(metrics, 'financial.budget_performance', 0)}%`}
                            trend={getSafeValue(metrics, 'financial.cost_variance')}
                            icon={CurrencyDollarIcon}
                        />
                        <MetricCard
                            title="Schedule Performance"
                            value={`${getSafeValue(metrics, 'schedule.schedule_variance', 0)} days`}
                            trend={getSafeValue(metrics, 'schedule.variance_trend')}
                            icon={CalendarIcon}
                        />
                    </div>

                    {/* Main Content Area */}
                    <div className="col-span-1 md:col-span-2 h-96">
                        <ProgressChart data={getSafeArray(getSafeValue(metrics, 'general.progress_timeline', []))} />
                    </div>
                    <div className="col-span-1">
                        <ResourceUtilization data={getSafeValue(metrics, 'resource', {})} />
                    </div>

                    {/* Quality Metrics - Full Width */}
                    <div className="col-span-1 md:col-span-2 xl:col-span-3">
                        <QualityMetrics data={getSafeValue(metrics, 'quality', {})} />
                    </div>
                </div>
            )}

            {activeView === 'financial' && (
                <div className="space-y-6">
                    <CostChart data={getSafeArray(getSafeValue(metrics, 'financial.cost_analysis', []))} />
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                        <MetricCard
                            title="Earned Value"
                            value={`$${getSafeValue(metrics, 'financial.earned_value', 0).toLocaleString()}`}
                            trend={getSafeValue(metrics, 'financial.earned_value_trend')}
                            icon={CurrencyDollarIcon}
                        />
                        <MetricCard
                            title="Cost Variance"
                            value={`$${getSafeValue(metrics, 'financial.cost_variance', 0).toLocaleString()}`}
                            trend={getSafeValue(metrics, 'financial.variance_trend')}
                            icon={ChartBarIcon}
                        />
                        <MetricCard
                            title="Forecasted Cost"
                            value={`$${getSafeValue(metrics, 'financial.forecasted_completion', 0).toLocaleString()}`}
                            icon={ArrowTrendingUpIcon}
                        />
                    </div>
                </div>
            )}

            {activeView === 'schedule' && (
                <div className="space-y-6">
                    <ScheduleAnalysis data={getSafeValue(metrics, 'schedule', {})} />
                    <div className="bg-white rounded-lg shadow p-6">
                        <h3 className="text-lg font-medium text-gray-900 mb-4">Projects Schedule Summary</h3>
                        <div className="space-y-4">
                            {getSafeArray(getSafeValue(metrics, 'schedule.projects_summary', [])).map((project, index) => (
                                <div key={index} className="flex items-center justify-between border-b pb-2">
                                    <div>
                                        <span className="font-medium">{project.name || 'Unnamed Project'}</span>
                                        <span className="text-sm text-gray-500 ml-2">
                                            {project.completion_percentage || 0}% Complete
                                        </span>
                                    </div>
                                    <div className={`text-sm font-medium ${(project.status === 'on_track' ? 'text-green-600' :
                                        project.status === 'at_risk' ? 'text-yellow-600' : 'text-red-600')
                                        }`}>
                                        {(project.status || 'unknown').replace('_', ' ').toUpperCase()}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}

            {activeView === 'quality' && (
                <div className="space-y-6">
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                        <QualityMetrics data={getSafeValue(metrics, 'quality', {})} />
                        <div className="bg-white rounded-lg shadow p-6">
                            <h3 className="text-lg font-medium text-gray-900 mb-4">Quality Trends</h3>
                            {getSafeArray(getSafeValue(metrics, 'quality.trends', [])).length > 0 ? (
                                <div className="h-64">
                                    <ResponsiveContainer width="100%" height="100%">
                                        <LineChart data={metrics.quality.trends}>
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis
                                                dataKey="date"
                                                tickFormatter={(date) => date ? format(new Date(date), 'MMM d') : ''}
                                            />
                                            <YAxis />
                                            <Tooltip
                                                labelFormatter={(date) => date ? format(new Date(date), 'MMM d, yyyy') : ''}
                                            />
                                            <Legend />
                                            <Line type="monotone" dataKey="defects" stroke="#ff7300" name="Defects" />
                                            <Line type="monotone" dataKey="issues" stroke="#387908" name="Issues" />
                                        </LineChart>
                                    </ResponsiveContainer>
                                </div>
                            ) : (
                                <div className="h-64 flex items-center justify-center text-gray-500">
                                    No quality trend data available
                                </div>
                            )}
                        </div>
                    </div>

                    {/* Quality Issues Summary */}
                    <div className="bg-white rounded-lg shadow p-6">
                        <h3 className="text-lg font-medium text-gray-900 mb-4">Quality Issues by Project</h3>
                        <div className="overflow-x-auto">
                            <table className="min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Project
                                        </th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Open Issues
                                        </th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Critical Issues
                                        </th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Resolution Rate
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                    {getSafeArray(getSafeValue(metrics, 'quality.projects_summary', [])).map((project, index) => (
                                        <tr key={index}>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                {project.name || 'Unnamed Project'}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                {project.open_issues || 0}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                {project.critical_issues || 0}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                {project.resolution_rate || 0}%
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            )}

            {activeView === 'resources' && (
                <div className="space-y-6">
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                        <ResourceUtilization data={getSafeValue(metrics, 'resource', {})} />
                        <div className="bg-white rounded-lg shadow p-6">
                            <h3 className="text-lg font-medium text-gray-900 mb-4">Resource Allocation by Project</h3>
                            {getSafeArray(getSafeValue(metrics, 'resource.project_allocation', [])).length > 0 ? (
                                <div className="h-64">
                                    <ResponsiveContainer width="100%" height="100%">
                                        <BarChart data={metrics.resource.project_allocation}>
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="project" />
                                            <YAxis />
                                            <Tooltip />
                                            <Legend />
                                            <Bar dataKey="allocated" fill="#8884d8" name="Allocated" />
                                            <Bar dataKey="actual" fill="#82ca9d" name="Actual Usage" />
                                        </BarChart>
                                    </ResponsiveContainer>
                                </div>
                            ) : (
                                <div className="h-64 flex items-center justify-center text-gray-500">
                                    No resource allocation data available
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AnalyticsDashboard;