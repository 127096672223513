import React, { useEffect, useRef } from 'react';
import { Card, CardContent } from '../../components/ui/cardui';
import * as d3 from 'd3';

const RiskHeatMap = ({ risks, onRiskClick }) => {
    const svgRef = useRef(null);

    useEffect(() => {
        if (risks.length === 0 || !svgRef.current) return;

        // Clear previous visualization
        d3.select(svgRef.current).selectAll("*").remove();

        // Map severity and probability to numerical values
        const severityMap = {
            'LOW': 1,
            'MEDIUM': 2,
            'HIGH': 3,
            'CRITICAL': 4
        };

        const probabilityMap = {
            'RARE': 1,
            'UNLIKELY': 2,
            'POSSIBLE': 3,
            'LIKELY': 4,
            'ALMOST_CERTAIN': 5
        };

        // Get label from numerical value
        const getSeverityLabel = (value) => {
            const labels = ['LOW', 'MEDIUM', 'HIGH', 'CRITICAL'];
            return labels[value - 1];
        };

        const getProbabilityLabel = (value) => {
            const labels = ['RARE', 'UNLIKELY', 'POSSIBLE', 'LIKELY', 'ALMOST_CERTAIN'];
            return labels[value - 1];
        };

        // Map risks to coordinates
        const riskData = risks.map(risk => ({
            id: risk.id,
            title: risk.title,
            x: severityMap[risk.severity] || 1,
            y: probabilityMap[risk.probability] || 1,
            severity: risk.severity,
            probability: risk.probability,
            category: risk.category_name,
            risk_score: risk.risk_score,
            ai_suggested: risk.ai_suggested,
            originalRisk: risk
        }));

        // Set up dimensions
        const margin = { top: 40, right: 40, bottom: 60, left: 60 };
        const width = 600 - margin.left - margin.right;
        const height = 400 - margin.top - margin.bottom;

        // Create scales
        const xScale = d3.scaleLinear()
            .domain([0.5, 4.5])
            .range([0, width]);

        const yScale = d3.scaleLinear()
            .domain([0.5, 5.5])
            .range([height, 0]);

        // Create SVG element
        const svg = d3.select(svgRef.current)
            .attr('width', width + margin.left + margin.right)
            .attr('height', height + margin.top + margin.bottom)
            .append('g')
            .attr('transform', `translate(${margin.left},${margin.top})`);

        // Create heat map grid
        for (let i = 1; i <= 4; i++) {
            for (let j = 1; j <= 5; j++) {
                const riskLevel = i * j;
                let color;

                if (riskLevel <= 4) color = '#92d050'; // Green
                else if (riskLevel <= 8) color = '#ffff00'; // Yellow
                else if (riskLevel <= 12) color = '#ffc000'; // Orange
                else color = '#ff0000'; // Red

                svg.append('rect')
                    .attr('x', xScale(i - 0.5))
                    .attr('y', yScale(j + 0.5))
                    .attr('width', xScale(1) - xScale(0))
                    .attr('height', yScale(0) - yScale(1))
                    .attr('fill', color)
                    .attr('stroke', 'white')
                    .attr('stroke-width', 2)
                    .attr('opacity', 0.7);
            }
        }

        // Add x-axis
        const xAxis = d3.axisBottom(xScale)
            .tickValues([1, 2, 3, 4])
            .tickFormat(d => getSeverityLabel(d));

        svg.append('g')
            .attr('transform', `translate(0,${height})`)
            .call(xAxis);

        // Add y-axis
        const yAxis = d3.axisLeft(yScale)
            .tickValues([1, 2, 3, 4, 5])
            .tickFormat(d => getProbabilityLabel(d));

        svg.append('g')
            .call(yAxis);

        // Add axis labels
        svg.append('text')
            .attr('x', width / 2)
            .attr('y', height + 40)
            .attr('text-anchor', 'middle')
            .attr('font-weight', 'bold')
            .text('Severity');

        svg.append('text')
            .attr('transform', 'rotate(-90)')
            .attr('x', -height / 2)
            .attr('y', -40)
            .attr('text-anchor', 'middle')
            .attr('font-weight', 'bold')
            .text('Probability');

        // Add risk dots
        svg.selectAll('circle')
            .data(riskData)
            .enter()
            .append('circle')
            .attr('cx', d => xScale(d.x))
            .attr('cy', d => yScale(d.y))
            .attr('r', 8)
            .attr('fill', d => d.ai_suggested ? '#00bcd4' : '#4e73df')
            .attr('stroke', 'black')
            .attr('stroke-width', 1)
            .attr('cursor', 'pointer')
            .on('click', (event, d) => {
                onRiskClick(d.originalRisk);
            })
            .append('title')
            .text(d => `${d.title}\nCategory: ${d.category}\nSeverity: ${d.severity}\nProbability: ${d.probability}\nRisk Score: ${d.risk_score}`);

        // Add legend
        const legend = svg.append('g')
            .attr('transform', `translate(${width - 100}, 0)`);

        legend.append('text')
            .attr('x', 0)
            .attr('y', -10)
            .attr('font-weight', 'bold')
            .text('Risk Level');

        // Add colored rectangles
        const legendData = [
            { color: '#92d050', label: 'Low (1-4)' },
            { color: '#ffff00', label: 'Medium (5-8)' },
            { color: '#ffc000', label: 'High (9-12)' },
            { color: '#ff0000', label: 'Critical (13+)' },
            { color: '#4e73df', label: 'Risk' },
            { color: '#00bcd4', label: 'AI Suggested' }
        ];

        legendData.forEach((item, i) => {
            legend.append('rect')
                .attr('x', 0)
                .attr('y', i * 20)
                .attr('width', 15)
                .attr('height', 15)
                .attr('fill', item.color);

            legend.append('text')
                .attr('x', 20)
                .attr('y', i * 20 + 12)
                .text(item.label);
        });

    }, [risks, onRiskClick]);

    return (
        <Card>
            <CardContent className="flex justify-center">
                <svg ref={svgRef}></svg>
            </CardContent>
        </Card>
    );
};

export default RiskHeatMap;