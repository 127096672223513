import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import api from '../services/api';
import secureLocalStorage from "react-secure-storage";
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';

const Login = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ email: '', password: '' });
  const [error, setError] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [verificationMessage, setVerificationMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  // Handle input change for email, password, and verification code
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleVerificationChange = (e) => {
    setVerificationCode(e.target.value);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const fetchUserProfile = async (token) => {
    try {
      const response = await api.get('users/profile/');

      // Extract the role from the current tenant data structure
      const roleFromProfile = response.data?.current_tenant?.role || response.data.role_name;

      const userData = {
        role_name: roleFromProfile,
        tenant: response.data.tenant || response.data.current_tenant
      };

      console.log("Processing user data:", userData);

      localStorage.setItem('userRole', roleFromProfile);
      if (userData.tenant) {
        localStorage.setItem('tenantIdentifier', userData.tenant);
      }
      secureLocalStorage.setItem('user', userData);

      // Map roles to routes considering the FeatureAccess context
      const roleHomeRoutes = {
        'Tenant Admin': '/dashboard',
        'Tenant Admin Project': '/projects',
        'Tenant Admin Safety': '/safety',
        'Tenant Admin Bid': '/bid-management',
        'Tenant Member': '/projects',
        'Contractor Admin': '/contractor-portal',
        'Client Admin': '/client-portal'
      };

      const redirectPath = roleHomeRoutes[roleFromProfile] || '/dashboard';
      console.log("Redirecting to:", redirectPath);

      // Force a reload to ensure contexts are properly updated
      window.location.href = redirectPath;
    } catch (error) {
      console.error('Error fetching user profile:', error);
      setError('Error loading user profile');
    }
  };

  const handleLoginSuccess = (response) => {

    localStorage.setItem('token', response.data.access);
    localStorage.setItem('refreshToken', response.data.refresh);
    api.defaults.headers['Authorization'] = `Bearer ${response.data.access}`;

    const pendingOnboarding = localStorage.getItem('pendingOnboarding');
    if (pendingOnboarding === 'true') {
      window.location.href = '/onboarding';
    } else {
      fetchUserProfile(response.data.access);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    try {
      if (!isCodeSent) {
        const response = await api.post('login/', {
          email: formData.email.toLowerCase(), // Normalize email
          password: formData.password,
        });

        // Check for verification requirement
        if (response.data.requires_verification) {
          setIsCodeSent(true);
          setVerificationMessage(response.data.message || 'Verification code sent to email');
        } else {
          handleLoginSuccess(response);
        }
      } else {
        // Verification code submission remains the same
        const response = await api.post('verify-code/', {
          email: formData.email.toLowerCase(),
          code: verificationCode,
        });
        handleLoginSuccess(response);
      }
    } catch (error) {
      console.error('Login error:', error);
      setError(
        error.response?.data?.detail ||
        error.response?.data?.message ||
        'An error occurred during login'
      );
    } finally {
      setIsLoading(false);
    }
  };



  return (
    <div className="bg-gray-100 p-8 rounded-lg shadow-md w-full max-w-md">
      <h2 className="text-3xl font-bold text-center mb-6 text-gray-800">
        BUILDIFYPRO <br />
        Login
      </h2>

      {error && (
        <div
          className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4"
          role="alert"
        >
          <span className="block sm:inline">{error}</span>
        </div>
      )}

      {verificationMessage && (
        <div
          className="bg-blue-100 border border-blue-400 text-blue-700 px-4 py-3 rounded relative mb-4"
          role="alert"
        >
          <span className="block sm:inline">{verificationMessage}</span>
        </div>
      )}

      <form onSubmit={handleSubmit} className="space-y-4">
        {!isCodeSent ? (
          <>
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
              className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
              disabled={isLoading}
            />
            {/* <input
              type="password"
              name="password"
              placeholder="Password"
              value={formData.password}
              onChange={handleChange}
              className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
              disabled={isLoading}
            />
          </> */}
            <div className="relative">
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                placeholder="Password"
                value={formData.password}
                onChange={handleChange}
                className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 pr-10"
                required
                disabled={isLoading}
              />
              <button
                type="button"
                className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-600 hover:text-gray-800 focus:outline-none"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? (
                  <EyeSlashIcon className="h-5 w-5" />
                ) : (
                  <EyeIcon className="h-5 w-5" />
                )}
              </button>
            </div>
          </>
        ) : (
          <input
            type="text"
            name="verificationCode"
            placeholder="Enter verification code"
            value={verificationCode}
            onChange={handleVerificationChange}
            className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
            disabled={isLoading}
          />
        )}
        <button
          type="submit"
          className="w-full bg-blue-500 text-white p-3 rounded-md hover:bg-blue-600 transition duration-300 flex items-center justify-center"
          disabled={isLoading}
        >
          {isLoading ? (
            <>
              <svg className="animate-spin h-5 w-5 mr-2 text-white" viewBox="0 0 24 24">
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                  fill="none"
                />
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                />
              </svg>
              {isCodeSent ? 'Verifying...' : 'Logging in...'}
            </>
          ) : (
            <>{isCodeSent ? 'Verify Code' : 'Login'}</>
          )}
        </button>
      </form>

      {!isCodeSent && (
        <>
          <p className="mt-4 text-center text-gray-600">
            <Link to="/reset-password" className="text-blue-500 hover:underline">
              Forgot Password?
            </Link>
          </p>
          <p className="mt-4 text-center text-gray-600">
            Don't have an account?{' '}
            <Link to="/register" className="text-blue-500 hover:underline">
              Sign up
            </Link>
          </p>
        </>
      )}
    </div>
  );
};

export default Login;
/*

  return (
    <div className="bg-gray-100 p-8 rounded-lg shadow-md w-full max-w-md">
      <h2 className="text-3xl font-bold text-center mb-6 text-gray-800">BUILDIFYPRO <br />Login</h2>

      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
          <span className="block sm:inline">{error}</span>
        </div>
      )}

      <form onSubmit={handleSubmit} className="space-y-4">
        <input
          type="email"
          name="email"
          placeholder="Email"
          value={formData.email}
          onChange={handleChange}
          className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          required
        />
        <input
          type="password"
          name="password"
          placeholder="Password"
          value={formData.password}
          onChange={handleChange}
          className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          required
        />
        <button
          type="submit"
          className="w-full bg-blue-500 text-white p-3 rounded-md hover:bg-blue-600 transition duration-300"
        >
          Login
        </button>
      </form>
      <p className="mt-4 text-center text-gray-600">
        <Link to="/reset-password" className="text-blue-500 hover:underline">Forgot Password?</Link>
      </p>
      <p className="mt-4 text-center text-gray-600">
        Don't have an account? <Link to="/register" className="text-blue-500 hover:underline">Sign up</Link>
      </p>
    </div>
  );
};

export default Login;
*/