import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "../../../ui/cardui";
import { Button } from "../../../ui/button";
import { Loader2 } from 'lucide-react';
import api from '../../../../services/api';
import { useToast } from "../../../ui/use-toast";

const LinkRequestsTab = ({ bidId }) => {
    const [requests, setRequests] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [error, setError] = useState(null);
    const { toast } = useToast();
    const PER_PAGE = 10;

    useEffect(() => {
        fetchRequests();
    }, [page, bidId]);

    const fetchRequests = async () => {
        try {
            setLoading(true);
            const response = await api.get(`bids/${bidId}/link-renewal-requests/`, {
                params: {
                    page,
                    page_size: PER_PAGE
                }
            });
            setRequests(response.data.results);
            setTotalPages(Math.ceil(response.data.count / PER_PAGE));
        } catch (err) {
            console.error('Error fetching requests:', err);
            setError('Failed to load renewal requests');
            toast({
                title: "Error",
                description: "Failed to load link renewal requests",
                variant: "destructive"
            });
        } finally {
            setLoading(false);
        }
    };

    const handleAction = async (requestId, action) => {
        try {
            await api.post('bids/handle-renewal-request/', {
                request_id: requestId,
                action
            });

            toast({
                title: "Success",
                description: `Request ${action}d successfully`
            });

            fetchRequests(); // Refresh the list
        } catch (err) {
            toast({
                title: "Error",
                description: `Failed to ${action} request`,
                variant: "destructive"
            });
        }
    };

    return (
        <Card>
            <CardHeader>
                <CardTitle>Link Renewal Requests</CardTitle>
            </CardHeader>
            <CardContent>
                {loading ? (
                    <div className="flex justify-center py-4">
                        <Loader2 className="h-6 w-6 animate-spin" />
                    </div>
                ) : error ? (
                    <p className="text-center text-red-500">{error}</p>
                ) : requests.length === 0 ? (
                    <p className="text-center text-gray-500 py-4">No link renewal requests</p>
                ) : (
                    <div className="space-y-4">
                        {requests.map((request) => (
                            <div key={request.id}
                                className="flex justify-between items-center p-4 border rounded-lg hover:bg-gray-50">
                                <div className="space-y-1">
                                    <p className="font-medium">
                                        {request.invitation?.profile?.company_name || 'Unknown Company'}
                                    </p>
                                    <p className="text-sm text-gray-500">
                                        Trade: {request.invitation?.profile?.specialized_trades?.map(t => t.name).join(', ') || 'Not specified'}
                                    </p>
                                    <div className="flex items-center gap-2 text-sm text-gray-500">
                                        <p>Requested: {new Date(request.created_at).toLocaleDateString()}</p>
                                        <p>•</p>
                                        <p className="capitalize">Status: {request.status}</p>
                                    </div>
                                </div>
                                {request.status === 'pending' && (
                                    <div className="flex gap-2">
                                        <Button
                                            onClick={() => handleAction(request.id, 'approve')}
                                            variant="outline"
                                            className="bg-green-50 hover:bg-green-100 text-green-700"
                                        >
                                            Approve
                                        </Button>
                                        <Button
                                            onClick={() => handleAction(request.id, 'deny')}
                                            variant="outline"
                                            className="bg-red-50 hover:bg-red-100 text-red-700"
                                        >
                                            Deny
                                        </Button>
                                    </div>
                                )}
                            </div>
                        ))}

                        {/* Pagination */}
                        {totalPages > 1 && (
                            <div className="flex justify-center gap-2 mt-4">
                                <Button
                                    variant="outline"
                                    onClick={() => setPage(p => Math.max(1, p - 1))}
                                    disabled={page === 1}
                                >
                                    Previous
                                </Button>
                                <span className="flex items-center px-4">
                                    Page {page} of {totalPages}
                                </span>
                                <Button
                                    variant="outline"
                                    onClick={() => setPage(p => Math.min(totalPages, p + 1))}
                                    disabled={page === totalPages}
                                >
                                    Next
                                </Button>
                            </div>
                        )}
                    </div>
                )}
            </CardContent>
        </Card>
    );
};
export default LinkRequestsTab;
