import React, { useState, useEffect } from 'react';
import ProjectDocuments from './feature-types/ProjectDocuments';
import SafetyDocuments from './feature-types/SafetyDocuments';
import BidDocuments from './feature-types/BidDocuments';
import GeneralDocuments from './feature-types/GeneralDocuments';
import DocumentHeader from './shared/DocumentHeader';
import api from '../../services/api';
import { toast } from 'react-toastify';

const DocumentManagement = () => {
  const [userRole, setUserRole] = useState(null);
  const [accessibleFeatureTypes, setAccessibleFeatureTypes] = useState([]);
  const [activeFeatureType, setActiveFeatureType] = useState(null);
  const [loading, setLoading] = useState(true);
  const [authError, setAuthError] = useState(false);
  const [rootFolders, setRootFolders] = useState({});

  useEffect(() => {
    fetchUserRole();
  }, []);

  useEffect(() => {
    if (accessibleFeatureTypes.length > 0) {
      fetchRootFolders();
    }
  }, [accessibleFeatureTypes]);

  const fetchUserRole = async () => {
    try {
      setLoading(true);
      const response = await api.get('users/profile/', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        }
      });
      setUserRole(response.data.role_name);

      console.log("Role: ", response.data.role_name);

      // Determine which feature types this role can access
      const featureTypes = [];

      if (response.data.role_name === 'Tenant Admin') {
        featureTypes.push('project', 'safety', 'bid', 'document');
      } else if (response.data.role_name === 'Tenant Admin Safety') {
        featureTypes.push('safety');
      } else if (response.data.role_name === 'Tenant Admin Bid') {
        featureTypes.push('bid');
      } else if (response.data.role_name === 'Tenant Admin Project') {
        featureTypes.push('project');
      }

      setAccessibleFeatureTypes(featureTypes);

      // Set the default active feature type
      if (featureTypes.length > 0) {
        setActiveFeatureType(featureTypes[0]);
      }
    } catch (error) {
      console.error('Error fetching user role:', error);
      if (error.response?.status === 401) {
        setAuthError(true);
      }
      toast.error('Failed to fetch user role');
    } finally {
      setLoading(false);
    }
  };

  const fetchRootFolders = async () => {
    try {
      const featureTypesParam = accessibleFeatureTypes.join(',');
      const response = await api.get('document-management/root-folders/', {
        params: { feature_types: featureTypesParam }
      });

      console.log("Root folders response:", response.data);

      // Create a map of feature type to folder ID
      const folderMap = {};
      response.data.root_folders.forEach(folder => {
        folderMap[folder.feature_type] = folder.id;
      });

      setRootFolders(folderMap);
    } catch (error) {
      console.error('Error fetching root folders:', error);
      toast.error('Failed to load root folders');
    }
  };

  const renderFeatureComponent = () => {
    if (loading) {
      return <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
      </div>;
    }

    const rootFolderId = rootFolders[activeFeatureType];

    switch (activeFeatureType) {
      case 'project':
        return <ProjectDocuments rootFolderId={rootFolderId} />;
      case 'safety':
        return <SafetyDocuments rootFolderId={rootFolderId} />;
      case 'bid':
        return <BidDocuments rootFolderId={rootFolderId} />;
      case 'document':
        return <GeneralDocuments rootFolderId={rootFolderId} />;
      default:
        return <div className="text-center py-12">No document access available</div>;
    }
  };

  return (
    <div className="flex flex-col h-screen bg-gray-50">
      {authError && <p className="text-red-500">Authorization error. Please log in.</p>}

      {/* Header */}
      <DocumentHeader />

      {/* Feature Type Navigation */}
      {/* <div className="container mx-auto mt-6 px-4">
        <div className="flex overflow-x-auto space-x-2 pb-2">
          {accessibleFeatureTypes.map(featureType => (
            <button
              key={featureType}
              onClick={() => setActiveFeatureType(featureType)}
              className={`px-4 py-2 rounded-md whitespace-nowrap ${activeFeatureType === featureType
                ? 'bg-[#344bfc] text-white'
                : 'bg-white text-gray-800 border border-gray-300 hover:bg-gray-50'
                }`}
            >
              {featureType.charAt(0).toUpperCase() + featureType.slice(1)} Documents
            </button>
          ))}
        </div>
      </div> */}

      {/* Feature-specific document management component */}
      <div className="flex-1 container mx-auto mt-4 px-4 pb-6">
        {renderFeatureComponent()}
      </div>
    </div>
  );
};

export default DocumentManagement;