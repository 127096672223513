import React, { useState, useEffect } from 'react';
import { DatePicker } from "../../components/ui/date-picker";
import { Button } from "../../components/ui/button";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "../../components/ui/dialog";
import { Input } from "../../components/ui/input";
import { Label } from "../../components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../components/ui/select";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../components/ui/tabs";
import { Textarea } from "../../components/ui/textarea";
import { toast } from "react-toastify";
import api from '../../services/api';
import { primaryButtonClasses, secondaryButtonClasses } from '../common/ButtonStyles';

const SupplierForm = ({
    isOpen,
    onClose,
    onSubmit,
    supplier = null
}) => {
    const [formData, setFormData] = useState({});
    const [loading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useState('basic');

    useEffect(() => {
        if (supplier) {
            setFormData({
                ...supplier,
                // Convert dates to Date objects if they exist
                insurance_expiry: supplier.insurance_expiry ? new Date(supplier.insurance_expiry) : null,
                certification_expiry: supplier.certification_expiry ? new Date(supplier.certification_expiry) : null,
            });
        } else {
            setFormData({
                status: 'active',
                payment_terms: 'net_30'
            });
        }
    }, [supplier]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleSelectChange = (name, value) => {
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleDateChange = (name, date) => {
        setFormData(prev => ({ ...prev, [name]: date }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            await onSubmit(formData);
        } catch (error) {
            console.error("Error submitting form:", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className="sm:max-w-[800px] w-full mx-4 sm:mx-auto max-h-[90vh] overflow-y-auto">
                <DialogHeader>
                    <DialogTitle>{supplier ? 'Edit Supplier' : 'Add New Supplier'}</DialogTitle>
                </DialogHeader>

                <form onSubmit={handleSubmit} className="space-y-6">
                    <Tabs value={activeTab} onValueChange={setActiveTab}>
                        <TabsList className="grid grid-cols-4">
                            <TabsTrigger value="basic">Basic Info</TabsTrigger>
                            <TabsTrigger value="address">Address</TabsTrigger>
                            <TabsTrigger value="business">Business Info</TabsTrigger>
                            <TabsTrigger value="financial">Financial</TabsTrigger>
                        </TabsList>

                        <TabsContent value="basic" className="space-y-4 pt-4">
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div>
                                    <Label htmlFor="company_name">Company Name*</Label>
                                    <Input
                                        id="company_name"
                                        name="company_name"
                                        value={formData.company_name || ''}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div>
                                    <Label htmlFor="supplier_code">Supplier Code*</Label>
                                    <Input
                                        id="supplier_code"
                                        name="supplier_code"
                                        value={formData.supplier_code || ''}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>

                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div>
                                    <Label htmlFor="contact_name">Contact Name*</Label>
                                    <Input
                                        id="contact_name"
                                        name="contact_name"
                                        value={formData.contact_name || ''}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div>
                                    <Label htmlFor="email">Email*</Label>
                                    <Input
                                        id="email"
                                        name="email"
                                        type="email"
                                        value={formData.email || ''}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>

                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div>
                                    <Label htmlFor="phone">Phone*</Label>
                                    <Input
                                        id="phone"
                                        name="phone"
                                        value={formData.phone || ''}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div>
                                    <Label htmlFor="alternate_phone">Alternate Phone</Label>
                                    <Input
                                        id="alternate_phone"
                                        name="alternate_phone"
                                        value={formData.alternate_phone || ''}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>

                            <div>
                                <Label htmlFor="status">Status*</Label>
                                <Select
                                    value={formData.status || 'active'}
                                    onValueChange={(value) => handleSelectChange('status', value)}
                                >
                                    <SelectTrigger>
                                        <SelectValue placeholder="Select status" />
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectItem value="active">Active</SelectItem>
                                        <SelectItem value="inactive">Inactive</SelectItem>
                                        <SelectItem value="pending">Pending Approval</SelectItem>
                                        <SelectItem value="suspended">Suspended</SelectItem>
                                    </SelectContent>
                                </Select>
                            </div>
                        </TabsContent>

                        <TabsContent value="address" className="space-y-4 pt-4">
                            <div>
                                <Label htmlFor="address_line1">Address Line 1*</Label>
                                <Input
                                    id="address_line1"
                                    name="address_line1"
                                    value={formData.address_line1 || ''}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div>
                                <Label htmlFor="address_line2">Address Line 2</Label>
                                <Input
                                    id="address_line2"
                                    name="address_line2"
                                    value={formData.address_line2 || ''}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div>
                                    <Label htmlFor="city">City*</Label>
                                    <Input
                                        id="city"
                                        name="city"
                                        value={formData.city || ''}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div>
                                    <Label htmlFor="state">State/Province*</Label>
                                    <Input
                                        id="state"
                                        name="state"
                                        value={formData.state || ''}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div>
                                    <Label htmlFor="postal_code">Postal Code*</Label>
                                    <Input
                                        id="postal_code"
                                        name="postal_code"
                                        value={formData.postal_code || ''}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div>
                                    <Label htmlFor="country">Country*</Label>
                                    <Input
                                        id="country"
                                        name="country"
                                        value={formData.country || ''}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>
                        </TabsContent>

                        <TabsContent value="business" className="space-y-4 pt-4">
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div>
                                    <Label htmlFor="tax_id">Tax ID</Label>
                                    <Input
                                        id="tax_id"
                                        name="tax_id"
                                        value={formData.tax_id || ''}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div>
                                    <Label htmlFor="business_license">Business License</Label>
                                    <Input
                                        id="business_license"
                                        name="business_license"
                                        value={formData.business_license || ''}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div>
                                    <Label htmlFor="year_established">Year Established</Label>
                                    <Input
                                        id="year_established"
                                        name="year_established"
                                        type="number"
                                        value={formData.year_established || ''}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div>
                                    <Label htmlFor="insurance_expiry">Insurance Expiry</Label>
                                    <DatePicker
                                        value={formData.insurance_expiry}
                                        onChange={(date) => handleDateChange('insurance_expiry', date)}
                                    />
                                </div>
                                <div>
                                    <Label htmlFor="certification_expiry">Certification Expiry</Label>
                                    <DatePicker
                                        value={formData.certification_expiry}
                                        onChange={(date) => handleDateChange('certification_expiry', date)}
                                    />
                                </div>
                            </div>
                        </TabsContent>

                        <TabsContent value="financial" className="space-y-4 pt-4">
                            <div>
                                <Label htmlFor="payment_terms">Payment Terms*</Label>
                                <Select
                                    value={formData.payment_terms || 'net_30'}
                                    onValueChange={(value) => handleSelectChange('payment_terms', value)}
                                >
                                    <SelectTrigger>
                                        <SelectValue placeholder="Select payment terms" />
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectItem value="net_30">Net 30</SelectItem>
                                        <SelectItem value="net_45">Net 45</SelectItem>
                                        <SelectItem value="net_60">Net 60</SelectItem>
                                        <SelectItem value="cod">Cash on Delivery</SelectItem>
                                        <SelectItem value="advance">Advance Payment</SelectItem>
                                    </SelectContent>
                                </Select>
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div>
                                    <Label htmlFor="credit_limit">Credit Limit</Label>
                                    <Input
                                        id="credit_limit"
                                        name="credit_limit"
                                        type="number"
                                        step="0.01"
                                        value={formData.credit_limit || ''}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div>
                                    <Label htmlFor="bank_name">Bank Name</Label>
                                    <Input
                                        id="bank_name"
                                        name="bank_name"
                                        value={formData.bank_name || ''}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div>
                                    <Label htmlFor="bank_account">Bank Account</Label>
                                    <Input
                                        id="bank_account"
                                        name="bank_account"
                                        value={formData.bank_account || ''}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </TabsContent>
                    </Tabs>

                    <DialogFooter>
                        <Button
                            className={secondaryButtonClasses}
                            type="button"
                            variant="outline"
                            onClick={onClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            className={primaryButtonClasses}
                            type="submit"
                            disabled={loading}
                        >
                            {loading ? "Saving..." : supplier ? "Update Supplier" : "Create Supplier"}
                        </Button>
                    </DialogFooter>
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default SupplierForm;