import * as React from "react"
import { cn } from "../../lib/utils"
import { ChevronDown } from "lucide-react"

const Accordion = React.forwardRef(({ className, type = "single", collapsible = false, value, defaultValue, onValueChange, ...props }, ref) => {
    const [state, setState] = React.useState(defaultValue || [])

    const handleValueChange = React.useCallback((value) => {
        setState(value)
        onValueChange?.(value)
    }, [onValueChange])

    return (
        <div
            ref={ref}
            data-type={type}
            data-collapsible={collapsible}
            className={cn("", className)}
            {...props}
            value={value || state}
            onValueChange={handleValueChange}
        />
    )
})
Accordion.displayName = "Accordion"

const AccordionItem = React.forwardRef(({ className, value, ...props }, ref) => (
    <div
        ref={ref}
        data-state={value ? "open" : "closed"}
        className={cn("border-b", className)}
        {...props}
    />
))
AccordionItem.displayName = "AccordionItem"

const AccordionTrigger = React.forwardRef(({ className, children, ...props }, ref) => {
    const accordionContext = React.useContext(AccordionContext)
    const isOpen = accordionContext?.isOpen || false

    return (
        <button
            ref={ref}
            type="button"
            className={cn(
                "flex flex-1 items-center justify-between py-4 font-medium transition-all hover:underline [&[data-state=open]>svg]:rotate-180",
                className
            )}
            {...props}
        >
            {children}
            <ChevronDown className="h-4 w-4 shrink-0 transition-transform duration-200" />
        </button>
    )
})
AccordionTrigger.displayName = "AccordionTrigger"

const AccordionContent = React.forwardRef(({ className, children, ...props }, ref) => {
    const accordionContext = React.useContext(AccordionContext)
    const isOpen = accordionContext?.isOpen || false

    return (
        <div
            ref={ref}
            data-state={isOpen ? "open" : "closed"}
            className={cn(
                "overflow-hidden text-sm transition-all data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down",
                className
            )}
            {...props}
        >
            <div className="pb-4 pt-0">{children}</div>
        </div>
    )
})
AccordionContent.displayName = "AccordionContent"

// Create context for accordion state management
const AccordionContext = React.createContext(null)

// Custom hook for handling the accordion state
const useAccordion = (initialValue = null, collapsible = false) => {
    const [openItem, setOpenItem] = React.useState(initialValue)

    const toggle = React.useCallback((value) => {
        setOpenItem((prev) => {
            if (prev === value && collapsible) {
                return null
            }
            return value
        })
    }, [collapsible])

    return { openItem, toggle }
}

export {
    Accordion,
    AccordionItem,
    AccordionTrigger,
    AccordionContent,
    AccordionContext,
    useAccordion
}