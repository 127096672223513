import React, { useState } from 'react';
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
} from '../ui/dialog';
import { Button } from '../ui/button';
import { Badge } from '../ui/badge';
import { Check, CreditCard, Loader2 } from 'lucide-react';
import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger
} from '../ui/tabs';
import { ScrollArea } from '../ui/scroll-area';

const formatFeatureName = (name) => {
    return name
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};

const UpgradeDialog = ({
    currentPlan,
    currentBillingCycle,
    availablePlans = [],
    onUpgrade,
    onCancel,
    isLoading = false,
    isTrial = false,
    showAllPlans = false,
    planFeatures = []
}) => {
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [billingCycle, setBillingCycle] = useState(isTrial ? 'monthly' : currentBillingCycle);

    const calculateSavings = (monthlyPrice, yearlyPrice) => {
        const monthlyTotal = monthlyPrice * 12;
        const yearlyTotal = yearlyPrice;
        return Math.round(((monthlyTotal - yearlyTotal) / monthlyTotal) * 100);
    };

    return (
        <Dialog open onOpenChange={onCancel}>
            <DialogContent className="max-w-2xl h-[90vh] p-0 flex flex-col">
                <div className="shrink-0 px-6 py-4 border-b">
                    <DialogHeader>
                        <DialogTitle>
                            {isTrial ? 'Choose Your Plan' : 'Upgrade Your Plan'}
                        </DialogTitle>
                        <DialogDescription>
                            {isTrial
                                ? 'Select a plan to continue using our services after your trial'
                                : 'Choose a plan that best fits your needs'}
                        </DialogDescription>
                    </DialogHeader>
                </div>

                <div className="shrink-0 px-6 py-4 border-b">
                    <Tabs
                        defaultValue={billingCycle}
                        onValueChange={setBillingCycle}
                        className="w-full"
                    >
                        <TabsList className="grid w-full grid-cols-2">
                            <TabsTrigger value="monthly">Monthly Billing</TabsTrigger>
                            <TabsTrigger value="yearly">
                                Yearly Billing
                                <Badge variant="secondary" className="ml-2">Save up to 15%</Badge>
                            </TabsTrigger>
                        </TabsList>
                    </Tabs>
                </div>

                <ScrollArea className="flex-1 px-6 py-4">
                    <div className="space-y-6">
                        {availablePlans.map((plan) => {
                            const monthlyPrice = parseFloat(plan.monthly_price);
                            const yearlyPrice = parseFloat(plan.annual_price);
                            const pricePerMonth = billingCycle === 'yearly'
                                ? yearlyPrice / 12
                                : monthlyPrice;

                            return (
                                <div
                                    key={plan.id}
                                    onClick={() => setSelectedPlan(plan)}
                                    className={`
                                        space-y-6 rounded-lg border-2 p-6 cursor-pointer transition-all
                                        ${selectedPlan?.id === plan.id
                                            ? 'border-primary bg-primary/5'
                                            : 'border-muted hover:border-primary/50'}
                                    `}
                                >
                                    <div className="text-center">
                                        <h2 className="text-2xl font-semibold">{plan.name}</h2>
                                        <p className="text-muted-foreground mt-2 max-w-2xl mx-auto">
                                            {plan.description}
                                        </p>
                                    </div>

                                    <div className="text-center">
                                        <div className="flex items-baseline justify-center">
                                            <span className="text-4xl font-bold">
                                                ${pricePerMonth.toFixed(2)}
                                            </span>
                                            <span className="text-muted-foreground ml-2">
                                                per month
                                            </span>
                                        </div>
                                        {billingCycle === 'yearly' && (
                                            <div className="mt-2">
                                                <Badge variant="secondary">
                                                    Save {calculateSavings(monthlyPrice, yearlyPrice)}%
                                                </Badge>
                                                <div className="text-sm text-muted-foreground mt-1">
                                                    ${yearlyPrice.toFixed(2)} billed yearly
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    <div className="relative">
                                        <div className="absolute -top-3 left-0 right-0 text-center">
                                            <span className="bg-background px-4 text-base">
                                                Plan Features
                                            </span>
                                        </div>
                                        <div className="border-t pt-8">
                                            <div className="space-y-6">
                                                {plan.features?.map((feature) => (
                                                    <div key={feature.id || feature} className="flex items-start gap-4">
                                                        <Check className="h-5 w-5 text-primary mt-1 shrink-0" />
                                                        <div>
                                                            <div className="font-medium">
                                                                {typeof feature === 'string'
                                                                    ? formatFeatureName(feature)
                                                                    : formatFeatureName(feature.name)}
                                                            </div>
                                                            {feature.description && (
                                                                <div className="text-sm text-muted-foreground mt-1">
                                                                    {feature.description}
                                                                </div>
                                                            )}
                                                            {feature.limit && (
                                                                <div className="text-sm text-muted-foreground mt-1">
                                                                    Limit: {feature.limit}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>

                </ScrollArea >

                <div className="shrink-0 px-6 py-4 border-t bg-background">
                    <div className="flex justify-between items-center gap-4">
                        <Button variant="outline" onClick={onCancel}>
                            Cancel
                        </Button>
                        <Button
                            onClick={() => onUpgrade({
                                plan: selectedPlan?.name.toLowerCase(),
                                billingCycle,
                                price_id: billingCycle === 'yearly'
                                    ? selectedPlan?.stripe_price_id_yearly
                                    : selectedPlan?.stripe_price_id_monthly
                            })}
                            disabled={!selectedPlan || isLoading}
                            className="gap-2"
                        >
                            {isLoading ? (
                                <>
                                    <Loader2 className="h-4 w-4 animate-spin" />
                                    Processing...
                                </>
                            ) : (
                                <>
                                    <CreditCard className="h-4 w-4" />
                                    {isTrial ? 'Start Plan' : 'Upgrade Plan'}
                                </>
                            )}
                        </Button>
                    </div>
                    {selectedPlan && billingCycle === 'yearly' && (
                        <div className="text-sm text-muted-foreground text-center mt-4">
                            You will be charged ${selectedPlan.annual_price.toFixed(2)} for a yearly subscription
                        </div>
                    )}
                </div>
            </DialogContent >
        </Dialog >
    );
};

export default UpgradeDialog;