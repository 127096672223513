import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Header from '../Header';
import ContentCards from '../Contractors/ContentCards';
import {
    HomeIcon,
    ClipboardDocumentListIcon,
    DocumentIcon,
    CurrencyDollarIcon,
    DocumentDuplicateIcon,
    QuestionMarkCircleIcon,
    ClockIcon,
} from '@heroicons/react/24/outline';

const ContractorPortalLayout = () => {
    const location = useLocation();

    const navigation = [
        /*  {
             name: 'Dashboard',
             href: '/contractor',
             icon: HomeIcon,
             description: 'Overview of your projects and activities'
         }, */
        {
            name: 'My Projects',
            href: '/contractor/projects',
            icon: ClipboardDocumentListIcon,
            description: 'View and manage your current projects'
        },
        {
            name: 'Tasks',
            href: '/contractor/tasks',
            icon: DocumentIcon,
            description: 'Track and update your assigned tasks'
        },
        {
            name: 'Invoices',
            href: '/contractor/invoices',
            icon: CurrencyDollarIcon,
            description: 'Manage your invoices and payments'
        },
        {
            name: 'Documents',
            href: '/contractor/documents',
            icon: DocumentDuplicateIcon,
            description: 'Access project documents and files'
        },
        {
            name: 'RFIs',
            href: '/contractor/rfis',
            icon: QuestionMarkCircleIcon,
            description: 'Submit and track information requests'
        },
        {
            name: 'Timesheets',
            href: '/contractor/timesheets',
            icon: ClockIcon,
            description: 'Manage your time and attendance'
        },
    ];

    const NavCard = ({ item }) => (
        <Link
            to={item.href}
            className="bg-white rounded-2xl shadow-sm p-6 hover:shadow-md transition-shadow duration-200"
        >
            <div className="flex items-start space-x-4">
                <div className="bg-gray-100 p-3 rounded-xl">
                    <item.icon className="h-6 w-6 text-gray-600" />
                </div>
                <div>
                    <h3 className="text-lg font-medium text-gray-900 mb-1">
                        {item.name}
                    </h3>
                    <p className="text-sm text-gray-500">{item.description}</p>
                </div>
            </div>
        </Link>
    );

    return (
        <div className="min-h-screen bg-gray-50">

            {/* Main Content */}
            <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-24">
                {/* Content Cards Section */}
                <div className="mb-12">
                    <ContentCards />
                </div>

                {/* Navigation Cards Grid */}
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {navigation.map((item) => (
                        <NavCard key={item.name} item={item} />
                    ))}
                </div>
            </main>
        </div>
    );
};

export default ContractorPortalLayout;