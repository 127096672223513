
import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
  CardFooter
} from '../../components/ui/cardui';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../components/ui/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";
import {
  Dialog,
  DialogContent,
} from "../../components//ui/dialog";
import { Lock } from 'lucide-react';
import { Input } from '../../components/ui/input';
import { Textarea } from '../../components/ui/textarea';
import { Button } from '../../components/ui/button';
import { useToast } from '../../components/ui/use-toast';
import { Breadcrumb } from '../../components/common/Breadcrumb';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { primaryButtonClasses, secondaryButtonClasses } from '../../components/common/ButtonStyles';
import api from '../../services/api';
import { EstimateItemForm } from '../../components/Estimate';
import { SubscriptionContext } from '../../components/Subscription/context/SubscriptionContext';
import { FeatureUpgrade } from '../../components/Subscription/FeatureUpgrade';

const estimateSchema = z.object({
  name: z.string().min(1, 'Name is required'),
  description: z.string().optional(),
  estimate_type: z.enum(['conceptual', 'preliminary', 'detailed', 'change_order']),
  version: z.number().default(1),
  status: z.enum(['draft', 'in_review', 'approved', 'submitted', 'awarded', 'lost', 'archived']).default('draft'),
  overhead_percentage: z.coerce.number()
    .min(0, 'Must be at least 0')
    .max(100, 'Must be at most 100')
    .default(10),
  profit_percentage: z.coerce.number()
    .min(0, 'Must be at least 0')
    .max(100, 'Must be at most 100')
    .default(10),
  contingency_percentage: z.coerce.number()
    .min(0, 'Must be at least 0')
    .max(100, 'Must be at most 100')
    .default(5),
  client_name: z.string().min(1, 'Client name is required'),
  notes: z.string().optional(),
});

const NewEstimate = () => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const [loading, setLoading] = useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const { checkFeature, isLoading } = useContext(SubscriptionContext);
  const canCreateDetailedEstimates = checkFeature('advanced_estimate');


  const form = useForm({
    resolver: zodResolver(estimateSchema),
    defaultValues: {
      name: '',
      description: '',
      estimate_type: 'conceptual',
      version: 1,
      status: 'draft',
      overhead_percentage: 10,
      profit_percentage: 10,
      contingency_percentage: 5,
      client_name: '',
      notes: '',
    }
  });



  const handleEstimateTypeChange = (value) => {
    if (value === 'detailed' && !canCreateDetailedEstimates) {
      setShowUpgradeModal(true);
      return;
    }
    form.setValue('estimate_type', value);
  };


  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const estimateResponse = await api.post('estimates/', {
        ...data,
        project: null,
        overhead_percentage: Number(data.overhead_percentage),
        profit_percentage: Number(data.profit_percentage),
        contingency_percentage: Number(data.contingency_percentage)
      });

      const estimate = estimateResponse.data;

      if (data.estimate_type === 'conceptual') {
        const conceptualData = {
          estimate: estimate.id,
          estimate_number: `CE-${Date.now()}`,
          revision_number: 0,
          project_name: data.name,
          project_description: data.description,
          client_name: data.client_name, // Add client name
          contingency_percentage: Number(data.contingency_percentage),
          profit_margin: Number(data.profit_percentage),
          overhead_percentage: Number(data.overhead_percentage),
          status: 'draft',
          show_factors_in_pdf: false,
          show_allowances_in_pdf: true
        };

        const conceptualResponse = await api.post('conceptual-estimates/', conceptualData);

        toast({
          title: "Success",
          description: "Conceptual estimate created successfully"
        });

        navigate(`/estimates/conceptual/${conceptualResponse.data.id}`);
      } else {
        toast({
          title: "Success",
          description: "Estimate created successfully"
        });
        navigate(`/estimates/${estimate.id}`);
      }
    } catch (error) {
      console.error('Estimate creation error:', error);
      toast({
        title: "Error",
        description: error.response?.data?.detail || "Failed to create estimate",
        variant: "destructive"
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-6">
      <Breadcrumb />
      <Dialog open={showUpgradeModal} onOpenChange={setShowUpgradeModal}>
        <DialogContent className="sm:max-w-[500px]">
          <div className="p-6">
            <h3 className="text-lg font-semibold mb-4">Upgrade Required</h3>
            <p className="text-gray-600 mb-6">
              Detailed estimates are available in the Professional plan. Upgrade your subscription to access this feature.
            </p>
            <div className="flex justify-end space-x-4">
              <Button variant="outline" onClick={() => setShowUpgradeModal(false)} className={secondaryButtonClasses}>
                Cancel
              </Button>
              <Button onClick={() => navigate('/subscription')} className={primaryButtonClasses}>
                Upgrade Now
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <Card className="max-w-2xl mx-auto">
        <CardHeader>
          <CardTitle className="text-2xl font-bold">Create New Estimate</CardTitle>
        </CardHeader>

        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <CardContent className="space-y-6">
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Estimate Name</FormLabel>
                    <FormControl>
                      <Input {...field} className="rounded-xl" placeholder="Enter estimate name" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="estimate_type"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Estimate Type</FormLabel>
                    <Select onValueChange={handleEstimateTypeChange} value={field.value}>
                      <FormControl>
                        <SelectTrigger className="rounded-xl">
                          <SelectValue placeholder="Select estimate type" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem value="conceptual">Conceptual</SelectItem>
                        <SelectItem
                          value="detailed"
                          className="flex items-center justify-between"
                          onSelect={(e) => {
                            if (!canCreateDetailedEstimates) {
                              e.preventDefault();
                              setShowUpgradeModal(true);
                            }
                          }}
                        >
                          <div className="flex items-center gap-2">
                            <span>Detailed</span>
                            {!canCreateDetailedEstimates && (
                              <Lock className="h-4 w-4 text-gray-400 ml-2" />
                            )}
                          </div>
                        </SelectItem>
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="client_name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Client Name</FormLabel>
                    <FormControl>
                      <Input {...field} className="rounded-xl" placeholder="Enter client name" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <div className="grid grid-cols-3 gap-4">
                <FormField
                  control={form.control}
                  name="overhead_percentage"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Overhead %</FormLabel>
                      <FormControl>
                        <Input {...field} type="number" step="0.01" className="rounded-xl" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="profit_percentage"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Profit %</FormLabel>
                      <FormControl>
                        <Input {...field} type="number" step="0.01" className="rounded-xl" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="contingency_percentage"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Contingency %</FormLabel>
                      <FormControl>
                        <Input {...field} type="number" step="0.01" className="rounded-xl" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <FormField
                control={form.control}
                name="description"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Description</FormLabel>
                    <FormControl>
                      <Textarea {...field} className="rounded-xl" rows={4} placeholder="Enter estimate description" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="notes"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Notes</FormLabel>
                    <FormControl>
                      <Textarea {...field} className="rounded-xl" rows={3} placeholder="Add any additional notes" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </CardContent>

            <CardFooter className="flex justify-end space-x-4">
              <Button
                type="button"
                variant="outline"
                onClick={() => navigate('/estimates')}
                className="rounded-xl"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                disabled={loading}
                className="rounded-xl bg-primary text-primary-foreground hover:bg-primary/90"
              >
                {loading ? 'Creating...' : 'Create Estimate'}
              </Button>
            </CardFooter>
          </form>
        </Form>
      </Card>
    </div>
  );
};

export default NewEstimate;