import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from '../../services/api';
import {
  ArrowLeftIcon, BellIcon, MagnifyingGlassIcon, CogIcon, PlusIcon,
  PencilIcon, TrashIcon, LightBulbIcon, DocumentTextIcon, ClockIcon,
  CurrencyDollarIcon, ClipboardDocumentCheckIcon, ChartBarIcon,
  EllipsisHorizontalIcon
} from '@heroicons/react/24/outline';
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../ui/tabs";
import { Alert, AlertDescription } from "../ui/alert";
import ProjectTimeline from './ProjectTimeline';
import RFITable from '../RFI/RFITable';
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import TaskBoard from '../Tasks/TaskBoard';
import { primaryButtonClasses, secondaryButtonClasses } from '../common/ButtonStyles';
import ProgressTracking from '../Projects/Progress/ProgressTracking';
import ClientDocuments from '../Client/ClientDocuments';
import ProjectQualityManagement from './ProjectQualityManagement';
import QualityIssuesManager from './QualityIssuesManager.js'
import InspectionManager from './Inspections/InspectionManager.js';
import ErrorBoundary from '../../utils/ErrorBoundary';
import ScheduleController from './Schedule/ScheduleController';
import secureLocalStorage from "react-secure-storage";
import {
  CalendarDays,
  ClipboardCheck,
  FileText,
  CheckCircle2,
  AlertTriangle,
  HelpCircle,
  MoreHorizontal,
  TriangleAlertIcon
} from 'lucide-react';
import BudgetList from '../budgets/BudgetList.jsx';
import { useFeatureAccess, FEATURES } from '../../contexts/FeatureAccessContext.js';
import RiskManagementDashboard from '../RiskManagement/RiskManagementDashboard.jsx';

function ProjectDetail() {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const [project, setProject] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [activeTab, setActiveTab] = useState('overview');
  const [aiInsight, setAiInsight] = useState('');
  const [showMoreMenu, setShowMoreMenu] = useState(false);
  const moreMenuRef = useRef(null);
  const [visibleTabs, setVisibleTabs] = useState([]);
  const [overflowTabs, setOverflowTabs] = useState([]);
  const tabContainerRef = useRef(null);

  const [formData, setFormData] = useState({
    name: '',
    location: '',
    status: '',
    start_date: '',
    end_date: '',
    budget: '',
    description: ''
  });
  const [metrics, setMetrics] = useState({
    totalHours: 0,
    budgetedAmount: 0,
    actualAmount: 0,
    pendingApprovals: 0,
    tasksCompleted: 0,
    tasksTrend: [],
    rfiCount: 0,
    taskCount: 0,
  });

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [tabPermissions, setTabPermissions] = useState({
    tasks: true,
    risks: true,
    schedule: true,
    defects: true,
    inspections: true,
    rfis: true
  });

  const { hasFeatureAccess, userRole } = useFeatureAccess();


  const canAccessProjectOverview =
    hasFeatureAccess(FEATURES.PROJECT_MANAGEMENT) ||
    hasFeatureAccess(FEATURES.ALL_FEATURES) ||
    userRole === 'Tenant Admin Project';


  const configureRequest = (config = {}) => {

    let tenantId =

      localStorage.getItem('tenantIdentifier') ||
      (typeof secureLocalStorage !== 'undefined' && secureLocalStorage.getItem('currentTenantId')) ||
      localStorage.getItem('tenantId') ||


      sessionStorage.getItem('tenantId') ||
      sessionStorage.getItem('tenantIdentifier');

    if (!tenantId) {
      try {

        const userData = JSON.parse(localStorage.getItem('userData') || '{}');
        tenantId = userData.tenant?.id || userData.tenantId;

        // If found, save to localStorage for future use
        if (tenantId) {
          localStorage.setItem('tenantId', tenantId);

        }
      } catch (e) {
        console.error("Error parsing user data:", e);
      }
    }

    if (!tenantId) {
      console.warn("No tenant ID found in any storage location. This will cause permission errors.");
    }

    return {
      ...config,
      headers: {
        ...config.headers,
        'X-Tenant-ID': tenantId || '',
      }
    };
  };

  useEffect(() => {
    const tenantId = localStorage.getItem('tenantId') ||
      localStorage.getItem('tenantIdentifier') ||
      (typeof secureLocalStorage !== 'undefined' && secureLocalStorage.getItem('currentTenantId'));
    if (!tenantId) {
      console.warn("No tenant ID found in primary storage locations. This may cause permission errors.");
    }
  }, []);

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(amount);
  };

  const checkTabPermissions = async () => {
    try {

      try {
        await api.get(`projects/${projectId}/tasks/`, configureRequest());
        setTabPermissions(prev => ({ ...prev, tasks: true }));
      } catch (error) {
        if (error.response?.status === 403) {
          console.log('No permission for tasks');
          setTabPermissions(prev => ({ ...prev, tasks: false }));
        }
      }


      try {
        await api.get(`projects/${projectId}/rfis/`, configureRequest());
        setTabPermissions(prev => ({ ...prev, rfis: true }));
      } catch (error) {
        if (error.response?.status === 403) {
          console.log('No permission for RFIs');
          setTabPermissions(prev => ({ ...prev, rfis: false }));
        }
      }


      try {
        await api.get(`projects/${projectId}/schedules/`, configureRequest());
        setTabPermissions(prev => ({ ...prev, schedule: true }));
      } catch (error) {
        if (error.response?.status === 403) {
          console.log('No permission for schedule');
          setTabPermissions(prev => ({ ...prev, schedule: false }));
        }
      }

      //Risk Management

      try {
        await api.get(`projects/${projectId}/risks/`, configureRequest());
        setTabPermissions(prev => ({ ...prev, risks: true }));
      } catch (error) {
        if (error.response?.status === 403) {
          console.log('No permission for risks');
          setTabPermissions(prev => ({ ...prev, risks: false }));
        }
      }


      try {

        await api.get(`projects/${projectId}/quality-issues/`, configureRequest());
        setTabPermissions(prev => ({ ...prev, defects: true }));
      } catch (qualityError) {

        try {
          await api.get(`projects/${projectId}/defects/`, configureRequest());
          setTabPermissions(prev => ({ ...prev, defects: true }));
        } catch (defectsError) {

          if (qualityError.response?.status === 403 || defectsError.response?.status === 403) {
            console.log('No permission for defects/quality issues');
            setTabPermissions(prev => ({ ...prev, defects: false }));
          } else if (qualityError.response?.status === 404 && defectsError.response?.status === 404) {

            console.log('Quality issues endpoints not found, but allowing tab');
            setTabPermissions(prev => ({ ...prev, defects: true }));
          }
        }
      }


      try {
        await api.get(`projects/${projectId}/inspections/`, configureRequest());
        setTabPermissions(prev => ({ ...prev, inspections: true }));
      } catch (error) {
        if (error.response?.status === 403) {
          setTabPermissions(prev => ({ ...prev, inspections: false }));
        }
      }

    } catch (error) {
      console.error("Error checking permissions:", error);
    }
  };

  useEffect(() => {
    const fetchProjectAndCounts = async () => {
      setIsLoading(true);
      setError(null);
      try {

        const projectResponse = await api.get(`projects/${projectId}/`, configureRequest());
        console.log("Project details: ", projectResponse);
        const projectData = projectResponse.data;
        setProject(projectData);
        setFormData(projectData);

        await checkTabPermissions();


        if (projectData) {
          try {

            const [rfiCountResponse, taskCountResponse] = await Promise.allSettled([
              api.get(`projects/${projectId}/rfis/`, configureRequest()),
              api.get(`projects/${projectId}/tasks/`, configureRequest())
            ]);

            setMetrics({
              totalHours: projectData.totalHours || 0,
              budgetedAmount: formatCurrency(projectData.budget || 0),
              actualAmount: formatCurrency(projectData.actualAmount || 0),
              pendingApprovals: projectData.pendingApprovals || 0,
              tasksCompleted: projectData.tasksCompleted || 0,
              tasksTrend: projectData.tasksTrend || [],
              rfiCount: rfiCountResponse.status === 'fulfilled' ? rfiCountResponse.value.data.count || 0 : 0,
              taskCount: taskCountResponse.status === 'fulfilled' ? taskCountResponse.value.data.count || 0 : 0,
            });

          } catch (countError) {
            console.error('Error fetching counts:', countError);

            setMetrics({
              totalHours: projectData.totalHours || 0,
              budgetedAmount: formatCurrency(projectData.budget || 0),
              actualAmount: formatCurrency(projectData.actualAmount || 0),
              pendingApprovals: projectData.pendingApprovals || 0,
              tasksCompleted: projectData.tasksCompleted || 0,
              tasksTrend: projectData.tasksTrend || [],
              rfiCount: 0,
              taskCount: 0,
            });
          }
        }
      } catch (error) {
        console.error('Error fetching project details:', error);
        if (error.response?.status === 403) {
          setError('You do not have permission to access this project. Please check your access rights.');
        } else {
          setError('Failed to load project details. Please try again later.');
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchProjectAndCounts();
  }, [projectId]);

  const availableTabs = [
    {
      id: 'overview',
      label: 'Overview',
      isAvailable: true,
      icon: FileText
    },
    {
      id: 'schedule',
      label: 'Schedule',
      isAvailable: tabPermissions.schedule,
      icon: CalendarDays
    },
    {
      id: 'risks',
      label: 'Risks',
      isAvailable: tabPermissions.risks,
      icon: TriangleAlertIcon
    },
    {
      id: 'tasks',
      label: 'Tasks',
      isAvailable: tabPermissions.tasks,
      icon: CheckCircle2
    },
    {
      id: 'budget',
      label: 'Budget',
      isAvailable: true,
      icon: CurrencyDollarIcon
    },
    {
      id: 'defects',
      label: 'Defects',
      isAvailable: tabPermissions.defects,
      icon: AlertTriangle
    },
    {
      id: 'inspections',
      label: 'Inspections',
      isAvailable: tabPermissions.inspections,
      icon: ClipboardCheck
    },
    {
      id: 'rfis',
      label: 'RFIs',
      isAvailable: tabPermissions.rfis,
      icon: HelpCircle
    },
    {
      id: 'punchlist',
      label: 'Punch List',
      isAvailable: true,
      icon: CheckCircle2
    },
  ];

  const toggleMoreMenu = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    setShowMoreMenu(prevState => !prevState);
  };

  const calculateVisibleTabs = () => {

    const filteredTabs = availableTabs.filter(tab => tab.isAvailable);
    const primaryTabIds = ['overview', 'schedule', 'risks', 'budget'];
    const primaryTabs = filteredTabs.filter(tab => primaryTabIds.includes(tab.id));
    const secondaryTabs = filteredTabs.filter(tab => !primaryTabIds.includes(tab.id));

    const screenWidth = window.innerWidth;

    if (screenWidth <= 640) {
      setVisibleTabs(primaryTabs.slice(0, 2));
      setOverflowTabs([...primaryTabs.slice(2), ...secondaryTabs]);
    } else if (screenWidth <= 768) {
      setVisibleTabs(primaryTabs.slice(0, 3));
      setOverflowTabs([...primaryTabs.slice(3), ...secondaryTabs]);
    } else {
      setVisibleTabs(primaryTabs);
      setOverflowTabs(secondaryTabs);
    }
  };

  useEffect(() => {
    calculateVisibleTabs();
    window.addEventListener('resize', calculateVisibleTabs);

    return () => {
      window.removeEventListener('resize', calculateVisibleTabs);
    };
  }, [tabPermissions]);


  useEffect(() => {
    function handleClickOutside(event) {
      if (moreMenuRef.current && !moreMenuRef.current.contains(event.target)) {
        setShowMoreMenu(false);
      }
    }


    if (showMoreMenu) {
      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("touchstart", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    };
  }, [showMoreMenu]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleUpdate = async () => {
    try {
      await api.put(`projects/${projectId}/`, formData, configureRequest());
      setProject(formData);
      setIsEditing(false);
      toast.success('Project has been successfully updated.');
    } catch (error) {
      console.error('Error updating project:', error);
      toast.error('Failed to update project. Please try again.');
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
    setFormData(project);
  };

  const handleSoftDelete = async () => {
    const confirmDelete = window.confirm('Are you sure you want to delete this project?');
    if (confirmDelete) {
      try {
        await api.delete(`projects/${projectId}/`, configureRequest());
        toast.success('Project has been successfully deleted.');
        navigate('/projects');
      } catch (error) {
        console.error('Error deleting project:', error);
        toast.error('Failed to delete project. Please try again.');
      }
    }
  };

  const handleSettingsClick = () => {
    navigate(`settings`);
  };

  if (isLoading) return <p>Loading project details...</p>;

  if (error) {
    return (
      <Alert>
        <AlertDescription>
          {error}
        </AlertDescription>
      </Alert>
    );
  }

  if (!project) {
    return (
      <Alert>
        <AlertDescription>
          Project not found. It may have been deleted or you don't have access to it.
        </AlertDescription>
      </Alert>
    );
  }

  if (!canAccessProjectOverview) {
    return (
      <Alert>
        <AlertDescription>
          Please upgrade your subscription to access project management features.
          <a href="/subscription" className="text-blue-600 hover:text-blue-800 ml-2">
            Upgrade now
          </a>
        </AlertDescription>
      </Alert>
    );
  }

  const metricsConfig = [
    {
      title: "Total Hours",
      value: `${metrics.totalHours.toLocaleString()} hrs`,
      icon: ClockIcon,
      color: "text-blue-600",
      requires: true
    },
    {
      title: "Budget",
      value: `${metrics.actualAmount.toLocaleString()} / ${metrics.budgetedAmount.toLocaleString()}`,
      icon: CurrencyDollarIcon,
      color: "text-green-600",
      requires: canAccessProjectOverview,
      chart: (
        <LineChart data={[
          { name: 'Budgeted', value: metrics.budgetedAmount },
          { name: 'Actual', value: metrics.actualAmount }
        ]}>
          <XAxis dataKey="name" hide />
          <YAxis hide />
          <Tooltip />
          <Line type="monotone" dataKey="value" stroke="#10B981" strokeWidth={2} />
        </LineChart>
      )
    },
    {
      title: "Pending Approvals",
      value: metrics.pendingApprovals,
      icon: ClipboardDocumentCheckIcon,
      color: "text-yellow-600"
    },
    {
      title: "Tasks Completed",
      value: `${metrics.tasksCompleted}%`,
      icon: ChartBarIcon,
      color: "text-purple-600",
      chart: (
        <LineChart data={metrics.tasksTrend}>
          <XAxis dataKey="date" hide />
          <YAxis hide />
          <Tooltip />
          <Line type="monotone" dataKey="completed" stroke="#8B5CF6" strokeWidth={2} />
        </LineChart>
      )
    },
    {
      title: "Tasks",
      value: metrics.taskCount,
      icon: ClipboardDocumentCheckIcon,
      color: "text-red-600"
    },
    {
      title: "RFIs",
      value: metrics.rfiCount,
      icon: DocumentTextIcon,
      color: "text-indigo-600"
    }
  ];

  const MetricCard = ({ title, value, icon: Icon, color, chart }) => (
    <div className={`bg-white p-3 rounded-lg shadow-sm ${color}`}>
      <div className="flex items-center justify-between mb-1">
        <div>
          <p className="text-xs text-gray-500">{title}</p>
          <p className="text-lg font-semibold">{value}</p>
        </div>
        <Icon className="h-5 w-5 text-gray-400" />
      </div>
      {chart && (
        <div className="h-12">
          <ResponsiveContainer width="100%" height="100%">
            {chart}
          </ResponsiveContainer>
        </div>
      )}
    </div>
  );

  return (
    <div className="bg-gray-100 min-h-screen">

      <header className="bg-white shadow-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-2 flex justify-between items-center">
          <div className="flex items-center flex-1 min-w-0">
            <Link to="/projects" className="mr-2 text-gray-500 hover:text-gray-700">
              <ArrowLeftIcon className="h-5 w-5" />
            </Link>
            <h1 className="text-lg sm:text-xl font-bold text-gray-900 truncate">{project.name}</h1>
          </div>
          <div className="flex items-center space-x-2">
            <button className="text-gray-500 hover:text-gray-700 p-1.5 hover:bg-gray-100 rounded-full">
              <BellIcon className="h-5 w-5" />
            </button>
            <button className="text-gray-500 hover:text-gray-700 p-1.5 hover:bg-gray-100 rounded-full md:block hidden">
              <MagnifyingGlassIcon className="h-5 w-5" />
            </button>
            <button
              className="p-1.5 hover:bg-gray-100 rounded-full text-gray-500 hover:text-gray-700 sm:hidden"
              onClick={handleSettingsClick}
            >
              <CogIcon className="h-5 w-5" />
            </button>
            <button
              className={`${primaryButtonClasses} hidden sm:flex`}
              onClick={handleSettingsClick}
            >
              <CogIcon className="h-4 w-4" />
              <span className="hidden md:inline">Settings</span>
            </button>
          </div>
        </div>
      </header>

      {/* Responsive main content */}
      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4 sm:py-6">
        {/* AI Insight card - collapsed on mobile */}
        <div className="bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-2 sm:p-3 mb-4 sm:mb-6 rounded-r-lg flex items-start">
          <LightBulbIcon className="h-5 w-5 mr-2 flex-shrink-0 mt-0.5" />
          <p className="text-sm"><strong className="sm:inline hidden">AI Insight:</strong> {aiInsight || "No insights available yet. Check back soon for AI-powered recommendations for this project."}</p>
        </div>

        {/* Responsive metric cards grid */}
        {/*  <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-3 gap-2 sm:gap-4 mb-4 sm:mb-6">
          {metricsConfig
            .filter(metric => metric.requires)
            .map((metric, index) => (
              <MetricCard
                key={index}
                title={metric.title}
                value={metric.value}
                icon={metric.icon}
                color={metric.color}
                chart={metric.chart}
              />
            ))}
        </div> */}

        <div className="bg-white rounded-lg shadow-sm p-3 sm:p-4">
          {/* Mobile-responsive tabs with fixed "more" menu */}
          <div className="flex justify-between items-center mb-3">
            <div className="flex border-b overflow-x-auto hide-scrollbar" ref={tabContainerRef}>
              {visibleTabs.map(tab => (
                <button
                  key={tab.id}
                  className={`
                    mr-3 py-2 px-2 sm:px-4 flex items-center space-x-1 sm:space-x-2 whitespace-nowrap
                    ${activeTab === tab.id ? 'border-b-2 border-brand-blue text-brand-blue' : 'text-gray-500'}
                  `}
                  onClick={() => setActiveTab(tab.id)}
                >
                  <tab.icon className="w-4 h-4" />
                  <span className="text-sm sm:text-base">{tab.label}</span>
                </button>
              ))}
              {/* More menu button with centered modal popup */}
              {overflowTabs.length > 0 && (
                <>
                  <button
                    type="button"
                    className={`
        mr-3 py-2 px-2 sm:px-4 flex items-center space-x-1 sm:space-x-2 whitespace-nowrap
        ${overflowTabs.some(tab => activeTab === tab.id) ? 'border-b-2 border-brand-blue text-brand-blue' : 'text-gray-500'}
      `}
                    onClick={() => setShowMoreMenu(true)}
                  >
                    <MoreHorizontal className="w-4 h-4" />
                    <span className="text-sm sm:text-base">More</span>
                  </button>

                  {/* Centered Modal Popup */}
                  {showMoreMenu && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                      <div className="bg-white rounded-lg shadow-xl w-64 max-w-md mx-4">
                        <div className="flex justify-between items-center p-4 border-b">
                          <h3 className="text-lg font-medium">More Options</h3>
                          <button
                            onClick={() => setShowMoreMenu(false)}
                            className="text-gray-400 hover:text-gray-500"
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                            </svg>
                          </button>
                        </div>
                        <div className="p-2">
                          {overflowTabs.map(tab => (
                            <button
                              key={tab.id}
                              className={`
                  w-full text-left px-4 py-3 my-1 rounded flex items-center hover:bg-gray-100
                  ${activeTab === tab.id ? 'bg-gray-100 text-brand-blue' : 'text-gray-700'}
                `}
                              onClick={() => {
                                setActiveTab(tab.id);
                                setShowMoreMenu(false);
                              }}
                            >
                              <tab.icon className="w-5 h-5 mr-3" />
                              <span className="text-base">{tab.label}</span>
                            </button>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>

            {/* Action buttons - responsive layout */}
            {activeTab === 'overview' && canAccessProjectOverview && (
              <div className="flex items-center space-x-2">
                <button
                  onClick={() => setIsEditing(true)}
                  className={`${primaryButtonClasses} py-1 px-3 sm:px-6 sm:py-2 text-sm`}
                >
                  <PencilIcon className="h-4 w-4" />
                  <span className="hidden sm:inline ml-1">Edit</span>
                </button>
                <button
                  onClick={handleSoftDelete}
                  className="bg-red-500 text-white rounded-full px-3 py-1 sm:px-6 sm:py-2 shadow-md transition transform hover:scale-105 hover:bg-red-600 flex items-center gap-1 sm:gap-2 text-sm"
                >
                  <TrashIcon className="h-4 w-4" />
                  <span className="hidden sm:inline">Delete</span>
                </button>
              </div>
            )}
          </div>

          {/* Tab content */}
          {activeTab === 'overview' && (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-3 sm:gap-4">
              {isEditing ? (
                <>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-brand-blue"
                    placeholder="Project Name"
                  />
                  <input
                    type="text"
                    name="location"
                    value={formData.location}
                    onChange={handleInputChange}
                    className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-brand-blue"
                    placeholder="Location"
                  />
                  <select
                    name="status"
                    value={formData.status}
                    onChange={handleInputChange}
                    className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-brand-blue"
                  >
                    <option value="In Progress">In Progress</option>
                    <option value="Completed">Completed</option>
                    <option value="Pending">Pending</option>
                  </select>
                  <input
                    type="date"
                    name="start_date"
                    value={formData.start_date}
                    onChange={handleInputChange}
                    className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-brand-blue"
                  />
                  <input
                    type="date"
                    name="end_date"
                    value={formData.end_date}
                    onChange={handleInputChange}
                    className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-brand-blue"
                  />
                  <input
                    type="number"
                    name="budget"
                    value={formData.budget}
                    onChange={handleInputChange}
                    className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-brand-blue"
                    placeholder="Budget"
                  />
                  <textarea
                    name="description"
                    value={formData.description}
                    onChange={handleInputChange}
                    className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-brand-blue col-span-1 md:col-span-2"
                    placeholder="Description"
                    rows="4"
                  />
                  <div className="col-span-1 md:col-span-2 flex items-center space-x-2">
                    <button
                      onClick={handleUpdate}
                      className={primaryButtonClasses}
                    >
                      Save
                    </button>
                    <button
                      onClick={handleCancel}
                      className={secondaryButtonClasses}
                    >
                      Cancel
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div className="bg-gray-50 p-3 sm:p-4 rounded-lg">
                    <h3 className="font-semibold text-gray-700 text-sm">Name</h3>
                    <p className="break-words">{project.name}</p>
                  </div>
                  <div className="bg-gray-50 p-3 sm:p-4 rounded-lg">
                    <h3 className="font-semibold text-gray-700 text-sm">Location</h3>
                    <p className="break-words">{project.location}</p>
                  </div>
                  <div className="bg-gray-50 p-3 sm:p-4 rounded-lg">
                    <h3 className="font-semibold text-gray-700 text-sm">Status</h3>
                    <p>{project.status}</p>
                  </div>
                  <div className="bg-gray-50 p-3 sm:p-4 rounded-lg">
                    <h3 className="font-semibold text-gray-700 text-sm">Start Date</h3>
                    <p>{project.start_date}</p>
                  </div>
                  <div className="bg-gray-50 p-3 sm:p-4 rounded-lg">
                    <h3 className="font-semibold text-gray-700 text-sm">End Date</h3>
                    <p>{project.end_date}</p>
                  </div>
                  <div className="bg-gray-50 p-3 sm:p-4 rounded-lg">
                    <h3 className="font-semibold text-gray-700 text-sm">Budget</h3>
                    <p>${project.budget}</p>
                  </div>
                  <div className="bg-gray-50 p-3 sm:p-4 rounded-lg col-span-1 md:col-span-2">
                    <h3 className="font-semibold text-gray-700 text-sm">Description</h3>
                    <p className="break-words">{project.description}</p>
                  </div>
                </>
              )}
            </div>
          )}
          {activeTab === 'timeline' && (
            <div>
              <ProjectTimeline />
            </div>
          )}
          {activeTab === 'budget' && (
            <ErrorBoundary fallback={
              <Alert>
                <AlertDescription>
                  Unable to load budget information. Please check your permissions or try again later.
                </AlertDescription>
              </Alert>
            }>
              <BudgetList
                projectId={projectId}
                configureRequest={configureRequest}
              />
            </ErrorBoundary>
          )}

          <div className="space-y-4 sm:space-y-6">
            {/* Schedule Overview Cards */}
            {activeTab === 'schedule' && (
              <ErrorBoundary fallback={
                <Alert>
                  <AlertDescription>
                    Unable to load schedule. Please check your permissions or try again later.
                  </AlertDescription>
                </Alert>
              }>
                <ScheduleController
                  projectId={projectId}
                  scheduleType="project_schedule"
                  hasAdvancedAccess={true}
                  configureRequest={configureRequest}
                />
              </ErrorBoundary>
            )}
          </div>
          {activeTab === 'risks' && tabPermissions.risks && (
            <ErrorBoundary fallback={
              <Alert>
                <AlertDescription>
                  Unable to load risks. Please check your permissions or try again later.
                </AlertDescription>
              </Alert>
            }>
              <RiskManagementDashboard
                projectId={projectId}
                configureRequest={configureRequest}
              />
            </ErrorBoundary>
          )}


          {activeTab === 'tasks' && tabPermissions.tasks && (
            <ErrorBoundary fallback={
              <Alert>
                <AlertDescription>
                  Unable to load tasks. Please check your permissions or try again later.
                </AlertDescription>
              </Alert>
            }>
              <TaskBoard
                projectId={projectId}
                configureRequest={configureRequest}
              />
            </ErrorBoundary>
          )}

          {activeTab === 'tasks' && !tabPermissions.tasks && (
            <Alert>
              <AlertDescription>
                You don't have permission to access tasks for this project.
              </AlertDescription>
            </Alert>
          )}

          {activeTab === 'defects' && (
            <ErrorBoundary fallback={
              <Alert>
                <AlertDescription>
                  Unable to load defects. Please check your permissions or try again later.
                </AlertDescription>
              </Alert>
            }>
              {/* <ProjectQualityManagement projectId={projectId} configureRequest={configureRequest} /> */}
              <QualityIssuesManager projectId={projectId} configureRequest={configureRequest} />
            </ErrorBoundary>
          )}

          {activeTab === 'inspections' && (
            <ErrorBoundary fallback={
              <Alert>
                <AlertDescription>
                  Unable to load inspections. Please check your permissions or try again later.
                </AlertDescription>
              </Alert>
            }>
              <InspectionManager projectId={projectId} configureRequest={configureRequest} />
            </ErrorBoundary>
          )}

          {activeTab === 'rfis' && (
            <ErrorBoundary fallback={
              <Alert>
                <AlertDescription>
                  Unable to load RFIs. Please check your permissions or try again later.
                </AlertDescription>
              </Alert>
            }>
              {tabPermissions.rfis ? (
                <RFITable
                  projectId={projectId}
                  configureRequest={configureRequest}
                />
              ) : (
                <Alert>
                  <AlertDescription>
                    You don't have permission to access RFIs for this project.
                  </AlertDescription>
                </Alert>
              )}
            </ErrorBoundary>
          )}
        </div>
      </main >

      {/* Add some custom styles for mobile */}
      < style jsx > {`
        /* Hide scrollbar but allow scrolling */
        .hide-scrollbar {
          -ms-overflow-style: none;  /* IE and Edge */
          scrollbar-width: none;  /* Firefox */
        }
        .hide-scrollbar::-webkit-scrollbar {
          display: none;  /* Chrome, Safari and Opera */
        }
      `}</style >
    </div >
  );
}
export default ProjectDetail;