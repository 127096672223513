import React from 'react';
import { Label } from "../../../components/ui/label";
import { Input } from "../../../components/ui/input";
import { Textarea } from "../../../components/ui/textarea";
import { DollarSign } from 'lucide-react';
import { cn } from '../../../lib/utils';

const TradeCostEstimates = ({ trade, estimate, onChange, error }) => {
    return (
        <div className="space-y-4 p-4 border rounded-lg mt-2">
            <div>
                <Label>Estimated Cost for {trade.name}</Label>
                <div className="relative">
                    <DollarSign className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-gray-500" />
                    <Input
                        type="number"
                        placeholder="Enter estimated cost"
                        className={cn(
                            "pl-8",
                            error && "border-red-500"
                        )}
                        value={estimate?.estimated_cost || ''}
                        onChange={(e) => {
                            const value = e.target.value;
                            // Ensure we're always passing a number or empty string
                            onChange({
                                ...estimate,
                                estimated_cost: value ? parseFloat(value) : ''
                            });
                        }}
                        min="0"
                        step="0.01"
                        required
                    />
                </div>
                {error && (
                    <p className="text-sm text-red-500 mt-1">{error}</p>
                )}
            </div>

            <div>
                <Label>Notes (Optional)</Label>
                <Textarea
                    placeholder="Add any notes about the estimate"
                    value={estimate?.notes || ''}
                    onChange={(e) => onChange({
                        ...estimate,
                        notes: e.target.value
                    })}
                />
            </div>
        </div>
    );
};

export default TradeCostEstimates;