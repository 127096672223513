import React, { useState, useEffect } from 'react';
import { Button } from '../components/ui/button';
import { Input } from '../components/ui/input';
import { Card, CardHeader, CardTitle, CardContent } from '../components/ui/cardui';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../components/ui/tabs';
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogFooter
} from '../components/ui/dialog';
import { useToast } from '../components/ui/use-toast';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Plus, Trash2, Save, Edit, Clipboard } from 'lucide-react';
import api from '../services/api';
import LoadingSpinner from '../components/common/LoadingSpinner';

const TemplateManagementView = ({ project }) => {
    const [templates, setTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const { toast } = useToast();

    // Confirmation dialog state
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [confirmAction, setConfirmAction] = useState(null);
    const [confirmMessage, setConfirmMessage] = useState('');

    useEffect(() => {
        fetchTemplates();
    }, []);



    const fetchTemplates = async () => {
        try {
            setIsLoading(true);
            const response = await api.get(`projects/${project.id}/safety-audit-templates/`);

            // Debug the response
            console.log('API response:', response.data);

            // Ensure we have an array of templates
            let templatesData;

            if (Array.isArray(response.data)) {
                templatesData = response.data;
            } else if (response.data && response.data.results && Array.isArray(response.data.results)) {
                templatesData = response.data.results;
            } else if (response.data && response.data.id) {
                // Single object with ID, wrap in array
                templatesData = [response.data];
            } else {
                // Fallback to empty array
                templatesData = [];
            }

            setTemplates(templatesData);
            if (templatesData.length > 0) {
                setSelectedTemplate(templatesData[0]);
            }
        } catch (error) {
            console.error('Error loading templates:', error);
            toast({
                title: "Error",
                description: "Failed to load templates",
                variant: "destructive"
            });
            // Initialize with empty array to prevent further errors
            setTemplates([]);
        } finally {
            setIsLoading(false);
        }
    };


    const getReadableTemplateName = (template) => {
        // Handle null/undefined template
        if (!template) return 'Unnamed Template';

        // If template has a description that follows our pattern, use that
        if (template.description) {
            const match = template.description.match(/template for (.*?)(?:$|\s*Template)/i);
            if (match && match[1]) {
                return match[1] + " Template";
            }
        }

        // If template has a name that includes underscore, extract the meaningful part
        if (template.name && template.name.includes('_')) {
            const nameParts = template.name.split('_');
            // Check if first part looks like a UUID
            if (/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i.test(nameParts[0]) ||
                /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}/i.test(nameParts[0])) {
                // Remove the UUID part and format the remainder
                return nameParts.slice(1).join(' ')
                    .replace(/_/g, ' ') // Replace underscores with spaces
                    .split(' ')
                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(' ');
            } else {
                // Name has underscores but first part is not UUID
                return template.name.replace(/_/g, ' ');
            }
        }

        // If template has a name without underscores, use it
        if (template.name) return template.name;

        // If we've reached here, fallback to the template ID
        if (template.id) {
            return "Template " + template.id.substring(0, 8);
        }

        return "Unnamed Template";
    };





    const handleTemplateSelect = async (templateId) => {
        try {
            const response = await api.get(`projects/${project.id}/safety-audit-templates/${templateId}/`);
            setSelectedTemplate(response.data);
        } catch (error) {
            console.error('Error loading template details:', error);
            toast({
                title: "Error",
                description: "Failed to load template details",
                variant: "destructive"
            });
        }
    };

    const handleCategoryDrop = async (result) => {
        if (!result.destination || !selectedTemplate || !selectedTemplate.categories) return;

        const items = Array.from(selectedTemplate.categories);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        // Update order values
        const updatedItems = items.map((item, index) => ({
            ...item,
            order: index + 1
        }));

        setSelectedTemplate({ ...selectedTemplate, categories: updatedItems });

        // Save the new order to the backend
        try {
            for (const category of updatedItems) {
                await api.patch(
                    `projects/${project.id}/safety-audit-templates/${selectedTemplate.id}/categories/${category.id}/`,
                    { order: category.order }
                );
            }

            toast({
                title: "Success",
                description: "Category order updated"
            });
        } catch (error) {
            console.error('Error updating category order:', error);
            toast({
                title: "Error",
                description: "Failed to update category order",
                variant: "destructive"
            });
        }
    };

    const handleItemDrop = async (categoryId, result) => {
        if (!result.destination || !selectedTemplate || !selectedTemplate.categories) return;

        const categoryIndex = selectedTemplate.categories.findIndex(c => c.id === categoryId);
        if (categoryIndex === -1) return;

        const category = selectedTemplate.categories[categoryIndex];
        if (!category.items) return;

        const items = Array.from(category.items);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        // Update order values
        const updatedItems = items.map((item, index) => ({
            ...item,
            order: index + 1
        }));

        const updatedCategories = [...selectedTemplate.categories];
        updatedCategories[categoryIndex] = {
            ...updatedCategories[categoryIndex],
            items: updatedItems
        };

        setSelectedTemplate({ ...selectedTemplate, categories: updatedCategories });

        // Save the new order to the backend
        try {
            for (const item of updatedItems) {
                await api.patch(
                    `projects/${project.id}/safety-audit-templates/${selectedTemplate.id}/categories/${categoryId}/items/${item.id}/`,
                    { order: item.order }
                );
            }

            toast({
                title: "Success",
                description: "Item order updated"
            });
        } catch (error) {
            console.error('Error updating item order:', error);
            toast({
                title: "Error",
                description: "Failed to update item order",
                variant: "destructive"
            });
        }
    };

    // Confirmation dialog helper
    const showConfirmation = (message, action) => {
        setConfirmMessage(message);
        setConfirmAction(() => action);
        setShowConfirmDialog(true);
    };

    const addCategory = async () => {
        if (!selectedTemplate) return;

        try {
            const categoryCount = selectedTemplate.categories ? selectedTemplate.categories.length : 0;

            const response = await api.post(
                `projects/${project.id}/safety-audit-templates/${selectedTemplate.id}/categories/`,
                {
                    name: "New Category",
                    description: "",
                    order: categoryCount + 1
                }
            );

            // Update the selected template with the new category
            const existingCategories = selectedTemplate.categories || [];

            setSelectedTemplate({
                ...selectedTemplate,
                categories: [...existingCategories, response.data]
            });

            toast({
                title: "Success",
                description: "Category added"
            });
        } catch (error) {
            console.error('Error adding category:', error);
            toast({
                title: "Error",
                description: "Failed to add category",
                variant: "destructive"
            });
        }
    };

    const updateCategory = async (categoryId, data) => {
        if (!selectedTemplate || !selectedTemplate.categories) return;

        try {
            const response = await api.patch(
                `projects/${project.id}/safety-audit-templates/${selectedTemplate.id}/categories/${categoryId}/`,
                data
            );

            // Update the selected template with the updated category
            const updatedCategories = selectedTemplate.categories.map(category =>
                category.id === categoryId ? response.data : category
            );

            setSelectedTemplate({
                ...selectedTemplate,
                categories: updatedCategories
            });

            toast({
                title: "Success",
                description: "Category updated"
            });
        } catch (error) {
            console.error('Error updating category:', error);
            toast({
                title: "Error",
                description: "Failed to update category",
                variant: "destructive"
            });
        }
    };



    const addItem = async (categoryId) => {
        if (!selectedTemplate || !selectedTemplate.categories) return;

        try {
            const categoryIndex = selectedTemplate.categories.findIndex(c => c.id === categoryId);
            if (categoryIndex === -1) return;

            const category = selectedTemplate.categories[categoryIndex];
            const items = category.items || [];
            const itemOrder = items.length + 1;

            const response = await api.post(
                `projects/${project.id}/safety-audit-templates/${selectedTemplate.id}/categories/${categoryId}/items/`,
                {
                    description: "New item",
                    guidance_notes: "",
                    order: itemOrder
                }
            );

            // Update the selected template with the new item
            const updatedCategories = [...selectedTemplate.categories];
            updatedCategories[categoryIndex] = {
                ...category,
                items: [...items, response.data]
            };

            setSelectedTemplate({
                ...selectedTemplate,
                categories: updatedCategories
            });

            toast({
                title: "Success",
                description: "Item added"
            });
        } catch (error) {
            console.error('Error adding item:', error);
            toast({
                title: "Error",
                description: "Failed to add item",
                variant: "destructive"
            });
        }
    };

    const updateItem = async (categoryId, itemId, data) => {
        if (!selectedTemplate || !selectedTemplate.categories) return;

        try {
            const response = await api.patch(
                `projects/${project.id}/safety-audit-templates/${selectedTemplate.id}/categories/${categoryId}/items/${itemId}/`,
                data
            );

            // Update the selected template with the updated item
            const categoryIndex = selectedTemplate.categories.findIndex(c => c.id === categoryId);
            if (categoryIndex === -1) return;

            const category = selectedTemplate.categories[categoryIndex];
            if (!category.items) return;

            const updatedItems = category.items.map(item =>
                item.id === itemId ? response.data : item
            );

            const updatedCategories = [...selectedTemplate.categories];
            updatedCategories[categoryIndex] = {
                ...category,
                items: updatedItems
            };

            setSelectedTemplate({
                ...selectedTemplate,
                categories: updatedCategories
            });

            toast({
                title: "Success",
                description: "Item updated"
            });
        } catch (error) {
            console.error('Error updating item:', error);
            toast({
                title: "Error",
                description: "Failed to update item",
                variant: "destructive"
            });
        }
    };

    // Update the deleteCategory function
    const deleteCategory = async (categoryId) => {
        if (!selectedTemplate || !selectedTemplate.categories) return;

        showConfirmation(
            "Are you sure you want to delete this category? All items within it will also be deleted.",
            async () => {
                try {
                    await api.delete(
                        `projects/${project.id}/safety-audit-templates/${selectedTemplate.id}/categories/${categoryId}/`
                    );

                    // Immediately update the local state to reflect the deletion
                    const updatedCategories = selectedTemplate.categories.filter(category =>
                        category.id !== categoryId
                    );

                    setSelectedTemplate({
                        ...selectedTemplate,
                        categories: updatedCategories
                    });

                    // Then refetch the template to ensure we have fresh data from the server
                    await fetchTemplateDetails(selectedTemplate.id);

                    toast({
                        title: "Success",
                        description: "Category deleted"
                    });
                } catch (error) {
                    console.error('Error deleting category:', error);
                    toast({
                        title: "Error",
                        description: "Failed to delete category",
                        variant: "destructive"
                    });

                    // On error, refresh the data to ensure consistent state
                    await fetchTemplateDetails(selectedTemplate.id);
                }
            }
        );
    };

    // Add a fetchTemplateDetails function
    const fetchTemplateDetails = async (templateId) => {
        try {
            const response = await api.get(`projects/${project.id}/safety-audit-templates/${templateId}/`);
            setSelectedTemplate(response.data);
        } catch (error) {
            console.error('Error refreshing template details:', error);
            toast({
                title: "Error",
                description: "Failed to refresh template data",
                variant: "destructive"
            });
        }
    };

    // Similarly update the deleteItem function
    const deleteItem = async (categoryId, itemId) => {
        if (!selectedTemplate || !selectedTemplate.categories) return;

        showConfirmation(
            "Are you sure you want to delete this item?",
            async () => {
                try {
                    await api.delete(
                        `projects/${project.id}/safety-audit-templates/${selectedTemplate.id}/categories/${categoryId}/items/${itemId}/`
                    );

                    // Immediately update the local state to reflect the deletion
                    const categoryIndex = selectedTemplate.categories.findIndex(c => c.id === categoryId);
                    if (categoryIndex === -1) return;

                    const category = selectedTemplate.categories[categoryIndex];
                    if (!category.items) return;

                    const updatedItems = category.items.filter(item => item.id !== itemId);

                    const updatedCategories = [...selectedTemplate.categories];
                    updatedCategories[categoryIndex] = {
                        ...category,
                        items: updatedItems
                    };

                    setSelectedTemplate({
                        ...selectedTemplate,
                        categories: updatedCategories
                    });

                    // Then refetch the template to ensure we have fresh data from the server
                    await fetchTemplateDetails(selectedTemplate.id);

                    toast({
                        title: "Success",
                        description: "Item deleted"
                    });
                } catch (error) {
                    console.error('Error deleting item:', error);
                    toast({
                        title: "Error",
                        description: "Failed to delete item",
                        variant: "destructive"
                    });

                    // On error, refresh the data to ensure consistent state
                    await fetchTemplateDetails(selectedTemplate.id);
                }
            }
        );
    };

    if (isLoading) {
        return <LoadingSpinner message="Loading templates..." />;
    }

    return (
        <div className="container mx-auto py-6">
            <h1 className="text-2xl font-bold mb-6">Audit Template Management</h1>

            <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
                <div className="md:col-span-1">
                    <Card>
                        <CardHeader>
                            <CardTitle>Templates</CardTitle>
                        </CardHeader>
                        <CardContent>
                            <ul className="space-y-2">
                                {Array.isArray(templates) && templates.length > 0 ? (
                                    templates.map(template => (
                                        <li key={template.id}>
                                            <Button
                                                variant={selectedTemplate?.id === template.id ? "default" : "outline"}
                                                className="w-full text-left justify-start truncate"
                                                onClick={() => handleTemplateSelect(template.id)}
                                            >
                                                {getReadableTemplateName(template)}

                                            </Button>
                                        </li>
                                    ))
                                ) : (
                                    <li className="text-center py-4 text-gray-500">No templates available</li>
                                )}
                            </ul>
                        </CardContent>
                    </Card>
                </div>

                <div className="md:col-span-3">
                    {selectedTemplate && (
                        <Card>
                            <CardHeader>
                                <CardTitle>{selectedTemplate.id && selectedTemplate.id.includes('_') ?
                                    selectedTemplate.id.split('_').slice(1).join(' ') :
                                    getReadableTemplateName(selectedTemplate)}</CardTitle>
                                <p className="text-sm text-gray-500">{selectedTemplate.description || "Auto-generated template"}</p>
                            </CardHeader>
                            <CardContent>
                                <Tabs defaultValue="categories">
                                    <TabsList>
                                        <TabsTrigger value="categories">Categories</TabsTrigger>
                                        <TabsTrigger value="settings">Template Settings</TabsTrigger>
                                    </TabsList>

                                    <TabsContent value="categories" className="mt-4">
                                        <div className="flex justify-end mb-4">
                                            <Button onClick={addCategory}>
                                                <Plus className="w-4 h-4 mr-2" />
                                                Add Category
                                            </Button>
                                        </div>

                                        {selectedTemplate.categories && selectedTemplate.categories.length > 0 ? (
                                            <DragDropContext onDragEnd={handleCategoryDrop}>
                                                <Droppable droppableId="categories">
                                                    {(provided) => (
                                                        <div
                                                            {...provided.droppableProps}
                                                            ref={provided.innerRef}
                                                            className="space-y-6"
                                                        >
                                                            {selectedTemplate.categories.map((category, index) => (
                                                                <Draggable
                                                                    key={category.id}
                                                                    draggableId={category.id}
                                                                    index={index}
                                                                >
                                                                    {(provided) => (
                                                                        <div
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                            className="border rounded-lg p-4"
                                                                        >
                                                                            <CategoryEditor
                                                                                category={category}
                                                                                onUpdate={(data) => updateCategory(category.id, data)}
                                                                                onDelete={() => deleteCategory(category.id)}
                                                                                onAddItem={() => addItem(category.id)}
                                                                                onUpdateItem={(itemId, data) => updateItem(category.id, itemId, data)}
                                                                                onDeleteItem={(itemId) => deleteItem(category.id, itemId)}
                                                                                onItemDragEnd={(result) => handleItemDrop(category.id, result)}
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </Draggable>
                                                            ))}
                                                            {provided.placeholder}
                                                        </div>
                                                    )}
                                                </Droppable>
                                            </DragDropContext>
                                        ) : (
                                            <div className="text-center p-8 border rounded-lg bg-gray-50">
                                                <p className="text-gray-500 mb-4">No categories found for this template</p>
                                                <Button onClick={addCategory} variant="outline">
                                                    <Plus className="w-4 h-4 mr-2" />
                                                    Add First Category
                                                </Button>
                                            </div>
                                        )}
                                    </TabsContent>

                                    <TabsContent value="settings">
                                        <div className="space-y-4">
                                            <div>
                                                <label className="block text-sm font-medium mb-1">
                                                    Template Name
                                                </label>
                                                <Input
                                                    value={selectedTemplate.name || getReadableTemplateName(selectedTemplate)}
                                                    onChange={(e) => setSelectedTemplate({
                                                        ...selectedTemplate,
                                                        name: e.target.value
                                                    })}
                                                />
                                            </div>

                                            <div>
                                                <label className="block text-sm font-medium mb-1">
                                                    Description
                                                </label>
                                                <Input
                                                    value={selectedTemplate.description || ''}
                                                    onChange={(e) => setSelectedTemplate({
                                                        ...selectedTemplate,
                                                        description: e.target.value
                                                    })}
                                                />
                                            </div>

                                            <Button
                                                onClick={async () => {
                                                    try {
                                                        await api.patch(
                                                            `/projects/${project.id}/safety-audit-templates/${selectedTemplate.id}/`,
                                                            {
                                                                name: selectedTemplate.name,
                                                                description: selectedTemplate.description
                                                            }
                                                        );

                                                        toast({
                                                            title: "Success",
                                                            description: "Template updated"
                                                        });
                                                    } catch (error) {
                                                        console.error('Error updating template:', error);
                                                        toast({
                                                            title: "Error",
                                                            description: "Failed to update template",
                                                            variant: "destructive"
                                                        });
                                                    }
                                                }}
                                            >
                                                <Save className="w-4 h-4 mr-2" />
                                                Save Template
                                            </Button>
                                        </div>
                                    </TabsContent>
                                </Tabs>
                            </CardContent>
                        </Card>
                    )}
                </div>
            </div>

            {/* Confirmation Dialog */}
            <Dialog open={showConfirmDialog} onOpenChange={setShowConfirmDialog}>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>Confirm Action</DialogTitle>
                    </DialogHeader>
                    <p>{confirmMessage}</p>
                    <DialogFooter>
                        <Button variant="outline" onClick={() => setShowConfirmDialog(false)}>
                            Cancel
                        </Button>
                        <Button onClick={() => {
                            setShowConfirmDialog(false);
                            if (confirmAction) confirmAction();
                        }}>
                            Confirm
                        </Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </div>
    );
};

// CategoryEditor component for editing a category and its items
const CategoryEditor = ({
    category,
    onUpdate,
    onDelete,
    onAddItem,
    onUpdateItem,
    onDeleteItem,
    onItemDragEnd
}) => {
    const [isEditing, setIsEditing] = useState(false);
    const [name, setName] = useState(category.name);
    const [description, setDescription] = useState(category.description || "");

    const handleSave = () => {
        onUpdate({ name, description });
        setIsEditing(false);
    };

    return (
        <div>
            {isEditing ? (
                <div className="space-y-4 mb-4">
                    <Input
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Category name"
                    />
                    <Input
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        placeholder="Description (optional)"
                    />
                    <div className="flex space-x-2">
                        <Button onClick={handleSave}>
                            <Save className="w-4 h-4 mr-2" />
                            Save
                        </Button>
                        <Button variant="outline" onClick={() => setIsEditing(false)}>
                            Cancel
                        </Button>
                    </div>
                </div>
            ) : (
                <div className="flex justify-between items-start mb-4">
                    <div>
                        <h3 className="text-lg font-bold">{category.name}</h3>
                        {category.description && (
                            <p className="text-sm text-gray-500">{category.description}</p>
                        )}
                    </div>
                    <div className="flex space-x-2">
                        <Button size="sm" variant="outline" onClick={() => setIsEditing(true)}>
                            <Edit className="w-4 h-4" />
                        </Button>
                        <Button size="sm" variant="outline" className="text-red-500" onClick={onDelete}>
                            <Trash2 className="w-4 h-4" />
                        </Button>
                    </div>
                </div>
            )}

            <div className="mb-4">
                <h4 className="text-md font-medium mb-2">Audit Items</h4>
                <Button size="sm" variant="outline" onClick={onAddItem}>
                    <Plus className="w-4 h-4 mr-2" />
                    Add Item
                </Button>
            </div>

            <DragDropContext onDragEnd={onItemDragEnd}>
                <Droppable droppableId={`items-${category.id}`}>
                    {(provided) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            className="space-y-3"
                        >
                            {category.items && category.items.length > 0 ? (
                                category.items.map((item, index) => (
                                    <Draggable
                                        key={item.id}
                                        draggableId={item.id}
                                        index={index}
                                    >
                                        {(provided) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                className="border rounded p-3 bg-gray-50"
                                            >
                                                <ItemEditor
                                                    item={item}
                                                    onUpdate={(data) => onUpdateItem(item.id, data)}
                                                    onDelete={() => onDeleteItem(item.id)}
                                                />
                                            </div>
                                        )}
                                    </Draggable>
                                ))
                            ) : (
                                <div className="text-center p-4 border rounded bg-gray-50">
                                    <p className="text-gray-500 mb-2">No items in this category</p>
                                    <Button size="sm" variant="ghost" onClick={onAddItem}>
                                        <Plus className="w-4 h-4 mr-1" />
                                        Add First Item
                                    </Button>
                                </div>
                            )}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </div>
    );
};

// ItemEditor component for editing an audit item
const ItemEditor = ({ item, onUpdate, onDelete }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [description, setDescription] = useState(item.description);
    const [guidanceNotes, setGuidanceNotes] = useState(item.guidance_notes || "");

    const handleSave = () => {
        onUpdate({ description, guidance_notes: guidanceNotes });
        setIsEditing(false);
    };

    return isEditing ? (
        <div className="space-y-3">
            <div>
                <label className="block text-sm font-medium mb-1">Description</label>
                <Input
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    placeholder="Item description"
                />
            </div>
            <div>
                <label className="block text-sm font-medium mb-1">Guidance Notes</label>
                <textarea
                    className="w-full p-2 border rounded"
                    value={guidanceNotes}
                    onChange={(e) => setGuidanceNotes(e.target.value)}
                    placeholder="Additional guidance for auditors"
                    rows={3}
                />
            </div>
            <div className="flex space-x-2">
                <Button size="sm" onClick={handleSave}>
                    <Save className="w-4 h-4 mr-2" />
                    Save
                </Button>
                <Button size="sm" variant="outline" onClick={() => setIsEditing(false)}>
                    Cancel
                </Button>
            </div>
        </div>
    ) : (
        <div className="flex justify-between items-start">
            <div>
                <p className="font-medium">{item.description}</p>
                {item.guidance_notes && (
                    <p className="text-sm text-gray-500">{item.guidance_notes}</p>
                )}
            </div>
            <div className="flex space-x-2">
                <Button size="sm" variant="ghost" onClick={() => setIsEditing(true)}>
                    <Edit className="w-4 h-4" />
                </Button>
                <Button size="sm" variant="ghost" className="text-red-500" onClick={onDelete}>
                    <Trash2 className="w-4 h-4" />
                </Button>
            </div>
        </div>
    );
};

export default TemplateManagementView;