import React from 'react';
import { Card, CardHeader, CardTitle, CardContent } from "../../../../components/ui/cardui";
import BidRFIPanel from '../BidRFIPanel';

const BidRFITab = ({ bidRequestId }) => {
    return (
        <Card>
            <CardHeader>
                <CardTitle className="text-lg sm:text-xl">RFI Management</CardTitle>
            </CardHeader>
            <CardContent className="p-0 sm:p-6">
                <BidRFIPanel bidRequestId={bidRequestId} isManager={true} />
            </CardContent>
        </Card>
    );
};

export default BidRFITab;