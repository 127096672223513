import React, { createContext, useContext, useState, useEffect } from 'react';
import api from '../services/api';

// Feature code constants
export const FEATURES = {
    PROJECT_MANAGEMENT: 'PROJECT',
    SAFETY_MANAGEMENT: 'SAFETY',
    BID_MANAGEMENT: 'BID',
    ALL_FEATURES: 'ALL'
};

const FeatureAccessContext = createContext();

export const FeatureAccessProvider = ({ children }) => {
    const [userRole, setUserRole] = useState(null);
    const [permissions, setPermissions] = useState([]);
    const [featureType, setFeatureType] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchUserAccess = async () => {
            const token = localStorage.getItem('token');
            if (!token) {
                setLoading(false);
                return;
            }

            try {
                // First get user profile
                const profileResponse = await api.get('users/profile/');
                // Set user role
                const roleFromProfile = profileResponse.data?.current_tenant?.role
                    || profileResponse.data.role_name;

                if (roleFromProfile) {
                    setUserRole(roleFromProfile);
                    localStorage.setItem('userRole', roleFromProfile);
                }

                // Set permissions
                if (profileResponse.data?.permissions) {
                    setPermissions(profileResponse.data.permissions);
                }

                // Get subscription info
                const subscriptionResponse = await api.get('subscription-debug/');

                // Try to get feature type from different possible locations
                let featureTypeCode = null;
                if (subscriptionResponse.data?.current_subscription?.plan?.feature_type?.code) {
                    featureTypeCode = subscriptionResponse.data.current_subscription.plan.feature_type.code;
                } else if (profileResponse.data?.feature_type) {
                    featureTypeCode = profileResponse.data.feature_type;
                } else if (roleFromProfile) {
                    // Map role to feature type if subscription doesn't provide it
                    const roleToFeature = {
                        'Tenant Admin Project': FEATURES.PROJECT_MANAGEMENT,
                        'Tenant Admin Safety': FEATURES.SAFETY_MANAGEMENT,
                        'Tenant Admin Bid': FEATURES.BID_MANAGEMENT,
                        'Tenant Admin': FEATURES.ALL_FEATURES
                    };
                    featureTypeCode = roleToFeature[roleFromProfile];
                }

                // Override featureTypeCode for Safety-specific roles
                if (isSafetyRole(roleFromProfile)) {
                    console.log(`Safety role detected (${roleFromProfile}), ensuring safety feature access`);
                    if (featureTypeCode !== FEATURES.SAFETY_MANAGEMENT && featureTypeCode !== FEATURES.ALL_FEATURES) {
                        featureTypeCode = FEATURES.SAFETY_MANAGEMENT;
                    }
                }

                if (featureTypeCode) {
                    setFeatureType(featureTypeCode);
                }

            } catch (err) {
                console.error('Error fetching user access:', err);
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchUserAccess();
    }, []);

    // Helper function to determine if a role is a safety-specific role
    const isSafetyRole = (role) => {
        const safetyRoles = [
            'Safety Manager',
            'Safety Officer',
            'Quality Control Officer',
            'Safety Director',
            'Safety Coordinator',
            'Chief Safety Officer',
            'Tenant Admin Safety'
        ];
        return safetyRoles.includes(role);
    };

    // Check if user has a specific permission
    const hasPermission = (permissionName) => {
        return permissions.includes(permissionName);
    };

    // Check if user has access to a specific feature
    const hasFeatureAccess = (featureCode) => {
        // All Features includes access to everything
        if (featureType === FEATURES.ALL_FEATURES) {
            return true;
        }

        // Specialized admin roles should have access to their specific feature
        if (userRole === 'Tenant Admin Project' && featureCode === FEATURES.PROJECT_MANAGEMENT) {
            return true;
        }
        if (userRole === 'Tenant Admin Safety' && featureCode === FEATURES.SAFETY_MANAGEMENT) {
            return true;
        }
        if (userRole === 'Tenant Admin Bid' && featureCode === FEATURES.BID_MANAGEMENT) {
            return true;
        }

        // Safety roles always have access to safety features
        if (isSafetyRole(userRole) && featureCode === FEATURES.SAFETY_MANAGEMENT) {
            return true;
        }

        // Specific feature check
        return featureType === featureCode;
    };

    // Check if user is a specialized admin
    const isSpecializedAdmin = () => {
        return [
            'Tenant Admin Project',
            'Tenant Admin Safety',
            'Tenant Admin Bid'
        ].includes(userRole);
    };

    // Check if user has access to quality features
    const canAccessQualityFeatures = () => {
        const qualityAccessRoles = [
            'Tenant Admin',
            'Tenant Admin Safety',
            'Tenant Admin Project',
            'Safety Manager',
            'Safety Officer',
            'Quality Control Officer',
            'Safety Director'
        ];

        return qualityAccessRoles.includes(userRole) &&
            (hasFeatureAccess(FEATURES.SAFETY_MANAGEMENT) || hasFeatureAccess(FEATURES.ALL_FEATURES));
    };

    return (
        <FeatureAccessContext.Provider
            value={{
                userRole,
                permissions,
                featureType,
                loading,
                error,
                hasPermission,
                hasFeatureAccess,
                isSpecializedAdmin,
                canAccessQualityFeatures,
                isSafetyRole,
                FEATURES
            }}
        >
            {children}
        </FeatureAccessContext.Provider>
    );
};

export const useFeatureAccess = () => {
    const context = useContext(FeatureAccessContext);
    if (!context) {
        throw new Error('useFeatureAccess must be used within FeatureAccessProvider');
    }
    return context;
};

// Component for conditional rendering based on feature access
export const FeatureGuard = ({ featureCode, fallback = null, children }) => {
    const { hasFeatureAccess, loading } = useFeatureAccess();

    if (loading) {
        return <div>Loading...</div>;
    }

    return hasFeatureAccess(featureCode) ? children : fallback;
};

// Component for conditional rendering based on permission
export const PermissionGuard = ({ permission, fallback = null, children }) => {
    const { hasPermission, loading } = useFeatureAccess();

    if (loading) {
        return <div>Loading...</div>;
    }

    return hasPermission(permission) ? children : fallback;
};

// Component for conditional rendering based on quality feature access
export const QualityFeatureGuard = ({ fallback = null, children }) => {
    const { canAccessQualityFeatures, loading } = useFeatureAccess();

    if (loading) {
        return <div>Loading...</div>;
    }

    return canAccessQualityFeatures() ? children : fallback;
};