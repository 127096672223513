import api from "./api";

const BidService = {
    // Bid Requests
    getBidRequests: () => api.get('bid-requests/'),

    getBidRequestDetails: async (bidId) => {
        try {
            const response = await api.get(`bid-requests/${bidId}/`);
            return response;
        } catch (error) {
            console.error('Error getting bid request:', error);
            throw error;
        }
    },

    createBidRequest: async (data) => {

        const tradeEstimates = data.required_trades.map(tradeId => ({
            trade_id: tradeId,
            estimated_cost: parseFloat(data.trade_estimates[tradeId]?.estimated_cost || 0),
            notes: data.trade_estimates[tradeId]?.notes || ''
        }));

        const requestData = {
            title: data.title,
            description: data.description,
            scope: data.scope,
            required_trades: data.required_trades,
            submission_deadline: data.submission_deadline,
            notify_subcontractors: data.notify_subcontractors,
            trade_estimates: tradeEstimates
        };

        const response = await api.post('bid-requests/', requestData, {
            headers: {
                'Content-Type': 'application/json',
            }
        });

        if (data.documents?.length) {

            for (const doc of data.documents) {
                console.log('Uploading document:', {
                    name: doc.name,
                    type: doc.type,
                    size: doc.size
                });

                if (!doc.type) {
                    console.error('Document type is missing for:', doc.name);
                    continue;
                }

                const docData = new FormData();
                docData.append('file', doc);
                docData.append('bid', response.data.id);
                docData.append('title', doc.name);
                docData.append('document_type', doc.type);
                docData.append('owner_type', 'request');

                try {
                    const uploadResponse = await BidService.uploadDocument(docData);
                } catch (error) {
                    console.error('Document upload failed:', error.response?.data || error);
                }
            }
        } else {
            console.log('No documents to upload');
        }

        return response;
    },

    uploadDocument: async (formData) => {

        try {
            const response = await api.post('bid-documents/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            return response;
        } catch (error) {
            console.error('Document upload error:', error.response?.data || error);
            throw error;
        }
    },

    deleteDocument: (documentId) => {
        return api.delete(`bid-documents/${documentId}/`);
    },

    updateBidRequest: async (id, data) => {
        try {

            // First update the bid request
            const response = await api.put(`bid-requests/${id}/`, data, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            // After successful bid update, handle invitations if notify_subcontractors is true
            if (data.notify_subcontractors) {
                try {
                    // Get existing invitations
                    const existingInvitationsResponse = await api.get(`bid-invitations/?bid_request=${id}`);
                    const existingInvitations = existingInvitationsResponse.data.results || existingInvitationsResponse.data;

                    // Get existing profile IDs
                    const existingProfileIds = new Set(
                        existingInvitations.map(inv => inv.profile.id)
                    );

                    // Get all selected profile IDs from the selectedProfiles data
                    const allSelectedProfileIds = Object.values(data.selectedProfiles || {}).flat();

                    // Filter to only get new profile IDs
                    const newProfileIds = allSelectedProfileIds.filter(id => !existingProfileIds.has(id));

                    if (newProfileIds.length > 0) {
                        // Create new invitations and send emails
                        await api.post('bid-invitations/bulk_create/', {
                            bid_request: id,
                            profile_ids: newProfileIds
                        });
                    }
                } catch (invitationError) {
                    console.error('Error handling invitations:', invitationError);
                    // Don't throw the error, just log it to prevent interrupting the update
                    console.error('Invitation error details:', invitationError.response?.data);
                }
            }

            return response;
        } catch (error) {
            console.error('Error updating bid request:', error);
            console.error('Error response:', error.response?.data);
            throw error;
        }
    },


    getBidSubmissions: (params) => api.get('bid-submissions/', { params }),
    getBidSubmissionsByRequest: (bidRequestId) => api.get('bid-submissions/', {
        params: { bid_request: bidRequestId }
    }),
    createBidSubmission: (data) => api.post('bid-submissions/', data),
    getBidSubmissionDetails: (id) => api.get(`bid-submissions/${id}/`),
    updateBidSubmission: (id, data) => api.put(`bid-submissions/${id}/`, data),
    deleteBidSubmission: (id) => api.delete(`bid-submissions/${id}/`),

    deleteBidRequest: (id) => api.delete(`bid-requests/${id}/`),
    // Templates
    getBidTemplates: () => api.get('bid-requests/templates/'),
    saveAsTemplate: (id) => api.post(`bid-requests/${id}/save-template/`),

    // Trades
    getTrades: async () => {
        try {
            const response = await api.get('construction-trades/');
            return { data: response.data.data || [] };
        } catch (error) {
            console.error('Trade fetch error:', error);
            throw error;
        }
    },

    getTradeDetails: (id) => api.get(`construction-trades/${id}/`),

    getBidExclusions: () => api.get('bid-exclusions/'),
    getBidDeliverables: () => api.get('bid-deliverables/'),
    createBidExclusion: (data) => api.post('bid-exclusions/', data),
    createBidDeliverable: (data) => api.post('bid-deliverables/', data),

    getProfiles: (params) => api.get('bid-profiles/', { params }),
    getProfileDetails: (id) => api.get(`bid-profiles/${id}/`),
    createProfile: async (formData) => {
        try {
            const response = await api.post('bid-profiles/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            return response;
        } catch (error) {
            // Log the actual error content for debugging
            console.error('Profile creation error:', error.response?.data || error);
            throw error;
        }
    },
    updateProfile: (id, data) => api.put(`bid-profiles/${id}/`, data),
    deleteProfile: (id) => api.delete(`bid-profiles/${id}/`),
    getProfilesByTrade: (tradeId) => {
        return api.get(`bid-profiles/?specialized_trades=${tradeId}`);
    },

    verifyProfile: (id) => api.post(`bid-profiles/${id}/verify/`),
    updateQualityRating: (id, rating) => api.post(`bid-profiles/${id}/update_quality_rating/`, { rating }),


    getBidInvitations: async (bidId) => {
        try {
            const response = await api.get(`bid-invitations/?bid_request=${bidId}`);
            return response;
        } catch (error) {
            console.error('Error getting bid invitations:', error);
            throw error;
        }
    },
    createBidInvitation: (data) => api.post('bid-invitations/', data),
    updateBidInvitation: (id, data) => api.put(`bid-invitations/${id}/`, data),
    deleteBidInvitation: (id) => api.delete(`bid-invitations/${id}/`),
    getBidInvitationsByRequest: async (bidRequestId) => {
        try {
            const response = await api.get('bid-invitations/', {
                params: {
                    bid_request: bidRequestId,
                    page_size: 100 // Adjust as needed
                }
            });
            return response;
        } catch (error) {
            console.error('Error fetching bid invitations:', error);
            // Return a safe default that matches the expected structure
            return { data: { results: [] } };
        }
    },

    createBulkInvitations: async (bidRequestId, profileIds) => {
        try {
            const response = await api.post('bid-invitations/bulk_create/', {
                bid_request: bidRequestId,
                profile_ids: profileIds
            });
            return response;
        } catch (error) {
            console.error('Error creating bulk invitations:', error);
            throw error;
        }
    },
    respondToInvitation: (id, status, notes) => api.post(`bid-invitations/${id}/respond/`, {
        status,
        notes
    }),
    getBidInvitationDetails: (id) => {
        return api.get(`bid-invitations/${id}/`);
    },

    getPendingInvitations: () => api.get('bid-invitations/', {
        params: { status: 'pending' }
    }),

    // Marketplace
    getMarketplaceEntries: (params) => api.get('bid-marketplace/', { params }),
    getTopRatedProfiles: (role) => api.get('bid-marketplace/top_rated/', {
        params: { role }
    }),
    verifyMarketplaceEntry: (id) => api.post(`bid-marketplace/${id}/verify_entry/`),
    updateJobCount: (id, jobCount) => api.post(`bid-marketplace/${id}/update_job_count/`, {
        job_count: jobCount
    }),

    // Ratings
    getBidRatings: (params) => api.get('bid-ratings/', { params }),
    createBidRating: (data) => api.post('bid-ratings/', data),
    getMyRatings: () => api.get('bid-ratings/my_ratings/'),
    getRatingsGiven: () => api.get('bid-ratings/ratings_given/'),
    getUserRatingSummary: (userId) => api.get('bid-ratings/user_summary/', {
        params: { user_id: userId }
    })
};

export default BidService;