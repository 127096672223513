import React, { useState } from 'react';
import { Button } from '../../components/ui/button';
import { Input } from '../../components/ui/input';
import { Badge } from '../../components/ui/badge';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../components/ui/select';
import { Link } from 'react-router-dom';
import { Search } from 'lucide-react';

const RiskTable = ({ risks, onEdit, onDelete, onSelect }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [filterOptions, setFilterOptions] = useState({
        status: 'all',
        severity: 'all',
        category: 'all'
    });

    // Get unique categories from risks
    const categories = [...new Set(risks.map(risk => risk.category_name))];

    // Apply filters and search
    const filteredRisks = risks.filter(risk => {
        const matchesSearch =
            risk.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
            risk.description.toLowerCase().includes(searchTerm.toLowerCase());

        const matchesStatus = filterOptions.status === 'all' ? true : risk.status === filterOptions.status;
        const matchesSeverity = filterOptions.severity === 'all' ? true : risk.severity === filterOptions.severity;
        const matchesCategory = filterOptions.category === 'all' ? true : risk.category_name === filterOptions.category;

        return matchesSearch && matchesStatus && matchesSeverity && matchesCategory;
    });

    const handleFilterChange = (name, value) => {
        setFilterOptions({
            ...filterOptions,
            [name]: value
        });
    };

    // Get variant for severity
    const getSeverityVariant = (severity) => {
        switch (severity) {
            case 'LOW': return 'success';
            case 'MEDIUM': return 'warning';
            case 'HIGH': return 'destructive';
            case 'CRITICAL': return 'default';
            default: return 'secondary';
        }
    };

    // Get variant for status
    const getStatusVariant = (status) => {
        switch (status) {
            case 'IDENTIFIED': return 'default';
            case 'ASSESSED': return 'primary';
            case 'MITIGATED': return 'success';
            case 'CLOSED': return 'secondary';
            case 'OCCURRED': return 'destructive';
            default: return 'outline';
        }
    };

    const handleRiskClick = (risk) => {
        if (onSelect) {
            onSelect(risk);
        }
    };

    return (
        <div className="space-y-4">
            <div className="grid grid-cols-1 md:grid-cols-12 gap-3">
                <div className="md:col-span-6 flex">
                    <div className="relative flex-1">
                        <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
                        <Input
                            placeholder="Search by title or description..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="pl-8"
                        />
                    </div>
                </div>
                <div className="md:col-span-2">
                    <Select
                        value={filterOptions.status}
                        onValueChange={(value) => handleFilterChange('status', value)}
                    >
                        <SelectTrigger>
                            <SelectValue placeholder="All Statuses" />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectItem value="all">All Statuses</SelectItem>
                            <SelectItem value="IDENTIFIED">Identified</SelectItem>
                            <SelectItem value="ASSESSED">Assessed</SelectItem>
                            <SelectItem value="MITIGATED">Mitigated</SelectItem>
                            <SelectItem value="CLOSED">Closed</SelectItem>
                            <SelectItem value="OCCURRED">Occurred</SelectItem>
                        </SelectContent>
                    </Select>
                </div>
                <div className="md:col-span-2">
                    <Select
                        value={filterOptions.severity}
                        onValueChange={(value) => handleFilterChange('severity', value)}
                    >
                        <SelectTrigger>
                            <SelectValue placeholder="All Severities" />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectItem value="all">All Severities</SelectItem>
                            <SelectItem value="LOW">Low</SelectItem>
                            <SelectItem value="MEDIUM">Medium</SelectItem>
                            <SelectItem value="HIGH">High</SelectItem>
                            <SelectItem value="CRITICAL">Critical</SelectItem>
                        </SelectContent>
                    </Select>
                </div>
                <div className="md:col-span-2">
                    <Select
                        value={filterOptions.category}
                        onValueChange={(value) => handleFilterChange('category', value)}
                    >
                        <SelectTrigger>
                            <SelectValue placeholder="All Categories" />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectItem value="all">All Categories</SelectItem>
                            {categories.map((category, index) => (
                                <SelectItem key={index} value={category || "uncategorized"}>
                                    {category || "Uncategorized"}
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                </div>
            </div>

            {filteredRisks.length === 0 ? (
                <div className="text-center p-8 bg-muted/20 rounded-md">
                    <p className="text-muted-foreground">No risks found matching your criteria.</p>
                </div>
            ) : (
                <div className="overflow-x-auto">
                    <table className="w-full border-collapse">
                        <thead>
                            <tr className="bg-muted text-muted-foreground">
                                <th className="p-2 text-left border">Title</th>
                                <th className="p-2 text-left border">Category</th>
                                <th className="p-2 text-left border">Severity</th>
                                <th className="p-2 text-left border">Probability</th>
                                <th className="p-2 text-left border">Risk Score</th>
                                <th className="p-2 text-left border">Status</th>
                                <th className="p-2 text-left border">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredRisks.map((risk) => (
                                <tr
                                    key={risk.id}
                                    className="border-b hover:bg-muted/50 cursor-pointer"
                                    onClick={() => handleRiskClick(risk)}
                                >
                                    <td className="p-2 border">
                                        <div className="flex items-center gap-2">
                                            {risk.title}
                                            {risk.ai_suggested && (
                                                <Badge variant="info" className="ml-1">AI</Badge>
                                            )}
                                        </div>
                                    </td>
                                    <td className="p-2 border">{risk.category_name || "Uncategorized"}</td>
                                    <td className="p-2 border">
                                        <Badge variant={getSeverityVariant(risk.severity)}>
                                            {risk.severity}
                                        </Badge>
                                    </td>
                                    <td className="p-2 border">{risk.probability}</td>
                                    <td className="p-2 border">{risk.risk_score}</td>
                                    <td className="p-2 border">
                                        <Badge variant={getStatusVariant(risk.status)}>
                                            {risk.status}
                                        </Badge>
                                    </td>
                                    <td className="p-2 border">
                                        <div className="flex gap-1">
                                            <Button
                                                variant="outline"
                                                size="sm"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    onEdit(risk);
                                                }}
                                            >
                                                Edit
                                            </Button>
                                            <Button
                                                variant="destructive"
                                                size="sm"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    onDelete(risk.id);
                                                }}
                                            >
                                                Delete
                                            </Button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

export default RiskTable;