import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "../../components/ui/dialog";
import { Button } from "../../components/ui/button";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../components/ui/tabs";
import { Card, CardContent, CardHeader, CardTitle } from "../../components/ui/cardui";
import { Badge } from "../../components/ui/badge";
import { Separator } from "../../components/ui/separator";
import { primaryButtonClasses, secondaryButtonClasses } from '../common/ButtonStyles';
import { Phone, Mail, MapPin, Building, Calendar, CreditCard, Star, Award, Shield, File, Edit, Trash2 } from "lucide-react";
import api from '../../services/api';
import { toast } from "react-toastify";
import SupplierDetailTabs from './SupplierDetailTabs';

const SupplierDetail = ({
    isOpen,
    onClose,
    supplier,
    onEdit,
    onDelete
}) => {
    const [performance, setPerformance] = useState(null);
    const [loading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useState('overview');

    useEffect(() => {
        if (supplier && isOpen) {
            fetchPerformanceData();
        }
    }, [supplier, isOpen]);

    const fetchPerformanceData = async () => {
        setLoading(true);
        try {
            const response = await api.get(`suppliers/${supplier.id}/performance/`);
            setPerformance(response.data);
        } catch (error) {
            console.error("Failed to load supplier performance:", error);
            toast.error("Failed to load supplier performance data");
        } finally {
            setLoading(false);
        }
    };

    const formatDate = (dateString) => {
        if (!dateString) return 'N/A';
        return new Date(dateString).toLocaleDateString();
    };

    const formatPaymentTerms = (term) => {
        const terms = {
            'net_30': 'Net 30',
            'net_45': 'Net 45',
            'net_60': 'Net 60',
            'cod': 'Cash on Delivery',
            'advance': 'Advance Payment'
        };
        return terms[term] || term;
    };

    const getStatusBadge = (status) => {
        const statusMap = {
            'active': { variant: 'success', label: 'Active' },
            'inactive': { variant: 'secondary', label: 'Inactive' },
            'pending': { variant: 'warning', label: 'Pending Approval' },
            'suspended': { variant: 'destructive', label: 'Suspended' }
        };

        const { variant, label } = statusMap[status] || { variant: 'default', label: status };

        return <Badge variant={variant}>{label}</Badge>;
    };

    const getRatingDisplay = (rating) => {
        if (!rating) return 'N/A';

        let color = 'text-gray-500';
        if (rating >= 4.5) color = 'text-green-500';
        else if (rating >= 3.5) color = 'text-blue-500';
        else if (rating >= 2.5) color = 'text-yellow-500';
        else color = 'text-red-500';

        const stars = [];
        for (let i = 1; i <= 5; i++) {
            stars.push(
                <Star
                    key={i}
                    className={`h-4 w-4 ${i <= Math.round(rating) ? color : 'text-gray-300'}`}
                    fill={i <= Math.round(rating) ? 'currentColor' : 'none'}
                />
            );
        }

        return (
            <div className="flex items-center gap-1">
                <div className="flex">{stars}</div>
                <span className={`ml-2 ${color} font-medium`}>{rating.toFixed(1)}</span>
            </div>
        );
    };

    if (!supplier) return null;

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className="sm:max-w-[800px] w-full mx-4 sm:mx-auto max-h-[90vh] overflow-y-auto">
                <DialogHeader>
                    <div className="flex justify-between items-center">
                        <DialogTitle>Supplier Details</DialogTitle>
                        <div className="flex space-x-2">
                            <Button variant="outline" size="sm" onClick={onEdit}>
                                <Edit className="h-4 w-4 mr-1" /> Edit
                            </Button>
                            <Button variant="destructive" size="sm" onClick={onDelete}>
                                <Trash2 className="h-4 w-4 mr-1" /> Delete
                            </Button>
                        </div>
                    </div>
                </DialogHeader>

                <div className="flex flex-col space-y-4">
                    <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-2 pb-2">
                        <div>
                            <h2 className="text-xl font-bold">{supplier.company_name}</h2>
                            <p className="text-sm text-gray-500">Code: {supplier.supplier_code}</p>
                        </div>
                        <div className="flex items-center gap-2">
                            {getStatusBadge(supplier.status)}
                        </div>
                    </div>

                    <Tabs value={activeTab} onValueChange={setActiveTab}>
                        <TabsList className="grid grid-cols-4">
                            <TabsTrigger value="overview">Overview</TabsTrigger>
                            <TabsTrigger value="details">Details & Management</TabsTrigger>
                            <TabsTrigger value="contact">Contact & Address</TabsTrigger>
                            <TabsTrigger value="performance">Performance</TabsTrigger>
                        </TabsList>

                        <TabsContent value="overview" className="space-y-4 pt-4">
                            <Card>
                                <CardHeader className="pb-2">
                                    <CardTitle className="text-lg">Business Information</CardTitle>
                                </CardHeader>
                                <CardContent className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                    <div>
                                        <p className="text-sm text-gray-500">Payment Terms</p>
                                        <p className="font-medium">{formatPaymentTerms(supplier.payment_terms)}</p>
                                    </div>
                                    <div>
                                        <p className="text-sm text-gray-500">Credit Limit</p>
                                        <p className="font-medium">{supplier.credit_limit ? `$${supplier.credit_limit}` : 'N/A'}</p>
                                    </div>
                                    <div>
                                        <p className="text-sm text-gray-500">Tax ID</p>
                                        <p className="font-medium">{supplier.tax_id || 'N/A'}</p>
                                    </div>
                                    <div>
                                        <p className="text-sm text-gray-500">Business License</p>
                                        <p className="font-medium">{supplier.business_license || 'N/A'}</p>
                                    </div>
                                    <div>
                                        <p className="text-sm text-gray-500">Year Established</p>
                                        <p className="font-medium">{supplier.year_established || 'N/A'}</p>
                                    </div>
                                    <div>
                                        <p className="text-sm text-gray-500">Bank Info</p>
                                        <p className="font-medium">
                                            {supplier.bank_name && supplier.bank_account
                                                ? `${supplier.bank_name} - ${supplier.bank_account.slice(0, 4)}****`
                                                : 'N/A'}
                                        </p>
                                    </div>
                                </CardContent>

                            </Card>


                            <Card>
                                <CardHeader className="pb-2">
                                    <CardTitle className="text-lg">Certification & Insurance</CardTitle>
                                </CardHeader>
                                <CardContent className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                    <div>
                                        <p className="text-sm text-gray-500">Insurance Expiry</p>
                                        <p className="font-medium">{formatDate(supplier.insurance_expiry)}</p>
                                    </div>
                                    <div>
                                        <p className="text-sm text-gray-500">Certification Expiry</p>
                                        <p className="font-medium">{formatDate(supplier.certification_expiry)}</p>
                                    </div>
                                </CardContent>
                            </Card>
                        </TabsContent>
                        <TabsContent value="details" className="pt-4">
                            <SupplierDetailTabs supplierId={supplier.id} />
                        </TabsContent>

                        <TabsContent value="contact" className="space-y-4 pt-4">
                            <Card>
                                <CardHeader className="pb-2">
                                    <CardTitle className="text-lg">Contact Information</CardTitle>
                                </CardHeader>
                                <CardContent className="space-y-4">
                                    <div className="flex items-center gap-2">
                                        <Building className="h-4 w-4 text-gray-500" />
                                        <div>
                                            <p className="font-medium">{supplier.contact_name}</p>
                                            <p className="text-sm text-gray-500">Primary Contact</p>
                                        </div>
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <Mail className="h-4 w-4 text-gray-500" />
                                        <p>{supplier.email}</p>
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <Phone className="h-4 w-4 text-gray-500" />
                                        <p>{supplier.phone}</p>
                                    </div>
                                    {supplier.alternate_phone && (
                                        <div className="flex items-center gap-2">
                                            <Phone className="h-4 w-4 text-gray-500" />
                                            <p>{supplier.alternate_phone} (Alternative)</p>
                                        </div>
                                    )}
                                </CardContent>
                            </Card>

                            <Card>
                                <CardHeader className="pb-2">
                                    <CardTitle className="text-lg">Address</CardTitle>
                                </CardHeader>
                                <CardContent>
                                    <div className="flex items-start gap-2">
                                        <MapPin className="h-4 w-4 text-gray-500 mt-1" />
                                        <div>
                                            <p>{supplier.address_line1}</p>
                                            {supplier.address_line2 && <p>{supplier.address_line2}</p>}
                                            <p>{supplier.city}, {supplier.state} {supplier.postal_code}</p>
                                            <p>{supplier.country}</p>
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                        </TabsContent>

                        <TabsContent value="performance" className="space-y-4 pt-4">
                            {loading ? (
                                <div className="text-center py-10">Loading performance data...</div>
                            ) : performance ? (
                                <>
                                    <Card>
                                        <CardHeader className="pb-2">
                                            <CardTitle className="text-lg">Performance Metrics</CardTitle>
                                        </CardHeader>
                                        <CardContent className="grid grid-cols-1 md:grid-cols-3 gap-4">
                                            <div className="flex flex-col items-center p-4 border rounded-md">
                                                <p className="text-sm text-gray-500 mb-2">Overall Rating</p>
                                                {getRatingDisplay(performance.rating)}
                                            </div>
                                            <div className="flex flex-col items-center p-4 border rounded-md">
                                                <p className="text-sm text-gray-500 mb-2">On-Time Delivery</p>
                                                <div className="text-lg font-medium">
                                                    {performance.on_time_delivery_rate
                                                        ? `${performance.on_time_delivery_rate.toFixed(1)}%`
                                                        : 'N/A'}
                                                </div>
                                            </div>
                                            <div className="flex flex-col items-center p-4 border rounded-md">
                                                <p className="text-sm text-gray-500 mb-2">Quality Rating</p>
                                                <div className="text-lg font-medium">
                                                    {performance.quality_rating
                                                        ? `${performance.quality_rating.toFixed(1)}/5.0`
                                                        : 'N/A'}
                                                </div>
                                            </div>
                                        </CardContent>
                                    </Card>

                                    {performance.reviews && performance.reviews.length > 0 ? (
                                        <Card>
                                            <CardHeader className="pb-2">
                                                <CardTitle className="text-lg">Recent Reviews</CardTitle>
                                            </CardHeader>
                                            <CardContent className="space-y-4">
                                                {performance.reviews.map((review, index) => (
                                                    <div key={review.id} className="p-4 border rounded-md">
                                                        <div className="flex justify-between items-start mb-2">
                                                            <div>
                                                                <p className="font-medium">Review by {review.reviewer.name || review.reviewer}</p>
                                                                <p className="text-sm text-gray-500">
                                                                    {formatDate(review.review_date)}
                                                                </p>
                                                            </div>
                                                            <div>
                                                                {getRatingDisplay((
                                                                    review.delivery_rating +
                                                                    review.quality_rating +
                                                                    review.communication_rating +
                                                                    review.pricing_rating
                                                                ) / 4)}
                                                            </div>
                                                        </div>
                                                        <p className="text-sm mt-2">{review.comments}</p>
                                                    </div>
                                                ))}
                                            </CardContent>
                                        </Card>
                                    ) : (
                                        <Card>
                                            <CardContent className="py-6">
                                                <p className="text-center text-gray-500">No reviews available for this supplier.</p>
                                            </CardContent>
                                        </Card>
                                    )}
                                </>
                            ) : (
                                <Card>
                                    <CardContent className="py-6">
                                        <p className="text-center text-gray-500">No performance data available for this supplier.</p>
                                    </CardContent>
                                </Card>
                            )}
                        </TabsContent>
                    </Tabs>
                </div>

                <DialogFooter>
                    <Button
                        className={secondaryButtonClasses}
                        onClick={onClose}
                    >
                        Close
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};

export default SupplierDetail;