import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import api from '../../services/api';
import { ArrowRightIcon } from '@heroicons/react/24/solid';
import CreateProjectModal from './CreateProjectModal';
import { Breadcrumb } from '../../components/common/Breadcrumb';
import { showToast } from '../../utils/toast';
import { SubscriptionContext } from '../Subscription/context/SubscriptionContext';
import { primaryButtonClasses } from '../common/ButtonStyles';
import { CalendarDaysIcon, FunnelIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { format } from 'date-fns';
import { Alert, AlertDescription } from "../../components/ui/alert";

function Projects({ tenantId }) {
  const navigate = useNavigate();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [projects, setProjects] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [roleName, setRoleName] = useState('');
  const [statusFilter, setStatusFilter] = useState('all');
  const [dateFilter, setDateFilter] = useState('');


  const { checkFeature } = useContext(SubscriptionContext);

  const canCreateProject = roleName === 'Tenant Admin' || roleName === 'Tenant Admin Project' || roleName === 'Project Admin';


  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      // Get user profile with role_name
      const userResponse = await api.get('users/profile/');

      // Assuming the API returns role_name in the profile
      if (userResponse.data?.role_name) {
        setRoleName(userResponse.data.role_name);
      }
      // Get projects
      const projectsResponse = await api.get('projects/');
      //setProjects(projectsResponse.data.results || []);
      const sortedProjects = sortProjects(projectsResponse.data.results || []);
      setProjects(sortedProjects);
    } catch (err) {
      console.error('Failed to load data', err);
      showToast.error('Failed to load data. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleProjectCreation = () => {
    setShowCreateModal(false);
    fetchData();
  };
  const sortProjects = (projectList) => {
    return projectList.sort((a, b) => {
      // Priority order: In Progress > Pending > Completed
      const statusOrder = { 'In Progress': 0, 'Pending': 1, 'Completed': 2 };
      return statusOrder[a.status] - statusOrder[b.status];
    });
  };

  const filteredProjects = projects.filter(project => {
    const matchesSearch = project.name.toLowerCase().includes(searchQuery.toLowerCase());
    const matchesStatus = statusFilter === 'all' || project.status === statusFilter;
    const matchesDate = !dateFilter || format(new Date(project.start_date), 'yyyy-MM-dd') === dateFilter;
    return matchesSearch && matchesStatus && matchesDate;
  });



  if (isLoading) {
    return (
      <div>
        <Breadcrumb />
        <div className="flex items-center justify-center h-64">Loading...</div>
      </div>
    );
  }

  return (
    <div>
      <Breadcrumb />
      <div className="space-y-6">
        <div className="flex flex-wrap gap-4 items-center bg-white p-4 rounded-lg shadow-sm">
          <div className="flex items-center gap-2">
            <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" />
            <input
              type="text"
              placeholder="Search projects..."
              className="border-none focus:ring-0"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          <div className="flex items-center gap-2">
            <FunnelIcon className="h-5 w-5 text-gray-400" />
            <select
              value={statusFilter}
              onChange={(e) => setStatusFilter(e.target.value)}
              className="border-none focus:ring-0"
            >
              <option value="all">All Status</option>
              <option value="In Progress">In Progress</option>
              <option value="Pending">Pending</option>
              <option value="Completed">Completed</option>
            </select>
          </div>
          <div className="flex items-center gap-2">
            <CalendarDaysIcon className="h-5 w-5 text-gray-400" />
            <input
              type="date"
              value={dateFilter}
              onChange={(e) => setDateFilter(e.target.value)}
              className="border-none focus:ring-0"
            />
          </div>
          {canCreateProject && (
            <button
              className="ml-auto bg-blue-600 text-white px-4 py-2 rounded-full hover:bg-blue-700 transition"
              onClick={() => setShowCreateModal(true)}
            >
              Create New Project
            </button>
          )}
        </div>
        <h1 className="text-3xl font-bold text-midnight-blue">Projects</h1>
        {/* Projects Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredProjects.map((project) => (
            <div key={project.id} className="bg-white overflow-hidden shadow-lg rounded-2xl">
              <div className="p-6">
                <h3 className="text-lg font-semibold text-gray-900 mb-2">{project.name}</h3>
                <p className="text-sm text-gray-600 mb-4">{project.location}</p>
                <div className="flex justify-between items-center">
                  <span className={`px-2 py-1 rounded-full text-xs font-medium ${project.status === 'Completed' ? 'bg-green-100 text-green-800' :
                    project.status === 'In Progress' ? 'bg-blue-100 text-blue-800' :
                      'bg-yellow-100 text-yellow-800'
                    }`}>
                    {project.status}
                  </span>
                  <Link
                    to={`/projects/${project.id}`}
                    className="text-blue-600 hover:text-blue-800 transition flex items-center"
                  >
                    <span className="mr-2">View</span>
                    <ArrowRightIcon className="h-4 w-4" />
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
        {showCreateModal && (
          <CreateProjectModal closeModal={() => setShowCreateModal(false)} onProjectCreated={fetchData} />
        )}
      </div>
    </div>
  );
}

export default Projects;