import React, { useState, useEffect, useCallback } from 'react';
import api from '../../services/api';
import {
    BriefcaseIcon,
    EnvelopeIcon,
    BellIcon,
    MagnifyingGlassIcon,
    Bars3Icon,
    PaperClipIcon,
    ExclamationTriangleIcon,
    CheckCircleIcon,
    SparklesIcon,
    XMarkIcon,
    ChartBarIcon,
    ChevronDownIcon,
    ChevronUpIcon,
    AdjustmentsHorizontalIcon,
    UserGroupIcon,
    PhoneIcon,
    ClockIcon
} from '@heroicons/react/24/outline';
import NotificationPanel from './NotificationPanel';
import CommunicationSearchPanel from './CommunicationSearchPanel';

const CommunicationHeader = ({ activeProject, setIsSidebarOpen }) => {
    const [isSearchOpen, setIsSearchOpen] = useState(false);

    const handleSearchResult = (result) => {
        // Navigate to the communication detail
        if (result && result.id) {
            window.location.href = `/projects/${result.project}/communications/${result.id}`;
        }
    };

    return (
        <header className="bg-[#4452FE] text-white p-4">
            <div className="flex justify-between items-center">
                <div className="flex items-center">
                    <Bars3Icon
                        className="h-6 w-6 cursor-pointer mr-3"
                        onClick={() => setIsSidebarOpen(true)}
                    />
                    <h1 className="text-2xl font-bold">
                        {activeProject ? activeProject.name : 'Select a Project'}
                    </h1>
                </div>
                <div className="flex items-center space-x-4">
                    <MagnifyingGlassIcon
                        className="h-5 w-5 cursor-pointer"
                        onClick={() => setIsSearchOpen(true)}
                    />
                    <NotificationPanel />
                    <div className="w-8 h-8 bg-white rounded-full"></div>
                </div>
            </div>

            <CommunicationSearchPanel
                isOpen={isSearchOpen}
                setIsOpen={setIsSearchOpen}
                activeProject={activeProject}
                onResultSelect={handleSearchResult}
            />
        </header>
    );
};


export default CommunicationHeader;