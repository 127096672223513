import React, { useState, useEffect } from 'react';
import { Button } from "../ui/button";
import { formatCurrency } from '../../utils/formatter';
import { Card, CardContent, CardHeader, CardTitle } from "../ui/cardui";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "../ui/tabs";
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Avatar, AvatarImage, AvatarFallback } from "../ui/avatar";
import { Table } from '../ui/table';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import { Input } from '../ui/input';
import { Alert, AlertDescription } from '../ui/alert';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue
} from '../ui/select';
import { LockIcon, AlertTriangle } from 'lucide-react';
import budgetService from '../../services/budgetService';
import { useToast } from '../ui/use-toast';
import {
    Plus,
    FileDown,
    PlusSquareIcon,
    FileArchiveIcon,
    Check,
    X,
    Clock,
    FileText
} from 'lucide-react';
import { Badge } from '../ui/badge';
export default function BudgetChangeOrders({ budget, onUpdate }) {
    const { toast } = useToast();
    const [filters, setFilters] = useState({
        status: '',
        search: ''
    });
    const [editingChangeOrder, setEditingChangeOrder] = useState(null);
    const [loading, setLoading] = useState(false);

    const getStatusBadge = (status) => {
        const statusConfig = {
            'PENDING': { color: 'yellow', icon: <Clock className="w-4 h-4" /> },
            'APPROVED': { color: 'green', icon: <Check className="w-4 h-4" /> },
            'REJECTED': { color: 'red', icon: <X className="w-4 h-4" /> }
        };

        const config = statusConfig[status];
        return (
            <Badge variant={config.color} className="flex items-center gap-1">
                {config.icon}
                {status}
            </Badge>
        );
    };

    const handleAddChangeOrder = () => {
        setEditingChangeOrder({
            budget: budget.id,
            title: '',
            description: '',
            amount: 0,
            status: 'PENDING'
        });
    };

    const handleSaveChangeOrder = async (changeOrder) => {
        try {
            setLoading(true);
            if (changeOrder.id) {
                await budgetService.updateChangeOrder(changeOrder.id, changeOrder);
            } else {
                await budgetService.createChangeOrder(changeOrder);
            }
            setEditingChangeOrder(null);
            onUpdate();
            toast({
                title: "Success",
                description: "Change order saved successfully"
            });
        } catch (error) {
            toast({
                title: "Error",
                description: "Failed to save change order",
                variant: "destructive"
            });
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteChangeOrder = async (changeOrderId) => {
        if (!window.confirm('Are you sure you want to delete this change order?')) return;

        try {
            setLoading(true);
            await budgetService.deleteChangeOrder(changeOrderId);
            onUpdate();
            toast({
                title: "Success",
                description: "Change order deleted successfully"
            });
        } catch (error) {
            toast({
                title: "Error",
                description: "Failed to delete change order",
                variant: "destructive"
            });
        } finally {
            setLoading(false);
        }
    };

    const handleApproveReject = async (changeOrderId, newStatus) => {
        try {
            setLoading(true);
            await budgetService.updateChangeOrder(changeOrderId, { status: newStatus });
            onUpdate();
            toast({
                title: "Success",
                description: `Change order ${newStatus.toLowerCase()} successfully`
            });
        } catch (error) {
            toast({
                title: "Error",
                description: `Failed to ${newStatus.toLowerCase()} change order`,
                variant: "destructive"
            });
        } finally {
            setLoading(false);
        }
    };

    const getFilteredChangeOrders = () => {
        let filtered = budget.change_orders;

        if (filters.status) {
            filtered = filtered.filter(co => co.status === filters.status);
        }

        if (filters.search) {
            const searchLower = filters.search.toLowerCase();
            filtered = filtered.filter(co =>
                co.title.toLowerCase().includes(searchLower) ||
                co.description.toLowerCase().includes(searchLower)
            );
        }

        return filtered;
    };

    const calculateTotalImpact = () => {
        return budget.change_orders
            .filter(co => co.status === 'APPROVED')
            .reduce((total, co) => total + parseFloat(co.amount), 0);
    };

    return (
        <div className="space-y-6">
            <div className="grid grid-cols-3 gap-4">
                <Card>
                    <CardContent className="p-4">
                        <div className="flex items-center gap-2 text-yellow-700 mb-1">
                            <Clock className="w-4 h-4" />
                            <span className="font-medium">Pending Changes</span>
                        </div>
                        <div className="text-2xl font-bold">
                            {budget.change_orders.filter(co => co.status === 'PENDING').length}
                        </div>
                    </CardContent>
                </Card>

                <Card>
                    <CardContent className="p-4">
                        <div className="flex items-center gap-2 text-green-700 mb-1">
                            <Check className="w-4 h-4" />
                            <span className="font-medium">Approved Impact</span>
                        </div>
                        <div className="text-2xl font-bold">
                            {formatCurrency(calculateTotalImpact())}
                        </div>
                    </CardContent>
                </Card>

                <Card>
                    <CardContent className="p-4">
                        <div className="flex items-center gap-2 text-blue-700 mb-1">
                            <FileText className="w-4 h-4" />
                            <span className="font-medium">Total Changes</span>
                        </div>
                        <div className="text-2xl font-bold">
                            {budget.change_orders.length}
                        </div>
                    </CardContent>
                </Card>
            </div>

            <Card>
                <CardContent>
                    <div className="flex justify-between items-center mb-4">
                        <div className="flex gap-4">
                            <Select
                                value={filters.status}
                                onValueChange={(value) => setFilters({ ...filters, status: value })}
                            >
                                <SelectTrigger className="w-48">
                                    <SelectValue placeholder="Filter by status" />
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectItem value="">All Statuses</SelectItem>
                                    <SelectItem value="PENDING">Pending</SelectItem>
                                    <SelectItem value="APPROVED">Approved</SelectItem>
                                    <SelectItem value="REJECTED">Rejected</SelectItem>
                                </SelectContent>
                            </Select>

                            <Input
                                placeholder="Search change orders..."
                                value={filters.search}
                                onChange={(e) => setFilters({ ...filters, search: e.target.value })}
                                className="w-64"
                            />
                        </div>

                        <div className="flex gap-2">
                            <Button variant="outline">
                                <FileDown className="w-4 h-4 mr-2" />
                                Export
                            </Button>
                            <Button onClick={handleAddChangeOrder}>
                                <Plus className="w-4 h-4 mr-2" />
                                New Change Order
                            </Button>
                        </div>
                    </div>

                    <Table>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Title</th>
                                <th>Description</th>
                                <th>Amount</th>
                                <th>Status</th>
                                <th>Created By</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {getFilteredChangeOrders().map(changeOrder => (
                                <tr key={changeOrder.id}>
                                    <td>{new Date(changeOrder.created_at).toLocaleDateString()}</td>
                                    <td>{changeOrder.title}</td>
                                    <td className="max-w-md truncate">{changeOrder.description}</td>
                                    <td className={changeOrder.amount >= 0 ? 'text-green-600' : 'text-red-600'}>
                                        {formatCurrency(changeOrder.amount)}
                                    </td>
                                    <td>{getStatusBadge(changeOrder.status)}</td>
                                    <td>{changeOrder.created_by_name}</td>
                                    <td>
                                        <div className="flex gap-2">
                                            {changeOrder.status === 'PENDING' && (
                                                <>
                                                    <Button
                                                        size="sm"
                                                        variant="outline"
                                                        className="text-green-600"
                                                        onClick={() => handleApproveReject(changeOrder.id, 'APPROVED')}
                                                    >
                                                        <Check className="w-4 h-4 mr-1" />
                                                        Approve
                                                    </Button>
                                                    <Button
                                                        size="sm"
                                                        variant="outline"
                                                        className="text-red-600"
                                                        onClick={() => handleApproveReject(changeOrder.id, 'REJECTED')}
                                                    >
                                                        <X className="w-4 h-4 mr-1" />
                                                        Reject
                                                    </Button>
                                                </>
                                            )}
                                            <Button
                                                size="sm"
                                                variant="ghost"
                                                onClick={() => setEditingChangeOrder(changeOrder)}
                                            >
                                                Edit
                                            </Button>
                                            <Button
                                                size="sm"
                                                variant="ghost"
                                                className="text-red-600"
                                                onClick={() => handleDeleteChangeOrder(changeOrder.id)}
                                            >
                                                Delete
                                            </Button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </CardContent>
            </Card>

            {editingChangeOrder && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                    <Card className="w-full max-w-2xl">
                        <CardContent className="p-6">
                            <h3 className="text-lg font-bold mb-4">
                                {editingChangeOrder.id ? 'Edit Change Order' : 'New Change Order'}
                            </h3>
                            <div className="space-y-4">
                                <div>
                                    <label className="text-sm font-medium">Title</label>
                                    <Input
                                        value={editingChangeOrder.title}
                                        onChange={(e) => setEditingChangeOrder({
                                            ...editingChangeOrder,
                                            title: e.target.value
                                        })}
                                        placeholder="Enter change order title"
                                    />
                                </div>

                                <div>
                                    <label className="text-sm font-medium">Description</label>
                                    <Input
                                        value={editingChangeOrder.description}
                                        onChange={(e) => setEditingChangeOrder({
                                            ...editingChangeOrder,
                                            description: e.target.value
                                        })}
                                        placeholder="Enter detailed description"
                                    />
                                </div>

                                <div>
                                    <label className="text-sm font-medium">Amount</label>
                                    <Input
                                        type="number"
                                        value={editingChangeOrder.amount}
                                        onChange={(e) => setEditingChangeOrder({
                                            ...editingChangeOrder,
                                            amount: parseFloat(e.target.value)
                                        })}
                                        placeholder="Enter amount (use negative for deductions)"
                                    />
                                </div>

                                {editingChangeOrder.id && (
                                    <div>
                                        <label className="text-sm font-medium">Status</label>
                                        <Select
                                            value={editingChangeOrder.status}
                                            onValueChange={(value) => setEditingChangeOrder({
                                                ...editingChangeOrder,
                                                status: value
                                            })}
                                        >
                                            <SelectTrigger>
                                                <SelectValue />
                                            </SelectTrigger>
                                            <SelectContent>
                                                <SelectItem value="PENDING">Pending</SelectItem>
                                                <SelectItem value="APPROVED">Approved</SelectItem>
                                                <SelectItem value="REJECTED">Rejected</SelectItem>
                                            </SelectContent>
                                        </Select>
                                    </div>
                                )}
                            </div>

                            <div className="flex justify-end gap-2 mt-6">
                                <Button
                                    variant="outline"
                                    onClick={() => setEditingChangeOrder(null)}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    onClick={() => handleSaveChangeOrder(editingChangeOrder)}
                                    disabled={loading}
                                >
                                    {loading ? 'Saving...' : 'Save'}
                                </Button>
                            </div>
                        </CardContent>
                    </Card>
                </div>
            )}
        </div>
    );
}