import React, { useState } from 'react';
import { format } from 'date-fns';
import { Badge } from "../../ui/badge";
import { Button } from "../../ui/button";
import { FileText } from "lucide-react";
import {
    Card,
    CardHeader,
    CardContent
} from "../../ui/cardui";
import InspectionReportGenerator from './InspectionReportGenerator';
import { primaryButtonClasses } from '../../common/ButtonStyles';

const getStatusBadgeColor = (status) => {
    switch (status) {
        case 'passed':
            return 'bg-green-100 text-green-800';
        case 'failed':
            return 'bg-red-100 text-red-800';
        case 'scheduled':
            return 'bg-blue-100 text-blue-800';
        case 'in_progress':
            return 'bg-yellow-100 text-yellow-800';
        case 'remediation_required':
            return 'bg-orange-100 text-orange-800';
        default:
            return 'bg-gray-100 text-gray-800';
    }
};


const InspectionDetails = ({ inspection }) => {
    const [showReportGenerator, setShowReportGenerator] = useState(false);

    return (
        <div className="space-y-6">
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 gap-2">
                <h2 className="text-xl font-bold">Inspection Details</h2>
                <Button
                    onClick={() => setShowReportGenerator(true)}
                    className={`${primaryButtonClasses} w-full sm:w-auto`}
                >
                    <FileText className="w-4 h-4 mr-2" />
                    Generate Report
                </Button>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <div>
                    <h3 className="font-medium text-gray-500">Template</h3>
                    <p>{inspection?.template_name || 'N/A'}</p>
                </div>
                <div>
                    <h3 className="font-medium text-gray-500">Status</h3>
                    {inspection?.status ? (
                        <Badge className={getStatusBadgeColor(inspection.status)}>
                            {inspection.status.replace('_', ' ')}
                        </Badge>
                    ) : (
                        <p>N/A</p>
                    )}
                </div>
                <div>
                    <h3 className="font-medium text-gray-500">Scheduled Date</h3>
                    <p>{inspection?.scheduled_date ? format(new Date(inspection.scheduled_date), 'PPP p') : 'N/A'}</p>
                </div>
                {inspection?.completed_date && (
                    <div>
                        <h3 className="font-medium text-gray-500">Completed Date</h3>
                        <p>{format(new Date(inspection.completed_date), 'PPP p')}</p>
                    </div>
                )}
                <div>
                    <h3 className="font-medium text-gray-500">Inspector</h3>
                    <p>{inspection?.inspector_name || 'Not assigned'}</p>
                </div>
            </div>

            {inspection?.notes && (
                <div>
                    <h3 className="font-medium text-gray-500">Notes</h3>
                    <p className="mt-1">{inspection.notes}</p>
                </div>
            )}

            {inspection?.results && Object.keys(inspection.results).length > 0 && (
                <div>
                    <h3 className="font-medium text-gray-500 mb-2">Results</h3>
                    <div className="space-y-4">
                        {Object.entries(inspection.results).map(([itemId, result]) => (
                            <Card key={itemId}>
                                <CardHeader>
                                    <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-2">
                                        <h4 className="font-medium">{result.name || 'Unnamed Item'}</h4>
                                        <Badge className={result.passed ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}>
                                            {result.passed ? 'Passed' : 'Failed'}
                                        </Badge>
                                    </div>
                                </CardHeader>
                                {(result.notes || (result.photos && result.photos.length > 0)) && (
                                    <CardContent>
                                        {result.notes && <p className="text-sm mb-2">{result.notes}</p>}
                                        {result.photos && result.photos.length > 0 && (
                                            <div className="grid grid-cols-2 sm:grid-cols-4 gap-2">
                                                {result.photos.map((photo, index) => (
                                                    <div key={index} className="relative">
                                                        <img
                                                            src={photo.url || photo}
                                                            alt={photo.caption || `Photo ${index + 1}`}
                                                            className="rounded-lg object-cover w-full h-24"
                                                        />
                                                        {photo.caption && (
                                                            <p className="text-xs text-gray-500 mt-1 truncate">
                                                                {photo.caption}
                                                            </p>
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </CardContent>
                                )}
                            </Card>
                        ))}
                    </div>
                </div>
            )}

            {inspection?.weather_conditions && (
                <div>
                    <h3 className="font-medium text-gray-500">Weather Conditions</h3>
                    <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mt-2">
                        <div>
                            <p className="text-sm text-gray-500">Temperature</p>
                            <p>{inspection.weather_conditions.temperature}°C</p>
                        </div>
                        <div>
                            <p className="text-sm text-gray-500">Conditions</p>
                            <p>{inspection.weather_conditions.conditions}</p>
                        </div>
                        <div>
                            <p className="text-sm text-gray-500">Wind Speed</p>
                            <p>{inspection.weather_conditions.wind_speed} km/h</p>
                        </div>
                    </div>
                </div>
            )}

            {inspection?.location_data && (
                <div>
                    <h3 className="font-medium text-gray-500">Location</h3>
                    <div className="mt-2">
                        <p className="text-sm">
                            Coordinates: {inspection.location_data.latitude}, {inspection.location_data.longitude}
                        </p>
                    </div>
                </div>
            )}

            {showReportGenerator && (
                <InspectionReportGenerator
                    inspection={inspection}
                    onClose={() => setShowReportGenerator(false)}
                />
            )}
        </div>
    );
};
export default InspectionDetails;