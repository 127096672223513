import * as React from "react"
import { cva } from "class-variance-authority"
import { X } from "lucide-react"

import { cn } from "../../lib/utils"

const Sheet = React.forwardRef(({ className, children, ...props }, ref) => (
    <div
        ref={ref}
        className={cn("fixed inset-0 z-50 flex", className)}
        {...props}
    >
        {children}
    </div>
))
Sheet.displayName = "Sheet"

// Alias for Drawer
const Drawer = Sheet

const SheetPortal = ({ className, ...props }) => (
    <div className={cn("fixed inset-0 z-50 flex", className)} {...props} />
)
SheetPortal.displayName = "SheetPortal"

const DrawerPortal = SheetPortal

const SheetOverlay = React.forwardRef(({ className, ...props }, ref) => (
    <div
        ref={ref}
        className={cn(
            "fixed inset-0 z-50 bg-background/80 backdrop-blur-sm data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0",
            className
        )}
        {...props}
    />
))
SheetOverlay.displayName = "SheetOverlay"

const DrawerOverlay = SheetOverlay

const sheetVariants = cva(
    "fixed z-50 gap-4 bg-background p-6 shadow-lg transition ease-in-out data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:duration-300 data-[state=open]:duration-500",
    {
        variants: {
            side: {
                top: "inset-x-0 top-0 border-b data-[state=closed]:slide-out-to-top data-[state=open]:slide-in-from-top",
                bottom: "inset-x-0 bottom-0 border-t data-[state=closed]:slide-out-to-bottom data-[state=open]:slide-in-from-bottom",
                left: "inset-y-0 left-0 h-full w-3/4 border-r data-[state=closed]:slide-out-to-left data-[state=open]:slide-in-from-left sm:max-w-sm",
                right: "inset-y-0 right-0 h-full w-3/4 border-l data-[state=closed]:slide-out-to-right data-[state=open]:slide-in-from-right sm:max-w-sm",
            },
        },
        defaultVariants: {
            side: "right",
        },
    }
)

const SheetContent = React.forwardRef(
    ({ side = "right", className, children, ...props }, ref) => (
        <SheetPortal>
            <SheetOverlay />
            <div
                ref={ref}
                className={cn(sheetVariants({ side }), className)}
                {...props}
            >
                {children}
                <div className="absolute right-4 top-4">
                    <SheetClose />
                </div>
            </div>
        </SheetPortal>
    )
)
SheetContent.displayName = "SheetContent"

// Alias for DrawerContent
const DrawerContent = SheetContent

const SheetHeader = ({ className, ...props }) => (
    <div
        className={cn(
            "flex flex-col space-y-2 text-center sm:text-left",
            className
        )}
        {...props}
    />
)
SheetHeader.displayName = "SheetHeader"

// Alias for DrawerHeader
const DrawerHeader = SheetHeader

const SheetFooter = ({ className, ...props }) => (
    <div
        className={cn(
            "flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2",
            className
        )}
        {...props}
    />
)
SheetFooter.displayName = "SheetFooter"

// Alias for DrawerFooter
const DrawerFooter = SheetFooter

const SheetTitle = React.forwardRef(({ className, ...props }, ref) => (
    <h2
        ref={ref}
        className={cn("text-lg font-semibold text-foreground", className)}
        {...props}
    />
))
SheetTitle.displayName = "SheetTitle"

// Alias for DrawerTitle
const DrawerTitle = SheetTitle

const SheetDescription = React.forwardRef(({ className, ...props }, ref) => (
    <p
        ref={ref}
        className={cn("text-sm text-muted-foreground", className)}
        {...props}
    />
))
SheetDescription.displayName = "SheetDescription"

// Alias for DrawerDescription
const DrawerDescription = SheetDescription

const SheetClose = React.forwardRef(({ className, ...props }, ref) => (
    <button
        ref={ref}
        className={cn(
            "rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none",
            className
        )}
        {...props}
    >
        <X className="h-4 w-4" />
        <span className="sr-only">Close</span>
    </button>
))
SheetClose.displayName = "SheetClose"

// Alias for DrawerClose
const DrawerClose = SheetClose

// Alias for DrawerTrigger (commonly used with Sheet components)
const SheetTrigger = React.forwardRef(({ className, ...props }, ref) => (
    <button
        ref={ref}
        className={cn(className)}
        {...props}
    />
))
SheetTrigger.displayName = "SheetTrigger"

const DrawerTrigger = SheetTrigger

export {
    // Export Sheet components
    Sheet,
    SheetPortal,
    SheetOverlay,
    SheetContent,
    SheetHeader,
    SheetFooter,
    SheetTitle,
    SheetDescription,
    SheetClose,
    SheetTrigger,

    // Export Drawer aliases
    Drawer,
    DrawerPortal,
    DrawerOverlay,
    DrawerContent,
    DrawerHeader,
    DrawerFooter,
    DrawerTitle,
    DrawerDescription,
    DrawerClose,
    DrawerTrigger
}