import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "../ui/cardui";
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import { Badge } from "../ui/badge";
import { Calendar, Users, Wrench, Package, AlertCircle, X, Plus, Search, Save, Settings, ChevronDown, ChevronUp } from 'lucide-react';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "../ui/select";
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogFooter,
} from "../ui/dialog";
import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
} from "../ui/tabs";
import { Alert, AlertDescription } from "../ui/alert";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import api from '../../services/api';
import { toast } from 'react-toastify';
import { DatePicker } from "../ui/date-picker";
import { primaryButtonClasses, secondaryButtonClasses } from '../common/ButtonStyles';
import secureLocalStorage from "react-secure-storage";

// Virtual list helper component for efficient rendering of large lists
const VirtualizedList = ({ items, renderItem, itemHeight = 60, containerHeight = 400 }) => {
    const [scrollTop, setScrollTop] = useState(0);

    const onScroll = (e) => {
        setScrollTop(e.target.scrollTop);
    };

    const startIndex = Math.floor(scrollTop / itemHeight);
    const endIndex = Math.min(
        startIndex + Math.ceil(containerHeight / itemHeight) + 1,
        items.length
    );

    const visibleItems = items.slice(startIndex, endIndex);
    const paddingTop = startIndex * itemHeight;
    const totalHeight = items.length * itemHeight;

    return (
        <div
            style={{ height: containerHeight, overflow: 'auto' }}
            onScroll={onScroll}
        >
            <div style={{ height: totalHeight, position: 'relative' }}>
                <div style={{ position: 'absolute', top: paddingTop, width: '100%' }}>
                    {visibleItems.map((item, index) =>
                        renderItem(item, startIndex + index)
                    )}
                </div>
            </div>
        </div>
    );
};

const ResourceAssignment = ({ projectId, scheduleId }) => {
    // State variables (same as original)
    const [activities, setActivities] = useState([]);
    const [resources, setResources] = useState({
        labor: [],
        equipment: [],
        material: []
    });
    const [assignments, setAssignments] = useState([]);
    const [selectedActivity, setSelectedActivity] = useState(null);
    const [isAssignmentModalOpen, setIsAssignmentModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [activeResourceType, setActiveResourceType] = useState('labor');
    const [activityFilter, setActivityFilter] = useState('');
    const [resourceFilter, setResourceFilter] = useState('');
    const [dateRangeFilter, setDateRangeFilter] = useState({ start: null, end: null });
    const [assignmentForm, setAssignmentForm] = useState({
        activity: '',
        resource_type: 'labor',
        resource_id: '',
        units: 1,
        start_date: '',
        end_date: '',
        cost_rate: 0,
    });
    const [editingAssignment, setEditingAssignment] = useState(null);
    const [viewMode, setViewMode] = useState('activity');
    const [expandedActivities, setExpandedActivities] = useState(new Set());

    // New state variables for pagination and virtual list
    const [page, setPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const [containerHeight, setContainerHeight] = useState(500);

    // Adjust container height based on screen size
    useEffect(() => {
        const updateHeight = () => {
            const isMobile = window.innerWidth < 768;
            setContainerHeight(isMobile ? 300 : 500);
            setItemsPerPage(isMobile ? 5 : 10);
        };

        updateHeight();
        window.addEventListener('resize', updateHeight);
        return () => window.removeEventListener('resize', updateHeight);
    }, []);

    useEffect(() => {
        if (projectId && scheduleId) {
            fetchData();
        }
    }, [projectId, scheduleId]);

    const fetchData = async () => {
        setIsLoading(true);
        setError(null);

        try {
            // Parallel API calls
            const [activitiesRes, assignmentsRes, laborRes, equipmentRes, materialRes] = await Promise.all([
                api.get(`projects/${projectId}/activities/`, { params: { schedule: scheduleId } }),
                api.get(`projects/${projectId}/resource-assignments/`),
                api.get(`projects/${projectId}/resources/`, { params: { type: 'labor' } }),
                api.get(`projects/${projectId}/resources/`, { params: { type: 'equipment' } }),
                api.get(`projects/${projectId}/resources/`, { params: { type: 'material' } })
            ]);

            setActivities(activitiesRes.data.results || []);
            setAssignments(assignmentsRes.data.results || []);

            // Organize resources by type
            setResources({
                labor: laborRes.data.results || [],
                equipment: equipmentRes.data.results || [],
                material: materialRes.data.results || []
            });
        } catch (error) {
            console.error('Error fetching data:', error);
            setError('Failed to load resource assignment data');
        } finally {
            setIsLoading(false);
        }
    };

    const getTenantId = useCallback(() => {
        const tenantId =
            localStorage.getItem('tenantIdentifier') ||
            (typeof secureLocalStorage !== 'undefined' && secureLocalStorage.getItem('currentTenantId')) ||
            localStorage.getItem('tenantId') ||
            sessionStorage.getItem('tenantId') ||
            sessionStorage.getItem('tenantIdentifier');

        let finalTenantId = tenantId;

        if (!finalTenantId) {
            try {
                const userData = JSON.parse(localStorage.getItem('userData') || '{}');
                finalTenantId = userData.tenant?.id || userData.tenantId;

                if (finalTenantId) {
                    localStorage.setItem('tenantId', finalTenantId);
                }
            } catch (e) {
                console.error("Error parsing user data:", e);
            }
        }

        return finalTenantId;
    }, []);

    const handleCreateAssignment = async () => {
        try {
            // Validate form
            if (!assignmentForm.activity || !assignmentForm.resource_id) {
                toast.error('Please select both an activity and a resource');
                return;
            }

            if (!assignmentForm.start_date || !assignmentForm.end_date) {
                toast.error('Please select start and end dates');
                return;
            }

            if (new Date(assignmentForm.start_date) > new Date(assignmentForm.end_date)) {
                toast.error('Start date cannot be after end date');
                return;
            }

            const finalTenantId = getTenantId();

            if (!finalTenantId) {
                toast.error('Unable to determine tenant ID. Please try logging out and in again.');
                return;
            }

            // Calculate total cost
            const startDate = new Date(assignmentForm.start_date);
            const endDate = new Date(assignmentForm.end_date);
            const days = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24)) || 1;
            const total_cost = assignmentForm.cost_rate * assignmentForm.units * days;

            // Create the config with tenant ID in header
            const config = {
                headers: {
                    'X-Tenant-ID': finalTenantId
                }
            };

            // Create the payload with both tenant and tenant_id for compatibility
            const payload = {
                activity: assignmentForm.activity,
                resource_type: assignmentForm.resource_type,
                resource_id: assignmentForm.resource_id,
                units: assignmentForm.units,
                start_date: assignmentForm.start_date,
                end_date: assignmentForm.end_date,
                cost_rate: assignmentForm.cost_rate,
                total_cost: total_cost,
                project: projectId,
                schedule: scheduleId,
                tenant: finalTenantId,
                tenant_id: finalTenantId
            };

            let response;
            if (editingAssignment) {
                response = await api.put(
                    `projects/${projectId}/resource-assignments/${editingAssignment.id}/`,
                    payload,
                    config
                );

                // Update assignments in state
                setAssignments(prevAssignments =>
                    prevAssignments.map(a => a.id === editingAssignment.id ? response.data : a)
                );

                toast.success('Resource assignment updated successfully');
            } else {
                response = await api.post(
                    `projects/${projectId}/resource-assignments/`,
                    payload,
                    config
                );

                // Add new assignment to state
                setAssignments(prevAssignments => [...prevAssignments, response.data]);
                toast.success('Resource assigned successfully');
            }

            // Reset form and close modal
            resetAssignmentForm();
            setIsAssignmentModalOpen(false);
            setEditingAssignment(null);

        } catch (error) {
            console.error('Error creating assignment:', error);
            toast.error(error.response?.data?.detail || 'Failed to assign resource');
        }
    };

    const handleDeleteAssignment = async (assignmentId) => {
        if (!window.confirm('Are you sure you want to remove this resource assignment?')) {
            return;
        }

        try {
            await api.delete(`projects/${projectId}/resource-assignments/${assignmentId}/`);
            // Remove assignment from state
            setAssignments(assignments.filter(a => a.id !== assignmentId));
            toast.success('Resource assignment removed');
        } catch (error) {
            console.error('Error deleting assignment:', error);
            toast.error('Failed to remove resource assignment');
        }
    };

    const resetAssignmentForm = () => {
        setAssignmentForm({
            activity: selectedActivity?.id || '',
            resource_type: 'labor',
            resource_id: '',
            units: 1,
            start_date: selectedActivity?.planned_start?.slice(0, 10) || '',
            end_date: selectedActivity?.planned_finish?.slice(0, 10) || '',
            cost_rate: 0,
        });
    };

    const openAssignmentModal = (activity = null) => {
        setSelectedActivity(activity);

        if (activity) {
            setAssignmentForm(prev => ({
                ...prev,
                activity: activity.id,
                start_date: activity.planned_start?.slice(0, 10) || '',
                end_date: activity.planned_finish?.slice(0, 10) || ''
            }));
        } else {
            resetAssignmentForm();
        }

        setIsAssignmentModalOpen(true);
    };

    const editAssignment = (assignment) => {
        setEditingAssignment(assignment);
        const activity = activities.find(a => a.id === assignment.activity);
        setSelectedActivity(activity);

        setAssignmentForm({
            activity: assignment.activity,
            resource_type: assignment.resource_type,
            resource_id: assignment.resource_id,
            units: assignment.units,
            start_date: assignment.start_date?.slice(0, 10) || '',
            end_date: assignment.end_date?.slice(0, 10) || '',
            cost_rate: assignment.cost_rate || 0,
        });

        setActiveResourceType(assignment.resource_type);
        setIsAssignmentModalOpen(true);
    };

    const handleResourceTypeChange = (type) => {
        setActiveResourceType(type);
        setAssignmentForm(prev => ({
            ...prev,
            resource_type: type,
            resource_id: '' // Reset selected resource when type changes
        }));
    };

    const toggleExpandActivity = (activityId) => {
        const newExpanded = new Set(expandedActivities);
        if (expandedActivities.has(activityId)) {
            newExpanded.delete(activityId);
        } else {
            newExpanded.add(activityId);
        }
        setExpandedActivities(newExpanded);
    };

    // Memoized filtered assignments for better performance
    const filteredAssignments = useMemo(() => {
        return assignments.filter(assignment => {
            const activity = activities.find(a => a.id === assignment.activity);
            const resource = resources[assignment.resource_type]?.find(r => r.id === assignment.resource_id);

            // Activity name filter
            const matchesActivity = !activityFilter ||
                (activity && activity.name.toLowerCase().includes(activityFilter.toLowerCase()));

            // Resource name filter
            const matchesResource = !resourceFilter ||
                (resource && resource.name.toLowerCase().includes(resourceFilter.toLowerCase()));

            // Date range filter
            const startDate = new Date(assignment.start_date);
            const endDate = new Date(assignment.end_date);
            const matchesDateRange = (
                !dateRangeFilter.start || startDate >= new Date(dateRangeFilter.start)
            ) && (
                    !dateRangeFilter.end || endDate <= new Date(dateRangeFilter.end)
                );

            return matchesActivity && matchesResource && matchesDateRange;
        });
    }, [assignments, activities, resources, activityFilter, resourceFilter, dateRangeFilter]);

    // Memoized filtered activities for better performance
    const filteredActivities = useMemo(() => {
        return activities.filter(activity => {
            return (!activityFilter ||
                activity.name.toLowerCase().includes(activityFilter.toLowerCase()));
        });
    }, [activities, activityFilter]);

    // Get resource name by type and id
    const getResourceName = useCallback((type, id) => {
        const resource = resources[type]?.find(r => r.id === id);
        return resource ? resource.name : 'Unknown Resource';
    }, [resources]);

    // Get activity name by id
    const getActivityName = useCallback((id) => {
        const activity = activities.find(a => a.id === id);
        return activity ? activity.name : 'Unknown Activity';
    }, [activities]);

    // Get Resource Icon by type
    const getResourceIcon = (type) => {
        switch (type) {
            case 'labor':
                return <Users className="h-4 w-4" />;
            case 'equipment':
                return <Wrench className="h-4 w-4" />;
            case 'material':
                return <Package className="h-4 w-4" />;
            default:
                return null;
        }
    };

    // Format date string
    const formatDate = (dateString) => {
        if (!dateString) return 'N/A';
        return new Date(dateString).toLocaleDateString();
    };

    // Get assignments for an activity
    const getActivityAssignments = useCallback((activityId) => {
        return assignments.filter(a => a.activity === activityId);
    }, [assignments]);

    // Calculate total cost for an assignment
    const calculateTotalCost = useCallback((assignment) => {
        const startDate = new Date(assignment.start_date);
        const endDate = new Date(assignment.end_date);
        const days = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24)) || 1;
        return assignment.cost_rate * assignment.units * days;
    }, []);

    // Duration in days
    const getDurationDays = (startDate, endDate) => {
        if (!startDate || !endDate) return 0;
        const start = new Date(startDate);
        const end = new Date(endDate);
        return Math.ceil((end - start) / (1000 * 60 * 60 * 24)) || 1;
    };

    // Resource utilization by date - for future visualization
    const calculateResourceUtilization = useCallback((resourceId, resourceType) => {
        const resourceAssignments = assignments.filter(
            a => a.resource_id === resourceId && a.resource_type === resourceType
        );

        // Calculate utilization percentage - simplified for this example
        return resourceAssignments.length > 0 ?
            Math.min(100, resourceAssignments.length * 25) : 0;
    }, [assignments]);

    // Pagination helpers
    useEffect(() => {
        if (viewMode === 'activity') {
            setTotalPages(Math.ceil(filteredActivities.length / itemsPerPage));
        } else {
            setTotalPages(Math.ceil(resources[activeResourceType]?.length / itemsPerPage) || 1);
        }
        setPage(1); // Reset to first page when filters change
    }, [filteredActivities, resources, activeResourceType, viewMode, itemsPerPage]);

    const paginatedActivities = useMemo(() => {
        const startIndex = (page - 1) * itemsPerPage;
        return filteredActivities.slice(startIndex, startIndex + itemsPerPage);
    }, [filteredActivities, page, itemsPerPage]);

    const paginatedResources = useMemo(() => {
        const startIndex = (page - 1) * itemsPerPage;
        return resources[activeResourceType]?.slice(startIndex, startIndex + itemsPerPage) || [];
    }, [resources, activeResourceType, page, itemsPerPage]);

    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= totalPages) {
            setPage(newPage);
        }
    };

    // Render assignment table for an activity
    const renderAssignmentTable = (assignments) => {
        return (
            <div className="border-t">
                <table className="w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                        <tr>
                            <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Resource</th>
                            <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Type</th>
                            <th className="px-4 py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Units</th>
                            <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Dates</th>
                            <th className="px-4 py-2 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Cost</th>
                            <th className="px-4 py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {assignments.map(assignment => (
                            <tr key={assignment.id} className="hover:bg-gray-50">
                                <td className="px-4 py-2 whitespace-nowrap">
                                    <div className="flex items-center">
                                        {getResourceIcon(assignment.resource_type)}
                                        <span className="ml-2">{getResourceName(assignment.resource_type, assignment.resource_id)}</span>
                                    </div>
                                </td>
                                <td className="px-4 py-2 whitespace-nowrap">
                                    <Badge variant="outline">
                                        {assignment.resource_type}
                                    </Badge>
                                </td>
                                <td className="px-4 py-2 whitespace-nowrap text-center">
                                    {assignment.units}
                                </td>
                                <td className="px-4 py-2 whitespace-nowrap">
                                    {formatDate(assignment.start_date)} - {formatDate(assignment.end_date)}
                                </td>
                                <td className="px-4 py-2 whitespace-nowrap text-right">
                                    ${calculateTotalCost(assignment).toFixed(2)}
                                </td>
                                <td className="px-4 py-2 whitespace-nowrap text-center">
                                    <div className="flex justify-center space-x-2">
                                        <Button
                                            variant="ghost"
                                            size="sm"
                                            onClick={() => editAssignment(assignment)}
                                        >
                                            <Settings className="h-4 w-4" />
                                        </Button>
                                        <Button
                                            variant="ghost"
                                            size="sm"
                                            onClick={() => handleDeleteAssignment(assignment.id)}
                                            className="text-red-500 hover:text-red-700"
                                        >
                                            <X className="h-4 w-4" />
                                        </Button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    };

    // Render pagination controls
    const renderPagination = () => {
        if (totalPages <= 1) return null;

        return (
            <div className="flex justify-center items-center space-x-2 py-3 border-t">
                <Button
                    variant="outline"
                    size="sm"
                    onClick={() => handlePageChange(page - 1)}
                    disabled={page === 1}
                >
                    Previous
                </Button>
                <span className="text-sm">
                    Page {page} of {totalPages}
                </span>
                <Button
                    variant="outline"
                    size="sm"
                    onClick={() => handlePageChange(page + 1)}
                    disabled={page === totalPages}
                >
                    Next
                </Button>
            </div>
        );
    };

    // Render individual activity card (optimized for virtual list)
    const renderActivityCard = (activity, index) => {
        const activityAssignments = getActivityAssignments(activity.id);
        const isExpanded = expandedActivities.has(activity.id);

        return (
            <Card key={activity.id} className="overflow-hidden mb-4">
                <div
                    className="p-4 flex flex-col sm:flex-row justify-between items-start sm:items-center gap-2 bg-gray-50 cursor-pointer hover:bg-gray-100"
                    onClick={() => toggleExpandActivity(activity.id)}
                >
                    <div className="flex items-center gap-2">
                        {isExpanded ? (
                            <ChevronUp className="h-4 w-4 text-gray-500" />
                        ) : (
                            <ChevronDown className="h-4 w-4 text-gray-500" />
                        )}
                        <h3 className="font-medium">
                            {activity.name}
                        </h3>
                        <Badge variant="outline" className="ml-2 bg-white">
                            {activity.activity_type}
                        </Badge>
                        {activity.is_critical && (
                            <Badge variant="destructive" className="ml-2">
                                Critical Path
                            </Badge>
                        )}
                    </div>

                    <div className="flex flex-wrap items-center gap-2">
                        <span className="text-sm text-gray-500">
                            {formatDate(activity.planned_start)} - {formatDate(activity.planned_finish)}
                        </span>

                        <Badge variant="secondary">
                            {activityAssignments.length} Resource{activityAssignments.length !== 1 ? 's' : ''}
                        </Badge>

                        <Button
                            variant="ghost"
                            size="sm"
                            onClick={(e) => {
                                e.stopPropagation();
                                openAssignmentModal(activity);
                            }}
                        >
                            <Plus className="h-4 w-4 mr-1" />
                            Assign
                        </Button>
                    </div>
                </div>

                {isExpanded && (
                    <CardContent className="p-0">
                        {activityAssignments.length > 0 ? (
                            renderAssignmentTable(activityAssignments)
                        ) : (
                            <div className="p-6 text-center">
                                <p className="text-gray-500">No resources assigned to this activity yet.</p>
                                <Button
                                    onClick={() => openAssignmentModal(activity)}
                                    className="mt-2"
                                >
                                    Assign Resources
                                </Button>
                            </div>
                        )}
                    </CardContent>
                )}
            </Card>
        );
    };

    // Render individual resource card
    const renderResourceCard = (resource, index) => {
        const resourceAssignments = assignments.filter(
            a => a.resource_id === resource.id && a.resource_type === activeResourceType
        );
        const isExpanded = expandedActivities.has(resource.id);
        const utilization = calculateResourceUtilization(resource.id, activeResourceType);

        return (
            <Card key={resource.id} className="overflow-hidden mb-4">
                <div
                    className="p-4 flex flex-col sm:flex-row justify-between items-start sm:items-center gap-2 bg-gray-50 cursor-pointer hover:bg-gray-100"
                    onClick={() => toggleExpandActivity(resource.id)}
                >
                    <div className="flex items-center gap-2">
                        {isExpanded ? (
                            <ChevronUp className="h-4 w-4 text-gray-500" />
                        ) : (
                            <ChevronDown className="h-4 w-4 text-gray-500" />
                        )}
                        <h3 className="font-medium">{resource.name}</h3>
                    </div>

                    <div className="flex flex-wrap items-center gap-2">
                        <div className="w-32">
                            <div className="text-xs text-gray-500 mb-1">Utilization</div>
                            <div className="w-full bg-gray-200 rounded-full h-2.5">
                                <div
                                    className={`h-2.5 rounded-full ${utilization > 80 ? 'bg-red-500' :
                                        utilization > 60 ? 'bg-yellow-500' :
                                            'bg-green-500'
                                        }`}
                                    style={{ width: `${utilization}%` }}
                                ></div>
                            </div>
                        </div>

                        <Badge variant="secondary">
                            {resourceAssignments.length} Assignment{resourceAssignments.length !== 1 ? 's' : ''}
                        </Badge>

                        <Button
                            variant="ghost"
                            size="sm"
                            onClick={(e) => {
                                e.stopPropagation();
                                setAssignmentForm(prev => ({
                                    ...prev,
                                    resource_type: activeResourceType,
                                    resource_id: resource.id
                                }));
                                openAssignmentModal();
                            }}
                        >
                            <Plus className="h-4 w-4 mr-1" />
                            Assign
                        </Button>
                    </div>
                </div>

                {isExpanded && (
                    <CardContent className="p-0">
                        {resourceAssignments.length > 0 ? (
                            <div className="border-t">
                                <table className="w-full divide-y divide-gray-200">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Activity</th>
                                            <th className="px-4 py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Units</th>
                                            <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Dates</th>
                                            <th className="px-4 py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Duration</th>
                                            <th className="px-4 py-2 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Cost</th>
                                            <th className="px-4 py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                        {resourceAssignments.map(assignment => (
                                            <tr key={assignment.id} className="hover:bg-gray-50">
                                                <td className="px-4 py-2 whitespace-nowrap">
                                                    {getActivityName(assignment.activity)}
                                                </td>
                                                <td className="px-4 py-2 whitespace-nowrap text-center">
                                                    {assignment.units}
                                                </td>
                                                <td className="px-4 py-2 whitespace-nowrap">
                                                    {formatDate(assignment.start_date)} - {formatDate(assignment.end_date)}
                                                </td>
                                                <td className="px-4 py-2 whitespace-nowrap text-center">
                                                    {getDurationDays(assignment.start_date, assignment.end_date)} days
                                                </td>
                                                <td className="px-4 py-2 whitespace-nowrap text-right">
                                                    ${calculateTotalCost(assignment).toFixed(2)}
                                                </td>
                                                <td className="px-4 py-2 whitespace-nowrap text-center">
                                                    <div className="flex justify-center space-x-2">
                                                        <Button
                                                            variant="ghost"
                                                            size="sm"
                                                            onClick={() => editAssignment(assignment)}
                                                        >
                                                            <Settings
                                                                className="h-4 w-4" />
                                                        </Button>
                                                        <Button
                                                            variant="ghost"
                                                            size="sm"
                                                            onClick={() => handleDeleteAssignment(assignment.id)}
                                                            className="text-red-500 hover:text-red-700"
                                                        >
                                                            <X className="h-4 w-4" />
                                                        </Button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        ) : (
                            <div className="p-6 text-center">
                                <p className="text-gray-500">This resource is not assigned to any activities yet.</p>
                                <Button
                                    onClick={() => {
                                        setAssignmentForm(prev => ({
                                            ...prev,
                                            resource_type: activeResourceType,
                                            resource_id: resource.id
                                        }));
                                        openAssignmentModal();
                                    }}
                                    className="mt-2"
                                >
                                    Assign to Activity
                                </Button>
                            </div>
                        )}
                    </CardContent>
                )}
            </Card>
        );
    };

    // Loading and error states
    if (isLoading) {
        return (
            <div className="w-full h-64 flex items-center justify-center">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
            </div>
        );
    }

    if (error) {
        return (
            <Alert variant="destructive">
                <AlertCircle className="h-5 w-5" />
                <AlertDescription>{error}</AlertDescription>
            </Alert>
        );
    }

    return (
        <div className="space-y-6">
            {/* Header with view mode switcher */}
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
                <h2 className="text-2xl font-bold">Resource Assignments</h2>

                <div className="flex flex-wrap gap-2">
                    <Button
                        variant={viewMode === 'activity' ? 'default' : 'outline'}
                        onClick={() => setViewMode('activity')}
                        className="flex items-center gap-2"
                    >
                        <Calendar className="h-4 w-4" />
                        Activity View
                    </Button>
                    <Button
                        variant={viewMode === 'resource' ? 'default' : 'outline'}
                        onClick={() => setViewMode('resource')}
                        className="flex items-center gap-2"
                    >
                        <Users className="h-4 w-4" />
                        Resource View
                    </Button>
                    <Button
                        onClick={() => openAssignmentModal()}
                        className={primaryButtonClasses}
                    >
                        <Plus className="h-4 w-4 mr-2" />
                        New Assignment
                    </Button>
                </div>
            </div>

            {/* Filters - responsive design */}
            <Card>
                <CardContent className="p-4">
                    <div className="flex flex-col md:flex-row gap-4 items-end">
                        <div className="w-full md:w-1/3">
                            <Label className="mb-2 block">Activity Filter</Label>
                            <div className="relative">
                                <Search className="absolute left-2 top-3 h-4 w-4 text-gray-400" />
                                <Input
                                    placeholder="Filter by activity name"
                                    value={activityFilter}
                                    onChange={(e) => setActivityFilter(e.target.value)}
                                    className="pl-8"
                                />
                            </div>
                        </div>

                        <div className="w-full md:w-1/3">
                            <Label className="mb-2 block">Resource Filter</Label>
                            <div className="relative">
                                <Search className="absolute left-2 top-3 h-4 w-4 text-gray-400" />
                                <Input
                                    placeholder="Filter by resource name"
                                    value={resourceFilter}
                                    onChange={(e) => setResourceFilter(e.target.value)}
                                    className="pl-8"
                                />
                            </div>
                        </div>

                        <div className="w-full md:w-1/3">
                            <Label className="mb-2 block">Date Range</Label>
                            <div className="flex gap-2">
                                <Input
                                    type="date"
                                    value={dateRangeFilter.start || ''}
                                    onChange={(e) => setDateRangeFilter({ ...dateRangeFilter, start: e.target.value })}
                                    className="w-full"
                                />
                                <span className="self-center">to</span>
                                <Input
                                    type="date"
                                    value={dateRangeFilter.end || ''}
                                    onChange={(e) => setDateRangeFilter({ ...dateRangeFilter, end: e.target.value })}
                                    className="w-full"
                                />
                            </div>
                        </div>

                        <Button
                            variant="outline"
                            onClick={() => {
                                setActivityFilter('');
                                setResourceFilter('');
                                setDateRangeFilter({ start: null, end: null });
                            }}
                            className="whitespace-nowrap self-end"
                        >
                            Clear Filters
                        </Button>
                    </div>
                </CardContent>
            </Card>

            {/* Main content using virtualized lists for efficiency */}
            {viewMode === 'activity' ? (
                // Activity-based view with virtualization for large datasets
                <div className="space-y-4">
                    {filteredActivities.length === 0 ? (
                        <Card>
                            <CardContent className="p-6 text-center">
                                <p className="text-gray-500">
                                    {activities.length === 0
                                        ? "No activities found for this schedule. Create activities first to assign resources."
                                        : "No activities match your filter criteria."
                                    }
                                </p>
                            </CardContent>
                        </Card>
                    ) : (
                        <>
                            {/* Show how many results found */}
                            <div className="text-sm text-gray-500">
                                Showing {paginatedActivities.length} of {filteredActivities.length} activities
                            </div>

                            {/* Activity list - efficient rendering for large datasets */}
                            <div className="space-y-4">
                                {filteredActivities.length > 20 ? (
                                    <VirtualizedList
                                        items={filteredActivities}
                                        renderItem={renderActivityCard}
                                        containerHeight={containerHeight}
                                    />
                                ) : (
                                    paginatedActivities.map((activity, index) => renderActivityCard(activity, index))
                                )}
                            </div>

                            {/* Pagination controls */}
                            {renderPagination()}
                        </>
                    )}
                </div>
            ) : (
                // Resource-based view with virtualization for large datasets
                <Tabs defaultValue="labor" value={activeResourceType} onValueChange={setActiveResourceType} className="w-full">
                    <TabsList className="grid w-full grid-cols-3">
                        <TabsTrigger
                            value="labor"
                            className="flex items-center gap-2"
                        >
                            <Users className="h-4 w-4" />
                            Labor
                        </TabsTrigger>
                        <TabsTrigger
                            value="equipment"
                            className="flex items-center gap-2"
                        >
                            <Wrench className="h-4 w-4" />
                            Equipment
                        </TabsTrigger>
                        <TabsTrigger
                            value="material"
                            className="flex items-center gap-2"
                        >
                            <Package className="h-4 w-4" />
                            Material
                        </TabsTrigger>
                    </TabsList>

                    <TabsContent value={activeResourceType}>
                        {resources[activeResourceType]?.length === 0 ? (
                            <Card>
                                <CardContent className="p-6 text-center">
                                    <p className="text-gray-500">No {activeResourceType} resources found. Add resources first to assign them to activities.</p>
                                </CardContent>
                            </Card>
                        ) : (
                            <>
                                {/* Resource count indicator */}
                                <div className="text-sm text-gray-500 mb-4">
                                    Showing {paginatedResources.length} of {resources[activeResourceType]?.length} {activeResourceType} resources
                                </div>

                                {/* Resource list - efficient rendering for large datasets */}
                                <div className="space-y-4">
                                    {resources[activeResourceType]?.length > 20 ? (
                                        <VirtualizedList
                                            items={resources[activeResourceType]}
                                            renderItem={renderResourceCard}
                                            containerHeight={containerHeight}
                                        />
                                    ) : (
                                        paginatedResources.map((resource, index) => renderResourceCard(resource, index))
                                    )}
                                </div>

                                {/* Pagination controls */}
                                {renderPagination()}
                            </>
                        )}
                    </TabsContent>
                </Tabs>
            )}

            {/* Summary Section - responsive design */}
            <Card>
                <CardHeader>
                    <CardTitle>Assignment Summary</CardTitle>
                </CardHeader>
                <CardContent>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
                        <div className="bg-blue-50 p-4 rounded-lg">
                            <h3 className="text-lg font-medium text-blue-700">Total Assignments</h3>
                            <p className="text-2xl font-bold">{filteredAssignments.length}</p>
                        </div>

                        <div className="bg-green-50 p-4 rounded-lg">
                            <h3 className="text-lg font-medium text-green-700">Resource Types</h3>
                            <div className="flex flex-wrap gap-2 mt-2">
                                <Badge variant="outline" className="bg-white">
                                    <Users className="h-4 w-4 mr-1" />
                                    Labor: {assignments.filter(a => a.resource_type === 'labor').length}
                                </Badge>
                                <Badge variant="outline" className="bg-white">
                                    <Wrench className="h-4 w-4 mr-1" />
                                    Equipment: {assignments.filter(a => a.resource_type === 'equipment').length}
                                </Badge>
                                <Badge variant="outline" className="bg-white">
                                    <Package className="h-4 w-4 mr-1" />
                                    Material: {assignments.filter(a => a.resource_type === 'material').length}
                                </Badge>
                            </div>
                        </div>

                        <div className="bg-amber-50 p-4 rounded-lg">
                            <h3 className="text-lg font-medium text-amber-700">Total Cost</h3>
                            <p className="text-2xl font-bold">
                                ${filteredAssignments.reduce((sum, assignment) => sum + calculateTotalCost(assignment), 0).toFixed(2)}
                            </p>
                        </div>

                        <div className="bg-purple-50 p-4 rounded-lg">
                            <h3 className="text-lg font-medium text-purple-700">Critical Activities</h3>
                            <p className="text-2xl font-bold">
                                {new Set(filteredAssignments
                                    .filter(a => activities.find(act => act.id === a.activity)?.is_critical)
                                    .map(a => a.activity)
                                ).size}
                            </p>
                        </div>
                    </div>
                </CardContent>
            </Card>

            {/* Assignment Modal */}
            <Dialog open={isAssignmentModalOpen} onOpenChange={setIsAssignmentModalOpen}>
                <DialogContent className="sm:max-w-md">
                    <DialogHeader>
                        <DialogTitle>
                            {editingAssignment ? 'Edit Resource Assignment' : 'Assign Resource'}
                        </DialogTitle>
                    </DialogHeader>

                    <div className="space-y-4">
                        {/* Activity selector */}
                        <div>
                            <Label htmlFor="activity">Activity</Label>
                            <Select
                                value={assignmentForm.activity}
                                onValueChange={(value) => setAssignmentForm({ ...assignmentForm, activity: value })}
                                disabled={!!selectedActivity}
                            >
                                <SelectTrigger>
                                    <SelectValue placeholder="Select an activity" />
                                </SelectTrigger>
                                <SelectContent>
                                    {activities.map(activity => (
                                        <SelectItem key={activity.id} value={activity.id}>
                                            {activity.name}
                                        </SelectItem>
                                    ))}
                                </SelectContent>
                            </Select>
                        </div>

                        {/* Resource type tabs */}
                        <div>
                            <Label>Resource Type</Label>
                            <div className="flex border rounded-md overflow-hidden mt-1">
                                <button
                                    type="button"
                                    className={`flex-1 py-2 px-3 text-sm flex justify-center items-center ${activeResourceType === 'labor' ? 'bg-blue-100 text-blue-700' : 'bg-white'
                                        }`}
                                    onClick={() => handleResourceTypeChange('labor')}
                                >
                                    <Users className="h-4 w-4 mr-1" />
                                    Labor
                                </button>
                                <button
                                    type="button"
                                    className={`flex-1 py-2 px-3 text-sm flex justify-center items-center ${activeResourceType === 'equipment' ? 'bg-blue-100 text-blue-700' : 'bg-white'
                                        }`}
                                    onClick={() => handleResourceTypeChange('equipment')}
                                >
                                    <Wrench className="h-4 w-4 mr-1" />
                                    Equipment
                                </button>
                                <button
                                    type="button"
                                    className={`flex-1 py-2 px-3 text-sm flex justify-center items-center ${activeResourceType === 'material' ? 'bg-blue-100 text-blue-700' : 'bg-white'
                                        }`}
                                    onClick={() => handleResourceTypeChange('material')}
                                >
                                    <Package className="h-4 w-4 mr-1" />
                                    Material
                                </button>
                            </div>
                        </div>

                        {/* Resource selector */}
                        <div>
                            <Label htmlFor="resource">Resource</Label>
                            <Select
                                value={assignmentForm.resource_id}
                                onValueChange={(value) => {
                                    // Find the resource to get its default cost rate
                                    const resource = resources[activeResourceType]?.find(r => r.id === value);
                                    const costRate = resource ?
                                        (activeResourceType === 'labor' ? resource.cost_per_hour :
                                            activeResourceType === 'equipment' ? resource.daily_cost :
                                                resource.unit_cost) || 0 : 0;

                                    setAssignmentForm({
                                        ...assignmentForm,
                                        resource_id: value,
                                        cost_rate: costRate
                                    });
                                }}
                            >
                                <SelectTrigger>
                                    <SelectValue placeholder={`Select a ${activeResourceType}`} />
                                </SelectTrigger>
                                <SelectContent>
                                    {resources[activeResourceType]?.map(resource => (
                                        <SelectItem key={resource.id} value={resource.id}>
                                            {resource.name}
                                        </SelectItem>
                                    ))}
                                </SelectContent>
                            </Select>
                        </div>

                        {/* Units */}
                        <div>
                            <Label htmlFor="units">Units</Label>
                            <Input
                                id="units"
                                type="number"
                                min="1"
                                step="1"
                                value={assignmentForm.units}
                                onChange={(e) => setAssignmentForm({
                                    ...assignmentForm,
                                    units: parseInt(e.target.value) || 1
                                })}
                            />
                        </div>

                        {/* Date range */}
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                            <div>
                                <Label htmlFor="start_date">Start Date</Label>
                                <Input
                                    id="start_date"
                                    type="date"
                                    value={assignmentForm.start_date}
                                    onChange={(e) => setAssignmentForm({ ...assignmentForm, start_date: e.target.value })}
                                />
                            </div>
                            <div>
                                <Label htmlFor="end_date">End Date</Label>
                                <Input
                                    id="end_date"
                                    type="date"
                                    value={assignmentForm.end_date}
                                    onChange={(e) => setAssignmentForm({ ...assignmentForm, end_date: e.target.value })}
                                />
                            </div>
                        </div>

                        {/* Cost rate */}
                        <div>
                            <Label htmlFor="cost_rate">
                                Cost Rate ({activeResourceType === 'labor' ? 'per hour' : activeResourceType === 'equipment' ? 'per day' : 'per unit'})
                            </Label>
                            <div className="relative">
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-500">$</span>
                                <Input
                                    id="cost_rate"
                                    type="number"
                                    min="0"
                                    step="0.01"
                                    className="pl-8"
                                    value={assignmentForm.cost_rate}
                                    onChange={(e) => setAssignmentForm({
                                        ...assignmentForm,
                                        cost_rate: parseFloat(e.target.value) || 0
                                    })}
                                />
                            </div>
                        </div>

                        {/* Cost calculation preview */}
                        {assignmentForm.start_date && assignmentForm.end_date && assignmentForm.cost_rate > 0 && (
                            <div className="bg-blue-50 p-3 rounded-md">
                                <h4 className="text-sm font-medium text-blue-700">Cost Calculation</h4>
                                <div className="text-sm mt-1">
                                    <p>
                                        {getDurationDays(assignmentForm.start_date, assignmentForm.end_date)} days ×
                                        {assignmentForm.units} {assignmentForm.units > 1 ? 'units' : 'unit'} ×
                                        ${assignmentForm.cost_rate}/
                                        {activeResourceType === 'labor' ? 'hour' : activeResourceType === 'equipment' ? 'day' : 'unit'}
                                    </p>
                                    <p className="font-bold mt-1">
                                        Total: ${(
                                            getDurationDays(assignmentForm.start_date, assignmentForm.end_date) *
                                            assignmentForm.units *
                                            assignmentForm.cost_rate
                                        ).toFixed(2)}
                                    </p>
                                </div>
                            </div>
                        )}
                    </div>

                    <DialogFooter>
                        <Button className={secondaryButtonClasses} variant="outline" onClick={() => {
                            setIsAssignmentModalOpen(false);
                            setEditingAssignment(null);
                        }}>
                            Cancel
                        </Button>
                        <Button className={primaryButtonClasses} type="button" onClick={handleCreateAssignment}>
                            {editingAssignment ? 'Update Assignment' : 'Assign Resource'}
                        </Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default ResourceAssignment;