import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../components/ui/tabs';
import { Button } from '../components/ui/button';
import { Alert, AlertDescription } from '../components/ui/alert';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../components/ui/select';
import {
  AlertCircle, Menu, X, ChevronDown, BarChart, HardHat,
  FileCheck, Users, ClipboardCheck, AlertTriangle, Clipboard
} from 'lucide-react';
import { useFeatureAccess, QualityFeatureGuard } from '../contexts/FeatureAccessContext';
import SafetyDashboard from './SafetyDashboard';
import VideoAnalysis from './VideoAnalysis';
import SafetyReports from './SafetyReports';
import { SafetyAuditForm } from './Forms/SafetyAuditForm';
import { IncidentForm } from './Forms/IncidentForm';
import { HazardForm } from './Forms/HazardForm';
import SafetyActionButtons from './SafetyActionButtons';
import api from '../services/api';
import HazardsTable from './Tables/HazardsTable';
import IncidentsTable from './Tables/IncidentsTable';
import SafetyAuditsTable from './Tables/SafetyAuditsTable';
import CreateProjectModal from '../components/Projects/CreateProjectModal';
import { primaryButtonClasses } from '../components/common/ButtonStyles';
import InspectionManager from '../components/Projects/Inspections/InspectionManager';
import QualityIssuesManager from '../components/Projects/QualityIssuesManager';
import SafetyTrainingManager from './SafetyTrainingManager';
import TemplateManagementView from './TemplateManagementView';

function Safety() {
  const [selectedProject, setSelectedProject] = useState(null);
  const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('dashboard');
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Form states
  const [isAuditFormOpen, setIsAuditFormOpen] = useState(false);
  const [isIncidentFormOpen, setIsIncidentFormOpen] = useState(false);
  const [isHazardFormOpen, setIsHazardFormOpen] = useState(false);

  const [filteredProjects, setFilteredProjects] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('active');

  const { projectId } = useParams();
  const navigate = useNavigate();

  // Access feature context to get user role
  const { userRole, canAccessQualityFeatures, isSafetyRole, loading: featureLoading } = useFeatureAccess();

  // Determine if user has admin/management permissions
  const canCreateProject = userRole === 'Tenant Admin' || userRole === 'Tenant Admin Safety';
  const isManager = ['Safety Manager', 'Safety Director', 'Chief Safety Officer', 'Tenant Admin Safety', 'Tenant Admin'].includes(userRole);
  const isQualityOfficer = userRole === 'Quality Control Officer';

  // Determine which tabs should be available based on role
  const getAvailableTabs = () => {
    const baseTabs = ['dashboard', 'incidents', 'hazards', 'audits', 'video', 'reports'];

    // Add template management for admins and safety managers
    if (['Tenant Admin', 'Tenant Admin Safety', 'Safety Manager', 'Safety Director'].includes(userRole)) {
      baseTabs.push('templates');
    }

    // Training tab is visible to everyone but with different permissions inside
    baseTabs.push('training');

    // Only show quality tabs for users with quality access
    if (canAccessQualityFeatures && canAccessQualityFeatures()) {
      baseTabs.push('inspections', 'quality-issues');
    }

    return baseTabs;
  };

  // Determine if screen is mobile
  const isMobile = windowWidth < 768;

  // Track window resize for responsive design
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Fetch projects
  useEffect(() => {
    fetchProjects();
  }, []);

  // Handle project selection
  useEffect(() => {
    if (!userRole) return;

    if (projectId && projects.length > 0) {
      const normalizedProjectId = projectId.trim();
      const matchingProject = projects.find(p => p.id.trim() === normalizedProjectId);

      if (!matchingProject) {
        console.error("No matching project found for projectId:", normalizedProjectId);
      }

      setSelectedProject(matchingProject || projects[0]);
    } else if (!projectId && projects.length > 0) {
      setSelectedProject(projects[0]);
    }
  }, [projectId, projects, userRole]);

  // Parse tab from URL hash
  useEffect(() => {
    const hash = window.location.hash.replace('#', '');
    if (hash && getAvailableTabs().includes(hash)) {
      setActiveTab(hash);
    }
  }, [window.location.hash]);

  // Update URL hash when tab changes
  useEffect(() => {
    window.location.hash = activeTab;
  }, [activeTab]);

  // Filter projects by status
  useEffect(() => {
    if (projects.length > 0) {
      let filtered = [];
      switch (selectedStatus) {
        case 'active':
          filtered = projects.filter(project => project.status !== 'Completed');
          break;
        case 'completed':
          filtered = projects.filter(project => project.status === 'Completed');
          break;
        case 'all':
          filtered = projects;
          break;
        default:
          filtered = projects.filter(project => project.status !== 'Completed');
      }
      setFilteredProjects(filtered);

      // Reset selected project if it's not in filtered list
      if (selectedProject && !filtered.find(p => p.id === selectedProject.id)) {
        setSelectedProject(filtered[0] || null);
      }
    }
  }, [projects, selectedStatus]);

  const fetchProjects = async () => {
    try {
      setIsLoading(true);
      const response = await api.get('projects/');
      const projects = response.data.results || [];
      setProjects(projects);

      if (!projectId && projects.length > 0) {
        setSelectedProject(projects[0]);
      }
    } catch (error) {
      console.error('Error fetching projects:', error);
      setProjects([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleProjectCreation = () => {
    setShowCreateModal(false);
    fetchProjects();
  };

  // Form submit handlers
  const handleAuditSubmit = async (data) => {
    try {
      await api.post(`projects/${selectedProject.id}/safety-audits/`, data);
      setIsAuditFormOpen(false);
      setActiveTab('audits');
    } catch (error) {
      console.error('Error submitting audit:', error);
    }
  };

  const handleIncidentSubmit = async (data) => {
    try {
      await api.post(`projects/${selectedProject.id}/safety-incidents/`, data);
      setIsIncidentFormOpen(false);
      setActiveTab('incidents');
    } catch (error) {
      console.error('Error submitting incident:', error);
    }
  };

  const handleHazardSubmit = async (data) => {
    try {
      await api.post(`projects/${selectedProject.id}/safety-hazards/`, data);
      setIsHazardFormOpen(false);
      setActiveTab('hazards');
    } catch (error) {
      console.error('Error submitting hazard:', error);
    }
  };

  const handleProjectChange = (projectId) => {
    const project = projects.find((p) => p.id === projectId);
    if (!project) {
      console.error("No matching project found for ID:", projectId);
      return;
    }
    setSelectedProject(project);
    navigate(`/safety/${projectId}`);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    if (isMobile) {
      setIsMobileMenuOpen(false);
    }
  };

  // Render mobile menu with role-aware options
  const renderMobileMenu = () => {
    return (
      <div className="md:hidden">
        <div className="flex justify-between items-center mb-4">
          <Button variant="ghost" onClick={toggleMobileMenu}>
            {isMobileMenuOpen ? (
              <X className="h-6 w-6" />
            ) : (
              <Menu className="h-6 w-6" />
            )}
          </Button>
          <h2 className="text-xl font-semibold">
            {activeTab.charAt(0).toUpperCase() + activeTab.slice(1).replace(/-/g, ' ')}
          </h2>
          <div className="w-8"></div> {/* Spacer for alignment */}
        </div>

        {isMobileMenuOpen && (
          <div className="bg-white shadow-lg rounded-lg p-4 mb-4 border">
            <div className="space-y-2">
              {getAvailableTabs().map(tab => (
                <button
                  key={tab}
                  className={`w-full text-left p-2 rounded ${activeTab === tab ? 'bg-blue-100 text-blue-800' : ''
                    }`}
                  onClick={() => handleTabChange(tab)}
                >
                  {tab === 'dashboard' && <BarChart className="h-4 w-4 inline mr-2" />}
                  {tab === 'incidents' && <AlertCircle className="h-4 w-4 inline mr-2" />}
                  {tab === 'hazards' && <AlertTriangle className="h-4 w-4 inline mr-2" />}
                  {tab === 'audits' && <ClipboardCheck className="h-4 w-4 inline mr-2" />}
                  {tab === 'templates' && <Clipboard className="h-4 w-4 inline mr-2" />}
                  {tab === 'inspections' && <FileCheck className="h-4 w-4 inline mr-2" />}
                  {tab === 'quality-issues' && <AlertCircle className="h-4 w-4 inline mr-2" />}
                  {tab === 'video' && <HardHat className="h-4 w-4 inline mr-2" />}
                  {tab === 'reports' && <BarChart className="h-4 w-4 inline mr-2" />}
                  {tab === 'training' && <Users className="h-4 w-4 inline mr-2" />}
                  {tab.charAt(0).toUpperCase() + tab.slice(1).replace(/-/g, ' ')}
                </button>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  // Action buttons based on role permissions
  const renderActionButtons = () => {
    // Only Safety Manager, Officers, and admins can report incidents/hazards
    const canReportSafetyIssues = ['Safety Manager', 'Safety Officer', 'Tenant Admin Safety', 'Tenant Admin', 'Safety Director', 'Chief Safety Officer'].includes(userRole);

    // Only Safety Manager and admins can conduct audits
    const canConductAudits = ['Safety Manager', 'Tenant Admin Safety', 'Tenant Admin', 'Safety Director', 'Chief Safety Officer'].includes(userRole);

    return (
      <div className="flex flex-wrap gap-2 mb-4">
        <SafetyActionButtons
          onAuditClick={canConductAudits ? () => setIsAuditFormOpen(true) : null}
          onIncidentClick={canReportSafetyIssues ? () => setIsIncidentFormOpen(true) : null}
          onHazardClick={canReportSafetyIssues ? () => setIsHazardFormOpen(true) : null}
        />
      </div>
    );
  };

  if (isLoading || featureLoading) {
    return <div className="flex items-center justify-center h-screen">Loading...</div>;
  }

  return (
    <div className="space-y-4 p-4 md:p-6">
      {/* Header section */}
      <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4 mb-4">
        <h1 className="text-2xl md:text-3xl font-bold text-gray-900">Safety & Quality</h1>
        {canCreateProject && (
          <Button
            onClick={() => setShowCreateModal(true)}
            className={`${primaryButtonClasses} w-full md:w-auto`}
          >
            Create New Project
          </Button>
        )}
      </div>

      {/* Project selector */}
      {(isMobile) ? (
        <div className="md:hidden mb-4">
          <Select
            value={selectedProject?.id || ''}
            onValueChange={handleProjectChange}
          >
            <SelectTrigger className="w-full">
              <SelectValue placeholder="Select project..." />
            </SelectTrigger>
            <SelectContent>
              {filteredProjects.map((project) => (
                <SelectItem key={project.id} value={project.id}>
                  {project.name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      ) : (
        <div className="hidden md:flex gap-4 mb-4">
          <div className="flex-1">
            <Select
              value={selectedProject?.id || ''}
              onValueChange={handleProjectChange}
            >
              <SelectTrigger className="w-full">
                <SelectValue placeholder="Select project..." />
              </SelectTrigger>
              <SelectContent>
                {filteredProjects.map((project) => (
                  <SelectItem key={project.id} value={project.id}>
                    {project.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>

          <Select
            value={selectedStatus}
            onValueChange={setSelectedStatus}
          >
            <SelectTrigger className="w-48">
              <SelectValue placeholder="Filter by status" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="active">Active Projects</SelectItem>
              <SelectItem value="completed">Completed Projects</SelectItem>
              <SelectItem value="all">All Projects</SelectItem>
            </SelectContent>
          </Select>
        </div>
      )}

      {/* Mobile navigation menu */}
      {renderMobileMenu()}

      {selectedProject && (
        <>
          {/* Action buttons - conditionally rendered based on role */}
          {renderActionButtons()}

          {/* Desktop Tabs */}
          <div className="hidden md:block">
            <Tabs value={activeTab} onValueChange={handleTabChange} className="w-full">
              <TabsList className="w-full justify-start overflow-x-auto flex-nowrap">
                {getAvailableTabs().map(tab => (
                  <TabsTrigger key={tab} value={tab}>
                    {tab.charAt(0).toUpperCase() + tab.slice(1).replace(/-/g, ' ')}
                  </TabsTrigger>
                ))}
              </TabsList>

              <TabsContent value="dashboard">
                <SafetyDashboard
                  project={selectedProject}
                  userRole={userRole}
                  showQualityMetrics={canAccessQualityFeatures && canAccessQualityFeatures()}
                />
              </TabsContent>

              <TabsContent value="incidents">
                <IncidentsTable project={selectedProject} userRole={userRole} />
              </TabsContent>

              <TabsContent value="hazards">
                <HazardsTable project={selectedProject} userRole={userRole} />
              </TabsContent>

              <TabsContent value="audits">
                <SafetyAuditsTable project={selectedProject} userRole={userRole} />
              </TabsContent>

              <TabsContent value="templates">
                <TemplateManagementView project={selectedProject} />
              </TabsContent>

              {canAccessQualityFeatures && canAccessQualityFeatures() && (
                <>
                  <TabsContent value="inspections">
                    <InspectionManager projectId={selectedProject.id} userRole={userRole} />
                  </TabsContent>

                  <TabsContent value="quality-issues">
                    <QualityIssuesManager projectId={selectedProject.id} userRole={userRole} />
                  </TabsContent>
                </>
              )}

              <TabsContent value="video">
                <VideoAnalysis project={selectedProject} />
              </TabsContent>

              <TabsContent value="reports">
                <SafetyReports project={selectedProject} userRole={userRole} />
              </TabsContent>

              <TabsContent value="training">
                <SafetyTrainingManager project={selectedProject} userRole={userRole} />
              </TabsContent>
            </Tabs>
          </div>

          {/* Mobile Tab Content */}
          <div className="md:hidden">
            {activeTab === 'dashboard' && (
              <SafetyDashboard
                project={selectedProject}
                userRole={userRole}
                showQualityMetrics={canAccessQualityFeatures && canAccessQualityFeatures()}
              />
            )}
            {activeTab === 'incidents' && <IncidentsTable project={selectedProject} userRole={userRole} />}
            {activeTab === 'hazards' && <HazardsTable project={selectedProject} userRole={userRole} />}
            {activeTab === 'audits' && <SafetyAuditsTable project={selectedProject} userRole={userRole} />}
            {activeTab === 'templates' && <TemplateManagementView project={selectedProject} />}

            {canAccessQualityFeatures && canAccessQualityFeatures() && (
              <>
                {activeTab === 'inspections' && <InspectionManager projectId={selectedProject.id} userRole={userRole} />}
                {activeTab === 'quality-issues' && <QualityIssuesManager projectId={selectedProject.id} userRole={userRole} />}
              </>
            )}

            {activeTab === 'video' && <VideoAnalysis project={selectedProject} />}
            {activeTab === 'reports' && <SafetyReports project={selectedProject} userRole={userRole} />}
            {activeTab === 'training' && <SafetyTrainingManager project={selectedProject} userRole={userRole} />}
          </div>

          {/* Forms - conditionally rendered based on open state */}
          {isAuditFormOpen && (
            <SafetyAuditForm
              project={selectedProject}
              isOpen={isAuditFormOpen}
              onClose={() => setIsAuditFormOpen(false)}
              onSubmit={handleAuditSubmit}
            />
          )}
          {isIncidentFormOpen && (
            <IncidentForm
              project={selectedProject}
              isOpen={isIncidentFormOpen}
              onClose={() => setIsIncidentFormOpen(false)}
              onSubmit={handleIncidentSubmit}
            />
          )}
          {isHazardFormOpen && (
            <HazardForm
              project={selectedProject}
              isOpen={isHazardFormOpen}
              onClose={() => setIsHazardFormOpen(false)}
              onSubmit={handleHazardSubmit}
            />
          )}
        </>
      )}
      {showCreateModal && (
        <CreateProjectModal
          closeModal={() => setShowCreateModal(false)}
          onProjectCreated={handleProjectCreation}
        />
      )}
    </div>
  );
}

export default Safety;