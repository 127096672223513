import React from 'react';
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
} from "../components/ui/alert-dialog";
import { Alert, AlertDescription } from "../components/ui/alert";
import { Button } from "../components/ui/button";
import { XCircle, AlertCircle, Clock, RefreshCw } from 'lucide-react';
import { primaryButtonClasses } from './common/ButtonStyles';
import CancellationDialog from './CancellationDialog';
import api from '../services/api';

const TrialBanner = ({
    daysRemaining,
    onCancel,
    onChoosePlan,
    onReactivate,
    status,
    trialEndDate,
    isLoading
}) => {
    const [showCancelDialog, setShowCancelDialog] = React.useState(false);

    const handleCancellation = async (cancellationDetails) => {
        try {
            await api.post('cancel-subscription/', {
                ...cancellationDetails,
                is_trial_cancellation: true
            });
            setShowCancelDialog(false);
            onCancel();
        } catch (error) {
            console.error('Failed to cancel trial:', error);
        }
    };

    if (status === 'canceled') {
        return (
            <div className="w-full bg-gray-50 border-b border-gray-200">
                <div className="container max-w-7xl mx-auto py-2 px-4">
                    <div className="flex items-center justify-between">
                        <div className="flex items-center gap-2">
                            <AlertCircle className="h-4 w-4 text-gray-600" />
                            <span className="text-sm text-gray-600">
                                Your trial has been canceled. You can reactivate until {new Date(trialEndDate).toLocaleDateString()}.
                            </span>
                        </div>
                        <div className="flex items-center gap-3">
                            <Button
                                size="sm"
                                onClick={onReactivate}
                                disabled={isLoading}
                                className="bg-green-600 hover:bg-green-700"
                            >
                                <RefreshCw className={`h-4 w-4 mr-1 ${isLoading ? 'animate-spin' : ''}`} />
                                Reactivate Trial
                            </Button>
                            <Button
                                size="sm"
                                onClick={onChoosePlan}
                                disabled={isLoading}
                                className={primaryButtonClasses}
                            >
                                Upgrade
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <>
            <div className="w-full bg-blue-50 border-b border-blue-100">
                <div className="container max-w-7xl mx-auto py-2 px-4">
                    <div className="flex items-center justify-between">
                        <div className="flex items-center gap-2">
                            <Clock className="h-4 w-4 text-blue-600" />
                            <span className={`text-sm font-medium ${daysRemaining <= 3 ? 'text-red-600' : 'text-blue-600'}`}>
                                You have {daysRemaining} {daysRemaining === 1 ? 'day' : 'days'} left in your trial period.
                            </span>
                        </div>
                        <div className="flex items-center gap-3">
                            <Button
                                variant="ghost"
                                size="sm"
                                onClick={() => setShowCancelDialog(true)}
                                disabled={isLoading}
                                className="text-gray-600 hover:text-red-600 hover:bg-red-50"
                            >
                                <XCircle className="h-4 w-4 mr-1" />
                                Cancel Trial
                            </Button>
                            <Button
                                size="sm"
                                onClick={onChoosePlan}
                                disabled={isLoading}
                                className={`${daysRemaining <= 3
                                    ? 'bg-red-600 hover:bg-red-700'
                                    : 'bg-blue-600 hover:bg-blue-700'
                                    }`}
                            >
                                Upgrade
                            </Button>
                        </div>
                    </div>
                </div>
            </div>

            <CancellationDialog
                isOpen={showCancelDialog}
                onClose={() => setShowCancelDialog(false)}
                onConfirm={handleCancellation}
                title="Cancel Your Trial"
                description="Are you sure you want to cancel your trial? You can reactivate it any time before the trial period ends."
                confirmButtonText="Yes, Cancel Trial"
                confirmButtonClass="bg-red-600 hover:bg-red-700"
                isTrialCancellation={true}
            />
        </>
    );
};

export default TrialBanner;