import React, { useState, useEffect } from 'react';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import { Card, CardContent, CardHeader, CardTitle, CardDescription, CardFooter } from "../../../components/ui/cardui";
import { Button } from "../../../components/ui/button";
import { Textarea } from "../../../components/ui/textarea";
import { useToast } from "../../../components/ui/use-toast";
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogTrigger } from "../../../components/ui/alert-dialog";
import { Label } from "../../../components/ui/label";
import { Loader2, AlertCircle, ArrowLeft, Mail, RefreshCw, UserPlus } from 'lucide-react';
import api from '../../../services/api';
import { primaryButtonClasses, secondaryButtonClasses } from '../../common/ButtonStyles';


const ErrorState = ({
    error,
    canRequestNewLink,
    onRequestNewLink,
    onNavigateBack,
    invitation,
    searchParams
}) => {
    const isAcceptedInvitation = invitation?.status === 'accepted';
    const isUnauthenticated = !localStorage.getItem('token');
    const shouldShowAccountCreation = isAcceptedInvitation && isUnauthenticated;
    const isExpiredToken = error?.toLowerCase().includes('invitation link has expired');
    const showNewLinkRequest = isExpiredToken;

    return (
        <div className="min-h-screen bg-gray-50 px-4 py-6 sm:px-6 flex items-center justify-center">
            <Card className="w-full max-w-lg">
                <CardHeader className="text-center border-b pb-6">
                    <div className="mx-auto w-12 h-12 rounded-full bg-red-100 flex items-center justify-center mb-4">
                        <AlertCircle className="h-6 w-6 text-red-600" />
                    </div>
                    <CardTitle className="text-xl font-semibold text-gray-900">
                        {shouldShowAccountCreation ? 'Complete Your Registration' : 'Bid Response Error'}
                    </CardTitle>
                    <CardDescription className="mt-2 text-gray-600">
                        {shouldShowAccountCreation
                            ? "You've accepted this invitation. Create an account to proceed with your bid."
                            : error}
                    </CardDescription>
                </CardHeader>

                <CardContent className="pt-6">
                    <div className="space-y-4">
                        {shouldShowAccountCreation ? (
                            <div className="bg-blue-50 border border-blue-200 rounded-lg p-4">
                                <h3 className="font-medium text-blue-900 mb-2">Next Steps:</h3>
                                <ul className="text-sm text-blue-800 space-y-2">
                                    <li>• Create your account to access the bid portal</li>
                                    <li>• Your acceptance has been recorded</li>
                                    <li>• You'll be able to submit your bid after registration</li>
                                </ul>
                            </div>
                        ) : (
                            <div className="bg-amber-50 border border-amber-200 rounded-lg p-4">
                                <h3 className="font-medium text-amber-900 mb-2">What you can do:</h3>
                                <ul className="text-sm text-amber-800 space-y-2">
                                    <li>• Contact support for assistance</li>
                                    {showNewLinkRequest && (
                                        <li>• Request a new invitation link</li>
                                    )}
                                </ul>
                            </div>
                        )}
                    </div>
                </CardContent>

                <CardFooter className="flex flex-col sm:flex-row gap-3 pt-6">
                    {shouldShowAccountCreation ? (
                        <Button
                            onClick={() => {
                                window.location.href = `/create-bid-account/${invitation.bid_request}/${invitation.id}?token=${searchParams.get('token')}`;
                            }}
                            className="w-full sm:w-auto bg-blue-600 hover:bg-blue-700"
                        >
                            <UserPlus className="mr-2 h-4 w-4" />
                            Create Account
                        </Button>
                    ) : (
                        <>
                            {showNewLinkRequest && (
                                <Button
                                    onClick={onRequestNewLink}
                                    className="w-full sm:w-auto bg-blue-600 hover:bg-blue-700"
                                    variant="default"
                                >
                                    <RefreshCw className="mr-2 h-4 w-4" />
                                    Request New Link
                                </Button>
                            )}
                            <Button
                                variant="outline"
                                className="w-full sm:w-auto"
                                onClick={() => window.location.href = 'mailto:hello@buildifypro.com'}
                            >
                                <Mail className="mr-2 h-4 w-4" />
                                Contact Support
                            </Button>
                        </>
                    )}
                </CardFooter>
            </Card>
        </div>
    );
};


const BidResponse = () => {
    const { bidId, invitationId } = useParams();
    const [searchParams] = useSearchParams();
    const [loading, setLoading] = useState(true);
    const { toast } = useToast();
    const [state, setState] = useState({
        loading: true,
        submitting: false,
        bidDetails: null,
        invitation: null,
        notes: '',
        error: null,
        tradeEstimates: []
    });

    const token = searchParams.get('token');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await api.get(`bids/public-details/?token=${token}`);

                setState(prev => ({
                    ...prev,
                    loading: false,
                    bidDetails: response.data.bid_request,
                    invitation: response.data.invitation,
                    tradeEstimates: response.data.invitation?.trade_estimate || []
                }));
            } catch (err) {
                setState(prev => ({
                    ...prev,
                    loading: false,
                    error: err.response?.data?.error || 'Failed to load bid details'
                }));
            }
        };

        fetchData();
    }, [token]);


    const handleResponse = async (status) => {
        setState(prev => ({ ...prev, submitting: true }));
        try {
            window.location.href = `/create-bid-account/${bidId}/${invitationId}?token=${token}&status=${status}&notes=${encodeURIComponent(state.notes)}`;
        } catch (err) {
            toast({
                title: "Error",
                description: "Failed to process response",
                variant: "destructive"
            });
            setState(prev => ({ ...prev, submitting: false }));
        }
    };


    if (state.loading) {
        return (
            <div className="flex justify-center items-center min-h-screen">
                <Loader2 className="h-8 w-8 animate-spin" />
            </div>
        );
    }

    const handleRequestNewLink = async () => {
        try {


            const response = await api.post('bids/request-new-link/', {
                invitation_id: invitationId
            });


            toast({
                title: "Success",
                description: "Renewal request submitted successfully"
            });
        } catch (err) {
            console.error("Full error:", err);
            console.error("Error response:", err.response?.data);
            toast({
                title: "Error",
                description: err.response?.data?.error || "Failed to request new link",
                variant: "destructive"
            });
        }
    };

    if (state.error) {
        return <ErrorState
            error={state.error}
            invitation={state.invitation}
            searchParams={searchParams}
            onRequestNewLink={handleRequestNewLink}
        />;
    }

    return (
        <div className="max-w-2xl mx-auto p-6">
            <Card>
                <CardHeader>
                    <CardTitle>Bid Invitation</CardTitle>
                    <CardDescription>
                        {state.bidDetails?.title}
                    </CardDescription>
                </CardHeader>

                <CardContent className="space-y-6">
                    {state.bidDetails?.trade_estimates?.length > 0 && (
                        <div className="bg-blue-50 border border-blue-200 rounded-lg p-4">
                            <h3 className="text-sm font-medium text-blue-900">Trade Estimate</h3>
                            {state.bidDetails.trade_estimates.map((estimate) => (
                                <div key={estimate.trade_id} className="mt-2">
                                    <p className="text-base font-medium text-blue-800">
                                        ${estimate.estimated_cost.toLocaleString()}
                                    </p>
                                    {estimate.notes && (
                                        <p className="text-sm text-blue-700 mt-1">
                                            {estimate.notes}
                                        </p>
                                    )}
                                </div>
                            ))}
                            <p className="text-xs text-blue-600 mt-2">
                                This is the estimated cost for your trade work
                            </p>
                        </div>
                    )}
                    <div>
                        <h3 className="text-sm font-medium text-gray-500">Project Description</h3>
                        <p className="mt-1">{state.bidDetails?.description}</p>
                    </div>

                    <div>
                        <h3 className="text-sm font-medium text-gray-500">Submission Deadline</h3>
                        <p className="mt-1">
                            {state.bidDetails?.submission_deadline &&
                                new Date(state.bidDetails.submission_deadline).toLocaleDateString()}
                        </p>
                    </div>

                    <div className="space-y-2">
                        <Label>Additional Notes (Optional)</Label>
                        <Textarea
                            value={state.notes}
                            onChange={(e) => setState(prev => ({ ...prev, notes: e.target.value }))}
                            placeholder="Add any notes or questions..."
                        />
                    </div>

                    <div className="flex justify-end space-x-4 pt-4">
                        <AlertDialog>
                            <AlertDialogTrigger asChild>
                                <Button variant="outline" disabled={state.submitting}>
                                    Decline
                                </Button>
                            </AlertDialogTrigger>
                            <AlertDialogContent>
                                <AlertDialogHeader>
                                    <AlertDialogTitle>Decline Bid Invitation?</AlertDialogTitle>
                                    <AlertDialogDescription>
                                        Are you sure you want to decline this bid invitation? This action cannot be undone.
                                    </AlertDialogDescription>
                                </AlertDialogHeader>
                                <AlertDialogFooter>
                                    <AlertDialogCancel>Cancel</AlertDialogCancel>
                                    <AlertDialogAction
                                        onClick={() => handleResponse('declined')}
                                        className="bg-red-600 hover:bg-red-700"
                                    >
                                        Decline
                                    </AlertDialogAction>
                                </AlertDialogFooter>
                            </AlertDialogContent>
                        </AlertDialog>

                        <AlertDialog>
                            <AlertDialogTrigger asChild>
                                <Button disabled={state.submitting}>
                                    Accept
                                </Button>
                            </AlertDialogTrigger>
                            <AlertDialogContent>
                                <AlertDialogHeader>
                                    <AlertDialogTitle>Accept Bid Invitation?</AlertDialogTitle>
                                    <AlertDialogDescription>
                                        By accepting this invitation, you agree to submit a bid proposal by the deadline.
                                    </AlertDialogDescription>
                                </AlertDialogHeader>
                                <AlertDialogFooter>
                                    <AlertDialogCancel>Cancel</AlertDialogCancel>
                                    <AlertDialogAction onClick={() => handleResponse('accepted')}>
                                        Accept
                                    </AlertDialogAction>
                                </AlertDialogFooter>
                            </AlertDialogContent>
                        </AlertDialog>
                    </div>
                </CardContent>
            </Card>
        </div>
    );
};
export default BidResponse;