import React, { useState, useEffect } from 'react';
import { Button } from '../components/ui/button';
import { useToast } from '../components/ui/use-toast';
import { AlertCircle, Camera } from 'lucide-react';
import { cn } from "../lib/utils";
import api from '../services/api';

const ChecklistTab = ({ audit, project, responses, onResponseUpdate }) => {
    const [template, setTemplate] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const { toast } = useToast();

    useEffect(() => {
        const fetchTemplate = async () => {
            try {
                setIsLoading(true);
                const response = await api.get(
                    `projects/${project.id}/safety-audits/${audit.id}/template/`
                );

                console.log('Raw template data:', response.data);
                const templateData = response.data;

                // Normalize template structure
                let normalizedTemplate;

                if (Array.isArray(templateData.items)) {
                    // If template has a flat items array
                    normalizedTemplate = {
                        name: templateData.name || 'Audit Template',
                        description: templateData.description || '',
                        categories: [{
                            id: 'default',
                            name: 'Items',
                            items: templateData.items
                        }]
                    };
                } else if (templateData.categories && Array.isArray(templateData.categories)) {
                    // If template has categories
                    normalizedTemplate = templateData;
                } else {
                    // Fallback structure
                    normalizedTemplate = {
                        name: templateData.name || 'Audit Template',
                        description: templateData.description || '',
                        categories: [{
                            id: 'default',
                            name: 'Items',
                            items: []
                        }]
                    };
                }

                console.log('Normalized template:', normalizedTemplate);
                setTemplate(normalizedTemplate);
            } catch (error) {
                // Error handling...
            } finally {
                setIsLoading(false);
            }
        };

        fetchTemplate();
    }, [audit.id, project.id, toast]);


    if (isLoading) {
        return (
            <div className="flex items-center justify-center h-full">
                <div className="text-center p-8">
                    <div className="animate-spin h-8 w-8 border-4 border-blue-500 rounded-full border-t-transparent mx-auto mb-4"></div>
                    <p>Loading template...</p>
                </div>
            </div>
        );
    }

    if (!template) {
        return (
            <div className="flex items-center justify-center h-full">
                <div className="text-center p-8">
                    <AlertCircle className="h-12 w-12 text-amber-500 mx-auto mb-4" />
                    <h3 className="text-lg font-medium mb-2">Template Not Found</h3>
                    <p className="text-gray-500">
                        This audit doesn't have a valid template associated with it.
                        Please contact your administrator.
                    </p>
                </div>
            </div>
        );
    }

    // Debug - check structure of template data to understand what's missing
    console.log('Template structure:', JSON.stringify(template, null, 2));

    // Check if categories exist but are empty
    const hasEmptyCategories = template.categories?.length > 0 &&
        template.categories.every(cat => !cat.items || cat.items.length === 0);

    if (hasEmptyCategories) {
        return (
            <div className="flex items-center justify-center h-full">
                <div className="text-center p-8">
                    <AlertCircle className="h-12 w-12 text-amber-500 mx-auto mb-4" />
                    <h3 className="text-lg font-medium mb-2">No Audit Items Found</h3>
                    <p className="text-gray-500">
                        This template has categories but no audit items.
                        Please contact your administrator to add items to this template.
                    </p>
                </div>
            </div>
        );
    }

    return (
        <div className="space-y-6">
            {template.categories?.map(category => (
                <div key={category.id} className="bg-white rounded-lg border p-4">
                    <h3 className="text-lg font-bold mb-4">{category.name}</h3>
                    {category.description && (
                        <p className="text-sm text-gray-500 mb-4">{category.description}</p>
                    )}

                    {(!category.items || category.items.length === 0) ? (
                        <p className="text-sm text-gray-500">No items in this category</p>
                    ) : (
                        <div className="space-y-4">
                            {category.items?.map(item => (
                                <ChecklistItem
                                    key={item.id}
                                    item={item}
                                    response={responses[item.id]}
                                    onResponse={onResponseUpdate}
                                    project={project}
                                    audit={audit}
                                />
                            ))}
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

const ChecklistItem = ({ item, response, onResponse, project, audit }) => {
    const [isUploading, setIsUploading] = useState(false);
    const [localNotes, setLocalNotes] = useState(response?.notes || '');
    const { toast } = useToast();

    useEffect(() => {
        setLocalNotes(response?.notes || '');
    }, [response?.notes]);

    const handleNotesChange = async (e) => {
        const newNotes = e.target.value;
        setLocalNotes(newNotes);

        try {
            // Use the onResponse function passed from parent
            await onResponse(item.id, response?.status || 'non_compliant', newNotes);
        } catch (error) {
            console.error('Error saving notes:', error);
            toast({
                title: "Error",
                description: "Failed to save notes.",
                variant: "destructive"
            });
        }
    };

    const handleStatusChange = async (status) => {
        try {
            console.log(`Changing status for item ${item.id} to ${status}`);
            // Keep existing notes if available
            const notesToSend = localNotes || response?.notes || '';
            const updatedResponse = await onResponse(item.id.toString(), status, notesToSend);
            console.log(`Response updated:`, updatedResponse);
        } catch (error) {
            console.error('Error updating status:', error);
            toast({
                title: "Error",
                description: "Failed to update status.",
                variant: "destructive"
            });
        }
    };



    const handlePhotoUpload = async (file) => {
        if (!response || !response.id) {
            // If no response exists yet, create one first
            try {
                const newResponse = await onResponse(item.id, 'non_compliant', localNotes);
                if (!newResponse || !newResponse.id) {
                    throw new Error("Could not create response");
                }
                uploadPhoto(file, newResponse.id);
            } catch (error) {
                console.error('Error creating response for photo upload:', error);
                toast({
                    title: "Error",
                    description: "Failed to associate photo with item.",
                    variant: "destructive"
                });
            }
        } else {
            uploadPhoto(file, response.id);
        }
    };

    const uploadPhoto = async (file, responseId) => {
        try {
            setIsUploading(true);
            const formData = new FormData();
            formData.append('photo', file);

            const uploadResponse = await api.post(
                `projects/${project.id}/safety-audits/${audit.id}/responses/${responseId}/upload_photo/`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            );

            // Refresh the response data to include the new photo
            await onResponse(item.id, 'non_compliant', localNotes);

            toast({
                title: "Success",
                description: "Photo uploaded successfully."
            });
        } catch (error) {
            console.error('Error uploading photo:', error);
            toast({
                title: "Error",
                description: "Failed to upload photo.",
                variant: "destructive"
            });
        } finally {
            setIsUploading(false);
        }
    };

    return (
        <div className="bg-white p-4 rounded-lg border">
            <div className="flex justify-between items-start gap-4">
                <div className="flex-1">
                    <p className="font-medium">{item.description}</p>
                    {item.guidance_notes && (
                        <p className="text-sm text-gray-500 mt-1">{item.guidance_notes}</p>
                    )}
                </div>
                <div className="flex space-x-2 shrink-0">
                    <Button
                        size="sm"
                        variant="outline"
                        className={cn(
                            "transition-colors",
                            response?.status === 'compliant' && "bg-green-600 text-white hover:bg-green-700"
                        )}
                        onClick={() => handleStatusChange('compliant')}
                    >
                        Compliant
                    </Button>
                    <Button
                        size="sm"
                        variant="outline"
                        className={cn(
                            "transition-colors",
                            response?.status === 'non_compliant' && "bg-red-600 text-white hover:bg-red-700"
                        )}
                        onClick={() => handleStatusChange('non_compliant')}
                    >
                        Non-Compliant
                    </Button>
                </div>
            </div>

            {response?.status === 'non_compliant' && (
                <div className="mt-4 space-y-3">
                    <textarea
                        className="w-full p-2 border rounded"
                        placeholder="Add notes..."
                        value={localNotes}
                        onChange={handleNotesChange}
                    />
                    <div className="flex items-center space-x-2">
                        <input
                            type="file"
                            id={`photo-upload-${item.id}`}
                            className="hidden"
                            accept="image/*"
                            onChange={(e) => {
                                if (e.target.files?.[0]) {
                                    handlePhotoUpload(e.target.files[0]);
                                }
                            }}
                        />
                        <Button
                            size="sm"
                            variant="outline"
                            disabled={isUploading}
                            onClick={() => document.getElementById(`photo-upload-${item.id}`).click()}
                        >
                            <Camera className="w-4 h-4 mr-2" />
                            {isUploading ? 'Uploading...' : 'Add Photo'}
                        </Button>
                    </div>

                    {/* Photo gallery */}
                    {response?.photos && response.photos.length > 0 && (
                        <div className="grid grid-cols-4 gap-2">
                            {response.photos.map(photo => (
                                <div key={photo.id} className="relative">
                                    <img
                                        src={photo.url}
                                        alt="Evidence"
                                        className="w-full h-24 object-cover rounded"
                                    />
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default ChecklistTab;