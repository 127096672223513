import React, { useState, useEffect, useCallback } from 'react';
import api from '../../services/api';
import {
    BriefcaseIcon,
    EnvelopeIcon,
    BellIcon,
    MagnifyingGlassIcon,
    Bars3Icon,
    PaperClipIcon,
    ExclamationTriangleIcon,
    CheckCircleIcon,
    SparklesIcon,
    XMarkIcon,
    ChartBarIcon,
    ChevronDownIcon,
    ChevronUpIcon,
    AdjustmentsHorizontalIcon,
    UserGroupIcon,
    PhoneIcon,
    ClockIcon
} from '@heroicons/react/24/outline';
import { format } from 'date-fns';

import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogFooter
} from "../../components/ui/dialog";
import { Input } from "../../components/ui/input";
import { Label } from "../../components/ui/label";
import { Button } from "../../components/ui/button";

import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "../../components/ui/select";

const CommunicationSearchPanel = ({ isOpen, setIsOpen, activeProject, onResultSelect }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [dateFrom, setDateFrom] = useState('');
    const [dateTo, setDateTo] = useState('');
    const [commType, setCommType] = useState('');
    const [hasAttachments, setHasAttachments] = useState(false);
    const [linkedToTask, setLinkedToTask] = useState(false);
    const [isRead, setIsRead] = useState('');

    const [searchResults, setSearchResults] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    const [noResults, setNoResults] = useState(false);

    const handleSearch = async () => {
        if (!searchTerm.trim() && !dateFrom && !dateTo && !commType && !hasAttachments && !linkedToTask && isRead === '') {
            return;
        }

        setIsSearching(true);
        setNoResults(false);

        try {
            // Build query parameters
            const params = new URLSearchParams();
            if (searchTerm) params.append('q', searchTerm);
            if (dateFrom) params.append('date_from', dateFrom);
            if (dateTo) params.append('date_to', dateTo);
            if (commType) params.append('type', commType);
            if (hasAttachments) params.append('has_attachments', 'true');
            if (linkedToTask) params.append('linked_to_task', 'true');
            if (isRead !== '') params.append('is_read', isRead);


            const endpoint = activeProject
                ? `search/communications/?project=${activeProject.id}&${params.toString()}`
                : `search/communications/?${params.toString()}`;

            const response = await api.get(endpoint);

            const results = response.data.results || [];
            setSearchResults(results);
            setNoResults(results.length === 0);
        } catch (error) {
            console.error('Error searching communications:', error);
            setSearchResults([]);
            setNoResults(true);
        } finally {
            setIsSearching(false);
        }
    };

    const handleResultClick = (result) => {
        if (onResultSelect) {
            onResultSelect(result);
        }
        setIsOpen(false);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSearch();
        }
    };

    const formatDate = (dateString) => {
        if (!dateString) return 'No date';

        try {
            const date = new Date(dateString);
            // Check if date is valid
            if (isNaN(date.getTime())) {
                return 'Invalid date';
            }
            return format(date, 'MMM d, yyyy');
        } catch (error) {
            console.error('Error formatting date:', error);
            return 'Invalid date';
        }
    };

    return (
        <Dialog open={isOpen} onOpenChange={setIsOpen}>
            <DialogContent className="sm:max-w-md md:max-w-xl">
                <DialogHeader>
                    <DialogTitle>Search Communications</DialogTitle>
                </DialogHeader>

                <div className="p-4">
                    <div className="mb-6">
                        <div className="relative">
                            <Input
                                type="text"
                                placeholder="Search communications..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                onKeyDown={handleKeyDown}
                                className="pl-10"
                            />
                            <MagnifyingGlassIcon className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-gray-400" />
                        </div>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
                        <div>
                            <Label htmlFor="date-from">From Date</Label>
                            <Input
                                id="date-from"
                                type="date"
                                value={dateFrom}
                                onChange={(e) => setDateFrom(e.target.value)}
                            />
                        </div>
                        <div>
                            <Label htmlFor="date-to">To Date</Label>
                            <Input
                                id="date-to"
                                type="date"
                                value={dateTo}
                                onChange={(e) => setDateTo(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
                        <div>
                            <Label htmlFor="comm-type">Communication Type</Label>
                            <Select value={commType} onValueChange={setCommType}>
                                <SelectTrigger>
                                    <SelectValue placeholder="All Types" />
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectItem value="all">All Types</SelectItem>
                                    <SelectItem value="general">General</SelectItem>
                                    <SelectItem value="rfi">RFI</SelectItem>
                                    <SelectItem value="change_order">Change Order</SelectItem>
                                    <SelectItem value="issue">Issue</SelectItem>
                                </SelectContent>
                            </Select>
                        </div>
                        <div>
                            <Label htmlFor="read-status">Read Status</Label>
                            <Select value={isRead} onValueChange={setIsRead}>
                                <SelectTrigger>
                                    <SelectValue placeholder="Any Status" />
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectItem value="any">Any Status</SelectItem>
                                    <SelectItem value="true">Read</SelectItem>
                                    <SelectItem value="false">Unread</SelectItem>
                                </SelectContent>
                            </Select>
                        </div>
                    </div>

                    <div className="flex flex-wrap gap-4 mb-6">
                        <div className="flex items-center space-x-2">
                            <input
                                type="checkbox"
                                id="has-attachments"
                                checked={hasAttachments}
                                onChange={(e) => setHasAttachments(e.target.checked)}
                                className="h-4 w-4 rounded border-gray-300"
                            />
                            <Label htmlFor="has-attachments">Has Attachments</Label>
                        </div>
                        <div className="flex items-center space-x-2">
                            <input
                                type="checkbox"
                                id="linked-to-task"
                                checked={linkedToTask}
                                onChange={(e) => setLinkedToTask(e.target.checked)}
                                className="h-4 w-4 rounded border-gray-300"
                            />
                            <Label htmlFor="linked-to-task">Linked to Tasks</Label>
                        </div>
                    </div>

                    <div className="flex justify-end space-x-2 mb-6">
                        <Button
                            type="button"
                            variant="outline"
                            onClick={() => {
                                setSearchTerm('');
                                setDateFrom('');
                                setDateTo('');
                                setCommType('');
                                setHasAttachments(false);
                                setLinkedToTask(false);
                                setIsRead('');
                            }}
                        >
                            Clear Filters
                        </Button>
                        <Button
                            type="button"
                            onClick={handleSearch}
                            disabled={isSearching}
                        >
                            {isSearching ? 'Searching...' : 'Search'}
                        </Button>
                    </div>

                    <div className="border-t pt-4">
                        {isSearching ? (
                            <div className="flex justify-center items-center h-32">
                                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
                            </div>
                        ) : noResults ? (
                            <div className="text-center py-8 text-gray-500">
                                <p>No results found</p>
                            </div>
                        ) : searchResults.length > 0 ? (
                            <div>
                                <h3 className="font-medium mb-2">Search Results</h3>
                                <div className="max-h-60 overflow-y-auto">
                                    {searchResults.map(result => (
                                        <div
                                            key={result.id}
                                            className="py-3 px-2 border-b cursor-pointer hover:bg-gray-50"
                                            onClick={() => handleResultClick(result)}
                                        >
                                            <div className="flex justify-between">
                                                <span className="font-medium">{result.subject}</span>
                                                <span className="text-sm text-gray-500">
                                                    {formatDate(result.date_created)}
                                                </span>
                                            </div>
                                            <div className="text-sm text-gray-600 mt-1">
                                                From: {result.sender_name}
                                                {result.receiver_name && ` To: ${result.receiver_name}`}
                                            </div>
                                            <div className="text-sm text-gray-600 mt-1 line-clamp-2">
                                                {result.content}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default CommunicationSearchPanel;