import React from 'react';
import { Card, CardHeader, CardTitle, CardContent, CardFooter } from '../../components/ui/cardui';
import { Badge } from '../../components/ui/badge';
import { Button } from '../../components/ui/button';

const TopRisksTable = ({ risks, projectId, onViewRisk }) => {
    // Get variant for severity
    const getSeverityVariant = (severity) => {
        switch (severity) {
            case 'LOW': return 'success';
            case 'MEDIUM': return 'warning';
            case 'HIGH': return 'destructive';
            case 'CRITICAL': return 'default';
            default: return 'secondary';
        }
    };

    return (
        <Card className="h-full">
            <CardHeader>
                <CardTitle>Top Risk Priorities</CardTitle>
            </CardHeader>
            <CardContent className="p-0">
                {risks.length === 0 ? (
                    <div className="flex justify-center items-center py-6">
                        <p className="text-muted-foreground">No risks to display.</p>
                    </div>
                ) : (
                    <div className="overflow-x-auto">
                        <table className="w-full">
                            <thead>
                                <tr className="bg-muted">
                                    <th className="p-2 text-left border">Risk</th>
                                    <th className="p-2 text-left border">Severity</th>
                                    <th className="p-2 text-left border">Score</th>
                                    <th className="p-2 text-left border">Status</th>
                                    <th className="p-2 text-left border">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {risks.map(risk => (
                                    <tr key={risk.id} className="border-b hover:bg-muted/50">
                                        <td className="p-2 border">
                                            <div className="flex flex-col">
                                                <span className="font-medium">{risk.title}</span>
                                                <span className="text-xs text-muted-foreground">{risk.category_name}</span>
                                            </div>
                                        </td>
                                        <td className="p-2 border">
                                            <Badge variant={getSeverityVariant(risk.severity)}>
                                                {risk.severity}
                                            </Badge>
                                        </td>
                                        <td className="p-2 border font-medium">
                                            {risk.risk_score}
                                        </td>
                                        <td className="p-2 border">
                                            {risk.status}
                                        </td>
                                        <td className="p-2 border">
                                            <Button
                                                variant="outline"
                                                size="sm"
                                                onClick={() => {
                                                    onViewRisk(risk);
                                                }}
                                            >
                                                View
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </CardContent>
            <CardFooter className="flex justify-center border-t py-2">
                <Button
                    variant="link"
                    onClick={() => window.location.href = `/projects/${projectId}/risks/risks-register`}
                >
                    View All Risks
                </Button>
            </CardFooter>
        </Card>
    );
};

export default TopRisksTable;