import React, { useState, useEffect } from 'react';
import api from '../../services/api';
import { PlusIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import RFIApproval from './RFIApproval';
import { Alert, AlertDescription } from "../ui/alert";
import { useFeatureAccess, FEATURES } from '../../contexts/FeatureAccessContext.js';
import { primaryButtonClasses } from '../common/ButtonStyles.js';

const RFITable = ({ projectId, configureRequest }) => {
  const [rfis, setRFIs] = useState([]);
  const [filterStatus, setFilterStatus] = useState('all');
  const [currentPage, setCurrentPage] = useState(1);
  const [rfisPerPage] = useState(10);
  const [selectedRFI, setSelectedRFI] = useState(null); // State for selected RFI
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [hasPermission, setHasPermission] = useState(true);
  const [totalRfis, setTotalRfis] = useState(0);

  // Use the FeatureAccessContext for consistent permission checking
  const { hasFeatureAccess } = useFeatureAccess();
  const canCreateRFI = hasFeatureAccess(FEATURES.PROJECT_MANAGEMENT);

  useEffect(() => {
    const fetchRFIs = async () => {
      setLoading(true);
      try {
        const response = await api.get(
          `projects/${projectId}/rfis/`,
          configureRequest ? configureRequest() : {}
        );

        // Handle the response structure with 'rfis' and 'total'
        if (response.data && response.data.rfis) {
          setRFIs(response.data.rfis);
          setTotalRfis(response.data.total || 0);
        } else {
          // Fallback for direct array response
          setRFIs(response.data);
          setTotalRfis(response.data.length || 0);
        }

        setHasPermission(true);
        setError(null);
      } catch (err) {
        console.error("Error fetching RFIs:", err);
        if (err.response?.status === 403) {
          setHasPermission(false);
          setError("You don't have permission to access RFIs for this project.");
        } else {
          setError("Failed to load RFIs. Please try again later.");
        }
      } finally {
        setLoading(false);
      }
    };

    if (projectId) {
      fetchRFIs();
    }
  }, [projectId, configureRequest]);

  const filteredRFIs = rfis.filter(rfi =>
    filterStatus === 'all' || rfi.status === filterStatus
  );

  const displayedRFIs = filteredRFIs.slice(
    (currentPage - 1) * rfisPerPage, currentPage * rfisPerPage
  );

  const openModal = (rfi) => {
    setSelectedRFI(rfi);
    setIsModalOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setSelectedRFI(null);
    setIsModalOpen(false);
  };

  if (loading) {
    return <div className="p-4">Loading RFIs...</div>;
  }

  if (!hasPermission) {
    return (
      <Alert>
        <AlertDescription>
          {error || "You don't have permission to access RFIs for this project."}
        </AlertDescription>
      </Alert>
    );
  }

  if (error) {
    return (
      <Alert>
        <AlertDescription>
          {error}
        </AlertDescription>
      </Alert>
    );
  }

  return (
    <div className="bg-white shadow-md rounded-lg overflow-hidden">
      <div className="flex justify-between items-center p-4 bg-gray-50">
        <h2 className="text-xl font-semibold">RFIs</h2>
        <div className="flex space-x-2">
          <select
            className="border rounded-md p-2"
            value={filterStatus}
            onChange={(e) => setFilterStatus(e.target.value)}
          >
            <option value="all">All Status</option>
            <option value="open">Pending</option>
            <option value="pending">Approved</option>
            <option value="closed">Closed</option>
          </select>

          {canCreateRFI && (
            <Link
              to={`/projects/${projectId}/rfis/create`}
              className={primaryButtonClasses}
            >
              <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
              New RFI
            </Link>
          )}
        </div>
      </div>

      {displayedRFIs.length === 0 ? (
        <div className="text-center py-10">
          <p className="text-gray-500">No RFIs found matching the current filter.</p>
          {canCreateRFI && (
            <p className="mt-2 text-sm text-gray-400">
              You can create a new RFI to request information from project stakeholders.
            </p>
          )}
        </div>
      ) : (
        <>
          <table className="w-full">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">RFI Number</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Title</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Submission Date</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Deadline</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {displayedRFIs.map((rfi) => (
                <tr key={rfi.id}>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <button onClick={() => openModal(rfi)} className="text-blue-600 hover:underline">
                      {rfi.rfi_number}
                    </button>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">{rfi.title}</td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${rfi.status === 'open' ? 'bg-green-100 text-green-800' :
                      rfi.status === 'pending' ? 'bg-yellow-100 text-yellow-800' :
                        'bg-red-100 text-red-800'
                      }`}>
                      {rfi.status}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">{new Date(rfi.submission_date).toLocaleDateString()} {new Date(rfi.submission_date).toLocaleTimeString()}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{new Date(rfi.response_deadline).toLocaleDateString()} {new Date(rfi.response_deadline).toLocaleTimeString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
            <div className="flex-1 flex justify-between sm:hidden">
              <button
                onClick={() => setCurrentPage(page => Math.max(page - 1, 1))}
                disabled={currentPage === 1}
                className={`relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white ${currentPage === 1 ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-50'}`}
              >
                Previous
              </button>
              <button
                onClick={() => setCurrentPage(page => Math.min(page + 1, Math.ceil(filteredRFIs.length / rfisPerPage)))}
                disabled={currentPage >= Math.ceil(filteredRFIs.length / rfisPerPage)}
                className={`ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white ${currentPage >= Math.ceil(filteredRFIs.length / rfisPerPage) ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-50'}`}
              >
                Next
              </button>
            </div>
            <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
              <p className="text-sm text-gray-700">
                Showing <span className="font-medium">{(currentPage - 1) * rfisPerPage + 1}</span> to <span className="font-medium">{Math.min(currentPage * rfisPerPage, filteredRFIs.length)}</span> of <span className="font-medium">{filteredRFIs.length}</span> results
              </p>
              <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                <button
                  onClick={() => setCurrentPage(page => Math.max(page - 1, 1))}
                  disabled={currentPage === 1}
                  className={`relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 ${currentPage === 1 ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-50'}`}
                >
                  <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                </button>
                <button
                  onClick={() => setCurrentPage(page => Math.min(page + 1, Math.ceil(filteredRFIs.length / rfisPerPage)))}
                  disabled={currentPage >= Math.ceil(filteredRFIs.length / rfisPerPage)}
                  className={`relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 ${currentPage >= Math.ceil(filteredRFIs.length / rfisPerPage) ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-50'}`}
                >
                  <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </nav>
            </div>
          </div>
        </>
      )}

      {/* Modal for RFI Details */}
      {isModalOpen && selectedRFI && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-3xl relative">
            <button onClick={closeModal} className="absolute top-4 right-4 text-gray-400 hover:text-gray-600">
              X
            </button>
            <RFIApproval rfi={selectedRFI} onApprovalUpdate={() => {/* Handle update here */ }} onClose={closeModal} />
          </div>
        </div>
      )}
    </div>
  );
};

export default RFITable;