import React from 'react';
import { Card, CardContent } from "../../../../components/ui/cardui";
import { Input } from "../../../../components/ui/input";
import { Textarea } from "../../../../components/ui/textarea";
import { Label } from "../../../../components/ui/label";
import { Button } from "../../../../components/ui/button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../../../components/ui/select";
import { Checkbox } from "../../../../components/ui/checkbox";
import { formatDisplayDate } from '../../../../utils/bidUtils';
import { primaryButtonClasses } from '../../../common/ButtonStyles';
import TradeSelector from '../TradeSelector';
import BidderProfileSelector from '../BidderProfileSelector';
import { DollarSign } from 'lucide-react';

const BidDetailsTab = ({
    bid,
    isEditing,
    formData,
    formErrors,
    handleInputChange,
    handleStatusChange,
    handleSubmit,
    trades,
    selectedProfiles,
    handleProfileSelect,
    setFormData
}) => {
    const handleTradeEstimateChange = (tradeId, field, value) => {
        setFormData(prev => {
            // Get current trade estimates array or initialize if empty
            const currentEstimates = prev.trade_estimates || [];

            // Find index of existing estimate for this trade
            const estimateIndex = currentEstimates.findIndex(est => est.trade?.id === tradeId);

            // Create new estimate array
            let newEstimates;
            if (estimateIndex >= 0) {
                // Update existing estimate
                newEstimates = currentEstimates.map((est, index) =>
                    index === estimateIndex
                        ? { ...est, [field]: value }
                        : est
                );
            } else {
                // Add new estimate
                newEstimates = [
                    ...currentEstimates,
                    {
                        trade: { id: tradeId },
                        [field]: value
                    }
                ];
            }

            return {
                ...prev,
                trade_estimates: newEstimates
            };
        });
    };



    const renderTradeEstimate = (tradeId) => {
        const trade = trades.find(t => t.id === tradeId);
        // Find the estimate from formData.trade_estimates array
        const estimate = formData.trade_estimates?.find(est => est.trade?.id === tradeId) || {
            trade: { id: tradeId },
            estimated_cost: 0,
            notes: ''
        };

        // Ensure the estimated_cost is a number
        const cost = parseFloat(estimate.estimated_cost || 0);

        return (
            <div className="space-y-4 mt-4">
                <div>
                    <Label>Estimated Cost</Label>
                    <div className="relative">
                        <DollarSign className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-gray-500" />
                        {isEditing ? (
                            <Input
                                type="number"
                                value={estimate.estimated_cost}
                                onChange={(e) => handleTradeEstimateChange(tradeId, 'estimated_cost', e.target.value)}
                                placeholder="Enter estimated cost"
                                className="pl-8"
                            />
                        ) : (
                            <p className="text-sm sm:text-base mt-1">
                                ${cost.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                            </p>
                        )}
                    </div>
                </div>
                <div>
                    <Label>Notes</Label>
                    {isEditing ? (
                        <Textarea
                            value={estimate.notes || ''}
                            onChange={(e) => handleTradeEstimateChange(tradeId, 'notes', e.target.value)}
                            placeholder="Add any notes about the estimate"
                            rows={3}
                        />
                    ) : (
                        <p className="text-sm sm:text-base mt-1 whitespace-pre-wrap">
                            {estimate.notes || 'No notes provided'}
                        </p>
                    )}
                </div>
            </div>
        );
    };

    return (
        <Card>
            <CardContent className="pt-6">
                {isEditing ? (
                    <form onSubmit={handleSubmit} className="space-y-6">
                        <div className="space-y-4">
                            <div className="space-y-2">
                                <Label htmlFor="title">Title</Label>
                                <Input
                                    id="title"
                                    name="title"
                                    value={formData.title}
                                    onChange={handleInputChange}
                                    className={`w-full ${formErrors.title ? 'border-red-500' : ''}`}
                                />
                                {formErrors.title && (
                                    <p className="text-sm text-red-500">{formErrors.title}</p>
                                )}
                            </div>

                            <div className="space-y-2">
                                <Label htmlFor="description">Description</Label>
                                <Textarea
                                    id="description"
                                    name="description"
                                    value={formData.description}
                                    onChange={handleInputChange}
                                    className={`w-full min-h-[100px] ${formErrors.description ? 'border-red-500' : ''}`}
                                    rows={4}
                                />
                                {formErrors.description && (
                                    <p className="text-sm text-red-500">{formErrors.description}</p>
                                )}
                            </div>

                            <div className="space-y-2">
                                <Label htmlFor="status">Status</Label>
                                <Select
                                    value={formData.status}
                                    onValueChange={handleStatusChange}
                                >
                                    <SelectTrigger className="w-full">
                                        <SelectValue placeholder="Select status" />
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectItem value="draft">Draft</SelectItem>
                                        <SelectItem value="open_for_submission">Open for Submission</SelectItem>
                                        <SelectItem value="evaluation_in_progress">Under Evaluation</SelectItem>
                                        <SelectItem value="awarded">Awarded</SelectItem>
                                        <SelectItem value="closed">Closed</SelectItem>
                                    </SelectContent>
                                </Select>
                            </div>

                            <div className="space-y-4">
                                <div>
                                    <Label>Required Trades</Label>
                                    <div className="mt-2">
                                        <TradeSelector
                                            trades={trades}
                                            formData={formData}
                                            onTradeSelect={(trade) => {
                                                const isSelected = formData.required_trades.includes(trade.id);
                                                if (isSelected) {
                                                    const updatedTrades = formData.required_trades.filter(id => id !== trade.id);
                                                    handleInputChange({
                                                        target: {
                                                            name: 'required_trades',
                                                            value: updatedTrades
                                                        }
                                                    });
                                                } else {
                                                    handleInputChange({
                                                        target: {
                                                            name: 'required_trades',
                                                            value: [...formData.required_trades, trade.id]
                                                        }
                                                    });
                                                }
                                            }}
                                        />
                                    </div>
                                </div>

                                {formData.required_trades.map((tradeId) => {
                                    const trade = trades.find(t => t.id === tradeId);
                                    if (!trade) return null;

                                    return (
                                        <div key={tradeId} className="p-4 border rounded-lg space-y-3">
                                            <Label className="text-base sm:text-lg font-medium">
                                                {trade.name}
                                            </Label>
                                            {renderTradeEstimate(tradeId)}
                                            <div className="mt-6">
                                                <Label className="text-base">Select Contractors</Label>
                                                <BidderProfileSelector
                                                    trade={trade}
                                                    onSelect={(profileInfo) => handleProfileSelect(tradeId, profileInfo)}
                                                    selectedProfiles={selectedProfiles[tradeId] || []}
                                                />
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>

                            <div className="space-y-2">
                                <Label htmlFor="submission_deadline">Submission Deadline</Label>
                                <Input
                                    id="submission_deadline"
                                    name="submission_deadline"
                                    type="date"
                                    value={formData.submission_deadline}
                                    onChange={handleInputChange}
                                    className={`w-full ${formErrors.submission_deadline ? 'border-red-500' : ''}`}
                                />
                                {formErrors.submission_deadline && (
                                    <p className="text-sm text-red-500">{formErrors.submission_deadline}</p>
                                )}
                            </div>

                            <div className="flex items-center space-x-2">
                                <Checkbox
                                    id="notify"
                                    checked={formData.notify_subcontractors}
                                    onCheckedChange={(checked) => {
                                        handleInputChange({
                                            target: {
                                                name: 'notify_subcontractors',
                                                value: checked
                                            }
                                        });
                                    }}
                                />
                                <Label htmlFor="notify" className="text-sm sm:text-base">
                                    Notify contractors of updates {selectedProfiles &&
                                        `(${Object.values(selectedProfiles).flat().length} contractors selected)`}
                                </Label>
                            </div>

                            <div className="pt-4">
                                <Button
                                    className={`${primaryButtonClasses} w-full sm:w-auto`}
                                    type="submit"
                                >
                                    Save Changes
                                </Button>
                            </div>
                        </div>
                    </form>
                ) : (
                    <div className="space-y-6">
                        <div>
                            <h3 className="text-sm font-medium text-gray-500">Title</h3>
                            <p className="mt-1 text-base sm:text-lg">{formData.title}</p>
                        </div>

                        <div>
                            <h3 className="text-sm font-medium text-gray-500">Description</h3>
                            <p className="mt-1 text-sm sm:text-base whitespace-pre-wrap">{formData.description}</p>
                        </div>

                        <div>
                            <h3 className="text-sm font-medium text-gray-500">Status</h3>
                            <span className={`inline-block mt-1 px-2 py-1 rounded-full text-xs sm:text-sm ${formData.status === 'open_for_submission'
                                ? 'bg-green-100 text-green-800'
                                : formData.status === 'draft'
                                    ? 'bg-yellow-100 text-yellow-800'
                                    : 'bg-gray-100'
                                }`}>
                                {formData.status.replace(/_/g, ' ')}
                            </span>
                        </div>

                        <div>
                            <h3 className="text-sm font-medium text-gray-500">Required Trades</h3>
                            <div className="space-y-6 mt-4">
                                {formData.required_trades?.map((tradeId) => {
                                    const trade = trades.find(t => t.id === tradeId);
                                    if (!trade) return null;

                                    const estimate = formData.trade_estimates?.find(est => est.trade?.id === tradeId);
                                    const cost = parseFloat(estimate?.estimated_cost || 0);

                                    return (
                                        <div key={tradeId} className="p-4 border rounded-lg">
                                            <h4 className="font-medium text-base mb-4">{trade.name}</h4>
                                            <div className="space-y-4 mt-4">
                                                <div>
                                                    <Label>Estimated Cost</Label>
                                                    <p className="text-sm sm:text-base mt-1">
                                                        ${cost.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                    </p>
                                                </div>
                                                <div>
                                                    <Label>Notes</Label>
                                                    <p className="text-sm sm:text-base mt-1 whitespace-pre-wrap">
                                                        {estimate?.notes || 'No notes provided'}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>

                        <div>
                            <h3 className="text-sm font-medium text-gray-500">Submission Deadline</h3>
                            <p className="mt-1 text-sm sm:text-base">
                                {formatDisplayDate(formData.submission_deadline)}
                            </p>
                        </div>
                    </div>
                )}
            </CardContent>
        </Card>
    );
}

export default BidDetailsTab;