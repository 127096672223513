import React, { useState, useEffect, useRef } from 'react';
import {
    Card,
    CardContent,
    CardHeader,
    CardTitle
} from "../../components/ui/cardui";
import { Button } from "../../components/ui/button";
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogFooter
} from "../../components/ui/dialog";
import { Input } from "../../components/ui/input";
import { Label } from "../../components/ui/label";
import { Textarea } from "../../components/ui/textarea";
import { DatePicker } from "../../components/ui/date-picker";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../components/ui/select";
import {
    FileText,
    Download,
    Trash2,
    Upload,
    Plus,
    Calendar,
    FileIcon,
    AlertCircle
} from "lucide-react";
import { toast } from "react-toastify";
import api from '../../services/api';
import { primaryButtonClasses, secondaryButtonClasses } from '../common/ButtonStyles';
import secureLocalStorage from "react-secure-storage";

const DocumentForm = ({ isOpen, onClose, onSubmit, supplierId, document = null }) => {
    const [formData, setFormData] = useState({
        document_type: '',
        title: '',
        description: '',
        expiry_date: null,
        file: null
    });
    const [loading, setLoading] = useState(false);
    const [fileName, setFileName] = useState('');
    const fileInputRef = useRef(null);

    useEffect(() => {
        if (document) {
            setFormData({
                document_type: document.document_type || '',
                title: document.title || '',
                description: document.description || '',
                expiry_date: document.expiry_date ? new Date(document.expiry_date) : null,
                file: null // File cannot be pre-populated
            });
            setFileName(document.file?.name || 'Current file');
        } else {
            setFormData({
                document_type: '',
                title: '',
                description: '',
                expiry_date: null,
                file: null
            });
            setFileName('');
        }
    }, [document, isOpen]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleSelectChange = (name, value) => {
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleDateChange = (date) => {
        setFormData(prev => ({ ...prev, expiry_date: date }));
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setFormData(prev => ({ ...prev, file }));
            setFileName(file.name);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!formData.document_type || !formData.title) {
            toast.error("Document type and title are required");
            return;
        }

        // For new documents, require a file upload
        if (!document && !formData.file) {
            toast.error("Please upload a file");
            return;
        }

        setLoading(true);
        try {
            // Create FormData object for file upload
            const formDataToSubmit = new FormData();
            formDataToSubmit.append('document_type', formData.document_type);
            formDataToSubmit.append('title', formData.title);
            formDataToSubmit.append('supplier', supplierId);

            if (formData.description) {
                formDataToSubmit.append('description', formData.description);
            }

            if (formData.expiry_date) {
                formDataToSubmit.append('expiry_date', formData.expiry_date.toISOString().split('T')[0]);
            }

            if (formData.file) {
                formDataToSubmit.append('file', formData.file);
            }

            await onSubmit(formDataToSubmit);
            onClose();
        } catch (error) {
            console.error("Error submitting document:", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className="sm:max-w-[550px]">
                <DialogHeader>
                    <DialogTitle>{document ? 'Edit Document' : 'Upload New Document'}</DialogTitle>
                </DialogHeader>

                <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                        <Label htmlFor="document_type">Document Type*</Label>
                        <Select
                            value={formData.document_type}
                            onValueChange={(value) => handleSelectChange('document_type', value)}
                            required
                        >
                            <SelectTrigger>
                                <SelectValue placeholder="Select document type" />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectItem value="insurance">Insurance Certificate</SelectItem>
                                <SelectItem value="certification">Certification</SelectItem>
                                <SelectItem value="license">Business License</SelectItem>
                                <SelectItem value="tax">Tax Document</SelectItem>
                                <SelectItem value="agreement">Supply Agreement</SelectItem>
                                <SelectItem value="other">Other</SelectItem>
                            </SelectContent>
                        </Select>
                    </div>

                    <div>
                        <Label htmlFor="title">Document Title*</Label>
                        <Input
                            id="title"
                            name="title"
                            value={formData.title}
                            onChange={handleInputChange}
                            placeholder="Enter document title"
                            required
                        />
                    </div>

                    <div>
                        <Label htmlFor="description">Description</Label>
                        <Textarea
                            id="description"
                            name="description"
                            rows={3}
                            value={formData.description || ''}
                            onChange={handleInputChange}
                            placeholder="Enter document description"
                        />
                    </div>

                    <div>
                        <Label htmlFor="expiry_date">Expiry Date</Label>
                        <DatePicker
                            value={formData.expiry_date}
                            onChange={handleDateChange}
                        />
                    </div>

                    <div>
                        <Label htmlFor="file">Document File {!document && '*'}</Label>
                        <div className="flex items-center gap-2">
                            {/* <Button
                                type="button"
                                variant="outline"
                                onClick={() => document.getElementById('file-upload').click()}
                                className="w-full justify-center"
                            >
                                <Upload className="h-4 w-4 mr-2" />
                                Browse
                            </Button> */}
                            <Button onClick={() => fileInputRef.current && fileInputRef.current.click()}>
                                Browse
                            </Button>
                            <Input
                                ref={fileInputRef}
                                type="file"
                                className="hidden"
                                onChange={handleFileChange}
                            />
                            <Input
                                id="file-upload"
                                type="file"
                                className="hidden"
                                onChange={handleFileChange}
                            />
                            {fileName && (
                                <span className="text-sm text-gray-600 truncate max-w-[250px]">
                                    {fileName}
                                </span>
                            )}
                        </div>
                        {document && !formData.file && (
                            <p className="text-xs text-gray-500 mt-1">
                                Upload a new file to replace the current one, or leave empty to keep the existing file.
                            </p>
                        )}
                    </div>

                    <DialogFooter>
                        <Button
                            type="button"
                            variant="outline"
                            onClick={onClose}
                            className={secondaryButtonClasses}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            className={primaryButtonClasses}
                            disabled={loading}
                        >
                            {loading ? 'Uploading...' : document ? 'Update Document' : 'Upload Document'}
                        </Button>
                    </DialogFooter>
                </form>
            </DialogContent>
        </Dialog>
    );
};

const SupplierDocuments = ({ supplierId }) => {
    const [documents, setDocuments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [expiringDocs, setExpiringDocs] = useState([]);

    useEffect(() => {
        fetchDocuments();
    }, [supplierId]);

    const fetchDocuments = async () => {
        setLoading(true);
        try {
            const response = await api.get('supplier-documents/', {
                params: { supplier: supplierId }
            });

            const docsData = response.data.results || response.data;
            setDocuments(docsData);

            // Find documents expiring in the next 30 days
            const now = new Date();
            const thirtyDaysFromNow = new Date(now);
            thirtyDaysFromNow.setDate(now.getDate() + 30);

            const expiring = docsData.filter(doc => {
                if (!doc.expiry_date) return false;
                const expiryDate = new Date(doc.expiry_date);
                return expiryDate > now && expiryDate <= thirtyDaysFromNow;
            });

            setExpiringDocs(expiring);
        } catch (error) {
            console.error("Error fetching supplier documents:", error);
            toast.error("Failed to load supplier documents");
        } finally {
            setLoading(false);
        }
    };

    const handleAddDocument = () => {
        setSelectedDocument(null);
        setIsFormOpen(true);
    };

    const handleEditDocument = (document) => {
        setSelectedDocument(document);
        setIsFormOpen(true);
    };

    const handleDeleteDocument = async (documentId) => {
        if (window.confirm("Are you sure you want to delete this document?")) {
            try {
                await api.delete(`supplier-documents/${documentId}/`);
                toast.success("Document deleted successfully");
                fetchDocuments();
            } catch (error) {
                console.error("Error deleting document:", error);
                toast.error("Failed to delete document");
            }
        }
    };

    const handleDownloadDocument = async (document) => {
        try {
            // In a real application, you would need to implement the download link correctly
            // This is just a placeholder implementation
            const response = await api.get(`supplier-documents/${document.id}/download/`, {
                responseType: 'blob'
            });

            // Create a download link and trigger it
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', document.title + '.pdf'); // Use the actual file extension
            document.body.appendChild(link);
            link.click();
            link.remove();

            toast.success("Document downloaded successfully");
        } catch (error) {
            console.error("Error downloading document:", error);
            toast.error("Failed to download document");
        }
    };

    // In SupplierReviews.jsx, add the getTenantId function
    const getTenantId = () => {
        let tenantId =
            localStorage.getItem('tenantIdentifier') ||
            (typeof secureLocalStorage !== 'undefined' && secureLocalStorage.getItem('currentTenantId')) ||
            localStorage.getItem('tenantId') ||
            sessionStorage.getItem('tenantId') ||
            sessionStorage.getItem('tenantIdentifier');

        if (!tenantId) {
            try {
                // Try regular localStorage userData
                const userData = JSON.parse(localStorage.getItem('userData') || '{}');
                tenantId = userData.tenant?.id || userData.tenantId;

                // If found, save to localStorage for future use
                if (tenantId) {
                    localStorage.setItem('tenantId', tenantId);
                    console.log("Retrieved tenant ID from user data and saved to localStorage");
                }
            } catch (e) {
                console.error("Error parsing user data:", e);
            }
        }

        return tenantId;
    };


    const handleSubmitDocument = async (formData) => {
        try {
            // Get the tenant ID
            const tenantId = getTenantId();
            if (!tenantId) {
                toast.error("Tenant ID not found. Cannot upload document.");
                return;
            }

            // Add tenant ID to FormData
            formData.append('tenant', tenantId);

            if (selectedDocument) {
                // Update existing document
                await api.put(`supplier-documents/${selectedDocument.id}/`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                toast.success("Document updated successfully");
            } else {
                // Create new document
                await api.post('supplier-documents/', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                toast.success("Document uploaded successfully");
            }
            fetchDocuments();
        } catch (error) {
            console.error("Error submitting document:", error);
            toast.error("Failed to save document");
            throw error;
        }
    };

    const formatDate = (dateString) => {
        if (!dateString) return 'N/A';
        return new Date(dateString).toLocaleDateString();
    };

    const getDocumentTypeLabel = (type) => {
        const types = {
            'insurance': 'Insurance Certificate',
            'certification': 'Certification',
            'license': 'Business License',
            'tax': 'Tax Document',
            'agreement': 'Supply Agreement',
            'other': 'Other'
        };
        return types[type] || type;
    };

    const isExpired = (dateString) => {
        if (!dateString) return false;
        const expiryDate = new Date(dateString);
        const now = new Date();
        return expiryDate < now;
    };

    const isExpiringSoon = (dateString) => {
        if (!dateString) return false;
        const expiryDate = new Date(dateString);
        const now = new Date();
        const thirtyDaysFromNow = new Date(now);
        thirtyDaysFromNow.setDate(now.getDate() + 30);
        return expiryDate > now && expiryDate <= thirtyDaysFromNow;
    };

    return (
        <div className="space-y-6">
            <div className="flex justify-between items-center">
                <h2 className="text-xl font-semibold">Supplier Documents</h2>
                <Button
                    onClick={handleAddDocument}
                    className={primaryButtonClasses}
                >
                    <Plus className="h-4 w-4 mr-2" />
                    Add Document
                </Button>
            </div>

            {expiringDocs.length > 0 && (
                <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 rounded-md">
                    <div className="flex items-start">
                        <AlertCircle className="h-6 w-6 text-yellow-400 mr-3 flex-shrink-0" />
                        <div>
                            <h3 className="text-sm font-medium text-yellow-800">Expiring Documents</h3>
                            <div className="mt-1 text-sm text-yellow-700">
                                <p>The following documents will expire within 30 days:</p>
                                <ul className="list-disc pl-5 mt-1 space-y-1">
                                    {expiringDocs.map(doc => (
                                        <li key={doc.id}>
                                            {doc.title} - Expires on {formatDate(doc.expiry_date)}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {loading ? (
                <div className="text-center py-8">Loading documents...</div>
            ) : documents.length === 0 ? (
                <Card>
                    <CardContent className="text-center py-12">
                        <FileText className="h-12 w-12 text-gray-400 mx-auto mb-4" />
                        <p className="text-gray-500">No documents added yet.</p>
                    </CardContent>
                </Card>
            ) : (
                <div className="space-y-4">
                    {documents.map((doc) => (
                        <Card key={doc.id} className="hover:shadow-md transition-shadow">
                            <CardContent className="p-4">
                                <div className="flex items-start justify-between">
                                    <div className="flex items-start space-x-4">
                                        <div className="bg-gray-100 p-3 rounded-md">
                                            <FileIcon className="h-8 w-8 text-gray-500" />
                                        </div>
                                        <div>
                                            <h3 className="font-medium">{doc.title}</h3>
                                            <p className="text-sm text-gray-500">{getDocumentTypeLabel(doc.document_type)}</p>
                                            {doc.description && (
                                                <p className="text-sm text-gray-600 mt-1">{doc.description}</p>
                                            )}
                                            <div className="flex items-center mt-2">
                                                <Calendar className="h-4 w-4 text-gray-400 mr-1" />
                                                <span
                                                    className={`text-xs ${isExpired(doc.expiry_date)
                                                        ? 'text-red-600 font-medium'
                                                        : isExpiringSoon(doc.expiry_date)
                                                            ? 'text-yellow-600'
                                                            : 'text-gray-500'
                                                        }`}
                                                >
                                                    {doc.expiry_date
                                                        ? `${isExpired(doc.expiry_date) ? 'Expired' : 'Expires'} on ${formatDate(doc.expiry_date)}`
                                                        : 'No expiration date'}
                                                </span>
                                            </div>
                                            <p className="text-xs text-gray-400 mt-1">
                                                Uploaded on {formatDate(doc.uploaded_at)}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex space-x-2">
                                        <Button
                                            variant="outline"
                                            size="sm"
                                            onClick={() => handleDownloadDocument(doc)}
                                        >
                                            <Download className="h-4 w-4" />
                                        </Button>
                                        <Button
                                            variant="outline"
                                            size="sm"
                                            onClick={() => handleEditDocument(doc)}
                                        >
                                            <FileText className="h-4 w-4" />
                                        </Button>
                                        <Button
                                            variant="destructive"
                                            size="sm"
                                            onClick={() => handleDeleteDocument(doc.id)}
                                        >
                                            <Trash2 className="h-4 w-4" />
                                        </Button>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    ))}
                </div>
            )}

            <DocumentForm
                isOpen={isFormOpen}
                onClose={() => setIsFormOpen(false)}
                onSubmit={handleSubmitDocument}
                supplierId={supplierId}
                document={selectedDocument}
            />
        </div>
    );
};

export default SupplierDocuments;