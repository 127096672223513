import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardTitle, CardContent, CardDescription } from '../../components/ui/cardui';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../components/ui/tabs';
import { Alert, AlertTitle, AlertDescription } from '../../components/ui/alert';
import { Button } from '../../components/ui/button';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from '../../components/ui/dialog';
import { Badge } from '../../components/ui/badge';
import { Bell, AlertTriangle, X } from 'lucide-react';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../components/ui/select';
import { Spinner } from '../../components/ui/spinner';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import RiskRegister from './RiskRegister';
import MonteCarloSimulation from './MonteCarloSimulation';
import RiskResponsePlan from './RiskResponsePlan';
import RiskSummaryCards from './RiskSummaryCards';
import RiskSeverityDistribution from './RiskSeverityDistribution';
import RiskStatusTracker from './RiskStatusTracker';
import RiskCategoryDistribution from './RiskCategoryDistribution';
import TopRisksTable from './TopRisksTable';
import RiskTrendChart from './RiskTrendChart';
import UpcomingResponsesTable from './UpcomingResponsesTable';
import RiskReportGenerator from './RiskReportGenerator';
import api from '../../services/api';
import { fetchProjectRisks, fetchRiskCategories } from '../../services/riskApi';

// Notification Component
const NotificationIcon = ({ notifications, onViewDetails, onClearAll }) => {
    const [showNotificationsDialog, setShowNotificationsDialog] = useState(false);

    const totalCount = notifications.reduce((total, notif) => total + notif.count, 0);

    const getVariantColor = (type) => {
        switch (type) {
            case 'destructive': return 'bg-destructive text-destructive-foreground';
            case 'warning': return 'bg-amber-500 text-white';
            default: return 'bg-primary text-primary-foreground';
        }
    };

    return (
        <>
            <Button
                variant="ghost"
                className="relative p-2"
                onClick={() => setShowNotificationsDialog(true)}
            >
                <Bell className="h-6 w-6" />
                {totalCount > 0 && (
                    <Badge variant="destructive" className="absolute -top-1 -right-1 rounded-full px-2 min-w-6 h-6 flex items-center justify-center">
                        {totalCount}
                    </Badge>
                )}
            </Button>

            <Dialog open={showNotificationsDialog} onOpenChange={setShowNotificationsDialog}>
                <DialogContent className="sm:max-w-md">
                    <DialogHeader>
                        <DialogTitle className="flex items-center">
                            <Bell className="mr-2 h-5 w-5" />
                            Risk Notifications
                            <Badge variant="destructive" className="ml-2 rounded-full">
                                {totalCount}
                            </Badge>
                        </DialogTitle>
                    </DialogHeader>

                    {notifications.length === 0 ? (
                        <Alert variant="success">
                            <div className="flex items-center">
                                <div className="mr-3">
                                    <div className="rounded-full bg-green-100 p-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                                        </svg>
                                    </div>
                                </div>
                                <div>
                                    <AlertTitle>All Clear!</AlertTitle>
                                    <AlertDescription>There are no critical risk notifications at this time.</AlertDescription>
                                </div>
                            </div>
                        </Alert>
                    ) : (
                        <div className="max-h-80 overflow-y-auto divide-y">
                            {notifications.slice(0, 3).map((notification) => (
                                <div key={notification.id} className={`p-4 ${notification.type === 'destructive' ? 'bg-red-50' : notification.type === 'warning' ? 'bg-amber-50' : 'bg-blue-50'}`}>
                                    <div className="flex items-start">
                                        <div className="mr-3">
                                            <AlertTriangle className={`h-6 w-6 ${notification.type === 'destructive' ? 'text-red-500' : notification.type === 'warning' ? 'text-amber-500' : 'text-blue-500'}`} />
                                        </div>
                                        <div className="flex-grow">
                                            <h6 className="font-medium">{notification.title}</h6>
                                            <p className="text-sm text-muted-foreground">{notification.message}</p>

                                            <div className="flex justify-between items-center mt-2">
                                                <Button
                                                    variant="outline"
                                                    size="sm"
                                                    onClick={() => {
                                                        setShowNotificationsDialog(false);
                                                        onViewDetails(notification);
                                                    }}
                                                >
                                                    View Details
                                                </Button>
                                                <Badge className={`${getVariantColor(notification.type)} rounded-full`}>
                                                    {notification.count}
                                                </Badge>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}

                            {notifications.length > 3 && (
                                <div className="p-4 text-center">
                                    <Button
                                        variant="link"
                                        onClick={() => {
                                            setShowNotificationsDialog(false);
                                            onViewDetails('all');
                                        }}
                                    >
                                        View All Notifications ({notifications.length})
                                    </Button>
                                </div>
                            )}
                        </div>
                    )}

                    <DialogFooter className="flex justify-between">
                        <Button
                            variant="outline"
                            onClick={() => setShowNotificationsDialog(false)}
                        >
                            Close
                        </Button>
                        {notifications.length > 0 && (
                            <Button
                                variant="destructive"
                                onClick={() => {
                                    onClearAll();
                                    setShowNotificationsDialog(false);
                                }}
                            >
                                Clear All
                            </Button>
                        )}
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </>
    );
};

// Main Dashboard Component
const RiskManagementDashboard = ({ projectId, configureRequest }) => {
    const navigate = useNavigate();
    const [selectedRisk, setSelectedRisk] = useState(null);
    const [activeTab, setActiveTab] = useState('dashboard');
    const [project, setProject] = useState({
        id: projectId,
        name: 'Loading project...',
        type: '',
        size: ''
    });
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [risks, setRisks] = useState([]);
    const [categories, setCategories] = useState([]);
    const [timeframe, setTimeframe] = useState('last30days');
    const [filteredRisks, setFilteredRisks] = useState([]);
    const [showReportGenerator, setShowReportGenerator] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [loadingNotifications, setLoadingNotifications] = useState(true);
    const [showAllNotifications, setShowAllNotifications] = useState(false);

    // Fetch project details
    useEffect(() => {
        const fetchProjectDetails = async () => {
            setIsLoading(true);
            setError(null);
            try {
                const response = await api.get(`projects/${projectId}/`, configureRequest());
                const projectData = response.data;
                setProject({
                    id: projectId,
                    name: projectData.name,
                    type: projectData.project_type || 'commercial', // Fallback if not available
                    size: projectData.project_size || 'medium' // Fallback if not available
                });
            } catch (err) {
                console.error('Error fetching project details:', err);
                setError('Failed to load project details. Please try again later.');
            } finally {
                setIsLoading(false);
            }
        };

        if (projectId) {
            fetchProjectDetails();
        }
    }, [projectId, configureRequest]);

    // Fetch risk data
    useEffect(() => {
        const loadData = async () => {
            try {
                setIsLoading(true);
                // Use the fetchProjectRisks function from the riskApi service
                const risksData = await fetchProjectRisks(projectId);
                setRisks(risksData);
                setFilteredRisks(risksData);

                // Load risk categories
                const categoriesData = await fetchRiskCategories();
                setCategories(categoriesData);
            } catch (error) {
                console.error('Error loading dashboard data:', error);
                toast.error('Failed to load dashboard data');
            } finally {
                setIsLoading(false);
            }
        };

        if (projectId) {
            loadData();
        }
    }, [projectId]);

    // Load notifications
    useEffect(() => {
        const loadNotifications = async () => {
            try {
                setLoadingNotifications(true);

                // Fetch project risks if not already loaded
                let risksData = risks;
                if (risks.length === 0) {
                    risksData = await fetchProjectRisks(projectId);
                    setRisks(risksData);
                    setFilteredRisks(risksData);
                }

                // Generate notifications
                const notificationsList = [];

                // 1. High severity risks with no response plans
                const highSeverityNoResponse = risksData.filter(risk =>
                    (risk.severity === 'HIGH' || risk.severity === 'CRITICAL') &&
                    (!risk.responses || risk.responses.length === 0)
                );

                if (highSeverityNoResponse.length > 0) {
                    notificationsList.push({
                        id: 'high-severity-no-response',
                        type: 'destructive',
                        title: 'High Severity Risks Without Response Plans',
                        message: `${highSeverityNoResponse.length} high severity risks have no mitigation plans defined.`,
                        count: highSeverityNoResponse.length,
                        risks: highSeverityNoResponse
                    });
                }

                // 2. Risks with overdue responses
                const now = new Date();
                const risksWithOverdueResponses = risksData.filter(risk => {
                    if (!risk.responses || risk.responses.length === 0) return false;

                    return risk.responses.some(response => {
                        if (!response.due_date) return false;
                        if (response.status === 'COMPLETED' || response.status === 'CANCELLED') return false;

                        const dueDate = new Date(response.due_date);
                        return dueDate < now;
                    });
                });

                if (risksWithOverdueResponses.length > 0) {
                    notificationsList.push({
                        id: 'overdue-responses',
                        type: 'warning',
                        title: 'Overdue Risk Responses',
                        message: `${risksWithOverdueResponses.length} risks have overdue response actions.`,
                        count: risksWithOverdueResponses.length,
                        risks: risksWithOverdueResponses
                    });
                }

                // 3. Unassigned high severity risks
                const unassignedHighRisks = risksData.filter(risk =>
                    (risk.severity === 'HIGH' || risk.severity === 'CRITICAL') &&
                    !risk.assigned_to
                );

                if (unassignedHighRisks.length > 0) {
                    notificationsList.push({
                        id: 'unassigned-high-risks',
                        type: 'default',
                        title: 'Unassigned High Severity Risks',
                        message: `${unassignedHighRisks.length} high severity risks are not assigned to anyone.`,
                        count: unassignedHighRisks.length,
                        risks: unassignedHighRisks
                    });
                }

                setNotifications(notificationsList);
            } catch (error) {
                console.error('Error loading risk notifications:', error);
            } finally {
                setLoadingNotifications(false);
            }
        };

        if (projectId) {
            loadNotifications();
        }
    }, [projectId, risks]);

    // Filter risks based on selected timeframe
    useEffect(() => {
        if (risks.length === 0) return;

        const now = new Date();
        let cutoffDate = new Date();

        switch (timeframe) {
            case 'last7days':
                cutoffDate.setDate(now.getDate() - 7);
                break;
            case 'last30days':
                cutoffDate.setDate(now.getDate() - 30);
                break;
            case 'last90days':
                cutoffDate.setDate(now.getDate() - 90);
                break;
            case 'lastYear':
                cutoffDate.setFullYear(now.getFullYear() - 1);
                break;
            case 'all':
            default:
                cutoffDate = new Date(0); // Beginning of time
                break;
        }

        const filtered = timeframe === 'all'
            ? risks
            : risks.filter(risk => new Date(risk.created_at) >= cutoffDate);

        setFilteredRisks(filtered);
    }, [timeframe, risks]);

    const handleSelectRisk = (risk) => {
        setSelectedRisk(risk);
        setActiveTab('responses');
    };

    const handleViewRiskDetail = (riskId) => {
        // Find the risk in our risks array
        const risk = risks.find(r => r.id === riskId);
        if (risk) {
            setSelectedRisk(risk);
            setActiveTab('responses');
        } else {
            // If not found in our array, fetch it from API
            api.get(`risks/${riskId}/`, configureRequest())
                .then(response => {
                    setSelectedRisk(response.data);
                    setActiveTab('responses');
                })
                .catch(error => {
                    console.error('Error fetching risk details:', error);
                    toast.error('Failed to load risk details');
                });
        }
    };

    const handleNotificationAction = (notification) => {
        if (notification === 'all') {
            setShowAllNotifications(true);
            return;
        }

        // Navigate based on notification type
        if (notification.risks && notification.risks.length > 0) {
            // Select the first risk and navigate to response plan
            setSelectedRisk(notification.risks[0]);
            setActiveTab('responses');
            window.scrollTo(0, 0);  // Ensure the tab navigation is visible
        } else {
            // If no risks in notification, go to risk register
            setActiveTab('register');
        }
    };

    const handleClearAllNotifications = () => {
        setNotifications([]);
        setShowAllNotifications(false);
        toast.success('All notifications cleared');
    };

    // Calculate summary metrics
    const getSummaryMetrics = () => {
        if (filteredRisks.length === 0) {
            return {
                totalRisks: 0,
                highSeverityRisks: 0,
                mitigatedRisks: 0,
                openRisks: 0,
                avgRiskScore: 0
            };
        }

        const highSeverityRisks = filteredRisks.filter(
            risk => risk.severity === 'HIGH' || risk.severity === 'CRITICAL'
        ).length;

        const mitigatedRisks = filteredRisks.filter(
            risk => risk.status === 'MITIGATED' || risk.status === 'CLOSED'
        ).length;

        const openRisks = filteredRisks.filter(
            risk => risk.status !== 'CLOSED' && risk.status !== 'MITIGATED'
        ).length;

        const avgRiskScore = filteredRisks.reduce(
            (sum, risk) => sum + risk.risk_score, 0
        ) / filteredRisks.length;

        return {
            totalRisks: filteredRisks.length,
            highSeverityRisks,
            mitigatedRisks,
            openRisks,
            avgRiskScore: avgRiskScore.toFixed(1)
        };
    };

    // Calculate top risks (highest risk score)
    const getTopRisks = () => {
        return [...filteredRisks]
            .sort((a, b) => b.risk_score - a.risk_score)
            .slice(0, 5);
    };

    // Get upcoming risk responses
    const getUpcomingResponses = () => {
        const allResponses = filteredRisks.flatMap(risk =>
            (risk.responses || []).map(response => ({
                ...response,
                risk_title: risk.title,
                risk_id: risk.id,
                risk_severity: risk.severity
            }))
        );

        return allResponses
            .filter(response =>
                response.status !== 'COMPLETED' &&
                response.status !== 'CANCELLED' &&
                response.due_date
            )
            .sort((a, b) => new Date(a.due_date) - new Date(b.due_date))
            .slice(0, 5);
    };

    if (isLoading) {
        return (
            <div className="flex justify-center items-center p-4">
                <p>Loading risk management dashboard...</p>
            </div>
        );
    }

    if (error) {
        return (
            <Alert>
                <AlertDescription>{error}</AlertDescription>
            </Alert>
        );
    }

    // Dashboard content
    const renderDashboardContent = () => {
        const summaryMetrics = getSummaryMetrics();
        const topRisks = getTopRisks();

        return (
            <div className="container py-4">
                <div className="mb-4 flex flex-col md:flex-row md:items-center md:justify-between">
                    <div>
                        <h2 className="text-2xl font-bold">Risk Management Dashboard</h2>
                        <p className="text-muted-foreground">
                            Overview of project risks, trends, and priorities
                        </p>
                    </div>
                    <div className="flex flex-col sm:flex-row gap-2 mt-4 md:mt-0">
                        <div className="flex items-center gap-2">
                            <span className="text-sm">Time Period:</span>
                            <Select
                                value={timeframe}
                                onValueChange={setTimeframe}
                            >
                                <SelectTrigger className="w-[180px]">
                                    <SelectValue placeholder="Select time period" />
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectItem value="last7days">Last 7 Days</SelectItem>
                                    <SelectItem value="last30days">Last 30 Days</SelectItem>
                                    <SelectItem value="last90days">Last 90 Days</SelectItem>
                                    <SelectItem value="lastYear">Last Year</SelectItem>
                                    <SelectItem value="all">All Time</SelectItem>
                                </SelectContent>
                            </Select>
                        </div>
                        <Button
                            variant={showReportGenerator ? "secondary" : "default"}
                            onClick={() => setShowReportGenerator(!showReportGenerator)}
                        >
                            {showReportGenerator ? "Hide Report Generator" : "Generate Report"}
                        </Button>
                        <NotificationIcon
                            notifications={notifications}
                            onViewDetails={handleNotificationAction}
                            onClearAll={handleClearAllNotifications}
                        />
                    </div>
                </div>

                {showReportGenerator && (
                    <div className="mb-4">
                        <RiskReportGenerator
                            projectId={projectId}
                            projectName={project.name}
                        />
                    </div>
                )}

                {showAllNotifications && (
                    <div className="mb-4">
                        <Card>
                            <CardHeader className="flex flex-row items-center justify-between">
                                <CardTitle>All Risk Notifications</CardTitle>
                                <Button
                                    variant="ghost"
                                    size="sm"
                                    onClick={() => setShowAllNotifications(false)}
                                >
                                    <X className="h-4 w-4" />
                                </Button>
                            </CardHeader>
                            <CardContent>
                                {notifications.length === 0 ? (
                                    <Alert variant="success">
                                        <AlertTitle>All Clear!</AlertTitle>
                                        <AlertDescription>There are no critical risk notifications at this time.</AlertDescription>
                                    </Alert>
                                ) : (
                                    <div className="max-h-80 overflow-y-auto divide-y">
                                        {notifications.map((notification) => (
                                            <div key={notification.id} className={`p-4 ${notification.type === 'destructive' ? 'bg-red-50' : notification.type === 'warning' ? 'bg-amber-50' : 'bg-blue-50'}`}>
                                                <div className="flex items-start">
                                                    <div className="mr-3">
                                                        <AlertTriangle className={`h-6 w-6 ${notification.type === 'destructive' ? 'text-red-500' : notification.type === 'warning' ? 'text-amber-500' : 'text-blue-500'}`} />
                                                    </div>
                                                    <div className="flex-grow">
                                                        <h6 className="font-medium">{notification.title}</h6>
                                                        <p className="text-sm text-muted-foreground">{notification.message}</p>

                                                        <div className="flex justify-between items-center mt-2">
                                                            <Button
                                                                variant="outline"
                                                                size="sm"
                                                                onClick={() => {
                                                                    setShowAllNotifications(false);
                                                                    handleNotificationAction(notification);
                                                                }}
                                                            >
                                                                View Details
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}

                                {notifications.length > 0 && (
                                    <div className="flex justify-end mt-4">
                                        <Button
                                            variant="destructive"
                                            onClick={handleClearAllNotifications}
                                        >
                                            Clear All Notifications
                                        </Button>
                                    </div>
                                )}
                            </CardContent>
                        </Card>
                    </div>
                )}

                <div className="grid grid-cols-1 lg:grid-cols-12 gap-4 mb-4">
                    <div className="lg:col-span-12">
                        {/* Summary Cards */}
                        <RiskSummaryCards metrics={summaryMetrics} />
                    </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-4">
                    {/* Risk Severity Distribution */}
                    <div>
                        <RiskSeverityDistribution risks={filteredRisks} />
                    </div>

                    {/* Risk Status Tracker */}
                    <div>
                        <RiskStatusTracker risks={filteredRisks} />
                    </div>

                    {/* Risk Category Distribution */}
                    <div>
                        <RiskCategoryDistribution
                            risks={filteredRisks}
                            categories={categories}
                        />
                    </div>
                </div>

                <div className="mb-4">
                    {/* Risk Trend Over Time */}
                    <RiskTrendChart
                        projectId={projectId}
                        timeframe={timeframe}
                        configureRequest={configureRequest}
                    />
                </div>

                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4">
                    {/* Top Risks */}
                    <div>
                        <TopRisksTable
                            risks={topRisks}
                            projectId={projectId}
                            onViewRisk={(risk) => {
                                console.log("Viewing risk:", risk);
                                setSelectedRisk(risk);
                                setActiveTab('responses');
                            }}
                        />
                    </div>

                    {/* Upcoming Risk Responses */}
                    <div>
                        <UpcomingResponsesTable
                            projectId={projectId}
                            configureRequest={configureRequest}
                            onViewResponse={(response) => {
                                const riskObj = filteredRisks.find(r => r.id === response.risk_id);
                                if (riskObj) {
                                    setSelectedRisk(riskObj);
                                    setActiveTab('responses');
                                } else {
                                    handleViewRiskDetail(response.risk_id);
                                }
                            }}
                            setActiveTab={setActiveTab}
                        />
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="py-4">
            <div className="mb-4">
                <Card>
                    <CardContent className="pt-6">
                        <CardTitle className="text-2xl">Risk Management - {project.name}</CardTitle>
                        <CardDescription>
                            Identify, assess, monitor, and mitigate risks to ensure project success.
                        </CardDescription>

                        {!projectId && (
                            <Alert variant="warning" className="mt-4">
                                <AlertTitle>Warning</AlertTitle>
                                <AlertDescription>
                                    Please select a project to view its risk management dashboard.
                                </AlertDescription>
                            </Alert>
                        )}
                    </CardContent>
                </Card>
            </div>

            {projectId && (
                <Tabs defaultValue={activeTab} onValueChange={setActiveTab} className="mb-4">
                    <TabsList className="grid grid-cols-4 mb-4">
                        <TabsTrigger value="dashboard">Dashboard</TabsTrigger>
                        <TabsTrigger value="register">Risk Register</TabsTrigger>
                        <TabsTrigger value="simulation">Monte Carlo Simulation</TabsTrigger>
                        <TabsTrigger value="responses" disabled={!selectedRisk}>
                            Response Plans
                        </TabsTrigger>
                    </TabsList>

                    <TabsContent value="dashboard">
                        {renderDashboardContent()}
                    </TabsContent>

                    <TabsContent value="register">
                        <RiskRegister
                            projectId={projectId}
                            projectType={project.type}
                            projectSize={project.size}
                            onRiskSelect={handleSelectRisk}
                            configureRequest={configureRequest}
                        />
                    </TabsContent>

                    <TabsContent value="simulation">
                        <MonteCarloSimulation projectId={projectId} configureRequest={configureRequest} />
                    </TabsContent>

                    <TabsContent value="responses">
                        {selectedRisk ? (
                            <RiskResponsePlan riskId={selectedRisk.id} configureRequest={configureRequest} />
                        ) : (
                            <Card>
                                <CardContent>
                                    <Alert className="mt-4">
                                        <AlertDescription>
                                            Please select a risk from the Risk Register to view or create response plans.
                                        </AlertDescription>
                                    </Alert>
                                </CardContent>
                            </Card>
                        )}
                    </TabsContent>
                </Tabs>
            )}
        </div>
    );
};

export default RiskManagementDashboard;