import { ArrowLeft } from "lucide-react"
import { useNavigate } from "react-router-dom"

const BackButton = () => {
    const navigate = useNavigate()

    return (
        <button
            onClick={() => navigate(-1)}
            className="inline-flex items-center text-gray-600 hover:text-gray-900 mb-4"
        >
            <ArrowLeft className="h-5 w-5 mr-1" />
            <span>Back</span>
        </button>
    )
}

export default BackButton