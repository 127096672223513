
export const getHomeRouteForRole = (role) => {
    const roleHomeRoutes = {
        'Tenant Admin': '/dashboard',
        'Tenant Member': '/projects',
        'Contractor Admin': '/contractor-portal',
        'Client Admin': '/client-portal',
        'Bid Submitter': '/my-bids'
    };

    return roleHomeRoutes[role] || '/dashboard';
};

export const isValidRoute = (pathname, userRole) => {


    const publicRoutes = [
        '/login',
        '/register',
        '/reset-password'
    ];

    if (pathname.includes('/bids/') && pathname.includes('/invitation/')) {
        return true;
    }

    if (publicRoutes.includes(pathname)) {
        return true;
    }


    // Define all valid routes by role
    const validRoutes = {
        'Tenant Admin': [
            '/dashboard',
            '/projects',
            //'/estimates',
            //'/budgets',
            '/docs',
            '/safety',
            '/communication',
            '/client-management',
            '/contractor-management',
            '/bid-management',
            '/settings',
            '/subscription'
        ],
        'Tenant Member': [
            '/projects',
            '/settings'
        ],
        'Contractor Admin': [
            '/contractor-portal',
            '/contractor',
            '/contractor/projects',
            '/contractor/tasks',
            '/contractor/rfis',
            '/settings'
        ],
        'Client Admin': [
            '/client-portal',
            '/settings'
        ],
        'Bid Submitter': [
            '/my-bids',
            '/bids-rfi',
            '/settings'
        ]
    };

    // Get base route (first segment)
    const baseRoute = '/' + pathname.split('/')[1];

    // Get valid routes for user role
    const roleRoutes = validRoutes[userRole] || [];

    // Special handling for nested routes
    const isProjectRoute = pathname.startsWith('/projects/');
    // const isEstimateRoute = pathname.startsWith('/estimates/');
    const isContractorRoute = pathname.startsWith('/contractor/');
    const isClientRoute = pathname.startsWith('/client-portal/');
    const isBidRoute = pathname.startsWith('/my-bids/');

    // Check if route is valid based on role
    if (userRole === 'Tenant Admin') {
        return roleRoutes.includes(baseRoute) || isProjectRoute; // || isEstimateRoute;
    }
    if (userRole === 'Tenant Member') {
        return roleRoutes.includes(baseRoute) || isProjectRoute;
    }

    if (userRole === 'Contractor Admin') {
        return roleRoutes.includes(baseRoute) || isContractorRoute;
    }

    if (userRole === 'Client Admin') {
        return roleRoutes.includes(baseRoute) || isClientRoute;
    }
    if (userRole === 'Bid Submitter') {
        return roleRoutes.includes(baseRoute) || isBidRoute;
    }

    return false;
};

export const shouldRedirectToHome = (pathname, userRole) => {
    // Always redirect root path to role-specific home
    if (pathname === '/') {
        return true;
    }

    // Add this check before any role validation
    if (pathname.includes('/bids/') && pathname.includes('/invitation/')) {
        return false; // Never redirect bid invitation routes
    }

    // Check if current path is invalid for user's role
    if (!isValidRoute(pathname, userRole)) {
        return true;
    }

    return false;
};