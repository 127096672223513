import React from "react";
import { cn } from "../../lib/utils"
import { Input } from "./input";
import { Label } from "./label";

const DatePicker = React.forwardRef(({ className, value, onChange, label, ...props }, ref) => {
    const handleChange = (e) => {
        if (onChange) {
            onChange(e.target.value);
        }
    };

    return (
        <div className={cn("grid gap-2", className)}>
            {label && <Label>{label}</Label>}
            <Input
                {...props}
                type="date"
                ref={ref}
                value={value || ""}
                onChange={handleChange}
            />
        </div>
    );
});

DatePicker.displayName = "DatePicker";

export { DatePicker };