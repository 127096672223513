import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter
} from "../ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from "../ui/form";
import { Button } from "../ui/button";
import { StarIcon } from '@heroicons/react/24/solid';
import { StarIcon as StarIconOutline } from '@heroicons/react/24/outline';
import { Loader2 } from 'lucide-react';
import api from '../../services/api';
import { showToast } from '../../utils/toast';
import { primaryButtonClasses, secondaryButtonClasses } from '../common/ButtonStyles';

const RatingForm = ({ contractor, onClose, onSuccess }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hoveredRating, setHoveredRating] = useState(0);

  const form = useForm({
    defaultValues: {
      rating: 0,
      review: ''
    }
  });

  const onSubmit = async (data) => {
    if (data.rating === 0) {
      showToast.error('Please select a rating');
      return;
    }

    setIsSubmitting(true);
    try {
      await api.post('ratings/', {
        contractor: contractor.id,
        rating: data.rating,
        review: data.review
      });
      showToast.success('Rating submitted successfully');
      onSuccess?.();
      onClose();
    } catch (error) {
      showToast.error(error.message || 'Failed to submit rating');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleStarClick = (rating) => {
    form.setValue('rating', rating);
  };

  return (
    <Dialog open={true} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[500px]">
        <DialogHeader>
          <DialogTitle>Rate Contractor</DialogTitle>
        </DialogHeader>

        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
            <FormField
              control={form.control}
              name="rating"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Rating</FormLabel>
                  <div className="flex justify-center space-x-1">
                    {[1, 2, 3, 4, 5].map((rating) => (
                      <button
                        key={rating}
                        type="button"
                        className="hover:scale-110 transition-transform"
                        onMouseEnter={() => setHoveredRating(rating)}
                        onMouseLeave={() => setHoveredRating(0)}
                        onClick={() => handleStarClick(rating)}
                      >
                        {rating <= (hoveredRating || field.value) ? (
                          <StarIcon className="h-8 w-8 text-yellow-400" />
                        ) : (
                          <StarIconOutline className="h-8 w-8 text-yellow-400" />
                        )}
                      </button>
                    ))}
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="review"
              rules={{ required: "Please provide a review" }}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Review</FormLabel>
                  <FormControl>
                    <textarea
                      {...field}
                      className="w-full min-h-[100px] rounded-md border border-input bg-background px-3 py-2 text-sm"
                      placeholder="Share your experience with this contractor..."
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <DialogFooter className="gap-2 sm:gap-0">
              <Button
                type="button"
                variant="outline"
                onClick={onClose}
                className={secondaryButtonClasses}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                disabled={isSubmitting}
                className={primaryButtonClasses}
              >
                {isSubmitting ? (
                  <>
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    Submitting...
                  </>
                ) : 'Submit Rating'}
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default RatingForm;