import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Card, CardHeader, CardTitle, CardContent } from '../../../components/ui/cardui';
import { Button } from '../../../components/ui/button';
import { useToast } from '../../../components/ui/use-toast';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../../components/ui/tabs';
import { Badge } from '../../../components/ui/badge';
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogTrigger } from '../../../components/ui/alert-dialog';
import { Loader2, ArrowLeft, FileText, Download } from 'lucide-react';
import api from '../../../services/api';
import { primaryButtonClasses, secondaryButtonClasses } from '../../common/ButtonStyles';

const BidSubmitterDetails = () => {
    const { id } = useParams();

    const navigate = useNavigate();
    const { toast } = useToast();
    const [bid, setBid] = useState(null);
    const [loading, setLoading] = useState(true);
    const [activeTab, setActiveTab] = useState('details');
    const [showAcceptDialog, setShowAcceptDialog] = useState(false);

    useEffect(() => {
        fetchBidDetails();
    }, [id]);

    const fetchBidDetails = async () => {
        try {
            setLoading(true);

            // Check for valid UUID format
            const validUUID = /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
            if (!id || id === 'undefined' || !validUUID.test(id)) {

                toast({
                    title: "Error",
                    description: "Invalid bid ID",
                    variant: "destructive"
                });
                navigate('/my-bids');
                return;
            }

            const response = await api.get(`bid-submitter/${id}/`);

            if (!response.data) {
                throw new Error('No data received');
            }

            setBid(response.data);
        } catch (error) {
            console.error('Error fetching bid details:', error);
            toast({
                title: "Error",
                description: error.response?.status === 403 ?
                    "You don't have permission to view this bid" :
                    "Failed to load bid details",
                variant: "destructive"
            });
            // Only navigate away on specific error conditions
            if (error.response?.status === 404 || error.response?.status === 403) {
                navigate('/my-bids');
            }
        } finally {
            setLoading(false);
        }
    };

    const handleAcceptInvitation = async () => {
        try {
            await api.post(`bid-submitter/${id}/accept_invitation/`);
            toast({
                title: "Success",
                description: "Invitation accepted successfully"
            });
            fetchBidDetails();
            setShowAcceptDialog(false);
        } catch (error) {
            toast({
                title: "Error",
                description: "Failed to accept invitation",
                variant: "destructive"
            });
        }
    };

    const handleDeclineInvitation = async () => {
        try {
            await api.post(`bid-submitter/${id}/decline_invitation/`);
            toast({
                title: "Success",
                description: "Invitation declined"
            });
            navigate('/my-bids');
        } catch (error) {
            toast({
                title: "Error",
                description: "Failed to decline invitation",
                variant: "destructive"
            });
        }
    };

    if (loading) {
        return (
            <div className="flex justify-center items-center h-64">
                <Loader2 className="h-8 w-8 animate-spin" />
            </div>
        );
    }

    if (!bid) {
        return (
            <div className="p-6">
                <h2 className="text-xl font-semibold text-red-600">Bid not found or access denied</h2>
            </div>
        );
    }

    const canSubmitBid = bid.invitation?.status === 'accepted' && !bid.submission;
    const isBeforeDeadline = new Date(bid.submission_deadline) > new Date();
    const canEditBid = bid.submission?.status === 'submitted';

    return (
        <div className="container mx-auto p-6 max-w-4xl">
            <div className="flex justify-between items-center mb-6">
                <div className="flex items-center gap-4">
                    <Button
                        variant="ghost"
                        onClick={() => navigate('/my-bids')}
                        className="flex items-center gap-2"
                    >
                        <ArrowLeft className="h-4 w-4" />
                        Back to My Bids
                    </Button>
                    <h1 className="text-2xl font-bold">{bid.title}</h1>
                </div>
                <div className="space-x-2">
                    {bid.invitation?.status === 'pending' && (
                        <>
                            <Button
                                onClick={() => setShowAcceptDialog(true)}
                                className={primaryButtonClasses}
                            >
                                Accept Invitation
                            </Button>
                            <Button
                                variant="outline"
                                onClick={handleDeclineInvitation}
                            >
                                Decline
                            </Button>
                        </>
                    )}
                    {canSubmitBid && isBeforeDeadline && (
                        <Button
                            onClick={() => navigate(`/my-bids/${id}/submit`)}
                            className={primaryButtonClasses}
                        >
                            Submit Bid
                        </Button>
                    )}
                    {canEditBid && isBeforeDeadline && (
                        <Button onClick={() => navigate(`/my-bids/${id}/submit`)} className={primaryButtonClasses}>
                            Edit Submission
                        </Button>
                    )}
                </div>
            </div>

            <Tabs value={activeTab} onValueChange={setActiveTab}>
                <TabsList>
                    <TabsTrigger value="details">Details</TabsTrigger>
                    <TabsTrigger value="scope">Scope</TabsTrigger>
                    <TabsTrigger value="documents">Documents</TabsTrigger>
                </TabsList>

                <TabsContent value="details">
                    <Card>
                        <CardContent className="space-y-4 pt-6">
                            {bid?.invitation?.trade_estimate?.length > 0 && (
                                <div className="bg-blue-50 border border-blue-200 rounded-lg p-4">
                                    <h3 className="text-sm font-medium text-blue-900">Trade Estimate</h3>
                                    {bid.invitation.trade_estimate.map((estimate) => (
                                        <div key={estimate.id} className="mt-2">
                                            <p className="text-base font-medium text-blue-800">
                                                ${estimate.estimated_cost.toLocaleString()}
                                            </p>
                                            {estimate.notes && (
                                                <p className="text-sm text-blue-700 mt-1">
                                                    {estimate.notes}
                                                </p>
                                            )}
                                        </div>
                                    ))}
                                    <p className="text-xs text-blue-600 mt-2">
                                        This is the estimated cost for your trade work
                                    </p>
                                </div>
                            )}
                            <div>
                                <h3 className="text-sm font-medium text-gray-500">Status</h3>
                                <Badge className="mt-1">
                                    {bid.invitation?.status.toUpperCase()}
                                </Badge>
                            </div>

                            <div>
                                <h3 className="text-sm font-medium text-gray-500">Description</h3>
                                <p className="mt-1">{bid.description}</p>
                            </div>

                            <div>
                                <h3 className="text-sm font-medium text-gray-500">Submission Deadline</h3>
                                <p className="mt-1">
                                    {new Date(bid.submission_deadline).toLocaleDateString()}
                                </p>
                                {!isBeforeDeadline && (
                                    <p className="text-sm text-red-600 mt-1">
                                        Submission deadline has passed
                                    </p>
                                )}
                            </div>

                            {bid.submission && (
                                <div>
                                    <h3 className="text-sm font-medium text-gray-500">Your Submission</h3>
                                    <div className="mt-1">
                                        <Badge variant={bid.submission.status === 'submitted' ? 'default' : 'secondary'}>
                                            {bid.submission.status.replace('_', ' ').toUpperCase()}
                                        </Badge>
                                        <p className="text-sm text-gray-500 mt-1">
                                            Submitted on: {new Date(bid.submission.submitted_at).toLocaleString()}
                                        </p>
                                    </div>
                                </div>
                            )}
                        </CardContent>
                    </Card>
                </TabsContent>

                <TabsContent value="scope">
                    <Card>
                        <CardContent className="space-y-6 pt-6">
                            <div>
                                <h3 className="text-sm font-medium text-gray-500">General Requirements</h3>
                                <p className="mt-1 whitespace-pre-wrap">
                                    {bid.scope?.general_requirements}
                                </p>
                            </div>

                            <div>
                                <h3 className="text-sm font-medium text-gray-500">Specific Requirements</h3>
                                {bid.scope?.specific_requirements?.length > 0 ? (
                                    <ul className="mt-1 list-disc list-inside space-y-1">
                                        {bid.scope.specific_requirements.map((req, index) => (
                                            <li key={index}>{req}</li>
                                        ))}
                                    </ul>
                                ) : (
                                    <p className="mt-1 text-gray-500">No specific requirements specified</p>
                                )}
                            </div>

                            <div>
                                <h3 className="text-sm font-medium text-gray-500">Exclusions</h3>
                                {bid.scope?.exclusions?.length > 0 ? (
                                    <ul className="mt-1 list-disc list-inside space-y-1">
                                        {bid.scope.exclusions.map((exclusion, index) => (
                                            <li key={index}>{exclusion}</li>
                                        ))}
                                    </ul>
                                ) : (
                                    <p className="mt-1 text-gray-500">No exclusions specified</p>
                                )}
                            </div>

                            <div>
                                <h3 className="text-sm font-medium text-gray-500">Deliverables</h3>
                                {bid.scope?.deliverables?.length > 0 ? (
                                    <ul className="mt-1 list-disc list-inside space-y-1">
                                        {bid.scope.deliverables.map((deliverable, index) => (
                                            <li key={index}>{deliverable}</li>
                                        ))}
                                    </ul>
                                ) : (
                                    <p className="mt-1 text-gray-500">No deliverables specified</p>
                                )}
                            </div>
                        </CardContent>
                    </Card>
                </TabsContent>

                <TabsContent value="documents">
                    <Card>
                        <CardHeader>
                            <CardTitle>Bid Documents</CardTitle>
                        </CardHeader>
                        <CardContent>
                            {bid.documents?.length > 0 ? (
                                <div className="grid gap-4">
                                    {bid.documents.map((doc) => (
                                        <div
                                            key={doc.id}
                                            className="flex items-center justify-between p-4 bg-gray-50 rounded-lg"
                                        >
                                            <div className="flex items-center gap-3">
                                                <FileText className="h-5 w-5 text-gray-400" />
                                                <div>
                                                    <p className="font-medium">{doc.title}</p>
                                                    <p className="text-sm text-gray-500">
                                                        Added {new Date(doc.created_at).toLocaleDateString()}
                                                    </p>
                                                </div>
                                            </div>
                                            <a
                                                href={doc.file}
                                                download
                                                className="flex items-center gap-2 text-blue-600 hover:text-blue-800"
                                            >
                                                <Download className="h-4 w-4" />
                                                Download
                                            </a>
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <p className="text-center text-gray-500 py-8">
                                    No documents available
                                </p>
                            )}
                        </CardContent>
                    </Card>
                </TabsContent>
            </Tabs>

            <AlertDialog open={showAcceptDialog} onOpenChange={setShowAcceptDialog}>
                <AlertDialogContent>
                    <AlertDialogHeader>
                        <AlertDialogTitle>Accept Bid Invitation</AlertDialogTitle>
                        <AlertDialogDescription>
                            Are you sure you want to accept this bid invitation? You will be able to submit your bid proposal once accepted.
                        </AlertDialogDescription>
                    </AlertDialogHeader>
                    <AlertDialogFooter>
                        <AlertDialogCancel>Cancel</AlertDialogCancel>
                        <AlertDialogAction onClick={handleAcceptInvitation}>
                            Accept Invitation
                        </AlertDialogAction>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
        </div>
    );
};

export default BidSubmitterDetails;