import React from 'react';
import { CheckIcon, Sparkles } from 'lucide-react';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "../ui/cardui";
import { Button } from "../ui/button";
import { Badge } from "../ui/badge";



export const PlanCard = ({ plan, isSelected, onSelect, billingCycle }) => (
    <Card className={`relative ${isSelected ? 'ring-2 ring-primary' : ''}`}>
        <CardHeader>
            <CardTitle>{plan.name}</CardTitle>
            <CardDescription>{plan.description}</CardDescription>
            <div className="mt-4">
                <span className="text-4xl font-bold">
                    ${billingCycle === 'monthly' ? plan.monthly_price : plan.annual_price}
                </span>
                <span className="text-muted-foreground">/{billingCycle === 'monthly' ? 'mo' : 'yr'}</span>
            </div>
        </CardHeader>
        <CardContent>
            <ul className="space-y-2">
                {plan.feature_settings && Object.entries(plan.feature_settings).map(([key, value]) => (
                    <li key={key} className="flex items-center gap-2">
                        <CheckIcon className="h-4 w-4 text-primary" />
                        <span className="text-sm">{`${key}: ${value}`}</span>
                    </li>
                ))}
            </ul>
        </CardContent>
        <CardFooter>
            <Button
                variant={isSelected ? "secondary" : "default"}
                className="w-full"
                onClick={() => onSelect(plan)}
            >
                {isSelected ? 'Selected' : 'Select Plan'}
            </Button>
        </CardFooter>
    </Card>
);