import React, { createContext, useContext, useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { SubscriptionProvider } from './components/Subscription/context/SubscriptionContext';
import 'react-toastify/dist/ReactToastify.css';
import Dashboard from './pages/Dashboards/Dashboard';
import AnalyticsDashboard from './pages/Dashboards/AnalyticsDashboard';
import Projects from './components/Projects/Projects';
import CommunicationHub from './components/Communication/CommunicationHub';
import Safety from './Safety/Safety';
import PlaceholderPage from './components/PlaceholderPage';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import Signup from './components/Signup';
import Login from './components/Login';
import TeamManagement from './components/TeamManagement';
import Settings from './components/Settings';
import PasswordReset from './components/PasswordReset';
import PasswordResetConfirm from './components/PasswordResetConfirm';
import ProjectDetail from './components/Projects/ProjectDetails';
import DocumentManagement from './components/Docs/DocumentManagement';
import EmployeeManagement from './components/EmployeeManagement';
import EmployeeCertifications from './components/EmployeeCertifications';
import RFIManagementSystem from './components/RFI/RFIManagement';
import RFIForm from './components/RFI/RFIForm';
import ProjectSettings from './components/Projects/ProjectSettings';
import ResourceManager from './components/Resources/ResourceManager';
import ProjectTimeline from './components/Projects/ProjectTimeline';
import TaskManagement from './components/Tasks/TaskManagement';
import TakeoffModule from './components/Estimate/TakeoffModule';
import EstimatePage from './components/Estimate/EstimateDetail';
import EstimateList from './pages/Estimate/EstimateList';
import NewEstimate from './pages/Estimate/NewEstimate';
import BudgetList from './components/budgets/BudgetList';
import CreateBudget from './components/budgets/CreateBudget';
import BudgetDetail from './components/budgets/BudgetDetail';
import BasicBudgetDetail from './components/budgets/BasicBudgetDetail';
import { RouteGuard } from './services/RouteGuard';
import ContractorPortalLayout from './components/Contractors/ContractorPortalLayout';
import ClientFinancials from './components/Client/ClientFinancials';
import ClientDashboard from './components/Client/ClientDashboard';
import ClientPortalDashboard from './components/Client/ClientPortalDashboard';
import ClientDocuments from './components/Client/ClientDocumentManagement';
import ClientPortalRoutes from './components/Client/ClientPortalRoutes';
import ContractorDashboard from './components/Contractors/ContractorDashboard';
import ErrorBoundary from './utils/ErrorBoundary';
import SubscriptionSuccess from './components/Subscription/SubscriptionSuccess';
import SubscriptionManagement from './components/Subscription/SubscriptionManagement';
import EstimateHandler from './components/Estimate/EstimateHandler';
import CreateConceptualEstimate from './components/Estimate/Conceptual/CreateConceptualEstimate';
import ConceptualEstimate from './components/Estimate/Conceptual/ConceptualEstimate';
import EstimateApprovalRouter from './components/Estimate/EstimateApprovalRouter';
import ContractorProjects from './components/Contractors/ContractorProjects';
import ContractorProjectDetail from './components/Contractors/ContractorProjectDetail';
import ContractorTasks from './components/Contractors/ContractorTasks';
import ContractorRFIs from './components/Contractors/ContractorRFIs';
import ClientManagement from './components/Client/ClientManagement';
import ContractorManagement from './components/Contractors/ContractorManagement';
import BidDashboard from './components/Bids/subs/BidDashboard';
import BidSolicitationForm from './components/Bids/subs/Forms/BidSolicitationForm';
import SupplierManagement from './components/Supplier/SupplierManagement';
import SupplierCategories from './components/Supplier/SupplierCategories';
import SupplierDetail from './components/Supplier/SupplierDetails';
import SupplierDocuments from './components/Supplier/SupplierDocument';
import NotFound from './pages/NotFound';
import BidDetails from './components/Bids/subs/BidDetails';
import BidResponse from './components/Bids/subs/BidResponse';
import CreateBidAccount from './components/Bids/subs/CreateBidAccount';
import SubcontractorBidPortal from './components/Bids/subs/SubcontractorBidPortal';
import BidSubmissionForm from './components/Bids/subs/Forms/BidSubmissionForm';
import BidResponseDetail from './components/Bids/subs/BidResponseDetail';
import BidSubmitterDetails from './components/Bids/subs/BidSubmitterDetails';
import BidComparisonPage from './components/Bids/subs/BidComparisonPage';
import { FeatureAccessProvider } from './contexts/FeatureAccessContext';
import SupplierCategoriesPage from './components/Supplier/SupplierCategoriesPage';
import OnboardingFlow from './components/Onboarding/OnboardingFlow';
import CheckoutCanceledPage from './components/CheckoutCanceledPage';
import api from './services/api';
import CheckoutSuccessHandler from './components/CheckoutSuccessHandler';
import RiskManagementDashboard from './components/RiskManagement/RiskManagementDashboard';
import PromotionSignup from './components/PromotionSignup';
import QualityIssuesManager from './components/Projects/QualityIssuesManager';
import InspectionManager from './components/Projects/Inspections/InspectionManager';
import SafetyTrainingManager from './Safety/SafetyTrainingManager';

const PublicBidLayout = ({ children }) => (
  <div className="min-h-screen bg-gray-50">
    {children}
  </div>
);


const PublicRoute = ({ children, isBidRoute = false }) => {
  const token = localStorage.getItem('token');
  if (token && !isBidRoute) {
    return <Navigate to="/" replace />;
  }
  return (
    <div className="min-h-screen bg-gradient-to-r from-blue-500 to-indigo-600 flex justify-center items-center">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
        {children}
      </div>
    </div>
  );
};

const PublicRouteSignup = ({ children, isBidRoute = false }) => {
  const token = localStorage.getItem('token');
  if (token && !isBidRoute) {
    return <Navigate to="/" replace />;
  }
  return (
    <div className="min-h-screen bg-gradient-to-r from-blue-500 to-indigo-600 flex justify-center items-center">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full">
        {children}
      </div>
    </div>
  );
};

function App() {

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const onboarding = urlParams.get('onboarding');
    const checkoutSessionId = urlParams.get('session_id');

    if (onboarding === 'true' && checkoutSessionId) {
      // Verify the checkout session was successful on the backend
      api.post('verify-checkout-session/', { session_id: checkoutSessionId })
        .then(response => {
          if (response.data.verified) {
            localStorage.setItem('showOnboarding', 'true');
            // Clean up the URL
            window.history.replaceState({}, document.title, window.location.pathname);
          }
        })
        .catch(error => {
          console.error('Failed to verify checkout session:', error);
        });
    }
  }, []);

  return (
    <ErrorBoundary>
      <SubscriptionProvider>
        <FeatureAccessProvider>
          <Router>
            <ToastContainer />
            <Routes>
              <Route path="/bids/:bidId/invitation/:invitationId" element={<PublicRoute><BidResponse /></PublicRoute>} />
              <Route path="/create-bid-account/:bidId/:invitationId" element={
                <PublicBidLayout>
                  <CreateBidAccount />
                </PublicBidLayout>
              } />


              {/* Public Routes */}
              <Route path="/login" element={<PublicRoute isBidRoute={true}><Login /></PublicRoute>} />
              <Route path="/register" element={<PublicRouteSignup><Signup /></PublicRouteSignup>} />
              <Route path="/reset-password" element={<PasswordReset />} />
              <Route path="/password-reset-confirm/:uid/:token" element={<PasswordResetConfirm />} />
              <Route path="/checkout/canceled" element={<CheckoutCanceledPage />} />
              <Route path="/checkout/success" element={
                <CheckoutSuccessHandler />
              } />
              <Route path="/promotion/:promotionId" element={<PublicRouteSignup><PromotionSignup /></PublicRouteSignup>} />

              {/* Tenant Admin Routes */}
              <Route path="/dashboard" element={
                <RouteGuard allowedRoles={['Tenant Admin']}>
                  <AnalyticsDashboard />
                </RouteGuard>
              } />
              <Route
                path="/onboarding"
                element={
                  <RouteGuard allowedRoles={[
                    'Tenant Admin',
                    'Tenant Admin Project',
                    'Tenant Admin Safety',
                    'Tenant Admin Bid'
                  ]}>
                    <OnboardingFlow />
                  </RouteGuard>
                }
              />
              <Route path="/projects" element={
                <RouteGuard allowedRoles={['Tenant Admin', 'Tenant Member', 'Tenant Admin Project']}>
                  <Projects />
                </RouteGuard>
              } />
              <Route path="/projects/:projectId" element={
                <RouteGuard allowedRoles={['Tenant Admin', 'Tenant Admin Project']}>
                  <ProjectDetail />
                </RouteGuard>
              } />
              <Route path="/projects/:projectId/settings" element={
                <RouteGuard allowedRoles={['Tenant Admin', 'Tenant Admin Project']}>
                  <ProjectSettings />
                </RouteGuard>
              } />
              <Route path="/projects/:projectId/rfis/create" element={
                <RouteGuard allowedRoles={['Tenant Admin', 'Tenant Admin Project', 'Contractor Admin']}>
                  <RFIForm />
                </RouteGuard>
              } />
              <Route path="/docs" element={
                <RouteGuard allowedRoles={['Tenant Admin']}>
                  <DocumentManagement />
                </RouteGuard>
              } />

              <Route path="/docs/project" element={
                <RouteGuard allowedRoles={['Tenant Admin', 'Tenant Admin Project']}>
                  <DocumentManagement featureType="project" />
                </RouteGuard>
              } />


              <Route path="/safety/:projectId" element={
                <RouteGuard allowedRoles={[
                  'Tenant Admin',
                  'Tenant Admin Safety',
                  'Safety Manager',
                  'Safety Officer',
                  'Quality Control Officer'
                ]}>
                  <Safety />
                </RouteGuard>
              } />
              <Route path="/projects/:projectId/safety-training" element={
                <RouteGuard allowedRoles={[
                  'Tenant Admin',
                  'Tenant Admin Safety',
                  'Safety Manager',
                  'Safety Officer',
                  'Quality Control Officer'
                ]}>
                  <SafetyTrainingManager />
                </RouteGuard>
              } />

              <Route path="/projects/:projectId/quality-issues" element={
                <RouteGuard allowedRoles={[
                  'Tenant Admin',
                  'Tenant Admin Project',
                  'Tenant Admin Safety',
                  'Safety Manager',
                  'Safety Officer',
                  'Quality Control Officer'
                ]}>
                  <QualityIssuesManager />
                </RouteGuard>
              } />


              <Route path="/projects/:projectId/inspections" element={
                <RouteGuard allowedRoles={[
                  'Tenant Admin',
                  'Tenant Admin Project',
                  'Tenant Admin Safety',
                  'Safety Manager',
                  'Safety Officer',
                  'Quality Control Officer'
                ]}>
                  <InspectionManager />
                </RouteGuard>
              } />


              <Route path="/safety" element={
                <RouteGuard allowedRoles={[
                  'Tenant Admin',
                  'Tenant Admin Safety',
                  'Safety Manager',
                  'Safety Officer',
                  'Quality Control Officer',
                  'Safety Director',
                  'Safety Coordinator'
                ]}>
                  <Safety />
                </RouteGuard>
              } />

              <Route path="/safety/:projectId" element={
                <RouteGuard allowedRoles={[
                  'Tenant Admin',
                  'Tenant Admin Safety',
                  'Safety Manager',
                  'Safety Officer',
                  'Quality Control Officer',
                  'Safety Director',
                  'Safety Coordinator'
                ]}>
                  <Safety />
                </RouteGuard>
              } />


              <Route path="/docs/safety" element={
                <RouteGuard allowedRoles={[
                  'Tenant Admin',
                  'Tenant Admin Safety',
                  'Safety Manager',
                  'Safety Officer',
                  'Quality Control Officer',
                  'Safety Director',
                  'Safety Coordinator'
                ]}>
                  <DocumentManagement featureType="safety" />
                </RouteGuard>
              } />

              <Route path="/safety/:projectId/templates" element={
                <RouteGuard allowedRoles={[
                  'Tenant Admin',
                  'Tenant Admin Safety',
                  'Safety Manager',
                  'Safety Director'
                ]}>
                  <Safety />
                </RouteGuard>
              } />

              {/* Bid-specific Document Routes */}
              <Route path="/docs/bid" element={
                <RouteGuard allowedRoles={['Tenant Admin', 'Tenant Admin Bid']}>
                  <DocumentManagement featureType="bid" />
                </RouteGuard>
              } />
              <Route path="/communication" element={
                <RouteGuard allowedRoles={['Tenant Admin']}>
                  <CommunicationHub />
                </RouteGuard>
              } />
              <Route path="/projects/:projectId/communications/:communicationId" element={
                <RouteGuard allowedRoles={['Tenant Admin']}>
                  <CommunicationHub />
                </RouteGuard>
              } />



              {/* Common Routes for All Roles */}
              <Route path="/settings" element={
                <RouteGuard allowedRoles={['Tenant Admin', 'Tenant Admin Safety', 'Tenant Admin Bid', , 'Tenant Admin Project', 'Contractor Admin', 'Client Admin', 'Tenant Member', 'Bid Submitter']}>
                  <Settings />
                </RouteGuard>
              } />

              {/* Contractor Routes */}
              <Route path="/contractor-portal" element={
                <RouteGuard allowedRoles={['Tenant Admin', 'Contractor Admin']}>
                  <ContractorPortalLayout />
                </RouteGuard>
              } />
              <Route path="/contractor" element={
                <RouteGuard allowedRoles={['Contractor Admin']}>
                  <ContractorDashboard />
                </RouteGuard>
              } />
              <Route path="/contractor-management" element={
                <RouteGuard allowedRoles={['Tenant Admin']}>
                  <ContractorManagement />
                </RouteGuard>
              } />
              <Route path="/contractor/projects" element={
                <RouteGuard allowedRoles={['Contractor Admin']}>
                  <ContractorProjects />
                </RouteGuard>
              } />
              <Route path="/contractor/projects/:projectId" element={
                <RouteGuard allowedRoles={['Contractor Admin']}>
                  <ContractorProjectDetail />
                </RouteGuard>
              } />
              <Route path="/contractor/tasks" element={
                <RouteGuard allowedRoles={['Contractor Admin']}>
                  <ContractorTasks />
                </RouteGuard>
              } />
              <Route path="/contractor/rfis" element={
                <RouteGuard allowedRoles={['Tenant Admin', 'Tenant Admin Project', 'Contractor Admin']}>
                  <ContractorRFIs />
                </RouteGuard>
              } />

              {/* Client Routes */}
              <Route path="/client-portal/*" element={
                <RouteGuard allowedRoles={['Client Admin']}>
                  <ClientPortalRoutes />
                </RouteGuard>
              } />

              <Route path="/client-management" element={
                <RouteGuard allowedRoles={['Tenant Admin']}>
                  <ClientManagement />
                </RouteGuard>

              } />

              <Route path="/supplier" element={
                <RouteGuard allowedRoles={['Tenant Admin', 'Tenant Admin Project', 'Tenant Admin Bid']}>
                  <SupplierManagement />
                </RouteGuard>
              } />
              <Route path="/supplier/:supplierId" element={
                <RouteGuard allowedRoles={['Tenant Admin', 'Tenant Admin Project', 'Tenant Admin Bid']}>
                  <SupplierDetail />
                </RouteGuard>
              } />

              <Route path="/supplier/categories" element={
                <RouteGuard allowedRoles={['Tenant Admin', 'Tenant Admin Project', 'Tenant Admin Bid']}>
                  <SupplierCategories />
                </RouteGuard>
              } />

              <Route path="/supplier/:supplierId/documents" element={
                <RouteGuard allowedRoles={['Tenant Admin', 'Tenant Admin Project', 'Tenant Admin Bid']}>
                  <SupplierDocuments />
                </RouteGuard>
              } />

              <Route path="/supplier-categories" element={
                <RouteGuard allowedRoles={['Tenant Admin', 'Tenant Admin Project', 'Tenant Admin Bid']}>
                  <SupplierCategoriesPage />
                </RouteGuard>
              } />
              {/* Tenant Admin Additional Routes */}
              {/* <Route path="/estimates" element={
              <RouteGuard allowedRoles={['Tenant Admin']}>
                <EstimateList />
              </RouteGuard>
            } />
            <Route path="/estimates/new" element={
              <RouteGuard allowedRoles={['Tenant Admin']}>
                <NewEstimate />
              </RouteGuard>
            } />
            <Route path="/estimates/:estimateId" element={
              <RouteGuard allowedRoles={['Tenant Admin']}>
                <EstimatePage />
              </RouteGuard>
            } />
            <Route path="/estimates/:estimateId/conceptual" element={
              <RouteGuard allowedRoles={['Tenant Admin']}>
                <EstimateHandler />
              </RouteGuard>
            } />
            <Route path="/estimates/:estimateId/approve" element={
              <RouteGuard allowedRoles={['Tenant Admin']}>
                <EstimateApprovalRouter />
              </RouteGuard>
            } />
            <Route path="/conceptual-estimates/:estimateId/approve-review" element={
              <RouteGuard allowedRoles={['Tenant Admin']}>
                <EstimateApprovalRouter />
              </RouteGuard>
            } />
            <Route path="/estimates/:estimateId/conceptual/create" element={
              <RouteGuard allowedRoles={['Tenant Admin']}>
                <CreateConceptualEstimate />
              </RouteGuard>
            } />
            <Route path="/conceptual-estimates/:id" element={
              <RouteGuard allowedRoles={['Tenant Admin']}>
                <ConceptualEstimate />
              </RouteGuard>
            } />
           
            <Route path="/budgets" element={
              <RouteGuard allowedRoles={['Tenant Admin']}>
                <BudgetList />
              </RouteGuard>
            } /> */}

              {/* Bid-specific Routes */}
              <Route path="/bid-management" element={
                <RouteGuard allowedRoles={['Tenant Admin', 'Tenant Admin Bid']}>
                  <BidDashboard />
                </RouteGuard>
              } />
              <Route path="/bid-management/new" element={
                <RouteGuard allowedRoles={['Tenant Admin', 'Tenant Admin Bid']}>
                  <BidSolicitationForm />
                </RouteGuard>
              } />
              <Route path="/bid-management/:id" element={
                <RouteGuard allowedRoles={['Tenant Admin', 'Tenant Admin Bid']}>
                  <BidDetails />
                </RouteGuard>
              } />
              <Route path="/bid-management/:id/compare" element={
                <RouteGuard allowedRoles={['Tenant Admin', 'Tenant Admin Bid']}>
                  <BidComparisonPage />
                </RouteGuard>
              } />

              <Route path="/my-bids/:bidId/submit" element={
                <RouteGuard allowedRoles={['Tenant Admin', 'Bid Submitter']}>
                  <BidSubmissionForm />
                </RouteGuard>
              } />

              <Route path="/bid-management/:bidId/responses/:responseId" element={
                <RouteGuard allowedRoles={['Tenant Admin', 'Tenant Admin Bid']}>
                  <BidResponseDetail />
                </RouteGuard>
              } />

              <Route path="/my-bids" element={
                <RouteGuard allowedRoles={['Tenant Admin', 'Bid Submitter']}>
                  <SubcontractorBidPortal />
                </RouteGuard>
              } />
              <Route path="/my-bids/:id" element={
                <RouteGuard allowedRoles={['Bid Submitter']}>
                  <BidSubmitterDetails />
                </RouteGuard>
              } />
              <Route path="/bids-rfi" element={
                <RouteGuard allowedRoles={['Bid Submitter']}>
                  <SubcontractorBidPortal />
                </RouteGuard>
              } />
              <Route path="/projects/:projectId/budgets/new" element={
                <RouteGuard allowedRoles={['Tenant Admin']}>
                  <CreateBudget />
                </RouteGuard>
              } />
              <Route path="/projects/:projectId/budgets/:budgetId/advanced" element={
                <RouteGuard allowedRoles={['Tenant Admin']}>
                  <BudgetDetail />
                </RouteGuard>
              } />
              <Route path="/projects/:projectId/budgets/:budgetId/basic" element={
                <RouteGuard allowedRoles={['Tenant Admin']}>
                  <BasicBudgetDetail />
                </RouteGuard>
              } />
              <Route path="/projects/:projectId/budgets" element={
                <RouteGuard allowedRoles={['Tenant Admin']}>
                  <BudgetList projectId={':projectId'} />
                </RouteGuard>
              } />
              <Route path="/projects/:projectId/timeline" element={
                <RouteGuard allowedRoles={['Tenant Admin', 'Tenant Admin Project']}>
                  <ProjectTimeline />
                </RouteGuard>
              } />
              <Route path="/projects/:projectId/resources" element={
                <RouteGuard allowedRoles={['Tenant Admin', 'Tenant Admin Project']}>
                  <ResourceManager />
                </RouteGuard>
              } />
              <Route path="/projects/:projectId/tasks" element={
                <RouteGuard allowedRoles={['Tenant Admin', 'Tenant Admin Project']}>
                  <TaskManagement />
                </RouteGuard>
              } />
              <Route path="/tasks" element={
                <RouteGuard allowedRoles={['Tenant Admin', 'Tenant Admin Project']}>
                  <TaskManagement />
                </RouteGuard>
              } />

              {/* Subscription Routes */}
              <Route path="/payment/success" element={
                <RouteGuard allowedRoles={[
                  'Tenant Admin',
                  'Tenant Admin Project',
                  'Tenant Admin Safety',
                  'Tenant Admin Bid'
                ]}>
                  <SubscriptionSuccess />
                </RouteGuard>
              } />
              <Route path="/subscription" element={
                <RouteGuard allowedRoles={[
                  'Tenant Admin',
                  'Tenant Admin Project',
                  'Tenant Admin Safety',
                  'Tenant Admin Bid'
                ]}>
                  <SubscriptionManagement />
                </RouteGuard>
              } />
              <Route
                path="/projects/:projectId/risks"
                element={
                  <RouteGuard allowedRoles={['Tenant Admin', 'Tenant Admin Project']}>
                    <RiskManagementDashboard />
                  </RouteGuard>
                }
              />


              <Route path="*" element={<NotFound />} />
            </Routes>
          </Router>
        </FeatureAccessProvider>
      </SubscriptionProvider>
    </ErrorBoundary>
  );
}

export default App;