import React, { useState, useEffect } from 'react';
import api from '../../services/api';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Loader } from 'lucide-react';
import { primaryButtonClasses, secondaryButtonClasses } from '../common/ButtonStyles';


const MilestoneDropdown = ({ selectedMilestone, onMilestoneSelect, projectId: propProjectId }) => {
    const { projectId: urlProjectId } = useParams();
    const finalProjectId = propProjectId || urlProjectId;
    const [milestones, setMilestones] = useState([]);
    const [isCreating, setIsCreating] = useState(false);
    const [newMilestoneName, setNewMilestoneName] = useState('');
    const [startDate, setStartDate] = useState('');
    const [dueDate, setDueDate] = useState('');
    const [tenant, setTenant] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    // Fetch project details to get tenant ID
    useEffect(() => {
        const fetchProjectDetails = async () => {
            if (!finalProjectId) return;

            try {
                const response = await api.get(`projects/${finalProjectId}/`);
                setTenant(response.data.tenant);
            } catch (error) {
                console.error('Error fetching project details:', error);
                toast.error('Failed to fetch project details');
            }
        };

        fetchProjectDetails();
    }, [finalProjectId]);

    // Fetch milestones for the selected project
    useEffect(() => {
        const fetchMilestones = async () => {
            if (!finalProjectId) return;

            setIsLoading(true);
            setError(null);

            try {
                const response = await api.get(`milestones/?project_id=${finalProjectId}`);
                setMilestones(response.data.results || []);
            } catch (error) {
                console.error('Error fetching milestones:', error);
                setError('Failed to fetch milestones');
                toast.error('Error loading milestones');
            } finally {
                setIsLoading(false);
            }
        };

        fetchMilestones();
    }, [finalProjectId]);

    const handleSelectChange = (e) => {
        const value = e.target.value;
        if (typeof onMilestoneSelect === 'function') {
            onMilestoneSelect(value);
        } else {
            console.error('onMilestoneSelect is not a function');
        }
    };

    const validateMilestoneData = () => {
        if (!newMilestoneName.trim()) {
            toast.error('Please enter a milestone name');
            return false;
        }
        if (!startDate) {
            toast.error('Please select a start date');
            return false;
        }
        if (!dueDate) {
            toast.error('Please select a due date');
            return false;
        }
        if (new Date(startDate) > new Date(dueDate)) {
            toast.error('Start date cannot be after due date');
            return false;
        }
        if (!tenant) {
            toast.error('No tenant ID available');
            return false;
        }
        return true;
    };

    const resetForm = () => {
        setNewMilestoneName('');
        setStartDate('');
        setDueDate('');
        setIsCreating(false);
    };

    const handleMilestoneCreate = async () => {
        if (!validateMilestoneData()) return;

        setIsLoading(true);

        const payload = {
            name: newMilestoneName.trim(),
            start_date: startDate,
            due_date: dueDate,
            project: finalProjectId,
            tenant: tenant
        };

        try {
            const response = await api.post('milestones/', payload);
            setMilestones(prev => [...prev, response.data]);
            // Immediately select the newly created milestone
            const newMilestoneId = String(response.data.id);
            if (typeof onMilestoneSelect === 'function') {
                onMilestoneSelect(newMilestoneId);
            }
            toast.success('Milestone created successfully');
            resetForm();
        } catch (error) {
            console.error('Error creating milestone:', error);
            toast.error(error.response?.data?.detail || 'Failed to create milestone');
        } finally {
            setIsLoading(false);
        }
    };

    if (error) {
        return (
            <div className="text-red-500 p-4 border border-red-200 rounded-lg">
                {error}
            </div>
        );
    }

    return (
        <div className="space-y-4">
            <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                    Milestone
                </label>
                <div className="relative">
                    <select
                        value={selectedMilestone || ''}
                        onChange={handleSelectChange}
                        className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
                        disabled={isLoading}
                    >
                        <option value="">Select a Milestone</option>
                        {milestones.map(milestone => (
                            <option key={milestone.id} value={milestone.id}>
                                {milestone.name}
                            </option>
                        ))}
                    </select>
                    {isLoading && (
                        <Loader className="absolute right-3 top-1/2 transform -translate-y-1/2 animate-spin" size={20} />
                    )}
                </div>
            </div>
            {!isCreating ? (
                <button
                    onClick={() => setIsCreating(true)}
                    className="text-blue-600 hover:text-blue-800 text-sm font-medium"
                    disabled={isLoading}
                >
                    + Add New Milestone
                </button>
            ) : (
                <div className="space-y-4 p-4 border border-gray-200 rounded-lg">
                    <h3 className="font-medium">Create New Milestone</h3>

                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            Name
                        </label>
                        <input
                            type="text"
                            value={newMilestoneName}
                            onChange={(e) => setNewMilestoneName(e.target.value)}
                            placeholder="Enter milestone name"
                            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
                        />
                    </div>

                    <div className="grid grid-cols-2 gap-4">
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Start Date
                            </label>
                            <input
                                type="date"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
                            />
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Due Date
                            </label>
                            <input
                                type="date"
                                value={dueDate}
                                onChange={(e) => setDueDate(e.target.value)}
                                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
                            />
                        </div>
                    </div>

                    <div className="flex space-x-4">
                        <button
                            onClick={handleMilestoneCreate}
                            disabled={isLoading}
                            className={primaryButtonClasses}
                        >
                            {isLoading ? 'Creating...' : 'Create Milestone'}
                        </button>
                        <button
                            onClick={() => setIsCreating(false)}
                            disabled={isLoading}
                            className={secondaryButtonClasses}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default MilestoneDropdown;