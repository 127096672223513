import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '../../components/ui/dialog';
import { AlertDialog, AlertDialogContent, AlertDialogHeader, AlertDialogTitle, AlertDialogDescription, AlertDialogAction, AlertDialogCancel, AlertDialogFooter } from '../../components/ui/alert-dialog';
import { Table, TableHeader, TableBody, TableRow, TableHead, TableCell } from '../../components/ui/table';
import { Button } from '../../components/ui/button';
import { Form, FormField, FormItem, FormLabel, FormControl, FormMessage } from '../../components/ui/form';
import { Input } from '../../components/ui/input';
import { Textarea } from '../../components/ui/textarea';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../components/ui/select';
import { Edit, FileText, Trash2, Plus, X } from 'lucide-react';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import api from '../../services/api';
import safetyApi from '../../services/safetyService';
import { toast } from 'react-toastify';
import { primaryButtonClasses } from '../../components/common/ButtonStyles';
import LoadingSpinner from '../../components/common/LoadingSpinner';

const SeverityBadge = ({ severity }) => {
    const colors = {
        low: 'bg-green-100 text-green-800',
        medium: 'bg-yellow-100 text-yellow-800',
        high: 'bg-orange-100 text-orange-800',
        critical: 'bg-red-100 text-red-800',
    };

    return (
        <span className={`px-2 py-1 rounded-full text-xs font-medium ${colors[severity]}`}>
            {severity}
        </span>
    );
};

const formSchema = z.object({
    incident_type: z.string(),
    incident_date: z.string(),
    location: z.string(),
    severity: z.string(),
    description: z.string(),
    status: z.string(),
    corrective_actions: z.array(z.object({
        action: z.string(),
        responsible: z.string(),
        due_date: z.string(),
        completed: z.boolean().default(false)
    }))
});

export const IncidentsTable = ({ project, onIncidentUpdated }) => {
    const [incidents, setIncidents] = useState([]);
    const [incidentTypes, setIncidentTypes] = useState({});
    const [sortConfig, setSortConfig] = useState({ key: 'incident_date', direction: 'desc' });
    const [filters, setFilters] = useState({
        severity: '',
        status: '',
    });
    const [isLoading, setIsLoading] = useState(true);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [selectedIncident, setSelectedIncident] = useState(null);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [viewDialogOpen, setViewDialogOpen] = useState(false);
    const [correctiveActions, setCorrectiveActions] = useState([]);
    const [isClosingTicket, setIsClosingTicket] = useState(false);

    const form = useForm({
        resolver: zodResolver(formSchema),
        defaultValues: {
            incident_type: '',
            incident_date: '',
            location: '',
            severity: '',
            description: '',
            status: '',
            corrective_actions: []
        }
    });

    const formatIncidentType = (type) => {
        if (!type) return '';
        const parts = type.split('_');
        return parts.length > 1 ? parts.slice(1).join(' ') : type;
    };

    useEffect(() => {
        fetchIncidents();
        fetchIncidentTypes();
    }, [project.id]);

    const fetchIncidentTypes = async () => {
        try {
            const response = await safetyApi.getIncidentTypes();
            const typesMap = response.data.types.reduce((acc, type) => {
                acc[type.id] = type.name;
                return acc;
            }, {});
            setIncidentTypes(typesMap);
        } catch (error) {
            console.error('Error fetching incident types:', error);
        }
    };

    const fetchIncidents = async () => {
        try {
            setIsLoading(true);
            const response = await api.get(`projects/${project.id}/safety-incidents/`);
            setIncidents(response.data.results || []);
        } catch (error) {
            console.error('Error fetching incidents:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleEdit = async (incident) => {
        setSelectedIncident(incident);
        form.reset({
            incident_type: incident.incident_type,
            incident_date: incident.incident_date.split('T')[0],
            location: incident.location,
            severity: incident.severity,
            description: incident.description,
            status: incident.status,
            corrective_actions: incident.corrective_actions || []
        });
        setCorrectiveActions(incident.corrective_actions || []);
        setEditDialogOpen(true);
    };

    const addCorrectiveAction = () => {
        const newAction = {
            action: '',
            responsible: '',
            due_date: '',
            completed: false
        };
        const currentActions = form.getValues('corrective_actions') || [];
        form.setValue('corrective_actions', [...currentActions, newAction]);
    };

    const removeCorrectiveAction = (index) => {
        const currentActions = form.getValues('corrective_actions');
        form.setValue('corrective_actions', currentActions.filter((_, i) => i !== index));
    };

    const handleCloseTicket = async (data) => {
        try {
            setIsClosingTicket(true);
            const allActionsCompleted = data.corrective_actions.every(action => action.completed);
            if (!allActionsCompleted) {
                throw new Error('All corrective actions must be completed before closing the ticket');
            }

            await api.patch(
                `projects/${project.id}/safety-incidents/${selectedIncident.id}/`,
                {
                    ...data,
                    status: 'closed',
                    notification_required: true
                }
            );

            await fetchIncidents();
            setEditDialogOpen(false);
            setSelectedIncident(null);
            form.reset();
        } catch (error) {
            console.error('Error closing incident:', error);
        } finally {
            setIsClosingTicket(false);
        }
    };

    const onSubmit = async (data) => {
        try {
            if (data.status === 'closed') {
                await handleCloseTicket(data);
            } else {
                await api.patch(
                    `projects/${project.id}/safety-incidents/${selectedIncident.id}/`,
                    data
                );
                await fetchIncidents();
                setEditDialogOpen(false);
                setSelectedIncident(null);
                form.reset();
            }
        } catch (error) {
            console.error('Error updating incident:', error);
        }
    };

    const handleView = (incident) => {
        setSelectedIncident(incident);
        setViewDialogOpen(true);
    };

    const handleDelete = async () => {
        if (!selectedIncident) return;

        try {

            const response = await api.delete(
                `projects/${project.id}/safety-incidents/${selectedIncident.id}/`
            );

            if (response.status === 204) {
                await fetchIncidents();
                setDeleteDialogOpen(false);
                setSelectedIncident(null);
                toast({
                    title: "Success",
                    description: "Incident deleted successfully",
                });
            }
        } catch (error) {
            console.error('Error deleting incident:', error);
            toast({
                title: "Error",
                description: "Failed to delete incident. Please try again.",
                variant: "destructive",
            });
        }
    };

    const confirmDelete = (incident) => {
        setSelectedIncident(incident);
        setDeleteDialogOpen(true);
    };

    const sortedIncidents = React.useMemo(() => {
        let sorted = [...incidents];
        if (sortConfig.key) {
            sorted.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === 'asc' ? -1 : 1;
                if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === 'asc' ? 1 : -1;
                return 0;
            });
        }
        return sorted.filter(incident =>
            (!filters.severity || incident.severity === filters.severity) &&
            (!filters.status || incident.status === filters.status)
        );
    }, [incidents, sortConfig, filters]);

    const requestSort = (key) => {
        setSortConfig(current => ({
            key,
            direction: current.key === key && current.direction === 'asc' ? 'desc' : 'asc',
        }));
    };

    return (
        <div className="space-y-4">
            <div className="flex gap-4 mb-4">
                <select
                    value={filters.severity}
                    onChange={(e) => setFilters(prev => ({ ...prev, severity: e.target.value }))}
                    className="w-[180px] px-3 py-2 rounded-md border border-gray-300 bg-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                >
                    <option value="">All Severities</option>
                    <option value="low">Low</option>
                    <option value="medium">Medium</option>
                    <option value="high">High</option>
                    <option value="critical">Critical</option>
                </select>

                <select
                    value={filters.status}
                    onChange={(e) => setFilters(prev => ({ ...prev, status: e.target.value }))}
                    className="w-[180px] px-3 py-2 rounded-md border border-gray-300 bg-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                >
                    <option value="">All Statuses</option>
                    <option value="open">Open</option>
                    <option value="in_progress">In Progress</option>
                    <option value="resolved">Resolved</option>
                </select>
            </div>

            <Table>
                <TableHeader>
                    <TableRow>
                        <TableHead
                            onClick={() => requestSort('incident_date')}
                            className="cursor-pointer"
                        >
                            Date {sortConfig.key === 'incident_date' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                        </TableHead>
                        <TableHead>Type</TableHead>
                        <TableHead>Location</TableHead>
                        <TableHead>Severity</TableHead>
                        <TableHead>Status</TableHead>
                        <TableHead>Actions</TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {isLoading ? (
                        <TableRow>
                            <TableCell colSpan={6}>
                                <LoadingSpinner message="Loading incidents..." />
                            </TableCell>
                        </TableRow>
                    ) : sortedIncidents.length === 0 ? (
                        <TableRow>
                            <TableCell colSpan={6} className="text-center">No incidents found</TableCell>
                        </TableRow>
                    ) : (

                        sortedIncidents.map((incident) => (
                            <TableRow key={incident.id}>
                                <TableCell>{new Date(incident.incident_date).toLocaleDateString()}</TableCell>
                                <TableCell>
                                    {formatIncidentType(incidentTypes[incident.incident_type] || incident.incident_type)}
                                </TableCell>
                                <TableCell>{incident.location}</TableCell>
                                <TableCell>
                                    <SeverityBadge severity={incident.severity} />
                                </TableCell>
                                <TableCell>{incident.status}</TableCell>
                                <TableCell>
                                    <div className="flex gap-2">
                                        <Button
                                            variant="ghost"
                                            size="icon"
                                            onClick={() => handleEdit(incident)}
                                        >
                                            <Edit className="h-4 w-4" />
                                        </Button>
                                        <Button
                                            variant="ghost"
                                            size="icon"
                                            onClick={() => handleView(incident)}
                                        >
                                            <FileText className="h-4 w-4" />
                                        </Button>
                                        <Button
                                            variant="ghost"
                                            size="icon"
                                            onClick={() => confirmDelete(incident)}
                                            className="text-red-600 hover:text-red-700"
                                        >
                                            <Trash2 className="h-4 w-4" />
                                        </Button>
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))

                    )}
                </TableBody>
            </Table>

            {/* Edit Dialog */}
            <Dialog open={editDialogOpen} onOpenChange={setEditDialogOpen}>
                <DialogContent className="w-full max-w-2xl max-h-[90vh] overflow-y-auto p-4 md:p-6">
                    <DialogHeader>
                        <DialogTitle>Edit Incident</DialogTitle>
                    </DialogHeader>
                    <Form {...form}>
                        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <FormField
                                    control={form.control}
                                    name="incident_type"
                                    render={({ field }) => (
                                        <FormItem className="col-span-full">
                                            <FormLabel>Type</FormLabel>
                                            <Select onValueChange={field.onChange} defaultValue={field.value}>
                                                <FormControl>
                                                    <SelectTrigger className="w-full">
                                                        <SelectValue placeholder="Select type" />
                                                    </SelectTrigger>
                                                </FormControl>
                                                <SelectContent>
                                                    {Object.entries(incidentTypes).map(([id, name]) => (
                                                        <SelectItem key={id} value={id}>
                                                            {name.split('_')[1] || name}
                                                        </SelectItem>
                                                    ))}
                                                </SelectContent>
                                            </Select>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                <FormField
                                    control={form.control}
                                    name="incident_date"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>Date</FormLabel>
                                            <FormControl>
                                                <Input type="date" {...field} className="w-full" />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                <FormField
                                    control={form.control}
                                    name="severity"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>Severity</FormLabel>
                                            <Select onValueChange={field.onChange} defaultValue={field.value}>
                                                <FormControl>
                                                    <SelectTrigger className="w-full">
                                                        <SelectValue placeholder="Select severity" />
                                                    </SelectTrigger>
                                                </FormControl>
                                                <SelectContent>
                                                    <SelectItem value="low">Low</SelectItem>
                                                    <SelectItem value="medium">Medium</SelectItem>
                                                    <SelectItem value="high">High</SelectItem>
                                                    <SelectItem value="critical">Critical</SelectItem>
                                                </SelectContent>
                                            </Select>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                <FormField
                                    control={form.control}
                                    name="status"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>Status</FormLabel>
                                            <Select onValueChange={field.onChange} defaultValue={field.value}>
                                                <FormControl>
                                                    <SelectTrigger className="w-full">
                                                        <SelectValue placeholder="Select status" />
                                                    </SelectTrigger>
                                                </FormControl>
                                                <SelectContent>
                                                    <SelectItem value="open">Open</SelectItem>
                                                    <SelectItem value="in_progress">In Progress</SelectItem>
                                                    <SelectItem value="resolved">Resolved</SelectItem>
                                                </SelectContent>
                                            </Select>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                <FormField
                                    control={form.control}
                                    name="location"
                                    render={({ field }) => (
                                        <FormItem className="col-span-full">
                                            <FormLabel>Location</FormLabel>
                                            <FormControl>
                                                <Input {...field} className="w-full" />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                <FormField
                                    control={form.control}
                                    name="description"
                                    render={({ field }) => (
                                        <FormItem className="col-span-full">
                                            <FormLabel>Description</FormLabel>
                                            <FormControl>
                                                <Textarea {...field} className="min-h-[100px]" />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                            </div>

                            {/* Corrective Actions Section */}
                            <div className="space-y-4">
                                <div className="flex justify-between items-center flex-wrap gap-2">
                                    <h3 className="text-lg font-medium">Corrective Actions</h3>
                                    <Button
                                        type="button"
                                        variant="outline"
                                        size="sm"
                                        onClick={addCorrectiveAction}
                                    >
                                        <Plus className="h-4 w-4 mr-2" />
                                        Add Action
                                    </Button>
                                </div>

                                {form.watch('corrective_actions')?.map((action, index) => (
                                    <div key={index} className="space-y-4 p-4 border rounded-lg relative">
                                        <Button
                                            type="button"
                                            variant="ghost"
                                            size="sm"
                                            className="absolute top-2 right-2"
                                            onClick={() => removeCorrectiveAction(index)}
                                        >
                                            <X className="h-4 w-4" />
                                        </Button>

                                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                            <FormField
                                                control={form.control}
                                                name={`corrective_actions.${index}.action`}
                                                render={({ field }) => (
                                                    <FormItem className="col-span-full">
                                                        <FormLabel>Action Description</FormLabel>
                                                        <FormControl>
                                                            <Textarea {...field} />
                                                        </FormControl>
                                                        <FormMessage />
                                                    </FormItem>
                                                )}
                                            />

                                            <FormField
                                                control={form.control}
                                                name={`corrective_actions.${index}.responsible`}
                                                render={({ field }) => (
                                                    <FormItem>
                                                        <FormLabel>Responsible Person</FormLabel>
                                                        <FormControl>
                                                            <Input {...field} />
                                                        </FormControl>
                                                        <FormMessage />
                                                    </FormItem>
                                                )}
                                            />

                                            <FormField
                                                control={form.control}
                                                name={`corrective_actions.${index}.due_date`}
                                                render={({ field }) => (
                                                    <FormItem>
                                                        <FormLabel>Due Date</FormLabel>
                                                        <FormControl>
                                                            <Input type="date" {...field} />
                                                        </FormControl>
                                                        <FormMessage />
                                                    </FormItem>
                                                )}
                                            />

                                            <FormField
                                                control={form.control}
                                                name={`corrective_actions.${index}.completed`}
                                                render={({ field }) => (
                                                    <FormItem className="flex items-center space-x-2">
                                                        <FormControl>
                                                            <input
                                                                type="checkbox"
                                                                checked={field.value}
                                                                onChange={field.onChange}
                                                                className="w-4 h-4"
                                                            />
                                                        </FormControl>
                                                        <FormLabel className="m-0">Completed</FormLabel>
                                                        <FormMessage />
                                                    </FormItem>
                                                )}
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>

                            <div className="flex justify-end gap-2 flex-wrap">
                                <Button type="button" variant="outline" onClick={() => setEditDialogOpen(false)}>
                                    Cancel
                                </Button>
                                {selectedIncident?.status !== 'closed' && (
                                    <Button
                                        type="submit"
                                        onClick={() => form.setValue('status', 'closed')}
                                        disabled={isClosingTicket}
                                        className="bg-green-600 hover:bg-green-700"
                                    >
                                        Close Ticket
                                    </Button>
                                )}
                                <Button type="submit">Save Changes</Button>
                            </div>
                        </form>
                    </Form>
                </DialogContent>
            </Dialog>

            {/* View Dialog */}
            <Dialog open={viewDialogOpen} onOpenChange={setViewDialogOpen}>
                <DialogContent className="w-full max-w-2xl max-h-[90vh] overflow-y-auto p-4 md:p-6">
                    <DialogHeader>
                        <DialogTitle>Incident Details</DialogTitle>
                    </DialogHeader>
                    {selectedIncident && (
                        <div className="space-y-6">
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div className="space-y-2">
                                    <h4 className="text-sm font-medium text-gray-600">Type</h4>
                                    <p className="text-base">
                                        {formatIncidentType(incidentTypes[selectedIncident.incident_type] || selectedIncident.incident_type)}
                                    </p>
                                </div>

                                <div className="space-y-2">
                                    <h4 className="text-sm font-medium text-gray-600">Date</h4>
                                    <p className="text-base">
                                        {new Date(selectedIncident.incident_date).toLocaleDateString()}
                                    </p>
                                </div>

                                <div className="space-y-2">
                                    <h4 className="text-sm font-medium text-gray-600">Location</h4>
                                    <p className="text-base">{selectedIncident.location}</p>
                                </div>

                                <div className="space-y-2">
                                    <h4 className="text-sm font-medium text-gray-600">Status</h4>
                                    <p className="text-base capitalize">{selectedIncident.status}</p>
                                </div>

                                <div className="space-y-2">
                                    <h4 className="text-sm font-medium text-gray-600">Severity</h4>
                                    <div>
                                        <SeverityBadge severity={selectedIncident.severity} />
                                    </div>
                                </div>
                            </div>

                            <div className="space-y-2">
                                <h4 className="text-sm font-medium text-gray-600">Description</h4>
                                <p className="text-base whitespace-pre-wrap bg-gray-50 p-3 rounded-md">
                                    {selectedIncident.description}
                                </p>
                            </div>

                            {selectedIncident.corrective_actions?.length > 0 && (
                                <div className="space-y-4">
                                    <h4 className="text-sm font-medium text-gray-600">Corrective Actions</h4>
                                    <div className="grid grid-cols-1 gap-4">
                                        {selectedIncident.corrective_actions.map((action, index) => (
                                            <div key={index} className="bg-gray-50 p-4 rounded-lg space-y-2">
                                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                                    <div>
                                                        <p className="text-sm font-medium text-gray-600">Action</p>
                                                        <p className="text-base">{action.action}</p>
                                                    </div>
                                                    <div>
                                                        <p className="text-sm font-medium text-gray-600">Responsible</p>
                                                        <p className="text-base">{action.responsible}</p>
                                                    </div>
                                                    <div>
                                                        <p className="text-sm font-medium text-gray-600">Due Date</p>
                                                        <p className="text-base">
                                                            {new Date(action.due_date).toLocaleDateString()}
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <p className="text-sm font-medium text-gray-600">Status</p>
                                                        <p className="text-base">
                                                            {action.completed ? (
                                                                <span className="text-green-600 font-medium">Completed</span>
                                                            ) : (
                                                                <span className="text-yellow-600 font-medium">Pending</span>
                                                            )}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}

                            <div className="flex justify-end pt-4">
                                <Button
                                    onClick={() => setViewDialogOpen(false)}
                                    className={primaryButtonClasses}
                                >
                                    Close
                                </Button>
                            </div>
                        </div>
                    )}
                </DialogContent>
            </Dialog>

            {/* Delete Dialog */}
            <AlertDialog open={deleteDialogOpen} onOpenChange={setDeleteDialogOpen}>
                <AlertDialogContent>
                    <AlertDialogHeader>
                        <AlertDialogTitle>Delete Incident</AlertDialogTitle>
                        <AlertDialogDescription>
                            Are you sure you want to delete this incident? This action cannot be undone.
                        </AlertDialogDescription>
                    </AlertDialogHeader>
                    <AlertDialogFooter>
                        <AlertDialogCancel>Cancel</AlertDialogCancel>
                        <AlertDialogAction onClick={handleDelete} className="bg-red-600 hover:bg-red-700">
                            Delete
                        </AlertDialogAction>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
        </div>
    );
};

export default IncidentsTable;