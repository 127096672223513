import React, { useState, useEffect } from 'react';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue
} from '../components/ui/select';
import { Input } from '../components/ui/input';
import api from '../services/api';

const FindingsTab = ({ audit, project, responses }) => {
    const [assignees, setAssignees] = useState([]);
    const [isLoadingAssignees, setIsLoadingAssignees] = useState(false);

    useEffect(() => {
        const fetchAssignees = async () => {
            try {
                setIsLoadingAssignees(true);
                const response = await api.get(`projects/${project.id}/users/`);
                setAssignees(response.data);
            } catch (error) {
                console.error('Error fetching assignees:', error?.response?.data || error);
                // Don't show toast error, just set to empty array
                setAssignees([]);
            } finally {
                setIsLoadingAssignees(false);
            }
        };
        fetchAssignees();
    }, [project.id]);

    const handleAssign = async (responseId, assigneeId, dueDate) => {
        try {
            await api.patch(
                `projects/${project.id}/safety-audits/${audit.id}/responses/${responseId}/`,
                {
                    assigned_to: assigneeId,
                    due_date: dueDate
                }
            );
        } catch (error) {
            console.error('Error assigning correction:', error);
        }
    };

    // Render assignee dropdown with loading state
    const renderAssigneeDropdown = (response) => {
        if (isLoadingAssignees) {
            return <div className="text-sm text-gray-500">Loading assignees...</div>;
        }

        return (
            <Select
                value={response.assigned_to}
                onValueChange={(value) => handleAssign(response.id, value, response.due_date)}
            >
                <SelectTrigger>
                    <SelectValue placeholder="Select assignee" />
                </SelectTrigger>
                <SelectContent>
                    {assignees.length > 0 ? (
                        assignees.map(assignee => (
                            <SelectItem key={assignee.id} value={assignee.id}>
                                {assignee.full_name}
                            </SelectItem>
                        ))
                    ) : (
                        <SelectItem value="none" disabled>No assignees available</SelectItem>
                    )}
                </SelectContent>
            </Select>
        );
    };

    return (
        <div className="space-y-4">
            <div className="bg-blue-50 p-4 rounded-lg mb-4">
                <h3 className="font-medium text-blue-800 mb-2">Non-Compliant Items</h3>
                <p className="text-sm text-blue-600">
                    {responses.length} items require attention
                </p>
            </div>

            {responses.length === 0 ? (
                <div className="text-center text-gray-500 py-8">
                    No non-compliant items found
                </div>
            ) : (
                responses.map(response => (
                    <div key={response.id} className="bg-white p-4 rounded-lg border space-y-3">
                        <div className="flex justify-between items-start">
                            <div>
                                <h4 className="font-medium">{response.audit_item.description}</h4>
                                <p className="text-sm text-gray-500 mt-1">{response.notes}</p>
                            </div>
                            <span className="bg-red-100 text-red-800 text-xs px-2 py-1 rounded-full">
                                Non-Compliant
                            </span>
                        </div>

                        <div className="grid grid-cols-2 gap-4">
                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                    Assign To
                                </label>
                                {renderAssigneeDropdown(response)}
                            </div>

                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                    Due Date
                                </label>
                                <Input
                                    type="date"
                                    value={response.due_date || ''}
                                    onChange={(e) => handleAssign(response.id, response.assigned_to, e.target.value)}
                                    min={new Date().toISOString().split('T')[0]}
                                />
                            </div>
                        </div>

                        {response.photos?.length > 0 && (
                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-2">
                                    Evidence Photos
                                </label>
                                <div className="flex space-x-2">
                                    {response.photos.map(photo => (
                                        <div key={photo.id} className="relative">
                                            <img
                                                src={photo.url}
                                                alt="Evidence"
                                                className="w-16 h-16 object-cover rounded"
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                ))
            )}
        </div>
    );
};

export default FindingsTab;