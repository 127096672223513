import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Card, CardContent, CardHeader, CardTitle } from "../../../ui/cardui";
import { Input } from "../../../ui/input";
import { Button } from "../../../ui/button";
import { Textarea } from "../../../ui/textarea";
import { Label } from "../../../ui/label";
import { Calendar as CalendarIcon, Upload, Plus, X, Loader2 } from 'lucide-react';
import { Calendar } from "../../../ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "../../../ui/popover";
import { DialogDescription } from '../../../ui/dialog';
import { format } from "date-fns";
import { Badge } from "../../../ui/badge";
import { primaryButtonClasses, secondaryButtonClasses } from '../../../common/ButtonStyles';
import { useToast } from "../../../ui/use-toast";
import { showToast } from '../../../../utils/toast';
import { Checkbox } from "../../../ui/checkbox";
import { zodResolver } from "@hookform/resolvers/zod";
import BidDocumentUpload from '../BidDocumentUpload';
import BidDocumentsTab from '../tabs/BidDocumentsTab';

import * as z from "zod";
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
} from "../../../ui/command";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "../../../ui/select";
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "../../../ui/dialog";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../../ui/tabs";
import BidService from '../../../../services/bidService';
import ScopeSection from '../ScopeSection';
import axios from 'axios';
import BidderProfileSelector from '../BidderProfileSelector';
import api from '../../../../services/api';
import { cn } from '../../../../lib/utils';
import TradeCostEstimates from '../TradeCostEstimates';

const TradeSelector = ({ trades, formData, onTradeSelect, isLoading, loadTrades }) => {
    const [open, setOpen] = useState(false);

    if (isLoading) {
        return <Button disabled>Loading trades...</Button>;
    }

    return (
        <div className="space-y-4">
            <Dialog open={open} onOpenChange={setOpen}>
                <DialogTrigger asChild>
                    <Button variant="outline" className="w-full">
                        {trades.length > 0 ? "Select Required Trades" : "No trades available"}
                    </Button>
                </DialogTrigger>
                <DialogContent className="w-[90vw] max-w-[500px] h-[80vh] flex flex-col p-0">
                    <DialogHeader className="px-4 py-2 border-b">
                        <DialogTitle>Select Trades</DialogTitle>
                        <DialogDescription>
                            Choose required trades for your bid request
                        </DialogDescription>
                    </DialogHeader>

                    <div className="flex-1 overflow-y-auto px-4">
                        {trades.length === 0 ? (
                            <div className="flex flex-col items-center justify-center h-full p-4">
                                <p className="text-muted-foreground text-center mb-4">
                                    No trades available
                                </p>
                                <Button onClick={loadTrades}>Refresh Trades</Button>
                            </div>
                        ) : (
                            <div className="grid gap-2 py-4">
                                <Input
                                    placeholder="Search trades..."
                                    className="mb-2"
                                    onChange={(e) => {
                                        // Add search functionality here
                                    }}
                                />
                                <div className="grid gap-2">
                                    {trades.map((trade) => (
                                        <div
                                            key={trade.id}
                                            className="flex items-center justify-between p-3 rounded-lg border hover:bg-accent cursor-pointer"
                                            onClick={() => {
                                                onTradeSelect(trade);
                                                setOpen(false);
                                            }}
                                        >
                                            <span className="font-medium">{trade.name}</span>
                                            {formData.required_trades?.includes(trade.id) && (
                                                <Badge variant="secondary">Selected</Badge>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                </DialogContent>
            </Dialog>

            {formData.required_trades?.length > 0 && (
                <div className="rounded-lg border p-4 space-y-3">
                    <div className="flex items-center justify-between">
                        <h3 className="font-medium">Selected Trades</h3>
                        <Badge variant="secondary">
                            {formData.required_trades.length} selected
                        </Badge>
                    </div>
                    <div className="flex flex-wrap gap-2">
                        {formData.required_trades.map((tradeId) => {
                            const trade = trades.find(t => t.id === tradeId);
                            return trade ? (
                                <Badge
                                    key={trade.id}
                                    className="flex items-center gap-2 py-1.5"
                                >
                                    {trade.name}
                                    <Button
                                        type="button"
                                        variant="ghost"
                                        size="sm"
                                        className="h-4 w-4 p-0 hover:bg-transparent"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            onTradeSelect(trade);
                                            e.preventDefault();
                                            e.stopPropagation();
                                        }}
                                    >
                                        <X className="h-3 w-3" />
                                    </Button>
                                </Badge>
                            ) : null;
                        })}
                    </div>
                </div>
            )}
        </div>
    );
};

const BidSolicitationForm = () => {
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [selectedContractors, setSelectedContractors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [documents, setDocuments] = useState([]);
    const [trades, setTrades] = useState([]);
    const [tradeInput, setTradeInput] = useState('');
    const [date, setDate] = useState(null);
    const navigate = useNavigate();
    const { toast } = useToast();
    const [contractors, setContractors] = useState([]);
    const [selectedProfiles, setSelectedProfiles] = useState({});
    const [marketplaceData, setMarketplaceData] = useState({});
    const [loadingError, setLoadingError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [errors, setErrors] = useState({});
    const [documentTypes, setDocumentTypes] = useState([]);
    const [bidId, setBidId] = useState(null);
    const [isEditMode, setIsEditMode] = useState(false);
    const [existingBidId, setExistingBidId] = useState(null);

    const [formData, setFormData] = useState({
        title: '',
        description: '',
        scope: {
            general_requirements: '',
            specific_requirements: [],
            exclusions: [],
            deliverables: []
        },
        required_trades: [],
        notify_subcontractors: true,
        trade_estimates: {},
        submission_deadline: null
    });

    useEffect(() => {
        const fetchDocumentTypes = async () => {
            try {
                const response = await api.get('bid-document-types/');
                setDocumentTypes(response.data);
            } catch (error) {
                showToast.error("Failed to load document types");
                toast({
                    title: "Error",
                    description: "Failed to load document types",
                    variant: "destructive"
                });
            } finally {
                setIsLoading(false);
            }
        };

        fetchDocumentTypes();
    }, []);

    useEffect(() => {
        const loadExistingBid = async () => {
            if (bidId) {
                try {
                    const response = await BidService.getBidRequest(bidId);
                    const bidData = response.data;

                    setIsEditMode(true);
                    setExistingBidId(bidId);

                    // Update form data with existing bid details
                    setFormData({
                        title: bidData.title,
                        description: bidData.description,
                        scope: bidData.scope || {
                            general_requirements: '',
                            specific_requirements: [],
                            exclusions: [],
                            deliverables: []
                        },
                        required_trades: bidData.required_trades || [],
                        notify_subcontractors: bidData.notify_subcontractors,
                        trade_estimates: bidData.trade_estimates.reduce((acc, estimate) => ({
                            ...acc,
                            [estimate.trade_id]: {
                                estimated_cost: estimate.estimated_cost,
                                notes: estimate.notes || ''
                            }
                        }), {})
                    });

                    // Set submission deadline
                    if (bidData.submission_deadline) {
                        setDate(new Date(bidData.submission_deadline));
                    }


                    if (bidData.documents) {
                        // Format existing documents correctly
                        const formattedDocuments = bidData.documents.map(doc => ({
                            ...doc,
                            id: doc.id,
                            name: doc.title,
                            documentType: doc.document_type,
                            status: 'uploaded',
                            typeInfo: documentTypes?.results?.find(t => t.id === doc.document_type)
                        }));
                        setDocuments(formattedDocuments);
                    }

                    // Load existing selected profiles/contractors
                    if (bidData.invitations) {
                        const profilesByTrade = {};
                        bidData.invitations.forEach(invitation => {
                            invitation.profile.specialized_trades.forEach(trade => {
                                if (!profilesByTrade[trade.id]) {
                                    profilesByTrade[trade.id] = [];
                                }
                                profilesByTrade[trade.id].push(invitation.profile.id);
                            });
                        });
                        setSelectedProfiles(profilesByTrade);
                    }

                } catch (error) {
                    console.error('Error loading bid:', error);
                }
            }
        };

        loadExistingBid();
    }, [bidId]);

    const validateTradeEstimates = () => {
        const newErrors = {};

        // Check if all required trades have estimates
        formData.required_trades.forEach(tradeId => {
            const estimate = formData.trade_estimates[tradeId];
            if (!estimate || !estimate.estimated_cost) {
                newErrors[tradeId] = 'Estimated cost is required';
            } else if (isNaN(parseFloat(estimate.estimated_cost)) || parseFloat(estimate.estimated_cost) <= 0) {
                newErrors[tradeId] = 'Please enter a valid cost';
            }
        });

        return newErrors;
    };



    const validateForm = () => {
        const newErrors = {};

        if (!formData.title || formData.title.length < 3) {
            newErrors.title = 'Title must be at least 3 characters';
        }

        if (!formData.description || formData.description.length < 10) {
            newErrors.description = 'Description must be at least 10 characters';
        }

        if (!date) {
            newErrors.date = 'Please select a submission deadline';
        }

        if (!formData.required_trades.length) {
            newErrors.required_trades = 'At least one trade is required';
        }

        // Initialize trade_estimates errors object
        newErrors.trade_estimates = {};

        // Validate trade estimates
        formData.required_trades.forEach(tradeId => {
            const estimate = formData.trade_estimates[tradeId];
            if (!estimate?.estimated_cost) {
                newErrors.trade_estimates[tradeId] = 'Estimated cost is required';
            } else if (isNaN(estimate.estimated_cost) || estimate.estimated_cost <= 0) {
                newErrors.trade_estimates[tradeId] = 'Please enter a valid cost';
            }
        });


        setErrors(newErrors);
        return Object.keys(newErrors).length === 0 ||
            (Object.keys(newErrors).length === 1 &&
                Object.keys(newErrors.trade_estimates).length === 0);
    };

    // Field change handlers
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleProfileSelect = (tradeId, profile) => {
        setSelectedProfiles(prev => ({
            ...prev,
            [tradeId]: [...(prev[tradeId] || []), profile.id]
        }));
    };

    const handleScopeChange = (field, value) => {
        setFormData(prev => ({
            ...prev,
            scope: {
                ...prev.scope,
                [field]: value
            }
        }));
    };

    // Add/remove handlers for arrays
    const handleAddRequirement = () => {
        setFormData(prev => ({
            ...prev,
            scope: {
                ...prev.scope,
                specific_requirements: [...(prev.scope.specific_requirements || []), '']
            }
        }));
    };

    const handleUpdateRequirement = (index, value) => {
        setFormData(prev => {
            const newReqs = [...prev.scope.specific_requirements];
            newReqs[index] = value;
            return {
                ...prev,
                scope: {
                    ...prev.scope,
                    specific_requirements: newReqs
                }
            };
        });
    };

    const handleRemoveRequirement = (index) => {
        setFormData(prev => ({
            ...prev,
            scope: {
                ...prev.scope,
                specific_requirements: prev.scope.specific_requirements.filter((_, i) => i !== index)
            }
        }));
    };

    const loadTrades = async () => {
        setIsLoading(true);
        setLoadingError(null);
        try {
            const response = await api.get('construction-trades/');
            setTrades(response?.data || []);
        } catch (error) {
            setLoadingError("Failed to load trades");
            setTrades([]);
        } finally {
            setIsLoading(false);
        }
    };

    const handleTradeSelect = (trade) => {
        setFormData(prev => {
            const currentTrades = prev.required_trades;
            const isCurrentlySelected = currentTrades.includes(trade.id);
            const newTrades = isCurrentlySelected
                ? currentTrades.filter(id => id !== trade.id)
                : [...currentTrades, trade.id];

            // Update trade estimates while preserving existing ones
            const newEstimates = { ...prev.trade_estimates };
            if (isCurrentlySelected) {
                // Remove estimate if trade is deselected
                delete newEstimates[trade.id];
                // Also cleanup the selected profiles for this trade if needed
                setSelectedProfiles(prevProfiles => {
                    const updatedProfiles = { ...prevProfiles };
                    delete updatedProfiles[trade.id];
                    return updatedProfiles;
                });
            } else {
                // Initialize estimate if trade is selected
                newEstimates[trade.id] = {
                    estimated_cost: '',
                    notes: ''
                };
            }

            return {
                ...prev,
                required_trades: newTrades,
                trade_estimates: newEstimates
            };
        });
    };

    // Document handlers
    const handleDocumentUpload = (e) => {
        const files = Array.from(e.target.files);
        setDocuments(prev => [...prev, ...files]);
    };

    const removeDocument = (index) => {
        setDocuments(prev => prev.filter((_, i) => i !== index));
    };


    const updateFormWithRefreshedData = (refreshedData) => {
        setFormData({
            title: refreshedData.title,
            description: refreshedData.description,
            scope: refreshedData.scope,
            required_trades: refreshedData.required_trades,
            notify_subcontractors: refreshedData.notify_subcontractors,
            trade_estimates: refreshedData.trade_estimates.reduce((acc, estimate) => ({
                ...acc,
                [estimate.trade_id]: {
                    estimated_cost: estimate.estimated_cost,
                    notes: estimate.notes || ''
                }
            }), {})
        });

        // Update other state
        setDate(new Date(refreshedData.submission_deadline));
        if (refreshedData.documents) {
            setDocuments(refreshedData.documents);
        }

        // Update selected profiles if needed
        if (refreshedData.invitations) {
            const profilesByTrade = {};
            refreshedData.invitations.forEach(invitation => {
                invitation.profile.specialized_trades.forEach(trade => {
                    if (!profilesByTrade[trade.id]) {
                        profilesByTrade[trade.id] = [];
                    }
                    profilesByTrade[trade.id].push(invitation.profile.id);
                });
            });
            setSelectedProfiles(profilesByTrade);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            showToast.error("Please check the form for errors");
            return;
        }

        try {
            setIsSubmitting(true);

            // Format trade estimates using Object.entries to ensure all trades are included
            const tradeEstimates = Object.entries(formData.trade_estimates).map(([tradeId, estimate]) => ({
                trade_id: parseInt(tradeId),
                estimated_cost: parseFloat(estimate.estimated_cost),
                notes: estimate.notes || ''
            }));

            // Keep all existing request data structure, just update trade_estimates
            const requestData = {
                title: formData.title,
                description: formData.description,
                scope: formData.scope,
                required_trades: formData.required_trades,
                submission_deadline: date.toISOString(),
                notify_subcontractors: formData.notify_subcontractors,
                trade_estimates: tradeEstimates
            };

            // Create bid request
            const bidResponse = await BidService.createBidRequest(requestData);
            const newBidId = bidResponse.data.id;

            // Keep your exact document upload code as is
            if (documents.length > 0) {
                for (const doc of documents) {
                    if (!doc.documentType) {

                        continue;
                    }

                    const formData = new FormData();
                    formData.append('file', doc.file);
                    formData.append('bid', newBidId);
                    formData.append('document_type_id', doc.documentType);
                    formData.append('owner_type', 'request');
                    formData.append('title', doc.name);
                    formData.append('file_type', 'file');

                    try {
                        await api.post('bid-documents/', formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        });

                    } catch (uploadError) {
                        console.error('Failed to upload document:', doc.name, uploadError);
                        showToast.error(`Failed to upload ${doc.name}`);
                    }
                }
            }

            // Keep your exact invitation handling code as is
            if (formData.notify_subcontractors && Object.keys(selectedProfiles).length > 0) {
                const profileIds = Object.values(selectedProfiles).flat();

                if (profileIds.length > 0) {
                    try {
                        await BidService.createBulkInvitations(newBidId, profileIds);
                        showToast.success(`Invitations sent to ${profileIds.length} contractors`);
                    } catch (error) {
                        console.error('Failed to send invitations:', error);
                        showToast.warning("Failed to send some invitations");
                    }
                }
            }

            showToast.success("Bid request created successfully");
            setTimeout(() => {
                navigate('/bid-management');
            }, 500);

        } catch (error) {
            console.error('Submission error:', error);
            const errorMessage = error.response?.data?.message || "Failed to process bid request";
            showToast.error(errorMessage);
        } finally {
            setIsSubmitting(false);
        }
    };


    return (
        <div className="max-w-4xl mx-auto p-6">
            <Card>
                <CardHeader>
                    <CardTitle>Create Bid Request</CardTitle>
                </CardHeader>
                <CardContent>
                    <form onSubmit={handleSubmit} className="space-y-6">
                        {/* Title Field */}
                        <div className="space-y-2">
                            <Label htmlFor="title">Project Title</Label>
                            <Input
                                id="title"
                                name="title"
                                value={formData.title}
                                onChange={handleChange}
                                placeholder="Enter project title"
                                className={cn(errors.title && "border-red-500")}
                            />
                            {errors.title && (
                                <p className="text-sm text-red-500">{errors.title}</p>
                            )}
                        </div>

                        {/* Description Field */}
                        <div className="space-y-2">
                            <Label htmlFor="description">Project Description</Label>
                            <Textarea
                                id="description"
                                name="description"
                                value={formData.description}
                                onChange={handleChange}
                                placeholder="Enter project description"
                                className={cn(errors.description && "border-red-500")}
                                rows={4}
                            />
                            {errors.description && (
                                <p className="text-sm text-red-500">{errors.description}</p>
                            )}
                        </div>

                        {/* Scope Field */}
                        <div className="space-y-4">
                            <Label>Scope Details</Label>

                            {/* General Requirements */}
                            <div className="space-y-2">
                                <Label>General Requirements</Label>
                                <Textarea
                                    value={formData.scope.general_requirements}
                                    onChange={(e) => handleScopeChange('general_requirements', e.target.value)}
                                    placeholder="Enter general requirements"
                                    rows={6}
                                />
                            </div>

                            {/* Specific Requirements */}
                            <div className="space-y-2">
                                <Label>Specific Requirements</Label>
                                <div className="space-y-2">
                                    {formData.scope.specific_requirements.map((req, index) => (
                                        <div key={index} className="flex gap-2">
                                            <Input
                                                value={req}
                                                onChange={(e) => handleUpdateRequirement(index, e.target.value)}
                                            />
                                            <Button
                                                type="button"
                                                variant="ghost"
                                                onClick={() => handleRemoveRequirement(index)}
                                            >
                                                <X className="h-4 w-4" />
                                            </Button>
                                        </div>
                                    ))}
                                    <Button
                                        className={primaryButtonClasses}
                                        type="button"
                                        variant="outline"
                                        onClick={handleAddRequirement}
                                    >
                                        Add Requirement
                                    </Button>
                                </div>
                            </div>

                            {/* Exclusions */}
                            <div className="space-y-2">
                                <Label>Exclusions</Label>
                                <div className="space-y-2">
                                    {formData.scope.exclusions.map((exclusion, index) => (
                                        <div key={index} className="flex gap-2">
                                            <Input
                                                value={exclusion}
                                                onChange={(e) => {
                                                    const newExclusions = [...formData.scope.exclusions];
                                                    newExclusions[index] = e.target.value;
                                                    handleScopeChange('exclusions', newExclusions);
                                                }}
                                            />
                                            <Button
                                                type="button"
                                                variant="ghost"
                                                onClick={() => {
                                                    const newExclusions = formData.scope.exclusions.filter((_, i) => i !== index);
                                                    handleScopeChange('exclusions', newExclusions);
                                                }}
                                            >
                                                <X className="h-4 w-4" />
                                            </Button>
                                        </div>
                                    ))}
                                    <Button
                                        className={primaryButtonClasses}
                                        type="button"
                                        variant="outline"
                                        onClick={() => {
                                            const newExclusions = [...formData.scope.exclusions, ''];
                                            handleScopeChange('exclusions', newExclusions);
                                        }}
                                    >
                                        Add Exclusion
                                    </Button>
                                </div>
                            </div>

                            {/* Deliverables */}
                            <div className="space-y-2">
                                <Label>Deliverables</Label>
                                <div className="space-y-2">
                                    {formData.scope.deliverables.map((deliverable, index) => (
                                        <div key={index} className="flex gap-2">
                                            <Input
                                                value={deliverable}
                                                onChange={(e) => {
                                                    const newDeliverables = [...formData.scope.deliverables];
                                                    newDeliverables[index] = e.target.value;
                                                    handleScopeChange('deliverables', newDeliverables);
                                                }}
                                            />
                                            <Button
                                                type="button"
                                                variant="ghost"
                                                onClick={() => {
                                                    const newDeliverables = formData.scope.deliverables.filter((_, i) => i !== index);
                                                    handleScopeChange('deliverables', newDeliverables);
                                                }}
                                            >
                                                <X className="h-4 w-4" />
                                            </Button>
                                        </div>
                                    ))}
                                    <Button
                                        className={primaryButtonClasses}
                                        type="button"
                                        variant="outline"
                                        onClick={() => {
                                            const newDeliverables = [...formData.scope.deliverables, ''];
                                            handleScopeChange('deliverables', newDeliverables);
                                        }}
                                    >
                                        Add Deliverable
                                    </Button>
                                </div>
                            </div>

                            {/* Required Trades */}
                            <div className="space-y-4">
                                <Label>Required Trades</Label>
                                <TradeSelector
                                    trades={trades}
                                    formData={formData}
                                    onTradeSelect={handleTradeSelect}
                                    isLoading={isLoading}
                                    loadTrades={loadTrades}
                                />

                                {/* Trade Details */}
                                {formData.required_trades.map((tradeId) => {
                                    const trade = trades.find(t => t.id === tradeId);
                                    if (!trade) return null;

                                    return (
                                        <div key={tradeId} className="mt-4 border rounded-lg p-4">
                                            <div className="flex items-center justify-between mb-4">
                                                <h3 className="text-lg font-medium">{trade.name}</h3>
                                                <Button
                                                    type="button"
                                                    variant="ghost"
                                                    size="sm"
                                                    onClick={() => handleTradeSelect(trade)}
                                                >
                                                    <X className="h-4 w-4" />
                                                </Button>
                                            </div>

                                            <TradeCostEstimates
                                                trade={trade}
                                                estimate={formData.trade_estimates[tradeId]}
                                                onChange={(estimate) => {
                                                    setFormData(prev => ({
                                                        ...prev,
                                                        trade_estimates: {
                                                            ...prev.trade_estimates,
                                                            [tradeId]: estimate
                                                        }
                                                    }));
                                                }}
                                                error={errors.trade_estimates?.[tradeId]}
                                            />

                                            <div className="mt-4">
                                                <BidderProfileSelector
                                                    trade={trade}
                                                    onSelect={(profile) => handleProfileSelect(tradeId, profile)}
                                                    selectedProfiles={selectedProfiles[tradeId] || []}
                                                />
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>

                            {/* Submission Deadline */}
                            <div className="space-y-2">
                                <Label>Submission Deadline</Label>
                                <Popover>
                                    <PopoverTrigger asChild>
                                        <Button
                                            type="button"
                                            variant="outline"
                                            className={`w-full justify-start text-left font-normal ${!date && "text-muted-foreground"}`}
                                        >
                                            <CalendarIcon className="mr-2 h-4 w-4" />
                                            {date ? format(date, "PPP") : "Select deadline"}
                                        </Button>
                                    </PopoverTrigger>
                                    <PopoverContent className="w-auto p-0">
                                        <Calendar
                                            mode="single"
                                            selected={date}
                                            onSelect={setDate}
                                            initialFocus
                                        />
                                    </PopoverContent>
                                </Popover>
                                {errors.date && (
                                    <p className="text-sm text-red-500">{errors.date}</p>
                                )}
                            </div>

                            {/* Document Upload */}
                            <div className="space-y-2">
                                {isEditMode ? (
                                    // For edit mode, use BidDocumentsTab
                                    <BidDocumentsTab
                                        isEditing={true}
                                        loadingDocuments={isLoading}
                                        uploading={isSubmitting}
                                        bidId={existingBidId}
                                        documentTypes={documentTypes?.results ? documentTypes : { results: [] }}
                                    />
                                ) : (
                                    // For create mode, use BidDocumentUpload
                                    <div className="border-2 border-dashed rounded-lg p-6 text-center">
                                        <BidDocumentUpload
                                            documents={documents}
                                            setDocuments={setDocuments}
                                            documentTypes={documentTypes}
                                            ownerType="request"
                                            bidRequestId={bidId}
                                        />
                                    </div>
                                )}
                            </div>



                            {/* Notify Contractors */}
                            <div className="flex items-center space-x-2">
                                <Checkbox
                                    id="notify"
                                    checked={formData.notify_subcontractors}
                                    onCheckedChange={(checked) => {
                                        setFormData(prev => ({
                                            ...prev,
                                            notify_subcontractors: checked
                                        }));
                                    }}
                                />
                                <Label htmlFor="notify">
                                    Notify selected contractors when published
                                </Label>
                            </div>

                            {/* Submit Buttons */}
                            <div className="flex justify-end gap-4">
                                <Button
                                    className={secondaryButtonClasses}
                                    variant="outline"
                                    type="button"
                                >
                                    Save as Draft
                                </Button>
                                <Button
                                    className={primaryButtonClasses}
                                    type="submit"
                                    disabled={isSubmitting}
                                >
                                    {isSubmitting ? (
                                        <>
                                            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                            {isEditMode ? 'Saving Changes...' : 'Creating Bid...'}
                                        </>
                                    ) : isEditMode ? 'Save Changes' : 'Publish Bid Request'}
                                </Button>
                            </div>
                        </div>
                    </form>
                </CardContent>
            </Card >
        </div >
    );
}

export default BidSolicitationForm;