import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "../../components/ui/cardui";
import SupplierCategories from './SupplierCategories';

const SupplierCategoriesPage = () => {
    return (
        <div className="container mx-auto py-6 px-4">
            <h1 className="text-2xl font-bold mb-6">Supplier Categories</h1>
            <Card>
                <CardContent className="p-6">
                    <SupplierCategories />
                </CardContent>
            </Card>
        </div>
    );
};

export default SupplierCategoriesPage;