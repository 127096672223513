import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "../../components/ui/cardui";
import { Button } from "../../components/ui/button";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "../../components/ui/dialog";
import { Input } from "../../components/ui/input";
import { Label } from "../../components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../components/ui/select";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../components/ui/tabs";
import { Alert, AlertDescription } from "../../components/ui/alert";
import { Badge } from "../../components/ui/badge";
import { Plus, Edit, Trash2, Phone, Mail, Building, Search, Star, Shield, ArrowUpDown } from "lucide-react";
import { toast } from "react-toastify";
import api from '../../services/api';
import { primaryButtonClasses } from '../common/ButtonStyles';
import SupplierForm from './SupplierForm';
import SupplierDetail from '../Supplier/SupplierDetails';
import secureLocalStorage from "react-secure-storage";

const SupplierManagement = () => {
    const [suppliers, setSuppliers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [selectedSupplier, setSelectedSupplier] = useState(null);
    const [isDetailOpen, setIsDetailOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [statusFilter, setStatusFilter] = useState('all');
    const [sortBy, setSortBy] = useState('company_name');
    const [sortDirection, setSortDirection] = useState('asc');

    useEffect(() => {
        fetchSuppliers();
    }, [statusFilter, sortBy, sortDirection]);


    const getTenantId = () => {
        let tenantId =
            localStorage.getItem('tenantIdentifier') ||
            (typeof secureLocalStorage !== 'undefined' && secureLocalStorage.getItem('currentTenantId')) ||
            localStorage.getItem('tenantId') ||
            sessionStorage.getItem('tenantId') ||
            sessionStorage.getItem('tenantIdentifier');

        if (!tenantId) {
            try {
                // Try regular localStorage userData
                const userData = JSON.parse(localStorage.getItem('userData') || '{}');
                tenantId = userData.tenant?.id || userData.tenantId;

                // If found, save to localStorage for future use
                if (tenantId) {
                    localStorage.setItem('tenantId', tenantId);
                    console.log("Retrieved tenant ID from user data and saved to localStorage");
                }
            } catch (e) {
                console.error("Error parsing user data:", e);
            }
        }

        return tenantId;
    };

    const handleCreateSupplier = async (data) => {
        try {
            // Get the tenant ID
            const tenantId = getTenantId();
            if (!tenantId) {
                toast.error("Tenant ID not found. Cannot create supplier.");
                return;
            }

            // Include tenant ID in the supplier data
            const supplierData = {
                ...data,
                tenant: tenantId
            };

            // Create supplier with tenant ID included
            await api.post('suppliers/', supplierData);
            toast.success("Supplier created successfully");
            setIsFormOpen(false);
            fetchSuppliers();
        } catch (error) {
            console.error("Error creating supplier:", error.response?.data || error);
            toast.error(error.response?.data?.detail || "Failed to create supplier");
        }
    };

    const handleUpdateSupplier = async (data) => {
        try {
            // Get the tenant ID
            const tenantId = getTenantId();
            if (!tenantId) {
                toast.error("Tenant ID not found. Cannot update supplier.");
                return;
            }

            // Include tenant ID in the supplier data
            const supplierData = {
                ...data,
                tenant: tenantId
            };

            await api.put(`suppliers/${selectedSupplier.id}/`, supplierData);
            toast.success("Supplier updated successfully");
            setIsFormOpen(false);
            fetchSuppliers();
        } catch (error) {
            console.error("Error updating supplier:", error.response?.data || error);
            toast.error(error.response?.data?.detail || "Failed to update supplier");
        }
    };

    const fetchSuppliers = async () => {
        setLoading(true);
        try {
            const params = {};
            if (statusFilter !== 'all') {
                params.status = statusFilter;
            }
            if (searchTerm) {
                params.search = searchTerm;
            }

            const response = await api.get('suppliers/', { params });
            console.log("Suppliers:", response.data);

            // Sort data if needed
            let sortedData = [...(response.data.results || [])];
            sortedData.sort((a, b) => {
                if (a[sortBy] < b[sortBy]) return sortDirection === 'asc' ? -1 : 1;
                if (a[sortBy] > b[sortBy]) return sortDirection === 'asc' ? 1 : -1;
                return 0;
            });

            setSuppliers(sortedData);
        } catch (error) {
            console.error("Failed to load suppliers:", error);
            toast.error("Failed to load suppliers");
        } finally {
            setLoading(false);
        }
    };

    const handleSearch = (e) => {
        e.preventDefault();
        fetchSuppliers();
    };

    const handleSort = (field) => {
        if (sortBy === field) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortBy(field);
            setSortDirection('asc');
        }
    };



    const handleDeleteSupplier = async (supplierId) => {
        if (window.confirm("Are you sure you want to delete this supplier?")) {
            try {
                await api.delete(`suppliers/${supplierId}/`);
                toast.success("Supplier deleted successfully");
                fetchSuppliers();
            } catch (error) {
                console.error("Error deleting supplier:", error.response?.data || error);
                toast.error(error.response?.data?.detail || "Failed to delete supplier");
            }
        }
    };

    const openEditForm = (supplier) => {
        setSelectedSupplier(supplier);
        setIsFormOpen(true);
    };

    const openDetailView = (supplier) => {
        setSelectedSupplier(supplier);
        setIsDetailOpen(true);
    };

    const getStatusBadge = (status) => {
        const statusMap = {
            'active': { variant: 'success', label: 'Active' },
            'inactive': { variant: 'secondary', label: 'Inactive' },
            'pending': { variant: 'warning', label: 'Pending' },
            'suspended': { variant: 'destructive', label: 'Suspended' }
        };

        const { variant, label } = statusMap[status] || { variant: 'default', label: status };

        return <Badge variant={variant}>{label}</Badge>;
    };

    const getRatingBadge = (rating) => {
        if (!rating) return null;

        let variant = 'default';
        if (rating >= 4.5) variant = 'success';
        else if (rating >= 3.5) variant = 'default';
        else if (rating >= 2.5) variant = 'warning';
        else variant = 'destructive';

        return (
            <Badge variant={variant} className="flex items-center gap-1">
                <Star className="h-3 w-3" />
                {rating.toFixed(1)}
            </Badge>
        );
    };

    return (
        <div className="space-y-6">
            <div className="flex justify-between items-center">
                <div>
                    <h2 className="text-2xl font-bold">Supplier Management</h2>
                    <p className="text-gray-500">Manage your material and service suppliers</p>
                </div>
                <Button className={primaryButtonClasses} onClick={() => {
                    setSelectedSupplier(null);
                    setIsFormOpen(true);
                }}>
                    <Plus className="h-4 w-4 mr-2" />
                    Add Supplier
                </Button>
            </div>

            <div className="flex flex-col md:flex-row justify-between gap-4">
                <form onSubmit={handleSearch} className="flex gap-2">
                    <div className="relative flex-1">
                        <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
                        <Input
                            placeholder="Search suppliers..."
                            className="pl-8"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </div>
                    <Button type="submit">Search</Button>
                </form>

                <div className="flex gap-4">
                    <Select
                        value={statusFilter}
                        onValueChange={setStatusFilter}
                    >
                        <SelectTrigger className="w-[180px]">
                            <SelectValue placeholder="Filter by status" />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectItem value="all">All Statuses</SelectItem>
                            <SelectItem value="active">Active</SelectItem>
                            <SelectItem value="inactive">Inactive</SelectItem>
                            <SelectItem value="pending">Pending</SelectItem>
                            <SelectItem value="suspended">Suspended</SelectItem>
                        </SelectContent>
                    </Select>
                </div>
            </div>

            {loading ? (
                <div className="text-center py-10">Loading suppliers...</div>
            ) : suppliers.length === 0 ? (
                <Alert>
                    <AlertDescription>
                        No suppliers found. Add your first supplier to get started.
                    </AlertDescription>
                </Alert>
            ) : (
                <div className="overflow-x-auto">
                    <table className="w-full border-collapse">
                        <thead>
                            <tr className="bg-gray-100 dark:bg-gray-800">
                                <th className="px-4 py-2 text-left cursor-pointer" onClick={() => handleSort('company_name')}>
                                    <div className="flex items-center">
                                        Company Name
                                        {sortBy === 'company_name' && (
                                            <ArrowUpDown className={`ml-2 h-4 w-4 ${sortDirection === 'asc' ? 'transform rotate-180' : ''}`} />
                                        )}
                                    </div>
                                </th>
                                <th className="px-4 py-2 text-left">Contact</th>
                                <th className="px-4 py-2 text-left cursor-pointer" onClick={() => handleSort('rating')}>
                                    <div className="flex items-center">
                                        Rating
                                        {sortBy === 'rating' && (
                                            <ArrowUpDown className={`ml-2 h-4 w-4 ${sortDirection === 'asc' ? 'transform rotate-180' : ''}`} />
                                        )}
                                    </div>
                                </th>
                                <th className="px-4 py-2 text-left cursor-pointer" onClick={() => handleSort('status')}>
                                    <div className="flex items-center">
                                        Status
                                        {sortBy === 'status' && (
                                            <ArrowUpDown className={`ml-2 h-4 w-4 ${sortDirection === 'asc' ? 'transform rotate-180' : ''}`} />
                                        )}
                                    </div>
                                </th>
                                <th className="px-4 py-2 text-right">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {suppliers.map((supplier) => (
                                <tr
                                    key={supplier.id}
                                    className="border-b border-gray-200 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-800 cursor-pointer"
                                    onClick={() => openDetailView(supplier)}
                                >
                                    <td className="px-4 py-3">
                                        <div className="font-medium">{supplier.company_name}</div>
                                        <div className="text-sm text-gray-500">{supplier.supplier_code}</div>
                                    </td>
                                    <td className="px-4 py-3">
                                        <div className="flex flex-col">
                                            <div className="flex items-center text-sm">
                                                <Mail className="h-4 w-4 mr-2 text-gray-500" />
                                                {supplier.email}
                                            </div>
                                            <div className="flex items-center text-sm mt-1">
                                                <Phone className="h-4 w-4 mr-2 text-gray-500" />
                                                {supplier.phone}
                                            </div>
                                        </div>
                                    </td>
                                    <td className="px-4 py-3">
                                        {getRatingBadge(supplier.rating)}
                                    </td>
                                    <td className="px-4 py-3">
                                        {getStatusBadge(supplier.status)}
                                    </td>
                                    <td className="px-4 py-3 text-right">
                                        <div className="flex justify-end space-x-2" onClick={(e) => e.stopPropagation()}>
                                            <Button
                                                variant="outline"
                                                size="sm"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    openEditForm(supplier);
                                                }}
                                            >
                                                <Edit className="h-4 w-4" />
                                            </Button>
                                            <Button
                                                variant="destructive"
                                                size="sm"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleDeleteSupplier(supplier.id);
                                                }}
                                            >
                                                <Trash2 className="h-4 w-4" />
                                            </Button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}

            {isFormOpen && (
                <SupplierForm
                    isOpen={isFormOpen}
                    onClose={() => {
                        setIsFormOpen(false);
                        setSelectedSupplier(null);
                    }}
                    onSubmit={selectedSupplier ? handleUpdateSupplier : handleCreateSupplier}
                    supplier={selectedSupplier}
                />
            )}

            {isDetailOpen && selectedSupplier && (
                <SupplierDetail
                    isOpen={isDetailOpen}
                    onClose={() => {
                        setIsDetailOpen(false);
                        setSelectedSupplier(null);
                    }}
                    supplier={selectedSupplier}
                    onEdit={() => {
                        setIsDetailOpen(false);
                        setIsFormOpen(true);
                    }}
                    onDelete={() => {
                        handleDeleteSupplier(selectedSupplier.id);
                        setIsDetailOpen(false);
                    }}
                />
            )}
        </div>
    );
};

export default SupplierManagement;