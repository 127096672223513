import React, { useState, useEffect, useCallback } from 'react';
import api from '../../services/api';
import {
  BriefcaseIcon,
  EnvelopeIcon,
  BellIcon,
  MagnifyingGlassIcon,
  Bars3Icon,
  PaperClipIcon,
  ExclamationTriangleIcon,
  CheckCircleIcon,
  SparklesIcon,
  XMarkIcon,
  ChartBarIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  UserGroupIcon,
  PhoneIcon,
  AdjustmentsHorizontalIcon,
  ClockIcon,
  UsersIcon,
  CalendarIcon
} from '@heroicons/react/24/outline';
import { Button } from "../../components/ui/button";

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";
import { toast } from 'react-toastify';
import CommunicationHeader from '../Communication/CommunicationHeader';
import CommunicationItem from '../Communication/CommunicationItem';
import CommunicationTaskTracker from '../Communication/CommunicationTaskTracker';
import CommunicationSidebar from '../Communication/CommunicationSidebar';
import CommunicationSearchPanel from '../Communication/CommunicationSearchPanel';
import TaskScheduleSelector from '../Communication/TaskScheduleSelector';
import RelatedTasksView from '../Communication/RelatedTasksView';
import ActivityForm from './ActivityForm';
import ProjectSidebar from './ProjectSidebar';
import NewCommunicationForm from '../Communication/NewCommunicationForm';
import { primaryButtonClasses } from '../common/ButtonStyles';
import { useParams } from 'react-router-dom';

const CommunicationHub = () => {
  const [projects, setProjects] = useState([]);
  const [activeProject, setActiveProject] = useState(null);
  const [activeProjectName, setActiveProjectName] = useState('');
  const [communications, setCommunications] = useState([]);
  const [showAIFeatures, setShowAIFeatures] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isUserRolesOpen, setIsUserRolesOpen] = useState(false);
  const [unreadNotifications, setUnreadNotifications] = useState(3);
  const [tasks, setTasks] = useState([]);
  const [filter, setFilter] = useState('all');
  const [isNewCommFormOpen, setIsNewCommFormOpen] = useState(false);
  const { projectId, communicationId } = useParams();

  const [isTaskScheduleOpen, setIsTaskScheduleOpen] = useState(false);
  const [selectedCommunication, setSelectedCommunication] = useState(null);
  const [schedules, setSchedules] = useState([]);
  const [activities, setActivities] = useState([]);
  const [activeSchedule, setActiveSchedule] = useState(null);
  const [loadingSchedules, setLoadingSchedules] = useState(false);
  const [isActivityFormOpen, setIsActivityFormOpen] = useState(false);
  const [isRelatedTasksOpen, setIsRelatedTasksOpen] = useState(false);
  const [relatedTasks, setRelatedTasks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [activeWbsId, setActiveWbsId] = useState(null);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await api.get('projects/', {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          }
        });

        const projectsData = response.data.results || [];
        setProjects(projectsData);

        if (projectsData.length > 0) {
          setActiveProject(projectsData[0]);
        }
        console.log("Active Project Tenant: ", activeProject);

        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching projects:', error);
        setIsLoading(false);
      }
    };

    fetchProjects();
  }, []);


  /* useEffect(() => {
    if (activeProject) {
      fetchCommunications(activeProject.id);
    }
  }, [activeProject, page]); */

  useEffect(() => {
    if (communicationId) {
      // Fetch specific communication
      fetchCommunicationDetails(communicationId);
    } else if (activeProject) {
      // Fetch all communications for project
      fetchCommunications(activeProject.id);
    }
  }, [activeProject, communicationId, page]);


  const fetchCommunications = async (projectId) => {
    setIsLoading(true);
    try {
      const response = await api.get(`projects/${projectId}/communications/?page=1&limit=2`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        }
      });
      setHasNextPage(response.data.has_next || false);

      const commsWithTasks = (response.data.results || []).map((comm, index) => {
        if (index % 3 === 0) {
          return {
            ...comm,
            linkedTasks: [
              { id: `task-${comm.id}-1`, status: 'In Progress' }
            ]
          };
        }
        return comm;
      });

      setCommunications(response.data.results || []);
    } catch (error) {
      console.error('Error fetching communications:', error);
      setCommunications([]);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCommunicationDetails = async (commId) => {
    setIsLoading(true);
    try {
      const response = await api.get(`communications/${commId}/`);
      // Set this as the only communication in the list
      setCommunications([response.data]);
    } catch (error) {
      console.error('Error fetching communication details:', error);
      setCommunications([]);
    } finally {
      setIsLoading(false);
    }
  };


  const fetchProjectSchedules = async (projectId) => {
    setLoadingSchedules(true);
    try {

      const schedulesResponse = await api.get(`projects/${projectId}/schedules/`);

      const projectSchedules = Array.isArray(schedulesResponse.data)
        ? schedulesResponse.data
        : (schedulesResponse.data.results || []);

      setSchedules(projectSchedules);


      const active = projectSchedules.find(s => s.is_active);
      setActiveSchedule(active || null);

      if (active) {

        try {
          const wbsResponse = await api.get(`wbs/?project=${projectId}`);
          if (wbsResponse.data && wbsResponse.data.length > 0) {
            setActiveWbsId(wbsResponse.data[0].id);
          }
        } catch (wbsError) {
          console.error('Error fetching WBS:', wbsError);
        }

        const activitiesResponse = await api.get(`projects/${projectId}/activities/`);
        setActivities(activitiesResponse.data || []);
      }
    } catch (error) {
      console.error('Error fetching project schedules:', error);
      toast.error('Failed to load project schedules');
      setSchedules([]);
      setActivities([]);
      setActiveSchedule(null);
      setActiveWbsId(null);
    } finally {
      setLoadingSchedules(false);
    }
  };


  const handleLinkToTask = async (activityId) => {
    if (!selectedCommunication || !activityId) {
      return;
    }

    try {

      setCommunications(prev =>
        prev.map(comm =>
          comm.id === selectedCommunication.id
            ? {
              ...comm,
              linkedTasks: [...(comm.linkedTasks || []), { id: activityId, status: 'Linked' }]
            }
            : comm
        )
      );

      toast.success('Communication linked to task successfully');
      setIsTaskScheduleOpen(false);
    } catch (error) {
      console.error('Error linking communication to task:', error);
      toast.error('Failed to link communication to task');
    }
  };


  const handleCreateNewActivity = async (createScheduleFirst = false) => {
    if (!selectedCommunication) {
      return;
    }

    try {

      if (createScheduleFirst) {
        const scheduleData = {
          project: activeProject.id,
          type: 'current',
          version: 1,
          start_date: new Date().toISOString().split('T')[0],
          end_date: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString().split('T')[0],
          is_active: true,
          tenant: activeProject.tenant
        };

        const scheduleResponse = await api.post(
          `projects/${activeProject.id}/schedules/`,
          scheduleData
        );
        setActiveSchedule(scheduleResponse.data);
        setSchedules(prev => [...prev, scheduleResponse.data]);
      }


      setIsTaskScheduleOpen(false);
      setIsActivityFormOpen(true);
    } catch (error) {
      console.error('Error preparing to create activity:', error);
      toast.error('Failed to prepare for creating activity');
    }
  };


  const handleActivitySubmit = async (activityData) => {
    try {
      // Get or create WBS
      let wbsId = activeWbsId;

      if (!wbsId) {
        console.log("No active WBS ID, fetching or creating one");

        try {
          // Try to fetch existing WBS
          const wbsResponse = await api.get(`wbs/?project=${activeProject.id}`);
          const wbsItems = wbsResponse.data.results || [];

          if (wbsItems.length > 0) {
            wbsId = wbsItems[0].id;
            console.log("Found existing WBS:", wbsId);
          } else {
            console.log("No WBS found, creating a new one");

            // Get tenant information from the current user or project
            let tenantId;

            if (activeProject.tenant) {
              tenantId = activeProject.tenant;
            } else {
              const userResponse = await api.get('tenant/users/');
              const currentUser = Array.isArray(userResponse.data) ? userResponse.data[0] : null;
              if (!currentUser?.tenant) {
                throw new Error('Unable to determine tenant information');
              }
              tenantId = currentUser.tenant;
            }

            const newWbsResponse = await api.post(`wbs/`, {
              name: 'Default WBS',
              project: activeProject.id,
              code: '1',
              description: 'Default WBS for project activities',
              level: 0,
              order: 0,
              tenant: tenantId
            });

            wbsId = newWbsResponse.data.id;
            console.log("Created new WBS with ID:", wbsId);
            setActiveWbsId(wbsId);
          }
        } catch (wbsError) {
          console.error('Error handling WBS:', wbsError);
          console.error('Error details:', wbsError.response?.data);
          toast.error('Failed to handle WBS for activity creation');
          throw wbsError;
        }
      }

      console.log("Preparing to create activity with WBS ID:", wbsId);

      // Complete the activity data with WBS and communication reference
      const completeActivityData = {
        ...activityData,
        wbs: wbsId,
        communication_id: selectedCommunication.id
      };

      console.log("Sending activity data:", completeActivityData);

      // Create the activity
      const response = await api.post(
        `projects/${activeProject.id}/activities/`,
        completeActivityData
      );

      console.log("Activity created successfully:", response.data);

      // Link the communication to this new activity
      await handleLinkToTask(response.data.id);

      // Refresh activities
      const activitiesResponse = await api.get(`projects/${activeProject.id}/activities/`);
      setActivities(activitiesResponse.data || []);

      setIsActivityFormOpen(false);
      toast.success('New activity created and linked');
    } catch (error) {
      console.error('Error creating activity:', error);
      console.error('Error details:', error.response?.data);
      toast.error('Failed to create activity');
    }
  };


  const handleCreateTask = (communication) => {
    setSelectedCommunication(communication);
    fetchProjectSchedules(activeProject.id);
    setIsTaskScheduleOpen(true);
  };


  const handleViewRelatedTasks = async (communication) => {
    setSelectedCommunication(communication);
    setIsLoading(true);

    try {

      const response = await api.get(`projects/${activeProject.id}/activities/`);

      const related = response.data.filter(activity =>
        activity.related_communication_id === communication.id
      );

      setRelatedTasks(related || []);
    } catch (error) {
      console.error('Error fetching related tasks:', error);
      toast.error('Failed to load related tasks');
      setRelatedTasks([]);
    } finally {
      setIsLoading(false);
      setIsRelatedTasksOpen(true);
    }
  };

  const filteredCommunications = communications.filter(comm => {
    if (filter === 'all') return true;
    if (filter === 'unread') return comm.isUnread;
    if (filter === 'with-attachments') return comm.attachments && comm.attachments.length > 0;
    if (filter === 'with-tasks') return comm.linkedTasks && comm.linkedTasks.length > 0;
    return true;
  });

  return (
    <div className="flex flex-col h-screen">
      {/* Project Sidebar (overlay) */}
      <ProjectSidebar
        projects={projects}
        activeProject={activeProject}
        setActiveProject={setActiveProject}
        isOpen={isSidebarOpen}
        setIsOpen={setIsSidebarOpen}
      />


      <div className="flex-1 flex flex-col">

        <CommunicationHeader
          activeProject={activeProject}
          setIsSidebarOpen={setIsSidebarOpen}
          unreadNotifications={unreadNotifications}
        />

        {!activeProject ? (
          <div className="flex-1 flex items-center justify-center bg-gray-50">
            <div className="text-center text-gray-500">
              <h2 className="text-2xl font-bold mb-2">Select a project to view communications</h2>
              <p>Choose a project from the sidebar to get started</p>
              <Button
                onClick={() => setIsSidebarOpen(true)}
                className="mt-4 bg-blue-600 hover:bg-blue-700 text-white"
              >
                Select Project
              </Button>
            </div>
          </div>
        ) : (
          <main className="flex-1 p-6 overflow-auto bg-gray-50">
            <div className="max-w-4xl mx-auto">
              {/* Communications */}
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-bold">Communications</h2>
                <div className="flex items-center">
                  <div className="mr-2">
                    <select
                      className="p-2 border rounded text-sm"
                      value={filter}
                      onChange={(e) => setFilter(e.target.value)}
                    >
                      <option value="all">All</option>
                      <option value="unread">Unread</option>
                      <option value="with-attachments">With Attachments</option>
                      <option value="with-tasks">With Tasks</option>
                    </select>
                  </div>
                  <Button
                    className={primaryButtonClasses}
                    onClick={() => setIsNewCommFormOpen(true)}
                  >
                    New Message
                  </Button>
                  <Button
                    className="bg-gray-200 text-gray-800 relative group"
                    variant="outline"
                  >
                    <SparklesIcon className="h-5 w-5" />
                    <span className="absolute -top-2 -right-2 bg-yellow-500 text-white text-xs rounded px-1 py-0.5">Soon</span>
                  </Button>
                </div>
              </div>

              <div className="space-y-4">
                {isLoading ? (
                  <div className="flex justify-center items-center h-64">
                    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
                  </div>
                ) : filteredCommunications.length > 0 ? (
                  filteredCommunications.map(comm => (
                    <CommunicationItem
                      key={comm.id}
                      comm={comm}
                      onCreateTask={handleCreateTask}
                      onViewRelatedTasks={handleViewRelatedTasks}
                    />
                  ))
                ) : (
                  <div className="text-center py-10 bg-white rounded-lg shadow">
                    <p className="text-gray-500">No communications available</p>
                    <Button
                      className="mt-4 bg-[#4452FE] text-white"
                      onClick={() => setIsNewCommFormOpen(true)}
                    >
                      Start New Conversation
                    </Button>
                  </div>
                )}
              </div>
            </div>
            <div className="flex justify-center mt-4">
              <Button
                onClick={() => setPage(prev => Math.max(prev - 1, 1))}
                disabled={page === 1}
              >
                Previous
              </Button>
              <span className="mx-4">Page {page}</span>
              <Button
                onClick={() => setPage(prev => prev + 1)}
                disabled={!hasNextPage}
              >
                Next
              </Button>
            </div>
          </main>

        )}
      </div>

      {/* Modals and Dialogs */}
      <CommunicationSearchPanel
        isOpen={isSearchOpen}
        setIsOpen={setIsSearchOpen}
        activeProject={activeProject}
      />
      <NewCommunicationForm
        isOpen={isNewCommFormOpen}
        setIsOpen={setIsNewCommFormOpen}
        activeProject={activeProject}
      />

      <TaskScheduleSelector
        isOpen={isTaskScheduleOpen}
        onClose={() => setIsTaskScheduleOpen(false)}
        onLinkToTask={handleLinkToTask}
        onCreateNewActivity={handleCreateNewActivity}
        communication={selectedCommunication}
        projectId={activeProject?.id}
        loadingSchedules={loadingSchedules}
        schedules={schedules}
        activities={activities}
        activeSchedule={activeSchedule}
      />

      <ActivityForm
        isOpen={isActivityFormOpen}
        onClose={() => setIsActivityFormOpen(false)}
        onSubmit={handleActivitySubmit}
        selectedCommunication={selectedCommunication}
        projectId={activeProject?.id}
        scheduleId={activeSchedule?.id}
        wbsId={activeWbsId}
        mode="create"
      />

      <RelatedTasksView
        isOpen={isRelatedTasksOpen}
        onClose={() => setIsRelatedTasksOpen(false)}
        communication={selectedCommunication}
        relatedTasks={relatedTasks}
      />
    </div>
  );
};

export default CommunicationHub;