import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogDescription,
    DialogFooter
} from "../../components/ui/dialog";
import { Input } from "../../components/ui/input";
import { Label } from "../../components/ui/label";
import { Button } from "../../components/ui/button";
import {
    CalendarIcon
} from '@heroicons/react/24/outline';
import { primaryButtonClasses } from '../common/ButtonStyles';

const TaskScheduleSelector = ({
    isOpen,
    onClose,
    onLinkToTask,
    onCreateNewActivity,
    communication,
    projectId,
    loadingSchedules,
    schedules,
    activities,
    activeSchedule
}) => {
    const [selectedActivity, setSelectedActivity] = useState(null);
    const [selectedActionType, setSelectedActionType] = useState('link');
    const [filteredActivities, setFilteredActivities] = useState([]);
    const [activityFilter, setActivityFilter] = useState('');


    useEffect(() => {
        if (activities && activities.length > 0) {
            const filtered = activities.filter(activity =>
                activity.name.toLowerCase().includes(activityFilter.toLowerCase())
            );
            setFilteredActivities(filtered);
        } else {
            setFilteredActivities([]);
        }
    }, [activities, activityFilter]);

    const handleSubmit = () => {
        if (selectedActionType === 'link' && selectedActivity) {
            onLinkToTask(selectedActivity.id);
        } else if (selectedActionType === 'create') {
            onCreateNewActivity();
        }
    };

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className="sm:max-w-md">
                <DialogHeader>
                    <DialogTitle>Link to Project Schedule</DialogTitle>
                    <DialogDescription>
                        Link this communication to an existing activity or create a new one.
                    </DialogDescription>
                </DialogHeader>

                {loadingSchedules ? (
                    <div className="py-6 text-center">Loading project schedules...</div>
                ) : !activeSchedule ? (
                    <div className="py-6">
                        <p className="text-center mb-4">No active schedule found for this project.</p>
                        <div className="flex justify-center">
                            <Button onClick={() => onCreateNewActivity(true)} className={primaryButtonClasses}>
                                Create New Schedule & Activity
                            </Button>
                        </div>
                    </div>
                ) : (
                    <div className="space-y-4 py-4">
                        <div className="flex p-2 bg-blue-50 rounded-md items-center">
                            <CalendarIcon className="h-5 w-5 text-blue-500 mr-2" />
                            <div>
                                <p className="font-medium">Active Schedule: {activeSchedule.type} v{activeSchedule.version}</p>
                                <p className="text-sm text-gray-600">
                                    {new Date(activeSchedule.start_date).toLocaleDateString()} to {new Date(activeSchedule.end_date).toLocaleDateString()}
                                </p>
                            </div>
                        </div>

                        <div className="flex space-x-2 border-b pb-4">
                            <button
                                className={`flex-1 py-2 px-4 rounded-md ${selectedActionType === 'link' ? 'bg-blue-100 text-blue-700' : 'bg-gray-100'}`}
                                onClick={() => setSelectedActionType('link')}
                            >
                                Link to Existing Activity
                            </button>
                            <button
                                className={`flex-1 py-2 px-4 rounded-md ${selectedActionType === 'create' ? 'bg-blue-100 text-blue-700' : 'bg-gray-100'}`}
                                onClick={() => setSelectedActionType('create')}
                            >
                                Create New Activity
                            </button>
                        </div>

                        {selectedActionType === 'link' && (
                            <>
                                <div>
                                    <Label htmlFor="activity-search">Search Activities</Label>
                                    <Input
                                        id="activity-search"
                                        placeholder="Search by activity name..."
                                        value={activityFilter}
                                        onChange={(e) => setActivityFilter(e.target.value)}
                                    />
                                </div>

                                <div className="max-h-60 overflow-y-auto border rounded-md">
                                    {filteredActivities.length > 0 ? (
                                        filteredActivities.map(activity => (
                                            <div
                                                key={activity.id}
                                                className={`p-3 border-b cursor-pointer hover:bg-gray-50 ${selectedActivity?.id === activity.id ? 'bg-blue-50' : ''}`}
                                                onClick={() => setSelectedActivity(activity)}
                                            >
                                                <p className="font-medium">{activity.name}</p>
                                                <div className="flex justify-between text-sm text-gray-600">
                                                    <span>{activity.activity_type}</span>
                                                    <span>
                                                        {new Date(activity.planned_start).toLocaleDateString()} -
                                                        {new Date(activity.planned_finish).toLocaleDateString()}
                                                    </span>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <p className="p-4 text-center text-gray-500">No activities found</p>
                                    )}
                                </div>
                            </>
                        )}

                        {selectedActionType === 'create' && (
                            <div className="bg-gray-50 p-4 rounded-md">
                                <p className="mb-3">
                                    You'll create a new activity in the schedule based on this communication:
                                </p>
                                <p className="font-medium">"{communication?.subject}"</p>
                            </div>
                        )}
                    </div>
                )}

                <DialogFooter className="sm:justify-between">
                    <Button
                        type="button"
                        variant="outline"
                        onClick={onClose}
                    >
                        Cancel
                    </Button>

                    {activeSchedule && (
                        <Button
                            type="button"
                            className="bg-blue-600 hover:bg-blue-700"
                            onClick={handleSubmit}
                            disabled={(selectedActionType === 'link' && !selectedActivity)}
                        >
                            {selectedActionType === 'link' ? 'Link to Activity' : 'Create New Activity'}
                        </Button>
                    )}
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};

export default TaskScheduleSelector;