import React, { useState, useEffect } from 'react';
import { Button } from '../ui/button';
import { Card, CardContent, CardHeader } from '../ui/cardui';
import { Input } from '../ui/input';
import { useToast } from '../ui/use-toast';
import budgetService from '../../services/budgetService';

export default function CategoryManager({ budgetId, onCategoriesUpdate }) {
    const { toast } = useToast();
    const [categories, setCategories] = useState([]);
    const [newCategoryName, setNewCategoryName] = useState('');
    const [editingCategory, setEditingCategory] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        loadCategories();
    }, []);

    const loadCategories = async () => {
        try {
            const data = await budgetService.getCategories(budgetId);
            setCategories(Array.isArray(data.results) ? data.results : []);
        } catch (error) {
            toast({
                title: 'Error',
                description: 'Failed to load categories',
                variant: 'destructive'
            });
            setCategories([]);
        }
    };

    const handleAddCategory = async () => {

        if (!newCategoryName.trim()) return;

        try {
            setLoading(true);
            const newCategory = await budgetService.createCategory({ name: newCategoryName, budget: budgetId });
            setCategories([...categories, newCategory]);
            setNewCategoryName('');
            onCategoriesUpdate();
            toast({
                title: 'Success',
                description: 'Category added successfully'
            });
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false); // Reset loading to re-enable button
        }
    };


    const handleEditCategory = (category) => {
        setEditingCategory(category);
    };

    const handleSaveEdit = async () => {
        try {
            setLoading(true);
            await budgetService.updateCategory(editingCategory.id, { name: editingCategory.name });
            setEditingCategory(null);
            loadCategories();
            toast({
                title: 'Success',
                description: 'Category updated successfully'
            });
        } catch (error) {
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteCategory = async (categoryId) => {
        if (!window.confirm('Are you sure you want to delete this category? This action cannot be undone.')) return;

        try {
            setLoading(true);
            await budgetService.deleteCategory(categoryId);
            setCategories(categories.filter((cat) => cat.id !== categoryId));
            onCategoriesUpdate();
            toast({
                title: 'Success',
                description: 'Category deleted successfully'
            });
        } catch (error) {
            toast({
                title: 'Error',
                description: 'Failed to delete category',
                variant: 'destructive'
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Card>
            <CardHeader>
                <h2 className="text-2xl font-bold">Manage Categories</h2>
            </CardHeader>
            <CardContent>
                {/* Add Category Input */}
                <div className="flex mt-4">
                    <Input
                        value={newCategoryName}
                        onChange={(e) => setNewCategoryName(e.target.value)}
                        placeholder="Enter category name"
                        className="w-full"
                    />
                    <Button
                        onClick={handleAddCategory}
                        disabled={!newCategoryName.trim() || loading}
                        className="ml-2 bg-[#344bfc] text-white rounded-full"
                    >
                        Add Category
                    </Button>
                </div>

                {/* Display List of Categories */}
                {(Array.isArray(categories) && categories.length > 0) ? (
                    categories.map((category) => (
                        <div key={category.id} className="flex items-center justify-between py-2">
                            {editingCategory && editingCategory.id === category.id ? (
                                <>
                                    <Input
                                        value={editingCategory.name}
                                        onChange={(e) => setEditingCategory({ ...editingCategory, name: e.target.value })}
                                        placeholder="Category name"
                                        className="w-full"
                                    />
                                    <Button
                                        onClick={handleSaveEdit}
                                        className="ml-2 rounded-full bg-[#344bfc] text-white"
                                        disabled={loading}
                                    >
                                        Save
                                    </Button>
                                </>
                            ) : (
                                <>
                                    <p className="font-medium">{category.name}</p>
                                    <div className="flex space-x-2">
                                        <Button
                                            variant="outline"
                                            onClick={() => handleEditCategory(category)}
                                            className="rounded-full"
                                        >
                                            Edit
                                        </Button>
                                        <Button
                                            variant="destructive"
                                            onClick={() => handleDeleteCategory(category.id)}
                                            className="rounded-full"
                                            disabled={category.line_items && category.line_items.length > 0}
                                        >
                                            Delete
                                        </Button>
                                    </div>
                                </>
                            )}
                        </div>
                    ))
                ) : (
                    <div className="text-center py-4">
                        <p>No categories available.</p>
                    </div>
                )}
            </CardContent>
        </Card>
    );
}