import React, { useState, useEffect } from 'react';
import { primaryButtonClasses } from '../common/ButtonStyles';
import { fetchSubscriptionPlans } from '../../services/api';

export const FeatureUpgrade = ({ featureName, className = '' }) => {
    const [featureDetails, setFeatureDetails] = useState(null);
    const [planDetails, setPlanDetails] = useState(null);

    useEffect(() => {
        const getDetails = async () => {
            try {
                const response = await fetchSubscriptionPlans();

                // Find the professional plan
                const professionalPlan = response.results.find(plan =>
                    plan.name === 'professional'
                );

                // Find the specific feature
                const feature = professionalPlan.features.find(f =>
                    f.name === featureName
                );

                setPlanDetails(professionalPlan);
                setFeatureDetails(feature);
            } catch (error) {
                console.error("Error fetching subscription details:", error);
            }
        };
        getDetails();
    }, [featureName]);

    if (!featureDetails || !planDetails) {
        return <div className="animate-pulse bg-gray-50 h-32 rounded-lg"></div>;
    }

    return (
        <div className={`p-6 bg-gradient-to-r from-gray-50 to-white rounded-lg border border-gray-200 shadow-sm ${className}`}>
            <div className="flex items-start">
                <div className="flex-grow">
                    <h4 className="text-xl font-semibold text-gray-900 mb-2">
                        {featureDetails.name === 'advanced_tasks' ? 'Advanced Task Features' :
                            featureDetails.name === 'basic_tasks' ? 'Basic Task Management' :
                                featureDetails.name.split('_').map(word =>
                                    word.charAt(0).toUpperCase() + word.slice(1)
                                ).join(' ')}
                    </h4>
                    <p className="text-gray-600 mb-4">{featureDetails.description}</p>

                    <div className="mb-4">
                        <h5 className="text-sm font-semibold text-gray-700 mb-2">Available in Professional Plan:</h5>
                        <ul className="space-y-2">
                            {featureDetails.name === 'advanced_tasks' && [
                                'File attachments for tasks',
                                'Task comments and discussions',
                                'Activity tracking and history',
                                'Task dependencies management',
                                'Advanced progress tracking'
                            ].map((benefit, index) => (
                                <li key={index} className="flex items-center text-gray-600">
                                    <svg className="w-4 h-4 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                    </svg>
                                    {benefit}
                                </li>
                            ))}
                        </ul>
                    </div>

                    <div className="mb-4">
                        <p className="text-sm text-gray-600">
                            Professional Plan: ${planDetails.monthly_price}/month
                        </p>
                    </div>

                    <a
                        href="/subscription"
                        className={`${primaryButtonClasses} inline-flex items-center justify-center px-6 py-3`}
                    >
                        Upgrade to Professional Plan
                    </a>
                </div>
            </div>
        </div>
    );
};