import React, { useState, useEffect, useRef } from 'react';
import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
} from "../../ui/cardui";
import { Alert, AlertDescription } from "../../ui/alert";
import { Button } from "../../ui/button";
import { Input } from "../../ui/input";
import { Textarea } from "../../ui/textarea";
import { Badge } from '../../ui/badge';
import { Label } from "../../ui/label";
import { Checkbox } from '../../ui/checkbox';
import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
} from "../../ui/tabs";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "../../ui/select";
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "../../ui/dialog";

import { ArrowUpCircle, ArrowRight, Tree, MoreHorizontal, X, Calendar, Plus } from "lucide-react";
import { Link } from "react-router-dom";
import { primaryButtonClasses, secondaryButtonClasses } from '../../common/ButtonStyles';
import { scheduleApi } from '../../../services/scheduleApi';
import GanttChart from './GanttChart.tsx';
import GanttTaskView from '../../Tasks/GanttTaskView.js';
import api from '../../../services/api.js';
import BasicMilestoneTracker from './BasicMilestoneTracker';
import WBSManager from './WBSManager';
import CriticalPathAnalysis from './CriticalPathAnalysis';
import BaselineComparison from './BaselineComparison';
import ResourceManagement from '../../Resources/ResourceManagement.js';
import ResourceAssignment from '../../Resources/ResourceAssignment.js';
import ResourceTimelineViewer from '../../Resources/ResourceTimelineViewer.js';
import PhaseCreationModal from './PhaseCreationModal';
import DependencyList from './DependencyList';
import PhaseCard from './PhaseCard';
import { toast } from 'react-toastify';
import ActivityList from './ActivityList';
import ActivityForm from './ActivityForm';
import secureLocalStorage from "react-secure-storage";
import { useFeatureAccess, FEATURES } from '../../../contexts/FeatureAccessContext.js';

const ScheduleController = ({
    projectId,
    scheduleType = 'basic_project_schedule',
    hasAdvancedAccess = false,
    configureRequest
}) => {

    const [phases, setPhases] = useState([]);
    const [activities, setActivities] = useState([]);
    const [dependencies, setDependencies] = useState([]);
    const [scheduleId, setScheduleId] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isPhaseModalOpen, setIsPhaseModalOpen] = useState(false);
    const [isActivityModalOpen, setIsActivityModalOpen] = useState(false);
    const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false);
    const [customModalOpen, setCustomModalOpen] = useState(false); // Simple custom modal state
    const [newScheduleName, setNewScheduleName] = useState('');
    const [defaultWbs, setDefaultWbs] = useState(null);
    const [schedules, setSchedules] = useState([]);
    const [selectedSchedule, setSelectedSchedule] = useState(null);
    const [showMoreMenu, setShowMoreMenu] = useState(false);
    const [activeTab, setActiveTab] = useState('phases');
    const [visibleTabs, setVisibleTabs] = useState([]);
    const [overflowTabs, setOverflowTabs] = useState([]);
    const [selectedActivity, setSelectedActivity] = useState(null);
    const { hasFeatureAccess } = useFeatureAccess();
    const canAccessScheduleFeatures = hasFeatureAccess(FEATURES.PROJECT_MANAGEMENT);

    // Add debugging for modal state changes
    useEffect(() => {
        console.log("Schedule modal state changed to:", isScheduleModalOpen);
    }, [isScheduleModalOpen]);

    useEffect(() => {
        const fetchWbsData = async () => {
            try {
                const wbsResponse = await api.get(`wbs/?project=${projectId}`);

                if (wbsResponse?.data?.results?.length > 0) {
                    const rootWbs = wbsResponse.data.results.find(wbs => wbs.level === 0);
                    if (rootWbs) {
                        setDefaultWbs(rootWbs);
                        return;
                    }
                }

                try {
                    const newWbs = await api.post(`wbs/`, {
                        project: projectId,
                        name: 'Project Root',
                        code: '1',
                        level: 0,
                        order: 0
                    });
                    setDefaultWbs(newWbs.data);
                } catch (error) {
                    console.error('Error creating WBS:', error);
                    setDefaultWbs(null);
                }
            } catch (error) {
                console.error('Error fetching WBS:', error);
                setDefaultWbs(null);
            }
        };

        if (projectId) {
            fetchWbsData();
        }
    }, [projectId]);

    useEffect(() => {
        fetchSchedules();
    }, [projectId]);

    const fetchSchedules = async () => {
        try {
            const response = await api.get(`projects/${projectId}/schedules/`);
            console.log('Schedules response:', response);
            if (response?.data?.results) {
                setSchedules(response.data.results);
                const activeSchedule = response.data.results.find(s => s.is_active && s.type === 'current');
                if (activeSchedule) setSelectedSchedule(activeSchedule);
            }
        } catch (error) {
            console.error('Error fetching schedules:', error);
            setError('Failed to load schedules');
        } finally {
            setLoading(false);
        }
    };

    const createNewSchedule = async () => {
        try {
            console.log("createNewSchedule function called");
            setLoading(true);

            // Get tenant ID from the same places the ProjectDetail component uses
            let tenantId =
                localStorage.getItem('tenantIdentifier') ||
                (typeof secureLocalStorage !== 'undefined' && secureLocalStorage.getItem('currentTenantId')) ||
                localStorage.getItem('tenantId') ||
                sessionStorage.getItem('tenantId') ||
                sessionStorage.getItem('tenantIdentifier');

            if (!tenantId) {
                try {
                    // Try regular localStorage userData
                    const userData = JSON.parse(localStorage.getItem('userData') || '{}');
                    tenantId = userData.tenant?.id || userData.tenantId;
                } catch (e) {
                    console.error("Error parsing user data:", e);
                }
            }

            console.log("Using tenant ID:", tenantId);

            const currentSchedules = schedules.filter(s =>
                s.type === 'current' &&
                s.project === projectId
            );
            const latestVersion = currentSchedules.length > 0
                ? Math.max(...currentSchedules.map(s => s.version))
                : 0;

            // Simplified data structure - only include fields that exist in the model
            const scheduleData = {
                project: projectId,
                type: 'current',
                version: latestVersion + 1,
                name: newScheduleName || `Schedule v${latestVersion + 1}`,
                start_date: new Date().toISOString().split('T')[0],
                end_date: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString().split('T')[0],
                is_active: true,
                tenant: tenantId
            };

            // Add the tenant ID in the header too, using configureRequest if available
            const config = configureRequest ? configureRequest() : {
                headers: {
                    'X-Tenant-ID': tenantId
                }
            };

            console.log('Creating schedule with data:', scheduleData);
            console.log('Using config:', config);

            const response = await api.post(`projects/${projectId}/schedules/`, scheduleData, config);
            const newSchedule = response.data;

            console.log('Schedule created successfully:', newSchedule);

            setSchedules(prev => [...prev, newSchedule]);
            setSelectedSchedule(newSchedule);
            setIsScheduleModalOpen(false);
            setCustomModalOpen(false);
            setNewScheduleName('');
            toast.success(`Schedule "${newSchedule.name || `v${newSchedule.version}`}" created successfully`);
            return newSchedule;
        } catch (error) {
            console.error('Error creating schedule:', error);
            if (error.response) {
                console.error('Response data:', error.response.data);
                console.error('Response status:', error.response.status);
            }
            toast.error('Failed to create schedule: ' + (error.response?.data?.detail || error.message || 'An error occurred'));
        } finally {
            setLoading(false);
        }
    };

    const handleOpenScheduleModal = () => {
        console.log("Opening schedule modal");
        setIsScheduleModalOpen(true);
    };

    const handleOpenCustomModal = () => {
        console.log("Opening custom modal");
        setCustomModalOpen(true);
    };

    const createActivity = async (data) => {
        try {
            const start = new Date(data.planned_start);
            const end = new Date(data.planned_finish);
            const durationDays = Math.max(1, Math.ceil((end - start) / (1000 * 60 * 60 * 24)));

            const payload = {
                ...data,
                project: projectId,
                wbs: defaultWbs.id,
                schedule: selectedSchedule.id,
                original_duration: durationDays,
                remaining_duration: durationDays
            };

            console.log('Sending to API:', payload);
            const response = await api.post(`projects/${projectId}/activities/`, payload);
            setActivities(prev => [...prev, response.data]);
            toast.success("Activity created successfully");
            return response.data;
        } catch (error) {
            console.log('Error response:', error.response?.data);
            throw error;
        }
    };

    useEffect(() => {
        const fetchProjectScheduleData = async () => {
            try {
                setLoading(true);

                const [phasesRes, activitiesRes] = await Promise.all([
                    api.get(`projects/${projectId}/phases/`),
                    api.get(`projects/${projectId}/activities/`)
                ]);

                if (phasesRes?.data?.results) {
                    setPhases(phasesRes.data.results);
                }
                if (activitiesRes?.data?.results) {
                    setActivities(activitiesRes.data.results);
                }

            } catch (error) {
                setError('Failed to load project schedule data');
                console.error('Error:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchProjectScheduleData();
    }, [projectId]);

    const handleActivitySubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const data = Object.fromEntries(formData.entries());
        await createActivity(data);
        setIsActivityModalOpen(false);
    };

    const deleteActivity = async (activityId) => {
        try {
            await api.delete(`projects/${projectId}/activities/${activityId}/`);
            setActivities(prev => prev.filter(activity => activity.id !== activityId));
        } catch (error) {
            setError('Failed to delete activity');
            console.error('Error:', error);
        }
    };

    const updatePhase = async (phaseId, field, value) => {
        try {
            const updateData = { [field]: value };
            const response = await api.patch(
                `projects/${projectId}/phases/${phaseId}/`,
                updateData
            );

            setPhases(prevPhases =>
                prevPhases.map(phase =>
                    phase.id === phaseId ? { ...phase, [field]: value } : phase
                )
            );
            toast.success("Phase updated successfully");
            return response.data;
        } catch (error) {
            console.error('Error updating phase:', error);
            throw error;
        }
    };

    const deletePhase = async (phaseId) => {
        try {
            await api.delete(`projects/${projectId}/phases/${phaseId}/`);
            setPhases(prevPhases => prevPhases.filter(phase => phase.id !== phaseId));
            toast.success("Phase deleted successfully");
        } catch (error) {
            console.error('Error deleting phase:', error);
            setError('Failed to delete phase');
        }
    };

    const addPhase = () => {
        setIsPhaseModalOpen(true);
    };

    const handlePhaseCreated = (newPhase) => {
        setPhases(prevPhases => [...prevPhases, newPhase]);
        setIsPhaseModalOpen(false);
    };

    const updateActivity = async (activityId, field, value) => {
        try {
            const response = await scheduleApi.updateActivity(activityId, { [field]: value });
            setActivities(prevActivities =>
                prevActivities.map(activity =>
                    activity.id === activityId ? { ...activity, [field]: value } : activity
                )
            );
            return response.data;
        } catch (error) {
            console.error('Error updating activity:', error);
            throw error;
        }
    };

    const addActivity = async (phaseId) => {
        if (!scheduleId) return;

        try {
            const newActivity = {
                name: 'New Activity',
                phase_id: phaseId,
                schedule_id: scheduleId,
                activity_type: 'task',
                priority: 'medium',
                original_duration: 1,
                percent_complete: 0
            };

            const response = await scheduleApi.createActivity(newActivity);
            setActivities(prevActivities => [...prevActivities, response.data]);
        } catch (error) {
            setError('Failed to create new activity');
            console.error('Error:', error);
        }
    };

    // Define tabs for both basic and advanced modes (Moved Gantt to last position)
    const basicTabs = [
        { id: 'phases', label: 'Phases' },
        { id: 'milestones', label: 'Milestones' },
        { id: 'gantt', label: 'Gantt' }
    ];

    const advancedTabs = [
        { id: 'phases', label: 'Phases' },
        { id: 'activities', label: 'Activities' },
        { id: 'dependencies', label: 'Dependencies' },
        { id: 'wbs', label: 'WBS' },
        { id: 'resources', label: 'Resources' },
        { id: 'critical-path', label: 'Critical Path' },
        { id: 'gantt', label: 'Gantt' }
    ];

    useEffect(() => {
        const calculateVisibleTabs = () => {
            const tabs = canAccessScheduleFeatures ? advancedTabs : basicTabs;
            const screenWidth = window.innerWidth;
            let visibleCount;

            // Always show at least 3 tabs before the More button
            if (screenWidth < 640) {
                visibleCount = 3; // Show 3 tabs on very small screens
            } else if (screenWidth < 768) {
                visibleCount = 4; // Show 4 tabs on medium small screens 
            } else if (screenWidth < 1024) {
                visibleCount = 5; // Show 5 tabs on medium screens
            } else {
                visibleCount = tabs.length; // Show all tabs on large screens
            }

            // Ensure we don't try to show more tabs than we have
            visibleCount = Math.min(visibleCount, tabs.length);

            setVisibleTabs(tabs.slice(0, visibleCount));
            setOverflowTabs(tabs.slice(visibleCount));
        };

        calculateVisibleTabs();
        window.addEventListener('resize', calculateVisibleTabs);

        return () => {
            window.removeEventListener('resize', calculateVisibleTabs);
        };
    }, [canAccessScheduleFeatures]);

    // Handle tab switching
    const handleTabChange = (tabId) => {
        setActiveTab(tabId);
        setShowMoreMenu(false);
    };

    // Add CSS to hide scrollbars but keep functionality
    useEffect(() => {
        const style = document.createElement('style');
        style.textContent = `
            .hide-scrollbar::-webkit-scrollbar {
                display: none;
            }
            .hide-scrollbar {
                -ms-overflow-style: none;
                scrollbar-width: none;
            }
        `;
        document.head.appendChild(style);
        return () => {
            document.head.removeChild(style);
        };
    }, []);

    const renderCustomModal = () => {
        if (!customModalOpen) return null;

        return (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
                    <h2 className="text-xl font-bold mb-4">Create New Schedule</h2>
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700 mb-1">Schedule Name</label>
                        <input
                            className="w-full px-3 py-2 border border-gray-300 rounded-lg"
                            value={newScheduleName}
                            onChange={(e) => setNewScheduleName(e.target.value)}
                            placeholder="Enter schedule name"
                        />
                    </div>
                    <div className="flex justify-end space-x-2">
                        <button
                            className="px-4 py-2 bg-gray-200 rounded"
                            onClick={() => setCustomModalOpen(false)}
                        >
                            Cancel
                        </button>
                        <button
                            className="px-4 py-2 bg-blue-500 text-white rounded"
                            onClick={() => {
                                console.log("Create schedule button clicked");
                                // Use the updated function
                                createNewSchedule();
                            }}
                            disabled={loading}
                        >
                            {loading ? 'Creating...' : 'Create Schedule'}
                        </button>
                    </div>
                </div>
            </div>
        );
    };

    const renderScheduleSelector = () => {
        if (schedules.length === 0) {
            return (
                <div className="mb-4 text-center">
                    <button
                        className="inline-flex items-center justify-center rounded-md bg-blue-600 px-4 py-2 text-sm font-medium text-white hover:bg-blue-700 w-full sm:w-auto"
                        onClick={handleOpenCustomModal}
                    >
                        <Calendar className="h-4 w-4 mr-2" />
                        Create Your First Schedule
                    </button>
                </div>
            );
        }

        return (
            <div className="mb-4">
                <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-2">
                    <div className="w-full sm:w-64">
                        <Label htmlFor="scheduleSelector" className="mb-1 block">Current Schedule</Label>
                        <Select
                            value={selectedSchedule?.id}
                            onValueChange={(value) => {
                                const schedule = schedules.find(s => s.id === value);
                                setSelectedSchedule(schedule);
                            }}
                        >
                            <SelectTrigger id="scheduleSelector" className="w-full">
                                <SelectValue placeholder="Select a schedule" />
                            </SelectTrigger>
                            <SelectContent>
                                {schedules.map(schedule => (
                                    <SelectItem key={schedule.id} value={schedule.id}>
                                        {schedule.name || `${schedule.type} v${schedule.version}`}
                                        {schedule.is_active && " (Active)"}
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    </div>
                    <button
                        className="inline-flex items-center justify-center rounded-md bg-white border border-gray-300 px-4 py-2 text-sm font-medium hover:bg-gray-50 mt-0 sm:mt-6"
                        onClick={handleOpenCustomModal}
                    >
                        <Plus className="h-4 w-4 mr-2" />
                        New Schedule
                    </button>
                </div>
            </div>
        );
    };

    const renderBasicSchedule = () => (
        <div className="space-y-6">
            <Card>
                <CardHeader>
                    <CardTitle>Project Timeline</CardTitle>
                </CardHeader>
                <CardContent className="px-1 sm:px-6">
                    {renderScheduleSelector()}

                    <div className="w-full border-b mb-4">
                        <div className="flex overflow-x-auto hide-scrollbar">
                            {visibleTabs.map(tab => (
                                <button
                                    key={tab.id}
                                    className={`
                                        py-2 px-3 mr-2 text-sm font-medium whitespace-nowrap 
                                        ${activeTab === tab.id
                                            ? 'border-b-2 border-blue-500 text-blue-600'
                                            : 'text-gray-500 hover:text-gray-700 hover:border-gray-300'
                                        }
                                    `}
                                    onClick={() => handleTabChange(tab.id)}
                                >
                                    {tab.label}
                                </button>
                            ))}

                            {overflowTabs.length > 0 && (
                                <button
                                    className={`
                                        py-2 px-3 mr-2 text-sm font-medium whitespace-nowrap flex items-center
                                        ${overflowTabs.some(tab => tab.id === activeTab)
                                            ? 'border-b-2 border-blue-500 text-blue-600'
                                            : 'text-gray-500 hover:text-gray-700 hover:border-gray-300'
                                        }
                                    `}
                                    onClick={() => setShowMoreMenu(true)}
                                >
                                    More <MoreHorizontal className="w-4 h-4 ml-1" />
                                </button>
                            )}
                        </div>
                    </div>

                    {showMoreMenu && (
                        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                            <div className="bg-white rounded-lg shadow-xl w-64 max-w-md mx-4">
                                <div className="flex justify-between items-center p-4 border-b">
                                    <h3 className="text-lg font-medium">More Options</h3>
                                    <button
                                        onClick={() => setShowMoreMenu(false)}
                                        className="text-gray-400 hover:text-gray-500"
                                    >
                                        <X className="h-5 w-5" />
                                    </button>
                                </div>
                                <div className="p-2">
                                    {overflowTabs.map(tab => (
                                        <button
                                            key={tab.id}
                                            className={`
                                                w-full text-left px-4 py-3 my-1 rounded flex items-center hover:bg-gray-100
                                                ${activeTab === tab.id ? 'bg-gray-100 text-blue-600' : 'text-gray-700'}
                                            `}
                                            onClick={() => handleTabChange(tab.id)}
                                        >
                                            <span className="text-base">{tab.label}</span>
                                        </button>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="mt-4">
                        {!selectedSchedule ? (
                            <Alert>
                                <AlertDescription>
                                    Please create a schedule to get started with project planning.
                                </AlertDescription>
                            </Alert>
                        ) : (
                            <>
                                {activeTab === 'phases' && (
                                    <div className="space-y-4 p-2 sm:p-4">
                                        {loading ? (
                                            <Card className="p-4">
                                                <div className="flex justify-center items-center h-24">
                                                    <p>Loading phases...</p>
                                                </div>
                                            </Card>
                                        ) : error ? (
                                            <Alert variant="destructive">
                                                <AlertDescription>{error}</AlertDescription>
                                            </Alert>
                                        ) : phases && phases.length > 0 ? (
                                            <>
                                                {phases.map(phase => {
                                                    return (
                                                        <PhaseCard
                                                            key={phase.id}
                                                            projectId={projectId}
                                                            phase={phase}
                                                            onUpdate={updatePhase}
                                                            onDelete={deletePhase}
                                                            isBasic={!canAccessScheduleFeatures}
                                                        />
                                                    );
                                                })}
                                                <Button
                                                    className={`${primaryButtonClasses} w-full sm:w-auto`}
                                                    onClick={() => setIsPhaseModalOpen(true)}
                                                >
                                                    Add Phase
                                                </Button>
                                            </>
                                        ) : (
                                            <Card className="p-4">
                                                <div className="text-center space-y-4">
                                                    <p className="text-muted-foreground">No phases created yet</p>
                                                    <Button
                                                        className={`${primaryButtonClasses} w-full sm:w-auto`}
                                                        onClick={() => setIsPhaseModalOpen(true)}
                                                    >
                                                        Create First Phase
                                                    </Button>
                                                </div>
                                            </Card>
                                        )}
                                    </div>
                                )}

                                {activeTab === 'milestones' && (
                                    <div className="p-2 sm:p-4">
                                        <BasicMilestoneTracker
                                            projectId={projectId}
                                        />
                                    </div>
                                )}

                                {activeTab === 'gantt' && (
                                    <div className="p-2 sm:p-4 overflow-x-auto">
                                        <GanttTaskView
                                            projectId={projectId}
                                            isBasic={true}
                                        />
                                    </div>
                                )}
                            </>
                        )}
                    </div>

                    <PhaseCreationModal
                        projectId={projectId}
                        isOpen={isPhaseModalOpen}
                        onClose={() => setIsPhaseModalOpen(false)}
                        onPhaseCreated={handlePhaseCreated}
                    />
                </CardContent>
            </Card>

            <Alert>
                <ArrowUpCircle className="h-4 w-4" />
                <AlertDescription className="text-sm">
                    Unlock advanced scheduling features with our Professional plan:
                    <ul className="mt-2 list-disc list-inside text-xs sm:text-sm">
                        <li>Work Breakdown Structure (WBS)</li>
                        <li>Resource allocation</li>
                        <li>Critical path analysis</li>
                        <li>Multiple baseline comparisons</li>
                        <li>Detailed progress tracking</li>
                    </ul>
                    <Link
                        to="/subscription"
                        className="mt-2 inline-flex text-blue-600 hover:text-blue-800 text-sm"
                    >
                        Upgrade Now →
                    </Link>
                </AlertDescription>
            </Alert>
        </div>
    );

    const renderAdvancedSchedule = () => (
        <div className="space-y-6">
            {renderScheduleSelector()}

            <div className="w-full border-b mb-4">
                <div className="flex overflow-x-auto hide-scrollbar">
                    {visibleTabs.map(tab => (
                        <button
                            key={tab.id}
                            className={`
                                py-2 px-3 mr-2 text-sm font-medium whitespace-nowrap 
                                ${activeTab === tab.id
                                    ? 'border-b-2 border-blue-500 text-blue-600'
                                    : 'text-gray-500 hover:text-gray-700 hover:border-gray-300'
                                }
                            `}
                            onClick={() => handleTabChange(tab.id)}
                        >
                            {tab.label}
                        </button>
                    ))}

                    {overflowTabs.length > 0 && (
                        <button
                            className={`
                                py-2 px-3 mr-2 text-sm font-medium whitespace-nowrap flex items-center
                                ${overflowTabs.some(tab => tab.id === activeTab)
                                    ? 'border-b-2 border-blue-500 text-blue-600'
                                    : 'text-gray-500 hover:text-gray-700 hover:border-gray-300'
                                }
                            `}
                            onClick={() => setShowMoreMenu(true)}
                        >
                            More <MoreHorizontal className="w-4 h-4 ml-1" />
                        </button>
                    )}
                </div>
            </div>

            {showMoreMenu && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white rounded-lg shadow-xl w-64 max-w-md mx-4">
                        <div className="flex justify-between items-center p-4 border-b">
                            <h3 className="text-lg font-medium">More Options</h3>
                            <button
                                onClick={() => setShowMoreMenu(false)}
                                className="text-gray-400 hover:text-gray-500"
                            >
                                <X className="h-5 w-5" />
                            </button>
                        </div>
                        <div className="p-2">
                            {overflowTabs.map(tab => (
                                <button
                                    key={tab.id}
                                    className={`
                                        w-full text-left px-4 py-3 my-1 rounded flex items-center hover:bg-gray-100
                                        ${activeTab === tab.id ? 'bg-gray-100 text-blue-600' : 'text-gray-700'}
                                    `}
                                    onClick={() => handleTabChange(tab.id)}
                                >
                                    <span className="text-base">{tab.label}</span>
                                </button>
                            ))}
                        </div>
                    </div>
                </div>
            )}

            {!selectedSchedule ? (
                <Alert>
                    <AlertDescription>
                        Please create a schedule to get started with project planning.
                    </AlertDescription>
                </Alert>
            ) : (
                <>
                    {activeTab === 'gantt' && (
                        <Card>
                            <CardContent className="p-2 sm:p-6 overflow-x-auto">
                                <GanttChart
                                    projectId={projectId}
                                    scheduleId={selectedSchedule?.id}
                                    isBasic={false}
                                    showResources={true}
                                    showCriticalPath={true}
                                />
                            </CardContent>
                        </Card>
                    )}

                    {activeTab === 'phases' && (
                        <div className="space-y-4 p-2 sm:p-4">
                            {phases.length > 0 ? (
                                phases.map(phase => (
                                    <PhaseCard
                                        key={phase.id}
                                        phase={phase}
                                        activities={activities.filter(a => a.phase_id === phase.id)}
                                        onAddActivity={() => addActivity(phase.id)}
                                        onUpdate={updatePhase}
                                        onDelete={deletePhase}
                                        isBasic={false}
                                    />
                                ))
                            ) : (
                                <Card className="p-4">
                                    <div className="text-center space-y-4">
                                        <p className="text-muted-foreground">No phases created yet</p>
                                        <Button
                                            className={`${primaryButtonClasses} w-full sm:w-auto`}
                                            onClick={() => setIsPhaseModalOpen(true)}
                                        >
                                            Create First Phase
                                        </Button>
                                    </div>
                                </Card>
                            )}
                            <Button className={`${primaryButtonClasses} w-full sm:w-auto`} onClick={addPhase}>
                                Add Phase
                            </Button>
                        </div>
                    )}

                    {activeTab === 'activities' && (
                        <Card>
                            <CardHeader className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-2">
                                <div>
                                    <CardTitle>Activities</CardTitle>
                                    {selectedSchedule && (
                                        <p className="text-sm text-muted-foreground">
                                            Schedule: {selectedSchedule.name || `${selectedSchedule.type} v${selectedSchedule.version}`}
                                            {selectedSchedule.is_active && (
                                                <Badge className="ml-2" variant="outline">Active</Badge>
                                            )}
                                        </p>
                                    )}
                                </div>
                                <div className="flex gap-2 w-full sm:w-auto">
                                    <Button
                                        onClick={() => setIsActivityModalOpen(true)}
                                        className={`${primaryButtonClasses} w-full sm:w-auto`}
                                        disabled={!selectedSchedule || !defaultWbs}
                                    >
                                        <Plus className="h-4 w-4 mr-2" />
                                        Add Activity
                                    </Button>
                                </div>
                            </CardHeader>
                            <CardContent className="p-2 sm:p-6">
                                {!defaultWbs?.id ? (
                                    <Alert variant="warning">
                                        <AlertDescription>
                                            Work Breakdown Structure not configured. Please set up your WBS in the WBS tab before creating activities.
                                        </AlertDescription>
                                    </Alert>
                                ) : (
                                    <div className="overflow-x-auto">
                                        <ActivityList
                                            activities={activities}
                                            phases={phases}
                                            onUpdate={updateActivity}
                                            onDelete={deleteActivity}
                                            projectId={projectId}
                                            scheduleId={selectedSchedule.id}
                                            wbsId={defaultWbs.id}
                                        />
                                    </div>
                                )}
                            </CardContent>
                        </Card>
                    )}

                    {selectedSchedule && defaultWbs && (
                        <ActivityForm
                            isOpen={isActivityModalOpen}
                            onClose={() => setIsActivityModalOpen(false)}
                            onSubmit={createActivity}
                            projectId={projectId}
                            scheduleId={selectedSchedule.id}
                            wbsId={defaultWbs.id}
                            mode="create"
                        />
                    )}

                    <Dialog open={isActivityModalOpen} onOpenChange={setIsActivityModalOpen}>
                        <DialogContent className="sm:max-w-md w-[95vw] max-h-[90vh] overflow-y-auto">
                            <DialogHeader>
                                <DialogTitle>Create New Activity</DialogTitle>
                            </DialogHeader>
                            <form onSubmit={handleActivitySubmit} className="space-y-4">
                                <div className="space-y-2">
                                    <Label htmlFor="name">Name</Label>
                                    <Input id="name" name="name" required />
                                </div>

                                <div className="space-y-2">
                                    <Label htmlFor="activity_type">Type</Label>
                                    <Select name="activity_type" defaultValue="task">
                                        <SelectTrigger>
                                            <SelectValue placeholder="Select type" />
                                        </SelectTrigger>
                                        <SelectContent>
                                            <SelectItem value="task">Task</SelectItem>
                                            <SelectItem value="milestone">Milestone</SelectItem>
                                            <SelectItem value="summary">Summary</SelectItem>
                                        </SelectContent>
                                    </Select>
                                </div>

                                <div className="space-y-2">
                                    <Label htmlFor="priority">Priority</Label>
                                    <Select name="priority" defaultValue="medium">
                                        <SelectTrigger>
                                            <SelectValue placeholder="Select priority" />
                                        </SelectTrigger>
                                        <SelectContent>
                                            <SelectItem value="low">Low</SelectItem>
                                            <SelectItem value="medium">Medium</SelectItem>
                                            <SelectItem value="high">High</SelectItem>
                                            <SelectItem value="critical">Critical</SelectItem>
                                        </SelectContent>
                                    </Select>
                                </div>

                                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                    <div className="space-y-2">
                                        <Label htmlFor="planned_start">Start Date</Label>
                                        <Input id="planned_start" name="planned_start" type="datetime-local" required />
                                    </div>
                                    <div className="space-y-2">
                                        <Label htmlFor="planned_finish">End Date</Label>
                                        <Input id="planned_finish" name="planned_finish" type="datetime-local" required />
                                    </div>
                                </div>

                                <DialogFooter className="flex flex-col sm:flex-row gap-2 sm:gap-0">
                                    <Button className={`${secondaryButtonClasses} w-full sm:w-auto`} type="button" variant="outline" onClick={() => setIsActivityModalOpen(false)}>
                                        Cancel
                                    </Button>
                                    <Button className={`${primaryButtonClasses} w-full sm:w-auto`} type="submit">Create Activity</Button>
                                </DialogFooter>
                            </form>
                        </DialogContent>
                    </Dialog>

                    {activeTab === 'dependencies' && (
                        <Card>
                            <CardHeader>
                                <CardTitle>Dependencies</CardTitle>
                                {selectedSchedule && (
                                    <p className="text-sm text-muted-foreground">
                                        Schedule: {selectedSchedule.name || `${selectedSchedule.type} v${selectedSchedule.version}`}
                                        {selectedSchedule.is_active && (
                                            <Badge className="ml-2" variant="outline">Active</Badge>
                                        )}
                                    </p>
                                )}
                            </CardHeader>
                            <CardContent className="p-2 sm:p-6 overflow-x-auto">
                                {!defaultWbs?.id ? (
                                    <Alert variant="warning">
                                        <AlertDescription>
                                            Work Breakdown Structure not configured. Please set up your WBS first.
                                        </AlertDescription>
                                    </Alert>
                                ) : (
                                    <DependencyList
                                        projectId={projectId}
                                        scheduleId={selectedSchedule.id}
                                    />
                                )}
                            </CardContent>
                        </Card>
                    )}

                    {activeTab === 'wbs' && (
                        <WBSManager projectId={projectId} />
                    )}

                    {activeTab === 'resources' && (
                        <div className="space-y-6">
                            <ResourceManagement projectId={projectId} scheduleId={selectedSchedule?.id} />
                            <ResourceAssignment projectId={projectId} scheduleId={selectedSchedule?.id} />
                            <ResourceTimelineViewer projectId={projectId} scheduleId={selectedSchedule?.id} />
                        </div>
                    )}

                    {activeTab === 'critical-path' && (
                        <CriticalPathAnalysis projectId={projectId} />
                    )}
                </>
            )}

            <PhaseCreationModal
                projectId={projectId}
                isOpen={isPhaseModalOpen}
                onClose={() => setIsPhaseModalOpen(false)}
                onPhaseCreated={handlePhaseCreated}
            />
        </div>
    );

    // Main render
    return (
        <div className="w-full max-w-7xl mx-auto p-2 sm:p-4 space-y-4">
            {error && (
                <Alert variant="destructive">
                    <AlertDescription>{error}</AlertDescription>
                </Alert>
            )}
            {/* Standard dialog component - might not work properly */}
            <Dialog open={isScheduleModalOpen} onOpenChange={setIsScheduleModalOpen}>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>Create New Schedule</DialogTitle>
                    </DialogHeader>
                    <div className="space-y-4">
                        <Label htmlFor="scheduleName">Schedule Name</Label>
                        <Input
                            id="scheduleName"
                            value={newScheduleName}
                            onChange={(e) => setNewScheduleName(e.target.value)}
                        />
                        <div className="flex justify-end space-x-2 mt-4">
                            <Button variant="outline" onClick={() => setIsScheduleModalOpen(false)}>
                                Cancel
                            </Button>
                            <Button
                                onClick={(event) => {
                                    event.stopPropagation();
                                    console.log("Create button clicked in Dialog");
                                    createNewSchedule();
                                }}
                            >
                                {loading ? 'Creating...' : 'Create Schedule'}
                            </Button>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>

            {/* Custom modal implementation that doesn't rely on Dialog component */}
            {renderCustomModal()}

            {loading ? (
                <Card>
                    <CardContent className="p-4">
                        <div className="flex items-center justify-center">
                            <span className="text-sm text-muted">Loading schedule data...</span>
                        </div>
                    </CardContent>
                </Card>
            ) : (
                !canAccessScheduleFeatures ?
                    <Alert>
                        <AlertDescription className="text-sm">
                            Advanced scheduling features require a Professional subscription.
                            <Link
                                to="/subscription"
                                className="block mt-2 sm:ml-2 sm:inline text-blue-600 hover:text-blue-800"
                            >
                                Upgrade now
                            </Link>
                        </AlertDescription>
                    </Alert> :
                    (canAccessScheduleFeatures && hasAdvancedAccess ?
                        renderAdvancedSchedule() :
                        renderBasicSchedule())
            )}
        </div>
    );
};

export default ScheduleController;