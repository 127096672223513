// src/components/EmployeeManagement.js

import React, { useState, useEffect } from 'react';
import { PlusIcon, ArrowPathIcon } from '@heroicons/react/24/solid';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import api from '../services/api';
import { toast } from 'react-toastify';

const schema = z.object({
  firstName: z.string().min(1, "First name is required"),
  lastName: z.string().min(1, "Last name is required"),
  email: z.string().email("Invalid email address"),
  employeeId: z.string().min(1, "Employee ID is required"),
  department: z.string().min(1, "Department is required"),
  jobTitle: z.string().min(1, "Job title is required"),
  hireDate: z.string().min(1, "Hire date is required"),
});

const EmployeeManagement = () => {
  const [employees, setEmployees] = useState([]);
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [currentEmployee, setCurrentEmployee] = useState(null);
  const { register, handleSubmit, reset } = useForm({ resolver: zodResolver(schema) });

  useEffect(() => {
    fetchEmployees();
  }, []);

  const fetchEmployees = async () => {
    try {
      const response = await api.get('employees/');
      setEmployees(response.data.results || []);
    } catch (error) {
      console.error('Error fetching employees:', error);
    }
  };

  const onSubmit = async (data) => {
    try {
      const payload = {
        first_name: data.firstName,
        last_name: data.lastName,
        email: data.email,
        employee_id: data.employeeId,
        department: data.department,
        job_title: data.jobTitle,
        hire_date: data.hireDate
      };
      
      if (currentEmployee) {
        await api.put(`employees/${currentEmployee.id}/`, payload);
        toast.success("Employee updated successfully");
      } else {
        await api.post('employees/', payload);
        toast.success("Employee added successfully");
      }
      
      fetchEmployees();
      setIsPanelOpen(false);
      reset();
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center space-y-4 sm:space-y-0">
        <h1 className="text-3xl font-bold text-midnight-blue">Employee Management</h1>
        <div className="flex space-x-2">
          <button 
            onClick={() => { setIsPanelOpen(true); setCurrentEmployee(null); reset(); }}
            className="bg-brand-blue text-white px-6 py-2 rounded-full hover:bg-lavender-mist hover:text-midnight-blue transition duration-300 flex items-center justify-center"
          >
            <PlusIcon className="h-5 w-5 mr-2" />
            Add New
          </button>
          <button 
            onClick={fetchEmployees}
            className="bg-snowfall text-midnight-blue px-6 py-2 rounded-full hover:bg-lavender-mist transition duration-300 flex items-center justify-center"
          >
            <ArrowPathIcon className="h-5 w-5 mr-2" />
            Refresh
          </button>
        </div>
      </div>

      <div className="bg-white shadow rounded-lg p-6">
        <h2 className="text-xl font-semibold text-midnight-blue mb-4">Overview</h2>
        <p className="text-gray-600">You have {employees.length} employees in your system. Manage them below.</p>
      </div>

      <div className="bg-white shadow rounded-lg p-6">
        <h2 className="text-xl font-semibold text-midnight-blue mb-4">Employee List</h2>
        <ul className="divide-y divide-gray-200">
          {employees.map((employee) => (
            <li key={employee.id} className="py-4">
              <div className="flex items-center space-x-4">
                <div className="flex-shrink-0">
                  <div className="h-8 w-8 rounded-full bg-lavender-mist flex items-center justify-center">
                    <span className="text-brand-blue font-semibold">
                      {employee.full_name.charAt(0)}
                    </span>
                  </div>
                </div>
                <div className="flex-1 min-w-0">
                  <p className="text-sm font-medium text-midnight-blue truncate">
                    {employee.full_name}
                  </p>
                  <p className="text-sm text-gray-500 truncate">
                    Email: {employee.email} - Department: {employee.department}
                  </p>
                </div>
                <div>
                  <button 
                    onClick={() => { setCurrentEmployee(employee); setIsPanelOpen(true); }}
                    className="inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50"
                  >
                    View
                  </button>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>

      {/* Slide-in Panel for Add/Edit Employee Form */}
      {isPanelOpen && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-end z-50 transition-opacity duration-300 ease-out">
          <div className={`bg-white w-1/3 p-6 shadow-lg transform transition-transform duration-300 ease-in-out ${isPanelOpen ? 'translate-x-0' : 'translate-x-full'}`}>
            <h2 className="text-xl font-semibold mb-4">{currentEmployee ? "Edit Employee" : "Add Employee"}</h2>
            <form onSubmit={handleSubmit(onSubmit)} className="grid gap-4">
              <input {...register("firstName")} defaultValue={currentEmployee?.first_name} placeholder="First Name" className="border p-2 rounded" />
              <input {...register("lastName")} defaultValue={currentEmployee?.last_name} placeholder="Last Name" className="border p-2 rounded" />
              <input {...register("email")} defaultValue={currentEmployee?.email} placeholder="Email" className="border p-2 rounded" />
              <input {...register("employeeId")} defaultValue={currentEmployee?.employee_id} placeholder="Employee ID" className="border p-2 rounded" />
              <input {...register("department")} defaultValue={currentEmployee?.department} placeholder="Department" className="border p-2 rounded" />
              <input {...register("jobTitle")} defaultValue={currentEmployee?.job_title} placeholder="Job Title" className="border p-2 rounded" />
              <input {...register("hireDate")} defaultValue={currentEmployee?.hire_date} type="date" className="border p-2 rounded" />
              <button type="submit" className="mt-4 bg-brand-blue text-white p-2 rounded">
                {currentEmployee ? "Update Employee" : "Submit"}
              </button>
            </form>
            <button onClick={() => setIsPanelOpen(false)} className="mt-4 text-gray-600">Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default EmployeeManagement;
