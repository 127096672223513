import { useState, useEffect } from 'react'

const TOAST_LIMIT = 1
const TOAST_REMOVE_DELAY = 1000000

export function useToast() {
  const [toasts, setToasts] = useState([])

  useEffect(() => {
    toasts.forEach((toast) => {
      if (toast.duration) {
        const timer = setTimeout(() => {
          dismissToast(toast.id)
        }, toast.duration)

        return () => clearTimeout(timer)
      }
    })
  }, [toasts])

  const toast = ({ title, description, variant = "default", duration = 5000 }) => {
    const id = Math.random().toString(36).substr(2, 9)
    
    setToasts((prevToasts) => {
      const newToasts = [
        ...prevToasts,
        { id, title, description, variant, duration },
      ].slice(-TOAST_LIMIT)

      return newToasts
    })

    return id
  }

  const dismissToast = (id) => {
    setToasts((prevToasts) => {
      const targetToast = prevToasts.find((toast) => toast.id === id)
      if (!targetToast) return prevToasts

      // Remove the toast after a delay
      setTimeout(() => {
        setToasts((prevToasts) =>
          prevToasts.filter((toast) => toast.id !== id)
        )
      }, TOAST_REMOVE_DELAY)

      return prevToasts
    })
  }

  return {
    toast,
    dismissToast,
    toasts,
  }
}