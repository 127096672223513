import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '../../components/ui/dialog';
import { Form, FormField, FormItem, FormLabel, FormControl, FormMessage } from '../../components/ui/form';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../components/ui/select';
import { Table, TableHeader, TableBody, TableRow, TableHead, TableCell } from '../../components/ui/table';
import { Textarea } from '../../components/ui/textarea';
import { Input } from '../../components/ui/input';
import { Button } from '../../components/ui/button';
import { ScrollArea } from '../../components/ui/scroll-area';
import { AlertCircle, CheckCircle2, Edit, Trash2, FileText } from 'lucide-react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import api from '../../services/api';
import safetyApi from '../../services/safetyService';
import { primaryButtonClasses } from '../../components/common/ButtonStyles';

// Safety Audit Form Schema
const auditFormSchema = z.object({
    audit_type: z.string(),
    subtype: z.string(),
    planned_date: z.string(),
    location: z.string(),
    priority: z.string(),
    reviewer: z.string().optional(),
    participants: z.array(z.string()).optional(),
});

const formatDisplayName = (name) => {
    // Extract the part after the last underscore
    const baseType = name.split('_').pop();
    // Capitalize the first letter
    return baseType.charAt(0).toUpperCase() + baseType.slice(1);
};

// Forms Components
export const SafetyAuditForm = ({ project, isOpen, onClose, onSubmit }) => {
    const form = useForm({
        resolver: zodResolver(auditFormSchema),
        defaultValues: {
            audit_type: 'safety',
            subtype: 'regular',
            planned_date: new Date().toISOString().split('T')[0],
            location: '',
            priority: 'medium',
            reviewer: '',
            participants: []
        }
    });

    const [auditTypes, setAuditTypes] = useState([]);
    const [safetySubtypes, setSafetySubtypes] = useState([]);

    useEffect(() => {
        const fetchTypes = async () => {
            try {
                const [auditTypesRes, subtypesRes] = await Promise.all([
                    safetyApi.getAuditTypes(),
                    safetyApi.getSafetySubtypes()
                ]);
                setAuditTypes(auditTypesRes.data.types);
                setSafetySubtypes(subtypesRes.data.subtypes);
            } catch (error) {
                console.error('Error fetching audit types:', error);
            }
        };
        fetchTypes();
    }, []);

    const handleSubmit = async (data) => {
        try {
            const response = await api.post(`projects/${project.id}/safety-audits/`, {
                ...data,
                project: project.id,
            });
            onSubmit(response.data);
            onClose();
        } catch (error) {
            console.error('Error creating audit:', error);
        }
    };

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className="sm:max-w-[600px]">
                <DialogHeader>
                    <DialogTitle>Start Safety Audit</DialogTitle>
                </DialogHeader>
                <ScrollArea className="max-h-[80vh] px-4">
                    <Form {...form}>
                        <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-4">
                            <FormField
                                control={form.control}
                                name="audit_type"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Audit Type</FormLabel>
                                        <Select onValueChange={field.onChange} defaultValue={field.value}>
                                            <SelectTrigger>
                                                <SelectValue placeholder="Select audit type" />
                                            </SelectTrigger>
                                            <SelectContent>
                                                {auditTypes.map(type => (
                                                    <SelectItem key={type.id} value={type.name}>
                                                        {formatDisplayName(type.name)}
                                                    </SelectItem>
                                                ))}
                                            </SelectContent>
                                        </Select>
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={form.control}
                                name="subtype"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Audit Subtype</FormLabel>
                                        <Select onValueChange={field.onChange} defaultValue={field.value}>
                                            <SelectTrigger>
                                                <SelectValue placeholder="Select audit subtype" />
                                            </SelectTrigger>
                                            <SelectContent>
                                                {safetySubtypes.map(subtype => (
                                                    <SelectItem key={subtype.id} value={subtype.name}>
                                                        {formatDisplayName(subtype.name)}
                                                    </SelectItem>
                                                ))}
                                            </SelectContent>
                                        </Select>
                                    </FormItem>
                                )}
                            />

                            <FormField
                                control={form.control}
                                name="planned_date"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Planned Date</FormLabel>
                                        <Input type="date" {...field} />
                                    </FormItem>
                                )}
                            />

                            <FormField
                                control={form.control}
                                name="location"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Location</FormLabel>
                                        <Input {...field} placeholder="Audit location" />
                                    </FormItem>
                                )}
                            />

                            <FormField
                                control={form.control}
                                name="priority"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Priority</FormLabel>
                                        <Select onValueChange={field.onChange} defaultValue={field.value}>
                                            <SelectTrigger>
                                                <SelectValue placeholder="Select priority" />
                                            </SelectTrigger>
                                            <SelectContent>
                                                <SelectItem value="low">Low</SelectItem>
                                                <SelectItem value="medium">Medium</SelectItem>
                                                <SelectItem value="high">High</SelectItem>
                                            </SelectContent>
                                        </Select>
                                    </FormItem>
                                )}
                            />
                            <Button className={primaryButtonClasses} type="submit">Start Audit</Button>
                        </form>
                    </Form>
                </ScrollArea>
            </DialogContent>
        </Dialog>
    );
};