import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
    ChartBarIcon,
    PhotoIcon,
    CalendarIcon,
    CheckCircleIcon,
    ExclamationTriangleIcon,
    DocumentIcon,
    CurrencyDollarIcon,
    ClockIcon
} from '@heroicons/react/24/outline';
import { format, parseISO } from 'date-fns';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer
} from 'recharts';
import api from '../../services/api';

// Custom Components
const MetricCard = ({ title, value, trend, icon: Icon, description }) => (
    <div className="bg-white rounded-lg shadow-sm p-6">
        <div className="flex items-center justify-between">
            <div className="flex items-center">
                <div className="rounded-lg p-3 bg-blue-50">
                    <Icon className="h-6 w-6 text-blue-600" />
                </div>
                <div className="ml-4">
                    <h3 className="text-sm font-medium text-gray-500">{title}</h3>
                    <p className="text-2xl font-semibold text-gray-900">{value}</p>
                    {trend && (
                        <p className={`text-sm ${trend > 0 ? 'text-green-600' : 'text-red-600'}`}>
                            {trend > 0 ? '↑' : '↓'} {Math.abs(trend)}%
                        </p>
                    )}
                </div>
            </div>
        </div>
        {description && (
            <p className="mt-2 text-sm text-gray-500">{description}</p>
        )}
    </div>
);

const RecentActivity = ({ updates, onViewAll }) => (
    <div className="bg-white rounded-lg shadow-sm p-6">
        <h3 className="text-lg font-medium text-gray-900 mb-4">
            Recent Activity
        </h3>
        <div className="space-y-4">
            {updates.map((update, index) => (
                <div
                    key={index}
                    className="flex items-start space-x-3 border-b border-gray-200 pb-4 last:border-0"
                >
                    <div className={`rounded-full p-1 ${update.type === 'progress' ? 'bg-blue-100' :
                        update.type === 'quality' ? 'bg-green-100' :
                            'bg-yellow-100'
                        }`}>
                        {update.icon}
                    </div>
                    <div>
                        <p className="text-sm font-medium text-gray-900">
                            {update.title}
                        </p>
                        <p className="text-sm text-gray-500">{update.description}</p>
                        <p className="text-xs text-gray-400 mt-1">
                            {format(parseISO(update.date), 'MMM d, h:mm a')}
                        </p>
                    </div>
                </div>
            ))}
        </div>
        <button
            className="mt-4 text-sm text-blue-600 hover:text-blue-700 font-medium"
            onClick={onViewAll}
        >
            View all activity →
        </button>
    </div>
);

const PhaseTimeline = ({ phases }) => (
    <div className="bg-white rounded-lg shadow-sm p-6">
        <h3 className="text-lg font-medium text-gray-900 mb-6">
            Project Phases
        </h3>
        <div className="space-y-8">
            {phases.map((phase, index) => (
                <div key={index} className="relative">
                    {index !== phases.length - 1 && (
                        <div className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" />
                    )}
                    <div className="relative flex items-start">
                        <div className={`relative flex h-8 w-8 items-center justify-center rounded-full ${phase.status === 'completed' ? 'bg-green-500' :
                            phase.status === 'in_progress' ? 'bg-blue-500' :
                                'bg-gray-200'
                            }`}>
                            <CheckCircleIcon className="h-5 w-5 text-white" />
                        </div>
                        <div className="ml-4 min-w-0 flex-1">
                            <div className="flex items-center justify-between">
                                <h4 className="text-sm font-medium text-gray-900">
                                    {phase.name}
                                </h4>
                                <span className="text-sm text-gray-500">
                                    {phase.completion}% Complete
                                </span>
                            </div>
                            <p className="mt-1 text-sm text-gray-500">
                                {format(parseISO(phase.start_date), 'MMM d')} -
                                {format(parseISO(phase.end_date), 'MMM d, yyyy')}
                            </p>
                            <div className="mt-2 h-2 rounded-full bg-gray-200">
                                <div
                                    className="h-full rounded-full bg-blue-600"
                                    style={{ width: `${phase.completion}%` }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    </div>
);

const ClientPortalDashboard = () => {
    const { projectId } = useParams();
    const navigate = useNavigate();
    const [projectData, setProjectData] = useState(null);
    const [recentUpdates, setRecentUpdates] = useState([]);
    const [qualityMetrics, setQualityMetrics] = useState(null);
    const [loading, setLoading] = useState(true);
    const [selectedTab, setSelectedTab] = useState('overview');
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchProjectData();
    }, [projectId]);

    const fetchProjectData = async () => {
        try {
            const responses = await Promise.all([
                api.get(`client-portal/projects/${projectId}/summary/`),
                api.get(`client-portal/projects/${projectId}/updates/`),
                api.get(`client-portal/projects/${projectId}/quality-metrics/`)
            ]);

            const hasError = responses.some(response => response.status === 'rejected');

            if (hasError) {
                throw new Error('Failed to load project data. The project may not exist or you may not have access.');
            }

            // Extract data from successful responses
            const [projectRes, updatesRes, qualityRes] = responses.map(r => r.value);

            setProjectData(projectRes.data);
            setRecentUpdates(updatesRes.data);
            setQualityMetrics(qualityRes.data);
        } catch (error) {
            console.error('Error fetching project data:', error);
            setError(error.message || 'An error occurred while loading the project.');
        } finally {
            setLoading(false);
        }
    };

    const ProgressOverview = () => (
        <div className="space-y-6">
            {/* Project Progress Summary */}
            <div className="bg-white rounded-lg shadow-sm p-6">
                <div className="flex justify-between items-center mb-4">
                    <h3 className="text-lg font-medium text-gray-900">Project Progress</h3>
                    <span className="text-sm text-gray-500">
                        Last updated: {format(parseISO(projectData.last_update), 'MMM d, yyyy')}
                    </span>
                </div>

                <div className="relative pt-1">
                    <div className="flex mb-2 items-center justify-between">
                        <div>
                            <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blue-600 bg-blue-200">
                                Overall Progress
                            </span>
                        </div>
                        <div className="text-right">
                            <span className="text-xs font-semibold inline-block text-blue-600">
                                {projectData.progress}%
                            </span>
                        </div>
                    </div>
                    <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-blue-200">
                        <div
                            style={{ width: `${projectData.progress}%` }}
                            className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-600"
                        />
                    </div>
                </div>

                {/* Progress Chart */}
                <div className="h-64 mt-6">
                    <ResponsiveContainer width="100%" height="100%">
                        <LineChart data={projectData.progress_history}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                                dataKey="date"
                                tickFormatter={(date) => format(parseISO(date), 'MMM d')}
                            />
                            <YAxis />
                            <Tooltip
                                labelFormatter={(date) => format(parseISO(date), 'MMM d, yyyy')}
                                formatter={(value) => [`${value}%`, 'Progress']}
                            />
                            <Line
                                type="monotone"
                                dataKey="progress"
                                stroke="#2563eb"
                                strokeWidth={2}
                            />
                        </LineChart>
                    </ResponsiveContainer>
                </div>
            </div>

            {/* Current Phase Details */}
            <div className="bg-white rounded-lg shadow-sm p-6">
                <h3 className="text-lg font-medium text-gray-900 mb-4">Current Phase</h3>
                <div className="space-y-4">
                    <div className="flex justify-between items-center">
                        <div>
                            <p className="text-xl font-semibold text-gray-900">
                                {projectData.current_phase.name}
                            </p>
                            <p className="text-sm text-gray-500">
                                {format(parseISO(projectData.current_phase.start_date), 'MMM d')} -
                                {format(parseISO(projectData.current_phase.end_date), 'MMM d, yyyy')}
                            </p>
                        </div>
                        <span className="px-3 py-1 text-sm font-medium rounded-full bg-blue-100 text-blue-800">
                            {projectData.current_phase.progress}% Complete
                        </span>
                    </div>
                    <p className="text-gray-600">
                        {projectData.current_phase.description}
                    </p>
                </div>
            </div>

            {/* Recent Photo Updates */}
            {projectData.recent_photos.length > 0 && (
                <div className="bg-white rounded-lg shadow-sm p-6">
                    <h3 className="text-lg font-medium text-gray-900 mb-4">Recent Photos</h3>
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                        {projectData.recent_photos.map((photo, index) => (
                            <div key={index} className="relative group">
                                <img
                                    src={photo.url}
                                    alt={photo.caption}
                                    className="w-full h-48 object-cover rounded-lg"
                                />
                                <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-50 transition-opacity duration-200 rounded-lg">
                                    <div className="absolute bottom-0 left-0 right-0 p-4 text-white opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                                        <p className="text-sm font-medium">{photo.caption}</p>
                                        <p className="text-xs">
                                            {format(parseISO(photo.date), 'MMM d, yyyy')}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );

    const QualityOverview = () => (
        <div className="space-y-6">
            {/* Quality Metrics Summary */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                <div className="bg-white rounded-lg shadow-sm p-6">
                    <div className="flex items-center">
                        <CheckCircleIcon className="h-8 w-8 text-green-500" />
                        <div className="ml-4">
                            <p className="text-sm font-medium text-gray-500">
                                Quality Score
                            </p>
                            <p className="text-2xl font-semibold text-gray-900">
                                {qualityMetrics.quality_score}%
                            </p>
                        </div>
                    </div>
                </div>

                <div className="bg-white rounded-lg shadow-sm p-6">
                    <div className="flex items-center">
                        <ClockIcon className="h-8 w-8 text-blue-500" />
                        <div className="ml-4">
                            <p className="text-sm font-medium text-gray-500">
                                Inspections Complete
                            </p>
                            <p className="text-2xl font-semibold text-gray-900">
                                {qualityMetrics.inspections_completed}
                            </p>
                        </div>
                    </div>
                </div>

                <div className="bg-white rounded-lg shadow-sm p-6">
                    <div className="flex items-center">
                        <ExclamationTriangleIcon
                            className={`h-8 w-8 ${qualityMetrics.open_issues === 0 ? 'text-green-500' : 'text-yellow-500'
                                }`}
                        />
                        <div className="ml-4">
                            <p className="text-sm font-medium text-gray-500">
                                Open Issues
                            </p>
                            <p className="text-2xl font-semibold text-gray-900">
                                {qualityMetrics.open_issues}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            {/* Recent Quality Updates */}
            <div className="bg-white rounded-lg shadow-sm p-6">
                <h3 className="text-lg font-medium text-gray-900 mb-4">
                    Recent Quality Updates
                </h3>
                <div className="space-y-4">
                    {qualityMetrics.recent_updates.map((update, index) => (
                        <div key={index} className="border-b border-gray-200 pb-4 last:border-0">
                            <div className="flex justify-between items-start">
                                <div>
                                    <p className="text-sm font-medium text-gray-900">
                                        {update.title}
                                    </p>
                                    <p className="text-sm text-gray-500 mt-1">
                                        {update.description}
                                    </p>
                                </div>
                                <span className={`px-2 py-1 text-xs font-medium rounded-full ${update.type === 'passed' ? 'bg-green-100 text-green-800' :
                                    update.type === 'issue' ? 'bg-yellow-100 text-yellow-800' :
                                        'bg-blue-100 text-blue-800'
                                    }`}>
                                    {update.type.toUpperCase()}
                                </span>
                            </div>
                            <p className="text-xs text-gray-500 mt-2">
                                {format(parseISO(update.date), 'MMM d, yyyy')}
                            </p>
                        </div>
                    ))}
                </div>
            </div>

            {/* Quality Standards */}
            <div className="bg-white rounded-lg shadow-sm p-6">
                <h3 className="text-lg font-medium text-gray-900 mb-4">
                    Quality Standards
                </h3>
                <div className="space-y-4">
                    {qualityMetrics.standards.map((standard, index) => (
                        <div key={index} className="flex items-start">
                            <CheckCircleIcon className="h-5 w-5 text-green-500 mt-0.5" />
                            <div className="ml-3">
                                <p className="text-sm font-medium text-gray-900">
                                    {standard.title}
                                </p>
                                <p className="text-sm text-gray-500">
                                    {standard.description}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );

    const TimelineUpdates = () => (
        <div className="bg-white rounded-lg shadow-sm p-6">
            <h3 className="text-lg font-medium text-gray-900 mb-6">Project Timeline</h3>
            <div className="flow-root">
                <ul className="-mb-8">
                    {recentUpdates.map((update, index) => (
                        <li key={index}>
                            <div className="relative pb-8">
                                {index !== recentUpdates.length - 1 && (
                                    <span
                                        className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                                        aria-hidden="true"
                                    />
                                )}
                                <div className="relative flex space-x-3">
                                    <div>
                                        <span className={`h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white ${update.type === 'progress' ? 'bg-blue-500' :
                                            update.type === 'quality' ? 'bg-green-500' :
                                                update.type === 'issue' ? 'bg-yellow-500' :
                                                    'bg-gray-500'
                                            }`}>
                                            {update.type === 'progress' && (
                                                <ChartBarIcon className="h-5 w-5 text-white" />
                                            )}
                                            {update.type === 'quality' && (
                                                <CheckCircleIcon className="h-5 w-5 text-white" />
                                            )}
                                            {update.type === 'issue' && (
                                                <ExclamationTriangleIcon className="h-5 w-5 text-white" />
                                            )}
                                        </span>
                                    </div>
                                    <div className="min-w-0 flex-1 pt-1.5">
                                        <div>
                                            <p className="text-sm font-medium text-gray-900">
                                                {update.title}
                                            </p>
                                            <p className="mt-1 text-sm text-gray-500">
                                                {update.description}
                                            </p>
                                        </div>
                                        <div className="mt-2">
                                            {update.photos?.length > 0 && (
                                                <div className="flex space-x-2 mt-2">
                                                    {update.photos.map((photo, photoIndex) => (
                                                        <img
                                                            key={photoIndex}
                                                            src={photo.url}
                                                            alt={photo.caption}
                                                            className="h-20 w-20 object-cover rounded"
                                                        />
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                        <div className="mt-2 text-xs text-gray-500">
                                            {format(parseISO(update.date), 'MMM d, yyyy h:mm a')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );

    if (loading || !projectData || !qualityMetrics) {
        return (
            <div className="flex items-center justify-center min-h-screen">
                <div className="text-gray-600">Loading project data...</div>
            </div>
        );
    }

    return (
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            {/* Project Header */}
            <div className="mb-8">
                <div className="flex justify-between items-center">
                    <h1 className="text-3xl font-bold text-gray-900">
                        {projectData?.name}
                    </h1>
                    <span className={`px-4 py-2 rounded-full text-sm font-medium ${projectData?.status === 'on_track' ? 'bg-green-100 text-green-800' :
                        projectData?.status === 'at_risk' ? 'bg-yellow-100 text-yellow-800' :
                            'bg-red-100 text-red-800'
                        }`}>
                        {projectData?.status === 'on_track' ? 'On Track' :
                            projectData?.status === 'at_risk' ? 'At Risk' : 'Delayed'}
                    </span>
                </div>
                <div className="mt-2 grid grid-cols-1 sm:grid-cols-4 gap-4">
                    <div className="flex items-center text-sm text-gray-500">
                        <CalendarIcon className="h-5 w-5 mr-2" />
                        <span>
                            {format(parseISO(projectData?.start_date), 'MMM d, yyyy')} -
                            {format(parseISO(projectData?.end_date), 'MMM d, yyyy')}
                        </span>
                    </div>
                    <div className="flex items-center text-sm text-gray-500">
                        <DocumentIcon className="h-5 w-5 mr-2" />
                        <span>{projectData?.phase_name}</span>
                    </div>
                    <div className="flex items-center text-sm text-gray-500">
                        <CurrencyDollarIcon className="h-5 w-5 mr-2" />
                        <span>{projectData?.budget_status}</span>
                    </div>
                    <div className="flex items-center text-sm text-gray-500">
                        <ClockIcon className="h-5 w-5 mr-2" />
                        <span>{projectData?.days_remaining} days remaining</span>
                    </div>
                </div>
            </div>

            {/* Navigation Tabs */}
            <div className="border-b border-gray-200 mb-6">
                <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                    {[
                        { name: 'Overview', key: 'overview' },
                        { name: 'Progress', key: 'progress' },
                        { name: 'Quality', key: 'quality' },
                        { name: 'Timeline', key: 'timeline' }
                    ].map((tab) => (
                        <button
                            key={tab.key}
                            onClick={() => setSelectedTab(tab.key)}
                            className={`${selectedTab === tab.key
                                ? 'border-blue-500 text-blue-600'
                                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                                } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm`}
                        >
                            {tab.name}
                        </button>
                    ))}
                </nav>
            </div>

            {/* Key Metrics Cards */}
            <div className="grid grid-cols-1 md:grid-cols-4 gap-6 mb-6">
                <MetricCard
                    title="Overall Progress"
                    value={`${projectData?.progress}%`}
                    trend={projectData?.progress_trend}
                    icon={ChartBarIcon}
                    description="Project completion percentage"
                />
                <MetricCard
                    title="Quality Score"
                    value={`${qualityMetrics?.quality_score}%`}
                    trend={qualityMetrics?.quality_trend}
                    icon={CheckCircleIcon}
                    description="Overall quality rating"
                />
                <MetricCard
                    title="Budget Status"
                    value={projectData?.budget_utilization}
                    trend={projectData?.budget_trend}
                    icon={CurrencyDollarIcon}
                    description="Budget utilization rate"
                />
                <MetricCard
                    title="Timeline Status"
                    value={projectData?.schedule_status}
                    icon={CalendarIcon}
                    description={`${projectData?.days_remaining} days remaining`}
                />
            </div>

            {/* Tab Content */}
            {selectedTab === 'overview' && (
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
                    <div className="lg:col-span-2">
                        <ProgressOverview />
                    </div>
                    <div>
                        <RecentActivity updates={recentUpdates.slice(0, 5)} />
                    </div>
                </div>
            )}

            {selectedTab === 'progress' && (
                <div className="space-y-6">
                    <ProgressOverview />
                    <PhaseTimeline phases={projectData?.phases} />
                </div>
            )}

            {selectedTab === 'quality' && <QualityOverview />}

            {selectedTab === 'timeline' && <TimelineUpdates />}

            {/* Action Items Panel */}
            <div className="fixed bottom-0 right-0 m-6">
                <div className="bg-white rounded-lg shadow-lg p-4">
                    <h4 className="text-sm font-medium text-gray-900 mb-2">
                        Action Items
                    </h4>
                    {projectData?.action_items.map((item, index) => (
                        <div
                            key={index}
                            className="flex items-center space-x-2 text-sm text-gray-600 hover:text-gray-900 cursor-pointer"
                        >
                            <span>{item.icon}</span>
                            <span>{item.text}</span>
                        </div>
                    ))}
                </div>
            </div>

        </div>
    );
};

export default ClientPortalDashboard;